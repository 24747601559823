import React, { useState, useEffect } from "react";
import "pages/common.css";
import "./style.css";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { SignalTrendFilters } from "components/MtrComponents/SignalTrend/SignalTrendFilters";
import { Header } from "components/MtrComponents/Header";
import { HealthDistributionTrend } from "components/MtrComponents/HealthDistributionTrend";
import { LastUpdated } from "components/MtrComponents/LastUpdated";
import { SignalTrend } from "components/MtrComponents/SignalTrend";
import { TopTrendingSignals } from "components/MtrComponents/TopTrendingSignals";
import { UnhealthyTenantMoverShaker } from "components/MtrComponents/UnhealthyTenantMoverShaker";
import { HealthyTenantTrend } from "components/MtrComponents/UnhealthyTenantTrend";
import { Workload } from "config/PlatformConfig";
import {
    commonPageStyle,
    commonPageBlock,
    splitCommonPageBlockIntoThree,
    headerBlock,
} from "pages/common";
import AppInsights from "utils/AppInsights";
import { SignalTrendFilter, MtrPivots } from "utils/Constants";
import { registerTenantsViewIcons } from "utils/IconRegistration";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";
import { fetchData, fetchSignalTrendData } from "./ApiHandler";
import { getSignalTrendFilter } from "./MTRUtils";
import { SignalTrendFilterState, MtrState } from "./types";

const initialSignalTrendFilters: SignalTrendFilterState = {
    platform: SignalTrendFilter.DefaultPlatform,
    group: SignalTrendFilter.DefaultGroup,
    app: SignalTrendFilter.DefaultApp,
    signal: SignalTrendFilter.DefaultSignal,
    cohort: SignalTrendFilter.DefaultCohort,
    color: SignalTrendFilter.DefaultColor,
};

const initialData: MtrState = {
    unhealthyTenantTrend: { headers: [], rows: [] },
    unhealthyMoverShaker: { headers: [], rows: [] },
    topTrendingSignals: { headers: [], rows: [] },
    webHealthChart: { headers: [], rows: [] },
    win32HealthChart: { headers: [], rows: [] },
    macHealthChart: { headers: [], rows: [] },
    signalTrends: { headers: [], rows: [] },
    lastUpdated: "",
    loading: true,
};

const MTR = () => {
    registerTenantsViewIcons();

    const [signalTrendFilters, setSignalTrendFilters] =
        useState<SignalTrendFilterState>(initialSignalTrendFilters);

    const [data, setData] = useState<MtrState>(initialData);
    const [error, setError] = useState<string>("");

    const onSignalFilterChange = (filterKey: string, filterValue: string) => {
        const update = getSignalTrendFilter(filterKey);
        update[filterKey] = filterValue;
        setSignalTrendFilters({ ...signalTrendFilters, ...update });
    };

    useEffect(() => {
        document.title = "MTR";
        fetchData(setData, setError);
        AppInsights.getInstance().TrackPage(document.title);
    }, []);

    useSendLaunchEvent("Monthly Tenant Review Dashboard");

    useEffect(() => {
        setData((d) => {
            return {
                ...d,
                signalTrends: { headers: [], rows: [] },
                loading: true,
            };
        });
        setError("");
        fetchSignalTrendData(setData, setError, signalTrendFilters);
    }, [signalTrendFilters]);

    const renderHomePageComponent = () => {
        return (
            <Stack data-testid="homePageComponent">
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    wrap
                    styles={commonPageBlock}
                    data-testid="first-panel"
                >
                    <Stack.Item styles={splitCommonPageBlockIntoThree}>
                        <HealthyTenantTrend
                            payload={data.unhealthyTenantTrend}
                            loading={data.loading}
                        />
                    </Stack.Item>
                    <Stack.Item styles={splitCommonPageBlockIntoThree}>
                        <UnhealthyTenantMoverShaker
                            payload={data.unhealthyMoverShaker.rows[0] as number[]}
                            loading={data.loading}
                        />
                    </Stack.Item>
                    <Stack.Item styles={splitCommonPageBlockIntoThree}>
                        <TopTrendingSignals
                            payload={data.topTrendingSignals}
                            loading={data.loading}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontalAlign="center">
                    <p className="commonPageBlockTitle healthTrendPlatform">
                        Office Health S500 Trend
                    </p>
                </Stack>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    wrap
                    className="commonPageBlock"
                    data-testid="second-panel"
                >
                    {Object.keys(Workload)
                        .filter(
                            (x) =>
                                Workload[x] === Workload.MAC ||
                                Workload[x] === Workload.WEB ||
                                Workload[x] === Workload.WIN32,
                        )
                        .map((workload) => (
                            <HealthDistributionTrend
                                payload={
                                    data[
                                        `${Workload[
                                            workload
                                        ].toLowerCase()}HealthChart`
                                    ]
                                }
                                loading={data.loading}
                                platform={Workload[workload]}
                                // key={workload}
                            />
                        ))}
                </Stack>
            </Stack>
        );
    };

    const renderSignalTrends = () => {
        return (
            <Stack data-testid="signalTrendsComponent">
                <Stack.Item>
                    <SignalTrendFilters
                        filters={signalTrendFilters}
                        onFilterChange={onSignalFilterChange}
                    />
                </Stack.Item>
                <Stack.Item>
                    <SignalTrend
                        payload={data.signalTrends}
                        filters={signalTrendFilters}
                        loading={data.loading}
                    />
                </Stack.Item>
                {signalTrendFilters.platform === Workload.WEB && (
                    <Stack.Item>
                        <strong>Note: </strong>ASHA data is only available from July
                        2023
                    </Stack.Item>
                )}
            </Stack>
        );
    };

    return (
        <div className="mtr">
            {error && <ErrorFallback message={error} />}
            <Stack styles={commonPageStyle}>
                <Stack styles={headerBlock}>
                    <Header />
                </Stack>

                <Stack horizontalAlign="end" verticalAlign="end">
                    <LastUpdated lastUpdated={data.lastUpdated} />
                </Stack>

                <Stack>
                    <Stack.Item>
                        <Pivot>
                            <PivotItem
                                headerText={MtrPivots.MTR.headerText}
                                itemKey={MtrPivots.MTR.itemKey}
                            >
                                {renderHomePageComponent()}
                            </PivotItem>
                            <PivotItem
                                headerText={MtrPivots.SignalTrends.headerText}
                                itemKey={MtrPivots.SignalTrends.itemKey}
                            >
                                {renderSignalTrends()}
                            </PivotItem>
                        </Pivot>
                    </Stack.Item>
                </Stack>
            </Stack>
        </div>
    );
};

export default MTR;
