export const generateOCVLink = (
    appName: string,
    copilotSurveyType: string,
    filters,
) => {
    let platform =
        filters["platform"] === "Win32" ? "Windows Desktop" : filters["platform"];
    let platformParam = `,"Platform.raw":["${platform}"]`;
    if (platform === "All") platformParam = "";

    if (appName === "WXPN") appName = `Word","Excel","PowerPoint","OneNote`;
    else if (appName === "Teams") appName = `Teams","Microsoft Teams`;
    else if (appName === "Stream") appName = "Microsoft Stream";
    let appParam = `,"Product.raw":["${appName}"]`;
    if (appName === "All") appParam = "";

    let office365Category =
        filters["office365Category"] === "Consumer Copilot Pro"
            ? "Consumer"
            : filters["office365Category"];
    let customerTypeParam = `,"CustomerType.raw":["${office365Category}"]`;
    if (office365Category === "All") customerTypeParam = "";

    // OCV takes the date in the URL as UTC, but converts it to local timezone
    // so add 1 day and timezone offset as adjustment
    const lookback = filters["cadence"] === "Monthly" ? 27 : 6;
    let tmpDate = new Date(filters["date"]);
    tmpDate.setDate(tmpDate.getDate() + 1);
    tmpDate.setHours(tmpDate.getHours() + tmpDate.getTimezoneOffset() / 60);
    const endDate = tmpDate?.toISOString().split("Z")[0];
    tmpDate.setDate(tmpDate.getDate() - lookback);
    const startDate = tmpDate?.toISOString().split("Z")[0];

    const template = `https://ocv.microsoft.com/#/discover/?allAreas \
        &spt=Filters \
        &q=SurveyName:"*${copilotSurveyType}*"&searchtype=OcvItems \
        &vs={"view":"list","co":[{"n":"DetailsPageLink","w":30},{"n":"SurveyName"},{"n":"SurveyRating"},{"n":"Comment","w":350},{"n":"CreatedDate"},{"n":"Product"}]} \
        &filters={"AudienceGroup.raw":["${filters["audienceGroup"]}"]${appParam}${platformParam}${customerTypeParam}} \
        &searchFrom=${startDate}&searchTo=${endDate}`;
    return encodeURI(template.replace(/\s{2,}/g, ""));
};
