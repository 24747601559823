import React, { CSSProperties } from "react";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsRowStyles,
    IRenderFunction,
    SelectionMode,
} from "@fluentui/react";
import {
    ScrollablePane,
    ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { getAIMBRFeatureScorecardColumns } from "components/AIScorecardComponents/AIScorecardTableHelper";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import { AIScorecardTablePropsType } from "components/AIScorecardComponents/types";
import { NO_DATA_MESSAGES } from "utils/Messages";

const getHeaderGroups = (columnConfig) => {
    const groups = {};
    columnConfig.forEach((column) => {
        groups[column.name] = column.headerGroups;
    });
    return groups;
};

const getColumnProperties = (columnConfig) => {
    const props = {};
    columnConfig.forEach((column) => {
        props[column.name] = column;
    });
    return props;
};

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    const column = props.column;

    // Override style for Goal row
    if (props.itemIndex === 0) {
        return (
            <div style={column.styles as CSSProperties} key={`${column.key}-style`}>
                <div className="goalCell">{defaultRender({ ...props })}</div>
            </div>
        );
    } else if (column.styles) {
        return (
            <div style={column.styles as CSSProperties} key={`${column.key}-style`}>
                {defaultRender({ ...props })}
            </div>
        );
    }

    return <div key={`${column.key}-style`}>{defaultRender({ ...props })}</div>;
};

const onRenderRow = (props?: IDetailsColumnFieldProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        // Every other row renders with a different background color.
        if (props.itemIndex % 2 === 1) {
            customStyles.root = {
                backgroundColor: "#fcf5ed",
            };
        }
        // First feature in a Capability group renders with a top-border on the row.
        // This provides a visual grouping of features that belong to the same capability.
        if (props.item["FirstFeatureForCapability"] === true) {
            customStyles.cell = {
                borderTop: "ridge",
                borderTopWidth: 1,
            };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

const getEmptyValue = (colKey: string, firstFeatureForCapability: boolean) => {
    var emptyValue = "-";
    if (colKey.startsWith("Capability") && !firstFeatureForCapability) {
        emptyValue = "";
    }
    return emptyValue;
};

var getMetricCellStyle = (isGoalMet) => {
    if (isGoalMet === false) {
        return "scorecardCell goalNotMetScorecardCell";
    } else if (isGoalMet === true) {
        return "scorecardCell goalMetScorecardCell";
    } else {
        return "scorecardCell";
    }
};

const CopilotAppMBRTable = ({
    items,
    loading,
    filters,
    columnConfig,
    className,
}: AIScorecardTablePropsType) => {
    const columnProps = getColumnProperties(columnConfig);
    const renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;
        const colKeyIsGoalMet = colKey + "IsGoalMet";
        const colKeyDeltaValue = colKey + "DeltaValue";
        const colKeyDeltaNegate = colKey + "DeltaNegate";
        const postfix = columnProps[colKey]?.postFix ?? "";
        const precision = columnProps[colKey]?.precision;
        const cellStyle: React.CSSProperties = {};
        const emptyValue =
            index !== 0
                ? getEmptyValue(colKey, item["FirstFeatureForCapability"])
                : "";

        if (
            colKey === "Feature" ||
            colKey === "RowLabel" ||
            colKey === "Capability"
        ) {
            return (
                <div>
                    <DetailsListHelper.TextValue
                        value={fieldContent}
                        class="featureCell"
                    />
                </div>
            );
        } else if (colKey !== "Dummy") {
            return (
                <div>
                    <div
                        className={getMetricCellStyle(item[colKeyIsGoalMet])}
                        style={cellStyle}
                    >
                        <DetailsListHelper.NumericalValue
                            value={fieldContent ?? emptyValue}
                            postfix={!isNaN(fieldContent) ? postfix : ""}
                            decimalPrecision={precision}
                        />
                    </div>

                    {fieldContent != null &&
                        item[colKeyDeltaValue] != null &&
                        ((colKey.startsWith("Capability") &&
                            item["FirstFeatureForCapability"]) ||
                            !colKey.startsWith("Capability")) && (
                            <div className="deltaValueCell" style={cellStyle}>
                                <DetailsListHelper.DeltaValue
                                    value={item[colKeyDeltaValue]}
                                    deltaPostfix={"%"}
                                    negate={item[colKeyDeltaNegate]}
                                    decimalPrecision={2}
                                />
                            </div>
                        )}
                </div>
            );
        }
    };

    return (
        <div data-is-scrollable className={className} id="scorecardTable">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <ShimmeredDetailsListWrapper
                    compact={true}
                    items={items}
                    message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                    columns={getAIMBRFeatureScorecardColumns(
                        columnConfig,
                        filters.cadence,
                    )}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsHeader={(headerProps, defaultrender) => {
                        return DetailsListHelper.renderGroupedDetailsHeader(
                            headerProps,
                            getHeaderGroups(columnConfig),
                            false,
                            defaultrender,
                        );
                    }}
                    enableShimmer={loading}
                    onRenderItemColumn={renderItemColumn}
                    onRenderField={onRenderField}
                    onRenderRow={onRenderRow}
                    styles={DetailsListHelper.tenantTableStyle}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    getAdditionalTelemetryFields={() => {
                        return {
                            ActiveAppTab: getAIScorecardAppTabSelection(),
                            ActiveScorecardTab: getAIScorecardTabSelection(),
                        };
                    }}
                />
            </ScrollablePane>
        </div>
    );
};

export default CopilotAppMBRTable;
