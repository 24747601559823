import React from "react";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { ScoreThreshold } from "components/OverviewComponents/ScoreThreshold";
import { STATUS } from "utils/Constants";
import "./style.css";

const StatusLegend = ({ platformConfig }) => {
    return (
        <table className="statusLegendTable">
            <tbody>
                <tr>
                    {!platformConfig?.featureConfig?.ExecOverview
                        ?.isScoreBannerDisabled && (
                        <td rowSpan={2} className="readMoreAboutScoreTableBox">
                            <ScoreThreshold platform={platformConfig.platform} />
                        </td>
                    )}
                    <td>
                        <span>
                            {Object.keys(STATUS)
                                .filter((status) => status !== "Regression")
                                .map((status) => {
                                    const content =
                                        (status !== ""
                                            ? STATUS[status]["name"]
                                            : "") +
                                        (STATUS[status]["definition"] &&
                                        status !== ""
                                            ? " - "
                                            : "") +
                                        (STATUS[status]["definition"]
                                            ? STATUS[status]["definition"]
                                            : "");
                                    return (
                                        <span key={status}>
                                            <StatusIndicator
                                                color={STATUS[status]["color"]}
                                                id="statusLegend"
                                            />
                                            <span className="statusLegendColorLegends">
                                                {content}
                                            </span>
                                        </span>
                                    );
                                })}
                            <br />
                            <sub className="statusLegendDisclaimer">
                                *Colored bars represent metric health and colored
                                dots represent tenant health.
                            </sub>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default StatusLegend;
