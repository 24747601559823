import { IColumn } from "@fluentui/react";

export enum ColumnType {
    REGULAR,
    EXEMPT,
    ORG_NAME,
    PAST_STATUS,
    VALUE,
    MOM_CHANGE,
    APPCOLOR,
    TEXT,
    PLATFORM,
}

export interface OverviewTableColumn extends IColumn {
    columnType: ColumnType;
}
