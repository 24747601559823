import React, { useEffect, useState } from "react";
import { AppButtons } from "components/CustomComponents/AppButtons";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { geoPath, geoMercator } from "d3-geo";
import { scaleSqrt } from "d3-scale";
import { LEGEND_CLICK, SESSION_DISTRIBUTION_MESSAGES } from "utils/Messages";
import continentjson from "utils/world-continents.json"; // Source - https://exploratory.io/map (on home page you'll see download link for world map)

interface IDistributionTypes {
    SPOFarm: boolean;
    Location: boolean;
    WacDatacenter: boolean;
}

export const GeographicDistribution = (props) => {
    const {
        geographicDistribution,
        geoDataMapping,
        application,
        distributionTypeInfo,
        distributionTypes,
        workload,
    } = props;
    let locationEmptyDataPercentage = 0;
    const [selectedApp, setSelectedApp] = useState(application);
    useEffect(() => {
        setSelectedApp(application);
    }, [application]);

    const [distributionTypeSelected, changeDistributionTypeSelected] =
        useState<IDistributionTypes>(distributionTypes);

    const distributionTypesToShow = Object.keys(distributionTypeSelected).filter(
        (distributionType) => distributionTypeSelected[distributionType] === true,
    );

    const _onChange = (distributionType) => {
        const newDistributionTypeSelection: IDistributionTypes = {
            ...distributionTypeSelected,
        };
        newDistributionTypeSelection[distributionType] =
            !distributionTypeSelected[distributionType];

        const anyDistributionTypesToShow = Object.keys(
            newDistributionTypeSelection,
        ).some((element) => newDistributionTypeSelection[element]);

        if (anyDistributionTypesToShow) {
            changeDistributionTypeSelected(newDistributionTypeSelection);
        }
    };

    let isPayloadEmpty = false;
    distributionTypesToShow.forEach((distributionType) => {
        // Payload Empty is used to display a text instead of a map when app data is not available.
        if (
            geographicDistribution &&
            geographicDistribution[distributionType] &&
            !geographicDistribution[distributionType][selectedApp]
        )
            isPayloadEmpty = true;
    });
    if (isPayloadEmpty) {
        return (
            <div>
                <EmptyTableContainer />
                <AppButtons
                    selectedApp={selectedApp}
                    setSelectedApp={setSelectedApp}
                    workload={workload}
                />
            </div>
        );
    }
    const leftComponentWidth = 330;
    const width = window.innerWidth - leftComponentWidth - 100; // (40 padding + margin on left, 40 padding + margin on right) for whole page, 20 left padding for this component
    const height = width / 2;
    const wordLength = 110;
    const max = {};
    const sortedData = [];
    const legendNoteMove = `translate(${width - leftComponentWidth},10)`;
    const circleNoteMove = `translate(${width - leftComponentWidth},25)`;
    const emptyLocationNoteMove = `translate(${width - leftComponentWidth},40)`;
    const move = `translate( ${width / 2 - 1.5 * wordLength}, 10)`;
    const legendRadius = 7;

    const projection = geoMercator().fitSize([width, height], continentjson);
    const path = geoPath().projection(projection);

    const getCentroid = (d) => {
        return `translate(${path.centroid(d)})`;
    };

    distributionTypesToShow.forEach((distributionType) => {
        max[distributionType] = {};
        // Return when the distribution data is not populated yet.
        if (!geographicDistribution || !geographicDistribution[distributionType]) {
            return;
        }
        const values: number[] = geographicDistribution[distributionType][
            selectedApp
        ].map((data) => data.sessionCount);
        max[distributionType][selectedApp] = Math.max(...values);
        geographicDistribution[distributionType][selectedApp].forEach((data) => {
            if (distributionType === "Location" && data.countryCode === "NA")
                locationEmptyDataPercentage = data.percent;
            else
                sortedData.push({
                    distributionType,
                    countryCode: data.countryCode,
                    title: data.title,
                    sessionCount: data.sessionCount,
                    centroid: getCentroid(geoDataMapping.get(data.countryCode)),
                    percent: data.percent,
                });
        });
    });

    // Sort by desc for distributionTypes selected (location, DC or both) and merge them into 1 so that bigger bubbles are rendered first
    sortedData.sort((a, b) => b.sessionCount - a.sessionCount);

    const getRadius = (distributionType, sessionCount) => {
        const radius = scaleSqrt()
            .domain([0, max[distributionType][selectedApp]])
            .range([3, distributionTypeInfo[distributionType].maxRadius]);

        return radius(sessionCount);
    };

    const getToolTipText = (entry) => {
        let toolTipText = "";
        if (entry.sessionCount == null) toolTipText = `: ${entry.distributionType}`;
        else if (entry.sessionCount !== "")
            toolTipText = `: ${entry.distributionType}\n ${entry.percent} % (${entry.sessionCount})`;
        else toolTipText = `: ${entry.distributionType} \n None`;

        return toolTipText;
    };

    return (
        <div>
            <svg width={width} height={height}>
                {continentjson.features.map((d) => (
                    <path
                        key={d.properties.continent}
                        d={path(d)}
                        fill="white"
                        stroke="#0e1724"
                        strokeWidth="0.8"
                        strokeOpacity="0.2"
                    />
                ))}
                <g className="bubble">
                    {sortedData.map((entry, index) => {
                        const radius = getRadius(
                            entry.distributionType,
                            entry.sessionCount,
                        );
                        return (
                            <g
                                transform={entry.centroid}
                                fill={
                                    distributionTypeInfo[entry.distributionType]
                                        .color
                                }
                                // TODO : using index for the time being to avoid duplicate keys, need to see if we can make use of other data
                                // Tried using Percent and sessionCount, duplicate keys still exists, though less in number.
                                key={`${entry.distributionType}_${entry.countryCode}_${index}`}
                            >
                                {distributionTypeInfo[entry.distributionType]
                                    .icon ? (
                                    distributionTypeInfo[
                                        entry.distributionType
                                    ].icon()
                                ) : (
                                    <circle r={radius} />
                                )}
                                <title>
                                    {entry.title}
                                    {getToolTipText(entry)}
                                </title>
                            </g>
                        );
                    })}
                </g>
            </svg>
            <svg width={width} height={30}>
                <g transform={move}>
                    {Object.keys(distributionTypeInfo).map(
                        (distributionType, index) => {
                            return (
                                <g
                                    transform={`translate(${wordLength * index}, 0)`}
                                    onClick={() => _onChange(distributionType)}
                                    cursor="pointer"
                                    key={distributionType}
                                >
                                    <circle
                                        r={legendRadius}
                                        fill={
                                            distributionTypeInfo[distributionType]
                                                .color
                                        }
                                        fillOpacity={
                                            distributionTypeSelected[
                                                distributionType
                                            ]
                                                ? 1
                                                : 0.5
                                        }
                                        stroke="#fff"
                                        strokeWidth={0.5}
                                    />
                                    <text
                                        transform={`translate(${
                                            legendRadius * 2
                                        }, 5)`}
                                    >
                                        {distributionType}
                                    </text>
                                </g>
                            );
                        },
                    )}
                </g>
            </svg>
            <svg width={width} height={55}>
                <text transform={legendNoteMove} fontSize={11}>
                    {LEGEND_CLICK}
                </text>
                <text transform={circleNoteMove} fontSize={11}>
                    {SESSION_DISTRIBUTION_MESSAGES.CIRCLEMESSAGE}
                </text>
                {distributionTypeSelected.Location &&
                    locationEmptyDataPercentage !== 0 && (
                        <text transform={emptyLocationNoteMove} fontSize={11}>
                            {`*${locationEmptyDataPercentage}% sessions have no location`}
                        </text>
                    )}
            </svg>
            <AppButtons
                selectedApp={selectedApp}
                setSelectedApp={setSelectedApp}
                workload={workload}
            />
        </div>
    );
};
