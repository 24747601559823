import React from "react";
import { FontIcon, Dropdown, Stack, IDropdownOption } from "@fluentui/react";
import { platformsConfig } from "config/PlatformConfig";
import { filterStackTokens, filterStyles } from "pages/common";
import { uihostOptions, hostOptions, dropdownStyles } from "utils/WebConstants";

interface AppMetricFiltersPropsType {
    metricFilters: {};
    platform: string;
    onFilterChange(
        item: string,
    ): (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => void;
}

export const AppMetricFilters = (props: AppMetricFiltersPropsType) => {
    const { metricFilters, platform, onFilterChange } = props;
    return (
        <Stack horizontal tokens={filterStackTokens} styles={filterStyles}>
            {platformsConfig[platform].showAppMetricsFilters && (
                <>
                    <FontIcon iconName="Filter" className="filterIcon" />
                    <Dropdown
                        placeholder="Select an option"
                        label="Host"
                        options={hostOptions}
                        selectedKey={metricFilters["Host"]}
                        styles={dropdownStyles}
                        onChange={onFilterChange("Host")}
                        className="filterDropdown"
                    />
                    <Dropdown
                        placeholder="Select an option"
                        label="UiHost"
                        options={uihostOptions}
                        selectedKey={metricFilters["UiHost"]}
                        styles={dropdownStyles}
                        onChange={onFilterChange("UiHost")}
                        className="filterDropdown"
                    />
                </>
            )}
        </Stack>
    );
};
