import React, { useState, useEffect } from "react";
import { Dropdown, IDropdownOption, Label, Stack } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import CustomLoader from "components/CustomComponents/CustomLoader/CustomLoader";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatFeatures,
} from "components/AIScorecardComponents/ApiHandler";
import { RetentionTable } from "components/TenantDrilldownComponents/Retention";
import { horizontalStackTokens } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { dropdownStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";

export const CopilotRetention = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState(false);
    const [feature, setFeature] = useState("All");
    const [capability, setCapability] = useState("All");

    const [cfMap, setCFMap] = useState({});

    let capabilityOptions: IDropdownOption[] = [{ key: "All", text: "All" }];
    const [capabilities, setCapabilities] = useState(capabilityOptions);

    let featureOptions: IDropdownOption[] = [{ key: "All", text: "All" }];
    const [features, setFeatures] = useState(featureOptions);

    const [retention, setRetention] = useState([]);

    useEffect(() => {
        let res: IDropdownOption[] = [];
        cfMap[capability]?.forEach((f) => res.push({ key: f, text: f }));
        if (Object.keys(res).length > 0) setFeatures(res);
        setFeature("All");
    }, [cfMap, capability]);

    useEffect(() => {
        const getRetention = async () => {
            setLoading(true);
            const queryParams = { ...filters, feature, capability };
            setRetention([]);
            setError(null);
            try {
                const retentionJson = await fetchCopilotData(
                    queryParams,
                    "copilotRetention",
                );

                setRetention(retentionJson?.Tables[0]?.Rows);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "AIScorecardRetentionProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format retention data",
                    },
                    error,
                );

                setError(error.message);
                setRetention([]);
                return;
            }
            setLoading(false);
        };
        getRetention();
    }, [capability, feature, filters]);

    useEffect(() => {
        const getFeatures = async () => {
            setError(null);
            try {
                const featuresJson = await fetchCopilotData(
                    filters,
                    "copilotFeatures",
                );

                const capabilities = formatFeatures(featuresJson);
                if (capabilities && Object.keys(capabilities).length > 0) {
                    setCFMap(capabilities);

                    let res: IDropdownOption[] = [];
                    Object.keys(capabilities).forEach((c) =>
                        res.push({ key: c, text: c }),
                    );
                    setCapabilities(res);
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "AIScorecardFeatureProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format AI Scorecard feature options",
                    },
                    error,
                );

                setError(error.message);
                return;
            }
        };

        getFeatures();

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.platform, filters.application]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <>
            <Stack horizontal className="filterPanel">
                <div>
                    <Label className="filterLabel">Capability:</Label>
                    <Dropdown
                        placeholder="Select an option"
                        options={capabilities}
                        selectedKey={capability}
                        styles={dropdownStyles}
                        onChange={(event, option) => {
                            sendAIScorecardUserActionTelemetryEvent(
                                {
                                    userActionName:
                                        "CopilotScorecardRetentionVerbFilterChange",
                                    commandSurface:
                                        UserActionCommandSurface.FilterBar,
                                },
                                { VerbChosen: option["key"] as string },
                            );
                            setCapability(option["key"] as string);
                        }}
                        className="filterDropdown"
                    />
                </div>
                <div>
                    <Label className="filterLabel">Feature:</Label>
                    <Dropdown
                        placeholder="Select an option"
                        options={features}
                        selectedKey={feature}
                        styles={dropdownStyles}
                        onChange={(event, option) => {
                            sendAIScorecardUserActionTelemetryEvent(
                                {
                                    userActionName:
                                        "CopilotScorecardRetentionFeatureFilterChange",
                                    commandSurface:
                                        UserActionCommandSurface.FilterBar,
                                },
                                { FeatureChosen: option["key"] as string },
                            );
                            setFeature(option["key"] as string);
                        }}
                        className="filterDropdown"
                    />
                </div>
            </Stack>
            {loading ? (
                <CustomLoader />
            ) : retention?.length === 0 ? (
                <EmptyTableContainer />
            ) : (
                <Stack horizontal tokens={horizontalStackTokens}>
                    <Stack.Item grow={1}>
                        <RetentionTable
                            payload={retention}
                            frequency={filters.cadence}
                        />
                    </Stack.Item>
                </Stack>
            )}
        </>
    );
};
