import React from "react";
import { TooltipProps } from "recharts";
import { IDropdownOption } from "@fluentui/react";
import { NameType, ValueType } from "recharts/src/component/DefaultTooltipContent";
import { Action } from "redux";
import { TenantTitleMetadata } from "components/CustomComponents/types";
import { LabelData, LegendEntry } from "pages/commonTypes";

export const Levels = ["Tpid", "TenantId"] as const;
export type Level = (typeof Levels)[number];
export type App = "Word" | "Excel" | "PowerPoint" | "Outlook" | "OneNote";
export type Scenario =
    | "Boot-Launch"
    | "Boot-File"
    | "FileOpen-Local"
    | "FileOpen-SharePointOnline";
export const DataPointsChoices = ["Monthly", "7Day"] as const;
export type DataPointsChoice = (typeof DataPointsChoices)[number];
export const Dimensions = [
    "Country",
    "OSVersionName",
    "DominantFork",
    "IsVirtualMachine",
    "IsManagedDevice",
    "Fork",
    "",
] as const;
export type Dimension = (typeof Dimensions)[number];
export const ValuesToShowChoices = [
    "TypicalDuration",
    "P25Duration",
    "P50Duration",
    "P75Duration",
    "P90Duration",
    "P95Duration",
    "PercentBelowThreshold",
] as const;
export type ValuesToShowChoice = (typeof ValuesToShowChoices)[number];

export interface PerformanceTitleProps {
    pageName: string;
    id: string;
    level: Level;
    setError: (error: string) => void;
}

export interface PerformanceTitleMetadata {
    level: Level;
    orgName: string;
    tpid: string;
    OmsTenantId: string;
    cohorts: {
        isS2500: boolean;
        isS500: boolean;
        isEpa: boolean;
        isGoogleMove: boolean;
        isGov: boolean;
    };
}
export interface BaseProps {
    startDate: string;
    endDate: string;
    tenantId: string;
}
export interface PerformanceProps extends BaseProps {
    valuesToShow: ValuesToShowChoice;
    partitionBy: Dimension;
    isMonthlyView: boolean;
    level: Level;
    dispatcher: React.Dispatch<LegendAction>;
    legend: LegendEntry[];
}

export interface LegendAction {
    type: "addEntries" | "clickedEntry" | "clearAndSetToAll" | "showDotsOnOneEntry";
    payload: LabelData[] | string;
}

export interface PerfChartQuery extends BaseProps {
    dimensionName: Dimension;
    overallTableName: string;
    rankingTableName: string;
    tenantTableName: string;
    valuesToShow: ValuesToShowChoice;
}

export interface PerfInsightsQuery {
    tableName: string;
    tenantId: string;
    date: string;
    scenario: BootScenario;
    metric: ValuesToShowChoice;
    sortOrder: KustoSort;
}

export interface PerfInsightsChartQuery {
    tenantId: string;
    startDate: string;
    endDate: string;
    appName: App;
    dimensionName: Dimension;
    scenario: BootScenario;
    valuesToShow: ValuesToShowChoice;
    tenantTableName: string;
}

export type KustoSort = "asc" | "desc";

export interface PerfRankingQuery {
    date: string;
    tenantId: string;
    rankingTableName: string;
    valuesToShow: ValuesToShowChoice;
}

export interface PerformanceTooltipProps extends TooltipProps<ValueType, NameType> {
    hoveredSeries: string;
    roundTooltipValues: boolean;
}

export interface TenantTopAddInPerformanceData {
    Name: string;
    ProgId: string;
    DevicePercentage: number;
    TypicalDuration: number;
}

export interface TenantTopAddInPerformanceDataWithHref
    extends TenantTopAddInPerformanceData {
    Href: string;
}

export interface KustoTenantData {
    Parent: string;
    OmsTenantId: string;
    OrgName: string;
    Current_Status: string;
    pastStatus: string;
    IsS500: boolean;
    IsEpa: boolean;
    IsS2500: boolean;
    IsGoogleMove: boolean;
    IsGov: boolean;
}
export interface ChartData {
    Date: string;
}

export interface PerfChartData extends ChartData {
    DominantFork: string;
    Series: string;
    Value: number;
    DeviceCount: number;
    EventCount: number;
}

export type AppScenarioMatrix<T> = {
    [appName in App]: { [scenario in Scenario]?: T };
};

export type AppObject<T> = {
    [appName in App]: T;
};

export type AllChartsData = AppScenarioMatrix<ChartData[]>;

export interface TenantMetadataParams {
    id: string;
    level: Level;
}

export interface PerfQueryParams extends TenantMetadataParams {
    date: string;
    timespan: number;
    dataPoints: DataPointsChoice;
    partitionBy: Dimension;
    valuesToShow: ValuesToShowChoice;
}

export interface PerfInsightsQueryParams extends TenantMetadataParams {
    date: string;
    valuesToShow: ValuesToShowChoice;
    scenario: BootScenario;
}

export interface PerfRankingData {
    isLargeEnterprise: boolean;
    percentile: number;
    significantDifference: boolean;
}

export type AllPerfRankingData = AppScenarioMatrix<PerfRankingData>;

export interface AddinPerfProps {
    id: string;
    date: string;
    timespan: number;
}

export interface AddinPerfByVersionProps extends AddinPerfProps {
    progId: string;
}

export interface TenantAddInOptions {
    progId: string;
    clsid: string;
    text: string;
}

export interface AllAddInCostHistoryResponseRow {
    Date: string;
    TypicalLoadDurationPerSession: number;
    DeviceCount: number;
    SessionCount: number;
    DeviceWithAddInCount: number;
}

export type AddInCountAndPerfImpactData = {
    [date: string]: {
        Counts: { [addin: string]: number | string };
        Durations: { [addin: string]: number | string };
    };
};
export type TenantTypicalAddInCostQueryParams = {
    id: string;
};

export interface TenantTypicalAddInCostResponse {
    AppName: string;
    TypicalLoadDurationPerSession: number;
}

export type AllTypicalAddInCostData = AppObject<number>;

export type Win32PerfInputUpdates =
    | Partial<PerfQueryParams>
    | Partial<AddinPerfProps>
    | Partial<AddinPerfByVersionProps>;

// Web Performance types here.
export const FETCH_PERFORMANCE_BEGIN = "FETCH_PERFORMANCE_BEGIN";
export const FETCH_PERFORMANCE_SUCCESS = "FETCH_PERFORMANCE_SUCCESS";
export const FETCH_PERFORMANCE_FAILURE = "FETCH_PERFORMANCE_FAILURE";

export type PerformanceActionType =
    | "FETCH_PERFORMANCE_BEGIN"
    | "FETCH_PERFORMANCE_SUCCESS"
    | "FETCH_PERFORMANCE_FAILURE";

export interface WebPerformanceState {
    isFetching: boolean;
    performanceTableData?: {};
    modalChartData?: {};
    euplCharts?: {};
    chartLabels?: {};
    dateOptions?: IDropdownOption[];
    metadata?: TenantTitleMetadata;
    filters?: {};
}

export interface WebPerfFilters {
    Host: string;
    UiHost: string;
    date: string;
}

export interface WebPerfBaseQueryParams {
    date: string;
    id: string;
    level: Level;
}

export interface WebPerfQueryParams extends WebPerfFilters {
    id: string;
    level: Level;
}

export type WebPerfFilterType = "date" | "Host" | "UiHost";

export type WebPerfAction = {
    type: PerformanceActionType;
    payload: WebPerformanceState;
};

export interface WebPerfFiltersAction extends Action<WebPerfFilterType> {
    payload: string;
}
export interface PerfTableData {
    P95Baseline: number;
    DimensionName: Dimension;
    DimensionValue: string;
    Scenario: BootScenario;
    P95: number;
    PercentBelowThreshold: number;
    EventCount: number;
    AppName: App;
    BootFileP95: number;
    BootLaunchP95: number;
}

export type BootScenario = "Boot-File" | "Boot-Launch" | "Boot-Overall" | "";

export type PerfAggregate = "P95Duration" | "PercentBelowThreshold";

export interface PerfInsightsRow {
    dimension: Dimension;
    scenario: BootScenario;
}
