import { isTest } from "utils/WebConstants";

export const webMetaData: string = `
// Web Metadata
let currentDate = GetCurrentDate(${isTest});
let app = "\${app}";//"WXP";
let dt = "\${date}";
let webScores = 
Score_WXP
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and UiHost == "All" and Application == "WXP"
    | project Date, OmsTenantId, Score = round(WXP_Score, 2), Status = WXP_Score_Color, Application
   | union (
    Score
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and UiHost == "All" and Application != "WXP"
    | project Date, OmsTenantId, Tpid, Score = round(CompositeScore,2), Status = CompositeScore_Color, Application)
;
StrategicTenants
 | where \${levelColumnName}=="\${id}" and Date == currentDate
 | extend OrgName=iif("\${levelColumnName}"=="Tpid",OrgName_Translated , TenantName_Translated), Parent=Tpid
 | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO, IsCopilot
 | take 1
 | extend OrgName=iff(OrgName =="MicrosoftStanleyGTest", "Microsoft", OrgName)
 | join kind=leftouter(
  webScores
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app 
    | project \${levelColumnName}, Score, Status
 ) on \${levelColumnName}
 | join kind=leftouter (
  webScores
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application == app 
    | project \${levelColumnName}, PreviousScore=Score
 ) on \${levelColumnName}
 | join kind=leftouter ( 
  PastStatus
      | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
     | where Application == app and UiHost == 'All' and Date == currentDate
 ) on \${levelColumnName}
 | project Parent, \${levelColumnName}, OrgName, 
  Score, Status, PastStatus = GetPastYearStatus(PastStatusSet, dt), MomChange=round(Score-PreviousScore,2),
  IsS500, IsEPA, IsS2500, IsGoogleMove, IsGov, IsEO, IsCopilot`;

export const webMetaData_V2: string = `
// Web Metadata
let currentDate = GetCurrentDate(${isTest});
let app = "\${app}";//"WXP";
let dt = "\${date}";
let webScores = 
Score_WXP_V2
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and UiHost == "All" and Application == "WXP"
    | project Date, OmsTenantId, Score = round(WXP_Score, 2), Status = WXP_Score_Color, Application
   | union (
    Score_V2
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and UiHost == "All" and Application != "WXP"
    | project Date, OmsTenantId, Tpid, Score = round(CompositeScore,2), Status = CompositeScore_Color, Application)
;
StrategicTenants
 | where \${levelColumnName}=="\${id}" and Date == currentDate
 | extend OrgName=iif("\${levelColumnName}"=="Tpid",OrgName_Translated , TenantName_Translated), Parent=Tpid
 | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO, IsCopilot
 | take 1
 | extend OrgName=iff(OrgName =="MicrosoftStanleyGTest", "Microsoft", OrgName)
 | join kind=leftouter(
  webScores
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app 
    | project \${levelColumnName}, Score, Status
 ) on \${levelColumnName}
 | join kind=leftouter (
  webScores
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application == app 
    | project \${levelColumnName}, PreviousScore=Score
 ) on \${levelColumnName}
 | join kind=leftouter ( 
  PastStatus
      | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
     | where Application == app and UiHost == 'All' and Date == currentDate
 ) on \${levelColumnName}
 | project Parent, \${levelColumnName}, OrgName, 
  Score, Status, PastStatus = GetPastYearStatus(PastStatusSet, dt), MomChange=round(Score-PreviousScore,2),
  IsS500, IsEPA, IsS2500, IsGoogleMove, IsGov, IsEO, IsCopilot`;
