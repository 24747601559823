import React, { useEffect, useState } from "react";
import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Bar,
    ComposedChart,
    Cell,
    Tooltip,
} from "recharts";
import { Slider, Stack, Toggle } from "@fluentui/react";
import { getTopNTenantsByField } from "pages/CohortAnalysis/CohortAnalysisUtils";
import CustomToolTip from "components/CustomComponents/CustomToolTip";
import { TenantList } from "components/ErrorLookupComponents";
import {
    cohortAnalysisChartsWidth,
    cohortAnalysisChartsHeight,
    stepForSlider,
    maxNumberOfOptionsToShow,
    startingNumberOfOptionsToShow,
} from "pages/CohortAnalysis/CohortAnalysisConstants";
import { chartMargin, horizontalStackTokens } from "pages/common";
import { tenantTableConstants } from "utils/Constants";
import { InsightsRoutes } from "utils/Links";

const customToolTipContent = (props) => {
    const { payload } = props;
    const data = payload[0].payload;
    return (
        <span>
            <span className="customTooltipHeader">
                <span className="customTooltipTitle">Tenant Name: </span>
                {data["Name"]}
            </span>
            <span className="customTooltipSelectionHelper">
                {" "}
                [click to navigate to reliability drilldown]{" "}
            </span>
            <br />
            <br />
            <span className="customTooltipMessage">
                This Tenant has <b>{data["% Users With Error"]}%</b> users affected
                by the error in question.
                <br />
                <br />
                The metric to measure the normalized impact of the error on this
                tenant (Impact Score) is: <b>{data["ImpactScore"]}</b> <br />
                (The higher the impact score, the more this tenant is contributing to
                the overall 'badness' of the cohort)
                <br />
            </span>
        </span>
    );
};

const TopTenantsComposedChart = ({
    chartData,
    XAxisDataKey,
    XAxisName,
    YAxisDataKey,
    YAxisName,
}) => {
    return (
        <ResponsiveContainer
            width={cohortAnalysisChartsWidth}
            height={cohortAnalysisChartsHeight}
        >
            <ComposedChart data={chartData} margin={chartMargin}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                    dataKey={XAxisDataKey}
                    scale="band"
                    label={{ value: XAxisName, position: "bottom" }}
                />
                <YAxis
                    dataKey={YAxisDataKey}
                    type="number"
                    label={{
                        value: YAxisName,
                        angle: -90,
                        position: "left",
                    }}
                />
                <Bar
                    dataKey={YAxisDataKey}
                    name={YAxisName}
                    barSize={20}
                    fill="#ffae91"
                    style={{ cursor: "pointer" }}
                >
                    {chartData.map((entry, index) => {
                        return (
                            <Cell
                                key={`cell-${index}`}
                                onClick={() => {
                                    window.open(
                                        `${InsightsRoutes.Reliability.path}?id=${entry.id}&level=TenantId&date=${entry.Date}`,
                                    );
                                }}
                            />
                        );
                    })}
                </Bar>

                <Tooltip
                    content={
                        <CustomToolTip
                            customToolTipContentFunction={customToolTipContent}
                        />
                    }
                />
                <Legend verticalAlign="top" height={50} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

const TopTenantsForError = ({
    tenantsImpactedByErrorChartData,
    tenantsImpactedByError,
    filters,
    getChartData,
    s2500Data,
}) => {
    const [showTopTenantsByErrorPercentage, changeTenantGroupingClassification] =
        useState<boolean>(false);
    const [tenantsNumberToShow, setTenantNumberToShow] = useState<number>(
        startingNumberOfOptionsToShow,
    );

    const [tenantsToShowInTable, setTenantsToShowInTable] = useState(
        getTopNTenantsByField(
            tenantsImpactedByError,
            "ImpactScore" /* orderBy */,
            tenantsNumberToShow /* Top N */,
        ),
    );

    useEffect(() => {
        setTenantsToShowInTable(
            getTopNTenantsByField(
                tenantsImpactedByError,
                showTopTenantsByErrorPercentage
                    ? "% Users With Error"
                    : "ImpactScore",
                tenantsNumberToShow,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        showTopTenantsByErrorPercentage,
        tenantsImpactedByError,
        tenantsNumberToShow,
    ]);

    const id = filters["level"];
    const idWidth =
        filters["level"] === "Tpid"
            ? tenantTableConstants.COLUMN_WIDTH
            : tenantTableConstants.FIXED_ROW_HEADER_COLUMN_WIDTH;

    const errorColumnOverride = [
        ["ReliabilityDrilldownLink", "", 20, null],
        ["ChartModalLink", "", 20, null],
        ["Name", "Name", tenantTableConstants.FIXED_ROW_HEADER_COLUMN_WIDTH, null],
        ["id", id, idWidth, null],
        ["Industry", "Industry", tenantTableConstants.COLUMN_WIDTH, null],
        ["Error Type", "Error Type", tenantTableConstants.COLUMN_WIDTH, null],
        [
            "ImpactScore",
            "Impact on cohort",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "# Users with Error",
            "# Users with Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "% Users With Error",
            "% Users With Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "S2500 Avg% Users With Error",
            "S2500 Avg% Users With Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "# Sessions With Error",
            "# Sessions With Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "% Sessions With Error",
            "% Sessions With Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
        [
            "S2500 Avg% Sessions With Error",
            "S2500 Avg% Sessions With Error",
            tenantTableConstants.COLUMN_WIDTH,
            "alignRightHeader",
        ],
    ];

    return (
        <Stack tokens={horizontalStackTokens}>
            <Stack.Item>
                <Stack horizontal className="chartControlsGrid">
                    <Stack.Item className="chartControl-component-left">
                        <Toggle
                            inlineLabel
                            checked={showTopTenantsByErrorPercentage}
                            onText={`Toggle off to show top ${tenantsNumberToShow} tenants by impact on the overall cohort`}
                            offText={`Toggle on to show top ${tenantsNumberToShow} tenants by % of users hitting this error`}
                            onChange={() => {
                                changeTenantGroupingClassification(
                                    !showTopTenantsByErrorPercentage,
                                );
                            }}
                            className="tenantDisplayToggle"
                        />
                    </Stack.Item>
                    <Stack.Item className="chartControl-component-right-label">
                        Number of Tenants to show
                    </Stack.Item>
                    <Stack.Item className="chartControl-component-right">
                        <Slider
                            min={startingNumberOfOptionsToShow}
                            max={maxNumberOfOptionsToShow}
                            step={stepForSlider}
                            value={tenantsNumberToShow}
                            onChange={(value: number) =>
                                setTenantNumberToShow(value)
                            }
                            showValue={true}
                            className="numberToDisplaySlider"
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <TopTenantsComposedChart
                    chartData={tenantsToShowInTable}
                    XAxisDataKey={"id"}
                    XAxisName={
                        "Tenant Id (Hover on the bar chart to see Tenant Name)"
                    }
                    YAxisDataKey={
                        showTopTenantsByErrorPercentage
                            ? "% Users With Error"
                            : "ImpactScore"
                    }
                    YAxisName={
                        showTopTenantsByErrorPercentage
                            ? "% Users With Error"
                            : "ImpactScore"
                    }
                />
            </Stack.Item>
            <Stack.Item>
                <TenantList
                    chartData={tenantsImpactedByErrorChartData}
                    errorTenants={tenantsToShowInTable}
                    filters={filters}
                    getChartData={getChartData}
                    s2500Data={s2500Data}
                    tableColumnsOverride={errorColumnOverride}
                    columnToSortByOverride={
                        showTopTenantsByErrorPercentage
                            ? "% Users With Error"
                            : "ImpactScore"
                    }
                />
            </Stack.Item>
        </Stack>
    );
};

export default TopTenantsForError;
