import React from "react";
import { IColumn, Icon, TooltipHost } from "@fluentui/react";
import { IntentAnalysisScorecardColumns } from "components/AIScorecardComponents/config";
import { WikiLinks } from "utils/Links";

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { Name, TriedCount, TriedRate, KeptRate } = payload[0].payload;
        return (
            <div
                style={{
                    backgroundColor: "white",
                    opacity: 0.9,
                    padding: "8px",
                    border: "1px solid LightGray",
                    fontSize: "1em",
                }}
            >
                <b>{Name}</b>
                <p>Tried Count: {TriedCount}</p>
                <p>% of Total Tries: {TriedRate}%</p>
                <p>KeptRate: {KeptRate}%</p>
            </div>
        );
    }
    return null;
};

export const getScorecardColumns = () => {
    const columns: IColumn[] = [];

    IntentAnalysisScorecardColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (column.name == "Intent" ? (
                <>
                    {column.displayName}
                    {column.tooltip && (
                        <TooltipHost content={column.tooltip}>
                            <a href={WikiLinks.Intents} target="_blank">
                                <Icon
                                    iconName="info"
                                    className="tableColumnDefintion"
                                />
                            </a>
                        </TooltipHost>
                    )}
                </>
            ) : (
                column.name
            )) as any,
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 120,
            maxWidth: column.minWidth ?? 120,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                column.className ?? "alignLeftHeader"
            }`,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};
