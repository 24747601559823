import { Reducer } from "react";
import { LabelData, LegendEntry } from "pages/commonTypes";
import { LegendAction } from "pages/Performance/types";
import { getChartColor } from "utils/Helpers";

const legendEntryAll: LegendEntry = {
    key: "All",
    Name: "All",
    color: "#000000",
    hidden: false,
    stroked: false,
    showDots: false,
};

const sortLegendWithAllFirst = (legend: LegendEntry[]) => {
    const sortedLegendWithoutAll = legend
        .filter((entry) => entry.key != "All")
        .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0));
    sortedLegendWithoutAll.forEach(
        (entry, index) => (entry.color = getChartColor(index)),
    );

    return [legendEntryAll, ...sortedLegendWithoutAll];
};

export const legendReducer: Reducer<LegendEntry[], LegendAction> = (
    previousState: LegendEntry[],
    action: LegendAction,
) => {
    switch (action.type) {
        case "addEntries":
            const incomingLabels = action.payload as LabelData[];
            const updatedLegend = [...previousState];
            incomingLabels.forEach((incomingLabel) => {
                if (updatedLegend.every((entry) => entry.key != incomingLabel.key))
                    updatedLegend.push({ ...incomingLabel, hidden: false });
            });

            if (updatedLegend.length > previousState.length) {
                return sortLegendWithAllFirst(updatedLegend);
            }
            return previousState;
        case "clickedEntry":
            const clickedEntry = action.payload as string;

            // If All was clicked, then we set everything opposite to the All state
            if (clickedEntry == "All") {
                const currentAllState = previousState[0].hidden;
                return previousState.map((entry) => ({
                    ...entry,
                    hidden: !currentAllState,
                }));
            }

            // Toggle the hidden property of the clicked entry
            const newState = previousState.map((entry) => ({
                ...entry,
                hidden: entry.key == clickedEntry ? !entry.hidden : entry.hidden,
            }));

            // Update the All state to be on only if everything is on
            newState[0].hidden = newState.some(
                (entry, index) => index != 0 && entry.hidden,
            );

            return newState;
        case "clearAndSetToAll":
            return [legendEntryAll];
        case "showDotsOnOneEntry":
            return previousState.map((entry) => ({
                ...entry,
                showDots: entry.key === (action.payload as string),
            }));
    }
};
