import React from "react";
import { BarChart, Bar, Cell, ReferenceLine } from "recharts";
import { STATUS } from "utils/WebConstants";

const SparkLine = (props) => {
    return (
        <BarChart
            width={100}
            height={40}
            data={props.data}
            margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
            }}
        >
            <Bar dataKey={props.metric} background={{ fill: "#eee" }}>
                {props.data.map((entry, index) => {
                    let val = entry[`${props.metric} Color`];
                    if (val === "Deep Red") val = "DeepRed";
                    return <Cell fill={STATUS[val]?.["color"]} key={index} />;
                })}
            </Bar>
            {props.goal !== undefined && (
                <>
                    <ReferenceLine
                        y={props.goal}
                        strokeWidth="1"
                        stroke="black"
                        strokeDasharray="3 3"
                        ifOverflow="extendDomain"
                    />
                </>
            )}
        </BarChart>
    );
};

export default SparkLine;
