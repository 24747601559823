import React, { useEffect, useState } from "react";
import {
    IChoiceGroupOption,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { CopilotProductDrilldownFilterPanel } from "components/CopilotComponents/CopilotProductDrilldownFilterPanel";
import { CopilotProductDrilldownTenantEnvironment } from "components/CopilotComponents/CopilotProductDrilldownTenantEnvironment";
import { CopilotProductDrilldownTenantList } from "components/CopilotComponents/CopilotProductDrilldownTenantList";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import { ScoreStatus } from "components/CustomComponents/ScoreStatus/ScoreStatus";
import TenantTitle from "components/CustomComponents/TenantTitle/TenantTitle";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { ACEHealthScore } from "components/TenantDrilldownComponents/ACEHealth/ACEHealthScore";
import { TenantTouchPoints } from "components/TenantDrilldownComponents/TenantTouchPoints/TenantTouchPoints";
import Tile from "components/CustomComponents/Tile";
import { TenantMetrics } from "components/TenantDrilldownComponents/TenantMetrics";
import { Workload, getConfig } from "config/PlatformConfig";
import {
    chartSizes,
    commonPageBlock,
    commonPageStyle,
    equalStackItemsStyle,
    horizontalStackTokens,
    searchCategoriesStyle,
} from "pages/common";
import {
    getAppsList,
    getProductDrilldownData,
    getTileLinks,
    loadChartMetrics,
} from "pages/Copilot/ApiHandler";
import {
    CopilotExecOverViewScoreType,
    CopilotProductDrilldownFilterState,
    CopilotProductDrilldownState,
} from "pages/Copilot/types";
import { logFilterUsage } from "utils/AppInsightsHelper";
import {
    copilotDisabledAppList,
    CopilotTenantTitleCohortTags,
} from "utils/CopilotConstants";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import { registerOverviewIcons } from "utils/IconRegistration";
import { COPILOT_MESSAGES, NO_DATA_MESSAGES } from "utils/Messages";

const CopilotProductDrilldown = () => {
    registerOverviewIcons();

    const config = getConfig(Workload.COPILOT_COMMERCIAL);

    const pageTitle = "Copilot Product Drilldown";

    const [filters, setFilters] = useState<CopilotProductDrilldownFilterState>(
        () => {
            const filtersFromQueryParams = extractQueryParams();
            const initialFilters: CopilotProductDrilldownFilterState = {
                date: null,
                platform: "Win32",
                level: config.defaults.level,
                application: config.defaults.app,
                id: "72f988bf-86f1-41af-91ab-2d7cd011db47", //Microsoft
            };

            return {
                ...initialFilters,
                ...filtersFromQueryParams,
            };
        },
    );

    const [scoreType, setScoreType] = useState<CopilotExecOverViewScoreType>(() => {
        const filtersFromQueryParams = extractQueryParams();
        if (filtersFromQueryParams["scoreType"]) {
            return filtersFromQueryParams[
                "scoreType"
            ] as CopilotExecOverViewScoreType;
        }

        return "Readiness";
    });

    const onScoreTypeChange = (
        _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ) => {
        const selectedScoreType = option.key as CopilotExecOverViewScoreType;

        if (selectedScoreType === "Readiness") {
            setFilters((oldFiltersState) => {
                return { ...oldFiltersState, application: "All", platform: "All" };
            });
        } else {
            setFilters((oldFiltersState) => {
                return {
                    ...oldFiltersState,
                    application: "Word",
                    platform: "Win32",
                };
            });
        }
        setScoreType((oldScoreType) => {
            return oldScoreType === selectedScoreType
                ? oldScoreType
                : selectedScoreType;
        });

        logFilterUsage(config.pageTitle, "scoreType", selectedScoreType);
    };

    const [data, setData] = useState<CopilotProductDrilldownState>({
        metadata: null,
        coreHealth: {
            tenantCoreHealthMetric: {},
            coreHealthScore: 0,
            coreHealthColor: "",
            pastStatus: [],
            momScore: 0,
        },
        sparkLineScores: [],
        appMetrics: [],
        chartData: null,
        loading: true,
        dateOptions: [],
    });

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        logFilterUsage(pageTitle, item, value);
        setUpDocumentUrl(
            {
                ...updatedFilter,
                scoreType: scoreType,
            },
            config.pageTitle,
        );

        setFilters(updatedFilter);
    };

    const [error, setError] = useState<string>(null);

    useEffect(() => {
        const getData = async (
            payloadFilters: CopilotProductDrilldownFilterState,
        ) => {
            setData((oldDataState) => {
                return { ...oldDataState, loading: true };
            });
            try {
                const dataResponse = await getProductDrilldownData(
                    payloadFilters,
                    scoreType,
                );
                if (typeof dataResponse === "string") {
                    setError(dataResponse);
                    setData((oldDataState) => ({ ...oldDataState, loading: false }));
                } else {
                    setData((oldDataState) => {
                        return {
                            ...oldDataState,
                            ...dataResponse,
                            loading: false,
                        };
                    });
                }
            } catch (error) {
                setData((oldDataState) => ({ ...oldDataState, loading: false }));
                setError(error.message);
            }
            setUpDocumentUrl(
                {
                    ...payloadFilters,
                    scoreType: scoreType,
                },
                pageTitle,
            );
        };

        getData({
            application: filters.application,
            date: filters.date,
            level: filters.level,
            id: filters.id,
            platform: filters.platform,
        });
    }, [
        scoreType,
        filters.application,
        filters.date,
        filters.level,
        filters.id,
        filters.platform,
    ]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack horizontal disableShrink styles={commonPageStyle}>
            <Stack.Item styles={searchCategoriesStyle}>
                <CopilotProductDrilldownTenantList
                    filters={filters}
                    scoreType={scoreType}
                    setFilters={setFilters}
                />
            </Stack.Item>
            <Stack.Item className="customerProductInsightsPageContentStyle">
                <Stack.Item>
                    <div className="orgHeader">
                        <TenantTitle
                            metadata={data.metadata}
                            level={filters.level}
                            showAdditional={false}
                            loading={data.loading}
                            tenantStatus={null}
                            cohortTags={CopilotTenantTitleCohortTags}
                        />
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <CopilotProductDrilldownFilterPanel
                        filters={filters}
                        scoreType={scoreType}
                        onFilterChange={onFilterChange}
                        dateOptions={data.dateOptions}
                        onScoreTypeChange={onScoreTypeChange}
                    />
                </Stack.Item>
                <Stack.Item styles={commonPageBlock}>
                    <h2 className="customerMetricGroupingTitle">Core Metrics</h2>
                    <AppOverviewIconPanel
                        apps={getAppsList(scoreType).filter((app) => app !== "WXP")}
                        onAppClick={onFilterChange}
                        selectedApp={filters.application}
                        disabledAppList={copilotDisabledAppList}
                    />
                    <Stack>
                        <Stack.Item>
                            <ScoreStatus
                                score={data.coreHealth.coreHealthScore}
                                application={filters.application}
                                status={data.coreHealth.coreHealthColor}
                                statusTrend={data.coreHealth.pastStatus}
                                loading={data.loading}
                                momScore={data.coreHealth.momScore}
                                pastStatusText={`Monthly ${scoreType} Trend`}
                                prefixText={scoreType}
                            />

                            <ul
                                className={`workloadTilesMetrics corePlatformTilesCopilot`}
                            >
                                {Object.keys(
                                    data.coreHealth.tenantCoreHealthMetric,
                                ).map((metric, i) => {
                                    return (
                                        <Tile
                                            data={
                                                data.coreHealth
                                                    .tenantCoreHealthMetric[metric]
                                            }
                                            key={i}
                                            sparkLineScores={
                                                data.sparkLineScores || []
                                            }
                                            loading={data.loading}
                                            link={getTileLinks(metric, filters)}
                                        />
                                    );
                                })}
                            </ul>
                        </Stack.Item>
                        <div className="break" />
                        <h2 className="customerMetricGroupingTitle">
                            Tenant Insights
                        </h2>
                        <Pivot linkSize="large">
                            <PivotItem
                                itemKey="App Metrics"
                                headerText="App Metrics"
                            >
                                <Stack styles={commonPageBlock}>
                                    {
                                        // No data condition
                                        data.appMetrics.length === 0 &&
                                        !data.loading ? (
                                            <MessageBar
                                                isMultiline={false}
                                                dismissButtonAriaLabel="Close"
                                            >
                                                {NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                                            </MessageBar>
                                        ) : (
                                            <>
                                                <MessageBar
                                                    messageBarType={
                                                        MessageBarType.warning
                                                    }
                                                    isMultiline={false}
                                                    dismissButtonAriaLabel="Close"
                                                >
                                                    {
                                                        COPILOT_MESSAGES.ENGAGEMENTMETRICS_WARNING
                                                    }
                                                </MessageBar>
                                                <TenantMetrics
                                                    payload={data.appMetrics}
                                                    getChartMetrics={(
                                                        metric,
                                                        display,
                                                        level,
                                                        orgKey,
                                                    ) => {
                                                        loadChartMetrics(
                                                            metric,
                                                            display,
                                                            level,
                                                            orgKey,
                                                            filters.platform,
                                                            setData,
                                                        );
                                                    }}
                                                    chartData={data.chartData}
                                                    filters={filters}
                                                    metricFilters={{}}
                                                    loading={data.loading}
                                                    platform={
                                                        Workload.COPILOT_COMMERCIAL
                                                    }
                                                />
                                            </>
                                        )
                                    }
                                </Stack>
                            </PivotItem>
                            <PivotItem
                                itemKey={"Tenant Environment"}
                                headerText={"Tenant Environment"}
                            >
                                <CopilotProductDrilldownTenantEnvironment
                                    filters={filters}
                                />
                            </PivotItem>
                            <PivotItem
                                itemKey={"Tenant Engagements"}
                                headerText={"Tenant Engagements"}
                            >
                                <Stack styles={commonPageBlock}>
                                    <Stack horizontal tokens={horizontalStackTokens}>
                                        <Stack.Item
                                            grow="initial"
                                            styles={equalStackItemsStyle}
                                        >
                                            <ACEHealthScore
                                                filters={filters}
                                                chartWidth={chartSizes.width}
                                                chartHeight={chartSizes.height}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <div className="break" />
                                    <Stack>
                                        <TenantTouchPoints filters={filters} />
                                    </Stack>
                                </Stack>
                            </PivotItem>
                        </Pivot>
                    </Stack>
                </Stack.Item>
            </Stack.Item>
        </Stack>
    );
};

export default CopilotProductDrilldown;
