import React, { useEffect, useState } from "react";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { Graph } from "@visx/network";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
    CustomLink,
    NetworkProps,
} from "components/QuIPSurveyReportComponents/UxSchemaDrillDownChart/types";
import { TreeNode } from "pages/QuIP/SurveyFunnelDrillDown20/types";

export const background = "#fff";

export default function UXSchemaDrillDownChart({
    width,
    height,
    rawNodes,
}: NetworkProps) {
    const [pNodes] = useState<TreeNode[]>([]);
    const [pLinks] = useState<CustomLink[]>([]);
    const [graph, setGraph] = useState<{
        links: CustomLink[];
        nodes: TreeNode[];
    }>({
        nodes: [],
        links: [],
    });

    useEffect(() => {
        let tW = width / rawNodes.length;
        let heightDirectionTop = true;
        let lastLinkNode: CustomLink;

        rawNodes.forEach((d, i) => {
            let x = i === 0 ? 0 : tW * i,
                y = height / 2;

            if (d.branchSource) {
                let middle = d.branchSource.reduce(
                    (a, b) => (b !== "variable" ? a + pNodes[b].y : a),
                    0,
                );
                x = tW * (parseInt(d.branchSource[0]) + 1);
                y =
                    d.branchSource.length >= 2
                        ? middle / 2
                        : heightDirectionTop
                        ? middle * (1.1 - 0.5)
                        : middle * (1.1 + 0.3);
                heightDirectionTop = !heightDirectionTop;
            }
            const newNode = {
                ...d,
                // the absolute X and Y position of the node on the SVG
                x: x,
                y: y,
            };
            pNodes.push(newNode);

            if (newNode.branchSource) {
                newNode.branchSource.forEach((b) => {
                    pLinks.push({
                        source: pNodes[b],
                        target: newNode,
                    });
                });
            } else if (lastLinkNode) {
                lastLinkNode.target = newNode;
                lastLinkNode = { source: newNode, target: null as any };
                pLinks.push(lastLinkNode);
            } else {
                lastLinkNode = { source: newNode, target: null as any };
                pLinks.push(lastLinkNode);
            }
        });

        setGraph({
            nodes: pNodes,
            links: pLinks,
        });
    }, [height, pLinks, pNodes, rawNodes, width]);

    return width < 10 ? null : (
        <svg width={width} height={height}>
            <rect width={width} height={height} rx={14} fill={background} />
            <Graph<CustomLink, TreeNode>
                graph={graph}
                left={100}
                nodeComponent={({ node }) => {
                    return <Node node={node} width={width} height={height} />;
                }}
                linkComponent={({ link: { source, target, dashed } }) =>
                    source &&
                    target && (
                        <line
                            x1={source.x}
                            y1={source.y}
                            x2={target.x}
                            y2={target.y}
                            strokeWidth={2}
                            stroke="#000"
                            strokeOpacity={0.6}
                            strokeDasharray={dashed ? "8,4" : undefined}
                        />
                    )
                }
            />
        </svg>
    );
}

function Node({
    node,
    width,
    height,
}: {
    node: TreeNode;
    width: number;
    height: number;
}) {
    const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
        useTooltip();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
        scroll: true,
    });

    const handleMouseOver = (event) => {
        const coords = localPoint(event.target.ownerSVGElement, event);
        showTooltip({
            tooltipLeft: coords.x * 0.001,
            tooltipTop: coords.y + 10,
        });
    };

    const tooltipStyles = {
        ...defaultStyles,
        backgroundColor: "#fff",
        color: "#000",
        padding: 12,
        maxWidth: 400,
        overflowWrap: "break-word",
    };

    return (
        <>
            <Group>
                <circle
                    ref={containerRef}
                    onMouseOver={handleMouseOver}
                    onMouseOut={hideTooltip}
                    r={width * height * 0.00004}
                    fill={node.variableIcon ? "#99b2c5" : "#0078d4"}
                />
                <text
                    dy=".33em"
                    fontSize={14}
                    textAnchor="middle"
                    fill="#fff"
                    style={{ pointerEvents: "none" }}
                >
                    {"Page "}
                    {node.pageIndex + 1}
                </text>
                <Group top={70}>
                    <text
                        dy=".33em"
                        fontSize={14}
                        textAnchor="middle"
                        fill="#000"
                        style={{ pointerEvents: "none" }}
                    >
                        {node.name}{" "}
                    </text>
                    <text
                        dy=".33em"
                        fontSize={14}
                        textAnchor="middle"
                        fill="#000"
                        y={20}
                        style={{ pointerEvents: "none" }}
                    >
                        {node.funnelData.rawValue.toLocaleString("en-US")}
                    </text>
                    <text
                        dy=".33em"
                        fontSize={14}
                        textAnchor="middle"
                        fill="#000"
                        y={40}
                        style={{ pointerEvents: "none" }}
                    >
                        {"("}
                        {node.funnelData.funnelPercentage.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                        })}
                        {"%)"}
                    </text>
                    {tooltipOpen && (
                        <TooltipInPortal
                            // set this to random so it correctly updates with parent bounds
                            key={Math.random()}
                            top={tooltipTop}
                            left={tooltipLeft}
                            style={tooltipStyles}
                        >
                            <div style={{ margin: "5px" }}>
                                <strong>Display Name: </strong> {node.name}
                            </div>
                            <div style={{ margin: "5px" }}>
                                <strong>Id:</strong> {node.templateId}
                            </div>
                            <div style={{ margin: "5px" }}>
                                <strong>Total Questions:</strong>
                                {node.questions}
                            </div>
                            <div style={{ margin: "5px" }}>
                                <strong>Final Page:</strong>
                                {JSON.stringify(node.isFinalPage)}
                            </div>

                            {node.branchConditions.length > 0 && (
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                    }}
                                >
                                    Branching Conditions:
                                    <div
                                        style={{
                                            padding: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {node.branchConditions.map(
                                            (branch, index) => (
                                                <div
                                                    key={index}
                                                    style={{ padding: 5 }}
                                                >
                                                    <div
                                                        style={{
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <strong>Type: </strong>
                                                        {branch.sourceType}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <strong>Source:</strong>
                                                        {branch.sourceId}
                                                    </div>
                                                    <div
                                                        style={{
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <strong>Value:</strong>
                                                        {JSON.stringify(
                                                            branch.conditionValue,
                                                        )}
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>
                            )}
                        </TooltipInPortal>
                    )}
                </Group>
            </Group>
        </>
    );
}
