export const win32CrossAppCompositeScore: string = `
//win32CrossAppCompositeScore
let app = "\${application}";
let level = "\${level}";
let cohort = "\${cohort}";
let currMonthdate = "\${date}";
let currentDate = GetCurrentDate(false); //isTesting value is set to false for GetCurrentDate function
let Mau = \${mauCountThreshold};
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
let Colors = TenantInsights_Win32_Quality_Colors
    | where Date == currMonthdate and iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | project \${levelColumnName}, Date, Perf_Colors=parse_json(Perf_Colors), Reliability_Colors=parse_json(Reliability_Colors), Feedback_Colors=parse_json(Feedback_Colors);
HealthyTenantScore_Win32Overview
    | project-rename Tpid = TopParentId
    | where Date == currMonthdate
    | where MAU > Mau
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == app and Color > 1
    | extend CurrencyDetails = parse_json(Currency_Details)
    | project OmsTenantId, Score, Color, MoMChange, MAU, Currency_InSupport_Score, Currency_InSupport_Color, 
        Date, Application, Perf_BootLaunchPercentageUnderNs_Score, Usage_PercMau5DayUsage_Score, 
        Usage_PercMau5DayUsage_Color, Usage_PercMau5DayUsage_MoMChange, Composite_Score = Score, Tpid, CurrencyDetails, TenantName_Translated
| extend Currency_DominantChannelFork_Text = CurrencyDetails.DominantChannelFork
| join kind=innerunique
    (StrategicTenants
        | extend IsMidMarket = iff(Cohorts contains "MidMarket", true, false)
        | where Is\${cohort} == true and Date == currentDate
        | project tolong(Tpid), OmsTenantId, OrgName_Translated, TenantName_Translated
        | distinct \${levelColumnName}, iif( level  == "Tpid", OrgName_Translated, TenantName_Translated) 
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    HealthyTenantScore_PastStatus | project-rename Tpid = TopParentId
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == app) on \${levelColumnName}
| join kind=leftouter (
    Colors
) on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, Application, OrgName = Name,
    Composite_Score = round(Score, 2),
    Composite_MoMChange = MoMChange,
    MAU_Score = MAU,
    EngagedUsers_Score = Usage_PercMau5DayUsage_Score, EngagedUsers_Score_Color = GetColor(Usage_PercMau5DayUsage_Color),
    Word_Performance_AppColor = GetColor(Perf_Colors.Word),
    Excel_Performance_AppColor = GetColor(Perf_Colors.Excel),
    PowerPoint_Performance_AppColor = GetColor(Perf_Colors.PowerPoint),
    Outlook_Performance_AppColor = GetColor(Perf_Colors.Outlook),
    OneNote_Performance_AppColor = GetColor(Perf_Colors.OneNote),
    Word_Reliability_AppColor = GetColor(Reliability_Colors.Word),
    Excel_Reliability_AppColor = GetColor(Reliability_Colors.Excel),
    PowerPoint_Reliability_AppColor = GetColor(Reliability_Colors.PowerPoint),
    Outlook_Reliability_AppColor = GetColor(Reliability_Colors.Outlook),
    OneNote_Reliability_AppColor = GetColor(Reliability_Colors.OneNote),
    Word_Feedback_AppColor = GetColor(Feedback_Colors.Word),
    Excel_Feedback_AppColor = GetColor(Feedback_Colors.Excel),
    PowerPoint_Feedback_AppColor = GetColor(Feedback_Colors.PowerPoint),
    Outlook_Feedback_AppColor = GetColor(Feedback_Colors.Outlook),
    OneNote_Feedback_AppColor = GetColor(Feedback_Colors.OneNote),
    Currency_Score = Currency_InSupport_Score , Currency_Score_Color = GetColor(Currency_InSupport_Color), Currency_DominantChannelFork_Text,
    Status =  GetColor(Color),
    Parent = Tpid,
    TenantName_Translated
| sort by Composite_Score asc, MAU_Score desc
`;
