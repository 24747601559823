export const m365TenantList: string = `
// m365TenantList
let currentMonthDateTime = todatetime(GetCurrentDate(false));
let prevMonthDateTime = datetime_add("month", -1, currentMonthDateTime) ;
let currentMonth1st = format_datetime(currentMonthDateTime, "yyyy-MM-dd");
let PrevMonth1st = format_datetime(prevMonthDateTime, "yyyy-MM-dd");
let currentMonth28th = format_datetime(datetime_add("day", 27, currentMonthDateTime), "yyyy-MM-dd");
let prevMonth28th = format_datetime(datetime_add("day", 27, prevMonthDateTime), "yyyy-MM-dd") ;
let macTenantList = 
HealthyTenantScore_MacAppView
| where ProcessDate in (currentMonth28th, prevMonth28th)
| where Color != 0 or OmsTenantId == "-100"
| distinct OmsTenantId, Tpid = tostring(Tpid)
| extend Source = "Mac";
let win32TenantList = 
HealthyTenantScore_Win32Overview
| where Date in (currentMonth28th, prevMonth28th)
| where Color != 0 or OmsTenantId == "-100" 
| extend Tpid = tostring(TopParentId)
| distinct OmsTenantId, tostring(Tpid) 
| extend Source="Win32";
let webTenantList = 
Score_V2
| where Date in (currentMonth1st, PrevMonth1st) and UiHost == "All" 
| where CompositeScore_Color != "Gray" or OmsTenantId == "-100"
| distinct OmsTenantId, tostring(Tpid)
| extend Source = "Web";
let tenantTpidSource =
(webTenantList
| union (win32TenantList)
| union (macTenantList))
| join kind=leftouter(
StrategicTenants
| where Date == toscalar(StrategicTenants| summarize max(Date))
| where isnotempty(OrgName_Translated)
| distinct tostring(Tpid), OrgName_Translated, OmsTenantId, TenantName_Translated
) on OmsTenantId , Tpid
| distinct Tpid, OrgName_Translated, OmsTenantId, TenantName_Translated, Source
| summarize TenantSource=make_list(Source) by Tpid,OrgName_Translated, OmsTenantId, TenantName_Translated;
//Tenant Sources
tenantTpidSource | where OmsTenantId != "All"
| join kind=inner (tenantTpidSource | where OmsTenantId == "All") on Tpid
| extend Order = iff(OrgName_Translated=="Microsoft", 1, 2)
| order by Order asc, Tpid desc, OrgName_Translated desc, TenantName_Translated desc, OmsTenantId desc
| project Tpid, OrgName_Translated, OmsTenantId, TenantName_Translated, TenantSource, TpidSource=TenantSource1
`;

export const m365TenantTpidDetails: string = `
// m365TenantTpidDetails
let currentMonthDateTime = todatetime(GetCurrentDate(false));
let prevMonthDateTime = datetime_add("month", -1, currentMonthDateTime) ;
let currentMonth1st = format_datetime(currentMonthDateTime, "yyyy-MM-dd");
let PrevMonth1st = format_datetime(prevMonthDateTime, "yyyy-MM-dd");
let currentMonth28th = format_datetime(datetime_add("day", 27, currentMonthDateTime), "yyyy-MM-dd");
let prevMonth28th = format_datetime(datetime_add("day", 27, prevMonthDateTime), "yyyy-MM-dd") ;
let macTenantList = 
HealthyTenantScore_MacAppView
| where ProcessDate in (currentMonth28th, prevMonth28th)
| where Color != 0 or OmsTenantId == "-100"
| distinct OmsTenantId, Tpid = tostring(Tpid)
| extend Source = "Mac";
let win32TenantList = 
HealthyTenantScore_Win32Overview
| where Date in (currentMonth28th, prevMonth28th)
| where Color != 0 or OmsTenantId == "-100" 
| extend Tpid = tostring(TopParentId)
| distinct OmsTenantId, tostring(Tpid) 
| extend Source="Win32";
let webTenantList = 
Score_V2
| where Date in (currentMonth1st, PrevMonth1st) and UiHost == "All" 
| where CompositeScore_Color != "Gray" or OmsTenantId == "-100"
| distinct OmsTenantId, tostring(Tpid)
| extend Source = "Web";
let tenantTpidSource =
(webTenantList
| union (win32TenantList)
| union (macTenantList))
| join kind=leftouter(
StrategicTenants
| where Date == toscalar(StrategicTenants| summarize max(Date))
| where isnotempty(OrgName_Translated)
| distinct tostring(Tpid), OrgName_Translated, OmsTenantId, TenantName_Translated
) on OmsTenantId , Tpid
| distinct Tpid, OrgName_Translated, OmsTenantId, TenantName_Translated, Source
| summarize TenantSource=make_list(Source) by Tpid,OrgName_Translated, OmsTenantId, TenantName_Translated;
//Tenant Sources
tenantTpidSource | where OmsTenantId != "All"
| join kind=inner (tenantTpidSource | where OmsTenantId == "All") on Tpid
| extend Order = iff(OrgName_Translated=="Microsoft", 1, 2)
| extend TenantName_Translated=iif("\${levelColumnName}"=="Tpid",OrgName_Translated , TenantName_Translated)
| order by Order asc, Tpid desc, OrgName_Translated desc, TenantName_Translated desc, OmsTenantId desc
| project Tpid, OrgName_Translated, OmsTenantId, TenantName_Translated, TenantSource, TpidSource=TenantSource1
| where \${levelColumnName} == "\${id}"
`;
