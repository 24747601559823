import { precisionRound } from "@fluentui/react";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { formatMetaData } from "pages/InProductDrillDown/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDisplayDate, setBlankIfEmpty } from "utils/Helpers";
import { COLLAB_STAGES } from "utils/Messages";
import { CollabFilterState, CollabResponseJsonType, CollabState } from "./types";

export function formatCollabResponse(data: CollabResponseJsonType) {
    const collabFunnelTable = data.collabFunnel.Tables[0];
    const metadataJson = data?.metadata;

    const funnelData = {};
    const chartDataTemp = [];

    Object.keys(COLLAB_STAGES).forEach((stage) => {
        funnelData[stage] = {};
        funnelData[`${stage} %`] = {};
        funnelData[stage]["Stage"] = stage;
        funnelData[`${stage} %`]["Stage"] = `${stage} %`;
    });

    collabFunnelTable.Rows.forEach((element) => {
        const date = element[0];
        const app = element[3] as string;

        if (!chartDataTemp[app]) {
            chartDataTemp[app] = {};
        }

        if (!chartDataTemp[app][date]) {
            chartDataTemp[app][date] = { Date: date };
        }

        let idx = 6;

        Object.keys(COLLAB_STAGES).forEach((stage) => {
            // const denominator = idx < 7 ? element[5] : element[5];
            const denominator = element[6] as number;
            const percent = precisionRound(
                ((element[idx] as number) / denominator) * 100,
                2,
            );

            // Adding data for charts
            chartDataTemp[app][date][stage] = element[idx];
            chartDataTemp[app][date][`${stage} %`] = percent;

            // Adding data for table
            if (element[11] === true) {
                funnelData[stage][app] = element[idx];
                funnelData[`${stage} %`][app] = percent;
            }

            idx += 1;
        });
    });

    const flattenFunnel = [];
    Object.keys(funnelData).forEach((funnel) => {
        flattenFunnel.push(funnelData[funnel]);
    });

    const chartData = [];
    Object.keys(chartDataTemp).forEach((app) => {
        chartData[app] = [];
        Object.keys(chartDataTemp[app]).forEach((date) => {
            chartData[app].push(chartDataTemp[app][date]);
        });
    });

    const collabFunnel = {
        funnelData: flattenFunnel,
        chartData,
    };

    // Date Options
    const dateOptions = [];
    const dates = data.dateOptions.Tables[0].Rows.sort().reverse();
    dates.forEach((x) => {
        dateOptions.push({ key: x[0], text: x[0] });
    });
    dateOptions.forEach((element) => {
        element.text = formatDisplayDate(element.key);
    });

    // UiHost Options
    const uihostOptions = [];
    const uihosts = data.uihostOptions.Tables[0].Rows.sort();
    uihosts.forEach((x) => {
        uihostOptions.push({ key: x[0], text: setBlankIfEmpty(x[0]) });
    });

    const filterOptions = {
        dateOptions,
        uihostOptions,
    };

    // To get tenant information
    const metadata = formatMetaData(metadataJson, Workload.WEB);

    const tenantCollab = {
        collabFunnel,
        filterOptions,
        metadata,
    };
    return tenantCollab;
}

export const fetchCollabData = async (
    filters: CollabFilterState,
    setState: React.Dispatch<React.SetStateAction<CollabState>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });

        const collabResponse = await API.getTenantCollab(filters);
        const response = formatCollabResponse(collabResponse);

        setState((state) => {
            return {
                ...state,
                isFetching: false,
                ...response,
            };
        });
    } catch (error) {
        {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "CollabDataFetchOrProcessFailed",
                ),
                {
                    message: "Failure while fetching or formatting collab data",
                    filters,
                },
                error,
            );
            setState((state) => {
                return {
                    ...state,
                    isFetching: false,
                };
            });
        }
    }
};
