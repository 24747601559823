import { precisionRound } from "@fluentui/react";
import { getRequiredFiltersUrlFriendly } from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import {
    AdditionalFilterType,
    CommonFilterStateType,
} from "components/CommonFunnelComponents/CommonFunnel/types";
import { getStageDelta } from "components/CommonFunnelComponents/CommonFunnelStages/commonFunnelStagesHelper";
import { FunnelDataType } from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { FunnelType, getFunnelConfig } from "config/FunnelConfig";
import { MetricsResponseType } from "pages/commonTypes";
import { extractQueryParams } from "utils/Helpers";

export const flattenStagesData = (funnelStagesResponse: MetricsResponseType) => {
    const funnelDataTable = funnelStagesResponse.Tables[0];
    const funnelData = {};
    funnelDataTable.Rows.forEach((element) => {
        const pct = precisionRound(
            ((element[6] as number) * 100) / (element[7] as number),
            2,
        );

        if (!(element[0] in funnelData)) {
            funnelData[element[0]] = {};
        }

        const stage = element[3] as string;
        funnelData[element[0]][stage] = {
            value: element[6] as number,
            percentage: pct,
        };
    });

    return funnelData;
};

export const formatFunnelStageOverTimeData = (
    funnelStagesResponse: MetricsResponseType,
) => {
    const funnelOverTime = [];
    const funnelData = flattenStagesData(funnelStagesResponse);

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            funnelOverTime.push({ date, ...funnelData[date] });
        });

    return funnelOverTime;
};

export const formatFunnelDropOffOverTime = (funnelData: FunnelDataType) => {
    const funnelDropOffOverTime = [];

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            let dropOffData = {};
            dropOffData["date"] = date;
            const stagesData = funnelData[date];
            for (let i = 0; i < stagesData.length - 1; i++) {
                dropOffData[stagesData[i].dropOff.friendlyName] = getStageDelta(
                    stagesData,
                    i,
                );
            }
            funnelDropOffOverTime.push(dropOffData);
        });

    return funnelDropOffOverTime;
};

export const getAdditionalFiltersToShow = (
    pivotOverrides: {},
    additionalPivotsForThisFunnel: string[],
) => {
    let additionalFilters = new Set<string>();
    const additionalFiltersSet = new Set<string>(additionalPivotsForThisFunnel);

    for (let key in pivotOverrides) {
        if (additionalFiltersSet.has(key)) {
            additionalFilters.add(key);
        }
    }

    return additionalFilters;
};

export const getDataTableName = (funnelType: FunnelType, application: string) => {
    const { tableName } = getFunnelConfig(funnelType);

    // Default to empty string if no tableName is provided.
    if (!tableName) {
        return "";
    }

    // TableName will either be the direct table name
    if (typeof tableName === "string") {
        return tableName;
    }

    // Or an object with app to table name mapping
    return tableName[application];
};

export const getInitialFiltersForFunnel = (
    funnelType: FunnelType,
    optionalAdditionalPivots: AdditionalFilterType,
    pivotOverrides: {},
) => {
    const requiredFiltersUrlFriendly = getRequiredFiltersUrlFriendly(funnelType);

    var initialFilters: CommonFilterStateType = {
        section: null,
        application: getFunnelConfig(funnelType).defaults.app,
        date: null,
        ...requiredFiltersUrlFriendly,
        additionalFilters: extractFiltersFromParamsOrDefault(
            optionalAdditionalPivots,
            pivotOverrides,
        ),
    };
    initialFilters = extractRequiredFiltersFromParams(
        funnelType,
        initialFilters,
        pivotOverrides,
    );

    return initialFilters;
};

export const extractFiltersFromParamsOrDefault = (
    additionalFilters: AdditionalFilterType,
    pivotOverrides: {},
) => {
    if (!additionalFilters) return undefined;
    const pivotDefaults: AdditionalFilterType = {};

    Object.keys(additionalFilters).forEach((pivot) => {
        pivotDefaults[pivot] = pivotOverrides[pivot] ?? "All";
    });

    return pivotDefaults;
};

export const extractRequiredFiltersFromParams = (
    funnelType: FunnelType,
    filterList: CommonFilterStateType,
    pivotOverrides: {},
) => {
    const filters = { ...filterList };

    // Update the required filters with query params
    Object.keys(pivotOverrides).map((key) => {
        if (
            key === "application" &&
            getFunnelConfig(funnelType).appsList.includes(pivotOverrides[key])
        ) {
            filters[key] = pivotOverrides[key];
        } else if (Object.keys(filters).includes(key)) {
            filters[key] = pivotOverrides[key];
        }
    });

    return filters;
};

export const getDefinedAdditionalPivotsForFunnel = (
    additionalFilters: AdditionalFilterType,
) => {
    if (!additionalFilters) return {};
    const additionalFiltersFromParams = {};
    const paramFilters = extractQueryParams();
    Object.keys(paramFilters).map((key) => {
        if (Object.keys(additionalFilters).includes(key)) {
            additionalFiltersFromParams[key] = paramFilters[key];
        }
    });

    return additionalFiltersFromParams;
};
