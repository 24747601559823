import React, { useState } from "react";
import {
    Dropdown,
    IDropdownOption,
    IStackTokens,
    Label,
    Panel,
    PanelType,
    Stack,
} from "@fluentui/react";
import { AppButtons } from "components/CustomComponents/AppButtons";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import {
    getFlyoutMetricOptions,
    getSegmentOptions,
    ERFMColumnConfig,
} from "components/ERFMComponents/ERFMHelper";
import { ERFMChartFlyoutPropsType } from "components/ERFMComponents/types";
import { Workload } from "config/PlatformConfig";
import { appStyles } from "utils/Constants";
import { getChartColor } from "utils/Helpers";
import { ERFM_MESSAGES } from "utils/Messages";

export const ERFMChartFlyout = ({
    isPanelOpen,
    togglePanel,
    segment,
    application,
    dataByApp,
    dataByCountry,
}: ERFMChartFlyoutPropsType) => {
    const CHART_HEIGHT = 400;

    const [selectedApp, setSelectedApp] = useState<string>(application);
    const [selectedSegment, setSelectedSegment] = useState<IDropdownOption>(
        segment
            ? { key: segment, text: ERFMColumnConfig[segment].displayName }
            : getSegmentOptions()[0],
    );
    const [selectedMetric, setSelectedMetric] = useState<IDropdownOption>(
        getFlyoutMetricOptions()[0],
    );

    const onSegmentChange = () => {
        return (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            setSelectedSegment(item);
        };
    };
    const onMetricChange = () => {
        return (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            setSelectedMetric(item);
        };
    };

    const stackTokens: IStackTokens = { childrenGap: 20 };

    const chartLabels = (data) => {
        if (!data || !data.length) {
            return [];
        }

        const labels = Object.keys(data[0]).filter((key) => key !== "date");
        return labels.map((key, index) => {
            return {
                color: appStyles[key]
                    ? appStyles[key]["color"]
                    : getChartColor(index),
                key,
            };
        });
    };

    const chartAppData = dataByApp?.[selectedSegment.key]?.[selectedMetric.key];
    const chartCountryData =
        dataByCountry?.[selectedApp]?.[selectedSegment.key]?.[selectedMetric.key];

    return (
        <Panel
            isLightDismiss
            isOpen={isPanelOpen}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                togglePanel();
            }}
            closeButtonAriaLabel="Close"
            headerText="Metric Historical Trends"
            headerClassName="flyoutHeader"
        >
            <Stack horizontal tokens={stackTokens}>
                <div>
                    <Label className="filterLabel">Segment:</Label>
                    <Dropdown
                        options={getSegmentOptions()}
                        onChange={onSegmentChange()}
                        defaultSelectedKey={selectedSegment.key}
                        selectedKey={selectedSegment.key}
                        className="filterDropdown"
                        dropdownWidth="auto"
                    />
                </div>
                <div>
                    <Label className="filterLabel">Metric:</Label>
                    <Dropdown
                        options={getFlyoutMetricOptions()}
                        onChange={onMetricChange()}
                        defaultSelectedKey={selectedMetric.key}
                        selectedKey={selectedMetric.key}
                        className="filterDropdown"
                        dropdownWidth="auto"
                    />
                </div>
            </Stack>

            <h2 className="commonPageBlockTitle">{selectedMetric.text} By App</h2>
            {!chartAppData ? (
                <span>{ERFM_MESSAGES.NO_DATA_AVAILABLE}</span>
            ) : (
                <TogglingLineChart
                    kind={
                        selectedMetric.key === "MAU"
                            ? togglingLineChartKind.valuesAbbreviated
                            : togglingLineChartKind.percentages
                    }
                    labels={chartLabels(chartAppData)}
                    xDataKey="date"
                    values={chartAppData}
                    width={600}
                    height={CHART_HEIGHT}
                />
            )}

            <h2 className="commonPageBlockTitle">
                {selectedMetric.text} By Country ({selectedApp})
            </h2>
            {!chartCountryData ? (
                <span>{ERFM_MESSAGES.NO_DATA_AVAILABLE}</span>
            ) : (
                <>
                    <TogglingLineChart
                        kind={
                            selectedMetric.key === "MAU"
                                ? togglingLineChartKind.valuesAbbreviated
                                : togglingLineChartKind.percentages
                        }
                        labels={chartLabels(chartCountryData)}
                        xDataKey="date"
                        values={chartCountryData}
                        width={600}
                        height={CHART_HEIGHT}
                    />
                    <AppButtons
                        selectedApp={selectedApp}
                        setSelectedApp={setSelectedApp}
                        workload={Workload.ERFM}
                    />
                </>
            )}
        </Panel>
    );
};
