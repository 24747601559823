import React, { useEffect, useReducer } from "react";
import {
    Dropdown,
    FontIcon,
    IDropdownOption,
    Pivot,
    PivotItem,
    Stack,
} from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import TenantTitle from "components/CustomComponents/TenantTitle/TenantTitle";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { PerformanceDrilldown } from "components/PerformanceComponents";
import { TenantTitleCohortTags } from "config/PlatformConfig";
import { commonPageBlock, commonPageStyle, filterStackTokens } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logLevelBasedView } from "utils/AppInsightsHelper";
import {
    addMonthsToDate,
    checkMissingDrilldownQueryParams,
    computeQueryParams,
    extractQueryParams,
    formatDisplayDate,
    getDateTime,
} from "utils/Helpers";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";
import { dropdownStyles, hostOptions, uihostOptions } from "utils/WebConstants";
import {
    WebPerfBaseQueryParams,
    WebPerfFiltersAction,
    WebPerfFilterType,
    WebPerformanceState,
    WebPerfQueryParams,
} from "./types";
import { fetchPerformance, WebPerformanceReducer } from "./WebPerfApiHandler";

const pageName = "Performance Drilldown";

function Performance() {
    document.title = "Office Web Performance Tier 2";
    AppInsights.getInstance().TrackPage(document.title);

    const perfMetrics: string[] = ["Browser", "Datacenter", "Os", "Location"];
    const perfInitialState: WebPerformanceState = {
        isFetching: false,
        performanceTableData: null,
        modalChartData: null,
        euplCharts: null,
        chartLabels: null,
        dateOptions: null,
        metadata: null,
        filters: null,
    };

    const queryParams: WebPerfBaseQueryParams = checkMissingDrilldownQueryParams(
        extractQueryParams(),
    ) as WebPerfBaseQueryParams;

    const { id, level } = queryParams;

    // Initialize query params of Host and UiHost to "All"
    let updatedQueryParams: WebPerfQueryParams = {
        Host: "All",
        UiHost: "All",
        ...queryParams,
    };

    // reducers.
    const queryParamsReducer = (
        prevState: WebPerfQueryParams,
        action: WebPerfFiltersAction,
    ): WebPerfQueryParams => {
        switch (action.type) {
            case "date":
                return { ...prevState, date: action.payload };
            case "Host":
                return { ...prevState, Host: action.payload };
            case "UiHost":
                return { ...prevState, UiHost: action.payload };
        }
    };

    // useReducer Hooks
    const [currentQueryParamsState, queryParamsDispatcher] = useReducer(
        queryParamsReducer,
        updatedQueryParams,
    );
    const [currentPerfState, perfStateDispatcher] = useReducer(
        WebPerformanceReducer,
        perfInitialState,
    );
    const { date, Host, UiHost } = currentQueryParamsState;

    useSendLaunchEvent("Office Web Performance Tier 2 Dashboard");

    // side effects that doesn't have any dependency.
    useEffect(() => {
        logLevelBasedView(document.title, level);
    }, [level]);

    useEffect(() => {
        async function triggerWebPerfData() {
            await fetchPerformance(currentQueryParamsState)(perfStateDispatcher);
        }
        triggerWebPerfData();
    }, [currentQueryParamsState, date, Host, UiHost]);

    if (!currentPerfState.performanceTableData) {
        return <LoadingModal text={`Loading ${pageName}...`} />;
    }

    currentPerfState.dateOptions.forEach((element) => {
        element.text = formatDisplayDate(element.key as string);
    });

    const _onFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            // TODO : Why are we updating the Url only when date changes . is this a bug ?
            if (dropdownFor === "date") {
                window.history.pushState(
                    "",
                    "",
                    window.location.pathname +
                        computeQueryParams({
                            ...currentQueryParamsState,
                            date: item.key.toString(),
                        }),
                );
            }
            let action: WebPerfFiltersAction = {
                type: dropdownFor as WebPerfFilterType,
                payload: item.key.toString(),
            };
            queryParamsDispatcher(action);
        };
    };

    const _getBootPerfDashboardLink = () => {
        const startDate = currentQueryParamsState["date"]
            ? new Date(currentQueryParamsState["date"])
            : addMonthsToDate(new Date(), -1);
        return `https://jarvis-west.dc.ad.msft.net/dashboard/share/12F69839?overrides=[{"query":"//*[id='Ring']","key":"value","replacement":"5"},
        {"query":"//*[id='TenantId']","key":"value","replacement":"${id}"}]&globalStartTime=${getDateTime(
            addMonthsToDate(startDate, -1),
        )}&globalEndTime=${getDateTime(
            addMonthsToDate(startDate, 1),
        )}&pinGlobalTimeRange=true`;
    };

    return (
        <>
            {currentPerfState.isFetching ? (
                <ShimmeredCardBody data-testid="WebPerfLoadingBar"></ShimmeredCardBody>
            ) : (
                <Stack disableShrink styles={commonPageStyle}>
                    <div className="orgHeader">
                        <br />
                        <span id="drilldownTitle"> {pageName} </span>
                    </div>
                    <div className="drilldownTenantName">
                        <TenantTitle
                            data-testid="WebPerfTenantTitle"
                            metadata={currentPerfState.metadata}
                            level={level}
                            loading={false}
                            tenantStatus={currentPerfState.metadata["status"]}
                            cohortTags={TenantTitleCohortTags}
                        />
                    </div>
                    <br />
                    <Stack
                        data-testid="WebPerfFiltersContainer"
                        horizontal
                        tokens={filterStackTokens}
                    >
                        <FontIcon
                            data-testid="WebPerfFilterIcon"
                            iconName="Filter"
                            className="filterIcon"
                        />
                        <Dropdown
                            placeholder="Select an option"
                            data-testid="WebPerfTimePeriodFilter"
                            label="Time Period"
                            options={currentPerfState.dateOptions}
                            selectedKey={currentQueryParamsState.date}
                            styles={dropdownStyles}
                            onChange={_onFilterChange("date")}
                            className="filterDropdown"
                        />
                        <Dropdown
                            placeholder="Select an option"
                            data-testid="WebPerfHostFilter"
                            label="Host"
                            options={hostOptions}
                            selectedKey={currentQueryParamsState.Host}
                            styles={dropdownStyles}
                            onChange={_onFilterChange("Host")}
                            className="filterDropdown"
                        />
                        <Dropdown
                            placeholder="Select an option"
                            data-testid="WebPerfUiHostFilter"
                            label="UiHost"
                            options={uihostOptions}
                            selectedKey={currentQueryParamsState.UiHost}
                            styles={dropdownStyles}
                            onChange={_onFilterChange("UiHost")}
                            className="filterDropdown"
                        />
                        <Stack.Item grow={1} className="menuNavigation">
                            {level === "TenantId" && (
                                <a
                                    data-testid="WebPerfDashboardLink"
                                    href={_getBootPerfDashboardLink()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {"Go to WISE BootPerf Dashboard"}
                                </a>
                            )}
                        </Stack.Item>
                    </Stack>
                    <Stack>
                        <Stack.Item styles={commonPageBlock}>
                            <Pivot
                                data-test-id="WebPerfDrilldownContainer"
                                linkSize={"large"}
                            >
                                {perfMetrics.map((metric, index) => {
                                    return (
                                        <PivotItem
                                            data-test-id={
                                                "WebPerf" + metric + "Panel"
                                            }
                                            headerText={metric}
                                            key={index}
                                        >
                                            <PerformanceDrilldown
                                                key={metric}
                                                data={
                                                    currentPerfState
                                                        .performanceTableData[metric]
                                                }
                                                modalChartData={
                                                    currentPerfState.modalChartData[
                                                        metric
                                                    ]
                                                }
                                                euplCharts={
                                                    currentPerfState.euplCharts[
                                                        metric
                                                    ]
                                                }
                                                chartLabels={
                                                    currentPerfState.chartLabels[
                                                        metric
                                                    ]
                                                }
                                            />
                                        </PivotItem>
                                    );
                                })}
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Stack>
            )}
        </>
    );
}

export default Performance;
