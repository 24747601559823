export function getChartDataForError(data: any, s2500Data: {}, filters: {}) {
    const chartTable = data.chartData.Tables[0];
    const chartData = [];

    // Schema- Date, PercentUserWithError, PercentSessionWithError
    chartTable.Rows.forEach((element) => {
        const Date = element[0];
        const tmp = { Date };
        tmp[`${filters["errorname"]}_onUsers`] = element[1];
        tmp[`S2500_${filters["errorname"]}_onUsers`] =
            s2500Data[Date]["S2500PercentageUsersWithError"];
        tmp[`${filters["errorname"]}_onSessions`] = element[2];
        tmp[`S2500_${filters["errorname"]}_onSessions`] =
            s2500Data[Date]["S2500PercentageSessionsWithError"];
        chartData.push(tmp);
    });

    return { chartData };
}
