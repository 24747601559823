export const webHealthChartMTR: string = `
// webHealthChart - cluster('ehfuelinsights.westus2.kusto.windows.net').database('TenantInsights')
set query_results_cache_max_age = time(12h);
let currDate = GetCurrentDate();
let tenantLevel = "TenantId";
let app = "WXP";
let cohort = "S500";
let mau = 2500;
HealthDistribution
| where Application == app
    and UiHost == "All"
    and Cohort == cohort
    and Level == tenantLevel
    and MinMau == mau
| extend Current = (Date == currDate)
| project Date, Status, Count, Current
| order by Date asc, Status asc
`;
