import React from "react";
import { TooltipHost, Icon, Label } from "@fluentui/react";
import { CadenceLabelProps } from "components/CustomComponents/CadenceLabel/types";
import "components/CustomComponents/CadenceLabel/styles.css";

const dailyToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"Daily: "}</strong>
            {"Aggregates per-day. Updates daily."}
        </div>
    );
};

const l28DToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"L28D (Last 28 Days): "}</strong>
            {
                "Runs at the end of the month. Includes data for the last 28 calendar days."
            }
        </div>
    );
};

const monthlyToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"Monthly: "}</strong>
            {"Runs at the month-end. Includes all days for the month."}
        </div>
    );
};

const rXDToolTipJSX = (days: number): JSX.Element => {
    return (
        <div>
            <strong>{`R${days}D (Rolling ${days} Days): `}</strong>
            {`Runs weekly each Saturday. Includes data for the last ${days} days.`}
        </div>
    );
};

const weeklyToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"Weekly: "}</strong>
            {"Aggregates per-week. Includes data from Sunday through Saturday."}
        </div>
    );
};

const weeklyDay0FunnelToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"Weekly: "}</strong>
            {
                "Aggregates 7 calendar days after the 1st, 8th, 15th and 22nd of each month."
            }
        </div>
    );
};

const monthlyDay0FunnelToolTipJSX = (): JSX.Element => {
    return (
        <div>
            <strong>{"Monthly: "}</strong>
            {"Runs at the month-end and includes all days for the month."}
        </div>
    );
};

export enum CadenceFrequency {
    Daily = "Daily",
    L28D = "L28D",
    Monthly = "Monthly",
    R28D = "R28D",
    R56D = "R56D",
    R84D = "R84D",
    Weekly = "Weekly",
    Weekly_Day0Funnel = "Weekly_Day0Funnel",
    Monthly_Day0Funnel = "Monthly_Day0Funnel",
}

export const CadenceMetadata: {
    [key in CadenceFrequency]: { displayText: string; tooltip: () => JSX.Element };
} = {
    Daily: { displayText: "Date", tooltip: dailyToolTipJSX },
    L28D: { displayText: "L28D", tooltip: l28DToolTipJSX },
    Monthly: { displayText: "Month", tooltip: monthlyToolTipJSX },
    R28D: { displayText: "R28D", tooltip: rXDToolTipJSX.bind(null, 28) },
    R56D: { displayText: "R56D", tooltip: rXDToolTipJSX.bind(null, 56) },
    R84D: { displayText: "R84D", tooltip: rXDToolTipJSX.bind(null, 84) },
    Weekly: { displayText: "Week", tooltip: weeklyToolTipJSX },
    Weekly_Day0Funnel: {
        displayText: "Week",
        tooltip: weeklyDay0FunnelToolTipJSX,
    },
    Monthly_Day0Funnel: {
        displayText: "Month",
        tooltip: monthlyDay0FunnelToolTipJSX,
    },
};

export const CadenceLabel = ({ cadence }: CadenceLabelProps) => {
    return (
        <>
            <Label className="filterLabel">
                {CadenceMetadata[cadence].displayText}:{" "}
            </Label>
            <TooltipHost content={CadenceMetadata[cadence].tooltip()}>
                <Icon iconName="info" className="slaInfoIcon" />
            </TooltipHost>
        </>
    );
};
