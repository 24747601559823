export const macHealthChartMTR: string = `
// macHealthChart - cluster('ehfuelinsights.westus2.kusto.windows.net').database('TenantInsights')
set query_results_cache_max_age = time(12h);
let currDate = toscalar(
    HealthyTenantScore_MacHealthDistribution
    | distinct ProcessDate
    | top 1 by ProcessDate desc);
let tenantLevel = "Tenant";
let app = "WXPON";
let cohort = "S500";
let mau = "1000";
let GetColor = (status: long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_MacHealthDistribution
| project
    Application=SubWorkload,
    Cohort,
    Color,
    Count,
    Level,
    MAU,
    ProcessDate
| where Application == app
    and Cohort == cohort
    and Level == tenantLevel
    and MAU == mau
| extend Current = (ProcessDate == currDate)
| project ProcessDate, Color=GetColor(Color), Count, Current
| order by ProcessDate asc, Color asc`;
