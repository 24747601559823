import React, { useState } from "react";
import "./style.css";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { logUsageMetricsTrendChart } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";
import { formatDate, formatDisplayDate, addMonth } from "utils/Helpers";

export const ChartModal = ({
    handleClose,
    show,
    data,
    appsList,
    useMonthlyIntervals = true,
}) => {
    const list = [];
    appsList.forEach((app) => {
        list.push({ color: appStyles[app]["color"], value: app, key: app });
    });

    const [appList, setAppList] = useState(list);

    const closeChart = () => {
        setAppList(list);
        handleClose();
    };

    if (!show || !data) {
        return null;
    }

    let _data = [];
    const x = {};

    for (let i = 0; i < data.rows.length; i++) {
        const date = data.rows[i][0];
        if (!x[date]) {
            x[date] = { Date: useMonthlyIntervals ? formatDisplayDate(date) : date };
        }
        x[date][data.rows[i][1]] = data.rows[i][2];
    }
    if (useMonthlyIntervals) {
        let nextDate = formatDate(data.rows[0][0]);
        Object.keys(x).forEach((key) => {
            while (formatDate(key) !== nextDate) {
                _data.push({ Date: nextDate });
                nextDate = addMonth(nextDate, 1);
            }
            nextDate = addMonth(nextDate, 1);
            _data.push(x[key]);
        });
    } else {
        Object.keys(x).forEach((key) => {
            _data.push(x[key]);
        });
    }

    logUsageMetricsTrendChart(data.title);

    return (
        <div className="backdropStyle">
            <div className="modalStyle">
                <button className="close" type="button" onClick={closeChart}>
                    {" "}
                </button>
                <h2> {data.title}</h2>

                <div>
                    <TogglingLineChart
                        kind={togglingLineChartKind.values}
                        xDataKey="Date"
                        values={_data}
                        labels={appList}
                        width={500}
                        height={300}
                    />
                    <span
                        style={{
                            float: "right",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        *Click on legend items to toggle.
                    </span>
                </div>
            </div>
        </div>
    );
};
