import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { IDropdownOption, Pivot, PivotItem, Stack } from "@fluentui/react";
import { AppMetricFilters } from "components/CustomComponents/AppMetricFilters/AppMetricFilters";
import { DatePicker } from "components/CustomComponents/DatePicker/DatePicker";
import { ExemptIcon } from "components/CustomComponents/Icons/Icons";
import { ErrorMessageBar } from "components/CustomComponents/MessageBars/ErrorMessageBar";
import { ScoreStatus } from "components/CustomComponents/ScoreStatus/ScoreStatus";
import { Tabs } from "components/CustomComponents/Tabs/Tabs";
import TenantTitle from "components/CustomComponents/TenantTitle/TenantTitle";
import { ExemptionNoteTable } from "components/ExemptionPortalComponents/ExemptionNoteTable/ExemptionNoteTable";
import { ACEHealthScore } from "components/TenantDrilldownComponents/ACEHealth/ACEHealthScore";
import { CopilotTab } from "components/TenantDrilldownComponents/CopilotTab/CopilotTab";
import { M365AppsHealthWrapper } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365AppsHealthWrapper";
import { PSSChart } from "components/TenantDrilldownComponents/PSS/PSSChart";
import { TeamsMetrics } from "components/TenantDrilldownComponents/TeamsMetrics/TeamsMetrics";
import { TenantEnvironmentWrapper } from "components/TenantDrilldownComponents/TenantEnvironment/TenantEnvironmentWrapper";
import { TenantTouchPoints } from "components/TenantDrilldownComponents/TenantTouchPoints/TenantTouchPoints";
import { getCoreHealthMetricTemplate } from "api/commonApiHandler";
import Tile from "components/CustomComponents/Tile";
import { TenantList } from "components/TenantDrilldownComponents/TenantList";
import { TenantMetrics } from "components/TenantDrilldownComponents/TenantMetrics";
import {
    getConfig,
    PlatformConfigType,
    platformsConfig,
    TenantTitleCohortTags,
    Workload,
} from "config/PlatformConfig";
import "pages/common.css";
import {
    chartSizes,
    commonPageBlock,
    commonPageStyle,
    equalStackItemsStyle,
    horizontalStackTokens,
    searchCategoriesStyle,
} from "pages/common";
import { CommonProps, WorkloadMetricFilterState } from "pages/commonTypes";
import "pages/Tenants/style.css";
import {
    loadChartMetrics,
    loadTenantCoreMetrics,
    loadTenantMetrics,
    loadTenants,
} from "pages/InProductDrillDown/ApiHandler";
import {
    getProductInsightsAppList,
    getTenantMetadataTemplate,
    getWorkloadMetricFiltersInitialState,
    getTenantDrillDownPageTitle,
} from "pages/InProductDrillDown/InProductDrillDownHelper";
import "pages/InProductDrilldown/style.css";
import AppInsights from "utils/AppInsights";
import {
    logFilterUsage,
    logUsageOfAppView,
    logLevelBasedView,
} from "utils/AppInsightsHelper";
import { defaultWorkloadAppListIndex, TenantMetricsTabs } from "utils/Constants";
import { computeQueryParams, extractQueryParams } from "utils/Helpers";
import { registerTenantsViewIcons } from "utils/IconRegistration";
import { AppMetricsLinkMapping, InsightsRoutes } from "utils/Links";
import * as TYPES from "utils/types";

const getWorkloadinitialState = (platform: string, subWorkload: string) => {
    const initialState = {
        tenantList: {
            headers: {},
            rows: [],
            loading: true,
        },
        officeTenantScore: {
            score: 0,
            status: "",
            momChange: 0,
            pastStatus: [],
            scoreBreakDown: [],
        },
        dates: [],
        chartData: {},
        apps: getProductInsightsAppList(platform),
    };
    const tenantMetrics = {
        coreHealth: getCoreHealthMetricTemplate(platform, subWorkload),
        metadata: getTenantMetadataTemplate(),
        coreHealthScore: null,
        coreHealthColor: "Gray",
        sparkLineScores: [],
        others: [],
        loading: true,
        loadingCoreMetrics: true,
        notes: [],
    };
    return { ...initialState, tenantMetrics };
};

const InProductDrillDown = (props: CommonProps) => {
    let queryParamsUrl = "";
    const platform = props.platform;
    const workloadConfig = getConfig(platform);
    const initialMount = useRef(true);

    const pageTitle = getTenantDrillDownPageTitle(platform);

    registerTenantsViewIcons();
    const [filters, setFilters] = useState<TYPES.WorkloadTenantFilterState>({
        rankOn: "",
        level: "",
    });

    const [data, setData] = useState<TYPES.WorkloadTenantState>(
        getWorkloadinitialState(platform, filters.rankOn),
    );
    const [crossAppRankOn, setCrossAppRankOn] = useState<string>("");

    const [metricFilters, setMetricFilters] = useState<WorkloadMetricFilterState>(
        getWorkloadMetricFiltersInitialState(platform),
    );

    const loadMetrics = () => {
        loadTenantMetrics(
            setData,
            filters,
            metricFilters,
            data.dates,
            crossAppRankOn,
            platform,
        );
    };

    const loadCoreMetrics = () => {
        loadTenantCoreMetrics(setData, filters, metricFilters, data.dates, platform);
    };

    const loadTenantList = () => {
        if (initialMount.current) {
            initialMount.current = false;
            return;
        }

        loadTenants(
            data,
            setData,
            filters,
            metricFilters,
            setMetricFilters,
            setFilters,
            crossAppRankOn,
            platform,
        );
    };

    const recomputeUrl = () => {
        const queryParamsFromUrl = extractQueryParams();
        const queryParamFilters = initialMount.current
            ? { ...filters, ...queryParamsFromUrl }
            : { ...queryParamsFromUrl, ...filters };
        if (metricFilters["Date"] !== "")
            queryParamFilters["date"] = metricFilters["Date"];
        queryParamsUrl = computeQueryParams(queryParamFilters);
        window.history.pushState(
            "",
            "",
            window.location.pathname + computeQueryParams(queryParamFilters),
        );
    };

    useEffect(() => {
        document.title = pageTitle;
        AppInsights.getInstance().TrackPage(document.title);
        const queryParams = extractQueryParams();
        if (!isEmpty(queryParams)) {
            const newFilters = { ...filters };
            const newMetricFilters = { ...metricFilters };
            setCrossAppRankOn(
                workloadConfig.crossAppsList.includes(queryParams["rankOn"])
                    ? queryParams["rankOn"]
                    : workloadConfig.defaults.app,
            );
            newFilters["rankOn"] =
                "rankOn" in queryParams &&
                workloadConfig.appsList.includes(queryParams["rankOn"])
                    ? queryParams["rankOn"]
                    : platformsConfig[Workload.WEB].appsList[
                          defaultWorkloadAppListIndex
                      ];
            newFilters["level"] =
                "level" in queryParams ? queryParams["level"] : "TenantId";
            newMetricFilters["Date"] =
                "date" in queryParams ? queryParams["date"] : metricFilters["Date"];
            if ("id" in queryParams) {
                newFilters["id"] = queryParams["id"];
            }
            setMetricFilters(newMetricFilters);
            setFilters(newFilters);
            return;
        }

        setFilters({
            rankOn: platformsConfig[Workload.WEB].appsList[
                defaultWorkloadAppListIndex
            ],
            level: "TenantId",
        });
        setCrossAppRankOn(workloadConfig.defaults.app);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        recomputeUrl();
        loadTenantList();
        loadCoreMetrics();
        // ADO 7955411: useCallback refactor on method dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.rankOn]);

    useEffect(() => {
        recomputeUrl();
        loadMetrics();
        // ADO 7955411: useCallback refactor on method dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricFilters]);

    // TODO: should we add other distribution as well and set to false.

    const onChangeDateSelection = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        if (option) {
            const newMetricFilters = { ...metricFilters };
            newMetricFilters["Date"] = String(option["key"]);
            setMetricFilters(newMetricFilters);
            logFilterUsage(document.title, "Date", String(option["key"]));
        }
    };

    const onAppTabClick = (item?: PivotItem) => {
        setFilters((filters) => {
            return {
                ...filters,
                rankOn: item.props.itemKey,
            };
        });
        logLevelBasedView(document.title, filters["level"]);
        logUsageOfAppView(document.title, item.props.itemKey);
    };

    const onTenantMetricsFilterChange = (dropdownFor: string) => {
        return (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            const newMetricFilters = { ...metricFilters };
            newMetricFilters[dropdownFor] = item.key.toString();
            setMetricFilters(newMetricFilters);
        };
    };

    const linkForMetric = (metric: string) => {
        const metricLink = data.tenantMetrics.coreHealth[metric]["Link"];
        if (!(metricLink in AppMetricsLinkMapping)) return null;
        if (
            AppMetricsLinkMapping[metricLink].disabled ||
            (filters["level"] === "Tpid" &&
                AppMetricsLinkMapping[metricLink].tpidDisabled)
        )
            return null;

        const params: TYPES.LinkParameters = {
            id: filters["id"],
            level: filters["level"],
            date: metricFilters["Date"],
        };

        return {
            href: AppMetricsLinkMapping[metricLink].getMetricUrl(params),
            toolTip: AppMetricsLinkMapping[metricLink].toolTip,
        };
    };

    const platformConfig: PlatformConfigType = getConfig(platform);
    const isCopilotFeatureEnabled: boolean =
        platformConfig.featureConfig?.InProductDrillDown["isCopilotEnabled"];

    // Date must be 28th of the month, otherwise no data will display
    const isDateValid = (date: string): boolean => {
        if (platform === Workload.WEB || platform === Workload.TEAMS) {
            return date.endsWith("01");
        }

        return date.endsWith("28");
    };

    return (
        <>
            {metricFilters["Date"] !== "" && !isDateValid(metricFilters["Date"]) && (
                <ErrorMessageBar
                    message={`No ${platform} data available for ${
                        metricFilters["Date"]
                    }. Please select a valid Time Period below or update the URL date to the ${
                        platform === Workload.WEB || platform === Workload.TEAMS
                            ? "1st"
                            : "28th"
                    } of the month.`}
                    refresh={false}
                />
            )}
            <Stack horizontal disableShrink styles={commonPageStyle}>
                <Stack.Item styles={searchCategoriesStyle}>
                    <TenantList
                        payload={data.tenantList.rows}
                        filters={{ ...filters }}
                        metricFilters={{ ...metricFilters }}
                        getTenantMetrics={(filters, metricFilters) => {
                            setFilters(filters);
                            setMetricFilters(metricFilters);
                        }}
                        queryParamsUrl={
                            queryParamsUrl.length !== 0
                                ? `&${queryParamsUrl.substring(1)}`
                                : ""
                        }
                        loading={data.tenantList.loading}
                        platform={platform}
                        updateUrl={true}
                    />
                </Stack.Item>
                <Stack.Item className="customerProductInsightsPageContentStyle">
                    <Stack.Item>
                        <div className="orgHeader">
                            <TenantTitle
                                metadata={data.tenantMetrics.metadata}
                                level={filters["level"]}
                                showAdditional
                                loading={data.tenantMetrics.loading}
                                tenantStatus={data.officeTenantScore.status}
                                platform={platform}
                                showTenantStatus={
                                    platformsConfig[platform].showTenantStatus
                                }
                                cohortTags={TenantTitleCohortTags}
                            />
                        </div>
                    </Stack.Item>
                    <Stack horizontal className="filterPanel">
                        <div className="timePeriod">
                            <DatePicker
                                dateOptions={data.dates}
                                onChangeSelection={onChangeDateSelection}
                                selected={metricFilters["Date"]}
                            />
                        </div>
                        {filters["level"] === "TenantId" ? (
                            <a
                                href={`${InsightsRoutes.SummarizedInsightsRequest.path}?TenantId=${filters["id"]}`}
                                className="summarizedInsightsButton"
                            >
                                {" "}
                                Request Summarized Insights 🔗
                            </a>
                        ) : (
                            <div> </div>
                        )}
                    </Stack>
                    <Stack.Item>
                        {platform !== Workload.TEAMS && (
                            <ScoreStatus
                                score={data.officeTenantScore.score}
                                application="All"
                                status={data.officeTenantScore.status}
                                statusTrend={data.officeTenantScore["pastStatus"]}
                                prefixText={
                                    filters["level"] === "Tpid"
                                        ? "TopParent Health"
                                        : "Tenant Health"
                                }
                                momScore={data.officeTenantScore.momChange}
                                loading={data.tenantMetrics.loading}
                                scoreBreakDown={
                                    data.officeTenantScore.scoreBreakDown
                                }
                            />
                        )}
                        {data.tenantMetrics.metadata["exemptionNote"] &&
                            data.tenantMetrics.metadata["exemptionNote"][
                                "IsExempted"
                            ] && (
                                <ExemptIcon
                                    note={
                                        data.tenantMetrics.metadata["exemptionNote"]
                                    }
                                />
                            )}
                    </Stack.Item>
                    <div className="break" />
                    <h2 className="customerMetricGroupingTitle">
                        Core Health Metrics
                    </h2>
                    <Tabs
                        defaultSelectedTab={filters["rankOn"]}
                        onTabClick={onAppTabClick}
                    >
                        {platformsConfig[platform].appsList.map((app) => (
                            <Stack key={app}>
                                <Stack.Item>
                                    <>
                                        <ScoreStatus
                                            score={
                                                data.tenantMetrics.metadata["score"]
                                            }
                                            application={app}
                                            status={
                                                data.tenantMetrics.metadata["status"]
                                            }
                                            statusTrend={
                                                data.tenantMetrics.metadata[
                                                    "pastStatusSet"
                                                ]
                                            }
                                            loading={
                                                data.tenantMetrics
                                                    .loadingCoreMetrics ||
                                                data.tenantMetrics.loading
                                            }
                                            prefixText={"Health"}
                                            momScore={
                                                data.tenantMetrics.metadata[
                                                    "momScore"
                                                ]
                                            }
                                        />
                                    </>
                                    <div className="coreHealthFilters">
                                        Filters - Application - {app}
                                    </div>
                                    <ul
                                        className={`workloadTilesMetrics corePlatformTiles${platform}`}
                                    >
                                        {Object.keys(
                                            data.tenantMetrics.coreHealth,
                                        ).map((metric, i) => {
                                            return (
                                                <Tile
                                                    data={
                                                        data.tenantMetrics
                                                            .coreHealth[metric]
                                                    }
                                                    key={i}
                                                    sparkLineScores={
                                                        data.tenantMetrics
                                                            .sparkLineScores
                                                            ? data.tenantMetrics
                                                                  .sparkLineScores
                                                            : []
                                                    }
                                                    loading={
                                                        data.tenantMetrics
                                                            .loadingCoreMetrics ||
                                                        data.tenantMetrics.loading
                                                    }
                                                    link={linkForMetric(metric)}
                                                />
                                            );
                                        })}
                                    </ul>
                                </Stack.Item>
                                <div className="break" />

                                <h2 className="customerMetricGroupingTitle">
                                    Tenant Insights
                                </h2>
                                <Pivot linkSize={"large"}>
                                    <PivotItem
                                        itemKey={TenantMetricsTabs.AppMetrics}
                                        headerText={TenantMetricsTabs.AppMetrics}
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <AppMetricFilters
                                                metricFilters={metricFilters}
                                                platform={platform}
                                                onFilterChange={
                                                    onTenantMetricsFilterChange
                                                }
                                            />
                                            <Stack.Item>
                                                <TenantMetrics
                                                    payload={
                                                        data.tenantMetrics.others
                                                    }
                                                    getChartMetrics={(
                                                        metric,
                                                        display,
                                                        level,
                                                        orgKey,
                                                        metricFilters,
                                                    ) =>
                                                        loadChartMetrics(
                                                            metric,
                                                            display,
                                                            level,
                                                            orgKey,
                                                            metricFilters,
                                                            setData,
                                                            platform,
                                                        )
                                                    }
                                                    chartData={data.chartData}
                                                    filters={filters}
                                                    metricFilters={metricFilters}
                                                    loading={
                                                        data.tenantMetrics.loading
                                                    }
                                                    platform={platform}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        {platformsConfig[platform]?.featureConfig
                                            ?.InProductDrillDown[
                                            "isTeamsMetricsEnabled"
                                        ] && (
                                            <Stack styles={commonPageBlock}>
                                                <TeamsMetrics
                                                    app={filters.rankOn}
                                                    filters={filters}
                                                    metricFilters={metricFilters}
                                                />
                                            </Stack>
                                        )}
                                    </PivotItem>

                                    <PivotItem
                                        itemKey={TenantMetricsTabs.TenantEnvironment}
                                        headerText={
                                            TenantMetricsTabs.TenantEnvironment
                                        }
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <TenantEnvironmentWrapper
                                                platform={platform}
                                                filters={filters}
                                                metricFilters={metricFilters}
                                            />
                                        </Stack>
                                    </PivotItem>

                                    <PivotItem
                                        itemKey={TenantMetricsTabs.Engagements}
                                        headerText={TenantMetricsTabs.Engagements}
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <Stack
                                                horizontal
                                                tokens={horizontalStackTokens}
                                            >
                                                <Stack.Item
                                                    grow="initial"
                                                    styles={equalStackItemsStyle}
                                                >
                                                    <ACEHealthScore
                                                        filters={filters}
                                                        chartWidth={chartSizes.width}
                                                        chartHeight={
                                                            chartSizes.height
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item
                                                    grow="initial"
                                                    styles={equalStackItemsStyle}
                                                >
                                                    <PSSChart
                                                        filters={filters}
                                                        platform={platform}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                            <div className="break" />
                                            <Stack>
                                                <TenantTouchPoints
                                                    filters={filters}
                                                />
                                            </Stack>
                                            <div className="break" />
                                            <Stack>
                                                <ExemptionNoteTable
                                                    payload={
                                                        data.tenantMetrics.notes
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                    </PivotItem>
                                    <PivotItem
                                        itemKey={TenantMetricsTabs.M365AppsHealth}
                                        headerText={TenantMetricsTabs.M365AppsHealth}
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <M365AppsHealthWrapper
                                                platform={platform}
                                                level={filters.level}
                                                id={filters["id"]}
                                                date={metricFilters.Date}
                                            />
                                        </Stack>
                                    </PivotItem>
                                    {isCopilotFeatureEnabled && (
                                        <PivotItem
                                            itemKey={TenantMetricsTabs.Copilot}
                                            headerText={TenantMetricsTabs.Copilot}
                                        >
                                            <Stack styles={commonPageBlock}>
                                                <CopilotTab
                                                    platform={platform}
                                                    level={filters.level}
                                                    id={filters["id"]}
                                                    app={app}
                                                    isCopilot={
                                                        data.tenantMetrics.metadata
                                                            ?.cohorts?.isCopilot
                                                    }
                                                />
                                            </Stack>
                                        </PivotItem>
                                    )}
                                </Pivot>
                            </Stack>
                        ))}
                    </Tabs>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default InProductDrillDown;
