import React, { useState, useEffect } from "react";
import {
    SelectionMode,
    IColumn,
    ScrollablePane,
    ScrollbarVisibility,
    ConstrainMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { NO_DATA_MESSAGES } from "utils/Messages";
import { AddInsTableProps, AddInsTableState } from "../../pages/AddIns/types";

const renderItemColumn = (item, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName] as string;
    switch (column.key) {
        case "Reliability":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    postfix="%"
                    decimalPrecision={2}
                />
            );
        case "Crashes":
        case "ImpactedTenants":
        case "UnsuccessfulLoads":
        case "DevicesWithAddIn":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    postfix=""
                    decimalPrecision={0}
                />
            );
        case "Rank":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    postfix=""
                    decimalPrecision={2}
                />
            );
        case "TypicalLoadDurationPerSession":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    postfix="s"
                    decimalPrecision={2}
                />
            );
        default:
            return <DetailsListHelper.TextValue value={fieldContent} />;
    }
};

export const AddInTable = ({
    rows,
    headers,
    loading,
    message,
}: AddInsTableProps) => {
    const [state, setState] = useState<AddInsTableState>({
        dataRows: [],
        columns: [],
    });

    useEffect(() => {
        setState({
            dataRows: rows,
            columns: headers,
        });
    }, [rows, headers]);

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(
            state.columns,
            column,
            state.dataRows,
        );
        setState({
            dataRows: result.items,
            columns: result.columns,
        });
    };

    return (
        <div>
            <div
                data-is-scrollable
                className="pageSection addInTableHeight"
                data-testid="AddInTable"
            >
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsListWrapper
                        items={state.dataRows}
                        message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                        columns={state.columns}
                        enableShimmer={loading}
                        selectionMode={SelectionMode.none}
                        compact
                        onRenderDetailsHeader={DetailsListHelper.renderDetailsHeader}
                        onRenderItemColumn={renderItemColumn}
                        onColumnHeaderClick={onColumnClick}
                        onRenderRow={DetailsListHelper.renderExpandableRow}
                        styles={DetailsListHelper.tenantTableStyle}
                        constrainMode={ConstrainMode.unconstrained}
                    />
                </ScrollablePane>
            </div>
            <span className="addInSubScriptStyle">{message}</span>
        </div>
    );
};
