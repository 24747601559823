import React from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Text,
} from "recharts";
import { Stack } from "@fluentui/react";
import { mtrCustomTooltipFunction } from "pages/MTR/MTRUtils";
import CustomToolTip from "components/CustomComponents/CustomToolTip";
import { drilldownChartHeight, chartMargin } from "pages/common";
import { SignalTrendChartData } from "pages/MTR/types";
import { STATUS } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";

export const SignalTrendChart = ({ data }: { data: SignalTrendChartData[] }) => {
    return (
        <Stack>
            <p className="commonPageBlockTitle">{data[0].Key}</p>
            <AreaChart
                data={data}
                width={450}
                height={drilldownChartHeight}
                margin={chartMargin}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" tickFormatter={formatDisplayDate} />
                <YAxis label={<CustomizedLabel />} />
                <Tooltip
                    content={
                        <CustomToolTip
                            props={data}
                            customToolTipContentFunction={mtrCustomTooltipFunction}
                        />
                    }
                />
                {Object.keys(STATUS).map((key) => (
                    <Area
                        name={STATUS[key]["name"]}
                        type="monotone"
                        dataKey={key}
                        stackId="1"
                        stroke={STATUS[key]["color"]}
                        fill={STATUS[key]["color"]}
                        key={key}
                    />
                ))}
            </AreaChart>
        </Stack>
    );
};

const CustomizedLabel = () => {
    return (
        <Text dx={-175} dy={15} transform="rotate(-90)">
            # Tenants
        </Text>
    );
};
