import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import * as API from "api";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { MonthlyTenantEnvironmentChart } from "components/TenantDrilldownComponents/TenantEnvironment";
import { Workload } from "config/PlatformConfig";
import { equalStackItemsStyle } from "pages/common";
import { ConsumerAppMetricsProps } from "pages/ConsumerProductDrillDown/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

interface LicenseState {
    payload: {
        [key: string]: {
            [key: string]: { percentage: number; value: number };
        };
    };
    loading: boolean;
}

const initialState: LicenseState = {
    payload: {},
    loading: true,
};

export const formatLicenseData = (LicenseJson) => {
    const licenseTable = LicenseJson.Tables[0];

    let total = 0;
    const license = {};
    // schema - Date, Metric, Value
    licenseTable.Rows.forEach((element) => {
        const date = element[0];
        const lic = element[1];
        const val = element[2];

        if (!(date in license)) license[date] = {};
        total += val;
        license[date][lic] = {
            value: val,
        };
    });

    Object.keys(license).forEach((date) => {
        Object.keys(license[date]).forEach((lic) => {
            license[date][lic]["percentage"] = Number(
                ((license[date][lic]["value"] * 100) / total).toFixed(2),
            );
        });
    });

    return license;
};

export const getLicenseResponse = async (params: {}) => {
    const queryParams = { ...params };

    const licenseResponse = await API.getKustoResponse({
        queryName: "webconsumerLicense",
        platform: Workload.WEB_CONSUMER,
        queryParams,
    }).catch((error) => {
        return Promise.reject(error);
    });

    return licenseResponse;
};

export const ConsumerLicense = ({
    ConsumerDrilldownFilters,
}: ConsumerAppMetricsProps) => {
    const [data, setData] = useState<LicenseState>(initialState);

    useEffect(() => {
        const getPayloadData = async () => {
            setData((data) => {
                return {
                    ...data,
                    loading: true,
                };
            });

            try {
                const response = await getLicenseResponse({
                    application: ConsumerDrilldownFilters.application,
                    cohortName: ConsumerDrilldownFilters.cohortName,
                    cohortType: ConsumerDrilldownFilters.cohortType,
                });
                const formattedResponse = formatLicenseData(response?.data);

                setData({
                    payload: formattedResponse,
                    loading: false,
                });
            } catch (err) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerLicenseMetricsFetchFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer License Metrics",
                    },
                    err,
                );
                setData({
                    payload: {},
                    loading: false,
                });
            }
        };

        getPayloadData();
    }, [
        ConsumerDrilldownFilters.application,
        ConsumerDrilldownFilters.cohortName,
        ConsumerDrilldownFilters.cohortType,
    ]);

    return (
        <>
            <h2 className="commonPageBlockTitle">License Distribution</h2>
            {Object.keys(data.payload).length === 0 ? (
                <EmptyTableContainer />
            ) : (
                <Stack>
                    <div className="coreHealthFilters">
                        {`Filters - Host: OneDriveWOPI`}
                    </div>
                    <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                        <MonthlyTenantEnvironmentChart
                            displayName=""
                            payload={data.payload}
                            kind={togglingLineChartKind.percentagesWithValues}
                            loading={data.loading}
                        />
                    </Stack.Item>
                </Stack>
            )}
        </>
    );
};
