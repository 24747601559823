import React from "react";
import { Icon } from "@fluentui/react";
import { appIconStyle, miniAppIconStyle } from "utils/commonStyles";
import { appStyles } from "utils/Constants";

export const ApplicationTitle = ({ app }) => {
    return (
        <div style={{ display: "inline-flex" }}>
            <Icon iconName={appStyles[app]["iconName"]} className={appIconStyle} />
            <h2 style={{ fontWeight: 400, margin: "10px" }}> {app} </h2>
        </div>
    );
};

export const ApplicationIcon = ({ app }) => {
    return <Icon iconName={appStyles[app]["iconName"]} className={appIconStyle} />;
};

export const MiniApplicationTitle = ({ icon, score }) => {
    return (
        <div style={{ display: "inline-flex" }}>
            <Icon iconName={icon} className={miniAppIconStyle} />
            &nbsp;
            {score}
        </div>
    );
};
