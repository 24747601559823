import React, { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import {
    DatePicker,
    IDatePickerStyles,
    defaultDatePickerStrings,
    IDatePickerStrings,
    ICalendarProps,
} from "@fluentui/react";
import {
    CadenceFrequency,
    CadenceLabel,
} from "components/CustomComponents/CadenceLabel/CadenceLabel";
import { addDays, format } from "date-fns";
import "components/CustomComponents/NavigableDatePicker/style.css";
import { getDisabledDatesWithinRange } from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendarHelper";

export interface NavigableDatePickerWithCalendarProps {
    backTitle: string;
    forwardTitle: string;
    dateOptions: string[];
    onDateChange: (date: string) => void;
    dateFilter: string;
    cadence?: CadenceFrequency;
    showGoToToday?: boolean;
}

export const NavigableDatePickerWithCalendar = ({
    backTitle,
    forwardTitle,
    dateOptions,
    onDateChange,
    dateFilter,
    cadence = CadenceFrequency.R28D,
    showGoToToday,
}: NavigableDatePickerWithCalendarProps) => {
    const datePickerStyles: Partial<IDatePickerStyles> = {
        root: [
            {
                maxWidth: 300,
                selectors: {
                    ".ms-TextField-field": { fontSize: 12, fontWeight: 600 },
                    ".ms-TextField-fieldGroup": { borderRadius: 14, border: "none" },
                },
            },
        ],
        textField: { width: 110 },
        statusMessage: { margin: 0 },
    };
    const formattedDates: Date[] = dateOptions.map((date) =>
        addDays(new Date(date), 1),
    );
    const minDate = new Date(Math.min.apply(null, formattedDates));
    const maxDate = new Date(Math.max.apply(null, formattedDates));

    const selectedDate: Date = new Date(dateFilter);
    const isValidDate: boolean =
        selectedDate && !Number.isNaN(selectedDate.valueOf());
    const strings: IDatePickerStrings = { ...defaultDatePickerStrings };
    const calendarDayProps: ICalendarProps = {
        restrictedDates: getDisabledDatesWithinRange(
            minDate,
            maxDate,
            formattedDates,
        ),
    };
    const [selectedDateIndex, setDateIndex] = useState<number>(() => {
        if (isValidDate) {
            return dateOptions.indexOf(format(selectedDate, `yyyy-MM-dd`));
        }

        return 0;
    });

    useEffect(() => {
        const index = dateOptions.indexOf(dateFilter);
        setDateIndex(index > 0 ? index : 0);
    }, [dateOptions, dateFilter]);

    return !isValidDate ? (
        <></>
    ) : (
        <>
            <CadenceLabel cadence={cadence} />
            <IconButton
                iconProps={{ iconName: "ChevronLeftSmall" }}
                title={backTitle}
                ariaLabel={backTitle}
                disabled={selectedDateIndex === dateOptions.length - 1}
                onClick={() => {
                    setDateIndex(selectedDateIndex + 1);
                    onDateChange(dateOptions[selectedDateIndex + 1]);
                }}
            />
            <DatePicker
                value={addDays(selectedDate, 1)}
                onSelectDate={(date) => {
                    onDateChange(format(date, `yyyy-MM-dd`));
                }}
                calendarProps={calendarDayProps}
                styles={datePickerStyles}
                strings={strings}
                minDate={minDate}
                maxDate={maxDate}
                formatDate={(date) => format(date, `MMM dd yyyy`)}
                showGoToToday={showGoToToday ?? true}
            />
            <IconButton
                iconProps={{ iconName: "ChevronRightSmall" }}
                title={forwardTitle}
                ariaLabel={forwardTitle}
                data-testid="nextDateButton"
                disabled={selectedDateIndex === 0}
                onClick={() => {
                    setDateIndex(selectedDateIndex - 1);
                    onDateChange(dateOptions[selectedDateIndex - 1]);
                }}
            />
        </>
    );
};

export default NavigableDatePickerWithCalendar;
