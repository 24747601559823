import { AxisDomain } from "recharts/types/util/types";
import { formatNumberCompact } from "utils/Helpers";

export interface TogglingLineChartType {
    yUnit?: string;
    domain: AxisDomain;
    tooltipFormatter: Function;
    // eslint-disable-next-line no-empty-pattern
    dataMapper: ({}) => {};
}

// When dealing with percentagesWithValues or valuesWithPercentages (A with B),
// we will separate the percentage and value as separate properties in the
// chart's payload, with A having the original name (and appearing in the labels
// array), and B having the original name with "(value)" or "(percentage)"
// tacked on the end (and not appearing in the labels array).
const valueName = (name: string) => `${name}(value)`;
const percentageName = (name: string) => `${name}(%)`;

const passthroughMapper = (value: {}) => value;

const percentageWithValueMapper = (values: {
    [key: string]: { value: number; percentage: number } | string;
}): { [key: string]: number } =>
    Object.entries(values).reduce(
        (obj, [propName, propValue]) =>
            typeof propValue == "object"
                ? {
                      ...obj,
                      [propName]: propValue.percentage,
                      [valueName(propName)]: propValue.value,
                  }
                : { ...obj, [propName]: propValue },
        {},
    );

const valueWithPercentageMapper = (values: {
    [key: string]: { value: number; percentage: number } | string;
}): { [key: string]: number } =>
    Object.entries(values).reduce(
        (obj, [propName, propValue]) =>
            typeof propValue == "object"
                ? {
                      ...obj,
                      [propName]: propValue.value,
                      [percentageName(propName)]: propValue.percentage,
                  }
                : { ...obj, [propName]: propValue },
        {},
    );

const tooltipNumberFormat = new Intl.NumberFormat("en-US");

const valueTooltipFormatter = (value: number) => tooltipNumberFormat.format(value);

const valueAbbreviatedTooltipFormatter = (value: number) =>
    formatNumberCompact(value);

const percentageTooltipFormatter = (value: number) => {
    return `${value}%`;
};

const percentageWithValueTooltipFormatter = (
    percentage: number,
    name: string,
    props: { payload: {} },
) => {
    const value = props.payload[valueName(name)];
    return value
        ? `${percentage}% (${tooltipNumberFormat.format(value)})`
        : percentage;
};

const valueWithPercentageTooltipFormatter = (
    value: number,
    name: string,
    props: { payload: {} },
) => {
    const formattedValue = tooltipNumberFormat.format(value);
    const percentage = props.payload[percentageName(name)];
    return percentage ? `${formattedValue} (${percentage}%)` : formattedValue;
};

/**
 * The various kinds of TogglingLineCharts:
 * - values: The y-axis shows values that vary from 0 to some arbitrary number.
 *       Units (if any) are specified manually.
 * - percentages: Values passed in to the chart are interpreted as percentages.
 *       The y-axis goes from 0% to 100%.
 * - percentagesWithValues: Values passed into the chart are objects with
 *       "percentage" and "value" properties. The y-axis shows percentages,
 *       values are shown in tooltips.
 * - valuesWithPercentages: Values passed into the chart are objects with
 *       "percentage" and "value" properties. The y-axis shows values,
 *       percentages are shown in tooltips.
 */
export const togglingLineChartKind: {
    [kind in
        | "values"
        | "valuesAbbreviated"
        | "percentages"
        | "percentagesWithValues"
        | "valuesWithPercentages"]: TogglingLineChartType;
} = {
    values: {
        domain: [0, "auto"],
        dataMapper: passthroughMapper,
        tooltipFormatter: valueTooltipFormatter,
    },
    valuesAbbreviated: {
        domain: [0, "auto"],
        dataMapper: passthroughMapper,
        tooltipFormatter: valueAbbreviatedTooltipFormatter,
    },
    percentages: {
        yUnit: "%",
        domain: [0, 100],
        dataMapper: passthroughMapper,
        tooltipFormatter: percentageTooltipFormatter,
    },
    percentagesWithValues: {
        yUnit: "%",
        domain: [0, 100],
        dataMapper: percentageWithValueMapper,
        tooltipFormatter: percentageWithValueTooltipFormatter,
    },
    valuesWithPercentages: {
        domain: [0, "auto"],
        dataMapper: valueWithPercentageMapper,
        tooltipFormatter: valueWithPercentageTooltipFormatter,
    },
};
