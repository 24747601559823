import React, { useEffect, useState } from "react";
import { Dropdown, Label, Pivot, PivotItem, Stack } from "@fluentui/react";
import GrowthDrilldownTitle from "components/ConsumerGrowthDrilldownComponents/GrowthDrilldownTitle";
import NavigableDatePicker from "components/CustomComponents/NavigableDatePicker/NavigableDatePicker";
import { Tabs } from "components/CustomComponents/Tabs/Tabs";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { SegmentParametersMap } from "pages/ConsumerGrowthDrilldown/ConsumerGrowthDrilldownHelper";
import Tile from "components/CustomComponents/Tile";
import { TenantMetrics } from "components/TenantDrilldownComponents/TenantMetrics";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { Workload, platformsConfig } from "config/PlatformConfig";
import { commonPageBlock, commonPageStyle } from "pages/common";
import "pages/common.css";
import {
    fetchChartMetrics,
    fetchGrowthDrilldownCoreHealthData,
    formatDateOptions,
} from "pages/ConsumerGrowthDrilldown/ApiHandler";
import {
    ChartDataType,
    ConsumerGrowthDrilldownFilterState,
    ConsumerGrowthDrilldownState,
} from "pages/ConsumerGrowthDrilldown/types";
import "pages/Tenants/style.css";
import { logException } from "utils/AppInsightsHelper";
import { PivotSize } from "utils/Constants";
import { ConsumerGrowthDrilldownMetricsTabs } from "utils/ConsumerConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import { InsightsRoutes } from "utils/Links";

const ConsumerGrowthDrilldown = () => {
    const pageConfig = getPageConfig(DrilldownPages.GROWTHDRILLDOWN_CONSUMER);
    document.title = pageConfig.pageTitle;
    const filtersFromQueryParams = extractQueryParams();

    const initialFilterState: ConsumerGrowthDrilldownFilterState = {
        application: pageConfig.defaults.app,
        platform: "Web",
        segment: "OneDriveFreeNew",
        date: "",
        referrer: "All",
    };

    const initialCoreMetricsState: ConsumerGrowthDrilldownState = {
        loading: true,
        coreMetrics: {
            coreMetricsData: {},
            sparklinesData: [],
            appMetricsData: {},
            platform: Workload.WEB_CONSUMER_GROWTH,
        },
        dateOptions: [],
    };

    const [error, setError] = useState<string>(null);

    const [filters, setFilters] = useState<ConsumerGrowthDrilldownFilterState>(
        () => {
            const newFilters: ConsumerGrowthDrilldownFilterState = {
                ...initialFilterState,
                ...filtersFromQueryParams,
            };

            return newFilters;
        },
    );

    const [coreMetricsData, setCoreMetricsData] =
        useState<ConsumerGrowthDrilldownState>(initialCoreMetricsState);

    const [chartData, setChartData] = useState<ChartDataType>(null);

    useEffect(() => {
        const setDatesData = async (platform: string) => {
            try {
                if (coreMetricsData.dateOptions.length === 0) {
                    const dates = await formatDateOptions(platform);

                    setCoreMetricsData((oldDataState) => {
                        return { ...oldDataState, dateOptions: dates };
                    });
                }

                if (filters.date === "" && coreMetricsData.dateOptions.length > 0) {
                    const currentDate = coreMetricsData.dateOptions[0];

                    setFilters((oldFiltersState) => {
                        return { ...oldFiltersState, date: currentDate };
                    });
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ConsumerGrowthDrilldownDatesOrConfigFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching dates/table metadata for Consumer Growth Drilldown Page from Kusto",
                    },
                    error,
                );
                setError(error.message);
            }
        };

        setDatesData(Workload.WEB_CONSUMER_GROWTH);
    }, [coreMetricsData.dateOptions, filters.date]);

    useEffect(() => {
        const getData = async () => {
            if (filters.date === "") return;
            try {
                await fetchGrowthDrilldownCoreHealthData(
                    {
                        segment: filters.segment,
                        application: filters.application,
                        platform: filters.platform,
                        date: filters.date,
                        referrer: filters.referrer,
                    },
                    Workload.WEB_CONSUMER_GROWTH,
                    setCoreMetricsData,
                    setError,
                );
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ConsumerGrowthDrilldownDataFetchFailed",
                    ),
                    {
                        message: "Failed to get data for the Growth Drilldown page",
                    },
                    error,
                );
                setError(error.message);
            }
        };

        getData();
    }, [
        filters.segment,
        filters.platform,
        filters.date,
        filters.application,
        filters.referrer,
    ]);

    const onFilterChange = (newFilter: {}) => {
        const updatedFilter = { ...filters, ...newFilter };
        setUpDocumentUrl(updatedFilter, pageConfig.pageTitle);
        setFilters(updatedFilter);
    };

    const renderTile = (coreMetricsData, metric, i) => {
        const link = {
            href: `${InsightsRoutes.ConsumerCAMAU.path}?&date=${filters["date"]}&application=${filters["application"]}`,
            toolTip: "Click here to view more details about CAMAU",
        };
        return (
            <Tile
                data={coreMetricsData.coreMetrics.coreMetricsData[metric]}
                key={i}
                sparkLineScores={coreMetricsData.coreMetrics.sparklinesData ?? []}
                loading={coreMetricsData.loading}
                link={metric === "CAMAU" ? link : ""}
            />
        );
    };

    return (
        <>
            <Stack styles={commonPageStyle}>
                {error && <ErrorFallback message={error} />}
                <Stack.Item>
                    <div className="orgHeader">
                        <GrowthDrilldownTitle
                            segment={
                                SegmentParametersMap[filters.segment].displayName
                            }
                            loading={coreMetricsData.loading}
                        />
                    </div>
                </Stack.Item>
                <Stack
                    horizontal
                    className="filterPanel"
                    data-testid="growthDrilldownFilterId"
                >
                    <div className="timePeriod">
                        <NavigableDatePicker
                            backTitle={"Go to Previous Week"}
                            forwardTitle={"Go to Next Week"}
                            dates={coreMetricsData.dateOptions}
                            onDateChange={(newDate: string) =>
                                onFilterChange({ date: newDate })
                            }
                            selectedDate={filters.date}
                        />
                    </div>
                    <div className="dropdown">
                        <Label className="filterLabel">Segment:</Label>
                        <Dropdown
                            options={Object.keys(SegmentParametersMap).map(
                                (segmentName: string) => {
                                    return {
                                        text: SegmentParametersMap[segmentName]
                                            .displayName,
                                        key: segmentName,
                                    };
                                },
                            )}
                            onChange={(_event, option) => {
                                const selectedSegment = option.key as string;
                                const newFilters = { segment: selectedSegment };
                                if (
                                    selectedSegment !== "OneDriveFreeNew" &&
                                    selectedSegment !== "OdspConvergedFreeNew"
                                )
                                    newFilters["referrer"] = "All";

                                onFilterChange(newFilters);
                            }}
                            selectedKey={filters.segment}
                            className="filterDropdown segmentDropdownMinWidth"
                            dropdownWidth="auto"
                        />
                    </div>
                    <div className="dropdown">
                        <Label className="filterLabel">Referrer:</Label>
                        <Dropdown
                            options={
                                SegmentParametersMap[filters.segment].referrerOptions
                            }
                            onChange={(_event, option) => {
                                onFilterChange({ referrer: option.key as string });
                            }}
                            selectedKey={filters.referrer}
                            className="filterDropdown"
                            dropdownWidth="auto"
                        />
                    </div>
                </Stack>
                <Stack>
                    <h2 className="customerMetricGroupingTitle coreMetricsHeader">
                        Core Growth Metrics
                    </h2>
                    <Tabs
                        defaultSelectedTab={filters.application}
                        onTabClick={(item?: PivotItem) => {
                            onFilterChange({
                                application: item.props.itemKey as string,
                            });
                        }}
                    >
                        {platformsConfig[Workload.WEB_CONSUMER_GROWTH].appsList.map(
                            (app) => (
                                <Stack key={app}>
                                    <Stack.Item>
                                        <ul
                                            className={`workloadTilesMetrics corePlatformTilesWebConsumerGrowth`}
                                        >
                                            {Object.keys(
                                                coreMetricsData.coreMetrics
                                                    .coreMetricsData,
                                            ).map((metric, i) => {
                                                return renderTile(
                                                    coreMetricsData,
                                                    metric,
                                                    i,
                                                );
                                            })}
                                        </ul>
                                    </Stack.Item>
                                    <div className="break" />

                                    <h2 className="customerMetricGroupingTitle">
                                        Growth Insights
                                    </h2>
                                    <Pivot linkSize={PivotSize.large}>
                                        <PivotItem
                                            itemKey={
                                                ConsumerGrowthDrilldownMetricsTabs.AppMetrics
                                            }
                                            headerText={
                                                ConsumerGrowthDrilldownMetricsTabs.AppMetrics
                                            }
                                        >
                                            <Stack styles={commonPageBlock}>
                                                <Stack.Item>
                                                    <TenantMetrics
                                                        payload={
                                                            coreMetricsData
                                                                .coreMetrics
                                                                .appMetricsData
                                                        }
                                                        getChartMetrics={(
                                                            metric,
                                                            display,
                                                            _level,
                                                            _id,
                                                            metricFilters,
                                                        ) => {
                                                            fetchChartMetrics(
                                                                metric,
                                                                display,
                                                                metricFilters,
                                                                setChartData,
                                                                Workload.WEB_CONSUMER_GROWTH,
                                                            );
                                                        }}
                                                        chartData={chartData}
                                                        filters={{
                                                            ...filters,
                                                            rankOn: filters.application,
                                                            level: "",
                                                            id: "",
                                                        }}
                                                        metricFilters={filters}
                                                        loading={
                                                            coreMetricsData.loading
                                                        }
                                                        platform={
                                                            Workload.WEB_CONSUMER_GROWTH
                                                        }
                                                        useMonthlyIntervals={false}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </PivotItem>
                                    </Pivot>
                                </Stack>
                            ),
                        )}
                    </Tabs>
                </Stack>
            </Stack>
        </>
    );
};

export default ConsumerGrowthDrilldown;
