import React, { useEffect, useMemo, useState } from "react";
import {
    Stack,
    Label,
    Dropdown,
    IContextualMenuProps,
    DefaultButton,
    IIconProps,
} from "@fluentui/react";
import { DoraLink } from "components/CustomComponents/DoraConnector/DoraLink";
import { NavigableDatePickerWithCalendar } from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendar";
import {
    getAdditionalFilterLabels,
    getDoraDashboardLink,
} from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import { AdditionalFilterType } from "components/CommonFunnelComponents/CommonFunnel/types";
import {
    formatAdditionalFunnelFilterOptions,
    getAdditionalPivotsFilterOptions,
} from "components/CommonFunnelComponents/CommonFunnelFilter/ApiHandler";
import { getContextualMenuPropsIfAvailable } from "components/CommonFunnelComponents/CommonFunnelFilter/commonFunnelFilterHelper";
import {
    CommonFunnelFilterOptions,
    CommonFunnelFiltersPropsType,
} from "components/CommonFunnelComponents/CommonFunnelFilter/types";
import { FunnelFilter, getFunnelConfig } from "config/FunnelConfig";
import { logException } from "utils/AppInsightsHelper";
import "components/CommonFunnelComponents/CommonFunnelFilter/styles.css";
import { defaultExtraLargeDropDownWidth } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";

export const CommonFunnelFilterPanel = ({
    additionalFiltersToShow,
    dateOptions,
    filters,
    funnelType,
    onFilterChange,
    onAddFilter,
}: CommonFunnelFiltersPropsType) => {
    const { requiredFilters } = getFunnelConfig(funnelType);
    const addIcon: IIconProps = { iconName: "Add" };
    const [filterOptions, setFilterOptions] = useState<CommonFunnelFilterOptions>(
        {},
    );

    const menuProps: IContextualMenuProps = getContextualMenuPropsIfAvailable(
        funnelType,
        additionalFiltersToShow,
        onAddFilter,
    );

    const additionalFilterLabels: AdditionalFilterType = useMemo(
        () => getAdditionalFilterLabels(funnelType),
        [funnelType],
    );

    const getAdditionalFilters = (): JSX.Element[] => {
        let additionalFiltersArray = [];
        additionalFiltersToShow.forEach((item) => {
            additionalFiltersArray.push(
                <div className="additionalFilterDropdown" key={item}>
                    <Label className="additionalFilterLabel">
                        {additionalFilterLabels[item]}:
                    </Label>
                    <Dropdown
                        options={filterOptions[item]?.sort((a, b) =>
                            a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
                        )}
                        onChange={(_event, option) =>
                            onFilterChange(item, option.key as string)
                        }
                        selectedKey={
                            filters.additionalFilters &&
                            filters.additionalFilters[item]
                                ? filters.additionalFilters[item]
                                : ""
                        }
                        className="filterDropdown"
                        dropdownWidth={defaultExtraLargeDropDownWidth}
                    />
                    <div
                        aria-label="Remove Filter"
                        title="Remove Filter"
                        className="additionalFilterCancelIcon"
                        onClick={() => {
                            onAddFilter(item);
                        }}
                    >
                        {"X"}
                    </div>
                </div>,
            );
        });
        return additionalFiltersArray;
    };

    useEffect(() => {
        const getFilterOptions = async () => {
            try {
                if (additionalFilterLabels) {
                    const response = await getAdditionalPivotsFilterOptions(
                        funnelType,
                        filters.application,
                        filters.date,
                    );
                    const filterOptionsFormatted: CommonFunnelFilterOptions =
                        formatAdditionalFunnelFilterOptions(funnelType, response);
                    setFilterOptions(filterOptionsFormatted);
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchFunnelFilterOptionsException",
                    ),
                    {
                        message:
                            "Failed to fetch filter options for the given date.",
                    },
                    error,
                );
            }
        };
        getFilterOptions();
    }, [funnelType, filters.application, filters.date, additionalFilterLabels]);

    return (
        <Stack horizontal className="compressedFilterPanel">
            <div>
                <NavigableDatePickerWithCalendar
                    backTitle="Go to Previous Week"
                    forwardTitle="Go to Next Week"
                    dateOptions={dateOptions}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    dateFilter={filters.date}
                    cadence={filters["intervalWindow"]}
                />
            </div>
            {requiredFilters?.map((filter: FunnelFilter) => {
                return (
                    <div
                        key={`funnel-filter-${filter.filterKey}`}
                        className="dropdown-wide"
                    >
                        <Label className="filterLabel">{filter.label}:</Label>
                        <Dropdown
                            options={filter.options}
                            onChange={(_event, option) =>
                                onFilterChange(
                                    filter.filterKey,
                                    option.key as string,
                                )
                            }
                            selectedKey={filters[filter.filterKey]}
                            className="filterDropdown"
                            dropdownWidth={defaultExtraLargeDropDownWidth}
                        />
                    </div>
                );
            })}
            {menuProps && menuProps.items.length > 0 && (
                <>
                    <Stack horizontal wrap data-testid="additionalFilterDropdown">
                        <div className="additionalFiltersContainer">
                            {getAdditionalFilters()}
                        </div>
                    </Stack>
                    <Stack verticalAlign="center">
                        <DefaultButton
                            className="filterButton"
                            iconProps={addIcon}
                            menuProps={menuProps}
                            text="Filters"
                            aria-label="Add Additional Filters"
                            title="Add Additional Filters"
                        />
                    </Stack>
                </>
            )}
            {getDoraDashboardLink(funnelType) && (
                <DoraLink funnelType={funnelType} filters={filters}></DoraLink>
            )}
        </Stack>
    );
};
