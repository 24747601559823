import { scaleBand, scaleLinear } from "@visx/scale";
import { SeriesPoint } from "@visx/shape/lib/types";
import { getStageName } from "components/ConsumerDay0FunnelComponents/CompositeFunnel/CompositeFunnelHelper";
import {
    areaWidth,
    yMax,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/constants";
import { FlattenedScenarioDataType } from "pages/ConsumerDay0Funnel/types";

export const stackedAreaYScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 1],
});

export const getStackedAreaXScale = (payload: FlattenedScenarioDataType[]) => {
    return scaleBand<string>({
        range: [0, 2 * areaWidth],
        domain: payload.map(getStageName),
    });
};

export const getY0 = (dataPoint: SeriesPoint<unknown>) => {
    return Number.isNaN(dataPoint[0]) ? 0 : dataPoint[0] / 100;
};

export const getY1 = (dataPoint: SeriesPoint<unknown>) =>
    Number.isNaN(dataPoint[1]) ? 0 : dataPoint[1] / 100;
