import { IDropdownOption } from "@fluentui/react";
import { ERFMColumns } from "components/ERFMComponents/config";
import { ERFMTableColumnConfig } from "components/ERFMComponents/types";

export const ERFMColumnConfig: ERFMTableColumnConfig = {
    RFM: {
        level: 1,
        displayName: "RFM",
        title: "Reduced Functionality Mode",
        exportCSVHeaderName: "RFM",
    },
    ERFM: {
        level: 1,
        displayName: "eRFM",
        title: "Enhanced Reduced Functionality Mode",
        exportCSVHeaderName: "eRFM",
    },
    ERFM_AdsEnabled: {
        level: 2,
        displayName: "Ads Enabled",
        title: "eRFM with Ads Enabled",
        exportCSVHeaderName: "eRFM Ads Enabled",
    },
    ERFM_AdsDisabled: {
        level: 2,
        displayName: "Ads Disabled",
        title: "eRFM with Ads Disabled",
        exportCSVHeaderName: "eRFM Ads Disabled",
    },
    WebAllFree: {
        level: 1,
        displayName: "Web All Free",
        exportCSVHeaderName: "Web All Free",
    },
    WebPlusERFM: {
        level: 1,
        displayName: "Web All Free + eRFM",
        title: "Sum of All Web and All eRFM",
        exportCSVHeaderName: "Web All Free + eRFM",
    },
};

export const getSegmentOptions = (): IDropdownOption[] => {
    return Object.keys(ERFMColumnConfig).map((segment) => {
        return {
            key: segment,
            text: ERFMColumnConfig[segment].displayName,
        };
    });
};

export const getFlyoutMetricOptions = (): IDropdownOption[] => {
    return ERFMColumns.filter((column) => column.isFlyoutMetric).map((column) => {
        return {
            key: column.name,
            text: column.displayName,
        };
    });
};
