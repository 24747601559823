import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDisplayDate } from "utils/Helpers";

export const formatSecurityVulnerabilities = (
    securityVulnerabilitiesJson: KustoResponseType<string | number>,
) => {
    const securityVulnerabilities = {};
    securityVulnerabilitiesJson.Tables[0].Rows.forEach((element) => {
        const date = element[0] as string;
        const formattedDate = formatDisplayDate(date);
        if (!(formattedDate in securityVulnerabilities))
            securityVulnerabilities[formattedDate] = {};

        securityVulnerabilities[formattedDate]["AveragePerDevice"] = element[1];
        securityVulnerabilities[formattedDate]["TotalVulnerabilities"] = element[2];
    });

    const formattedVulnerabilities = [];
    Object.keys(securityVulnerabilities).forEach((date) => {
        formattedVulnerabilities.push({
            Date: date,
            ...securityVulnerabilities[date],
        });
    });

    return formattedVulnerabilities;
};

export const getSecurityVulnerabilities = async (
    filters,
): Promise<KustoResponseType<string>> => {
    let queryParams = {};
    const levelParams = API.getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams = { ...levelParams, ...queryParams };

    const securityVulnerabilitiesData = await API.getKustoResponse({
        queryName: "win32SecurityVulnerabilityHistory",
        platform: Workload.WIN32,
        queryParams,
    });

    return securityVulnerabilitiesData?.data;
};

export const getPayloadData = async (filters: {}, setData) => {
    setData((data) => {
        return {
            ...data,
            loading: true,
        };
    });

    try {
        const response: KustoResponseType<string | number> =
            await getSecurityVulnerabilities(filters);
        const formattedResponse = formatSecurityVulnerabilities(response);
        setData({
            payload: formattedResponse,
            loading: false,
        });
    } catch (error) {
        setData({
            payload: [],
            loading: false,
        });
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "SecurityVulnerabilitiesFetchOrProcessingFailed",
            ),
            {
                message: "Failed to fetch or format security vulnerabilities",
                filters,
            },
            error,
        );
    }
};
