export const macTenantStatus: string = `
//MacTenantStatus
let app = "\${application}";
let level = "\${level}";
let dt = "\${date}";
let cohort = "\${cohort}";
let Mau = \${mauCountThreshold};
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime( dt)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_MacAppView
    | where SubWorkload == app | where ProcessDate == dt or ProcessDate == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | join kind=inner 
        (StrategicTenants
        | where Date == toscalar(StrategicTenants|summarize max(Date))    
        | where iff(cohort == "Other", IsOther, Cohorts has cohort)
        | distinct \${levelColumnName}, iif(level == "Tpid", OrgName, TenantName)
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName} 
    | extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,
    App_Currency = Currency_SignalGroup.App_Currency, App_Currency_Color = Currency_SignalGroup.App_Currency_Color,
    CH_Rate = Reliability_SignalGroup.CH_Rate, CH_Color = Reliability_SignalGroup.CH_Color,
    FQ_Rate = Reliability_SignalGroup.FQ_Rate, FQ_Color = Reliability_SignalGroup.FQ_Color,
    PerfP95_Score = Performance_SignalGroup.Perf_P95, Perf_Color = Performance_SignalGroup.Perf_Color,
    PerfBoot_Score = Performance_SignalGroup.Boot_Threshold,PerfBoot_Color = Performance_SignalGroup.Boot_Threshold_Color,
    NPS = Feedback_SignalGroup.NPS, NPS_Color = Feedback_SignalGroup.NPS_Color,
    EngagedUsers_Score = Usage_SignalGroup.Engaged_Users, EngagedUsers_Color = Usage_SignalGroup.Engaged_Users_Color
    | project-rename Application = SubWorkload
    | where Color > 1
    | project \${levelColumnName}, Score, Color, MAU , OS_Currency, OS_Currency_Color, App_Currency, App_Currency_Color,
Date = ProcessDate, Application, Composite_Score = Score, CH_Color, FQ_Color, Perf_Color,PerfBoot_Color, NPS_Color, CH_Rate, FQ_Rate, PerfP95_Score,
PerfBoot_Score, NPS,EngagedUsers_Score,EngagedUsers_Color,PastStatus, Name);
Overview 
    | where MAU >= Mau
    | where Date == dt
    | extend CurrentStatus = Color
 | join kind= inner 
    (
        Overview
        | where Date == prev
        | extend PreviousStatus = Color
    )     
on \${levelColumnName}
| where CurrentStatus == 3 or CurrentStatus == 2 or PreviousStatus == 3 or PreviousStatus == 2
    | extend Composite_Score = round(Score,2) 
    | extend PerfBoot_Score_Round = round(todouble(PerfBoot_Score),2)
    | extend Composite_MoMChange = round((todouble(Score-Score1)/Score1)*100,2)
    | extend MAU_MoMChange = round((todouble(MAU-MAU1)/MAU1)*100,2) | extend MAU_Score = MAU
    | extend OS_Currency_MoMChange = round((todouble(todouble(OS_Currency) - todouble(OS_Currency1))/todouble(OS_Currency1))*100, 2)
    | extend App_Currency_MoMChange = round((todouble(todouble(App_Currency) - todouble(App_Currency1))/todouble(App_Currency1))*100, 2)
    | extend NPS_Score_MoMChange = round((todouble(todouble(NPS) - todouble(NPS1))/todouble(NPS1))*100, 2)
    | extend PerfP95_MoMChange = round((todouble(todouble(PerfP95_Score) - todouble(PerfP95_Score1))/todouble(PerfP95_Score1))*100, 2)
    | extend PerfBoot_MoMChange = round((todouble(todouble(PerfBoot_Score) - todouble(PerfBoot_Score1))/todouble(PerfBoot_Score1))*100, 2)
    | extend CH_Score_MoMChange = round((todouble(todouble(CH_Rate) - todouble(CH_Rate1))/todouble(CH_Rate1))*100, 2)
    | extend FQ_Score_MoMChange = round((todouble(todouble(FQ_Rate) - todouble(FQ_Rate1))/todouble(FQ_Rate1))*100, 2)
    | extend EngagedUsers_MoMChange = round((round((100*todouble(EngagedUsers_Score))/todouble(MAU),2) - round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2))/round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2)*100, 2)
    | project \${level} = \${levelColumnName}, OrgName = Name,
    Composite_Score, Composite_MoMChange, 
    MAU_Score, MAU_MoMChange,
    EngagedUsers_Score = round((100*todouble(EngagedUsers_Score))/todouble(MAU),2), EngagedUsers_Score_Color = GetColor(EngagedUsers_Color),EngagedUsers_MoMChange,
    PerfP95_Score, PerfP95_Score_Color = GetColor(Perf_Color), PerfP95_MoMChange,
    PerfBoot_Score = PerfBoot_Score_Round,PerfBoot_Score_Color =GetColor(PerfBoot_Color), PerfBoot_MoMChange,
    CH_Score = iff(isnotnull(CH_Rate), CH_Rate, 0), CH_Score_Color = GetColor(CH_Color), CH_Score_MoMChange,
    FQ_Score = iff(isnotnull(FQ_Rate), FQ_Rate, 0), FQ_Score_Color = GetColor(FQ_Color), FQ_Score_MoMChange,
    NPS_Score = iff(isnotnull(NPS), NPS, 0), NPS_Score_Color = GetColor(iff(isnotnull(NPS_Color), NPS_Color, 0)), NPS_Score_MoMChange,
    OS_Currency_Score = iff(isnotnull(OS_Currency), OS_Currency, 0) , OS_Currency_Score_Color = GetColor(OS_Currency_Color), OS_Currency_MoMChange,
    App_Currency_Score = iff(isnotnull(App_Currency), App_Currency, 0), App_Currency_Score_Color = GetColor(App_Currency_Color), App_Currency_MoMChange, 
    PastStatus, CurrentStatus = GetColor(CurrentStatus), PreviousStatus = GetColor(PreviousStatus)
    `;
