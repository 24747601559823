import React from "react";
import { Icon } from "@fluentui/react";
import "components/ConsumerDay0FunnelComponents/CompositeFunnelStatusBar/style.css";
import {
    DefaultSelectedFunnel,
    DefaultSelectedScenerio,
    DefaultSelectedReferrer,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { CompositeFunnelStatusBarProps } from "components/ConsumerDay0FunnelComponents/CompositeFunnelStatusBar/types";

export const CompositeFunnelStatusBar = ({
    selectedScenario,
    selectedStage,
    selectedReferrer,
    setShowPanel,
    clearFunnelCallback,
}: CompositeFunnelStatusBarProps) => {
    const isDefaultFilters = () => {
        return (
            selectedStage === DefaultSelectedFunnel &&
            selectedScenario === DefaultSelectedScenerio &&
            selectedReferrer === DefaultSelectedReferrer
        );
    };

    return (
        <div className="funnelFilterStatusContainer">
            <span className="selectedFilter">
                <strong>Referrer: </strong>
                {selectedReferrer}
            </span>
            <span className="selectedFilter">
                <strong>Stage: </strong>
                {selectedStage}
            </span>
            <span className="selectedFilter">
                <strong>Document Type: </strong>
                {selectedScenario}
            </span>
            <Icon
                title="Reset Funnel Filters"
                iconName={"clearFilter"}
                className="clearFilterIcon"
                onClick={clearFunnelCallback}
                style={{ visibility: isDefaultFilters() ? "hidden" : "visible" }}
            />
            <div
                className="exploreButton floatRight"
                onClick={() => {
                    setShowPanel(true);
                }}
                aria-label="Explore Historical Trends"
                title="Explore Historical Trends"
            >
                Historical Trends <Icon iconName="Chart" />
            </div>
        </div>
    );
};
