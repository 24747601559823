import { aceHealthScore } from "./aceHealthScore";
import { frontLineWorkers } from "./frontLineWorkers";
import { modernizationScore } from "./modernizationScore";
import { networkScore } from "./networkScore";
import { tenantMetricsChartSupportTickets } from "./pssInfoQuery";
import { tenantTouchPoints } from "./tenantTouchPoints";

const inProductCommonWorkloadDrilldownQueries: {} = {
    aceHealthScore,
    frontLineWorkers,
    modernizationScore,
    networkScore_monthly: networkScore["monthly"],
    networkScore_daily: networkScore["daily"],
    tenantMetricsChartSupportTickets,
    tenantTouchPoints,
};

export default inProductCommonWorkloadDrilldownQueries;
