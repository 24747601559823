import React from "react";
import { CSVLink } from "react-csv";
import { CommandBarButton, Stack } from "@fluentui/react";
import { CadenceFrequency } from "components/CustomComponents/CadenceLabel/CadenceLabel";
import NavigableDatePickerWithCalendar from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendar";
import { ERFMColumnConfig } from "components/ERFMComponents/ERFMHelper";
import { ERFMFiltersPropsType } from "components/ERFMComponents/types";
import { getExportCsvHeaders } from "utils/Helpers";

const ERFMScorecardFilters = ({
    erfmState,
    filters,
    onFilterChange,
}: ERFMFiltersPropsType) => {
    return (
        <Stack horizontal className="filterPanel">
            <Stack horizontal style={{ minWidth: 270 }}>
                <NavigableDatePickerWithCalendar
                    backTitle="Go to Previous Week"
                    forwardTitle="Go to Next Week"
                    dateOptions={erfmState?.distinctDates}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    dateFilter={filters.date}
                    cadence={CadenceFrequency.Weekly}
                    showGoToToday={false}
                />
            </Stack>
            {Object.keys(erfmState.erfmTableRows).length > 0 &&
                erfmState.erfmTableRows[filters.application] && (
                    <CSVLink
                        data={erfmState.erfmTableRows[filters.application]}
                        filename={`eRFM-${filters.application}_${filters.date}.csv`}
                        headers={getExportCsvHeaders(
                            erfmState.erfmTableRows,
                            filters.application,
                            ERFMColumnConfig,
                        )}
                    >
                        <CommandBarButton
                            iconProps={{
                                iconName: "Download",
                            }}
                            styles={{
                                root: {
                                    height: 30,
                                    backgroundColor: "unset",
                                },
                            }}
                            text="Export"
                            title="Export all weeks data"
                        />
                    </CSVLink>
                )}
        </Stack>
    );
};

export default ERFMScorecardFilters;
