import {
    ApplicationInsights,
    SeverityLevel,
} from "@microsoft/applicationinsights-web";
// import { appInsightsConfig } from "config/Config";

/**
 * Instance is used to log telemetry into application insights.
 */
export default class AppInsights {
    // AppInsights object used to log telemetry.
    private static instance: AppInsights;

    // Instrumentation key used to configure application insights SDK.
    private static instrumentationKey: string;

    // Application insights object.
    private appInsights = null;

    /**
     * Constructor.
     * This will initializes and loads application insights.
     */
    private constructor(instrumentationKey: string) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                disableFetchTracking: false,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                disableTelemetry: instrumentationKey === "",
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
            },
        });
        try {
            this.appInsights.loadAppInsights();
        } catch (err) {
            console.error("App Insights might not work!");
        }
    }

    static isInitialized(): boolean {
        return AppInsights.instrumentationKey ? true : false;
    }

    static init(instrumentationKey: string) {
        AppInsights.instrumentationKey = instrumentationKey;
    }

    static getInstance() {
        if (!AppInsights.instance) {
            AppInsights.instance = new AppInsights(AppInsights.instrumentationKey);
        }

        return AppInsights.instance;
    }

    // Log the exception in AI.
    public LogException(error: Error, customProperties: any = {}) {
        try {
            const severity: SeverityLevel = error.severityLevel;

            this.appInsights.trackException({
                exception: error,
                severityLevel: severity,
                properties: customProperties,
            });
            this.appInsights.flush();
        } catch (err) {
            console.log(`Not able to log error ${error} into app inisghts: ${err}`);
        }
    }

    // Log the event in the AI with event propetries.
    public LogEvent(eventName: string, eventProperties?: {}) {
        try {
            this.appInsights.trackEvent({
                name: eventName,
                properties: eventProperties,
            });
        } catch (err) {
            console.log(
                `Not able to log event ${eventName} with properties ${eventProperties} into app insight: ${err}`,
            );
        }
    }

    // Log the event in the AI with event propetries.
    public TrackPage(pageName: string) {
        try {
            this.appInsights.trackPageView({ name: pageName });
        } catch (err) {
            console.log(`Not able to log page ${pageName} into app insight: ${err}`);
        }
    }

    // Set authenticated user.
    public SetAuthenticatedUserContext(userId: string) {
        try {
            this.appInsights.setAuthenticatedUserContext(userId);
            this.appInsights.addTelemetryInitializer((envelope) => {
                envelope.tags = envelope.tags || {};
                envelope.tags["ai.user.id"] = userId;
            });
        } catch (err) {
            console.log(
                `Not able to set authenticated user with user id ${userId}: ${err}`,
            );
        }
    }
}
