import React from "react";
import { XAxis, YAxis, Bar, BarChart } from "recharts";
import { precisionRound, Stack } from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { COLLAB_STAGES } from "utils/Messages";

const funnelBarHeight = 300;
const funnelBarWidth = 90;

export const CollabFunnel = (props) => {
    const data = [];
    if (props.payload === undefined) {
        return (
            <div className="funnel">
                <h4 className="commonPageBlockTitle"> {"Collab Funnel Chart"} </h4>
                <EmptyTableContainer />
            </div>
        );
    }

    Object.keys(COLLAB_STAGES)
        .filter((i) => !i.includes("%"))
        .map((stage) => {
            return data.push([
                {
                    name: stage,
                    count: props.payload[stage],
                    percent: props.payload[`${stage} %`],
                    label: `${props.payload[`${stage} %`]}   % 
                            ${props.payload[stage]}`,
                },
            ]);
        });

    /*
    const FunnelToolTip = (tooltipProps) => {
        if (!tooltipProps.active) {
            return null;
        }
        return (
            <div className="funnelTooltip">
                <b>{tooltipProps.payload[0].payload["name"]}</b>
                <br />
                Count: {tooltipProps.payload[0].payload["count"]}
                <br />
                Percent: {tooltipProps.payload[0].payload["percent"]}%
            </div>
        );
    };
    */

    const barGroups = data.map((d, i) => (
        <span style={{ display: "inline-flex" }} key={data[i][0]["name"]}>
            <div className="diffValue" title="% users dropped">
                {i !== 0 && (
                    <div className="diffArrow">
                        <span>
                            {data[i][0]["count"] - data[i - 1][0]["count"] !== 0
                                ? precisionRound(
                                      ((data[i][0]["count"] -
                                          data[i - 1][0]["count"]) /
                                          data[i - 1][0]["count"]) *
                                          100,
                                      2,
                                  )
                                : "0"}
                            %
                        </span>
                    </div>
                )}
            </div>

            <div className="funnelBar">
                <div
                    style={{
                        /* 
                            Style element top is set to a value above the bar. 
                            Bar height is 3 times the value, hence (3 * value)
                            Subtracting 5 points from the height to place the label a little above the bar.
                        */
                        top: `${funnelBarHeight - 5 - 3 * data[i][0]["percent"]}px`,
                    }}
                    className="funnelLabel"
                    title={COLLAB_STAGES[data[i][0]["name"]]}
                >
                    <span>{data[i][0]["count"]}</span>
                    <br />
                    <span>{data[i][0]["percent"]}%</span>
                </div>
                <BarChart
                    width={funnelBarWidth}
                    height={funnelBarHeight}
                    data={[data[i][0]]}
                >
                    <YAxis domain={[0, 100]} hide />
                    <XAxis dataKey="name" hide />
                    {/* <Tooltip content={<FunnelToolTip />} /> */}
                    <Bar dataKey="percent" fill="#3288bd" width="5" />
                </BarChart>
                <span
                    className="funnelBarName"
                    title={COLLAB_STAGES[data[i][0]["name"]]}
                >
                    {data[i][0]["name"]}
                </span>
            </div>
        </span>
    ));
    return (
        <div className="funnel">
            <h4 className="commonPageBlockTitle"> {"Collab Funnel Chart"} </h4>
            <Stack horizontal> {barGroups} </Stack>
        </div>
    );
};
