import React, { useEffect, useState } from "react";
import LoadingBar from "react-redux-loading-bar";
import { Stack, Dropdown, IDropdownOption, Label, FontIcon } from "@fluentui/react";
import "./style.css";
import CohortErrorDrilldown from "components/CohortAnalysisComponents/CohortErrorDrilldown";
import TopTenantsForError from "components/CohortAnalysisComponents/TopTenantsForError";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import useDidMount from "hooks/useDidMount";
import DataComposedChart from "components/CustomComponents/DataComposedChart";
import LoadingModal from "components/CustomComponents/LoadingModal";
import {
    commonInnerBlock,
    commonPageBlock,
    commonPageStyle,
    filterStackTokens,
} from "pages/common";
import AppInsights from "utils/AppInsights";
import { logFilterUsage } from "utils/AppInsightsHelper";
import { extractQueryParams } from "utils/Helpers";
import { NO_DATA_MESSAGES } from "utils/Messages";
import { flattenFilters, useSendLaunchEvent } from "utils/PlgTelemetryLogger";
import { Application, dropdownStyles } from "utils/WebConstants";
import {
    fetchCohortCategories,
    fetchCohortErrorDistribution,
    fetchCohortReliabilityData,
    fetchErrorLookupChart,
    fetchTenantsImpactedByError,
    fetchValidDates,
} from "./ApiHandler";
import { defaultApp, defaultFilterChangeChain } from "./CohortAnalysisConstants";
import {
    appOptions,
    changeURLParams,
    customToolTipContentForDataComposedChart,
    getCellColor,
    getCellStroke,
    getFiltersFromQueryParams,
    handleFilterChange,
} from "./CohortAnalysisUtils";
import {
    CohortAnalysisFilters,
    CohortAnalysisQueryParams,
    CohortAnalysisState,
} from "./types";

const CohortAnalysis = () => {
    const [filters, setFilters] = useState<CohortAnalysisFilters>({});
    const [state, setState] = useState<CohortAnalysisState>({
        isFetching: true,
        filters: {
            app: defaultApp,
        },
    });
    let queryParams: CohortAnalysisQueryParams = {};

    useSendLaunchEvent("Cohort Analysis", flattenFilters(filters));

    useEffect(() => {
        document.title = "Cohort Analysis";
        AppInsights.getInstance().TrackPage(document.title);

        if (appOptions.length === 0) {
            Object.keys(Application).forEach((app) =>
                appOptions.push({ key: app, text: app }),
            );
        }

        getFiltersFromQueryParams(filters, extractQueryParams(), setFilters);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        changeURLParams(filters, queryParams);
        // ADO 7955411: possible useMemo refactor on queryParams
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useDidMount(() => {
        if (!filters.app) {
            return;
        }

        fetchValidDates(filters, setState, setFilters);
    }, [filters.app]);

    useDidMount(() => {
        if (!filters.date) {
            return;
        }

        fetchCohortCategories(filters, setState, setFilters);
    }, [filters.date]);

    useDidMount(() => {
        if (!filters.cohortCategory || !filters.app || !filters.date) {
            return;
        }

        fetchCohortReliabilityData(filters, setState, setFilters);
    }, [filters.cohortCategory]);

    useDidMount(() => {
        if (
            !filters.cohort ||
            !filters.cohortCategory ||
            !filters.app ||
            !filters.date
        ) {
            return;
        }

        fetchCohortErrorDistribution(filters, setState, setFilters);
    }, [filters.cohort]);

    useDidMount(() => {
        if (
            !filters.errorname ||
            !filters.cohort ||
            !filters.cohortCategory ||
            !filters.app ||
            !filters.date
        ) {
            return;
        }

        fetchTenantsImpactedByError(filters, setState);
    }, [filters.errorname]);

    const handleFilterChangeHelper = (
        filterToChange: string,
        newFilterValue: string,
    ) => {
        handleFilterChange(
            filters,
            queryParams,
            filterToChange,
            newFilterValue,
            defaultFilterChangeChain,
            setFilters,
        );
    };

    const onDropDownChange = (filterToChange) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            logFilterUsage(document.title, filterToChange, item.key.toString());
            handleFilterChangeHelper(filterToChange, item.key.toString());
        };
    };

    return state.isFetching || !state.tenantsImpactedByError ? (
        <LoadingModal text="Loading Cohort Analysis page..." />
    ) : (
        <>
            <LoadingBar className="loadingBar" />
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader">Cohort Analysis - Web</div>
                </Stack.Item>
                <Stack horizontal tokens={filterStackTokens}>
                    <FontIcon iconName="FilterSolid" className="filterIconSmall" />
                    <div>
                        <Label className="filterLabel">Time Period:</Label>
                        <Dropdown
                            placeholder="Select an option"
                            options={state.dateOptions}
                            selectedKey={filters["date"]}
                            onChange={onDropDownChange("date")}
                            className="filterDropdown"
                            styles={dropdownStyles}
                        />
                    </div>
                    <div>
                        <Label className="filterLabel">Application:</Label>
                        <Dropdown
                            options={appOptions}
                            selectedKey={filters["app"]}
                            onChange={onDropDownChange("app")}
                            className="filterDropdown"
                            styles={dropdownStyles}
                        />
                    </div>
                    <div>
                        <Label className="filterLabel">Cohort Classification:</Label>
                        <Dropdown
                            options={state.cohortCategoryOptions}
                            selectedKey={filters["cohortCategory"]}
                            onChange={onDropDownChange("cohortCategory")}
                            className="filterDropdown"
                            styles={dropdownStyles}
                        />
                    </div>
                </Stack>
                <br />
                {state.dateOptions?.length === 0 && !state.isFetching ? (
                    <ErrorFallback message={NO_DATA_MESSAGES.NO_DATA} />
                ) : (
                    <Stack styles={commonPageBlock}>
                        <Stack.Item styles={commonInnerBlock}>
                            <h1 className="cohortAnalysisPageSectionHeader">
                                Reliability Disparity Among Cohorts
                            </h1>
                            <DataComposedChart
                                chartData={state.reliabilityDisparityChartData}
                                currentlySelectedCohort={filters["cohort"]}
                                chartCallBackFunction={(cohort) => {
                                    handleFilterChangeHelper(
                                        "cohort" /* filter to change */,
                                        cohort /* new Filter value */,
                                    );
                                }}
                                XAxisDataKey="Name"
                                XAxisName="Cohort"
                                YAxisDataKey="ACE_OR"
                                YAxisName="Odds of having ACE Errors compared to other cohorts (Odds Ratio)"
                                YAxisRightDataKey="ACE_IS"
                                YAxisRightName="Odds of having ACE Errors normalized by size of cohort (Impact Score)"
                                dashedLineDataKey="Normal_OR"
                                dashedLineName="Odds Ratio Threshold"
                                getCellColor={getCellColor}
                                getCellStroke={getCellStroke}
                                toolTipFunction={
                                    customToolTipContentForDataComposedChart
                                }
                            />
                        </Stack.Item>
                        <Stack.Item styles={commonInnerBlock}>
                            <h1 className="cohortAnalysisPageSectionHeader">
                                ACE Errors Deep Dive View
                            </h1>
                            <div className="cohortAnalysisPageSectionDescription">
                                Deep dive of ACE Errors for the cohort:
                                <b> {filters["cohort"]};</b> categorized by:{" "}
                                <b> {filters["cohortCategory"]};</b> for app:{" "}
                                <b> {filters["app"]}.</b>
                                <br />
                                Please click on the bar graph for the desired cohort
                                in the section above to re-scope this chart.
                            </div>
                            <CohortErrorDrilldown
                                chartData={state.errorsDeepDiveChartData}
                                dataItems={state.errorBreakdownByCohort}
                                filters={filters}
                                chartCallbackFunction={(errorName) => {
                                    handleFilterChangeHelper(
                                        "errorname" /* filter to change */,
                                        errorName /* new filter value */,
                                    );
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={commonInnerBlock}>
                            <h1 className="cohortAnalysisPageSectionHeader">
                                Top Tenants With Same Error
                            </h1>
                            <div className="cohortAnalysisPageSectionDescription">
                                Top tenants in cohort:
                                <b> {filters["cohort"]};</b> categorized by:{" "}
                                <b> {filters["cohortCategory"]};</b> and app:{" "}
                                <b> {filters["app"]}</b> that have the error:{" "}
                                <b> {filters["errorname"]}</b>
                                <br />
                                Please click on the scatter chart for the desired
                                error in the section above to re-scope this chart.
                            </div>
                            <TopTenantsForError
                                tenantsImpactedByErrorChartData={
                                    state.tenantsImpactedByErrorChartData
                                }
                                tenantsImpactedByError={state.tenantsImpactedByError}
                                filters={filters}
                                getChartData={(filters) => {
                                    fetchErrorLookupChart(
                                        filters,
                                        state.s2500Data,
                                        setState,
                                    );
                                }}
                                s2500Data={state.s2500Data}
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default CohortAnalysis;
