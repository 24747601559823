import {
    addinsDateFilters,
    addInsPerformanceScore,
    addInsReliabilityScore,
} from "./AddIns/addinQueries";
import { cohortAnalysis } from "./cohortAnalysis";
import { collabFunnel } from "./collabFunnel";
import { dateFilters } from "./dateFilters";
import { errorLookup } from "./errorLookup";
import { errorSpike } from "./errorSpike";
import execOverviewQueries from "./ExecOverview/index";
import { platformSignal } from "./ExecOverview/platformSignal";
import { dateFilterOptions, filterOptions } from "./filterOptions";
import inProductDrilldownQueries from "./InProductDrilldown/index";
import { m365TenantList, m365TenantTpidDetails } from "./m365TenantList";
import MTRQueries from "./MTR";
import { notes } from "./notes";
import perfTenantAddInQueries from "./PerfDrillDown/Win32/AddIns/index";
import perfDrillDownQueries from "./PerfDrillDown/Win32/index";
import { performanceDistribution } from "./performanceDistribution";
import { errorBrowserData, reliability } from "./reliability";
import { retention } from "./retention";
import { consumerday0FunnelQueries } from "./ConsumerDay0Funnel";
import { consumerGrowthQueries } from "./ConsumerGrowthDrilldown";
import { consumerScorecardQueries } from "./ConsumerScorecard";
import { summarizedInsightsRequest } from "./summarizedInsightsRequest";
import { aiScorecardQueries } from "./AIScorecard";
import { copilotQuries } from "./Copilot";
import { consumerCamauQueries } from "./ConsumerCamau";
import { erfmQueries } from "./ERFM";
import { createFunnelQueries } from "queries/JourneyFunnels/CreateFunnel";
import { harmonyFunnelQueries } from "queries/JourneyFunnels/HarmonyFunnel/index";
import { ahpFunnelQueries } from "queries/JourneyFunnels/NewAppHomePageFunnel/index";
import { quipSurveyFunnelQueries } from "./QuIP/surveyFunnelDrilldown20";
import { uhpFunnelQueries } from "queries/JourneyFunnels/NewAppHomePageFunnel/index";

let errorLookupQueries: {} = {
    tenantsImpacted: errorLookup["TenantsImpacted"],
    errorNames: errorLookup["ErrorNames"],
    s2500AvgData: errorLookup["S2500AvgData"],
    cohortAvgData: errorLookup["CohortAvgData"],
    tenantsImpactedCount: errorLookup["TenantsImpactedCount"],
    chartData: errorLookup["ChartData"],
    tenantsImpactedByHost: errorLookup["TenantsImpactedByHost"],
    tenantsImpactedByUiHost: errorLookup["TenantsImpactedByUiHost"],
    tenantsImpactedAllDates: errorLookup["TenantsImpactedAllDates"],
    tenantsImpactedByHostAllDates: errorLookup["TenantsImpactedByHostAllDates"],
    tenantsImpactedByUiHostAllDates: errorLookup["TenantsImpactedByUiHostAllDates"],
    cohortImpactCountAllDates: errorLookup["CohortImpactCountAllDates"],
};

let cohortAnalysisQueries: {} = {
    datesAvailable: cohortAnalysis["DatesAvailable"],
    cohortCategories: cohortAnalysis["CohortCategories"],
    overallCohortReliability: cohortAnalysis["OverallCohortReliability"],
    cohortErrors: cohortAnalysis["CohortErrors"],
    cohortErrorBreakdown: cohortAnalysis["CohortErrorBreakdown"],
    tenantsImpactedInCohort: cohortAnalysis["TenantsImpactedInCohort"],
};

let queries: {} = {
    dateFilters: dateFilters,
    addinsDateFilters: addinsDateFilters,
    addInsPerformanceScore: addInsPerformanceScore,
    addInsReliabilityScore: addInsReliabilityScore,
    ...execOverviewQueries,
    reliability: reliability,
    filterOptions: filterOptions,
    collabFunnel: collabFunnel,
    notes: notes,
    errorSpike: errorSpike,
    ...errorLookupQueries,
    performanceDistribution: performanceDistribution,
    dateFilterOptions: dateFilterOptions,
    errorBrowserData,
    platformSignal: platformSignal,
    retention: retention,
    m365TenantList: m365TenantList,
    m365TenantTpidDetails: m365TenantTpidDetails,
    ...inProductDrilldownQueries,
    ...cohortAnalysisQueries,
    ...perfDrillDownQueries,
    ...perfTenantAddInQueries,
    ...MTRQueries,
    ...consumerday0FunnelQueries,
    ...consumerGrowthQueries,
    ...consumerScorecardQueries,
    supportedTenantList: summarizedInsightsRequest["SupportedTenantList"],
    ...aiScorecardQueries,
    ...consumerCamauQueries,
    ...erfmQueries,
    ...harmonyFunnelQueries,
    ...ahpFunnelQueries,
    ...uhpFunnelQueries,
    ...createFunnelQueries,
    ...copilotQuries,
    ...quipSurveyFunnelQueries,
};

export default queries;
