import React, { useState, useEffect } from "react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import * as API from "api";
import { RetentionTable } from "components/TenantDrilldownComponents/Retention";
import { Workload } from "config/PlatformConfig";
import { ConsumerAppMetricsProps } from "pages/ConsumerProductDrillDown/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

interface RetentionState {
    payload: Array<Array<[string, number]>>;
}

const initialState: RetentionState = {
    payload: [],
};

export const getRetentionResponse = async (params: {}) => {
    const queryParams = { ...params };

    const retentionResponse = await API.getKustoResponse({
        queryName: "webconsumerRetention",
        platform: Workload.WEB_CONSUMER,
        queryParams,
    }).catch((error) => {
        return Promise.reject(error);
    });

    return retentionResponse;
};

export const ConsumerRetention = ({
    ConsumerDrilldownFilters,
}: ConsumerAppMetricsProps) => {
    const [data, setData] = useState<RetentionState>(initialState);

    const getPayloadData = async () => {
        setData((data) => {
            return {
                ...data,
            };
        });

        try {
            const response = await getRetentionResponse(ConsumerDrilldownFilters);
            const formattedResponse = response.data?.Tables[0]?.Rows;

            setData({
                payload: formattedResponse,
            });
        } catch (err) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "ConsumerRetentionMetricsFetchFailed",
                ),
                {
                    message:
                        "Failed to fetch or format Consumer Retention Table Metrics",
                },
                err,
            );
            setData({
                payload: [],
            });
        }
    };

    useEffect(() => {
        getPayloadData();
        // ADO 7955411: useCallback refactor on getPayloadData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ConsumerDrilldownFilters.application,
        ConsumerDrilldownFilters.cohortName,
        ConsumerDrilldownFilters.cohortType,
        ConsumerDrilldownFilters.date,
    ]);

    return (
        <>
            <h2 className="commonPageBlockTitle">Retention</h2>
            {data.payload.length === 0 ? (
                <EmptyTableContainer />
            ) : (
                <RetentionTable payload={data.payload} />
            )}
        </>
    );
};
