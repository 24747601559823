export const summarizedInsightsRequest: {} = {
    SupportedTenantList: `
            set notruncation;
            let webMaxDate = toscalar ( Score | summarize max(Date));
            let Win32MaxDate = toscalar ( HealthyTenantScore_Win32Overview | summarize max(Date));
            union
            ( Score | where Date == webMaxDate | distinct OmsTenantId),
            ( HealthyTenantScore_Win32Overview | where Date == Win32MaxDate | distinct  OmsTenantId)
            | distinct OmsTenantId
            | join kind = inner (StrategicTenants) on OmsTenantId
            | where isnotempty(TenantName)
            | distinct OmsTenantId, TenantName
        `,
};
