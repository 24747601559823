export const teamsTenantEnvironmentData: string = `
// Teams Tenant Environment
let currentDate = GetCurrentTeamsDate(false);
let totalWXPSessions = toscalar( MiscellaneousMetrics
     | where OmsTenantId == "\${id}"  and Application == "WXP" 
     and Metric=="SessionOrigin" and Name == "TEAMS" and Date == currentDate
     | project Value=tolong(Value)
);
MiscellaneousMetrics
| where OmsTenantId == "\${id}"  and Application == "WXP" 
and Metric == "SessionOrigin" and Name == "TEAMS" 
| project
          Metric = "OfficeUsage",
          Date,
          Name = "Teams",
          Value = tolong(Value),
          Total  
| order by Date asc
| union (
     License
     | where OmsTenantId == "\${id}"
     | project Metric="LicenseDistribution", Date, Name, Value=tolong(Value), Total
     | order by Date asc
)
| union (
     MiscellaneousMetrics
     | where OmsTenantId == "\${id}"  and (Application == "Word" or Application == "Excel" or Application == "PowerPoint")  and Date==currentDate
     and Metric == "SessionOrigin" and Name == "TEAMS" 
     | project
               Metric = "OfficeUsageBreakDown",
               Date,
               Name = Application,
               Value = tolong(Value),
               Total = totalWXPSessions  
)
| union (
     Teams_ProductInsights
     | where OmsTenantId =="\${id}"
     | where Metric == "Location"
     | extend Value = tolong(Value))
     | project Date, OmsTenantId, Tpid="Dummy", SubWorkload, 
               Metric,
               Name,
               Value,
               Total
| order by Date asc, Metric, SubWorkload, Value
`;
