export const ahpFunnelDates: string = `
// ERFM Funnel Dates
database('ConsumerInsights').\${tableName}
| summarize by format_datetime(SliceDate, "yyyy-MM-dd")
| order by SliceDate desc
`;

export const ahpFunnelStages: string = `
// eRFM Funnel Stages
let allStages =
    database('ConsumerInsights').\${tableName}
    | where AppName == "\${application}"
    | where ReferralCategory == "\${ReferralCategory}"
    | where DeploymentEnvironment == "\${DeploymentEnvironment}"
    | where ComputedLicense == "\${ComputedLicense}"
    | where RegisteredLocale == "\${RegisteredLocale}"
    | where RegisteredCountry == "\${RegisteredCountry}"
    | where Platform == "\${Platform}"
    | where IsDay0Event == "All"
    // | where IsDay0Event == "\${IsDay0Event}"
    // | where iff("\${IsNewToWXP}" == "All", true, IsNewToWXP == "\${IsNewToWXP}")
    | summarize Count = sum(PuidDistinctCount) by ReceivedDate, MetricName
    | order by ReceivedDate desc, MetricName asc;
let defaultDenominator = toscalar(allStages | project Count);
database('ConsumerInsights').\${tableName}_FunnelConfig
| extend JoinKey = 1
| join kind=fullouter (allStages
    | project Date = format_datetime(ReceivedDate, "yyyy-MM-dd"), JoinKey = 1)
    on JoinKey
| summarize count()
    by
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    Application,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    ToolTipMessage
| project-away count_
| join kind=fullouter  (
    allStages
    | extend
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        StageName = MetricName,
        Value = Count
    | order by Date desc
    )
    on StageName, Date
| where isnotempty(Date)
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    Value,
    ToolTipMessage
| extend joinKey = DenominatorColumn
| join kind=leftouter (
    allStages
    | extend
        joinKey = MetricName,
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        Total = Count
    | project Date, joinKey, Total
    )
    on joinKey, Date
| extend Total = iff(isempty(Total) or isnull(Total), defaultDenominator, Total)
| extend Percentage = round(100.0 * todouble(Value) / todouble(Total), 2)
| extend StageFriendlyName=replace_string(StageFriendlyName, '_appName', "\${application}")
| extend DropOffFriendlyName=replace_string(DropOffFriendlyName, '_appName', "\${application}")
| where isnotempty(Percentage) or CanBeNull == true
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    Value,
    Total,
    Percentage,
    ToolTipMessage
| order by Date desc, StageIndex asc
`;

export const ahpFunnelFilterChoices: string = `
let AllFilterOptions = datatable(
    IsNewToWXP: string,
) [
    "All"
];
let DeploymentEnvironment=database('ConsumerInsights').\${tableName}
| distinct DeploymentEnvironment;
let ComputedLicense=database('ConsumerInsights').\${tableName}
| distinct ComputedLicense;
let RegisteredLocale=database('ConsumerInsights').\${tableName}
| distinct RegisteredLocale;
let RegisteredCountry =database('ConsumerInsights').\${tableName}
| distinct RegisteredCountry;
let Platform=database('ConsumerInsights').\${tableName}
| distinct Platform;
let IsNewToWXP=database('ConsumerInsights').\${tableName}
| distinct IsNewToWXP;
let IsDay0Event=database('ConsumerInsights').\${tableName}
| distinct IsDay0Event;
let ReferralCategory=database('ConsumerInsights').\${tableName}
| distinct ReferralCategory;
DeploymentEnvironment
| union ComputedLicense
| union RegisteredLocale
| union RegisteredCountry
| union Platform
| union IsNewToWXP
| union IsDay0Event
| union AllFilterOptions
| union ReferralCategory
| project DeploymentEnvironment, ComputedLicense, RegisteredLocale, RegisteredCountry, Platform, IsNewToWXP, IsDay0Event, ReferralCategory
`;

export const ahpFunnelDecomposition: string = `
// AHP Funnel Decomposition
let testRatio = 0.33;
let selectSpecificStage = "\${selectedStage}" != "All";
let stage = "\${selectedStage}";
database('ConsumerInsights').AppHomePagesAuthFunnelDecomp
| where iff(selectSpecificStage, tostring(StartStage) == stage, true)
| where AppName == "\${application}"
| where Date == "\${date}"
| where Explanation !has('"Other"') and Explanation !has('"Unknown"')
| where PValue < 0.01
| where ExampleCount > 100
| where abs(DeltaToStage) > 0.05
| where Novelty > 0.33
| extend GroupSize  = round(ExampleCount/testRatio,0)
| project StartStage,
    EndStage,
    Depth,
    ExampleCount,
    GroupSize,
    Novelty,
    PValue,
    Opportunity = Novelty*GroupSize*abs(DeltaToStage),
    GroupLoss = 100.0 * PercentGroupLoss,
    GroupLossToStage = 100.0 * DeltaToStage,
    Explanation = replace_strings(
        Explanation,
        dynamic(['BrowserName', 'ComputedLicense', 'DeviceType', 'AppLanguage', 'ReferralCategory']),
        dynamic(['Browser', 'License', 'Device', 'Language', 'Referral'])
    )
| order by Opportunity desc
`;
