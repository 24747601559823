export const topTrendingSignals: string = `
// Top Trending Signals
let currentMonth =  database("TenantInsights").GetCurrentDateCrossPlatform();
let lookback = toscalar (NumericConstants | where Constant == "MTR_LOOKBACK" | project tolong(Value));
let startMonth = datetime_add("Month", -lookback, todatetime(currentMonth));
let unhealthyTenants = StagingGetUnhealthyTenants(todatetime(currentMonth))
    | where Application == "Base"
    | join kind = inner(
        database("TenantInsights").CrossPlatform
        | where todatetime(Date) > todatetime(startMonth)
            and todatetime(Date) <= todatetime(currentMonth)
        | project OmsTenantId, Date, Application, W32_Issues, Web_Issues, CrossApp_Color)
        on OmsTenantId, Application
    | where CrossApp_Color in ("Red", "DeepRed")
    | project OmsTenantId, W32_Issues, Web_Issues; 
let denom = StagingGetUnhealthyTenantCounts(currentMonth)
    | where Application == "Base"
    | project Total;
let w32Issues = unhealthyTenants
    | project OmsTenantId, todynamic(W32_Issues)
    | mv-expand W32_Issues.apps   
    | project OmsTenantId, W32_Issues_apps.name, W32_Issues_apps.issues    
    | mv-expand W32_Issues_apps_issues    
    | project
        platform = "Win32",
        OmsTenantId,
        W32_Issues_apps_name,
        W32_Issues_apps_issues.type,
        W32_Issues_apps_issues.color        
    | where W32_Issues_apps_issues_color < 4    
    | summarize count()
        by
        OmsTenantId,
        tostring(platform),
        tostring(W32_Issues_apps_name),
        tostring(W32_Issues_apps_issues_type)
    | where count_ >= 2
    | summarize count()
        by
        platform,
        W32_Issues_apps_issues_type,
        W32_Issues_apps_name   
    | project-rename
        app = W32_Issues_apps_name,
        signal = W32_Issues_apps_issues_type,
        numIssues = count_;
let webIssues = unhealthyTenants
    | project OmsTenantId, todynamic(Web_Issues)
    | mv-expand Web_Issues.apps
    | project OmsTenantId, Web_Issues_apps.name, Web_Issues_apps.issues
    | mv-expand Web_Issues_apps_issues
    | project
        platform = "Web",
        OmsTenantId,
        Web_Issues_apps_name,
        Web_Issues_apps_issues.type,
        Web_Issues_apps_issues.color
    | where Web_Issues_apps_issues_color < 4
    | summarize count()
        by
        OmsTenantId,
        tostring(platform),
        tostring(Web_Issues_apps_name),
        tostring(Web_Issues_apps_issues_type)
    | where count_ >= 2
    | summarize count()
        by
        platform,
        Web_Issues_apps_issues_type,
        Web_Issues_apps_name   
    | project-rename
        app = Web_Issues_apps_name,
        signal = Web_Issues_apps_issues_type,
        numIssues = count_;
let both_platforms = w32Issues
    | union webIssues
    | extend total = toscalar(denom);
both_platforms
| extend PercWithIssues = todouble(numIssues) / total * 100
| sort by PercWithIssues desc;
`;
