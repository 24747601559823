export const crossplatformCompositeScore: string = `
// cross platform score - get composite score
let currDate = GetCurrentDateCrossPlatform();
let dt = iif(isempty("\${date}"),currDate, "\${date}");
let minMAU = 2500;
let app = "\${application}";
CrossPlatform
| where Date == dt and Application == app and CrossApp_Color != "Gray" and (W32_Mau>=minMAU or Web_Mau>=minMAU)
| extend Tpid = TopParentId
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| join kind=inner
    (
        StrategicTenants
        | where Is\${cohort} == true and Date == currDate
        | distinct \${levelColumnName}, iif("\${levelColumnName}"  == "Tpid", OrgName_Translated, TenantName_Translated)
        |  project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind = leftouter (
    CrossPlatform
    | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application == app
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | extend Tpid = TopParentId, PrevMonthWin32_Score = W32_Score, PrevMonthW32_Mau = W32_Mau,
        PrevMonthWeb_Score = Web_Score, PrevMonthWeb_Mau = Web_Mau
    )
    on \${levelColumnName}
  | join kind=leftouter (
    CrossPlatform_PastStatus
      | where Application == app and Date == currDate
    ) on OmsTenantId, Tpid
    | extend Ratio = round(iff(Web_Mau==0 or isempty( Web_Mau), 1.0, todouble(W32_Mau)/(W32_Mau+Web_Mau)),2)
    | extend InvRatio = round(iff(W32_Mau==0 or isempty( W32_Mau), 1.0, todouble(Web_Mau)/(W32_Mau+Web_Mau)),2)
    | extend Factor = round(iff(Web_Mau==0 or isempty( Web_Mau), 1.0, todouble(W32_Mau)/Web_Mau),2)
    | extend Times = iff(Ratio>=0.6 and Factor>=2, strcat(" (" ,tolong(Factor), "x Web MAU)"), iff(InvRatio>=0.6 and Factor<=0.5 and Factor>0 ,   strcat(" (" ,tolong(1/Factor), "x Win32 MAU)"), ""))
    | extend Major_Text = strcat (ClientWeb_Flag, Times)
| project Date, \${level} = \${levelColumnName}, Major_Text, OrgName = Name, Composite_Score=CrossApp_Score, Win32_Score_Platform = W32_Score, Web_Score_Platform = Web_Score, Win32_MAU_Score=W32_Mau, Web_MAU_Score = Web_Mau, Win32_Issues = W32_Issues, Web_Issues, Win32_Score_Platform_Color = W32_Color, Web_Score_Platform_Color = Web_Color, Status = CrossApp_Color, PastStatus  = GetPastYearStatusCrossPlatform(PastStatusSet, dt)
| sort by Composite_Score desc, Win32_MAU_Score desc
`;
