import React from "react";
import "components/CommonFunnelComponents/CommonFunnelGraph/style.css";
import { scaleLinear } from "@visx/scale";
import { CommonFunnelFooter } from "components/CommonFunnelComponents/CommonFunnelFooter/CommonFunnelFooter";
import { CommonFunnelHeader } from "components/CommonFunnelComponents/CommonFunnelHeader/CommonFunnelHeader";
import { FunnelAreaChart } from "components/CustomComponents/FunnelAreaChart/FunnelAreaChart";
import { FunnelBarChart } from "components/CustomComponents/FunnelBarChart/FunnelBarChart";
import {
    areaHeight,
    areaWidth,
    barHeight,
    barWidth,
    margin,
    yMax,
} from "components/CommonFunnelComponents/CommonFunnelGraph/constants";
import { getAreaXScale } from "components/CommonFunnelComponents/CommonFunnelGraph/funnelAreaChartHelper";
import { xScale } from "components/CommonFunnelComponents/CommonFunnelGraph/funnelBarChartHelper";
import { CommonFunnelGraphProps } from "components/CommonFunnelComponents/CommonFunnelGraph/types";
import {
    getFunnelArrowValue,
    getStageDelta,
    isStageNullAsExpected,
} from "components/CommonFunnelComponents/CommonFunnelStages/commonFunnelStagesHelper";
import { FunnelStageProps } from "components/CommonFunnelComponents/CommonFunnelStages/types";

export const CommonFunnelGraph = ({
    stagesData,
    showAllStagesHandler,
}: CommonFunnelGraphProps) => {
    const maxYValue = stagesData[0].slices[0].value;

    const getYScale = (maxYValue: number) => {
        return scaleLinear<number>({
            range: [yMax, 0],
            domain: [0, maxYValue],
        });
    };

    return (
        <>
            <div className="commonFunnelContainer">
                {stagesData &&
                    stagesData.map((data, stageIndex) => {
                        const areaChartData = [
                            stagesData[stageIndex],
                            stagesData[stageIndex + 1],
                        ];
                        const nextStageDelta = getStageDelta(stagesData, stageIndex);
                        return (
                            <div
                                className="commonFunnelColumn"
                                key={`funnelColumn-${data.stage.id}`}
                            >
                                <CommonFunnelHeader funnelStageData={data} />
                                <div
                                    className="commonFunnelChartContainer"
                                    key={`funnelChartContainer-${data.stage.id}`}
                                >
                                    <FunnelBarChart
                                        chartFormattingData={{
                                            height: barHeight,
                                            width: barWidth,
                                            margin: margin,
                                        }}
                                        data={data}
                                        xScale={xScale}
                                        yScale={getYScale(maxYValue)}
                                        showAllStagesHandler={showAllStagesHandler}
                                    />
                                    {stageIndex < stagesData.length - 1 &&
                                        !isStageNullAsExpected(
                                            stagesData[stageIndex + 1],
                                        ) && (
                                            <FunnelAreaChart
                                                chartFormattingData={{
                                                    height: areaHeight,
                                                    width: areaWidth,
                                                    margin: margin,
                                                }}
                                                data={areaChartData}
                                                dropOffValue={getFunnelArrowValue(
                                                    stagesData,
                                                    stageIndex,
                                                    nextStageDelta.percentage,
                                                )}
                                                xScale={getAreaXScale(areaChartData)}
                                                yScale={getYScale(maxYValue)}
                                                xAccessor={(d: FunnelStageProps) => {
                                                    return d?.stage.id;
                                                }}
                                                yAccessor={(d: FunnelStageProps) => {
                                                    return d?.slices[0].value ?? 0;
                                                }}
                                            />
                                        )}
                                </div>
                                <CommonFunnelFooter
                                    dropOffName={data.dropOff}
                                    isLastStage={
                                        stageIndex == stagesData.length - 1 ||
                                        isStageNullAsExpected(
                                            stagesData[stageIndex + 1],
                                        )
                                    }
                                    dropOffDeltaPercent={`${nextStageDelta?.percentage}`}
                                    dropOffDeltaValue={nextStageDelta?.value}
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    );
};
