import React from "react";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { appStyles } from "utils/Constants";
import { NO_DATA_MESSAGES } from "utils/Messages";

export const PSSChartModal = ({ show, data, appsList }) => {
    const list = [];
    appsList.forEach((app) => {
        list.push({ color: appStyles[app]["color"], value: app, key: app });
    });

    if (!show || !data) {
        <span className="subscriptStyleWithPadding">
            {NO_DATA_MESSAGES.NO_DATA}
        </span>;
    }

    const legendCustomFormatter = (value: SVGStringList) => {
        return (
            <span className="recharts-legend-item-text" style={{ fontSize: 12 }}>
                {value}
            </span>
        );
    };

    return (
        <>
            <TogglingLineChart
                kind={togglingLineChartKind.values}
                xDataKey="Date"
                values={data}
                labels={list}
                width={450}
                height={300}
                legendFormatter={legendCustomFormatter}
            />
            <span className="subscriptStyleWithPadding">
                *Click on legend items to toggle.
            </span>
        </>
    );
};
