import { format as stringFormat } from "util";
import React from "react";
import { Link } from "@fluentui/react/lib/Link";
import { MessageBar } from "@fluentui/react/lib/MessageBar";
import { PanelType } from "@fluentui/react/lib/Panel";
import { DetailPanelV2, DetailPanelV2Header } from "@m365-admin/detail-panel";
import { MetaDataList, MetaDataItem } from "@m365-admin/metadata";
import {
    IMultiCountItemProps,
    AnnotationType,
    MultiCount,
} from "@m365-admin/multi-count";
import { MiniApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import { AdvisoriesFlyoutHistoricChart } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesFlyoutHistoricChart";
import {
    getFormattedMetricValueWithUnits,
    getSeverityIcon,
    multiCountRowColor,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { isNil, isEqual } from "lodash";
import {
    AdvisoriesFlyoutDetails,
    AdvisoriesMetadataTitles,
    AdvisoriesMetricChartHeaders,
    AdvisoriesMetricNameResolver,
    AdvisoriesRecommendedActions,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import {
    AdvisoryFilterNames,
    AdvisoryMetricType,
    AdvisoryTypeV2,
    FlyoutPanelProps,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { CustomDetailsItemsType } from "pages/commonTypes";
import { logFeatureUsage } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";

const getLatestAdvisory = (
    selectedRow: CustomDetailsItemsType,
    dataItems: CustomDetailsItemsType[],
): CustomDetailsItemsType => {
    if (dataItems.length === 0) {
        return null;
    }

    const relatedAdvisories = dataItems.filter((alert) => {
        return (
            alert.Product == selectedRow.Product &&
            alert.Channel == selectedRow.Channel &&
            alert.BuildVersion == selectedRow.BuildVersion &&
            alert[AdvisoryFilterNames.Metric] ==
                selectedRow[AdvisoryFilterNames.Metric]
        );
    });

    if (relatedAdvisories.length === 0) return null;

    return relatedAdvisories.reduce((a, b) => {
        return a.CreatedEpochInSeconds > b.CreatedEpochInSeconds ? a : b;
    });
};

export const getMetricDeltaNote = (
    metricDelta: number,
    metric: string,
    advisoryType: string,
) => {
    if (metric !== AdvisoryMetricType.CrashRate) {
        return null;
    }
    const metricDeltaFormatted = getFormattedMetricValueWithUnits(
        metric,
        Math.abs(metricDelta),
    );
    const metricDeltaSign: string = metricDelta > 0 ? "+" : "";
    const metricDeltaWithSign = `${metricDeltaSign}${metricDeltaFormatted}`;

    const metricDeltaNoteTemplate =
        advisoryType === AdvisoryTypeV2.MetricOutOfRangeSameBuild
            ? AdvisoriesFlyoutDetails.SameBuildMetricDeltaNote
            : AdvisoriesFlyoutDetails.BaselineBuildMetricDeltaNote;

    return stringFormat(metricDeltaNoteTemplate, metricDeltaWithSign);
};

export const getMetricAnnotationType = (
    metricDelta: number,
    metric: string,
): AnnotationType => {
    if (metric !== AdvisoryMetricType.CrashRate) {
        return null;
    }

    if (metricDelta < 0) {
        return AnnotationType.positive;
    } else if (metricDelta > 0) {
        return AnnotationType.negative;
    }

    return AnnotationType.neutral;
};

export const createMultiCountRows = (
    selectedRow: CustomDetailsItemsType,
    latestAdvisory: CustomDetailsItemsType,
    areAdvisoriesEqual: boolean,
): IMultiCountItemProps[] => {
    const countRows = [selectedRow];

    // if not latest then add
    if (!areAdvisoriesEqual) {
        countRows.push(latestAdvisory);
    }

    return countRows.map((advisory): IMultiCountItemProps => {
        const metricDelta: number =
            (advisory.MetricValue as number) -
            (advisory.ComparedMetricValue as number);

        const metric = advisory[AdvisoryFilterNames.Metric] as string;
        const color = multiCountRowColor; // constant color
        return {
            data: getFormattedMetricValueWithUnits(
                metric,
                Number(advisory.MetricValue),
            ),
            styles: {
                data: { color },
            },
            bodyText:
                !areAdvisoriesEqual && isEqual(advisory, latestAdvisory)
                    ? AdvisoriesFlyoutDetails.LatestUpdated
                    : AdvisoriesFlyoutDetails.Deterioration,
            annotationText: getMetricDeltaNote(
                metricDelta,
                metric,
                advisory.AdvisoryType as string,
            ),
            annotationType: getMetricAnnotationType(metricDelta, metric),
        };
    });
};

export const getMetadataList = (
    selectedRow: CustomDetailsItemsType,
    latestAdvisory: CustomDetailsItemsType,
    areAdvisoriesEqual: boolean,
): JSX.Element => {
    if (isNil(selectedRow)) return null;
    const severityType = selectedRow[AdvisoryFilterNames.Type] as string;
    const trendType = selectedRow[AdvisoryFilterNames.Trend] as string;
    const icon = getSeverityIcon(severityType, trendType);

    const metaDataItems: JSX.Element[] = [
        <MetaDataItem
            key="officeAppHealthAlertDetailsFlyoutProductDataItem"
            header={AdvisoriesMetadataTitles.Product}
            body={
                <MiniApplicationTitle
                    icon={appStyles[selectedRow.Product]?.iconName}
                    score={selectedRow.Product}
                />
            }
        />,
        <MetaDataItem
            key="officeAppHealthAlertDetailsFlyoutMetric"
            header={AdvisoriesMetadataTitles.Metric}
            body={
                AdvisoriesMetricNameResolver[selectedRow[AdvisoryFilterNames.Metric]]
            }
        />,
        <MetaDataItem
            key="officeAppHealthAlertDetailsFlyoutSeverityDataItem"
            header={AdvisoriesMetadataTitles.Severity}
            body={
                <span>
                    {icon} {selectedRow.Severity}
                </span>
            }
        />,
    ];

    if (selectedRow[AdvisoryFilterNames.Metric] === AdvisoryMetricType.CrashRate) {
        metaDataItems.push(
            <MetaDataItem
                key="officeAppHealthAlertDetailsFlyoutImpactedDevicesDataItem"
                header={AdvisoriesMetadataTitles.Devices}
                body={<span>{selectedRow.DeviceCount}</span>}
            />,
        );
    }

    metaDataItems.push(
        <MetaDataItem
            key="officeAppHealthAlertDetailsFlyoutImpactedBuildDataItem"
            header={AdvisoriesMetadataTitles.DeteriotedBuid}
            body={
                <span>
                    {selectedRow.Build} in {selectedRow.Channel}
                </span>
            }
        />,
    );

    if (selectedRow.AdvisoryType === AdvisoryTypeV2.MetricOutOfRangeBaselineBuild) {
        metaDataItems.push(
            <MetaDataItem
                key="officeAppHealthAlertDetailsFlyoutBaselineBuildDataItem"
                header={AdvisoriesMetadataTitles.BaselineBuild}
                body={
                    <span>
                        {selectedRow.ComparedBuild} in {selectedRow.Channel}
                    </span>
                }
            />,
        );
    }

    metaDataItems.push(
        <MetaDataItem
            key="officeAppHealthAlertDetailsFlyoutMetricValueDataItem"
            header={
                AdvisoriesMetricNameResolver[selectedRow[AdvisoryFilterNames.Metric]]
            }
            body={
                <div>
                    <MultiCount
                        items={createMultiCountRows(
                            selectedRow,
                            latestAdvisory,
                            areAdvisoriesEqual,
                        )}
                    />
                </div>
            }
            styles={{ root: { minWidth: 300 } }} // so that it is always rendered in new line
        />,
    );
    return <MetaDataList>{metaDataItems}</MetaDataList>;
};
const showLatestAdvisoryLink = (
    areAdvisoriesEqual: boolean,
    latestAdvisory: CustomDetailsItemsType,
    setSelectedRow: React.Dispatch<React.SetStateAction<CustomDetailsItemsType>>,
) => {
    return areAdvisoriesEqual || isNil(latestAdvisory) ? (
        <div />
    ) : (
        <MessageBar>
            <span>{AdvisoriesFlyoutDetails.LatestAdvisory}</span>
            &nbsp;
            <Link
                id="officeAppHealthShowLatestAlertLink"
                onClick={() => setSelectedRow(latestAdvisory)}
            >
                {AdvisoriesFlyoutDetails.SeeLatestAdvisory}
            </Link>
        </MessageBar>
    );
};
const onRenderHeader = (selectedRow: CustomDetailsItemsType) => {
    const severityType = selectedRow[AdvisoryFilterNames.Type] as string;
    const trendType = selectedRow[AdvisoryFilterNames.Trend] as string;
    const icon = getSeverityIcon(severityType, trendType);

    return (
        <DetailPanelV2Header
            title={
                <span>
                    {icon}&nbsp;{selectedRow.Message}
                </span>
            }
            titleTooltipHostProps={{
                content: null,
            }}
        />
    );
};

const onRenderBody = (
    selectedRow: CustomDetailsItemsType,
    latestAdvisory: CustomDetailsItemsType,
    setSelectedRow: React.Dispatch<React.SetStateAction<CustomDetailsItemsType>>,
    id: string,
    date: string,
    areAdvisoriesEqual: boolean,
) => (
    <>
        <div>
            {showLatestAdvisoryLink(
                areAdvisoriesEqual,
                latestAdvisory,
                setSelectedRow,
            )}
        </div>
        <div>{selectedRow.Description}</div>
        <div style={{ paddingTop: 20 }}>
            <span role={"heading"} className="flyoutPanelHeader">
                {AdvisoriesRecommendedActions.Header}
            </span>
            <p style={{ marginTop: 5 }}>{selectedRow.RecommendedAction}</p>
        </div>
        {selectedRow &&
            selectedRow[AdvisoryFilterNames.Metric] !==
                AdvisoryMetricType.CrashRate && (
                <Link
                    target="blank"
                    href="https://go.microsoft.com/fwlink/?linkid=2167660"
                >
                    {AdvisoriesFlyoutDetails.LearnMorePerfAdvisories}
                </Link>
            )}
        <div
            role={"heading"}
            className="flyoutPanelHeader"
            style={{ marginBottom: 5 }}
        >
            {AdvisoriesFlyoutDetails.DeteriotedMetricDetailsHeader}
        </div>
        {getMetadataList(selectedRow, latestAdvisory, areAdvisoriesEqual)}
        <div style={{ paddingTop: 20 }}>
            <span role={"heading"} className="flyoutPanelHeader">
                {
                    AdvisoriesMetricChartHeaders[
                        selectedRow[AdvisoryFilterNames.Metric]
                    ]
                }
            </span>
            <AdvisoriesFlyoutHistoricChart
                selectedRow={selectedRow}
                id={id}
                date={date}
            />
        </div>
    </>
);

export const AdvisoriesFlyoutPanel = ({
    isOpen,
    setOpen,
    selectedRow,
    setSelectedRow,
    dataItems,
    id,
    date,
}: FlyoutPanelProps) => {
    const latestAdvisory = getLatestAdvisory(
        selectedRow as CustomDetailsItemsType,
        dataItems,
    );
    const areAdvisoriesEqual = isEqual(latestAdvisory, selectedRow);
    logFeatureUsage("Apps Admin Center - Advisories Flyout Panel");
    return (
        <div>
            <DetailPanelV2
                isOpen={isOpen}
                type={PanelType.medium}
                isLightDismiss={true}
                onDismiss={() => setOpen(false)}
                onLightDismissClick={() => setOpen(false)}
                onRenderHeader={() =>
                    onRenderHeader(selectedRow as CustomDetailsItemsType)
                }
                onRenderBody={() =>
                    onRenderBody(
                        selectedRow as CustomDetailsItemsType,
                        latestAdvisory,
                        setSelectedRow,
                        id,
                        date,
                        areAdvisoriesEqual,
                    )
                }
                closeButtonAriaLabel="Close"
            />
        </div>
    );
};
