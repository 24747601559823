export const retention: string = `
    Retention
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Application == "\${app}" and Host =="\${host}" and UiHost =="\${uiHost}" and AllOrNewUsers =="\${userType}" and IsEdit==\${isEdit}
    | project-away Tpid, OmsTenantId, AllOrNewUsers, IsEdit, Host, UiHost, Application, MonthsAgo, DaysSinceEndOfMonth
    | order by StartingCohortMonth asc
    | project StartingCohortMonth = format_datetime(StartingCohortMonth, 'yyyy-MM-dd'), UserCount, RetentionRate_2ndPeriod, RetentionRate_3rdPeriod, RetentionRate_4thPeriod,
        RetentionRate_5thPeriod, RetentionRate_6thPeriod, RetentionRate_7thPeriod, RetentionRate_8thPeriod, RetentionRate_9thPeriod, RetentionRate_10thPeriod,
        RetentionRate_11thPeriod, RetentionRate_12thPeriod
`;
