import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { LegendList } from "components/CustomComponents/LegendList/LegendList";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import PerformanceCharts from "pages/Performance/PerformanceCharts";
import PerformanceTitle from "pages/Performance/PerformanceTitle";
import { Win32PerformanceEnvironment } from "pages/Performance/Win32PerformanceEnvironment";
import { Win32PerformanceInputBar } from "pages/Performance/Win32PerformanceInputBar";
import { legendReducer } from "components/CustomComponents/LegendList/LegendReducer";
import { commonPageBlock, commonPageStyle } from "pages/common";
import PerformanceContext from "pages/Performance/PerformanceContext";
import { PerformanceProps, PerfQueryParams } from "pages/Performance/types";
import {
    getInitialPerfQueryParams,
    getStartDate,
    getUpdatedPerfQueryParams,
    parseParams,
} from "pages/Performance/Win32PerformanceHelper";
import "pages/common.css";
import AppInsights from "utils/AppInsights";
import { computeQueryParams } from "utils/Helpers";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

const Win32Performance = () => {
    const pageName = "Office Win32 Performance";
    AppInsights.getInstance().TrackPage(pageName);
    document.title = pageName;
    const [legendState, dispatch] = useReducer(legendReducer, []);

    const location = useLocation();
    const path = location.search;
    const [perfQueryParamsState, setPerfQueryParamsState] =
        useState<PerfQueryParams>(
            getInitialPerfQueryParams(parseParams(path) as Partial<PerfQueryParams>),
        );

    const isMonthly = perfQueryParamsState.dataPoints === "Monthly";

    const { id, level } = perfQueryParamsState;

    const [error, setError] = useState<string>(null);

    useSendLaunchEvent("Office Win32 Performance Dashboard");

    // Update the URL when necessary
    useEffect(() => {
        window.history.pushState(
            {},
            "",
            window.location.pathname + computeQueryParams(perfQueryParamsState),
        );
    }, [perfQueryParamsState]);

    const startDateString: string = getStartDate(
        perfQueryParamsState.date,
        perfQueryParamsState.timespan,
        isMonthly,
    );

    const selectedOptions: PerformanceProps = {
        partitionBy: perfQueryParamsState.partitionBy,
        tenantId: id,
        level,
        valuesToShow: perfQueryParamsState.valuesToShow,
        isMonthlyView: isMonthly,
        endDate: perfQueryParamsState.date,
        startDate: startDateString,
        dispatcher: dispatch,
        legend: legendState,
    };

    const onQueryParamChange = (updates: Partial<PerfQueryParams>) => {
        dispatch({ type: "clearAndSetToAll", payload: "" });
        setPerfQueryParamsState(
            getUpdatedPerfQueryParams(perfQueryParamsState, updates),
        );
    };
    // UI Rendering definition.
    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack disableShrink styles={commonPageStyle}>
            <PerformanceTitle
                pageName={pageName}
                id={id}
                level={level}
                setError={setError}
            />
            <Pivot linkSize="large">
                <PivotItem headerText="Performance">
                    <Stack disableShrink styles={commonPageStyle}>
                        <Win32PerformanceInputBar
                            queryParams={perfQueryParamsState}
                            onInputChange={onQueryParamChange}
                        />
                        <Stack.Item styles={commonPageBlock}>
                            <LegendList legend={legendState} dispatcher={dispatch} />
                            <PerformanceContext.Provider value={selectedOptions}>
                                <PerformanceCharts />
                            </PerformanceContext.Provider>
                        </Stack.Item>
                    </Stack>
                </PivotItem>
                <PivotItem headerText="Environment" itemKey="Environment">
                    <Win32PerformanceEnvironment id={id} level={level} />
                </PivotItem>
            </Pivot>
        </Stack>
    );
};

export default Win32Performance;
