import addDays from "date-fns/addDays";
import format from "date-fns/format";
import parse from "date-fns/parse";
import {
    ConsumerGrowthScorecardFiltersType,
    // ConsumerGrowthScorecardFiltersType,
    ConsumerGrowthScorecardNoteItemType,
} from "components/ConsumerGrowthScorecardComponents/types";
import { AzureAPIPermissions, azureFunctionConfig } from "config/AuthConfig";
import {
    ICosmosDbConnectionConfig,
    consumerScorecardDbConfig,
    notesDbConfig,
    outagesDbConfig,
} from "config/CosmosDbConfig";
import { logException } from "utils/AppInsightsHelper";
import AuthenticationUtil from "utils/AuthenticationUtil";
import { Severity, TenantInsightsException } from "utils/Exceptions";

// Must be kept in sync with values in the function app
enum CosmosDbQueryType {
    GetAllExemptionNotes = "GetAllExemptionNotes",
    GetPlatformExemptionNotes = "GetPlatformExemptionNotes",
    GetPlatformExemptionNotesForTpid = "GetPlatformExemptionNotesForTpid",
    UpdateExemptionNote = "UpdateExemptionNote",
    AddExemptionNote = "AddExemptionNote",
    GetAllActiveOutages = "GetAllActiveOutages",
    GetAllConsumerGrowthNotes = "GetAllConsumerGrowthNotes",
    UpdateConsumerGrowthNote = "UpdateConsumerGrowthNote",
    AddConsumerGrowthNote = "AddConsumerGrowthNote",
}

interface CosmosDbRequestBody {
    databaseId: string;
    containerId: string;
    queryType: CosmosDbQueryType;
    queryParameters?: any;
}

/*
    HTTP status codes for successful operations:
        * 200 OK - GET, PUT or POST operation is successful.
        * 201 Created - POST operation to create a resource is successful.
        * 204 No content - DELETE operation is successful.
*/
export class NotesCosmosDbAccess {
    private containerConfig: ICosmosDbConnectionConfig = notesDbConfig;

    public async getExemptionNotes() {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.GetAllExemptionNotes,
            queryParameters: {},
        };
        const response = await callCosmosDbFunction(body);
        return response.json();
    }

    public async getTenantExemptionNotes(platform: string) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.GetPlatformExemptionNotes,
            queryParameters: { platform },
        };
        const response = await callCosmosDbFunction(body);
        return response.json();
    }

    public async getExemptionNotesForTpid(platform: string, tpid: string) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.GetPlatformExemptionNotesForTpid,
            queryParameters: { platform, tpid },
        };
        const response = await callCosmosDbFunction(body);
        return response.json();
    }

    public async updateExemptionNotes(note) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.UpdateExemptionNote,
            queryParameters: note,
        };
        const response = await callCosmosDbFunction(body);
        return response.status === 200;
    }

    public async addExemptionNotes(note) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.AddExemptionNote,
            queryParameters: note,
        };
        const response = await callCosmosDbFunction(body);
        return response.status === 201;
    }
}

export class OutagesCosmosDbAccess {
    private containerConfig: ICosmosDbConnectionConfig = outagesDbConfig;

    public async getOutageNotifications() {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.GetAllActiveOutages,
            queryParameters: {},
        };
        const response = await callCosmosDbFunction(body);
        return response.json();
    }
}

export class ConsumerGrowthScorecardCosmosDbAccess {
    private containerConfig: ICosmosDbConnectionConfig = consumerScorecardDbConfig;

    public async getAllNotes(filters: ConsumerGrowthScorecardFiltersType) {
        // todo: order is important - get all notes that are across weeks and then notes that are for selected week
        const endOfWeek = format(
            addDays(parse(filters.date, "yyyy-MM-dd", new Date()), 6),
            "yyyy-MM-dd",
        );

        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.GetAllConsumerGrowthNotes,
            queryParameters: {
                application: filters.application,
                date: filters.date,
                endOfWeek,
            },
        };
        const response = await callCosmosDbFunction(body);
        return response.json();
    }

    public async updateNote(note: ConsumerGrowthScorecardNoteItemType) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.UpdateConsumerGrowthNote,
            queryParameters: note,
        };
        const response = await callCosmosDbFunction(body);
        return response.status === 200;
    }

    public async addNote(note: ConsumerGrowthScorecardNoteItemType) {
        const body: CosmosDbRequestBody = {
            databaseId: this.containerConfig.database,
            containerId: this.containerConfig.container,
            queryType: CosmosDbQueryType.AddConsumerGrowthNote,
            queryParameters: note,
        };
        const response = await callCosmosDbFunction(body);
        return response.status === 201;
    }
}

export const callCosmosDbFunction = async (body: CosmosDbRequestBody) => {
    try {
        const token = await AuthenticationUtil.getToken(
            AzureAPIPermissions.functionApp,
        );
        const headers = {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=UTF-8",
        };
        const url = `${azureFunctionConfig.CosmosDb}`;
        return await fetch(url, {
            method: "post",
            headers,
            body: JSON.stringify(body),
        });
    } catch (error) {
        logException(
            new TenantInsightsException(Severity.SEV3, "CosmosDbFunctionFailure"),
            {
                message: `"Failure while calling CosmosDB function`,
            },
            error,
        );
    }
};
