import parse from "date-fns/parse";
import * as API from "api/index";
import {
    segmentMetadata,
    segmentOrder,
} from "components/ConsumerCamauComponents/ConsumerCamauHelper";
import {
    ConsumerCamauFiltersType,
    ConsumerCamauRowType,
    ConsumerCamauTableType,
} from "components/ConsumerCamauComponents/types";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { addMonthsToDate } from "utils/Helpers";

export const formatScorecardTableData = (
    mauJson: KustoResponseType<string | number>,
): [ConsumerCamauTableType, string[]] => {
    const mauDataTable = mauJson.Tables[0];

    const formattedData: ConsumerCamauTableType = {};

    if (mauDataTable.Rows.length === 0) {
        return [formattedData, []];
    }
    const distinctDates: {} = {};

    //schema- Date, Application, Segment, MMAU, CAMAU, perCentEditors, TargetMAU, TargetCAMAU, TargetPerCentEditors, VTT, VTT%, VTT % Actual CAMAU
    mauDataTable.Rows.forEach((dataRow) => {
        const date = dataRow[0] as string;
        const app = dataRow[1] as string;
        const platform_operatingSystem = dataRow[2] as string;

        if (!(app in formattedData)) formattedData[app] = [];

        if (!(date in distinctDates)) {
            distinctDates[date] = date;
        }

        formattedData[app].push({
            Date: date,
            Application: app,
            Segment: platform_operatingSystem,
            MAU: dataRow[3] as number,
            CAMAU: dataRow[4] as number,
            CAMAUPct: dataRow[5] as number,
            MAU_Target: dataRow[6] as number,
            CAMAU_Target: dataRow[7] as number,
            CAMAUPct_Target: dataRow[8] as number,
            MAU_VTT: dataRow[9] as number,
            MAU_VTT_Pct: dataRow[10] as number,
            CAMAU_VTT_Pct: dataRow[11] as number,
        });
    });

    Object.keys(formattedData).forEach((app) => {
        formattedData[app].sort(
            (a: ConsumerCamauRowType, b: ConsumerCamauRowType) => {
                // first sort by date
                const dateOrder = b.Date.localeCompare(a.Date);

                if (dateOrder === 0) {
                    return segmentOrder[a.Segment] - segmentOrder[b.Segment];
                }

                return dateOrder;
            },
        );
    });

    return [formattedData, Object.keys(distinctDates)];
};

export const fetchScorecardTabledata = async (
    filters: ConsumerCamauFiltersType,
    queryName: string,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...filters };
    const scorecardData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.CONSUMER_CAMAU,
        queryParams,
    });
    return scorecardData?.data;
};

export const updateScorecardChartData = (
    segment: string,
    application: string,
    monthsToShow: number,
    data: ConsumerCamauTableType,
): ConsumerCamauRowType[] => {
    try {
        const nowDate = new Date();
        const lowestDate = addMonthsToDate(nowDate, -monthsToShow);
        const result = data[application]?.filter((value) => {
            const dateCheck = parse(value.Date, "yyyy-MM-dd", nowDate) > lowestDate;
            // Add a null check here for when the segment metadata is null
            const segmentCheck =
                segmentMetadata[value.Segment]?.displayText === segment;

            return dateCheck && segmentCheck;
        });

        return result.reverse();
    } catch (e) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "ConsumerCamauTrendProcessingFailed",
            ),
            {
                message: "Failed to format Consumer camau metric trends data",
            },
            e,
        );

        return [];
    }
};
