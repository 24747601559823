import React, { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
    ConstrainMode,
    Pivot,
    PivotItem,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    generateColumns,
    getLevelForColumns,
    renderItemColumn,
} from "pages/ExecOverview/ExecOverviewUtils";
import {
    CompositeOverviewTableProps,
    CompositeOverviewTableState,
} from "pages/ExecOverview/types";
import { logTenantColorBasedView } from "utils/AppInsightsHelper";
import { defaultCompositeOverviewHealthColor, PivotSize } from "utils/Constants";
import { extractQueryParams, hashTenantInfo } from "utils/Helpers";
import { NO_DATA_MESSAGES } from "utils/Messages";
import { Filters } from "utils/WebConstants";

export const CompositeOverviewTable = (props: CompositeOverviewTableProps) => {
    const queryParams = useRef(extractQueryParams());
    const queryParamsUrl = useRef(
        props.queryParamsUrl.length !== 0
            ? `${props.queryParamsUrl.substring(1)}`
            : "",
    );
    const subGroupStyling = props.platformConfig.subGroupStyling;

    const [state, setState] = useState<CompositeOverviewTableState>({
        status: defaultCompositeOverviewHealthColor,
        data: [],
        columns: [],
        queryParams,
        loading: props.loading,
        sortOptions: {
            sortKey: props.platformConfig.defaults.sortKey,
            sortedDescending: props.platformConfig.defaults.sortedDescending,
        },
    });

    const onclickTenantStatus = (item: PivotItem): any => {
        setState((state) => {
            return {
                ...state,
                status: item.props.itemKey,
            };
        });
        logTenantColorBasedView({
            page: document.title,
            table: "Composite Overview",
            color: item.props.itemKey,
        });
    };

    useEffect(() => {
        queryParams.current = extractQueryParams();
        queryParamsUrl.current =
            props.queryParamsUrl.length !== 0
                ? `${props.queryParamsUrl.substring(1)}`
                : "";
        setState({
            status: defaultCompositeOverviewHealthColor,
            data: props.payload ? props.payload.rows : [],
            columns: generateColumns(
                queryParams["current"]["level"],
                props,
                state.sortOptions,
                setState,
            ),
            queryParams,
            loading: props.loading,
            sortOptions: {
                sortKey: props.platformConfig.defaults.sortKey,
                sortedDescending: props.platformConfig.defaults.sortedDescending,
            },
        });

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        queryParams.current = extractQueryParams();
        queryParamsUrl.current =
            props.queryParamsUrl.length !== 0
                ? `${props.queryParamsUrl.substring(1)}`
                : "";
        const columns = generateColumns(
            queryParams["current"]["level"],
            props,
            state.sortOptions,
            setState,
        );
        if (!columns.some((column) => column.key === state.sortOptions.sortKey)) {
            setState((state) => {
                return {
                    ...state,
                    data: props.payload.rows,
                    columns,
                    queryParams,
                    loading: props.loading,
                };
            });
            return;
        }
        const result = DetailsListHelper.sortItems(
            columns,
            columns.filter((column) => column.key === state.sortOptions.sortKey)[0],
            props.payload.rows,
            true,
        );
        setState((state) => {
            return {
                ...state,
                data: result.items,
                columns: result.columns,
                queryParams,
                loading: props.loading,
            };
        });

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.payload, props.loading]);

    const redactTenantInfo = queryParams["current"]["redact"];
    if (redactTenantInfo === "on") {
        hashTenantInfo(state);
    }

    return state.data.length === 0 && !state.loading ? (
        <ShimmeredDetailsListWrapper
            items={[]}
            message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
        />
    ) : (
        <div className="compositeTable tenantTable">
            <Pivot
                linkSize={PivotSize.large}
                onLinkClick={onclickTenantStatus}
                selectedKey={state.status}
            >
                {Filters.TenantStatusList.filter((color) =>
                    props.showAllColorTab ? true : color !== "All",
                ).map((el) => {
                    return (
                        <PivotItem
                            headerText={el}
                            itemKey={el === "Deep Red" ? "DeepRed" : el}
                            key={el}
                        />
                    );
                })}
            </Pivot>

            <div data-is-scrollable className="pageSection">
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsListWrapper
                        items={
                            state.status !== "All"
                                ? state.data.filter(
                                      (i) => i["Status"] === state.status,
                                  )
                                : cloneDeep(state.data)
                        }
                        message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                        columns={state.columns}
                        enableShimmer={state.loading}
                        selectionMode={SelectionMode.none}
                        compact
                        onRenderRow={DetailsListHelper.renderExpandableRow}
                        onRenderItemColumn={renderItemColumn(
                            getLevelForColumns(
                                props.platformConfig.platform,
                                state.queryParams["current"]["level"],
                            ),
                            props,
                            queryParamsUrl.current,
                        )}
                        onRenderDetailsHeader={(headerProps, defaultrender) => {
                            return DetailsListHelper.renderGroupedDetailsHeader(
                                headerProps,
                                props.platformConfig.groups,
                                false,
                                defaultrender,
                                subGroupStyling,
                            );
                        }}
                        styles={DetailsListHelper.tenantTableStyle}
                        constrainMode={ConstrainMode.unconstrained}
                    />
                </ScrollablePane>
            </div>
        </div>
    );
};
