import React from "react";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Stack,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { commonInnerBlock } from "pages/common";

const _generateColumns = () => {
    const displayColumns = [
        ["continent", "Continent", 150, null],
        ["value", "% Sessions", 200, "alignRightHeader"],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        true,
    );

    return columns;
};

const _renderItemColumn = (item, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName] as string;
    switch (column.key) {
        case "value":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    decimalPrecision={2}
                    postfix="%"
                />
            );
        default:
            return <DetailsListHelper.TextValue value={fieldContent} />;
    }
};

export const SessionDistributionByContinent = (props) => {
    const { payload } = props;

    return (
        <>
            {payload && payload.length !== 0 ? (
                <>
                    <div className="continentTitle">
                        Datacenter distribution of sessions by continent (All Apps)
                    </div>
                    <Stack.Item styles={commonInnerBlock} grow="initial">
                        <DetailsList
                            items={payload}
                            columns={_generateColumns()}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            onRenderItemColumn={_renderItemColumn}
                            selectionMode={SelectionMode.none}
                            setKey="none"
                            compact
                            styles={{
                                root: {
                                    height: 45 + payload.length * 32,
                                    width: 400,
                                },
                            }}
                            className="tenantTable continentCompactStyle"
                        />
                    </Stack.Item>
                </>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
