import React from "react";
import {
    IDetailsRowProps,
    IGroupDividerProps,
    Pivot,
    PivotItem,
} from "@fluentui/react";
import {
    generateTenantListTpidGroups,
    tenantListColumns,
} from "components/SharedComponentFunctionality/TenantListSharedFunctionality";
import TenantDetailsList from "components/TenantDrilldownComponents/TenantList/TenantDetailsList";
import { TenantSearchSuggestions } from "../TenantSearchBar/types";
import { TenantSearchResultDialogProps } from "./types";

const TenantSearchResultsDialog = ({
    tenants,
    isTpidHeaderClickable,
    showTenantStatus,
}: TenantSearchResultDialogProps) => {
    return (
        <Pivot>
            {tenants.map((tenantSearchSuggestion: TenantSearchSuggestions) => {
                const onDetailsRowClick = (props: IDetailsRowProps | undefined) => {
                    tenantSearchSuggestion.callback(
                        props.item.TenantId,
                        "TenantId",
                        tenantSearchSuggestion.workload,
                    );
                };

                const onHeaderRowClick = (props: IGroupDividerProps | undefined) => {
                    if (isTpidHeaderClickable) {
                        tenantSearchSuggestion.callback(
                            props.group.key,
                            "Tpid",
                            tenantSearchSuggestion.workload,
                        );
                    }
                };

                return (
                    <PivotItem
                        headerText={`${tenantSearchSuggestion.headerName} (${tenantSearchSuggestion.rows.length})`}
                        key={tenantSearchSuggestion.headerName}
                    >
                        <div>
                            <TenantDetailsList
                                columns={tenantListColumns}
                                tenantListItems={tenantSearchSuggestion.rows}
                                platform={tenantSearchSuggestion.workload}
                                tpidGroup={generateTenantListTpidGroups(
                                    tenantSearchSuggestion.rows,
                                    true,
                                )}
                                isCompactMode={true}
                                showTenantStatus={showTenantStatus}
                                onHeaderRowClick={onHeaderRowClick}
                                onDetailsRowClick={onDetailsRowClick}
                            />
                        </div>
                    </PivotItem>
                );
            })}
        </Pivot>
    );
};

export default TenantSearchResultsDialog;
