import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@fluentui/react";

interface LinkIconButtonProps {
    message: string;
    link: string;
    iconName?: string;
}

export const LinkIconButton = (props: LinkIconButtonProps) => {
    return (
        <span>
            <Link to={props.link} target="_blank">
                <IconButton
                    iconProps={{
                        iconName: props.iconName ?? "Unknown",
                    }}
                    title={props.message}
                    ariaLabel={props.message}
                />
            </Link>
        </span>
    );
};
