import React, { useEffect, useState } from "react";
import {
    Checkbox,
    getTheme,
    IButtonStyles,
    IconButton,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    TextField,
    Toggle,
    ChoiceGroup,
    IChoiceGroupOption,
    DatePicker,
} from "@fluentui/react";
import { NotesCosmosDbAccess } from "api/CosmosDbAccess";
import { PlatformExemptionNote } from "components/ExemptionPortalComponents/types";
import { logException } from "utils/AppInsightsHelper";
import AuthenticationUtil from "utils/AuthenticationUtil";
import { platformExemptSignals } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { addMonthsToDate } from "utils/Helpers";
import { EXEMPTION_PORTAL_MSG } from "utils/Messages";

const EXEMPT_UNTIL_MONTHS_TO_ADD = 3;
const theme = getTheme();

export const NoteForm = (props) => {
    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "420px",
        },
    });

    const stackTokens = { childrenGap: 2 };
    const [note, setNote] = useState<PlatformExemptionNote>(props.note);
    const [formError, setFormError] = useState({});
    const [exemptDate, setExemptDate] = useState(props.note.ExemptedUntil);

    useEffect(() => {
        setNote(props.note);
        setExemptDate(props.note.ExemptedUntil);
    }, [props.note]);

    useEffect(() => {
        const isExempted: boolean =
            note.IsExempted ||
            platformExemptSignals[note.Platform].some((signal) => note[signal]);

        if (!isExempted) setExemptDate(null);
        else if (isExempted && (exemptDate === null || exemptDate === undefined))
            setExemptDate(addMonthsToDate(new Date(), EXEMPT_UNTIL_MONTHS_TO_ADD));
    }, [exemptDate, note]);

    const closeForm = () => {
        setFormError({});
        props.handleClose();
    };

    if (!props.showForm) {
        return null;
    }

    const setUpdatedField = (field: string, newValue: string | boolean) => {
        const updateNote = { ...note };
        updateNote[field] = newValue;
        setNote(updateNote);
        if (field === "ExemptedUntil") setExemptDate(newValue);
    };

    const updateTenantId = (isChecked: boolean) => {
        if (isChecked) {
            setUpdatedField("OmsTenantId", "All");
        } else {
            setUpdatedField("OmsTenantId", "");
        }
    };

    const validateForm = (): boolean => {
        const err = {};
        if (note.Tpid === undefined || note.Tpid.length === 0)
            err["Tpid"] = "Required";
        if (note.OmsTenantId === undefined || note.OmsTenantId.length === 0)
            err["OmsTenantId"] = "Required";
        setFormError(err);
        return Object.keys(err).length === 0;
    };

    const formatForm = (): PlatformExemptionNote => {
        return {
            ...note,
            Tpid: note.Tpid.trim(),
            OmsTenantId: note.OmsTenantId.trim(),
        };
    };

    /**
     * This function preserves the selections for common signals like MAU, MEU etc that are present
     * in all platforms while switching between platforms
     */
    const updateNotePreservingCommonSignalChoices = (
        note: PlatformExemptionNote,
    ): PlatformExemptionNote => {
        const otherPlatformSignals = [];
        Object.keys(platformExemptSignals).forEach((platform) => {
            if (platform !== note.Platform) {
                platformExemptSignals[platform].forEach((signal) => {
                    if (!platformExemptSignals[note.Platform].includes(signal))
                        otherPlatformSignals.push(signal);
                });
            }
        });

        otherPlatformSignals.forEach((signal) => {
            if (signal in note) delete note[signal];
        });

        return note;
    };

    const submitNote = async () => {
        if (!validateForm()) return;
        let temp = formatForm();
        temp = updateNotePreservingCommonSignalChoices(temp);
        let resp;
        temp.LastUpdatedBy = AuthenticationUtil.getLoggedInUser();
        temp.LastUpdatedDate = new Date().toLocaleDateString();
        temp.ExemptedUntil = exemptDate
            ? new Date(exemptDate).toLocaleDateString()
            : null;
        try {
            if (props.isNewNote) {
                temp.Date = new Date().toLocaleDateString();
                temp.AddedBy = AuthenticationUtil.getLoggedInUser();
                temp.IsArchived = false;
                resp = await new NotesCosmosDbAccess().addExemptionNotes(temp);
            } else {
                resp = await new NotesCosmosDbAccess().updateExemptionNotes(temp);
            }
            props.setSuccessMessage(
                resp
                    ? `Note was successfully ${
                          props.isNewNote ? "added" : "edited"
                      }.`
                    : EXEMPTION_PORTAL_MSG.SOMETHING_WENT_WRONG,
            );
        } catch (error) {
            props.setSuccessMessage(EXEMPTION_PORTAL_MSG.SOMETHING_WENT_WRONG);
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "ExemptionNoteAddOrUpdateFailed",
                ),
                {
                    message: "Failure adding/updating exemption note",
                    isNewNote: props.isNewNote,
                    note: {
                        ...temp,
                    },
                },
                error,
            );
        }
        props.setHide(false);
        closeForm();
        props.refresh();
    };

    const onPlatformChange = (
        ev: React.FormEvent<HTMLInputElement>,
        option: IChoiceGroupOption,
    ) => {
        setNote(
            updateNotePreservingCommonSignalChoices({
                ...note,
                Platform: option.key,
            }),
        );
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            {
                fontWeight: "Bold",
                fontSize: "Larger",
                borderTop: `2px solid ${theme.palette.themePrimary}`,
                padding: "20px 0px 10px 40px",
            },
        ],
        body: {
            height: "700px",
            padding: "0px 30px 30px 40px",
        },
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: "270px",
            marginRight: "10px",
        },
    };

    return (
        <Modal
            isOpen={props.showForm}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header} data-testid="noteFormModalHeader">
                <span>Engagement Notes</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel="Close"
                    onClick={closeForm}
                />
            </div>
            <div className={contentStyles.body}>
                <DatePicker
                    label="Date"
                    isRequired={true}
                    isMonthPickerVisible={true}
                    className={controlClass.control}
                    placeholder="Select a date"
                    ariaLabel="Select a date"
                    value={note.Date ? new Date(note.Date) : new Date()}
                    onSelectDate={(date) =>
                        setUpdatedField("Date", date.toLocaleDateString())
                    }
                    disabled={true}
                />
                <Toggle
                    onText="Tpid level note"
                    offText="TenantId level note"
                    defaultChecked={note.OmsTenantId === "All"}
                    onChange={(ev, isChecked) => updateTenantId(isChecked)}
                    checked={note.OmsTenantId === "All"}
                />
                <TextField
                    label="Tpid"
                    className={controlClass.control}
                    required
                    defaultValue={note.Tpid}
                    onChange={(ev, text) => setUpdatedField("Tpid", text)}
                    errorMessage={formError["Tpid"]}
                />
                <TextField
                    label="TenantId"
                    className={controlClass.control}
                    required
                    defaultValue={note.OmsTenantId}
                    onChange={(ev, text) => setUpdatedField("OmsTenantId", text)}
                    errorMessage={formError["OmsTenantId"]}
                    disabled={note.OmsTenantId === "All"}
                    value={note.OmsTenantId}
                />
                <Toggle
                    label="Do you want to exempt the tenant from Red and Deep Red status?"
                    onText="Yes"
                    offText="No"
                    defaultChecked={note.IsExempted}
                    onChange={(ev, checked) =>
                        setUpdatedField("IsExempted", checked)
                    }
                />
                <ChoiceGroup
                    defaultSelectedKey={note.Platform}
                    options={Object.keys(platformExemptSignals).map((signal) => {
                        return {
                            key: signal,
                            text: signal,
                        };
                    })}
                    onChange={onPlatformChange}
                    label="Platform"
                    required={true}
                />
                <br />
                <Label>
                    Do you want to exempt any specific core health metric?
                    <br />
                    (Choose all that apply)
                </Label>
                <Stack tokens={stackTokens}>
                    {platformExemptSignals[note.Platform].map((signal) => {
                        return (
                            <Checkbox
                                key={note.Platform.concat(signal)}
                                label={signal.replace("Exempt", "")}
                                defaultChecked={note[signal]}
                                onChange={(ev, isChecked) =>
                                    setUpdatedField(signal, isChecked)
                                }
                            />
                        );
                    })}
                    <br />
                </Stack>
                <DatePicker
                    label="Exemption End Date (defaulted to 3 months from today)"
                    className={controlClass.control}
                    isMonthPickerVisible={true}
                    placeholder="Select a date"
                    ariaLabel="Select a date"
                    value={exemptDate ? new Date(exemptDate) : null}
                    onSelectDate={(date) =>
                        setUpdatedField("ExemptedUntil", date.toLocaleDateString())
                    }
                    disabled={!exemptDate}
                />
                <Toggle
                    label="Was the tenant moved out of unhealthy status?"
                    onText="Yes"
                    offText="No"
                    defaultChecked={note.MovedToHealthyStatus}
                    onChange={(ev, checked) =>
                        setUpdatedField("MovedToHealthyStatus", checked)
                    }
                />
                <TextField
                    label="Notes"
                    multiline
                    rows={5}
                    placeholder="Add notes"
                    className={controlClass.control}
                    defaultValue={note.Notes}
                    onChange={(ev, text) => setUpdatedField("Notes", text)}
                />
                <TextField
                    label="Alias"
                    className={controlClass.control}
                    defaultValue={AuthenticationUtil.getLoggedInUser()}
                    disabled
                />
                <PrimaryButton
                    style={{ margin: "auto" }}
                    text="Submit"
                    onClick={submitNote}
                    data-testid="noteSubmitBtn"
                />
            </div>
        </Modal>
    );
};
