import { descending } from "d3-array";
import * as API from "api";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { setBlankIfEmpty } from "utils/Helpers";
import { formatDisplayDate } from "utils/Helpers";

export function formatErrorOptions(data: any, filters: {}) {
    const errorsTable = data.errorNames.Tables[0];
    const datesTable = data.dateOptions.Tables[0];
    const errorNames = [];
    const dateOptions = [];
    let isFirstErrorFound = false;

    dateOptions.push({ key: "All", text: "All" });

    const uniqueDates = Array.from(new Set(datesTable.Rows)).sort(descending);
    const filterVals = {
        date: filters["date"] ? filters["date"] : uniqueDates[0][0],
    };
    uniqueDates.forEach((a) =>
        dateOptions.push({ key: a[0], text: formatDisplayDate(a[0]) }),
    );

    errorsTable.Rows.forEach((element) => {
        // get  the first non number error
        const error = element[0];
        if (!isFirstErrorFound && Number.isNaN(+error.charAt(0))) {
            filterVals["errorname"] = error;
            isFirstErrorFound = true;
        }
        errorNames.push({ key: error, text: error });
    });

    return {
        errorNames,
        dateOptions,
        filterVals,
    };
}

export function formatErrorTenants(data: any, filters: {}) {
    const tenantsImpactedTable = data.tenantsImpacted.Tables[0];
    const cohortAvgDataTable = data.cohortAvgData.Tables[0];
    const tenantCountTable = data.tenantsImpactedCount.Tables[0];
    const hostCountTable = data.tenantsImpactedByHost.Tables[0];
    const uiHostCountTable = data.tenantsImpactedByUiHost.Tables[0];
    const cohortAvgCountDateAllTable = data.cohortImpactCountAllDates.Tables[0];

    const tenantsImpacted: any[] = [];
    const cohortData = {};
    const tenantsImpactedCount = [];
    const tenantsImpactedByHost = [];
    const tenantsImpactedByUiHost = [];
    const isDateAll = filters["date"] === "All";

    // Schema - Host, count
    hostCountTable.Rows.forEach((element) => {
        tenantsImpactedByHost.push({
            name: element[0],
            "# Tenants": element[1],
        });
    });

    // Schema- UiHost, count
    uiHostCountTable.Rows.forEach((element) => {
        tenantsImpactedByUiHost.push({
            name: setBlankIfEmpty(element[0]),
            "# Tenants": element[1],
        });
    });

    // Schema -  Date, CohortTenantCount, CohortPercentageSessionsWithError, CohortPercentageUsersWithError
    cohortAvgDataTable.Rows.forEach((element) => {
        const tmp = {};
        tmp["CohortTenantCount"] = element[1];
        tmp["CohortPercentageSessionsWithError"] = element[2];
        tmp["CohortPercentageUsersWithError"] = element[3];

        cohortData[element[0]] = tmp;
    });

    // To Calculate the tenant count for Date = All and dedupe
    const tenantCountAll: Set<string> = new Set<string>();

    // Schema - \${id}, Name, Industry,ErrorType, UserErrorCount, PercentUsersWithError, SessionErrorCount, PercentSessionsWithError, (Date)
    // if Date == All then schema will have 9 columns, Date will be the last
    tenantsImpactedTable.Rows.forEach((element) => {
        let cohortPercentageUsersWithError = "";
        let cohortPercentageSessionsWithError = "";
        const tempDate = isDateAll ? element[8] : filters["date"];

        // to calculate the total tenant counts for Date= All, put all tenantids in the set and take a count.
        if (isDateAll) tenantCountAll.add(element[0]);

        cohortPercentageUsersWithError =
            cohortData[tempDate]?.["CohortPercentageUsersWithError"];
        cohortPercentageSessionsWithError =
            cohortData[tempDate]?.["CohortPercentageSessionsWithError"];

        tenantsImpacted.push({
            Name: element[1],
            id: element[0],
            Industry: element[2],
            "Error Type": element[3],
            "# Users with Error": element[4],
            "% Users With Error": element[5],
            [`${filters["cohort"]} Avg% Users With Error`]:
                cohortPercentageUsersWithError,
            "# Sessions With Error": element[6],
            "% Sessions With Error": element[7],
            [`${filters["cohort"]} Avg% Sessions With Error`]:
                cohortPercentageSessionsWithError,
            Date: formatDisplayDate(tempDate),
        });
    });

    // schema - Date, count
    tenantCountTable.Rows.forEach((element) => {
        const dt = element[0];

        if (dt in cohortData)
            tenantsImpactedCount.push({
                Date: formatDisplayDate(dt),
                "All Cohort TenantCount": element[1],
                [`${filters["cohort"]} TenantCount`]:
                    cohortData[dt]["CohortTenantCount"],
            });
    });

    // schema - Count, there will be just one row with count at 0th index
    // put into tenantCount, put it at last index so that when date != all, just pop from array
    cohortAvgCountDateAllTable.Rows.forEach((element) => {
        const count = element[0];

        tenantsImpactedCount.push({
            Date: "All",
            "All Cohort TenantCount": tenantCountAll.size,
            [`${filters["cohort"]} TenantCount`]: count,
        });
    });

    return {
        tenantsImpacted,
        cohortData,
        tenantsImpactedCount,
        tenantsImpactedByHost,
        tenantsImpactedByUiHost,
    };
}

export const fetchErrorNames = async (filters: {}, setState, setFilters) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const errorNameResponses = await API.getErrorNames(filters);
        const errorNames = formatErrorOptions(errorNameResponses, filters);
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                errorNames: errorNames["errorNames"],
                dateOptions: errorNames["dateOptions"],
            };
        });
        setFilters((filters) => {
            return {
                ...filters,
                ...errorNames["filterVals"],
            };
        });
    } catch (error) {
        {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "ErrorNamesFetchOrProcessFailed",
                ),
                {
                    message: "Failure while fetching or formatting error names",
                    filters,
                },
                error,
            );
            setState((state) => {
                return {
                    ...state,
                    isFetching: false,
                };
            });
        }
    }
};

export const fetchErrorLookup = async (filters: {}, setState) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const errorTenantsResponse = await API.getErrorTenants(filters);
        const errorTenants = formatErrorTenants(errorTenantsResponse, filters);
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                tenantsImpacted: errorTenants["tenantsImpacted"],
                cohortData: errorTenants["cohortData"],
                tenantsImpactedCount: errorTenants["tenantsImpactedCount"],
                tenantsImpactedByHost: errorTenants["tenantsImpactedByHost"],
                tenantsImpactedByUiHost: errorTenants["tenantsImpactedByUiHost"],
            };
        });
    } catch (error) {
        {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "ErrorLookUpDataFetchOrProcessFailed",
                ),
                {
                    message:
                        "Failure while fetching or formatting error lookup data",
                    filters,
                },
                error,
            );
            setState((state) => {
                return {
                    ...state,
                    isFetching: false,
                };
            });
        }
    }
};

export function formatChartDataForError(data: any, cohortData: {}, filters: {}) {
    const chartTable = data.chartData.Tables[0];
    const chartData = [];

    // Schema- Date, PercentUserWithError, PercentSessionWithError
    chartTable.Rows.forEach((element) => {
        const Date = element[0];
        const tmp = { Date: formatDisplayDate(Date) };
        tmp[`${filters["errorname"]}_onUsers`] = element[1];
        tmp[`${filters["cohort"]}_${filters["errorname"]}_onUsers`] =
            cohortData[Date]["CohortPercentageUsersWithError"];
        tmp[`${filters["errorname"]}_onSessions`] = element[2];
        tmp[`${filters["cohort"]}_${filters["errorname"]}_onSessions`] =
            cohortData[Date]["CohortPercentageSessionsWithError"];
        chartData.push(tmp);
    });

    return { chartData };
}

export async function fetchErrorLookupChart(filters: {}, cohortData: {}, setState) {
    try {
        const chartDataResponse = await API.getChartDataForError(filters);
        const chartata = formatChartDataForError(
            chartDataResponse,
            cohortData,
            filters,
        );
        setState((state) => {
            return {
                ...state,
                chartData: chartata["chartData"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "ErrorLookUpChartDataFetchOrProcessFailed",
            ),
            {
                message:
                    "Failure while fetching or formatting error lookup chart data",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
            };
        });
    }
}
