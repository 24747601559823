import { isTest } from "utils/WebConstants";

export const webTenantStatus: string = `
//webTenantStatus
let currDate = GetCurrentDate(${isTest});
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let levelName = "\${levelColumnName}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    Score
    | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app and UiHost == "All" and Mau > \${mauCountThreshold}
    | extend Score = CompositeScore, CurrentStatus = CompositeScore_Color
    | join kind= inner
    (
            Score
            | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Date == prev and Application == app and UiHost == "All"
            | extend Score = CompositeScore, PreviousStatus = CompositeScore_Color
    )
    on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend ScoreDelta =  round(Score-Score1,2)
    | join kind=inner
        (StrategicTenants
            | where Date == currDate and Is\${cohort} == true
            | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName}
    | join kind=inner (
        PastStatus
        | where Application == app and UiHost == 'All' and Date == currDate
        | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
    ) on Tpid, OmsTenantId
    | extend Score = round(Score, 2)
    | extend ScoreDelta = round(Score-Score1,2)
    | extend ActiveUsersMom = round(MoM_ActiveUsers*100,2)
    | extend MauMom = round(todouble(Mau-PreviousMonthMau)/PreviousMonthMau*100,2)
    | extend MauRateMom = round(MoM_MauRate*100,2)
    | extend MeuRateMom = round(MoM_MeuRate*100,2)
    | extend EngEditorsMom = round(MoM_EngagedEditorsRate*100,2)
    | extend ReturningMauMom = round(MoM_ReturningMauRate*100,2)
    | extend EUPLP95Mom = round(MoM_EUPLP95*100,2)
    | extend EUPLP50Mom = round(MoM_EUPLP50*100,2)
    | extend ACEFreeMom = round(MoM_ACEFreeUsersRate*100,2)
    | extend ICEFreeMom = round(MoM_ICEFreeUsersRate*100,2)
    | extend Feedback_MoMChange = round(MoM_Feedback*100,2)
    | extend OpenInClientMom = round(MoM_OpenInClientRate*100,2)
     | project \${level} = \${levelColumnName}, OrgName =  Name,
        Composite_Score = Score, Composite_MoMChange = ScoreDelta,
        ActiveUsers_Score = ActiveUsers, ActiveUsers_MoMChange = ActiveUsersMom,
        MAU_Score = Mau, MAU_MoMChange = MauMom,
        MAURate_Score = round(MauRate*100, 2), MAURate_Score_Color = Color_Mau, MAURate_MoMChange = MauRateMom,
        MEURate_Score = round(MeuRate*100, 2), MEURate_Score_Color = Color_Meu, MEURate_MoMChange = MeuRateMom,
        EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors, EngagedEditors_MoMChange = EngEditorsMom,
        Retention_Score = round(ReturningMauRate*100, 2), Retention_Score_Color = Color_ReturningMau, Retention_MoMChange=ReturningMauMom,
        EUPL_P95_Score = round(EUPL_P95, 2), EUPL_P95_Score_Color = Color_Performance, EUPL_P95_MoMChange=EUPLP95Mom,
        EUPL_P50_Score = round(EUPL_P50, 2), EUPL_P50_Score_Color = Color_Performance, EUPL_P50_MoMChange=EUPLP50Mom,
        ACEFreeUsers_Score = round( ACEFreeUsersRate*100, 2), ACEFreeUsers_Score_Color = Color_ACEFreeUsers, ACEFreeUsers_MoMChange=ACEFreeMom,
        ICEFreeUsers_Score = round( ICEFreeUsersRate*100, 2), ICEFreeUsers_Score_Color = Color_ICEFreeUsers, ICEFreeUsers_MoMChange = ICEFreeMom,
        Feedback_Score = round(Feedback_SubScore, 2), Feedback_Score_Color = Color_Feedback, Feedback_MoMChange,
        OpenInClient_Score = round(OpenInClientRate*100, 2), OpenInClient_Score_Color = Color_OpenInClient, OpenInClient_MoMChange = OpenInClientMom,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
`;

export const webTenantStatus_V2: string = `
//webTenantStatus
let currDate = GetCurrentDate(${isTest});
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let levelName = "\${levelColumnName}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
Score_V2
    | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app and UiHost == "All" and Mau > \${mauCountThreshold}
    | extend Score = CompositeScore, CurrentStatus = CompositeScore_Color
    | join kind= inner
    (
        Score_V2
            | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Date == prev and Application == app and UiHost == "All"
            | extend Score = CompositeScore,
                                PreviousStatus = CompositeScore_Color,
                                PreviousMauRate = MauRate,
                                PreviousMeuRate = MeuRate,
                                PreviousEngagedEditorsRate = EngagedEditorsRate,
                                PreviousReturningMauRate = ReturningMauRate,
                                PreviousEUPL_P95 = EUPL_P95,
                                PreviousEUPL_P50 = EUPL_P50,
                                PreviousFeedback_SubScore = Feedback_SubScore,
                                PreviousOpenInClientRate = OpenInClientRate
    )
    on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend ScoreDelta =  round(Score-Score1,2)
    | join kind=inner
        (StrategicTenants
            | where Date == currDate and Is\${cohort} == true
            | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName}
    | join kind=inner (
        PastStatus
        | where Application == app and UiHost == 'All' and Date == currDate
        | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
    ) on Tpid, OmsTenantId
    | extend Score = round(Score, 2)
    | extend ScoreDelta = round(Score-Score1,2)
    | extend ActiveUsersMom = round(MoM_ActiveUsers*100,2)
    | extend MauMom = round(todouble(Mau-PreviousMonthMau)/PreviousMonthMau*100,2)
    | extend MauRateMom = round((MoM_MauRate / PreviousMauRate) * 100.0,2)
    | extend MeuRateMom = round((MoM_MeuRate / PreviousMeuRate) * 100.0,2)
    | extend EngEditorsMom = round((MoM_EngagedEditorsRate / PreviousEngagedEditorsRate) * 100.0,2)
    | extend ReturningMauMom = round((MoM_ReturningMauRate / PreviousReturningMauRate) * 100.0 ,2)
    | extend EUPLP95Mom = round((MoM_EUPLP95 / PreviousEUPL_P95) * 100.0,2)
    | extend EUPLP50Mom = round((MoM_EUPLP50 / PreviousEUPL_P50) * 100.0,2)
    | extend ASHAMom = round(ASHA - ASHA1,2)
    | extend Feedback_MoMChange = iff( PreviousFeedback_SubScore == 0, 100.00, round((MoM_Feedback / PreviousFeedback_SubScore) * 100.0,2))
    | extend OpenInClientMom = round((MoM_OpenInClientRate / PreviousOpenInClientRate) *100.0,2)
     | project \${level} = \${levelColumnName}, OrgName =  Name,
        Composite_Score = Score, Composite_MoMChange = ScoreDelta,
        ActiveUsers_Score = ActiveUsers, ActiveUsers_MoMChange = ActiveUsersMom,
        MAU_Score = Mau, MAU_MoMChange = MauMom,
        MAURate_Score = round(MauRate*100, 2), MAURate_Score_Color = Color_Mau, MAURate_MoMChange = MauRateMom,
        MEURate_Score = round(MeuRate*100, 2), MEURate_Score_Color = Color_Meu, MEURate_MoMChange = MeuRateMom,
        EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors, EngagedEditors_MoMChange = EngEditorsMom,
        Retention_Score = round(ReturningMauRate*100, 2), Retention_Score_Color = Color_ReturningMau, Retention_MoMChange=ReturningMauMom,
        EUPL_P95_Score = round(EUPL_P95, 2), EUPL_P95_Score_Color = Color_Performance, EUPL_P95_MoMChange=EUPLP95Mom,
        EUPL_P50_Score = round(EUPL_P50, 2), EUPL_P50_Score_Color = Color_Performance, EUPL_P50_MoMChange=EUPLP50Mom,
        ASHA_Score = round(ASHA, 2), ASHA_Score_Color = Color_ASHA, ASHA_MoMChange=ASHAMom,
        Feedback_Score = round(Feedback_SubScore, 2), Feedback_Score_Color = Color_Feedback, Feedback_MoMChange,
        OpenInClient_Score = round(OpenInClientRate*100, 2), OpenInClient_Score_Color = Color_OpenInClient, OpenInClient_MoMChange = OpenInClientMom,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
`;
