import React, { useEffect, useState } from "react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import * as API from "api";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { Workload } from "config/PlatformConfig";
import { chartSizes, lineChartTooltipStyles } from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import { TogglingLineChartLabelsType } from "redux/types";
import { logException } from "utils/AppInsightsHelper";
import { NetworkScoreEndPoints } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { addDay } from "utils/Helpers";
import { InfoLinks } from "utils/Links";
import { NETWORKSCORE_MESSAGES } from "utils/Messages";
import { NetworkScoreState, NetworkScoreDailyPayloadType } from "./types";

const chartLabels: TogglingLineChartLabelsType[] = Object.keys(
    NetworkScoreEndPoints,
).map((endpt) => ({
    key: endpt,
    color: NetworkScoreEndPoints[endpt],
}));

const dailyNetworkScoreDateMap = {
    [Workload.WIN32]: -27,
    [Workload.MAC]: -27,
    [Workload.WEB]: 0,
    [Workload.COPILOT_COMMERCIAL]: 0,
};

const getDailyNetworkScore = async (
    filters,
    selectedDate,
    platform,
): Promise<KustoResponseType<string>> => {
    let queryParams = {};
    const levelParams = API.getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams["date"] = addDay(selectedDate, dailyNetworkScoreDateMap[platform]);
    queryParams = { ...levelParams, ...queryParams };

    const networkDaily = await API.getKustoResponse({
        queryName: "networkScore_daily",
        platform: Workload.WEB,
        queryParams,
    });

    return networkDaily?.data;
};

export const formatNetworkScoreDaily = (
    networkScoreJson: KustoResponseType<string | number>,
) => {
    const temp = {};
    // schema - Date, Metric, Name, Value, Total
    networkScoreJson.Tables[0].Rows.forEach((element) => {
        const date = element[0];
        const name = element[2];
        const value = element[3];

        if (!(date in temp)) temp[date] = {};

        temp[date][name] = value;
    });

    const networkScore: NetworkScoreDailyPayloadType[] = [];

    Object.keys(temp).forEach((date) => {
        networkScore.push({ date, ...temp[date] });
    });

    return networkScore;
};

export const getPayloadData = async (
    filters: {},
    setData,
    selectedDate,
    platform,
) => {
    setData((data) => {
        return {
            ...data,
            loading: true,
        };
    });

    try {
        const response: KustoResponseType<string | number> =
            await getDailyNetworkScore(filters, selectedDate, platform);

        const formattedResponse = formatNetworkScoreDaily(response);

        setData({
            payload: formattedResponse,
            loading: false,
        });
    } catch (error) {
        setData({
            payload: [],
            loading: false,
        });
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "DailyNetworkTrendFetchOrProcessingFailed",
            ),
            {
                message: "Failed to fetch or format DailyNetworkTrend",
                filters,
            },
            error,
        );
    }
};

export const DailyNetworkTrend = ({ filters, platform, selectedDate }) => {
    const tenantId = filters["id"];
    const isTpid = filters["level"] === "Tpid";

    const intialState: NetworkScoreState = {
        payload: [],
        loading: true,
    };
    const [data, setData] = useState<NetworkScoreState>(intialState);

    useEffect(() => {
        if (tenantId && selectedDate)
            getPayloadData(filters, setData, selectedDate, platform);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, selectedDate]);

    return (
        <>
            <h2
                className="commonPageBlockTitle"
                data-testid="dailyNetworkScoreHeader"
            >
                Daily Network Score
                <LinkIconButton
                    link={InfoLinks.NetworkScore}
                    message={NETWORKSCORE_MESSAGES.READ_MORE}
                />
                {isTpid ? (
                    <div />
                ) : (
                    <LinkIconButton
                        link={InfoLinks.NetworkScorePowerApp + tenantId}
                        message={NETWORKSCORE_MESSAGES.NETWORKSCORE_SITE}
                        iconName="PowerAppsLogo"
                    />
                )}
            </h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.payload.length !== 0 ? (
                <>
                    <TogglingLineChart
                        kind={togglingLineChartKind.values}
                        xDataKey="date"
                        domain={[0, 100]}
                        values={data.payload}
                        labels={chartLabels}
                        width={chartSizes.width}
                        height={chartSizes.height}
                        yLabel="Score"
                        tooltipStyles={lineChartTooltipStyles}
                    />
                    <span className="subscriptStyleWithPadding">
                        {NETWORKSCORE_MESSAGES.NO_DATA}
                    </span>
                </>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
