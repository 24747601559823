import { IDropdownStyles, IStackItemStyles, IStackTokens } from "@fluentui/react";

export const horizontalStackTokensDay0FunnelFilters: IStackTokens = {
    childrenGap: 10,
};

export const stackItemStylesDay0FunnelFilters: IStackItemStyles = {
    root: {
        overflowX: "auto",
    },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 165 },
    dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
    dropdownItem: { height: "auto" },
};
