import React, { useEffect, useState } from "react";
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { DefaultButton, Stack } from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import {
    buttonStyle,
    chartSizes,
    chartXAxisPaddingStyles,
    filterStackTokens,
} from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { NetworkScoreEndPoints } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDisplayDate } from "utils/Helpers";
import { InfoLinks } from "utils/Links";
import { NETWORKSCORE_MESSAGES } from "utils/Messages";
import {
    NetworkScoreState,
    NetworkScoreMonthlyPayloadType,
    NetworkScoreMonthlyChartPayloadType,
} from "./types";

const chartDataKeys = {
    Date: "Date",
    Score: "Value",
    Quartile: "Range",
};

const EndPointButtons = ({
    endpoints,
    endPointSelected,
    changeEndPointSelected,
}) => {
    return (
        <Stack horizontal tokens={filterStackTokens}>
            {endpoints.map((endpt) => {
                return (
                    <DefaultButton
                        text={endpt}
                        onClick={() => changeEndPointSelected(endpt)}
                        checked={endPointSelected === endpt}
                        styles={buttonStyle}
                        key={endpt}
                    />
                );
            })}
        </Stack>
    );
};

export const MonthlyNetworkTrend = ({ filters }) => {
    const tenantId = filters["id"];
    const level = filters["level"];
    const isTpid = filters["level"] === "Tpid";

    const getMonthlyNetworkScore = async (
        filters,
    ): Promise<KustoResponseType<string>> => {
        let queryParams = {};
        const levelParams = API.getQueryParamsForLevel(filters["level"]);
        queryParams["id"] = filters["id"];
        queryParams = { ...levelParams, ...queryParams };

        const networkMonthly = await API.getKustoResponse({
            queryName: "networkScore_monthly",
            platform: Workload.WEB,
            queryParams,
        });

        return networkMonthly?.data;
    };

    const formatNetworkScoreMonthly = (
        networkScoreJson: KustoResponseType<string | number>,
    ) => {
        const temp = {};
        // schema - Date, Metric, Name, Value, Total
        networkScoreJson.Tables[0].Rows.forEach((element) => {
            const date = element[0] as string;
            const formattedDate = formatDisplayDate(date);
            const isCurrentRowDataQuartile = element[1] === "NetworkScore_Quartile";
            const name = element[2];
            const value = element[3];
            const total = element[4];

            if (!(name in temp)) temp[name] = {};

            if (!(formattedDate in temp[name])) temp[name][formattedDate] = {};

            if (isCurrentRowDataQuartile)
                temp[name][formattedDate][chartDataKeys.Quartile] = [value, total];
            else temp[name][formattedDate][chartDataKeys.Score] = value;
        });

        const networkScore: NetworkScoreMonthlyPayloadType = {};

        Object.keys(temp).forEach((endpoint) => {
            const flatten: NetworkScoreMonthlyChartPayloadType[] = [];
            Object.keys(temp[endpoint]).forEach((Date) => {
                flatten.push({ Date, ...temp[endpoint][Date] });
            });

            networkScore[endpoint] = flatten;
        });

        return networkScore;
    };

    const intialState: NetworkScoreState = {
        payload: {},
        loading: true,
    };
    const [data, setData] = useState<NetworkScoreState>(intialState);
    const [endPointSelected, changeEndPointSelected] = useState<string>(
        Object.keys(NetworkScoreEndPoints)[0],
    );

    useEffect(() => {
        const getPayloadData = async (filters: {}) => {
            setData((data) => {
                return {
                    ...data,
                    loading: true,
                };
            });

            try {
                const response: KustoResponseType<string | number> =
                    await getMonthlyNetworkScore(filters);

                const formattedResponse = formatNetworkScoreMonthly(response);

                setData({
                    payload: formattedResponse,
                    loading: false,
                });
            } catch (error) {
                setData({
                    payload: {},
                    loading: false,
                });
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "MonthlyNetworkTrendFetchOrProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format MonthlyNetworkTrend",
                        filters,
                    },
                    error,
                );
            }
        };

        if (tenantId) {
            getPayloadData({ id: tenantId, level });
        }
    }, [tenantId, level]);

    return (
        <>
            <h2
                className="commonPageBlockTitle"
                data-testid="monthlyNetworkScoreHeader"
            >
                Monthly Network Score
                <LinkIconButton
                    link={InfoLinks.NetworkScore}
                    message={NETWORKSCORE_MESSAGES.READ_MORE}
                />
                {isTpid ? (
                    <div />
                ) : (
                    <LinkIconButton
                        link={InfoLinks.NetworkScorePowerApp + tenantId}
                        message={NETWORKSCORE_MESSAGES.NETWORKSCORE_SITE}
                        iconName="PowerAppsLogo"
                    />
                )}
            </h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : (
                <div>
                    {data.payload[endPointSelected] ? (
                        <ComposedChart
                            width={chartSizes.width}
                            height={chartSizes.height}
                            data={data.payload[endPointSelected]}
                        >
                            <XAxis
                                dataKey={chartDataKeys.Date}
                                padding={chartXAxisPaddingStyles}
                            />
                            <YAxis
                                domain={[0, 100]}
                                label={{
                                    value: "Score",
                                    angle: -90,
                                    dx: -25,
                                }}
                            />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Area
                                type="monotone"
                                dataKey={chartDataKeys.Quartile}
                                fill="#9fc2ed"
                                stroke="#9fc2ed"
                                isAnimationActive={false}
                            />
                            <Line
                                type="monotone"
                                dataKey={chartDataKeys.Score}
                                stroke={NetworkScoreEndPoints[endPointSelected]}
                                isAnimationActive={false}
                                name="Network Score"
                            />
                        </ComposedChart>
                    ) : (
                        <EmptyTableContainer />
                    )}
                    <EndPointButtons
                        endPointSelected={endPointSelected}
                        changeEndPointSelected={changeEndPointSelected}
                        endpoints={Object.keys(NetworkScoreEndPoints)}
                    />
                </div>
            )}
        </>
    );
};
