import { precisionRound } from "@fluentui/react";
import { sortBy } from "lodash";
import {
    ConsumerDay0FunnelTables,
    isCadenceWeekly,
    generateDynamicPivotsQuery,
    populateQueryParamsForAsha,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import * as API from "api/index";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import {
    AdditionalFunnelFilters,
    FunnelStageNames,
    ShortenedFunnelStageDropOffNames,
    FunnelDroppedStage,
    AppSpecificDropOffNames,
} from "pages/ConsumerDay0Funnel/constants";
import { mergeStageScoresToGenerateAshaInsights } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelAshaInsightsHelper";
import {
    FunnelFilterStateType,
    FunnelStageInsightType,
    FunnelAshaData,
    FunnelStateDataType,
    AshaStagesPillarSessionCountData,
    AshaStagesScoreData,
    AshaDropOffStageRatesData,
    FunnelStagesAshaInsights,
    FunnelStageScenarioDataType,
    FunnelStageScenarioReferrerDataType,
    FunnelFilterOptions,
} from "pages/ConsumerDay0Funnel/types";
import { getStageDropOff, getStageIndex } from "utils/Helpers";

export const addDefaultsForAdditionalFilters = (filters: FunnelFilterStateType) => {
    const filtersWithDefaults: FunnelFilterStateType = { ...filters };

    Object.keys(AdditionalFunnelFilters).forEach((key) => {
        const additionalFunnelFilter = AdditionalFunnelFilters[key];
        if (!filters[additionalFunnelFilter]) {
            filtersWithDefaults[additionalFunnelFilter] = "All";
        }
    });

    return filtersWithDefaults;
};

export const formatFunnelStageData = (
    funnelJson: KustoResponseType<string | number>,
): FunnelStateDataType => {
    const funnelDataTable = funnelJson.Tables[0];
    const funnelData: FunnelStateDataType = {};
    const FunnelStageNamesArray = Object.keys(FunnelStageNames);

    //schema- date, stage, value, total
    funnelDataTable.Rows.forEach((element) => {
        const pct = precisionRound(
            ((element[2] as number) * 100) / (element[3] as number),
            2,
        );

        if (!(element[0] in funnelData)) funnelData[element[0]] = {};

        const stage = element[1] as string;

        if (FunnelStageNamesArray.includes(stage))
            funnelData[element[0]][stage] = {
                value: element[2] as number,
                percentage: pct,
            };
    });

    // Sort the data based on the stages, so that stages are shown in order
    const funnelDataSorted: FunnelStateDataType = {};
    Object.keys(funnelData).forEach((date) => {
        const tmp = funnelData[date];
        funnelDataSorted[date] = Object.fromEntries(
            Object.entries(tmp).sort(
                ([a], [b]) =>
                    FunnelStageNamesArray.indexOf(a) -
                    FunnelStageNamesArray.indexOf(b),
            ),
        );
    });

    return funnelDataSorted;
};

export const formatFunnelScenarioDistribution = (
    funnelJson: KustoResponseType<string | number>,
): FunnelStageScenarioDataType => {
    const funnelDataTable = funnelJson.Tables[0];
    const funnelData = {};
    const FunnelStageNamesArray = Object.keys(FunnelStageNames);

    //schema - date, stage, scenario, value
    funnelDataTable.Rows.forEach((element) => {
        if (!(element[0] in funnelData)) funnelData[element[0]] = {};
        const stage = element[1] as string;
        const scenario = element[2] as string;
        if (FunnelStageNamesArray.includes(stage))
            if (!(stage in funnelData[element[0]]))
                funnelData[element[0]][stage] = {};

        funnelData[element[0]][stage][scenario] = element[3];
    });

    const funnelDataFlattened: FunnelStageScenarioDataType = {};
    Object.keys(funnelData).forEach((date) => {
        if (!(date in funnelDataFlattened)) funnelDataFlattened[date] = {};
        Object.keys(funnelData[date]).forEach((stage) => {
            if (!(stage in funnelDataFlattened[date]))
                funnelDataFlattened[date][stage] = {
                    innerGroups: [],
                    outerGroups: [],
                };
            Object.keys(funnelData[date][stage]).forEach((scenario) => {
                switch (scenario) {
                    case "Create":
                    case "Open":
                        funnelDataFlattened[date][stage].innerGroups.push({
                            name: scenario,
                            value: funnelData[date][stage][scenario],
                            percent:
                                (funnelData[date][stage][scenario] /
                                    funnelData[date][stage]["All"]) *
                                100,
                        });
                        break;
                    case "Create From Template":
                    case "Create From Blank":
                    case "Open Own Doc":
                    case "Open Shared Doc":
                        funnelDataFlattened[date][stage].outerGroups.push({
                            name: scenario,
                            value: funnelData[date][stage][scenario],
                            percent:
                                (funnelData[date][stage][scenario] /
                                    funnelData[date][stage]["All"]) *
                                100,
                        });
                        break;
                    case "All":
                    default:
                        break;
                }
            });
        });
    });
    return funnelDataFlattened;
};

export const formatFunnelStageOverTimeData = (funnelData: FunnelStateDataType) => {
    const funnelOverTime = [];

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            funnelOverTime.push({ date, ...funnelData[date] });
        });

    return funnelOverTime;
};

export const formatFunnelDropOffOverTime = (funnelData: FunnelStateDataType) => {
    const funnelDropOffOverTime = [];

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            let dropOffData = {};
            dropOffData["date"] = date;
            const stagesData = funnelData[date];
            const stages = Object.keys(stagesData).sort((a, b) => {
                return getStageIndex(a) - getStageIndex(b);
            });
            for (let i = 0; i < stages.length - 1; i++) {
                const currentStage = stagesData[stages[i]];
                const nextStage = stagesData[stages[i + 1]];

                const stageDropOff = getStageDropOff(currentStage, nextStage);
                dropOffData[ShortenedFunnelStageDropOffNames[stages[i + 1]]] = {
                    value: stageDropOff.value * -1,
                    percentage: stageDropOff.percentage * -1,
                };
            }
            funnelDropOffOverTime.push(dropOffData);
        });

    return funnelDropOffOverTime;
};

export const getFunnelData = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelResponse = await API.getKustoResponse({
        queryName: "consumerFunnelStages",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelResponse?.data;
};

export const getFunnelDataAcrossScenarioAndReferrer = async (
    filters: FunnelFilterStateType,
) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelResponse = await API.getKustoResponse({
        queryName: "consumerFunnelStagesAcrossScenarioAndReferrer",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelResponse?.data;
};

export const getScenarioData = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelResponse = await API.getKustoResponse({
        queryName: "consumerFunnelScenarios",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelResponse?.data;
};

export const getDates = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelDatesResponse = await API.getKustoResponse({
        queryName: "consumerFunnelStageDates",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelDatesResponse?.data?.Tables[0]?.Rows.flatMap((date) => date);
};

export const getFilterChoices = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelReferrerResponse = await API.getKustoResponse({
        queryName: "consumerFunnelFilterChoices",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelReferrerResponse?.data;
};

export const getCSVExportData = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelCSVDataResponse = await API.getKustoResponse({
        queryName: "consumerFunnelExportData",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelCSVDataResponse?.data;
};

export const getReferrers = async (filters: FunnelFilterStateType) => {
    const queryParams = { ...addDefaultsForAdditionalFilters(filters) };

    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelReferrerResponse = await API.getKustoResponse({
        queryName: "consumerFunnelStageReferrers",
        platform: Workload.WEB,
        queryParams,
    });

    return funnelReferrerResponse?.data;
};

export const getFunnelInsights = async (
    filters: FunnelFilterStateType,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    queryParams["dynamicPivotsQuery"] = generateDynamicPivotsQuery(filters);
    queryParams["tableName"] = isCadenceWeekly(filters.cadence)
        ? ConsumerDay0FunnelTables.weekly
        : ConsumerDay0FunnelTables.monthly;

    const funnelInsightsData = await API.getKustoResponse({
        queryName: "consumerFunnelInsights",
        platform: Workload.WEB,
        queryParams,
    });
    return funnelInsightsData?.data;
};

export const formatCSVData = (
    funnelData: KustoResponseType<string | number>,
): Array<Array<number | string>> => {
    const csvExportData: Array<Array<number | string>> = [];
    const funnelDataTable = funnelData.Tables[0];

    csvExportData.push(
        funnelDataTable.Columns.map((column) => {
            return column.ColumnName;
        }),
    );
    funnelDataTable.Rows.forEach((row) => {
        csvExportData.push(row);
    });

    return csvExportData;
};

export const formatInsights = (
    funnelInsightsJson: KustoResponseType<string | number>,
): FunnelStageInsightType => {
    const funnelInsightsData: FunnelStageInsightType = {};

    if (!funnelInsightsJson) return funnelInsightsData;

    const funnelInsightsJsonTable = funnelInsightsJson.Tables[0];

    // schema - Stage, DroppedStage, Metric, Name, Value, Total, PercentOfStage, DroppedValue, DroppedTotal, DroppedDelta, KeptDelta
    funnelInsightsJsonTable.Rows.forEach((element) => {
        const stage: string = element[0] as string;
        const metric: string = element[2] as string;
        const metricName: string = element[3] as string;
        const percent: number = element[6] as number;

        if (!(stage in funnelInsightsData)) {
            funnelInsightsData[stage] = {};
        }

        if (!(metric in funnelInsightsData[stage])) {
            funnelInsightsData[stage][metric] = [];
        }

        if (!["Unknown", "Others"].includes(metricName) && percent > 0.5) {
            funnelInsightsData[stage][metric].push({
                name: metricName,
                Percent: percent,
            });
        }
    });

    return funnelInsightsData;
};

export const formatDroppedInsights = (
    funnelInsightsJson: KustoResponseType<string | number>,
): FunnelStageInsightType => {
    const funnelDroppedInsightsData: FunnelStageInsightType = {};
    const funnelDataSorted: FunnelStageInsightType = {};
    if (!funnelInsightsJson) return funnelDroppedInsightsData;

    const funnelInsightsJsonTable = funnelInsightsJson.Tables[0];

    // schema - Stage, DroppedStage, Metric, Name, Value, Total, PercentOfStage, DroppedValue, DroppedTotal, DroppedDelta, KeptDelta
    funnelInsightsJsonTable.Rows.forEach((element) => {
        const droppedStage: string = element[1] as string;
        const metric: string = element[2] as string;
        const metricName: string = element[3] as string;
        const droppedDeltaPercent: number = element[9] as number;

        if (droppedStage !== "") {
            if (
                !(droppedStage in funnelDroppedInsightsData) &&
                droppedStage !== FunnelDroppedStage.Default
            ) {
                funnelDroppedInsightsData[droppedStage] = {};
                funnelDataSorted[droppedStage] = {};
            }

            if (!(metric in funnelDroppedInsightsData[droppedStage])) {
                funnelDroppedInsightsData[droppedStage][metric] = [];
                funnelDataSorted[droppedStage][metric] = [];
            }

            if (
                !["Unknown", "Others"].includes(metricName) &&
                droppedDeltaPercent > 0.5
            ) {
                funnelDroppedInsightsData[droppedStage][metric].push({
                    name: metricName,
                    Percent: droppedDeltaPercent,
                });
            }
        }
    });

    if (Object.keys(funnelDroppedInsightsData).length !== 0) {
        Object.keys(funnelDroppedInsightsData).forEach((stage) => {
            Object.keys(funnelDroppedInsightsData[stage]).forEach((metric) => {
                const droppedInsights = funnelDroppedInsightsData[stage][metric];
                funnelDataSorted[stage][metric] = sortBy(droppedInsights, [
                    "Percent",
                ]).reverse();
            });
        });
    }

    return funnelDataSorted;
};

export const formatFunnelReferrerSource = (
    funnelJson: KustoResponseType<string | number>,
): FunnelStageScenarioReferrerDataType => {
    const funnelDataTable = funnelJson.Tables[0];
    const funnelData: FunnelStageScenarioReferrerDataType = {};

    const FunnelStageNamesArray = Object.keys(FunnelStageNames);

    //schema- date, stage, scenario, referrer, value
    funnelDataTable.Rows.forEach((element) => {
        if (!(element[0] in funnelData)) funnelData[element[0]] = {};
        const stage = element[1] as string;
        const scenario = element[2] as string;
        const referrer = element[3] as string;
        const value = element[4] as number;
        if (FunnelStageNamesArray.includes(stage))
            if (!(stage in funnelData[element[0]]))
                funnelData[element[0]][stage] = {};

        if (!(scenario in funnelData[element[0]][stage]))
            funnelData[element[0]][stage][scenario] = { referralSources: [] };

        funnelData[element[0]][stage][scenario].referralSources.push({
            name: referrer,
            value: value,
        });
    });
    return funnelData;
};

export const formatFunnelFilterOptions = (
    funnelJson: KustoResponseType<string | number>,
): FunnelFilterOptions => {
    const funnelDataTable = funnelJson.Tables[0];
    const funnelData: FunnelFilterOptions = {};

    Object.values(AdditionalFunnelFilters).forEach((element) => {
        funnelData[element] = [];
    });

    //schema- AppMode, Browser, Country, Datacenter, OS, ReferrerPlatform
    funnelDataTable.Rows.forEach((element) => {
        const appMode = element[0] as string;
        const browser = element[1] as string;
        const country = element[2] as string;
        const datacenter = element[3] as string;
        const os = element[4] as string;
        const referrerPlatform = element[5] as string;

        if (appMode) {
            funnelData["appMode"].push({ key: appMode, text: appMode });
        }
        if (browser) {
            funnelData["browser"].push({ key: browser, text: browser });
        }
        if (country) {
            funnelData["country"].push({ key: country, text: country });
        }
        if (datacenter) {
            funnelData["datacenter"].push({ key: datacenter, text: datacenter });
        }
        if (os) {
            funnelData["os"].push({ key: os, text: os });
        }
        if (referrerPlatform) {
            funnelData["referrerPlatform"].push({
                key: referrerPlatform,
                text: referrerPlatform,
            });
        }
    });
    return funnelData;
};

export const fetchFunnelAshaPillarData = async (
    filters: FunnelFilterStateType,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    let queryName;
    populateQueryParamsForAsha(queryParams);

    queryName = isCadenceWeekly(filters.cadence)
        ? "ashaConsumerFunnelPillarScoreWeekly"
        : "ashaConsumerFunnelPillarScoreMonthly";

    const funnelAshaPillarData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return funnelAshaPillarData?.data;
};

export const fetchFunnelAshaVetoData = async (
    filters: FunnelFilterStateType,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    let queryName;
    populateQueryParamsForAsha(queryParams);

    queryName = isCadenceWeekly(filters.cadence)
        ? "ashaConsumerFunnelVetoScoreWeekly"
        : "ashaConsumerFunnelVetoScoreMonthly";

    const funnelAshaVetoData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return funnelAshaVetoData?.data;
};

export const fetchFunnelAshaErrorData = async (
    filters: FunnelFilterStateType,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    let queryName;
    populateQueryParamsForAsha(queryParams);

    queryName = isCadenceWeekly(filters.cadence)
        ? "ashaConsumerFunnelErrorScoreWeekly"
        : "ashaConsumerFunnelErrorScoreMonthly";

    const funnelAshaErrorData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return funnelAshaErrorData?.data;
};

export const fetchFunnelAshaStageRatesData = async (
    filters: FunnelFilterStateType,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    let queryName;
    populateQueryParamsForAsha(queryParams);

    queryName = isCadenceWeekly(filters.cadence)
        ? `ashaConsumerFunnelStageRatesWeekly_${filters.application}`
        : `ashaConsumerFunnelStageRatesMonthly_${filters.application}`;

    const funnelAshaStageRatesData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return funnelAshaStageRatesData?.data;
};

export const processAshaDataToGetInsights = (
    ashaPillarJson: KustoResponseType<string | number>,
    ashaVetoJson: KustoResponseType<string | number>,
    ashaErrorJson: KustoResponseType<string | number>,
    ashaStageRatesJson: KustoResponseType<string | number>,
    filters: FunnelFilterStateType,
): FunnelStagesAshaInsights => {
    if (!ashaPillarJson || !ashaVetoJson || !ashaErrorJson || !ashaStageRatesJson)
        return null;

    const ashaPillarTable = ashaPillarJson.Tables[0];
    const ashaVetoTable = ashaVetoJson.Tables[0];
    const ashaErrorTable = ashaErrorJson.Tables[0];
    const ashaStageRatesTable = ashaStageRatesJson.Tables[0];

    // Query result is empty
    if (
        ashaPillarTable.Rows.length === 0 ||
        ashaVetoTable.Rows.length === 0 ||
        ashaErrorTable.Rows.length === 0 ||
        ashaStageRatesTable.Rows.length === 0
    )
        return null;

    const sessionCounts: AshaStagesPillarSessionCountData = {};
    const pillarScores: AshaStagesScoreData = {};
    const vetoScores: AshaStagesScoreData = {};
    const errorScores: AshaStagesScoreData = {};
    const stageRates: AshaDropOffStageRatesData = {};

    // Process pillar data
    ashaPillarTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const stage: string = element[1] as string;
        const score: number = element[2] as number;
        const pillarSessionCount: number = element[3] as number;
        const hitVetoOrErrorSessionCount: number = element[4] as number;

        if (!(stage in pillarScores)) {
            pillarScores[stage] = { AshaScore: [] };
        }

        // The query results should only have 1 row per pillar/stage combo
        if (
            pillarScores[stage].AshaScore.find(
                (score) => score.Pillar === pillar && score.Stage === stage,
            )
        ) {
            throw new Error(
                "Duplicate pillar/stage combo in ASHA pillar query results",
            );
        }

        pillarScores[stage].AshaScore.push({
            Stage: stage,
            Pillar: pillar,
            Score: score,
            HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
            Key: `${pillar}`,
            SessionCountKey: `${pillar}`,
        });

        if (!(stage in sessionCounts)) {
            sessionCounts[stage] = { AshaPillarSessionCounts: [] };
        }

        sessionCounts[stage].AshaPillarSessionCounts.push({
            Stage: stage,
            Pillar: pillar,
            PillarSessionCount: pillarSessionCount,
            Key: `${pillar}`,
        });
    });

    // Process veto data
    ashaVetoTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const veto: string = element[1] as string;
        const stage: string = element[2] as string;
        const score: number = element[3] as number;
        const hitVetoOrErrorSessionCount: number = element[5] as number;

        if (!(stage in vetoScores)) {
            vetoScores[stage] = { AshaScore: [] };
        }

        // The query results should only have 1 row per pillar/veto/stage combo
        if (
            vetoScores[stage].AshaScore.find(
                (score) =>
                    score.Pillar === pillar &&
                    score.Veto === veto &&
                    score.Stage === stage,
            )
        ) {
            throw new Error(
                "Duplicate pillar/veto/stage combo in ASHA pillar query results",
            );
        }

        vetoScores[stage].AshaScore.push({
            Stage: stage,
            Pillar: pillar,
            Veto: veto,
            Score: score,
            HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
            Key: `${pillar}${veto}`,
            SessionCountKey: `${pillar}`,
        });
    });

    // Process error data
    ashaErrorTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const veto: string = element[1] as string;
        const error: string = element[2] as string;
        const stage: string = element[3] as string;
        const score: number = element[4] as number;
        const hitVetoOrErrorSessionCount: number = element[6] as number;

        if (!(stage in errorScores)) {
            errorScores[stage] = { AshaScore: [] };
        }

        // The query results should only have 1 row per pillar/veto/error/stage combo
        if (
            errorScores[stage].AshaScore.find(
                (score) =>
                    score.Pillar === pillar &&
                    score.Veto === veto &&
                    score.Error === error &&
                    score.Stage === stage,
            )
        ) {
            throw new Error(
                "Duplicate pillar/veto/error/stage combo in ASHA pillar query results",
            );
        }

        errorScores[stage].AshaScore.push({
            Stage: stage,
            Pillar: pillar,
            Veto: veto,
            Error: error,
            Score: score,
            HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
            Key: `${pillar}${veto}${error}`,
            SessionCountKey: `${pillar}`,
        });
    });

    // Process stage rates data
    ashaStageRatesTable.Rows.forEach((element) => {
        const firstStage: string = element[0] as string;
        const secondStage: string = element[1] as string;
        const firstStagePercent: number = element[2] as number;
        const secondStagePercent: number = element[3] as number;
        const percentDrop: number = element[4] as number;
        const dropOff: string = element[5] as string;

        if (!(dropOff in stageRates)) {
            stageRates[dropOff] = {
                FirstStageName: firstStage,
                SecondStageName: secondStage,
                FirstStagePercent: firstStagePercent,
                SecondStagePercent: secondStagePercent,
                PercentDropBetweenFirstAndSecondStages: percentDrop,
            };
        } else {
            // The query results should only have 1 row per dropOff
            throw new Error("Duplicate dropOff in ASHA stage rates query results");
        }
    });

    const processedAshaData: FunnelAshaData = {
        PillarSessionCounts: sessionCounts,
        PillarScores: pillarScores,
        VetoScores: vetoScores,
        ErrorScores: errorScores,
        StageRates: stageRates,
    };

    // Get top ASHA insights for each dropoff
    const ashaInsights: FunnelStagesAshaInsights = {};

    Object.keys(AppSpecificDropOffNames[filters.application]).forEach((dropOff) => {
        const ashaTopInsightsForStage = mergeStageScoresToGenerateAshaInsights(
            processedAshaData,
            dropOff,
        );

        ashaInsights[dropOff] = {
            Insights: ashaTopInsightsForStage,
            StageRates: processedAshaData.StageRates[dropOff],
        };
    });

    return ashaInsights;
};
