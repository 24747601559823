import { IIconProps } from "@fluentui/react";
import { FunnelType, getFunnelConfig } from "config/FunnelConfig";

export const getContextualMenuPropsIfAvailable = (
    funnelType: FunnelType,
    additionalPivotsFilter: Set<string>,
    onClickHandler: (item: string) => void,
) => {
    const { additionalFilters } = getFunnelConfig(funnelType);

    // Does the specified funnel even have additional pivots specified?
    if (!additionalFilters) {
        return null;
    }

    const cancelIcon: IIconProps = { iconName: "Cancel" };
    let menuPropsItems = [];
    additionalFilters.forEach(({ filterKey, label }) => {
        const friendlyDisplayName = label;

        menuPropsItems.push({
            key: friendlyDisplayName,
            text: friendlyDisplayName,
            checked: additionalPivotsFilter.has(filterKey),
            iconProps: additionalPivotsFilter.has(filterKey) && cancelIcon,
            onClick: () => onClickHandler(filterKey),
        });
    });

    return { items: menuPropsItems };
};
