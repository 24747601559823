import React, { useEffect, useState } from "react";
import { IconButton, Link, Stack } from "@fluentui/react";
import { AshaTopInsightsWidget } from "components/AshaComponents/AshaTopInsightsWidget";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import {
    commonPageBlock,
    equalStackItemsStyle,
    horizontalStackTokens,
} from "pages/common";
import {
    fetchFunnelAshaPillarData,
    fetchFunnelAshaVetoData,
    fetchFunnelAshaErrorData,
    fetchFunnelAshaStageRatesData,
    processAshaDataToGetInsights,
} from "pages/ConsumerDay0Funnel/ApiHandler";
import { AshaLinkMapping } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelAshaInsightsHelper";
import {
    AshaLinks,
    FunnelAshaInsightsProps,
    FunnelAshaInsightsState,
    FunnelStagesAshaInsights,
} from "pages/ConsumerDay0Funnel/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { CONSUMERDAY0_MESSAGES } from "utils/Messages";

const initialState: FunnelAshaInsightsState = {
    ashaInsights: null,
    loading: true,
};

export const FunnelAshaInsights = ({
    filters,
    dropOff,
}: FunnelAshaInsightsProps) => {
    const [data, setData] = useState<FunnelAshaInsightsState>(initialState);

    useEffect(() => {
        const getAshaData = async () => {
            setData((data) => {
                return {
                    ...data,
                    loading: true,
                };
            });

            try {
                const [
                    pillarQueryData,
                    vetoQueryData,
                    errorQueryData,
                    stagesRatesQueryData,
                ] = await Promise.all([
                    fetchFunnelAshaPillarData(filters),
                    fetchFunnelAshaVetoData(filters),
                    fetchFunnelAshaErrorData(filters),
                    fetchFunnelAshaStageRatesData(filters),
                ]);

                try {
                    const ashaInsights: FunnelStagesAshaInsights =
                        processAshaDataToGetInsights(
                            pillarQueryData,
                            vetoQueryData,
                            errorQueryData,
                            stagesRatesQueryData,
                            filters,
                        );

                    setData({
                        ashaInsights: ashaInsights,
                        loading: false,
                    });
                } catch (error) {
                    logException(
                        new TenantInsightsException(
                            Severity.SEV3,
                            "ConsumerDay0FunnelAshaDataProcessingFailed",
                        ),
                        {
                            message:
                                "Failed to process Consumer Day 0 Funnel Asha data",
                            dropOff,
                        },
                        error,
                    );
                    setData({
                        ashaInsights: null,
                        loading: false,
                    });
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerDay0FunnelAshaFetchFailed",
                    ),
                    {
                        message: "Failed to fetch Consumer Day 0 Funnel Asha data",
                        dropOff,
                    },
                    error,
                );
                setData({
                    ashaInsights: null,
                    loading: false,
                });
            }
        };
        getAshaData();

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filters.date,
        filters.cadence,
        filters.application,
        filters.cohort,
        filters.scenario,
        filters.referrer,
        filters.browser,
        filters.datacenter,
        filters.country,
        filters.appMode,
        filters.os,
        filters.referrerPlatform,
    ]);

    const ashaLink: AshaLinks = {
        app: filters["application"],
        viewBy: filters["cadence"] === "weekly" ? "Weekly" : "Monthly",
        cohortName: filters["cohort"],
        date: filters["date"],
        dropOff: dropOff,
        scenario: filters.scenario,
        referrer: filters.referrer,
        browser: filters.browser === undefined ? "All" : filters.browser,
        datacenter: filters.datacenter === undefined ? "All" : filters.datacenter,
        country: filters.country === undefined ? "All" : filters.country,
        appMode: filters.appMode === undefined ? "All" : filters.appMode,
        os: filters.os === undefined ? "All" : filters.os,
        referrerPlatform:
            filters.referrerPlatform === undefined
                ? "All"
                : filters.referrerPlatform,
    };

    return (
        <>
            <Stack>
                <Stack.Item>
                    <h2
                        style={{
                            fontWeight: "bold",
                            paddingLeft: 15,
                            color: "GrayText",
                        }}
                    >
                        {`ASHA - Insights for Funnel drop-offs`}
                        <span>
                            <Link
                                href={AshaLinkMapping.getAshaUrl(ashaLink)}
                                target="_blank"
                                disabled={dropOff === "Default"}
                            >
                                <IconButton
                                    iconProps={{ iconName: "DrillThrough" }}
                                    className="drillDownIcon"
                                    disabled={dropOff === "Default"}
                                />
                            </Link>
                        </span>
                    </h2>
                    <span
                        className="subscriptStyleBottom"
                        style={{
                            paddingLeft: 15,
                        }}
                    >
                        {CONSUMERDAY0_MESSAGES.ASHA_INSIGHTS_HOWTO}
                    </span>
                </Stack.Item>
            </Stack>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.ashaInsights === null ? (
                <Stack styles={commonPageBlock}>
                    <Stack.Item>
                        <EmptyTableContainer />
                    </Stack.Item>
                </Stack>
            ) : (
                <>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <AshaTopInsightsWidget
                                ashaTopInsights={data.ashaInsights[dropOff]}
                                dropOff={dropOff}
                                application={filters.application}
                            />
                        </Stack.Item>
                    </Stack>
                </>
            )}
        </>
    );
};
