export const FETCH_TENANTS_BEGIN = "FETCH_TENANTS_BEGIN";
export const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
export const FETCH_TENANTS_FAILURE = "FETCH_TENANTS_FAILURE";

export const FETCH_TENANTLIST_BEGIN = "FETCH_TENANTLIST_BEGIN";
export const FETCH_TENANTLIST_SUCCESS = "FETCH_TENANTLIST_SUCCESS";
export const FETCH_TENANTLIST_FAILURE = "FETCH_TENANTLIST_FAILURE";

export const FETCH_TENANTMETRICS_BEGIN = "FETCH_TENANTMETRICS_BEGIN";
export const FETCH_TENANTMETRICS_SUCCESS = "FETCH_TENANTMETRICS_SUCCESS";
export const FETCH_TENANTMETRICS_FAILURE = "FETCH_TENANTMETRICS_FAILURE";

export const FETCH_COREHEALTH_SUCCESS = "FETCH_COREHEALTHSUCCESS";
export const FETCH_COREHEALTH_FAILURE = "FETCH_COREHEALTH_FAILURE";

export const SET_APP_FILTER = "SET_APP_FILTER";

export const FETCH_RELIABILITY_BEGIN = "FETCH_RELIABILITY_BEGIN";
export const FETCH_RELIABILITY_SUCCESS = "FETCH_RELIABILITY_SUCCESS";
export const FETCH_RELIABILITY_FAILURE = "FETCH_RELIABILITY_FAILURE";

export const FETCH_CHARTMETRICS_BEGIN = "FETCH_CHARTMETRICS_BEGIN";
export const FETCH_CHARTMETRICS_SUCCESS = "FETCH_CHARTMETRICS_SUCCESS";
export const FETCH_CHARTMETRICS_FAILURE = "FETCH_CHARTMETRICS_FAILURE";

export const FETCH_ERROR_BROWSER_CHART_SUCCESS = "FETCH_ERROR_BROWSER_CHART_SUCCESS";
export const FETCH_ERROR_BROWSER_CHART_FAILURE = "FETCH_ERROR_BROWSER_CHART_FAILURE";

export const FETCH_COLLAB_BEGIN = "FETCH_COLLAB_BEGIN";
export const FETCH_COLLAB_SUCCESS = "FETCH_COLLAB_SUCCESS";
export const FETCH_COLLAB_FAILURE = "FETCH_COLLAB_FAILURE";
export const FETCH_PERFORMANCE_BEGIN = "FETCH_PERFORMANCE_BEGIN";
export const FETCH_PERFORMANCE_SUCCESS = "FETCH_PERFORMANCE_SUCCESS";
export const FETCH_PERFORMANCE_FAILURE = "FETCH_PERFORMANCE_FAILURE";

export const FETCH_PLATFORMOVERVIEW_BEGIN = "FETCH_PLATFORMOVERVIEW_BEGIN";
export const FETCH_PLATFORMOVERVIEW_SUCCESS = "FETCH_PLATFORMOVERVIEW_SUCCESS";
export const FETCH_PLATFORMOVERVIEW_FAILURE = "FETCH_PLATFORMOVERVIEW_FAILURE";
