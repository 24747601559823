import React from "react";
import { IStackItemStyles, Stack } from "@fluentui/react";
import "components/AIScorecardComponents/style.css";
import { MetricsType } from "components/AIScorecardComponents/CopilotTrends/types";

const legendStackItemsStyle: IStackItemStyles = {
    root: {
        width: "40px",
    },
};

const renderMarker = (marker: string, checked: boolean) => {
    // SVG
    const width = 20;
    const height = 20;

    // Line, Circle
    const size = 10;

    // X
    const mid = size / 2;
    const points = `0 ${mid}, ${mid} ${mid}, ${mid} 0, ${mid} ${size}, ${mid} ${mid}, ${size} ${mid}`;

    const color = checked ? "white" : "black";
    switch (marker) {
        case "line":
            return (
                <svg width={width} height={height}>
                    <rect width={1} height={10} x={10} stroke={color} fill={color} />
                </svg>
            );
        case "circle":
            return (
                <svg width={width} height={height}>
                    <circle r={2} cx={10} cy={5} stroke={color} fill={color} />
                </svg>
            );
        case "x":
            return (
                <svg width={width} height={height} transform="rotate(45)">
                    <polyline
                        points={points}
                        orient={45}
                        stroke={color}
                        fill={color}
                    />
                </svg>
            );
    }
};

export const MetricLegend = ({
    onClickHandler,
    metrics,
}: {
    onClickHandler: (x: string) => void;
    metrics: MetricsType[];
}) => {
    return (
        <Stack horizontal>
            <>
                {metrics.map((metric, idx) => {
                    const checked = metric.State ? "checked" : "";
                    return (
                        <Stack
                            horizontal
                            className={`metricLegendKey ${checked}`}
                            onClick={() => onClickHandler(metric.Metric)}
                            key={idx}
                        >
                            <Stack.Item styles={legendStackItemsStyle}>
                                {renderMarker(metric.Marker, metric.State)}
                            </Stack.Item>
                            <Stack.Item grow="initial" style={{ marginTop: "5px" }}>
                                {metric.Metric}
                            </Stack.Item>
                        </Stack>
                    );
                })}
            </>
        </Stack>
    );
};
