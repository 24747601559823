import {
    consumerMauCamauStatSigMetrics,
    consumerRetentionStatSigMetrics,
    consumerScorecardMetrics,
} from "./consumerScorecardQueries";

import {
    ashaConsumerGrowthScorecardRetentionPillarScoreWeekly,
    ashaConsumerGrowthScorecardRetentionVetoScoreWeekly,
    ashaConsumerGrowthScorecardRetentionErrorScoreWeekly,
} from "./consumerScorecardAshaScoresQueries";

export const consumerScorecardQueries = {
    consumerScorecardMetrics,
    ashaConsumerGrowthScorecardRetentionPillarScoreWeekly,
    ashaConsumerGrowthScorecardRetentionVetoScoreWeekly,
    ashaConsumerGrowthScorecardRetentionErrorScoreWeekly,
    consumerRetentionStatSigMetrics,
    consumerMauCamauStatSigMetrics,
};
