import { isTest } from "utils/WebConstants";

export const webCrossAppTenantStatus: string = `
//webCrossAppTenantStatus
let currDate = GetCurrentDate(${isTest});
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let levelName = "\${levelColumnName}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
Score_WXP
    | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app and UiHost == "All" and WXP_Mau > \${mauCountThreshold}
    | extend Score = WXP_Score, CurrentStatus = WXP_Score_Color
    | join kind= inner
    (
        Score_WXP
            | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Date == prev and Application == app and UiHost == "All"
            | extend Score = WXP_Score, PreviousStatus = WXP_Score_Color, PreviousMonthMau = WXP_Mau
    )
    on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend ScoreDelta =  round(Score-Score1,2)
    | join kind=inner
        (StrategicTenants
            | where Date == currDate and Is\${cohort} == true
            | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName}
    | join kind=inner (
        PastStatus
        | where Application == app and UiHost == 'All' and Date == currDate
        | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
    ) on Tpid, OmsTenantId
    | extend Score = round(Score, 2)
    | extend ScoreDelta = round(Score-Score1,2)
    | extend ActiveUsersMom = round(MoM_ActiveUsers*100,2)
    | extend MauMom = round(todouble(WXP_Mau-PreviousMonthMau)/PreviousMonthMau*100,2)
    | extend MauRateMom = round(MoM_MauRate*100,2)
    | extend MeuRateMom = round(MoM_MeuRate*100,2)
    | extend EngEditorsMom = round(MoM_EngagedEditorsRate*100,2)
    | extend ReturningMauMom = round(MoM_ReturningMauRate*100,2)
    | extend OpenInClientMom = round(MoM_OpenInClientRate*100,2)
     | project \${level} = \${levelColumnName}, OrgName =  Name,
        Composite_Score = Score, Composite_MoMChange = ScoreDelta,
        ActiveUsers_Score = ActiveUsers, ActiveUsers_MoMChange = ActiveUsersMom,
        MAU_Score = WXP_Mau, MAU_MoMChange = MauMom,
        MAURate_Score = round(MauRate*100, 2), MAURate_Score_Color = Color_Mau, MAURate_MoMChange = MauRateMom,
        MEURate_Score = round(MeuRate*100, 2), MEURate_Score_Color = Color_Meu, MEURate_MoMChange = MeuRateMom,
        EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors, EngagedEditors_MoMChange = EngEditorsMom,
        Retention_Score = round(ReturningMauRate*100, 2), Retention_Score_Color = Color_ReturningMau, Retention_MoMChange=ReturningMauMom,
        Word_Performance_AppColor = Word_PerfColor,
        Excel_Performance_AppColor = Excel_PerfColor,
        PowerPoint_Performance_AppColor = PPT_PerfColor,
        Word_ACEFreeUsers_AppColor = Word_ACEColor,
        Excel_ACEFreeUsers_AppColor = Excel_ACEColor,
        PowerPoint_ACEFreeUsers_AppColor = PPT_ACEColor,
        Word_ICEFreeUsers_AppColor = Word_ICEColor,
        Excel_ICEFreeUsers_AppColor = Excel_ICEColor,
        PowerPoint_ICEFreeUsers_AppColor = PPT_ICEColor,
        Word_Feedback_AppColor = Word_FbkColor,
        Excel_Feedback_AppColor = Excel_FbkColor,
        PowerPoint_Feedback_AppColor = PPT_FbkColor,
        Word_Performance_AppColor_Prev = Word_PerfColor1,
        Excel_Performance_AppColor_Prev = Excel_PerfColor1,
        PowerPoint_Performance_AppColor_Prev = PPT_PerfColor1,
        Word_ACEFreeUsers_AppColor_Prev = Word_ACEColor1,
        Excel_ACEFreeUsers_AppColor_Prev = Excel_ACEColor1,
        PowerPoint_ACEFreeUsers_AppColor_Prev = PPT_ACEColor1,
        Word_ICEFreeUsers_AppColor_Prev = Word_ICEColor1,
        Excel_ICEFreeUsers_AppColor_Prev = Excel_ICEColor1,
        PowerPoint_ICEFreeUsers_AppColor_Prev = PPT_ICEColor1,
        Word_Feedback_AppColor_Prev = Word_FbkColor1,
        Excel_Feedback_AppColor_Prev = Excel_FbkColor1,
        PowerPoint_Feedback_AppColor_Prev = PPT_FbkColor1,
        Word_OpenInClient_AppColor = Word_OICColor,
        Excel_OpenInClient_AppColor = Excel_OICColor,
        PowerPoint_OpenInClient_AppColor = PPT_OICColor,
        Word_OpenInClient_AppColor_Prev = Word_OICColor1,
        Excel_OpenInClient_AppColor_Prev = Excel_OICColor1,
        PowerPoint_OpenInClient_AppColor_Prev = PPT_OICColor1,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
`;

export const webCrossAppTenantStatus_V2: string = `
//webCrossAppTenantStatus
let currDate = GetCurrentDate(${isTest});
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let levelName = "\${levelColumnName}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
Score_WXP_V2
    | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and Application == app and UiHost == "All" and WXP_Mau > \${mauCountThreshold}
    | extend Score = WXP_Score, CurrentStatus = WXP_Score_Color
    | join kind= inner
    (
        Score_WXP_V2
            | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Date == prev and Application == app and UiHost == "All"
            | extend Score = WXP_Score,
                                PreviousStatus = WXP_Score_Color,
                                PreviousMonthMau = WXP_Mau,
                                PreviousMauRate = MauRate,
                                PreviousMeuRate = MeuRate,
                                PreviousEngagedEditorsRate = EngagedEditorsRate,
                                PreviousReturningMauRate = ReturningMauRate,
                                PreviousEUPL_P95 = EUPL_P95,
                                PreviousEUPL_P50 = EUPL_P50,
                                PreviousFeedback_SubScore = Feedback_SubScore,
                                PreviousOpenInClientRate = OpenInClientRate
    )
    on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend ScoreDelta =  round(Score-Score1,2)
    | join kind=inner
        (StrategicTenants
            | where Date == currDate and Is\${cohort} == true
            | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName}
    | join kind=inner (
        PastStatus
        | where Application == app and UiHost == 'All' and Date == currDate
        | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
    ) on Tpid, OmsTenantId
    | extend Score = round(Score, 2)
    | extend ScoreDelta = round(Score-Score1,2)
    | extend ActiveUsersMom = round(MoM_ActiveUsers*100,2)
    | extend MauMom = round(todouble(WXP_Mau-PreviousMonthMau)/PreviousMonthMau*100,2)
    | extend MauRateMom = round((MoM_MauRate / PreviousMauRate) * 100.0,2)
    | extend MeuRateMom = round((MoM_MeuRate / PreviousMeuRate) * 100.0,2)
    | extend EngEditorsMom = round((MoM_EngagedEditorsRate / PreviousEngagedEditorsRate) * 100.0,2)
    | extend ReturningMauMom = round((MoM_ReturningMauRate / PreviousReturningMauRate) * 100.0 ,2)
    | extend OpenInClientMom = round((MoM_OpenInClientRate / PreviousOpenInClientRate) *100.0,2)
     | project \${level} = \${levelColumnName}, OrgName =  Name,
        Composite_Score = Score, Composite_MoMChange = ScoreDelta,
        ActiveUsers_Score = ActiveUsers, ActiveUsers_MoMChange = ActiveUsersMom,
        MAU_Score = WXP_Mau, MAU_MoMChange = MauMom,
        MAURate_Score = round(MauRate*100, 2), MAURate_Score_Color = Color_Mau, MAURate_MoMChange = MauRateMom,
        MEURate_Score = round(MeuRate*100, 2), MEURate_Score_Color = Color_Meu, MEURate_MoMChange = MeuRateMom,
        EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors, EngagedEditors_MoMChange = EngEditorsMom,
        Retention_Score = round(ReturningMauRate*100, 2), Retention_Score_Color = Color_ReturningMau, Retention_MoMChange=ReturningMauMom,
        Word_Performance_AppColor = Word_PerfColor,
        Excel_Performance_AppColor = Excel_PerfColor,
        PowerPoint_Performance_AppColor = PPT_PerfColor,
        Word_ASHA_AppColor = Word_ASHAColor,
        Excel_ASHA_AppColor = Excel_ASHAColor,
        PowerPoint_ASHA_AppColor = PPT_ASHAColor,
        Word_Feedback_AppColor = Word_FbkColor,
        Excel_Feedback_AppColor = Excel_FbkColor,
        PowerPoint_Feedback_AppColor = PPT_FbkColor,
        Word_Performance_AppColor_Prev = Word_PerfColor1,
        Excel_Performance_AppColor_Prev = Excel_PerfColor1,
        PowerPoint_Performance_AppColor_Prev = PPT_PerfColor1,
        Word_ASHA_AppColor_Prev = Word_ASHAColor1,
        Excel_ASHA_AppColor_Prev = Excel_ASHAColor1,
        PowerPoint_ASHA_AppColor_Prev = PPT_ASHAColor1,
        Word_Feedback_AppColor_Prev = Word_FbkColor1,
        Excel_Feedback_AppColor_Prev = Excel_FbkColor1,
        PowerPoint_Feedback_AppColor_Prev = PPT_FbkColor1,
        Word_OpenInClient_AppColor = Word_OICColor,
        Excel_OpenInClient_AppColor = Excel_OICColor,
        PowerPoint_OpenInClient_AppColor = PPT_OICColor,
        Word_OpenInClient_AppColor_Prev = Word_OICColor1,
        Excel_OpenInClient_AppColor_Prev = Excel_OICColor1,
        PowerPoint_OpenInClient_AppColor_Prev = PPT_OICColor1,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
`;
