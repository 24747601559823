import React from "react";
import { Link } from "react-router-dom";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    IDetailsColumnFieldProps,
    IRenderFunction,
    SelectionMode,
    Icon,
    TooltipHost,
} from "@fluentui/react";
import {
    ScrollablePane,
    ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { M365ScorecardColumns } from "components/AIScorecardComponents/config";
import { generateOCVLink } from "components/AIScorecardComponents/CopilotM365ScorecardTableHelper";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
    sendAIScorecardUserActionTelemetryEvent,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    AIScorecardTablePropsType,
    CopilotM365ColKey,
} from "components/AIScorecardComponents/types";
import { NO_DATA_MESSAGES } from "utils/Messages";

const CopilotM365ScorecardTable = ({
    items,
    loading,
    filters,
}: AIScorecardTablePropsType) => {
    const getHeaderGroups = () => {
        const groups = {};
        M365ScorecardColumns.forEach((column) => {
            groups[column.name] = column.headerGroups;
        });
        return groups;
    };

    const getColumns = (cadence: string) => {
        const columns: IColumn[] = [];

        M365ScorecardColumns.forEach((column) => {
            columns.push({
                key: column.name,
                name: (
                    <>
                        {column.displayName === "Tried"
                            ? cadence === "Weekly"
                                ? "WAU"
                                : "MAU"
                            : column.displayName}
                        {column.tooltip && (
                            <TooltipHost
                                content={column.tooltip}
                                onTooltipToggle={(isTooltipVisible) => {
                                    if (isTooltipVisible) {
                                        sendAIScorecardUserActionTelemetryEvent(
                                            {
                                                userActionName:
                                                    "M365ScorecardTableColumnTooltipActivate",
                                                commandSurface:
                                                    UserActionCommandSurface.Canvas,
                                            },
                                            {
                                                TableColumnName: column.displayName,
                                            },
                                        );
                                    }
                                }}
                            >
                                <Icon
                                    iconName="info"
                                    className="tableColumnDefintion"
                                />
                            </TooltipHost>
                        )}
                    </>
                ) as any,
                fieldName: column.displayName,
                minWidth: column.minWidth ?? 120,
                maxWidth: column.minWidth ?? 120,
                ariaLabel: column.displayName,
                isResizable: true,
                headerClassName: `detailsListColumnHeaderWrap ${
                    column.className ?? "alignRightHeader"
                }`,
            });
        });

        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
        return columns;
    };

    const nullFieldContentCell = (cellStyle): JSX.Element => (
        <div className="scorecardCell alignRight" style={cellStyle}>
            -
        </div>
    );

    const renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const appName = item["AppName"];
        const colKey = column.key;
        const cellStyle: React.CSSProperties = {};
        if (colKey === CopilotM365ColKey.AppName) {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.TextValue value={fieldContent} />
                </div>
            );
        } else if (colKey === CopilotM365ColKey.ThumbsUpResponseRate) {
            return (
                <>
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalValue
                            value={fieldContent ?? "-"}
                            postfix={fieldContent ? "%" : ""}
                            decimalPrecision={1}
                        />
                    </div>
                    <div className="scorecardCell">
                        {item["ThumbsResponseCount"] && (
                            <div className="alignRight responseCount">
                                {item["ThumbsResponseCount"]} clicks
                            </div>
                        )}
                    </div>
                </>
            );
        } else if (colKey.endsWith("Rate")) {
            return (
                <div className="scorecardCell" style={cellStyle}>
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={fieldContent ? "%" : ""}
                        decimalPrecision={1}
                    />
                </div>
            );
        } else if (
            colKey === CopilotM365ColKey.TriedCountsPerUser ||
            colKey === CopilotM365ColKey.QuickRatio ||
            colKey === CopilotM365ColKey.CopilotNps
        ) {
            return (
                <div className="scorecardCell" style={cellStyle}>
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix=""
                        decimalPrecision={1}
                    />
                </div>
            );
        } else if (colKey === CopilotM365ColKey.ASHA) {
            const endDate = filters["date"];
            const lookback = filters["cadence"] === "Monthly" ? 27 : 6;
            let tmpDate = new Date(endDate);
            tmpDate.setDate(tmpDate.getDate() - lookback);
            const startDate = tmpDate?.toISOString().split("T")[0];
            // The Loop app uses the "Loop App" name in the ASHA dashboard/tables instead of "Loop"
            const app = item["AppName"] === "Loop" ? "Loop App" : item["AppName"];
            const link = `//asha-next.azurewebsites.net/?app=${app}&datePreset=&startDate=${startDate}&endDate=${endDate}&platform=${filters["platform"]}&office365Category=${filters["office365Category"]}&audienceGroup=${filters["audienceGroup"]}`;
            return fieldContent ? (
                <Link
                    to={link}
                    target="_blank"
                    onClick={() => {
                        sendAIScorecardUserActionTelemetryEvent({
                            userActionName: "M365ScorecardTableASHALinkClick",
                            commandSurface: UserActionCommandSurface.Canvas,
                        });
                    }}
                >
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalValue
                            value={fieldContent}
                            postfix={"%"}
                            decimalPrecision={1}
                        />
                    </div>
                </Link>
            ) : (
                nullFieldContentCell(cellStyle)
            );
        } else if (colKey === CopilotM365ColKey.MTEPassFail) {
            return fieldContent != null ? (
                <Link
                    to={generateOCVLink(appName, "MTE", filters)}
                    target="_blank"
                    onClick={() => {
                        sendAIScorecardUserActionTelemetryEvent({
                            userActionName: "M365ScorecardTableMTELinkClick",
                            commandSurface: UserActionCommandSurface.Canvas,
                        });
                    }}
                >
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalValue
                            value={item["VerbsPassedMTE"] + "/" + item["NumVerbs"]}
                            postfix={""}
                        />
                    </div>
                </Link>
            ) : (
                nullFieldContentCell(cellStyle)
            );
        } else if (colKey === CopilotM365ColKey.TVPassFail) {
            return fieldContent != null ? (
                <Link
                    to={generateOCVLink(appName, "TV", filters)}
                    target="_blank"
                    onClick={() => {
                        sendAIScorecardUserActionTelemetryEvent({
                            userActionName: "M365ScorecardTableTVLinkClick",
                            commandSurface: UserActionCommandSurface.Canvas,
                        });
                    }}
                >
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalValue
                            value={fieldContent == 1.0 ? "Pass" : "Fail"}
                            postfix={""}
                        />
                    </div>
                </Link>
            ) : (
                nullFieldContentCell(cellStyle)
            );
        } else if (colKey === CopilotM365ColKey.CopilotSessionsPercent) {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "NA"}
                        postfix={""}
                    />
                </div>
            );
        } else if (colKey === CopilotM365ColKey.COGSCost) {
            const date = new Date(filters.date);
            const month = date.getUTCMonth() + 1;
            const year = date.getUTCFullYear();
            const fiscalYear = month >= 6 ? year + 1 : year;
            const link = `https://ms-cogswell.azurewebsites.net/opg-copilot?fiscalYear=${fiscalYear}&app=${appName}&scenario=All&type=LLM&model=All&env=All&tenantType=All`;
            return fieldContent ? (
                <Link
                    to={link}
                    target="_blank"
                    onClick={() => {
                        sendAIScorecardUserActionTelemetryEvent({
                            userActionName: "M365ScorecardTableCOGSCostLinkClick",
                            commandSurface: UserActionCommandSurface.Canvas,
                        });
                    }}
                >
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalAbbreviatedValue
                            value={fieldContent}
                            preFix="$"
                        />
                    </div>
                </Link>
            ) : (
                nullFieldContentCell(cellStyle)
            );
        } else if (colKey === CopilotM365ColKey.COGSCostPerUser) {
            return fieldContent ? (
                <div className="scorecardCell" style={cellStyle}>
                    <DetailsListHelper.NumericalAbbreviatedValue
                        value={fieldContent}
                        preFix="$"
                    />
                </div>
            ) : (
                nullFieldContentCell(cellStyle)
            );
        } else if (colKey !== "Dummy") {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={""}
                    />
                </div>
            );
        } else {
            return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    const onRenderField = (
        props?: IDetailsColumnFieldProps,
        defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
    ) => {
        return defaultRender({ ...props });
    };

    return (
        <>
            <div
                data-is-scrollable
                className="m365ScorecardTable"
                id="scorecardTable"
            >
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsListWrapper
                        items={items}
                        message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                        columns={getColumns(filters.cadence)}
                        selectionMode={SelectionMode.none}
                        onRenderDetailsHeader={(headerProps, defaultrender) => {
                            return DetailsListHelper.renderGroupedDetailsHeader(
                                headerProps,
                                getHeaderGroups(),
                                false,
                                defaultrender,
                            );
                        }}
                        enableShimmer={loading}
                        onRenderItemColumn={renderItemColumn}
                        onRenderField={onRenderField}
                        styles={DetailsListHelper.tenantTableStyle}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.unconstrained}
                        tableName="CopilotM365ScorecardTable"
                        getAdditionalTelemetryFields={() => {
                            return {
                                ActiveAppTab: getAIScorecardAppTabSelection(),
                                ActiveScorecardTab: getAIScorecardTabSelection(),
                            };
                        }}
                    />
                </ScrollablePane>
            </div>
        </>
    );
};

export default CopilotM365ScorecardTable;
