export const healthTrendConstants = {
    Web: {
        minMau: 2500,
        application: "WXP",
    },
    Win32: {
        minMau: 2500,
        application: "WXPO",
    },
    Mac: {
        minMau: 1000,
        application: "WXPON",
    },
};
