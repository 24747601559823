import React, { useEffect, useState } from "react";
import { LegendOrdinal } from "@visx/legend";
import { CompositeFunnelFooter } from "components/ConsumerDay0FunnelComponents/CompositeFunnelFooter/CompositeFunnelFooter";
import { CompositeFunnelHeader } from "components/ConsumerDay0FunnelComponents/CompositeFunnelHeader/CompositeFunnelHeader";
import { StackedAreaChart } from "components/CustomComponents/StackedAreaChart/StackedAreaChart";
import { StackedBarChart } from "components/CustomComponents/StackedBarChart/StackedBarChart";
import {
    getCompactValue,
    getDropOffNameForStage,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    flattenData,
    getKeys,
    getNextStageName,
    getStageAndNextStageDelta,
    getStageName,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/CompositeFunnelHelper";
import "components/ConsumerDay0FunnelComponents/CompositeFunnel/style.css";
import {
    areaHeight,
    areaWidth,
    barHeight,
    barWidth,
    margin,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/constants";
import {
    getStackedAreaXScale,
    stackedAreaYScale,
    getY0,
    getY1,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/StackedAreaHelper";
import {
    getColorScale,
    getStackedBarXScale,
    stackedBarYScale,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/StackedBarHelper";
import { CompositeFunnelProps } from "components/ConsumerDay0FunnelComponents/CompositeFunnel/types";
import { TooltipData } from "components/CustomComponents/StackedBarChart/types";
import { FlattenedScenarioDataType } from "pages/ConsumerDay0Funnel/types";

export const CompositeFunnel = ({
    stageScenarioDistribution,
    funnelData,
    selectedStage,
    selectedDropOff,
    selectedScenario,
    filters,
    changeSelectedFunnel,
    changeSelectedDropOff,
    changeSelectedScenario,
}: CompositeFunnelProps) => {
    const [flattenedData, setFlattenedData] =
        useState<FlattenedScenarioDataType[]>(null);

    useEffect(() => {
        const data: FlattenedScenarioDataType[] = flattenData(
            stageScenarioDistribution,
            selectedScenario,
        );
        setFlattenedData(data);
    }, [stageScenarioDistribution, selectedScenario, selectedStage]);

    const barChartOnClickHandler = (bar) => {
        if (
            bar.key === selectedScenario &&
            bar.bar.data["stage"] === selectedStage
        ) {
            // When a user clicks again on the selected bar, we want to remove the filter.
            changeSelectedScenario("All");
        } else {
            changeSelectedScenario(bar.key);
        }
    };

    const getToolTipMessage = (toolTipData: TooltipData) => {
        return (
            <div>
                <div className="stackedBarChartToolTipMajor">
                    <strong>{toolTipData.key}</strong> - [
                    {toolTipData.bar.data[toolTipData.key].toFixed(2)}% or{" "}
                    {getCompactValue(
                        stageScenarioDistribution[toolTipData.bar.data["stage"]][
                            "outerGroups"
                        ].filter((k) => k.name == toolTipData.key)[0].value,
                    )}
                    ]
                    <br />
                    (of All Sessions)
                </div>
                <div className="stackedBarChartToolTipMinor">
                    <p>Click to Scope / UnScope the funnel.</p>
                </div>
            </div>
        );
    };

    return (
        <div className="compositeFunnelContainer">
            {flattenedData &&
                flattenedData.map((data, i) => {
                    const stackedAreaPayload = [
                        flattenedData[i],
                        flattenedData[i + 1],
                    ];
                    const delta = getStageAndNextStageDelta(
                        i,
                        funnelData,
                        flattenedData,
                    );

                    return (
                        <div
                            className="compositeFunnelColumn"
                            key={`funnel-column-${i}`}
                        >
                            <CompositeFunnelHeader
                                funnelStageData={funnelData[data.stage]}
                                stageName={data.stage}
                            />
                            <div className="compositeFunnelChartContainer">
                                <StackedBarChart
                                    chartFormattingData={{
                                        height: barHeight,
                                        width: barWidth,
                                        margin: margin,
                                    }}
                                    data={[data]}
                                    keys={getKeys(flattenedData)}
                                    colorScale={getColorScale(
                                        flattenedData,
                                        filters.application,
                                    )}
                                    xScale={getStackedBarXScale([data])}
                                    yScale={stackedBarYScale}
                                    getXAxis={(x) => {
                                        return getStageName(x);
                                    }}
                                    onClickHandler={(bar) => {
                                        barChartOnClickHandler(bar);
                                        changeSelectedDropOff(
                                            getDropOffNameForStage(
                                                flattenedData[i]["stage"],
                                                filters.application,
                                            ),
                                        );
                                        changeSelectedFunnel(bar.bar.data["stage"]);
                                    }}
                                    isBarInactive={(bar) => {
                                        return (
                                            selectedScenario !== "All" &&
                                            bar.key !== selectedScenario &&
                                            selectedStage !== bar.bar.data["stage"]
                                        );
                                    }}
                                    isBarActive={(bar) => {
                                        return (
                                            selectedStage ===
                                                bar.bar.data["stage"] &&
                                            (bar.key === selectedScenario ||
                                                selectedScenario === "All")
                                        );
                                    }}
                                    getToolTipMessage={(toolTipData) => {
                                        return getToolTipMessage(toolTipData);
                                    }}
                                />
                                {i < flattenedData.length - 1 && (
                                    <StackedAreaChart
                                        chartFormattingData={{
                                            height: areaHeight,
                                            width: areaWidth,
                                            margin: margin,
                                        }}
                                        keys={getKeys(flattenedData)}
                                        data={stackedAreaPayload}
                                        defaultLabel={`${delta?.percentage.toFixed(
                                            2,
                                        )}%`}
                                        isDefaultState={selectedScenario === "All"}
                                        getXAxis={(dataPoint) => {
                                            const xScale1 =
                                                getStackedAreaXScale(
                                                    stackedAreaPayload,
                                                );
                                            return xScale1(
                                                getStageName(
                                                    dataPoint.data as FlattenedScenarioDataType,
                                                ),
                                            );
                                        }}
                                        getY0={(dataPoint) => {
                                            return stackedAreaYScale(
                                                getY0(dataPoint),
                                            );
                                        }}
                                        getY1={(dataPoint) => {
                                            return stackedAreaYScale(
                                                getY1(dataPoint),
                                            );
                                        }}
                                        isStackActive={(stack) => {
                                            return stack.key === selectedScenario;
                                        }}
                                        isStackInactive={(stack) => {
                                            return (
                                                selectedScenario !== "All" &&
                                                stack.key !== selectedScenario
                                            );
                                        }}
                                        defaultLabelOnClickHandler={() => {
                                            changeSelectedDropOff(
                                                getDropOffNameForStage(
                                                    flattenedData[i + 1]["stage"],
                                                    filters.application,
                                                ),
                                            );
                                            changeSelectedFunnel(
                                                flattenedData[i + 1]["stage"],
                                            );
                                        }}
                                        isLabelActive={() => {
                                            return (
                                                getDropOffNameForStage(
                                                    flattenedData[i + 1]["stage"],
                                                    filters.application,
                                                ) === selectedDropOff
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <CompositeFunnelFooter
                                nextStageName={getNextStageName(i, flattenedData)}
                                stageDeltaValue={delta?.value}
                            />
                        </div>
                    );
                })}
            <div className="compositeFunnelLegend">
                <LegendOrdinal
                    scale={getColorScale(flattenedData, filters.application)}
                    direction="row"
                    labelMargin="0 15px 0 0"
                />
            </div>
        </div>
    );
};
