import { macAvgCohortMetrics } from "./macAvgCohortMetrics";
import { macCoreMetrics } from "./macCoreMetrics";
import { macMetaData } from "./macMetaData";
import { macTenantEnvironmentData } from "./macTenantEnvironmentData";
import { macOfficeTenantScore } from "./macOfficeTenantScore";
import { macSparkLineScores } from "./macSparkLineScores";
import { macTenantList } from "./macTenantList";
import { macTenantMetrics, macTenantMetricsChart } from "./macTenantMetrics";

const inProductMacDrilldownQueries: {} = {
    macAvgCohortMetrics,
    macCoreMetrics,
    macMetaData,
    macTenantEnvironmentData,
    macOfficeTenantScore,
    macSparkLineScores,
    macTenantList,
    macTenantMetrics,
    macTenantMetricsChart,
};

export default inProductMacDrilldownQueries;
