import React from "react";
import Loader from "components/CustomComponents/Loader/index";

const LoadingModal = ({
    text = "",
    className = "loading-modal-wrapper",
}: {
    text: string;
    className?: string;
}): JSX.Element => {
    return (
        <div className={className}>
            {text !== "" && <h2>{text}</h2>}
            <Loader />
        </div>
    );
};

export default LoadingModal;
