import React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import AIScorecardFilters from "components/AIScorecardComponents/AIScorecardFilters";
import { CopilotCapAnalysis } from "components/AIScorecardComponents/CopilotCapAnalysis";
import { CopilotJourneyFunnel } from "components/AIScorecardComponents/CopilotJourneyFunnel";
import { CopilotRetention } from "components/AIScorecardComponents/CopilotRetention";
import { CopilotTopTenantInsights } from "components/AIScorecardComponents/CopilotTopTenantInsights";
import { CopilotAppMBRScorecard } from "components/AIScorecardComponents/CopilotViews/CopilotAppMBRScorecard";
import { CopilotAppScorecard } from "components/AIScorecardComponents/CopilotViews/CopilotAppScorecard";
import { commonPageBlock } from "pages/common";
import "components/AIScorecardComponents/style.css";

export const CopilotAppView = ({
    dates,
    filters,
    onFilterChange,
    tab,
    onTabSelected,
}) => {
    return (
        <>
            <AIScorecardFilters
                dates={dates}
                filters={filters}
                onFilterChange={onFilterChange}
            />
            <Pivot
                linkSize={"normal"}
                defaultSelectedKey={tab}
                onLinkClick={onTabSelected}
            >
                <PivotItem itemKey={"Scorecard"} headerText={"Scorecard"}>
                    <CopilotAppScorecard filters={filters} />
                </PivotItem>
                <PivotItem itemKey={"MBR"} headerText={"MBR"}>
                    <CopilotAppMBRScorecard filters={filters} />
                </PivotItem>
                <PivotItem itemKey={"Retention"} headerText={"Retention"}>
                    <Stack styles={commonPageBlock}>
                        <CopilotRetention filters={filters}></CopilotRetention>
                    </Stack>
                </PivotItem>
                <PivotItem
                    itemKey={"Top Tenant Insights"}
                    headerText={"Top Tenant Insights"}
                >
                    <Stack styles={commonPageBlock}>
                        <CopilotTopTenantInsights
                            filters={filters}
                        ></CopilotTopTenantInsights>
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={"Funnel"} headerText={"Funnel"}>
                    <Stack styles={commonPageBlock}>
                        <CopilotJourneyFunnel
                            filters={filters}
                        ></CopilotJourneyFunnel>
                    </Stack>
                </PivotItem>
                <PivotItem
                    itemKey={"Intent Analysis"}
                    headerText={"Intent Analysis"}
                >
                    <Stack styles={commonPageBlock}>
                        <CopilotCapAnalysis
                            filters={filters}
                            onFilterChange={onFilterChange}
                        />
                    </Stack>
                </PivotItem>
            </Pivot>
        </>
    );
};
