import React from "react";
import { Stack, Label, Dropdown, IDropdownOption } from "@fluentui/react";
import { SignalTrendFilterProps } from "pages/MTR/types";
import {
    dropdownStyles,
    comboBoxStyles,
    signalTrendFilterOptions,
} from "utils/Constants";
import { MtrComboBox } from "../MtrComboBox";
import { filterConstants } from "./SignalTrendConstants";

const getPlatformSpecificFilterOptions = (
    platform: string,
    filter: string,
): IDropdownOption[] => {
    return signalTrendFilterOptions[platform][filter];
};

export const SignalTrendFilters = ({
    filters,
    onFilterChange,
}: SignalTrendFilterProps) => {
    return (
        <Stack horizontal className="filterPanel">
            {filterConstants
                .filter((filterItem) =>
                    filters.group === "Application"
                        ? filterItem.item !== "app"
                        : filterItem.item !== "signal",
                )
                .map((filterItem) => {
                    return filterItem.isComboBox ? (
                        <div className="filter" key={filterItem.item}>
                            <Label className="filterLabel">{filterItem.label}</Label>
                            <MtrComboBox
                                filterKey={filterItem.item}
                                filterValue={filters[filterItem.item]}
                                className="filterDropdown"
                                styles={comboBoxStyles}
                                options={
                                    filterItem.platformSpecific
                                        ? getPlatformSpecificFilterOptions(
                                              filters.platform,
                                              filterItem.item,
                                          )
                                        : filterItem.options
                                }
                                onFilterChange={(filterKey, filterValue) => {
                                    onFilterChange(filterKey, filterValue);
                                }}
                            />
                        </div>
                    ) : (
                        <div className="filter" key={filterItem.item}>
                            <Label className="filterLabel">{filterItem.label}</Label>
                            <Dropdown
                                className="filterDropdown"
                                styles={dropdownStyles}
                                options={
                                    filterItem.platformSpecific
                                        ? getPlatformSpecificFilterOptions(
                                              filters.platform,
                                              filterItem.item,
                                          )
                                        : filterItem.options
                                }
                                onChange={(event, option) => {
                                    onFilterChange(
                                        filterItem.item,
                                        option.key.toString(),
                                    );
                                }}
                                selectedKey={filters[filterItem.item]}
                            />
                        </div>
                    );
                })}
        </Stack>
    );
};
