export const teamsTenantMetrics: string = `
//Teams Tenant Metrics
let id = "\${id}";
let dt = "\${date}";
let teamsNPS = Teams_NPS
| where TenantId == id and Date == format_datetime(datetime_add("day", 27, startofmonth(todatetime(dt))), "yyyy-MM-dd")
| distinct Date, TenantId, RatingScale, Total
| summarize TotalResponders = sum(Total) by Date, TenantId;
let cqd_filtered = 
MeetingCQD_Mar
| extend Date = dt
| where TenantId == id;
let rmc =
cqd_filtered
| extend MetricName = "RMC", Percentage = RMC;
let audioPoor =
cqd_filtered
| extend MetricName = "Audio Poor", Percentage = ['Audio Poor'];
let videoPoor =
cqd_filtered
| extend MetricName = "Video Poor", Percentage = ['Video Poor'];
let setupFail =
cqd_filtered
| extend MetricName = "Setup Fail", Percentage = ['Setup Fail'];
let Drop =
cqd_filtered
| extend MetricName = "Drop", Percentage = Drop;
let TCP =
cqd_filtered
| extend MetricName = "TCP", Percentage = TCP;
let VPN =
cqd_filtered
| extend MetricName = "VPN", Percentage = VPN;
let OldClients =
cqd_filtered
| extend MetricName = "Old Clients", Percentage = ['Old Clients'];
let cqd_Metrics = 
rmc
| union audioPoor | union videoPoor | union setupFail | union Drop | union TCP | union VPN | union OldClients
| project Date, Metric = MetricName, SubWorkload = "Meeting", Value = toreal(replace_string(Percentage, "%", "")) ;
(Teams_Signals
| where iff("OmsTenantId" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where OmsTenantId == id and Date == dt and (Signal == "Score" or Signal == "NPS" or Signal == "UsageRate")
| project Date, Metric=Signal, SubWorkload, Value = Signal_Value) 
| union (
Teams_Meetings_Health_Scores
| where TenantId == id and Date == dt
| project Date, Metric = "MAU", SubWorkload = "Meeting", Value = toreal(Meetings_MAU)
| union (
Teams_Chat_Health_Scores
| where TenantId == id and Date == dt
| project Date, Metric = "MAU", SubWorkload = "Chat", Value = toreal(Usage_Chats_MAU)))
|  union
(Teams_Meetings_Health_Scores
| where TenantId == id and Date == dt
| project Date, Metric = "Teams_MAU", SubWorkload = "Meeting", Value = toreal(Teams_MAU))
|  union (
Teams_Chat_Health_Scores
| where TenantId == id and Date == dt
| project Date, Metric = "Teams_MAU", SubWorkload = "Chat", Value = toreal(Usage_Teams_MAU))
| union (
teamsNPS
| project Date = dt, Metric = "NPSResponders", SubWorkload = "Chat", Value = toreal(TotalResponders))
| union (
teamsNPS
| project Date = dt, Metric = "NPSResponders", SubWorkload = "Meeting", Value = toreal(TotalResponders))
| join kind = inner (
datatable (Signal:string, DisplayName:string)[
"Score","Score","MAU", "MAU", "Teams_MAU", "Teams MAU", "UsageRate", "Attach Rate","NPS", "Teams NPS", "NPSResponders", "NPS Responders",
"RMC", "RMC", "Video Poor", "Poor Video", "Drop", "Drop", "Setup Fail", "Setup Fail", "VPN", "VPN", "TCP", "TCP", "Old Clients", "Old Clients",
"Audio Poor", "Poor Audio"]) 
on $left.Metric == $right.Signal 
| join kind = inner (
        datatable (SignalGroup:string, Signals:dynamic , Order:int)[
        "Score", dynamic(["Score"]),1, "Usage", dynamic(["MAU", "Teams_MAU", "UsageRate"]), 2, 
        "Call Quality", dynamic(["RMC","Video Poor", "Drop", "Setup Fail", "VPN", "TCP", "Old Clients",
        "Audio Poor" ]), 3,
        "Feedback", dynamic(["NPS", "NPSResponders"]),4]
        | mv-expand Signals
        | extend Signal = tostring(Signals)
) on $left.Metric == $right.Signal
| order by Order asc
| project Date, Metric, DisplayName, SignalGroup, SubWorkload, Value = iff(isempty( Value), "NA",tostring(Value))
`;
export const teamsTenantMetricsChart: string = `
// Teams TenantMetricsChart
let id = "\${id}";
let teamsNPS = Teams_NPS
| where TenantId == id;
(Teams_Signals
| where iff("OmsTenantId" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where OmsTenantId == id and (Signal == "Score" or Signal=="NPS" or Signal == "UsageRate")
| project Date, Metric = Signal, SubWorkload, Value = Signal_Value
| union (
Teams_Meetings_Health_Scores
| where TenantId == id 
| project Date, Metric = "MAU", SubWorkload = "Meeting", Value = toreal(Meetings_MAU))
| union (
Teams_Chat_Health_Scores
| where TenantId == id
| project Date, Metric = "MAU", SubWorkload = "Chat", Value = toreal(Usage_Chats_MAU))
|  union (
Teams_Meetings_Health_Scores
| where TenantId == id 
| project Date, Metric = "Teams_MAU", SubWorkload = "Meeting", Value = toreal(Teams_MAU))
|  union (
Teams_Chat_Health_Scores
| where TenantId == id
| project Date, Metric = "Teams_MAU", SubWorkload = "Chat", Value = toreal(Usage_Teams_MAU))
| union (
teamsNPS
| project Date, Metric = "NPSResponders", SubWorkload = "Chat", Value = toreal(Total))
| union (
teamsNPS
| project Date, Metric = "NPSResponders", SubWorkload = "Meeting", Value = toreal(Total)))
| where Metric == "\${metric}"         
| project Date, SubWorkload, Value = round(Value,2)
| order by Date asc
`;
