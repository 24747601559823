import React, { useState } from "react";
import { Toggle, DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { appStyles } from "utils/Constants";

const CONSTANTS = {
    COLUMN_WIDTH: 100,
    FIXED_ROW_HEADER_COLUMN_WIDTH: 225,
};

const _generateColumns = () => {
    const displayColumns = [
        ["Stage", "", CONSTANTS.FIXED_ROW_HEADER_COLUMN_WIDTH, null],
        ["Word", "", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
        ["Excel", "", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
        ["PowerPoint", "", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
        ["WXP", "", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        false,
    );
    columns.forEach((column) => {
        if (column.key !== "Stage") {
            column.iconName = appStyles[column.key]["iconName"];
        } else {
            column.isRowHeader = true;
        }
    });
    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

    return columns;
};

export const CollabDataTable = (props) => {
    const [showCount, setShowCount] = useState(true);
    const _toggleCountView = () => {
        setShowCount(!showCount);
    };

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        switch (column.key) {
            case "Stage":
                return <DetailsListHelper.TextValue value={fieldContent} />;
            default:
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={showCount ? 0 : 2}
                    />
                );
        }
    };

    return (
        <>
            {props.loading || !props.payload ? (
                <ShimmeredCardBody />
            ) : (
                <>
                    <h2
                        className="commonPageBlockTitle"
                        style={{
                            display: "inline-flex",
                        }}
                    >
                        Collab Funnel
                        <span className="funnelToggle">
                            <Toggle
                                checked={showCount}
                                onChange={_toggleCountView}
                                offText="Percent"
                                onText="Numbers"
                            />
                        </span>
                    </h2>
                    {Object.keys(props.payload[0]).length > 1 ? (
                        <DetailsList
                            items={props.payload.filter((i) =>
                                !showCount
                                    ? i.Stage.includes("%")
                                    : !i.Stage.includes("%"),
                            )}
                            columns={_generateColumns()}
                            onRenderItemColumn={_renderItemColumn}
                            selectionMode={SelectionMode.none}
                            className={"tenantTable"}
                        />
                    ) : (
                        <EmptyTableContainer />
                    )}
                </>
            )}
        </>
    );
};
