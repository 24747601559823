export const tenantMetricsChartSupportTickets: string = `
        // new product support tickets
        let supportTickets = database('TenantInsights').ProductSupportTickets
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")   
        | where \${levelColumnName} == toupper("\${id}")
        | where Metric == "\${metric}"
        | extend \${levelColumnName} = tostring(\${levelColumnName});
        let applications = parse_csv("\${appsList}");
        TenantInsights_Win32_MonthsAvailable
        | extend Application = applications
        | mv-expand Application
        | extend Application = tostring(Application)
        | extend \${levelColumnName} = toupper("\${id}")
        | join kind = leftouter(supportTickets) on \${levelColumnName}, Application, $left.Dates == $right.ProcessDate
        | project Date = Dates, Application, Value = iff(isempty( Value), 0,Value)
        | order by Date asc
`;
