export const win32TenantStatus: string = `
//win32TenantStatus (for movers/shakers)
let dt = "\${date}";
let app = "\${application}";
let cohort = "\${cohort}";
let Mau = \${mauCountThreshold};
let level = "\${level}";
let currentDate = GetCurrentDate(false); //isTesting value is set to false for GetCurrentDate function
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime( dt)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_Win32Overview
    | where Date == dt or Date == prev
    | where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == app  and Color > 1
    | project-rename Tpid = TopParentId
    | extend CurrencyDetails = parse_json(Currency_Details)
    | project OmsTenantId, Score, Color, MoMChange, MAU, Currency_InSupport_Score, Currency_InSupport_Color, 
        Date, Application, Perf_BootLaunchPercentageUnderNs_Score, Usage_PercMau5DayUsage_Score, 
        Usage_PercMau5DayUsage_Color, Usage_PercMau5DayUsage_MoMChange, Composite_Score = Score, 
        Tpid, Quality_Watson_Score, Quality_Watson_Color, Quality_UAE_Score, Quality_UAE_Color, 
        Feedback_Score, Feedback_Color, Perf_BootTimeP95_Score, Perf_Color, Perf_BootPercentageUnderNs_Score,
        Perf_Boot_Color, Perf_BootTimeP95_MoMChange, Perf_BootPercentageUnderNs_MoMChange, 
        Quality_Watson_MoMChange, Quality_UAE_MoMChange, Feedback_MoMChange, TenantName_Translated,Currency_DominantChannelFork_Text = CurrencyDetails.DominantChannelFork);
Overview 
    | where MAU >= Mau
    | where Date == dt
    | extend CurrentStatus = Color
 | join kind= inner 
    (
        Overview
        | where Date == prev
        | extend PreviousStatus = Color
    )     
on \${levelColumnName}  
| where CurrentStatus == 3 or CurrentStatus == 2 or PreviousStatus == 3 or PreviousStatus == 2
| join kind=inner 
        (StrategicTenants
            | extend IsMidMarket = iff(Cohorts contains "MidMarket", true, false)
            | where Date == currentDate and Is\${cohort} == true
            | project tolong(Tpid), OmsTenantId, OrgName_Translated, TenantName_Translated
            | distinct \${levelColumnName}, iif(level  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName} 
    | join kind = inner (
        HealthyTenantScore_PastStatus | project-rename Tpid = TopParentId | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application ==  app
    )
    on \${levelColumnName}
    | extend Composite_Score = round(Score,2) 
    | extend Composite_MoMChange = round(MoMChange,2)
    | extend MAU_MoMChange = round((todouble(MAU-MAU1)/MAU1)*100,2) | extend MAU_Score = MAU
    | extend EngagedUsers_MoMChange = round((todouble(Usage_PercMau5DayUsage_Score-Usage_PercMau5DayUsage_Score1)/Usage_PercMau5DayUsage_Score1)*100,2)
    | extend PerfP95_Score = Perf_BootTimeP95_Score | extend PerfP95_Score_Color = GetColor(Perf_Boot_Color)
    | extend PerfP95_MoMChange = round(Perf_BootTimeP95_MoMChange*100,2)
    | extend PerfBoot_Score = round(Perf_BootPercentageUnderNs_Score,2)
    | extend PerfBoot_MoMChange = round(Perf_BootPercentageUnderNs_MoMChange*100,2) | extend PerfBoot_Score_Color = GetColor(Perf_Boot_Color)
    | extend Watson_Score= Quality_Watson_Score |extend Watson_Score_Color = GetColor(Quality_Watson_Color) | extend Watson_MoMChange = round(Quality_Watson_MoMChange*100,2) 
    | extend UAE_Score = Quality_UAE_Score| extend UAE_Score_Color = GetColor(Quality_UAE_Color)|extend UAE_MoMChange = round(Quality_UAE_MoMChange*100,2) 
    | extend OCV_Score = Feedback_Score | extend OCV_Score_Color = GetColor(Feedback_Color)| extend OCV_MoMChange = round(Feedback_MoMChange*100,2)
    | extend Currency_MoMChange = round((todouble(Currency_InSupport_Score - Currency_InSupport_Score1)/Currency_InSupport_Score)*100, 2)
    | project \${level} = \${levelColumnName}, OrgName = Name , 
    Composite_Score, Composite_MoMChange, 
    MAU_Score, MAU_MoMChange, 
    EngagedUsers_Score = Usage_PercMau5DayUsage_Score, EngagedUsers_Score_Color = GetColor(Usage_PercMau5DayUsage_Color), EngagedUsers_MoMChange,
    PerfP95_Score, PerfP95_Score_Color, PerfP95_MoMChange, 
    PerfBoot_Score, PerfBoot_MoMChange,PerfBoot_Score_Color,
    Watson_Score, Watson_Score_Color, Watson_MoMChange, 
    UAE_Score, UAE_Score_Color, UAE_MoMChange,
    OCV_Score, OCV_Score_Color, OCV_MoMChange,
    Currency_Score = Currency_InSupport_Score, Currency_Score_Color = GetColor(Currency_InSupport_Color), Currency_MoMChange, Currency_DominantChannelFork_Text,
    PastStatus, CurrentStatus = GetColor(CurrentStatus), PreviousStatus = GetColor(PreviousStatus), Parent = Tpid, TenantName_Translated`;
