export const crossplatformHealthChart: string = `
// cross platform health chart
let currDate = GetCurrentDateCrossPlatform();
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let minMAU = 2500;
let tenantSet =
CrossPlatform
    | where Application == app and CrossApp_Color!="Gray"  and (W32_Mau>=minMAU or Web_Mau>=minMAU)
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | extend Tpid = TopParentId, Status = CrossApp_Color
    | join kind=inner hint.strategy=broadcast
        (
            StrategicTenants
            | where Is\${cohort} == true and Date == currDate
        ) on \${levelColumnName};
tenantSet
    | summarize Count = dcount(\${levelColumnName}) by Date, Status
    | extend Current = (Date == dt)
    | project Date, Status, Count, Current
    | order by Date asc, Status asc;
`;
