export const perfInsightsChartQuery = `
// perfInsightsChartQuery: Query to fetch the data for charts corresponding to the row selected in Performance insights table.
\${tenantTableName}
| where SQLizerStreamDateId between (datetime("\${startDate}")..datetime("\${endDate}"))
| where TenantId == "\${tenantId}" and AppName == "\${appName}" and DimensionName == "\${dimensionName}"
| where Scenario in (iff(("\${scenario}" == "Boot-Overall"),dynamic(["Boot-File","Boot-Launch"]),dynamic(["\${scenario}"])))
| extend PercentBelowThreshold=100.0*EventUnderThresholdCount/EventCount
| project Date=SQLizerStreamDateId, DeviceCount, Value=round(\${valuesToShow},1), EventCount, DominantFork, Series=DimensionValue
| sort by Series, Date asc
`;
