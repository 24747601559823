import React, { useEffect, useState } from "react";
import {
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Stack,
} from "@fluentui/react";
import { ParentSize } from "@visx/responsive";
import {
    Card,
    CardHeader,
    CardHeaderTitle,
    CardBody,
} from "components/CustomComponents/Card/Card";
import FiltersPanel from "components/QuIPSurveyReportComponents/FiltersPanel/FiltersPanel";
import FunnelChart from "components/QuIPSurveyReportComponents/SurveyFunnelChart/SurveyFunnelChart";
import UxSchemaDrillDownArea from "pages/QuIP/SurveyFunnelDrillDown20/UxSchemaDrillDown20";
import ISurveyFunnelDropDownFilters from "components/QuIPSurveyReportComponents/FiltersPanel/types";
import { commonPageStyle } from "pages/common";
import { executeQuipSurveyQuery } from "pages/QuIP/KustoHelper";
import { ISurveyFunnelChartData } from "pages/QuIP/SurveyFunnelDrillDown20/types";
import { prepareDataForSurveyFunnelChart } from "pages/QuIP/SurveyFunnelDrillDown20/Utils";

const SurveyFunnelDrillDown20 = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [surveyFunnelDrillDownFilters, setSurveyFunnelDrillDownFilters] =
        useState<ISurveyFunnelDropDownFilters>({
            appIds: [{ key: "All", text: "All" }],
            audienceGroups: [{ key: "All", text: "All" }],
            dateRange: {
                startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
                endDate: new Date(),
            },
            platforms: [{ key: "All", text: "All" }],
            campaignIds: [{ key: "All", text: "All" }],
        });

    const [selectedStartDate, setSelectedStartDate] = useState<string>(
        surveyFunnelDrillDownFilters.dateRange.startDate.toLocaleDateString("en-US"),
    );
    const [selectedEndDate, setSelectedEndDate] = useState<string>(
        surveyFunnelDrillDownFilters.dateRange.endDate.toLocaleDateString("en-US"),
    );
    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([
        surveyFunnelDrillDownFilters.platforms[0].key as string,
    ]);
    const [selectedAudience, setSelectedAudience] = useState<string[]>([
        surveyFunnelDrillDownFilters.audienceGroups[0].key as string,
    ]);
    const [selectedAppId, setSelectedAppId] = useState<string>(
        surveyFunnelDrillDownFilters.appIds[0].key as string,
    );
    const [selectedCampaignIds, setSelectedCampaignIds] = useState<string>(
        surveyFunnelDrillDownFilters.campaignIds[0].key as string,
    );
    const [isDrillDownReady, setIsDrillDownReady] = useState<boolean>(false);

    const [generalFunnelData, setGeneralFunnelData] = useState<
        ISurveyFunnelChartData[]
    >([]);

    const onFiltersValueChange = (
        field: string,
        value: number | string | object,
    ) => {
        switch (field) {
            case "startDate":
                setSelectedStartDate(value as string);
                break;
            case "endDate":
                setSelectedEndDate(value as string);
                break;
            case "appId":
                setSelectedAppId(value as string);
                break;
            case "platforms":
                setSelectedPlatforms(value as string[]);
                break;
            case "audienceGroup":
                setSelectedAudience(value as string[]);
                break;
            case "campaignId":
                setSelectedCampaignIds(value as string);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setIsDrillDownReady(
            selectedAppId !== "" &&
                selectedAppId !== "All" &&
                selectedAudience.length === 1 &&
                !selectedAudience.includes("All") &&
                selectedPlatforms.length === 1 &&
                !selectedPlatforms.includes("All") &&
                selectedCampaignIds !== undefined &&
                selectedCampaignIds !== "All",
        );
    }, [
        selectedAppId,
        selectedAudience,
        selectedAudience.length,
        selectedCampaignIds,
        selectedPlatforms,
        selectedPlatforms.length,
    ]);

    useEffect(() => {
        const fetchFilters = async () => {
            setLoading(true);
            const platforms = await executeQuipSurveyQuery(
                "quipSurveyFunnelAppPlatforms",
            );
            const audienceGroup = await executeQuipSurveyQuery(
                "quipSurveyFunnelAudienceGroups",
            );
            const hostIds = await executeQuipSurveyQuery(
                "quipSurveyFunnelApplicationIds",
            );
            const campaignIds = await executeQuipSurveyQuery(
                "quipSurveyFunnelCampaignIds",
            );
            setSurveyFunnelDrillDownFilters((oldVal) => ({
                ...oldVal,
                platforms: [
                    ...oldVal.platforms,
                    ...(platforms?.map((platform) => ({
                        key: platform,
                        text: platform,
                    })) || []),
                ],
                audienceGroups: [
                    ...oldVal.audienceGroups,
                    ...(audienceGroup?.map((audience) => ({
                        key: audience,
                        text: audience,
                    })) || []),
                ],
                appIds: [
                    ...oldVal.appIds,
                    ...(hostIds?.map((appId) => ({
                        key: appId,
                        text: appId,
                    })) || []),
                ],
                campaignIds: [
                    ...oldVal.campaignIds,
                    ...(campaignIds?.map((campaignId) => ({
                        key: campaignId,
                        text: campaignId,
                    })) || []),
                ],
            }));
            setLoading(false);
        };
        fetchFilters();
    }, []);

    useEffect(() => {
        setLoading(true);
        executeQuipSurveyQuery(
            "quipSurveyGeneralFunnelData",
            {
                startTime: `'${selectedStartDate}'`,
                endTime: `'${selectedEndDate}'`,
                surveyIds: JSON.stringify(
                    selectedCampaignIds ? [selectedCampaignIds] : null,
                ),
                platforms: JSON.stringify(
                    selectedPlatforms.length > 0 ? selectedPlatforms : null,
                ),
                audience: JSON.stringify(
                    selectedAudience.length > 0 ? selectedAudience : null,
                ),
                appIds: JSON.stringify(selectedAppId ? [selectedAppId] : null),
            },
            false,
        ).then((generalFunnelData) => {
            const processedSurveyData = prepareDataForSurveyFunnelChart(
                generalFunnelData,
                [],
            );
            processedSurveyData.push({
                index: processedSurveyData.length,
                label: "",
                value: 0,
                rawValue: 0,
            });
            setGeneralFunnelData(processedSurveyData);
            setLoading(false);
        });
    }, [
        selectedAppId,
        selectedAudience,
        selectedCampaignIds,
        selectedEndDate,
        selectedPlatforms,
        selectedStartDate,
    ]);

    return (
        <Stack styles={commonPageStyle} enableScopedSelectors verticalFill={true}>
            <Stack.Item>
                <div className="orgHeader">
                    Floodgate 2.0 - Survey Funnel (DrillDown)
                </div>
            </Stack.Item>
            <Stack.Item>
                <FiltersPanel
                    data={surveyFunnelDrillDownFilters}
                    onChange={onFiltersValueChange}
                />
            </Stack.Item>
            {loading ? (
                <Stack.Item style={{ marginTop: "10%" }}>
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>
            ) : (
                <Stack.Item>
                    <Card>
                        <CardHeader>
                            <CardHeaderTitle>General Funnel</CardHeaderTitle>
                        </CardHeader>
                        <CardBody>
                            {generalFunnelData.length < 2 ? (
                                <p>No data available for the selected filters</p>
                            ) : (
                                <ParentSize>
                                    {({ width }) => (
                                        <FunnelChart
                                            width={width}
                                            height={500}
                                            data={generalFunnelData}
                                        />
                                    )}
                                </ParentSize>
                            )}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardHeaderTitle>Drill Down by UxSchema</CardHeaderTitle>
                        </CardHeader>
                        <CardBody>
                            {!isDrillDownReady ? (
                                <MessageBar messageBarType={MessageBarType.info}>
                                    Drill Down report is only available for a unique
                                    Survey Id, Audience, Application and Platform
                                    combination.
                                </MessageBar>
                            ) : (
                                <p>
                                    <ParentSize>
                                        {({ width }) => (
                                            <UxSchemaDrillDownArea
                                                application={selectedAppId}
                                                audience={selectedAudience[0]}
                                                campaignId={selectedCampaignIds}
                                                platform={selectedPlatforms[0]}
                                                startDate={selectedStartDate}
                                                endDate={selectedEndDate}
                                                width={width}
                                                height={600}
                                            />
                                        )}
                                    </ParentSize>
                                </p>
                            )}
                        </CardBody>
                    </Card>
                </Stack.Item>
            )}
        </Stack>
    );
};

export default SurveyFunnelDrillDown20;
