import React from "react";

export const ExpandRow = (props) => {
    return (
        <div>
            {props.condition ? (
                <span
                    style={{
                        paddingRight: "2px",
                        position: "relative",
                        top: "-7px",
                        fontSize: "12px",
                    }}
                    title="Click to view notes."
                >
                    +
                </span>
            ) : (
                <span style={{ paddingLeft: "10px" }} />
            )}
            {props.children}
        </div>
    );
};
