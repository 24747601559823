import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import CopilotAppScorecardTable from "components/AIScorecardComponents/CopilotAppScorecardTable";
import EmtpyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchCopilotData,
    formatScorecardTableData,
} from "components/AIScorecardComponents/ApiHandler";
import { commonPageBlock } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";

export const CopilotAppScorecard = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [scorecardData, setScorecardData] = useState([]);

    useEffect(() => {
        const getScorecardData = async () => {
            setLoading(true);
            setError(null);

            try {
                const [data] = await Promise.all([
                    fetchCopilotData(
                        {
                            application: filters.application,
                            date: filters.date,
                            audienceGroup: filters.audienceGroup,
                            office365Category: filters.office365Category,
                            platform: filters.platform,
                            cadence: filters.cadence,
                            cohort: filters.cohort,
                            tenantId: "All",
                        },
                        "aiScorecardMetrics",
                    ),
                ]);

                let formattedData = formatScorecardTableData(data);
                setScorecardData(formattedData);
                setLoading(false);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotAppScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot App Scorecard data",
                    },
                    e,
                );

                setError(e.message);
                setScorecardData([]);
                setLoading(false);
            }
        };

        if (filters.date !== null) getScorecardData();
    }, [
        filters.application,
        filters.date,
        filters.audienceGroup,
        filters.office365Category,
        filters.platform,
        filters.cadence,
        filters.cohort,
    ]);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            {!loading && filters.date === null ? (
                <EmtpyTableContainer />
            ) : (
                <Stack.Item styles={commonPageBlock}>
                    <CopilotAppScorecardTable
                        items={scorecardData}
                        loading={loading}
                        filters={filters}
                    />
                </Stack.Item>
            )}
        </>
    );
};
