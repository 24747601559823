import React from "react";
import { Link } from "react-router-dom";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsRowStyles,
    IRenderFunction,
    SelectionMode,
} from "@fluentui/react";
import {
    ScrollablePane,
    ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import {
    getMTEandTVScorecardColumns,
    getGroups,
} from "components/AIScorecardComponents/AIScorecardTableHelper";
import { MTEandTVScorecardColumns } from "components/AIScorecardComponents/config";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
    sendAIScorecardUserActionTelemetryEvent,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import { AIScorecardTablePropsType } from "components/AIScorecardComponents/types";
import { NO_DATA_MESSAGES } from "utils/Messages";

const getHeaderGroups = () => {
    const groups = {};
    MTEandTVScorecardColumns.forEach((column) => {
        groups[column.name] = column.headerGroups;
    });
    return groups;
};

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    return defaultRender({ ...props });
};

const onRenderRow = (props?: IDetailsColumnFieldProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#fcf5ed" };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

const renderCellWithSubtext = (
    fieldContent,
    subText,
    cellStyle: React.CSSProperties,
    subTextPrefix = "responses",
): JSX.Element => {
    fieldContent = fieldContent === 0 ? `0%` : fieldContent;
    return (
        <>
            <div className="scorecardCell" style={cellStyle}>
                <DetailsListHelper.NumericalValue
                    value={fieldContent ?? "-"}
                    postfix={fieldContent ? "%" : ""}
                    decimalPrecision={1}
                />
            </div>
            <div className="scorecardCell">
                {subText ? (
                    <div className="alignRight responseCount">
                        {subText} {subTextPrefix}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

const CopilotMTEandTVTable = ({
    items,
    loading,
    filters,
}: AIScorecardTablePropsType) => {
    const columns = getMTEandTVScorecardColumns();

    const renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;

        // Check to not render unnecessary columns when used with GroupHeader component
        if (
            item.FeatureVerb === "All" &&
            ![
                "PositiveProductMarketFitRate",
                "ExceededExpectationsRate",
                "FeatureVerb",
                "CopilotNps",
            ].includes(colKey)
        ) {
            return <></>;
        }
        const cellStyle: React.CSSProperties = {};
        if (fieldContent === "Not Complete")
            return (
                <DetailsListHelper.StatusBar color={"#f3501e"}>
                    <DetailsListHelper.TextValue value={fieldContent} />
                </DetailsListHelper.StatusBar>
            );

        if (colKey === "FeatureVerb") {
            return (
                <div>
                    <DetailsListHelper.TextValue
                        value={fieldContent}
                        class="featureCell"
                    />
                </div>
            );
        } else if (colKey === "NegativeFeelingsRate") {
            return renderCellWithSubtext(
                fieldContent,
                item["NegativeFeelingResponseCount"],
                cellStyle,
            );
        } else if (colKey === "PositiveProductMarketFitRate") {
            return renderCellWithSubtext(
                fieldContent,
                item["PMFResponseCount"],
                cellStyle,
            );
        } else if (colKey === "ExceededExpectationsRate") {
            return renderCellWithSubtext(
                fieldContent,
                item["ExceedExpectationsResponseCount"],
                cellStyle,
            );
        } else if (colKey === "ThumbsUpResponseRate") {
            return renderCellWithSubtext(
                fieldContent,
                item["ThumbUpResponseCount"],
                cellStyle,
                "clicks",
            );
        } else if (colKey === "NegativeTimeSavedRate") {
            return renderCellWithSubtext(
                fieldContent,
                item["TimeSavedResponseCount"],
                cellStyle,
            );
        } else if (colKey.endsWith("Rate")) {
            return (
                <div className="scorecardCell" style={cellStyle}>
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={fieldContent ? "%" : ""}
                        decimalPrecision={1}
                    />
                </div>
            );
        } else if (colKey === "ASHA") {
            const endDate = filters["date"];
            const lookback = filters["cadence"] === "Monthly" ? 27 : 6;
            let tmpDate = new Date(endDate);
            tmpDate.setDate(tmpDate.getDate() - lookback);
            const startDate = tmpDate?.toISOString().split("T")[0];

            // The Loop app uses the "Loop App" name in the ASHA dashboard/tables instead of "Loop"
            const app = item["AppName"] === "Loop" ? "Loop App" : item["AppName"];
            const link = `//asha-next.azurewebsites.net/?app=${app}&datePreset=&startDate=${startDate}&endDate=${endDate}&platform=${filters["platform"]}&office365Category=${filters["office365Category"]}&audienceGroup=${filters["audienceGroup"]}`;
            return fieldContent ? (
                <Link
                    to={link}
                    target="_blank"
                    onClick={() => {
                        sendAIScorecardUserActionTelemetryEvent({
                            userActionName: "M365ScorecardTableASHALinkClick",
                            commandSurface: UserActionCommandSurface.Canvas,
                        });
                    }}
                >
                    <div className="scorecardCell" style={cellStyle}>
                        <DetailsListHelper.NumericalValue
                            value={fieldContent}
                            postfix={"%"}
                            decimalPrecision={1}
                        />
                    </div>
                </Link>
            ) : (
                <div className="scorecardCell alignRight" style={cellStyle}>
                    -
                </div>
            );
        } else if (colKey === "CopilotNps") {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={""}
                        decimalPrecision={1}
                    />
                </div>
            );
        } else if (colKey !== "Feature" && colKey !== "Dummy") {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={""}
                    />
                </div>
            );
        } else {
            return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    const groups = getGroups(items, "AppName");

    return (
        <>
            <div data-is-scrollable className="scorecardTable" id="scorecardTable">
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsListWrapper
                        items={items}
                        groups={groups}
                        message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onRenderDetailsHeader={(headerProps, defaultrender) => {
                            return DetailsListHelper.renderGroupedDetailsHeader(
                                headerProps,
                                getHeaderGroups(),
                                true,
                                defaultrender,
                            );
                        }}
                        enableShimmer={loading}
                        onRenderItemColumn={renderItemColumn}
                        onRenderField={onRenderField}
                        onRenderRow={onRenderRow}
                        styles={DetailsListHelper.tenantTableStyle}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        tableName="CopilotM365ScorecardMTEandTVTable"
                        getAdditionalTelemetryFields={() => {
                            return {
                                ActiveAppTab: getAIScorecardAppTabSelection(),
                                ActiveScorecardTab: getAIScorecardTabSelection(),
                            };
                        }}
                    />
                </ScrollablePane>
            </div>
        </>
    );
};

export default CopilotMTEandTVTable;
