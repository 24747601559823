import { SetStateAction } from "react";
import { SegmentParametersMap } from "pages/ConsumerGrowthDrilldown/ConsumerGrowthDrilldownHelper";
import * as API from "api";
import {
    formatAppMetrics,
    formatCoreMetrics,
    formatScoreLines,
} from "api/commonApiHandler";
import { MetricsResponseType } from "pages/commonTypes";
import {
    ChartDataType,
    ConsumerGrowthDrilldownCoreMetricsType,
    ConsumerGrowthDrilldownFilterState,
    ConsumerGrowthDrilldownState,
} from "pages/ConsumerGrowthDrilldown/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

export const formatGrowthDrilldownCoreMetricsResponse = (
    coreMetrics: MetricsResponseType,
    sparkLineScores: MetricsResponseType,
    appMetricsScores: MetricsResponseType,
    workload: string,
): ConsumerGrowthDrilldownCoreMetricsType => {
    const emptyAvgCohortJson = {
        Tables: [{ Columns: [], Rows: [], TableName: "Tables_0" }],
    };
    try {
        const { tenantCoreHealthMetric } = formatCoreMetrics(
            coreMetrics,
            emptyAvgCohortJson,
            workload,
        );

        const sparkLines = formatScoreLines(sparkLineScores, workload);

        const appMetrics = formatAppMetrics(appMetricsScores);

        return {
            coreMetricsData: tenantCoreHealthMetric,
            sparklinesData: sparkLines,
            appMetricsData: appMetrics,
            platform: workload,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerGrowthDrilldownFormatCoreMetricsResponseFailed",
            ),
            {
                message:
                    "Failure in parsing Core Metrics responses for the Growth Drilldown page",
                data: {
                    coreMetrics,
                    sparkLineScores,
                    workload,
                },
            },
            error,
        );
        throw error;
    }
};

export const fetchGrowthDrilldownCoreHealthData = async (
    filters: ConsumerGrowthDrilldownFilterState,
    platform: string,
    stateUpdater: React.Dispatch<SetStateAction<ConsumerGrowthDrilldownState>>,
    errorHandler: React.Dispatch<SetStateAction<string>>,
) => {
    const queryParams = {
        ...filters,
        ...SegmentParametersMap[filters.segment].queryParameters,
    };

    try {
        const [coreHealth, sparkLineScores, appMetrics] = await Promise.all([
            API.coreMetrics(queryParams, platform),
            API.sparkLineScores(queryParams, platform),
            API.tenantMetrics(queryParams, platform),
        ]);

        const coreHealthMetrics = formatGrowthDrilldownCoreMetricsResponse(
            coreHealth?.data,
            sparkLineScores?.data,
            appMetrics?.data,
            platform,
        );

        stateUpdater((oldDataState) => {
            return {
                ...oldDataState,
                coreMetrics: coreHealthMetrics,
                loading: false,
            };
        });
    } catch (error) {
        errorHandler(error.message);
        stateUpdater((oldDataState) => {
            return {
                ...oldDataState,
                loading: false,
            };
        });
    }
};

export const formatDateOptions = async (platform: string): Promise<string[]> => {
    const datesResponse = await API.getDateOptions(platform);
    const dateRows = datesResponse.Tables[0].Rows;

    const dates = dateRows.map((row: string[]) => {
        return row[0];
    });

    return dates;
};

export const fetchChartMetrics = async (
    metricName: string,
    display: string,
    metricFilters: {},
    setChartData: React.Dispatch<SetStateAction<ChartDataType>>,
    workload: string,
) => {
    setChartData(null);
    const queryParams = {
        ...SegmentParametersMap[metricFilters["segment"]].queryParameters,
        referrer: metricFilters["referrer"] ?? "All",
        metric: metricName,
    };
    try {
        const metricsChartResponse = await API.tenantMetricsChart(
            queryParams,
            workload,
        );
        const chartJson = metricsChartResponse?.data.Tables[0];
        const formattedChartData: ChartDataType = {
            headers: chartJson.Columns.map((x: any) => x.ColumnName),
            rows: chartJson.Rows,
            title: display,
        };
        setChartData(formattedChartData);
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "GrowthDrilldownChartMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching charts data in Growth drilldown page",
                metricName,
                display,
                metricFilters,
                workload,
            },
            error,
        );
    }
};
