import React from "react";
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    BarChart,
    Legend,
} from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { chartLegendStyles, chartMarginStyles } from "pages/common";

export const SessionDistributionByAppMode = (props) => {
    const { payload } = props;
    const customFormatter = (value) => {
        return `${value} %`;
    };

    return (
        <>
            <h2 className="commonPageBlockTitle"> AppMode Distribution</h2>
            {payload && payload.data && payload.data.length !== 0 ? (
                <BarChart
                    width={450}
                    height={300}
                    data={payload.data}
                    layout="vertical"
                    margin={chartMarginStyles}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 100]} />
                    <YAxis type="category" dataKey="Application" />
                    <Tooltip
                        position={{ x: 460, y: 100 }}
                        labelStyle={{ fontWeight: "bold" }}
                        itemStyle={{ color: "black" }}
                        formatter={customFormatter}
                    />
                    {Object.keys(payload.appModes).map((appmode, i) => {
                        return (
                            <Bar
                                dataKey={appmode}
                                stackId="a"
                                fill={payload.appModes[appmode]}
                                barSize={20}
                                isAnimationActive={false}
                                key={i}
                            />
                        );
                    })}
                    <Legend wrapperStyle={chartLegendStyles} />
                </BarChart>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
