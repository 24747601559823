import {
    tenantAppsHealthAdvisories,
    tenantAppAdvisoriesHistory,
    tenantAppsHealthAddins,
} from "./m365appsHealth";
import { win32AvgCohortMetrics } from "./win32AvgCohortMetrics";
import { win32CoreMetrics } from "./win32CoreMetrics";
import { win32MetaData } from "./win32MetaData";
import { win32TenantEnvironmentData } from "./win32TenantEnvironmentData";
import { win32OfficeTenantScore } from "./win32OfficeTenantScore";
import { win32PerfEnvironmentData } from "./win32PerfEnvironmentData";
import { win32SecurityVulnerabilityHistory } from "./win32SecurityVulnerabilities";
import { win32SparkLineScores } from "./win32SparkLineScores";
import { win32TenantList } from "./win32TenantList";
import { win32TenantMetrics, win32TenantMetricsChart } from "./win32TenantMetrics";

const inProductWin32DrilldownQueries: {} = {
    win32AvgCohortMetrics,
    win32CoreMetrics,
    win32MetaData,
    win32TenantEnvironmentData,
    win32OfficeTenantScore,
    win32PerfEnvironmentData,
    win32SecurityVulnerabilityHistory,
    win32SparkLineScores,
    win32TenantList,
    win32TenantMetrics,
    win32TenantMetricsChart,
    tenantAppsHealthAdvisories,
    tenantAppAdvisoriesHistory,
    tenantAppsHealthAddins,
};

export default inProductWin32DrilldownQueries;
