import React from "react";
import { Pie, PieChart, Tooltip } from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import CustomToolTip from "components/CustomComponents/CustomToolTip";

export const CustomPieChart = (props) => {
    const { payload } = props;
    const textfontSize = 11;

    if (!payload) {
        return <EmptyTableContainer />;
    }

    const customToolTipContent = (toolTipProps): string => {
        const { payload: toolTipPayload } = toolTipProps;
        const percentage = (
            (toolTipPayload[0].value / toolTipPayload[0].payload.total) *
            100
        ).toFixed(2);
        return `${toolTipPayload[0].name}: ${percentage}%`;
    };

    // Break down the whole text into tspans of length 30 each and show them one after another
    const partitions = (name: string, x: number, y: number) => {
        const maxLengthInLine = 30;

        const lineHeight = 1.1;
        let i = 0;
        const result = [];
        while (i < name.length) {
            let end =
                i + maxLengthInLine < name.length
                    ? i + maxLengthInLine
                    : name.length;

            // Dont break the word in between but check for next space
            if (end < name.length && name.charAt(end) !== " ")
                while (end < name.length && name.charAt(end) !== " ") end += 1;

            result.push(end);
            i = end;
        }

        if (i !== name.length) result.push(name.length - 1);

        return result.map((data, index) => {
            const start = index === 0 ? 0 : result[index - 1];
            return (
                <tspan x={x} y={y + index * textfontSize * lineHeight} key={index}>
                    {name.substring(start, data)}
                </tspan>
            );
        });
    };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        name,
    }): any => {
        const IsminSectorPercent = percent * 100 > 5;

        if (IsminSectorPercent) {
            const RADIAN = Math.PI / 180;
            const radius = outerRadius + 10;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            const percentRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const percentX = cx + percentRadius * Math.cos(-midAngle * RADIAN);
            const percentY = cy + percentRadius * Math.sin(-midAngle * RADIAN);
            const percentage = (percent * 100).toFixed(2);

            return (
                <>
                    <text
                        x={percentX}
                        y={percentY}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={textfontSize}
                        fontWeight={500}
                    >
                        {`${percentage}%`}
                    </text>
                    <text
                        x={x}
                        y={y}
                        alignmentBaseline="middle"
                        fontSize={textfontSize}
                        fill="black"
                        textAnchor={x > cx ? "start" : "end"}
                    >
                        {partitions(`${name} : ${percentage}%`, x, y)}
                    </text>
                </>
            );
        }
    };

    return (
        <div>
            <PieChart width={450} height={230}>
                <Pie
                    data={payload}
                    dataKey="value"
                    label={renderCustomizedLabel}
                    labelLine={false}
                    isAnimationActive={false}
                />
                <Tooltip
                    content={
                        <CustomToolTip
                            customToolTipContentFunction={customToolTipContent}
                        />
                    }
                />
            </PieChart>
        </div>
    );
};
