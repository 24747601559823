import { isTest } from "utils/WebConstants";

export const webCompositeScore: string = `
//webCompositeScore
let dt = iif(isempty("\${date}"), GetCurrentDate(${isTest}), "\${date}");
let currentDate = GetCurrentDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
let MAU = \${mauCountThreshold};
//let levelColumnName = \${levelColumnName};
Score
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and Application ==  app and UiHost == "All" and Mau > MAU
| join kind=inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == currentDate
        | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    PastStatus
    | where Application == app and UiHost == 'All' and Date == currentDate 
    | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
) on OmsTenantId, Tpid
| join kind = leftouter
    (
        Score
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application ==  app and UiHost == 'All'
        | project \${levelColumnName}, PrevMonthScore = round(CompositeScore, 2)
    )
    on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, OrgName = Name, Application, 
    Composite_Score = round(CompositeScore, 2),
    Composite_MoMChange = CompositeScore - PrevMonthScore,
    ActiveUsers_Score = ActiveUsers,
    MAU_Score = Mau,
    MAURate_Score = round(MauRate*100,2), MAURate_Score_Color = Color_Mau,
    MEURate_Score = round(MeuRate*100,2), MEURate_Score_Color = Color_Meu,
    EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors,
    Retention_Score = round(ReturningMauRate*100,2), Retention_Score_Color = Color_ReturningMau,
    EUPL_P95_Score = round(EUPL_P95,2), EUPL_P95_Score_Color = Color_Performance,
    EUPL_P50_Score = round(EUPL_P50,2), EUPL_P50_Score_Color = Color_Performance,
    ACEFreeUsers_Score = round(ACEFreeUsersRate*100,2), ACEFreeUsers_Score_Color = Color_ACEFreeUsers,
    ICEFreeUsers_Score = round(ICEFreeUsersRate*100,2), ICEFreeUsers_Score_Color = Color_ICEFreeUsers,
    Feedback_Score = round(Feedback_SubScore ,2), Feedback_Score_Color = Color_Feedback, 
    OpenInClient_Score = round(OpenInClientRate*100, 2), OpenInClient_Score_Color = Color_OpenInClient,
    Status = CompositeScore_Color,
    Parent = Tpid
| sort by Composite_Score desc , MAU_Score desc
`;

export const webCompositeScore_V2: string = `
//webCompositeScore V2
let dt = iif(isempty("\${date}"), GetCurrentDate(${isTest}), "\${date}");
let currentDate = GetCurrentDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
let MAU = \${mauCountThreshold};
//let levelColumnName = \${levelColumnName};
Score_V2
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and Application ==  app and UiHost == "All" and Mau > MAU
| join kind=inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == currentDate
        | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    PastStatus
    | where Application == app and UiHost == 'All' and Date == currentDate 
    | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
) on OmsTenantId, Tpid
| join kind = leftouter
    (
        Score_V2
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application ==  app and UiHost == 'All'
        | project \${levelColumnName}, PrevMonthScore = round(CompositeScore, 2)
    )
    on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, OrgName = Name, Application, 
    Composite_Score = round(CompositeScore, 2),
    Composite_MoMChange = CompositeScore - PrevMonthScore,
    ActiveUsers_Score = ActiveUsers,
    MAU_Score = Mau,
    MAURate_Score = round(MauRate*100,2), MAURate_Score_Color = Color_Mau,
    MEURate_Score = round(MeuRate*100,2), MEURate_Score_Color = Color_Meu,
    EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors,
    Retention_Score = round(ReturningMauRate*100,2), Retention_Score_Color = Color_ReturningMau,
    EUPL_P95_Score = round(EUPL_P95,2), EUPL_P95_Score_Color = Color_Performance,
    EUPL_P50_Score = round(EUPL_P50,2), EUPL_P50_Score_Color = Color_Performance,
    ASHA_Score = round(ASHA, 2), ASHA_Score_Color = Color_ASHA,
    Feedback_Score = round(Feedback_SubScore ,2), Feedback_Score_Color = Color_Feedback, 
    OpenInClient_Score = round(OpenInClientRate*100, 2), OpenInClient_Score_Color = Color_OpenInClient,
    Status = CompositeScore_Color,
    Parent = Tpid
| sort by Composite_Score desc , MAU_Score desc
`;
