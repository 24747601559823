import React, { CSSProperties } from "react";
import { Icon } from "@fluentui/react";
import {
    App,
    PerfRankingData,
    Scenario,
    ValuesToShowChoice,
} from "pages/Performance/types";

/**
 * The header above each chart in the Win32 performance drilldown.
 */
const ScenarioChartHeader = ({
    appName,
    scenario,
    ranking,
    valuesToShow,
}: {
    appName: App;
    scenario: Scenario;
    ranking: PerfRankingData;
    valuesToShow: ValuesToShowChoice;
}) => {
    const thresholdView = valuesToShow === "PercentBelowThreshold";

    const headerStyle: CSSProperties = {
        fontWeight: 600,
        marginBottom: thresholdView ? 0 : null,
    };

    const shouldShowAlert =
        ranking && ranking.significantDifference && ranking.percentile >= 90;

    const getRankingMessage = () => {
        if (!ranking) return "\u00A0"; // &nbsp;
        const cohort = ranking.isLargeEnterprise
            ? "large enterprises"
            : "all tenants";
        const percentile = ranking.percentile.toFixed(1);
        return `Worse than ${percentile}% of ${cohort}`;
    };

    // Logic duplicated from ThresholdForApp in https://dev.azure.com/office/OE/_git/TWCPerf-Insights?path=%2FTelemetry%2FCosmosUtilities%2FPerfScore.cs&_a=contents&version=GBmaster
    const threshold =
        scenario === "Boot-File" || scenario.startsWith("FileOpen")
            ? 5
            : appName == "Outlook"
            ? 6
            : 3;

    return (
        <>
            <div>
                {shouldShowAlert ? (
                    <Icon
                        iconName="img/alert.svg"
                        title="alert"
                        className="scenarioChartAlert"
                    />
                ) : (
                    ""
                )}

                <h3 style={headerStyle}>{scenario}</h3>
                {thresholdView && (
                    <span className="subscriptStyle">{`Threshold = ${threshold}s`}</span>
                )}
                <div>{getRankingMessage()}</div>
            </div>
        </>
    );
};

export default ScenarioChartHeader;
