import { IDropdownOption } from "@fluentui/react";
import { CamauCadenceType } from "components/ConsumerCamauComponents/types";
import { ClusterDetail } from "pages/commonTypes";
import { Day0FunnelCadenceType } from "pages/ConsumerDay0Funnel/types";

export const cohortOptions: IDropdownOption[] = [
    { key: "S2500", text: "S2500" },
    { key: "S500", text: "S500" },
    { key: "EPA", text: "EPA" },
    { key: "GoogleMove", text: "GoogleMove" },
    { key: "Gov", text: "US Gov" },
    { key: "Copilot", text: "M365 Copilot Program" },
    { key: "Other", text: "Other" },
];

// EO cohort will be displayed only in the Tenant tile it will not be part of any Cohort dropdowns
export const TenantTitleCohortTags: IDropdownOption[] = [
    ...cohortOptions,
    { key: "EO", text: "EO" },
];

export enum Workload {
    MAC = "Mac",
    WEB = "Web",
    WIN32 = "Win32",
    TEAMS = "Teams",
    CROSSPLATFORM = "CrossPlatform",
    WEB_CONSUMER = "WebConsumer",
    WEB_CONSUMER_GROWTH = "WebConsumerGrowth",
    CONSUMER_CAMAU = "ConsumerCamau",
    ERFM = "ERFM",
    COPILOT = "COPILOT",
    COPILOT_COMMERCIAL = "COPILOT_COMMERCIAL",
    QUIP = "QuIP",
}

export enum TeamsSubWorkload {
    MEETING = "Meeting",
    CHAT = "Chat",
}

export interface CommonConfigType {
    appsList: string[];
    pageTitle: string;
    pageHeader: string;
    pageToolNameForTelemetry?: string;
    defaults: DefaultsType;
}

export interface DefaultsType {
    app: string;
    level?: string;
    cohort?: string;
    minMau?: string;
    sortKey?: string;
    sortedDescending?: boolean;
    mauOptions?: IDropdownOption[];
    cadence?: CamauCadenceType | Day0FunnelCadenceType;
}

interface FeatureConfig {
    [key: string]: {};
}

export const dashboardHeader = "Customer Health & Insights Dashboard";

export interface PlatformConfigType extends CommonConfigType {
    platform: string;
    crossAppsList: string[];
    showHost: boolean;
    showMAU: boolean;
    showDrilldownLink: boolean;
    showWorkloadPageLinks: boolean;
    showTpidData: boolean;
    showTenantStatus: boolean;
    showTenantScore: boolean;
    showIconsInMetricsTable?: boolean;
    showAppMetricsFilters: boolean;
    platformNameNPS?: string;
    productInsightsPagePath?: string;
    groups?: any;
    subGroupStyling?: {};
    featureConfig?: FeatureConfig;
    defaults: DefaultsType;
    clusterDetails: ClusterDetail;
}

export interface PlatformsConfigType {
    [key: string]: PlatformConfigType;
}

export const platformsConfig: PlatformsConfigType = {
    [Workload.WIN32]: {
        platform: Workload.WIN32,
        crossAppsList: ["WXP", "WXPO", "WXPON"],
        appsList: ["Word", "Excel", "PowerPoint", "Outlook", "OneNote"],
        pageTitle: "Win32 Overview",
        pageHeader: dashboardHeader + " - Win32",
        pageToolNameForTelemetry: "Health & Insights Win32 Overview Dashboard",
        showHost: false,
        showMAU: true,
        showDrilldownLink: true,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "win32tenant",
        showTpidData: true,
        showTenantStatus: true,
        showTenantScore: true,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        platformNameNPS: "Windows Desktop",
        groups: {
            MAU_Score: ["USAGE", "None"],
            EngagedUsers_Score: ["USAGE", "None"],
            Watson_Score: ["QUALITY", "None"],
            UAE_Score: ["QUALITY", "None"],
            OCV_Score: ["QUALITY", "None"],
            PerfP95_Score: ["QUALITY", "None"],
            PerfBoot_Score: ["QUALITY", "None"],
            Currency_Score: ["CURRENCY", "None"],
            MAU_MoMChange: ["USAGE", "None"],
            EngagedUsers_MoMChange: ["USAGE", "None"],
            Watson_MoMChange: ["QUALITY", "None"],
            UAE_MoMChange: ["QUALITY", "None"],
            OCV_MoMChange: ["QUALITY", "None"],
            PerfP95_MoMChange: ["QUALITY", "None"],
            PerfBoot_MoMChange: ["QUALITY", "None"],
            Currency_MoMChange: ["CURRENCY", "None"],
            Currency_DominantChannelFork_Text: ["CURRENCY", "None"],
            Word_Performance_AppColor: ["QUALITY", "Performance"],
            Excel_Performance_AppColor: ["QUALITY", "Performance"],
            PowerPoint_Performance_AppColor: ["QUALITY", "Performance"],
            OneNote_Performance_AppColor: ["QUALITY", "Performance"],
            Outlook_Performance_AppColor: ["QUALITY", "Performance"],
            Word_Reliability_AppColor: ["QUALITY", "Reliability"],
            Excel_Reliability_AppColor: ["QUALITY", "Reliability"],
            PowerPoint_Reliability_AppColor: ["QUALITY", "Reliability"],
            OneNote_Reliability_AppColor: ["QUALITY", "Reliability"],
            Outlook_Reliability_AppColor: ["QUALITY", "Reliability"],
            Word_Feedback_AppColor: ["QUALITY", "Feedback"],
            Excel_Feedback_AppColor: ["QUALITY", "Feedback"],
            PowerPoint_Feedback_AppColor: ["QUALITY", "Feedback"],
            OneNote_Feedback_AppColor: ["QUALITY", "Feedback"],
            Outlook_Feedback_AppColor: ["QUALITY", "Feedback"],
            "dummy*": ["QUALITY", "None"],
        },
        defaults: {
            app: "WXPO",
            level: "TenantId",
            cohort: "S500",
            minMau: "2500",
            sortKey: "Composite_Score",
            sortedDescending: false,
            mauOptions: [
                { key: "500", text: "500" },
                { key: "1000", text: "1000" },
                { key: "2000", text: "2000" },
                { key: "2500", text: "2500 (Recommended)" },
            ],
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.WIN32,
            },
            PerformanceDrillDown: {
                kustoUrl: "https://oariaperf.kusto.windows.net/v1/rest/query",
                db: "Office Performance",
                tablePrefix: Workload.WIN32,
            },
        },
        featureConfig: {
            ExecOverview: {
                TenantsWithNoDataLabelEnabled: true,
                TenantsWithNoDataLabelIndex: 4,
                IsNoDataLabelNewRow: false,
            },
            InProductDrillDown: {
                isAppModeDistributionDisabled: true,
                isSessionOriginDistributionDisabled: true,
                isBrowserDistributionDisabled: true,
                isAdminCenterEnabled: true,
                isRetentionChartDisabled: true,
                isSessionDistributionContinentDisabled: true,
                isCopilotEnabled: true,
            },
        },
    },
    [Workload.WEB]: {
        platform: Workload.WEB,
        crossAppsList: ["WXP"],
        appsList: ["Word", "Excel", "PowerPoint", "OneNote", "Visio"],
        pageTitle: "Web Overview",
        pageHeader: dashboardHeader + " - Web",
        pageToolNameForTelemetry: "Health & Insights Web Overview Dashboard",
        showHost: true,
        showMAU: true,
        showDrilldownLink: true,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "tenant",
        showTpidData: true,
        showTenantStatus: true,
        showTenantScore: true,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: true,
        platformNameNPS: "Web",
        groups: {
            MAU_Score: ["USAGE", "None"],
            MAURate_Score: ["USAGE", "None"],
            MEURate_Score: ["USAGE", "None"],
            EngagedEditors_Score: ["USAGE", "None"],
            Retention_Score: ["USAGE", "None"],
            MAU_MoMChange: ["USAGE", "None"],
            MAURate_MoMChange: ["USAGE", "None"],
            MEURate_MoMChange: ["USAGE", "None"],
            EngagedEditors_MoMChange: ["USAGE", "None"],
            Retention_MoMChange: ["USAGE", "None"],
            EUPL_P95_Score: ["QUALITY", "None"],
            EUPL_P95_MoMChange: ["QUALITY", "None"],
            EUPL_P50_Score: ["QUALITY", "None"],
            EUPL_P50_MoMChange: ["QUALITY", "None"],
            ACEFreeUsers_Score: ["QUALITY", "None"],
            ACEFreeUsers_MoMChange: ["QUALITY", "None"],
            ICEFreeUsers_Score: ["QUALITY", "None"],
            ICEFreeUsers_MoMChange: ["QUALITY", "None"],
            ASHA_Score: ["QUALITY", "None"],
            ASHA_MoMChange: ["QUALITY", "None"],
            Feedback_Score: ["QUALITY", "None"],
            Feedback_MoMChange: ["QUALITY", "None"],
            OpenInClient_Score: ["QUALITY", "None"],
            OpenInClient_MoMChange: ["QUALITY", "None"],
            Word_Performance_AppColor: ["QUALITY", "Performance"],
            Excel_Performance_AppColor: ["QUALITY", "Performance"],
            PowerPoint_Performance_AppColor: ["QUALITY", "Performance"],
            Word_ACEFreeUsers_AppColor: ["QUALITY", "ACEFreeUsers"],
            Excel_ACEFreeUsers_AppColor: ["QUALITY", "ACEFreeUsers"],
            PowerPoint_ACEFreeUsers_AppColor: ["QUALITY", "ACEFreeUsers"],
            Word_ICEFreeUsers_AppColor: ["QUALITY", "ICEFreeUsers"],
            Excel_ICEFreeUsers_AppColor: ["QUALITY", "ICEFreeUsers"],
            PowerPoint_ICEFreeUsers_AppColor: ["QUALITY", "ICEFreeUsers"],
            Word_ASHA_AppColor: ["QUALITY", "ASHA"],
            Excel_ASHA_AppColor: ["QUALITY", "ASHA"],
            PowerPoint_ASHA_AppColor: ["QUALITY", "ASHA"],
            Word_Feedback_AppColor: ["QUALITY", "Feedback"],
            Excel_Feedback_AppColor: ["QUALITY", "Feedback"],
            PowerPoint_Feedback_AppColor: ["QUALITY", "Feedback"],
            Word_OpenInClient_AppColor: ["QUALITY", "OpenInClient"],
            Excel_OpenInClient_AppColor: ["QUALITY", "OpenInClient"],
            PowerPoint_OpenInClient_AppColor: ["QUALITY", "OpenInClient"],
            "dummy*": ["QUALITY", "None"],
        },
        defaults: {
            app: "WXP",
            level: "TenantId",
            cohort: "S500",
            minMau: "2500",
            sortKey: "Composite_Score",
            sortedDescending: true,
            mauOptions: [
                { key: "500", text: "500" },
                { key: "1000", text: "1000" },
                { key: "2000", text: "2000" },
                { key: "2500", text: "2500 (Recommended)" },
            ],
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.WEB,
            },
        },
        featureConfig: {
            ExecOverview: {
                TenantsWithNoDataLabelEnabled: true,
                TenantsWithNoDataLabelIndex: 2,
                IsNoDataLabelNewRow: true,
            },
            InProductDrillDown: {
                isCurrencyDistributionDisabled: true,
                isAdminCenterEnabled: false,
                isCopilotEnabled: true,
            },
        },
    },
    [Workload.MAC]: {
        platform: Workload.MAC,
        crossAppsList: ["WXPON"],
        appsList: ["Word", "Excel", "PowerPoint", "Outlook", "OneNote"],
        pageTitle: "Mac Overview",
        pageHeader: dashboardHeader + " - Mac",
        pageToolNameForTelemetry: "Health & Insights Mac Overview Dashboard",
        showHost: false,
        showMAU: true,
        showDrilldownLink: true,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "mactenant",
        showTpidData: true,
        showTenantStatus: true,
        showTenantScore: true,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        platformNameNPS: "Mac Desktop",
        groups: {
            MAU_Score: ["USAGE", "None"],
            MAURate_Score: ["USAGE", "None"],
            MAU_MoMChange: ["USAGE", "None"],
            MAURate_MoMChange: ["USAGE", "None"],
            MEU_Score: ["USAGE", "None"],
            MEU_MoMChange: ["USAGE", "None"],
            MEURate_MoMChange: ["USAGE", "None"],
            EngagedUsers_Score: ["USAGE", "None"],
            EngagedUsers_MoMChange: ["USAGE", "None"],
            CH_Score: ["QUALITY", "None"],
            CH_Score_MoMChange: ["QUALITY", "None"],
            FQ_Score: ["QUALITY", "None"],
            FQ_Score_MoMChange: ["QUALITY", "None"],
            NPS_Score: ["QUALITY", "None"],
            NPS_Score_MoMChange: ["QUALITY", "None"],
            PerfP95_Score: ["QUALITY", "None"],
            PerfP95_MoMChange: ["QUALITY", "None"],
            PerfBoot_Score: ["QUALITY", "None"],
            PerfBoot_MoMChange: ["QUALITY", "None"],
            Word_Performance_AppColor: ["QUALITY", "Performance"],
            Excel_Performance_AppColor: ["QUALITY", "Performance"],
            PowerPoint_Performance_AppColor: ["QUALITY", "Performance"],
            OneNote_Performance_AppColor: ["QUALITY", "Performance"],
            Outlook_Performance_AppColor: ["QUALITY", "Performance"],
            Word_Reliability_AppColor: ["QUALITY", "Reliability"],
            Excel_Reliability_AppColor: ["QUALITY", "Reliability"],
            PowerPoint_Reliability_AppColor: ["QUALITY", "Reliability"],
            OneNote_Reliability_AppColor: ["QUALITY", "Reliability"],
            Outlook_Reliability_AppColor: ["QUALITY", "Reliability"],
            Word_Feedback_AppColor: ["QUALITY", "Feedback"],
            Excel_Feedback_AppColor: ["QUALITY", "Feedback"],
            PowerPoint_Feedback_AppColor: ["QUALITY", "Feedback"],
            OneNote_Feedback_AppColor: ["QUALITY", "Feedback"],
            Outlook_Feedback_AppColor: ["QUALITY", "Feedback"],
            Word_AppCurrency_AppColor: ["CURRENCY", "Build Currency"],
            Excel_AppCurrency_AppColor: ["CURRENCY", "Build Currency"],
            PowerPoint_AppCurrency_AppColor: ["CURRENCY", "Build Currency"],
            OneNote_AppCurrency_AppColor: ["CURRENCY", "Build Currency"],
            Outlook_AppCurrency_AppColor: ["CURRENCY", "Build Currency"],
            OS_Currency_Score: ["CURRENCY", "None"],
            OS_Currency_MoMChange: ["CURRENCY", "None"],
            App_Currency_Score: ["CURRENCY", "None"],
            App_Currency_MoMChange: ["CURRENCY", "None"],
            dummy5: ["CURRENCY", "None"],
            "dummy*": ["QUALITY", "None"],
        },
        defaults: {
            app: "WXPON",
            level: "TenantId",
            cohort: "S500",
            minMau: "1000",
            sortKey: "Composite_Score",
            sortedDescending: false,
            mauOptions: [
                { key: "500", text: "500" },
                { key: "1000", text: "1000 (Recommended)" },
                { key: "2000", text: "2000" },
                { key: "2500", text: "2500" },
            ],
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.MAC,
            },
        },
        featureConfig: {
            InProductDrillDown: {
                isAppModeDistributionDisabled: true,
                isSessionOriginDistributionDisabled: true,
                isBrowserDistributionDisabled: true,
                isRetentionChartDisabled: true,
                isSessionDistributionContinentDisabled: true,
                isAdminCenterEnabled: false,
                isCopilotEnabled: true,
            },
        },
    },
    [Workload.CROSSPLATFORM]: {
        platform: Workload.CROSSPLATFORM,
        crossAppsList: ["Office"],
        appsList: [],
        pageTitle: "Cross Platform Overview",
        pageHeader: dashboardHeader + " - Office Cross Platform",
        pageToolNameForTelemetry:
            "Health & Insights Cross Platform Overview Dashboard",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: true,
        showTpidData: true,
        showTenantStatus: true,
        showTenantScore: true,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        groups: {
            Win32_Score_Platform: ["USAGE", "None"],
            Web_Score_Platform: ["USAGE", "None"],
            Win32_MAU_Score: ["USAGE", "None"],
            Web_MAU_Score: ["USAGE", "None"],
            Composite_MoMChange: ["USAGE", "None"],
            Web_Score_MoMChange: ["USAGE", "None"],
            Win32_Score_MoMChange: ["USAGE", "None"],
            Win32_MAU_MoMChange: ["USAGE", "None"],
            Web_MAU_MoMChange: ["USAGE", "None"],
            Win32_Usage_AppColor: ["USAGE", "None"],
            Web_Usage_AppColor: ["USAGE", "None"],
            Win32_Performance_AppColor: ["QUALITY", "Performance"],
            Web_Performance_AppColor: ["QUALITY", "Performance"],
            Win32_Reliability_AppColor: ["QUALITY", "Reliability"],
            Web_Reliability_AppColor: ["QUALITY", "Reliability"],
            Win32_Feedback_AppColor: ["QUALITY", "Feedback"],
            Web_Feedback_AppColor: ["QUALITY", "Feedback"],
            Win32_Currency_AppColor: ["CURRENCY", "None"],
            Web_Currency_AppColor: ["CURRENCY", "None"],
            dummy1: ["USAGE", "None"],
            "dummy*": ["QUALITY", "None"],
        },
        defaults: {
            app: "Office",
            level: "TenantId",
            cohort: "S500",
            sortKey: "Composite_Score",
            sortedDescending: true,
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.CROSSPLATFORM,
            },
        },
        featureConfig: {
            ExecOverview: { isScoreBannerDisabled: true },
        },
    },
    [Workload.TEAMS]: {
        platform: Workload.TEAMS,
        crossAppsList: [],
        appsList: [TeamsSubWorkload.MEETING, TeamsSubWorkload.CHAT],
        pageTitle: "Teams Overview",
        pageHeader: dashboardHeader + " - Teams",
        pageToolNameForTelemetry: "Health & Insights Teams Overview Dashboard",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "teamstenant",
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showIconsInMetricsTable: false,
        showAppMetricsFilters: false,
        groups: {
            UsageRate_Score: ["USAGE", "None"],
            UsageRate_Color: ["USAGE", "None"],
            UsageRate_MoMChange: ["USAGE", "None"],
            MAU_Score: ["USAGE", "None"],
            TeamsMAU_Score: ["USAGE", "None"],
            AudioPSRPercentage_Score: ["QUALITY", "Reliability"],
            AudioPSRPercentage_MoMChange: ["QUALITY", "Reliability"],
            ChatSendMessage_Score: ["QUALITY", "Reliability"],
            ChatSendMessage_MoMChange: ["QUALITY", "Reliability"],
            ChatSwitchV2_Score: ["QUALITY", "Reliability"],
            ChatSwitchV2_MoMChange: ["QUALITY", "Reliability"],
            DropRatePercentage_Score: ["QUALITY", "Reliability"],
            DropRatePercentage_MoMChange: ["QUALITY", "Reliability"],
            PoorMemoryRate_Score: ["QUALITY", "Performance"],
            PoorMemoryRate_MoMChange: ["QUALITY", "Performance"],
            StreamEstablishedSuccessPercentage_Score: ["QUALITY", "Reliability"],
            StreamEstablishedSuccessPercentage_MoMChange: ["QUALITY", "Reliability"],
            P50ChatSwitchV2_Score: ["QUALITY", "Performance"],
            P50ChatSwitchV2_MoMChange: ["QUALITY", "Performance"],
            P95ChatSwitchV2_Score: ["QUALITY", "Performance"],
            P95ChatSwitchV2_MoMChange: ["QUALITY", "Performance"],
            PoorCPURate_Score: ["QUALITY", "Performance"],
            PoorCPURate_MoMChange: ["QUALITY", "Performance"],
            PoorFrequencyRate_Score: ["QUALITY", "Performance"],
            PoorFrequencyRate_MoMChange: ["QUALITY", "Performance"],
            NewChat_Score: ["QUALITY", "Reliability"],
            NewChat_MoMChange: ["QUALITY", "Reliability"],
            FeedbackPoorPercentage_Score: ["SENTIMENT", "Feedback"],
            FeedbackPoorPercentage_MoMChange: ["SENTIMENT", "Feedback"],
            NPS_Score: ["SENTIMENT", "Feedback"],
            NPS_MoMChange: ["SENTIMENT", "Feedback"],
            "dummy*": ["QUALITY", "None"],
        },
        subGroupStyling: { borderBottom: "1px solid black" },
        defaults: {
            app: TeamsSubWorkload.MEETING,
            level: "TenantId",
            cohort: "S500",
            minMau: "1000",
            sortKey: "Composite_Score",
            sortedDescending: true,
            mauOptions: [
                { key: "500", text: "500" },
                { key: "1000", text: "1000 (Recommended)" },
                { key: "2000", text: "2000" },
            ],
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.TEAMS,
            },
        },
        featureConfig: {
            InProductDrillDown: {
                isTeamsMetricsEnabled: true,
                isAdminCenterEnabled: false,
                isCopilotEnabled: false,
            },
        },
    },
    [Workload.WEB_CONSUMER]: {
        platform: Workload.WEB_CONSUMER,
        crossAppsList: ["WXP"],
        appsList: ["Word", "Excel", "PowerPoint", "OneNote"],
        pageTitle: "Consumer Health Overview",
        pageHeader: "Consumer Insights Dashboard - Web",
        pageToolNameForTelemetry: "Consumer Insights Web Dashboard",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "cohortDeepDive",
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        groups: {
            MAU_Score: ["USAGE", "None"],
            MAURate_Score: ["USAGE", "None"],
            CoreActionMAURate_Score: ["USAGE", "None"],
            RetentionRate_Score: ["USAGE", "None"],
            MAU_MoMChange: ["USAGE", "None"],
            MAURate_MoMChange: ["USAGE", "None"],
            CoreActionMAURate_MoMChange: ["USAGE", "None"],
            RetentionRate_MoMChange: ["USAGE", "None"],
            EUPL_P95_Score: ["QUALITY", "None"],
            EUPL_P95_MoMChange: ["QUALITY", "None"],
            EUPL_P50_Score: ["QUALITY", "None"],
            EUPL_P50_MoMChange: ["QUALITY", "None"],
            ASHA_Score: ["QUALITY", "None"],
            ASHA_MoMChange: ["QUALITY", "None"],
            Feedback_Score: ["QUALITY", "None"],
            Feedback_MoMChange: ["QUALITY", "None"],
            Word_Performance_AppColor: ["QUALITY", "Performance"],
            Excel_Performance_AppColor: ["QUALITY", "Performance"],
            PowerPoint_Performance_AppColor: ["QUALITY", "Performance"],
            Word_ASHA_AppColor: ["QUALITY", "ASHA"],
            Excel_ASHA_AppColor: ["QUALITY", "ASHA"],
            PowerPoint_ASHA_AppColor: ["QUALITY", "ASHA"],
            Word_Feedback_AppColor: ["QUALITY", "Feedback"],
            Excel_Feedback_AppColor: ["QUALITY", "Feedback"],
            PowerPoint_Feedback_AppColor: ["QUALITY", "Feedback"],
            "dummy*": ["QUALITY", "None"],
        },
        defaults: {
            app: "WXP",
            cohort: "MSA Research",
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.WEB_CONSUMER,
            },
        },
    },
    [Workload.WEB_CONSUMER_GROWTH]: {
        platform: Workload.WEB_CONSUMER_GROWTH,
        appsList: ["Word", "Excel", "PowerPoint"],
        crossAppsList: [],
        pageTitle: "Consumer Growth Drilldown",
        pageHeader: "Consumer Growth Drilldown",
        pageToolNameForTelemetry: "Consumer Growth Drilldown",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "growthDrilldown",
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        defaults: {
            app: "Word",
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.WEB_CONSUMER_GROWTH,
            },
        },
    },
    [Workload.CONSUMER_CAMAU]: {
        platform: Workload.CONSUMER_CAMAU,
        appsList: ["Word", "Excel", "PowerPoint"],
        crossAppsList: [],
        pageTitle: "Consumer Camau Overview",
        pageHeader: "Consumer Camau Overview",
        pageToolNameForTelemetry: "Consumer Camau Overview",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "growthDrilldown",
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        defaults: {
            app: "Word",
            cadence: "daily",
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "TenantInsights",
                tablePrefix: Workload.CONSUMER_CAMAU,
            },
        },
    },
    [Workload.COPILOT]: {
        platform: Workload.COPILOT,
        appsList: [],
        crossAppsList: [],
        pageTitle: "Copilot User Journey",
        pageHeader: "Copilot User Journey",
        pageToolNameForTelemetry: "Copilot User Journey",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: null,
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showAppMetricsFilters: false,
        defaults: {
            app: "Word",
            cadence: "weekly",
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "Copilot",
                tablePrefix: "",
            },
        },
    },
    [Workload.COPILOT_COMMERCIAL]: {
        platform: Workload.COPILOT_COMMERCIAL,
        crossAppsList: [],
        appsList: [
            "WXP",
            "Word",
            "Excel",
            "PowerPoint",
            "M365 Chat",
            "Teams",
            "Outlook",
            "Whiteboard",
            "Loop",
            "Stream",
        ],
        pageTitle: dashboardHeader + " - Copilot",
        pageHeader: dashboardHeader + " - Copilot",
        pageToolNameForTelemetry: "Health & Insights Copilot Dashboard",
        showHost: false,
        showMAU: true,
        showDrilldownLink: true,
        showWorkloadPageLinks: false,
        productInsightsPagePath: "copilottenant",
        showTpidData: false,
        showTenantStatus: true,
        showTenantScore: false,
        showIconsInMetricsTable: true,
        showAppMetricsFilters: false,
        groups: {
            // Readiness
            Readiness_Score: ["Readiness", "None"],
            Readiness_Score_MoMChange: ["Readiness", "None"],
            OneDrivePct_Score: ["Readiness", "None"],
            OneDrivePct_Score_MoMChange: ["Readiness", "None"],
            MIPEnabledPct_Score: ["Readiness", "None"],
            MIPEnabledPct_Score_MoMChange: ["Readiness", "None"],
            CopilotEnabledPct_Score: ["License", "None"],
            CopilotEnabledPct_Score_MoMChange: ["License", "None"],
            EligibleLicenses_Score: ["License", "None"],
            CopilotEnabled_Score: ["License", "None"],
            CopilotLicenses_Score: ["License", "None"],
            // Health
            Health_Score: ["Health", "None"],
            Health_Score_MoMChange: ["Health", "None"],
            MAU_Score: ["Health", "None"],
            MAU_Score_MoMChange: ["Health", "None"],
            ASHA_Score: ["Quality", "None"],
            ASHA_Score_MoMChange: ["Quality", "None"],
            Performance_Score: ["Quality", "None"],
            Performance_Score_MoMChange: ["Quality", "None"],
            FCFRP50_Score: ["Quality", "None"],
            FCFRP50_Score_MoMChange: ["Quality", "None"],
            FCFRP95_Score: ["Quality", "None"],
            FCFRP95_Score_MoMChange: ["Quality", "None"],
            Currency_Score: ["Quality", "None"],
            Currency_Score_MoMChange: ["Quality", "None"],
            SeenRate_Score: ["Engagement", "None"],
            SeenRate_Score_MoMChange: ["Engagement", "None"],
            TriedRate_Score: ["Engagement", "None"],
            TriedRate_Score_MoMChange: ["Engagement", "None"],
            KeepRate_Score: ["Engagement", "None"],
            KeepRate_Score_MoMChange: ["Engagement", "None"],
            M1Retention_Score: ["Engagement", "None"],
            M1Retention_Score_MoMChange: ["Engagement", "None"],
            Intensity_Score: ["Engagement", "None"],
            Intensity_Score_MoMChange: ["Engagement", "None"],
            SATPct_Score: ["Feedback", "None"],
            SATPct_Score_MoMChange: ["Feedback", "None"],
            MTE_Score: ["Feedback", "None"],
            MTE_Score_MoMChange: ["Feedback", "None"],
            NPS_Score: ["Feedback", "None"],
            NPS_Score_MoMChange: ["Feedback", "None"],
            Word_ASHA_AppColor: ["Quality", "ASHA"],
            Excel_ASHA_AppColor: ["Quality", "ASHA"],
            PowerPoint_ASHA_AppColor: ["Quality", "ASHA"],
            dummy2: ["Quality", "None"],
            Word_Performance_AppColor: ["Quality", "ASHA Performance"],
            Excel_Performance_AppColor: ["Quality", "ASHA Performance"],
            PowerPoint_Performance_AppColor: ["Quality", "ASHA Performance"],
            dummy3: ["Quality", "None"],
            Word_FCFR_AppColor: ["Quality", "FCFR"],
            Excel_FCFR_AppColor: ["Quality", "FCFR"],
            PowerPoint_FCFR_AppColor: ["Quality", "FCFR"],
            dummy4: ["Quality", "None"],
            Word_Currency_AppColor: ["Quality", "Currency"],
            Excel_Currency_AppColor: ["Quality", "Currency"],
            PowerPoint_Currency_AppColor: ["Quality", "Currency"],
            Word_SeenRate_AppColor: ["Engagement", "SeenRate"],
            Excel_SeenRate_AppColor: ["Engagement", "SeenRate"],
            PowerPoint_SeenRate_AppColor: ["Engagement", "SeenRate"],
            dummy6: ["Engagement", "None"],
            Word_TriedRate_AppColor: ["Engagement", "TriedRate"],
            Excel_TriedRate_AppColor: ["Engagement", "TriedRate"],
            PowerPoint_TriedRate_AppColor: ["Engagement", "TriedRate"],
            dummy7: ["Engagement", "None"],
            Word_KeepRate_AppColor: ["Engagement", "KeepRate"],
            Excel_KeepRate_AppColor: ["Engagement", "KeepRate"],
            PowerPoint_KeepRate_AppColor: ["Engagement", "KeepRate"],
            dummy8: ["Engagement", "None"],
            Word_M1Retention_AppColor: ["Engagement", "M1Retention"],
            Excel_M1Retention_AppColor: ["Engagement", "M1Retention"],
            PowerPoint_M1Retention_AppColor: ["Engagement", "M1Retention"],
            dummy9: ["Engagement", "None"],
            Word_Intensity_AppColor: ["Engagement", "Intensity"],
            Excel_Intensity_AppColor: ["Engagement", "Intensity"],
            PowerPoint_Intensity_AppColor: ["Engagement", "Intensity"],
            Word_SATPct_AppColor: ["Feedback", "SAT"],
            Excel_SATPct_AppColor: ["Feedback", "SAT"],
            PowerPoint_SATPct_AppColor: ["Feedback", "SAT"],
            dummy11: ["Feedback", "None"],
            Word_MTE_AppColor: ["Feedback", "MTE"],
            Excel_MTE_AppColor: ["Feedback", "MTE"],
            PowerPoint_MTE_AppColor: ["Feedback", "MTE"],
            dummy12: ["Feedback", "None"],
            Word_NPS_AppColor: ["Feedback", "NPS"],
            Excel_NPS_AppColor: ["Feedback", "NPS"],
            PowerPoint_NPS_AppColor: ["Feedback", "NPS"],
            "dummy*": ["None", "None"],
        },
        defaults: {
            app: "WXP",
            level: "TenantId",
            cohort: "ECAP",
            minMau: "500",
            sortKey: "Readiness_Score",
            sortedDescending: true,
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "Copilot",
                tablePrefix: Workload.COPILOT_COMMERCIAL,
            },
        },
        featureConfig: {
            ProductDrilldown: {
                platformList: ["Win32", "Web", "Mac"],
                appMetricsAppList: ["Word", "Excel", "PowerPoint", "All"],
            },
        },
    },
    [Workload.QUIP]: {
        platform: Workload.QUIP,
        appsList: [],
        crossAppsList: [],
        pageTitle: "Quality Insights Platform",
        pageHeader: "Quality Insights Platform",
        pageToolNameForTelemetry: "Quality Insights Platform Dashboard",
        showHost: false,
        showMAU: false,
        showDrilldownLink: false,
        showWorkloadPageLinks: false,
        productInsightsPagePath: null,
        showTpidData: false,
        showTenantStatus: false,
        showTenantScore: false,
        showAppMetricsFilters: false,
        defaults: {
            app: "Word",
            cadence: "weekly",
        },
        clusterDetails: {
            Default: {
                kustoUrl:
                    "https://ehfuelinsights.westus2.kusto.windows.net/v1/rest/query",
                db: "QuIP",
                tablePrefix: "",
            },
        },
    },
};

export const getConfig = (platform: string): PlatformConfigType => {
    return platformsConfig[platform];
};

interface CamauScorecardConfig {
    [key: string]: any;
}

export const config: CamauScorecardConfig = {
    appsList: ["Word", "Excel", "PowerPoint"],
    pageTitle: "Consumer Camau Overview",
    pageHeader: "Consumer Camau Overview",
    pageToolNameForTelemetry: "Consumer Camau Overview",
    defaults: {
        app: "Word",
        cadence: "daily",
    },
};

export const platformColumns = {
    [Workload.WEB]: [
        ...platformsConfig[Workload.WEB].appsList,
        ...platformsConfig[Workload.WEB].crossAppsList,
    ],
    [Workload.WIN32]: [...platformsConfig[Workload.WIN32].appsList],
    [Workload.MAC]: [...platformsConfig[Workload.MAC].appsList],
    [Workload.TEAMS]: [...platformsConfig[Workload.TEAMS].appsList],
    [Workload.WEB_CONSUMER]: [...platformsConfig[Workload.WEB_CONSUMER].appsList],
    [Workload.WEB_CONSUMER_GROWTH]: [
        ...platformsConfig[Workload.WEB_CONSUMER_GROWTH].appsList,
    ],
    [Workload.CONSUMER_CAMAU]: [
        ...platformsConfig[Workload.CONSUMER_CAMAU].appsList,
        ...platformsConfig[Workload.CONSUMER_CAMAU].defaults.cadence,
    ],
    [Workload.COPILOT_COMMERCIAL]: [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...platformsConfig[Workload.COPILOT_COMMERCIAL].featureConfig
            ?.ProductDrilldown?.["appMetricsAppList"],
    ],
};
