export const teamsMetaData: string = `
// Teams metadata
let currentDate = GetCurrentTeamsDate();
let app = "\${app}";
let dt = "\${date}";
StrategicTenants
| where \${levelColumnName} == "\${id}" and Date == toscalar(StrategicTenants|summarize max(Date))
| extend OrgName = iif("\${levelColumnName}" == "Tpid",OrgName_Translated , TenantName_Translated), Parent = Tpid
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO
| take 1
| extend OrgName = iff(OrgName == "MicrosoftStanleyGTest", "Microsoft", OrgName)
| join kind = leftouter(
    Teams_Signals
    | where Date == dt and Signal == "Score" and SubWorkload == app
    | project OmsTenantId, Score = round(Signal_Value,2), Status = Signal_Color
    ) on \${levelColumnName}
| join kind = leftouter(
    Teams_Signals
    | where Date == format_datetime(datetime_add('Month', -1, todatetime("\${date}")), "yyyy-MM-dd")  and Signal == "Score" and SubWorkload == app
    | project OmsTenantId, PrevMonthScore = round(Signal_Value,2)
    ) on \${levelColumnName}
| join kind = leftouter ( 
    Teams_PastStatus
    | where Date == dt and SubWorkload == app
    | project OmsTenantId, PastStatusSet
    ) on \${levelColumnName}
| project Parent, OmsTenantId, OrgName, 
Score, Current_Status = Status, PastStatus = GetTeamsPastYearStatus(PastStatusSet, dt), ScoreMoMChange = round(Score - PrevMonthScore, 2),
IsS500, IsEPA,IsS2500, IsGoogleMove,IsGov, IsEO`;
