import React, { useState } from "react";
import {
    IDropdownOption,
    DropdownMenuItemType,
    SearchBox,
    Dropdown,
    IDropdownProps,
} from "@fluentui/react";

const SearchableDropdown = (props: IDropdownProps) => {
    const [searchText, setSearchText] = useState<string>("");

    function renderOption(option: IDropdownOption): JSX.Element {
        return option.itemType === DropdownMenuItemType.Header &&
            option.key === "FilterHeader" ? (
            <SearchBox
                onChange={(ev, newValue) => setSearchText(newValue)}
                underlined={true}
                placeholder="Search..."
            />
        ) : (
            <>{option.text}</>
        );
    }

    return (
        <Dropdown
            {...props}
            options={[
                {
                    key: "FilterHeader",
                    text: "-",
                    itemType: DropdownMenuItemType.Header,
                },
                {
                    key: "divider_filterHeader",
                    text: "-",
                    itemType: DropdownMenuItemType.Divider,
                },
                ...props.options.map((option) =>
                    !option.disabled &&
                    option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
                        ? option
                        : { ...option, hidden: true },
                ),
            ]}
            onRenderOption={renderOption}
            onDismiss={() => setSearchText("")}
        />
    );
};

export default SearchableDropdown;
