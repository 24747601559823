export const consumerFunnelStages: string = `
    // ConsumerDay0FunnelStages    
    //* De-Dupe Data for Bug 8237392: Duplicate entries in Data until 04-22-2023 *//
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let scenario = "\${scenario}";
    let referrer = "\${referrer}";
    let country = "\${country}";
    let datacenter = "\${datacenter}";
    let browser = "\${browser}";
    let appMode = "\${appMode}";
    let os = "\${os}";
    let referrerPlatform = "\${referrerPlatform}";
    let stagesData = 
    database('ConsumerInsights').\${tableName} 
    | where Application == app and
        Host == iif(todatetime(Date) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI") and
        CohortType == cohortType and
        CohortName == cohortName and
        Stage !in ("Abandoned", "Presents") and
        Scenario == scenario and
        Referrer == referrer
    | where Country == country
        and Datacenter == datacenter
        and Browser == browser
        and AppMode == appMode
        and OS == os
        and ReferrerPlatform == referrerPlatform
    | summarize Value = max(Value), 
        Total = max(Total) by 
            Date, 
            CohortType,
            CohortName, 
            Application, 
            Host, 
            Scenario, 
            Referrer, 
            Stage, 
            ReferrerPlatform, 
            Country, 
            Datacenter, 
            Browser, 
            AppMode, 
            OS
    | where Stage !contains "_SessionsDropped"
    | project Date, Stage, Value
    | order by Date desc;
    let firstStageData = 
    stagesData
    | where Stage == "FirstSessions"
    | project Date, Stage, Value;
    stagesData
    | join kind=leftouter ( firstStageData ) on Date
    | project Date, Stage, Value, Total = Value1
`;

export const consumerFunnelScenarios: string = `
    // ConsumerDay0FunnelScenarios
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let referrer = "\${referrer}";
    let country = "\${country}";
    let datacenter = "\${datacenter}";
    let browser = "\${browser}";
    let appMode = "\${appMode}";
    let os = "\${os}";
    let referrerPlatform = "\${referrerPlatform}";
    database('ConsumerInsights').\${tableName} 
    | where Application == app
        and Host == iif(todatetime(Date) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI")
        and CohortType == cohortType 
        and CohortName == cohortName
        and Stage !in ("Abandoned", "Presents")
        and Referrer == referrer
        and Country == country
        and Datacenter == datacenter
        and Browser == browser
        and AppMode == appMode
        and OS == os
        and ReferrerPlatform == referrerPlatform
    | summarize Value = max(Value), 
        Total = max(Total) by 
            Date, 
            CohortType,
            CohortName, 
            Application, 
            Host, 
            Scenario, 
            Referrer, 
            Stage, 
            ReferrerPlatform, 
            Country, 
            Datacenter, 
            Browser, 
            AppMode, 
            OS 
    | where Stage !contains "_SessionsDropped"
    | project Date, Stage, Scenario, Value
    | order by Date desc, Stage, Scenario asc
`;

export const consumerFunnelStageDates: string = `
    // ConsumerDay0FunnelDates
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let scenario = "\${scenario}";
    database('ConsumerInsights').\${tableName} 
    | where Application == app and CohortType == cohortType and 
    CohortName == cohortName and Stage !in ("Abandoned", "Presents") and Scenario == scenario
    and Host == iif(todatetime(Date) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI")
    | distinct Date
    | order by Date desc
`;

export const consumerFunnelExportData: string = `
    // ConsumerDay0ExportData
    let cohortType = "Segment";
    let app = "\${application}";
    let referrer = "\${referrer}";
    let country = "\${country}";
    let datacenter = "\${datacenter}";
    let browser = "\${browser}";
    let appMode = "\${appMode}";
    let os = "\${os}";
    let referrerPlatform = "\${referrerPlatform}";
    database('ConsumerInsights').\${tableName} 
    | where Application == app
        and Host == iif(todatetime(Date) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI") 
        and CohortType == cohortType 
        and Stage !in ("Abandoned", "Presents")
        and Referrer == referrer
        and Country == country
        and Datacenter == datacenter
        and Browser == browser
        and AppMode == appMode
        and OS == os
        and ReferrerPlatform == referrerPlatform
    | project Date, Application, Host, CohortName, Scenario, Referrer, Country, Datacenter, Browser, AppMode, OS, ReferrerPlatform, Stage, Value
`;

export const consumerFunnelStageReferrers: string = `
    // ConsumerDay0FunnelReferrers
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let country = "\${country}";
    let datacenter = "\${datacenter}";
    let browser = "\${browser}";
    let appMode = "\${appMode}";
    let os = "\${os}";
    let referrerPlatform = "\${referrerPlatform}";
    let dateFilter = "\${date}";
    database('ConsumerInsights').\${tableName} 
    | where Application == app
        and Host == iif(todatetime(dateFilter) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI")
        and CohortType == cohortType
        and CohortName == cohortName
        and Date == dateFilter
        and Stage !in ("Abandoned", "Presents")
        and Country == country
        and Datacenter == datacenter
        and Browser == browser
        and AppMode == appMode
        and OS == os
        and ReferrerPlatform == referrerPlatform
    | distinct Referrer
`;

export const consumerFunnelFilterChoices: string = `
    // ConsumerDay0FunnelFilterChoices
    let dateFilter = "\${date}";
    let app = "\${application}";
    let AppMode=database('ConsumerInsights').\${tableName} 
    | where Date == dateFilter and Application == app | distinct AppMode;
    let Browser=database('ConsumerInsights').\${tableName} 
    | where Date ==dateFilter and Application == app | distinct Browser;
    let Country=database('ConsumerInsights').\${tableName} 
    | where Date ==dateFilter and Application == app | distinct Country;
    let Datacenter=database('ConsumerInsights').\${tableName} 
    | where Date ==dateFilter and Application == app | distinct Datacenter;
    let OS=database('ConsumerInsights').\${tableName} 
    | where Date ==dateFilter and Application == app | distinct OS;
    let Referrer=database('ConsumerInsights').\${tableName} 
    | where Date ==dateFilter and Application == app | distinct ReferrerPlatform;
    AppMode
    | union Browser
    | union Country
    | union Datacenter
    | union OS
    | union Referrer
`;

export const consumerFunnelStagesAcrossScenarioAndReferrer: string = `
    // ConsumerFunnelStagesAcrossScenarioAndReferrer
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let country = "\${country}";
    let datacenter = "\${datacenter}";
    let browser = "\${browser}";
    let appMode = "\${appMode}";
    let os = "\${os}";
    let referrerPlatform = "\${referrerPlatform}";
    database('ConsumerInsights').\${tableName} 
    | where Application == app 
        and Host == iif(todatetime(Date) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI")  
        and CohortName == cohortName 
        and Stage !in ("Abandoned", "Presents") 
        and Country == country
        and Datacenter == datacenter
        and Browser == browser
        and AppMode == appMode
        and OS == os
        and ReferrerPlatform == referrerPlatform
    | summarize Value = max(Value), 
        Total = max(Total) by 
            Date, 
            CohortType,
            CohortName, 
            Application, 
            Host, 
            Scenario, 
            Referrer, 
            Stage, 
            ReferrerPlatform, 
            Country, 
            Datacenter, 
            Browser, 
            AppMode, 
            OS    
    | where Stage !contains "_SessionsDropped"
    | project Date, Stage, Scenario, Referrer, Value
    | order by Date desc
`;

export const consumerFunnelInsights: string = `
    // Consumer Funnel Insights
    let cohortType = "Segment";
    let cohortName = "\${cohort}";
    let app = "\${application}";
    let scenario = "\${scenario}";
    let referrer = "\${referrer}";
    let currMonthdate = "\${date}";
    let AllData =
    database('ConsumerInsights').\${tableName}
        | where Host == iif(todatetime(currMonthdate) >= datetime(2024-03-01), "ODSP Converged", "OneDriveWOPI") and Date == currMonthdate 
        | where CohortType == cohortType
        | where CohortName == cohortName
        | where Application == app
        | where Scenario == scenario
        | where Referrer == referrer
        | project Date, CohortType, CohortName, Application, Host, Scenario, Referrer, Stage, referrerPlatform = ReferrerPlatform,
        country = Country, datacenter = Datacenter, browser = Browser, appMode = AppMode, os = OS, Value, Total;
    \${dynamicPivotsQuery}
    let allInsightsData = 
    union referrerPlatformInsights, osInsights, browserInsights, countryInsights, appModeInsights, datacenterInsights
    | extend isStageDroppedSessions = Stage has_cs "_SessionsDropped";
    allInsightsData
        | where isStageDroppedSessions == false
        | join kind=leftouter (
            allInsightsData
            | where isStageDroppedSessions == true
            | extend tempStage = replace_string(Stage, 'PresentAndEdit', 'Edit')
            | extend StageTemp = substring(tempStage, 0, indexof(tempStage, "_"))
            ) on $left.Stage == $right.StageTemp, Metric, Name
    | where Name != "All"
    | where not (Metric == "ReferrerPlatform" and Name in ("All", "OneDrive All", "Outlook All"))
    | extend dropValue = round(Value1*1.0/Value*100, 2), 
        PercentOfStage = round((Value*1.0/Total)*100, 2), 
        keptDelta = round(((Value - Value1)*1.0 / Value)*100, 2)
    | project StageName = Stage, DroppedStage = tempStage, Metric, Name, StageValue = Value,
         StageTotal= Total, PercentOfStage, DroppedValue = Value1, DroppedTotal = Total1,  DroppedDelta = dropValue, keptDelta
    | order by StageValue desc`;