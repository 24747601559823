import {
    ISurveyFunnelChartData,
    ProcessedUIFunnelDataValue,
    TreeNode,
} from "pages/QuIP/SurveyFunnelDrillDown20/types";

export const prepareDataForSurveyFunnelChart = (
    data: Array<Array<any>>,
    hiddenColumns: string[],
): ISurveyFunnelChartData[] => {
    const surveyFunnelData: ISurveyFunnelChartData[] = [];

    const sortedData = sortByFunnelStageIndex(data, hiddenColumns).flatMap(
        (data) => data[1],
    );
    let baseValue = 0;
    sortedData.forEach((row, i) => {
        let valuePercentage = 0;
        if (i == 0) {
            baseValue = row[1];
            valuePercentage = 100;
        } else {
            valuePercentage = (row[1] / baseValue) * 100;
        }

        surveyFunnelData.push({
            index: i,
            label: row[0],
            value: valuePercentage,
            rawValue: row[1],
        });
    });

    return surveyFunnelData;
};

function sortByFunnelStageIndex(
    data: Array<Array<any>>,
    hiddenColumns: string[],
): Array<Array<any>> {
    const sortedMap = new Map<number, Array<any>>();
    data?.forEach((row: Array<any>) => {
        const surveyStageIndex = getFunnelStageIndex(row[0]);
        if (surveyStageIndex === -1 || hiddenColumns.includes(row[0])) {
            return;
        }

        sortedMap.set(surveyStageIndex, { ...row });
    });
    return [...sortedMap.entries()].sort();
}

function getFunnelStageIndex(stage: string): number {
    switch (stage) {
        case "Survey Tracked":
            return 0;
        case "Trigger Met":
            return 1;
        case "Prompt Shown":
            return 2;
        case "Survey Accepted":
            return 3;
        case "Survey Shown":
            return 4;
        case "Survey Sent":
            return 5;
        case "Survey Complete":
            return 6;
        default:
            return -1;
    }
}

export const processRawUIFunnelData = (
    data: Array<Array<any>>,
): Map<string, ProcessedUIFunnelDataValue> => {
    const processedMap = new Map<string, ProcessedUIFunnelDataValue>();
    const arrayBaseCol = data.find((row: Array<any>) => row[1] === 0);
    const baseValue = arrayBaseCol ? arrayBaseCol[3] : 1;
    data.forEach((row: Array<any>) => {
        processedMap.set(`${row[1]}-${row[2]}`, {
            rawValue: row[3],
            valuePercentage: row[1] !== 0 ? (row[3] / baseValue) * 100 : 100,
        });
    });
    return processedMap;
};

export function uxSchemaToTree(
    uxSchema: any,
    data: Map<string, ProcessedUIFunnelDataValue>,
): TreeNode[] {
    const tree: TreeNode[] = [];

    if (!uxSchema.pages && !Array.isArray(uxSchema.pages)) {
        return tree;
    }

    let branchingRulesByPageIndex: Map<string, any> = new Map();
    if (uxSchema.branchingRules) {
        uxSchema.branchingRules.forEach((rule: any) => {
            if (rule.targets && Array.isArray(rule.targets)) {
                rule.targets.forEach((target: any) => {
                    if (target.type === "Page") {
                        target.hiddenPageIndices.forEach((pageIndex: string) => {
                            if (rule.conditions && Array.isArray(rule.conditions)) {
                                rule.conditions.forEach((condition: any) => {
                                    if (!branchingRulesByPageIndex.has(pageIndex)) {
                                        branchingRulesByPageIndex.set(pageIndex, [
                                            condition,
                                        ]);
                                    } else {
                                        branchingRulesByPageIndex
                                            .get(pageIndex)
                                            .push(condition);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    let isLastBranchingNode = false;
    let isLastConnectingNode = false;

    for (let k = 0; k < uxSchema.pages.length; k++) {
        let hasVariableIcon = false;
        let hasHidePageIcon = false;
        let branchingConditions = [];
        let lastBranchingNodeSource = undefined;
        if (branchingRulesByPageIndex.has(k as any)) {
            branchingConditions = branchingRulesByPageIndex.get(k as any);
            hasVariableIcon = !!branchingConditions.find(
                (val) => val.sourceType === "Variable",
            );
            hasHidePageIcon = !!branchingConditions.find(
                (val) => val.sourceType === "Question",
            );
            if (hasHidePageIcon) {
                isLastBranchingNode = true;
            }
        } else if (isLastBranchingNode) {
            isLastBranchingNode = false;
            isLastConnectingNode = true;
            lastBranchingNodeSource = [tree[k - 1].branchSource];
        } else if (isLastConnectingNode) {
            isLastConnectingNode = false;
            lastBranchingNodeSource = [k - 2, k - 1];
        }

        const childNode: TreeNode = {
            name: uxSchema.pages[k].displayName,
            templateId: uxSchema.pages[k].id,
            questions: uxSchema.pages[k].questions.length,
            isFinalPage: uxSchema.pages[k].isFinalPage,
            variableIcon: hasVariableIcon,
            hidePageIcon: hasHidePageIcon,
            pageIndex: k,
            branchSource: hasHidePageIcon
                ? branchingRulesByPageIndex
                      .get(k as any)
                      .filter((val) => val.sourceType === "Question")
                      .map((val) => val.sourcePageIndex)
                : lastBranchingNodeSource,
            branchConditions: branchingConditions,
            funnelData: {
                rawValue: data.get(`${k}-${uxSchema.pages[k].id}`)?.rawValue || 0,
                funnelPercentage:
                    data.get(`${k}-${uxSchema.pages[k].id}`)?.valuePercentage || 0,
            },
        };

        tree.push(childNode);
    }
    return tree;
}
