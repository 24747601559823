import { isTest } from "utils/WebConstants";

export const filterOptions: string = `
let currDate = GetCurrentDate(${isTest});
\${table}
| where todatetime(Date) <= todatetime(currDate)
| distinct \${filterItem}
\${cleanUp}
`;

export const dateFilterOptions: string = `
\${table}
| distinct Date | order by Date desc 
`;
