import { isTest } from "utils/WebConstants";

export const webHealthChart: string = `
//webHealthChart
let currDate = GetCurrentDate(${isTest});
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let MAU = \${mauCountThreshold};
let level = "\${level}";
let cohort = "\${cohort}";
HealthDistribution
| where iff(cohort == "Other", Cohort == "All", Cohort == cohort)
| where Application == app and UiHost == "All" and Level == level and MinMau == MAU
| union (
    HealthDistribution
    | where iff(cohort == "Other", Cohort == "All", Cohort == cohort)
    | where Application == app and UiHost == "All" and Level == level and MinMau == 0 and Status in ("NoData")
)
| extend Current = (Date == dt)
| project Date, Status, Count, Current
| order by Date asc, Status asc
`;
