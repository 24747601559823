export const macHealthChart: string = `
//MacHealthChart
let dt = "\${date}";
let appName = "\${application}";
let MAU = \${mauCountThreshold};
let cohort = "\${cohort}";
let tenantLevel = iff("\${level}" == "Tpid", "TopParent", "Tenant");
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_MacHealthDistribution
| project Application=SubWorkload, Cohort, Color, Count, Level, MauTags=MAU, ProcessDate, NoDataCount
| where iff(cohort == "Other", Cohort == "All", Cohort == cohort)
| where Application == appName and Level == tenantLevel and MauTags == MAU
| extend Current = (ProcessDate == dt)
| project ProcessDate, Color=GetColor(Color), Count, Current, NoDataCount
| order by ProcessDate asc, Color asc`;
