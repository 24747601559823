export const win32TenantEnvironmentData: string = `
// win32TenantEnvironmentData (To display all the charts [like Currency, P95, etc.])
//win32TenantEnvironment data filtered on id and Level.
let filteredData = TenantInsights_Win32MiscellaneousMetrics
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" and todatetime(Date) <= todatetime("\${date}");
// Filter non-currency and non-Frequency Metrics
let nonCurrencyFreqMetrics = filteredData
| where Metric in ("LicenseDistribution", "Location", "OS", "OfficeDiagnosticConsentLevel", "WindowsTelemetryLevel");
// Filter currency Metrics( as currency Metrics are populated with something other and Dev-In Service)
let currencyMetrics = filteredData
| where Metric == "Currency"
| summarize arg_max(Value, *) by Date
| extend Name = strcat("Dominant Fork: ", Name)
| extend Value = Value * 100;
// userFrequency Metrics are nothing but openDocument metrics
let userFreqMetricsInPerc = filteredData
| where Metric == "OpenDocument"
| extend Value = round(todouble(100 * Value/Total),2);
// join currency metrics with overview table to fetch the Dev IN Service.
let updatedCurrency = currencyMetrics
| join  kind=innerunique (HealthyTenantScore_Win32Overview
| project-rename Tpid = TopParentId
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" 
| distinct Date, OmsTenantId, Currency_InSupport_Score ) on Date, OmsTenantId
| project-away Date1, OmsTenantId1
| extend  Name = "Dev-In service", Value = Currency_InSupport_Score
| project-away Currency_InSupport_Score;
let result = nonCurrencyFreqMetrics
| union currencyMetrics
| union updatedCurrency
| union userFreqMetricsInPerc;
// Filter the data for Location chart, as we have to show location chart only for one month.
let locationResults = result
| where Metric == "Location" and todatetime(Date) == todatetime("\${date}");
// union the location data and sorted non location data.
result
| where Metric != "Location"
| order by Date asc, Metric, Application, Value
| union locationResults
`;
