import React, { useEffect, useRef, useState, useCallback } from "react";
import { Stack } from "@fluentui/react";
import { CommonFunnelFilterPanel } from "components/CommonFunnelComponents/CommonFunnelFilter/CommonFunnelFilter";
import { CommonFunnelStages } from "components/CommonFunnelComponents/CommonFunnelStages/CommonFunnelStages";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { getDates } from "components/CommonFunnelComponents/CommonFunnel/ApiHandler";
import {
    getAdditionalFiltersToShow,
    getDefinedAdditionalPivotsForFunnel,
    getInitialFiltersForFunnel,
} from "components/CommonFunnelComponents/CommonFunnel/commonFunnelHelper";
import {
    getAdditionalFilterDefaults,
    getAdditionalFilterLabels,
    getRequiredFiltersUrlFriendly,
} from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import {
    AdditionalFilterType,
    CommonFilterStateType,
} from "components/CommonFunnelComponents/CommonFunnel/types";
import { getFunnelConfig } from "config/FunnelConfig";
import { ConsumerFunnelProps } from "pages/commonTypes";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import "components/CommonFunnelComponents/CommonFunnel/style.css";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

export const CommonFunnel = ({ funnelType }: ConsumerFunnelProps) => {
    const funnelConfig = getFunnelConfig(funnelType);

    const filtersFromQueryParams = extractQueryParams();

    const additionalFilterLabels: AdditionalFilterType =
        getAdditionalFilterLabels(funnelType);
    const additionalFilterDefaults: AdditionalFilterType =
        getAdditionalFilterDefaults(funnelType);

    const initialFilters: CommonFilterStateType = getInitialFiltersForFunnel(
        funnelType,
        additionalFilterLabels,
        filtersFromQueryParams,
    );

    const [filters, setFilters] = useState<CommonFilterStateType>(() => {
        const newFilters: CommonFilterStateType = {
            ...initialFilters,
            ...getDefinedAdditionalPivotsForFunnel(additionalFilterLabels),
        };
        return newFilters;
    });

    // Needs to be memoized to not cause infinite render loop in below useEffect
    const requiredFiltersUrlFriendly = useCallback(
        () => getRequiredFiltersUrlFriendly(funnelType, filters),
        [funnelType, filters],
    );

    const [additionalFiltersToShow, setAdditionalFiltersToShow] = useState<
        Set<string>
    >(() => {
        return getAdditionalFiltersToShow(
            { ...additionalFilterDefaults, ...filtersFromQueryParams },
            additionalFilterLabels ? Object.keys(additionalFilterLabels) : [],
        );
    });

    const [dateOptions, setDateOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);
    const didMount = useRef<boolean>(false);
    const reloadPresets = useRef<boolean>(false);
    const onFilterChange = useCallback(
        (item: string, value: string | boolean) => {
            logFilterUsage(funnelConfig.pageTitle, item, value as string);
            if (filters.additionalFilters && filters.additionalFilters[item]) {
                const prevAdditionalFilters = { ...filters.additionalFilters };
                prevAdditionalFilters[item] = value as string;

                const updatedFilter = {
                    ...filters,
                    additionalFilters: prevAdditionalFilters,
                };
                setFilters(updatedFilter);
                sendFiltersChangeTelemetryEvent(flattenFilters(updatedFilter));
            } else {
                const updatedFilter = {
                    ...filters,
                    [item]: value,
                };
                setFilters(updatedFilter);
                sendFiltersChangeTelemetryEvent(flattenFilters(updatedFilter));
            }
        },
        [filters, funnelConfig.pageTitle],
    );

    useSendLaunchEvent(
        funnelConfig?.pageToolNameForTelemetry,
        flattenFilters(filters),
    );

    useEffect(() => {
        const getDateOptions = async () => {
            setLoading(true);
            try {
                const queryFilters: CommonFilterStateType = {
                    application: filters.application,
                    date: filters.date,
                    ...requiredFiltersUrlFriendly(),
                };

                const dateOptions = await getDates(queryFilters, funnelType);
                if (!filters.date && !didMount.current) {
                    if (dateOptions && dateOptions.length !== 0) {
                        if (filters.date === null) didMount.current = true;
                        const latestDate = dateOptions[0] as string;
                        onFilterChange("date", latestDate);
                    }
                } else {
                    // this case will be done when date is passed in URL params
                    didMount.current = true;
                    if (dateOptions && dateOptions.length !== 0) {
                        if (!dateOptions.includes(filters.date)) {
                            onFilterChange("date", dateOptions[0]);
                        }
                    }
                }

                setDateOptions(dateOptions);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CommonFunnelDatesFetchFailed",
                    ),
                    {
                        message: `Failed to fetch or format dates for ${funnelType} Funnel page`,
                    },
                    e,
                );
                setError(`${funnelConfig.pageTitle} fetch failed`);
            }
            setLoading(false);
        };

        getDateOptions();
    }, [
        filters.application,
        filters.date,
        funnelType,
        requiredFiltersUrlFriendly,
        onFilterChange,
        funnelConfig.pageTitle,
    ]);

    useEffect(() => {
        AppInsights.getInstance().TrackPage(funnelConfig.pageTitle);

        const newInitialFilters = getInitialFiltersForFunnel(
            funnelType,
            additionalFilterLabels,
            filtersFromQueryParams,
        );
        if (reloadPresets.current) {
            reloadPresets.current = false;
            setFilters({ ...newInitialFilters, date: null });
        }
    }, [
        funnelType,
        additionalFilterDefaults,
        additionalFilterLabels,
        funnelConfig.pageTitle,
        filtersFromQueryParams,
    ]);

    const getFiltersToDisplay = () => {
        if (!filters.additionalFilters) {
            return null;
        }

        const filtersForUrl = {};
        Object.keys(filters.additionalFilters).forEach((filter) => {
            if (additionalFiltersToShow.has(filter)) {
                filtersForUrl[filter] = filters.additionalFilters[filter];
            }
        });

        return filtersForUrl;
    };

    const filtersToAdd = { ...filters };
    delete filtersToAdd.additionalFilters;
    setUpDocumentUrl(
        {
            ...filtersToAdd,
            ...getFiltersToDisplay(),
        },
        funnelConfig.pageTitle,
    );

    const onAddFilter = (item: string) => {
        const updatedFilters = { ...filters };
        const filtersOnDisplay = new Set(additionalFiltersToShow);
        if (filtersOnDisplay.has(item)) {
            // We need to remove the item from filters we display & URL
            filtersOnDisplay.delete(item);
            delete updatedFilters[item];
        } else {
            filtersOnDisplay.add(item);
        }
        setAdditionalFiltersToShow(filtersOnDisplay);

        // Whether we just added or removed the item to / from filtersOnDisplay,
        // In both case, we need to set the filter value to default "All" and update filters
        updatedFilters.additionalFilters[item] = "All";

        setFilters(() => {
            return { ...updatedFilters };
        });
    };

    return loading ? (
        <ShimmeredCardBody />
    ) : error ? (
        <EmptyTableContainer message={error} />
    ) : (
        <Stack>
            <Stack.Item>
                <CommonFunnelFilterPanel
                    dateOptions={dateOptions}
                    filters={filters}
                    onFilterChange={onFilterChange}
                    funnelType={funnelType}
                    onAddFilter={onAddFilter}
                    additionalFiltersToShow={additionalFiltersToShow}
                />
            </Stack.Item>
            <Stack.Item>
                <AppOverviewIconPanel
                    apps={funnelConfig.appsList}
                    onAppClick={onFilterChange}
                    selectedApp={filters.application}
                />
            </Stack.Item>
            <Stack.Item>
                <CommonFunnelStages
                    filters={filters}
                    funnelType={funnelType}
                    onFilterChange={onFilterChange}
                />
            </Stack.Item>
        </Stack>
    );
};
