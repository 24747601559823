export const macCrossAppTenantStatus: string = `
//MacCrossAppTenantStatus
let app = "\${application}";
let level = "\${level}";
let cohort = "\${cohort}";
let dt = "\${date}";
let Mau = \${mauCountThreshold};
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_MacCrossAppView
    | where SubWorkload == app | where ProcessDate == dt or ProcessDate == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | extend App_Currency_Color = Currency_SignalGroup.App_Currency_Color
    | extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,EngagedUsers_Score = Usage_SignalGroup.Engaged_Users, EngagedUsers_Color = Usage_SignalGroup.Engaged_Users_Color
    | project \${levelColumnName}, Score, Color, MoMChange, MAU, Date = ProcessDate, Composite_Score = Score, Performance_Colors, Feedback_Colors
    , Reliability_Colors, OS_Currency, OS_Currency_Color,EngagedUsers_Score,EngagedUsers_Color, App_Currency_Color, PastStatus, Application = SubWorkload
    | join kind=inner
        (StrategicTenants
        | where Date == toscalar(StrategicTenants|summarize max(Date))    
        | where iff(cohort == "Other", IsOther, Cohorts has cohort)
        | distinct \${levelColumnName} ,iif(level == "Tpid", OrgName, TenantName)
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName});
Overview 
    | where Date == dt
    | where MAU > Mau
    | where Color > 1
    | extend CurrentStatus = Color
 | join kind= inner 
    (
        Overview
        | where Date == prev
        | extend PreviousStatus = Color
    )     
on \${levelColumnName}
| where CurrentStatus == 3 or CurrentStatus == 2 or PreviousStatus == 3 or PreviousStatus == 2
| extend ScoreDelta = round(Score - Score1, 2)
    | extend Composite_MoMChange = round(Score-Score1, 2)
    | extend MAU_MoMChange = round((todouble(MAU-MAU1)/MAU1)*100,2) | extend MAU_Score = MAU
    | extend OS_Currency_MoMChange = round((todouble(todouble(OS_Currency) - todouble(OS_Currency1))/todouble(OS_Currency1))*100, 2)
    | extend EngagedUsers_MoMChange = round((round((100*todouble(EngagedUsers_Score))/todouble(MAU),2) - round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2))/round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2)*100, 2)
    | project \${level} = \${levelColumnName}, OrgName = Name,
    Composite_Score, Composite_MoMChange,
    MAU_Score, MAU_MoMChange,EngagedUsers_Score = iff(isnotnull(EngagedUsers_Score), round((100*todouble(EngagedUsers_Score))/todouble(MAU),2), 0.0),EngagedUsers_Score_Color = GetColor(EngagedUsers_Color),EngagedUsers_MoMChange,
    Word_Performance_AppColor = GetColor(Performance_Colors.Word),
    Excel_Performance_AppColor = GetColor(Performance_Colors.Excel),
    PowerPoint_Performance_AppColor = GetColor(Performance_Colors.PowerPoint),
    Outlook_Performance_AppColor = GetColor(Performance_Colors.Outlook),
    OneNote_Performance_AppColor = GetColor(Performance_Colors.OneNote),
    Word_Reliability_AppColor = GetColor(Reliability_Colors.Word),
    Excel_Reliability_AppColor = GetColor(Reliability_Colors.Excel),
    PowerPoint_Reliability_AppColor = GetColor(Reliability_Colors.PowerPoint),
    Outlook_Reliability_AppColor = GetColor(Reliability_Colors.Outlook),
    OneNote_Reliability_AppColor = GetColor(Reliability_Colors.OneNote),
    Word_Feedback_AppColor = GetColor(Feedback_Colors.Word),
    Excel_Feedback_AppColor = GetColor(Feedback_Colors.Excel),
    PowerPoint_Feedback_AppColor = GetColor(Feedback_Colors.PowerPoint),
    Outlook_Feedback_AppColor = GetColor(Feedback_Colors.Outlook),
    OneNote_Feedback_AppColor = GetColor(Feedback_Colors.OneNote),
    Word_AppCurrency_AppColor = GetColor(App_Currency_Color.Word),
    Excel_AppCurrency_AppColor = GetColor(App_Currency_Color.Excel),
    PowerPoint_AppCurrency_AppColor = GetColor(App_Currency_Color.PowerPoint),
    Outlook_AppCurrency_AppColor = GetColor(App_Currency_Color.Outlook),
    OneNote_AppCurrency_AppColor = GetColor(App_Currency_Color.OneNote),
    Word_Performance_AppColor_Prev = GetColor(Performance_Colors1.Word),
    Excel_Performance_AppColor_Prev = GetColor(Performance_Colors1.Excel),
    PowerPoint_Performance_AppColor_Prev = GetColor(Performance_Colors1.PowerPoint),
    Outlook_Performance_AppColor_Prev = GetColor(Performance_Colors1.Outlook),
    OneNote_Performance_AppColor_Prev = GetColor(Performance_Colors1.OneNote),
    Word_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Word),
    Excel_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Excel),
    PowerPoint_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.PowerPoint),
    Outlook_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Outlook),
    OneNote_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.OneNote),
    Word_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Word),
    Excel_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Excel),
    PowerPoint_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.PowerPoint),
    Outlook_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Outlook),
    OneNote_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.OneNote),
    Word_AppCurrency_AppColor_Prev = GetColor(App_Currency_Color1.Word),
    Excel_AppCurrency_AppColor_Prev = GetColor(App_Currency_Color1.Excel),
    PowerPoint_AppCurrency_AppColor_Prev = GetColor(App_Currency_Color1.PowerPoint),
    Outlook_AppCurrency_AppColor_Prev = GetColor(App_Currency_Color1.Outlook),
    OneNote_AppCurrency_AppColor_Prev = GetColor(App_Currency_Color1.OneNote),
    OS_Currency_Score = OS_Currency , OS_Currency_Score_Color = GetColor(OS_Currency_Color), OS_Currency_MoMChange,
    // App_Currency_Score = App_Currency, App_Currency_Score_Color = GetColor(App_Currency_Color), App_Currency_MoMChange, 
    PastStatus, CurrentStatus = GetColor(CurrentStatus), PreviousStatus = GetColor(PreviousStatus)
`;
