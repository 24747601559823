import { IDropdownOption, IDropdownStyles } from "@fluentui/react";

export * from "./Constants";

export enum Application {
    Word = "Word",
    Excel = "Excel",
    PowerPoint = "PowerPoint",
    OneNote = "OneNote",
    Visio = "Visio",
}

export const Filters = {
    DefaultRankOn: "WXP",
    DefaultTabOn: "Word",
    DefaultLevel: "TenantId",
    DefaultCohort: "S500",
    DefaultMinMau: "2500",
    AppsToRankOn: ["WXP", "Excel", "OneNote", "PowerPoint", "Visio", "Word"],
    TenantStatusList: ["All", "Blue", "Yellow", "Red", "Deep Red"],
};

export const ErrorSpikeThreshold = 0.01;

export const DefaultAppButtonLabel = "WXP";

export const defaultOptions: IDropdownOption[] = [{ key: "All", text: "All" }];

export const hostOptions: IDropdownOption[] = [
    { key: "SharePoint Online", text: "SharePoint Online" },
    { key: "Exchange Online", text: "Exchange Online" },
    { key: "All", text: "All" },
];

export const uihostOptions: IDropdownOption[] = [
    { key: "Teams", text: "Teams" },
    { key: "owa", text: "OWA" },
    { key: "officecom", text: "officecom" },
    { key: "officecompwa", text: "officecompwa" },
    { key: "", text: "(Blank)" },
    { key: "All", text: "All" },
];

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 150 },
};

export const ashaNewPivotAvailableDate = new Date("2021-01-01");

export const continentCodeToName = {
    AF: "Africa",
    NA: "North America",
    OC: "Oceania",
    AN: "Antarctica",
    AS: "Asia",
    EU: "Europe",
    SA: "South America",
};

export const isTest = false;

export const defaultCohort = "S2500";
