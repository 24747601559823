import { isTest } from "utils/WebConstants";

export const teamsChatTenantStatus: string = `
//TeamsChatTenantStatus
let dt = iif(isempty("\${date}"), GetCurrentTeamsDate(${isTest}), "\${date}");
let currentDate = GetCurrentTeamsDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
let MAULimit = 1000;
Teams_Scores
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and SubWorkload ==  app
| extend Score=Score_Score, CurrentStatus=Score_Color
| join kind = inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == toscalar(StrategicTenants|summarize max(Date))
        | distinct OmsTenantId, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project OmsTenantId, Name = Column1
    ) on OmsTenantId
| join kind = leftouter
    (Teams_Scores
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and SubWorkload ==  app 
        | project OmsTenantId, PrevMonthScore = round(Score_Score, 2), PreviousStatus = Score_Color
    ) on OmsTenantId
| where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus == "Red" or PreviousStatus == "DeepRed"
| join kind = leftouter
    (Teams_PastStatus
        | where SubWorkload == app and Date == dt 
        | extend PastStatus = GetTeamsPastYearStatus(PastStatusSet, dt)
    ) on OmsTenantId
| join kind = leftouter(
    (Teams_Meetings_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId = tostring(TenantId), MAU = Meetings_MAU, TeamsMAU = Teams_MAU)
    | union 
    (Teams_Chat_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId , MAU = Usage_Chats_MAU, TeamsMAU = Usage_Teams_MAU)
    ) on $left.OmsTenantId == $right.TenantId
| where MAU > MAULimit
| project PastStatus, Date,
TenantId = OmsTenantId, SubWorkload, OrgName=Name,Tpid, Status = Score_Color,
Composite_Score = round(Score_Score,2), Composite_MoMChange = MoM_Score,
CurrentStatus = Score_Color, PreviousStatus,
MAU_Score = MAU, TeamsMAU_Score = TeamsMAU,
UsageRate_Score, UsageRate_Score_Color = UsageRate_Color, 
ChatSwitchV2_Score, ChatSwitchV2_Score_Color = ChatSwitchV2_Color, ChatSwitchV2_MoMChange = MoM_ChatSwitchV2,
NewChat_Score, NewChat_Score_Color = NewChat_Color, NewChat_MoMChange = MoM_NewChat,
ChatSendMessage_Score, ChatSendMessage_Score_Color = ChatSendMessage_Color, ChatSendMessage_MoMChange = MoM_ChatSendMessage,
P95ChatSwitchV2_Score, P95ChatSwitchV2_Score_Color = P95ChatSwitchV2_Color, P95ChatSwitchV2_MoMChange = MoM_P95ChatSwitchV2,
P50ChatSwitchV2_Score, P50ChatSwitchV2_Score_Color = P50ChatSwitchV2_Color, P50ChatSwitchV2_MoMChange = MoM_P50ChatSwitchV2,
NPS_Score, NPS_Score_Color = NPS_Color, NPS_MoMChange = MoM_NPS
| sort by Composite_Score, MAU_Score desc
`;
