import React from "react";
import { Shimmer, Stack, IStackItemStyles } from "@fluentui/react";

export const ShimmeredStackCard = ({ minHeight, border = false }) => {
    const stackItemStyles: IStackItemStyles = {
        root: {
            marginBottom: "20px",
            marginTop: "20px !important",
            border: border ? "solid 2px #f3f2f1" : "none",
            padding: "0px",
            borderRadius: "14px",
            overflowX: "auto",
            minHeight: `${minHeight}px`,
        },
    };

    // # shimmers to display = (min card height - padding top and bottom)/(shimmer height = 16px high + margin top and bottom)
    const countShimmers = Math.floor((minHeight - 50) / 36);

    return (
        <Stack styles={stackItemStyles}>
            {[...Array(countShimmers).keys()].map((i) => (
                <Shimmer style={{ margin: "10px" }} key={`shimmer-${i}`} />
            ))}
        </Stack>
    );
};
