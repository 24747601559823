const appIdMapping: Map<string, string> = new Map([
    ["1100", "Sway"],
    ["2001", "Word"],
    ["2003", "Excel"],
    ["2021", "ToDo"],
    ["2028", "Delve"],
    ["2029", "Delve"],
    ["2031", "Outlook"],
    ["2045", "Odd"],
    ["2046", "UlsViewer"],
    ["2048", "Outlook"],
    ["2055", "OneDrive"],
    ["2056", "OneDrive"],
    ["2057", "OneDrive"],
    ["2072", "Excel"],
    ["2081", "Sway"],
    ["2115", "OCV Tool"],
    ["2116", "Office MRO Dashboard"],
    ["2124", "Metrical"],
    ["2125", "Oasysnet"],
    ["2127", "Delve"],
    ["2128", "MyAccount"],
    ["2131", "Office Home"],
    ["2133", "Office Add-ins Store"],
    ["2134", "Project Online"],
    ["2135", "Yammer"],
    ["2136", "Yammer"],
    ["2138", "Yammer"],
    ["2139", "Yammer"],
    ["2144", "SharePoint"],
    ["2145", "Member Insights"],
    ["2148", "SharePoint Admin"],
    ["2151", "MSN News"],
    ["2152", "MSN News"],
    ["2153", "Service Trust Portal"],
    ["2154", "Config.Office.Com"],
    ["2155", "Word"],
    ["2156", "Excel"],
    ["2157", "PowerPoint"],
    ["2158", "OneNote"],
    ["2159", "Visio"],
    ["2160", "OneDrive"],
    ["2162", "HITS"],
    ["2174", "COIN MMX"],
    ["2175", "SharePoint Page Authoring"],
    ["2176", "Configuration Manager Console"],
    ["2177", "Office Enrichment"],
    ["2178", "Configuration Manager Support Center"],
    ["2179", "Microsoft Kaizala"],
    ["2181", "Intune Company Portal"],
    ["2182", "Intune Company Portal"],
    ["2183", "Intune Company Portal"],
    ["2185", "Intune Company Portal"],
    ["2188", "Teams"],
    ["2189", "Teams"],
    ["2194", "Rave2"],
    ["2195", "Admin Portal"],
    ["2196", "MyAnalytics"],
    ["2197", "Workplace Analytics"],
    ["2198", "Office Telemetry Dynamic Config"],
    ["2201", "Security & Compliance Center"],
    ["2202", "Microsoft Planner"],
    ["2203", "Microsoft Planner"],
    ["2204", "Microsoft Planner"],
    ["2206", "Microsoft Store for Business"],
    ["2215", "MMX Launcher"],
    ["2216", "Azure AD"],
    ["2217", "MMX Microsoft Apps"],
    ["2218", "CSEO DevOps"],
    ["2219", "Azure Data Center Management"],
    ["2220", "Edge MMX"],
    ["2221", "Edge MMX"],
    ["2222", "Outlook"],
    ["2223", "MyOrder"],
    ["2224", "Microsoft News – msn.com"],
    ["2225", "Microsoft Stream"],
    ["2226", "Microsoft Stream"],
    ["2227", "Dynamics"],
    ["2228", "Microsoft Kaizala"],
    ["2229", "Microsoft Forms"],
    ["2231", "Edge"],
    ["2232", "Sharepoint"],
    ["2233", "Merge"],
    ["2234", "ToDo"],
    ["2235", "ToDo"],
    ["2237", "OPermissions"],
    ["2239", "Cortana"],
    ["2240", "Cortana"],
    ["2246", "M365"],
    ["2247", "Edge"],
    ["2248", "Edge"],
    ["2249", "Office Home"],
    ["2250", "Supplier Web"],
    ["2251", "Microsoft Kaizala"],
    ["2254", "Teams"],
    ["2255", "Teams"],
    ["2256", "Microsoft Graph"],
    ["2257", "Microsoft Identity Platform"],
    ["2258", "Support.Office.Com"],
    ["2259", "MSAuthorize"],
    ["2260", "P2P Support Portal"],
    ["2261", "MSApprovals"],
    ["2262", "Teams"],
    ["2264", "Microsoft Stream"],
    ["2269", "Azure Role Based Access Control Interface"],
    ["2272", "Outlook"],
    ["2273", "OneDrive"],
    ["2274", "OneDrive for Business"],
    ["2275", "P2P Receipting"],
    ["2276", "MSX Portal"],
    ["2277", "MyHub"],
    ["2278", "MyHub"],
    ["2279", "MyHub"],
    ["2280", "Microsoft Defender ATP"],
    ["2282", "Surface Diagnostic Toolkit"],
    ["2283", "Microsoft Forms Pro"],
    ["2285", "Microsoft 365 Public Roadmap"],
    ["2286", "Office Feedback SDK Test"],
    ["2291", "OneDrive"],
    ["2292", "Azure AD B2C"],
    ["2293", "account.microsoft.com"],
    ["2296", "Extend To Azure"],
    ["2297", "Azure AD Identity Governance"],
    ["2298", "Intune app"],
    ["2300", "Azure Managed Identities UX"],
    ["2301", "Azure AD PIM"],
    ["2302", "One Catalog"],
    ["2303", "Azure AD - ELM"],
    ["2304", "setup.office.com"],
    ["2306", "Royalties"],
    ["2307", "Activity Registry"],
    ["2309", "Microsoft Defender ATP"],
    ["2316", "Azure Machine Learning"],
    ["2318", "Teams"],
    ["2323", "Fluid Experience"],
    ["2324", "Microsoft Teams Admin Center"],
    ["2325", "Docs"],
    ["2327", "SharePoint"],
    ["2328", "SharePoint"],
    ["2329", "Support.microsoft.com"],
    ["2334", "MSX D365"],
    ["2337", "OMEX Partner Opportunity Dashboard"],
    ["2338", "Yammer"],
    ["2339", "HR Profile Management"],
    ["2340", "Legal Contracting Experience"],
    ["2341", "MSX Insights"],
    ["2343", "OneAsset"],
    ["2346", "PowerApps"],
    ["2347", "PowerApps"],
    ["2348", "Microsoft Teams for Life"],
    ["2350", "Microsoft Teams for Life"],
    ["2351", "Microsoft Teams for Life"],
    ["2352", "Microsoft Teams for Life"],
    ["2353", "Microsoft Teams for Life"],
    ["2355", "Windows Defender - Researcher Studio"],
    ["2356", "Microsoft Commercial Signup Experience"],
    ["2357", "Supplier Scorecard"],
    ["2358", "Who+"],
    ["2359", "Enterprise Services Experience Platform"],
    ["2360", "Enterprise Services Experience Platform"],
    ["2361", "MCO+I - Compass (CE)"],
    ["2365", "AadMyApps"],
    ["2366", "StatementPortal"],
    ["2367", "Family Safety"],
    ["2368", "Family Safety"],
    ["2369", "Virtual Machine Acquisition Server"],
    ["2372", "SCE Testing Platform"],
    ["2373", "Jasper"],
    ["2374", "Windows Community"],
    ["2375", "Microsoft Defender ATP"],
    ["2376", "Editor Browser Extension"],
    ["2377", "Microsoft Autofill"],
    ["2378", "Microsoft Recruit"],
    ["2379", "Office 365"],
    ["2380", "ModernRDP"],
    ["2381", "Branch Management"],
    ["2382", "Compliant Save"],
    ["2384", "Self Serve Administration"],
    ["2385", "GetConnected"],
    ["2386", "Manage Payments"],
    ["2387", "Enterprise Services Experience Platform"],
    ["2390", "ProcureWeb 2.0"],
    ["2391", "Mint"],
    ["2393", "Edge"],
    ["2394", "Edge"],
    ["2395", "Configuration Manager Setup"],
    ["2396", "CSEO Feedback Monitoring"],
    ["2397", "Digital Operations Center"],
    ["2398", "Express Route Portal"],
    ["2399", "POST"],
    ["2401", "Service Health Report"],
    ["2404", "CoreIdentity"],
    ["2407", "Accessibility Inventory and Reporting Tool"],
    ["2412", "Enterprise User Access"],
    ["2413", "UTP Bulk Ingestion"],
    ["2415", "OneDrive for Business"],
    ["2416", "OneDrive for Business"],
    ["2417", "OneDrive for Business"],
    ["2420", "OneDrive"],
    ["2422", "DevX SCRIPT"],
    ["2428", "Edge"],
    ["2431", "Product Catalog"],
    ["2432", "Azure Network Insights"],
    ["2433", "ServicesHub"],
    ["2435", "Operational Reporting Platform"],
    ["2436", "Your Phone"],
    ["2437", "Incentive Claims Management"],
    ["2438", "Insights HR Platform"],
    ["2439", "Policy Catalog"],
    ["2440", "ServiceNow Core Platform"],
    ["2442", "Microsoft Edge Addons"],
    ["2443", "Compliance Portal"],
    ["2444", "CSEO BMR"],
    ["2445", "M365 Admin Center"],
    ["2446", "HEART Onboarding Portal"],
    ["2447", "Microsoft Advertising Editor"],
    ["2449", "Modern Engineering Wiki"],
    ["2450", "OneITVSO Wiki"],
    ["2453", "Controller Workspace"],
    ["2454", "Lists Home"],
    ["2455", "CSEO Coherence"],
    ["2457", "Mercury"],
    ["2458", "Global Careers Site"],
    ["2459", "Microsoft Lists"],
    ["2460", "Approvals Modern Website"],
    ["2461", "Mint Web"],
    ["2464", "Talent Reporting Tool"],
    ["2465", "Hiring Manager Experience"],
    ["2467", "Sapphire News"],
    ["2468", "Sapphire News"],
    ["2469", "Sapphire Search"],
    ["2470", "Sapphire Search"],
    ["2471", "Edge"],
    ["2472", "Edge"],
    ["2473", "IcM Incident Analytics"],
    ["2474", "insider.office.com"],
    ["2475", "Windows Feeds"],
    ["2476", "Microsoft Authenticator"],
    ["2477", "Microsoft Authenticator"],
    ["2479", "Microsoft Consumer Security Portal"],
    ["2480", "Azure Iridias"],
    ["2482", "Microsoft Advertising"],
    ["2489", "Professional Services Data Lake Platform"],
    ["2490", "One Voice Data Insights"],
    ["2501", "ARO Dashboard"],
    ["2503", "ESAT Experience"],
    ["2507", "MLS Patching Portal"],
    ["2509", "Performance"],
    ["2510", "Guest Management System"],
    ["2514", "Security Platform for Assessments Consulting and Engineering (SPACE)"],
    ["2515", "STAT"],
    ["2518", "Symfonia"],
    ["2519", "Heart Insights Portal"],
    ["2521", "TrackingNG"],
    ["2522", "MetricLava"],
    ["2524", "Edge"],
    ["2525", "Surface Duo"],
    ["2526", "MyWorkspace"],
    ["2530", "Azure Community Insider"],
    ["2531", "Report IT Now"],
    ["2533", "MyNetwork"],
    ["2534", "SASWeb"],
    ["2535", "Outlook Monarch"],
    ["2536", "Azure Digital Twins Explorer"],
    ["2537", "CO+I Workflow Management System"],
    ["2538", "Microsoft Teams Dev Portal"],
    ["2539", "Docs Platform"],
    ["2541", "Recommendations Analytics Dashboard"],
    ["2543", "Office SMB Purchase"],
    ["2544", "Dynamics 365 for Sales"],
    ["2545", "Dynamics 365 for Sales"],
    ["2546", "Outlook"],
    ["2547", "Outlook Browser Extension"],
    ["2548", "Meeting Catchup Portal"],
    ["2549", "IdentityExtensions"],
    ["2550", "Field Service Mobile"],
    ["2551", "Field Service Mobile"],
    ["2552", "Microsoft Viva Topics"],
    ["2553", "GTA Event Management"],
    ["2555", "Cascade Feedback Lite"],
    ["2556", "M365 Lighthouse"],
    ["2558", "Xbox Game Streaming"],
    ["2559", "Xbox Game Streaming"],
    ["2560", "Xbox Game Streaming"],
    ["2561", "Xbox Game Streaming"],
    ["2562", "Xbox Game Streaming"],
    ["2563", "M365 Admin Mobile"],
    ["2564", "M365 Admin Mobile"],
    ["2565", "Microsoft Bookings"],
    ["2566", "CAP\\PCM FeedbackLite"],
    ["2567", "Cloud PC IW Portal"],
    ["2570", "Recon Intelligence"],
    ["2571", "Journal Entry Management"],
    ["2572", "SM Device Workflow Automation"],
    ["2577", "Viva Learning"],
    ["2578", "Viva Learning"],
    ["2579", "Source VNext"],
    ["2580", "Microsoft Lists"],
    ["2581", "Watchtower"],
    ["2582", "Total Rewards Portal"],
    ["2583", "Outlook Lite"],
    ["2585", "Lens Suite FeedbackLite"],
    ["2587", "Mentoring"],
    ["2588", "Microsoft Viva Topics"],
    ["2589", "Microsoft Search"],
    ["2594", "Partner Benefits Service"],
    ["2597", "Microsoft Loop"],
    ["2605", "My Skills"],
    ["2606", "Modern Engineering Connection"],
    ["2607", "Exchange Admin Center"],
    ["2608", "SureStepSTART"],
    ["2609", "Find legal Contact"],
    ["2610", "Global Country Holidays"],
    ["2611", "AskHR Support"],
    ["2612", "Employee Verification Letter"],
    ["2613", "ServiceNow"],
    ["2615", "OneAssetV2"],
    ["2617", "Polls"],
    ["2618", "Education Insights"],
    ["2619", "User Access Review"],
    ["2620", "Service Maturity Model"],
    ["2621", "OneServicesEstimator"],
    ["2622", "Virtuoso"],
    ["2623", "Insider Risk Management"],
    ["2625", "Project Orland"],
    ["2626", "Designer"],
    ["2629", "RNEXT"],
    ["2630", "Repair Action Manager"],
    ["2637", "W365"],
    ["2644", "Microsoft Teams Rooms - Managed Services"],
    ["2651", "Designer Hub"],
    ["2652", "Designer Ecosystem"],
    ["2654", "Microsoft Create"],
    ["2655", "Services Engagement Experience-SEE"],
    ["2656", "Incident Response Test(IRT) Portal"],
    ["2660", "Surface App Mobile"],
    ["2661", "Surface App Mobile"],
    ["2662", "Analyze Data"],
    ["2663", "Canada Tax App"],
    ["2672", "Loop"],
    ["2674", "Emerald"],
    ["2675", "LRP RAI"],
    ["2676", "FinancialPlanning"],
    ["2678", "Viva Goals"],
    ["2680", "Outside-In UX"],
    ["2681", "MeshXP"],
    ["2685", "ACE Hub"],
    ["2687", "Whiteboard NPS Survey"],
    ["2691", "Benefits Dashboard"],
    ["2692", "Microsoft Advertising Mobile"],
]);

export default appIdMapping;
