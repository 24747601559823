import React from "react";

const CustomToolTip = (props) => {
    const { active, customToolTipContentFunction } = props;

    if (active) {
        return (
            <div className="customTooltip">
                <div className="label">{customToolTipContentFunction(props)}</div>
            </div>
        );
    }

    return null;
};

export default CustomToolTip;
