import { isTest } from "utils/WebConstants";

export const notes: string = `
let currDate = GetCurrentDate(${isTest});
StrategicTenants
| where Date == GetCurrentDate(${isTest}) and \${levelColumnName} == "\${id}"
| project Tpid
| take 1
| join kind=inner 
Notes on Tpid
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId =="All", \${levelColumnName} == "\${id}")
| extend ExemptedUntil = iif(isnotempty(ExemptedUntil), todatetime(ExemptedUntil), datetime_add("month", 3, todatetime(Date)))
| extend IsExempted = ExemptEngagedEditors or ExemptFrown or ExemptMAU or ExemptMEU or ExemptOpenInClient or ExemptPerformance
    or ExemptReliability or ExemptReturningMAU or ExemptReturningMAU or IsExempted
| sort by Date, id asc
| project Date, Tpid, TenantId = OmsTenantId, IsExempted = (now() <= ExemptedUntil) and IsExempted, Notes,
    ExemptMAU, ExemptMEU, ExemptEngagedEditors, ExemptReturningMAU, ExemptPerformance, ExemptReliability, ExemptFrown, ExemptOpenInClient,
    ExemptedUntil = format_datetime(ExemptedUntil, "MM-dd-yyyy"), AddedBy
`;
