export const consumerCamauDailyMetrics = `
// Consumer Camau Daily queries.
set query_results_cache_max_age = time(2h);
let VTT_MAU = "VTT Actual MAU";
let VTT_PerCentActual_CAMAU = "VTT Actuals %Core actions MAU";
let VTT_PerCentMAU = "VTT % MAU";
let Cadence = "cadence";
database('ConsumerInsights').CamauDaily
| where OperatingSystem != "Unknown" and OperatingSystem != "Other"
| project ProcessDate, Application, Segment=strcat_delim("-", Platform, OperatingSystem), MAU, Editors, perCentEditors, TargetMAU, TargetCAMAU, TargetPerCentEditors,
 VTT_MAU = (MAU - TargetMAU), VTT_PerCentMAU = (MAU - TargetMAU)/TargetMAU, 
 VTT_PerCentActual_CAMAU = perCentEditors - TargetPerCentEditors, Cadence = IsMonthEnd
| order by Application, ProcessDate desc
`;

export const consumerCamauMonthlyMetrics = `
// Consumer Camau Monthly queries.
set query_results_cache_max_age = time(2h);
let VTT_MAU = "VTT Actual MAU";
let VTT_PerCentActual_CAMAU = "VTT Actuals %Core actions MAU";
let VTT_PerCentMAU = "VTT % MAU";
let Cadence = "cadence";
database('ConsumerInsights').CamauDaily
| extend ProcessDateAsDate = todatetime(ProcessDate)
| where ProcessDateAsDate == startofday(endofmonth(ProcessDateAsDate)) and OperatingSystem != "Unknown" and OperatingSystem != "Other" and OperatingSystem != "Mac" and OperatingSystem != "Android" and OperatingSystem != "Windows" and OperatingSystem != "iOS" and Platform != "AllPlat"
| project ProcessDate, Application, Segment=strcat_delim("-", Platform, OperatingSystem), MAU, Editors, perCentEditors, TargetMAU, TargetCAMAU,
 TargetPerCentEditors, VTT_MAU = (MAU - TargetMAU), VTT_PerCentMAU = (MAU - TargetMAU)/TargetMAU, 
 VTT_PerCentActual_CAMAU = perCentEditors - TargetPerCentEditors, Cadence = IsMonthEnd
| order by Application, ProcessDate desc
`;
