import { IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import parse from "date-fns/parse";
import * as API from "api/index";
import { processRetentionAshaScoresToGenerateInsights } from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardAshaInsightsHelper";
import {
    checkSignificanceAndUpdateMap,
    segmentMetadata,
    segmentOrder,
} from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardHelper";
import {
    ConsumerGrowthScorecardFiltersType,
    ConsumerGrowthScorecardRowType,
    ConsumerGrowthScorecardTableType,
    AshaFetchedData,
    AshaInsightsForRetention,
    ConsumerGrowthScorecardSegmentNames,
    StatSigSegmentMetricMap,
} from "components/ConsumerGrowthScorecardComponents/types";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { addMonthsToDate, formatDisplayDate } from "utils/Helpers";
import { AshaHierarchyLevel } from "utils/types";
import "./style.css";

export const formatScorecardTableData = (
    mauJson: KustoResponseType<string | number>,
): [ConsumerGrowthScorecardTableType, string[]] => {
    const mauDataTable = mauJson.Tables[0];

    const formattedData: ConsumerGrowthScorecardTableType = {};

    if (mauDataTable.Rows.length === 0) {
        return [formattedData, []];
    }
    const distinctDates: {} = {};

    //schema- Date, Application, Segment, MAU_Score, MAU_YoY, MAUGoal_Score, CAMAUDay0_Pct, CAMAUDay0_YoY, CAMAUDay0Goal_Pct,
    // CAMAUPct_Pct, CAMAUPct_YoY, CAMAUPctGoal_Pct,
    // CAMAU_Score, CAMAU_YoY, CAMAUGoal_Score,
    // M1Retention_Pct, M1Retention_YoY, M1RetentionGoal_Pct, M3Retention_Pct, M3Retention_YoY, M3RetentionGoal_Pct
    mauDataTable.Rows.forEach((dataRow) => {
        const date = dataRow[0] as string;
        const app = dataRow[1] as string;
        const segment = dataRow[2] as string;
        if (!(app in formattedData)) formattedData[app] = [];

        if (!(date in distinctDates)) {
            distinctDates[date] = date;
        }

        formattedData[app].push({
            Date: date,
            Application: app,
            Segment: segment,
            MAU_Score: dataRow[3] as number,
            MAU_YoY: dataRow[4] as number,
            CAMAUDay0_Pct: dataRow[6] as number,
            CAMAUDay0_YoY: dataRow[7] as number,
            CAMAUPct_Pct: dataRow[9] as number,
            CAMAUPct_YoY: dataRow[10] as number,
            CAMAU_Score: dataRow[12] as number,
            CAMAU_YoY: dataRow[13] as number,
            M1Retention_Pct: dataRow[15] as number,
            M1Retention_YoY: dataRow[16] as number,
            M3Retention_Pct: dataRow[18] as number,
            M3Retention_YoY: dataRow[19] as number,
        });
    });

    Object.keys(formattedData).forEach((app) => {
        formattedData[app].sort(
            (
                a: ConsumerGrowthScorecardRowType,
                b: ConsumerGrowthScorecardRowType,
            ) => {
                // first sort by date
                const dateOrder = b.Date.localeCompare(a.Date);

                if (dateOrder === 0) {
                    return segmentOrder[a.Segment] - segmentOrder[b.Segment];
                }

                return dateOrder;
            },
        );
    });

    return [formattedData, Object.keys(distinctDates)];
};

export const fetchScorecardTabledata = async (
    filters: ConsumerGrowthScorecardFiltersType,
    queryName: string,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...filters };
    const scorecardData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return scorecardData?.data;
};

export const formatScorecardDateOptions = (
    distinctDatesArray: string[],
    onFilterChange: (item: string, value: string) => void,
) => {
    const dateOptions: IContextualMenuItem[] = [];

    if (distinctDatesArray.length === 0) {
        return dateOptions;
    }

    interface tempInterface {
        [monthString: string]: string[];
    }

    const datesTemp: tempInterface = {};

    distinctDatesArray.forEach((date) => {
        const convertedMonth = formatDisplayDate(date);

        if (!(convertedMonth in datesTemp)) {
            datesTemp[convertedMonth] = [];
        }

        datesTemp[convertedMonth].push(date);
    });

    Object.keys(datesTemp).forEach((month) => {
        dateOptions.push({
            key: month,
            text: month,
            subMenuProps: {
                items: datesTemp[month].map((dates) => ({
                    text: dates,
                    key: dates,
                })),
                onItemClick: (_ev, item: IContextualMenuItem) => {
                    onFilterChange("date", item?.key);
                },
                className: "growthScorecardDateMenu",
            },
        });
    });

    return dateOptions;
};
export const updateScorecardChartData = (
    segment: string,
    application: string,
    monthsToShow: number,
    data: ConsumerGrowthScorecardTableType,
): ConsumerGrowthScorecardRowType[] => {
    try {
        const nowDate = new Date();
        const lowestDate = addMonthsToDate(nowDate, -monthsToShow);
        const result = data[application]?.filter((value) => {
            const dateCheck = parse(value.Date, "yyyy-MM-dd", nowDate) > lowestDate;

            const segmentCheck = value.Segment === segment;

            return dateCheck && segmentCheck;
        });

        return result.reverse();
    } catch (e) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "ConsumerScorecardTrendProcessingFailed",
            ),
            {
                message:
                    "Failed to format Consumer growth scorecard metric trends data",
            },
            e,
        );

        return [];
    }
};

export const fetchRetentionAshaData = async (
    filters: ConsumerGrowthScorecardFiltersType,
    ashaLevel: AshaHierarchyLevel,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = { ...filters };
    let queryName = `ashaConsumerGrowthScorecardRetention${ashaLevel}ScoreWeekly`;

    const growthScorecardRetentionAshaData = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return growthScorecardRetentionAshaData?.data;
};

export const processRetentionAshaDataToGetInsights = (
    ashaPillarJson: KustoResponseType<string | number>,
    ashaVetoJson: KustoResponseType<string | number>,
    ashaErrorJson: KustoResponseType<string | number>,
): AshaInsightsForRetention => {
    if (!ashaPillarJson || !ashaVetoJson || !ashaErrorJson) return null;

    const ashaPillarTable = ashaPillarJson.Tables[0];
    const ashaVetoTable = ashaVetoJson.Tables[0];
    const ashaErrorTable = ashaErrorJson.Tables[0];

    let fetchedAshaData: AshaFetchedData = {};

    // If there are no rows, return null
    // This can happen, for example, if there is no ASHA data yet for a given date
    if (
        ashaPillarTable.Rows.length == 0 ||
        ashaVetoTable.Rows.length == 0 ||
        ashaErrorTable.Rows.length == 0
    ) {
        return null;
    }

    // Process pillar data
    ashaPillarTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const segment: string = element[1] as string;
        const retentionPeriod: string = element[2] as string;
        const churnedOrRetained: string = element[3] as string;
        const score: number = element[4] as number;
        const pillarSessionCount: number = element[5] as number;
        const hitVetoOrErrorSessionCount: number = element[6] as number;

        ensureSegmentInFetchedAshaDataIsPrepared(
            fetchedAshaData,
            segment,
            retentionPeriod,
            churnedOrRetained,
        );

        // The query results should only have 1 row per segment/pillar/retentionPeriod/churnedOrRetained combo
        if (
            fetchedAshaData[segment].PillarScores[retentionPeriod][
                churnedOrRetained
            ].find((score) => score.Pillar === pillar)
        ) {
            throw new Error(
                "Duplicate segment/pillar/retentionPeriod/churnedOrRetained combo in ASHA pillar query results",
            );
        }

        fetchedAshaData[segment].PillarScores[retentionPeriod][
            churnedOrRetained
        ].push({
            Segment: segment,
            RetentionPeriod: retentionPeriod,
            ChurnedOrRetained: churnedOrRetained,
            Pillar: pillar,
            Score: score,
            HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
            Key: pillar,
            SessionCountKey: pillar,
        });

        fetchedAshaData[segment].PillarSessionCounts[retentionPeriod][
            churnedOrRetained
        ].push({
            Segment: segment,
            RetentionPeriod: retentionPeriod,
            ChurnedOrRetained: churnedOrRetained,
            Pillar: pillar,
            PillarSessionCount: pillarSessionCount,
            Key: pillar,
        });
    });

    // Process veto data
    ashaVetoTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const veto: string = element[1] as string;
        const segment: string = element[2] as string;
        const retentionPeriod: string = element[3] as string;
        const churnedOrRetained: string = element[4] as string;
        const score: number = element[5] as number;
        const hitVetoOrErrorSessionCount: number = element[7] as number;

        ensureSegmentInFetchedAshaDataIsPrepared(
            fetchedAshaData,
            segment,
            retentionPeriod,
            churnedOrRetained,
        );

        // The query results should only have 1 row per segment/pillar/veto/retentionPeriod/churnedOrRetained combo
        if (
            fetchedAshaData[segment].VetoScores[retentionPeriod][
                churnedOrRetained
            ].find((score) => score.Pillar === pillar && score.Veto === veto)
        ) {
            throw new Error(
                "Duplicate segment/pillar/veto/retentionPeriod/churnedOrRetained combo in ASHA pillar query results",
            );
        }

        fetchedAshaData[segment].VetoScores[retentionPeriod][churnedOrRetained].push(
            {
                Segment: segment,
                RetentionPeriod: retentionPeriod,
                ChurnedOrRetained: churnedOrRetained,
                Pillar: pillar,
                Veto: veto,
                Score: score,
                HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
                Key: `${pillar}${veto}`,
                SessionCountKey: pillar,
            },
        );
    });

    // Process error data
    ashaErrorTable.Rows.forEach((element) => {
        const pillar: string = element[0] as string;
        const veto: string = element[1] as string;
        const error: string = element[2] as string;
        const segment: string = element[3] as string;
        const retentionPeriod: string = element[4] as string;
        const churnedOrRetained: string = element[5] as string;
        const score: number = element[6] as number;
        const hitVetoOrErrorSessionCount: number = element[8] as number;

        ensureSegmentInFetchedAshaDataIsPrepared(
            fetchedAshaData,
            segment,
            retentionPeriod,
            churnedOrRetained,
        );

        // The query results should only have 1 row per segment/pillar/veto/error/retentionPeriod/churnedOrRetained combo
        if (
            fetchedAshaData[segment].ErrorScores[retentionPeriod][
                churnedOrRetained
            ].find(
                (score) =>
                    score.Pillar === pillar &&
                    score.Veto === veto &&
                    score.Error === error,
            )
        ) {
            throw new Error(
                "Duplicate segment/pillar/veto/error/retentionPeriod/churnedOrRetained combo in ASHA pillar query results",
            );
        }

        fetchedAshaData[segment].ErrorScores[retentionPeriod][
            churnedOrRetained
        ].push({
            Segment: segment,
            RetentionPeriod: retentionPeriod,
            ChurnedOrRetained: churnedOrRetained,
            Pillar: pillar,
            Veto: veto,
            Error: error,
            Score: score,
            HitVetoOrErrorSessionCount: hitVetoOrErrorSessionCount,
            Key: `${pillar}${veto}${error}`,
            SessionCountKey: pillar,
        });
    });

    // Get top ASHA insights for each segment
    const ashaInsights: AshaInsightsForRetention = {};
    Object.keys(ConsumerGrowthScorecardSegmentNames).forEach((segment) => {
        const ashaTopInsightsForSegment =
            processRetentionAshaScoresToGenerateInsights(fetchedAshaData, segment);

        ashaInsights[segment] = ashaTopInsightsForSegment;
    });

    return ashaInsights;
};

const ensureSegmentInFetchedAshaDataIsPrepared = (
    fetchedAshaData: AshaFetchedData,
    segment: string,
    retentionPeriod: string,
    churnedOrRetained: string,
) => {
    if (!(segment in fetchedAshaData)) {
        fetchedAshaData[segment] = {
            PillarSessionCounts: {},
            PillarScores: {},
            VetoScores: {},
            ErrorScores: {},
        };
    }

    // Pillar Session Counts
    if (!(retentionPeriod in fetchedAshaData[segment].PillarSessionCounts)) {
        fetchedAshaData[segment].PillarSessionCounts[retentionPeriod] = {};
    }

    if (
        !(
            churnedOrRetained in
            fetchedAshaData[segment].PillarSessionCounts[retentionPeriod]
        )
    ) {
        fetchedAshaData[segment].PillarSessionCounts[retentionPeriod][
            churnedOrRetained
        ] = [];
    }

    // Pillar Scores
    if (!(retentionPeriod in fetchedAshaData[segment].PillarScores)) {
        fetchedAshaData[segment].PillarScores[retentionPeriod] = {};
    }

    if (
        !(
            churnedOrRetained in
            fetchedAshaData[segment].PillarScores[retentionPeriod]
        )
    ) {
        fetchedAshaData[segment].PillarScores[retentionPeriod][churnedOrRetained] =
            [];
    }

    // Veto Scores
    if (!(retentionPeriod in fetchedAshaData[segment].VetoScores)) {
        fetchedAshaData[segment].VetoScores[retentionPeriod] = {};
    }

    if (
        !(churnedOrRetained in fetchedAshaData[segment].VetoScores[retentionPeriod])
    ) {
        fetchedAshaData[segment].VetoScores[retentionPeriod][churnedOrRetained] = [];
    }

    // Error Scores
    if (!(retentionPeriod in fetchedAshaData[segment].ErrorScores)) {
        fetchedAshaData[segment].ErrorScores[retentionPeriod] = {};
    }

    if (
        !(churnedOrRetained in fetchedAshaData[segment].ErrorScores[retentionPeriod])
    ) {
        fetchedAshaData[segment].ErrorScores[retentionPeriod][churnedOrRetained] =
            [];
    }
};

export const fetchRetentionSignificanceData = async (
    filters: ConsumerGrowthScorecardFiltersType,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...filters };
    const retentionData = await API.getKustoResponse({
        queryName: "consumerRetentionStatSigMetrics",
        platform: Workload.WEB,
        queryParams,
    });
    return retentionData?.data;
};

export const fetchMauCamauSignificanceData = async (
    filters: ConsumerGrowthScorecardFiltersType,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...filters };
    const mauCamauData = await API.getKustoResponse({
        queryName: "consumerMauCamauStatSigMetrics",
        platform: Workload.WEB,
        queryParams,
    });
    return mauCamauData?.data;
};

export const formatStatSigData = (
    retentionData: KustoResponseType<string | number>,
    mauCamaData: KustoResponseType<string | number>,
): StatSigSegmentMetricMap => {
    const statSigMetrics: StatSigSegmentMetricMap = new Map<string, string[]>();
    retentionData.Tables[0].Rows.forEach((row) => {
        const segment = segmentMetadata[row[0]]?.displayText;
        if (segment) {
            const m1r = row[1] as number;
            const m3r = row[2] as number;
            const m1rLastYear = row[3] as number;
            const m3rLastYear = row[4] as number;
            const m1Mau = row[5] as number;
            const m3Mau = row[6] as number;
            const m1MauLastYear = row[7] as number;
            const m3MauLastYear = row[8] as number;

            if (m1r && m1rLastYear && m1Mau && m1MauLastYear) {
                checkSignificanceAndUpdateMap(
                    statSigMetrics,
                    segment,
                    "M1Retention_YoY",
                    m1r,
                    m1Mau,
                    m1rLastYear,
                    m1MauLastYear,
                );
            }

            if (m3r && m3rLastYear && m3Mau && m3MauLastYear) {
                checkSignificanceAndUpdateMap(
                    statSigMetrics,
                    segment,
                    "M3Retention_YoY",
                    m3r,
                    m3Mau,
                    m3rLastYear,
                    m3MauLastYear,
                );
            }
        }
    });

    mauCamaData.Tables[0].Rows.forEach((row) => {
        const segment = segmentMetadata[row[0]]?.displayText;
        if (segment) {
            if (!statSigMetrics.has(segment)) {
                statSigMetrics.set(segment, []);
            }

            if (row[1]) {
                statSigMetrics.get(segment).push("MAU_YoY");
            }

            if (row[2]) {
                statSigMetrics.get(segment).push("CAMAU_YoY");
            }
        }
    });

    return statSigMetrics;
};
