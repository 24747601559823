import React from "react";
import "pages/Error/style.css";
import { WikiLinks } from "utils/Links";
/*
SOURCE for 403 error page- https://codepen.io/cassidoo/pen/rZQQrj
*/
const Error = () => {
    return (
        <div className="errorBody">
            <div className="headline">
                <h2>
                    {" "}
                    <span role="img" aria-label="Raised hand">
                        ✋
                    </span>{" "}
                    You don&apos;t have permission to access this site{" "}
                    <span role="img" aria-label="Raised hand">
                        ✋
                    </span>
                </h2>
                <h2>
                    Please check{" "}
                    <a
                        href={WikiLinks["Access"]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "blue" }}
                    >
                        wiki
                    </a>{" "}
                    for access requirements
                </h2>
            </div>
            <div className="hover">
                <div className="background">
                    <div className="door">401</div>
                    <div className="rug" />
                </div>
                <div className="foreground">
                    <div className="bouncer">
                        <div className="head">
                            <div className="neck" />
                            <div className="eye left" />
                            <div className="eye right" />
                            <div className="ear" />
                        </div>
                        <div className="body" />
                        <div className="arm" />
                    </div>
                    <div className="poles">
                        <div className="pole left" />
                        <div className="pole right" />
                        <div className="rope" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error;
