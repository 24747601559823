import React from "react";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
} from "recharts";
import {
    cohortAnalysisChartsHeight,
    cohortAnalysisChartsWidth,
} from "pages/CohortAnalysis/CohortAnalysisConstants";
import { chartMargin } from "pages/common";
import { getDomainForYAxis, getTicksForChart } from "utils/Helpers";
import CustomToolTip from "../CustomToolTip";

const DataComposedChart = ({
    chartData,
    XAxisDataKey,
    XAxisName,
    YAxisDataKey,
    YAxisName,
    YAxisRightDataKey,
    YAxisRightName,
    currentlySelectedCohort,
    chartCallBackFunction,
    getCellColor,
    getCellStroke,
    dashedLineDataKey,
    dashedLineName,
    toolTipFunction,
}) => {
    return (
        <ResponsiveContainer
            width={cohortAnalysisChartsWidth}
            height={cohortAnalysisChartsHeight}
        >
            <ComposedChart data={chartData} margin={chartMargin}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                    dataKey={XAxisDataKey}
                    scale="band"
                    label={{ value: XAxisName, position: "bottom" }}
                />
                <YAxis
                    dataKey={YAxisDataKey}
                    type="number"
                    domain={getDomainForYAxis(
                        chartData.map((element) => element[YAxisDataKey]),
                    )}
                    ticks={getTicksForChart(
                        chartData.map((element) => element[YAxisDataKey]),
                    )}
                    label={{
                        value: YAxisName,
                        angle: -90,
                        position: "insideBottomLeft",
                    }}
                />
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    dataKey={YAxisRightDataKey}
                    domain={getDomainForYAxis(
                        chartData.map((element) => element[YAxisRightDataKey]),
                    )}
                    ticks={getTicksForChart(
                        chartData.map((element) => element[YAxisRightDataKey]),
                    )}
                    label={{
                        value: YAxisRightName,
                        angle: -90,
                        position: "insideTopRight",
                        offset: 5,
                    }}
                />
                <Tooltip
                    content={
                        <CustomToolTip
                            customToolTipContentFunction={toolTipFunction}
                            currentlySelectedCohort={currentlySelectedCohort}
                        />
                    }
                />
                <Legend verticalAlign="top" height={50} />
                <Bar
                    dataKey={YAxisDataKey}
                    name={YAxisName}
                    barSize={20}
                    fill="#00D084"
                    style={{ cursor: "pointer" }}
                >
                    {chartData.map((entry, index) => {
                        return (
                            <Cell
                                key={`cell-${index}`}
                                fill={getCellColor(entry)}
                                stroke={getCellStroke(
                                    entry,
                                    currentlySelectedCohort,
                                )}
                                strokeWidth={3}
                                onClick={() => chartCallBackFunction(entry.Name)}
                            />
                        );
                    })}
                </Bar>
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={YAxisRightDataKey}
                    name={YAxisRightName}
                    stroke="#ff7300"
                    style={{ cursor: "pointer" }}
                />
                <Line
                    dataKey={dashedLineDataKey}
                    name={dashedLineName}
                    dot={false}
                    strokeDasharray="2 1 2"
                    strokeWidth={3}
                    stroke="#B6B4B4"
                    style={{ cursor: "pointer" }}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default DataComposedChart;
