export const teamsMeetingLatency: string = `
// Teams Meeting Latency
let group = datatable(Scenario: dynamic, ScenarioGroup: string) [
    dynamic(["call_accept_meeting", "create_meetup", "join_or_create_meetup_from_link", "join_scheduled_meetup"]), "Meeting Join Time",
    dynamic(["toggle_mute_latency", "toggle_mute_latency_local_mute_unmute", "toggle_mute_latency_server_unmute"]), "Mute/Unmute",
    dynamic(["meeting_reactions_send_reaction"]), "Meeting Reactions",
    dynamic(["raise_hand", "dismiss_hand", "dismiss_hand_for_everyone"]), "Raise Hands",
    dynamic(["roster_panel_render"]), "Roster Open",
    dynamic(["share_tray_fetch_ppt", "share_tray_previews_latency", "share_tray_whiteboard_latency"]), "Share Tray",
    dynamic(["chat_pane_from_call_render"]), "Chat",
    dynamic(["floating_sharetray_render", "share_tray_render"]), "Share Tray Load",
    dynamic(["participant_vbss_latency"]), "Video Share Display Time",
    dynamic(["calling_window_popout_intent"]), "Window Launch Time"
];
let data = Teams_Meeting_Latency
| where OmsTenantId == "\${id}"
| where Date == "\${date}"
| project Scenario_Name, Platform, Latency_P50 = round(Latency_P50/1000,2),
 Latency_P95 = round(Latency_P95/1000,2), Latency_P99 = round(Latency_P99/1000,2)
| join kind = inner 
(group | mv-expand Scenario | project Scenario = tostring(Scenario), Group = ScenarioGroup
) on $left.Scenario_Name == $right.Scenario
| extend Scenario_Name = replace_string(Scenario_Name, "_", " ")
| where Platform != "Other"
| extend Platform = iff(Platform == "Shub-Electron", "Electron", Platform)
| project-away Scenario;
let p_50 =
data | evaluate pivot(Platform, max(Latency_P50), Scenario_Name, Group)
| extend Latency = "P50";
let p_95 =
data | evaluate pivot(Platform, max(Latency_P95), Scenario_Name, Group)
| extend Latency = "P95";
let p_99 =
data | evaluate pivot(Platform, max(Latency_P99), Scenario_Name, Group)
| extend Latency = "P99";
p_50 | union p_95 | union p_99
| extend Scenario_Name = iff(Scenario_Name contains("latency"), replace_string(Scenario_Name, "latency", ""), 
    iff(Scenario_Name contains "render", replace_string(Scenario_Name, "render", ""), Scenario_Name))
| extend Group = iff(Group == "Share Tray Load", "Share Tray Load Render", iff(Group == "Chat", "Chat Render" , Group))
| order by Group, Scenario_Name, Latency asc
`;
