export const win32HealthChartMTR: string = `
// win32HealthChart - cluster('ehfuelinsights.westus2.kusto.windows.net').database('TenantInsights')
set query_results_cache_max_age = time(12h);
let currDate = toscalar(
    TenantInsights_Win32_MonthsAvailable
    | distinct Dates
    | top 1 by Dates desc);
let tenantLevel = "Tenant";
let app = "WXPO";
let cohort = "S500";
let mau = 2500;
let GetColor = (status: long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_HealthDistribution
| project Application, Cohort, Color, Count, level, mauTags, ProcessDate
| where Application == app
    and Cohort == cohort
    and level == tenantLevel
    and mauTags == mau
| extend Current = (ProcessDate == currDate)
| project ProcessDate, Color=GetColor(Color), Count, Current
| order by ProcessDate asc, Color asc
`;
