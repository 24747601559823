export const teamsTenantList: string = `
//TeamsTenantList
let currDate = GetCurrentTeamsDate(false);
let stratTenantsMaxDate = toscalar(StrategicTenants|summarize max(Date));
StrategicTenants
| where Date == stratTenantsMaxDate
| distinct Tpid, OrgName_Translated, IsS500, IsS2500, IsEPA, IsGoogleMove, IsGov, IsEO
| extend OrgName_Translated = iff(OrgName_Translated == "MicrosoftStanleyGTest", "Microsoft", OrgName_Translated)
| summarize IsTpidS2500 = max(IsS2500), IsTpidS500 = max(IsS500), IsTpidEPA = max(IsEPA), IsTpidGoogleMove = max(IsGoogleMove), IsTpidGov = max(IsGov), IsTpidEO = max(IsEO) by Tpid, OrgName_Translated = iff(Tpid == 23321296, toupper(OrgName_Translated), OrgName_Translated)
| project Tpid, OrgName_Translated, IsTpidS500, IsTpidS2500, IsTpidEPA, IsTpidGoogleMove, IsTpidGov, IsTpidEO
| join kind = inner
    ( Teams_Meetings_Health_Scores
        | where Date == currDate and Teams_MAU >= 1000
        | project OmsTenantId = tostring(TenantId), OmsMAU = Meetings_MAU
      | join kind = inner
        (StrategicTenants
            | where Date == stratTenantsMaxDate
        ) on OmsTenantId) on Tpid
| where isnotempty( OrgName_Translated)
| order by OrgName_Translated asc, OmsMAU desc
| project Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName_Translated, TpidStatus = "", OmsStatus = "",
IsS500 = iif(isempty(IsS500), IsTpidS500, IsS500), 
IsS2500 = iif(isempty(IsS2500), IsTpidS2500, IsS2500),
IsEPA = iif(isempty(IsEPA), IsTpidEPA, IsEPA),
IsGoogleMove = iif(isempty(IsGoogleMove), IsTpidGoogleMove, IsGoogleMove),
IsGov = iif(isempty(IsGov), IsTpidGov, IsGov),
IsEO = iif(isempty(IsEO), IsTpidEO, IsEO)
`;
