import React from "react";
import { PerformanceProps } from "pages/Performance/types";

// This module uses the useContext hook to hold all the values that has to be passed to the
// scenario charts for making Kusto API calls.
const context: PerformanceProps = {
    partitionBy: "",
    tenantId: "",
    level: "TenantId",
    valuesToShow: "TypicalDuration",
    isMonthlyView: true,
    endDate: "",
    startDate: "",
    // eslint-disable-next-line no-empty-pattern
    dispatcher: ({}) => {},
    legend: null,
};

const PerformanceContext = React.createContext<PerformanceProps>(context);
export default PerformanceContext;
