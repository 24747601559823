export const networkScore: {} = {
    monthly: ` // network score monthly
                NetworkScore
                    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                    | where \${levelColumnName} == "\${id}"
                    | project Date, Metric, Name, Value, Total
                    | order by Date asc`,

    daily: `
            // network score daily
            let currMonth = todatetime("\${date}");
            let endOfMonth = datetime_add('Day', 27, currMonth);    
            NetworkScore_Daily
                | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                | where \${levelColumnName} == "\${id}" 
                | where todatetime(Date) between (currMonth .. endOfMonth)
                | project Date, Metric, Name, Value, Total
                | order by Date asc`,
};
