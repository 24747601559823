import { fetchFunnelDataFromKusto } from "components/CommonFunnelComponents/CommonFunnel/ApiHandler";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import {
    DecompositionDataType,
    DecompositionInsight,
    DecompositionInsightsType,
} from "components/CommonFunnelComponents/CommonFunnelDecomposition/types";
import { FunnelQueryType, FunnelType } from "config/FunnelConfig";
import { KustoResponseType, MetricsResponseType } from "pages/commonTypes";

export const getFunnelDecomposition = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
    selectedStage: string,
): Promise<MetricsResponseType> => {
    if (selectedStage) {
        filters.additionalFilters["selectedStage"] = selectedStage;
    }

    const payload = await fetchFunnelDataFromKusto(
        filters,
        funnelType,
        FunnelQueryType.Decomposition,
    );

    return payload;
};

// Expected schema: Text, Stage, BetweenStageLossPctToStage, BetweenStageLossPctToGroup, MetricName
export const formatDecompositionData = (
    decompData: KustoResponseType<string | number>,
): DecompositionInsightsType => {
    const decompInsights: DecompositionInsightsType = {
        [DecompositionDataType.StageLossToStagePct]: [],
        [DecompositionDataType.StageLossToGroupPct]: [],
    };

    decompData.Tables[0].Rows.forEach((row) => {
        const metricName = row[4] as string;

        const insight: DecompositionInsight = {
            text: row[0] as string,
            stage: row[1] as number,
            stageLossToStage: row[2] as number,
            stageLossToGroup: row[3] as number,
        };

        decompInsights[metricName].push(insight);
    });

    return decompInsights;
};
