import React, { useEffect, useState } from "react";
import {
    IconButton,
    Icon,
    Stack,
    ScrollablePane,
    ScrollbarVisibility,
    DetailsList,
    IColumn,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import ConfirmationDialog from "components/ExemptionPortalComponents/Dialog/ConfirmationDialog";
import SuccessDialog from "components/ExemptionPortalComponents/Dialog/SuccessDialog";
import { NoteForm } from "components/ExemptionPortalComponents/NoteForm/NoteForm";
import { NotesCosmosDbAccess } from "api/CosmosDbAccess";
import { PlatformExemptionNote } from "components/ExemptionPortalComponents/types";
import { Workload, platformsConfig } from "config/PlatformConfig";
import { commonInnerBlock } from "pages/common";
import { getExemptedFields } from "utils/Helpers";
import { EXEMPTION_PORTAL_MSG } from "utils/Messages";
import { Filters } from "utils/WebConstants";

const displayColumns = [
    ["Date", "Date Added", 75, null],
    ["Tpid", "Tpid", 75, null],
    ["OmsTenantId", "TenantId", 75, null],
    ["IsExempted", "Tenant Exempted", 100, null],
    ["ExemptedFields", "Exempted Fields", 100, null],
    ["ExemptedUntil", "Exempted Until", 100, null],
    ["MovedToHealthyStatus", "Moved To Healthy Status", 125, null],
    ["Notes", "Engagement Notes", 300, null],
    ["AddedBy", "Added By", 75, null],
    ["LastUpdatedDate", "Last Updated Date", 75, null],
    ["LastUpdatedBy", "Last Updated By", 75, null],
    ["Platform", "Platform", 75, null],
];

const getproductInsightsPagePath = (item: PlatformExemptionNote) => {
    return platformsConfig[item.Platform].productInsightsPagePath;
};

export const ExemptionNoteTable = (props) => {
    const { payload, showPortalFeatures } = props;

    const [showForm, setShowForm] = useState(false);
    const initialNoteState = {
        Platform: Workload.WEB,
        IsExempted: false,
        Date: "",
        Tpid: "",
        OmsTenantId: "",
        IsArchived: false,
        AddedBy: "",
        LastUpdatedBy: "",
        LastUpdatedDate: "",
    };
    const [currentNote, setCurrentNote] =
        useState<PlatformExemptionNote>(initialNoteState);
    const [notes, setNotes] = useState(payload);
    const [hideArchiveDialog, setHideArchiveDialog] = useState(true);
    const [hideConfirmation, setHideConfirmation] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isNewNote, setIsNewNote] = useState(false);

    useEffect(() => {
        setNotes(props.payload);
    }, [props.payload]);

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columns, column, notes);
        setNotes(result.items);
        setColumns(result.columns);
    };

    const generateColumns = (displayColumns, showPortalFeatures) => {
        let columnsToDisplay = displayColumns;
        if (showPortalFeatures) {
            columnsToDisplay = [["Operations", "", 50, null], ...displayColumns];
        }
        const columns: IColumn[] = DetailsListHelper.customBuildColumns(
            columnsToDisplay,
            true,
            onColumnClick,
            "Date",
            true,
            true,
        );
        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

        return columns;
    };

    const [columns, setColumns] = useState(
        generateColumns(displayColumns, showPortalFeatures),
    );

    const closeForm = () => {
        props.refresh();
        setShowForm(!showForm);
    };

    const editTenantNote = (note: PlatformExemptionNote) => {
        setCurrentNote(note);
        setIsNewNote(false);
        setShowForm(true);
    };

    const archiveTenantNote = async () => {
        const temp = currentNote;
        temp["IsArchived"] = true;
        const resp = await new NotesCosmosDbAccess().updateExemptionNotes(temp);
        setSuccessMessage(
            resp
                ? EXEMPTION_PORTAL_MSG.NOTE_ARCHIVED
                : EXEMPTION_PORTAL_MSG.SOMETHING_WENT_WRONG,
        );
        setHideConfirmation(!hideConfirmation);
        props.refresh();
    };

    const addTenantNote = () => {
        setCurrentNote(initialNoteState);
        setIsNewNote(true);
        setShowForm(true);
    };

    const renderItemColumn = (
        item: PlatformExemptionNote,
        index: number,
        column: IColumn,
    ) => {
        const fieldContent = item[column.fieldName] as string;
        const productInsightsPagePath = getproductInsightsPagePath(item);

        switch (column.key) {
            case "Operations":
                return (
                    <div>
                        <IconButton
                            iconProps={{ iconName: "EditNote" }}
                            title="Edit Note"
                            ariaLabel="Edit Note"
                            onClick={() => editTenantNote(item)}
                        />
                        <IconButton
                            iconProps={{ iconName: "Archive" }}
                            title="Archive Note"
                            ariaLabel="Archive Note"
                            onClick={() => {
                                setHideArchiveDialog(!hideArchiveDialog);
                                setCurrentNote(item);
                            }}
                            data-testid={`archiveBtn${index}`}
                        />
                    </div>
                );
            case "Tpid":
                const tpidLink = `/${productInsightsPagePath}?rankOn=${
                    Filters.DefaultRankOn
                }&level=Tpid&id=${fieldContent.trim()}`;
                return (
                    <DetailsListHelper.LinkedTextValue
                        value={fieldContent}
                        link={tpidLink}
                    />
                );
            case "OmsTenantId":
                const level = fieldContent === "All" ? "Tpid" : "TenantId";
                const tenantLink = `/${productInsightsPagePath}?rankOn=${
                    Filters.DefaultRankOn
                }&level=${level}&id=${(level === "Tpid"
                    ? item.Tpid
                    : fieldContent
                ).trim()}`;
                return (
                    <DetailsListHelper.LinkedTextValue
                        value={fieldContent}
                        link={tenantLink}
                    />
                );
            case "ExemptedFields":
                return (
                    <DetailsListHelper.TextValue value={getExemptedFields(item)} />
                );
            case "IsExempted":
            case "MovedToHealthyStatus":
                return (
                    <DetailsListHelper.TextValue
                        value={fieldContent ? "Yes" : "No"}
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    return (
        <>
            <h2 className="commonPageBlockTitle">Tenant Notes/Exemptions</h2>
            {showPortalFeatures && (
                <div
                    className="addIcon"
                    onClick={() => addTenantNote()}
                    data-testid="addExemptionNoteIcon"
                >
                    <Icon iconName="Add" className="addIconSymbol" />
                    <div style={{ fontSize: "13px", color: "gray" }}>Add Note</div>
                </div>
            )}
            {payload && payload.length > 0 ? (
                <Stack styles={commonInnerBlock}>
                    <div data-is-scrollable className="pageSection">
                        <ScrollablePane
                            scrollbarVisibility={ScrollbarVisibility.auto}
                        >
                            <DetailsList
                                items={notes}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                onRenderRow={DetailsListHelper.renderExpandableRow}
                                onRenderItemColumn={renderItemColumn}
                                onRenderDetailsHeader={
                                    DetailsListHelper.renderDetailsHeader
                                }
                                styles={DetailsListHelper.tenantTableStyle}
                                className="tenantTable"
                            />
                        </ScrollablePane>
                    </div>
                </Stack>
            ) : (
                <EmptyTableContainer />
            )}
            {showPortalFeatures && (
                <>
                    <NoteForm
                        showForm={showForm}
                        handleClose={closeForm}
                        note={currentNote}
                        isNewNote={isNewNote}
                        refresh={props.refresh}
                        setHide={setHideConfirmation}
                        setSuccessMessage={setSuccessMessage}
                    />
                    <ConfirmationDialog
                        hide={hideArchiveDialog}
                        setHide={setHideArchiveDialog}
                        action={archiveTenantNote}
                        message={EXEMPTION_PORTAL_MSG.NOTE_CONFIRMATION}
                    />
                    <SuccessDialog
                        hide={hideConfirmation}
                        setHide={setHideConfirmation}
                        message={successMessage}
                    />
                </>
            )}
        </>
    );
};
