import React from "react";
import { Shimmer } from "@fluentui/react";

const GrowthDrilldownTitle = ({
    segment,
    loading,
}: {
    segment: string;
    loading: boolean;
}) => {
    return loading ? (
        <div>
            <Shimmer className="customerTitleShimmerHeader" />
            <Shimmer className="customerTitleShimmerBody" />
        </div>
    ) : (
        <div className="tenantTitleWrapper">
            <span>
                <span id="tenantName"> Consumer Growth Drilldown </span>
                <br />
                <span
                    style={{
                        fontSize: "14px",
                        paddingRight: "15px",
                    }}
                >
                    {`Segment: ${segment}`}
                </span>
            </span>
        </div>
    );
};

export default GrowthDrilldownTitle;
