export const ERFMColumns: {
    name: string;
    tooltip?: string;
    displayName: string;
    className?: string;
    minWidth?: number;
    isFlyoutMetric?: boolean;
}[] = [
    {
        name: "Segment",
        displayName: "Segment",
        className: "alignLeftHeader",
    },
    {
        name: "MAU",
        tooltip: "Monthly Active Users",
        displayName: "MAU",
        isFlyoutMetric: true,
    },
    {
        name: "PercentCAMAU",
        tooltip: "Percent Core Action Monthly Active Users",
        displayName: "CAMAU %",
        isFlyoutMetric: true,
    },
    {
        name: "M1",
        tooltip: "Month 1 Retention",
        displayName: "M1",
        isFlyoutMetric: true,
    },
    {
        name: "M2",
        tooltip: "Month 2 Retention",
        displayName: "M2",
        isFlyoutMetric: true,
    },
    {
        name: "M3",
        tooltip: "Month 3 Retention",
        displayName: "M3",
        isFlyoutMetric: true,
    },
    {
        name: "PercentConvertedUsers",
        displayName: "% Users Who Buy M365",
        isFlyoutMetric: true,
    },
    {
        name: "UAECrashRate",
        tooltip: "Ungraceful App Exit Crash Rate",
        displayName: "UAE Crash Rate",
        minWidth: 125,
    },
    {
        name: "WatsonCrashRate",
        displayName: "Watson Crash Rate",
        minWidth: 130,
    },
];
