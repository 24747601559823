import React from "react";
import {
    ChoiceGroup,
    Dropdown,
    IDropdownOption,
    Label,
    Stack,
} from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import {
    platformOptions,
    audienceGroupOptions,
    office365CategoryOptions,
    cadenceOptions,
    cohortOptions,
} from "components/AIScorecardComponents/AIScorecardConstants";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import { AIScorecardFiltersPropsType } from "components/AIScorecardComponents/types";
import { DatePicker } from "components/CustomComponents/DatePicker";
import {
    defaultLargeDropDownWidth,
    dropdownStyles,
    wideDropdownStyles,
} from "utils/Constants";

const AIScorecardFilters = ({
    dates,
    filters,
    onFilterChange,
}: AIScorecardFiltersPropsType) => {
    const dateOptions: IDropdownOption[] = [];
    dates.map((x) => dateOptions.push({ key: x, text: x }));

    return (
        <Stack horizontal className="filterPanel copilotFilter">
            <div>
                <Stack horizontal>
                    <ChoiceGroup
                        defaultSelectedKey={filters.cadence}
                        options={cadenceOptions}
                        onChange={(event, option) => {
                            sendAIScorecardUserActionTelemetryEvent(
                                {
                                    userActionName: "CadenceFilterClick",
                                    commandSurface:
                                        UserActionCommandSurface.FilterBar,
                                },
                                { CadenceChosen: option.key as string },
                            );
                            onFilterChange("cadence", option.key as string);
                        }}
                        required={true}
                        styles={{
                            flexContainer: {
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                marginTop: "-8px",
                            },
                        }}
                    />
                </Stack>
            </div>
            <div>
                <DatePicker
                    dateOptions={dateOptions}
                    onChangeSelection={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption<any>,
                    ) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName: "DateFilterChange",
                                commandSurface: UserActionCommandSurface.FilterBar,
                            },
                            { DateChosen: option.key as string },
                        );
                        onFilterChange("date", option.key as string);
                    }}
                    selected={filters.date}
                    shouldFormat={false}
                />
            </div>
            <div>
                <Label className="filterLabel">Platform:</Label>
                <Dropdown
                    placeholder="Select an option"
                    options={platformOptions}
                    selectedKey={filters.platform}
                    styles={dropdownStyles}
                    onChange={(event, option) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName: "PlatformFilterChange",
                                commandSurface: UserActionCommandSurface.FilterBar,
                            },
                            { PlatformChosen: option["key"] as string },
                        );
                        onFilterChange("platform", option["key"] as string);
                    }}
                    className="filterDropdown"
                />
            </div>
            <div>
                <Label className="filterLabel">Audience Group:</Label>
                <Dropdown
                    placeholder="Select an option"
                    options={audienceGroupOptions}
                    selectedKey={filters.audienceGroup}
                    styles={dropdownStyles}
                    onChange={(event, option) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName: "AudienceGroupFilterChange",
                                commandSurface: UserActionCommandSurface.FilterBar,
                            },
                            { AudienceGroupChosen: option["key"] as string },
                        );
                        onFilterChange("audienceGroup", option["key"] as string);
                    }}
                    className="filterDropdown"
                />
            </div>
            <div>
                <Label className="filterLabel">User Segment:</Label>
                <Dropdown
                    placeholder="Select an option"
                    options={office365CategoryOptions}
                    selectedKey={filters.office365Category}
                    styles={wideDropdownStyles}
                    onChange={(event, option) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName: "UserSegmentFilterChange",
                                commandSurface: UserActionCommandSurface.FilterBar,
                            },
                            { UserSegmentChosen: option["key"] as string },
                        );
                        onFilterChange("office365Category", option["key"] as string);
                    }}
                    className="filterDropdown"
                    dropdownWidth={defaultLargeDropDownWidth}
                />
            </div>
            <div>
                <Label className="filterLabel">Cohort:</Label>
                <Dropdown
                    placeholder="Select an option"
                    options={cohortOptions}
                    selectedKey={filters.cohort}
                    styles={dropdownStyles}
                    onChange={(event, option) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName: "CohortFilterChange",
                                commandSurface: UserActionCommandSurface.FilterBar,
                            },
                            { CohortChosen: option["key"] as string },
                        );
                        onFilterChange("cohort", option["key"] as string);
                    }}
                    className="filterDropdown"
                />
            </div>
        </Stack>
    );
};

export default AIScorecardFilters;
