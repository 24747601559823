import React, { useEffect, useState } from "react";
import {
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    App,
    BootScenario,
    Dimension,
    PerfTableData,
} from "pages/Performance/types";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents } from "utils/AppInsightsHelper";

const constants = {
    TEXT_TYPE_WIDTH: 70,
    LONG_TEXT_WIDTH: 120,
    INTEGRAL_TYPE_WIDTH: 60,
    LONG_INTEGRAL_TYPE_WIDTH: 120,
};

const Win32PerfTableStyle = {
    root: {
        height: 250,
        width: "max-content",
    },
};

const _generateDisplayColumns = () => {
    // Key, fieldName are set to the first item in the array.
    // name is set to second item in the array.
    return [
        ["DimensionName", "Dimension", constants.LONG_TEXT_WIDTH, null],
        ["DimensionValue", "Value", constants.LONG_TEXT_WIDTH, null],
        ["P95Duration", "P95", constants.INTEGRAL_TYPE_WIDTH, null],
        [
            "PercentBelowThreshold",
            "% Below Threshold",
            constants.LONG_INTEGRAL_TYPE_WIDTH,
            null,
        ],
        ["Scenario", "Scenario", constants.LONG_TEXT_WIDTH, null],
        ["EventCount", "Events", constants.INTEGRAL_TYPE_WIDTH, null],
        ["DeviceCount", "Devices", constants.INTEGRAL_TYPE_WIDTH, null],
    ];
};

export default function Win32PerformanceInsightsTable({
    app,
    payload,
    loading,
    scenario,
    onRowSelectHandler,
}: {
    app: App;
    payload: PerfTableData[];
    loading: boolean;
    scenario: BootScenario;
    onRowSelectHandler: (
        app: App,
        dimensionName: Dimension,
        scenario: BootScenario,
    ) => void;
}) {
    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columns, column, items);
        setItems(result.items);
        setColumns(result.columns);
    };

    const _generateColumns = () => {
        // Key, fieldName are set to the first item in the array.
        // name is set to second item in the array.
        const columns: IColumn[] = DetailsListHelper.customBuildColumns(
            _generateDisplayColumns(),
            true,
            _onColumnClick,
            "P95Duration",
            true,
        );

        return columns;
    };

    const [items, setItems] = useState(payload);
    const [columns, setColumns] = useState(_generateColumns());

    useEffect(() => {
        setItems(payload);
        setColumns(_generateColumns());
        // ADO 7955411: useCallback refactor on _generateColumns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload]);

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "DimensionName":
                return (
                    <DetailsListHelper.TextValue value={fieldContent as string} />
                );
            case "DimensionValue":
                return (
                    <DetailsListHelper.TextValue value={fieldContent as string} />
                );
            case "EventCount":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent as Number}
                        postfix=""
                    />
                );
            case "PercentBelowThreshold":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent as Number}
                        postfix="%"
                    />
                );
            case "P95Duration":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent as Number}
                        decimalPrecision={1}
                        postfix="s"
                    />
                );
            case "DeviceCount":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent as Number}
                        postfix=""
                    />
                );

            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    const _onRenderRow = (rowProps: IDetailsRowProps | undefined): JSX.Element => {
        return (
            <div className="metricExpand">
                <DetailsRow {...rowProps} />
            </div>
        );
    };

    const _onItemSelected = (item?: any) => {
        onRowSelectHandler(app, item.DimensionName, item.Scenario);
        AppInsights.getInstance().LogEvent(appInsightsCustomEvents.PerformanceView, {
            type: "UIControl",
            event: "Tier2TableRowSelected",
            value: app,
            scenario: scenario,
        });
    };

    return (
        <div data-is-scrollable>
            <ShimmeredDetailsListWrapper
                message={"No results above baseline."}
                items={items}
                columns={columns}
                onRenderRow={_onRenderRow}
                onRenderItemColumn={_renderItemColumn}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick={true}
                setKey="none"
                onActiveItemChanged={_onItemSelected}
                onColumnHeaderClick={_onColumnClick}
                compact
                styles={Win32PerfTableStyle}
                enableShimmer={!loading}
            />
        </div>
    );
}
