import { SignalTrendFilterConstants } from "pages/MTR/types";
import {
    signalTrendPlatformOptions,
    signalTrendGroupOptions,
    signalTrendWebAppOptions,
    signalTrendWin32AppOptions,
    signalTrendWebSignalOptions,
    signalTrendWin32SignalOptions,
    signalTrendWebCohortOptions,
    signalTrendWin32CohortOptions,
    signalTrendColorOptions,
} from "utils/Constants";

export const filterConstants: SignalTrendFilterConstants[] = [
    {
        label: "Platform:",
        options: signalTrendPlatformOptions,
        item: "platform",
        platformSpecific: false,
        isComboBox: false,
    },
    {
        label: "Group:",
        options: signalTrendGroupOptions,
        item: "group",
        platformSpecific: false,
        isComboBox: false,
    },
    {
        label: "App:",
        options: signalTrendWebAppOptions || signalTrendWin32AppOptions,
        item: "app",
        platformSpecific: true,
        isComboBox: true,
    },
    {
        label: "Signal:",
        options: signalTrendWebSignalOptions || signalTrendWin32SignalOptions,
        item: "signal",
        platformSpecific: true,
        isComboBox: true,
    },
    {
        label: "Cohort:",
        options: signalTrendWebCohortOptions || signalTrendWin32CohortOptions,
        item: "cohort",
        platformSpecific: true,
        isComboBox: false,
    },
    {
        label: "Color:",
        options: signalTrendColorOptions,
        item: "color",
        platformSpecific: false,
        isComboBox: false,
    },
];
