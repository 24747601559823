export const webconsumerCustomerAppMetrics: string = `
// webconsumerCustomerAppMetrics
let dt = "\${date}";
let cohortType = "\${cohortType}";
let cohortName = "\${cohortName}";
let pivotName = "All";
let host = "\${host}";
ConsumerMetrics
        | where Host == host and Date == dt and CohortType == cohortType and CohortName  == cohortName and PivotName == pivotName
        |  join kind=inner 
                (database('TenantInsights').MetricMapping) // TODO: update this to add new metrics or create a new one for consumer
                on Metric
        | project Date, Metric, DisplayName, Group, Application, Value = iff(Application =="WXP" and Group in ("Score","Reliability/ASHA","Performance"),real(null),Value)
        | extend GroupOrder = database('TenantInsights').GetMetricGroupOrder(Group), MetricOrder = database('TenantInsights').GetMetricOrder(Metric)
        | extend Value = iff(isnull( Value), "NA", tostring(Value))
        | order by GroupOrder asc, MetricOrder asc, DisplayName asc
        | project-away MetricOrder, GroupOrder
`;

export const webconsumerCustomerAppMetricsChart: string = `
//webconsumerCustomerAppMetricsChart
let metric = "\${metric}";
let cohortType = "\${cohortType}";
let cohortName = "\${cohortName}";
let pivotName = "All";
let host = "\${host}";
ConsumerMetrics
        | where Host == host and CohortType == cohortType and CohortName  == cohortName and PivotName == pivotName and Metric == metric
        | project Date, Application, Value= round(Value,2)
        | order by Date asc
`;
