export const webTenantEnvironmentData: string = `
// Web Miscellaneous Metrics
let DcData = MiscellaneousMetrics
| where Metric == "WacDatacenter"
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" and Date == "\${date}" 
| join kind=inner (
    cluster('ehfuelinsights.westus2.kusto.windows.net').database('TenantInsights').ARCA_Topology 
)
on $left.Name == $right.Datacenter
| project Value, Metric, Application,Total, Country, Continent;
DcData
| where Application != "WXP"
| summarize Value = sum(Value) by Continent
| project Metric = "Continent", Application="All", Name= Continent, Value
| union ( // data for continent for current month
    DcData
    | summarize Value = sum(Value) by Metric, Application, Total, Country
    | project Metric, Application, Name=Country, Value, Total
)
| union(
    MiscellaneousMetrics
    | where Metric == "UserFrequency"
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Date == "\${date}" 
    | project Metric="OpenDocument", Application, Name, Value, Total
)
| union ( // data for geo distribution for current month
    MiscellaneousMetrics
    | where Metric !in ("WacDatacenter", "OS", "Browser", "UserFrequency") 
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Date == "\${date}" 
    | project Metric, Application, Name, Value, Total
)
| union ( // data for OS, Browser, License Charts for all months
    MiscellaneousMetrics
    | where Metric in ("OS", "Browser")
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}"
    | project Metric, Application, Name, Value, Total, Date
    | union (
        License 
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == "\${id}"
        | project Metric, Application, Name, Value, Total, Date
    )
)
| project Date, OmsTenantId="dummy" , Tpid=0, Application, Metric, Name, Value, Total
| order by Date asc, Metric, Application, Value`;
