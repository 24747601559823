import React from "react";
import { IDropdownOption } from "@fluentui/react";
import { computeQueryParams } from "utils/Helpers";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
} from "utils/PlgTelemetryLogger";
import { defaultApp, paramsToLook } from "./CohortAnalysisConstants";
import {
    CohortAnalysisErrorDrillDownDataType,
    CohortAnalysisFilters,
    CohortAnalysisQueryParams,
    CohortAnalysisReliabilityDataType,
    filterChangeChainType,
} from "./types";

export const appOptions: IDropdownOption[] = [];

export const handleFilterChange = (
    filters: CohortAnalysisFilters,
    queryParams: CohortAnalysisQueryParams,
    filterToChange: string,
    newFilterValue: string,
    filterChangeChain: filterChangeChainType[],
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    const filterChangeOption = filterChangeChain.filter(
        (x) => x.filterName === filterToChange,
    )[0];

    filters[filterToChange] = newFilterValue;
    filterChangeOption.filterChain.forEach((param) => {
        filters[param] = null;
        queryParams[param] = null;
    });

    queryParams = {};
    setFilters(() => {
        return {
            ...filters,
        };
    });

    sendFiltersChangeTelemetryEvent(flattenFilters(filters));
};

export const getFiltersFromQueryParams = (
    filters: CohortAnalysisFilters,
    queryParams: CohortAnalysisQueryParams,
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    paramsToLook.forEach((param) => {
        if (queryParams[param]) {
            filters[param] = queryParams[param];
        }
    });

    // We always want to set the level and app in the filters to kick off the chain of useDidMounts
    filters["level"] = "TenantId";
    filters["app"] = filters["app"] ?? (appOptions[0]?.key as string) ?? defaultApp;

    setFilters((previouslySetFilters) => {
        return {
            ...previouslySetFilters,
            ...filters,
        };
    });
};

export const changeURLParams = (
    filters: CohortAnalysisFilters,
    queryParams: CohortAnalysisQueryParams,
) => {
    paramsToLook.forEach((param) => {
        if (filters[param]) {
            queryParams[param] = filters[param];
        }
    });

    window.history.pushState(
        "",
        "",
        window.location.pathname + computeQueryParams(queryParams),
    );
};

export const getCurrentlySelectedTag = (
    selectedOption: string,
    selectedFromDashboard: string,
) => {
    if (selectedOption === selectedFromDashboard) {
        return (
            <span style={{ color: "#03C07B", fontSize: "13px" }}>
                {" "}
                [currently selected]{" "}
            </span>
        );
    }
    return (
        <span style={{ color: "#8E9092", fontSize: "13px" }}>
            {" "}
            [click to select]{" "}
        </span>
    );
};

export const getCellColor = (
    entry: CohortAnalysisReliabilityDataType | CohortAnalysisErrorDrillDownDataType,
): string => {
    if (entry.ACE_OR > 2) {
        return "#BB4A56";
    }
    return "#00D084";
};

export const getCellStroke = (
    entry: CohortAnalysisReliabilityDataType | CohortAnalysisErrorDrillDownDataType,
    currentlySelected: string,
): string => {
    if (entry.Name === currentlySelected) {
        return "#525252";
    }
};

export const getTopNTenantsByField = (
    parentArray: any[],
    orderBy: string,
    topNToReturn: number,
    descendingOrder: boolean = true,
): any[] => {
    if (descendingOrder) {
        return parentArray
            ?.sort((a, b) => {
                return b[orderBy] - a[orderBy];
            })
            .slice(0, topNToReturn);
    }

    const arrayLength = parentArray.length;
    const startIndex = arrayLength < topNToReturn ? 0 : arrayLength - topNToReturn;
    return parentArray
        ?.sort((a, b) => {
            return a[orderBy] - b[orderBy];
        })
        .slice(startIndex, arrayLength);
};

export const customToolTipContentForScatterChart = (props) => {
    const { payload, currentlySelectedError } = props;
    const data = payload[0].payload;
    return (
        <span>
            <span className="customTooltipHeader">
                <span className="customTooltipTitle">Error Name: </span>
                {data["Name"]}
                <span className="customTooltipSelectionHelper">
                    {getCurrentlySelectedTag(data["Name"], currentlySelectedError)}
                </span>
            </span>
            <br />
            <br />
            <span className="customTooltipMessage">
                This error is <b>{data["ACE_OR"]}</b> times more likely to happen in
                this cohort than other errors.
                <br /> Number of sessions encountering this error:{" "}
                <b>{data["SessionErrorCount"]}</b>
                <br />
                <br />
                The metric to measure this error's normalized impact on the cohort
                (Impact Score) is: <b>{data["Score"]}</b> <br />
                (This is the size of the bubble. Bigger the bubble, the more impact
                the error has on the Cohort)
            </span>
        </span>
    );
};

export const customToolTipContentForDataComposedChart = (props) => {
    const { payload, currentlySelectedCohort } = props;
    const data = payload[0].payload;
    return (
        <span>
            <span className="customTooltipHeader">
                <span className="customTooltipTitle">Cohort Name: </span>
                {data["Name"]}
                <span className="customTooltipSelectionHelper">
                    {getCurrentlySelectedTag(data["Name"], currentlySelectedCohort)}
                </span>
            </span>
            <br />
            <br />
            <span className="customTooltipMessage">
                This cohort is <b>{data["ACE_OR"]}</b> times more likely to have ACE
                Errors than other cohorts (This is the <i>odds ratio</i>. Bar chart).
                <br /> The score calculated by normalizing this odds ratio by the
                size of this cohort is: <b>{data["ACE_IS"]} </b>(This is the{" "}
                <i>Impact Score</i>. Line Chart)
                <br />
                <br />
                Impact score helps us compare the cohorts in a more equitable way
                (the odds ratio could be high because of small cohort size)
            </span>
        </span>
    );
};
