import React from "react";
import {
    IconButton,
    DetailsList,
    IColumn,
    DetailsRow,
    IDetailsRowProps,
    IDetailsGroupRenderProps,
    SelectionMode,
    ShimmeredDetailsList,
    ConstrainMode,
} from "@fluentui/react";
import "./style.css";
import {
    renderDetailsHeader,
    TextValue,
} from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { platformsConfig } from "config/PlatformConfig";
import { detailsRowStyle } from "pages/common";
import { STATUS } from "utils/WebConstants";

const TenantDetailsList = (props) => {
    const {
        loading,
        columns,
        tenantListItems,
        platform,
        tpidGroup,
        isCompactMode,
        showTenantStatus,
        onHeaderRowClick,
        onDetailsRowClick,
    } = props;

    const renderItemColumn = (item, index: number, column: IColumn) => {
        const { fieldName } = column;
        if (fieldName === "OmsStatus")
            return (
                showTenantStatus && (
                    <StatusIndicator
                        color={STATUS[item["OmsStatus"]]?.["color"]}
                        id="customerListStatus"
                    />
                )
            );
        return <TextValue class="customerListOrgName" value={item["TenantName"]} />;
    };

    const onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        return (
            <div
                className="customerListRow"
                onClick={() => onDetailsRowClick(props)}
            >
                <DetailsRow {...props} styles={detailsRowStyle} compact />
            </div>
        );
    };

    const onToggleCollapse = (props) => {
        return () => {
            if (!props.group.isEmptyGroup) props!.onToggleCollapse!(props!.group!);
        };
    };

    const getTenantListHeaderRowStyle = () => {
        if (platformsConfig[platform] && !platformsConfig[platform]?.showTpidData) {
            return "noPointerTenantListHeaderRow";
        }
        return "tenantListHeaderRow";
    };

    const onRenderGroupHeader: IDetailsGroupRenderProps["onRenderHeader"] = (
        props,
    ) => {
        return (
            <div className="tenantGroupHeader">
                <span onClick={onToggleCollapse(props)}>
                    {props.group!.isCollapsed ? (
                        <IconButton
                            iconProps={{ iconName: "ChevronRight" }}
                            title="Expand"
                            className="tenantListChevron"
                        />
                    ) : (
                        <IconButton
                            iconProps={{ iconName: "ChevronDown" }}
                            title="Collapse"
                            className="tenantListChevron"
                        />
                    )}
                </span>
                <span
                    className={getTenantListHeaderRowStyle()}
                    onClick={() => {
                        onHeaderRowClick(props);
                    }}
                >
                    {platformsConfig[platform]?.showTpidData && (
                        <StatusIndicator
                            color={STATUS[props.group["status"]]?.["color"]}
                            id="customerListStatus"
                        />
                    )}
                    <span style={{ width: "20px" }} />
                    <TextValue
                        class="customerListOrgName"
                        value={props.group["name"]}
                    />
                </span>
            </div>
        );
    };

    return (
        <div>
            {loading ? (
                <ShimmeredDetailsList
                    items={[]}
                    constrainMode={ConstrainMode.unconstrained}
                    enableShimmer={true}
                    shimmerLines={25}
                    columns={columns}
                    compact
                    onRenderDetailsHeader={renderDetailsHeader}
                    onRenderItemColumn={renderItemColumn}
                    onRenderRow={onRenderRow}
                    selectionMode={SelectionMode.none}
                />
            ) : (
                <DetailsList
                    items={tenantListItems.filter((i) => i.Show)}
                    groups={
                        (platformsConfig?.platform?.showTpidData
                            ? platformsConfig[platform].showTpidData
                            : true) && tpidGroup
                    }
                    columns={columns}
                    compact={isCompactMode}
                    onRenderDetailsHeader={renderDetailsHeader}
                    onRenderItemColumn={renderItemColumn}
                    onRenderRow={onRenderRow}
                    selectionMode={SelectionMode.none}
                    groupProps={{
                        onRenderHeader: onRenderGroupHeader,
                        showEmptyGroups: true,
                        isAllGroupsCollapsed: true,
                    }}
                />
            )}
        </div>
    );
};

export default TenantDetailsList;
