import * as API from "api/index";
import { Workload } from "config/PlatformConfig";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

export const executeQuipSurveyQuery = async (
    queryName: string,
    queryParams?: any,
    flatMap = true,
) => {
    try {
        if (!queryParams) {
            queryParams = {};
        }

        queryParams["tableName"] = "WAC_FG2_0_SurveyMetrics_AllUp";

        const appPlatformResponse = await API.getKustoResponse({
            queryName: queryName,
            cluster: "Default",
            platform: Workload.QUIP,
            queryParams,
        });

        return flatMap
            ? appPlatformResponse?.data?.Tables[0]?.Rows.flatMap((date) => date)
            : appPlatformResponse?.data?.Tables[0]?.Rows;
    } catch (e) {
        logException(
            new TenantInsightsException(Severity.SEV3, "QuIP Survey Query Failed"),
            {
                message: `Failed to fetch QuIP Floodgate 2.0 survey funnel data for query: ${queryName}.`,
            },
            e,
        );
    }
};
