export const webOfficeTenantScore: string = `
// WebOfficeTenantScore - over all office score for tenant
let currMonthdate = "\${date}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
Score_WXP
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == currMonthdate and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All"
| project WXP_Score, Status = WXP_Score_Color, Word_Contribution, Excel_Contribution, PPT_Contribution, OmsTenantId
| join kind=leftouter
    (Score_WXP
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date==prev and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All" | project OmsTenantId, prevMonthScore=WXP_Score
    ) on OmsTenantId
| join kind=leftouter
    (PastStatus
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == currMonthdate and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All" | project PastStatusSet, OmsTenantId
    ) on OmsTenantId
| extend
Word_Contribution = round(Word_Contribution*100,2),
Excel_Contribution = round(Excel_Contribution *100,2),
PPT_Contribution = round(PPT_Contribution*100,2)
| extend
HealthScoreContribution = tostring(bag_pack("Word", Word_Contribution, "Excel", Excel_Contribution, "PowerPoint", PPT_Contribution))
| project Score=round(WXP_Score,2), Status, Score_MoMChange = round(WXP_Score- prevMonthScore,2),PastStatus = strcat_array(GetPastYearStatus(PastStatusSet, currMonthdate),";"), HealthScoreContribution`;

export const webOfficeTenantScore_V2: string = `
// WebOfficeTenantScore - over all office score for tenant
let currMonthdate = "\${date}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
Score_WXP_V2
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == currMonthdate and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All"
| project WXP_Score, Status = WXP_Score_Color, Word_Contribution, Excel_Contribution, PPT_Contribution, OmsTenantId
| join kind=leftouter
    (Score_WXP_V2
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date==prev and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All" | project OmsTenantId, prevMonthScore=WXP_Score
    ) on OmsTenantId
| join kind=leftouter
    (PastStatus
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == currMonthdate and \${levelColumnName} == "\${id}" and Application == "WXP" and UiHost == "All" | project PastStatusSet, OmsTenantId
    ) on OmsTenantId
| extend
Word_Contribution = round(Word_Contribution*100,2),
Excel_Contribution = round(Excel_Contribution *100,2),
PPT_Contribution = round(PPT_Contribution*100,2)
| extend
HealthScoreContribution = tostring(bag_pack("Word", Word_Contribution, "Excel", Excel_Contribution, "PowerPoint", PPT_Contribution))
| project Score=round(WXP_Score,2), Status, Score_MoMChange = round(WXP_Score- prevMonthScore,2),PastStatus = strcat_array(GetPastYearStatus(PastStatusSet, currMonthdate),";"), HealthScoreContribution`;
