import {
    AdvisoryMetricType,
    AdvisoryTrend,
    AdvisoryTypeV2,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";

export const advisoriesResourceStrings = {
    [AdvisoryMetricType.CrashRate]: {
        [AdvisoryTrend.Improvement]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "%s % crash rate decrease",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "Crash rate is %s % under the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the app session crash rate of %s version %s, build %s had improved compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the app session crash rate of %s version %s, build %s was better than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
        [AdvisoryTrend.Degradation]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "Crash rate has increased by %s% compared to the previous day",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "Crash rate is %s% over the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "In the %s, the app session crash rate of %s version %s, build %s has deteriorated compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the app session crash rate of %s version %s, build %s was worse than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
    },
    [AdvisoryMetricType.BootTime]: {
        [AdvisoryTrend.Improvement]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "%s seconds boot time decrease",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "Boot time is %s seconds under the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the app boot time of %s version %s, build %s had improved compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the app boot time of %s version %s, build %s was better than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
        [AdvisoryTrend.Degradation]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "Boot time has incresed by %s seconds compared to the previous day",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "App boot time has worsened by %s seconds over the last seven days",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the app boot time of %s version %s, build %s had deteriorated compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the app boot time of %s version %s, build %s has been deteriorating over the last 7 days.",
            },
        },
    },
    [AdvisoryMetricType.FileLocalOpenTime]: {
        [AdvisoryTrend.Improvement]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "%s seconds local file open time decrease",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "Local file open time is %s seconds under the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open local files in %s version %s, build %s had improved compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open local files in %s version %s, build %s was better than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
        [AdvisoryTrend.Degradation]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "Local file open time has increased by %s seconds compared to the previous day",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "Local file open time is %s seconds over the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open local files in %s version %s, build %s had deteriorated compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open local files in %s version %s, build %s was worse than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
    },
    [AdvisoryMetricType.FileSharePointOpenTime]: {
        [AdvisoryTrend.Improvement]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "%s seconds SharePoint file open time decrease",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "SharePoint file open time is %s seconds under the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open Sharepoint files in %s version %s, build %s had improved compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open Sharepoint files in %s version %s, build %s was better than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
        [AdvisoryTrend.Degradation]: {
            Message: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "SharePoint file open time has increased by %s compared to the previous day",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "SharePoint file open time is %s seconds over the build with most active devices",
            },
            Description: {
                [AdvisoryTypeV2.MetricOutOfRangeSameBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open Sharepoint files in %s version %s, build %s had deteriorated compared to the previous period.",
                [AdvisoryTypeV2.MetricOutOfRangeBaselineBuild]:
                    "At the time of this advisory, in the %s, the time it takes to open Sharepoint files in %s version %s, build %s was worse than what most devices were experiencing (most devices in this channel using version %s, build %s).",
            },
        },
    },
};

export const DateResourceStrings = {
    OneDayAgo: "1 day ago",
    NDaysAgo: "%d days ago",
};

export const AdvisoriesRecommendedActions = {
    Header: "Recommended Solution",
    BootTime:
        "We recommend moving all the devices with this build to the latest build in Semi-Annual channel.",
    UnsupportedBuild:
        "This build is unsupported. We recommend you moving this devices to the latest available build in %s.",
    SupportedOlder:
        "We recommend pausing the deployment of this build and validate impact with users that already have it installed. If problems persist, consider moving business critical devices from this build, %s, to the build with most active devices in your tenant, %s.",
    Supported:
        "We recommend evaluating recent changes in your environment (such as add-in, macro, antivirus, or hardware updates) on devices with this build %s.",
};

export const AdvisoriesMetadataTitles = {
    Product: "Application",
    Metric: "Metric",
    Severity: "Type",
    Devices: "Impacted devices",
    DeteriotedBuid: "Deteriorated build",
    BaselineBuild: "Build with most devices",
};

export const AdvisoriesMetricNameResolver = {
    [AdvisoryMetricType.CrashRate]: "App session crash rate",
    [AdvisoryMetricType.BootTime]: "App boot time (sec.)",
    [AdvisoryMetricType.FileLocalOpenTime]: "Local file open time (sec.)",
    [AdvisoryMetricType.FileSharePointOpenTime]: "Sharepoint file open time (sec.)",
    [AdvisoryMetricType.Unknown]: "Unknown",
};

// all other messages that are too random or less in numbers to group
export const AdvisoriesFlyoutDetails = {
    LatestUpdated: "at latest update",
    Deterioration: "at deterioration",
    SameBuildMetricDeltaNote: "%s over the previous period",
    BaselineBuildMetricDeltaNote: "%s over most active build",
    DeteriotedMetricDetailsHeader: "Deteriorated metric details",
    ThisAdvisory: "This Advisory",
    LearnMorePerfAdvisories: "Learn more about performance advisories",
    LatestAdvisory:
        "There is more recent advisory for this app, channel, build and metric.",
    SeeLatestAdvisory: "See latest advisory",
};

export const AdvisoriesMetricChartHeaders = {
    [AdvisoryMetricType.CrashRate]: "App session crash rate (%) trends",
    [AdvisoryMetricType.BootTime]: "App boot time (seconds) trends",
    [AdvisoryMetricType.FileLocalOpenTime]:
        "App file local open time (seconds) trends",
    [AdvisoryMetricType.FileSharePointOpenTime]:
        "App SharePoint open time (seconds) trends",
    [AdvisoryMetricType.Unknown]: "Unknown",
};

export const AddinsAnnotationText = {
    All: "Total add-ins",
    Crash: "Add-ins with high crash rates",
    Load: "Add-ins with high load time",
};

export const AddinsMetadataTitles = {
    Product: "Application(s)",
    Publisher: "Publisher",
    Versions: "Version count",
    Sessions: "Session count",
    Devices: "Monitored devices",
    AddinProgId: "Add-in Prog Id",
};

export const MILLISECONDS_IN_ONE_SECOND: number = 1000;
export const SECONDS_IN_ONE_MONTH: number = 60 * 60 * 24 * 30;
export const SECONDS_IN_ONE_DAY: number = 60 * 60 * 24;
export const DefaultFilterValue = "All";
export const SearchLabel = "Search";
export const NO_DATA_SEARCH_RESULTS = "No Results found";
export const DEFAULT_DATE = "0001-01-01T00:00:00Z";
export const NO_DATA_LOADTIME = "Not enough data";
