import { tenantAllAddInCostHistory } from "queries/PerfDrillDown/Win32/AddIns/tenantAllAddInCostHistory";
import { tenantTopAddInsPerformanceQuery } from "queries/PerfDrillDown/Win32/AddIns/tenantTopAddInsPerformanceQuery";
import { tenantAddInHistory } from "queries/PerfDrillDown/Win32/AddIns/tenantAddInHistory";
import { tenantAddInOptions } from "queries/PerfDrillDown/Win32/AddIns/tenantAddInOptions";
import { tenantAddInVersionHistory } from "queries/PerfDrillDown/Win32/AddIns/tenantAddInVersionHistory";
import { tenantTypicalAddInCost } from "queries/PerfDrillDown/Win32/AddIns/tenantTypicalAddInCost";

const PerfTenantAddInQueries = {
    tenantAddInHistory,
    tenantAddInOptions,
    tenantAddInVersionHistory,
    tenantAllAddInCostHistory,
    tenantTopAddInsPerformanceQuery,
    tenantTypicalAddInCost,
};

export default PerfTenantAddInQueries;
