import React from "react";
import {
    Dropdown,
    IDropdownOption,
    Label,
    Link,
    Stack,
    Toggle,
} from "@fluentui/react";
import { DatePicker } from "components/CustomComponents/DatePicker/DatePicker";
import { DrillDownMenu } from "components/OverviewComponents/DrillDownMenu/DrillDownMenu";
import TenantSearchBar from "components/OverviewComponents/TenantSearchBar/TenantSearchBar";
import { getTenantDrilldownRedirectUrl } from "pages/ExecOverview/ExecOverviewUtils";
import { cohortOptions, dashboardHeader, Workload } from "config/PlatformConfig";
import "pages/common.css";
import { OverviewFilterPanelProps, PanelFilterType } from "pages/ExecOverview/types";
import { InsightsRoutes } from "utils/Links";

export const OverviewFilterPanel = ({
    panelFilters,
    filterPanelHandler,
    tenantsData,
    platform,
}: OverviewFilterPanelProps) => {
    const redirectUrl = getTenantDrilldownRedirectUrl(panelFilters);

    const midMarketCohortOptions: IDropdownOption[] = [
        ...cohortOptions,
        { key: "MidMarket", text: "MidMarket" },
    ];

    const midMarketMauOptions: IDropdownOption[] = [
        { key: "25", text: "25 (Recommended)" },
        { key: "100", text: "100" },
        { key: "500", text: "500" },
        { key: "1000", text: "1000" },
        { key: "2000", text: "2000" },
        { key: "2500", text: "2500" },
    ];

    const getCohortOptions = (
        cohortOptions: IDropdownOption[],
        platform: string,
    ) => {
        if (platform == Workload.WIN32) {
            return midMarketCohortOptions;
        } else {
            return cohortOptions;
        }
    };

    const getMinMauOptions = (panelFilters: PanelFilterType) => {
        if (panelFilters.filters.cohort === "MidMarket") {
            return midMarketMauOptions;
        }

        return panelFilters.config.defaults.mauOptions;
    };

    const filterNavigationLabel = "Go to " + dashboardHeader + ":";

    return (
        <Stack horizontal className="filterPanel">
            <div className="timePeriod" data-testid="timePeriod">
                <DatePicker
                    dateOptions={panelFilters.dateOptions}
                    onChangeSelection={filterPanelHandler.onChangeSelection}
                    selected={panelFilters.dateFilter}
                />
            </div>
            {panelFilters.config.showHost && (
                <div>
                    <Label className="filterLabel">Host: </Label>
                    <div className="filterValue">SharePoint Online</div>
                </div>
            )}
            <div className="cohortFilter">
                <Label className="filterLabel">Cohort:</Label>
                <Dropdown
                    options={getCohortOptions(cohortOptions, platform)}
                    onChange={(event, option) =>
                        filterPanelHandler.onFilterChange("cohort", event, option)
                    }
                    selectedKey={panelFilters.filters.cohort}
                    className="filterDropdown"
                />
            </div>
            {panelFilters.config.showMAU && (
                <div className="minMauFilter">
                    <Label className="filterLabel">Minimum MAU: </Label>
                    <Dropdown
                        options={getMinMauOptions(panelFilters)}
                        onChange={(event, option) =>
                            filterPanelHandler.onFilterChange(
                                "minMau",
                                event,
                                option,
                            )
                        }
                        selectedKey={panelFilters.filters.minMau}
                        className="filterDropdown"
                    />
                </div>
            )}
            <div>
                <Toggle
                    inlineLabel
                    checked={panelFilters.filters.level !== "Tpid"}
                    onText="Tenant View"
                    offText="Tpid View"
                    disabled={!panelFilters?.config?.showTpidData}
                    onChange={filterPanelHandler.onLevelChange}
                    className="filterToggle"
                />
            </div>
            <div>
                <TenantSearchBar
                    tenantsData={tenantsData}
                    dateFilter={panelFilters.dateFilter}
                    isTpidHeaderClickable={true}
                    showTenantStatus={true}
                />
            </div>
            {panelFilters.config.showDrilldownLink && (
                <Stack.Item grow={1} className="menuNavigation">
                    <DrillDownMenu
                        items={[
                            {
                                key: "item1",
                                name: "Go to Tenant Drilldown View",
                                to: redirectUrl,
                            },
                        ]}
                    />
                </Stack.Item>
            )}
            {panelFilters.config.showWorkloadPageLinks && (
                <Stack.Item grow={1} className="menuNavigation">
                    <Label className="filterLabel">{filterNavigationLabel}</Label>
                    <Link
                        href={InsightsRoutes.Web.path}
                        target="_blank"
                        rel="noreferrer"
                        style={{ paddingRight: 5 }}
                    >
                        Web |
                    </Link>
                    <Link
                        href={InsightsRoutes.Win32.path}
                        target="_blank"
                        rel="noreferrer"
                        style={{ paddingRight: 5 }}
                    >
                        Win32 |
                    </Link>
                    <Link
                        href={InsightsRoutes.Mac.path}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Mac
                    </Link>
                </Stack.Item>
            )}
        </Stack>
    );
};

export default OverviewFilterPanel;
