import React, { useEffect, useState } from "react";
import { DetailsList, IColumn, SelectionMode, Icon } from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { RELIABILITY_MESSAGES } from "utils/Messages";

const ErrorSpike = ({ props }) => {
    const [items, setItems] = useState(props);
    useEffect(() => {
        setItems(props);
    }, [props]);

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(errorSpikeColumns, column, items);
        setItems(result.items);
        setErrorSpikeColumns(result.columns);
    };

    const _generateColumns = () => {
        const displayColumns = [
            ["ErrorName", "Error Name", 170, null],
            ["Application", "Application", 80, null],
            ["IsNewError", "New Error", 70, null],
            ["IsSpike", "Spike", 60, null],
            ["IsHighTrend", "Significant", 75, null],
            ["IsHighUserImpact", "High User Impact", 100, null],
            ["PercentUsersWithError", "% Users With Error", 160, "alignRightHeader"],
            [
                "PercentSessionsWithError",
                "% Sessions With Error",
                160,
                "alignRightHeader",
            ],
        ];

        const columns: IColumn[] = DetailsListHelper.customBuildColumns(
            displayColumns,
            true,
            _onColumnClick,
            "PercentUsersWithError",
            true,
        );
        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
        return columns;
    };

    const [errorSpikeColumns, setErrorSpikeColumns] = useState(_generateColumns());

    const _renderItemColumn = (item: any, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;

        switch (column.fieldName) {
            case "IsNewError":
            case "IsSpike":
            case "IsHighTrend":
            case "IsHighUserImpact":
                if (!fieldContent)
                    return (
                        <Icon
                            iconName="Cancel"
                            title="False"
                            className="falseIcon"
                        />
                    );
                return <Icon iconName="Accept" title="True" className="trueIcon" />;
            case "PercentUsersWithError":
            case "PercentUsersWithError_Prev":
            case "PercentSessionsWithError":
            case "PercentSessionsWithError_Prev":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    return (
        <>
            <h2 className="commonPageBlockTitle">High Impact Errors</h2>
            {items.length > 0 ? (
                <>
                    <DetailsList
                        items={items}
                        columns={errorSpikeColumns}
                        onRenderItemColumn={_renderItemColumn}
                        selectionMode={SelectionMode.none}
                        compact
                        onRenderDetailsHeader={DetailsListHelper.renderDetailsHeader}
                        setKey="none"
                        styles={{
                            root: {
                                height: "270px",
                                overflowX: "hidden",
                            },
                        }}
                        className="tenantTable"
                    />
                    <div className="subscriptStyleBottom">
                        {
                            RELIABILITY_MESSAGES.ERROR_SPIKE_COLUMNS
                                .ERROR_SPIKE_CONDITION
                        }
                        <br />
                        {RELIABILITY_MESSAGES.ERROR_SPIKE_COLUMNS.SPIKE}
                        <br />
                        {RELIABILITY_MESSAGES.ERROR_SPIKE_COLUMNS.HIGH_TREND}
                        <br />
                        {RELIABILITY_MESSAGES.ERROR_SPIKE_COLUMNS.HIGH_USER_IMPACT}
                    </div>
                </>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};

export default ErrorSpike;
