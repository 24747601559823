import React from "react";
import { STATUS } from "utils/Constants";
import "./style.css";

const StatusIndicator = (props) => {
    return (
        <span
            data-testid="statusIndicator"
            id={props.id}
            style={{
                backgroundColor: props.color != null ? props.color : "",
                border: props.color === "#e0e0e091" ? "1px solid black" : "none",
            }}
        >
            {props.children}
        </span>
    );
};

const StatusIndicatorTrend = (props) => {
    let { values } = props;
    if (values === null || values === undefined) values = [];
    // On a very rare occasion, the length of values is larger than 12 so doing
    // a simple check to ensure we don't hit a RangeError exception when creating
    // the filler Array.  See IcM:323381715.
    const filler = new Array(Math.max(0, 12 - values.length)).fill("");
    values = [...filler, ...values];
    const trend = values.map((element, i) => {
        const elementUpdated = element === "Deep Red" ? "DeepRed" : element;
        return [
            <StatusIndicator
                color={STATUS[elementUpdated]["color"]}
                id="statusIndicator"
                key={i}
            />,
        ];
    });
    return trend;
};

export { StatusIndicator, StatusIndicatorTrend };
