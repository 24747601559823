import { IColumn } from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import * as API from "api";
import { ColumnInfoType, PageConfigType, TableInfoType } from "config/PagesConfig";
import {
    CustomDetailsItemsType,
    CustomDetailsListTableType,
} from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDateOptions } from "utils/Helpers";
import { ADDIN_MESSAGES } from "utils/Messages";
import {
    AddInsFilterState,
    AddInsResponseTableType,
    AddInsResponseType,
    AddInsState,
} from "./types";

export const getAddinsTableRows = (
    addInDashboardTable: AddInsResponseTableType,
): CustomDetailsItemsType[] => {
    const addInDashboardTableRows: CustomDetailsItemsType[] = [];
    addInDashboardTable.Rows.forEach((row) => {
        const dict = {};

        row.forEach((val, idx) => {
            const colName = addInDashboardTable.Columns[idx].ColumnName;
            dict[colName] = val;
        });
        addInDashboardTableRows.push(dict);
    });
    return addInDashboardTableRows;
};

export const getAddinsTableColumns = (
    addInDashboardTable: AddInsResponseTableType,
    tableColumnName: TableInfoType,
): IColumn[] => {
    let sortedColumn: string = null;

    const customColumns = addInDashboardTable.Columns.map((x) => x.ColumnName)
        .filter((header) => header in tableColumnName)
        .map((header) => {
            const columnInfo: ColumnInfoType = tableColumnName[header];
            const displayName = columnInfo.displayName ?? header;
            const minWidth = columnInfo.minWidth ?? 100;
            const cssClass = columnInfo.cssClassName ?? null;

            if (columnInfo.isSorted) sortedColumn = header;

            return [header, displayName, minWidth, cssClass];
        });

    const columns: Array<IColumn> = DetailsListHelper.customBuildColumns(
        customColumns,
        true,
        null,
        sortedColumn,
        true,
    );

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

    return columns;
};

export const formatAddInsScore = (
    addInScoreJson: AddInsResponseType,
    tableColumnName: TableInfoType,
): CustomDetailsListTableType => {
    const AddInScoreTable = addInScoreJson.Tables[0];
    const rows = getAddinsTableRows(AddInScoreTable);
    const columns = getAddinsTableColumns(AddInScoreTable, tableColumnName);

    return { items: rows, columns };
};

export const formatAddInDashboardData = (
    performanceScoreJson: AddInsResponseType,
    reliabilityScoreJson: AddInsResponseType,
    config: PageConfigType,
) => {
    const performanceScore = formatAddInsScore(
        performanceScoreJson,
        config.tableColumns.performance,
    );
    const reliabilityScore = formatAddInsScore(
        reliabilityScoreJson,
        config.tableColumns.reliability,
    );
    return { performanceScore, reliabilityScore };
};

export const fetchData = async (
    filters: AddInsFilterState,
    setData: React.Dispatch<React.SetStateAction<AddInsState>>,
    setError: React.Dispatch<React.SetStateAction<string>>,
    setFilters: React.Dispatch<React.SetStateAction<AddInsFilterState>>,
    config: PageConfigType,
) => {
    try {
        const queryParams = {};
        queryParams["application"] = filters.application;
        queryParams["cohort"] = filters.cohort;
        queryParams["publisher"] = filters.publisher;
        queryParams["date"] = filters.date;

        const [dates, performanceScore, reliabilityScore] = await Promise.all([
            API.getAddInDateFilters(queryParams),
            API.getAddInPerformanceScore(queryParams),
            API.getAddInReliabilityScore(queryParams),
        ]);
        try {
            const responseData = formatAddInDashboardData(
                performanceScore?.data,
                reliabilityScore?.data,
                config,
            );
            const dateOptions = formatDateOptions(dates?.data);

            if (filters.date === "") {
                const latestDate = dateOptions[0].key as string;
                setFilters((fils) => ({ ...fils, date: latestDate }));
            }

            const updatedResponseData: AddInsState = {
                ...responseData,
                dateOptions,
                loading: false,
            };
            setData(updatedResponseData);
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "AddInDashboardDataFetchFailed",
                ),
                {
                    message:
                        "Failure processing response data from Add-In Health Dashboard page queries",
                },
                error,
            );
            setData((data) => {
                return { ...data, loading: false };
            });
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "AddInDashboardDataFetchFailed",
            ),
            {
                message: "Failure in fetching data for Add-In Health Dashboard page",
            },
            error,
        );
        setData((data) => {
            return { ...data, loading: false };
        });
        setError(ADDIN_MESSAGES.ADDIN_INFO_DISPLAY_ERROR);
    }
};
