import React, { useState, useEffect } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import DOMPurify from "dompurify";
import { OutagesCosmosDbAccess } from "api/CosmosDbAccess";
import { logException } from "utils/AppInsightsHelper";
import { useOnDismissMessageBar } from "utils/Helpers";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";
import { Outage } from "utils/types";

interface InsightsOutage extends Outage {
    safeMessage: string;
    messageType: MessageBarType;
}

export const OutageBanner = () => {
    const [showOutageNotification, setShowOutageNotification] =
        useState<boolean>(false);

    const [outages, setOutages] = useState<Outage[]>([]);

    const close = () => {
        // Never want telemetry to cause end-user impact, so wrap this in a try-catch just in case
        try {
            const outageCount = outages.length;
            const outageIds = JSON.stringify(outages.map((outage) => outage.id));
            const outageMessages = JSON.stringify(
                outages.map((outage) => outage.message),
            );

            sendUserActionTelemetryEvent(
                {
                    userActionName: "DismissOutageBanner",
                    commandSurface: UserActionCommandSurface.Header,
                },
                {
                    OutageCount: outageCount,
                    OutageIds: outageIds,
                    OutageMessages: outageMessages,
                },
            );
        } catch {
            sendUserActionTelemetryEvent({
                userActionName: "DismissOutageBanner",
                commandSurface: UserActionCommandSurface.Header,
            });
        }

        setShowOutageNotification(false);
    };
    const { handleDismissMessageBar } = useOnDismissMessageBar(close);

    const [pathname, setPathname] = useState(window.location.pathname);

    useEffect(() => {
        const handlePathnameChange = () => {
            setPathname(window.location.pathname);
        };

        window.addEventListener("popstate", handlePathnameChange);

        return () => {
            window.removeEventListener("popstate", handlePathnameChange);
        };
    }, []);

    useEffect(() => {
        const getMessageType = (type: string): MessageBarType => {
            let messageType: MessageBarType;

            switch (type) {
                case "Error":
                    messageType = MessageBarType.error;
                    break;
                case "Warning":
                    messageType = MessageBarType.warning;
                    break;
                default:
                    messageType = MessageBarType.info;
                    break;
            }

            return messageType;
        };

        const fetchOutageNotifications = async () => {
            try {
                const fetchedOutages: Outage[] =
                    await new OutagesCosmosDbAccess().getOutageNotifications();

                // To declare outage:
                //            We either specify the target page and have the outage banner show up there.
                //            OR we leave the target array empty to target all pages.
                const filteredOutages: Outage[] = fetchedOutages
                    .filter(
                        (outage: Outage) =>
                            outage.targets?.includes(window.location.pathname) ||
                            outage.targets?.length === 0,
                    )
                    .map((outage: Outage) => {
                        return {
                            ...outage,
                            safeMessage: DOMPurify.sanitize(outage.message),
                            messageType: getMessageType(outage.type),
                        } as InsightsOutage;
                    });

                setOutages(filteredOutages);

                if (filteredOutages.length > 0) {
                    setShowOutageNotification(true);
                }
            } catch (error) {
                logException(
                    new Error("OutageNotificationQueryFailed"),
                    {
                        message:
                            "Unable to fetch outage notifications from CosmosDB",
                    },
                    error,
                );
            }
        };

        fetchOutageNotifications();
    }, [pathname]);

    return showOutageNotification ? (
        <>
            {outages.map((outage: InsightsOutage) => {
                return (
                    <MessageBar
                        key={`message-bar-outage-${outage.id}`}
                        data-testid="outage-message-bar"
                        messageBarType={outage.messageType}
                        isMultiline={false}
                        onDismiss={handleDismissMessageBar}
                        dismissButtonAriaLabel="Close"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: outage.safeMessage,
                            }}
                        />
                    </MessageBar>
                );
            })}
        </>
    ) : (
        <></>
    );
};
