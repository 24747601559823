import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { GeographicDistributionWrapper } from "components/TenantDrilldownComponents/TenantEnvironment/GeographicDistributionWrapper";
import { MonthlyTenantEnvironmentChart } from "components/TenantDrilldownComponents/TenantEnvironment/MonthlyTenantEnvironmentChart";
import * as API from "api";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { Workload } from "config/PlatformConfig";
import {
    commonPageBlock,
    commonPageStyle,
    equalStackItemsStyle,
    horizontalStackTokens,
} from "pages/common";
import { formatTenantEnvironmentData } from "pages/InProductDrillDown/ApiHandler";
import { TenantEnvironmentType } from "pages/InProductDrillDown/types";
import { Level } from "pages/Performance/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

const chartLayout = [
    ["os", "AntiVirus"],
    ["Audience", "Fork"],
    ["BootDiskType", "Architecture"],
    ["Ram", "ProcessorCount"],
    ["DeviceAge", "PredictedPriceBand"],
    ["OfficeDiagnosticConsentLevel", "WindowsTelemetryLevel"],
    ["licenseDistribution", "DeviceManagement"],
    ["VmType"],
];

const displayNameMap = {
    AntiVirus: "Anti-Virus",
    BootDiskType: "Boot Disk Type",
    DeviceAge: "Device Age",
    DeviceManagement: "Device Management",
    licenseDistribution: "License",
    OfficeDiagnosticConsentLevel: "Office Diagnostic Consent Level",
    os: "Operating System",
    PredictedPriceBand: "Predicted Price Band",
    ProcessorCount: "Processor Count",
    Ram: "RAM",
    VmType: "Virtual Machine Type",
    WindowsTelemetryLevel: "Windows Telemetry Level",
};

const yLabelForChart = (chartName: string) => {
    switch (chartName) {
        case "licenseDistribution":
            return "# Licenses";
        case "VmType":
            return "# Clients";
        default:
            return "# Devices";
    }
};

export const Win32PerformanceEnvironment = ({
    id,
    level,
}: {
    id: string;
    level: Level;
}) => {
    const [environmentData, setEnvironmentData] =
        useState<TenantEnvironmentType>(undefined);

    useEffect(() => {
        const queryEnvData = async () => {
            try {
                const tenantEnvironment = await API.fetchWin32PerfEnvironment({
                    id,
                    level,
                });
                const geoDataMapping = new Map<string, {}>();
                const formattedData = formatTenantEnvironmentData(
                    tenantEnvironment,
                    geoDataMapping,
                    Workload.WIN32,
                );
                setEnvironmentData({ ...formattedData, geoDataMapping });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "Win32PerformanceEnvironmentDataFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching/processing data for Win32 Performance Environment.",
                    },
                    error,
                );
                setEnvironmentData({});
            }
        };
        queryEnvData();
    }, [id, level]);

    return (
        <Stack disableShrink styles={commonPageStyle}>
            <Stack.Item styles={commonPageBlock}>
                {chartLayout.map((chartPair) => (
                    <React.Fragment key={chartPair[0]}>
                        <Stack horizontal tokens={horizontalStackTokens}>
                            {chartPair.map(
                                (chartName) =>
                                    chartName && (
                                        <Stack.Item
                                            grow="initial"
                                            styles={equalStackItemsStyle}
                                            key={chartName}
                                        >
                                            <MonthlyTenantEnvironmentChart
                                                displayName={
                                                    displayNameMap[chartName] ??
                                                    chartName
                                                }
                                                payload={
                                                    environmentData?.[chartName]
                                                }
                                                kind={
                                                    togglingLineChartKind.valuesWithPercentages
                                                }
                                                yLabel={yLabelForChart(chartName)}
                                                strokeWidth={2}
                                                loading={
                                                    environmentData === undefined
                                                }
                                            />
                                        </Stack.Item>
                                    ),
                            )}
                        </Stack>
                        <div className="break" />
                    </React.Fragment>
                ))}
                <GeographicDistributionWrapper
                    geographicDistribution={environmentData?.geographicDistribution}
                    geoDataMapping={
                        environmentData?.geoDataMapping ?? new Map<string, {}>()
                    }
                    loading={environmentData === undefined}
                    rankOn="WXPON"
                    workload={Workload.WIN32}
                />
            </Stack.Item>
        </Stack>
    );
};
