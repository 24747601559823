import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { kustoApiResponseStatusCode } from "config/ApiConfiguration";
import { AzureAPIPermissions } from "config/AuthConfig";
import { logKustoRetriedQueryView } from "utils/AppInsightsHelper";
import AuthenticationUtil from "utils/AuthenticationUtil";

export class ApiError extends Error {
    axiosError: AxiosError;

    constructor(axiosError: AxiosError) {
        const message = axiosError.response?.data?.error?.["@message"];
        super(message ?? axiosError.message);
        Object.setPrototypeOf(this, ApiError.prototype);
        this.axiosError = axiosError;
    }

    toJSON() {
        const ret = this.axiosError.toJSON();
        ret.config = undefined; // Remove config as it contains auth tokens
        ret.axiosError = this.axiosError.response?.data?.error;
        return ret;
    }
}

export const getQueryName = (
    config: AxiosRequestConfig,
): string | number | boolean => {
    return config?.headers ? config.headers["x-ms-kql-queryName"] : "none";
};

/**
 * Request interceptor
 * @param request Request configuration
 */
export const requestInterceptor = async (
    request: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
    const token = await AuthenticationUtil.getToken(AzureAPIPermissions.kusto);
    request.headers = { ...request.headers, Authorization: `Bearer ${token}` };
    return request;
};

/**
 * Success response interceptor
 * @param axiosResponse Axios response
 */
export const responseInterceptor = (axiosResponse: AxiosResponse): AxiosResponse => {
    return axiosResponse;
};

/**
 * Error response interceptor
 * @param axiosError Axios error response
 */
export const errorInterceptor = (axiosError: AxiosError): Promise<ApiError> => {
    return Promise.reject(new ApiError(axiosError));
};

export const apiRetryConfiguration = {
    retries: 3, // number of retries
    retryDelay: axiosRetry.exponentialDelay, // BUG: https://office.visualstudio.com/OC/_workitems/edit/6405322
    retryCondition: (error: AxiosError) => {
        // if retry condition is not specified, by default idempotent requests are retried
        const isRetryValid = kustoApiResponseStatusCode.isRetryable.includes(
            error.response.status,
        );
        if (isRetryValid) {
            logKustoRetriedQueryView({
                queryName: getQueryName(error?.config) as string,
                status: error?.response?.status,
                retryCount: error?.config?.["axios-retry"]["retryCount"],
                lastRequestTime: error?.config?.["axios-retry"]["lastRequestTime"],
            });
        }
        return isRetryValid;
    },
};
