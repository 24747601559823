import React, { useEffect, useReducer } from "react";
import { Stack } from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import { LegendList } from "components/CustomComponents/LegendList/LegendList";
import { AddInCountAndPerfImpactCharts } from "pages/Performance/AddIns/AddInCountAndPerfImpactCharts";
import { AllAddInCostHistoryChart } from "pages/Performance/AddIns/AllAddInCostHistoryChart";
import { TenantTopAddInTable } from "pages/Performance/AddIns/TenantTopAddInTable";
import { legendReducer } from "components/CustomComponents/LegendList/LegendReducer";
import { commonPageBlock } from "pages/common";
import { progIdsFromHistoryData } from "pages/Performance/AddIns/AddInPerformanceHelper";
import {
    AddInCountAndPerfImpactData,
    App,
    ChartData,
    TenantTopAddInPerformanceData,
} from "pages/Performance/types";
import { getChartColor } from "utils/Helpers";

export const ApplicationAddInPerformance = ({
    id,
    date,
    timespan,
    appName,
    topAddInData,
    allAddInCostHistory,
    addInHistory,
}: {
    id: string;
    date: string;
    timespan: number;
    appName: App;
    topAddInData: TenantTopAddInPerformanceData[];
    allAddInCostHistory: ChartData[];
    addInHistory: AddInCountAndPerfImpactData;
}) => {
    const [legend, dispatcher] = useReducer(legendReducer, []);

    useEffect(() => {
        if (!addInHistory) return;

        const labels = progIdsFromHistoryData(addInHistory)
            .sort()
            .map((progId, index) => ({
                key: progId,
                Name: progId,
                color: getChartColor(index),
                showDots: false,
            }));
        dispatcher({
            type: "addEntries",
            payload: labels,
        });
    }, [addInHistory]);

    return (
        <Stack styles={commonPageBlock}>
            <ApplicationTitle app={appName} />
            <TenantTopAddInTable
                id={id}
                date={date}
                timespan={timespan}
                topAddInData={topAddInData}
                dispatcher={dispatcher}
            />
            <LegendList legend={legend} dispatcher={dispatcher} />
            <Stack horizontal>
                <AllAddInCostHistoryChart data={allAddInCostHistory} />
                <AddInCountAndPerfImpactCharts legend={legend} data={addInHistory} />
            </Stack>
        </Stack>
    );
};
