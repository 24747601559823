import React from "react";
import { Shimmer, Stack } from "@fluentui/react";
import PerformanceLineChart from "components/CustomComponents/PerformanceLineChart";
import { LegendEntry } from "pages/commonTypes";
import { getChartDataFromCountAndPerfImpactData } from "pages/Performance/AddIns/AddInPerformanceHelper";
import { perfChartStackItemStyles } from "pages/Performance/PerformanceConstants";
import { AddInCountAndPerfImpactData } from "pages/Performance/types";

export const AddInVersionPerfCharts = ({
    progId,
    data,
    legend,
}: {
    progId: string;
    data: AddInCountAndPerfImpactData;
    legend: LegendEntry[];
}) => {
    return (
        <Stack horizontal>
            <Shimmer className="scenarioChart" isDataLoaded={data !== undefined}>
                <Stack styles={perfChartStackItemStyles}>
                    <h3 className="scenarioChartHeader">{`Devices with versions of ${progId}`}</h3>
                    <PerformanceLineChart
                        values={getChartDataFromCountAndPerfImpactData(
                            "Counts",
                            data,
                        )}
                        labels={legend}
                    />
                </Stack>
            </Shimmer>
            <Shimmer className="scenarioChart" isDataLoaded={data !== undefined}>
                <Stack styles={perfChartStackItemStyles}>
                    <h3 className="scenarioChartHeader">
                        Typical boot load cost by version
                    </h3>
                    <PerformanceLineChart
                        values={getChartDataFromCountAndPerfImpactData(
                            "Durations",
                            data,
                        )}
                        labels={legend}
                        yUnit="s"
                    />
                </Stack>
            </Shimmer>
        </Stack>
    );
};
