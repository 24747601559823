import React, { useState } from "react";
import {
    IStackItemStyles,
    Icon,
    Panel,
    PanelType,
    Stack,
    Toggle,
    TooltipHost,
} from "@fluentui/react";
import { DefaultSelectedFunnel } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    getReferrerSourceDataOverTime,
    getScenarioDataOverTime,
} from "components/ConsumerDay0FunnelComponents/ExploreHistoricalTrendsPanel/ExploreHistoricalTrendsHelper";
import { ExploreHistoricalTrendsPanelProps } from "components/ConsumerDay0FunnelComponents/ExploreHistoricalTrendsPanel/types";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { chartSizes } from "pages/common";
import { ShortenedFunnelStageDropOffNames } from "pages/ConsumerDay0Funnel/constants";
import { getChartColor } from "utils/Helpers";
import "components/ConsumerDay0FunnelComponents/ExploreHistoricalTrendsPanel/style.css";

export const stackItemStylesDay0Funnel: IStackItemStyles = {
    root: {
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "10px",
        borderRadius: "14px",
        overflowX: "auto",
        minHeight: "825px",
        flexWrap: "wrap",
    },
};

export const ExploreHistoricalTrendsPanel = ({
    showPanel,
    setShowPanel,
    funnelDataOverTime,
    funnelDropOffOverTime,
    funnelData,
    filters,
    selectedStage,
    scenarioData,
    referrerSourceData,
}: ExploreHistoricalTrendsPanelProps) => {
    const historicalTrendsChartHeight = 400;
    const [isDocTypeGrouped, setDocTypeGrouped] = useState<boolean>(false);
    const [isReferrerGrouped, setReferrerGrouped] = useState<boolean>(false);

    const scenarioDataOverTime = getScenarioDataOverTime(
        scenarioData,
        selectedStage,
        isDocTypeGrouped,
    );

    const referrerDataOverTime = getReferrerSourceDataOverTime(
        referrerSourceData,
        selectedStage,
        filters.scenario,
        isReferrerGrouped,
    );

    const chartLabels = () => {
        const stages = Object.keys(funnelData[filters.date]);
        return stages
            .filter((stage) => stage !== DefaultSelectedFunnel)
            .map((stage, index) => {
                return { color: getChartColor(index), key: stage };
            });
    };

    const dropOffChartsLabels = () => {
        const stages = Object.keys(funnelData[filters.date]);
        return stages
            .filter((stage) => stage !== DefaultSelectedFunnel)
            .map((stage, index) => {
                return {
                    color: getChartColor(index),
                    key: ShortenedFunnelStageDropOffNames[stage],
                };
            });
    };

    const scenarioChartLabels = () => {
        return Object.keys(scenarioDataOverTime["0"])
            .filter((key) => key !== "date")
            .map((key, index) => {
                return { color: getChartColor(index), key };
            });
    };

    const referrerLabels = () => {
        return referrerDataOverTime?.length > 0
            ? Object.keys(referrerDataOverTime["0"])
                  .filter((key) => key !== "date")
                  .map((key, index) => {
                      return { color: getChartColor(index), key };
                  })
            : [];
    };

    return (
        <Panel
            isLightDismiss
            isOpen={showPanel}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                setShowPanel(false);
            }}
        >
            <Stack styles={stackItemStylesDay0Funnel}>
                <Stack.Item grow>
                    <center>
                        <h1 className="commonPageBlockTitle">
                            Historical Trends of The Day 0 Session 0 Funnel
                        </h1>
                        <div className="exploreHistoricalTrendsPanelScope">
                            <strong>DocType:</strong> {filters.scenario},{" "}
                            <strong>Referrer:</strong> {filters.referrer},{" "}
                            <strong>Stage:</strong> {selectedStage}{" "}
                            <TooltipHost content="The historical trends are set to the scope (DocType, Referrer, Stage) selected from the funnel. To change the scope, select the desired DocType / Referrer / Stage combination from the funnel.">
                                <Icon iconName="Info" />
                            </TooltipHost>
                        </div>
                    </center>
                </Stack.Item>
                <div className="break" />
                <Stack.Item grow>
                    <h2 className="commonPageBlockTitle">Funnel Over Time</h2>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={chartLabels()}
                        xDataKey="date"
                        values={funnelDataOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
                <div className="break" />
                <Stack.Item grow>
                    <h2 className="commonPageBlockTitle">
                        Drop From Previous Stage Over Time
                    </h2>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={dropOffChartsLabels()}
                        xDataKey="date"
                        values={funnelDropOffOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
                <div className="break" />
                <Stack.Item grow>
                    <h2 className="commonPageBlockTitle">
                        DocType Distribution for Stage: {selectedStage} Over Time
                    </h2>
                    <Stack horizontal>
                        <Stack.Item>
                            <Toggle
                                className="filterToggle"
                                inlineLabel
                                onText="Grouped"
                                offText="Not Grouped"
                                onChange={(event, checked) =>
                                    setDocTypeGrouped(checked)
                                }
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TooltipHost content="Group DocType on Create / Open or individual scenarios">
                                <Icon
                                    iconName="Info"
                                    aria-label="Group DocType on Create / Open or individual scenarios"
                                />
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={scenarioChartLabels()}
                        xDataKey="date"
                        values={scenarioDataOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
                <div className="break" />
                <Stack.Item grow>
                    <h2 className="commonPageBlockTitle">
                        Referrer Source Distribution Over Time
                    </h2>
                    <Stack horizontal>
                        <Stack.Item>
                            <Toggle
                                className="filterToggle"
                                inlineLabel
                                onText="Grouped"
                                offText="Not Grouped"
                                onChange={(_event, checked) =>
                                    setReferrerGrouped(checked)
                                }
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TooltipHost content="Group Referrer Source">
                                <Icon
                                    iconName="Info"
                                    aria-label="Group Referrer Source"
                                />
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={referrerLabels()}
                        xDataKey="date"
                        values={referrerDataOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
            </Stack>
        </Panel>
    );
};
