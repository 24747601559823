import React from "react";
import { Icon } from "@fluentui/react";
import { getExemptedFields } from "utils/Helpers";

export const ExemptIcon = (props) => {
    const exemptedField = props.note ? getExemptedFields(props.note) : null;
    return (
        <span>
            <Icon
                iconName="img/shield.svg"
                style={{
                    paddingLeft: "5px",
                    paddingTop: "10px",
                    paddingRight: "5px",
                }}
                title={"Exempted from Red and Deep Red Status"}
            />
            {props.note && (
                <span className="specialNote">
                    <b>Exemption Note: </b>
                    Tenant is exempted from Red and Deep Red status until{" "}
                    {props.note.ExemptedUntil}.
                    {exemptedField && <span>Exempted fields : {exemptedField}</span>}
                </span>
            )}
        </span>
    );
};
