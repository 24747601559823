export const addinsDateFilters: string = `
//addinsDateFilters
// Using perf table because it has more data but it wouldn't matter because if there is more dates then reliability will just show empty
let queryName = "addinsDateFilters";
    Addins_Performance 
    | distinct Date
    | order by Date desc
    | top 18 by Date`;

export const addInsPerformanceScore: string = `
    // addInsPerformanceScore
    let queryName = "addInsPerformanceScore";
    let app = "\${application}";
    let maxDate =  toscalar(Addins_Performance | summarize max(Date));
    let dt = iff(isempty("\${date}"), maxDate, "\${date}");
    Addins_Performance  
        | where OmsTenantId == "All" and Tpid == "All"
        | where case("All" == "\${publisher}", IsMicrosoftAddin == true or IsMicrosoftAddin == false, "Microsoft" == "\${publisher}", IsMicrosoftAddin == true, IsMicrosoftAddin == false)
        | where Cohort == "\${cohort}" and Application == app and Date == dt
        | project AddInProgId,
            Rank = ImpactedLoadTimeRank,
            TypicalLoadDurationPerSession,
            ImpactedApps, 
            ImpactedTenants, DevicesWithAddIn, AddInCLSID, AddInProvider , AddInFriendlyName
        | where Rank > 0
        | order by ImpactedTenants desc, Rank desc 
        | take 200`;

export const addInsReliabilityScore: string = `
    //addInsReliabilityScore
    let queryName = "addInsReliabilityScore";
    let app = "\${application}";
    let maxDate =  toscalar(Addins_Reliability | summarize max(Date));
    let dt = iff(isempty("\${date}"), maxDate, "\${date}");
    Addins_Reliability 
        | where OmsTenantId == "All" and Tpid == "All"
        | where case("All" == "\${publisher}", IsMicrosoftAddin == true or IsMicrosoftAddin == false, "Microsoft" == "\${publisher}", IsMicrosoftAddin == true, IsMicrosoftAddin == false)
        | where Cohort == "\${cohort}" and Application == app and Date == dt
        | project AddInProgId,
            Rank = ImpactedReliabilityRank,
            Crashes,
            Reliability,
            ImpactedApps, 
            ImpactedTenants, ImpactedDevices, UnsuccessfulLoads, AddInCLSID, AddInProvider, AddInFriendlyName
        | order by Crashes desc, ImpactedTenants desc
        | take 200`;
