import React from "react";
import { IColumn, ICommandBarItemProps, SelectionMode } from "@fluentui/react";
import {
    CompositeList,
    ICompositeListDetailsList,
    ICompositeListFarItemOptions,
} from "@m365-admin/composite-list";
import {
    NO_DATA_SEARCH_RESULTS,
    SearchLabel,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import { DataTableProps } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import "./style.css";

export const DataTable = ({
    items,
    columns,
    onSearch,
    loading,
    FilterPanel,
    onColumnClick,
    onColumnRender,
    setOpenFlyoutPanel,
    setSelectedRow,
    maxHeight,
    emptyMessage = NO_DATA_SEARCH_RESULTS,
}: DataTableProps) => {
    const detailsListProps: ICompositeListDetailsList = {
        listProps: {
            items: items,
            columns: columns,
            enableShimmer: loading,
            selectionMode: SelectionMode.none,
            onColumnHeaderClick: (
                ev: React.MouseEvent<HTMLElement>,
                column: IColumn,
            ) => {
                if (onColumnClick) {
                    onColumnClick(column, items);
                }
            },
            onItemInvoked: (item?) => {
                if (setSelectedRow) {
                    setSelectedRow(item);
                    setOpenFlyoutPanel(true);
                }
            },

            onRenderItemColumn: onColumnRender,
        },
        key: "advisoryTable",
    };

    const onGetFarItems = (
        defaultRender: (
            options: ICompositeListFarItemOptions,
        ) => ICommandBarItemProps[],
    ): ICommandBarItemProps[] => {
        return [
            {
                key: "dataTableItemCountKey",
                text: `${items.length} items`,
            },
            ...defaultRender({
                compactMenuProps: {
                    onRender: () => null,
                },
                searchBoxProps: {
                    placeholder: SearchLabel,
                    onChange: (
                        ev?: React.ChangeEvent<HTMLInputElement>,
                        newValue?: string,
                    ) => {
                        onSearch(newValue);
                    },
                },
                searchBoxButtonAriaLabel: SearchLabel,
            }),
        ];
    };

    return (
        <div className="dataTableClass">
            <CompositeList
                detailsListProps={detailsListProps}
                commandBarProps={{
                    items: [],
                }}
                onGetFarItems={onGetFarItems}
                onRenderSecondaryArea={FilterPanel}
                isEmpty={!loading && items.length === 0}
                emptyStateProps={{ title: emptyMessage, body: "" }}
                styles={{
                    header: {
                        "::before": { display: "none" },
                        "::after": { display: "none" },
                    },
                    detailsListsWrapper: {
                        display: "block",
                        overflow: "auto",
                        minHeight: 400,
                        maxHeight: maxHeight ?? 400,
                    },
                }}
                singleClickToInvoke={true}
            />
        </div>
    );
};
