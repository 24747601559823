import React from "react";
import { Stack } from "@fluentui/react";
import { CommonFunnelStages } from "components/CommonFunnelComponents/CommonFunnelStages/CommonFunnelStages";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import { AIScorecardFiltersType } from "components/AIScorecardComponents/types";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { CopilotFunnelType } from "config/FunnelConfig";

export const CopilotJourneyFunnel = ({ filters }) => {
    const getFunnelFilters = (filters: AIScorecardFiltersType) => {
        const funnelFilters: CommonFilterStateType = {
            application: filters.application,
            date: filters.date,
            additionalFilters: {
                cadence: filters.cadence,
                office365Category: filters.office365Category,
                platform: filters.platform,
                cohort: filters.cohort,
            },
        };
        return funnelFilters;
    };

    return (
        <Stack>
            <Stack.Item>
                <CommonFunnelStages
                    filters={getFunnelFilters(filters)}
                    funnelType={CopilotFunnelType.UserJourney}
                    getAdditionalTelemetryFields={() => {
                        return {
                            ActiveAppTab: getAIScorecardAppTabSelection(),
                            ActiveScorecardTab: getAIScorecardTabSelection(),
                        };
                    }}
                />
            </Stack.Item>
        </Stack>
    );
};
