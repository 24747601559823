import React, { useEffect, useRef, useState } from "react";
import { Stack, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import FunnelFilterPanel from "components/ConsumerDay0FunnelComponents/FunnelFilters/FunnelFilters";
import { FunnelInsights } from "components/ConsumerDay0FunnelComponents/FunnelInsights/FunnelInsights";
import { FunnelStages } from "components/ConsumerDay0FunnelComponents/FunnelStages";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import {
    DefaultSelectedFunnel,
    DefaultSelectedDropOff,
    getDroppedStageNames,
    getAdditionalFiltersToShow,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { scrollForInsightsTopHeight } from "components/ConsumerDay0FunnelComponents/CompositeFunnel/constants";
import { getPageConfig, DrilldownPages } from "config/PagesConfig";
import { commonPageStyle } from "pages/common";
import { getDates, getReferrers } from "pages/ConsumerDay0Funnel/ApiHandler";
import {
    FunnelStage,
    FunnelDropOff,
    FunnelReferralSources,
} from "pages/ConsumerDay0Funnel/constants";
import { FunnelFilterStateType } from "pages/ConsumerDay0Funnel/types";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const ConsumerDay0Funnel = () => {
    const pageConfig = getPageConfig(DrilldownPages.DAY0FUNNEL_CONSUMER);
    document.title = pageConfig.pageTitle;
    const initialFilters: FunnelFilterStateType = {
        application: pageConfig.defaults.app,
        cadence: pageConfig.defaults.cadence,
        date: null,
        cohort: pageConfig.defaults.cohort,
        scenario: pageConfig.defaults.scenario,
        referrer: pageConfig.defaults.referrer,
    };

    const filtersFromQueryParams = extractQueryParams();
    const initialAdditionalFilters = getAdditionalFiltersToShow(
        filtersFromQueryParams,
    );

    const [filters, setFilters] = useState<FunnelFilterStateType>(() => {
        return {
            ...initialFilters,
            ...filtersFromQueryParams,
        };
    });

    const [additionalFilters, setAdditionalFilters] = useState<Set<string>>(() => {
        return initialAdditionalFilters.size > 0
            ? initialAdditionalFilters
            : new Set<string>();
    });

    const [dateOptions, setDateOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedFunnel, setSelectedFunnel] = useState<FunnelStage>(() => {
        return filtersFromQueryParams["stage"] ?? DefaultSelectedFunnel;
    });

    const handleFunnelChange = (newFunnel: FunnelStage) => {
        return newFunnel === selectedFunnel
            ? setSelectedFunnel(DefaultSelectedFunnel)
            : setSelectedFunnel(newFunnel);
    };

    const handleDropoffChange = (newDropOff: FunnelDropOff) => {
        return newDropOff === selectedDropOff
            ? setSelectedDropOff(DefaultSelectedDropOff)
            : setSelectedDropOff(newDropOff);
    };

    const [selectedDropOff, setSelectedDropOff] = useState<FunnelDropOff>(() => {
        return filtersFromQueryParams["dropOff"] ?? DefaultSelectedDropOff;
    });

    const [showNewInsightsButton, setNewInsightsButtonVisibility] =
        useState<boolean>(false);
    const didMount = useRef<boolean>(false);

    window.onscroll = () => {
        scrollFunction();
    };

    useSendLaunchEvent(
        pageConfig?.pageToolNameForTelemetry,
        flattenFilters(filters),
    );

    useEffect(() => {
        const getDateOptions = async () => {
            setLoading(true);
            try {
                const dateOptions = await getDates(filters);

                if (filters.date === null || didMount.current) {
                    if (dateOptions && dateOptions.length !== 0) {
                        if (filters.date === null) didMount.current = true;
                        const latestDate = dateOptions[0] as string;
                        onFilterChange("date", latestDate);
                    }
                } else {
                    // this case will be done when date is passed in URL params
                    didMount.current = true;
                }

                setDateOptions(dateOptions);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerDay0FunnelPageDatesFetchFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format dates for Consumer Day 0 Funnel page",
                    },
                    e,
                );
            }
            setLoading(false);
        };

        //reset to first stage when app and cadence changed
        setSelectedFunnel(DefaultSelectedFunnel);
        setSelectedDropOff(DefaultSelectedDropOff);
        //reset also the scenario and referrer filters when application and cadence changes
        onFilterChange("referrer", FunnelReferralSources.All);
        onFilterChange("scenario", "All");

        getDateOptions();

        setNewInsightsButtonVisibility(true);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.application, filters.cadence]);

    // App insights tracking
    useEffect(() => {
        AppInsights.getInstance().TrackPage(document.title);
    }, []);

    useEffect(() => {
        const getReferrerOptions = async () => {
            try {
                const response = await getReferrers({
                    application: filters.application,
                    cadence: filters.cadence,
                    cohort: filters.cohort,
                    date: filters.date,
                    scenario: filters.scenario,
                    referrer: "",
                });
                if (
                    response?.Tables[0]?.Rows.length === 1 &&
                    response?.Tables[0]?.Rows[0].includes(FunnelReferralSources.All)
                ) {
                    setFilters((oldFilters) => {
                        return {
                            ...oldFilters,
                            referrer: FunnelReferralSources.All,
                        };
                    });
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchReferrersForDateException",
                    ),
                    {
                        message:
                            "Failed to fetch referrer data for a given date and other parameters.",
                    },
                    error,
                );
            }
        };
        getReferrerOptions();
    }, [
        filters.application,
        filters.cadence,
        filters.cohort,
        filters.date,
        filters.scenario,
    ]);

    useEffect(() => {
        setNewInsightsButtonVisibility(true);
    }, [
        filters.scenario,
        filters.referrer,
        filters.appMode,
        filters.browser,
        filters.country,
        filters.datacenter,
        filters.os,
        filters.referrerPlatform,
        selectedFunnel,
        selectedDropOff,
    ]);

    const scrollFunction = () => {
        if (document.documentElement.scrollTop > scrollForInsightsTopHeight) {
            setNewInsightsButtonVisibility(false);
        }
    };

    const onFilterChange = (item: string, value: string) => {
        logFilterUsage(pageConfig.pageTitle, item, value);

        const updatedFilter = {
            ...filters,
            [item]: value,
        };
        setFilters(updatedFilter);
        sendFiltersChangeTelemetryEvent(updatedFilter);
    };

    const onAppFilterChange = (item: string, value: string) => {
        setLoading(true);
        onFilterChange(item, value);
    };

    /**
     * On click of Scroll for new Insights button:
     *  1. Scroll into Insights view
     *  2. Hide the Scroll for new insights button
     */
    const onClickScrollToInsights = () => {
        const element = document.getElementById("day0FunnelInsights");
        if (element) {
            element.scrollIntoView();
        }
        element.scrollIntoView();
        setNewInsightsButtonVisibility(false);
    };

    const onAddFilter = (item: string) => {
        const pivots = new Set(additionalFilters);
        if (pivots.has(item)) {
            pivots.delete(item);
            delete filters[item];
        } else {
            pivots.add(item);
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    [item]: "All",
                };
            });
        }
        setAdditionalFilters(pivots);
    };

    setUpDocumentUrl(
        {
            ...filters,
            stage: selectedFunnel,
            dropOff: selectedDropOff,
        },
        pageConfig.pageTitle,
    );

    return (
        <Stack styles={commonPageStyle} enableScopedSelectors verticalFill={true}>
            <Stack.Item>
                <div className="orgHeader">{pageConfig.pageHeader}</div>
            </Stack.Item>
            <Stack.Item>
                <FunnelFilterPanel
                    dateOptions={dateOptions}
                    filters={filters}
                    onFilterChange={onFilterChange}
                    onAddFilter={onAddFilter}
                    additionalFilters={additionalFilters}
                />
            </Stack.Item>
            <Stack.Item>
                <AppOverviewIconPanel
                    apps={pageConfig.appsList}
                    onAppClick={onAppFilterChange}
                    selectedApp={filters.application}
                />
            </Stack.Item>
            {loading ? (
                <Stack.Item style={{ marginTop: "10%" }}>
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>
            ) : (
                <>
                    <FunnelStages
                        filters={filters}
                        changeSelectedFunnel={(newFunnel) =>
                            handleFunnelChange(newFunnel)
                        }
                        changeSelectedDropOff={(newDropOff) =>
                            handleDropoffChange(newDropOff)
                        }
                        selectedStage={selectedFunnel}
                        selectedDropOff={selectedDropOff}
                        onFilterChange={onFilterChange}
                    />
                    <Stack.Item>
                        <button
                            className={`scrollForInsightsButton ${
                                showNewInsightsButton ? "" : "btnHidden"
                            }`}
                            aria-label="Scroll for new insights"
                            title="Scroll for new insights"
                            onClick={onClickScrollToInsights}
                        >
                            <Icon iconName="Down" className="downArrowToScroll" />
                            Scroll for new insights
                        </button>
                    </Stack.Item>
                    <Stack.Item>
                        <FunnelInsights
                            filters={filters}
                            stage={selectedFunnel}
                            droppedStage={getDroppedStageNames(
                                selectedFunnel,
                                filters.application,
                            )}
                            dropOff={selectedDropOff}
                        />
                    </Stack.Item>
                </>
            )}
        </Stack>
    );
};

export default ConsumerDay0Funnel;
