import { isTest } from "utils/WebConstants";

export const webDateFilters: string = `
    //webDateFilters
    let queryName = "webDateFilters";
    let currDate = GetCurrentDate(${isTest});
    Score_WXP 
        | where Application=="WXP" and UiHost == "All"
        | summarize make_set(Date)
        | mv-expand Date= set_Date
        | where todatetime(Date) <= todatetime(currDate)
        | project Date = tostring(Date)
        | union (
        Score_WXP_V2 
            | where Application=="WXP" and UiHost == "All"
            | summarize make_set(Date)
            | mv-expand Date= set_Date
            | where todatetime(Date) <= todatetime(currDate)
            | project Date = tostring(Date)
        )
        | distinct Date
        | order by Date desc
        | top 18 by Date`;
