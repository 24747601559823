import * as API from "api/index";
import { getDataTableName } from "components/CommonFunnelComponents/CommonFunnel/commonFunnelHelper";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { FunnelQueryType, FunnelType, getFunnelConfig } from "config/FunnelConfig";

export const getDates = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
) => {
    const payload = await fetchFunnelDataFromKusto(
        filters,
        funnelType,
        FunnelQueryType.Dates,
    );
    const dateOptions: string[] = payload?.Tables[0]?.Rows.map((date) => date[0]);

    return dateOptions;
};

export const fetchFunnelDataFromKusto = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
    queryType: FunnelQueryType,
) => {
    let queryParams = { ...filters };
    const tableName = getDataTableName(funnelType, filters.application);
    if (tableName) {
        queryParams["tableName"] = tableName;
    }
    if (filters.additionalFilters) {
        // If there are any additional pivots, add them to the query params too
        delete queryParams.additionalFilters;

        queryParams = { ...queryParams, ...filters.additionalFilters };
    }

    // Dates Schema: Date (in desc order)
    // Stages Schema: Date (in desc order), StageIndex (in asc order), StageName, StageFriendlyName, DropOffName, DropOffFriendlyName, CanBeNull, Value, Total, Percentage, ToolTipMessage
    // (following are Harmony only) HarmonyPlatform, RegisteredCountry, IsFromTemplate, IsOpen, IsSharedDoc, IsDay0Event, RegisteredLocale, PaymentTypeCohort, HarmonyUserSeenType, IsNewToWXP
    const funnelResponse = await API.getKustoResponse({
        queryName: `${funnelType.toLowerCase()}Funnel${queryType}`, // Example: HarmonyFunnelDates, HarmonyFunnelStages
        platform: getFunnelConfig(funnelType).dataPlatform,
        queryParams,
    });

    return funnelResponse?.data;
};
