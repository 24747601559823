import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./modules";

const composeEnhancers = compose;
const middleware = applyMiddleware(thunk);

export default () => {
    const store = createStore(rootReducer, composeEnhancers(middleware));
    return { store };
};

export type AppState = ReturnType<typeof rootReducer>;
