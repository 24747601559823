import {
    IComboBoxStyles,
    IDropdownOption,
    IDropdownStyles,
    mergeStyles,
    SelectableOptionMenuItemType,
} from "@fluentui/react";
import { ColumnType } from "components/OverviewComponents/types";
import { Workload } from "config/PlatformConfig";
import { ContactType, IPivotsize } from "utils/types";

export const IS_PROD_ENVIRONMENT = process.env.NODE_ENV === "production";

export const addinPublisherCategories: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Microsoft", text: "Microsoft" },
    { key: "3rd-Party", text: "3rd-Party" },
];

export const appStyles = {
    Word: {
        color: "blue",
        iconName: "img/word_32x.svg",
        smallIconName: "img/word_16x.svg",
    },
    Excel: {
        color: "green",
        iconName: "img/excel_32x.svg",
        smallIconName: "img/excel_16x.svg",
    },
    PowerPoint: {
        color: "red",
        iconName: "img/powerpoint_32x.svg",
        smallIconName: "img/powerpoint_16x.svg",
    },
    OneNote: {
        color: "purple",
        iconName: "img/onenote_32x.svg",
        smallIconName: "img/onenote_16x.svg",
    },
    Visio: {
        color: "#4eb3d3",
        iconName: "img/visio_32x.svg",
        smallIconName: "img/visio_16x.svg",
    },
    Outlook: {
        color: "#4eb3d3",
        iconName: "img/outlook_32x.svg",
        smallIconName: "img/outlook_16x.svg",
    },
    WXP: {
        color: "#8c510a",
        iconName: "img/wxp.svg",
        smallIconName: "img/wxp.svg",
    },
    WXPO: {
        color: "#e4f000",
        iconName: "img/wxpo.svg",
        smallIconName: "img/wxpo.svg",
    },
    WXPON: {
        color: "#050505",
        iconName: "img/wxpom.svg",
        smallIconName: "img/wxpon.svg",
    },
    All: {
        color: "#feb24c",
        iconName: "img/all.svg",
        smallIconName: "img/office_16x.svg",
    },
    Web: {
        color: "#feb24c",
        iconName: "Globe",
        smallIconName: "Globe",
        iconClassName: "platformIcon",
    },
    Win32: {
        color: "#feb24c",
        iconName: "WindowsLogo",
        smallIconName: "WindowsLogo",
        iconClassName: "platformIcon",
    },
    Teams: {
        color: "#5d5bd4",
        iconName: "img/Teams.svg",
        iconClassName: "platformIcon",
    },
    Mac: {
        color: "#4d56c0",
        iconName: "img/Mac_32x.svg",
        smallIconName: "img/Mac_32x.svg",
        iconClassName: "platformIcon",
    },
    Meeting: {
        color: "#feb24c",
        iconName: null,
        iconClassName: "platformIcon",
    },
    Chat: {
        color: "red",
        iconName: null,
        iconClassName: "platformIcon",
    },
    CrossPlatform: {
        color: "#feb24c",
        iconName: "img/all.svg",
        smallIconName: "img/office_16x.svg",
        iconClassName: "platformIcon",
    },
    Loop: {
        color: "#8230ff",
        iconName: "img/loop_32x.svg",
        smallIconName: "img/loop_32x.svg",
    },
    Whiteboard: {
        color: "#0f44f7",
        iconName: "img/Whiteboard.svg",
        smallIconName: "img/Whiteboard.svg",
    },
    WXPN: {
        color: "#a6611a",
    },
    Microsoft: {
        iconName: "img/microsoft.ico",
    },
    "M365 Chat": {
        color: "#ffa500",
        iconName: "img/M365Chat.svg",
        smallIconName: "img/M365Chat.svg",
    },
    Stream: {
        color: "#ff00ff",
        iconName: "img/Stream.svg",
        smallIconName: "img/Stream.svg",
    },
    Forms: {
        color: "#038387",
        iconName: "img/Forms_32x.svg",
        smallIconName: "img/Forms_32x.svg",
    },
    Planner: {
        color: "#a0d8b9",
        iconName: "img/Planner_32x.svg",
        smallIconName: "img/Planner_32x.svg",
    },
};

export const mauCountThreshold = 500;

export const OrderedStatusList = {
    Blue: 1,
    Yellow: 2,
    Regression: 3,
    Red: 4,
    DeepRed: 5,
    Gray: 6,
    NoData: 7,
    "": 7,
};

export const optionRootClass = mergeStyles({
    display: "flex",
    marginLeft: "10px",
    marginBottom: "10px",
});

const ModHCHITeam = "OPG Insights Platform";
const ModHCHIContact = "mailto:AskCHI@microsoft.com";

export const DefaultPageContact: ContactType = {
    name: ModHCHITeam,
    email: ModHCHIContact,
};

export const STATUS = {
    Blue: {
        color: "rgba(0, 120, 212, 0.57)",
        name: "Blue",
        definition: "Healthy",
        highlight: false,
    },
    Yellow: {
        color: "#ffc107",
        name: "Yellow",
        definition: "Low Risk",
        highlight: false,
    },
    Red: { color: "#f3501e", name: "Red", definition: "Mild Risk", highlight: true },
    DeepRed: {
        color: "#800000",
        name: "Deep Red",
        definition: "High Risk",
        highlight: true,
    },
    Gray: {
        color: "gray",
        name: "Gray",
        definition: "Not enough data points",
        highlight: false,
    },
    Regression: { color: "#9732a8", name: "Regression", highlight: true },
    "": {
        color: "#e0e0e091",
        name: "",
        definition: "No Data",
        highlight: false,
    },
};

export const tenantEnvironmentMetricsType = {
    SessionOrigin: "SessionOrigin",
    Location: "Location",
    Browser: "Browser",
    AppMode: "AppMode",
    OS: "OS",
    Currency: "Currency",
    OpenDocument: "OpenDocument",
    WacDatacenter: "WacDatacenter",
    Continent: "Continent",
    LicenseDistribution: "LicenseDistribution",
    SPOLocation: "SPO Location",
    AntiVirus: "AntiVirus",
    Audience: "Audience",
    Fork: "Fork",
    BootDiskType: "BootDiskType",
    Architecture: "Architecture",
    Ram: "RamGB",
    ProcessorCount: "ProcessorCount",
    DeviceAge: "DeviceAge",
    PredictedPriceBand: "PredictedPriceBand",
    OfficeDiagnosticConsentLevel: "OfficeDiagnosticConsentLevel",
    WindowsTelemetryLevel: "WindowsTelemetryLevel",
    DeviceManagement: "DeviceManagement",
    VmType: "VmType",
    OfficeUsage: "OfficeUsage",
    OfficeUsageBreakDown: "OfficeUsageBreakDown",
};

export const TenantMetricsTabs = {
    AppMetrics: "App Metrics",
    TenantEnvironment: "Tenant Environment",
    Engagements: "Engagements",
    M365AppsHealth: "Apps Admin Center",
    Copilot: "Copilot",
};

// TODO move this to config
export const platformExemptSignals = {
    [Workload.WEB]: [
        "ExemptMAU",
        "ExemptMEU",
        "ExemptReturningMAU",
        "ExemptEngagedEditors",
        "ExemptPerformance",
        "ExemptReliability",
        "ExemptFeedback",
        "ExemptOpenInClient",
    ],
    [Workload.WIN32]: [
        "ExemptMAU",
        "ExemptPerformance",
        "ExemptReliability",
        "ExemptFeedback",
        "ExemptCurrency",
    ],
    [Workload.MAC]: [
        "ExemptMAU",
        "ExemptPerformance",
        "ExemptReliability",
        "ExemptFeedback",
        "ExemptCurrency",
    ],
};

export const S2500Color = "#FFA500";

export const defaultTenantStatusHealthColor = "DeepRed";

export const defaultCompositeOverviewHealthColor = "DeepRed";

// Grouping Index Variables Together
export const coreMetricsHealthScoreIndex = 2;

export const coreMetricsHealthColourIndex = 3;

export const crossAppColourSortIndex = 7;

export const defaultWorkloadAppListIndex = 0;

export const lastGroupedSignals = {
    WXP: "PowerPoint",
    WXPO: "Outlook",
    WXPON: "OneNote",
};

export const headerColumnDetailsTable = {
    Score: [70, ColumnType.VALUE, "alignRightHeader"],
    Text: [70, ColumnType.TEXT, "alignRightHeader"],
    MoMChange: [40, ColumnType.MOM_CHANGE, "alignCenterHeader"],
    AppColor: [30, ColumnType.APPCOLOR, "alignCenterHeader"],
    Platform: [70, ColumnType.PLATFORM, "alignRightHeader"],
};

export const headerColumnWidthIndex = 0;
export const headerColumnTypeIndex = 1;
export const headerColumnAlignmentIndex = 2;

export const scorePrecisionValue = {
    Composite_Score: "2",
    ActiveUsers_Score: "0",
    MAU_Score: "0",
    Web_Score_Platform: "2",
    Win32_Score_Platform: "2",
    Web_MAU_Score: "0",
    Win32_MAU_Score: "0",
};

export const tenantNoteDisplayColumns = [
    ["Date", "Date Added", 75, null],
    ["TenantId", "TenantId", 75, null],
    ["IsExempted", "Tenant Exempted", 100, null],
    ["ExemptedFields", "Exempted Fields", 100, null],
    ["ExemptedUntil", "Exempted Until", 100, null],
    ["Notes", "Engagement Notes", 300, null],
    ["AddedBy", "Added By", 75, null],
];

export const NetworkScoreEndPoints = {
    Overall: "SandyBrown",
    Exchange: "SkyBlue",
    SharePoint: "Teal",
    Teams: "SlateBlue",
};

export const tenantTableConstants = {
    COLUMN_WIDTH: 80,
    FIXED_ROW_HEADER_COLUMN_WIDTH: 250,
};

// This enum holds all the applications except the default one for the platform which is WXPO for win32.
export const WorkloadApplicationNameStore = {
    Win32: {
        WXP: "WXP",
        WXPON: "WXPON",
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
        OneNote: "OneNote",
        Outlook: "Outlook",
    },
    Mac: {
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
        OneNote: "OneNote",
        Outlook: "Outlook",
    },
    Web: {
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
        OneNote: "OneNote",
        Visio: "Visio",
    },
    Teams: {
        Meeting: "Meeting",
        Chat: "Chat",
    },
    WebConsumer: {
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
    },
    ConsumerCamau: {
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
    },
    ERFM: {
        WXP: "WXP",
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
    },
    [Workload.COPILOT_COMMERCIAL]: {
        All: "All",
        Word: "Word",
        Excel: "Excel",
        PowerPoint: "PowerPoint",
    },
};

export const DefaultWorkloadAppButtonLabel = {
    Win32: "WXPO",
    Mac: "WXPON",
    Web: "WXP",
    Teams: "",
    WebConsumer: "",
    ConsumerCamau: "",
    ERFM: "",
    [Workload.COPILOT_COMMERCIAL]: "",
};

export const SignalTrendFilter = {
    DefaultPlatform: Workload.WEB,
    DefaultGroup: "Application",
    DefaultApp: "All",
    DefaultSignal: "All",
    DefaultCohort: "S500",
    DefaultColor: "All",
};

export const signalTrendPlatformOptions: IDropdownOption[] = [
    { key: "Web", text: "Web" },
    { key: "Win32", text: "Win32" },
];

export const signalTrendGroupOptions: IDropdownOption[] = [
    { key: "Application", text: "App" },
    { key: "Signal", text: "Signal" },
];

export const signalTrendWebAppOptions: IDropdownOption[] = [
    {
        key: "All",
        text: "Select All",
    },
    {
        key: "Divider",
        text: "-",
        itemType: SelectableOptionMenuItemType.Divider,
    },
    { key: "Word", text: "Word" },
    { key: "Excel", text: "Excel" },
    { key: "PowerPoint", text: "PowerPoint" },
    { key: "WXP", text: "WXP" },
];

export const signalTrendWin32AppOptions: IDropdownOption[] = [
    {
        key: "All",
        text: "Select All",
    },
    {
        key: "Divider",
        text: "-",
        itemType: SelectableOptionMenuItemType.Divider,
    },
    { key: "Word", text: "Word" },
    { key: "Excel", text: "Excel" },
    { key: "PowerPoint", text: "PowerPoint" },
    { key: "Outlook", text: "Outlook" },
    { key: "WXPO", text: "WXPO" },
];

export const signalTrendWebSignalOptions: IDropdownOption[] = [
    {
        key: "All",
        text: "Select All",
    },
    {
        key: "Divider",
        text: "-",
        itemType: SelectableOptionMenuItemType.Divider,
    },
    { key: "Performance", text: "Performance" },
    { key: "Feedback", text: "Feedback" },
    { key: "ACEFreeUsers", text: "ACE Free Users" },
    { key: "ICEFreeUsers", text: "ICE Free Users" },
    { key: "MAU", text: "MAU" },
    { key: "Returning MAU", text: "Returning MAU" },
    { key: "EngagedEditors", text: "Engaged Editors" },
    { key: "MEU", text: "MEU" },
    { key: "OpenInClient", text: "Open In Client" },
    { key: "ASHA", text: "ASHA" },
];

export const signalTrendWin32SignalOptions: IDropdownOption[] = [
    {
        key: "All",
        text: "Select All",
    },
    {
        key: "Divider",
        text: "-",
        itemType: SelectableOptionMenuItemType.Divider,
    },
    { key: "Performance", text: "Performance" },
    { key: "Feedback", text: "Feedback" },
    { key: "Currency", text: "Currency" },
    { key: "UAE", text: "UAE" },
    { key: "Watson", text: "Watson" },
];

export const signalTrendWebCohortOptions: IDropdownOption[] = [
    { key: "S500", text: "S500" },
    { key: "S2500", text: "S2500" },
    { key: "EPA", text: "EPA" },
    { key: "GoogleMove", text: "GoogleMove" },
];

export const signalTrendWin32CohortOptions: IDropdownOption[] = [
    { key: "S500", text: "S500" },
    { key: "S2500", text: "S2500" },
    { key: "EPA", text: "EPA" },
];

export const signalTrendColorOptions: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Red", text: "Red" },
    { key: "DeepRed", text: "Deep Red" },
    { key: "Regression", text: "Regression" },
];

export const signalTrendFilterOptions = {
    Web: {
        app: signalTrendWebAppOptions,
        signal: signalTrendWebSignalOptions,
        cohort: signalTrendWebCohortOptions,
    },
    Win32: {
        app: signalTrendWin32AppOptions,
        signal: signalTrendWin32SignalOptions,
        cohort: signalTrendWin32CohortOptions,
    },
};

export const MtrPivots = {
    MTR: {
        headerText: "MTR",
        itemKey: "MTR",
    },
    SignalTrends: {
        headerText: "Signal Trends",
        itemKey: "SignalTrends",
    },
};

export const TenantsWithNoDataLabel = "NoData";

export const OrderedAppList = {
    WXP: 1,
    WXPO: 2,
    Word: 3,
    Excel: 4,
    PowerPoint: 5,
    Outlook: 6,
    "": 7,
};

export const OrderedSignalList = {
    MAU: 1,
    MEU: 2,
    ReturningMAU: 3,
    EngagedEditors: 4,
    ACEFreeUsers: 5,
    ICEFreeUsers: 6,
    Performance: 7,
    Currency: 8,
    UAE: 9,
    Feedback: 10,
    Watson: 11,
    OpenInClient: 12,
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 130 },
};

export const wideDropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 160 },
};

export const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { width: 143 },
};

export const HealthyTenantTrendPivots = {
    Chart: {
        headerText: "Chart",
        itemKey: "Chart",
    },
    Table: {
        headerText: "Table",
        itemKey: "Table",
    },
};

export const PivotSize: IPivotsize = {
    normal: "normal",
    large: "large",
};

export const SLAs = {
    Consumer: "6 calendar days after every Saturday",
    Copilot: "4 calendar days after every Saturday and last day of each month",
    ERFM: "6 calendar days after every Saturday",
    Default: "7 business days after the 28th of each month",
};

export const defaultLargeDropDownWidth = 175;

export const defaultExtraLargeDropDownWidth = 225;
