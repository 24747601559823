import React from "react";
import { Dropdown, Icon, Label, TooltipHost } from "@fluentui/react";
import { getCurrentSLAText } from "components/InsightsComponents/Helpers";
import { defaultLargeDropDownWidth } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";

export const DatePicker = ({
    dateOptions,
    onChangeSelection,
    selected,
    shouldFormat = true,
}) => {
    if (shouldFormat)
        dateOptions.forEach((element) => {
            element.text = formatDisplayDate(element.key);
        });

    return (
        <>
            <TooltipHost content={`SLA: ${getCurrentSLAText()}`}>
                <Icon iconName="info" className="slaInfoIcon" />
            </TooltipHost>
            <Label className="filterLabel">Time Period: </Label>
            <Dropdown
                options={dateOptions}
                onChange={onChangeSelection}
                selectedKey={selected}
                className="filterDropdown"
                dropdownWidth={defaultLargeDropDownWidth}
            />
        </>
    );
};
