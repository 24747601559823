export const win32TenantMetrics: string = `
// win32TenantMetrics
let level = iff("\${level}" == "Tpid", "Parent", "Tenant");
TenantInsights_Win32_Metrics(level, "\${id}")
| where Date == "\${date}"
| project Date, Metric, DisplayName, Group, Application, Value = iff(isempty( Value), "NA",Value)
| extend GroupOrder = TenantInsights_Win32_GetMetricGroupOrder(Group), MetricOrder = TenantInsights_Win32_GetMetricOrder(Metric)
| order by GroupOrder asc, MetricOrder asc, DisplayName asc
| project-away MetricOrder, GroupOrder
`;

export const win32TenantMetricsChart: string = `
// win32TenantMetricsChart
let level = iff("\${level}" == "Tpid", "Parent", "Tenant");
TenantInsights_Win32_Metrics(level, "\${id}")
| where Metric == "\${metric}"
| project Date, Application, Value = round(todecimal(Value),2)
| order by Date asc
`;
