export const getCrossAppBorderStyles = (): {} => {
    return {
        borderLeft: "1px solid #c2c2c2",
        paddingLeft: 1,
    };
};

export const tenantTableStyle = {
    root: {
        alignContent: "center",
        overflowX: "visible",
    },
};
