import React, { useState, useEffect } from "react";
import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import {
    AIMBRAppOverviewColumns,
    AIMBRFeatureScorecardColumns,
} from "components/AIScorecardComponents/config";
import CopilotAppMBRTable from "components/AIScorecardComponents/CopilotAppMBRTable";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchCopilotData,
    formatMBRFeatureScorecardTableData,
    formatMBROverviewScorecardData,
} from "components/AIScorecardComponents/ApiHandler";
import { MBRScorecardType } from "components/AIScorecardComponents/types";
import { commonPageBlock } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";

export const CopilotAppMBRScorecard = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [state, setState] = useState<MBRScorecardType>({
        loading: false,
        featureData: [],
        overviewData: [],
    });

    useEffect(() => {
        const getScorecardData = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });
            setError(null);

            try {
                const [featureData, goalConfig, overviewData, appGoalConfig] =
                    await Promise.all([
                        fetchCopilotData(
                            {
                                application: filters.application,
                                date: filters.date,
                                audienceGroup: filters.audienceGroup,
                                office365Category: filters.office365Category,
                                platform: filters.platform,
                                cadence: filters.cadence,
                                cohort: filters.cohort,
                                tenantId: "All",
                            },
                            "aiMBRScorecardMetrics",
                        ),
                        fetchCopilotData(
                            {
                                application: filters.application,
                            },
                            "aiMBRFeatureGoals",
                        ),
                        fetchCopilotData(
                            {
                                application: filters.application,
                                date: filters.date,
                                audienceGroup: filters.audienceGroup,
                                office365Category: filters.office365Category,
                                platform: filters.platform,
                                cadence: filters.cadence,
                                cohort: filters.cohort,
                                tenantId: "All",
                            },
                            "aiMBROverviewScorecardMetrics",
                        ),
                        fetchCopilotData(
                            {
                                application: filters.application,
                            },
                            "aiMBRAppGoals",
                        ),
                    ]);

                let formattedData = formatMBRFeatureScorecardTableData(
                    featureData,
                    goalConfig,
                );

                let formattedOverviewData = formatMBROverviewScorecardData(
                    overviewData,
                    appGoalConfig,
                );

                setState((oldState) => {
                    return {
                        ...oldState,
                        loading: false,
                        featureData: formattedData,
                        overviewData: formattedOverviewData,
                    };
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotAppMBRScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot App MBR Scorecard data",
                    },
                    e,
                );

                setError(e.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        loading: false,
                        featureData: [],
                        overviewData: [],
                    };
                });
            }
        };

        if (filters.date != null) getScorecardData();
    }, [
        filters.application,
        filters.date,
        filters.audienceGroup,
        filters.office365Category,
        filters.platform,
        filters.cadence,
        filters.cohort,
    ]);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <MessageBar messageBarType={MessageBarType.error}>
                This scorecard is work in progess. Reliability and Performance data
                is updated manually and may be incorrect or missing.
            </MessageBar>
            <Stack.Item
                styles={commonPageBlock}
                style={{
                    maxWidth: "1044px",
                }}
            >
                <div className="commonPageBlockSubTitle">{"App Scorecard"}</div>
                <CopilotAppMBRTable
                    items={state.overviewData}
                    loading={state.loading}
                    filters={filters}
                    columnConfig={AIMBRAppOverviewColumns}
                    className="mbrOverviewScorecardTable"
                />
            </Stack.Item>
            <Stack.Item
                styles={commonPageBlock}
                style={{
                    maxWidth: "1648px",
                }}
            >
                <div className="commonPageBlockSubTitle">{"Feature Scorecard"}</div>
                <CopilotAppMBRTable
                    items={state.featureData}
                    loading={state.loading}
                    filters={filters}
                    columnConfig={AIMBRFeatureScorecardColumns}
                    className="mbrScorecardTable"
                />
            </Stack.Item>
        </>
    );
};
