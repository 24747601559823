export const win32OfficeTenantScore = `
//win32OfficeTenantScore (To display Office Tenant component, which has app contributions)
let level = "\${level}";
let app = "\${crossAppRankOn}";
let dt = "\${date}";
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_Win32Overview | project-rename Tpid = TopParentId
| where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}"
and Date == dt and Application == app
| project Score, Color, MoMChange, OmsTenantId, Tpid, HealthScoreContribution
| join kind=leftouter (
    HealthyTenantScore_PastStatus | project-rename Tpid = TopParentId
    | where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application ==  app) on \${levelColumnName}
| project Score=round(Score,2), Color = GetColor(Color), Score_MoMChange = round(MoMChange,2), PastStatus, HealthScoreContribution
`;
