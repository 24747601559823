import { isTest } from "utils/WebConstants";

export const cohortAnalysis: {} = {
    DatesAvailable: `
                        let currDate = GetCurrentDate(${isTest});
                        CohortOverview
                        | where Application == "\${app}"
                        | summarize make_set(Date)
                        | mv-expand Date= set_Date
                        | where todatetime(tostring(Date)) <= todatetime(currDate)
                        | project tostring(Date)
                        | order by Date desc 
                        | top 18 by Date`,

    CohortCategories: `
                        let currDate = GetCurrentDate(${isTest});
                        let dt = iff(isempty("\${date}"), currDate, "\${date}");
                        // Categories for the cohort
                        CohortOverview
                        | where Application == "\${app}" and Date == dt
                        | summarize count() by CohortCategory
                        | order by CohortCategory asc
                        | where count_ > 10`,

    OverallCohortReliability: `
                        let currDate = GetCurrentDate(${isTest});
                        let dt = iff(isempty("\${date}"), currDate, "\${date}");
                        CohortOverview
                        | where Application == "\${app}" and CohortCategory == "\${cohortCategory}" and Metric == "Rewards_ACEFreeUsers"
                        | where IsS500 == "Both"
                        | where Date == dt
                        | where Cohort !in ("Other", "nan")
                        | project Cohort, ACE_OR = OddsRatio, ACE_IS = Impact
                        | order by ACE_OR desc`,

    CohortErrors: `
                        let currDate = GetCurrentDate(${isTest});
                        let dt = iff(isempty("\${date}"), currDate, "\${date}");
                        CohortErrors
                        | where Application == "\${app}" and CohortCategory == "\${cohortCategory}" and Cohort == "\${cohort}"
                        | where Date == dt
                        | where round(OddsRatio, 2) > 2 and (Metric == "TotalSessions" and Volume > 100)
                        | project Error = ErrorName, ACE_OR = OddsRatio, SessionErrorCount = Volume, Score = Impact, ScoreRank = Ranking
                        | sort by SessionErrorCount asc`,

    CohortErrorBreakdown: `
                        let currDate = GetCurrentDate(${isTest});
                        let dt = iff(isempty("\${date}"), currDate, "\${date}");
                        CohortApplicationReliability
                        | where Application == "\${app}" and Cohort == "\${cohort}"
                        | where Date == dt
                        | join kind=inner (
                            CohortErrors
                            | where Application == "\${app}" and Cohort == "\${cohort}"
                            | where Date == dt
                            | where round(OddsRatio, 2) > 2 and (Metric == "TotalSessions" and Volume > 100)
                            | extend Application, ErrorName
                            | project Application, ErrorName, ACE_OR = OddsRatio, Score = Impact
                            )
                            on Application, ErrorName
                        | join kind=leftouter (
                            StrategicTenants
                            | where IsS2500 == true 
                            | extend OrgName = TenantName_Translated
                            | distinct OmsTenantId, OrgName
                            | join kind = inner
                            (
                                Reliability
                                | where Application == "\${app}" and Date == dt
                                | where OmsTenantId != "All"
                                | where Host == "All" and UiHost == "All" 
                            ) on OmsTenantId
                            | summarize sum(UserErrorCount), sum(TotalUsers), sum(SessionErrorCount), sum(TotalSessions) by ErrorName, Application, Date
                        | extend
                            S2500PercentageUsersWithError = todouble(sum_UserErrorCount) / sum_TotalUsers * 100,
                            S2500PercentageSessionsWithError = todouble(sum_SessionErrorCount) / sum_TotalSessions * 100
                        | project
                            Date,
                            Application,
                            ErrorName,
                            S2500PercentageUsersWithError,
                            S2500PercentageSessionsWithError
                        )
                        on Application, ErrorName
                        | project Date=Date1, Application=Application, ErrorName=ErrorName, ErrorType, OddsRatio = ACE_OR, Score, 
                        PercentUsersWithError = todouble(UserErrorCount) / TotalUsers * 100, S2500PercentageUsersWithError,
                        PercentSessionsWithError = todouble(SessionErrorCount) / TotalSessions * 100, S2500PercentageSessionsWithError, Current = 1, UserErrorCount
                        | sort by Date, Application, PercentUsersWithError`,

    TenantsImpactedInCohort: `
                        let currDate = GetCurrentDate(${isTest});
                        let dt = iff(isempty("\${date}"), currDate, "\${date}");
                        Reliability
                        | where OmsTenantId != "All"
                        | where Application == "\${app}" and Host == "All" and UiHost == "All" and ErrorName == "\${errorname}" and Date==dt 
                        | project-away Application, Host, UiHost
                        | join kind=inner (
                            StrategicTenants
                            | where Date== currDate
                            | extend Name = TenantName_Translated
                            | distinct OmsTenantId, Name, Industry
                        ) on OmsTenantId
                        | join kind=inner (
                            CohortTenantReliability
                            | where Date == dt and Application == "\${app}" and Cohort == "\${cohort}" and ErrorName == "\${errorname}"
                        ) on OmsTenantId
                        | distinct OmsTenantId, Name, Industry, ErrorType, UserErrorCount, PercentUsersWithError, SessionErrorCount, PercentSessionsWithError, Impact
                        | order by PercentUsersWithError desc`,
};
