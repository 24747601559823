import React from "react";
import {
    DetailsRow,
    FontSizes,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    SelectionMode,
    ShimmeredDetailsList,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { TenantsWithNoDataLabel } from "components/OverviewComponents/HealthDistribution/TenantsWithNoData";
import { HealthDistributionTableProps } from "pages/ExecOverview/types";
import { OrderedStatusList } from "utils/Constants";
import { STATUS } from "utils/WebConstants";

const columns: Array<IColumn> = [
    {
        key: "Status",
        name: "Status",
        fieldName: "Status",
        minWidth: 150,
        maxWidth: 150,
        isResizable: false,
        isPadded: false,
    },
    {
        key: "Count",
        name: "Tenant Count",
        fieldName: "Count",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
        isPadded: false,
    },
    {
        key: "Percent",
        name: "Tenant Percent",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
        isPadded: false,
    },
];

export const HealthDistributionTable = ({
    payload,
    loading,
}: HealthDistributionTableProps) => {
    interface TenantHealthStats {
        Status: string;
        Count: string;
        Order: number;
    }
    const data: TenantHealthStats[] = [];
    let total = 0;
    payload
        .filter((x) => x[1] !== TenantsWithNoDataLabel)
        .forEach((element) => {
            data.push({
                Status: element[1],
                Count: element[2],
                Order: Number(OrderedStatusList[element[1]]),
            });
            total += element[2];
        });

    const onRenderRow = (props: IDetailsRowProps): JSX.Element => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        customStyles.root = {
            color: "black",
            fontSize: FontSizes.large,
        };

        return (
            <DetailsRow
                {...props}
                onRenderItemColumn={onRenderItemColumn}
                styles={customStyles}
            />
        );
    };

    const onRenderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        const fieldColor = STATUS[fieldContent]
            ? STATUS[fieldContent]["color"]
            : STATUS[""]["color"];
        const fieldName = STATUS[fieldContent]
            ? STATUS[fieldContent]["name"]
            : STATUS[""]["name"];
        const fieldTooltip = STATUS[fieldContent]
            ? STATUS[fieldContent]["definition"]
            : STATUS[""]["definition"];

        switch (column.key) {
            case "Status":
                return (
                    <div title={fieldTooltip}>
                        <StatusIndicator color={fieldColor} id="statusLegend" />
                        {fieldName}
                    </div>
                );
            case "Percent":
                return (
                    <DetailsListHelper.NumericalValue
                        value={(item.Count / total) * 100}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return <div>{fieldContent}</div>;
        }
    };
    return (
        <>
            <h2 className="commonPageBlockTitle"> Monthly Status </h2>
            <ShimmeredDetailsList
                items={data.sort((a, b) => a["Order"] - b["Order"])}
                enableShimmer={loading}
                columns={columns}
                compact
                selectionMode={SelectionMode.none}
                onRenderRow={onRenderRow}
                styles={{
                    root: {
                        maxWidth: 440,
                        overflowX: "hidden",
                        paddingLeft: "40px",
                    },
                }}
            />
        </>
    );
};
