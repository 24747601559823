import { IColumn } from "@fluentui/react";

export const tenantListColumns: Array<IColumn> = [
    {
        key: "1",
        name: "",
        fieldName: "OmsStatus",
        minWidth: 10,
        maxWidth: 20,
    },
    {
        key: "2",
        name: "Tenant Name",
        fieldName: "TenantName",
        minWidth: 195,
        maxWidth: 195,
    },
];

export const generateTenantListTpidGroups = (
    payload: any[],
    isCollapsed: boolean,
) => {
    const tenantGroup = {};
    let index = 0;

    // Payload is already sorted
    payload.forEach((element) => {
        const tpid: string = element["Tpid"];

        if (!(tpid in tenantGroup)) {
            tenantGroup[tpid] = {
                name: element["OrganizationName"],
                status: element["TpidStatus"],
                startIndex: index,
                isEmptyGroup: element["TenantId"] === "",
            };
        }
        tenantGroup[tpid]["endIndex"] = index;

        index += 1;
    });

    return Object.keys(tenantGroup)
        .map((tpid) => {
            const sIdx = tenantGroup[tpid]["startIndex"];
            const eIdx = tenantGroup[tpid]["endIndex"];

            return {
                key: tpid,
                name: tenantGroup[tpid]["name"],
                startIndex: sIdx,
                endIndex: eIdx,
                level: 0,
                count: tenantGroup[tpid]["isEmptyGroup"] ? 0 : eIdx - sIdx + 1,
                isCollapsed,
                isEmptyGroup: tenantGroup[tpid]["isEmptyGroup"],
                status: tenantGroup[tpid]["status"],
            };
        })
        .sort((a, b) => a.startIndex - b.startIndex);
};

export const getSearchRankForTenant = (text: string, tenant: any) => {
    const exactMatchRank =
        tenant.OrganizationName.toLowerCase() === text ||
        tenant.Tpid.toString() === text ||
        tenant.TenantName.toLowerCase() === text ||
        tenant.TenantId.toString() === text
            ? -1 // Exact match should pop to the top of the list
            : Number.MAX_SAFE_INTEGER;

    const isMsftRank =
        tenant["Show"] == true && tenant.Tpid.toString() === "23321296"
            ? tenant.TenantId.toString() == "72f988bf-86f1-41af-91ab-2d7cd011db47"
                ? -100
                : -2
            : Number.MAX_SAFE_INTEGER;

    return Math.min(
        exactMatchRank,
        isMsftRank,
        tenant.OrganizationName.toLowerCase().indexOf(text) > -1
            ? tenant.OrganizationName.toLowerCase().indexOf(text)
            : Number.MAX_SAFE_INTEGER,
        tenant.Tpid.toString().indexOf(text) > -1
            ? tenant.Tpid.toString().indexOf(text)
            : Number.MAX_SAFE_INTEGER,
        tenant.TenantName.toLowerCase().indexOf(text) > -1
            ? tenant.TenantName.toLowerCase().indexOf(text)
            : Number.MAX_SAFE_INTEGER,
        tenant.TenantId.toString().indexOf(text) > -1
            ? tenant.TenantId.toString().indexOf(text)
            : Number.MAX_SAFE_INTEGER,
    );
};
