export const webconsumerCustomerList: string = `
    // Web consumer Cohort List
    let app = "WXP";
    let selectedDate = toscalar(ConsumerOverviewScore | summarize max(Date));
    let pivot = "All";
    let host = "OneDriveWOPI";
    ConsumerOverviewScore
    | where Application == app and Date == selectedDate and PivotName == pivot and Host == host
    | project CohortType, CohortName, Status = Score_Color
    | order by CohortType, CohortName`;
