export const perfInsightsQuery = `
// perfInsightsQuery: Win32 Performance Insights Query
let tenantId = "\${tenantId}";
let month = "\${date}";
let metric= "\${metric}";
let sortOrder= "\${sortOrder}";
let filteredResults =  \${tableName}
| where SQLizerStreamDateId == month 
| where TenantId ==  tenantId
| where Scenario in (iff(("\${scenario}" == "Boot-Overall"),dynamic(["Boot-File","Boot-Launch"]),dynamic(["\${scenario}"])))
| extend PercentBelowThreshold = EventUnderThresholdCount * 100 / EventCount
| project DimensionName, DimensionValue, Scenario, EventCount, P95Duration, PercentBelowThreshold, DeviceCount, AppName;
let maxEventCountTable = filteredResults
| summarize maxEventCount = max(EventCount) by Scenario, AppName;
let p95ForDominantFork = filteredResults
| where isempty(DimensionName)
| project AppName, Scenario, P95Baseline = P95Duration;
filteredResults
| join kind=inner maxEventCountTable on AppName, Scenario
| project-away AppName1, Scenario1
| join kind=inner p95ForDominantFork on AppName, Scenario
| project-away AppName1, Scenario1
| where EventCount > maxEventCount/100 
| where P95Duration > P95Baseline
| sort by \${metric} \${sortOrder}
| join kind = inner(filteredResults
| where isempty(DimensionName)
| extend Metric = iff(Scenario == "Boot-Launch", "BootLaunchP95", "BootFileP95")
| evaluate pivot(Metric, sum(P95Duration), AppName)) on AppName
| project-away AppName1
| sort by \${metric} \${sortOrder}`;
