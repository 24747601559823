export const perfChartQuery = `
// perfChartQuery: Data that will go in the Win32Performance line charts
let IsLargeEnterprise=toscalar(\${rankingTableName}
| where TenantId == "\${tenantId}"
| summarize arg_max(SQLizerStreamDateId, IsLargeEnterprise)
| project IsLargeEnterprise);
union
(\${tenantTableName}
| where SQLizerStreamDateId between (datetime("\${startDate}")..datetime("\${endDate}"))
| where TenantId == "\${tenantId}" and DimensionName == "\${dimensionName}"
| extend PercentBelowThreshold=100.0*EventUnderThresholdCount/EventCount
| project AppName, Scenario, Date=SQLizerStreamDateId, DeviceCount, EventCount, DominantFork, Value=\${valuesToShow}, Series=DimensionValue
| sort by Series, Date asc),
(\${overallTableName}
| where SQLizerStreamDateId between (datetime("\${startDate}")..datetime("\${endDate}"))
| where Cohort == iff(IsLargeEnterprise, "LargeEnterprise", "All")
| extend PercentBelowThreshold=100.0*EventUnderThresholdCount/EventCount
| project AppName, Scenario, Date=SQLizerStreamDateId, DeviceCount, EventCount, Value=\${valuesToShow}, Series=iff(IsLargeEnterprise, "Large Enterprises", "Overall")
| sort by AppName, Scenario, Series, Date asc
);`;
