import React from "react";
import { SearchBox } from "@fluentui/react";
import { SearchBarWithSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/SearchBarWithSuggestions";
import { getSearchRankForTenant } from "components/SharedComponentFunctionality/TenantListSharedFunctionality";
import { SearchSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/types";
import { getConfig, Workload } from "config/PlatformConfig";
import { TenantsListType } from "pages/ExecOverview/types";
import { addDay, getDay } from "utils/Helpers";
import TenantSearchResultsDialog from "../TenantSearchResultsDialog/TenantSearchResultsDialog";
import { TenantSearchBarProps, TenantSearchSuggestions } from "./types";

const loadTenantDrilldown = (
    id: string,
    level: string,
    workload: string,
    dateFilter: string,
) => {
    let pathName = "";
    const platformConfig = getConfig(workload);
    const dayOfTheDate = getDay(dateFilter);
    switch (workload) {
        case Workload.WIN32:
        case Workload.MAC:
            dateFilter = dayOfTheDate !== 28 ? addDay(dateFilter, 27) : dateFilter;
            pathName = `/${platformConfig.productInsightsPagePath}?level=${level}&id=${id}&date=${dateFilter}`;
            break;
        case Workload.COPILOT_COMMERCIAL:
            pathName = `/${platformConfig.productInsightsPagePath}?level=${level}&id=${id}&date=${dateFilter}&platform=All&scoreType=Readiness&application=All`;
            break;
        default:
            dateFilter = dayOfTheDate !== 1 ? addDay(dateFilter, -27) : dateFilter;
            pathName = `/tenant?level=${level}&id=${id}&date=${dateFilter}`;
            break;
    }
    window.open(pathName, "_blank");
};

const TenantSearchBar = ({
    tenantsData,
    dateFilter,
    isTpidHeaderClickable,
    showTenantStatus,
}: TenantSearchBarProps) => {
    const defaultSearchPrompt: string = "Tenant Drilldown Search";
    const isDataLoaded = tenantsData && Object.keys(tenantsData).length > 0;

    const onChangeText = (
        tenantListItems: TenantsListType,
        text: string,
    ): TenantSearchSuggestions => {
        let isCollapsed = true;
        if (text) {
            text = text.toLowerCase();
            tenantListItems.rows.forEach((i) => {
                if (isCollapsed) {
                    isCollapsed = !(
                        i.TenantName.toLowerCase().indexOf(text) > -1 ||
                        i.TenantId.toString().indexOf(text) > -1
                    ); // To expand groups is tenant searched is oms level
                }

                i["Show"] =
                    i.OrganizationName.toLowerCase().indexOf(text) > -1 ||
                    i.Tpid.toString().indexOf(text) > -1 ||
                    i.TenantName.toLowerCase().indexOf(text) > -1 ||
                    i.TenantId.toString().indexOf(text) > -1;

                i["Rank"] = getSearchRankForTenant(text, i);
            });
        }

        return {
            headerName: tenantListItems.headerName,
            rows: tenantListItems.rows
                .filter((i) => i.Show)
                .sort((a, b) => (a.Rank > b.Rank ? 1 : -1)),
            workload: tenantListItems.workload,
            callback: (id: string, level: string, workload) => {
                return loadTenantDrilldown(id, level, workload, dateFilter);
            },
        };
    };

    const filterResults = (text: string) => {
        if (text) {
            const searchResult: SearchSuggestions = {
                renderResults(): JSX.Element {
                    const listOfTenants = Object.keys(tenantsData).map((key) => {
                        return onChangeText(tenantsData[key], text);
                    });
                    return (
                        <TenantSearchResultsDialog
                            tenants={listOfTenants}
                            isTpidHeaderClickable={isTpidHeaderClickable}
                            showTenantStatus={showTenantStatus}
                        />
                    );
                },
            };
            return searchResult;
        }
    };

    return (
        <div>
            {isDataLoaded ? (
                <SearchBarWithSuggestions
                    defaultPrompt={defaultSearchPrompt}
                    searchSuggestionFilterCallback={(value) => {
                        return filterResults(value);
                    }}
                />
            ) : (
                <SearchBox placeholder={defaultSearchPrompt} disabled />
            )}
        </div>
    );
};

export default TenantSearchBar;
