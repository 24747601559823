import React from "react";
import { getConfig } from "config/PlatformConfig";
import { TenantsWithNoDataProps } from "pages/ExecOverview/types";

export const TenantsWithNoDataLabel = "NoData";

export const TenantsWithNoData = ({ payload, platform }: TenantsWithNoDataProps) => {
    const currentMonth = (item: (string | number | boolean)[]) => {
        return item[3] === true;
    };
    const config = getConfig(platform);
    if (config?.featureConfig?.ExecOverview?.TenantsWithNoDataLabelEnabled) {
        const currentMonthData = payload.filter((i) => currentMonth(i));
        const entry = config?.featureConfig?.ExecOverview?.IsNoDataLabelNewRow
            ? currentMonthData.filter((i) => i[1] === TenantsWithNoDataLabel)[0]
            : currentMonthData[0];
        const idx = config?.featureConfig?.ExecOverview?.TenantsWithNoDataLabelIndex;

        if (entry && entry[idx])
            return (
                <span className="subscriptStyleBottom">
                    {`*${entry[idx]} tenants do not have enough signals.`}
                </span>
            );
    }
    return null;
};
