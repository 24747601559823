import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import {
    SignalTrendData,
    SignalTrendProps,
    SignalTrendState,
} from "pages/MTR/types";
import { OrderedAppList, OrderedSignalList } from "utils/Constants";
import { SignalTrendChart } from "./SignalTrendChart";

export const groupData = (
    signalTrends: SignalTrendData[],
    filters: SignalTrendProps["filters"],
) => {
    if (filters.app !== "All" || filters.signal !== "All") {
        signalTrends = filterMultiSelect(signalTrends, filters);
    }

    const groupedSignalTrends = Object.values(
        signalTrends.reduce((acc, item) => {
            const key = item[filters.group];
            if (!acc[key]) {
                acc[key] = [];
            }
            if (!acc[key][item.Date]) {
                acc[key][item.Date] = {
                    Key: key,
                    Date: item.Date,
                    Red: 0,
                    DeepRed: 0,
                    Regression: 0,
                    Order: 0,
                };
            }
            acc[key][item.Date][item.Color] += item.TenantCount;
            acc[key][item.Date]["Order"] =
                filters.group === "Signal"
                    ? OrderedSignalList[item.Signal]
                    : OrderedAppList[item.Application];
            return acc;
        }, []),
    );

    const chartData = new Array(groupedSignalTrends.length);
    for (let i = 0; i < groupedSignalTrends.length; i++) {
        chartData[i] = [];
        Object.keys(groupedSignalTrends[i]).forEach((date) => {
            chartData[i].push(groupedSignalTrends[i][date]);
        });
    }
    chartData.sort((a, b) => a[0]["Order"] - b[0]["Order"]);
    return chartData;
};

export const filterMultiSelect = (
    signalTrends: SignalTrendData[],
    filters: SignalTrendProps["filters"],
): SignalTrendData[] => {
    const separator = ",";

    return signalTrends.filter((signalTrend) => {
        switch (filters.group) {
            case "Signal":
                const apps = filters.app.split(separator);
                return apps.some((app) => signalTrend.Application === app);
            case "Application":
                const signals = filters.signal.split(separator);
                return signals.some((signal) => signalTrend.Signal === signal);
            default:
                return signalTrends;
        }
    });
};

export const SignalTrend = ({ payload, filters, loading }: SignalTrendProps) => {
    const initialData: SignalTrendState = {
        data: [],
        filters,
        loading,
    };
    const [state, setState] = useState<SignalTrendState>(initialData);

    useEffect(() => {
        if (!loading && payload.rows.length > 0) {
            const signalTrends: SignalTrendData[] = [];

            payload.rows.forEach((element) => {
                const signal = String(element[0]);
                const app = String(element[1]);
                const date = String(element[2]);
                const color = String(element[3]);
                const tenantCount = Number(element[4]);

                signalTrends.push({
                    Signal: signal,
                    Application: app,
                    Date: date,
                    Color: color,
                    TenantCount: tenantCount,
                });
            });

            setState({
                data: groupData(signalTrends, filters),
                filters,
                loading,
            });
        }
    }, [payload, filters, loading]);

    return (
        <Stack className="signalTrends" horizontal wrap>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                Object.keys(state.data).map((item, idx) => {
                    return (
                        <Stack.Item key={idx}>
                            <SignalTrendChart data={state.data[item]} />
                        </Stack.Item>
                    );
                })
            )}
        </Stack>
    );
};
