import React from "react";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    footerDownArrowFillColor,
    footerDownArrowStrokeColor,
    footerHeight,
    footerWidth,
    arrowWidth,
    arrowHeight,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnelFooter/constants";
import "components/ConsumerDay0FunnelComponents/CompositeFunnelFooter/style.css";
import { CompositeFunnelFooterProps } from "components/ConsumerDay0FunnelComponents/CompositeFunnelFooter/types";
import { ShortenedFunnelStageDropOffNames } from "pages/ConsumerDay0Funnel/constants";

export const CompositeFunnelFooter = ({
    nextStageName,
    stageDeltaValue,
}: CompositeFunnelFooterProps) => {
    const emptyRect = (
        <svg width={footerWidth} height={footerHeight}>
            <rect height={footerHeight} width={footerWidth} opacity={0} />
        </svg>
    );
    return (
        <>
            {nextStageName === "FirstSessions" ? (
                <div>
                    {emptyRect}
                    <div className="compositeFunnelFooterTextContainer">
                        {emptyRect}
                    </div>
                </div>
            ) : (
                <>
                    <div className="compositeFunnelArrowTextContainer">
                        <svg width={arrowWidth} height={arrowHeight}>
                            <path
                                transform="rotate(180 16.5 25)"
                                stroke={footerDownArrowStrokeColor}
                                id="compositeFunnelFooterDownArrow"
                                d="m0,24.9652l16.5,-14.4652l16.5,14.4652l-8.25,0l0,14.5348l-16.5,0l0,-14.5348l-8.25,0z"
                                fill={footerDownArrowFillColor}
                            />
                        </svg>
                    </div>
                    <div className="compositeFunnelFooterTextContainer">
                        <div className="compositeFunnelFooterStageName">
                            {ShortenedFunnelStageDropOffNames[nextStageName]}
                        </div>
                        {nextStageName === "FirstSessions"
                            ? "All"
                            : getCompactValue(stageDeltaValue)}
                    </div>
                </>
            )}
        </>
    );
};
