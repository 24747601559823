import React from "react";
import { CSVLink } from "react-csv";
import { Shimmer, ShimmerElementType, Stack } from "@fluentui/react";
import { CommandBarButton } from "@fluentui/react/lib/Button";
import NavigableDatePicker from "components/CustomComponents/NavigableDatePicker/NavigableDatePicker";
import { ScorecardTableColumnConfig } from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardHelper";
import { ConsumerGrowthScorecardFiltersPropsType } from "components/ConsumerGrowthScorecardComponents/types";
import "pages/common.css";
import { getExportCsvHeaders } from "utils/Helpers";
import { InsightsRoutes } from "utils/Links";

const GrowthScorecardFilters = ({
    growthScorecardState,
    filters,
    onFilterChange,
}: ConsumerGrowthScorecardFiltersPropsType) => {
    return growthScorecardState.loading ? (
        <Shimmer
            shimmerElements={[
                { type: ShimmerElementType.line, width: "100%", height: 40 },
            ]}
        />
    ) : (
        <Stack horizontal className="filterPanel">
            <div className="dropdown">
                <NavigableDatePicker
                    backTitle={"Go to Previous Week"}
                    forwardTitle={"Go to Next Week"}
                    dates={growthScorecardState?.distinctDates}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    selectedDate={filters.date}
                />
            </div>
            <CSVLink
                data={
                    growthScorecardState.growthScorecardTableRows[
                        filters.application
                    ]
                }
                filename={`ConsumerGrowthScorecard-${filters.application}.csv`}
                headers={getExportCsvHeaders(
                    growthScorecardState.growthScorecardTableRows,
                    filters.application,
                    ScorecardTableColumnConfig,
                )}
            >
                <CommandBarButton
                    iconProps={{
                        iconName: "Download",
                    }}
                    styles={{
                        root: {
                            height: 30,
                            backgroundColor: "unset",
                        },
                    }}
                    text="Export"
                    title="Export all weeks data"
                />
            </CSVLink>
            <Stack.Item grow={1} className="menuNavigation">
                <a
                    href={`${InsightsRoutes.GrowthDrilldown.path}?date=${filters["date"]}&application=${filters["application"]}`}
                    rel="noopener noreferrer"
                >
                    {"Go to Growth Drilldown"}
                </a>
            </Stack.Item>
        </Stack>
    );
};

export default GrowthScorecardFilters;
