import React, { useEffect, useRef, useState } from "react";
import {
    ConstrainMode,
    Pivot,
    PivotItem,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    generateColumns,
    getLevelForColumns,
    renderItemColumn,
} from "pages/ExecOverview/ExecOverviewUtils";
import { TenantStatusProps, TenantStatusState } from "pages/ExecOverview/types";
import { logTenantColorBasedView } from "utils/AppInsightsHelper";
import { defaultTenantStatusHealthColor, PivotSize } from "utils/Constants";
import { extractQueryParams, hashTenantInfo } from "utils/Helpers";
import { NO_DATA_MESSAGES } from "utils/Messages";

const sortDataSet = (dataRows: any[], type: string, sortKey: string) => {
    const _data: any[] = [...dataRows];

    const isDesc: number = type === "Movers" ? 1 : -1;
    _data.sort((a, b) => isDesc * (b[sortKey] - a[sortKey]));

    return _data;
};

export const TenantStatus = (props: TenantStatusProps) => {
    const sortKey = props.sortKey ?? "Composite_MoMChange";
    const queryParams = useRef(extractQueryParams());
    const queryParamsUrl = useRef(
        props.queryParamsUrl.length !== 0
            ? `&${props.queryParamsUrl.substring(1)}`
            : "",
    );

    const subGroupStyling = props.platformConfig.subGroupStyling;

    const [state, setState] = useState<TenantStatusState>({
        status: defaultTenantStatusHealthColor,
        data: [],
        columns: [],
        queryParams,
        loading: props.loading,
        sortOptions: {
            sortKey: sortKey,
            sortedDescending: props.type === "Movers",
        },
    });

    const onclickTenantStatus = (item: PivotItem): any => {
        setState((state) => {
            return {
                ...state,
                status: item.props.itemKey,
            };
        });
        logTenantColorBasedView({
            page: document.title,
            table: `Tenant Status: ${props.type}`,
            color: item.props.itemKey,
        });
    };

    useEffect(() => {
        queryParams.current = extractQueryParams();
        queryParamsUrl.current =
            props.queryParamsUrl.length !== 0
                ? `&${props.queryParamsUrl.substring(1)}`
                : "";
        setState({
            status: defaultTenantStatusHealthColor,
            data: props.payload
                ? sortDataSet(props.payload.rows, props.type, sortKey)
                : [],
            columns: generateColumns(
                queryParams["current"]["level"],
                props,
                state.sortOptions,
                setState,
            ),
            queryParams,
            loading: props.loading,
            sortOptions: {
                sortKey: sortKey,
                sortedDescending: props.type === "Movers",
            },
        });

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        queryParams.current = extractQueryParams();
        queryParamsUrl.current =
            props.queryParamsUrl.length !== 0
                ? `&${props.queryParamsUrl.substring(1)}`
                : "";
        const columns = generateColumns(
            queryParams["current"]["level"],
            props,
            state.sortOptions,
            setState,
        );
        if (!columns.some((column) => column.key === state.sortOptions.sortKey)) {
            setState((state) => {
                return {
                    ...state,
                    data: sortDataSet(props.payload.rows, props.type, sortKey),
                    columns,
                    queryParams,
                    loading: props.loading,
                };
            });
            return;
        }
        const result = DetailsListHelper.sortItems(
            columns,
            columns.filter((column) => column.key === state.sortOptions.sortKey)[0],
            sortDataSet(props.payload.rows, props.type, sortKey),
            true,
        );
        setState((state) => {
            return {
                ...state,
                data: result.items,
                columns: result.columns,
                queryParams,
                loading: props.loading,
            };
        });

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.payload, props.loading]);

    const redactTenantInfo = queryParams["current"]["redact"];
    if (redactTenantInfo === "on") {
        hashTenantInfo(state);
    }

    return (
        <>
            <h2 className="commonPageBlockTitle">
                {/* To do - Remove conditional oeprator once Mac gets Tpid Ids */}
                {props.type === "Movers"
                    ? "Moved out of Red/Deep Red this month"
                    : "Moved to Red/Deep Red this month"}
            </h2>
            {state.data.length === 0 && !state.loading ? (
                <ShimmeredDetailsListWrapper
                    items={[]}
                    message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                />
            ) : (
                <div className="tenantStatusTable tenantTable">
                    <Pivot
                        linkSize={PivotSize.normal}
                        onLinkClick={onclickTenantStatus}
                        selectedKey={state.status}
                    >
                        {["Red", "DeepRed"].map((el) => {
                            return (
                                <PivotItem
                                    headerText={el === "DeepRed" ? "Deep Red" : el}
                                    itemKey={el}
                                    key={el}
                                />
                            );
                        })}
                    </Pivot>
                    <div data-is-scrollable className="pageSection">
                        <ScrollablePane
                            scrollbarVisibility={ScrollbarVisibility.auto}
                        >
                            <ShimmeredDetailsListWrapper
                                items={state.data.filter((i) =>
                                    props.type === "Movers"
                                        ? i["PreviousStatus"] === state.status &&
                                          i["CurrentStatus"] !== state.status &&
                                          i[sortKey] > 0
                                        : i["CurrentStatus"] === state.status &&
                                          i["PreviousStatus"] !== state.status &&
                                          i[sortKey] < 0,
                                )}
                                enableShimmer={state.loading}
                                columns={state.columns}
                                selectionMode={SelectionMode.none}
                                compact
                                onRenderDetailsHeader={(
                                    headerProps,
                                    defaultrender,
                                ) => {
                                    return DetailsListHelper.renderGroupedDetailsHeader(
                                        headerProps,
                                        props.platformConfig.groups,
                                        false,
                                        defaultrender,
                                        subGroupStyling,
                                    );
                                }}
                                onRenderRow={DetailsListHelper.renderExpandableRow}
                                onRenderItemColumn={renderItemColumn(
                                    getLevelForColumns(
                                        props.platformConfig.platform,
                                        state.queryParams["current"]["level"],
                                    ),
                                    props,
                                    queryParamsUrl.current,
                                )}
                                constrainMode={ConstrainMode.unconstrained}
                                styles={DetailsListHelper.tenantTableStyle}
                                message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                            />
                        </ScrollablePane>
                    </div>
                </div>
            )}
        </>
    );
};
