import camelCase from "lodash/camelCase";
import { ApiClient, UnauthenticatedApiClient } from "api/ApiClient";
import { IsWebV2Model } from "api/commonApiHandler";
import { incrementQueryCount } from "components/Developer/DeveloperStateStore";
import { kustoApiRequestDefaultConfiguration } from "config/ApiConfiguration";
import { getConfig, Workload } from "config/PlatformConfig";
import { ClusterProp, KustoResponseType, QueryDetail } from "pages/commonTypes";
import {
    Level,
    PerfChartQuery,
    PerfInsightsChartQuery,
    PerfInsightsQuery,
    PerfRankingQuery,
    TenantMetadataParams,
    TenantTypicalAddInCostQueryParams,
} from "pages/Performance/types";
import queries from "queries";
import { mauCountThreshold } from "utils/Constants";

export function substituteQueryParameters(query, queryParameters) {
    let res = query;
    Object.keys(queryParameters).forEach(function setSubstitute() {
        res = res.replace(
            /\${([a-zA-Z0-9_]*)}/g,
            function getSubstitute(match, capture) {
                // For ASHA error names (coming from QoS 2.0), all
                // underscores in the error name need to be removed
                // because QoS 2.0 removes underscores before logging
                // to Aria so that the table names generated in Kusto
                // don't have underscores in them in the error name
                // portion of the table name.
                // Note that errorname exists in various other queries
                // and we only want to remove underscores for queries
                // going against the QoS 2.0 tables.
                const sub =
                    capture === "errorname" &&
                    typeof query === "string" &&
                    query.includes("_Online_Health_Qos_Error_")
                        ? queryParameters[capture].replace(/_/g, "")
                        : queryParameters[capture];
                return sub === undefined ? match : sub;
            },
        );
    });
    return res;
}

export const getClusterValue = (clusterConfig: ClusterProp) => {
    const { platform, key } = clusterConfig;
    const cluster = clusterConfig.cluster ? clusterConfig.cluster : "Default";
    const config = getConfig(platform);
    return config ? config.clusterDetails[cluster][key] : "";
};

/**
 * Kusto Request Handler to make Kusto API calls
 * @param QueryDetail query object
 * @returns A Promise of <AxiosResponse> type
 */
export const getKustoResponse = async ({
    queryName = "",
    platform,
    queryParams,
    cluster = "Default",
}: QueryDetail) => {
    const dbName = getClusterValue({ platform, cluster, key: "db" });
    const url = getClusterValue({ platform, cluster, key: "kustoUrl" });
    const postBody = {
        db: dbName,
        csl: queryParams
            ? substituteQueryParameters(queries[queryName], queryParams)
            : queries[queryName],
    };

    const apiClient = ApiClient.getInstance();
    incrementQueryCount();
    const response = await apiClient.post(url, postBody, {
        headers: {
            ...kustoApiRequestDefaultConfiguration.headers,
            "x-ms-kql-queryName": queryName,
        },
        timeout: kustoApiRequestDefaultConfiguration.timeout,
    });
    return response;
};

//  TO DO : move this helper function to Helper.ts
export function getQueryParamsForLevel(level: string) {
    return {
        levelColumnName: level === "Tpid" ? "Tpid" : "OmsTenantId",
        level,
    };
}

export function getQueryParamsForMetricFilters(metricFilters: {}) {
    const metricFiltersFormatted = {};
    Object.keys(metricFilters).forEach(function (key) {
        metricFiltersFormatted[camelCase(key)] = metricFilters[key];
    });

    return metricFiltersFormatted;
}

export function getTenantReliability(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams["app"] = "WXP";
    queryParams["date"] = filters["date"] ? filters["date"] : "";
    queryParams["host"] = filters["Host"];
    queryParams["uiHost"] = filters["UiHost"];
    queryParams["mauCountThreshold"] = mauCountThreshold;
    queryParams = { ...levelParams, ...queryParams };

    return Promise.all([
        getKustoResponse({
            queryName: "reliability",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "webMetaData",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({ queryName: "dateFilters", platform: Workload.WEB }),
        getKustoResponse({
            queryName: "errorSpike",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([reliability, metadata, dates, errorSpike]) => {
            return {
                reliability: reliability?.data,
                metadata: metadata?.data,
                dates: dates?.data,
                errorSpike: errorSpike?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function removeErrorPrefix(errorName: string) {
    var formattedErrorName = errorName;
    if (errorName.startsWith("Error")) {
        formattedErrorName = errorName.substring(5);
    }
    return formattedErrorName;
}

export function getErrorBrowserData(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["app"] = filters["app"];
    queryParams["date"] = filters["date"];
    queryParams["id"] = filters["id"];
    queryParams["errorname"] = removeErrorPrefix(filters["errorname"]);
    queryParams["veto"] = filters["veto"];
    queryParams["host"] = filters["Host"] ? filters["Host"] : "All";
    queryParams["uiHost"] = filters["UiHost"] ? filters["UiHost"] : "All";

    queryParams = { ...levelParams, ...queryParams };

    return Promise.all([
        getKustoResponse({
            queryName: "errorBrowserData",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([errorBrowserData]) => {
            return {
                errorBrowserData: errorBrowserData?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getErrorNames(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);

    queryParams["app"] = filters["app"];
    queryParams["date"] =
        filters["date"] && filters["date"] !== "All" ? filters["date"] : "";
    queryParams["host"] = filters["host"] ? filters["host"] : "All";
    queryParams["uiHost"] = filters["uihost"] ? filters["uihost"] : "All";

    queryParams = { ...levelParams, ...queryParams };

    return Promise.all([
        getKustoResponse({
            queryName: "errorNames",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({ queryName: "dateFilters", platform: Workload.WEB }),
    ])
        .then(([errorNames, dateOptions]) => {
            return {
                errorNames: errorNames?.data,
                dateOptions: dateOptions?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getChartDataForError(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);

    queryParams["app"] = filters["app"];
    queryParams["date"] = filters["date"];
    queryParams["host"] = filters["host"] ? filters["host"] : "All";
    queryParams["uiHost"] = filters["uihost"] ? filters["uihost"] : "All";
    queryParams["id"] = filters["id"];
    queryParams["errorname"] = filters["errorname"];
    queryParams = { ...levelParams, ...queryParams };

    return Promise.all([
        getKustoResponse({
            queryName: "chartData",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([chartData]) => {
            return {
                chartData: chartData?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getErrorTenants(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["app"] = filters["app"] ? filters["app"] : "Word";
    queryParams["date"] = filters["date"] ? filters["date"] : "";
    queryParams["host"] = filters["host"] ? filters["host"] : "All";
    queryParams["uiHost"] = filters["uihost"] ? filters["uihost"] : "All";
    queryParams["errorname"] = filters["errorname"];
    queryParams["cohort"] = filters["cohort"];

    queryParams = { ...levelParams, ...queryParams };

    const isDateAll = filters["date"] === "All";
    let tenantsImpactedQueryName = "tenantsImpacted";
    let tenantsImpactedByHostQueryName = "tenantsImpactedByHost";
    let tenantsImpactedByUiHostQueryName = "tenantsImpactedByUiHost";

    if (isDateAll) {
        tenantsImpactedQueryName = "tenantsImpactedAllDates";
        tenantsImpactedByHostQueryName = "tenantsImpactedByHostAllDates";
        tenantsImpactedByUiHostQueryName = "tenantsImpactedByUiHostAllDates";
    }

    return Promise.all([
        getKustoResponse({
            queryName: tenantsImpactedQueryName,
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "cohortAvgData",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "tenantsImpactedCount",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: tenantsImpactedByHostQueryName,
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: tenantsImpactedByUiHostQueryName,
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "cohortImpactCountAllDates",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(
            ([
                tenantsImpacted,
                cohortAvgData,
                tenantsImpactedCount,
                tenantsImpactedByHost,
                tenantsImpactedByUiHost,
                cohortImpactCountAllDates,
            ]) => {
                return {
                    tenantsImpacted: tenantsImpacted?.data,
                    cohortAvgData: cohortAvgData?.data,
                    tenantsImpactedCount: tenantsImpactedCount?.data,
                    tenantsImpactedByHost: tenantsImpactedByHost?.data,
                    tenantsImpactedByUiHost: tenantsImpactedByUiHost?.data,
                    cohortImpactCountAllDates: cohortImpactCountAllDates?.data,
                };
            },
        )
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function loadBasicTenantMetaData(
    filters: TenantMetadataParams,
): Promise<KustoResponseType<string>> {
    const queryName = "basicMetadata";
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams = { ...levelParams, ...queryParams };

    const metadataResponse = await getKustoResponse({
        queryName,
        platform: Workload.WIN32,
        queryParams,
    });
    return metadataResponse?.data;
}

export async function fetchWin32PerfChartsData(
    queryParams: PerfChartQuery,
): Promise<KustoResponseType<string | number>> {
    const queryName = "perfChartQuery";
    const platform = Workload.WIN32;
    const perfChartsData = await getKustoResponse({
        queryName,
        platform,
        queryParams,
        cluster: "PerformanceDrillDown",
    });
    return perfChartsData?.data;
}

export async function fetchWin32PerfRankingData(
    queryParams: PerfRankingQuery,
): Promise<KustoResponseType<boolean | number>> {
    const rankingData = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "perfRankingQuery",
        queryParams,
    });
    return rankingData?.data;
}

export const fetchWin32PerfEnvironment = async (queryParams: {
    id: string;
    level: Level;
}): Promise<KustoResponseType<string | number>> => {
    const envData = await getKustoResponse({
        platform: Workload.WIN32,
        queryName: "win32PerfEnvironmentData",
        queryParams: {
            ...getQueryParamsForLevel(queryParams.level),
            ...queryParams,
        },
    });
    return envData?.data;
};

export const fetchTenantTypicalAddInCost = async (
    queryParams: TenantTypicalAddInCostQueryParams,
): Promise<KustoResponseType<string | number>> => {
    const addInCost = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantTypicalAddInCost",
        queryParams,
    });
    return addInCost?.data;
};

export const fetchTenantTopAddInsPerformance = async (queryParams: {
    id: string;
    date: string;
    timespan: number;
}): Promise<KustoResponseType<string | number>> => {
    const addInPerfList = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantTopAddInsPerformanceQuery",
        queryParams,
    });
    return addInPerfList?.data;
};

export const fetchTenantAllAddInCostHistory = async (queryParams: {
    id: string;
    date: string;
    timespan: number;
}): Promise<KustoResponseType<string | number>> => {
    const costHistory = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantAllAddInCostHistory",
        queryParams,
    });
    return costHistory?.data;
};

export const fetchTenantAddInHistory = async (queryParams: {
    id: string;
    date: string;
    timespan: number;
}): Promise<KustoResponseType<string | number>> => {
    const history = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantAddInHistory",
        queryParams,
    });
    return history?.data;
};

export const fetchTenantAddInVersionHistory = async (queryParams: {
    id: string;
    date: string;
    timespan: number;
    clsid: string;
    progid: string;
}): Promise<KustoResponseType<string | number>> => {
    const history = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantAddInVersionHistory",
        queryParams,
    });
    return history?.data;
};

export const fetchTenantAddInOptions = async (queryParams: {
    id: string;
}): Promise<KustoResponseType<string>> => {
    const options = await getKustoResponse({
        platform: Workload.WIN32,
        cluster: "PerformanceDrillDown",
        queryName: "tenantAddInOptions",
        queryParams,
    });
    return options?.data;
};

export function getChartMetrics(
    metric: string,
    level: string,
    orgKey: string,
    metricFilters: {},
) {
    const levelParams = getQueryParamsForLevel(level);
    let queryParams = {
        host: metricFilters["Host"],
        uiHost: metricFilters["UiHost"],
    };
    queryParams["id"] = orgKey;
    queryParams["metric"] = metric;

    queryParams = { ...levelParams, ...queryParams };

    return getKustoResponse({
        queryName: "tenantMetricsChart",
        platform: Workload.WEB,
        queryParams,
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getTenantCollab(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams["date"] = filters["date"];
    queryParams["uiHost"] = filters["UiHost"];
    queryParams["app"] = "WXP";
    queryParams = { ...levelParams, ...queryParams };

    const queryParamsDate = {
        table: `CollabFunnel`,
        filterItem: "Date",
        cleanUp: "",
    };

    const queryParamsUiHost = {
        table: `CollabFunnel`,
        filterItem: "UiHost",
        cleanUp: "| where UiHost !in (InvalidUiHostValues)",
    };

    return Promise.all([
        getKustoResponse({
            queryName: "collabFunnel",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "filterOptions",
            platform: Workload.WEB,
            queryParams: queryParamsDate,
        }),
        getKustoResponse({
            queryName: "filterOptions",
            platform: Workload.WEB,
            queryParams: queryParamsUiHost,
        }),
        getKustoResponse({
            queryName: "webMetaData",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([collabFunnel, dateOptions, uihostOptions, metadata]) => {
            return {
                collabFunnel: collabFunnel?.data,
                dateOptions: dateOptions?.data,
                uihostOptions: uihostOptions?.data,
                metadata: metadata?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getPerformanceDistribution(filters: {}) {
    let queryParams = [];
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams["date"] = filters["date"];
    queryParams["host"] = filters["Host"];
    queryParams["uiHost"] = filters["UiHost"];
    queryParams = { ...levelParams, ...queryParams };

    const queryParamsDate = {
        table: `PerformanceDistribution`,
    };

    return Promise.all([
        getKustoResponse({
            queryName: "performanceDistribution",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "dateFilterOptions",
            platform: Workload.WEB,
            queryParams: queryParamsDate,
        }),
        getKustoResponse({
            queryName: "webMetaData",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([performanceDistribution, dateOptions, metadata]) => {
            return {
                performanceDistribution: performanceDistribution?.data,
                dateOptions: dateOptions?.data,
                metadata: metadata?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export const getExecOverviewQuery = (
    queryName: string,
    application: string,
    platform: string,
    date: string,
): string => {
    // Teams subworkloads have different signals unlike the Office Platforms,
    // splitting the query by Application
    if (platform == Workload.TEAMS) {
        return `${platform.toLowerCase()}${application}${queryName}`;
    }

    const isWebV2 = IsWebV2Model(date);
    if (platform === Workload.WEB && isWebV2) {
        if (application === "WXP")
            return `${platform.toLowerCase()}CrossApp${queryName}_V2`;

        return `${platform.toLowerCase().concat(queryName)}_V2`;
    }

    if (["WXP", "WXPO", "WXPON"].includes(application)) {
        return `${platform.toLowerCase()}CrossApp${queryName}`;
    }

    return platform.toLowerCase().concat(queryName);
};

export const platformSignal = async (platform) => {
    const dates = await getKustoResponse({
        queryName: "platformSignal",
        platform,
        queryParams: {
            tablePrefix: getClusterValue({
                platform,
                cluster: "Default",
                key: "tablePrefix",
            }),
        },
    });
    return dates;
};

export const dateFilters = async (platform) => {
    const dates = await getKustoResponse({
        queryName: platform.toLowerCase().concat("DateFilters"),
        platform,
        queryParams: {},
    });
    return dates;
};

export const compositeScore = async (queryParams, platform) => {
    const compositeScore = await getKustoResponse({
        queryName: getExecOverviewQuery(
            "CompositeScore",
            queryParams["application"],
            platform,
            queryParams["date"],
        ),
        platform,
        queryParams,
    });
    return compositeScore;
};

export const healthChart = async (queryParams, platform) => {
    const healthChart = await getKustoResponse({
        queryName: platform.toLowerCase().concat("HealthChart"),
        platform,
        queryParams,
    });
    return healthChart;
};

export const mtrhealthChart = async (queryParams, platform) => {
    const mtrhealthChart = await getKustoResponse({
        queryName: `${platform.toLowerCase().concat("HealthChart")}MTR`,
        platform,
        queryParams,
    });
    return mtrhealthChart;
};

export const tenantStatus = async (queryParams, platform) => {
    const tenantStatus = await getKustoResponse({
        queryName: getExecOverviewQuery(
            "TenantStatus",
            queryParams["application"],
            platform,
            queryParams["date"],
        ),
        platform,
        queryParams,
    });
    return tenantStatus;
};

export const tenantList = async (
    queryParams,
    platform,
): Promise<KustoResponseType<string>> => {
    const tenantList = await getKustoResponse({
        queryName: platform.toLowerCase().concat("TenantList"),
        platform,
        queryParams,
    });
    return tenantList?.data;
};

export const customerList = async (
    queryParams,
    platform,
): Promise<KustoResponseType<string>> => {
    const customerList = await getKustoResponse({
        queryName: platform.toLowerCase().concat("CustomerList"),
        platform,
        queryParams,
    });
    return customerList?.data;
};

export const officeTenantScore = async (filters, platform) => {
    let query = platform.toLowerCase().concat("OfficeTenantScore");

    if (IsWebV2Model(filters["date"]) && platform === Workload.WEB)
        query = platform.toLowerCase().concat("OfficeTenantScore_V2");

    const officeTenantScore = await getKustoResponse({
        queryName: query,
        platform,
        queryParams: filters,
    });
    return officeTenantScore;
};

export const coreMetrics = async (filters, platform) => {
    let query = platform.toLowerCase().concat("CoreMetrics");

    if (IsWebV2Model(filters["date"]) && platform === Workload.WEB)
        query = platform.toLowerCase().concat("CoreMetrics_V2");

    const coreMetrics = await getKustoResponse({
        queryName: query,
        platform,
        queryParams: filters,
    });
    return coreMetrics;
};

export const avgCohortMetrics = async (filters, platform) => {
    let query = platform.toLowerCase().concat("AvgCohortMetrics");

    if (IsWebV2Model(filters["date"]) && platform === Workload.WEB)
        query = platform.toLowerCase().concat("AvgCohortMetrics_V2");

    const avgCoreMetrics = await getKustoResponse({
        queryName: query,
        platform,
        queryParams: filters,
    });
    return avgCoreMetrics;
};

export const sparkLineScores = async (filters, platform) => {
    let query = platform.toLowerCase().concat("SparkLineScores");

    if (IsWebV2Model(filters["date"]) && platform === Workload.WEB)
        query = platform.toLowerCase().concat("SparkLineScores_V2");

    const sparkLineScores = await getKustoResponse({
        queryName: query,
        platform,
        queryParams: filters,
    });
    return sparkLineScores;
};

export const metaData = async (filters, platform) => {
    let query = platform.toLowerCase().concat("MetaData");

    if (IsWebV2Model(filters["date"]) && platform === Workload.WEB)
        query = platform.toLowerCase().concat("MetaData_V2");

    const metaData = await getKustoResponse({
        queryName: query,
        platform,
        queryParams: filters,
    });
    return metaData;
};

export const miscellaneousData = async (filters, platform) => {
    const miscellaneousData = await getKustoResponse({
        queryName: platform.toLowerCase().concat("TenantEnvironmentData"),
        platform,
        queryParams: filters,
    });
    return miscellaneousData;
};

export const tenantMetrics = async (filters, platform) => {
    const tenantMetrics = await getKustoResponse({
        queryName: platform.toLowerCase().concat("TenantMetrics"),
        platform,
        queryParams: filters,
    });
    return tenantMetrics;
};

export const tenantMetricsChart = async (filters, platform) => {
    const tenantMetrics = await getKustoResponse({
        queryName: platform.toLowerCase().concat("TenantMetricsChart"),
        platform,
        queryParams: filters,
    });
    return tenantMetrics;
};

export const customerAppMetrics = async (filters, platform) => {
    const tenantMetrics = await getKustoResponse({
        queryName: platform.toLowerCase().concat("CustomerAppMetrics"),
        platform,
        queryParams: filters,
    });
    return tenantMetrics;
};

export const getAddInPerformanceScore = async (queryParams) => {
    const performanceScore = await getKustoResponse({
        queryName: "addInsPerformanceScore",
        platform: Workload.WEB,
        queryParams,
    });
    return performanceScore;
};

export const getAddInReliabilityScore = async (queryParams) => {
    const reliabilityScore = await getKustoResponse({
        queryName: "addInsReliabilityScore",
        platform: Workload.WEB,
        queryParams,
    });
    return reliabilityScore;
};

export const getAddInDateFilters = async (queryParams) => {
    const performanceScore = await getKustoResponse({
        queryName: "addinsDateFilters",
        platform: Workload.WEB,
        queryParams,
    });
    return performanceScore;
};

export function getCohortAnalysisValidDates(filters: {}) {
    const queryParams = {};

    queryParams["app"] = filters["app"];
    return Promise.all([
        getKustoResponse({
            queryName: "datesAvailable",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([dates]) => {
            return { dates: dates?.data };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getCohortCategories(filters: {}) {
    const queryParams = {};

    queryParams["app"] = filters["app"];
    queryParams["date"] = filters["date"];
    return Promise.all([
        getKustoResponse({
            queryName: "cohortCategories",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([cohortCategories]) => {
            return { cohortCategories: cohortCategories?.data };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getCohortReliabilityData(filters: {}) {
    const queryParams = {};

    queryParams["date"] = filters["date"];
    queryParams["app"] = filters["app"];
    queryParams["cohortCategory"] = filters["cohortCategory"];
    return Promise.all([
        getKustoResponse({
            queryName: "overallCohortReliability",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([overallCohortReliability]) => {
            return { overallCohortReliability: overallCohortReliability?.data };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getErrorDistributionForCohort(filters: {}) {
    const queryParams = {};

    queryParams["date"] = filters["date"];
    queryParams["app"] = filters["app"];
    queryParams["cohortCategory"] = filters["cohortCategory"];
    queryParams["cohort"] = filters["cohort"];
    return Promise.all([
        getKustoResponse({
            queryName: "cohortErrors",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "cohortErrorBreakdown",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([cohortErrors, cohortErrorBreakdown]) => {
            return {
                cohortErrors: cohortErrors?.data,
                cohortErrorBreakdown: cohortErrorBreakdown?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getTenantsImpactedByErrorInCohort(filters: {}) {
    let queryParams = {};

    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["date"] = filters["date"];
    queryParams["app"] = filters["app"];
    queryParams["cohortCategory"] = filters["cohortCategory"];
    queryParams["cohort"] = filters["cohort"];
    queryParams["errorname"] = filters["errorname"];
    queryParams["host"] = "All";
    queryParams["uiHost"] = "All";

    queryParams = { ...levelParams, ...queryParams };
    return Promise.all([
        getKustoResponse({
            queryName: "tenantsImpactedInCohort",
            platform: Workload.WEB,
            queryParams,
        }),
        getKustoResponse({
            queryName: "s2500AvgData",
            platform: Workload.WEB,
            queryParams,
        }),
    ])
        .then(([tenantsImpacted, s2500AvgData]) => {
            return {
                tenantsImpacted: tenantsImpacted?.data,
                s2500AvgData: s2500AvgData?.data,
            };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export const getProductAdvisories = async (queryParams: {}) => {
    const officeProductAdvisories = await getKustoResponse({
        queryName: "tenantAppsHealthAdvisories",
        platform: Workload.WIN32,
        queryParams,
    });
    return officeProductAdvisories;
};

export const getAdvisoriesHistory = async (queryParams: {}) => {
    const officeProductAdvisories = await getKustoResponse({
        queryName: "tenantAppAdvisoriesHistory",
        platform: Workload.WIN32,
        queryParams,
    });
    return officeProductAdvisories;
};

export const getApphealthAddins = async (queryParams: {}) => {
    const officeProductAdvisories = await getKustoResponse({
        queryName: "tenantAppsHealthAddins",
        platform: Workload.WIN32,
        queryParams,
    });
    return officeProductAdvisories;
};

export const signalTrends = async (platform: Workload, queryParams: {}) => {
    const signalTrends = await getKustoResponse({
        queryName: platform.toLowerCase().concat("SignalTrends"),
        platform,
        queryParams,
    });
    return signalTrends;
};

export async function fetchPerformanceDates(
    queryName = "win32PerfDatesQuery",
): Promise<KustoResponseType<string>> {
    const dates = await getKustoResponse({
        queryName,
        platform: Workload.WIN32,
        queryParams: {},
        cluster: "PerformanceDrillDown",
    });
    return dates?.data;
}

export async function fetchWin32PerfInsights(
    queryParams: PerfInsightsQuery,
): Promise<KustoResponseType<string>> {
    const queryName = "perfInsightsQuery";
    const platform = Workload.WIN32;
    const perfInsightsData = await getKustoResponse({
        queryName,
        platform,
        queryParams,
        cluster: "PerformanceDrillDown",
    });
    return perfInsightsData?.data;
}

export async function fetchWin32PerfInsightsChart(
    queryParams: PerfInsightsChartQuery,
): Promise<KustoResponseType<string>> {
    const queryName = "perfInsightsChartQuery";
    const platform = Workload.WIN32;
    const perfInsightsChartsData = await getKustoResponse({
        queryName,
        platform,
        queryParams,
        cluster: "PerformanceDrillDown",
    });
    return perfInsightsChartsData?.data;
}

export const getDateOptions = async (platform: string) => {
    const [datesData] = await Promise.all([dateFilters(platform)]);
    return datesData?.data;
};

export function getSupportedTenantList() {
    return Promise.all([
        getKustoResponse({
            queryName: "supportedTenantList",
            platform: Workload.WEB,
            queryParams: {},
        }),
    ])
        .then(([supportedTenantList]) => {
            return { supportedTenantList: supportedTenantList?.data };
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export const getUxSchemaFromEcs = async (app: string, audience: string) => {
    const url = `https://ecs.office.com/config/v1/CHILL/1.0.0.0?agent=ChillWAC&Application=${app}&Audience=${audience}&Language=en-US`;
    const apiClient = UnauthenticatedApiClient.getInstance();
    const response = await apiClient.get(url);
    if (response.status !== 200) {
        throw new Error(
            `Failed to fetch UX schema from ECS. Status: ${response.status}`,
        );
    }
    return response.data;
};
