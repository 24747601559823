import React from "react";
import { IColumn, precisionRound } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { IEmptyStateStyles } from "@m365-admin/empty-state";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { NO_DATA_LOADTIME } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import {
    AddInsFilterNames,
    AddInsFlyoutPanelData,
    AddInsTabletype,
    AdvisoryFilterNames,
    AdvisoryMetricType,
    AdvisorySeverity,
    AdvisoryTrend,
    IAppHealthAddIns,
    OfficeProduct,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";

export const emptyStateStyle: Partial<IEmptyStateStyles> = {
    root: { position: "relative" },
};

export const getSeverityIcon = (
    severityType: string,
    trendType: string,
): JSX.Element => {
    let iconName = "";
    let className = "";

    if (trendType === AdvisoryTrend.Degradation) {
        if (severityType === AdvisorySeverity.Significant) {
            iconName = "StatusErrorFull";
            className = "errorIconClass";
        } else {
            iconName = "Warning";
            className = "warningIconClass";
        }
    } else {
        iconName =
            severityType === AdvisorySeverity.Significant
                ? "CompletedSolid"
                : "Completed";
        className = "completedIconClass";
    }

    return <Icon className={className} iconName={iconName} />;
};

export const getFormattedMetricValueWithUnits = (
    metric: string,
    metricValue: number,
): string => {
    if (!metric || !metricValue) return "";

    return metric === AdvisoryMetricType.CrashRate
        ? `${(Number(metricValue) * 100).toFixed(2)}%`
        : `${Number(metricValue).toFixed(1)} sec`;
};

export const getMetricUnit = (metric: string): string => {
    return metric === AdvisoryMetricType.CrashRate ? "%" : "sec";
};

export const getMetricSuffix = (metric: string): string => {
    return metric === AdvisoryMetricType.CrashRate ? "Sessions" : "Events";
};

export const getFormattedMetricValue = (
    metric: string,
    metricValue: number,
): number => {
    if (!metric || !metricValue) return -1;

    return metric === AdvisoryMetricType.CrashRate
        ? precisionRound(metricValue * 100, 2)
        : precisionRound(metricValue, 1);
};

export const getAdvisoriesFlyoutPanelColors = (i: number) => {
    if (i > 1 || i < 0) return "black";
    const colors = [`rgb(16, 110, 190)`, `rgb(111, 97, 167)`];
    return colors[i];
};

export const multiCountRowColor: string = `rgb(59, 82, 180)`;

export const onColumnRender = (
    item?: { [field: string]: string | number },
    index?: number,
    column?: IColumn,
) => {
    const fieldContent = item[column.fieldName] as string;

    switch (column.key) {
        case "Severity":
            const severityType = item[AdvisoryFilterNames.Type] as string;
            const trendType = item[AdvisoryFilterNames.Trend] as string;

            const icon = getSeverityIcon(severityType, trendType);

            return (
                <span>
                    {icon} &nbsp; {fieldContent}
                </span>
            );
        case "deviceCounts":
        case "monitoredDeviceCount":
        case "sessionCount":
            return (
                <DetailsListHelper.NumericalValue
                    value={fieldContent}
                    postfix=""
                    defaultValue="NA"
                />
            );
        // reference -https://office.visualstudio.com/OC/_git/EnterpriseHealth?path=/EnterpriseHealthUX/EnterpriseHealthUX.UXService/Website/src/components/addInHealth/addInsTable.tsx&version=GC2427207715d4ddb751fd8a10077a5688d160e585&line=484&lineEnd=487&lineStartColumn=4&lineEndColumn=1&lineStyle=plain&_a=contents
        case "addinLoadTime":
            if (item["addinCrashrate"] === 100)
                return <span>{NO_DATA_LOADTIME}</span>;
            else return <span>{fieldContent}</span>;
        default:
            return <span>{fieldContent}</span>;
    }
};

export const GroupAddins = (data: IAppHealthAddIns[]): AddInsTabletype[] => {
    if (data.length === 0) return [];

    const AddinGroupMap: Map<string, AddInsFlyoutPanelData[]> = new Map<
        string,
        AddInsFlyoutPanelData[]
    >();
    const grouped = data
        .map((addin: IAppHealthAddIns) => {
            const addinKey: string = `${addin.addinId} ${addin.addinProgId}`;
            const flyoutData: AddInsFlyoutPanelData = {
                [AddInsFilterNames.Applications]:
                    addin[AddInsFilterNames.Applications],
                [AddInsFilterNames.Builds]: addin[AddInsFilterNames.Builds],
                [AddInsFilterNames.Channels]: addin[AddInsFilterNames.Channels],
                version: addin.version,
                monitoredDeviceCount: addin.monitoredDeviceCount,
                sessionCount: addin.sessionCount,
                crashRate: precisionRound(addin.crashRate * 100, 2),
                loadTime: precisionRound(addin.loadTime, 3),
                architecture: addin.architecture,
            };

            if (AddinGroupMap.has(addinKey)) {
                AddinGroupMap.get(addinKey).push(flyoutData);
            } else {
                AddinGroupMap.set(addinKey, [flyoutData]);

                return {
                    addinName: addin.addinName,
                    addinProvider: addin.addinProvider,
                    addinProgId: addin.addinProgId,
                    panelData: AddinGroupMap.get(addinKey),
                };
            }
        })
        .filter((addin) => addin !== undefined);

    const groupedAddins: AddInsTabletype[] = grouped.map((val) => {
        const versionCounts = new Set<string>();
        const apps = new Set<string>();
        let deviceCounts: number = 0;
        let crashRate: number = 0;
        let loadTime: number = 0;
        let sessions: number = 0;
        val.panelData.forEach((value: AddInsFlyoutPanelData) => {
            versionCounts.add(value.version);
            apps.add(value[AddInsFilterNames.Applications]);
            deviceCounts += value.monitoredDeviceCount;
            crashRate += value.crashRate * value.sessionCount;
            loadTime = Math.max(loadTime, value.loadTime);
            sessions += value.sessionCount;
        });

        return {
            addinName: val.addinName,
            addinProvider: val.addinProvider,
            addinVersion:
                versionCounts.size > 1
                    ? `${versionCounts.size} versions`
                    : val.panelData[0].version,
            addinApps: [...apps].join(", "),
            addinProgId: val.addinProgId,
            deviceCounts,
            sessionCounts: sessions,
            addinCrashrate:
                sessions === 0 ? 0 : precisionRound(crashRate / sessions, 2),
            addinLoadTime: precisionRound(loadTime, 3),
            flyoutPanelData: val.panelData,
        };
    });

    return groupedAddins;
};

// reference - https://office.visualstudio.com/OC/_git/EnterpriseHealth?path=/EnterpriseHealthUX/EnterpriseHealthUX.UXService/Website/src/components/addInHealth/addInsTableHelpers.ts&version=GC2427207715d4ddb751fd8a10077a5688d160e585&line=37&lineEnd=47&lineStartColumn=1&lineEndColumn=4&lineStyle=plain&_a=contents
export const AddInHighLoadTimeInSecondsByAppThreshold: Map<OfficeProduct, number> =
    new Map([
        [OfficeProduct.Access, 1.29],
        [OfficeProduct.Excel, 2.81],
        [OfficeProduct.OneNote, 4.41],
        [OfficeProduct.Outlook, 0.9],
        [OfficeProduct.PowerPoint, 2.21],
        [OfficeProduct.Project, 3.16],
        [OfficeProduct.Publisher, 0.97],
        [OfficeProduct.Visio, 3.26],
        [OfficeProduct.Word, 2.65],
    ]);

// Values represent % e.g. 0.44 => 44%
export const AddInHighCrashRateByAppThreshold: Map<OfficeProduct, number> = new Map([
    [OfficeProduct.Access, 0.44],
    [OfficeProduct.Excel, 0.4],
    [OfficeProduct.OneNote, 0.44],
    [OfficeProduct.Outlook, 0.19],
    [OfficeProduct.PowerPoint, 0.74],
    [OfficeProduct.Project, 0.44],
    [OfficeProduct.Publisher, 0.44],
    [OfficeProduct.Visio, 0.44],
    [OfficeProduct.Word, 0.47],
]);

export const countAnnotationRectangleStyles = { rectangle: { width: 5 } };
