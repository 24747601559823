export const macAvgCohortMetrics: string = `
//macAvgCohortMetrics"; (To display average of a metric per cohort, on tiles)
let app = "\${app}";
let dt = "\${date}";
StrategicTenants
| where Date == toscalar(StrategicTenants|summarize max(Date))
| where \${levelColumnName} == "\${id}" 
| sort by IsS2500, IsS500, IsEPA, IsGov 
| take 1
| project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
| project Cohorts = replace_string(Cohorts, "All;", "")  // We don't want to treat the "other cohort" any different
| project allcohorts = split(Cohorts,';')
| project iff(array_length( allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
| mv-expand Cohort = Column1
| project Cohort = tostring(Cohort)
| where Cohort != "OXOPriorityTenants"
| join kind=inner(
	HealthyTenantScore_MacCohortAverage
	| where SubWorkload==app and ProcessDate == dt
	) on Cohort
| project Cohort,
			Engagement = round(MAURate,2),
			NPS = round(NPS,2),
			PerfP95 = round(PerfP95,2),
			BootThreshold=round(BootThreshold,2),
			FQ = round(FQ_Rate,2),
			CH = round(CH_Rate,2),
			OSCurrency = round(OS_Currency,2),
 			AppCurrency = round(App_Currency, 2)`;
