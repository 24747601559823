export const ashaConsumerFunnelPillarScoreWeekly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_PillarCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelVetoScoreWeekly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_VetoCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelErrorScoreWeekly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_ErrorCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesWeekly_Excel: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_StageRates_ForFunnelPage_Excel(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesWeekly_PowerPoint: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_StageRates_ForFunnelPage_PowerPoint(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesWeekly_Word: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Weekly_StageRates_ForFunnelPage_Word(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelPillarScoreMonthly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_PillarCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelVetoScoreMonthly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_VetoCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelErrorScoreMonthly: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_ErrorCountsTotal(datetime("\${date}"), "\${application}", "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesMonthly_Excel: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_StageRates_ForFunnelPage_Excel(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesMonthly_PowerPoint: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_StageRates_ForFunnelPage_PowerPoint(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;

export const ashaConsumerFunnelStageRatesMonthly_Word: string = `
    database("Asha").ASHAConsumer_Day0Funnel_OfficeWeb_Monthly_StageRates_ForFunnelPage_Word(datetime("\${date}"), "Segment", "\${cohort}", "\${scenario}", "\${referrer}", "\${browser}", "\${datacenter}", "\${country}", "\${appMode}", "\${os}", "\${referrerPlatform}")
`;
