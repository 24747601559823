import { TeamsSubWorkload } from "config/PlatformConfig";
import { WorkloadMetricFilterState } from "pages/commonTypes";
import * as TYPES from "pages/InProductDrillDown/types";

export interface TeamsMetricsState {
    payload: {};
    loading: boolean;
}

export interface TeamsMetricsPropsType {
    app: TeamsSubWorkload;
    filters: TYPES.WorkloadTenantFilterState;
    metricFilters: WorkloadMetricFilterState;
}

export enum TeamsMetricType {
    MEETING_DURATION = "Meeting Duration",
    PARTICIPANT_COUNT = "Participant Count",
    PLATFORM = "Platform",
    LICENSE_DISTRIBUTION = "LicenseDistribution",
    SESSION_QUALITY = "Session Quality",
    OFFICE_USAGE_BREAKDOWN = "OfficeUsageBreakdown",
    OFFICE_USAGE = "OfficeUsage",
    MODALITY = "Modality",
    CHAT_TYPE = "ChatType",
    FEATURE = "Feature",
    CHAT_SENDER = "Chat Sender",
    CHAT_READER = "Chat Reader",
    OS = "OS",
    MESSAGES_SENT = "Messages Sent",
    USER_TYPE = "User Type",
}

export interface ColorFillsType {
    [category: string]: string;
}

export interface AppMetricsFillColorsType {
    [TeamsMetricType.SESSION_QUALITY]: ColorFillsType;
    [TeamsMetricType.MESSAGES_SENT]: ColorFillsType;
    [TeamsMetricType.CHAT_SENDER]: ColorFillsType;
    [TeamsMetricType.CHAT_READER]: ColorFillsType;
    [TeamsMetricType.OS]: ColorFillsType;
}
