export const crossplatformTenantStatus: string = `
// cross platform tenant status - get moves/shakers
let currDate = GetCurrentDateCrossPlatform();
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let app = "\${application}";
let minMAU = 2500;
CrossPlatform
    | where Date == dt and Application == app and CrossApp_Color != "Gray" and (W32_Mau>=minMAU or Web_Mau>=minMAU)
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | extend Tpid = TopParentId,Score = CrossApp_Score, CurrentStatus = CrossApp_Color
    | join kind= inner
    (
        CrossPlatform
            | where Date == prev and Application == app and CrossApp_Color != "Gray" and (W32_Mau>=minMAU or Web_Mau>=minMAU)
            | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | extend Tpid = TopParentId, PreviousScore = CrossApp_Score, PreviousStatus = CrossApp_Color
    )
    on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | join kind=inner (
        StrategicTenants
            | where Date == currDate and Is\${cohort} == true
            | distinct \${levelColumnName}, iif("\${levelColumnName}"  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName}
    | join kind=leftouter (
        CrossPlatform_PastStatus
        | where Application == app and Date == currDate
        ) on OmsTenantId, Tpid
    | extend Score_MoMChange = Score-PreviousScore
    | extend Web_MAU_MoMChange = todouble(Web_Mau-Web_Mau1)/Web_Mau1*100
    | extend Win32_MAU_MoMChange = todouble(W32_Mau-W32_Mau1)/W32_Mau1*100
    | extend Web_Score_MoMChange = Web_Score-Web_Score1
    | extend Win32_Score_MoMChange = W32_Score-W32_Score1
    | project \${level} = \${levelColumnName}, OrgName =  Name, Composite_Score = Score, Composite_MoMChange = Score_MoMChange,
        Win32_Score_Platform = W32_Score, Win32_Score_MoMChange,
        Web_Score_Platform = Web_Score, Web_Score_MoMChange,
        Win32_MAU_Score = W32_Mau, Win32_MAU_MoMChange,
        Web_MAU_Score = Web_Mau, Web_MAU_MoMChange,
        CurrentStatus, PreviousStatus, PastStatus = GetPastYearStatusCrossPlatform(PastStatusSet, dt), Current_Web_Issues = Web_Issues, Current_Win32_Issues = W32_Issues, Previous_Web_Issues = Web_Issues1, Previous_Win32_Issues = W32_Issues1
`;
