import { webconsumerCompositeScore } from "./webconsumerCompositeScore";
import { webconsumerCrossAppCompositeScore } from "./webconsumerCrossAppCompositeScore";
import { webconsumerDateFilters } from "./webconsumerDateFilters";
import { webconsumerCrossAppTenantStatus } from "./webconsumerCrossAppTenantStatus";
import { webconsumerTenantStatus } from "./webconsumerTenantStatus";
import { webconsumerHealthChart } from "./webconsumerHealthChart";

export const consumerExecOverviewQueries = {
    webconsumerCompositeScore,
    webconsumerCrossAppCompositeScore,
    webconsumerDateFilters,
    webconsumerCrossAppTenantStatus,
    webconsumerTenantStatus,
    webconsumerHealthChart,
};
