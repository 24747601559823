import React from "react";
import { Stack } from "@fluentui/react";
import { CommonTenantEnvironment } from "components/TenantDrilldownComponents/TenantEnvironment/CommonTenantEnvironment";
import { GeographicDistributionWrapper } from "components/TenantDrilldownComponents/TenantEnvironment/GeographicDistributionWrapper";
import { MonthlyTenantEnvironmentChart } from "components/TenantDrilldownComponents/TenantEnvironment/MonthlyTenantEnvironmentChart";
import { UserFrequencyWrapper } from "components/TenantDrilldownComponents/TenantEnvironment/UserFrequencyWrapper";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { TenantEnvironmentPropsType } from "components/TenantDrilldownComponents/TenantEnvironment/types";
import { platformsConfig, Workload } from "config/PlatformConfig";
import {
    equalStackItemsStyle,
    horizontalStackTokens,
    verticalStackTokens,
} from "pages/common";

export const MacTenantEnvironment = ({
    tenantEnvironment,
    loading,
    filters,
    metricFilters,
}: TenantEnvironmentPropsType) => {
    return (
        <>
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="OS Distribution"
                        payload={tenantEnvironment.os}
                        kind={togglingLineChartKind.percentagesWithValues}
                        yLabel="% Devices"
                        loading={loading}
                    />
                </Stack.Item>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <UserFrequencyWrapper
                        payload={tenantEnvironment.openDocument}
                        loading={loading}
                        appsList={platformsConfig[Workload.MAC].appsList}
                    />
                </Stack.Item>
            </Stack>
            <div className="break" />
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="License Distribution"
                        payload={tenantEnvironment.licenseDistribution}
                        kind={togglingLineChartKind.percentagesWithValues}
                        yLabel="% Licenses"
                        loading={loading}
                    />
                </Stack.Item>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="Currency Distribution"
                        payload={tenantEnvironment.currency}
                        kind={togglingLineChartKind.percentagesWithValues}
                        yLabel="% Devices"
                        loading={loading}
                    />
                </Stack.Item>
            </Stack>
            <CommonTenantEnvironment
                filters={filters}
                selectedDate={metricFilters["Date"]}
                platform={Workload.MAC}
            />
            <div className="break" />
            <Stack tokens={verticalStackTokens}>
                <GeographicDistributionWrapper
                    geographicDistribution={tenantEnvironment.geographicDistribution}
                    geoDataMapping={tenantEnvironment.geoDataMapping}
                    loading={loading}
                    rankOn={filters["rankOn"]}
                    workload={Workload.MAC}
                />
            </Stack>
        </>
    );
};
