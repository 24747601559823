import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import ERFMScorecardFilters from "components/ERFMComponents/ERFMScorecardFilters";
import ERFMTable from "components/ERFMComponents/ERFMTable";
import { getERFMColumns } from "components/ERFMComponents/ERFMTableHelper";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import useDidMount from "hooks/useDidMount";
import {
    formatAppChartData,
    formatCountryChartData,
    fetchERFMData,
    formatDateOptions,
    formatTableData,
} from "components/ERFMComponents/ApiHandler";
import {
    ERFMFiltersType,
    ERFMQueryNames,
    ERFMStateType,
} from "components/ERFMComponents/types";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { commonPageBlock } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import { ERFM_MESSAGES } from "utils/Messages";
import "pages/ERFM/style.css";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const erfmTableColumns = getERFMColumns();

const ERFMScorecard = () => {
    const config = getPageConfig(DrilldownPages.ERFM);

    const [filters, setFilters] = useState<ERFMFiltersType>(() => {
        const initFilters: ERFMFiltersType = {
            application: config.defaults.app,
            date: null,
        };
        const filtersFromQueryParams = extractQueryParams();

        if (filtersFromQueryParams["application"]) {
            const appFromURL = filtersFromQueryParams["application"] as string;

            if (config.appsList.includes(appFromURL)) {
                initFilters.application = appFromURL;
            }
        }

        if (filtersFromQueryParams["date"]) {
            initFilters.date = filtersFromQueryParams["date"] as string;
        }

        return {
            ...initFilters,
        };
    });

    const [error, setError] = useState<string>(null);

    const [state, setState] = useState<ERFMStateType>({
        loading: true,
        erfmTableRows: {},
        distinctDates: [],
        dataByApp: {},
        dataByCountry: {},
    });

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        logFilterUsage(config.pageTitle, item, value);
        setUpDocumentUrl(updatedFilter, config.pageTitle);
        setFilters(() => updatedFilter);
        sendFiltersChangeTelemetryEvent(flattenFilters(updatedFilter));
    };

    const getDateOptions = async () => {
        setState((oldState) => {
            return { ...oldState, loading: true };
        });
        setError(null);

        try {
            const dateJson = await fetchERFMData(filters, ERFMQueryNames.ERFM_DATES);
            const distinctDates = formatDateOptions(dateJson);

            if (!filters.date || !distinctDates.includes(filters.date)) {
                onFilterChange("date", distinctDates[0]);
            }

            setState((oldState) => {
                return {
                    ...oldState,
                    distinctDates,
                };
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "ERFMDateOrMetricsProcessingFailed",
                ),
                {
                    message: ERFM_MESSAGES.NO_DATES_DATA,
                },
                error,
            );

            setError(error.message);
            setState((oldState) => {
                return {
                    ...oldState,
                    distinctDates: [],
                };
            });
            return;
        }
    };

    const getERFMData = async () => {
        setState((oldState) => {
            return { ...oldState, loading: true };
        });
        setError(null);

        try {
            // Fetching table data
            let metricTableDate = filters.date;
            const scorecardData = await fetchERFMData(
                { date: metricTableDate, application: filters.application },
                ERFMQueryNames.ERFM_TABLE,
            );
            const items = formatTableData(scorecardData);

            if (Object.keys(items).length === 0) {
                throw new Error(ERFM_MESSAGES.NO_TABLE_DATA);
            }

            // Fetching chart data
            const chartJsonByApp = await fetchERFMData(
                {
                    date: metricTableDate,
                    application: filters.application,
                },
                ERFMQueryNames.ERFM_APP_CHART,
            );
            const chartDataByApp = formatAppChartData(chartJsonByApp);

            const chartJsonByCountry = await fetchERFMData(
                {
                    date: metricTableDate,
                    application: filters.application,
                },
                ERFMQueryNames.ERFM_COUNTRY_CHART,
            );
            const chartDataByCountry = formatCountryChartData(chartJsonByCountry);

            setState((oldState) => {
                return {
                    ...oldState,
                    loading: false,
                    erfmTableRows: items,
                    dataByApp: chartDataByApp,
                    dataByCountry: chartDataByCountry,
                };
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "ERFMDateOrMetricsProcessingFailed",
                ),
                {
                    message: ERFM_MESSAGES.FETCH_FAILURE,
                },
                error,
            );

            setError(error.message);
            setState((oldState) => {
                return {
                    ...oldState,
                    scorecardTableRows: [],
                    loading: false,
                };
            });
            return;
        }
    };

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        getDateOptions();

        if (filters.date !== null) {
            setUpDocumentUrl(
                {
                    section: extractQueryParams()["section"],
                    application: filters.application,
                },
                config.pageTitle,
            );

            getERFMData();
        }
        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDidMount(() => {
        getERFMData();
        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.date]);

    // App insights tracking
    useEffect(() => {
        document.title = config.pageTitle;
        AppInsights.getInstance().TrackPage(config.pageTitle);
    }, [config.pageTitle]);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            {!state.loading && filters.date === null ? (
                <EmptyTableContainer />
            ) : (
                <>
                    <Stack.Item>
                        <ERFMScorecardFilters
                            erfmState={state}
                            filters={filters}
                            onFilterChange={onFilterChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <AppOverviewIconPanel
                            apps={config.appsList}
                            onAppClick={onFilterChange}
                            selectedApp={filters.application}
                        />
                    </Stack.Item>
                    <Stack.Item styles={commonPageBlock}>
                        <ERFMTable
                            items={state.erfmTableRows}
                            dataByApp={state.dataByApp}
                            dataByCountry={state.dataByCountry}
                            columns={erfmTableColumns}
                            loading={state.loading}
                            application={filters.application}
                        />
                    </Stack.Item>
                </>
            )}
        </>
    );
};

export default ERFMScorecard;
