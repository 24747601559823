import React, { useContext, useEffect, useMemo, useState } from "react";
import AppPerformance from "pages/Performance/AppPerformance";
import * as API from "api";
import { parseTypicalAddInCosts } from "pages/Performance/AddIns/AddInPerformanceHelper";
import { perfConfig } from "pages/Performance/PerfConfig";
import PerformanceContext from "pages/Performance/PerformanceContext";
import {
    AllChartsData,
    AllPerfRankingData,
    AllTypicalAddInCostData,
    PerfChartData,
    PerfChartQuery,
} from "pages/Performance/types";
import {
    adaptToChartingLibrary,
    appScenarioMatrixForEach,
    appScenarioMatrixMap,
    parseRankingResponse,
    populateMatrixWithResponse,
} from "pages/Performance/Win32PerformanceHelper";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

/** This holds all of the performance charts for all apps */
function PerformanceCharts() {
    const performanceOptions = useContext(PerformanceContext);
    const {
        tenantId,
        level,
        partitionBy,
        isMonthlyView,
        valuesToShow,
        startDate,
        endDate,
        dispatcher,
    } = performanceOptions;

    const [chartDataState, setChartDataState] = useState<AllChartsData>();
    const [rankingDataState, setRankingDataState] = useState<AllPerfRankingData>();
    const [addInCostDataState, setAddInCostDataState] =
        useState<AllTypicalAddInCostData>();

    const tables = useMemo(
        () =>
            isMonthlyView
                ? {
                      tenantTableName: "Summary_TenantPerfMonthly",
                      overallTableName: "Summary_TenantPerfOverallMonthly",
                      rankingTableName: "Summary_TenantRankingsMonthly",
                  }
                : {
                      tenantTableName: "Summary_TenantPerf7Day",
                      overallTableName: "Summary_TenantPerfOverall7Day",
                      rankingTableName: "Summary_TenantRankings7Day",
                  },
        [isMonthlyView],
    );

    useEffect(() => {
        const queryChartData = async () => {
            setChartDataState(undefined);
            const queryParams: PerfChartQuery = {
                ...tables,
                startDate,
                tenantId,
                endDate,
                dimensionName: partitionBy,
                valuesToShow,
            };

            try {
                const response = await API.fetchWin32PerfChartsData(queryParams);

                const responseMatrix = populateMatrixWithResponse<
                    string | number,
                    PerfChartData
                >(response);

                const labelsAndData = appScenarioMatrixMap(responseMatrix, (v) =>
                    adaptToChartingLibrary(v, isMonthlyView),
                );

                appScenarioMatrixForEach(labelsAndData, ([labels]) =>
                    dispatcher({
                        type: "addEntries",
                        payload: labels,
                    }),
                );
                setChartDataState(
                    appScenarioMatrixMap(labelsAndData, ([, data]) => data),
                );
            } catch (error) {
                setChartDataState(null);
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchWin32PerfChartsDataException",
                    ),
                    {
                        message: "Failed to fetch data for Win32 perf chart.",
                        queryParams,
                    },
                );
            }
        };
        queryChartData();
    }, [
        tenantId,
        startDate,
        valuesToShow,
        tables,
        partitionBy,
        endDate,
        isMonthlyView,
        dispatcher,
    ]); // End of useEffect.

    useEffect(() => {
        const queryRankingData = async () => {
            setRankingDataState(undefined);
            const response = await API.fetchWin32PerfRankingData({
                date: endDate,
                tenantId,
                rankingTableName: tables.rankingTableName,
                valuesToShow,
            });
            setRankingDataState(parseRankingResponse(response));
        };
        queryRankingData();
    }, [endDate, tenantId, tables, valuesToShow]);

    useEffect(() => {
        const queryTypicalAddInCosts = async () => {
            setAddInCostDataState(undefined);
            try {
                const response = await API.fetchTenantTypicalAddInCost({
                    id: tenantId,
                });

                setAddInCostDataState(parseTypicalAddInCosts(response));
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantTypicalAddInCostException",
                    ),
                    {
                        message: "Failed to fetch typical add-in cost.",
                    },
                    error,
                );
            }
        };

        if (level == "TenantId") queryTypicalAddInCosts();
    }, [tenantId, level]);

    return (
        <>
            {perfConfig.Win32.AppsList.map((appName) => {
                return (
                    <AppPerformance
                        key={appName}
                        appName={appName}
                        rankingData={rankingDataState}
                        chartData={chartDataState}
                        addInCosts={addInCostDataState}
                    />
                );
            })}
        </>
    );
}

export default PerformanceCharts;
