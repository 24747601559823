export const win32PerfEnvironmentData: string = `
// win32PerfEnvironmentData
let data =
TenantInsights_Win32MiscellaneousMetrics
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}"
| where Metric in ("AntiVirus", "Architecture", "Audience", "BootDiskType", "DeviceAge", "DeviceManagement", "Fork", "LicenseDistribution", "Location", "OS", "OfficeDiagnosticConsentLevel", "PredictedPriceBand", "ProcessorCount", "RamGB", "VmType", "WindowsTelemetryLevel");
let locationDate = toscalar(data | where Metric == "Location" | summarize max(todatetime(Date)));
data
| where Metric != "Location" or todatetime(Date) == locationDate
| order by Date asc, Metric, Application, Value
`;
