import React, { useEffect, useState } from "react";
import {
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    LineChart,
    Line,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import { Formatter } from "recharts/lib/component/DefaultLegendContent";
import { AxisDomain, DataKey } from "recharts/types/util/types";
import { TogglingLineChartType } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { chartXAxisPaddingStyles } from "pages/common";

const axisNumberFormat = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
});

const TogglingLineChart = ({
    kind,
    xDataKey,
    xLabel = null,
    yUnit,
    yLabel = null,
    domain,
    values,
    labels,
    width = null,
    height,
    strokeWidth = 1,
    tooltipFormatter = null,
    tooltipStyles = {},
    legendFormatter = null,
}: {
    kind: TogglingLineChartType;
    xDataKey?: DataKey<any>;
    xLabel?: string;
    yUnit?: string;
    yLabel?: string;
    domain?: AxisDomain;
    values?: any[];
    labels: { key?: string; Name?: string; color?: string }[];
    width?: string | number;
    height?: string | number;
    strokeWidth?: string | number;
    tooltipFormatter?: Formatter;
    tooltipStyles?: {
        labelStyle?: React.CSSProperties;
        itemStyle?: React.CSSProperties;
    };
    legendFormatter?: Formatter;
}) => {
    const [lineProps, setLineProps] = useState(
        labels.reduce(
            (a, { key }) => {
                a[key] = false;
                return a;
            },
            { hover: null },
        ),
    );

    const [data, setData] = useState(values.map(kind.dataMapper));
    useEffect(() => {
        setData(values.map(kind.dataMapper));
    }, [kind, values]);

    const selectLine = (e) => {
        setLineProps({
            ...lineProps,
            [e.dataKey]: !lineProps[e.dataKey],
            hover: null,
        });
    };

    // Keeping seperate properties in case we need to change in future.
    // CSS property for the legend without the X axis Label
    const cssWithoutXlabels = {
        left: "48px",
    };

    // CSS property for the legend with X axis Label, need to adjust the Legend position so that it goes down after Label
    const cssWithXlabels = {
        left: "48px",
        bottom: "-10px",
    };

    return (
        <ResponsiveContainer width={width ?? "100%"} height={height}>
            <LineChart data={data}>
                <XAxis
                    dataKey={xDataKey}
                    padding={chartXAxisPaddingStyles}
                    label={
                        xLabel
                            ? {
                                  value: xLabel,
                                  dy: 18,
                              }
                            : null
                    }
                />
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                    domain={domain ?? kind.domain}
                    unit={yUnit ?? kind.yUnit}
                    label={
                        yLabel
                            ? {
                                  value: yLabel,
                                  angle: -90,
                                  dx: -25,
                              }
                            : null
                    }
                    tickFormatter={(num) => axisNumberFormat.format(num)}
                />
                <Tooltip
                    labelStyle={
                        tooltipStyles["labelStyle"]
                            ? tooltipStyles["labelStyle"]
                            : {}
                    }
                    itemStyle={
                        tooltipStyles["itemStyle"] ? tooltipStyles["itemStyle"] : {}
                    }
                    formatter={tooltipFormatter ?? kind.tooltipFormatter}
                />
                <Legend
                    formatter={legendFormatter}
                    onClick={selectLine}
                    wrapperStyle={xLabel ? cssWithXlabels : cssWithoutXlabels}
                />
                {labels.map((label, index) => (
                    <Line
                        strokeWidth={strokeWidth}
                        type="monotone"
                        key={index}
                        name={label.Name ? label.Name : label.key}
                        dataKey={label.key}
                        stroke={label.color}
                        hide={lineProps[label.key] === true}
                        activeDot={{ r: 8 }}
                        connectNulls={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default TogglingLineChart;
