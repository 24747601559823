export const webSignalTrends: string = `
// webSignalTrends
set query_results_cache_max_age = time(12h);
let cohort = "Is\${cohort}";
let color = "\${color}";
let MAU = StagingGetWebSignalTrend("Color_Mau", "MAU", cohort, color);
let MEU = StagingGetWebSignalTrend("Color_Meu", "MEU", cohort, color);
let EngagedEditors = StagingGetWebSignalTrend("Color_EngagedEditors", "EngagedEditors", cohort, color);
let ReturningMAU = StagingGetWebSignalTrend("Color_ReturningMau", "ReturningMAU", cohort, color);
let OpenInClient = StagingGetWebSignalTrend("Color_OpenInClient", "OpenInClient", cohort, color);
let Performance = StagingGetWebSignalTrend("Color_Performance", "Performance", cohort, color);
let Feedback = StagingGetWebSignalTrend("Color_Feedback", "Feedback", cohort, color);
let ACEFreeUsers = StagingGetWebSignalTrend("Color_ACEFreeUsers", "ACEFreeUsers", cohort, color);
let ICEFreeUsers = StagingGetWebSignalTrend("Color_ICEFreeUsers", "ICEFreeUsers", cohort, color);
let ASHA = StagingGetWebSignalTrend("Color_ASHA", "ASHA", cohort, color);
let WXP_MAU = StagingGetWebWXPSignalTrend("Color_Mau", "MAU", cohort, color);
let WXP_MEU = StagingGetWebWXPSignalTrend("Color_Meu", "MEU", cohort, color);
let WXP_EngagedEditors = StagingGetWebWXPSignalTrend("Color_EngagedEditors", "EngagedEditors", cohort, color);
let WXP_ReturningMAU = StagingGetWebWXPSignalTrend("Color_ReturningMau", "ReturningMAU", cohort, color);
let WXP_OpenInClient = StagingGetWebWXPSignalTrend("WXP_OICColor", "OpenInClient", cohort, color);
let WXP_Performance = StagingGetWebWXPSignalTrend("WXP_PerfColor", "Performance", cohort, color);
let WXP_Feedback = StagingGetWebWXPSignalTrend("WXP_FbkColor", "Feedback", cohort, color);
let WXP_ACEFreeUsers = StagingGetWebWXPSignalTrend("WXP_ACEColor", "ACEFreeUsers", cohort, color);
let WXP_ICEFreeUsers = StagingGetWebWXPSignalTrend("WXP_ICEColor", "ICEFreeUsers", cohort, color);
let WXP_ASHA = StagingGetWebWXPSignalTrend("WXP_ASHA_Color", "ASHA", cohort, color);
union
    MAU,
    MEU,
    EngagedEditors,
    ReturningMAU,
    OpenInClient,
    Performance,
    Feedback,
    ACEFreeUsers,
    ICEFreeUsers,
    ASHA,
    WXP_MAU,
    WXP_MEU,
    WXP_EngagedEditors,
    WXP_ReturningMAU,
    WXP_OpenInClient,
    WXP_Performance,
    WXP_Feedback,
    WXP_ACEFreeUsers,
    WXP_ICEFreeUsers,
    WXP_ASHA
| sort by Date asc
`;
