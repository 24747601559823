import React from "react";
import {
    funnelActiveColor,
    funnelHeight,
    funnelInactiveColor,
    funnelTextFill,
    funnelWidth,
    funnelXCoordinate,
    funnelYCoordinate,
    strokeColor,
} from "components/CustomComponents/StackedAreaChart/constants";

export const FunnelArrow = ({ onClickHandler, label, isActive }) => {
    return (
        <svg
            width={funnelWidth}
            height={funnelHeight}
            onClick={() => onClickHandler()}
            style={{ cursor: "pointer" }}
        >
            <g>
                <path
                    stroke={strokeColor}
                    d="M-0.000003814697265625,230.5 L82.49996618530274,230.5 L110.00000618530274,249.99996212233307 L82.49996618530274,269.5000083623147 L-0.000003814697265625,269.5000083623147 L27.500026185302733,249.99996212233307 L-0.000003814697265625,230.5 z"
                    strokeWidth="0"
                    fill={isActive() ? funnelActiveColor : funnelInactiveColor}
                />
                <text
                    transform="matrix(1 0 0 1 0 0)"
                    fontWeight="semibold"
                    textAnchor="start"
                    fontSize="14"
                    y={funnelYCoordinate}
                    x={funnelXCoordinate}
                    strokeWidth="0"
                    stroke={strokeColor}
                    fill={funnelTextFill}
                >
                    {label}
                </text>
            </g>
        </svg>
    );
};
