import React from "react";
import { initializeIcons, registerIcons } from "@fluentui/react";

const wordIcon = "img/word_32x.svg";
const excelIcon = "img/Excel_32x.svg";
const powerpointIcon = "img/PowerPoint_32x.svg";
const onenoteIcon = "img/OneNote_32x.svg";
const visioIcon = "img/visio_32x.svg";
const smallWordIcon = "img/word_16x.svg";
const smallExcelIcon = "img/excel_16x.svg";
const smallPowerpointIcon = "img/powerPoint_16x.svg";
const smallOnenoteIcon = "img/oneNote_16x.svg";
const smallVisioIcon = "img/visio_16x.svg";
const smallOutlookIcon = "img/outlook_16x.svg";
const wxpIcon = "img/WXP.svg";
const wxpoIcon = "img/WXPO.svg";
const wxponIcon = "img/WXPON.svg";
const allAppIcon = "img/All.svg";
const alertIcon = "img/alert.svg";
const shieldIcon = "img/shield.svg";
const outlookIcon = "img/Outlook_32x.svg";
const macIcon = "img/Mac_32x.svg";
const teamsIcon = "img/Teams.svg";
const loopIcon = "img/loop_32x.svg";
const whiteboardIcon = "img/Whiteboard.svg";
const microsoftIcon = "img/Microsoft.ico";
const streamIcon = "img/Stream.svg";
const m365chatIcon = "img/M365Chat.svg";
const formsIcon = "img/Forms_32x.svg";
const plannerIcon = "img/Planner_32x.svg";
const doraIcon = "img/dora_Logo.svg";
const betaIcon = "img/beta.svg";

function addIconsFromImgPath(iconsToRegister: string[]): any {
    const icons: { [key: string]: JSX.Element } = {};
    iconsToRegister.forEach((icon) => {
        icons[icon] = React.createElement("img", { src: icon });
    });
    return icons;
}

function registerCustomIcons(iconsToRegister: string[]): void {
    let icons: { [key: string]: JSX.Element };
    if (iconsToRegister.length > 0) {
        icons = addIconsFromImgPath(iconsToRegister);
        registerIcons({ icons });
    }
}

export const registerTenantsViewIcons = (): void => {
    initializeIcons();
    const icons: string[] = [
        wordIcon,
        excelIcon,
        powerpointIcon,
        visioIcon,
        onenoteIcon,
        visioIcon,
        outlookIcon,
        wxpIcon,
        allAppIcon,
        shieldIcon,
        alertIcon,
        wxpoIcon,
        wxponIcon,
        macIcon,
        teamsIcon,
    ];
    registerCustomIcons(icons);
};

export const registerOverviewIcons = (): void => {
    initializeIcons();
    const icons: string[] = [
        shieldIcon,
        smallWordIcon,
        smallExcelIcon,
        smallPowerpointIcon,
        smallVisioIcon,
        smallOutlookIcon,
        smallOnenoteIcon,
    ];
    registerCustomIcons(icons);
};

export const registerAppIcons = (): void => {
    initializeIcons();
    const icons: string[] = [
        wordIcon,
        excelIcon,
        powerpointIcon,
        visioIcon,
        onenoteIcon,
        visioIcon,
        wxpIcon,
        allAppIcon,
        outlookIcon,
        alertIcon,
        loopIcon,
        teamsIcon,
        whiteboardIcon,
        microsoftIcon,
        m365chatIcon,
        streamIcon,
        formsIcon,
        plannerIcon,
        doraIcon,
        betaIcon,
    ];
    registerCustomIcons(icons);
};

export const registerPlatformIcons = (): void => {
    initializeIcons();
    const icons: string[] = [macIcon, teamsIcon];
    registerCustomIcons(icons);
};
