import React, { useEffect, useState } from "react";
import {
    DefaultButton,
    IContextualMenuItem,
    Icon,
    IconButton,
    Label,
    TooltipHost,
} from "@fluentui/react";
import "components/CustomComponents/NavigableDatePicker/style.css";
import { getCurrentSLAText } from "components/InsightsComponents/Helpers";
import { formatDisplayDate } from "utils/Helpers";

export const coalesceDateOptions = (
    distinctDatesArray: string[],
    onDateChange: (value: string) => void,
) => {
    const dateOptions: IContextualMenuItem[] = [];

    if (distinctDatesArray.length === 0) {
        return dateOptions;
    }

    interface tempInterface {
        [monthString: string]: string[];
    }

    const datesTemp: tempInterface = {};

    distinctDatesArray.forEach((date) => {
        const convertedMonth = formatDisplayDate(date);

        if (!(convertedMonth in datesTemp)) {
            datesTemp[convertedMonth] = [];
        }

        datesTemp[convertedMonth].push(date);
    });

    Object.keys(datesTemp).forEach((month) => {
        dateOptions.push({
            key: month,
            text: month,
            subMenuProps: {
                items: datesTemp[month].map((dates) => ({
                    text: dates,
                    key: dates,
                })),
                onItemClick: (_ev, item: IContextualMenuItem) => {
                    onDateChange(item?.key);
                },
                className: "navigableDatePickerMenu",
            },
        });
    });

    return dateOptions;
};

export interface NavigableDatePickerProps {
    backTitle: string;
    forwardTitle: string;
    dates: string[];
    onDateChange: (date: string) => void;
    selectedDate?: string;
}

const NavigableDatePicker = ({
    backTitle,
    forwardTitle,
    dates,
    onDateChange,
    selectedDate,
}: NavigableDatePickerProps) => {
    const dateOptions = coalesceDateOptions(dates, (selectedDate) => {
        onDateChange(selectedDate);
    });
    const [selectedDateIndex, setDateIndex] = useState<number>(() => {
        if (selectedDate) {
            return dates.indexOf(selectedDate);
        }

        return 0;
    });

    useEffect(() => {
        const index = dates.indexOf(selectedDate);
        setDateIndex(index > 0 ? index : 0);
    }, [dates, selectedDate]);

    return (
        <>
            <TooltipHost content={`SLA: ${getCurrentSLAText()}`}>
                <Icon iconName="info" className="slaInfoIcon" />
            </TooltipHost>
            <Label className="filterLabel">Time Period: </Label>
            <IconButton
                iconProps={{ iconName: "ChevronLeftSmall" }}
                title={backTitle}
                ariaLabel={backTitle}
                disabled={selectedDateIndex === dates.length - 1}
                onClick={() => {
                    setDateIndex(selectedDateIndex + 1);
                    onDateChange(dates[selectedDateIndex + 1]);
                }}
            />
            <DefaultButton
                text={dates[selectedDateIndex]}
                menuProps={{
                    items: dateOptions,
                    className: "navigableDatePickerMenu",
                }}
                styles={{
                    root: {
                        borderRadius: 14,
                        fontSize: 12,
                        border: "1px solid #bdbdbda6",
                    },
                }}
            />
            <IconButton
                iconProps={{ iconName: "ChevronRightSmall" }}
                title={forwardTitle}
                ariaLabel={forwardTitle}
                data-testid="nextDateButton"
                disabled={selectedDateIndex === 0}
                onClick={() => {
                    setDateIndex(selectedDateIndex - 1);
                    onDateChange(dates[selectedDateIndex - 1]);
                }}
            />
        </>
    );
};

export default NavigableDatePicker;
