import {
    aiMBRAppGoals,
    aiMBRFeatureGoals,
    aiMBRScorecardMetrics,
    aiMBROverviewScorecardMetrics,
    aiScorecardChart,
    aiScorecardDates,
    aiScorecardMetrics,
    copilotFeatures,
    copilotRetention,
    copilotUsageTrends,
    copilotRAITrends,
    copilotRetentionTrends,
    copilotTriedCountsPerUserTrends,
    newTenants,
    topTenantsUsage,
    copilotTenantLicenses,
    copilotSummaryScorecard,
    copilotSummaryScorecardForExport,
    copilotM365Scorecard,
    copilotTriedTrends,
    copilotQuickRatioTrends,
    copilotuserjourneyFunnelStages,
    copilotMTEandTVTable,
    copilotCapData,
    copilotCapTrendsData,
} from "queries/AIScorecard/aiScorecardQueries";

export const aiScorecardQueries = {
    aiMBRAppGoals,
    aiMBRFeatureGoals,
    aiMBRScorecardMetrics,
    aiMBROverviewScorecardMetrics,
    aiScorecardMetrics,
    aiScorecardDates,
    aiScorecardChart,
    copilotFeatures,
    copilotRetention,
    copilotUsageTrends,
    copilotRAITrends,
    copilotRetentionTrends,
    copilotTriedCountsPerUserTrends,
    newTenants,
    topTenantsUsage,
    copilotTenantLicenses,
    copilotSummaryScorecard,
    copilotSummaryScorecardForExport,
    copilotM365Scorecard,
    copilotTriedTrends,
    copilotQuickRatioTrends,
    copilotuserjourneyFunnelStages,
    copilotMTEandTVTable,
    copilotCapData,
    copilotCapTrendsData,
};
