import React from "react";
import { localPoint } from "@visx/event";
import { Bar } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { getAggregatedSliceValueAndPercentageForStage } from "components/CommonFunnelComponents/CommonFunnelStages/commonFunnelStagesHelper";
import { FunnelStageProps } from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { tooltipStyles } from "components/CustomComponents/FunnelBarChart/constants";
import { FunnelBarChartProps } from "components/CustomComponents/FunnelBarChart/types";

export const FunnelBarChart = ({
    chartFormattingData,
    data,
    xScale,
    yScale,
    showAllStagesHandler,
}: FunnelBarChartProps) => {
    let toolTipTimeout: number;
    const retentionFriendlyName = "M1 Retention";
    const {
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    } = useTooltip<FunnelStageProps>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
    });

    const handleMouseMoveForToolTip = (
        event: React.MouseEvent<SVGRectElement, MouseEvent>,
        data: FunnelStageProps,
    ) => {
        if (toolTipTimeout) clearTimeout(toolTipTimeout);
        const eventSvgCoords = localPoint(event);

        showTooltip({
            tooltipData: data,
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
        });
    };

    const barY = yScale(data.slices[0].value) ?? 0;
    const barHeight = chartFormattingData.height - barY;

    const getToolTipMessage = (stageData: FunnelStageProps) => {
        const [stageAggregatedValue, stageTotalPercent] =
            getAggregatedSliceValueAndPercentageForStage(stageData);
        const stageFriendlyName = stageData.stage.friendlyName;
        return (
            <div>
                {stageAggregatedValue === 0 && stageData.canBeNull ? (
                    <div className="stackedBarChartToolTipMajor">
                        <strong>{stageFriendlyName}</strong> - Data is not available
                        yet for the selected cohort. <br />
                        {stageFriendlyName === retentionFriendlyName &&
                            `Per M1 retention definition, the data will be available 28 days from the selected date.`}
                        {showAllStagesHandler && (
                            <>
                                <br />
                                <br />
                                Click to jump to the latest date for which we have
                                all stages available.
                            </>
                        )}
                    </div>
                ) : (
                    <div className="stackedBarChartToolTipMajor">
                        <strong>{stageFriendlyName}</strong> - [{stageTotalPercent}%
                        or {getCompactValue(stageAggregatedValue)}
                        ]
                        <br />
                        {stageData.toolTipMessageOverride &&
                        stageData.toolTipMessageOverride !== ""
                            ? `(${stageData.toolTipMessageOverride})`
                            : "(from top of the funnel)"}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="funnelBarContainer">
            <svg
                ref={containerRef}
                width={chartFormattingData.width}
                height={chartFormattingData.height}
            >
                {data &&
                data.slices &&
                data.slices.length > 0 &&
                data.slices[0].value ? (
                    <Bar
                        key={`bar-${data.stage.id}`}
                        x={xScale(data.stage.id)}
                        y={barY}
                        width={xScale.bandwidth()}
                        height={barHeight}
                        fill="#0054e3"
                        onMouseLeave={() => {
                            toolTipTimeout = window.setTimeout(() => {
                                hideTooltip();
                            }, 150);
                        }}
                        onMouseMove={(event) => {
                            handleMouseMoveForToolTip(event, data);
                        }}
                    />
                ) : data.canBeNull ? (
                    <svg width="75" height="500" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <rect
                                style={showAllStagesHandler && { cursor: "pointer" }}
                                stroke="#000"
                                strokeDasharray="2,2"
                                id="svg_1"
                                height="181.99999"
                                width="69"
                                y="317.00001"
                                x="3"
                                fill="#d9d9d9"
                                opacity="0.3"
                                onMouseLeave={() => {
                                    toolTipTimeout = window.setTimeout(() => {
                                        hideTooltip();
                                    }, 300);
                                }}
                                onMouseMove={(event) => {
                                    handleMouseMoveForToolTip(event, data);
                                }}
                                onClick={() => {
                                    if (showAllStagesHandler) {
                                        showAllStagesHandler();
                                    }
                                }}
                            />
                            <text
                                filter="url(#svg_3_blur)"
                                textAnchor="start"
                                fontFamily="Noto Sans JP"
                                fontSize="12"
                                strokeWidth="0"
                                id="svg_3"
                                y="427.79999"
                                x="5"
                                strokeDasharray="2,2"
                                stroke="#000"
                                fill="#666666"
                            >
                                No Data Yet!
                            </text>
                        </g>
                        <defs>
                            <filter
                                height="200%"
                                width="200%"
                                y="-50%"
                                x="-50%"
                                id="svg_3_blur"
                            >
                                <feGaussianBlur
                                    stdDeviation="0.1"
                                    in="SourceGraphic"
                                />
                            </filter>
                        </defs>
                    </svg>
                ) : (
                    <EmptyTableContainer message="Error retrieving data." />
                )}
            </svg>
            {tooltipOpen && tooltipData && (
                <TooltipInPortal
                    top={tooltipTop}
                    left={tooltipLeft}
                    style={tooltipStyles}
                >
                    {getToolTipMessage(tooltipData)}
                </TooltipInPortal>
            )}
        </div>
    );
};
