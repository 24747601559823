import React from "react";
import { Group } from "@visx/group";
import { AreaStack } from "@visx/shape";
import { FunnelArrow } from "components/CustomComponents/StackedAreaChart/FunnelArrow";
import { areaFillColor } from "components/CustomComponents/StackedAreaChart/constants";
import { StackedAreaChartProps } from "components/CustomComponents/StackedAreaChart/types";

export const StackedAreaChart = ({
    chartFormattingData,
    keys,
    data,
    defaultLabel,
    getXAxis,
    getY0,
    getY1,
    isStackActive,
    isStackInactive,
    isDefaultState,
    defaultLabelOnClickHandler,
    isLabelActive,
}: StackedAreaChartProps) => {
    const getStackDropOff = (stack: any) => {
        return (
            stack[1]["data"][stack["key"]].toFixed(2) -
            stack[0]["data"][stack["key"]].toFixed(2)
        ).toFixed(2);
    };

    const stackHasBothDataPoints = (stack: any) => {
        return stack[0]["data"][stack["key"]] && stack[1]["data"][stack["key"]];
    };

    return (
        <svg width={chartFormattingData.width} height={chartFormattingData.height}>
            <Group>
                <AreaStack
                    top={chartFormattingData.margin.top}
                    left={chartFormattingData.margin.left}
                    keys={keys}
                    data={data}
                    x={(d) => getXAxis(d) ?? 0}
                    y0={(d) => getY0(d) ?? 0}
                    y1={(d) => getY1(d) ?? 0}
                >
                    {({ stacks, path }) =>
                        stacks.map((stack, i) => (
                            <Group key={`areaStack-${i}`}>
                                {stackHasBothDataPoints(stack) && (
                                    <Group>
                                        <path
                                            className={`${stack.key}`}
                                            key={`areaStack-path-${stack.key}`}
                                            d={path(stack) || ""}
                                            stroke="transparent"
                                            fill={areaFillColor}
                                            opacity={`${
                                                isStackInactive(stack)
                                                    ? "0.75"
                                                    : "100"
                                            }`}
                                        />
                                        {isStackActive(stack) && (
                                            <FunnelArrow
                                                onClickHandler={
                                                    defaultLabelOnClickHandler
                                                }
                                                label={`${getStackDropOff(stack)}%`}
                                                isActive={isLabelActive}
                                            />
                                        )}
                                    </Group>
                                )}
                            </Group>
                        ))
                    }
                </AreaStack>
                {isDefaultState && (
                    <FunnelArrow
                        onClickHandler={defaultLabelOnClickHandler}
                        label={defaultLabel}
                        isActive={isLabelActive}
                    />
                )}
            </Group>
        </svg>
    );
};
