import { unhealthyTenantMoverShaker } from "./unhealthyTenantMoverShaker";
import { unhealthyTenantTrend } from "./unhealthyTenantTrend";
import { topTrendingSignals } from "./topTrendingSignals";
import { win32HealthChartMTR } from "./win32HealthChartMTR";
import { webHealthChartMTR } from "./webHealthChartMTR";
import { macHealthChartMTR } from "./macHealthChartMTR";
import { webSignalTrends } from "./webSignalTrends";
import { win32SignalTrends } from "./win32SignalTrends";

let MTRQueries: {} = {
    unhealthyTenantMoverShaker,
    unhealthyTenantTrend,
    topTrendingSignals,
    win32HealthChartMTR,
    webHealthChartMTR,
    macHealthChartMTR,
    webSignalTrends,
    win32SignalTrends,
};

export default MTRQueries;
