import React, { useEffect, useState } from "react";
import {
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    Stack,
} from "@fluentui/react";
import { CohortList } from "components/ConsumerProductDrilldownComponents/CohortList/CohortList";
import CohortTitle from "components/ConsumerProductDrilldownComponents/CohortTitle/CohortTitle";
import { ConsumerLicense } from "components/ConsumerProductDrilldownComponents/ConsumerLicense/ConsumerLicense";
import { ConsumerMetrics } from "components/ConsumerProductDrilldownComponents/ConsumerMetrics/ConsumerMetrics";
import { ConsumerRetention } from "components/ConsumerProductDrilldownComponents/ConsumerRetention/ConsumerRetention";
import { DatePicker } from "components/CustomComponents/DatePicker/DatePicker";
import { ScoreStatus } from "components/CustomComponents/ScoreStatus/ScoreStatus";
import { Tabs } from "components/CustomComponents/Tabs/Tabs";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { getDateOptions } from "api";
import { getCoreHealthMetricTemplate } from "api/commonApiHandler";
import Tile from "components/CustomComponents/Tile";
import { getConfig, platformsConfig } from "config/PlatformConfig";
import "pages/common.css";
import {
    commonPageBlock,
    commonPageStyle,
    horizontalStackTokens,
    searchCategoriesStyle,
} from "pages/common";
import { CommonProps } from "pages/commonTypes";
import "pages/Tenants/style.css";
import { fetchCohortCoreHealthMetrics } from "pages/ConsumerProductDrillDown/ApiHandler";
import { getCohortMetadataTemplate } from "pages/ConsumerProductDrillDown/ConsumerProductDrillDownHelper";
import {
    CohortMetricsType,
    CohortProductDrilldownState,
    ConsumerProductDrilldownFilterState,
} from "pages/ConsumerProductDrillDown/types";
import { getTenantDrillDownPageTitle as getDrillDownPageTitle } from "pages/InProductDrillDown/InProductDrillDownHelper";
import { logException } from "utils/AppInsightsHelper";
import { PivotSize } from "utils/Constants";
import { ConsumerProductDrilldownMetricsTabs } from "utils/ConsumerConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import {
    extractQueryParams,
    formatDateOptions,
    setUpDocumentUrl,
} from "utils/Helpers";
import { registerTenantsViewIcons } from "utils/IconRegistration";
import { CONSUMERHEALTH_MESSAGES } from "utils/Messages";

const getInitialState = (platform: string): CohortProductDrilldownState => {
    const initialState = {
        loading: true,
        cohortOverallScore: {
            score: 0,
            status: "",
            momChange: 0,
            pastStatus: [],
            scoreBreakDown: [],
        },
        dateOptions: [],
    };
    const coreHealthMetrics: CohortMetricsType = {
        coreHealthTiles: getCoreHealthMetricTemplate(platform),
        metadata: getCohortMetadataTemplate(),
        sparkLineScores: [],
        scoreStatus: { score: 0, status: "" },
    };
    return { ...initialState, coreHealthMetrics };
};

const ConsumerProductDrillDown = (props: CommonProps) => {
    registerTenantsViewIcons();

    const platform = props.platform;
    const config = getConfig(platform);
    const pageTitle = getDrillDownPageTitle(platform);
    const filtersFromQueryParams = extractQueryParams();

    const initialFiltersState: ConsumerProductDrilldownFilterState = {
        application: config.defaults.app,
        cohortType: config.defaults.cohort,
        cohortName: "Freemium - Low Usage",
        date: "",
    };

    const [filters, setFilters] = useState<ConsumerProductDrilldownFilterState>(
        () => {
            const newFilters: ConsumerProductDrilldownFilterState = {
                ...initialFiltersState,
                ...filtersFromQueryParams,
            };

            if (newFilters.application === "WXP") newFilters.application = "Word";

            return newFilters;
        },
    );

    const [error, setError] = useState<string>(null);
    const [data, setData] = useState<CohortProductDrilldownState>(
        getInitialState(platform),
    );

    useEffect(() => {
        const setDatesData = async (platform: string) => {
            try {
                const dates = await getDateOptions(platform);
                const responseData = formatDateOptions(dates);

                if (filters.date === "") {
                    const currentDate = responseData[0]?.key as string;

                    setFilters((oldFiltersState) => {
                        return { ...oldFiltersState, date: currentDate };
                    });
                }

                setData((oldDataState) => {
                    return { ...oldDataState, dateOptions: responseData };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ConsumerOverviewDatesOrConfigFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching dates/table meta data for Consumer Overview Page from Kusto",
                    },
                    error,
                );
                setError(error.message);
                setData((oldDataState) => {
                    return { ...oldDataState, loading: false };
                });
            }
        };

        setDatesData(platform);

        setUpDocumentUrl(
            {
                application: filters.application,
                cohortType: filters.cohortType,
                cohortName: filters.cohortName,
            },
            pageTitle,
        );
    }, [
        filters.application,
        filters.cohortName,
        filters.cohortType,
        filters.date,
        pageTitle,
        platform,
    ]);

    useEffect(() => {
        const getData = async () => {
            setData((oldState) => {
                return {
                    ...oldState,
                    loading: true,
                };
            });

            const coreHealthMetrics = await fetchCohortCoreHealthMetrics(
                {
                    application: filters.application,
                    cohortName: filters.cohortName,
                    cohortType: filters.cohortType,
                    date: filters.date,
                },
                config.platform,
            );

            if (typeof coreHealthMetrics === "string") {
                setError(coreHealthMetrics);
                setData((oldDataState) => {
                    return { ...oldDataState, loading: false };
                });
            } else {
                setData((oldState) => {
                    return {
                        ...oldState,
                        coreHealthMetrics,
                        loading: false,
                    };
                });
            }
        };

        getData();
    }, [
        config.platform,
        filters.application,
        filters.cohortName,
        filters.cohortType,
        filters.date,
    ]);

    const onFilterChange = (newFilter: {}) => {
        const updatedFilter = { ...filters, ...newFilter };
        const dateFilter = updatedFilter["date"];
        delete updatedFilter["date"]; // deleteing the date filter here because we dont want to have date in the URL
        setUpDocumentUrl(updatedFilter, pageTitle);

        const finalFilter = { ...updatedFilter, ["date"]: dateFilter }; // resetting the date filter back to get the data
        setFilters(finalFilter);
    };

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <Stack horizontal disableShrink styles={commonPageStyle}>
                <Stack.Item styles={searchCategoriesStyle}>
                    <CohortList onCohortChange={onFilterChange} />
                </Stack.Item>
                <Stack.Item className="customerProductInsightsPageContentStyle">
                    <Stack.Item>
                        <div className="orgHeader">
                            <CohortTitle
                                metadata={data.coreHealthMetrics.metadata}
                                loading={data.loading}
                            />
                        </div>
                    </Stack.Item>
                    {/*TODO: Remove this messagebar once health work is finalized*/}
                    <MessageBar
                        messageBarType={MessageBarType.severeWarning}
                        isMultiline={false}
                    >
                        {CONSUMERHEALTH_MESSAGES.IN_PREVIEW}
                    </MessageBar>
                    <Stack horizontal className="filterPanel">
                        <div className="timePeriod">
                            <DatePicker
                                dateOptions={data.dateOptions}
                                onChangeSelection={(_event, option) => {
                                    onFilterChange({ date: option.key as string });
                                }}
                                selected={filters.date}
                            />
                        </div>
                    </Stack>
                    <div className="break" />
                    <h2 className="customerMetricGroupingTitle">
                        Core Health Metrics
                    </h2>
                    <Tabs
                        defaultSelectedTab={filters.application}
                        onTabClick={(item?: PivotItem) => {
                            onFilterChange({
                                application: item.props.itemKey as string,
                            });
                        }}
                    >
                        {platformsConfig[platform].appsList.map((app) => (
                            <Stack key={app}>
                                <Stack.Item>
                                    <ScoreStatus
                                        score={
                                            data.coreHealthMetrics.scoreStatus.score
                                        }
                                        application={app}
                                        status={
                                            data.coreHealthMetrics.scoreStatus.status
                                        }
                                        statusTrend={[]}
                                        loading={data.loading}
                                        momScore={0}
                                        showStatusTrend={false}
                                        prefixText={"Health"}
                                    />

                                    <div className="coreHealthFilters" />
                                    <ul
                                        className={`workloadTilesMetrics corePlatformTiles${platform}`}
                                    >
                                        {Object.keys(
                                            data.coreHealthMetrics.coreHealthTiles,
                                        ).map((metric, i) => {
                                            return (
                                                <Tile
                                                    data={
                                                        data.coreHealthMetrics
                                                            .coreHealthTiles[metric]
                                                    }
                                                    key={i}
                                                    sparkLineScores={
                                                        data.coreHealthMetrics
                                                            .sparkLineScores ?? []
                                                    }
                                                    loading={data.loading}
                                                    link={{}}
                                                />
                                            );
                                        })}
                                    </ul>
                                </Stack.Item>
                                <div className="break" />

                                <h2 className="customerMetricGroupingTitle">
                                    Consumer Insights
                                </h2>
                                <Pivot linkSize={PivotSize.large}>
                                    <PivotItem
                                        itemKey={
                                            ConsumerProductDrilldownMetricsTabs.AppMetrics
                                        }
                                        headerText={
                                            ConsumerProductDrilldownMetricsTabs.AppMetrics
                                        }
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <ConsumerMetrics
                                                ConsumerDrilldownFilters={filters}
                                            />
                                        </Stack>
                                    </PivotItem>

                                    <PivotItem
                                        itemKey={
                                            ConsumerProductDrilldownMetricsTabs.Environment
                                        }
                                        headerText={
                                            ConsumerProductDrilldownMetricsTabs.Environment
                                        }
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <Stack
                                                horizontal
                                                tokens={horizontalStackTokens}
                                            >
                                                <Stack.Item grow={1}>
                                                    <ConsumerLicense
                                                        ConsumerDrilldownFilters={
                                                            filters
                                                        }
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>
                                    </PivotItem>

                                    <PivotItem
                                        itemKey={
                                            ConsumerProductDrilldownMetricsTabs.Retention
                                        }
                                        headerText={
                                            ConsumerProductDrilldownMetricsTabs.Retention
                                        }
                                    >
                                        <Stack styles={commonPageBlock}>
                                            <Stack
                                                horizontal
                                                tokens={horizontalStackTokens}
                                            >
                                                <Stack.Item grow={1}>
                                                    <ConsumerRetention
                                                        ConsumerDrilldownFilters={
                                                            filters
                                                        }
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>
                                    </PivotItem>
                                </Pivot>
                            </Stack>
                        ))}
                    </Tabs>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default ConsumerProductDrillDown;
