export const webconsumerSparkLineScores: string = `
// Web consumer SparkLineScores - show bars in each metric signal for past 18 months
let currDate = todatetime("\${date}");
let oldestDate = datetime_add('month',-17,currDate);
let app = "\${application}";
let cohortType = "\${cohortType}";
let cohortName = "\${cohortName}";
let pivot = "All";
let host = "OneDriveWOPI";
ConsumerOverviewScore
    | where CohortType == cohortType and CohortName  == cohortName and Application == app and PivotName == pivot and Host == host
    | where todatetime(Date) between (oldestDate .. currDate)
    | project Date, MAURate, MAURate_Color, MEURate, MEURate_Color, RetentionRate, RetentionRate_Color, Performance_Score.EUPL_P95, Performance_Color.EUPL_P95, Performance_Score.EUPL_P50, Performance_Color.EUPL_P50, Reliability_Score.ASHA, Reliability_Color.ASHA,Feedback_Score.SAS, Feedback_Color.SAS
    | order by Date asc `;
