import React from "react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { UserFrequency } from "components/TenantDrilldownComponents/UserFrequency";

interface UserFrequencyPropsType {
    payload: {};
    loading: boolean;
    appsList: Array<string>;
}
export const UserFrequencyWrapper = ({
    payload,
    loading,
    appsList,
}: UserFrequencyPropsType) => {
    return (
        <>
            {" "}
            <h2 className="commonPageBlockTitle"> User Frequency </h2>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                payload && (
                    <UserFrequency
                        payload={payload}
                        metric="SessionBucket"
                        apps={appsList}
                        xLabelName="Open Documents"
                    />
                )
            )}
        </>
    );
};
