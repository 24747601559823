import React from "react";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { HealthyTenantTrendPivotProps } from "pages/MTR/types";
import { HealthyTenantTrendPivots } from "utils/Constants";

export const HealthyTenantTrendPivot = ({
    pivot,
    onPivotClick,
}: HealthyTenantTrendPivotProps) => {
    return (
        <Stack>
            <Pivot onLinkClick={onPivotClick} selectedKey={pivot}>
                <PivotItem
                    headerText={HealthyTenantTrendPivots.Chart.headerText}
                    itemKey={HealthyTenantTrendPivots.Chart.itemKey}
                ></PivotItem>
                <PivotItem
                    headerText={HealthyTenantTrendPivots.Table.headerText}
                    itemKey={HealthyTenantTrendPivots.Table.itemKey}
                ></PivotItem>
            </Pivot>
        </Stack>
    );
};
