import React from "react";
import { Stack } from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import ScenarioChart from "pages/Performance/ScenarioChart";
import { perfConfig } from "pages/Performance/PerfConfig";
import {
    AllChartsData,
    AllPerfRankingData,
    AllTypicalAddInCostData,
    App,
} from "pages/Performance/types";
import { AddInBootCost } from "./AddIns/AddInBootCost";

function AppPerformance({
    appName,
    rankingData,
    chartData,
    addInCosts,
}: {
    appName: App;
    rankingData: AllPerfRankingData;
    chartData: AllChartsData;
    addInCosts: AllTypicalAddInCostData;
}) {
    const scenarios = perfConfig.Win32.scenarios[appName];
    return (
        <Stack style={{ marginBottom: 20 }}>
            <ApplicationTitle app={appName} />
            <Stack horizontal horizontalAlign="space-between" wrap>
                {scenarios.map((scenario) => {
                    return (
                        <Stack.Item
                            className="scenarioChart"
                            key={`${appName}.${scenario}`}
                        >
                            <ScenarioChart
                                scenario={scenario}
                                appName={appName}
                                ranking={rankingData?.[appName]?.[scenario]}
                                values={chartData?.[appName]?.[scenario]}
                            ></ScenarioChart>
                        </Stack.Item>
                    );
                })}
            </Stack>
            <AddInBootCost cost={addInCosts?.[appName]} />
        </Stack>
    );
}

export default AppPerformance;
