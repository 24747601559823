import React, { useState, useEffect } from "react";
import { Bar, ComposedChart, XAxis, YAxis, Legend, Tooltip, Line } from "recharts";
import { ComboBox, IComboBox, IComboBoxOption, Label, Stack } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { updateScorecardChartData } from "components/ConsumerCamauComponents/ApiHandler";
import {
    chartFlyoutBarsDisplayNames,
    chartFlyoutLinesDisplayNames,
    defaultTimeSpanByCadence,
    legendPayload,
} from "components/ConsumerCamauComponents/ConsumerCamauHelper";
import "components/ConsumerCamauComponents/style.css";
import {
    ConsumerCamauFlyoutPropsType,
    ConsumerCamauRowType,
    LegendPayloadType,
} from "components/ConsumerCamauComponents/types";
import { getChartColor, getDomainForYAxis } from "utils/Helpers";

const timespanOptions = [1, 3, 6, 9, 12, 24, 36].map((v) => ({
    key: v,
    text: v.toString(),
}));

const getMetricFromGoalField = (metric: string): string => {
    return metric.replace(/Target/g, "");
};

export const CamauScorecardChartFlyout = ({
    isPanelOpen,
    togglePanel,
    segment,
    application,
    cadence,
    data,
}: ConsumerCamauFlyoutPropsType) => {
    const [selectedApp, setSelectedApp] = useState<string>(application);
    const [segmentSelected, changeSegmentSelected] = useState<string>(segment);
    const [showGoals, toggleGoals] = useState<boolean>(false);
    const [legendProps, setLegendProps] =
        useState<LegendPayloadType[]>(legendPayload);
    const [chartData, setChartData] = useState<ConsumerCamauRowType[]>([]);
    const [timespan, setTimespan] = useState<number>(
        cadence === "Daily"
            ? defaultTimeSpanByCadence.Daily
            : defaultTimeSpanByCadence.Monthly,
    );

    const onTimespanChange = async (
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ) => {
        let selectedTimespan = (option?.key as number) ?? Math.round(Number(value));
        if (!(selectedTimespan > 0))
            selectedTimespan =
                cadence === "Daily"
                    ? defaultTimeSpanByCadence.Daily
                    : defaultTimeSpanByCadence.Monthly;

        setTimespan(selectedTimespan);
    };

    // Metric and its Goal should be associated. Selecting/Unselecting a metric from legend
    // should also do the same for the metric Goal (when Goals is toggled on)
    // metric inactive, show goal -> goal inactive
    // metric inactive, no show goal -> goal inactive
    // metric active, show goal -> goal active
    // metric active, no show goal -> goal inactive
    const clickMetricFromLegend = (e) => {
        const updatedMetricProps = legendProps.map((legend) =>
            legend.dataKey == e.dataKey
                ? { ...legend, inactive: !legend.inactive }
                : legend,
        );

        const updatedGoalProps = updatedMetricProps.map((legend) =>
            legend.dataKey.includes("_Target") &&
            getMetricFromGoalField(legend.dataKey) == e.dataKey
                ? {
                      ...legend,
                      inactive: showGoals
                          ? updatedMetricProps.find(
                                (metric) => metric.dataKey === e.dataKey,
                            ).inactive
                          : true,
                  }
                : legend,
        );

        setLegendProps(updatedGoalProps);
    };

    const numberFormat = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
    });

    const customToolTipContent = (value, name, data): string => {
        const formattedValue = numberFormat.format(value);
        if (name == "MAU" || name == "CAMAU" || name == "CAMAU %") {
            const temp = data.dataKey.split("_");
            const goalField = temp[0] + "_Target";
            const goalValue = data.payload[goalField] ?? "NA";
            const formattedGoalValue =
                typeof goalValue === "number"
                    ? numberFormat.format(goalValue)
                    : "NA";
            return `${formattedValue} (Targets: ${formattedGoalValue})`;
        }
        return `${formattedValue}`;
    };

    // The 'segment' property is changed when a segment is clicked from table, so when a segment is clicked on the camau scorecard table
    // All the filters in the flyout should be reset to defaults from the Table
    useEffect(() => {
        changeSegmentSelected(segment);
        setLegendProps(legendPayload);
        setSelectedApp(application);
        toggleGoals(false);
        setTimespan(
            cadence === "Daily"
                ? defaultTimeSpanByCadence.Daily
                : defaultTimeSpanByCadence.Monthly,
        );
    }, [application, segment, changeSegmentSelected, setTimespan, cadence]);

    useEffect(() => {
        const updatedChartData = updateScorecardChartData(
            segmentSelected,
            selectedApp,
            timespan,
            data,
        );

        setChartData(updatedChartData);
    }, [data, timespan, segmentSelected, selectedApp]);

    return (
        <Panel
            isLightDismiss
            isOpen={isPanelOpen}
            type={PanelType.custom}
            customWidth="800px"
            onDismiss={() => {
                togglePanel();
            }}
            closeButtonAriaLabel="Close"
            headerText="Camau Scorecard metric trends"
            headerClassName="flyoutHeader"
        >
            <div className="camauScorecardFlyoutFilter">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <div>
                        <Label className="filterLabel">Months to show:</Label>
                        <ComboBox
                            options={timespanOptions}
                            allowFreeform={true}
                            styles={{
                                root: { width: 80 },
                                optionsContainerWrapper: { width: 80 },
                            }}
                            text={timespan.toString()}
                            onChange={onTimespanChange}
                            className="filterDropdown"
                        />
                    </div>
                </Stack>
            </div>
            {chartData?.length !== 0 ? (
                <>
                    <ComposedChart
                        width={750}
                        height={570}
                        data={chartData}
                        className="camauTrendsChart"
                    >
                        <XAxis dataKey="Date" />
                        <YAxis
                            yAxisId="left"
                            unit="%"
                            allowDecimals={true}
                            domain={getDomainForYAxis(
                                chartData
                                    .map((elem) => [
                                        elem.CAMAUPct,
                                        elem.CAMAUPct_Target,
                                        elem.MAU_VTT_Pct,
                                        elem.CAMAU_VTT_Pct,
                                    ])
                                    .flat(),
                                false,
                            )}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={(tick) =>
                                `${(tick / 1000000).toLocaleString()}M`
                            }
                        ></YAxis>
                        <Tooltip formatter={customToolTipContent} />
                        <Legend
                            onClick={clickMetricFromLegend}
                            wrapperStyle={{
                                paddingTop: "20px",
                            }}
                            payload={legendProps?.filter(
                                (elem) => !elem.dataKey.includes("Target"),
                            )}
                        />
                        {Object.keys(chartFlyoutBarsDisplayNames).map(
                            (barField, index) => (
                                <>
                                    <Bar
                                        key={barField}
                                        dataKey={barField}
                                        hide={
                                            legendProps.find(
                                                (metric) =>
                                                    metric.dataKey === barField,
                                            ).inactive
                                        }
                                        name={
                                            chartFlyoutBarsDisplayNames[barField]
                                                .DisplayName
                                        }
                                        fill={getChartColor(index + 5)}
                                        yAxisId="right"
                                    />
                                    {showGoals && (
                                        <Line
                                            key={
                                                chartFlyoutBarsDisplayNames[barField]
                                                    .GoalField
                                            }
                                            type="monotone"
                                            hide={
                                                legendProps.find(
                                                    (metric) =>
                                                        metric.dataKey ===
                                                        chartFlyoutBarsDisplayNames[
                                                            barField
                                                        ].GoalField,
                                                ).inactive
                                            }
                                            name={
                                                chartFlyoutBarsDisplayNames[barField]
                                                    .GoalFieldDisplay
                                            }
                                            dataKey={
                                                chartFlyoutBarsDisplayNames[barField]
                                                    .GoalField
                                            }
                                            yAxisId="right"
                                            dot={false}
                                            stroke={getChartColor(index + 5)}
                                            strokeDasharray="2 2"
                                            strokeWidth={2}
                                            isAnimationActive={false}
                                        />
                                    )}
                                </>
                            ),
                        )}
                        {Object.keys(chartFlyoutLinesDisplayNames).map(
                            (lineField, index) => (
                                <>
                                    <Line
                                        key={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalField
                                        }
                                        type="monotone"
                                        hide={
                                            legendProps.find(
                                                (metric) =>
                                                    metric.dataKey ===
                                                    chartFlyoutLinesDisplayNames[
                                                        lineField
                                                    ].GoalField,
                                            ).inactive
                                        }
                                        name={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalFieldDisplay
                                        }
                                        dataKey={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalField
                                        }
                                        yAxisId="left"
                                        dot={false}
                                        stroke={getChartColor(index)}
                                        strokeDasharray="2 2"
                                        strokeWidth={2}
                                        isAnimationActive={false}
                                    />
                                </>
                            ),
                        )}
                    </ComposedChart>
                </>
            ) : (
                <div>
                    <EmptyTableContainer />
                </div>
            )}
        </Panel>
    );
};
