import { teamsAvgCohortMetrics } from "./teamsAvgCohortMetrics";
import { teamsCoreMetrics } from "./teamsCoreMetrics";
import { teamsTenantEnvironmentData } from "./teamsTenantEnvironmentData";
import { teamsMetaData } from "./teamsMetaData";
import { teamsSparkLineScores } from "./teamsSparkLineScores";
import { teamsTenantList } from "./teamsTenantList";
import { teamsTenantMetrics, teamsTenantMetricsChart } from "./teamsTenantMetrics";
import { teamsMeetingLatency } from "./teamsMeetingLatency";
import { teamsMeetingProductInsights } from "./teamsMeetingProductInsights";
import { teamsChatProductInsights } from "./teamsChatProductInsights";

const inProductTeamsDrilldownQueries: {} = {
    teamsAvgCohortMetrics,
    teamsCoreMetrics,
    teamsTenantEnvironmentData,
    teamsMetaData,
    teamsSparkLineScores,
    teamsTenantList,
    teamsTenantMetrics,
    teamsTenantMetricsChart,
    teamsMeetingLatency,
    teamsMeetingProductInsights,
    teamsChatProductInsights,
};

export default inProductTeamsDrilldownQueries;
