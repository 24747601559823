import React from "react";
import { ErrorMessageBar } from "components/CustomComponents/MessageBars/ErrorMessageBar";
import { ForbiddenMessageBar } from "components/CustomComponents/MessageBars/ForbiddenMessageBar";
import { USER_FORBIDDEN_BANNER, NO_DATA_MESSAGES } from "utils/Messages";

export const ErrorFallback = ({ message }) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const close = () => {
        setIsOpen(false);
    };
    switch (message) {
        case message.includes(USER_FORBIDDEN_BANNER) && message:
            return <ForbiddenMessageBar message={message} />;
        case NO_DATA_MESSAGES.NO_GOV_DATA:
        case NO_DATA_MESSAGES.NO_DATA:
            return (
                <ErrorMessageBar close={close} message={message} refresh={false} />
            );
        default:
            return (
                isOpen && (
                    <ErrorMessageBar
                        close={close}
                        message={message}
                        refresh={true}
                    />
                )
            );
    }
};
