import { addDays } from "date-fns";

export const getDisabledDatesWithinRange = (
    minDate: Date,
    maxDate: Date,
    enabledDates: Date[],
) => {
    const disablesDates: string[] = getDatesBetweenRange(minDate, maxDate).filter(
        (date) => !enabledDates.map((date) => date.toDateString()).includes(date),
    );
    return disablesDates.map((date) => new Date(date));
};

const getDatesBetweenRange = (startDate: Date, endDate: Date): Array<string> => {
    var dateArray: string[] = [];
    var currentDate = startDate;
    while (currentDate <= endDate) {
        dateArray.push(currentDate.toDateString());
        currentDate = addDays(currentDate, 1);
    }
    return dateArray;
};
