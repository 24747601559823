import React, { FormEvent, useEffect, useState } from "react";
import {
    Callout,
    DatePicker,
    DayOfWeek,
    DefaultButton,
    Dropdown,
    List,
    PrimaryButton,
    Stack,
    TextField,
    defaultDatePickerStrings,
} from "@fluentui/react";
import { emptySearchSuggestion } from "components/CustomComponents/SearchBarWithSuggestions/SearchBarWithSuggestions";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { SearchSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/types";
import { commonPageStyle, halfPageBlock } from "pages/common";
import { fetchSupportedTenantList } from "pages/SummarizedInsightsRequest/ApiHandler";
import {
    ScenarioOptions,
    SuggestionsMaxHeight,
    SuggestionsMaxWidth,
    SummarizedInsightsRequestInitialState,
    SummarizedInsightsRequestStackStyle,
    TenantSearchBarId,
} from "pages/SummarizedInsightsRequest/SummarizedInsightsRequestConstants";
import {
    AzureQueueMessageType,
    SummarizedInsightsRequestState,
    TenantListItem,
} from "pages/SummarizedInsightsRequest/types";
import AppInsights from "utils/AppInsights";
import AuthenticationUtil from "utils/AuthenticationUtil";
import { extractQueryParams } from "utils/Helpers";
import "pages/SummarizedInsightsRequest/style.css";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

const SummarizedInsightsRequest = () => {
    /* Manage the overall flow of the page */
    const [state, setState] = useState<SummarizedInsightsRequestState>(
        SummarizedInsightsRequestInitialState,
    );

    /* Help search tenant ids */
    const [tenantList, setTenantList] = useState<TenantListItem[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [suggestions, setSuggestions] =
        useState<SearchSuggestions>(emptySearchSuggestion);

    /* Request Parameters */
    const [tenantId, setTenantId] = useState<string>(() => {
        const queryParams = extractQueryParams();
        const id = queryParams["TenantId"]?.toString().trim();
        return id ?? "";
    });
    const [scenario, setScenario] = useState<string>("Other");
    const [engagementDate, setEngagementDate] = useState<string>("");
    const [emailTo, setEmailTo] = useState<string>(
        AuthenticationUtil.getLoggedInUser(),
    );

    useEffect(() => {
        document.title = "Summarized Insights Request";
        AppInsights.getInstance().TrackPage(document.title);

        fetchSupportedTenantList(setTenantList, setState);
    }, []);

    useSendLaunchEvent("Summarized Insights Request Tool");

    useEffect(() => {
        const filterResults = (text: string) => {
            if (text) {
                const searchResult: SearchSuggestions = {
                    renderResults(): JSX.Element {
                        return (
                            <List
                                items={tenantList.filter(
                                    (item) =>
                                        item?.TenantName?.toLowerCase().indexOf(
                                            text.toLowerCase(),
                                        ) >= 0 ||
                                        item?.TenantId?.toLowerCase().indexOf(
                                            text.toLowerCase(),
                                        ) >= 0,
                                )}
                                onRenderCell={onRenderCell}
                            />
                        );
                    },
                };
                return searchResult;
            }
        };

        setSuggestions(filterResults(tenantId));
    }, [tenantId, tenantList]);

    const onRenderCell = (item: TenantListItem): JSX.Element => {
        return (
            <div
                data-is-focusable={true}
                onClick={() => {
                    setTenantId(item.TenantId);
                }}
                className="tenantSuggestionList"
            >
                <div>
                    <div className="tenantSuggestionName">{item.TenantName}</div>
                    <div className="tenantSuggestionId">{item.TenantId}</div>
                </div>
            </div>
        );
    };

    const showSearchSuggestions = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        text?: string,
    ) => {
        if (text) {
            setTenantId(text);
            setShowSuggestions(true);
        } else if (!tenantId || tenantId.length == 1) {
            setTenantId("");
            setShowSuggestions(false);
        }
    };

    const renderSuggestions = () => {
        if (showSuggestions) {
            return (
                <Callout
                    gapSpace={0}
                    coverTarget={false}
                    alignTargetEdge={true}
                    target={`#${TenantSearchBarId}`}
                    directionalHint={5}
                    calloutMaxHeight={SuggestionsMaxHeight}
                    calloutMaxWidth={SuggestionsMaxWidth}
                    isBeakVisible={false}
                    onDismiss={() => setShowSuggestions(false)}
                >
                    {suggestions?.renderResults()}
                </Callout>
            );
        }
    };

    const submitRequest = async () => {
        let shouldSendMessage = true;
        const message: AzureQueueMessageType = {
            TenantId: tenantId,
            Scenario: scenario,
            EngagementDate: engagementDate,
            RequestorAlias: emailTo,
        };

        if (!message.TenantId) {
            shouldSendMessage = false;
            setState({
                ...state,
                isErrord: true,
                ErrorList: [
                    ...state.ErrorList,
                    ...["TenantId not set. Please enter a TenantId to continue"],
                ],
            });
        }

        if (!message.Scenario) {
            shouldSendMessage = false;
            setState({
                ...state,
                isErrord: true,
                ErrorList: [
                    ...state.ErrorList,
                    ...[
                        "Scenario not set. Please pick a scenario from the dropdown to continue",
                    ],
                ],
            });
        }

        if (!message.RequestorAlias) {
            shouldSendMessage = false;
            setState({
                ...state,
                isErrord: true,
                ErrorList: [
                    ...state.ErrorList,
                    ...["EmailTo not set. Please enter a valid email to continue"],
                ],
            });
        }

        if (shouldSendMessage) {
            const success = await AuthenticationUtil.PostMessage(
                JSON.stringify(message),
            );

            if (!success) {
                setState({
                    ...state,
                    isErrord: true,
                    ErrorList: [
                        "Failed to request the Summarized Insights. Please contact Summarized Insights(summarizedinsightsfc@microsoft.com) FC for help",
                    ],
                });
            } else {
                setState({
                    ...state,
                    isErrord: false,
                    isSuccess: true,
                    ErrorList: [],
                });
            }
        }
    };

    return state.isFetching ? (
        <LoadingModal text="Loading Summarized Insights Request Page..." />
    ) : (
        <>
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader">Summarized Insights Request Form</div>
                </Stack.Item>
                <Stack styles={halfPageBlock}>
                    <Stack.Item>
                        <div>
                            <p>
                                Fill this form out to request a Summarized Insights
                                deck. The requested report will be emailed to you
                                within 1 business day of your request.
                            </p>
                            <p>
                                Our team only produces content at the tenant-level.
                                For TPID requests, please email{" "}
                                <a href="mailto:summarizedinsights@microsoft.com">
                                    Summarized Insights FC
                                </a>{" "}
                                so we can work with you to understand your need and
                                find a solution.
                            </p>
                            <p>
                                The Summarized Insights will be sent to the email on
                                the account used to submit this form.
                            </p>
                        </div>
                    </Stack.Item>
                </Stack>
                {state.isErrord ? (
                    <Stack styles={halfPageBlock} className="errorContainer">
                        <Stack.Item>
                            <h1>⚠️ Error!</h1>
                            <ul>
                                {state.ErrorList.map((error) => {
                                    return <li>{error}</li>;
                                })}
                            </ul>
                        </Stack.Item>
                    </Stack>
                ) : (
                    <div>{""}</div>
                )}
                {state.isSuccess ? (
                    <Stack styles={halfPageBlock} className="successContainer">
                        <Stack.Item>
                            <h1>✅ Request Submitted!</h1>
                            <div>
                                Your request for Summarized Insights has been
                                submitted! Please look out for an email with your
                                requested report.
                            </div>
                        </Stack.Item>
                    </Stack>
                ) : (
                    <div>{""}</div>
                )}
                <Stack styles={halfPageBlock}>
                    <Stack.Item styles={SummarizedInsightsRequestStackStyle}>
                        <div>
                            <TextField
                                id={TenantSearchBarId}
                                label="What is the TenantID? Please only submit 1 request per form response"
                                placeholder="Enter the TenantId or start typing TenantName to search for the TenantId"
                                value={tenantId}
                                required
                                onChange={showSearchSuggestions}
                                onFocus={showSearchSuggestions}
                            />

                            {renderSuggestions()}
                        </div>
                    </Stack.Item>
                    <br />
                    <Stack.Item styles={SummarizedInsightsRequestStackStyle}>
                        <Dropdown
                            placeholder="Select an option"
                            label="What is your use case for Summarized Insights?"
                            defaultSelectedKey={"Other"}
                            required
                            options={ScenarioOptions}
                            onChange={(event, option) => {
                                setScenario(option.key.toString());
                            }}
                        />
                    </Stack.Item>
                    <br />
                    <Stack.Item styles={SummarizedInsightsRequestStackStyle}>
                        <DatePicker
                            label="[Optional] If you have an upcoming engagement, when is it?"
                            firstDayOfWeek={DayOfWeek.Monday}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            strings={defaultDatePickerStrings}
                            onSelectDate={(
                                date: Date | null | UnderlyingByteSource,
                            ) => {
                                if (date) {
                                    setEngagementDate(date.toLocaleString());
                                }
                            }}
                        />
                    </Stack.Item>
                    <br />
                    <Stack.Item styles={SummarizedInsightsRequestStackStyle}>
                        <TextField
                            label="Email To:"
                            value={emailTo}
                            required
                            deferredValidationTime={2000}
                            onChange={(
                                event: FormEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >,
                                text?: string,
                            ) => {
                                if (text) {
                                    setEmailTo(text);
                                }
                            }}
                        />
                    </Stack.Item>
                    <br />
                    <Stack.Item
                        styles={SummarizedInsightsRequestStackStyle}
                        className="buttonsContainer"
                    >
                        <PrimaryButton
                            text="✅Submit"
                            onClick={submitRequest}
                            allowDisabledFocus
                            className="siButton"
                        />
                        <>{"  "}</>
                        <DefaultButton
                            text="👍/👎 Feedback"
                            onClick={() => {
                                window.open(
                                    "https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR5yr9i8vp_RLpCSO5UMM3DlUM0pPRFQ0WTBBUzNTU1hRMEhQVFVXTkxPMC4u",
                                    "_blank",
                                    "noopener,noreferrer",
                                );
                            }}
                            allowDisabledFocus
                            className="siButton"
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};

export default SummarizedInsightsRequest;
