export const basicMetadata: string = `
// basicMetadata (populate list of cohorts the tenant belongs to -- similar to win32MetaData, but without scores)
let currentDate = GetCurrentDate(false);
StrategicTenants
| where Date == currentDate and \${levelColumnName} == "\${id}" 
| project Parent=Tpid,
                 \${levelColumnName},
                 OrgName=iif("\${level}" == "Tpid", OrgName, TenantName),
                 IsS500,
                 IsEPA,
                 IsS2500,
                 IsGoogleMove,
                 IsGov,
                 IsEO`;
