import React from "react";

export const Header = () => {
    return (
        <div className="headerBlock">
            <h2 className="headerTitle">Monthly Tenant Review Scorecard</h2>
            <p className="headerDescription">
                Top level insights for the Monthly Tenant Review
            </p>
        </div>
    );
};
