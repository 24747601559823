import {
    ahpFunnelDates,
    ahpFunnelStages,
    ahpFunnelFilterChoices,
    ahpFunnelDecomposition,
} from "queries/JourneyFunnels/NewAppHomePageFunnel/ahpFunnelQueries";
import {
    uhpFunnelDates,
    uhpFunnelStages,
    uhpFunnelFilterChoices,
} from "queries/JourneyFunnels/NewAppHomePageFunnel/uhpFunnelQueries";

export const ahpFunnelQueries = {
    ahpFunnelDates,
    ahpFunnelStages,
    ahpFunnelFilterChoices,
    ahpFunnelDecomposition,
};

export const uhpFunnelQueries = {
    uhpFunnelDates,
    uhpFunnelStages,
    uhpFunnelFilterChoices,
};
