import React from "react";
import { MessageBarButton } from "@fluentui/react";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { OutageBanner } from "components/CustomComponents/MessageBars/OutageBanner";
import { ShellHeaderMessageBarType } from "components/CustomComponents/types";
import {
    getCurrentSLAText,
    getCurrentWikiLink,
    getCurrentIncidentLink,
    getCurrentPageContact,
} from "components/InsightsComponents/Helpers";
import { shouldShowConfidentialityBanner } from "utils/InsightsFrameHelpers";
import { FeedbackLink } from "utils/Links";
import { CONFIDENTIAL_MESSAGE } from "utils/Messages";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";
import { ContactType } from "utils/types";

export const ShellHeader = () => {
    const pageContact: ContactType = getCurrentPageContact();

    const messageBarProps: ShellHeaderMessageBarType =
        shouldShowConfidentialityBanner(location)
            ? {
                  messageBarType: MessageBarType.warning,
                  className: "confidentialMessage",
                  message: CONFIDENTIAL_MESSAGE,
              }
            : {
                  messageBarType: MessageBarType.info,
                  className: "slaMessage",
                  message: "",
              };

    return (
        <>
            <MessageBar
                messageBarType={messageBarProps.messageBarType}
                actions={
                    <div>
                        <MessageBarButton
                            iconProps={{ iconName: "Help" }}
                            className="shellHeaderButton"
                            href={getCurrentWikiLink()}
                            target="_blank"
                            onClick={() =>
                                sendUserActionTelemetryEvent({
                                    userActionName: "WikiButtonClick",
                                    commandSurface: UserActionCommandSurface.Header,
                                })
                            }
                        >
                            Wiki
                        </MessageBarButton>
                        <MessageBarButton
                            iconProps={{ iconName: "Feedback" }}
                            className="shellHeaderButton"
                            href={FeedbackLink}
                            target="_blank"
                            onClick={() =>
                                sendUserActionTelemetryEvent({
                                    userActionName: "FeedbackButtonClick",
                                    commandSurface: UserActionCommandSurface.Header,
                                })
                            }
                        >
                            Feedback
                        </MessageBarButton>
                        <MessageBarButton
                            iconProps={{ iconName: "IncidentTriangle" }}
                            className="escalateButton"
                            href={getCurrentIncidentLink()}
                            target="_blank"
                            onClick={() =>
                                sendUserActionTelemetryEvent({
                                    userActionName: "IncidentButtonClick",
                                    commandSurface: UserActionCommandSurface.Header,
                                })
                            }
                        >
                            Incident
                        </MessageBarButton>
                    </div>
                }
                isMultiline={false}
                styles={{
                    text: {
                        fontSize: "14px",
                        marginTop: "7px",
                        marginBottom: "7px",
                    },
                }}
            >
                <div className={messageBarProps.className}>
                    {messageBarProps.message} The data in this system is updated{" "}
                    {getCurrentSLAText()} per our SLA. For questions, contact
                    <a
                        href={pageContact.email}
                        onClick={() =>
                            sendUserActionTelemetryEvent({
                                userActionName: "ContactEmailLinkClick",
                                commandSurface: UserActionCommandSurface.Header,
                            })
                        }
                    >
                        {pageContact.name}
                    </a>
                    .
                </div>
            </MessageBar>
            <OutageBanner />
        </>
    );
};
