import React from "react";

export enum ComparisonType {
    Pillar = "pillar",
    Veto = "veto rate",
    Error = "error rate",
}

export const getDeltaSpan = (type: ComparisonType, delta: number): JSX.Element => {
    return (
        <span className={`topInsightsDifference ${getDeltaColor(type, delta)}`}>
            {Math.abs(delta).toFixed(2)}% {getDeltaText(type, delta)}
        </span>
    );
};

const getDeltaText = (type: ComparisonType, delta: number): string => {
    if (delta > 0) {
        return type === ComparisonType.Pillar ? "better" : "worse";
    }

    return type === ComparisonType.Pillar ? "worse" : "better";
};

const getDeltaColor = (type: ComparisonType, delta: number): string => {
    if (delta > 0) {
        return type === ComparisonType.Pillar ? "positiveDelta" : "negativeDelta";
    }

    return type === ComparisonType.Pillar ? "negativeDelta" : "positiveDelta";
};
