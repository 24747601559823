import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { formatMetaData } from "pages/InProductDrillDown/ApiHandler";
import * as actionTypes from "redux/actionTypes";
import { ReliabilityActionType, ReliabilityState, ThunkResult } from "redux/types";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { addMonth, formatDisplayDate } from "utils/Helpers";
import { DRILLDOWN_ERROR_MESSAGES, USER_FORBIDDEN_BANNER } from "utils/Messages";

export function fetchReliabilitySuccess(data: any, filters: {}) {
    const reliabilityTable = data.reliability.Tables[0];
    const metadataJson = data?.metadata;
    const datesTable = data.dates.Tables[0];
    const errorSpikeTable = data.errorSpike.Tables[0];

    const x = {};
    const dataTemp: {} = {};
    const chartTemp: {} = {};
    const dateList = [];
    const errorSpike = [];

    const dates = datesTable.Rows.map((a) => a[0]);
    dates.forEach((a) => dateList.push({ key: a, text: formatDisplayDate(a) }));
    // To show the browser Icon for only latest month, store the latest date in filters

    if (datesTable.Rows.length > 0 && datesTable.Rows[0].length > 0) {
        filters["latestMonth"] = datesTable.Rows[0][0];
    } else {
        filters["latestMonth"] = filters["date"];
    }

    reliabilityTable.Rows.forEach((element) => {
        if (element[8] === true) {
            let _tenantsWithSameError: any[] = [];
            Object.keys(element[9]).forEach((i) => {
                const percentUsersWithError = element[9][i]["PercentUsersWithError"];
                const tenantid = element[9][i]["Id"].toString();

                // Filtering out tpids that has user error percent greater than S2500 average
                if (
                    percentUsersWithError >= element[5] &&
                    tenantid !== element[13]
                ) {
                    const orgName = element[9][i]["OrgName"];
                    _tenantsWithSameError.push({
                        OrgName: orgName,
                        Id: tenantid,
                        PercentUsersWithError: percentUsersWithError,
                    });
                }
            });

            _tenantsWithSameError = _tenantsWithSameError
                .slice(0)
                .sort((a, b) =>
                    a["PercentUsersWithError"] > b["PercentUsersWithError"] ? -1 : 1,
                );
            if (!(element[1] in dataTemp)) {
                dataTemp[element[1]] = [];
            }
            dataTemp[element[1]].push({
                ErrorName: element[2],
                ErrorType: element[3],
                TenantsWithSameError: _tenantsWithSameError,
                PercentUsersWithError: element[4],
                S2500AverageUser: element[5],
                PercentSessionsWithError: element[6],
                S2500AverageSession: element[7],
                Host: element[10],
                UiHost: element[11],
                UserErrorCount: element[13],
            });
        }

        const app = element[1];
        const date = element[0];

        if (!x[app]) {
            x[app] = {};
        }
        if (!x[app][date]) {
            x[app][date] = { Date: formatDisplayDate(date) };
        }
        x[app][date][`${element[2]}_onUsers`] = Number(element[4].toFixed(2));
        x[app][date][`S2500_${element[2]}_onUsers`] =
            element[5] != null ? Number(element[5].toFixed(2)) : null;
        x[app][date][`${element[2]}_onSessions`] = Number(element[6].toFixed(2));
        x[app][date][`S2500_${element[2]}_onSessions`] =
            element[7] != null ? Number(element[7].toFixed(2)) : null;
        x[app][date]["Host"] = element[10];
        x[app][date]["UiHost"] = element[11];
        // x[app][date]["UserErrorCount"] = element[13];
    });
    Object.keys(x).forEach((app) => {
        chartTemp[app] = [];
        let isFirstElement = true;
        let prevDate = null;

        Object.keys(x[app])
            .sort()
            .forEach((date) => {
                /* Adding empty dataset with date alone if there is no data for a particular month */
                if (isFirstElement) {
                    isFirstElement = false;
                } else {
                    let nextDate = addMonth(prevDate, 1);
                    while (date !== nextDate) {
                        nextDate = addMonth(prevDate, 1);
                        chartTemp[app].push({ Date: nextDate });
                        prevDate = nextDate;
                    }
                }
                chartTemp[app].push(x[app][date]);
                prevDate = date;
            });
    });

    errorSpikeTable.Rows.forEach((element) => {
        errorSpike.push({
            ErrorName: element[0],
            Application: element[1],
            PercentUsersWithError: element[2] * 100,
            PercentUsersWithError_Prev: element[3] * 100,
            PercentSessionsWithError: element[4] * 100,
            PercentSessionsWithError_Prev: element[5] * 100,
            IsNewError: element[6],
            IsSpike: element[7],
            IsHighTrend: element[8],
            IsHighUserImpact: element[9],
        });
    });

    const metadata = formatMetaData(metadataJson, Workload.WEB);

    const reliability = {
        items: dataTemp,
        chart: chartTemp,
        metadata,
        dates: dateList,
        errorSpike,
    };

    return {
        type: actionTypes.FETCH_RELIABILITY_SUCCESS,
        payload: reliability,
    };
}

export function fetchReliabilityFailure(error: string | null) {
    return {
        type: actionTypes.FETCH_RELIABILITY_FAILURE,
        error,
    };
}

export function fetchReliability(filters: {}): ThunkResult<void> {
    return (dispatch) => {
        dispatch({ type: actionTypes.FETCH_RELIABILITY_BEGIN });
        dispatch(showLoading());
        API.getTenantReliability(filters)
            .then((response) => {
                dispatch(fetchReliabilitySuccess(response, filters));
                dispatch(hideLoading());
            })
            .catch((error) => {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ReliabilityDataFetchOrProcessFailed",
                    ),
                    {
                        message:
                            "Failure while fetching or formatting reliability data",
                        filters,
                    },
                    error,
                );
                dispatch(fetchReliabilityFailure(error));
                dispatch(hideLoading());
            });
    };
}

export function fetchBrowserDataReliability(filters: {}): ThunkResult<void> {
    return (dispatch) => {
        dispatch({ type: actionTypes.FETCH_RELIABILITY_BEGIN });
        API.getErrorBrowserData(filters)
            .then((response) => {
                dispatch(fetchBrowserDataErrorSuccess(response));
            })
            .catch((error) => {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ReliabilityBrowserDataFetchOrProcessFailed",
                    ),
                    {
                        message:
                            "Failure while fetching or formatting reliability browser data",
                        filters,
                    },
                    error,
                );
                let message: string = null;
                const errorType = "Error Browser Distribution";
                if (error.isUnAuthorized) {
                    message = `${USER_FORBIDDEN_BANNER} ${errorType}. `;
                } else {
                    message = `${errorType} ${DRILLDOWN_ERROR_MESSAGES}`;
                }
                dispatch(fetchBrowserDataErrorFailure(message));
            });
    };
}

export function fetchBrowserDataErrorSuccess(data: any) {
    const browserData = data.errorBrowserData.Tables[0];
    const chartData = [];
    browserData.Rows.forEach((element) => {
        chartData.push({ name: element[0], SessionCount: element[1] });
    });
    return {
        type: actionTypes.FETCH_ERROR_BROWSER_CHART_SUCCESS,
        chartData,
    };
}

export function fetchBrowserDataErrorFailure(message: string) {
    return {
        type: actionTypes.FETCH_ERROR_BROWSER_CHART_FAILURE,
        userMessage: message,
    };
}

const initialState: ReliabilityState = {
    isFetching: false,
    error: null,
    filters: { Host: "All", UiHost: "All" },
};

export default function reducer(
    state = initialState,
    action: ReliabilityActionType,
): ReliabilityState {
    switch (action.type) {
        case actionTypes.FETCH_RELIABILITY_BEGIN:
            return {
                ...state,
                isFetching: true,
            };
        case actionTypes.FETCH_RELIABILITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                tenantReliability: action.payload,
                dateOptions: action.payload["dates"],
                filters: {
                    ...state.filters,
                },
            };
        case actionTypes.FETCH_ERROR_BROWSER_CHART_SUCCESS:
            return {
                ...state,
                isFetching: false,
                chartData: action.chartData,
                filters: {
                    ...state.filters,
                },
            };
        case actionTypes.FETCH_RELIABILITY_FAILURE:
            return {
                ...state,
                isFetching: false,
                chartData: [],
                error: action.error,
            };
        case actionTypes.FETCH_ERROR_BROWSER_CHART_FAILURE:
            return {
                ...state,
                isFetching: false,
                browserDataError: action["userMessage"],
            };
        default:
            return state;
    }
}
