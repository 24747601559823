import { defaultStyles } from "@visx/tooltip";

export const background = "white";
export const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
export const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
    padding: 5,
    border: "0.5px solid grey",
};

export const barStackWidthOffSet = 50;
export const barStackXPaddingOffset = 30;
export const barStackYPaddingOffset = 20;
export const barStackPercentagePaddingOffset = 5;
export const barStackOffSet = 20;
export const childBarStackOffSet = 35;

export const barStackPercentageOffset = 20;

export const barStackHorizontalCompartmentSize = 100;
export const barStackVerticalCompartmentSize = 20;
export const barStackNumberOfCompartments = 6;

export const textFill = "#FFFFFF";
export const fontSize = "12px";
export const textAnchor = "middle";
export const dominantBaseline = "central";
export const strokeColor = "#000000";
export const strokeWidth = "0.5";

export const mapText: Map<string, string> = new Map([["All", "All Referrers"]]);

export const minHeight = 0.9;
export const heightAdjustWhenMin = 5;
