import React from "react";
import { CommandBarButton } from "@fluentui/react";
import { Workload } from "config/PlatformConfig";
import { WikiLinks } from "utils/Links";

export const ScoreThreshold = ({ platform }) => {
    const open = () => {
        switch (platform) {
            case Workload.WIN32:
                window.open(WikiLinks.Win32Scores, "_blank");
                return;
            case Workload.MAC:
                window.open(WikiLinks.MacScores, "_blank");
                return;
            case Workload.WEB:
                window.open(WikiLinks.WebScores, "_blank");
                return;
            case Workload.COPILOT_COMMERCIAL:
                window.open(WikiLinks.CopilotScores, "_blank");
                return;
            default:
                window.open(WikiLinks.Scores, "_blank");
                return;
        }
    };

    return (
        <CommandBarButton
            iconProps={{ iconName: "Articles" }}
            text="Read more about scores"
            className="thresholdChart"
            onClick={open}
        />
    );
};
