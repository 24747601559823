export const unhealthyTenantMoverShaker: string = `
// unhealthyTenantMoverShaker
set query_results_cache_max_age = time(12h);
let recentCrossPlatformMonth = todatetime(GetCurrentDateCrossPlatform());
let recentStrategicTenantsMonth = GetCurrentDate(false);
let prevMonth = datetime_add("Month", -1, recentCrossPlatformMonth);
let prevPrevMonth = datetime_add("Month", -1, prevMonth);
let currUnhealthyTenants = materialize (StagingGetUnhealthyTenants(recentCrossPlatformMonth)
    | where Application == "Base");
let prevUnhealthyTenants = materialize (StagingGetUnhealthyTenants(prevMonth) | where Application == "Base");
let prevPrevUnhealthyTenants = materialize(StagingGetUnhealthyTenants(prevPrevMonth) | where Application == "Base");
let currMoverCount = toscalar(
    prevUnhealthyTenants
    | join kind=leftanti currUnhealthyTenants on OmsTenantId
    | summarize count());
let currShakerCount = toscalar(
    prevUnhealthyTenants
    | join kind=rightanti currUnhealthyTenants on OmsTenantId
    | summarize count());
let prevMoverCount = toscalar(
    prevPrevUnhealthyTenants
    | join kind=leftanti prevUnhealthyTenants on OmsTenantId
    | summarize count());
let prevShakerCount = toscalar(
    prevPrevUnhealthyTenants
    | join kind=rightanti prevUnhealthyTenants on OmsTenantId
    | summarize count());
print
    CurrentMoverCount = currMoverCount,
    CurrentShakerCount = currShakerCount,
    PreviousMoverCount = prevMoverCount,
    PreviousShakerCount = prevShakerCount
`;
