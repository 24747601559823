import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { Icon, Link, Stack } from "@fluentui/react";
import { ErrorMessageBar } from "components/CustomComponents/MessageBars/ErrorMessageBar";
import { isEmpty } from "lodash";
import TenantSearch from "pages/M365TenantInsights/TenantSearch";
import { commonPageStyle, commonPageBlock } from "pages/common";
import "pages/common.css";
import { M365Params } from "pages/M365TenantInsights/types";
import "pages/M365TenantInsights/style.css";
import AppInsights from "utils/AppInsights";
import { appIconStyle } from "utils/commonStyles";
import { appStyles } from "utils/Constants";
import { extractQueryParams } from "utils/Helpers";
import { registerPlatformIcons } from "utils/IconRegistration";
import { InsightsRoutes } from "utils/Links";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

interface m365TileDataType {
    Name: string;
    DisplayName: string;
    ExecSummaryLink: string;
    TenantDrillDownLink: string;
    ClassName: string;
    IsTenantDrillDownAvailable: boolean;
}

export const m365TilesData: m365TileDataType[] = [
    {
        Name: "Win32",
        DisplayName: "Office Win32",
        ExecSummaryLink: InsightsRoutes.Win32.path,
        TenantDrillDownLink: `${InsightsRoutes.Win32Tenant.path}?rankOn=Word`,
        ClassName: "windowsPlatformIcon",
        IsTenantDrillDownAvailable: true,
    },
    {
        Name: "Web",
        DisplayName: "Office Web",
        ExecSummaryLink: InsightsRoutes.Web.path,
        TenantDrillDownLink: `${InsightsRoutes.Tenant.path}?rankOn=Word`,
        ClassName: "webPlatformIcon",
        IsTenantDrillDownAvailable: true,
    },
    {
        Name: "Mac",
        DisplayName: "Office Mac",
        ExecSummaryLink: InsightsRoutes.Mac.path,
        TenantDrillDownLink: `${InsightsRoutes.MacTenant.path}?rankOn=Word`,
        ClassName: "",
        IsTenantDrillDownAvailable: true,
    },
    {
        Name: "CrossPlatform",
        DisplayName: "Office Cross Platform",
        ExecSummaryLink: InsightsRoutes.CrossPlatformOverview.path,
        TenantDrillDownLink: "",
        ClassName: "",
        IsTenantDrillDownAvailable: false,
    },
];
const renderExecSummaryTiles = () => {
    const execSummaryTiles = [];
    m365TilesData.forEach((platform) => {
        execSummaryTiles.push(
            <li
                key={platform.Name}
                className="execSummaryContainer"
                data-testid="execSummaryContainer"
            >
                <div className="execSummaryCard">
                    <Link
                        href={platform.ExecSummaryLink}
                        target="_blank"
                        rel="noreferrer"
                        className="execCardLink"
                    >
                        <div className="execSummaryCardContainer">
                            <div className="iconAndName">
                                <div className="execIconWrapper">
                                    <Icon
                                        iconName={
                                            appStyles[platform.Name]["iconName"]
                                        }
                                        className={`execSummaryIcon ${platform.ClassName} ${appIconStyle}`}
                                    />
                                </div>
                                <div className="execPlatformName">
                                    {platform.DisplayName}
                                </div>
                            </div>
                            <img
                                className="execSummaryCardImg"
                                src="/img/win32HealthTrend.png"
                                alt=""
                            />
                        </div>
                    </Link>
                </div>
            </li>,
        );
    });
    return execSummaryTiles;
};
const filtersFromQueryParams = extractQueryParams();

export const validateQueryParams = (params: {}): M365Params => {
    const updatedFilters = defaultM365Params;
    if (["TenantId", "Tpid"].includes(params["level"]))
        updatedFilters["level"] = params["level"];

    updatedFilters["id"] = params["id"] ?? "";

    return updatedFilters;
};

export const defaultM365Params = {
    level: "",
    id: "",
};

const M365TenantInsights = (props: M365Params) => {
    const pageName = "M365 Tenant Insights";
    AppInsights.getInstance().TrackPage(pageName);
    registerPlatformIcons();

    const [error, setError] = useState<string>(null);
    var showTenantLinks = false;
    var updatedParams = defaultM365Params;

    if (
        !isEmpty(filtersFromQueryParams) &&
        !isEqual(filtersFromQueryParams, props)
    ) {
        updatedParams = validateQueryParams(filtersFromQueryParams);
        showTenantLinks = true;
    }

    useEffect(() => {
        document.title = pageName;
    }, []);

    useSendLaunchEvent("M365 Tenant Insights");

    return (
        <>
            {error && <ErrorMessageBar message={error} refresh={false} />}
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="m365SearchHeader">M365 Tenant Insights</div>
                </Stack.Item>
                <Stack.Item>
                    <div className="m365PageDescription">
                        Enter the Tenant name to get the insights on their usage,
                        product quality, performance and feedback across the Windows,
                        Web, Mac for Office.
                    </div>
                </Stack.Item>
                <div className="solidBreak" />
                <TenantSearch
                    setError={setError}
                    showTenantLinks={showTenantLinks}
                    m365params={updatedParams}
                />
                <Stack styles={commonPageBlock}>
                    <div className="m365SearchHeader">
                        Executive Summary of S500 Tenants
                    </div>
                    <ul className="execSummaryTiles">{renderExecSummaryTiles()}</ul>
                </Stack>
            </Stack>
        </>
    );
};

export default M365TenantInsights;
