import React from "react";
import { CommandBarButton } from "@fluentui/react";
import {
    getCurrentIncidentLink,
    getCurrentWikiLink,
} from "components/InsightsComponents/Helpers";
import { FeedbackLink } from "utils/Links";

interface HeaderButton {
    href: string;
    iconName: string;
    text: string;
}

const HeaderButtons = () => {
    const commandBarButtons: HeaderButton[] = [
        {
            href: getCurrentWikiLink(),
            iconName: "BookAnswers",
            text: "Wiki",
        },
        {
            href: FeedbackLink,
            iconName: "Feedback",
            text: "Feedback",
        },
        {
            href: getCurrentIncidentLink(),
            iconName: "Warning",
            text: "Incident",
        },
    ];

    return (
        <>
            {commandBarButtons.map(({ href, iconName, text }: HeaderButton) => {
                return (
                    <CommandBarButton
                        key={`command-bar-${iconName}`}
                        href={href}
                        className="headerButton"
                        iconProps={{
                            iconName,
                        }}
                        title={text}
                    />
                );
            })}
        </>
    );
};

export default HeaderButtons;
