import React from "react";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { BarStack } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
    barTextFontSize,
    barTextXOffset,
    barTextYOffset,
    minBarHeight,
    stackedBarWidth,
    tooltipStyles,
} from "components/CustomComponents/StackedBarChart/constants";
import {
    StackedBarChartProps,
    TooltipData,
} from "components/CustomComponents/StackedBarChart/types";
import "components/CustomComponents/StackedBarChart/style.css";

export const StackedBarChart = ({
    chartFormattingData,
    data,
    keys,
    colorScale,
    xScale,
    yScale,
    getXAxis,
    onClickHandler,
    isBarInactive,
    isBarActive,
    getToolTipMessage,
}: StackedBarChartProps) => {
    let tooltipTimeout: number;

    const {
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    } = useTooltip<TooltipData>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
    });

    const getBarOpacity = (bar) => {
        return isBarInactive(bar) ? "0.75" : "100";
    };

    const handleMouseMoveForToolTip = (event, bar) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout);
        const eventSvgCoords = localPoint(event);
        const left = bar.x + bar.width / 2;
        showTooltip({
            tooltipData: bar,
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: left,
        });
    };

    return (
        <div className="stackedBarContainer">
            <svg
                ref={containerRef}
                width={chartFormattingData.width}
                height={chartFormattingData.height}
            >
                <BarStack
                    data={data}
                    keys={keys}
                    x={getXAxis}
                    xScale={xScale}
                    yScale={yScale}
                    color={colorScale}
                >
                    {(barStacks) =>
                        barStacks.map((barStack) =>
                            barStack.bars.map((bar) => {
                                return (
                                    <Group key={`barStack-group-${bar.key}`}>
                                        {bar.bar.data[bar.key] && (
                                            <Group
                                                key={`bar-group-${barStack.index}-${bar.index}`}
                                                left={0}
                                                top={0}
                                            >
                                                <rect
                                                    key={`bar-stack-${barStack.index}-${bar.index}`}
                                                    x={bar.x}
                                                    y={bar.y}
                                                    height={bar.height}
                                                    width={stackedBarWidth}
                                                    fill={bar.color}
                                                    opacity={getBarOpacity(bar)}
                                                    onClick={() => {
                                                        onClickHandler(bar);
                                                    }}
                                                    onMouseLeave={() =>
                                                        hideTooltip()
                                                    }
                                                    onMouseMove={(event) => {
                                                        handleMouseMoveForToolTip(
                                                            event,
                                                            bar,
                                                        );
                                                    }}
                                                />
                                                {isBarActive(bar) && (
                                                    <rect
                                                        key={`bar-stack-${barStack.index}-highlight`}
                                                        style={{
                                                            stroke: "rgb(0 0 0)",
                                                            strokeWidth: "2",
                                                        }}
                                                        x={bar.x + 1}
                                                        y={bar.y + 1}
                                                        height={bar.height - 1}
                                                        width={stackedBarWidth - 2}
                                                        fill="none"
                                                    />
                                                )}
                                                {bar.height > minBarHeight && (
                                                    <text
                                                        x={bar.x + barTextXOffset}
                                                        y={
                                                            bar.y +
                                                            bar.height / 2 +
                                                            barTextYOffset
                                                        }
                                                        fill="white"
                                                        fontSize={barTextFontSize}
                                                        fontFamily="Arial"
                                                        textAnchor="middle"
                                                        onClick={() => {
                                                            onClickHandler(bar);
                                                        }}
                                                        opacity={getBarOpacity(bar)}
                                                        onMouseLeave={() =>
                                                            hideTooltip()
                                                        }
                                                        onMouseMove={(event) => {
                                                            handleMouseMoveForToolTip(
                                                                event,
                                                                bar,
                                                            );
                                                        }}
                                                    >
                                                        {`${bar.bar.data[
                                                            bar.key
                                                        ]?.toFixed(2)}%`}
                                                    </text>
                                                )}
                                            </Group>
                                        )}
                                    </Group>
                                );
                            }),
                        )
                    }
                </BarStack>
            </svg>
            {tooltipOpen && tooltipData && (
                <TooltipInPortal
                    top={tooltipTop}
                    left={tooltipLeft}
                    style={tooltipStyles}
                >
                    {getToolTipMessage(tooltipData)}
                </TooltipInPortal>
            )}
        </div>
    );
};
