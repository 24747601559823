import React, { useState } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import PerformanceTooltip from "components/CustomComponents/PerformanceLineChart/PerformanceTooltip";
import { chartXAxisPaddingStyles } from "pages/common";
import { LegendEntry } from "pages/commonTypes";
import { ChartData } from "pages/Performance/types";
import "./styles.css";

// This chart is used to display the charts for each Scenario like boot-file and boot-launch.
const PerformanceLineChart = ({
    yUnit,
    values,
    labels,
    domain,
    roundTooltipValues,
}: {
    yUnit?: string;
    values: ChartData[];
    labels: LegendEntry[];
    domain?: number[];
    roundTooltipValues?: boolean;
}) => {
    const [hoveredSeries, setHoveredSeries] = useState("");

    const numberFormat = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
    });

    return (values?.length ?? 0) === 0 ? (
        <span id="noScenarioData">No data available</span>
    ) : (
        <ResponsiveContainer width={"100%"} aspect={1.5}>
            <LineChart data={values}>
                <XAxis
                    dataKey="Date"
                    padding={chartXAxisPaddingStyles}
                    allowDuplicatedCategory={false}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                    domain={domain ?? [0, "auto"]}
                    unit={yUnit}
                    tickFormatter={(num) => numberFormat.format(num)}
                />
                <Tooltip
                    allowEscapeViewBox={{ x: true, y: true }}
                    wrapperStyle={{ zIndex: 1 }}
                    content={
                        <PerformanceTooltip
                            hoveredSeries={hoveredSeries}
                            roundTooltipValues={roundTooltipValues}
                        />
                    }
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                {labels.map((label, index) => (
                    <Line
                        type="monotone"
                        strokeWidth={2}
                        strokeDasharray={label.stroked ? "5 5" : ""}
                        key={index}
                        name={label.Name ? label.Name : label.key}
                        dataKey={label.key}
                        stroke={label.color}
                        hide={label.hidden}
                        dot={label.showDots === true}
                        activeDot={{
                            r: 8,
                            onMouseOver: () => {
                                setHoveredSeries(label.Name);
                            },
                            onMouseLeave: () => {
                                setHoveredSeries("");
                            },
                        }}
                        unit={yUnit}
                        connectNulls={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default PerformanceLineChart;
