import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import {
    IconButton,
    ScrollablePane,
    ScrollbarVisibility,
    Stack,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import ErrorImpactChart from "components/ReliabilityComponents/ErrorImpactChart/ErrorImpactChart";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { commonPageBlock } from "pages/common";
import { appStyles, S2500Color, tenantTableConstants } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";
import { InsightsRoutes } from "utils/Links";
import { Filters } from "utils/WebConstants";

export const TenantList = (props) => {
    const {
        errorTenants,
        chartData,
        filters,
        getChartData,
        tableColumnsOverride,
        columnToSortByOverride,
    } = props;

    const [show, setShow] = useState(false);
    const [items, setItems] = useState(errorTenants);
    const isDateAll = filters["date"] === "All";

    useEffect(() => {
        setItems(errorTenants);
        setColumns(_generateErrorColumns());
        // ADO 7955411: useCallback refactor on _generateErrorColumns
        // Also need to move this after _generateErrorColumns declaration
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorTenants]);

    const toggleModal = () => {
        setShow(!show);
    };

    const chartLabels = {
        "% Users Impacted": "Users",
        "% Sessions Impacted": "Sessions",
    };

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columns, column, items);
        setItems(result.items);
        setColumns(result.columns);
    };

    const _generateErrorColumns = () => {
        const id = filters["level"];
        const idWidth =
            filters["level"] === "Tpid"
                ? tenantTableConstants.COLUMN_WIDTH
                : tenantTableConstants.FIXED_ROW_HEADER_COLUMN_WIDTH;
        const errorColumns = tableColumnsOverride ?? [
            ["ReliabilityDrilldownLink", "", 20, null],
            ["ChartModalLink", "", 20, null],
            [
                "Name",
                "Name",
                tenantTableConstants.FIXED_ROW_HEADER_COLUMN_WIDTH,
                null,
            ],
            ["id", id, idWidth, null],
            ["Industry", "Industry", tenantTableConstants.COLUMN_WIDTH, null],
            ["Error Type", "Error Type", tenantTableConstants.COLUMN_WIDTH, null],
            [
                "# Users with Error",
                "# Users with Error",
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
            [
                "% Users With Error",
                "% Users With Error",
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
            [
                `${filters["cohort"]} Avg% Users With Error`,
                `${filters["cohort"]} Avg% Users With Error`,
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
            [
                "# Sessions With Error",
                "# Sessions With Error",
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
            [
                "% Sessions With Error",
                "% Sessions With Error",
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
            [
                `${filters["cohort"]} Avg% Sessions With Error`,
                `${filters["cohort"]} Avg% Sessions With Error`,
                tenantTableConstants.COLUMN_WIDTH,
                "alignRightHeader",
            ],
        ];

        const cols: Array<IColumn> = DetailsListHelper.customBuildColumns(
            errorColumns,
            true,
            null,
            columnToSortByOverride ?? "% Users With Error",
            true,
        );

        if (isDateAll) {
            cols.push({
                key: "Date",
                name: "Date",
                fieldName: "Date",
                minWidth: tenantTableConstants.COLUMN_WIDTH,
                maxWidth: tenantTableConstants.COLUMN_WIDTH,
                isResizable: true,
                isMultiline: true,
                ariaLabel: "Date",
            });
        } else {
            cols.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
        }
        return cols;
    };

    const [columns, setColumns] = useState(_generateErrorColumns());

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        const id = item["id"];

        switch (column.key) {
            case "ReliabilityDrilldownLink":
                return (
                    <div>
                        <Link
                            to={`${InsightsRoutes.Reliability.path}?id=${id}&level=${filters["level"]}&date=${filters["date"]}`}
                        >
                            <IconButton
                                iconProps={{ iconName: "DrillThrough" }}
                                title="Reliability Drilldown"
                                ariaLabel="Reliability Drilldown"
                                className="drillDownIcon"
                            />
                        </Link>
                    </div>
                );
            case "ChartModalLink":
                return (
                    <div
                        className="metricExpand"
                        onClick={() => {
                            filters["id"] = id;
                            getChartData(filters);
                            setShow(!show);
                        }}
                    >
                        <IconButton
                            iconProps={{ iconName: "Chart" }}
                            title="Chart"
                            ariaLabel="Chart"
                            className="drillDownIcon"
                        />
                    </div>
                );
            case "Name":
                const link = `/tenant?rankOn=${Filters.DefaultRankOn}&level=${filters["level"]}&id=${id}`;
                return (
                    <DetailsListHelper.LinkedTextValue
                        value={fieldContent}
                        link={link}
                    />
                );
            case "# Users with Error":
            case "# Sessions With Error":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={0}
                    />
                );
            case "% Users With Error":
            case `${filters["cohort"]} Avg% Users With Error`:
            case "% Sessions With Error":
            case `${filters["cohort"]} Avg% Sessions With Error`:
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    if (errorTenants.length === 0)
        return (
            <Stack styles={commonPageBlock}>
                <EmptyTableContainer />
            </Stack>
        );

    return (
        <div data-is-scrollable className="pageSection">
            <Modal
                handleClose={toggleModal}
                show={show}
                labels={chartLabels}
                chartData={chartData}
                app={filters["app"]}
                error={filters["errorname"]}
                cohort={filters["cohort"]}
            />
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    items={items}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    compact
                    isHeaderVisible={!show}
                    onColumnHeaderClick={_onColumnClick}
                    onRenderItemColumn={_renderItemColumn}
                    onRenderDetailsHeader={DetailsListHelper.renderDetailsHeader}
                    className="tenantTable"
                    styles={DetailsListHelper.tenantTableStyle}
                />
            </ScrollablePane>
        </div>
    );
};

export const Modal = (props) => {
    const { handleClose, show, chartData, labels, app, error, cohort } = props;

    if (!show || !chartData || chartData === undefined) {
        return null;
    }

    return (
        <div className="backdropStyle">
            <div className="modalStyle" style={{ paddingBottom: 20 }}>
                <button className="close" type="button" onClick={handleClose}>
                    {" "}
                </button>
                {Object.keys(labels).map((key) => (
                    <div>
                        <h3 className="commonPageBlockTitle">{key}</h3>
                        <ErrorImpactChart
                            data={chartData}
                            error={error}
                            app={app}
                            dataLevel={labels[key]}
                            width={500}
                            cohort={cohort}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export const TenantImpactedByError = (props) => {
    const { tenantCount, filters, hostCount, uiHostCount } = props;
    const isTopParent = filters["level"] === "Tpid";
    const width = (window.innerWidth - 220) / 3;
    const height = 300;
    const chartLabels = [
        {
            color: appStyles[filters["app"]]["color"],
            key: "All Cohort TenantCount",
        },
        {
            color: S2500Color,
            key: `${filters["cohort"]} TenantCount`,
        },
    ];

    let tenantsImpacted = 0;
    let cohortTenantsImpacted = 0;

    // Search from all dates to get the tenant count for current date, works for Date == All
    for (let i = 0; i < tenantCount.length; i++) {
        const element = tenantCount[i];

        const selectedDate =
            filters["date"] === "All" ? "All" : formatDisplayDate(filters["date"]);
        if (element["Date"] === selectedDate) {
            tenantsImpacted = element["All Cohort TenantCount"];
            cohortTenantsImpacted = element[`${filters["cohort"]} TenantCount`];
            break;
        }
    }

    return (
        <div>
            <div>
                <ul className="tenantsImpactedTile">
                    <div className="coreHealthTile">
                        {isTopParent
                            ? `# TopParents Impacted: ${tenantsImpacted}`
                            : `# Tenants Impacted: ${tenantsImpacted}`}
                    </div>
                    <div className="coreHealthTile">
                        {isTopParent
                            ? `# ${filters["cohort"]} TopParents Impacted: ${cohortTenantsImpacted}`
                            : `# ${filters["cohort"]} Tenants Impacted: ${cohortTenantsImpacted}`}
                    </div>
                </ul>
            </div>
            <Stack horizontal>
                <Stack.Item grow="initial">
                    <div className="commonPageBlockSubTitle">Host Distribution </div>
                    <HostUiHostBarChart
                        width={width}
                        height={height}
                        data={hostCount}
                        fill="#8884d8"
                    />
                </Stack.Item>
                <Stack.Item grow="initial">
                    <div className="commonPageBlockSubTitle">
                        UiHost Distribution{" "}
                    </div>
                    <HostUiHostBarChart
                        width={width}
                        height={height}
                        data={uiHostCount}
                        fill="#82ca9d"
                    />
                </Stack.Item>
                {tenantCount && tenantCount.length > 2 ? (
                    <Stack.Item grow="initial">
                        <div className="commonPageBlockSubTitle">
                            {isTopParent
                                ? "# Top Parent impacted over time"
                                : "# Tenants impacted over time"}
                        </div>
                        <TogglingLineChart
                            kind={togglingLineChartKind.values}
                            xDataKey="Date"
                            values={tenantCount.slice(0, tenantCount.length - 1)} // last entry is Date = All and we dont need that in chart
                            labels={chartLabels}
                            width={width}
                            height={height}
                        />
                    </Stack.Item>
                ) : (
                    <Stack styles={commonPageBlock} grow={1}>
                        <EmptyTableContainer />
                    </Stack>
                )}
            </Stack>
        </div>
    );
};

export const HostUiHostBarChart = ({ width, height, data, fill }) => {
    return (
        <>
            <BarChart
                width={width}
                height={height}
                data={data}
                maxBarSize={80}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip cursor={false} />
                <Legend />
                <Bar dataKey="# Tenants" fill={fill} />
            </BarChart>
        </>
    );
};
