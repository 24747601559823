import React, { useEffect, useState } from "react";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    IDetailsColumnFieldProps,
    IRenderFunction,
    SelectionMode,
    Stack,
    StackItem,
} from "@fluentui/react";
import {
    ScrollablePane,
    ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { AIScorecardColumns, config } from "components/AIScorecardComponents/config";
import { ExportLink } from "components/AIScorecardComponents/ExportLink";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatSummaryScorecardDataForExport,
} from "components/AIScorecardComponents/ApiHandler";
import { AIScorecardTablePropsType } from "components/AIScorecardComponents/types";
import { logException } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { NO_DATA_MESSAGES } from "utils/Messages";

const getHeaderGroups = () => {
    const groups = {};
    AIScorecardColumns.forEach((column) => {
        groups[column.name] = column.headerGroups;
    });
    return groups;
};

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    return defaultRender({ ...props });
};

const copilotSummaryScorecardTableColumns = () => {
    const cols = [
        {
            name: "MetricName",
            displayName: "Metric",
            headerGroups: [],
            minWidth: 200,
            className: "",
        },
    ];
    config.appsList.map((app) => {
        cols.push({
            name: app,
            displayName: appStyles[app] ? "" : app,
            headerGroups: [],
            minWidth: 120,
            className: "alignRightHeader",
        });
    });

    const columns: IColumn[] = [];

    cols.forEach((column) => {
        columns.push({
            key: column.name,
            name: column.displayName,
            iconName:
                column.name != "MetricName" && appStyles[column.name]
                    ? appStyles[column.name]["iconName"]
                    : "",
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 120,
            maxWidth: column.minWidth ?? 120,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${column.className}`,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};

const CopilotSummaryScorecardTable = ({
    items,
    loading,
    filters,
}: AIScorecardTablePropsType) => {
    const [dataForExport, setDataForExport] = useState([]);

    useEffect(() => {
        const getDataForExport = async () => {
            try {
                const [data] = await Promise.all([
                    fetchCopilotData(
                        {
                            date: filters.date,
                            audienceGroup: filters.audienceGroup,
                            office365Category: filters.office365Category,
                            platform: filters.platform,
                            cadence: filters.cadence,
                            cohort: filters.cohort,
                        },
                        "copilotSummaryScorecardForExport",
                    ),
                ]);

                let formattedData = formatSummaryScorecardDataForExport(data);
                setDataForExport(formattedData);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotSummaryScorecardExportFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot Summary Scorecard Export data",
                    },
                    e,
                );
                setDataForExport([]);
            }
        };
        getDataForExport();
    }, [
        filters.date,
        filters.audienceGroup,
        filters.office365Category,
        filters.platform,
        filters.cadence,
        filters.cohort,
    ]);

    const renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;
        const postfix = item["Postfix"];

        if (colKey !== "MetricName" && colKey !== "Dummy") {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.NumericalValue
                        value={fieldContent ?? "-"}
                        postfix={postfix}
                        decimalPrecision={
                            item["MetricName"] === "Tries per User" ? 1 : 0
                        }
                    />
                </div>
            );
        }
        if (colKey == "MetricName") {
            return (
                <div className="scorecardCell">
                    <DetailsListHelper.TextValue value={fieldContent} />
                </div>
            );
        }
    };

    return (
        <>
            <Stack>
                <StackItem align="end">
                    <ExportLink
                        filename={
                            "CopilotScorecard-" +
                            filters.cadence +
                            "-" +
                            filters.date +
                            ".csv"
                        }
                        data={dataForExport}
                    />
                </StackItem>
                <StackItem>
                    <div
                        data-is-scrollable
                        className="summaryScorecardTable"
                        id="scorecardTable"
                    >
                        <ScrollablePane
                            scrollbarVisibility={ScrollbarVisibility.auto}
                        >
                            <ShimmeredDetailsListWrapper
                                items={items}
                                message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                                columns={copilotSummaryScorecardTableColumns()}
                                selectionMode={SelectionMode.none}
                                onRenderDetailsHeader={(
                                    headerProps,
                                    defaultrender,
                                ) => {
                                    return DetailsListHelper.renderGroupedDetailsHeader(
                                        headerProps,
                                        getHeaderGroups(),
                                        true,
                                        defaultrender,
                                    );
                                }}
                                enableShimmer={loading}
                                onRenderItemColumn={renderItemColumn}
                                onRenderField={onRenderField}
                                styles={DetailsListHelper.tenantTableStyle}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                tableName="CopilotSummaryScorecardTable"
                                getAdditionalTelemetryFields={() => {
                                    return {
                                        ActiveAppTab:
                                            getAIScorecardAppTabSelection(),
                                        ActiveScorecardTab:
                                            getAIScorecardTabSelection(),
                                    };
                                }}
                            />
                        </ScrollablePane>
                    </div>
                </StackItem>
            </Stack>
        </>
    );
};

export default CopilotSummaryScorecardTable;
