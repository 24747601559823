import React from "react";
import { Icon } from "@fluentui/react";
import { IPivotItemProps } from "@fluentui/react/lib/Pivot";

export function addBetaTag(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null {
    if (!link || !defaultRenderer) {
        return null;
    }

    return (
        <span className="commonPivotWithTag">
            <Icon iconName={"img/beta.svg"} className="betaTag" />
            {defaultRenderer({ ...link, itemIcon: undefined })}
        </span>
    );
}
