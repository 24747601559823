import { UserActionEventFields, UtelFieldTypes } from "@oga-plg/plg-telemetry/dist";
import { config, getTabConfig } from "components/AIScorecardComponents/config";
import { extractQueryParams } from "utils/Helpers";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";

export const getAIScorecardAppTabSelection = () => {
    const queryParams = extractQueryParams();
    const appFromQueryParams = queryParams["application"];
    return appFromQueryParams ?? config.defaults.app;
};

export const getAIScorecardTabSelection = (app = null) => {
    const queryParams = extractQueryParams();
    const tabFromQueryParams = queryParams["tab"];
    const appFromQueryParams = queryParams["application"];
    const appTabConfig = getTabConfig(app ?? appFromQueryParams);
    const tab = appTabConfig.includes(tabFromQueryParams)
        ? tabFromQueryParams
        : appTabConfig[0];
    return tab;
};

export const sendAIScorecardUserActionTelemetryEvent = (
    userActionEventFields: UserActionEventFields,
    additionalFields?: UtelFieldTypes,
) => {
    sendUserActionTelemetryEvent(userActionEventFields, {
        ActiveAppTab: getAIScorecardAppTabSelection(),
        ActiveScorecardTab: getAIScorecardTabSelection(),
        ...additionalFields,
    });
};
