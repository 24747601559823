export const macCrossAppCompositeScore: string = `
//MacCrossAppCompositeScore
let level = "\${level}";
let app = "\${application}";
let cohort = "\${cohort}";
let currMonthdate = "\${date}";
let Mau = \${mauCountThreshold};
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_MacCrossAppView
    | where SubWorkload == app 
    | where MAU > Mau and Color > 1
    | where ProcessDate == currMonthdate or ProcessDate == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | join kind=inner
    (StrategicTenants
        | where Date == toscalar(StrategicTenants|summarize max(Date))
        | where iff(cohort == "Other", IsOther, Cohorts has cohort)
        | distinct \${levelColumnName} , iif(level == "Tpid", OrgName, TenantName)
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
    | extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,Engaged_Users_Pct = Usage_SignalGroup.Engaged_Users,
      Engaged_Users_Color = Usage_SignalGroup.Engaged_Users_Color, App_Currency_Color = Currency_SignalGroup.App_Currency_Color
    | project \${levelColumnName}, Score, Color, MoMChange, MAU, Composite_Score = Score, Performance_Colors, Feedback_Colors
    , Reliability_Colors, OS_Currency, OS_Currency_Color, PastStatus, Application = SubWorkload,Engaged_Users_Pct,App_Currency_Color, Date = ProcessDate,Engaged_Users_Color
    , Name);
Overview
| where Date == currMonthdate
| join kind=leftouter (
    Overview
    | where Date == prev and Application == app
) on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, Application, OrgName = Name,
    Composite_Score = round(Score, 2),
    Composite_MoMChange = round(Score-Score1, 2),
    MAU_Score = MAU, EngagedUsers_Score = iff(isnotnull(Engaged_Users_Pct), round((100*todouble(Engaged_Users_Pct))/todouble(MAU),2), 0.0),
    EngagedUsers_Score_Color = GetColor(Engaged_Users_Color),
    Word_Performance_AppColor = GetColor(Performance_Colors.Word),
    Excel_Performance_AppColor = GetColor(Performance_Colors.Excel),
    PowerPoint_Performance_AppColor = GetColor(Performance_Colors.PowerPoint),
    Outlook_Performance_AppColor = GetColor(Performance_Colors.Outlook),
    OneNote_Performance_AppColor = GetColor(Performance_Colors.OneNote),
    Word_Reliability_AppColor = GetColor(Reliability_Colors.Word),
    Excel_Reliability_AppColor = GetColor(Reliability_Colors.Excel),
    PowerPoint_Reliability_AppColor = GetColor(Reliability_Colors.PowerPoint),
    Outlook_Reliability_AppColor = GetColor(Reliability_Colors.Outlook),
    OneNote_Reliability_AppColor = GetColor(Reliability_Colors.OneNote),
    Word_Feedback_AppColor = GetColor(Feedback_Colors.Word),
    Excel_Feedback_AppColor = GetColor(Feedback_Colors.Excel),
    PowerPoint_Feedback_AppColor = GetColor(Feedback_Colors.PowerPoint),
    Outlook_Feedback_AppColor = GetColor(Feedback_Colors.Outlook),
    OneNote_Feedback_AppColor = GetColor(Feedback_Colors.OneNote),
    Word_AppCurrency_AppColor = GetColor(App_Currency_Color.Word),
    Excel_AppCurrency_AppColor = GetColor(App_Currency_Color.Excel),
    PowerPoint_AppCurrency_AppColor = GetColor(App_Currency_Color.PowerPoint),
    Outlook_AppCurrency_AppColor = GetColor(App_Currency_Color.Outlook),
    OneNote_AppCurrency_AppColor = GetColor(App_Currency_Color.OneNote),
    OS_Currency_Score = OS_Currency, OS_Currency_Score_Color = GetColor(OS_Currency_Color),
    Status = GetColor(Color)
| sort by Composite_Score asc, MAU_Score desc
`;
