export const webconsumerMetaData: string = `
// Web consumer Metadata
let app = "WXP";
let selectedDate = "\${date}";
let cohortType = "\${cohortType}";
let cohortName = "\${cohortName}";
let pivot = "All";
let host = "OneDriveWOPI";
ConsumerOverviewScore
| where CohortType == cohortType and CohortName  == cohortName and Application == app and Date == selectedDate and PivotName == pivot and Host == host
| project CohortType, CohortName, Status = Score_Color`;
