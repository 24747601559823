import React, { useState } from "react";
import { PivotItem, Stack } from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { MonthlyHealthyTenants, HealthyTenantTrendProps } from "pages/MTR/types";
import { HealthyTenantTrendPivots } from "utils/Constants";
import { convertUtcDate } from "utils/Helpers";
import { HealthyTenantTrendChart } from "./HealthyTenantTrendChart";
import { HealthyTenantTrendPivot } from "./HealthyTenantTrendPivot";
import { HealthyTenantTrendTable } from "./HealthyTenantTrendTable";

export const HealthyTenantTrend = ({
    payload,
    loading,
}: HealthyTenantTrendProps) => {
    const [pivot, setPivot] = useState<string>(
        HealthyTenantTrendPivots.Chart.itemKey,
    );
    const data: MonthlyHealthyTenants[] = [];

    if (!loading && payload.rows.length > 0) {
        payload.rows.forEach((element) => {
            data.push({
                Date: convertUtcDate(String(element[0])),
                UnhealthyTenants: Number(element[1]),
                TotalTenants: Number(element[2]),
                PercentUnhealthy: Number(element[3]).toFixed(2),
                PercentHealthy: Number(100 - element[3]).toFixed(2),
            });
        });
    }

    const onPivotClick = (item: PivotItem) => {
        const pivot = item.props.itemKey;
        setPivot(pivot);
    };

    const renderHealthyTenantTrend = (pivot) => {
        if (pivot === HealthyTenantTrendPivots.Chart.itemKey) {
            return <HealthyTenantTrendChart data={data} />;
        }
        if (pivot === HealthyTenantTrendPivots.Table.itemKey) {
            return <HealthyTenantTrendTable data={data} />;
        }
    };

    return (
        <div className="commonPageBlock">
            <Stack horizontal horizontalAlign="space-between">
                <p className="commonPageBlockTitle">Healthy Tenant Trend</p>
                <HealthyTenantTrendPivot pivot={pivot} onPivotClick={onPivotClick} />
            </Stack>
            {loading ? <ShimmeredCardBody /> : renderHealthyTenantTrend(pivot)}
        </div>
    );
};
