import { IDropdownOption } from "@fluentui/react";

export interface AIScorecardFiltersType {
    application: string;
    date: string;
    platform: string;
    office365Category: string;
    audienceGroup: string;
    cadence: string;
    cohort: string;
    tenantId: string;
    feature?: string;
}

export interface AIScorecardStateType {
    scorecardData: AIScorecardTableType[];
    loading: boolean;
    dateOptions: IDropdownOption[];
}

export interface MBRScorecardType {
    featureData: AIScorecardTableType[];
    overviewData: AIScorecardTableType[];
    loading: boolean;
}

export interface AIScorecardFiltersPropsType {
    dates: string[];
    filters: AIScorecardFiltersType;
    onFilterChange: (item: string, value: string) => void;
}

export interface AIScorecardTableType {
    Date: string;
    Feature: string;
    Capability: string;
    RowLabel?: string;
    Enabled?: number;
    Seen?: number;
    SeenRate?: number;
    Tried?: number;
    TriedRate?: number;
    TriedDropoff?: number;
    Kept?: number;
    KeptRate?: number;
    KeptDropoff?: number;
}

export interface AIScorecardTablePropsType {
    items: any[];
    loading: boolean;
    filters: AIScorecardFiltersType;
    columnConfig?: any[];
    className?: string;
}

export interface AIScorecardFlyoutPropsType {
    isPanelOpen: boolean;
    togglePanel: () => void;
    feature: string;
    capability: string;
    filters: AIScorecardFiltersType;
}

export interface CopilotWeeklyChartType {
    Date: string;
    Enabled: number;
    Seen: number;
    Tried: number;
    Kept: number;
}

export interface LegendPayloadType {
    inactive: boolean;
    dataKey: string;
    type: string;
    color: string;
    value: string;
}

export interface TopTenantInsightsType {
    newTenants: NewTenantsType[];
    topTenantsUsage: TopTenantUsageType[];
    loading: boolean;
}

export interface NewTenantsType {
    tenantName: string;
    tenantId: string;
    availableUnits: number;
}

export interface TopTenantUsageType {
    tenantName: string;
    availableUnits: number;
    enabledUsers: number;
    mau: number;
    tenantId: string;
}

export interface LicenseType {
    availableUnits: number;
    enabledUsers: number;
    skuName: string;
}

export enum CopilotM365ColKey {
    AppName = "AppName",
    ThumbsUpResponseRate = "ThumbsUpResponseRate",
    TriedCountsPerUser = "TriedCountsPerUser",
    QuickRatio = "QuickRatio",
    ASHA = "ASHA",
    MTEPassFail = "VerbsPassedMTE",
    TVPassFail = "TVPassFail",
    CopilotNps = "CopilotNps",
    CopilotSessionsPercent = "CopilotSessionsPercent",
    COGSCost = "COGSCost",
    COGSCostPerUser = "COGSCostPerUser",
}

export interface MBRTableType {
    name: string;
    tooltip?: string;
    displayName: string;
    headerGroups: string[];
    className?: string;
    minWidth?: number;
    style?: React.CSSProperties;
    postFix?: string;
    precision?: number;
}

export interface MTEandTVTableType {
    FeatureVerb: string;
    AppName: string;
    NegativeFeelingsRate?: number;
    NegativeTimeSavedRate?: number;
    ThumbsDownPer100k?: number;
    ASHA?: number;
    CopilotNps?: number;
    PositiveProductMarketFitRate?: number;
    ExceededExpectationsRate?: number;
    Week1RetentionRate?: number;
    Enabled?: number;
    NegativeFeelingResponseCount?: number;
    ExceedExpectationsResponseCount?: number;
    PMFResponseCount?: number;
    TimeSavedResponseCount?: number;
}
