import React from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    Text,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from "recharts";
import { mtrCustomTooltipFunction } from "pages/MTR/MTRUtils";
import CustomToolTip from "components/CustomComponents/CustomToolTip";
import {
    chartXAxisPaddingStyles,
    drilldownChartHeight,
    chartMargin,
} from "pages/common";
import { HealthDistributionTrendChartData } from "pages/MTR/types";
import { STATUS } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";

export const HealthDistributionTrendChart = ({
    data,
}: {
    data: HealthDistributionTrendChartData[];
}) => {
    return (
        <ResponsiveContainer height={drilldownChartHeight}>
            <LineChart data={data} margin={chartMargin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="Date"
                    tickFormatter={data.length > 0 ? formatDisplayDate : null}
                    padding={chartXAxisPaddingStyles}
                />
                <YAxis label={<CustomizedLabel />} />
                <Tooltip
                    content={
                        <CustomToolTip
                            props={data}
                            customToolTipContentFunction={mtrCustomTooltipFunction}
                        />
                    }
                />
                {Object.keys(STATUS).map((key) => (
                    <Line
                        name={`${STATUS[key]["name"]} Tenants`}
                        type="monotone"
                        dataKey={key}
                        stroke={STATUS[key]["color"]}
                        key={key}
                        // When there is a single data point, linechart does not show the active dot
                        // Workaround: https://github.com/recharts/recharts/issues/149
                        isAnimationActive={data.length !== 1}
                        dot={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

const CustomizedLabel = () => {
    return (
        <Text dx={-175} dy={15} transform="rotate(-90)">
            # Tenants
        </Text>
    );
};
