import React from "react";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { CommonFunnelHeaderProps } from "components/CommonFunnelComponents/CommonFunnelHeader/types";
import { getAggregatedSliceValueAndPercentageForStage } from "components/CommonFunnelComponents/CommonFunnelStages/commonFunnelStagesHelper";
import "components/CommonFunnelComponents/CommonFunnelHeader/style.css";

export const CommonFunnelHeader = ({ funnelStageData }: CommonFunnelHeaderProps) => {
    const [stageValue, stagePercentage] =
        getAggregatedSliceValueAndPercentageForStage(funnelStageData);
    return (
        <div className="commonFunnelHeaderContainer">
            <div
                className="commonFunnelHeaderStageName"
                title={funnelStageData.stage.friendlyName}
            >
                {funnelStageData.stage.friendlyName}
            </div>
            <div className="commonFunnelHeaderValue" title={`${stageValue}`}>
                {stageValue > 0 ? getCompactValue(stageValue) : ""}
            </div>
            <div className="commonFunnelHeaderPercent">
                {stageValue > 0 ? `[${stagePercentage}%]` : ""}
            </div>
        </div>
    );
};
