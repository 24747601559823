import React, { useState, useEffect } from "react";
import { IStackItemStyles, Shimmer, Stack } from "@fluentui/react";
import { parseISO, subMonths } from "date-fns";
import format from "date-fns/format";
import * as API from "api";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { KustoResponseType, LegendEntry } from "pages/commonTypes";
import { kustoToUIDimensionMap } from "pages/Performance/PerformanceConstants";
import {
    App,
    ChartData,
    PerfInsightsChartQuery,
    PerfInsightsRow,
    ValuesToShowChoice,
} from "pages/Performance/types";
import {
    adaptToChartingLibrary,
    transformLabelsData,
} from "pages/Performance/Win32PerformanceHelper";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { createObjectArrayFromKustoResponse } from "utils/Helpers";

/*
 *  This is the wrapper to each chart displayed on the Win32PerformanceInsights page.
 * @param props accepts appName, scenario, dimension, tenantId, valuesToShow, and endDate.
 * @returns UI rendering logic.
 */
function Win32PerformanceInsightsChart({
    appName,
    selectedRow = { dimension: "", scenario: "" },
    tenantId,
    valuesToShow = "P95Duration",
    endDate,
}: {
    appName: App;
    selectedRow: PerfInsightsRow;
    tenantId: string;
    valuesToShow: ValuesToShowChoice;
    endDate: string;
}) {
    const stackTextAlignStyles: IStackItemStyles = {
        root: {
            textAlign: "center",
        },
    };

    const ChartDataInitial: { data: ChartData[]; labels: LegendEntry[] } = {
        data: [],
        labels: [],
    };

    // React Hooks
    const [chartDataState, setChartDataState] = useState(ChartDataInitial);
    const [isChartsLoaded, setIsChartsLoaded] = useState(false);

    useEffect(() => {
        const updateState = async () => {
            // The dimension is passed as undefined when there are no elements in the table corresponding
            // to the chart. This will avoid making API call.
            if (
                selectedRow?.dimension === undefined ||
                selectedRow?.scenario === undefined
            ) {
                setChartDataState({ data: [], labels: [] });
                setIsChartsLoaded(true);
                return;
            }

            const queryParams: PerfInsightsChartQuery = {
                tenantId,
                startDate: format(subMonths(parseISO(endDate), 6), "yyyy-MM-dd"),
                endDate,
                appName,
                dimensionName: selectedRow.dimension,
                scenario: selectedRow.scenario,
                valuesToShow,
                tenantTableName: "Summary_TenantPerfMonthly",
            };

            try {
                const response: KustoResponseType<string> =
                    await API.fetchWin32PerfInsightsChart(queryParams);
                const [adaptedLabels, adaptedData] = adaptToChartingLibrary(
                    createObjectArrayFromKustoResponse(response),
                    true,
                );
                const adaptedLegends = transformLabelsData(adaptedLabels);
                const newState = { data: adaptedData, labels: adaptedLegends };
                setChartDataState(newState);
                setIsChartsLoaded(true);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "Win32PerfInsightsChartQueryFailed",
                    ),
                    {
                        message: "Kusto Query failed for Win32PerfInsightsChart",
                        status: error?.errorResponse?.status,
                        query: error?.errorResponse?.config?.data,
                    },
                );
            }
        };
        updateState();
    }, [
        tenantId,
        appName,
        endDate,
        valuesToShow,
        selectedRow.dimension,
        selectedRow.scenario,
    ]); // End of useEffect.

    // UI rendering definition
    const headerStyle = {
        fontWeight: 600,
    };

    const customFormatter = (value, name) => {
        if (name.includes("%")) {
            return `${value}%`;
        }
        return `${value}s`;
    };

    return (
        <Shimmer isDataLoaded={isChartsLoaded}>
            {selectedRow ? (
                <Stack
                    styles={stackTextAlignStyles}
                    key={`${appName}${selectedRow.scenario}`}
                >
                    <h3 style={headerStyle}>
                        {kustoToUIDimensionMap[selectedRow.dimension]}
                    </h3>
                    <TogglingLineChart
                        kind={
                            valuesToShow === "PercentBelowThreshold"
                                ? togglingLineChartKind.percentages
                                : togglingLineChartKind.values
                        }
                        xDataKey="Date"
                        yUnit={valuesToShow === "PercentBelowThreshold" ? "%" : "s"}
                        values={chartDataState.data}
                        labels={chartDataState.labels}
                        height={200}
                        strokeWidth={2}
                        tooltipFormatter={customFormatter}
                    />
                </Stack>
            ) : (
                ""
            )}
        </Shimmer>
    );
}

export default Win32PerformanceInsightsChart;
