import React from "react";
import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand } from "@visx/scale";
import { Bar } from "@visx/shape";
import { scaleThreshold } from "d3-scale";
import { interpolatePiYG } from "d3-scale-chromatic";

const width: number = 760;
const retentionStartIdx = 1;
export const RetentionTable = ({ payload, frequency = "Monthly" }) => {
    if (payload.length === 0) return <div />;
    const headers: string[] = [
        frequency === "Weekly" ? "Starting Cohort Week" : "Starting Cohort Month",
        "User Count",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
    ];

    const colHeaders = headers.map((col, i) => {
        return (
            <th key={col} className={`hcol-${i}`}>
                {col}
            </th>
        );
    });

    const domainValues = [];
    for (let x = 5; x <= 100; x += 5) domainValues.push(x);
    const rangeValues = [];
    for (let x = 0; x <= 100; x += 5) rangeValues.push(interpolatePiYG(x / 100));

    const color = scaleThreshold().domain(domainValues).range(rangeValues);

    const domainValuesXScale: number[] = [0, ...domainValues];
    const xScale = scaleBand({
        domain: domainValuesXScale,
        range: [0, width],
        paddingOuter: 0,
        paddingInner: 1,
    });

    // Color the cells in the table
    const rows = [];
    payload.forEach((row) => {
        const cells = [];
        row.forEach((el, idx) => {
            let elStyle = {};

            if (idx > retentionStartIdx) {
                if (!el) {
                    elStyle = { border: "none" };
                } else {
                    const newel = +(el * 100).toFixed(2);
                    el = newel;
                    elStyle = {
                        backgroundColor: color(newel),
                    };
                }
            }
            cells.push(
                <td key={`rcol-${idx}`} className={`rcol-${idx}`} style={elStyle}>
                    <span>{el}</span>
                </td>,
            );
        });
        rows.push(cells);
    });

    const data = rows.map((row, index) => {
        return <tr key={`rrow-${index}`}>{row}</tr>;
    });
    return (
        <div>
            <table id="retentionTable">
                <thead>
                    <tr>{colHeaders}</tr>
                </thead>
                <tbody>{data}</tbody>
            </table>
            <svg width={`${width + 20}px`} height="60px">
                <Group transform="translate(5,0)">
                    {domainValues.map((el, idx) => {
                        return (
                            <Bar
                                fill={color(el)}
                                width="38px"
                                height="15px"
                                key={idx}
                                x={38 * idx}
                            />
                        );
                    })}
                    <AxisBottom
                        scale={xScale}
                        top={15}
                        label={"%Users Retained"}
                        stroke={"#edebe9"}
                        tickLength={8}
                        labelProps={{ fontSize: 10 }}
                        tickLabelProps={() => ({
                            fontSize: 9,
                            dx: "-0.45em",
                        })}
                    />
                </Group>
            </svg>
        </div>
    );
};
