import React from "react";
import { Stack, mergeStyleSets } from "@fluentui/react";
import { CopilotTrendTemplate } from "components/AIScorecardComponents/CopilotTrends/CopilotTrendTemplate";
import { MetricsType } from "components/AIScorecardComponents/CopilotTrends/types";
import { commonPageBlock } from "pages/common";

const defaultApps = [
    "Word",
    "Excel",
    "PowerPoint",
    "OneNote",
    "Outlook",
    "Teams",
    "Loop",
    "Whiteboard",
    "M365 Chat",
    "Stream",
    "Forms",
    "Planner",
];

const crossApps = Array.from(defaultApps).concat("WXPN");

const usageMetrics: MetricsType[] = [
    { Metric: "Seen", Marker: "x", State: false },
    { Metric: "Tried", Marker: "circle", State: true },
    { Metric: "Kept", Marker: "line", State: false },
];

export const commonPageBlockNoPadding = mergeStyleSets(
    { ...commonPageBlock },
    {
        root: {
            padding: "0px",
        },
    },
);

export const CopilotTrends = ({ filters }) => {
    return (
        <Stack>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="Usage"
                    chartTitle={"Usage"}
                    applications={crossApps}
                    metrics={usageMetrics}
                />
            </Stack.Item>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="TriedCountsPerUser"
                    chartTitle={"Tries Per User"}
                    applications={crossApps}
                />
            </Stack.Item>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="Retention"
                    chartTitle={"Retention 1st Period"}
                    applications={crossApps}
                />
            </Stack.Item>
        </Stack>
    );
};
