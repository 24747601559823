import { CamauCadenceType } from "components/ConsumerCamauComponents/types";
import { CommonConfigType, DefaultsType } from "config/PlatformConfig";
import { Day0FunnelCadenceType } from "pages/ConsumerDay0Funnel/types";

export enum DrilldownPages {
    ADDINS = "AddIns",
    DAY0FUNNEL_CONSUMER = "Day0Funnel_Consumer",
    ERFM = "ERFM",
    GROWTHSCORECARD_CONSUMER = "GrowthScorecard_Consumer",
    GROWTHDRILLDOWN_CONSUMER = "GrowthDrilldown_Consumer",
}

export interface PageDefaultsType extends DefaultsType {
    publisher?: string;
    cadence?: CamauCadenceType | Day0FunnelCadenceType;
    scenario?: string;
    referrer?: string;
}

export interface ColumnInfoType {
    displayName?: string;
    minWidth?: number;
    cssClassName?: string;
    isSorted?: boolean;
}

export interface TableInfoType {
    [colunmName: string]: ColumnInfoType;
}

export interface TableColumnNameMappingType {
    [tableName: string]: TableInfoType;
}

export interface PageConfigType extends CommonConfigType {
    defaults: PageDefaultsType;
    tableColumns?: TableColumnNameMappingType;
}

export type DrilldownPageConfigType = {
    [key in DrilldownPages]: PageConfigType;
};

export const PagesConfig: DrilldownPageConfigType = {
    [DrilldownPages.ADDINS]: {
        appsList: ["All", "Word", "Excel", "PowerPoint", "Outlook", "OneNote"],
        pageTitle: "Add-Ins Dashboard",
        pageHeader: "Add-Ins Dashboard",
        pageToolNameForTelemetry: "Add-Ins Dashboard",
        defaults: {
            app: "All",
            level: "TenantId",
            cohort: "S500",
            publisher: "All",
        },
        tableColumns: {
            performance: {
                AddInFriendlyName: {
                    displayName: "Add-In Name",
                    minWidth: 200,
                },
                Rank: {
                    displayName: "Performance Impact",
                    cssClassName: "detailsListColumnHeaderWrap",
                },
                TypicalLoadDurationPerSession: {
                    displayName: "Typical LoadDuration / Session (in Seconds)",
                    cssClassName: "detailsListColumnHeaderWrap",
                    minWidth: 160,
                },
                ImpactedTenants: {
                    displayName: "# Impacted Tenants",
                    cssClassName: "detailsListColumnHeaderWrap",
                    isSorted: true,
                },
                DevicesWithAddIn: {
                    displayName: "# Devices with AddIn",
                    cssClassName: "detailsListColumnHeaderWrap",
                },
                AddInProvider: {
                    displayName: "Publisher Name",
                    minWidth: 140,
                },
                ImpactedApps: {
                    displayName: "Impacted Apps",
                    minWidth: 150,
                },
                AddInCLSID: {
                    displayName: "Add-In Id",
                },
                AddInProgId: {
                    displayName: "Add-In ProgId",
                    minWidth: 200,
                },
            },
            reliability: {
                AddInFriendlyName: {
                    displayName: "Add-In Name",
                    minWidth: 200,
                },
                Crashes: {
                    displayName: "# Crashes",
                    isSorted: true,
                },
                Reliability: {
                    displayName: "% Reliability",
                },
                ImpactedTenants: {
                    displayName: "# Impacted Tenants",
                    cssClassName: "detailsListColumnHeaderWrap",
                },
                ImpactedDevices: {
                    displayName: "# Impacted Devices",
                    cssClassName: "detailsListColumnHeaderWrap",
                },
                UnsuccessfulLoads: {
                    displayName: "# Unsuccessful Loads",
                    cssClassName: "detailsListColumnHeaderWrap",
                    minWidth: 120,
                },
                AddInProvider: {
                    displayName: "Publisher Name",
                    minWidth: 150,
                },
                ImpactedApps: {
                    displayName: "Impacted Apps",
                    minWidth: 150,
                },
                AddInCLSID: {
                    displayName: "Add-In Id",
                },
                AddInProgId: {
                    displayName: "Add-In ProgId",
                    minWidth: 200,
                },
            },
        },
    },
    [DrilldownPages.DAY0FUNNEL_CONSUMER]: {
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer - Day 0 Session 0 Journey Funnel",
        pageHeader: "Consumer - Day 0 Session 0 Journey Funnel",
        pageToolNameForTelemetry: "Consumer Day 0 Session 0 Journey Funnel",
        defaults: {
            app: "Word",
            cohort: "Free New Users",
            cadence: "weekly",
            scenario: "All",
            referrer: "All",
        },
    },
    [DrilldownPages.ERFM]: {
        appsList: ["WXP", "Word", "Excel", "PowerPoint"],
        pageTitle: "eRFM",
        pageHeader: "eRFM (Enhanced Reduced Functionality Mode)",
        pageToolNameForTelemetry: "eRFM Dashboard",
        defaults: {
            app: "WXP",
        },
    },
    [DrilldownPages.GROWTHSCORECARD_CONSUMER]: {
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer Growth Scorecard",
        pageHeader: "Consumer Overview - Web",
        pageToolNameForTelemetry: "Consumer Growth Scorecard",
        defaults: {
            app: "Word",
        },
    },
    [DrilldownPages.GROWTHDRILLDOWN_CONSUMER]: {
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer Growth Drilldown",
        pageHeader: "Consumer Growth Drilldown",
        defaults: {
            app: "Word",
        },
    },
};

export const getPageConfig = (page: string): PageConfigType => {
    return PagesConfig[page];
};
