import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { TenantMetrics } from "components/TenantDrilldownComponents/TenantMetrics";
import { Workload } from "config/PlatformConfig";
import { fetchCohortAppMetrics } from "pages/ConsumerProductDrillDown/ApiHandler";
import {
    ConsumerAppMetricsProps,
    ConsumerAppMetricsState,
    ConsumerAppMetricsFilterState,
} from "pages/ConsumerProductDrillDown/types";
import { loadChartMetrics } from "pages/InProductDrillDown/ApiHandler";
import { ConsumerAppMetricFilters } from "../ConsumerAppMetricFilters/ConsumerAppMetricFilters";

export const ConsumerMetrics = ({
    ConsumerDrilldownFilters,
}: ConsumerAppMetricsProps) => {
    const [data, setData] = useState<ConsumerAppMetricsState>({
        appMetrics: [],
        chartData: {},
        loading: true,
    });

    const [filters, setFilters] = useState<ConsumerAppMetricsFilterState>({
        host: "OneDriveWOPI",
    });

    const [error, setError] = useState<string>(null);

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        setFilters(updatedFilter);
    };

    useEffect(() => {
        const getAppMetrics = async () => {
            setData((oldState) => {
                return { ...oldState, loading: true };
            });

            try {
                const appmetrics = await fetchCohortAppMetrics(
                    { ...ConsumerDrilldownFilters, ...filters },
                    Workload.WEB_CONSUMER,
                );

                if (typeof appmetrics === "string") {
                    setError(appmetrics);
                    setData((oldDataState) => {
                        return { ...oldDataState, loading: false };
                    });
                } else {
                    setError(null);
                    setData((oldDataState) => {
                        return {
                            ...oldDataState,
                            appMetrics: appmetrics,
                            loading: false,
                        };
                    });
                }
            } catch (error) {
                setError(error.message);
                setData((oldDataState) => {
                    return { ...oldDataState, loading: false };
                });
            }
        };

        getAppMetrics();
        // ADO 7955411: useCallback refactor on getPayloadData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ConsumerDrilldownFilters.cohortName,
        ConsumerDrilldownFilters.cohortType,
        ConsumerDrilldownFilters.date,
        filters.host,
    ]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack>
            <ConsumerAppMetricFilters
                filters={filters}
                onFilterChange={onFilterChange}
            />
            <Stack.Item>
                <TenantMetrics
                    payload={data.appMetrics}
                    getChartMetrics={(metric, display, level, orgKey) =>
                        loadChartMetrics(
                            metric,
                            display,
                            level,
                            orgKey,
                            { ...filters, ...ConsumerDrilldownFilters },
                            setData,
                            Workload.WEB_CONSUMER,
                        )
                    }
                    chartData={data.chartData}
                    filters={{
                        rankOn: ConsumerDrilldownFilters.application,
                        ...ConsumerDrilldownFilters,
                    }}
                    metricFilters={{}}
                    loading={data.loading}
                    platform={Workload.WEB_CONSUMER}
                />
            </Stack.Item>
        </Stack>
    );
};
