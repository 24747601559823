export const teamsCoreMetrics: string = `
//TeamsCoreMetrics
let stDate = GetCurrentDateTeams();
let currentDate =  "\${date}";
let app = "\${app}";
StrategicTenants
| where Date == toscalar(StrategicTenants|summarize max(Date)) and \${levelColumnName} == "\${id}"
| join kind = inner(
Teams_Scores
| where \${levelColumnName} == "\${id}"
| where Date == currentDate and SubWorkload == app
) on \${levelColumnName}
| project UsageRate = round(UsageRate_Score,2), MoM_UsageRate = round(MoM_UsageRate,2), Color_UsageRate = UsageRate_Color,
StreamEstablishedSuccessPercentage = round(StreamEstablishedSuccessPercentage_Score,2), MoM_StreamEstablishedSuccessPercentage = round(MoM_StreamEstablishedSuccessPercentage,2), Color_StreamEstablishedSuccessPercentage = StreamEstablishedSuccessPercentage_Color,
DropRatePercentage = round(DropRatePercentage_Score,2), MoM_DropRatePercentage = round(MoM_DropRatePercentage,2), Color_DropRatePercentage = DropRatePercentage_Color,
AudioPSRPercentage = round(AudioPSRPercentage_Score,2), MoM_AudioPSRPercentage = round(MoM_AudioPSRPercentage,2), Color_AudioPSRPercentage = AudioPSRPercentage_Color,
PoorCPURate = round(PoorCPURate_Score,2), MoM_PoorCPURate = round(MoM_PoorCPURate,2), Color_PoorCPURate = PoorCPURate_Color,
PoorMemoryRate = round(PoorMemoryRate_Score,2), MoM_PoorMemoryRate = round(MoM_PoorMemoryRate,2), Color_PoorMemoryRate = PoorMemoryRate_Color,
PoorFrequencyRate = round(PoorFrequencyRate_Score,2), MoM_PoorFrequencyRate = round(MoM_PoorFrequencyRate,2), Color_PoorFrequencyRate = PoorFrequencyRate_Color,
FeedbackPoorPercentage = round(FeedbackPoorPercentage_Score,2), MoM_FeedbackPoorPercentage = round(MoM_FeedbackPoorPercentage,2), Color_FeedbackPoorPercentage = FeedbackPoorPercentage_Color,
NPS = round(NPS_Score,2), MoM_NPS = round(MoM_NPS,2), Color_NPS = NPS_Color,
ChatSwitchV2 = round(ChatSwitchV2_Score,2), MoM_ChatSwitchV2 = round(MoM_ChatSwitchV2,2), Color_ChatSwitchV2 = ChatSwitchV2_Color,
P95ChatSwitchV2 = round(P95ChatSwitchV2_Score,2), MoM_P95ChatSwitchV2 = round(MoM_P95ChatSwitchV2,2), Color_P95ChatSwitchV2 = P95ChatSwitchV2_Color,
P50ChatSwitchV2 = round(P50ChatSwitchV2_Score,2), MoM_P50ChatSwitchV2 = round(MoM_P50ChatSwitchV2,2), Color_P50ChatSwitchV2 = P50ChatSwitchV2_Color,
NewChat = round(NewChat_Score, 2), MoM_NewChat = round(MoM_NewChat,2), Color_NewChat = NewChat_Color,
ChatSendMessage = round(ChatSendMessage_Score, 2), MoM_ChatSendMessage = round(MoM_ChatSendMessage,2), Color_ChatSendMessage = ChatSendMessage_Color,
HealthColor = Score_Color, Score = Score_Score, Application = SubWorkload
`;
