import React from "react";
import { NO_DATA_MESSAGES } from "utils/Messages";

interface EmptyTableContainerProps {
    message?: string;
    minHeight?: number;
    minWidth?: number;
    center?: boolean;
}

const EmptyTableContainer = ({
    message = NO_DATA_MESSAGES.NO_DATA,
    minHeight = null,
    minWidth = null,
    center = false,
}: EmptyTableContainerProps) => {
    return (
        <div
            className="emptyResult"
            style={{
                minHeight: minHeight ?? "auto",
                minWidth: minWidth ?? "auto",
                display: center ? "flex" : "",
                alignItems: center ? "center" : "inherit",
                justifyContent: center ? "center" : "inherit",
            }}
        >
            {message}
        </div>
    );
};

export default EmptyTableContainer;
