import React from "react";
import { Stack } from "@fluentui/react";
import { CustomBarChart } from "components/CustomComponents/CustomBarChart/CustomBarChart";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { CustomPieChart } from "components/TenantDrilldownComponents/SessionDistribution/CustomPieChart";
import { GeographicDistributionWrapper } from "components/TenantDrilldownComponents/TenantEnvironment/GeographicDistributionWrapper";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import {
    DailyNetworkTrend,
    MonthlyNetworkTrend,
} from "components/TenantDrilldownComponents/NetworkScore";
import { TenantEnvironmentPropsType } from "components/TenantDrilldownComponents/TenantEnvironment/types";
import { Workload } from "config/PlatformConfig";
import { equalStackItemsStyle, horizontalStackTokens } from "pages/common";
import { MonthlyTrend } from "../MonthlyTrend";

export const TeamsTenantEnvironment = ({
    tenantEnvironment,
    filters,
    metricFilters,
    loading,
}: TenantEnvironmentPropsType) => {
    return (
        <>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">
                                Integration with Office on the Web
                            </h2>
                            <CustomBarChart
                                payload={tenantEnvironment.OfficeUsage}
                                keys={["Teams"]}
                                fill="#8884d8"
                                yLabel="% Sessions"
                            />
                        </Stack.Item>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <div
                                style={{
                                    height: "80px",
                                }}
                            ></div>
                            <CustomPieChart
                                payload={tenantEnvironment.OfficeUsageBreakDown}
                            />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <DailyNetworkTrend
                                filters={filters}
                                selectedDate={metricFilters["Date"]}
                                platform={Workload.WIN32}
                            />
                        </Stack.Item>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <MonthlyNetworkTrend
                                filters={filters}
                                platform={Workload.WIN32}
                            />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">
                                License Distribution
                            </h2>
                            <MonthlyTrend
                                payload={tenantEnvironment.licenseDistribution}
                                kind={togglingLineChartKind.percentagesWithValues}
                                yLabel="% Licenses"
                            />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack>
                        {tenantEnvironment.geographicDistribution && (
                            <GeographicDistributionWrapper
                                geographicDistribution={
                                    tenantEnvironment.geographicDistribution
                                }
                                geoDataMapping={tenantEnvironment.geoDataMapping}
                                loading={tenantEnvironment.loading}
                                rankOn={filters["rankOn"]}
                                workload={Workload.TEAMS}
                            />
                        )}
                    </Stack>
                </>
            )}
        </>
    );
};
