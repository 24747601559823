export const footerHeight: string = "50";

export const footerWidth: string = "200";

export const footerDownArrowStrokeColor = "#ffffff";

export const footerDownArrowFillColor = "#7f0000";

export const arrowWidth = 35;

export const arrowHeight = 40;
