export const macTenantMetrics: string = `
// macTenantMetrics
let level = iff("\${level}" == "Tpid", "Parent", "Tenant");
TenantInsights_Mac_Metrics(level, "\${id}")
| where Date == "\${date}"
| project Date, Metric, DisplayName, Group, Application, Value = iff(isempty( Value), "NA",Value)
| extend GroupOrder = TenantInsights_Mac_GetMetricGroupOrder(Group), MetricOrder = TenantInsights_Mac_GetMetricOrder(Metric)
| order by GroupOrder asc, MetricOrder asc, DisplayName asc
| project-away MetricOrder, GroupOrder
`;

export const macTenantMetricsChart: string = `
// macTenantMetricsChart
let level = iff("\${level}" == "Tpid", "Parent", "Tenant");
TenantInsights_Mac_Metrics(level, "\${id}")
| where Metric == "\${metric}"
| project Date, Application, Value = round(todecimal(Value),2)
| order by Date asc
`;
