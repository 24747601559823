import { isTest } from "utils/WebConstants";

export const webTenantList: string = `
    // tenant List - get tenant lists
    let currDate = GetCurrentDate(${isTest});
    let wxpTenants = Score_WXP_V2 | where Date == currDate and UiHost == "All" | project Color = WXP_Score_Color, MAU = WXP_Mau, OmsTenantId, Tpid, Score = WXP_Score;
    let allTenants = Score_V2 | where Date == currDate and UiHost == "All" and CompositeScore_Color != "Gray" | distinct OmsTenantId, Tpid;
    let Overview = allTenants | join kind = leftouter (wxpTenants) on OmsTenantId, Tpid
    | project Color = iff(isempty(Color), "Gray" , Color), MAU = iff(isempty(MAU), 0, MAU), OmsTenantId, Tpid, Score = iff(isempty(Score), 0.0, Score);
    StrategicTenants
        | where Date == currDate
        | distinct Tpid, OrgName_Translated, IsS500, IsS2500, IsEPA, IsGoogleMove, IsGov, IsEO, IsCopilot
        | summarize IsTpidS2500=max(IsS2500), IsTpidS500=max(IsS500), IsTpidEPA=max(IsEPA), IsTpidGoogleMove=max(IsGoogleMove), IsTpidGov=max(IsGov), IsTpidEO=max(IsEO), IsTpidCopilot=max(IsCopilot) by Tpid, OrgName_Translated = iff(Tpid == 23321296, toupper(OrgName_Translated), OrgName_Translated)
        | extend IsTpidOther = not (IsTpidS2500 or IsTpidS500 or IsTpidEPA or IsTpidGoogleMove or IsTpidGov or IsTpidEO or IsTpidCopilot)
        | join kind = inner
        (
            Overview
            | where OmsTenantId == "All"
        )
        on Tpid
        | extend Mau = iff(isempty(MAU), 0, MAU)
        | project Tpid, OrgName_Translated, Mau,
            TpidStatus = Color, 
            TpidOrder = case( Mau <= 500, 5,
                Score <= 25 , 4, 
                Score <= 50, 3,
                Score <= 75, 2, 1),
            TpidScore = Score,
            IsTpidS500, IsTpidS2500, IsTpidEPA, IsTpidGoogleMove, IsTpidGov, IsTpidEO, IsTpidCopilot, IsTpidOther
        | join kind = leftouter
        (
            Overview
            | where OmsTenantId != "All"
            | project Tpid, OmsTenantId,
                OmsStatus = Color, 
                OmsMAU = MAU
            | join kind=inner (StrategicTenants
                | where Date == currDate) on OmsTenantId
        )
        on Tpid
        | order by TpidOrder asc, TpidScore desc, Mau desc, OmsMAU desc 
        | project Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName_Translated, TpidStatus, OmsStatus,
            IsS500 = iif(isempty(IsS500), IsTpidS500, IsS500), 
            IsS2500 = iif(isempty(IsS2500), IsTpidS2500, IsS2500),
            IsEPA = iif(isempty(IsEPA), IsTpidEPA, IsEPA),
            IsGoogleMove = iif(isempty(IsGoogleMove), IsTpidGoogleMove, IsGoogleMove),
            IsGov = iif(isempty(IsGov), IsTpidGov, IsGov),
            IsEO = iif(isempty(IsEO), IsTpidEO, IsEO),
            IsOther = iif(isempty(IsOther), IsTpidOther, IsOther),
            IsCopilot = iif(isempty(IsCopilot), IsTpidCopilot, IsCopilot)
    `;
