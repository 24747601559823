import React, { useState, useEffect } from "react";
import { Bar, ComposedChart, XAxis, YAxis, Legend, Tooltip, Line } from "recharts";
import {
    ComboBox,
    Dropdown,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    Label,
    Stack,
} from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { AppButtons } from "components/CustomComponents/AppButtons";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { updateScorecardChartData } from "components/ConsumerGrowthScorecardComponents/ApiHandler";
import {
    chartFlyoutBarsDisplayNames,
    chartFlyoutLinesDisplayNames,
    legendPayload,
    segmentMetadata,
} from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardHelper";
import "components/ConsumerGrowthScorecardComponents/style.css";
import {
    ConsumerGrowthScorecardFlyoutPropsType,
    ConsumerGrowthScorecardRowType,
    LegendPayloadType,
} from "components/ConsumerGrowthScorecardComponents/types";
import { Workload } from "config/PlatformConfig";
import { getChartColor, getDomainForYAxis } from "utils/Helpers";

const defaultTimespan = 12;

const timespanOptions = [3, 6, 9, 12].map((v) => ({ key: v, text: v.toString() }));

const getSegmentOptions = () => {
    return Object.entries(segmentMetadata).map(([key, value]) => ({
        key,
        text: value.displayText,
    }));
};

const onRenderDropdownOption = (option: IDropdownOption): JSX.Element => {
    const marginLeftValue = segmentMetadata[option.key].segmentLevel * 6;
    return (
        <div style={{ marginLeft: marginLeftValue }}>
            <span style={{ marginLeft: marginLeftValue }}>{option.text}</span>
        </div>
    );
};

export const GrowthScorecardChartFlyout = ({
    isPanelOpen,
    togglePanel,
    segment,
    filters,
    data,
}: ConsumerGrowthScorecardFlyoutPropsType) => {
    const [selectedApp, setSelectedApp] = useState<string>(filters.application);
    const [segmentSelected, changeSegmentSelected] = useState<string>(segment);
    const [legendProps, setLegendProps] =
        useState<LegendPayloadType[]>(legendPayload);
    const [chartData, setChartData] = useState<ConsumerGrowthScorecardRowType[]>([]);
    const [timespan, setTimespan] = useState<number>(defaultTimespan);

    const onSegmentChange = () => {
        return (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            changeSegmentSelected(item.key.toString());
        };
    };

    const onTimespanChange = async (
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ) => {
        let selectedTimespan = (option?.key as number) ?? Math.round(Number(value));
        if (!(selectedTimespan > 0)) selectedTimespan = defaultTimespan;

        setTimespan(selectedTimespan);
    };

    // Metric and its Goal should be associated. Selecting/Unselecting a metric from legend
    // should also do the same for the metric Goal (when Goals is toggled on)
    // metric inactive, show goal -> goal inactive
    // metric inactive, no show goal -> goal inactive
    // metric active, show goal -> goal active
    // metric active, no show goal -> goal inactive
    const clickMetricFromLegend = (e) => {
        const updatedMetricProps = legendProps.map((legend) =>
            legend.dataKey == e.dataKey
                ? { ...legend, inactive: !legend.inactive }
                : legend,
        );

        setLegendProps(updatedMetricProps);
    };

    const numberFormat = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
    });

    const customToolTipContent = (value): string => {
        const formattedValue = numberFormat.format(value);
        return `${formattedValue}`;
    };

    // The 'segment' property is changed when a segment is clicked from table, so when a segment is clicked on the growth scorecard table
    // All the filters in the flyout should be reset to defaults from the Table
    useEffect(() => {
        changeSegmentSelected(segment);
        setLegendProps(legendPayload);
        setSelectedApp(filters.application);
        setTimespan(defaultTimespan);
    }, [filters.application, segment]);

    useEffect(() => {
        const updatedChartData = updateScorecardChartData(
            segmentSelected,
            selectedApp,
            timespan,
            data,
        );

        setChartData(updatedChartData);
    }, [data, timespan, segmentSelected, selectedApp]);

    return (
        <Panel
            isLightDismiss
            isOpen={isPanelOpen}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                togglePanel();
            }}
            closeButtonAriaLabel="Close"
            headerText="Growth Scorecard metric trends"
            headerClassName="flyoutHeader"
        >
            <div className="growthScorecardFlyoutFilter">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <div>
                        <Label className="filterLabel">Months to show:</Label>
                        <ComboBox
                            options={timespanOptions}
                            allowFreeform={true}
                            styles={{
                                root: { width: 80 },
                                optionsContainerWrapper: { width: 80 },
                            }}
                            text={timespan.toString()}
                            onChange={onTimespanChange}
                            className="filterDropdown"
                        />
                    </div>
                    <div>
                        <Label className="filterLabel">Segment:</Label>
                        <Dropdown
                            options={getSegmentOptions()}
                            onChange={onSegmentChange()}
                            defaultSelectedKey={segmentSelected}
                            selectedKey={segmentSelected}
                            onRenderOption={onRenderDropdownOption}
                            className="filterDropdown"
                            dropdownWidth="auto"
                        />
                    </div>
                </Stack>
            </div>
            {chartData?.length !== 0 ? (
                <>
                    <ComposedChart
                        width={650}
                        height={470}
                        data={chartData}
                        className="growthTrendsChart"
                    >
                        <XAxis dataKey="Date" />
                        <YAxis
                            yAxisId="left"
                            unit="%"
                            allowDecimals={true}
                            domain={getDomainForYAxis(
                                chartData
                                    .map((elem) => [
                                        elem.CAMAUDay0_Pct,
                                        elem.CAMAUPct_Pct,
                                        elem.M1Retention_Pct,
                                        elem.M3Retention_Pct,
                                    ])
                                    .flat(),
                                true,
                            )}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={(tick) =>
                                `${(tick / 1000000).toLocaleString()}M`
                            }
                        ></YAxis>
                        <Tooltip formatter={customToolTipContent} />
                        <Legend
                            onClick={clickMetricFromLegend}
                            wrapperStyle={{
                                paddingTop: "15px",
                            }}
                            payload={legendProps?.filter(
                                (elem) => !elem.dataKey.includes("Goal_"),
                            )}
                        />
                        {Object.keys(chartFlyoutBarsDisplayNames).map(
                            (barField, index) => (
                                <>
                                    <Bar
                                        key={barField}
                                        dataKey={barField}
                                        hide={
                                            legendProps.find(
                                                (metric) =>
                                                    metric.dataKey === barField,
                                            ).inactive
                                        }
                                        name={
                                            chartFlyoutBarsDisplayNames[barField]
                                                .DisplayName
                                        }
                                        fill={getChartColor(index + 5)}
                                        yAxisId="right"
                                    />
                                </>
                            ),
                        )}
                        {Object.keys(chartFlyoutLinesDisplayNames).map(
                            (lineField, index) => (
                                <>
                                    <Line
                                        key={lineField}
                                        type="monotone"
                                        hide={
                                            legendProps.find(
                                                (metric) =>
                                                    metric.dataKey === lineField,
                                            ).inactive
                                        }
                                        name={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .DisplayName
                                        }
                                        dataKey={lineField}
                                        yAxisId="left"
                                        dot={false}
                                        stroke={getChartColor(index)}
                                        strokeWidth={2}
                                    />
                                </>
                            ),
                        )}
                    </ComposedChart>
                    <span className="growthTrendsLegendSpan">
                        *Click on legend items to toggle.
                    </span>
                    <div
                        className="growthTrendsAppButtons"
                        data-testid="growthTrendsAppButtons"
                    >
                        <AppButtons
                            selectedApp={selectedApp}
                            setSelectedApp={setSelectedApp}
                            workload={Workload.WEB_CONSUMER}
                        />
                    </div>
                </>
            ) : (
                <div>
                    <EmptyTableContainer />
                </div>
            )}
        </Panel>
    );
};
