import { IStackItemStyles, IStackTokens } from "@fluentui/react";

export const stackItemStylesCommonFunnel: IStackItemStyles = {
    root: {
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "10px",
        borderRadius: "14px",
        overflowX: "auto",
    },
};

export const horizontalStackTokensCommonFunnel: IStackTokens = {
    childrenGap: 20,
    padding: "0 0 20px 0",
};

export const horizontalCompressedStackTokensCommonFunnel: IStackTokens = {
    childrenGap: 5,
    padding: "0 0 5px 0",
};
