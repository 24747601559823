import { fetchFunnelDataFromKusto } from "components/CommonFunnelComponents/CommonFunnel/ApiHandler";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import {
    FunnelDataType,
    FunnelStageProps,
    FunnelStageSliceProps,
    StageIdentifier,
} from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { FunnelQueryType, FunnelType } from "config/FunnelConfig";
import { MetricsResponseType } from "pages/commonTypes";

export const getStages = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
): Promise<MetricsResponseType> => {
    const payload = await fetchFunnelDataFromKusto(
        filters,
        funnelType,
        FunnelQueryType.Stages,
    );

    return payload;
};

export const formatStagesData = (
    funnelStagesJson: MetricsResponseType,
): FunnelDataType => {
    const funnelStagesTable = funnelStagesJson.Tables[0];
    const funnelData: FunnelDataType = {};

    // Schema: Date (in desc order), StageIndex (in asc order), StageName, StageFriendlyName, DropOffName, DropOffFriendlyName, CanBeNull, Value, Total, Percentage, ToolTipMessage
    funnelStagesTable.Rows.forEach((row) => {
        if (!(row[0] in funnelData)) funnelData[row[0]] = [];

        const stage: StageIdentifier = {
            id: row[2],
            friendlyName: row[3],
        };

        const dropOff: StageIdentifier = {
            id: row[4],
            friendlyName: row[5],
        };

        const stageSlice: FunnelStageSliceProps = {
            name: row[2],
            value: row[7] as number,
            total: row[8] as number,
            percentage: row[9] as number,
        };

        const funnelStage: FunnelStageProps = {
            level: row[1],
            stage,
            dropOff,
            slices: [stageSlice],
            canBeNull: row[6],
            toolTipMessageOverride: row[10],
        };

        funnelData[row[0]].push(funnelStage);
    });

    return funnelData;
};
