import { defaultStyles } from "@visx/tooltip";

export const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "white",
    padding: 15,
    border: "0.5px solid grey",
};

export const stackedBarWidth = 50;

export const minBarHeight = 10;

export const barTextXOffset = stackedBarWidth / 2;

export const barTextFontSize = 12;

export const barTextYOffset = barTextFontSize / 2;
