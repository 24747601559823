import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useOnDismissMessageBar } from "utils/Helpers";
import { INVALID_URL } from "utils/Messages";

export const PageNotFound = () => {
    const { isOpen, handleDismissMessageBar } = useOnDismissMessageBar();
    return (
        isOpen && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={handleDismissMessageBar}
                dismissButtonAriaLabel="Close"
            >
                {INVALID_URL}
            </MessageBar>
        )
    );
};
