import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { ChoiceGroup, Shimmer, ShimmerElementType, Stack } from "@fluentui/react";
import { CommandBarButton } from "@fluentui/react/lib/Button";
import { CadenceFrequency } from "components/CustomComponents/CadenceLabel/CadenceLabel";
import NavigableDatePickerWithCalendar from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendar";
import {
    CamauCadenceOptions,
    CamauTableColumnConfig,
} from "components/ConsumerCamauComponents/ConsumerCamauHelper";
import {
    CamauCadenceType,
    ConsumerCamauFiltersPropsType,
} from "components/ConsumerCamauComponents/types";
import { getExportCsvHeaders } from "utils/Helpers";

const CamauFilters = ({
    camauState,
    filters,
    onFilterChange,
}: ConsumerCamauFiltersPropsType) => {
    const [cadence, setCadence] = useState<CamauCadenceType>(filters.cadence);

    return camauState.loading ? (
        <Shimmer
            shimmerElements={[
                { type: ShimmerElementType.line, width: "100%", height: 40 },
            ]}
        />
    ) : (
        <Stack horizontal className="filterPanel">
            <div>
                <Stack horizontal>
                    <ChoiceGroup
                        defaultSelectedKey={filters.cadence}
                        options={CamauCadenceOptions}
                        onChange={(_event, option) => {
                            setCadence(option.key as CamauCadenceType);
                            onFilterChange("cadence", option.key as string);
                        }}
                        required={true}
                        styles={{
                            flexContainer: {
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                marginTop: "-8px",
                            },
                        }}
                    />
                </Stack>
            </div>
            <Stack horizontal style={{ minWidth: 270 }}>
                <NavigableDatePickerWithCalendar
                    backTitle="Go to Previous Week"
                    forwardTitle="Go to Next Week"
                    dateOptions={camauState?.distinctDates}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    dateFilter={filters.date}
                    cadence={
                        cadence === "daily"
                            ? CadenceFrequency.Daily
                            : CadenceFrequency.Monthly
                    }
                    showGoToToday={false}
                />
            </Stack>
            <CSVLink
                data={camauState.camauTableRows[filters.application]}
                filename={`ConsumerCamauScorecard-${filters.application}.csv`}
                headers={getExportCsvHeaders(
                    camauState.camauTableRows,
                    filters.application,
                    CamauTableColumnConfig,
                )}
            >
                <CommandBarButton
                    iconProps={{
                        iconName: "Download",
                    }}
                    styles={{
                        root: {
                            height: 30,
                            backgroundColor: "unset",
                        },
                    }}
                    text="Export"
                    title="Export all weeks data"
                />
            </CSVLink>
        </Stack>
    );
};

export default CamauFilters;
