import { IDropdownOption, IStackStyles } from "@fluentui/react";
import { SummarizedInsightsRequestState } from "./types";

export const SummarizedInsightsRequestStackStyle: IStackStyles = {
    root: {
        width: "55%",
        alignItems: "center",
    },
};

export const ScenarioOptions: IDropdownOption<string>[] = [
    {
        key: "Executive Placemat",
        text: "Executive Placemat",
    },
    {
        key: "CXP Proactive Outreach",
        text: "CXP Proactive Outreach",
    },
    {
        key: "ACE Proactive Outreach",
        text: "ACE Proactive Outreach",
    },
    {
        key: "M365 Apps Health Review Engagement (CSA)",
        text: "M365 Apps Health Review Engagement (CSA)",
    },
    {
        key: "Ranger Engagement",
        text: "Ranger Engagement",
    },
    { key: "Other", text: "Other" },
];

export const SummarizedInsightsRequestInitialState: SummarizedInsightsRequestState =
    {
        isFetching: true,
        isErrord: false,
        isSuccess: false,
        ErrorList: [],
    };

export const TenantSearchBarId = "TenantSearchBar";

export const SuggestionsMaxHeight = 300;

export const SuggestionsMaxWidth = 500;
