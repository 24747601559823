export const webconsumergrowthSparkLineScores = `
// webconsumergrowthSparkLineScores
    let maxProcessDateForCohortEndDate = database('ConsumerInsights').EarlyRetentionMetrics
    | summarize MaxProcessDate = max(ProcessDate) by CohortEndDate
    | join kind=inner (database('ConsumerInsights').EarlyRetentionMetrics | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and UserSeenType == "New") on $left.CohortEndDate == $right.CohortEndDate, $left.MaxProcessDate == $right.ProcessDate
    | project ProcessDate, CohortEndDate, Application, DeviceType, Referrer, UiHost, Host, Platform, OperatingSystem, LicenseModel, UserSeenType, M1R, M3R, M1R_YoY, M3R_YoY;
    let goalFn = (val:long, goal:long, gt:bool) { iff(isempty(val) or isempty(goal), "Gray", iff((gt and val >= goal) or (not(gt) and val < goal), "Blue", "Yellow")) };
    let currDate = todatetime("\${date}");
    let oldestDate = datetime_add('month',-6,currDate);
    let application = "\${application}";
    let platform = "\${platform}";
    let host  = "\${host}";
    let license = "\${license}";
    let seenType = "\${seenType}";
    let referrer = "\${referrer}";
    database('ConsumerInsights').Usage
    | where todatetime(Date) between (oldestDate .. currDate)
    | where OperatingSystem == "All" 
    | where DeviceType == "All"
    | where UiHost == "All"
    | where Application == application
    | where Platform == platform 
    | where Host == host
    | where LicenseModel == license
    | where UserSeenType == seenType
    | where Referrer == referrer
    | join kind=leftouter (database('ConsumerInsights').FYGoals | extend Referrer = "All")
            on Application, Host, LicenseModel, UserSeenType, Referrer
    | join kind=leftouter (
        maxProcessDateForCohortEndDate
        | where Application == application
        | where Platform == platform
        | where Referrer == referrer
        | where LicenseModel == license
        | where Host == host
        )
            on $left.Date == $right.CohortEndDate
    | join kind=leftouter (
        database('ConsumerInsights').CamauPctAtDay0
        | where Application == application
        | where CohortName in ("Free All", "Free New", "Free Returning", "OneDrive All", "Paid All")
        | extend CohortName = iff(CohortName  == "OneDrive All", "All All", CohortName)
        | extend LicenseModel = tostring(split(CohortName, " ", 0)[0])
        | extend UserSeenType = tostring(split(CohortName, " ", 1)[0])
        | extend CAMAUDay0_Pct = Value, CAMAUDay0_YoY = YoY
        ) 
            on Application, Host, LicenseModel, UserSeenType, Date
    | project Date, MAU, MAU_Color = goalFn(MAU, MAU_Goal, true), CAMAUDay0_Pct, CAMAUDay0_Color = goalFn(CAMAUDay0_Pct, CAMAUDay0_Goal, true), PercentCAMAU, PercentCAMAU_Color = goalFn(M1R, PercentCAMAU_Goal, true), CAMAU, CAMAU_Color = goalFn(M1R, CAMAU_Goal, true), M1R, M1R_Color = goalFn(M1R, M1Retention_Goal, true), M3R, M3R_Color = goalFn(M3R, M3Retention_Goal, true)
    | order by Date asc
`;
