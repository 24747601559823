import { IGroup } from "@fluentui/react";
import * as API from "api";
import {
    formatCoreMetrics,
    formatAppMetrics,
    formatScoreLines,
} from "api/commonApiHandler";
import {
    KustoResponseType,
    MetricsResponseTableType,
    MetricsResponseType,
} from "pages/commonTypes";
import {
    generateCohortGroups,
    getCohortMetadataTemplate,
} from "pages/ConsumerProductDrillDown/ConsumerProductDrillDownHelper";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import {
    CohortListItemsType,
    CohortListState,
    CohortMetricsType,
    CohortScoreType,
    CohortTitleMetadata,
    ConsumerProductDrilldownFilterState,
} from "./types";

export const formatMetaData = (metaDataJson: MetricsResponseType) => {
    const metadataTable: MetricsResponseTableType = metaDataJson.Tables[0];
    if (metadataTable.Rows.length === 0) {
        return getCohortMetadataTemplate();
    }
    const metadata: CohortTitleMetadata = {
        cohortType: metadataTable.Rows[0][0],
        cohortName: metadataTable.Rows[0][1],
        status: metadataTable.Rows[0][2],
    };
    return metadata;
};

export const formatCoreMetricsResponse = (
    coreHealthJson: MetricsResponseType,
    sparkLineScoresJson: MetricsResponseType,
    metaDataJson: MetricsResponseType,
    workload: string,
): CohortMetricsType => {
    // TODO: remove this once avg cohorts is added
    const dummyAvgCohortJson = {
        Tables: [{ Columns: [], Rows: [], TableName: "Tables_0" }],
    };
    try {
        const { tenantCoreHealthMetric, coreHealthColor, coreHealthScore } =
            formatCoreMetrics(coreHealthJson, dummyAvgCohortJson, workload);

        const scoreStatus: CohortScoreType = {
            score: coreHealthScore,
            status: coreHealthColor,
        };
        const sparkLineScores = formatScoreLines(sparkLineScoresJson, workload);
        const metadata = formatMetaData(metaDataJson);
        return {
            coreHealthTiles: tenantCoreHealthMetric,
            sparkLineScores,
            metadata,
            scoreStatus,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerProductDrilldownCoreMetricsProcessingFailed",
            ),
            {
                message:
                    "Failure in parsing Core Metrics responses @Consumer ProductDrilldown page",
                data: {
                    coreHealthJson,
                    sparkLineScoresJson,
                    metaDataJson,
                    workload,
                },
            },
            error,
        );
        throw error;
    }
};

export const fetchCohortCoreHealthMetrics = async (
    filters: ConsumerProductDrilldownFilterState,
    platform: string,
): Promise<CohortMetricsType | string> => {
    try {
        const queryParams = { ...filters };

        const [coreHealth, sparkLineScores, metaData] = await Promise.all([
            API.coreMetrics(queryParams, platform),
            API.sparkLineScores(queryParams, platform),
            API.metaData(queryParams, platform),
        ]);

        try {
            const responseData = formatCoreMetricsResponse(
                coreHealth?.data,
                sparkLineScores?.data,
                metaData?.data,
                platform,
            );
            return responseData;
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "ConsumerProductDrilldownTenantMetricsProcessingException",
                ),
                {
                    message:
                        "Exception while processing metrics data in Consumer ProductDrilldown page",
                    sparkLineScores: sparkLineScores.status,
                    MetaData: metaData.status,
                    filters,
                },
                error,
            );

            return error.message;
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerProductDrilldownMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching metrics data in ConsumerProductDrilldown page",
                platform,
                filters,
            },
            error,
        );
        return error.message;
    }
};

export const fetchCohortList = async (
    queryParams: {},
    workload: string,
): Promise<Partial<CohortListState> | string> => {
    try {
        const cohortList: KustoResponseType<string> = await API.customerList(
            queryParams,
            workload,
        );

        const responseData = formatCohortsList(cohortList);
        return responseData;
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerProductDrilldownCohortListFetchException",
            ),
            {
                message:
                    "Exception while fetching list of cohorts in ConsumerProductDrilldown page",
            },
            error,
        );
        return error.message;
    }
};

export function formatCohortsList(
    cohortsJson: KustoResponseType<string>,
): Partial<CohortListState> {
    try {
        const cohortsList = cohortsJson.Tables[0];
        const rows: CohortListItemsType[] = [];
        // schema - CohortType, CohortName, Status
        cohortsList.Rows.forEach((element) => {
            rows.push({
                cohortType: element[0],
                cohortName: element[1],
                status: element[2],
            });
        });

        const groups: IGroup[] = generateCohortGroups(rows, false);

        return {
            items: rows,
            groups,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerProductDrilldownCohortListProcessingFailed",
            ),
            {
                message:
                    "Failure in processing tenant list response in ConsumerProductDrilldown page",
                data: {
                    cohortsJson,
                },
            },
            error,
        );
        throw error;
    }
}

export const fetchCohortAppMetrics = async (
    queryParams: {},
    workload: string,
): Promise<any[] | string> => {
    try {
        const appMetrics = await API.customerAppMetrics(queryParams, workload);

        const responseData = formatAppMetrics(appMetrics?.data);
        return responseData;
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ConsumerProductDrillDownAppMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching app metrics in ConsumerProductDrillDown page",
            },
            error,
        );
        return error.message;
    }
};
