export const tenantAddInOptions = `
// tenantAddInOptions
Summary_AddInsByTenantForkDailyV3
| where Fork == "All"
| where TenantId == '\${id}'
    and (DailyDeviceCount > 100 or DevicesWithAddIn > 20)
| extend AddInProgId = case(isempty(AddInCLSID), "Private add-ins", coalesce(AddInProgId, "Unknown Add-In(s)"))
| summarize PercentDevicesWithAddIn = toint(max(PercentDevicesWithAddIn)), DevicesWithAddIn = max(DevicesWithAddIn) by AddInProgId, AppName, AddInCLSID, IsSampled
| summarize arg_max(DevicesWithAddIn, PercentDevicesWithAddIn, IsSampled) by AddInProgId, AppName, AddInCLSID
| where PercentDevicesWithAddIn >= 5
| summarize 
    Apps = strcat_array(make_list(AppName), ","),
    PercentDevicesWithAddIn = max(PercentDevicesWithAddIn) 
    by AddInProgId, AddInCLSID, IsSampled
| extend AddInProgId = strcat(AddInProgId, case(IsSampled, " (Sampled)", ""))
| project progId=AddInProgId, clsid=AddInCLSID, text=strcat(AddInProgId, " (", Apps, ")")
| order by text asc
`;
