import React, { useEffect, useState } from "react";
import { DetailsList, IColumn, SelectionMode, Slider, Stack } from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import {
    customToolTipContentForScatterChart,
    getCellColor,
    getCellStroke,
    getTopNTenantsByField,
} from "pages/CohortAnalysis/CohortAnalysisUtils";
import ScatterChart from "components/CustomComponents/ScatterChart";
import {
    maxNumberOfOptionsToShow,
    startingNumberOfOptionsToShow,
    stepForSlider,
} from "pages/CohortAnalysis/CohortAnalysisConstants";
import { detailsListStackItemStyle, horizontalStackTokens } from "pages/common";

const CohortErrorDrilldown = ({
    dataItems,
    filters,
    chartData,
    chartCallbackFunction,
}) => {
    const _generateErrorColumns = () => {
        const errorColumns = [
            ["ErrorName", "Error Name", 250, null],
            ["Score", "Impact on cohort", 180, "alignRightHeader"],
            ["UserErrorCount", "# Users With Error", 120, "alignRightHeader"],
            ["PercentUsersWithError", "% Users With Error", 150, "alignRightHeader"],
            [
                "S2500AverageUser",
                "S2500 Avg% Users With Error",
                200,
                "alignRightHeader",
            ],
            [
                "PercentSessionsWithError",
                "% Sessions With Error",
                150,
                "alignRightHeader",
            ],
            [
                "S2500AverageSession",
                "S2500 Avg% Sessions With Error",
                180,
                "alignRightHeader",
            ],
        ];

        const columns: Array<IColumn> = DetailsListHelper.customBuildColumns(
            errorColumns,
            true,
            null,
            "Score",
            true,
        );

        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

        return columns;
    };

    const [items, setItems] = useState(dataItems);
    const [dataForChart, setDataForChart] = useState(chartData);
    const [errorNumberToShow, setErrorNumberToShow] = useState<number>(
        startingNumberOfOptionsToShow,
    );
    const [columnsErrorTable, setColumnsErrorTable] = useState(
        _generateErrorColumns(),
    );

    useEffect(() => {
        setColumnsErrorTable(_generateErrorColumns());
        setItems(getTopNTenantsByField(dataItems, "Score", errorNumberToShow));
        const data = getTopNTenantsByField(
            chartData,
            "Score",
            errorNumberToShow,
            false,
        );
        setDataForChart(
            data.sort((first, second) =>
                first.SessionErrorCount > second.SessionErrorCount ? 1 : -1,
            ),
        );
    }, [chartData, dataItems, errorNumberToShow]);

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columnsErrorTable, column, items);
        setItems(result.items);
        setColumnsErrorTable(result.columns);
    };

    const _onRenderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        switch (column.key) {
            case "UserErrorCount":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={0}
                    />
                );
            case "Score":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={2}
                    />
                );
            case "PercentUsersWithError":
            case "S2500AverageUser":
            case "PercentSessionsWithError":
            case "S2500AverageSession":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    return (
        <Stack tokens={horizontalStackTokens}>
            <Stack.Item>
                <Stack horizontal className="chartControlsGrid">
                    <Stack.Item className="chartControl-component-right-label">
                        Number of errors to show
                    </Stack.Item>
                    <Stack.Item className="chartControl-component-right">
                        <Slider
                            min={startingNumberOfOptionsToShow}
                            max={maxNumberOfOptionsToShow}
                            step={stepForSlider}
                            value={errorNumberToShow}
                            onChange={(value: number) => setErrorNumberToShow(value)}
                            showValue={true}
                            className="numberToDisplaySlider"
                        />
                    </Stack.Item>
                </Stack>
                <ScatterChart
                    chartData={dataForChart}
                    chartTitle={`ACE Error Deep Dive for cohort: ${filters["cohort"]} and App: ${filters["app"]}`}
                    XAxisDataKey="SessionErrorCount"
                    XAxisName="Session Error Count"
                    YAxisDataKey="ACE_OR"
                    YAxisName="Odds of ACE Errors happening compared to other errors (Odds Ratio)"
                    ZAxisDataKey="Score"
                    ZAxisName="Score"
                    currentlySelectedError={filters["errorname"]}
                    chartCallbackFunction={chartCallbackFunction}
                    getCellColor={getCellColor}
                    getCellStroke={getCellStroke}
                    toolTipFunction={customToolTipContentForScatterChart}
                />
            </Stack.Item>
            <Stack.Item>
                <DetailsList
                    items={items}
                    columns={columnsErrorTable}
                    selectionMode={SelectionMode.none}
                    compact
                    onRenderDetailsHeader={DetailsListHelper.renderDetailsHeader}
                    onRenderItemColumn={_onRenderItemColumn}
                    onColumnHeaderClick={_onColumnClick}
                    styles={detailsListStackItemStyle}
                    className="tenantTable"
                />
            </Stack.Item>
        </Stack>
    );
};

export default CohortErrorDrilldown;
