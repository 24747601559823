export const tenantAppsHealthAdvisories: string = `
    let queryName = "tenantAppsHealthAdvisories";
    let dt = "\${date}";
    let tenantId = "\${tenantId}";
    TenantAppHealthAdvisories
        | where Date == dt
        | where TenantId == tenantId
        | project OfficeServicingChannel, OfficeProduct, OfficeHealthMetricType, OfficeHealthAlertType, 
            OfficeHealthAlertSeverity, OfficeHealthAlertTrend, OfficeBuild, ComparedOfficeBuild, ReleaseVersion, ComparedReleaseVersion, MetricValue, 
            ComparedMetricValue, EventCount, CreatedEpochInSeconds, ImpactedDevices, AvailabilityDate, EndOfSupportDate    
        | order by CreatedEpochInSeconds desc`;

export const tenantAppAdvisoriesHistory: string = `
    let queryName = "tenantAppAdvisoriesHistory";
    let dt = "\${date}";
    let tenantId = "\${tenantId}";
    let channel = "\${channel}";
    let product = "\${product}";
    let metric = "\${metric}";
    let currentBuild = "\${currentBuild}";
    let comparedBuild = "\${comparedBuild}";
    TenantAppHealthAdvisoriesHistory
        | where Date == dt and TenantId == tenantId and OfficeServicingChannel == channel
            and OfficeProduct == product and MetricType == metric and Metric != 0
        | where OfficeBuild	== currentBuild or OfficeBuild == comparedBuild
        | order by CreationTime asc
        | project ReleaseVersion, OfficeBuild, MetricDate, Metric, EventCount
`;

export const tenantAppsHealthAddins: string = `
    let queryName = "tenantAppsHealthAddins";
    let dt = "\${date}";
    let tenantId = "\${tenantId}";
    TenantAppHealthAddins 
    | where Date == dt and TenantId == tenantId
    | project OfficeProduct, OfficeArchitecture, ReleaseVersion ,BuildVersion, OfficeServicingChannel, AddInId, AddinProgId, 
        AddinVersion, AddinProvider, AddinName, MonitoredDeviceCount, MonitoredSessionCount, AddInCrashRate, AddInLoadTime_GeoMean
    | order by OfficeProduct asc`;
