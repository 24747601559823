import { CohortListItemsType } from "./types";

export const getCohortMetadataTemplate = () => {
    return {
        cohortName: "",
        cohortType: "",
        status: "Gray",
    };
};

export const generateCohortGroups = (
    payload: CohortListItemsType[],
    isCollapsed: boolean,
) => {
    const Group = {};
    let index = 0;

    // Payload is already sorted
    payload.forEach((element) => {
        const cohortType: string = element.cohortType;

        if (!(cohortType in Group)) {
            Group[cohortType] = {
                name: cohortType,
                startIndex: index,
            };
        }
        Group[cohortType]["endIndex"] = index;

        index += 1;
    });

    return Object.keys(Group)
        .map((cohortType) => {
            const sIdx = Group[cohortType]["startIndex"];
            const eIdx = Group[cohortType]["endIndex"];

            return {
                key: cohortType,
                name: Group[cohortType]["name"],
                startIndex: sIdx,
                endIndex: eIdx,
                level: 0,
                count: eIdx - sIdx + 1,
                isCollapsed,
            };
        })
        .sort((a, b) => a.startIndex - b.startIndex);
};
