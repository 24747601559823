import { isTest } from "utils/WebConstants";

export const teamsMeetingTenantStatus: string = `
//TeamsTenantStatus
let dt = iif(isempty("\${date}"), GetCurrentTeamsDate(${isTest}), "\${date}");
let currentDate = GetCurrentTeamsDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
let MAULimit = 1000;
Teams_Scores
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and SubWorkload ==  app
| extend Score = Score_Score, CurrentStatus = Score_Color
| join kind = inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == toscalar(StrategicTenants|summarize max(Date))
        | distinct OmsTenantId, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project OmsTenantId, Name = Column1
    ) on OmsTenantId
| join kind = leftouter
    (Teams_Scores
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and SubWorkload ==  app 
        | project OmsTenantId, PrevMonthScore = round(Score_Score, 2), PreviousStatus = Score_Color
    ) on OmsTenantId
| where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus == "Red" or PreviousStatus == "DeepRed"
| join kind = leftouter 
    (Teams_PastStatus
        | where SubWorkload == app and Date == dt 
        | extend PastStatus = GetTeamsPastYearStatus(PastStatusSet, dt)
    ) on OmsTenantId
| join kind = leftouter(
    (Teams_Meetings_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId = tostring(TenantId), MAU = Meetings_MAU, TeamsMAU = Teams_MAU)
    | union 
    (Teams_Chat_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId, MAU = Usage_Chats_MAU, TeamsMAU = Usage_Teams_MAU)
    ) on $left.OmsTenantId == $right.TenantId
| where MAU > MAULimit
| project PastStatus, Date,
TenantId = OmsTenantId, SubWorkload, OrgName = Name,Tpid,
Composite_Score = round(Score_Score,2), Composite_MoMChange = MoM_Score,
CurrentStatus = Score_Color, PreviousStatus,
MAU_Score = MAU, TeamsMAU_Score = TeamsMAU,
UsageRate_Score, UsageRate_Score_Color = UsageRate_Color, UsageRate_MoMChange = MoM_UsageRate,
StreamEstablishedSuccessPercentage_Score, StreamEstablishedSuccessPercentage_Score_Color = StreamEstablishedSuccessPercentage_Color, StreamEstablishedSuccessPercentage_MoMChange = MoM_StreamEstablishedSuccessPercentage,
DropRatePercentage_Score, DropRatePercentage_Score_Color = DropRatePercentage_Color, DropRatePercentage_MoMChange = MoM_DropRatePercentage,
AudioPSRPercentage_Score, AudioPSRPercentage_Score_Color = AudioPSRPercentage_Color, AudioPSRPercentage_MoMChange = MoM_AudioPSRPercentage,
PoorCPURate_Score, PoorCPURate_Score_Color = PoorCPURate_Color, PoorCPURate_MoMChange = MoM_PoorCPURate,
PoorMemoryRate_Score, PoorMemoryRate_Score_Color = PoorMemoryRate_Color, PoorMemoryRate_MoMChange = MoM_PoorMemoryRate,
PoorFrequencyRate_Score, PoorFrequencyRate_Score_Color = PoorFrequencyRate_Color, PoorFrequencyRate_MoMChange = MoM_PoorFrequencyRate,
FeedbackPoorPercentage_Score, FeedbackPoorPercentage_Score_Color = FeedbackPoorPercentage_Color, FeedbackPoorPercentage_MoMChange = MoM_FeedbackPoorPercentage,
NPS_Score, NPS_Score_Color = NPS_Color, NPS_MoMChange = MoM_NPS
| sort by Composite_Score, MAU_Score desc
`;
