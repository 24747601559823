export const macTenantEnvironmentData: string = `
// macTenantEnvironmentData (To display all the charts [Currency, License Distribution])
//macTenantEnvironment data filtered on id.
let filteredData = HealthyTenantScore_MacMiscellaneousMetrics
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" and todatetime(Date) <= todatetime("\${date}");
// Filter non-currency and non-Frequency Metrics
let nonCurrencyFreqMetrics = filteredData
| where Metric !has "Currency" and Metric !has "OpenDocument";
// Filter currency Metrics( as currency Metrics are populated with something other and Dev-In Service)
let currencyMetrics = filteredData
| where Metric == "Currency"
| summarize arg_max(Value, *) by Date
| extend Value = Value * 100;
// userFrequency Metrics are nothing but openDocument metrics
let userFreqMetricsInPerc = filteredData
| where Metric == "OpenDocument"
| extend Value = round(todouble(100 * Value/Total),2);
// join currency metrics with overview table to fetch the Dev IN Service.
let updatedCurrency = filteredData|where Name!= "Build Currency"|extend Tpid = tostring(Tpid)|distinct Date, OmsTenantId, Tpid, Application| join kind = inner(HealthyTenantScore_MacAppView| project OmsTenantId, tostring(Tpid), App_Currency = Currency_SignalGroup.App_Currency, Engaged_Users = Usage_SignalGroup.Engaged_Users, Application = SubWorkload, Date = ProcessDate) on OmsTenantId, Tpid, Application, Date
| project Date, OmsTenantId, Tpid, Application, Metric = "Currency", Name = "Build Currency", Value = round(todouble(App_Currency), 2), Total = tolong(Engaged_Users)
| union (filteredData|where Name!= "Build Currency"|extend Tpid = tostring(Tpid)|distinct Date, OmsTenantId, Tpid, Application|join kind = inner(HealthyTenantScore_MacAppView| project OmsTenantId, tostring(Tpid), OS_Currency = Currency_SignalGroup.OS_Currency, Engaged_Users = Usage_SignalGroup.Engaged_Users, Application = SubWorkload, Date = ProcessDate) on OmsTenantId, Tpid, Application, Date
| project Date, OmsTenantId, Tpid, Application, Metric = "Currency", Name = "OS Currency", Value = round(todouble(OS_Currency), 2), Total = tolong(Engaged_Users))
| extend Tpid = toint(Tpid);
let result = nonCurrencyFreqMetrics
| union currencyMetrics
| union updatedCurrency
| union userFreqMetricsInPerc;
// Filter the data for Location chart, as we have to show location chart only for one month.
let locationResults = result
| where Metric == "Location" and todatetime(Date) == todatetime("\${date}");
// union the location data and sorted non location data.
result
| where Metric != "Location"
| order by Date asc, Metric, Application, Value
| union locationResults
`;
