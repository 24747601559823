import React, { useContext, useMemo } from "react";
import { Link, Shimmer } from "@fluentui/react";
import PerformanceContext from "pages/Performance/PerformanceContext";
import { InsightsRoutes } from "utils/Links";

export const AddInBootCost = ({ cost }: { cost: number }) => {
    const { tenantId, level, startDate, endDate } = useContext(PerformanceContext);
    const timespan = useMemo(() => {
        const millisecondsInADay = 1000 * 60 * 60 * 24;
        const timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime();
        return 1 + timeDiff / millisecondsInADay;
    }, [startDate, endDate]);

    const durationAmount = cost ? `${cost} s` : "not available";
    const isDataLoaded = cost !== undefined;

    return level !== "TenantId" ? null : (
        <Shimmer isDataLoaded={isDataLoaded} className="addInCostMessage">
            <Link
                className="addInCostMessage"
                href={`${InsightsRoutes.AddInPerformance.path}?id=${tenantId}&timespan=${timespan}&date=${endDate}`}
            >
                {`Typical cost of add-in load at boot: ${durationAmount}`}
            </Link>
        </Shimmer>
    );
};
