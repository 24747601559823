import { MarginType } from "components/CustomComponents/types";

export const barWidth = 50;

export const barHeight = 500;

export const areaHeight = barHeight;

export const areaWidth = 110;

export const margin: MarginType = { top: 0, right: 0, bottom: 0, left: 0 };

export const yMax: number = barHeight - margin.top - margin.bottom;

export const xMaxBar: number = barWidth - margin.left - margin.right;

export const xMaxArea: number = areaWidth - margin.left - margin.right;

export const scrollForInsightsTopHeight: number = 100;
