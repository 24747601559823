export const ashaConsumerGrowthScorecardRetentionPillarScoreWeekly: string = `
    database("Asha").ASHAConsumer_GrowthScorecard_Retention_OfficeWeb_Weekly_PillarCountsTotal(datetime("\${date}"), "\${application}")
`;

export const ashaConsumerGrowthScorecardRetentionVetoScoreWeekly: string = `
    database("Asha").ASHAConsumer_GrowthScorecard_Retention_OfficeWeb_Weekly_VetoCountsTotal(datetime("\${date}"), "\${application}")
`;

export const ashaConsumerGrowthScorecardRetentionErrorScoreWeekly: string = `
    database("Asha").ASHAConsumer_GrowthScorecard_Retention_OfficeWeb_Weekly_ErrorCountsTotal(datetime("\${date}"), "\${application}")
`;
