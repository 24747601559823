export const macOfficeTenantScore = `
//macOfficeTenantScore (To display Office Tenant component, which has app contributions)
let level = "\${level}";
let app = "\${crossAppRankOn}";
let dt = "\${date}";
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_MacCrossAppView 
| where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}"
and ProcessDate == dt and SubWorkload == app 
|join kind = leftouter ( HealthyTenantScore_MacCrossAppView | where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}"
and ProcessDate == prev and SubWorkload == app )on \${levelColumnName}
| extend MoMChange = round(Score - Score1,2)
| project Score=round(Score,2), Color = GetColor(Color), Score_MoMChange = round(MoMChange,2), PastStatus,HealthScoreContribution=tostring(HealthScoreContribution)`;
