import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import {
    IDetailsHeaderProps,
    IRenderFunction,
    Sticky,
    StickyPositionType,
    TooltipHost,
    DetailsRow,
    IDetailsRowProps,
    IDetailsRowStyles,
    IColumn,
    getTheme,
} from "@fluentui/react";
import {
    getCrossAppBorderStyles,
    tenantTableStyle,
} from "components/CustomComponents/DetailsListHelper/style";
import {
    ColumnType,
    OverviewTableColumn,
} from "components/OverviewComponents/types";
import { logException, logOnHoverTenantNameView } from "utils/AppInsightsHelper";
import AuthenticationUtil from "utils/AuthenticationUtil";
import {
    STATUS,
    OrderedStatusList,
    crossAppColourSortIndex,
    lastGroupedSignals,
} from "utils/Constants";
import { shouldTranslate } from "utils/Helpers";

const theme = getTheme();

const renderDetailsHeader = (
    props: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>,
): JSX.Element => {
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip: (tooltipHostProps) => (
                    <TooltipHost {...tooltipHostProps} />
                ),
            })}
        </Sticky>
    );
};

const ExpandableDetailsRow = (props) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
        <div>
            <DetailsRow onClick={() => setIsExpanded(!isExpanded)} {...props} />
            {isExpanded && props.item.EntryDate && (
                <div className="tenantEngagementNoteInOverview">
                    {props.item.EntryDate} : {props.item.Notes}
                </div>
            )}
        </div>
    );
};

const renderExpandableRow = (props: IDetailsRowProps): JSX.Element => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
    }

    customStyles.cell = {
        // paddingTop: "6px",
        paddingBottom: "0px",
        selectors: {
            [`& .ms-List-cell`]: {
                maxHeight: "0px",
            },
        },
    };
    return <ExpandableDetailsRow {...props} styles={customStyles} />;
};

const DeltaValue = (props) => {
    let color = null;
    const negate = props.negate ? props.negate : false;
    const value = props.value;
    const showDelta = !!Number.isFinite(value);
    let arrowSpan = <span />;
    const precision = 0.01;
    const isZero = value > -precision && value < precision;
    if (showDelta && !isZero) {
        if (value >= precision) {
            color = negate ? "negativeDelta" : "positiveDelta";
            arrowSpan = (
                <span className={[color, "deltaIcon"].join(" ")}>&#9650; </span>
            );
        } else if (value <= -precision) {
            color = negate ? "positiveDelta" : "negativeDelta";
            arrowSpan = (
                <span className={[color, "deltaIcon"].join(" ")}>&#9660; </span>
            );
        }
        return (
            <div className="deltaColumn">
                {arrowSpan}
                <span className="alignRight">
                    {value.toFixed(2) + props.deltaPostfix}{" "}
                </span>
            </div>
        );
    }
    return <div className="alignRight">-</div>;
};

const StatusBar = (props) => {
    return (
        <div className="splitCell">
            <div className="splitCellColumn">{props.children}</div>
            <span
                style={{
                    width: "5px",
                    height: "30px",
                    backgroundColor: props.color,
                    borderRadius: "4px",
                }}
            />
        </div>
    );
};

export const isLastGroupedSignal = (
    selectedCrossApp: string,
    signal: string,
): boolean => {
    return (
        selectedCrossApp in lastGroupedSignals &&
        signal.startsWith(lastGroupedSignals[selectedCrossApp])
    );
};

const ColorBar = (props: {
    selectedCrossApp: string;
    signal: string;
    color: string;
}): JSX.Element => {
    const borderStyles = getCrossAppBorderStyles();
    if (isLastGroupedSignal(props.selectedCrossApp, props.signal)) {
        borderStyles["borderRight"] = "1px solid #c2c2c2";
        borderStyles["paddingLeft"] = 0;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                ...borderStyles,
            }}
        >
            {props.color !== undefined && props.color !== "" ? (
                <span
                    data-testid="colorBar"
                    style={{
                        width: "5px",
                        height: "30px",
                        backgroundColor: STATUS[props.color]["color"],
                        borderRadius: "4px",
                    }}
                />
            ) : (
                <span className="colorBar"></span>
            )}
        </div>
    );
};

const NumericalValue = (props) => {
    let value = props.value;
    const defaultValue = props.defaultValue ?? "";
    if (value && typeof value === "number") {
        value =
            value.toLocaleString("en-US", {
                minimumFractionDigits: value ? props.decimalPrecision ?? 0 : 0,
                maximumFractionDigits: value ? props.decimalPrecision ?? 0 : 0,
            }) + props.postfix ?? "";
    }
    const className = `alignRight ${props.class}`;
    return <div className={className}>{value ?? defaultValue}</div>;
};

/**
 * @param value - it takes value or "NA" string e.g. 1200000, 450000, "NA"
 * @param preFix - optional string to add to start of value
 *
 * input: 1200000 => output: 1.2M
 *
 * input: "NA" => output: "NA"
 * @returns JSX that has value in compact/abbreviated form e.g. 1.2M, 450K, "NA"
 */
const NumericalAbbreviatedValue = ({
    value,
    preFix = undefined,
    postFix = undefined,
    additionalClassName = undefined,
    decimalPrecision = 2,
}) => {
    let abbrValue;
    if (value && typeof value === "number") {
        abbrValue = Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: decimalPrecision,
        }).format(value);
    } else abbrValue = value;

    if (preFix) {
        abbrValue = preFix + abbrValue;
    }

    if (postFix) {
        abbrValue = abbrValue + postFix;
    }

    const className =
        "alignRight" + (additionalClassName ? ` ${additionalClassName}` : "");
    return <div className={className}>{abbrValue}</div>;
};

const TextValue = (props) => {
    const title = props.title ? props.title : props.value;
    const value = props.value;
    return (
        <span title={title} className={`textColumn ${props.class}`}>
            {value}
        </span>
    );
};

const AlignRightTextValue = (props) => {
    const value = props.value;
    return (
        <span title={value} className={`alignRightTextColumn ${props.class}`}>
            {value}
        </span>
    );
};

const AlignLeftTextValue = (props) => {
    const value = props.value;
    return (
        <span title={value} className={`alignLeftTextColumn ${props.class}`}>
            {value}
        </span>
    );
};

const LinkedTextValue = (props) => {
    const value = props.value;
    const [translatedName, setTranslatedName] = useState(null);
    return (
        <span
            className={`textColumn ${props.class}`}
            onMouseOver={() => {
                onHover(setTranslatedName, value);
            }}
            title={translatedName}
        >
            <Link to={props.link} target="_blank">
                {value}
            </Link>
        </span>
    );
};

const onHover = async (setTranslatedName, tenantName) => {
    if (shouldTranslate(tenantName)) {
        try {
            const response =
                await AuthenticationUtil.TranslateTenantName(tenantName);
            setTranslatedName(response[0]["translations"][0]["text"]);
            logOnHoverTenantNameView({
                page: document.title,
                tenantName: tenantName,
            });
        } catch (error) {
            logException(
                new Error("TenantTranslatedNameTooltipError"),
                {
                    message: "Tenant Name Translation Failed.",
                    page: document.title,
                    tenantName: tenantName,
                },
                error,
            );
        }
    } else {
        setTranslatedName(tenantName);
    }
};

const provideSortValue = <T extends unknown>(item: T, key, columnType) => {
    if (columnType === ColumnType.APPCOLOR) {
        return crossAppColourSortIndex - Number(OrderedStatusList[item[key]]);
    }

    if (
        typeof item[key] === "boolean" ||
        item[key] === undefined ||
        item[key] === null
    ) {
        if (item[key] === true) {
            return 3;
        } else if (item[key] === false) {
            return 2;
        } else if (item[key] === undefined) {
            return 1;
        } else if (item[key] === null) {
            return 0;
        }
    }
    return item[key];
};

function copyAndSort<T>(
    items: T[],
    columnKey: string,
    colType: ColumnType,
    isSortedDescending?: boolean,
): T[] {
    const key = columnKey as keyof T;
    return items
        .slice(0)
        .sort((a: T, b: T) =>
            (
                isSortedDescending
                    ? provideSortValue(a, key, colType) <
                      provideSortValue(b, key, colType)
                    : provideSortValue(a, key, colType) >
                      provideSortValue(b, key, colType)
            )
                ? 1
                : -1,
        );
}

function sortItems<T>(
    columns: IColumn[],
    column: OverviewTableColumn | IColumn,
    items: T[],
    preserveExistingOrder: boolean = false,
) {
    const colType = "columnType" in column ? column.columnType : ColumnType.REGULAR;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key,
    )[0];

    newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = preserveExistingOrder
                ? currColumn.isSortedDescending
                : !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });

    const newItems = copyAndSort(
        items,
        currColumn.fieldName!,
        colType,
        currColumn.isSortedDescending,
    );

    return {
        columns: newColumns,
        items: newItems,
    };
}

const MIN_COLUMN_WIDTH = 100; // this is the global min width

function customBuildColumns(
    displayColumns: any[],
    canResizeColumns?: boolean,
    onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
    sortedColumnKey?: string,
    isSortedDescending?: boolean,
    // groupedColumnKey?: string,
    isMultiline?: boolean,
) {
    const columns: OverviewTableColumn[] = [];

    if (displayColumns && displayColumns.length) {
        displayColumns.forEach((row) => {
            columns.push({
                key: row[0],
                name: row[1],
                fieldName: row[0],
                minWidth: row[2] === null ? MIN_COLUMN_WIDTH : row[2],
                maxWidth: row[2] === null ? 300 : row[2],
                headerClassName: row[3],
                // isCollapsable: !!columns.length,
                // isCollapsible: !!columns.length,
                isMultiline: isMultiline === undefined ? false : isMultiline,
                isSorted: sortedColumnKey === row[0],
                isSortedDescending: !!isSortedDescending,
                // isRowHeader: false,
                // columnActionsMode: ColumnActionsMode.clickable,
                isResizable: canResizeColumns,
                onColumnClick,
                // isGrouped: groupedColumnKey === propName,
                ariaLabel: row[1],
                columnType: row.length > 4 ? row[4] : ColumnType.REGULAR,
            });
        });
    }

    return columns;
}

const getGroupName = (
    groupings: {},
    column: OverviewTableColumn,
    level: number,
): string => {
    const searchKey = column.key;
    if (searchKey in groupings) return groupings[searchKey][level];

    const mapKeys = Object.keys(groupings);
    for (let i = 0; i < mapKeys.length; i++) {
        const key = mapKeys[i];
        const regexp = new RegExp(key);
        if (regexp.test(searchKey)) return groupings[key][level];
    }

    return "None";
};

const renderGroupedDetailsHeader = (
    props: IDetailsHeaderProps,
    groupings: {},
    isCollapsible: boolean,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>,
    subGroupStyling?: {},
): JSX.Element => {
    type ColumnGroup = { name: string; width: number; key: number };
    let groups: ColumnGroup[] = [];
    let subGroups: ColumnGroup[] = [];
    let totalWidth = 0;
    let currentGroup: ColumnGroup = { name: "None", width: 0, key: 0 };
    let currentSubGroup: ColumnGroup = {
        name: "None",
        width: 0,
        key: 0,
    };

    props.columns.forEach((column) => {
        const gName = getGroupName(groupings, column as OverviewTableColumn, 0);
        const sgName = getGroupName(groupings, column as OverviewTableColumn, 1);

        if (gName !== currentGroup.name) {
            totalWidth += currentGroup.width;
            groups.push(currentGroup);
            currentGroup = {
                name: gName,
                width: column.calculatedWidth + 20,
                key: groups.length,
            };
        } else {
            currentGroup.width += column.calculatedWidth + 20;
        }

        if (sgName !== currentSubGroup.name) {
            subGroups.push(currentSubGroup);
            currentSubGroup = {
                name: sgName,
                width: column.calculatedWidth + 20,
                key: subGroups.length,
            };
        } else {
            currentSubGroup.width += column.calculatedWidth + 20;
        }
    });
    totalWidth += currentGroup.width;
    groups.push(currentGroup);
    subGroups.push(currentSubGroup);

    if (groups.length === 1 && groups[0]["name"] === "None") groups = [];
    if (subGroups.length === 1 && subGroups[0]["name"] === "None") subGroups = [];

    let tableBottomStyle: {} = {
        paddingTop: 3,
    };
    if (groups.length === 0) tableBottomStyle = {};

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            <div
                style={{
                    minWidth: totalWidth,
                    zIndex: 1,
                    backgroundColor: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    display: "flex",
                    paddingLeft: isCollapsible ? 40 : 0,
                }}
                key={"listGrouping"}
            >
                {groups.map((group) => {
                    if (group.name === "None") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    minWidth: group.width,
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                                key={group.key}
                            >
                                {" "}
                                <div
                                    style={{
                                        borderBottom: "2px solid white",
                                        minWidth: group.width - 6,
                                    }}
                                />
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                minWidth: group.width,
                                display: "flex",
                                textAlign: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                            key={group.key}
                        >
                            {" "}
                            {group.name}
                            <div
                                style={{
                                    borderBottom: "2px solid black",
                                    minWidth: group.width - 6,
                                    display: "flex",
                                    paddingTop: 7,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                style={{
                    minWidth: totalWidth,
                    zIndex: 1,
                    backgroundColor: "white",
                    fontSize: "14px",
                    display: "flex",
                }}
                key={"listSubGrouping"}
            >
                {subGroups.map((subGroup) => {
                    if (subGroup.name === "None") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    minWidth: subGroup.width,
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                                key={subGroup.key}
                            >
                                {" "}
                                <div
                                    style={{
                                        minWidth: subGroup.width - 6,
                                    }}
                                />
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                minWidth: subGroup.width,
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                paddingTop: 7,
                                alignItems: "center",
                            }}
                            key={subGroup.key}
                        >
                            {" "}
                            {subGroup.name}
                            <div
                                style={{
                                    ...subGroupStyling,
                                    minWidth: subGroup.width - 6,
                                    display: "flex",
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip: (tooltipHostProps) => (
                    <TooltipHost {...tooltipHostProps} />
                ),
                styles: {
                    root: tableBottomStyle,
                },
            })}
        </Sticky>
    );
};

export {
    renderExpandableRow,
    renderDetailsHeader,
    renderGroupedDetailsHeader,
    DeltaValue,
    NumericalValue,
    NumericalAbbreviatedValue,
    sortItems,
    tenantTableStyle,
    TextValue,
    customBuildColumns,
    StatusBar,
    LinkedTextValue,
    ColorBar,
    AlignRightTextValue,
    AlignLeftTextValue,
    provideSortValue,
};
