export const tenantAllAddInCostHistory = `
// tenantAllAddInCostHistory
let data = Summary_AllAddInLoadDurationByTenantDailyV3
| where TenantId == '\${id}' and AppName in ("Word", "Excel", "PowerPoint", "Outlook", "OneNote");
let beginDate = max_of(datetime(\${date})-\${timespan}d+1d, toscalar(data | summarize min(SQLizerStreamDateId))+6d);
let endDate = min_of(datetime(\${date}), toscalar(data | summarize max(SQLizerStreamDateId)));
range Date from beginDate to endDate step 1d
| extend DateRange=range(Date-6d, Date, 1d)
| mv-expand DateRange to typeof(datetime)
| join kind=inner data on $left.DateRange==$right.SQLizerStreamDateId
| summarize 
    TypicalLoadDurationPerSession = round(exp(sum(SessionCount*log(TypicalLoadDurationPerSession))/sum(SessionCount)),3), //geometric mean
    DeviceCount = round(avg(DeviceCount)),
    SessionCount = round(avg(SessionCount)),
    DeviceWithAddInCount = round(avg(DevicesWithAnyAddInCount))
    by AppName, Date
| order by AppName asc, Date asc
`;
