import React from "react";
import "./style.css";

const CustomLoader = ({
    text = "Loading",
    className = "loader",
}: {
    text?: string;
    className?: string;
}): JSX.Element => {
    return (
        <div>
            {text !== "" && <div className="loadingTextFormat">{text}</div>}
            <div className={className} />
        </div>
    );
};

export default CustomLoader;
