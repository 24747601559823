import React from "react";
import { curveMonotoneX } from "@visx/curve";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { CommonFunnelArrow } from "components/CommonFunnelComponents/CommonFunnelArrow/CommonFunnelArrow";
import { CommonFunnelAreaChartProps } from "components/CustomComponents/FunnelAreaChart/types";

export const FunnelAreaChart = ({
    chartFormattingData,
    data,
    dropOffValue,
    xScale,
    yScale,
    xAccessor,
    yAccessor,
}: CommonFunnelAreaChartProps) => {
    return (
        <svg width={chartFormattingData.width} height={chartFormattingData.height}>
            <Group>
                <AreaClosed
                    data={data}
                    x={(d) => xScale(xAccessor(d)) ?? 0}
                    y={(d) => yScale(yAccessor(d)) ?? 0}
                    yScale={yScale}
                    fill="#e9e9e9"
                    curve={curveMonotoneX}
                />
                <CommonFunnelArrow label={dropOffValue} />
            </Group>
        </svg>
    );
};
