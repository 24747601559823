export const unhealthyTenantTrend: string = `
// UnhealthyTenantTrend
// set query_results_cache_max_age = time(12h);
let currentMonth = todatetime(GetCurrentDateCrossPlatform());   
range Step from 0 to 11 step 1 
| partition hint.strategy=native by Step
    {
    StagingGetUnhealthyTenantCounts(datetime_add("Month", -Step, currentMonth))
    }
| where Application=="Base"
| project-away Application
| sort by Date asc
`;
