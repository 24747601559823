export enum FunnelStage {
    FirstSessions = "FirstSessions",
    Unabandoned = "Unabandoned",
    CommandingActions = "CommandingActions",
    EditDocument = "EditDocument",
    Collab = "Collab",
    OtherRetentionDrivers = "OtherRetentionDrivers",
    OtherRetentionDriversHittingThreshold = "OtherRetentionDriversHittingThreshold",
}

export enum FunnelDroppedStage {
    Default = "Default",
    FirstSessionsSessionsDropped = "FirstSessions_SessionsDropped",
    UnabandonedSessionsDropped = "Unabandoned_SessionsDropped",
    CommandingActionsSessionsDropped = "CommandingActions_SessionsDropped",
    EditDocumentSessionsDropped = "EditDocument_SessionsDropped",
    OtherRetentionDriversSessionsDropped = "OtherRetentionDrivers_SessionsDropped",
}

export enum FunnelDropOff {
    Default = "Default",
    FirstSessionsToUnabandoned = "FirstSessionsToUnabandoned",
    UnabandonedToCommandingActions = "UnabandonedToCommandingActions",
    CommandingActionsToEditDocument = "CommandingActionsToEditDocument",
    EditDocumentToCollab = "EditDocumentToCollab",
    FirstSessionsToCommandingActions = "FirstSessionsToCommandingActions",
    EditDocumentToOtherRetentionDrivers = "EditDocumentToOtherRetentionDrivers",
    OtherRetentionDriversToOtherRetentionDriversHittingThreshold = "OtherRetentionDriversToOtherRetentionDriversHittingThreshold",
}

export const FunnelStageNames = {
    FirstSessions: "New Users started a web session",
    Unabandoned: "Users did not abandon before boot was done",
    CommandingActions: "Users clicked on any commanding",
    EditDocument: "Users edited document",
    Collab: "Users kept editor or designer suggestion or did collab",
    OtherRetentionDrivers: "Users did a retention driver other than edit",
    OtherRetentionDriversHittingThreshold:
        "Users did a retention driver at the threshold level",
    CollabForPPT: "Users kept Designer suggestion or did collab",
};

export const AppSpecificDropOffNames = {
    Word: {
        FirstSessionsToUnabandoned:
            "for sessions that dropped off in the FirstSessions stage compared to sessions that made it to the Unabandoned stage",
        UnabandonedToCommandingActions:
            "for sessions that dropped off in the Unabandoned stage compared to sessions that made it to the CommandingActions stage",
        CommandingActionsToEditDocument:
            "for sessions that dropped off in the CommandingActions stage compared to sessions that made it to the EditDocument stage",
        EditDocumentToCollab:
            "for sessions that dropped off in the EditDocument stage compared to sessions that made it to the Collab stage",
    },
    Excel: {
        FirstSessionsToCommandingActions:
            "for sessions that dropped off in the FirstSessions stage compared to sessions that made it to the CommandingActions stage",
        CommandingActionsToEditDocument:
            "for sessions that dropped off in the CommandingActions stage compared to sessions that made it to the EditDocument stage",
        EditDocumentToOtherRetentionDrivers:
            "for sessions that dropped off in the EditDocument stage compared to sessions that made it to the OtherRetentionDrivers stage",
        OtherRetentionDriversToOtherRetentionDriversHittingThreshold:
            "for sessions that dropped off in the OtherRetentionDrivers stage compared to sessions that made it to the OtherRetentionDriversHittingThreshold stage",
    },
    PowerPoint: {
        FirstSessionsToCommandingActions:
            "for sessions that dropped off in the FirstSessions stage compared to sessions that made it to the CommandingActions stage",
        CommandingActionsToEditDocument:
            "for sessions that dropped off in the CommandingActions stage compared to sessions that made it to the EditDocument stage",
        EditDocumentToCollab:
            "for sessions that dropped off in the EditDocument stage compared to sessions that made it to the Collab stage",
    },
};

export const AdditionalFunnelFilters = {
    AppMode: "appMode",
    Browser: "browser",
    Country: "country",
    Datacenter: "datacenter",
    OS: "os",
    ReferrerPlatform: "referrerPlatform",
};

export const DocTypeColors_Word = {
    "Create From Blank": "#103F91",
    "Create From Template": "#185ABD",
    "Open Own Doc": "#2B7CD3",
    "Open Shared Doc": "#41A5EE",
};

export const DocTypeColors_Excel = {
    "Create From Blank": "#185C37",
    "Create From Template": "#107C41",
    "Open Own Doc": "#21A366",
    "Open Shared Doc": "#33C481",
};

export const DocTypeColors_PowerPoint = {
    "Create From Blank": "#D35230",
    "Create From Template": "#ED6C47",
    "Open Own Doc": "#FF8F6B",
    "Open Shared Doc": "#FFAF97",
};

export const ShortenedFunnelStageNames = {
    FirstSessions: "All Sessions",
    Unabandoned: "Boot",
    CommandingActions: "Commanding Actions",
    EditDocument: "Edit",
    Collab: "Collab",
    OtherRetentionDrivers: "Other Retention Drivers",
    OtherRetentionDriversHittingThreshold: "Threshold Retention Drivers",
    CollabForPPT: "Collab or Designer",
};

export const ShortenedFunnelStageDropOffNames = {
    FirstSessions: "All Sessions",
    Unabandoned: "Abandoned Before Boot",
    CommandingActions: "No Commanding Action",
    EditDocument: "No Editing",
    Collab: "No Collab",
    OtherRetentionDrivers: "No HVA/Retention Drivers",
    OtherRetentionDriversHittingThreshold: "No Threshold Retention Drivers",
    CollabForPPT: "No Collab or Designer",
};

export enum FunnelReferralSources {
    All = "All",
    HarmonyAll = "Harmony All",
    HarmonyAppStart = "Harmony App Start Pages",
    HarmonyHome = "Harmony Home Page",
    HarmonyOther = "Harmony Other",
    OneDrive = "OneDrive",
    Other = "Other",
}
