export const tenantAddInVersionHistory = `
// tenantAddInVersionHistory
let data = Summary_AddInsByTenantForkDailyV3
| where Fork == "All"
| where AddInVersion != "All"
| where TenantId == '\${id}' and AppName in ('Word', 'Excel', 'PowerPoint', 'Outlook', 'OneNote')
| extend AddInProgId = case(isempty(AddInCLSID), "Private add-ins", coalesce(AddInProgId, "Unknown Add-In(s)"))
| extend AddInProgId = strcat(AddInProgId, case(IsSampled, " (Sampled)", ""))
| where AddInCLSID =~ '\${clsid}' and AddInProgId =~ '\${progid}'
| where (DailyDeviceCount > 100 and DevicesWithAddIn > 20)
| extend AddInVersion = coalesce(AddInVersion, "Version not available")
;
let beginDate = max_of(datetime(\${date})-\${timespan}d+1d, toscalar(data | summarize min(SQLizerStreamDateId))+6d);
let endDate = min_of(datetime(\${date}), toscalar(data | summarize max(SQLizerStreamDateId)));
range Date from beginDate to endDate step 1d
| extend DateRange = range(Date-6d, Date, 1d)
| mv-expand DateRange to typeof(datetime)
| join kind=inner data on $left.DateRange == $right.SQLizerStreamDateId
| summarize 
	TypicalLoadDurationPerSession = round(exp(sum(SessionCount*log(TypicalLoadDurationPerSession))/sum(SessionCount)), 3), 
    DevicesWithAddIn = round(avg(DevicesWithAddIn)),
    SessionCount = round(avg(SessionCount)),
    PercentOfDevices = strcat(round(100.0*avg(DevicesWithAddIn)/avg(DailyDeviceCount), 1), '%')
    by AppName, Date, AddInVersion, IsSampled
| order by AppName asc, Date asc, AddInVersion asc
`;
