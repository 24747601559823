export const crossplatformDateFilters: string = `
//crossplatformDateFilters
let queryName = "crossplatformDateFilters";
let currDate = GetCurrentDateCrossPlatform();
CrossPlatform
    | where Application=="Base"
    | distinct Date
    | where todatetime(Date) <= todatetime(currDate)
    | project Date = tostring(Date)
    | order by Date desc
    | top 18 by Date`;
