import { basicMetadata } from "queries/PerfDrillDown/Win32/basicMetadata";
import { perfChartQuery } from "queries/PerfDrillDown/Win32/perfChartQuery";
import { perfRankingQuery } from "queries/PerfDrillDown/Win32/perfRankingQuery";
import { perfInsightsQuery } from "queries/PerfDrillDown/Win32/perfInsightsQuery";
import { perfInsightsChartQuery } from "queries/PerfDrillDown/Win32/perfInsightsChartQuery";
import { win32PerfDatesQuery } from "queries/PerfDrillDown/Win32/win32PerfDatesQuery";

const perfDrillDownQueries = {
    basicMetadata,
    perfChartQuery,
    perfRankingQuery,
    perfInsightsChartQuery,
    perfInsightsQuery,
    win32PerfDatesQuery,
};

export default perfDrillDownQueries;
