export const macCompositeScore: string = `
//MacCompositeScore
let level = "\${level}";
let app = "\${application}";
let currMonthdate = "\${date}";
let cohort = "\${cohort}";
let Mau = \${mauCountThreshold};
let prev = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let Overview = materialize(HealthyTenantScore_MacAppView
    | where SubWorkload == app
    | where Color > 1
    | where MAU >= Mau
    | where ProcessDate == currMonthdate or ProcessDate == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | join kind=inner
    (StrategicTenants
        | where Date == toscalar(StrategicTenants|summarize max(Date))
        | where iff(cohort == "Other", IsOther, Cohorts has cohort)
        | distinct \${levelColumnName} ,iif(level == "Tpid", OrgName, TenantName)
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
    | extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,
    App_Currency = Currency_SignalGroup.App_Currency, App_Currency_Color = Currency_SignalGroup.App_Currency_Color,
    CH_Rate = Reliability_SignalGroup.CH_Rate, CH_Color = Reliability_SignalGroup.CH_Color,
    FQ_Rate = Reliability_SignalGroup.FQ_Rate, FQ_Color = Reliability_SignalGroup.FQ_Color,
    PerfP95_Score = Performance_SignalGroup.Perf_P95, Perf_Color = Performance_SignalGroup.Perf_Color,
    PerfBoot_Score = Performance_SignalGroup.Boot_Threshold,PerfBoot_Color = Performance_SignalGroup.Boot_Threshold_Color, NPS = Feedback_SignalGroup.NPS, NPS_Color = Feedback_SignalGroup.NPS_Color,
    Engaged_Users_Pct = Usage_SignalGroup.Engaged_Users,Engaged_Users_Pct_Color = Usage_SignalGroup.Engaged_Users_Color
    | project-rename Application = SubWorkload
    | project \${levelColumnName}, Score, Color, MAU , OS_Currency, OS_Currency_Color, App_Currency, App_Currency_Color,
Date = ProcessDate, Application, Composite_Score = Score, CH_Color, FQ_Color, Perf_Color,PerfBoot_Color, NPS_Color, CH_Rate, FQ_Rate, PerfP95_Score,
PerfBoot_Score, NPS, PastStatus, Engaged_Users_Pct, Engaged_Users_Pct_Color, Name);
Overview
| where Date == currMonthdate
| join kind=leftouter (
    Overview
    | where Date == prev and Application == app
) on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, Application, OrgName = Name,
    Composite_Score = round(Score, 2),
    Composite_MoMChange = round(Score-Score1,2),
    MAU_Score = MAU, EngagedUsers_Score = iff(isnotnull(Engaged_Users_Pct), round((100*todouble(Engaged_Users_Pct))/todouble(MAU),2), 0.0), EngagedUsers_Score_Color = GetColor(Engaged_Users_Pct_Color),
    PerfP95_Score, PerfP95_Score_Color = GetColor(Perf_Color),
    PerfBoot_Score = round(todouble(PerfBoot_Score), 2), PerfBoot_Score_Color = GetColor(PerfBoot_Color),
    CH_Score = iff(isnotnull(CH_Rate), CH_Rate, 0), CH_Score_Color = GetColor(CH_Color), FQ_Score = iff(isnotnull(FQ_Rate), FQ_Rate, 0), FQ_Score_Color = GetColor(FQ_Color),
    NPS_Score = iff(isnotnull(NPS), NPS, 0), NPS_Score_Color = GetColor(iff(isnotnull(NPS_Color), NPS_Color, 0)),
    OS_Currency_Score = iff(isnotnull(OS_Currency), OS_Currency, 0) , OS_Currency_Score_Color = GetColor(OS_Currency_Color), 
    App_Currency_Score = iff(isnotnull(App_Currency), App_Currency, 0), App_Currency_Score_Color = GetColor(App_Currency_Color),
    Status =  GetColor(Color)
| sort by Composite_Score asc, MAU_Score desc
`;
