import React from "react";
import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

const SuccessDialog = (props) => {
    return (
        <Dialog
            hidden={props.hide}
            onDismiss={() => props.setHide(!props.hide)}
            dialogContentProps={{
                closeButtonAriaLabel: "Close",
                subText: props.message,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => props.setHide(!props.hide)}
                    text="Ok"
                />
            </DialogFooter>
        </Dialog>
    );
};

export default SuccessDialog;
