import { createContext, useContext, useEffect, useRef } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { AudienceGroup, TelemetryLogger } from "@microsoft/oteljs";
import { OneDSSink } from "@microsoft/oteljs-1ds";
import {
    FilterParams,
    UserActionEventFields,
    UtelFieldTypes,
    sendFilterChangeEvent,
    sendToolLaunchEvent,
    sendUserActionEvent,
    sendDebouncedUserActionEvent,
    ReferralFields,
} from "@oga-plg/plg-telemetry/dist";
import { createPLGTelemetryLogger } from "@oga-plg/plg-telemetry/dist";
import { logException } from "utils/AppInsightsHelper";
import AuthenticationUtil from "utils/AuthenticationUtil";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { isDev } from "utils/Helpers";

export type PlgTelemetryState = {
    referralFields?: ReferralFields;
};

export const PlgTelemetryContext = createContext(null);

const logPlgTelemetryExceptionToAppInsights = (message: string) => {
    console.warn(message);

    logException(
        new TenantInsightsException(Severity.SEV3, "PlgTelemetryException"),
        {
            message: message,
        },
    );
};

const createLogger = (
    userObjectId: string,
    userTenantId: string,
    toolName: string,
) => {
    try {
        return createPLGTelemetryLogger({
            audienceGroup: isDev()
                ? AudienceGroup.Automation
                : AudienceGroup.Microsoft,
            tenantId: userTenantId,
            toolName: toolName,
            toolVersion: "Unknown",
            userObjectId: userObjectId,
        });
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while creating the telemetry logger: ${e.message}`,
        );
    }
};

let telemetryLogger: TelemetryLogger = createLogger(
    null /* userObjectId */,
    null /* userTenantId */,
    "NotSet",
);

// Note: this must be called when loading the particular tool/page before logging any telemetry,
// so that the events will have the proper information on them.
const applyUserInfoAndToolNameToPlgTelemetryLogger = (toolName: string) => {
    try {
        const fullToolName = `CI ${toolName}`;
        const userInfo: AccountInfo =
            AuthenticationUtil?.getMSALInstance()?.getActiveAccount();

        if (!userInfo || !userInfo.localAccountId || !userInfo.tenantId) {
            // In a testing environment, such as Jest, it is expected that we won't have any user info,
            // so skip logging an exception.
            if (process.env.NODE_ENV !== "test") {
                logPlgTelemetryExceptionToAppInsights(
                    "Could not get user info so telemetry logger could not be initialized",
                );
            }

            // No user info, so just set the tool name
            // We can't update an existing instance of the telemetry logger with the new fields,
            // so have to just create a new instance.
            telemetryLogger = createLogger(
                null /* userObjectId */,
                null /* userTenantId */,
                fullToolName,
            );

            return;
        }

        // We can't update an existing instance of the telemetry logger with the new fields,
        // so have to just create a new instance.
        telemetryLogger = createLogger(
            userInfo.localAccountId,
            userInfo.tenantId,
            fullToolName,
        );
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while applying user info and tool name to the telemetry logger: ${e.message}`,
        );
    }
};

const sendToolLaunchTelemetryEvent = (referralProps: PlgTelemetryState) => {
    try {
        sendToolLaunchEvent(telemetryLogger, {
            toolLaunchUrl: window?.location?.href ?? "",
            referralFromUrl: document?.referrer ?? "",
            referralFromToolName:
                referralProps?.referralFields?.referralFromToolName ?? "",
            referralFromFeatureName:
                referralProps?.referralFields?.referralFromFeatureName ?? "",
            referralFromSessionId:
                referralProps?.referralFields?.referralFromSessionId ?? "",
        });
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to send the ToolLaunch event: ${e.message}`,
        );
    }
};

const applyUserInfoAndToolNameAndSendLaunchEvent = (
    toolName: string,
    referralProps: PlgTelemetryState,
) => {
    applyUserInfoAndToolNameToPlgTelemetryLogger(toolName);
    sendToolLaunchTelemetryEvent(referralProps);
};

export const sendFiltersChangeTelemetryEvent = (
    filterEntries: FilterParams,
    areLaunchFilters: boolean = false,
) => {
    try {
        sendFilterChangeEvent(telemetryLogger, filterEntries, areLaunchFilters);
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to send the FilterChange event: ${e.message}`,
        );
    }
};

// Hook to send a launch event for a tool during boot
export const useSendLaunchEvent = (toolName: string, initFilters?: FilterParams) => {
    const alreadyLogged = useRef<boolean>(false);
    const plgTelemetryContext = useContext(PlgTelemetryContext);

    useEffect(() => {
        if (toolName && !alreadyLogged.current) {
            applyUserInfoAndToolNameAndSendLaunchEvent(toolName, {
                referralFields: {
                    referralFromToolName:
                        plgTelemetryContext?.state?.current?.referralFields
                            ?.referralFromToolName,
                    referralFromFeatureName:
                        plgTelemetryContext?.state?.current?.referralFields
                            ?.referralFromFeatureName,
                    referralFromSessionId:
                        plgTelemetryContext?.state?.current?.referralFields
                            ?.referralFromSessionId,
                },
            });

            // Clear out the referrer info since we've logged it
            plgTelemetryContext?.updateState({});

            if (initFilters) {
                sendFiltersChangeTelemetryEvent(
                    initFilters,
                    true /* areLaunchFilters */,
                );
            }

            alreadyLogged.current = true;
        }
    }, [toolName, initFilters, plgTelemetryContext]);
};

export interface FiltersWithAdditionalFilters {
    [key: string]: any;
    additionalFilters?: any;
}

export const flattenFilters = (
    filters: FiltersWithAdditionalFilters,
): FilterParams => {
    try {
        const flattenedFilters: FilterParams = {};

        for (const key in filters) {
            if (key === "additionalFilters") {
                for (const additionalKey in filters[key]) {
                    flattenedFilters[additionalKey] = filters[key][additionalKey];
                }
            } else {
                flattenedFilters[key] = filters[key];
            }
        }

        return flattenedFilters;
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to flatten filters for telemetry: ${e.message}`,
        );
    }
};

export const sendUserActionTelemetryEvent = (
    userActionEventFields: UserActionEventFields,
    additionalFields?: UtelFieldTypes,
) => {
    try {
        sendUserActionEvent(
            telemetryLogger,
            userActionEventFields,
            additionalFields,
        );
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to send the UserAction event: ${e.message}`,
        );
    }
};

export const sendDebouncedUserActionTelemetryEvent = (
    userActionEventFields: UserActionEventFields,
    additionalFields?: UtelFieldTypes,
) => {
    try {
        sendDebouncedUserActionEvent(
            telemetryLogger,
            userActionEventFields,
            additionalFields,
        );
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to send the UserAction event: ${e.message}`,
        );
    }
};

export const getSessionIdFromTelemetryLogger = (): string => {
    try {
        if (telemetryLogger.telemetrySinks?.[0] instanceof OneDSSink) {
            const sessionId = (
                telemetryLogger.telemetrySinks[0] as OneDSSink
            ).getOneDSPersistentDataFields()["Session.Id"];

            if (sessionId && typeof sessionId === "string") {
                return sessionId;
            }
        }

        return "";
    } catch (e) {
        logPlgTelemetryExceptionToAppInsights(
            `An error occurred while trying to get the session ID from the telemetry logger: ${e.message}`,
        );
    }
};
