import React from "react";
import { FontIcon, Dropdown, Stack } from "@fluentui/react";
import { filterStackTokens, filterStyles } from "pages/common";
import { ConsumerAppMetricsFilterState } from "pages/ConsumerProductDrillDown/types";
import { ConsumerHostOptions } from "utils/ConsumerConstants";
import { dropdownStyles } from "utils/WebConstants";

interface AppMetricFiltersPropsType {
    filters: ConsumerAppMetricsFilterState;
    onFilterChange: (item: string, value: string) => void;
}

export const ConsumerAppMetricFilters = ({
    filters,
    onFilterChange,
}: AppMetricFiltersPropsType) => {
    return (
        <Stack horizontal tokens={filterStackTokens} styles={filterStyles}>
            <FontIcon iconName="Filter" className="filterIcon" />
            <Dropdown
                placeholder="Select an option"
                label="Host"
                options={ConsumerHostOptions}
                selectedKey={filters.host}
                styles={dropdownStyles}
                onChange={(event, option) => {
                    onFilterChange("host", option.key as string);
                }}
                className="filterDropdown"
            />
        </Stack>
    );
};
