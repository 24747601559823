import { IDropdownOption } from "@fluentui/react";
import { descending } from "d3-array";
import * as API from "api";
import {
    CohortAnalysisErrorDrillDownDataType,
    CohortAnalysisErrorTableDataType,
    CohortAnalysisFilters,
    CohortAnalysisReliabilityDataType,
    CohortAnalysisResponse,
    CohortAnalysisState,
} from "pages/CohortAnalysis/types";
import { getChartDataForError } from "redux/modules/sharedErrorModules";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDisplayDate } from "utils/Helpers";

export const formatValidDates = (
    response: CohortAnalysisResponse,
    filters: CohortAnalysisFilters,
) => {
    const datesTable = response?.Tables[0];
    const dateOptions = [];

    const uniqueDates = Array.from(new Set(datesTable.Rows)).sort(descending);
    uniqueDates.forEach((a) =>
        dateOptions.push({ key: a[0], text: formatDisplayDate(a[0] as string) }),
    );

    const filterVals = filters["date"] ? {} : { date: uniqueDates[0][0] as string };

    return {
        dateOptions,
        filterVals,
    };
};

export const formatCohortCategories = (
    response: CohortAnalysisResponse,
    filters: CohortAnalysisFilters,
) => {
    const cohortCategoriesTable = response?.Tables[0];

    const cohortCategories = [];

    cohortCategoriesTable.Rows.forEach((element) => {
        const category = element[0];
        cohortCategories.push({ key: category, text: category });
    });

    const filterVals = filters["cohortCategory"]
        ? {}
        : {
              cohortCategory: cohortCategories[0].key,
          };

    return {
        cohortCategories,
        filterVals,
    };
};

export const formatCohortReliabilityData = (
    response: CohortAnalysisResponse,
    filters: CohortAnalysisFilters,
) => {
    const overallCohortReliabilityTable = response?.Tables[0];

    const chartData: CohortAnalysisReliabilityDataType[] = [];
    const cohorts: IDropdownOption[] = [];

    overallCohortReliabilityTable.Rows.forEach((element) => {
        chartData.push({
            Name: element[0] as string,
            ACE_OR: Number((element[1] as number).toFixed(2)),
            ACE_IS: Number((element[2] as number).toFixed(2)),
            Normal_OR: 2,
        });

        cohorts.push({ key: element[0] as string, text: element[0] as string });
    });

    const filterVals = filters["cohort"]
        ? {}
        : {
              cohort: cohorts[0].key as string,
          };

    return { chartData, cohorts, filterVals };
};

export const formatCohortErrorDistributionData = (
    errorTableResponse: CohortAnalysisResponse,
    errorBreakdownTableResponse: CohortAnalysisResponse,
    filters: CohortAnalysisFilters,
) => {
    const cohortErrorsTable = errorTableResponse?.Tables[0];
    const cohortErrorBreakdownTable = errorBreakdownTableResponse?.Tables[0];

    const errors: IDropdownOption[] = [];
    const chartData: CohortAnalysisErrorDrillDownDataType[] = [];
    const cohortErrorTable: CohortAnalysisErrorTableDataType[] = [];
    let highestScoreErrorName = null;
    let highestScoreFromError = 0;

    cohortErrorsTable.Rows.forEach((element) => {
        const score = Number((element[3] as number).toFixed(2));

        chartData.push({
            Name: element[0] as string,
            ACE_OR: Number((element[1] as number).toFixed(2)),
            SessionErrorCount: Number((element[2] as number).toFixed(2)),
            Score: score,
        });

        errors.push({ key: element[0] as string, text: element[0] as string });

        if (highestScoreFromError < score) {
            highestScoreFromError = score;
            highestScoreErrorName = element[0];
        }
    });

    cohortErrorBreakdownTable.Rows.forEach((element) => {
        cohortErrorTable.push({
            ErrorName: element[2] as string,
            ErrorType: element[3] as string,
            OddsRatio: Number((element[4] as number).toFixed(4)),
            Score: Number((element[5] as number).toFixed(2)),
            PercentUsersWithError: element[6] as number,
            S2500AverageUser: element[7] as number,
            PercentSessionsWithError: element[8] as number,
            S2500AverageSession: element[9] as number,
            UserErrorCount: element[11] as number,
        });
    });

    const filterVals = filters["errorname"]
        ? {}
        : {
              errorname: highestScoreErrorName,
          };

    return {
        chartData,
        cohortErrorTable,
        errors,
        filterVals,
    };
};

export const formatErrorTenants = (
    tenantsImpactedTableResponse: CohortAnalysisResponse,
    s2500AvgDataResponse: CohortAnalysisResponse,
    filters: CohortAnalysisFilters,
) => {
    const tenantsImpactedTable = tenantsImpactedTableResponse?.Tables[0];
    const s2500AvgDataTable = s2500AvgDataResponse?.Tables[0];

    const tenantsImpacted: any[] = [];
    const s2500Data = {};

    // Schema -  Date, S2500TenantCount, S2500PercentageSessionsWithError, S2500PercentageUsersWithError
    s2500AvgDataTable.Rows.forEach((element) => {
        const tmp = {};
        tmp["S2500TenantCount"] = element[1];
        tmp["S2500PercentageSessionsWithError"] = element[2];
        tmp["S2500PercentageUsersWithError"] = element[3];

        s2500Data[element[0] as string] = tmp;
    });

    tenantsImpactedTable.Rows.forEach((element) => {
        let s2500PercentageUsersWithError = "";
        let s2500PercentageSessionsWithError = "";
        const tempDate = filters["date"];

        s2500PercentageUsersWithError =
            s2500Data[tempDate]["S2500PercentageUsersWithError"];
        s2500PercentageSessionsWithError =
            s2500Data[tempDate]["S2500PercentageSessionsWithError"];

        const impactScore = Number((element[8] as number).toFixed(2));

        tenantsImpacted.push({
            Name: element[1],
            id: element[0],
            Industry: element[2],
            "Error Type": element[3],
            "# Users with Error": Number((element[4] as number).toFixed(0)),
            "% Users With Error": Number((element[5] as number).toFixed(2)),
            "S2500 Avg% Users With Error": s2500PercentageUsersWithError,
            "# Sessions With Error": Number((element[6] as number).toFixed(0)),
            "% Sessions With Error": Number((element[7] as number).toFixed(2)),
            "S2500 Avg% Sessions With Error": s2500PercentageSessionsWithError,
            Date: tempDate,
            ImpactScore: impactScore === 0 ? 0 : impactScore,
        });
    });

    return {
        tenantsImpacted,
        s2500Data,
    };
};

export const fetchValidDates = async (
    filters: CohortAnalysisFilters,
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });

        const validDatesResponse = await API.getCohortAnalysisValidDates(filters);
        const dates = formatValidDates(validDatesResponse?.dates, filters);
        setFilters((filters) => {
            return {
                ...filters,
                ...dates["filterVals"],
            };
        });
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                dateOptions: dates["dateOptions"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisPageFetchDatesFailed",
            ),
            {
                message: "Failure while fetching dates",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};

export const fetchCohortCategories = async (
    filters: CohortAnalysisFilters,
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });

        const cohortCategoryResponse = await API.getCohortCategories(filters);
        const cohortCategories = formatCohortCategories(
            cohortCategoryResponse?.cohortCategories,
            filters,
        );
        setFilters((filters) => {
            return {
                ...filters,
                ...cohortCategories["filterVals"],
            };
        });
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                cohortCategoryOptions: cohortCategories["cohortCategories"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisPageFetchCategoriesFailed",
            ),
            {
                message: "Failure while fetching cohort categories",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};

export const fetchCohortReliabilityData = async (
    filters: CohortAnalysisFilters,
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const cohortReliabilityResponse =
            await API.getCohortReliabilityData(filters);
        const reliabilityData = formatCohortReliabilityData(
            cohortReliabilityResponse?.overallCohortReliability,
            filters,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                reliabilityDisparityChartData: reliabilityData["chartData"],
                cohortOptions: reliabilityData["cohorts"],
            };
        });
        setFilters((filters) => {
            return {
                ...filters,
                ...reliabilityData["filterVals"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisFetchReliabilityFailed",
            ),
            {
                message:
                    "Failure while fetching reliability data for cohort analysis",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};

export const fetchCohortErrorDistribution = async (
    filters: CohortAnalysisFilters,
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
    setFilters: React.Dispatch<React.SetStateAction<CohortAnalysisFilters>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const cohortErrorDistributionResponse =
            await API.getErrorDistributionForCohort(filters);
        const errorDistribution = formatCohortErrorDistributionData(
            cohortErrorDistributionResponse?.cohortErrors,
            cohortErrorDistributionResponse?.cohortErrorBreakdown,
            filters,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                errorsDeepDiveChartData: errorDistribution["chartData"],
                errorBreakdownByCohort: errorDistribution["cohortErrorTable"],
                errorNames: errorDistribution["errors"],
            };
        });
        setFilters((filters) => {
            return {
                ...filters,
                ...errorDistribution["filterVals"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisFetchErrorDistributionFailed",
            ),
            {
                message:
                    "Failure while fetching error distribution data for cohort analysis",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};

export const fetchTenantsImpactedByError = async (
    filters: CohortAnalysisFilters,
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const tenantsImpactedResponse =
            await API.getTenantsImpactedByErrorInCohort(filters);
        const tenantsImpacted = formatErrorTenants(
            tenantsImpactedResponse?.tenantsImpacted,
            tenantsImpactedResponse?.s2500AvgData,
            filters,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                tenantsImpactedByError: tenantsImpacted["tenantsImpacted"],
                s2500Data: tenantsImpacted["s2500Data"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisFetchTenantsImpactedFailed",
            ),
            {
                message:
                    "Failure while fetching the tenant impact data for cohort analysis",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};

export const fetchErrorLookupChart = async (
    filters: CohortAnalysisFilters,
    s2500Data: {},
    setState: React.Dispatch<React.SetStateAction<CohortAnalysisState>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const chartDataForErrorResponse = API.getChartDataForError(filters);
        const chartDataForError = getChartDataForError(
            chartDataForErrorResponse,
            s2500Data,
            filters,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                tenantsImpactedByErrorChartData: chartDataForError["chartData"],
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CohortAnalysisFetchChartDataFailed",
            ),
            {
                message:
                    "Failure while fetching the error chart data for cohort analysis",
                filters,
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
    }
};
