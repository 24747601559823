import React, { useState } from "react";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { IStackItemStyles, Stack } from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { PerformanceTable } from "components/PerformanceComponents/PerformanceTable";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import {
    chartMarginStyles,
    chartXAxisPaddingStyles,
    commonInnerBlock,
    drilldownChartHeight,
    horizontalStackTokens,
} from "pages/common";
import { LEGEND_CLICK, PERFORMANCE_MESSAGES } from "utils/Messages";

const applicationOrder = ["WXP", "Word", "Excel", "PowerPoint", "OneNote"];
const widthPercentage = "32%"; // keeping 1% space for each of the table/chart
const stackTextAlignStyles: IStackItemStyles = {
    root: {
        textAlign: "center",
        width: widthPercentage,
    },
};

export const innerBlock: IStackItemStyles = {
    root: {
        marginBottom: "20px",
        border: "dashed 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "20px",
        borderRadius: "14px",
        width: widthPercentage,
    },
};

const customFormatter = (value, name) => {
    if (name.includes("%")) {
        return `${value}%`;
    }
    return `${value}s`;
};
export const PerformanceDrilldown = (props) => {
    const { data, modalChartData, euplCharts, chartLabels } = props;

    const [show, setShow] = useState(false);
    const [dataSelected, changeDataSelected] = useState({ app: "WXP", name: "" });
    const toggleModal = () => {
        setShow(!show);
    };

    return (
        <Stack>
            {applicationOrder.map((app) => {
                return (
                    <Stack.Item styles={commonInnerBlock} key={app}>
                        <ApplicationTitle app={app} />
                        <Stack horizontal tokens={horizontalStackTokens}>
                            {data && data[app] && data[app].length !== 0 ? (
                                <Stack.Item styles={innerBlock}>
                                    <PerformanceTable
                                        payload={data[app]}
                                        changeDataSelected={changeDataSelected}
                                        toggleModal={toggleModal}
                                        application={app}
                                    />
                                </Stack.Item>
                            ) : (
                                <EmptyTableContainer />
                            )}
                            {euplCharts && chartLabels && euplCharts[app] && (
                                <>
                                    {Object.keys(euplCharts[app]).map((P) => (
                                        <Stack.Item
                                            styles={stackTextAlignStyles}
                                            key={`${app}${P}`}
                                        >
                                            <h3 className="commonPageBlockTitle">
                                                EUPL ({P})
                                            </h3>
                                            <TogglingLineChart
                                                kind={togglingLineChartKind.values}
                                                xDataKey="Date"
                                                yUnit="s"
                                                values={euplCharts[app][P]}
                                                labels={chartLabels[app]}
                                                height={drilldownChartHeight}
                                                tooltipFormatter={customFormatter}
                                            />
                                        </Stack.Item>
                                    ))}
                                </>
                            )}
                        </Stack>
                        {euplCharts && chartLabels && euplCharts[app] ? (
                            <>
                                <span className="subscriptStyleBottom">
                                    {PERFORMANCE_MESSAGES.TOP_10}
                                </span>
                                <br />
                                <span className="subscriptStyleBottom">
                                    {LEGEND_CLICK}
                                </span>
                            </>
                        ) : (
                            <div />
                        )}
                    </Stack.Item>
                );
            })}
            {modalChartData && modalChartData[dataSelected.app] && (
                <PerfModal
                    handleClose={toggleModal}
                    show={show}
                    chartData={modalChartData[dataSelected.app][dataSelected.name]}
                    header={dataSelected.name}
                />
            )}
        </Stack>
    );
};

const PerfModal = (props) => {
    const { handleClose, show, chartData, header } = props;
    if (!show || !chartData || chartData === undefined) {
        return null;
    }
    const chartWidth = 550;
    const dotRadius = 8;

    return (
        <div className="backdropStyle">
            <div className="modalStyle" style={{ paddingBottom: 20 }}>
                <button className="close" type="button" onClick={handleClose}>
                    {" "}
                </button>
                <div>
                    <h3 className="commonPageBlockTitle">{header}</h3>
                    <ComposedChart
                        width={chartWidth}
                        height={drilldownChartHeight}
                        data={chartData}
                        margin={chartMarginStyles}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                        <YAxis yAxisId="left" unit="s" />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            domain={[0, 100]}
                            unit="%"
                        />
                        <Tooltip formatter={customFormatter} />
                        <Legend />
                        <Bar
                            yAxisId="right"
                            dataKey="% Sessions"
                            fill="#e0ad04"
                            barSize={15}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="P95"
                            stroke="#8884d8"
                            activeDot={{ r: dotRadius }}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="P50"
                            stroke="#82ca9d"
                            activeDot={{ r: dotRadius }}
                        />
                    </ComposedChart>
                </div>
            </div>
        </div>
    );
};
