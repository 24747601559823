import { Dispatch, SetStateAction } from "react";
import {
    IDetailsColumnProps,
    IRenderFunction,
    PivotLinkSizeType,
} from "@fluentui/react";
import { Workload } from "config/PlatformConfig";

export type tenantColorViewLogType = {
    page: string;
    table: string;
    color: string;
};

export type requestRetryLogType = {
    queryName: string;
    status: number;
    retryCount: number;
    lastRequestTime: number;
};

export type tenantDetailHoverViewType = {
    page: string;
    tenantName: string;
};

export type IPivotsize = {
    normal: PivotLinkSizeType;
    large: PivotLinkSizeType;
};

export interface Outage {
    id: string;
    type: string;
    status: string;
    message: string;
    targets: string[];
    createdBy: string;
    createdDate: string;
    closedBy?: string;
    closedDate?: string;
}

export interface ConsumerAppMetricsLinkType {
    [key: string]: {
        toolTip: string;
        getMetricUrl: (
            cohortName: string,
            cohortType: string,
            application: string,
            date?: string,
        ) => string;
    };
}

export enum StatTestResult {
    NotSignificant,
    Significant,
    HighlySignificant,
}

export enum AshaHierarchyLevel {
    Pillar = "Pillar",
    Veto = "Veto",
    Error = "Error",
}

export type tableColumnConfig = {
    [column: string]: {
        headerGroups: string[];
        tooltip?: string;
        displayName?: string;
        style?: React.CSSProperties;
        exportCSVHeaderName?: string;
        onRenderHeader?: IRenderFunction<IDetailsColumnProps>;
    };
};

export type AppButtonsPropsType = {
    selectedApp: string;
    setSelectedApp: Dispatch<SetStateAction<string>>;
    workload: Workload;
};

export type ContactType = {
    name: string;
    email: string;
};

export interface QueryDictType {
    [key: string]: string | number;
}

export interface LinkParameters {
    id: string;
    level: string;
    date: string;
    host?: string;
    uiHost?: string;
    npsPlatformName?: string;
}
