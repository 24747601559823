import React, { useEffect, useMemo, useState } from "react";
import {
    ContextualMenuItemType,
    IColumn,
    IContextualMenuItem,
    Stack,
} from "@fluentui/react";
import {
    CountAnnotationBar,
    ICountAnnotationProps,
} from "@m365-admin/count-annotation";
import { EmptyState } from "@m365-admin/empty-state";
import { useFilterOptions } from "@m365-admin/hooks";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { AddInsFlyoutPanel } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AddInsFlyoutPanel";
import { AdvisoriesFilterPanel } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesFilterPanel";
import { DataTable } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/DataTable";
import {
    GroupAddins,
    onColumnRender,
    emptyStateStyle,
    countAnnotationRectangleStyles,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { fetchApphealthAddIns } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/apihandler";
import {
    AddInsCardState,
    AddInsDataState,
    AddInsFilterNames,
    AddInsTabletype,
    AdvisoryFilterDetailsType,
    IAppHealthAddIns,
    LoadingState,
    OfficeProduct,
    ServicingChannelV2,
    TableCardProps,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { commonInnerBlock } from "pages/common";
import { logFeatureUsage } from "utils/AppInsightsHelper";
import { ADMINCENTER_MESSAGES } from "utils/Messages";

const buildColumns = (): IColumn[] => {
    const displayColumns = [
        ["addinName", "Name", 350, null],
        ["addinProvider", "Publisher", 140, null],
        ["addinVersion", "Version", 240, null],
        ["addinApps", "Office app", 140, null],
        ["deviceCounts", "Monitored Devices", 140, null],
        ["addinCrashrate", "Crash rate(%)", 120, null],
        ["addinLoadTime", "Load Time (sec.)", 120, null],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        true,
    );
    return columns;
};

export const addInsColumn: IColumn[] = buildColumns();

const getCountAnnotationProps = (
    state: AddInsCardState,
    setState: React.Dispatch<React.SetStateAction<AddInsCardState>>,
): ICountAnnotationProps[] => {
    return state.items.map((st: AddInsDataState) => {
        return {
            annotationText: st.text,
            count: st.uniqueCounts,
            isSelected: st.selected,
            annotationColor: st.color,
            linkProps: {
                onClick: () =>
                    setState((oldState) => {
                        const selectedItems = oldState.items.map(
                            (val: AddInsDataState) => ({
                                ...val,
                                selected: val.text === st.text,
                            }),
                        );
                        return {
                            ...oldState,
                            items: selectedItems,
                        };
                    }),
            },
            styles: countAnnotationRectangleStyles,
        };
    });
};

export const getBuildOptions = (
    buildOptions: string[],
    selectedBuilds: string[],
    setBuilds: React.Dispatch<React.SetStateAction<string[]>>,
): IContextualMenuItem[] => {
    if (buildOptions.length === 0) return [];

    return buildOptions.map((build) => {
        const splitBuilds = build.split(","); //  format is `${channel},${build}`
        const cellType = {};
        let buildVersion = build;
        // when the length is 1 then it is just a channel name without build version
        if (splitBuilds.length === 1) {
            buildVersion = splitBuilds[0];
            cellType["itemType"] = ContextualMenuItemType.Header;
        } else {
            buildVersion = splitBuilds[1];
        }

        return {
            key: build,
            text: buildVersion,
            canCheck: true,
            checked: selectedBuilds?.includes(buildVersion),
            onClick: () => {
                setBuilds((oldState) => {
                    if (oldState?.includes(buildVersion)) {
                        const filteredBuilds = oldState.filter(
                            (item) => item !== buildVersion,
                        );
                        return filteredBuilds;
                    }

                    return [...oldState, buildVersion];
                });
            },
            ...cellType,
        };
    });
};

export const AddInsTableCard = ({ id, date }: TableCardProps) => {
    const [state, setState] = useState<AddInsCardState>({
        items: [],
        error: "",
        buildOptions: [],
    });

    const [loadingState, setLoadingState] = useState<LoadingState>({
        isLoading: true,
        isLoadingFailed: false,
    });

    const [searchText, setSearchText] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<IColumn>();

    const [apps, setApps, selectedApps] = useFilterOptions(
        Object.values(OfficeProduct).filter(
            (value) => value !== OfficeProduct.Unknown,
        ),
    );

    const [channels, setChannels, selectedChannels] = useFilterOptions(
        Object.values(ServicingChannelV2).filter(
            (value) => value !== ServicingChannelV2.Unknown,
        ),
    );

    const [selectedBuilds, setBuilds] = useState<string[]>([]);

    const [isOpen, setOpen] = useState<boolean>(false);

    const [selectedRow, setSelectedRow] = useState<AddInsTabletype>();

    const advisoriesFilters: AdvisoryFilterDetailsType[] = [
        {
            name: AddInsFilterNames.Applications,
            items: apps,
            selectedItems: selectedApps,
            setItems: setApps,
        },
        {
            name: AddInsFilterNames.Channels,
            items: channels,
            selectedItems: selectedChannels,
            setItems: setChannels,
        },
        {
            name: AddInsFilterNames.Builds,
            items: getBuildOptions(state.buildOptions, selectedBuilds, setBuilds),
            selectedItems: selectedBuilds,
            setItems: setBuilds,
            isCustomFilterState: true,
        },
    ];

    const onSearch = (value: string) => {
        setSearchText(value);
    };

    const onColumnClick = (column: IColumn) => {
        setSortColumn(column);
    };

    useEffect(() => {
        logFeatureUsage("Apps Admin Center - AddinsTable");
        setLoadingState((oldLoadingState) => {
            return {
                ...oldLoadingState,
                isLoading: true,
            };
        });
        fetchApphealthAddIns(setState, setLoadingState, id, date);
    }, [id, date]);

    // To avoid recalculations when panel opens/closes, loadingState changes and row select/unselect
    const getItems = useMemo((): AddInsTabletype[] => {
        if (state.items.length === 0) return [];
        let filtered: IAppHealthAddIns[] = cloneDeep(
            state.items.find((value: AddInsDataState) => value.selected)?.data,
        );

        if (selectedApps !== undefined) {
            filtered = filtered.filter((item) =>
                selectedApps.includes(
                    item[AddInsFilterNames.Applications] as string,
                ),
            );
        }
        if (selectedChannels !== undefined) {
            filtered = filtered.filter((item) =>
                selectedChannels.includes(
                    item[AddInsFilterNames.Channels] as string,
                ),
            );
        }
        if (selectedBuilds !== undefined && selectedBuilds.length !== 0) {
            filtered = filtered.filter((item) =>
                selectedBuilds.includes(item[AddInsFilterNames.Builds] as string),
            );
        }

        if (!isNil(searchText) && !isEmpty(searchText)) {
            filtered = filtered.filter(
                (item) =>
                    item.addinName
                        .toUpperCase()
                        .indexOf(searchText.toUpperCase()) !== -1,
            );
        }

        const groupedAddins = GroupAddins(filtered);

        if (!sortColumn) return groupedAddins;

        const result = DetailsListHelper.sortItems(
            addInsColumn,
            sortColumn,
            groupedAddins,
        );

        return result.items;
    }, [
        selectedApps,
        selectedChannels,
        selectedBuilds,
        searchText,
        sortColumn,
        state.items,
    ]);

    return loadingState.isLoadingFailed ? (
        <EmptyState
            title={ADMINCENTER_MESSAGES.NO_DATA_LOADING_FAILED}
            body={state.error}
            styles={emptyStateStyle}
        />
    ) : (
        <Stack styles={commonInnerBlock}>
            <CountAnnotationBar
                countAnnotationProps={getCountAnnotationProps(state, setState)}
            />
            <DataTable
                items={getItems}
                columns={addInsColumn}
                onSearch={onSearch}
                loading={loadingState.isLoading}
                FilterPanel={
                    <AdvisoriesFilterPanel advisoriesFilters={advisoriesFilters} />
                }
                setSelectedRow={setSelectedRow}
                setOpenFlyoutPanel={setOpen}
                onColumnRender={onColumnRender}
                onColumnClick={onColumnClick}
            />
            <AddInsFlyoutPanel
                isOpen={isOpen}
                setOpen={setOpen}
                selectedRow={selectedRow}
            />
        </Stack>
    );
};
