import React, { useState } from "react";
import {
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsRowStyles,
    IRenderFunction,
    SelectionMode,
} from "@fluentui/react";
import { CamauScorecardChartFlyout } from "components/ConsumerCamauComponents/CamauScorecardChartFlyout";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    CamauTableColumnConfig,
    segmentMetadata,
} from "components/ConsumerCamauComponents/ConsumerCamauHelper";
import { CONSUMERCAMAU_MESSAGES, CONSUMERSCORECARD_MESSAGES } from "utils/Messages";

const getHeaderGroups = () => {
    const groups = {};
    Object.keys(CamauTableColumnConfig).forEach((column) => {
        groups[column] = CamauTableColumnConfig[column]?.headerGroups;
    });
    return groups;
};

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    const column = props.column;
    if (CamauTableColumnConfig[column.key]?.style) {
        return (
            <div
                style={CamauTableColumnConfig[column.key]?.style}
                key={`${column.key}-style`}
            >
                {defaultRender({ ...props })}
            </div>
        );
    }
    return defaultRender({ ...props });
};

const onRenderRow = (props?: IDetailsColumnFieldProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#fcf5ed" };
        }
        if (
            segmentMetadata[props.item["Segment"]]?.displayText ===
                "All Platforms - All OS" ||
            segmentMetadata[props.item["Segment"]]?.displayText ===
                "Desktop - All" ||
            segmentMetadata[props.item["Segment"]]?.displayText === "Mobile - All" ||
            segmentMetadata[props.item["Segment"]]?.displayText === "Web - All"
        ) {
            // only make it bold for New Users, rest keep it normal
            customStyles.cell = { fontWeight: "bold" };
        }

        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

const CamauTable = ({ items, columns, loading, application, cadence, date }) => {
    const [show, setShow] = useState<boolean>(false);
    const [segmentSelected, setSegmentSelected] = useState<string>("NA");
    const toggleModal = () => {
        setShow(!show);
    };
    const showChartFlyout = (segment: string) => {
        setSegmentSelected(segment);
        toggleModal();
    };

    const renderItemColumn = (item, _index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;
        const cellStyle: React.CSSProperties = {};
        const segmentDisplayText = segmentMetadata[item["Segment"]]?.displayText;

        if (fieldContent < 0) cellStyle.color = "#ff6347"; // if negative then color red
        if (colKey === "Segment") {
            return (
                <div
                    onClick={() => showChartFlyout(segmentDisplayText)}
                    style={{ cursor: "pointer" }}
                >
                    {/* left padding */}
                    <span
                        style={{
                            marginLeft:
                                segmentMetadata[fieldContent]?.segmentLevel * 10,
                        }}
                    >
                        {segmentMetadata[fieldContent]?.segmentLevel > 1 && " >> "}
                    </span>
                    <DetailsListHelper.TextValue value={segmentDisplayText} />
                </div>
            );
        } else if (colKey === "CAMAUPct") {
            return (
                <div style={cellStyle}>
                    <DetailsListHelper.NumericalValue
                        value={
                            fieldContent == null || fieldContent == 0
                                ? "NA"
                                : fieldContent
                        }
                        postfix={fieldContent ? "%" : ""}
                        decimalPrecision={1}
                    />
                </div>
            );
        } else if (colKey === "MAU" || colKey === "CAMAU") {
            return (
                <DetailsListHelper.NumericalAbbreviatedValue
                    value={fieldContent ?? "NA"}
                />
            );
        } else {
            return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    return (
        <>
            <div className="growthScorecardTopBanner">
                <span className="subscriptStyle">
                    {CONSUMERSCORECARD_MESSAGES.CLICK_SEGMENT_LINKS}
                </span>
            </div>

            <ShimmeredDetailsListWrapper
                items={
                    items[application]?.filter((value) => {
                        return value.Date === date;
                    }) || []
                }
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={(headerProps, defaultrender) => {
                    return DetailsListHelper.renderGroupedDetailsHeader(
                        headerProps,
                        getHeaderGroups(),
                        false,
                        defaultrender,
                    );
                }}
                enableShimmer={loading}
                onRenderItemColumn={renderItemColumn}
                onRenderField={onRenderField}
                onRenderRow={onRenderRow}
                layoutMode={DetailsListLayoutMode.fixedColumns}
            />
            <span className="subscriptStyleBottom">
                {CONSUMERCAMAU_MESSAGES.PERIOD_BYCADENCE}
            </span>
            {!loading && show && (
                <CamauScorecardChartFlyout
                    isPanelOpen={show}
                    togglePanel={toggleModal}
                    segment={segmentSelected}
                    application={application}
                    cadence={cadence}
                    data={items}
                />
            )}
        </>
    );
};

export default CamauTable;
