import * as React from "react";
import { Link } from "react-router-dom";
import {
    IButtonStyles,
    IconButton,
    IOverflowSetItemProps,
    OverflowSet,
} from "@fluentui/react";

const onRenderItem = (item?: IOverflowSetItemProps) => {
    return (
        <Link
            role="menuitem"
            to={item.to || "#"}
            target="_blank"
            rel="noopener noreferrer"
        >
            {item.name}
        </Link>
    );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: "0 4px",
            alignSelf: "stretch",
            height: "auto",
        },
    };
    return (
        <IconButton
            role="menuitem"
            title="More options"
            styles={buttonStyles}
            menuIconProps={{ iconName: "More" }}
            menuProps={{ items: overflowItems! }}
        />
    );
};

export const DrillDownMenu = ({ items }) => (
    <OverflowSet
        aria-label="Link to tenant drill down view"
        role="menubar"
        items={items}
        overflowItems={[]}
        onRenderItem={onRenderItem}
        onRenderOverflowButton={onRenderOverflowButton}
    />
);
