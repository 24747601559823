import React from "react";
import { ChoiceGroup, Dropdown, Label, Stack } from "@fluentui/react";
import "pages/common.css";
import { DatePicker } from "components/CustomComponents/DatePicker";
import { CopilotProductDrilldownFilterPanelProps } from "pages/Copilot/types";
import { copilotPlatformOptions, copilotScoreOptions } from "utils/CopilotConstants";

export const CopilotProductDrilldownFilterPanel = ({
    filters,
    scoreType,
    onFilterChange,
    dateOptions,
    onScoreTypeChange,
}: CopilotProductDrilldownFilterPanelProps) => {
    return (
        <Stack horizontal className="filterPanel">
            <div
                className="timePeriod"
                data-testid="CopilotDrilldownTimePeriodTestId"
            >
                <DatePicker
                    dateOptions={dateOptions}
                    onChangeSelection={(_event, option) => {
                        onFilterChange("date", option.key as string);
                    }}
                    selected={filters.date}
                />
            </div>
            <div>
                <Stack horizontal style={{ flexShrink: 0 }}>
                    <Label className="filterLabel">View By:</Label>
                    <ChoiceGroup
                        selectedKey={scoreType}
                        options={copilotScoreOptions}
                        onChange={onScoreTypeChange}
                        required
                        styles={{
                            flexContainer: {
                                display: "grid",
                                columnGap: "10px",
                            },
                        }}
                    />
                </Stack>
            </div>
            <div>
                <Label className="filterLabel">Platform:</Label>
                <Dropdown
                    options={copilotPlatformOptions[scoreType]}
                    onChange={(_event, option) => {
                        onFilterChange("platform", option.key as string);
                    }}
                    selectedKey={filters.platform}
                    className="filterDropdown"
                    data-testid="CopilotDrilldownPlatformTestId"
                />
            </div>
        </Stack>
    );
};
