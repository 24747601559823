import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    CollapseAllVisibility,
    ConstrainMode,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsColumnProps,
    IDetailsHeaderProps,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IRenderFunction,
    Icon,
    IconButton,
    SelectionMode,
    TooltipHost,
    getTheme,
} from "@fluentui/react";
import { GrowthScorecardChartFlyout } from "components/ConsumerGrowthScorecardComponents/GrowthScorecardChartFlyout";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    ScorecardTableColumnConfig,
    getGroupsForDate,
    segmentMetadata,
} from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardHelper";
import { ConsumerGrowthScorecardTablePropsType } from "components/ConsumerGrowthScorecardComponents/types";
import { InsightsRoutes } from "utils/Links";
import { CONSUMERSCORECARD_MESSAGES, NO_DATA_MESSAGES } from "utils/Messages";

const theme = getTheme();

export const onRenderSegmentHeader: IRenderFunction<IDetailsColumnProps> = (
    props,
) => {
    return (
        <div>
            <span>{props.column.name}</span>
            <br />
            <span className="growthScorecardSegmentSubtitle">
                Click segment name to see trend
            </span>
        </div>
    );
};

const getHeaderGroups = () => {
    const groups = {};
    Object.keys(ScorecardTableColumnConfig).forEach((column) => {
        groups[column] = ScorecardTableColumnConfig[column]?.headerGroups;
    });
    return groups;
};

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    const column = props.column;
    if (ScorecardTableColumnConfig[column.key]?.style) {
        return (
            <div
                style={ScorecardTableColumnConfig[column.key]?.style}
                key={`${column.key}-style`}
            >
                {defaultRender({ ...props })}
            </div>
        );
    }
    return defaultRender({ ...props });
};

const onRenderRow = (props?: IDetailsRowBaseProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (Math.abs(props.itemIndex - props.flatIndexOffset) % 2 === 1) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
        }
        if (segmentMetadata[props.item["Segment"]].displayText === "New Users") {
            // only make it bold for New Users, rest keep it normal
            customStyles.cell = { fontWeight: "bold" };
        }

        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

const GrowthScorecardTable = ({
    items,
    columns,
    loading,
    filters,
    statSigMetrics,
}: ConsumerGrowthScorecardTablePropsType) => {
    const [show, setShow] = useState<boolean>(false);
    const [segmentSelected, setSegmentSelected] = useState<string>("NA");
    const toggleModal = () => {
        setShow(!show);
    };
    const showChartFlyout = (segment: string) => {
        setSegmentSelected(segment);
        toggleModal();
    };

    const onRenderGroupHeader = (props) => {
        const filteredItems = getFilteredItems(true);
        if (filteredItems?.length > 0) {
            const groupItem = filteredItems[props.groupIndex];
            return (
                <div>
                    {props.group.data.isCollapsible && (
                        <span
                            onClick={onToggleCollapse(props)}
                            className="groupHeaderIcon"
                        >
                            <Icon
                                iconName={
                                    props.group.isCollapsed
                                        ? "ChevronRight"
                                        : "ChevronDown"
                                }
                            />
                        </span>
                    )}
                    <DetailsRow
                        {...props}
                        className="groupHeaderRow"
                        itemIndex={-1}
                        item={groupItem}
                        onRenderItemColumn={renderItemColumn}
                        onRenderField={onRenderField}
                    />
                </div>
            );
        }
    };

    const onToggleCollapse = (props) => {
        return () => {
            if (!props.group.isEmptyGroup) props!.onToggleCollapse!(props!.group!);
        };
    };

    const renderItemColumn = (item, _index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;
        const segmentDisplayText = segmentMetadata[item["Segment"]].displayText;

        const cellStyle: React.CSSProperties = {};
        if (fieldContent < 0) cellStyle.color = "red"; // if negative then color red

        const isNonValue = fieldContent == null;
        if (colKey === "Segment") {
            // https://office.visualstudio.com/DefaultCollection/OC/_sprints/taskboard/Customer%20Insights/OC/CY24Q2%20(Apr-Jun)/CI-Apr-01?workitem=8886785
            // The above work item is a temp change. It will be removed once the team decides.
            // concerned lines - 104-106, 124-128
            let isODSPHarmonyCheck = item["Segment"].includes(
                "ODSP Converged Free New Harmony All",
            );
            const leftMargin =
                segmentMetadata[fieldContent].segmentLevel === 1
                    ? 15
                    : segmentMetadata[fieldContent].segmentLevel * 10;
            return (
                <div
                    onClick={() => showChartFlyout(item["Segment"])}
                    style={{ cursor: "pointer" }}
                >
                    {/* Left padding to offset nested segments */}
                    <span
                        style={{
                            marginLeft: leftMargin,
                        }}
                    >
                        {segmentMetadata[fieldContent].segmentLevel > 1 && " >> "}
                    </span>
                    <DetailsListHelper.TextValue
                        value={segmentMetadata[fieldContent].displayText}
                    />
                    {isODSPHarmonyCheck && (
                        <TooltipHost
                            content={
                                "Harmony referral data is available from 07/22/2023 onward"
                            }
                        >
                            <Icon iconName="info" className="tableColumnDefintion" />
                        </TooltipHost>
                    )}
                </div>
            );
        } else if (
            // if its goal && free new users and is empty/null then show TBD
            colKey.includes("Goal_") &&
            segmentDisplayText === "New Users" &&
            isNonValue
        ) {
            return <DetailsListHelper.TextValue value={"TBD"} />;
        } else if (colKey.includes("_Score")) {
            if (!isNonValue) {
                if (
                    colKey === "CAMAU_Score" &&
                    segmentDisplayText === "OneDrive Host"
                ) {
                    return (
                        <div style={{ position: "relative", cursor: "pointer" }}>
                            <DetailsListHelper.NumericalAbbreviatedValue
                                value={fieldContent}
                            />
                            <div className="consumerCamauDrilldownLink">
                                <Link
                                    to={`${InsightsRoutes.ConsumerCAMAU.path}?&date=${filters["date"]}&application=${filters["application"]}`}
                                >
                                    <IconButton
                                        iconProps={{ iconName: "DrillThrough" }}
                                        title="CAMAU Drilldown"
                                        ariaLabel="camauDrilldownlink"
                                        className="drillDownIcon"
                                        disabled={false}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </Link>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <DetailsListHelper.NumericalAbbreviatedValue
                            value={fieldContent}
                        />
                    );
                }
            } else {
                return <DetailsListHelper.TextValue value={"NA"} class="nonValue" />;
            }
        } else if (colKey.includes("_YoY")) {
            let segmentName = segmentMetadata[item.Segment].displayText;
            let isStatSig = statSigMetrics.get(segmentName)?.includes(colKey);
            const isZeroValue = isNonValue || fieldContent === 0;
            let titleVariable = "";
            let spoDataCustomCheck = false;
            if (
                item["Segment"] === "SharePoint Online Consumer All All All" ||
                item["Segment"] === "SharePoint Online Consumer Paid All All" ||
                item["Segment"] === "SharePoint Online Consumer Free All All" ||
                item["Segment"] ===
                    "SharePoint Online Consumer Free Returning All" ||
                item["Segment"] === "SharePoint Online Consumer Free New All"
            ) {
                spoDataCustomCheck = true;
            }
            return (
                <div style={cellStyle} title={titleVariable}>
                    <DetailsListHelper.NumericalAbbreviatedValue
                        value={
                            isZeroValue || spoDataCustomCheck ? "NA" : fieldContent
                        }
                        additionalClassName={
                            isZeroValue || spoDataCustomCheck
                                ? "nonValue"
                                : "numericalValue"
                        } // making spo yoy to na. This is a custom requirement from the team. will change later.
                        postFix={fieldContent ? "%" : ""}
                        decimalPrecision={1}
                    />
                    {isStatSig && !(spoDataCustomCheck || isZeroValue) && (
                        <div className="statSig">σ</div>
                    )}
                </div>
            );
        } else if (colKey.includes("_Pct")) {
            // separating this out because of sharepoint yoy values needs a custom filter
            let isStatSig = statSigMetrics.get(item.Segment)?.includes(colKey);
            const isZeroValue = isNonValue || fieldContent === 0;
            return (
                <div style={cellStyle}>
                    <DetailsListHelper.NumericalValue
                        value={isZeroValue ? "NA" : fieldContent}
                        postfix={fieldContent ? "%" : ""}
                        decimalPrecision={1}
                        class={isZeroValue ? "nonValue" : "numericalValue"}
                    />
                    {isStatSig && <div className="statSig">σ</div>}
                </div>
            );
        } else {
            return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    const getFilteredItems = (headerItems: boolean) => {
        const allItems =
            items[filters.application]?.filter((value) => {
                return value.Date === filters.date;
            }) || [];

        return allItems.filter((value) => {
            return headerItems
                ? value.Segment.includes("All All All")
                : !value.Segment.includes("All All All");
        });
    };

    return (
        <>
            <div data-is-scrollable className="growthScorecardTablePageSection">
                <ShimmeredDetailsListWrapper
                    items={getFilteredItems(false)}
                    message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onRenderDetailsHeader={(
                        headerProps: IDetailsHeaderProps,
                        defaultrender: IRenderFunction<IDetailsHeaderProps>,
                    ) => {
                        return DetailsListHelper.renderGroupedDetailsHeader(
                            {
                                ...headerProps,
                                collapseAllVisibility: CollapseAllVisibility.hidden,
                            },
                            getHeaderGroups(),
                            false,
                            defaultrender,
                        );
                    }}
                    enableShimmer={loading}
                    onRenderItemColumn={renderItemColumn}
                    onRenderField={onRenderField}
                    onRenderRow={onRenderRow}
                    styles={DetailsListHelper.tenantTableStyle}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    groups={getGroupsForDate(filters.date)}
                    groupProps={{
                        onRenderHeader: onRenderGroupHeader,
                        showEmptyGroups: true,
                    }}
                />
            </div>
            <span className="subscriptStyleBottom">
                {CONSUMERSCORECARD_MESSAGES.RETENTION_NOTE}
                <br />
                {CONSUMERSCORECARD_MESSAGES.STAT_SIG_NOTE}
            </span>
            {!loading && show && (
                <GrowthScorecardChartFlyout
                    isPanelOpen={show}
                    togglePanel={toggleModal}
                    segment={segmentSelected}
                    filters={filters}
                    data={items}
                />
            )}
        </>
    );
};

export default GrowthScorecardTable;
