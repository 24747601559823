import React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import LegendIcon from "components/CustomComponents/LegendIcon/LegendIcon";
import { LegendEntry } from "pages/commonTypes";
import { LegendAction } from "pages/Performance/types";

export const LegendList = ({
    legend,
    dispatcher,
}: {
    legend: LegendEntry[];
    dispatcher: React.Dispatch<LegendAction>;
}) => {
    const stackTokens: IStackTokens = {
        childrenGap: 10, // Gap between children.
        padding: 10, // padding for the stack.
    };

    const onClickLegend = (selectedEntry: LegendEntry) => {
        dispatcher({
            type: "clickedEntry",
            payload: selectedEntry.key,
        });
    };

    // UI rendering logic.
    return (
        <Stack horizontalAlign="end">
            <Stack wrap horizontal horizontalAlign="end" tokens={stackTokens}>
                {legend.map((entry) => {
                    const legendIconColor = entry.hidden ? "#808080" : entry.color;
                    return (
                        <Stack
                            horizontal
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                onClickLegend(entry);
                            }}
                            key={entry.key}
                        >
                            <LegendIcon color={legendIconColor}></LegendIcon>
                            <span>{entry.Name}</span>
                        </Stack>
                    );
                })}
            </Stack>
            {legend.length === 0 ? (
                ""
            ) : (
                <span className="subscriptStyleBottom">
                    *Click on legend items to toggle line charts below.
                </span>
            )}
        </Stack>
    );
};
