import React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import AIScorecardFilters from "components/AIScorecardComponents/AIScorecardFilters";
import { CopilotTrends } from "components/AIScorecardComponents/CopilotTrends/CopilotTrends";
import { CopilotSummaryScorecard } from "components/AIScorecardComponents/CopilotViews/CopilotSummaryScorecard";
import { commonPageBlock } from "pages/common";

export const CopilotSummaryView = ({ dates, filters, onFilterChange }) => {
    return (
        <>
            <AIScorecardFilters
                dates={dates}
                filters={filters}
                onFilterChange={onFilterChange}
            />
            <Pivot linkSize={"normal"}>
                <PivotItem itemKey={"Scorecard"} headerText={"Scorecard"}>
                    <Stack.Item styles={commonPageBlock}>
                        <CopilotSummaryScorecard filters={filters} />
                    </Stack.Item>
                </PivotItem>
            </Pivot>
            <CopilotTrends filters={filters}></CopilotTrends>
        </>
    );
};
