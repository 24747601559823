import React from "react";
import {
    funnelColor,
    funnelHeight,
    funnelTextFill,
    funnelWidth,
    funnelXCoordinate,
    funnelYCoordinate,
    strokeColor,
} from "components/CommonFunnelComponents/CommonFunnelArrow/constants";

export const CommonFunnelArrow = ({ label }) => {
    return (
        <svg width={funnelWidth} height={funnelHeight}>
            <g transform="matrix(0.837624 0 0 0.793244 1.93069 -18.7281)">
                <path
                    stroke={strokeColor}
                    d="m0,421l82.49997,0l27.50004,19.49996l-27.50004,19.50005l-82.49997,0l27.50003,-19.50005l-27.50003,-19.49996z"
                    strokeWidth="0"
                    fill={funnelColor}
                />
                <text
                    transform="matrix(1 0 0 1 0 0)"
                    fontWeight="semibold"
                    textAnchor="start"
                    fontSize="14"
                    y={funnelYCoordinate}
                    x={funnelXCoordinate}
                    strokeWidth="0"
                    stroke={strokeColor}
                    fill={funnelTextFill}
                >
                    {label}
                </text>
            </g>
        </svg>
    );
};
