import React from "react";
import { IPivotItemProps, Icon, Pivot, PivotItem } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { config } from "components/AIScorecardComponents/config";
import { CopilotAppView } from "components/AIScorecardComponents/CopilotViews/CopilotAppView";
import { CopilotM365View } from "components/AIScorecardComponents/CopilotViews/CopilotM356View";
import { CopilotSummaryView } from "components/AIScorecardComponents/CopilotViews/CopilotSummaryView";
import { PivotSize, appStyles } from "utils/Constants";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";

const customRenderer = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null => {
    if (!link || !defaultRenderer) {
        return null;
    }

    return (
        <span style={{ flex: "0 1 100%" }}>
            <Icon iconName={link.itemIcon} className="appTabIcon" />
            {defaultRenderer({ ...link, itemIcon: undefined })}
        </span>
    );
};

const CopilotTabs = ({ onFilterChange, onTabSelected, filters, dates, tab }) => {
    return (
        <Pivot
            linkSize={PivotSize.large}
            onLinkClick={(item?: PivotItem) => {
                sendUserActionTelemetryEvent(
                    {
                        userActionName: "ApplicationTabSwitch",
                        commandSurface: UserActionCommandSurface.Header,
                    },
                    { SwitchedToApplication: item.props.itemKey as string },
                );
                onFilterChange("application", item.props.itemKey as string);
            }}
            selectedKey={filters.application}
            overflowBehavior="menu"
        >
            <PivotItem
                headerText="Summary"
                itemKey="Summary"
                key={0}
                itemIcon={appStyles["Microsoft"].iconName}
                onRenderItemLink={customRenderer}
            >
                <CopilotSummaryView
                    dates={dates}
                    filters={filters}
                    onFilterChange={onFilterChange}
                />
            </PivotItem>
            <PivotItem
                headerText="M365"
                itemKey="All"
                key={0}
                itemIcon={appStyles["All"].iconName}
                onRenderItemLink={customRenderer}
            >
                <CopilotM365View
                    dates={dates}
                    filters={filters}
                    onFilterChange={onFilterChange}
                    tab={tab}
                    onTabSelected={onTabSelected}
                />
            </PivotItem>
            {config.appsList.map((app, idx) => {
                return (
                    <PivotItem
                        headerText={app}
                        itemKey={app}
                        key={idx}
                        itemIcon={appStyles[app]?.iconName}
                        onRenderItemLink={customRenderer}
                    >
                        <CopilotAppView
                            dates={dates}
                            filters={filters}
                            onFilterChange={onFilterChange}
                            tab={tab}
                            onTabSelected={onTabSelected}
                        />
                    </PivotItem>
                );
            })}
        </Pivot>
    );
};

export default CopilotTabs;
