import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import { chartXAxisPaddingStyles } from "pages/common";
import { getChartColor } from "utils/Helpers";
import { COLLAB_STAGES } from "utils/Messages";

const renderLegend = (props) => {
    return props.split(" ")[0];
};

export const CollabStageChart = (props) => {
    let colorIndex = -1;
    return (
        <div className="collabStageTrend">
            <h4 className="commonPageBlockTitle">
                {"Collab Funnel Stages Over Time"}
            </h4>
            <LineChart
                width={500}
                height={370}
                data={props.payload}
                style={{ marginTop: "65px" }}
            >
                <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                <YAxis unit="%" domain={[0, 100]} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip allowEscapeViewBox={{ x: true, y: true }} />
                <Legend
                    formatter={renderLegend}
                    wrapperStyle={{
                        fontSize: "12px",
                        paddingLeft: "60px",
                        paddingTop: "20px",
                    }}
                />
                {Object.keys(COLLAB_STAGES)
                    .filter((i) => i !== "SignedInUsers")
                    .map((stage) => {
                        colorIndex += 1;
                        return (
                            <Line
                                type="monotone"
                                dataKey={`${stage} %`}
                                stroke={getChartColor(colorIndex)}
                                activeDot={{ r: 8 }}
                                key={stage}
                            />
                        );
                    })}
            </LineChart>
        </div>
    );
};
