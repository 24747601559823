import React from "react";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { OverviewAppsPanelProps } from "pages/ExecOverview/types";

export const OverviewAppsPanel = ({
    apps,
    crossApps,
    onAppClick,
    filterApp,
}: OverviewAppsPanelProps) => {
    return (
        <div>
            <Stack horizontal className="appPivotsPanel">
                <Stack.Item grow={1}>
                    <Pivot
                        linkSize={"large"}
                        onLinkClick={onAppClick}
                        selectedKey={filterApp}
                    >
                        {crossApps.map((el, idx) => {
                            return (
                                <PivotItem
                                    className="appPivot"
                                    headerText={el}
                                    itemKey={el}
                                    key={idx}
                                />
                            );
                        })}
                        {apps.map((el, idx) => {
                            return (
                                <PivotItem
                                    className="appPivot"
                                    headerText={el}
                                    itemKey={el}
                                    key={idx}
                                />
                            );
                        })}
                    </Pivot>
                </Stack.Item>
            </Stack>
        </div>
    );
};
