import {
    copilotReadinessScore,
    copilotDateOptions,
    copilotHealthScore,
    copilotReadinessHealthDistribution,
    copilotHealthHealthDistribution,
    copilotReadinessTenantStatus,
    copilotHealthTenantStatus,
    copilotTenantSearchBarList,
    copilotHealthScoreWXP,
    copilotHealthTenantStatusWXP,
} from "./CopilotExecOverviewQueries";
import {
    copilotProductDrilldownMetadata,
    copilotCoreReadinessMetrics,
    copilotCoreHealthMetrics,
    copilotHealthSparkLines,
    copilotReadinessSparkLines,
    copilotTenantAppCurrencyAndLicenseData,
    copilotTenantEnvironmentData,
    copilotAppMetrics,
    copilotAppMetricsChartData,
    copilotHealthScoreTenantList,
    copilotReadinessScoreTenantList,
    copilotHealthCohortAvg,
    copilotReadinessCohortAvg,
} from "./CopilotProductDrilldownQueries";

export const copilotQuries = {
    copilotReadinessScore,
    copilotDateOptions,
    copilotHealthScore,
    copilotReadinessHealthDistribution,
    copilotHealthHealthDistribution,
    copilotReadinessTenantStatus,
    copilotHealthTenantStatus,
    copilotProductDrilldownMetadata,
    copilotCoreReadinessMetrics,
    copilotCoreHealthMetrics,
    copilotHealthSparkLines,
    copilotReadinessSparkLines,
    copilotTenantAppCurrencyAndLicenseData,
    copilotTenantEnvironmentData,
    copilotAppMetrics,
    copilotAppMetricsChartData,
    copilotHealthScoreTenantList,
    copilotReadinessScoreTenantList,
    copilotHealthCohortAvg,
    copilotReadinessCohortAvg,
    copilotTenantSearchBarList,
    copilotHealthScoreWXP,
    copilotHealthTenantStatusWXP,
};
