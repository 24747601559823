import { IDropdownOption, MessageBarType } from "@fluentui/react";
import {
    HarmonyJourneyFunnelCohortNames,
    eRFMTimeWindows,
} from "components/CommonFunnelComponents/CommonFunnelFilter/constants";
import { CommonConfigType, Workload } from "config/PlatformConfig";
import { CONSUMERFUNNELS_MESSAGES, COPILOT_MESSAGES } from "utils/Messages";

// START HERE TO ADD A NEW FUNNEL (7 STEPS TOTAL):
// Step 1: Create a <yourFunnelType>FunnelQueries.ts file in src/queries/<appropriateFolder>
//         You can use harmonyFunnelQueries.ts as reference.
//         You will need to provide 3 queries:
//         a) <yourFunnelType>FunnelDates
//         b) <yourFunnelType>FunnelStages
//         c) <yourFunnelType>FunnelAdditionalPivots
// Step 2: Add the names of your queries to your corresponding src/queries/<appropriateFolder>/index.ts
//         You may reference src/queries/HarmonyFunnel/index.ts.

// Step 3: Create a new funnel type for your funnel
export enum MilestoneFunnelType {
    Harmony = "Harmony",
    AHP = "AHP",
    UHP = "UHP",
}

export enum CopilotFunnelType {
    UserJourney = "CopilotUserJourney",
}

export enum eRFMFunnelType {
    Upsell = "Upsell",
}

// Step 4: Add your funnel type here
export type FunnelType = MilestoneFunnelType | CopilotFunnelType | eRFMFunnelType;

export enum FunnelQueryType {
    Dates = "Dates",
    Stages = "Stages",
    AdditionalPivots = "AdditionalPivots",
    Decomposition = "Decomposition",
}

export interface FunnelFilterBasic {
    filterKey: string;
    label: string;
    selectedKey?: string;
}

export interface FunnelFilter extends FunnelFilterBasic {
    options: IDropdownOption[];
}

export interface FunnelAdditionalFilter extends FunnelFilterBasic {
    kustoColumnIndex: number;
}

export interface FunnelAppBasedTableName {
    WXP: string;
    Word: string;
    Excel: string;
    PowerPoint: string;
}

// Please avoid adding fields specific to your funnel here, as this is a common interface
export interface FunnelConfigType extends CommonConfigType {
    displayName: string; // Friendly name displayed in historical trends panel as such: ${displayName} Funnel
    defaultStage: string; // First stage in your funnel, chart labels won't be shown for this stage
    requiredFilters?: FunnelFilter[]; // Filters to show up on filter panel at all times
    additionalFilters?: FunnelAdditionalFilter[]; // Filters hidden behind the Additional Filters button
    dataPlatform: Workload; // Used in Kusto queries to determine cluster value
    tableName?: string | FunnelAppBasedTableName; // Table name for Kusto queries or object of table names for each app
    decompositionStages?: string[]; // Optional array of stage names for funnel decomposition metrics
    decompositionVersion?: number; // Optional version number for decomposition queries
    decompositionFooter?: string; // Optional footer string for metrics decomposition pane
    messageBars?: { message: string; type: MessageBarType }[]; // Optional message bars to show up on the page
    noDataMessage?: string; // Optional custom message to show when no data is available.
    doraDashboardId?: string; // Optional Dora dashboardId. If a doraDashboardId is added, a link to the Dora Dashboard will to show up in the Funnel filter bar.
    funnelDocumentationLink?: string; // Optional link to funnel documentation. Will be shown on the funnel dashboard header if it is not empty.
}

export type FunnelTypeConfigType = {
    [key in FunnelType]: FunnelConfigType;
};

// Step 5: Add your funnel type to the FunnelConfig object and fill in those properties
export const FunnelConfig: FunnelTypeConfigType = {
    [MilestoneFunnelType.Harmony]: {
        dataPlatform: Workload.WEB,
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer Web Funnels",
        pageHeader: "Consumer Web Funnels",
        pageToolNameForTelemetry: "Harmony Consumer Web Funnel",
        defaults: {
            app: "Word",
        },
        displayName: "Harmony",
        defaultStage: "All Users",
        requiredFilters: [
            {
                filterKey: "cohort",
                label: "Cohort",
                selectedKey: "Free New Harmony Users",
                options: HarmonyJourneyFunnelCohortNames,
            },
        ],
        additionalFilters: [
            {
                filterKey: "appPlatform",
                label: "Harmony Platform",
                kustoColumnIndex: 0,
            },
            {
                filterKey: "consumerCountry",
                label: "User Country",
                kustoColumnIndex: 1,
            },
            {
                filterKey: "documentScenario",
                label: "Document Scenario",
                kustoColumnIndex: 3,
            },
            {
                filterKey: "consumerLocale",
                label: "User Locale",
                kustoColumnIndex: 2,
            },
            {
                filterKey: "isDay0Event",
                label: "Harmony Newness",
                selectedKey: "Day0",
                kustoColumnIndex: 5,
            },
            {
                filterKey: "isNewToWXP",
                label: "WXP Newness",
                selectedKey: "New",
                kustoColumnIndex: 4,
            },
            { filterKey: "host", label: "WAC Host", kustoColumnIndex: 6 },
            {
                filterKey: "referralCategory",
                label: "Referral Category",
                kustoColumnIndex: 7,
            },
        ],
        messageBars: [
            {
                message: CONSUMERFUNNELS_MESSAGES.HARMONY_DATA_AVAILABILITY,
                type: MessageBarType.warning,
            },
        ],
        tableName: {
            Excel: "HarmonyFunnelView_Excel",
            PowerPoint: "HarmonyFunnelView_PowerPoint",
            Word: "HarmonyFunnelView_Word",
            WXP: "HarmonyFunnelView_WXP",
        },
        decompositionStages: ["Stage 1 to 2", "Stage 2 to 3", "Stage 3 to 4"],
        decompositionVersion: 1,
        decompositionFooter:
            "• Top available insights for browser and country pivots",
    },
    [MilestoneFunnelType.AHP]: {
        dataPlatform: Workload.WEB,
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer Web Funnels",
        pageHeader: "App Auth Funnel",
        pageToolNameForTelemetry: "Authenticated App Home Pages Consumer Web Funnel",
        defaults: {
            app: "Word",
        },
        displayName: "AHP",
        defaultStage: "All Users",
        tableName: "AHP",
        doraDashboardId: "612",
        additionalFilters: [
            {
                filterKey: "ReferralCategory",
                label: "Referral Category",
                selectedKey: "All",
                kustoColumnIndex: 7,
            },
            {
                filterKey: "DeploymentEnvironment",
                label: "Deployment Environment",
                kustoColumnIndex: 0,
            },
            {
                filterKey: "ComputedLicense",
                label: "LicenseModel",
                kustoColumnIndex: 1,
            },
            {
                filterKey: "RegisteredLocale",
                label: "Locale",
                kustoColumnIndex: 2,
            },
            {
                filterKey: "RegisteredCountry",
                label: "Country",
                kustoColumnIndex: 3,
            },
            {
                filterKey: "Platform",
                label: "Platform",
                kustoColumnIndex: 4,
            },
            // {
            //     filterKey: "IsNewToWXP",
            //     label: "Newness",
            //     kustoColumnIndex: 5,
            // },
            // {
            //     filterKey: "IsDay0Event",
            //     label: "Day 0",
            //     kustoColumnIndex: 6,
            // },
        ],
        decompositionStages: [
            "Stage 1 to 2",
            "Stage 2 to 3",
            "Stage 3 to 4",
            "Stage 4 to 5",
        ],
        decompositionVersion: 2,
        funnelDocumentationLink:
            "https://office.visualstudio.com/OC/_wiki/wikis/EH%20Fuel%20Insights/103580/App-Home-Pages-Funnels",
        messageBars: [
            {
                message: CONSUMERFUNNELS_MESSAGES.WORK_IN_PROGRESS,
                type: MessageBarType.warning,
            },
        ],
    },
    [MilestoneFunnelType.UHP]: {
        dataPlatform: Workload.WEB,
        appsList: ["Word", "Excel", "PowerPoint"],
        pageTitle: "Consumer Web Funnels",
        pageHeader: "App Unauth Funnel",
        pageToolNameForTelemetry:
            "Unauthenticated App Home Pages Consumer Web Funnel",
        defaults: {
            app: "Word",
        },
        displayName: "UHP",
        defaultStage: "All Users",
        tableName: "UHP",
        messageBars: [
            {
                message: CONSUMERFUNNELS_MESSAGES.WORK_IN_PROGRESS,
                type: MessageBarType.warning,
            },
        ],
    },
    [CopilotFunnelType.UserJourney]: {
        dataPlatform: Workload.COPILOT,
        appsList: [],
        pageTitle: "Copilot User Journey",
        pageHeader: "Copilot User Journey",
        pageToolNameForTelemetry: "Copilot User Journey Funnel",
        displayName: "Copilot User Journey",
        defaultStage: "PurchasedUnits",
        defaults: { app: "Word" },
        noDataMessage: COPILOT_MESSAGES.USERJOURNEYFUNNEL_NO_DATA_MESSAGE,
    },
    [eRFMFunnelType.Upsell]: {
        dataPlatform: Workload.WEB,
        appsList: ["WXP", "Word", "Excel", "PowerPoint"],
        pageTitle: "eRFM Funnels",
        pageHeader: "eRFM Upsell Funnel",
        pageToolNameForTelemetry: "eRFM Upsell Funnel",
        defaults: {
            app: "WXP",
        },
        displayName: "eRFM Upsell",
        defaultStage: "All Users",
        requiredFilters: [
            {
                filterKey: "intervalWindow",
                label: "Interval Window",
                selectedKey: "R28D",
                options: eRFMTimeWindows,
            },
        ],
        additionalFilters: [
            {
                filterKey: "country",
                label: "Country",
                kustoColumnIndex: 2,
            },
            {
                filterKey: "dimeEntryPoint",
                label: "Dime Entry Point",
                kustoColumnIndex: 0,
            },
            {
                filterKey: "eRFMEntryPoint",
                label: "eRFM Entry Point",
                kustoColumnIndex: 1,
            },
        ],
        tableName: "eRFMUpsell",
    },
};

export const getFunnelConfig = (funnelPage: FunnelType) => {
    return FunnelConfig[funnelPage];
};

// STEP 6: Add CommonFunnel to your component
//         You can either use <CommonFunnel> as the Harmony funnel does in ConsumerFunnelWrapper.tsx
//         Or directly use <CommonFunnelStages> as the Copilot funnel does in CopilotJourneyFunnel.tsx

// STEP 7: Test your funnel in the UI
//         Your funnel may have particularities that require you to delve deeper into the code,
//         but by this point you should have your funnel up and running.
