import React from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    Text,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { chartXAxisPaddingStyles } from "pages/common";
import { HealthDistributionTrendChartProps } from "pages/ExecOverview/types";
import { formatDisplayDate } from "utils/Helpers";
import { STATUS } from "utils/WebConstants";

export const HealthDistributionTrendChart = (
    props: HealthDistributionTrendChartProps,
) => {
    const data = [];
    const timePeriod = {};
    props.payload.rows.forEach((element) => {
        if (element[0] !== "2018-11-01") {
            const date = element[0];
            const status = element[1];
            if (!timePeriod[date]) {
                timePeriod[date] = { Date: formatDisplayDate(date) };
            }
            timePeriod[date][status] = element[2];
        }
    });
    Object.keys(timePeriod).forEach((date) => {
        data.push(timePeriod[date]);
    });
    return (
        <>
            <h2 className="commonPageBlockTitle"> {props.title} </h2>
            <LineChart
                width={600}
                height={350}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                <YAxis label={<CustomizedLabel />} />
                <Tooltip />
                {Object.keys(STATUS).map((key) => (
                    <Line
                        name={`${key} Tenants`}
                        type="monotone"
                        dataKey={key}
                        stroke={STATUS[key]["color"]}
                        key={key}
                        // When there is a single data point, linechart does not show the active dot
                        // Workaround: https://github.com/recharts/recharts/issues/149
                        isAnimationActive={data.length !== 1}
                    />
                ))}
            </LineChart>
        </>
    );
};

const CustomizedLabel = () => {
    return (
        <Text dx={-225} dy={40} transform="rotate(-90)">
            # Tenants
        </Text>
    );
};
