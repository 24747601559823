export const harmonyFunnelDates: string = `
// HarmonyFunnelDates
database('ConsumerInsights').\${tableName}
| distinct SliceDate
| project SliceDate = format_datetime(SliceDate, "yyyy-MM-dd")
| order by SliceDate desc
`;

export const harmonyFunnelStages: string = `
// Harmony Funnel Stages
let matchAllRegex = ".*";
let cohort = "\${cohort}";
let scenario = "\${documentScenario}";
let cohortToPivotsMapping = database('ConsumerInsights').HarmonyCohortToPivotsMapping | where CohortName == cohort;
let scenarioToPivotsMapping = database('ConsumerInsights').HarmonyScenarioToPivotsMapping | where ScenarioName == scenario;
let harmonyPlatformFilter = "\${appPlatform}";
let registeredCountryFilter = "\${consumerCountry}";
let isFromTemplateFilter = toscalar(scenarioToPivotsMapping | project IsFromTemplate);
let isOpenFilter = toscalar(scenarioToPivotsMapping | project IsOpen);
let isSharedFilter = toscalar(scenarioToPivotsMapping | project IsShared);
let registeredLocaleFilter = "\${consumerLocale}";
let isDay0EventFilter = "\${isDay0Event}";
let referralCategoryFilter = "\${referralCategory}";
let isNewToWXPFilter = "\${isNewToWXP}";
let hostFilter = "\${host}";
let paymentTypeCohortFilter = toscalar(cohortToPivotsMapping | project PaymentTypeCohort);
let harmonyUserSeenTypeFilter = toscalar(cohortToPivotsMapping | project HarmonyUserSeenType);
let registeredCountryRegex = iff(registeredCountryFilter == "All", matchAllRegex, strcat("^", registeredCountryFilter, "$"));
let registeredLocaleRegex = iff(registeredLocaleFilter == "All", matchAllRegex, strcat("^", registeredLocaleFilter, "$"));
let paymentTypeCohortRegex = iff(paymentTypeCohortFilter == "All", matchAllRegex, strcat("^", paymentTypeCohortFilter, "$"));
let harmonyUserSeenTypeRegex = iff(harmonyUserSeenTypeFilter == "All", matchAllRegex, strcat("^", harmonyUserSeenTypeFilter, "$"));
let isNewToWXPRegex = iff(isNewToWXPFilter == "All", matchAllRegex, strcat("^", isNewToWXPFilter, "$"));
let appData =
    database('ConsumerInsights').\${tableName}
    | extend WAC_Host = iff(isempty(WAC_Host), "All", WAC_Host)
    | where HarmonyPlatform == harmonyPlatformFilter
        and IsDay0Event == isDay0EventFilter
        and ReferralCategory == referralCategoryFilter
    | where PaymentTypeCohort matches regex paymentTypeCohortRegex
        and HarmonyUserSeenType matches regex harmonyUserSeenTypeRegex
        and RegisteredCountry matches regex registeredCountryRegex
        and RegisteredLocale matches regex registeredLocaleRegex
        and IsNewToWXP matches regex isNewToWXPRegex
    | where iff(MetricName in ("1-HarmonyNewUsers", "2-WXPReferralClick"), WAC_Host == "All", WAC_Host == hostFilter)
    | where iff(MetricName == "1-HarmonyNewUsers", IsFromTemplate == "All", IsFromTemplate == isFromTemplateFilter )
    | where iff(MetricName == "1-HarmonyNewUsers", IsOpen == "All", IsOpen == isOpenFilter )
    | where iff(MetricName in ("1-HarmonyNewUsers", "2-WXPReferralClick"), IsSharedDoc == "All", IsSharedDoc == isSharedFilter )
    | summarize Count = sum(PuidDistinctCount) by ReceivedDate, MetricName, HarmonyPlatform, IsFromTemplate, IsOpen, IsSharedDoc, IsDay0Event, ReferralCategory
    | extend
        RegisteredCountry = registeredCountryFilter,
        RegisteredLocale = registeredLocaleFilter,
        HarmonyUserSeenType = harmonyUserSeenTypeFilter,
        PaymentTypeCohort = paymentTypeCohortFilter,
        IsNewToWXP = isNewToWXPFilter
    | order by ReceivedDate desc, MetricName asc;
let defaultDenominator = toscalar(appData | project Count);
let AllStages =
    appData
    | union
        (
        database('ConsumerInsights').\${tableName}_M1Retention
        | extend WAC_Host = iff(isempty(WAC_Host), "All", WAC_Host)
        | where MetricName == "5-M1Retention"
        | extend ReceivedDate = ReceivedDate - 28d
        | where ReceivedDate >= datetime(2023-11-18)
        | where HarmonyPlatform == harmonyPlatformFilter
            and IsFromTemplate == isFromTemplateFilter
            and IsOpen == isOpenFilter
            and IsSharedDoc == isSharedFilter
            and IsDay0Event == "All"
            and ReferralCategory == referralCategoryFilter
            and WAC_Host == hostFilter
        | where PaymentTypeCohort matches regex paymentTypeCohortRegex
            and HarmonyUserSeenType matches regex harmonyUserSeenTypeRegex
            and RegisteredCountry matches regex registeredCountryRegex
            and RegisteredLocale matches regex registeredLocaleRegex
            and IsNewToWXP matches regex isNewToWXPRegex
        | summarize Count = sum(PuidDistinctCount) by ReceivedDate, MetricName, HarmonyPlatform, IsFromTemplate, IsOpen, IsSharedDoc, IsDay0Event, ReferralCategory
        | extend
            RegisteredCountry = registeredCountryFilter,
            RegisteredLocale = registeredLocaleFilter,
            HarmonyUserSeenType = harmonyUserSeenTypeFilter,
            PaymentTypeCohort = paymentTypeCohortFilter,
            IsNewToWXP = isNewToWXPFilter
        | project
            ReceivedDate,
            MetricName,
            HarmonyPlatform,
            IsFromTemplate,
            IsOpen,
            IsSharedDoc,
            IsDay0Event,
            ReferralCategory,
            Count,
            RegisteredCountry,
            RegisteredLocale,
            HarmonyUserSeenType,
            PaymentTypeCohort,
            IsNewToWXP);
database('ConsumerInsights').\${tableName}_FunnelConfig
| extend JoinKey = 1
| join kind=fullouter (AllStages
    | project Date = format_datetime(ReceivedDate, "yyyy-MM-dd"), JoinKey = 1)
    on JoinKey
| summarize count()
    by
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    Application,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    ToolTipMessage
| project-away count_
| join kind=fullouter  (
    AllStages
    | extend
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        StageName = MetricName,
        Value = Count
    | order by Date desc
    )
    on StageName, Date
| where isnotempty(Date)
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    Value,
    ToolTipMessage,
    HarmonyPlatform,
    RegisteredCountry,
    IsFromTemplate,
    IsOpen,
    IsSharedDoc,
    IsDay0Event,
    ReferralCategory,
    RegisteredLocale,
    PaymentTypeCohort,
    HarmonyUserSeenType,
    IsNewToWXP
| extend joinKey = DenominatorColumn
| join kind=leftouter (
    AllStages
    | extend
        joinKey = MetricName,
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        Total = Count
    | project Date, joinKey, Total
    )
    on joinKey, Date
| extend Total = iff(isempty(Total) or isnull(Total), defaultDenominator, Total)
| extend Percentage = round(100.0 * todouble(Value) / todouble(Total), 2)
| where isnotempty(Percentage) or CanBeNull == true
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    Value,
    Total,
    Percentage,
    ToolTipMessage,
    HarmonyPlatform,
    RegisteredCountry,
    IsFromTemplate,
    IsOpen,
    IsSharedDoc,
    IsDay0Event,
    ReferralCategory,
    RegisteredLocale,
    PaymentTypeCohort,
    HarmonyUserSeenType,
    IsNewToWXP
| order by Date desc, StageIndex asc`;

export const harmonyFunnelFilterChoices: string = `
// Harmony Funnel Additional Pivots Choices
let AllFilterOptions = datatable(RegisteredCountry: string, RegisteredLocale: string, IsNewToWXP: string) [
    "All", "All", "All"
];
let HarmonyPlatform=database('ConsumerInsights').\${tableName}
| distinct HarmonyPlatform;
let RegisteredCountry=database('ConsumerInsights').\${tableName}
| distinct RegisteredCountry;
let RegisteredLocale=database('ConsumerInsights').\${tableName}
| distinct RegisteredLocale;
let docTypeScenario=database('ConsumerInsights').HarmonyScenarioToPivotsMapping
| distinct ScenarioName;
let IsDay0Event=database('ConsumerInsights').\${tableName}
| distinct IsDay0Event;
let IsNewToWXP=database('ConsumerInsights').\${tableName}
| distinct IsNewToWXP;
let WAC_Host=database('ConsumerInsights').\${tableName}
| distinct WAC_Host;
let ReferralCategory=database('ConsumerInsights').\${tableName}
| distinct ReferralCategory;
HarmonyPlatform
| union RegisteredCountry
| union RegisteredLocale
| union docTypeScenario
| union AllFilterOptions
| union IsDay0Event
| union IsNewToWXP
| union WAC_Host
| union ReferralCategory
| project HarmonyPlatform, RegisteredCountry, RegisteredLocale, ScenarioName, IsNewToWXP, IsDay0Event, WAC_Host, ReferralCategory
`;

export const harmonyFunnelDecomposition: string = `
let cohort = "\${cohort}";
let cohortToPivotsMapping = database('ConsumerInsights').HarmonyCohortToPivotsMapping | where CohortName == cohort;
let isDay0EventFilter = "\${isDay0Event}";
let isNewToWXPFilter = "\${isNewToWXP}";
let hostFilter = "\${host}";
let paymentModelFilter = toscalar(cohortToPivotsMapping | project PaymentTypeCohort);
let harmonyUserSeenTypeFilter = toscalar(cohortToPivotsMapping | project HarmonyUserSeenType);
let selectSpecificStage = "\${selectedStage}" != "All";
let stage = "\${selectedStage}";
let dt = "\${date}";
let appName = "\${application}";
let country = "\${consumerCountry}";
let selectSpecificCountry = country !in ("All");
database('ConsumerInsights').HarmonyFunnelDecomp
| where iff(selectSpecificStage, tostring(Stage) == stage, true)
| where Date == dt
| where AppName == appName
| where IsDay0Event == isDay0EventFilter
| where IsNewToWxp == isNewToWXPFilter
| where WacHost == hostFilter
| where PaymentModel == paymentModelFilter
| where HarmonyUserSeenType == harmonyUserSeenTypeFilter
| where Country !in ("Unknown", "Other")
| where (selectSpecificCountry == false and (Browser != "All" and Country != "All")) or (selectSpecificCountry and (Country == country and Browser != "All"))
| where BetweenStageLossPctToStage >= 0.01
| project Country, Browser, Stage, BetweenStageLossPctToStage, BetweenStageLossPctToGroup, MetricName="StageLossToStagePct"
| top 10 by BetweenStageLossPctToStage desc
| union (database('ConsumerInsights').HarmonyFunnelDecomp
    | where iff(selectSpecificStage, tostring(Stage) == stage, true)
    | where Date == dt
    | where AppName == appName
    | where IsDay0Event == isDay0EventFilter
    | where IsNewToWxp == isNewToWXPFilter
    | where WacHost == hostFilter
    | where PaymentModel == paymentModelFilter
    | where HarmonyUserSeenType == harmonyUserSeenTypeFilter
    | where (selectSpecificCountry == false and (Browser != "All" and Country != "All")) or (selectSpecificCountry and (Country == country and Browser != "All"))
    | where BetweenStageLossPctToStage >= 0.01
    | where Country !in ("Unknown", "Other")
    | project Country, Browser, Stage, BetweenStageLossPctToStage, BetweenStageLossPctToGroup, MetricName="StageLossToGroupPct"
    | top 10 by BetweenStageLossPctToGroup desc)
| extend CountryString=iff(Country == "All", "", strcat("Country: ", Country)), BrowserString=iff(Browser == "All", "", strcat("Browser: ", Browser))
| project Text=strcat(CountryString, iff(isnotempty(CountryString),", ",""), BrowserString), Stage, BetweenStageLossPctToStage=(100.0*BetweenStageLossPctToStage), BetweenStageLossPctToGroup=(100.0*BetweenStageLossPctToGroup), MetricName
`;
