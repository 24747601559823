export const macMetaData: string = `
//macMetaData (populate list of cohorts the tenant belongs to, on tiles)
let dt = "\${date}";
let app = "\${app}";
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") }; 
StrategicTenants
| where Date == toscalar(StrategicTenants|summarize max(Date)) 
| where \${levelColumnName} == "\${id}" 
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO
| project \${levelColumnName},
                TenantName,
                MSSalesTopParentOrgName=OrgName,
                IsS500,
                IsEPA,
                IsS2500,
                IsGoogleMove,
                IsGov,
                IsEO
| limit 1
|join kind = leftouter (
    HealthyTenantScore_MacAppView
    | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}" and ProcessDate == dt and SubWorkload == app
    | extend Current_Status=GetColor(Color)
    ) on \${levelColumnName}
| join kind=leftouter (
    HealthyTenantScore_MacAppView 
    | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}" and ProcessDate == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and SubWorkload == app
    | project \${levelColumnName}, PrevMonthScore = round(Score, 2)
    ) on \${levelColumnName}
| project Parent = Tpid,
            \${levelColumnName},
            OrgName=iif("\${level}" == "Tpid", MSSalesTopParentOrgName, TenantName),
            Score=round(Score, 2),
            Current_Status,
            PastStatus,
            ScoreMoMChange=round(Score - PrevMonthScore, 2),
            IsS500,
            IsEPA,
            IsS2500,
            IsGoogleMove,
            IsGov,
            IsEO`;
