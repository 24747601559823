import { isTest } from "utils/WebConstants";

export const dateFilters: string = `
let currDate = GetCurrentDate(${isTest});
    Reliability
    | where Application=="WXP" and UiHost == "All"
    | summarize make_set(Date)
    | mv-expand Date= set_Date
    | where todatetime(Date) <= todatetime(currDate)
    | project Date = tostring(Date)
    | order by Date desc
    | top 18 by Date`;
