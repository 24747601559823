import isEmpty from "lodash/isEmpty";
import { schemeTableau10 } from "d3-scale-chromatic";
import * as API from "api";
import {
    formatCoreMetrics,
    formatAppMetrics,
    formatScoreLines,
} from "api/commonApiHandler";
import { NotesCosmosDbAccess } from "api/CosmosDbAccess";
import { TenantTitleMetadata } from "components/CustomComponents/types";
import { platformsConfig, Workload } from "config/PlatformConfig";
import {
    KustoResponseType,
    MetricsResponseTableType,
    MetricsResponseType,
    WorkloadMetricFilterState,
} from "pages/commonTypes";
import {
    parseScoreBreakdown,
    getTenantMetadataTemplate,
} from "pages/InProductDrillDown/InProductDrillDownHelper";
import {
    TenantEnvironmentType,
    WorkloadOfficeTenantScoreType,
    WorkloadTenantState,
} from "pages/InProductDrillDown/types";
import { logException } from "utils/AppInsightsHelper";
import { tenantEnvironmentMetricsType } from "utils/Constants";
import { appStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import {
    calculateOffset,
    formatStatusColors,
    transformUserFrequencyData,
    isTenantExempt,
    getChartColorPastel2,
} from "utils/Helpers";
import { Filters, continentCodeToName } from "utils/WebConstants";
import { OSCustomerNameMapping } from "utils/Win32Constants";
import geojson from "utils/world.json";

const nameToISOMapping = new Map<string, string>();
// ISO to name mapping is used in Win32, as the data holds countryCodes instead of long names.
const ISOtoNameMapping = new Map<string, string>();
const isoA3ToA2Mapping = new Map<string, string>();
const geoDataMapping = new Map<string, any>();
geojson.features.forEach((entry) => {
    geoDataMapping.set(entry.properties.ISO_A2, entry);
    nameToISOMapping.set(entry.properties.NAME_LONG, entry.properties.ISO_A2);
    ISOtoNameMapping.set(entry.properties.ISO_A2, entry.properties.NAME_LONG);
    isoA3ToA2Mapping.set(entry.properties.ISO_A3, entry.properties.ISO_A2);
});

/*
    Extending the SPOFarm data to be formatted like other location data, with data for each app.

    Adding special mapping for SPO farm location. 
    Locations LATAM, APAC and EMEA are mapped to the countries in the geographical center of respective locations. 
    This is done to properly place the bubbles in the map.
*/
export const geoCenterMappingForSpoFarm = {};
geoCenterMappingForSpoFarm["LATAM"] = "BR";
geoCenterMappingForSpoFarm["APAC"] = "CN";
geoCenterMappingForSpoFarm["EMEA"] = "BY";
geoCenterMappingForSpoFarm["MSIT"] = "US";

export const formatOfficeTenantScore = (
    officeTenantScoreJson: MetricsResponseType,
    dates: string[],
    metricFilters: WorkloadMetricFilterState,
    workload: string,
) => {
    const scoreTable: MetricsResponseTableType = officeTenantScoreJson?.Tables[0];

    const officeTenantData: WorkloadOfficeTenantScoreType = {
        score: 0,
        status: "",
        momChange: 0,
        pastStatus: [],
        scoreBreakDown: [],
    };
    if (scoreTable?.Rows && scoreTable.Rows.length > 0) {
        officeTenantData["score"] = scoreTable.Rows[0][0];
        officeTenantData["status"] = scoreTable.Rows[0][1];
        officeTenantData["momChange"] = scoreTable.Rows[0][2];
        let offset = 0;
        if (!isEmpty(dates) && workload === Workload.WIN32) {
            offset = calculateOffset(metricFilters["Date"], dates);
        }
        const pastStatus = formatStatusColors(scoreTable.Rows[0][3], offset);
        officeTenantData["pastStatus"] = pastStatus;
        officeTenantData["scoreBreakDown"] = parseScoreBreakdown(
            scoreTable.Rows[0][4],
        );
    }
    return officeTenantData;
};

export const formatMetaData = (
    metaDataJson: MetricsResponseType,
    workload: string,
    dates?: string[],
    metricFilter?: WorkloadMetricFilterState,
) => {
    const metadataTable: MetricsResponseTableType = metaDataJson.Tables[0];
    if (metadataTable.Rows.length === 0) {
        return getTenantMetadataTemplate();
    }
    let offset = 0;
    if (!isEmpty(dates) && workload === Workload.WIN32) {
        offset = calculateOffset(metricFilter["Date"], dates);
    }
    const pastStatus: string[] =
        workload !== Workload.WEB && workload !== Workload.TEAMS
            ? formatStatusColors(metadataTable.Rows[0][5], offset)
            : metadataTable.Rows[0][5];
    const metadata: TenantTitleMetadata = {
        tpid: metadataTable.Rows[0][0],
        level: metadataTable.Rows[0][1],
        orgName: metadataTable.Rows[0][2],
        score: metadataTable.Rows[0][3],
        status: metadataTable.Rows[0][4],
        pastStatusSet: pastStatus,
        momScore: metadataTable.Rows[0][6],
        cohorts: {
            isS500: metadataTable.Rows[0][7],
            isEpa: metadataTable.Rows[0][8],
            isS2500: metadataTable.Rows[0][9],
            isGoogleMove: metadataTable.Rows[0][10],
            isGov: metadataTable.Rows[0][11],
            isEo: metadataTable.Rows[0][12],
            isCopilot: metadataTable.Rows[0][13],
        },
    };
    return metadata;
};

const transformAppModeData = (data: {} = {}) => {
    const flattenedAppModeData = [];
    Object.keys(data).forEach((app) => {
        flattenedAppModeData.push({ Application: app, ...data[app] });
    });
    return flattenedAppModeData;
};

const getChartColor = (i) => {
    return schemeTableau10[i];
};
// Need because we need to sort and then select color for each
const transformSessionOrigin = (data: {} = {}) => {
    const flattenedSessionOriginData = {};
    Object.keys(data).forEach((application) => {
        flattenedSessionOriginData[application] = [];
        let i = 0;
        Object.keys(data[application])
            .filter((name) => name !== "total") // We put total while parsing data, dont need to put that as pie sector
            .forEach((name) => {
                flattenedSessionOriginData[application].push({
                    name,
                    value: data[application][name],
                    fill: getChartColor(i % 10),
                    total: data[application]["total"],
                });
                i += 1;
            });
    });

    return flattenedSessionOriginData;
};

function transformContinentData(data: {} = {}) {
    const flattenedContinentData = [];

    let totalSessions = 0;

    Object.keys(data).forEach((continent) => {
        totalSessions += data[continent];
    });

    Object.keys(data).forEach((continent) => {
        const percent = (data[continent] / totalSessions) * 100;

        if (percent > 0.01)
            flattenedContinentData.push({
                continent: continentCodeToName[continent],
                value: percent,
            });
    });

    return flattenedContinentData;
}

const getGeoDistributionData = (miscellaneous: {}, workload: string) => {
    const geographicDistribution = {};
    let geoDistributionTypes: { [key: string]: string } = {};
    switch (workload) {
        case Workload.WEB:
            geoDistributionTypes = {
                "SPO Location": "SPOFarm",
                Location: "Location",
                WacDatacenter: "WacDatacenter",
            };
            break;
        case Workload.WIN32:
        case Workload.MAC:
        default:
            geoDistributionTypes = { Location: "Location" };
            break;
    }

    Object.keys(geoDistributionTypes).forEach((distributionType) => {
        geographicDistribution[geoDistributionTypes[distributionType]] =
            miscellaneous[distributionType];
    });

    return geographicDistribution;
};

export const formatTenantEnvironmentData = (
    tenantEnvironmentDataJson: MetricsResponseType,
    geoDataMappingSelected: Map<string, {}>,
    workload: string,
) => {
    const tenantEnvironmentTable: MetricsResponseTableType =
        tenantEnvironmentDataJson.Tables[0];
    const miscellaneous = {};
    const appModePayload = {};
    const appModes = {};
    let colorIndex = 0;
    const spoData = [];

    // Schema - Date, OmsTenantId, Tpid, Application, Metric, Name, Value, Total
    tenantEnvironmentTable.Rows.forEach((element) => {
        const date = element[0];
        const metric = element[4];
        const application = element[3];
        const name = element[5] === "" ? "NA" : element[5];
        const value = element[6];
        const total = element[7] ?? 1;
        const percent = Number(((value / total) * 100).toFixed(2));

        switch (metric) {
            case tenantEnvironmentMetricsType.OS:
            case tenantEnvironmentMetricsType.Currency:
            case tenantEnvironmentMetricsType.Browser:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};

                if (!(date in miscellaneous[metric]))
                    miscellaneous[metric][date] = {};

                if (value > 0.005 && metric === "OS") {
                    const osName = OSCustomerNameMapping[name] || name;

                    // TODO: Remove this platform condition after data format for OS is consistant
                    // Web has OS value multiplied by 100 in Data where as other platforms don't.
                    if (workload == Workload.WEB) {
                        miscellaneous[metric][date][osName] = {
                            value:
                                +(miscellaneous[metric][date][osName]?.value ?? 0) +
                                Math.round((value / 100) * total),
                            percentage:
                                +(
                                    miscellaneous[metric][date][osName]
                                        ?.percentage ?? 0
                                ) + +value || +value,
                        };
                    } else {
                        miscellaneous[metric][date][osName] = {
                            value:
                                +(miscellaneous[metric][date][osName]?.value ?? 0) +
                                Math.round(value * total),
                            percentage:
                                +(
                                    miscellaneous[metric][date][osName]
                                        ?.percentage ?? 0
                                ) + Math.round(value * 100.0),
                        };
                    }
                }

                if (metric === tenantEnvironmentMetricsType.Currency) {
                    miscellaneous[metric][date][name] = {
                        value: Math.round((value / 100) * total),
                        percentage: value,
                    };
                }
                if (metric === tenantEnvironmentMetricsType.Browser) {
                    if (value > 0.5)
                        miscellaneous[metric][date][name] = {
                            value: Math.round((value / 100) * total),
                            percentage: value,
                        };
                }
                break;
            case tenantEnvironmentMetricsType.OpenDocument:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                if (!(name in miscellaneous[metric]))
                    miscellaneous[metric][name] = {};

                miscellaneous[metric][name][application] = value;
                break;
            case tenantEnvironmentMetricsType.AppMode:
                if (!(application in appModePayload))
                    appModePayload[application] = {};

                if (!(name in appModes)) {
                    appModes[name] = getChartColorPastel2(colorIndex % 7);
                    colorIndex += 1;
                }
                appModePayload[application][name] = value;
                break;
            case tenantEnvironmentMetricsType.SessionOrigin:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                if (!(application in miscellaneous[metric]))
                    miscellaneous[metric][application] = {};

                miscellaneous[metric][application][name] = value;
                miscellaneous[metric][application]["total"] = total;
                break;
            case tenantEnvironmentMetricsType.LicenseDistribution:
            case tenantEnvironmentMetricsType.AntiVirus:
            case tenantEnvironmentMetricsType.Audience:
            case tenantEnvironmentMetricsType.Fork:
            case tenantEnvironmentMetricsType.BootDiskType:
            case tenantEnvironmentMetricsType.Architecture:
            case tenantEnvironmentMetricsType.Ram:
            case tenantEnvironmentMetricsType.ProcessorCount:
            case tenantEnvironmentMetricsType.DeviceAge:
            case tenantEnvironmentMetricsType.PredictedPriceBand:
            case tenantEnvironmentMetricsType.OfficeDiagnosticConsentLevel:
            case tenantEnvironmentMetricsType.WindowsTelemetryLevel:
            case tenantEnvironmentMetricsType.DeviceManagement:
            case tenantEnvironmentMetricsType.VmType:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};

                if (!(date in miscellaneous[metric]))
                    miscellaneous[metric][date] = {};

                if (percent > 0.5)
                    miscellaneous[metric][date][name] = {
                        value: value,
                        percentage: percent,
                    };
                break;
            case tenantEnvironmentMetricsType.OfficeUsage:
                if (!(metric in miscellaneous)) miscellaneous[metric] = [];
                miscellaneous[metric].push({
                    name: date,
                    Teams: percent,
                });
                break;
            case tenantEnvironmentMetricsType.OfficeUsageBreakDown:
                if (!(metric in miscellaneous)) miscellaneous[metric] = [];

                miscellaneous[metric].push({
                    name,
                    value,
                    total,
                    fill: appStyles[name]?.color,
                });
                break;
            /* 
                For Location, we get the Name of the country as ISO Code 'US'
                
                Code handles Changing it to required data format = CountryCode : {Title, SessionCount} where country code is isoA2
                    and title is the either country code or country grouping like APAC, EMEA, LATAM, etc.

                Sample new format -
                    Word : {
                        "US" : {sessionCount: 1234, title: US},
                    },
            */
            case tenantEnvironmentMetricsType.Location:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                if (!(application in miscellaneous[metric]))
                    miscellaneous[metric][application] = [];

                if (percent > 0) {
                    const countryCode = nameToISOMapping.get(name) ?? name;

                    miscellaneous[metric][application].push({
                        sessionCount: value,
                        countryCode,
                        title: ISOtoNameMapping.has(countryCode)
                            ? ISOtoNameMapping.get(countryCode)
                            : countryCode,
                        percent,
                    });
                    geoDataMappingSelected.set(
                        countryCode,
                        geoDataMapping.get(countryCode),
                    );
                }

                break;
            case tenantEnvironmentMetricsType.WacDatacenter:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                if (!(application in miscellaneous[metric]))
                    miscellaneous[metric][application] = [];

                if (percent > 0) {
                    const geoData = geoDataMapping.get(name);
                    miscellaneous[metric][application].push({
                        sessionCount: value,
                        countryCode: name,
                        title: geoData ? geoData.properties.NAME : name,
                        percent,
                    });

                    geoDataMappingSelected.set(name, geoDataMapping.get(name));
                }

                break;
            case tenantEnvironmentMetricsType.SPOLocation: // handle no SPO condition
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};

                const mappedCountryCode = geoCenterMappingForSpoFarm[name] ?? name; // Getting the geo center for APAC, EMEA and LATAM
                const a2CountryCode =
                    isoA3ToA2Mapping.get(mappedCountryCode) ?? mappedCountryCode;
                const geodata = geoDataMapping.get(a2CountryCode);
                spoData.push({
                    sessionCount: null, // Setting a null value so that the structure is maintained.
                    countryCode: a2CountryCode,
                    title:
                        mappedCountryCode !== name // Will be different for APAC, LATAM, etc.
                            ? name
                            : geodata
                            ? geodata.properties.NAME
                            : name,
                    percent: null,
                });
                geoDataMappingSelected.set(
                    a2CountryCode,
                    geoDataMapping.get(a2CountryCode),
                );
                break;
            case tenantEnvironmentMetricsType.Continent:
                if (!(metric in miscellaneous)) miscellaneous[metric] = {};

                let continent = name;
                if (name === "IN" || name === "ME") continent = "AS";

                if (miscellaneous[metric][continent])
                    miscellaneous[metric][continent] += value;
                else miscellaneous[metric][continent] = value;
                break;

            default:
                break;
        }
    });
    // for ordering
    miscellaneous[tenantEnvironmentMetricsType.OpenDocument] =
        transformUserFrequencyData(
            miscellaneous[tenantEnvironmentMetricsType.OpenDocument],
        );
    // for session origin, need to fill color
    miscellaneous[tenantEnvironmentMetricsType.SessionOrigin] =
        transformSessionOrigin(
            miscellaneous[tenantEnvironmentMetricsType.SessionOrigin],
        );
    miscellaneous[tenantEnvironmentMetricsType.Continent] = transformContinentData(
        miscellaneous[tenantEnvironmentMetricsType.Continent],
    );
    // For App mode, need 2 things, appmode + color and payload data
    if (!(tenantEnvironmentMetricsType.AppMode in miscellaneous))
        miscellaneous[tenantEnvironmentMetricsType.AppMode] = {};
    miscellaneous[tenantEnvironmentMetricsType.AppMode]["appModes"] = appModes;
    miscellaneous[tenantEnvironmentMetricsType.AppMode]["data"] =
        transformAppModeData(appModePayload);
    if (!(tenantEnvironmentMetricsType.SPOLocation in miscellaneous))
        miscellaneous[tenantEnvironmentMetricsType.SPOLocation] = {};
    Filters.AppsToRankOn.forEach((app) => {
        miscellaneous[tenantEnvironmentMetricsType.SPOLocation][app] = spoData;
    });
    const environmentData: TenantEnvironmentType = {
        os: miscellaneous[tenantEnvironmentMetricsType.OS],
        browser: miscellaneous[tenantEnvironmentMetricsType.Browser],
        currency: miscellaneous[tenantEnvironmentMetricsType.Currency],
        openDocument: miscellaneous[tenantEnvironmentMetricsType.OpenDocument],
        licenseDistribution:
            miscellaneous[tenantEnvironmentMetricsType.LicenseDistribution],
        AntiVirus: miscellaneous[tenantEnvironmentMetricsType.AntiVirus],
        Audience: miscellaneous[tenantEnvironmentMetricsType.Audience],
        Fork: miscellaneous[tenantEnvironmentMetricsType.Fork],
        BootDiskType: miscellaneous[tenantEnvironmentMetricsType.BootDiskType],
        Architecture: miscellaneous[tenantEnvironmentMetricsType.Architecture],
        Ram: miscellaneous[tenantEnvironmentMetricsType.Ram],
        ProcessorCount: miscellaneous[tenantEnvironmentMetricsType.ProcessorCount],
        DeviceAge: miscellaneous[tenantEnvironmentMetricsType.DeviceAge],
        PredictedPriceBand:
            miscellaneous[tenantEnvironmentMetricsType.PredictedPriceBand],
        OfficeDiagnosticConsentLevel:
            miscellaneous[tenantEnvironmentMetricsType.OfficeDiagnosticConsentLevel],
        WindowsTelemetryLevel:
            miscellaneous[tenantEnvironmentMetricsType.WindowsTelemetryLevel],
        DeviceManagement:
            miscellaneous[tenantEnvironmentMetricsType.DeviceManagement],
        VmType: miscellaneous[tenantEnvironmentMetricsType.VmType],
        OfficeUsage: miscellaneous[tenantEnvironmentMetricsType.OfficeUsage],
        OfficeUsageBreakDown:
            miscellaneous[tenantEnvironmentMetricsType.OfficeUsageBreakDown],
        geographicDistribution: getGeoDistributionData(miscellaneous, workload),
        continent: miscellaneous[tenantEnvironmentMetricsType.Continent],
        appMode: miscellaneous[tenantEnvironmentMetricsType.AppMode],
        sessionOrigin: miscellaneous[tenantEnvironmentMetricsType.SessionOrigin],
    };
    return environmentData;
};

export const formatCoreMetricsResponse = (
    coreHealthJson: MetricsResponseType,
    avgCohortMetricsJson: MetricsResponseType,
    sparkLineScoresJson: MetricsResponseType,
    metaDataJson: MetricsResponseType,
    dates: string[],
    metricFilters: WorkloadMetricFilterState,
    workload: string,
    subWorkload?: string,
) => {
    try {
        const coreMetricInfo = formatCoreMetrics(
            coreHealthJson,
            avgCohortMetricsJson,
            workload,
            subWorkload,
            metricFilters.Date,
        );
        const sparkLineScores = formatScoreLines(
            sparkLineScoresJson,
            workload,
            subWorkload,
            metricFilters.Date,
        );
        const metaData = formatMetaData(
            metaDataJson,
            workload,
            dates,
            metricFilters,
        );
        return {
            coreHealth: coreMetricInfo.tenantCoreHealthMetric,
            coreHealthScore: coreMetricInfo.coreHealthScore,
            coreHealthColor: coreMetricInfo.coreHealthColor,
            sparkLineScores,
            metaData,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrilldownCoreMetricsProcessingFailed",
            ),
            {
                message:
                    "Failure in parsing Core Metrics responses @ In Product Drill down page",
                data: {
                    coreHealthJson,
                    avgCohortMetricsJson,
                    sparkLineScoresJson,
                    metaDataJson,
                    dates,
                    metricFilters,
                    workload,
                },
            },
            error,
        );
        throw error;
    }
};

export const formatTenantMetricsDataSuccess = (
    coreHealthJson: MetricsResponseType,
    avgCohortMetricsJson: MetricsResponseType,
    sparkLineScoresJson: MetricsResponseType,
    metaDataJson: MetricsResponseType,
    appMetricsJson: MetricsResponseType,
    officeTenantScoreJson: MetricsResponseType,
    dates: string[],
    metricFilters: WorkloadMetricFilterState,
    workload: string,
    subWorkload?: string,
) => {
    try {
        const coreMetricInfo = formatCoreMetrics(
            coreHealthJson,
            avgCohortMetricsJson,
            workload,
            subWorkload,
            metricFilters.Date,
        );
        const sparkLineScores = formatScoreLines(
            sparkLineScoresJson,
            workload,
            subWorkload,
            metricFilters.Date,
        );
        const metaData = formatMetaData(
            metaDataJson,
            workload,
            dates,
            metricFilters,
        );
        const appMetrics = formatAppMetrics(appMetricsJson);
        const officeTenantScore = formatOfficeTenantScore(
            officeTenantScoreJson,
            dates,
            metricFilters,
            workload,
        );

        return {
            coreHealth: coreMetricInfo.tenantCoreHealthMetric,
            coreHealthScore: coreMetricInfo.coreHealthScore,
            coreHealthColor: coreMetricInfo.coreHealthColor,
            sparkLineScores,
            metaData,
            others: appMetrics,
            officeTenantScore,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrilldownTenantMetricsResponseHandlingFailed",
            ),
            {
                message:
                    "Failure in processing Tenant metrics json in the Product drill down page",
                data: {
                    coreHealthJson,
                    avgCohortMetricsJson,
                    sparkLineScoresJson,
                    metaDataJson,
                    appMetricsJson,
                    officeTenantScoreJson,
                    dates,
                    metricFilters,
                    workload,
                },
            },
            error,
        );
        throw error;
    }
};

export const loadTenantCoreMetrics = async (
    setData,
    filters: {},
    metricFilters: WorkloadMetricFilterState,
    dates: string[],
    workload: string,
) => {
    if (!("id" in filters)) {
        return;
    }
    try {
        setData((data) => {
            return {
                ...data,
                tenantMetrics: { ...data.tenantMetrics, loadingCoreMetrics: true },
            };
        });
        let queryParams = [];
        const levelParams = API.getQueryParamsForLevel(filters["level"]);
        queryParams["id"] = filters["id"];
        queryParams["app"] = filters["rankOn"];
        queryParams["date"] = metricFilters["Date"];
        queryParams = { ...levelParams, ...queryParams };
        const [coreHealth, avgCohortMetrics, sparkLineScores, metaData] =
            await Promise.all([
                API.coreMetrics(queryParams, workload),
                API.avgCohortMetrics(queryParams, workload),
                API.sparkLineScores(queryParams, workload),
                API.metaData(queryParams, workload),
            ]);

        try {
            const responseData = formatCoreMetricsResponse(
                coreHealth?.data,
                avgCohortMetrics?.data,
                sparkLineScores?.data,
                metaData?.data,
                dates,
                metricFilters,
                workload,
                filters["rankOn"], //subWorkload
            );

            setData((data) => {
                const updatedResponseData = { ...responseData };
                const completeData = { ...data };
                completeData["tenantMetrics"]["coreHealth"] =
                    updatedResponseData["coreHealth"];
                completeData["tenantMetrics"]["coreHealthScore"] =
                    updatedResponseData["coreHealthScore"];
                completeData["tenantMetrics"]["coreHealthColor"] =
                    updatedResponseData["coreHealthColor"];
                completeData["tenantMetrics"]["sparkLineScores"] =
                    updatedResponseData["sparkLineScores"];
                completeData["tenantMetrics"]["metadata"] =
                    updatedResponseData["metaData"];
                completeData["tenantMetrics"]["loadingCoreMetrics"] = false;
                return completeData;
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "InProductDrillDownCoreMetricsProcessingException",
                ),
                {
                    message:
                        "Exception while processing core metrics response in Product drill down page",
                    responseCodes: {
                        win32CoreHealth: coreHealth.status,
                        win32AvgCohortMetrics: avgCohortMetrics.status,
                        win32sparkLineScores: sparkLineScores.status,
                        win32MetaData: metaData.status,
                    },
                },
                error,
            );
            setData((data) => {
                return {
                    ...data,
                    tenantMetrics: {
                        ...data.tenantMetrics,
                        loadingCoreMetrics: false,
                    },
                };
            });
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrillDownCoreMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching core metrics data in Product drill down page",
                workload,
                filters,
                metricFilters,
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                tenantMetrics: { ...data.tenantMetrics, loadingCoreMetrics: false },
            };
        });
    }
};

export const loadTenantMetrics = async (
    setData,
    filters: {},
    metricFilters: WorkloadMetricFilterState,
    dates: string[],
    crossAppRankOn: string,
    workload: string,
) => {
    if (!("id" in filters)) {
        return;
    }
    try {
        setData((data) => {
            return {
                ...data,
                tenantMetrics: {
                    ...data.tenantMetrics,
                    loading: true,
                    loadingCoreMetrics: false,
                    notes: [],
                },
            };
        });
        let queryParams = [];
        const levelParams = API.getQueryParamsForLevel(filters["level"]);
        const metricFilterParams = API.getQueryParamsForMetricFilters(metricFilters);
        queryParams["id"] = filters["id"];
        queryParams["app"] = filters["rankOn"];
        queryParams["date"] = metricFilters["Date"];
        queryParams["crossAppRankOn"] = crossAppRankOn;
        queryParams = { ...levelParams, ...queryParams, ...metricFilterParams };
        const allowTenantScore = platformsConfig[workload].showTenantScore;
        const [
            coreHealth,
            avgCohortMetrics,
            sparkLineScores,
            metaData,
            otherMetrics,
            officeTenantScore,
        ] = await Promise.all([
            API.coreMetrics(queryParams, workload),
            API.avgCohortMetrics(queryParams, workload),
            API.sparkLineScores(queryParams, workload),
            API.metaData(queryParams, workload),
            API.tenantMetrics(queryParams, workload),
            allowTenantScore ? API.officeTenantScore(queryParams, workload) : null,
        ]);
        try {
            const responseData = formatTenantMetricsDataSuccess(
                coreHealth?.data,
                avgCohortMetrics?.data,
                sparkLineScores?.data,
                metaData?.data,
                otherMetrics?.data,
                officeTenantScore?.data,
                dates,
                metricFilters,
                workload,
                filters["rankOn"],
            );

            setData((data) => {
                const updatedResponseData = { ...responseData };
                const completeData = { ...data };
                completeData["tenantMetrics"]["coreHealth"] =
                    updatedResponseData["coreHealth"];
                completeData["tenantMetrics"]["coreHealthScore"] =
                    updatedResponseData["coreHealthScore"];
                completeData["tenantMetrics"]["coreHealthColor"] =
                    updatedResponseData["coreHealthColor"];
                completeData["tenantMetrics"]["sparkLineScores"] =
                    updatedResponseData["sparkLineScores"];
                completeData["tenantMetrics"]["metadata"] =
                    updatedResponseData["metaData"];
                completeData["tenantMetrics"]["others"] =
                    updatedResponseData["others"];
                completeData["officeTenantScore"] =
                    updatedResponseData["officeTenantScore"];

                completeData["tenantMetrics"]["notes"] = [];
                completeData["tenantMetrics"]["loading"] = false;
                return completeData;
            });
            fetchNotes(
                setData,
                workload,
                responseData["metaData"]["tpid"],
                filters["id"],
            );
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "InProductDrillDownTenantMetricsProcessingException",
                ),
                {
                    message:
                        "Exception while processing tenant metrics data in Product drill down page",
                    win32CoreHealth: coreHealth.status,
                    win32AvgCohortMetrics: avgCohortMetrics.status,
                    win32sparkLineScores: sparkLineScores.status,
                    win32MetaData: metaData.status,
                    win32OtherMetrics: otherMetrics.status,
                    win32OfficeTenantScore: officeTenantScore.status,
                    workload,
                    filters,
                    metricFilters,
                },
                error,
            );
            setData((data) => {
                return {
                    ...data,
                    tenantMetrics: {
                        ...data.tenantMetrics,
                        loading: false,
                        loadingCoreMetrics: false,
                        notes: [],
                    },
                };
            });
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrillDownTenantMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching tenant metrics data in Product drill down page",
                workload,
                filters,
                metricFilters,
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                tenantMetrics: {
                    ...data.tenantMetrics,
                    loading: false,
                    loadingCoreMetrics: false,
                    notes: [],
                },
            };
        });
    }
};

export function fetchDatesSuccess(dates: MetricsResponseType) {
    const finalDates = [];
    dates.Tables[0].Rows.map((x) => finalDates.push({ key: x[0], text: x[0] }));
    return finalDates;
}

export function fetchTenantsSuccess(tenants: KustoResponseType<string>) {
    try {
        const tenantList = tenants.Tables[0];
        const rows: any[] = [];
        tenantList.Rows.forEach((element) => {
            rows.push({
                Tpid: element[0],
                OrganizationName: element[1],
                TenantId: element[2],
                TenantName: element[3],
                TpidStatus: element[4],
                OmsStatus: element[5],
                IsS500: element[6],
                IsS2500: element[7],
                IsEPA: element[8],
                IsGoogleMove: element[9],
                IsGov: element[10],
                IsEO: element[11],
                IsOther: element[12],
                IsCopilot: element[13],
                TenantName_Translated: element[14],
                Show: true,
            });
        });

        return {
            tenantList: {
                headers: tenantList.Columns.map((x: any) => x.ColumnName),
                rows,
                loading: false,
            },
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrilldownTenantListProcessingFailed",
            ),
            {
                message:
                    "Failure in processing tenant list response in product drill down page",
                data: {
                    tenants,
                },
            },
            error,
        );
        throw error;
    }
}

export const loadTenants = async (
    data: WorkloadTenantState,
    setData,
    filters: {},
    metricFilters: WorkloadMetricFilterState,
    setMetricFilters,
    setFilters,
    crossAppRankOn: string,
    workload: string,
) => {
    const queryParams: {} = {};
    queryParams["application"] = filters["rankOn"];
    const customProperties = {};

    if (data.dates.length === 0) {
        try {
            const dateFilters = await API.dateFilters(workload);
            customProperties["datesResponseCode"] = dateFilters.status;
            customProperties["datesResponseJson"] = dateFilters?.data;
            const finalDates = fetchDatesSuccess(dateFilters?.data);
            setData((data) => {
                return {
                    ...data,
                    dates: finalDates,
                };
            });

            const newMetricFilters = { ...metricFilters };
            if (metricFilters["Date"] === "") {
                newMetricFilters["Date"] = finalDates[0]["key"];
                setMetricFilters(newMetricFilters);
            }

            await loadTenantList(
                finalDates,
                queryParams,
                filters,
                newMetricFilters,
                setData,
                setFilters,
                crossAppRankOn,
                workload,
            );
            return;
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "InProductDrillDownTenantDatesFetchException",
                ),
                {
                    message:
                        "Exception while fetching dates in Product drill down page",
                    customProperties,
                },
                error,
            );
        }
    }
};

export const loadTenantList = async (
    dates,
    queryParams,
    filters,
    metricFilters,
    setData,
    setFilters,
    crossAppRankOn,
    workload,
) => {
    setData((data) => {
        return {
            ...data,
            tenantList: { ...data.tenantList, loading: true },
        };
    });

    try {
        queryParams["date"] = metricFilters["Date"];
        const tenantList: KustoResponseType<string> = await API.tenantList(
            queryParams,
            workload,
        );

        const responseData = fetchTenantsSuccess(tenantList);

        setData((data) => {
            return {
                ...data,
                ...responseData,
            };
        });
        const newFilters = { ...filters };
        if (!("id" in filters)) {
            const candidateTopParent = tenantList?.Tables[0].Rows[0][0];
            const candidateTenant = tenantList?.Tables[0].Rows[0][2];
            newFilters["id"] =
                filters["level"] === "Tpid" ? candidateTopParent : candidateTenant;
            setFilters(newFilters);
            loadTenantMetrics(
                setData,
                newFilters,
                metricFilters,
                dates,
                crossAppRankOn,
                workload,
            );
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrillDownTenantListFetchException",
            ),
            {
                message:
                    "Exception while fetching list of tenants in Product drill down page",
                filters,
                metricFilters,
                workload,
                crossAppRankOn,
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                tenantList: { ...data.tenantList, loading: false },
            };
        });
    }
};

export const fetchTenantMetricsChartSuccess = (
    tenantMetricsChartJson: MetricsResponseType,
    display: string,
) => {
    const data = tenantMetricsChartJson.Tables[0];
    return {
        headers: data.Columns.map((x: any) => x.ColumnName),
        rows: data.Rows,
        title: display,
    };
};

export const loadChartMetrics = async (
    metric: string,
    display: string,
    level: string,
    orgKey: string,
    metricFilters: WorkloadMetricFilterState,
    setData,
    workload: string,
) => {
    setData((data) => {
        return {
            ...data,
            chartData: null,
        };
    });
    let queryParams = API.getQueryParamsForLevel(level);
    const metricFilterParams = API.getQueryParamsForMetricFilters(metricFilters);
    queryParams["id"] = orgKey;
    queryParams["metric"] = metric;
    queryParams = { ...queryParams, ...metricFilterParams };
    const customProperties = {};

    try {
        const tenantMetricsChart = await API.tenantMetricsChart(
            queryParams,
            workload,
        );
        customProperties["responseCode"] = tenantMetricsChart.status;
        const tenantMetricsChartJson = tenantMetricsChart?.data;
        customProperties["responseJson"] = tenantMetricsChartJson;
        const finalMetricChartsData = fetchTenantMetricsChartSuccess(
            tenantMetricsChartJson,
            display,
        );
        customProperties["finalMetricChartsData"] = finalMetricChartsData;
        setData((data) => {
            return {
                ...data,
                chartData: finalMetricChartsData,
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrillDownChartMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching charts data in Product drill down page",
                customProperties,
                metric,
                display,
                level,
                orgKey,
                metricFilters,
                workload,
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                chartData: null,
            };
        });
    }
};

const fetchNotes = async (
    setData: any,
    workload: string,
    tpid: string,
    id: string,
) => {
    try {
        let notes = await new NotesCosmosDbAccess().getExemptionNotesForTpid(
            workload,
            tpid,
        );
        notes.forEach((note) => {
            note["TenantId"] = note["OmsTenantId"];
        });
        notes =
            tpid === id
                ? notes.filter((note) => note["TenantId"] === "All")
                : notes.filter(
                      (note) =>
                          note["TenantId"] === id || note["TenantId"] === "All",
                  );
        if (notes.length > 0) {
            const exemptionNote = notes[0];
            exemptionNote["IsExempted"] = isTenantExempt(exemptionNote, workload);
            setData((data) => {
                const updatedTenantMetricsData = { ...data.tenantMetrics };
                updatedTenantMetricsData.metadata = {
                    ...data.tenantMetrics.metadata,
                    exemptionNote,
                };
                updatedTenantMetricsData.notes = notes;
                return { ...data, tenantMetrics: updatedTenantMetricsData };
            });
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "InProductDrillDownExemptionNotesFetchException",
            ),
            {
                message:
                    "Exception while fetching notes from cosmos db in Product drill down page",
                workload,
                tpid,
                id,
            },
            error,
        );
    }
};
