import { DropdownMenuItemType, IDropdownOption } from "@fluentui/react";

export const HarmonyJourneyFunnelCohortNames: IDropdownOption[] = [
    {
        key: "New Harmony Users Title",
        text: "New Harmony Users",
        itemType: DropdownMenuItemType.Header,
        disabled: true,
    },
    { key: "New Harmony Users", text: "New Users - All" },
    { key: "Free New Harmony Users", text: "New Users - Free" },
    { key: "Paid New Harmony Users", text: "New Users - Paid" },
    { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
    {
        key: "Returning Harmony Users Title",
        text: "Returning Harmony Users",
        itemType: DropdownMenuItemType.Header,
        disabled: true,
    },
    { key: "Returning Harmony Users", text: "Returning Users - All" },
    { key: "Free Returning Harmony Users", text: "Returning Users - Free" },
    { key: "Paid Returning Harmony Users", text: "Returning Users - Paid" },
];

export const eRFMTimeWindows: IDropdownOption[] = [
    { key: "R28D", text: "R28D" },
    { key: "R56D", text: "R28D + 28D" },
    { key: "R84D", text: "R28D + 56D" },
];
