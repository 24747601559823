import React from "react";
import { Dropdown, Label, Stack } from "@fluentui/react";
import CopilotExecOverviewSearchBar from "components/CopilotComponents/CopilotExecOverviewSearchBar";
import { DatePicker } from "components/CustomComponents/DatePicker/DatePicker";
import "pages/common.css";
import { CopilotExecOverviewFilterPanelProps } from "pages/Copilot/types";
import {
    copilotCohortOptions,
    copilotMAUOptions,
    copilotPlatformOptions,
} from "utils/CopilotConstants";

export const CopilotExecOverviewFilterPanel = ({
    dateOptions,
    filters,
    scoreType,
    onFilterChange,
}: CopilotExecOverviewFilterPanelProps) => {
    return (
        <Stack horizontal className="filterPanel">
            <div
                className="timePeriod"
                data-testid="CopilotExecFilterPanelDateTestid"
            >
                <DatePicker
                    dateOptions={dateOptions}
                    onChangeSelection={(_event, option) => {
                        onFilterChange("date", option.key as string);
                    }}
                    selected={filters.date}
                />
            </div>
            <div className="consumerPivotFilter">
                <Label className="filterLabel">Platform:</Label>
                <Dropdown
                    options={copilotPlatformOptions[scoreType]}
                    onChange={(_event, option) => {
                        onFilterChange("platform", option.key as string);
                    }}
                    selectedKey={filters.platform}
                    className="filterDropdown"
                    data-testid="CopilotExecFilterPanelPlatformTestid"
                />
            </div>
            <div className="copilotCohortFilter">
                <Label className="filterLabel">Cohort:</Label>
                <Dropdown
                    options={copilotCohortOptions}
                    onChange={(_event, option) => {
                        onFilterChange("cohort", option.key as string);
                    }}
                    selectedKey={filters.cohort}
                    className="filterDropdown"
                    data-testid="CopilotExecFilterPanelCohortTestid"
                />
            </div>
            <div className="copilotMinLicenseFilter">
                <Label className="filterLabel">Min. Copilot Licenses:</Label>
                <Dropdown
                    options={copilotMAUOptions}
                    onChange={(_event, option) => {
                        onFilterChange("mau", option.key as string);
                    }}
                    selectedKey={filters.mau}
                    className="filterDropdown"
                    data-testid="CopilotExecFilterPanelMauTestid"
                />
            </div>
            <div>
                <CopilotExecOverviewSearchBar date={filters.date} />
            </div>
        </Stack>
    );
};
