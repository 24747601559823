import React, { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { chartXAxisPaddingStyles, lineChartTooltipStyles } from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { STATUS } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDisplayDate } from "utils/Helpers";
import { InfoLinks } from "utils/Links";
import { ACE_HEALTH_SCORE_MESSAGES } from "utils/Messages";

export interface TenantACEHealthScorePayloadType {
    Date: string;
    CustomerSupportHealthScore: number;
    CSHSCohortAverage: number;
}

interface ACEHealthScoreState {
    payload: TenantACEHealthScorePayloadType[];
    loading: boolean;
}

const initialState: ACEHealthScoreState = {
    payload: [],
    loading: true,
};

export const getColor = (score: number): string => {
    /*
        Blue        -inf : 0
        Yellow      0 : 10
        Red         10 : 36
        Deep Re     36 : inf
    */
    let color: string = "";
    if (score < 0) {
        color = STATUS.Blue.color;
    } else if (score < 10) {
        color = STATUS.Yellow.color;
    } else if (score < 36) {
        color = STATUS.Red.color;
    } else color = STATUS.DeepRed.color;

    return color;
};

const getACEHealthScore = async (
    filters,
): Promise<KustoResponseType<string | number>> => {
    let queryParams = {};
    const levelParams = API.getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams = { ...levelParams, ...queryParams };

    const aceHealthData = await API.getKustoResponse({
        queryName: "aceHealthScore",
        platform: Workload.WEB,
        queryParams,
    });
    return aceHealthData?.data;
};

export const formatACEHealthScore = (
    aceHealthScoreJson: KustoResponseType<string | number>,
): TenantACEHealthScorePayloadType[] => {
    const aceScore: TenantACEHealthScorePayloadType[] = [];

    if (!aceHealthScoreJson) return aceScore;

    const aceHealthScoreJsonTable = aceHealthScoreJson.Tables[0];
    const aceHealth = {};

    // Date, Name, Value
    aceHealthScoreJsonTable.Rows.forEach((element) => {
        const date: string = element[0] as string;
        const formattedDate: string = formatDisplayDate(date);
        const name: string = element[1] as string;
        const value: number = element[2] as number;

        if (!(formattedDate in aceHealth)) aceHealth[formattedDate] = {};

        aceHealth[formattedDate][name] = value;
    });

    Object.keys(aceHealth).forEach((date) => {
        aceScore.push({ Date: date, ...aceHealth[date] });
    });

    return aceScore;
};

export const ACEHealthScore = ({ filters, chartWidth, chartHeight }) => {
    const tenantId: string = filters.id;
    const level: string = filters.level;

    const getPayloadData = async (filters: {}) => {
        setData((data) => {
            return {
                ...data,
                loading: true,
            };
        });

        try {
            const response: KustoResponseType<string | number> =
                await getACEHealthScore(filters);
            const formattedResponse = formatACEHealthScore(response);

            setData({
                payload: formattedResponse,
                loading: false,
            });
        } catch (e) {
            setData({
                payload: [],
                loading: false,
            });
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "ACEHealthScoreFetchOrProcessingFailed",
                ),
                {
                    message: "Failed to fetch or format ACE Health score",
                    filters,
                },
                e,
            );
        }
    };

    const [data, setData] = useState<ACEHealthScoreState>(initialState);

    useEffect(() => {
        if (tenantId) {
            getPayloadData({ id: tenantId, level });
        }
    }, [tenantId, level]);

    return (
        <>
            <h2 className="commonPageBlockTitle">
                Customer Support Health Score
                <LinkIconButton
                    link={InfoLinks.ACEHealthScore}
                    message={ACE_HEALTH_SCORE_MESSAGES.READ_MORE}
                />
            </h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.payload && data.payload.length !== 0 ? (
                <div>
                    <LineChart
                        width={chartWidth}
                        height={chartHeight}
                        data={data.payload}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                        <YAxis domain={[-10, 20]} padding={{ top: 10, bottom: 5 }} />
                        <Tooltip
                            labelStyle={lineChartTooltipStyles.labelStyle}
                            itemStyle={lineChartTooltipStyles.itemStyle}
                        />
                        <Line
                            dataKey="CustomerSupportHealthScore"
                            stroke="#8884d8"
                            type="monotone"
                            name="Score"
                            dot={customizedDot}
                            activeDot={false}
                        />
                        <Line
                            dataKey="CSHSCohortAverage"
                            stroke="#82ca9d"
                            type="monotone"
                            name="Cohort Average Score"
                        />
                    </LineChart>
                    <CustomBarLegend width={chartWidth} />
                </div>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};

const CustomBarLegend = ({ width }) => {
    interface ColorScale {
        [key: string]: {
            showScaleText: boolean;
            scaleText?: string;
        };
    }

    const colorRange: ColorScale = {
        Blue: { showScaleText: false },
        Yellow: { showScaleText: true, scaleText: "< 0" },
        Red: { showScaleText: true, scaleText: "< 10" },
        DeepRed: { showScaleText: true, scaleText: "< 36" },
    };
    const betterHealthText: string[] = ["Better", "Health"];
    const worseHealthText: string[] = ["Worse", "Health"];
    const LegendPositions = {
        y: 5,
        buffer: 50,
        rectangleWidth: 70,
        rectangleHeight: 25,
        lineHeight: 15,
        textHeight: 15,
        fontSize: 13,
    };
    const worseHealthTextXPos: number =
        Object.keys(colorRange).length * LegendPositions.rectangleWidth +
        LegendPositions.buffer * 1.2;

    return (
        <svg
            height={
                LegendPositions.y +
                LegendPositions.rectangleHeight +
                LegendPositions.lineHeight +
                LegendPositions.textHeight
            }
            width={width}
        >
            <g
                style={{
                    transform: `translateX(${LegendPositions.rectangleWidth}px)`, // to center
                }}
            >
                {betterHealthText.map((text, index) => (
                    <text
                        key={`${text}-${index}`}
                        transform={`translate(0,${
                            LegendPositions.y +
                            LegendPositions.rectangleHeight /
                                (betterHealthText.length - index) // To acoomodate text within height of 1 rect height, looks better fit
                        })`}
                        fontSize={LegendPositions.fontSize}
                    >
                        {text}
                    </text>
                ))}
                {Object.keys(colorRange).map((color, index) => (
                    <g key={`${color}-${index}`}>
                        <rect
                            y={LegendPositions.y}
                            x={
                                index * LegendPositions.rectangleWidth +
                                LegendPositions.buffer
                            }
                            height={LegendPositions.rectangleHeight}
                            width={LegendPositions.rectangleWidth}
                            fill={STATUS[color].color}
                        />
                        {colorRange[color].showScaleText && (
                            <g>
                                <line
                                    style={{ stroke: "#e4e5eb" }}
                                    y1={
                                        LegendPositions.rectangleHeight +
                                        LegendPositions.y
                                    }
                                    y2={
                                        LegendPositions.rectangleHeight +
                                        LegendPositions.y +
                                        LegendPositions.lineHeight
                                    }
                                    x1={
                                        index * LegendPositions.rectangleWidth +
                                        LegendPositions.buffer
                                    }
                                    x2={
                                        index * LegendPositions.rectangleWidth +
                                        LegendPositions.buffer
                                    }
                                />
                                <text
                                    style={{
                                        textAnchor: "middle",
                                        fontSize: LegendPositions.fontSize,
                                    }}
                                    dy=".71em"
                                    x={
                                        index * LegendPositions.rectangleWidth +
                                        LegendPositions.buffer
                                    }
                                    y={
                                        LegendPositions.rectangleHeight +
                                        LegendPositions.y +
                                        LegendPositions.textHeight
                                    }
                                >
                                    {colorRange[color].scaleText}
                                </text>
                            </g>
                        )}
                    </g>
                ))}
                {worseHealthText.map((text, index) => (
                    <text
                        key={`${text}-${index}`}
                        transform={`translate(${worseHealthTextXPos},${
                            LegendPositions.y +
                            LegendPositions.rectangleHeight /
                                (worseHealthText.length - index)
                        })`}
                        fontSize={LegendPositions.fontSize}
                    >
                        {text}
                    </text>
                ))}
            </g>
        </svg>
    );
};

const customizedDot = (props: any) => {
    const { cx, cy, value }: CustomizedDotProps = props;
    return (
        value && (
            <circle
                cx={cx}
                cy={cy}
                r={5}
                stroke="gray"
                strokeWidth={1}
                fill={getColor(value)}
            />
        )
    );
};

interface CustomizedDotProps {
    cx: number;
    cy: number;
    value: number;
}
