import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { ParentSize } from "@visx/responsive";
import { AppLegend } from "components/AIScorecardComponents/CopilotTrends/AppLegend";
import { MetricLegend } from "components/AIScorecardComponents/CopilotTrends/MetricLegend";
import { ExportLink } from "components/AIScorecardComponents/ExportLink";
import CustomLoader from "components/CustomComponents/CustomLoader/CustomLoader";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import TrendChart from "components/CustomComponents/TrendChart/TrendChart";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatTrendData,
} from "components/AIScorecardComponents/ApiHandler";
import {
    BoolDictType,
    MetricsType,
    TrendDataType,
} from "components/AIScorecardComponents/CopilotTrends/types";
import { logException } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";

const createSeriesFromData = (apps: BoolDictType, data: TrendDataType[]) => {
    let result = [];
    Object.keys(apps).forEach((app) => {
        if (apps[app]) {
            let entry = {};
            entry["Color"] = appStyles[app]?.color ?? "#a6611a";
            const tmp = data.filter((row) => {
                return row["AppName"] === app;
            });
            if (tmp && tmp.length > 0) {
                entry["Data"] = tmp;
                result.push(entry);
            }
        }
    });
    return result;
};

const createSeriesFromDataAndMetrics = (
    apps: BoolDictType,
    data: TrendDataType[],
    metrics: MetricsType[],
) => {
    let result = [];
    Object.keys(apps).forEach((app) => {
        if (apps[app]) {
            metrics.forEach((metric) => {
                if (metric.State) {
                    let entry = {};
                    entry["Color"] = appStyles[app]?.color ?? "#a6611a";
                    entry["Marker"] = metric.Marker;
                    const tmp = data.filter((row) => {
                        return (
                            row["AppName"] === app &&
                            row["MetricName"] === metric.Metric
                        );
                    });
                    if (tmp && tmp.length > 0) {
                        entry["Data"] = tmp;
                        result.push(entry);
                    }
                }
            });
        }
    });
    return result;
};

export const CopilotTrendTemplate = ({
    filters,
    id,
    chartTitle,
    applications,
    metrics = null,
}) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState(false);

    const defaultAppsState = () => {
        const state: BoolDictType = {};
        applications.forEach((app) => (state[app] = true));
        return state;
    };

    const minWidth = 1000;
    const height = 500;

    const [apps, setApps] = useState<BoolDictType>(defaultAppsState);
    const [appsSelected, setAppsSelected] = useState<number>(applications.length);
    const [metricState, setMetricState] = useState<MetricsType[]>(metrics);

    const [data, setData] = useState<TrendDataType[]>([]);

    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (data && data.length !== 0) {
            let seriesData = null;
            if (metricState) {
                seriesData = createSeriesFromDataAndMetrics(apps, data, metricState);
            } else {
                seriesData = createSeriesFromData(apps, data);
            }
            setSeries(seriesData);
        } else {
            setSeries([]);
        }
    }, [data, apps, metricState]);

    const onAppLegendClick = (label: string) => {
        sendAIScorecardUserActionTelemetryEvent(
            {
                userActionName: "AppLegendInChartClick",
                commandSurface: UserActionCommandSurface.Canvas,
            },
            {
                ChartTitle: chartTitle,
                ApplicationClicked: label,
            },
        );

        setAppsSelected((previous) => (apps[label] ? previous - 1 : previous + 1));

        if (apps[label] && appsSelected - 1 === 0) {
            setApps(defaultAppsState);
            setAppsSelected(applications.length);
        } else setApps({ ...apps, [label]: !apps[label] });
    };

    const onMetricLegendClick = (metricName: string) => {
        sendAIScorecardUserActionTelemetryEvent(
            {
                userActionName: "MetricLegendInChartClick",
                commandSurface: UserActionCommandSurface.Canvas,
            },
            { ChartTitle: chartTitle, MetricClicked: metricName },
        );

        let updatedMetrics = metricState.map((metric) => {
            if (metric.Metric === metricName) {
                return { ...metric, State: !metric.State };
            }
            return metric;
        });

        let selectedMetrics = 0;
        updatedMetrics.forEach((metric) => {
            if (metric.State) selectedMetrics += 1;
        });

        if (selectedMetrics === 0) setMetricState(metrics);
        else setMetricState(updatedMetrics);
    };

    useEffect(() => {
        const getTrends = async () => {
            setLoading(true);
            const queryParams = {
                audienceGroup: filters.audienceGroup,
                office365Category: filters.office365Category,
                platform: filters.platform,
                cadence: filters.cadence,
                cohort: filters.cohort,
                date: filters.date,
            };
            const queryName = `copilot${id}Trends`;
            setData([]);
            setError(null);
            try {
                const dataJson = await fetchCopilotData(queryParams, queryName);
                const formattedTrendData = formatTrendData(dataJson);
                setData(formattedTrendData);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotTrendProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format copilot trend data",
                    },
                    error,
                );

                setError(error.message);
                setData([]);
                setSeries([]);
                return;
            }
            setLoading(false);
        };
        if (filters.date !== null) getTrends();
    }, [
        filters.audienceGroup,
        filters.office365Category,
        filters.platform,
        filters.cadence,
        filters.cohort,
        filters.date,
        id,
    ]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <>
            {loading ? (
                <CustomLoader />
            ) : (
                <>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        verticalAlign="center"
                        className="chartHeaderSection"
                    >
                        <Stack.Item>
                            <h3 className="chartTitle">{chartTitle}</h3>
                        </Stack.Item>
                        {series?.length !== 0 && metricState && (
                            <Stack.Item className="metricLegend">
                                <MetricLegend
                                    metrics={metricState}
                                    onClickHandler={onMetricLegendClick}
                                />
                            </Stack.Item>
                        )}
                        <Stack.Item>
                            <ExportLink
                                data={data}
                                filename={
                                    "Copilot" +
                                    id +
                                    "Trend_" +
                                    filters.cadence +
                                    "_" +
                                    filters.date +
                                    ".csv"
                                }
                            />
                        </Stack.Item>
                    </Stack>
                    {series?.length === 0 ? (
                        <EmptyTableContainer />
                    ) : (
                        <Stack className="chartBody">
                            <ParentSize>
                                {({ width }) => (
                                    <>
                                        <TrendChart
                                            series={series}
                                            width={Math.max(width, minWidth)}
                                            height={height}
                                            id={`${id}Trend`}
                                        />
                                        <AppLegend
                                            apps={apps}
                                            onClickHandler={onAppLegendClick}
                                            width={width}
                                        />
                                    </>
                                )}
                            </ParentSize>
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};
