import React from "react";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorMessageBar } from "components/CustomComponents/MessageBars/ErrorMessageBar";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { chartLegendStyles, chartMarginStyles } from "pages/common";

export const BrowserDataChartModal = ({
    handleClose,
    show,
    chartData,
    isFetching,
    errorName,
    componentError,
}) => {
    if (!show) {
        return null;
    }
    return (
        <div className="backdropStyle">
            <div className="modalStyle" style={{ paddingBottom: 20 }}>
                <button
                    className="close"
                    type="button"
                    onClick={handleClose}
                    style={{ zIndex: 100 }}
                >
                    {" "}
                </button>
                {isFetching ? (
                    <LoadingModal text={`Please Wait...`} />
                ) : (
                    <div>
                        <h3 className="commonPageBlockTitle">{`Browser Distribution`}</h3>
                        <h4 className="commonPageBlockTitle">{`Error: ${errorName}`}</h4>
                        {componentError && (
                            <ErrorMessageBar
                                message={componentError}
                                refresh={false}
                            />
                        )}
                        {!chartData ||
                        chartData === undefined ||
                        chartData.length === 0 ? (
                            <EmptyTableContainer />
                        ) : (
                            <ComposedChart
                                layout="vertical"
                                width={450}
                                height={300}
                                data={chartData}
                                margin={chartMarginStyles}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" domain={[0, 100]} />
                                <YAxis
                                    padding={{ top: 20, bottom: 20 }}
                                    dataKey="name"
                                    type="category"
                                    tick={{ fontSize: 14, width: 100 }}
                                />
                                <Tooltip />
                                <Legend wrapperStyle={chartLegendStyles} />
                                <Bar
                                    dataKey="SessionCount"
                                    barSize={20}
                                    fill={"#82ca9d"}
                                />
                            </ComposedChart>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
