import React, { useEffect, useState } from "react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import TenantSearchBar from "components/OverviewComponents/TenantSearchBar/TenantSearchBar";
import { getKustoResponse } from "api";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { CopilotExecOverviewSearchBarStateType } from "pages/Copilot/types";
import { TenantsListType } from "pages/ExecOverview/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

const CopilotExecOverviewSearchBar = ({ date }: { date: string }) => {
    const [data, setData] = useState<CopilotExecOverviewSearchBarStateType>({
        tenantList: {},
        error: null,
        loading: false,
    });

    useEffect(() => {
        const getTenantList = async () => {
            try {
                setData((data) => {
                    return {
                        ...data,
                        loading: true,
                    };
                });

                const tenantList = await getKustoResponse({
                    queryName: `copilotTenantSearchBarList`,
                    platform: Workload.COPILOT_COMMERCIAL,
                });

                const responseData = formatCopilotExecOverviewSearchBarData(
                    tenantList?.data,
                    Workload.COPILOT_COMMERCIAL,
                );

                setData((data) => {
                    return {
                        ...data,
                        tenantList: { [Workload.COPILOT_COMMERCIAL]: responseData },
                        loading: false,
                    };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotExecOverviewTenantListFetchingFailed",
                    ),
                    {
                        message:
                            "Failure in fetching TenantList for Copilot Exec Overview",
                    },
                    error,
                );

                setData((data) => {
                    return {
                        ...data,
                        error: error.message,
                        loading: false,
                    };
                });
            }
        };
        getTenantList();
    }, []);

    return data.error ? (
        <ErrorFallback message={data.error} />
    ) : (
        <TenantSearchBar
            tenantsData={data.tenantList}
            dateFilter={date}
            isTpidHeaderClickable={false}
            showTenantStatus={false}
        />
    );
};

export const formatCopilotExecOverviewSearchBarData = (
    tenantJson: KustoResponseType<string>,
    workload: string,
): TenantsListType => {
    const tenantList = tenantJson.Tables[0];

    const rows: any[] = [];
    tenantList.Rows.forEach((element) => {
        rows.push({
            Tpid: element[0],
            OrganizationName: element[1],
            TenantId: element[2],
            TenantName: element[3],
            Show: true,
        });
    });

    return {
        headerName: "Copilot",
        headers: tenantList.Columns.map((x: any) => x.ColumnName),
        rows,
        workload,
    };
};

export default CopilotExecOverviewSearchBar;
