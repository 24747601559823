import React from "react";
import { curveBasis } from "@visx/curve";
import { GradientOrangeRed } from "@visx/gradient";
import { scaleLinear } from "@visx/scale";
import { Area } from "@visx/shape";
import "./style.css";

const x = (d) => d.index;
const y = (d) => d.value;

const areas = [{ pad: 0, opacity: 1 }];

function range(n) {
    return Array.from(Array(n).keys());
}

function interpolatePoints(current, next) {
    if (!next) return current;
    const xStep = 0.25;
    const yStep = Math.abs(y(next) - y(current)) * 0.03;
    const yMid1 = Math.abs(y(current) - yStep);
    const yMid2 = Math.abs(y(next) + yStep);
    const xMid1 = Math.abs(x(current) + xStep);
    const xMid2 = Math.abs(x(next) - xStep);
    return [current, { index: xMid1, value: yMid1 }, { index: xMid2, value: yMid2 }];
}

function interpolateData(data) {
    return data.map((d, i) => interpolatePoints(d, data[i + 1])).flat();
}

const HorizontalCurveFunnel = ({ width, height, segments }) => {
    const data = interpolateData(segments);
    const numSegments = Math.max(...segments.map(x));
    const padding = width / numSegments / 2;

    const minmax = 100;
    const xScale = scaleLinear({
        range: [0, width],
        domain: [0, numSegments],
    });
    const yScale = scaleLinear({
        range: [height, 0],
        domain: [-minmax, minmax],
    });

    return (
        <svg width={width} height={height}>
            <GradientOrangeRed id="gradient" vertical={false} />
            <rect width={width} height={height} fill="none" rx={22} />
            {areas.map((area, i) => {
                return (
                    <Area
                        key={`area-${i}`}
                        data={data}
                        curve={curveBasis}
                        x={(d) => xScale(x(d))}
                        y0={(d) => yScale(y(d) + area.pad)}
                        y1={(d) => yScale(-y(d) - area.pad)}
                        fill="url(#gradient)"
                        fillOpacity={area.opacity}
                        stroke="transparent"
                    />
                );
            })}
            {data.map((d, i) => {
                if (!data[i + 1] || i === data.length - 1) return null;
                const r = range(numSegments);
                return (
                    <React.Fragment key={`label-${i}`}>
                        {r.includes(x(d)) && y(d) && (
                            <text
                                x={parseFloat(xScale(x(d))) + padding}
                                y={height / 2}
                                className="funnelStageText"
                            >
                                {`${y(d)}%`}
                            </text>
                        )}
                        {r.includes(x(d)) && d.dropoff && (
                            <text
                                x={parseFloat(xScale(x(d))) + padding}
                                y={height - 5}
                                className="funnelDropoffText"
                            >
                                {`${d.dropoff}%`}
                            </text>
                        )}
                        {r.includes(x(d) + 1) && (
                            <line
                                x1={xScale(x(d) + 1)}
                                x2={xScale(x(d) + 1)}
                                y1={0}
                                y2={height}
                                className="funnelBorder"
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </svg>
    );
};
export default HorizontalCurveFunnel;
