import inProductCommonWorkloadDrilldownQueries from "./Common";
import inProductMacDrilldownQueries from "./mac";
import inProductWin32DrilldownQueries from "./win32";
import inProductTeamsDrilldownQueries from "./teams";
import inProductWebDrilldownQueries from "./web";
import consumerProductDrilldownQueries from "./consumer";

const inProductDrilldownQueries: {} = {
    ...inProductMacDrilldownQueries,
    ...inProductWin32DrilldownQueries,
    ...inProductCommonWorkloadDrilldownQueries,
    ...inProductTeamsDrilldownQueries,
    ...inProductWebDrilldownQueries,
    ...consumerProductDrilldownQueries,
};

export default inProductDrilldownQueries;
