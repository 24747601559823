import React from "react";
import camelCase from "lodash/camelCase";
import { Shimmer, Icon } from "@fluentui/react";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { m365TilesData } from "pages/M365TenantInsights/M365TenantInsights";
import { TenantTitleProps } from "components/CustomComponents/types";
import { appIconStyle } from "utils/commonStyles";
import { appStyles } from "utils/Constants";
import { STATUS } from "utils/WebConstants";
import "./style.css";

const TenantTitle = (props: TenantTitleProps) => {
    if (props.loading) {
        return (
            <div>
                <Shimmer className="customerTitleShimmerHeader" />
                <Shimmer className="customerTitleShimmerBody" />
            </div>
        );
    }

    const tenantid =
        props.level === "TenantId" ? (
            `(TenantId : ${props.metadata["level"]})`
        ) : (
            <br />
        );

    const showTenantStatus = props.showTenantStatus ?? true;
    const cohortTagsToShow = [];
    props.cohortTags.forEach((cohort) => {
        const cohortName = cohort.text;
        const cohortKey = cohort.key.toString();
        const cohortBoolean = camelCase(`is${cohortKey}`);
        const id = camelCase(cohortKey);
        if (props.metadata.cohorts[cohortBoolean]) {
            cohortTagsToShow.push(
                <span id={id} key={id} className={`cohortTag ${id}`}>
                    {cohortName}
                </span>,
            );
        }
    });
    const platformDetails = m365TilesData.filter(
        (data) => data.Name === props.platform,
    )[0];

    return (
        <div className="tenantTitleWrapper">
            {platformDetails && (
                <span className="tenantTitlePlatformIconWrapper">
                    <span className="tenantTitlePlatformIcon">
                        <Icon
                            iconName={appStyles[platformDetails.Name]["iconName"]}
                            className={`tenantTitleSummaryIcon ${platformDetails.ClassName} ${appIconStyle}`}
                        />
                    </span>
                    <span className="tenantTitlePlatformName">
                        {platformDetails.DisplayName}
                    </span>
                </span>
            )}
            <span>
                {props.showAdditional && showTenantStatus && (
                    <StatusIndicator
                        id="statusTitle"
                        color={STATUS[props.tenantStatus]["color"]}
                    />
                )}

                <span id="tenantName"> {props.metadata["orgName"]} </span>
                <span id="tpid"> (Tpid : {props.metadata["tpid"]})</span>
                {cohortTagsToShow}
                <br />
                <span
                    style={{
                        fontSize: "14px",
                        paddingRight: props.showAdditional ? "15px" : "0px",
                    }}
                >
                    {tenantid}
                </span>
            </span>
        </div>
    );
};

export default TenantTitle;
