export const performanceDistribution: string = `PerformanceDistribution
            | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Host == "\${host}" and UiHost == "\${uiHost}" and \${levelColumnName} == "\${id}"
            | project Date, Application, Metric, Name,P95 = round(todouble(P95)/1000,2),P50= round(todouble(P50)/1000,2), N
            | join kind=inner (
                        PerformanceDistribution
                        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                        | where Host == "\${host}" and UiHost == "\${uiHost}" and \${levelColumnName} == "\${id}"
                        | top-nested of Date by max(1),
                          top-nested of Application by max(1),
                          top-nested of Metric by max(1),
                          top-nested 10 of Name by max(N)
                ) on Application, Date, Metric, Name
            | project Date, Application, Metric, Name, P95, P50, N
            | join kind=inner (
                    PerformanceDistribution
                    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                    | where Host == "\${host}" and UiHost == "\${uiHost}" and \${levelColumnName} == "\${id}"
                    | summarize Total = sum(N) by Date, Application, Metric
                    ) on Date, Application, Metric
            | project Date, Application, Metric, Name, P95, P50, N, Total
            | order by Date asc, N desc `;
