import React from "react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { lineChartTooltipStyles } from "pages/common";
import { formatDisplayDate, getChartColor } from "utils/Helpers";
import { MONTHLYTREND_MESSAGE } from "utils/Messages";

export const MonthlyTrend = ({
    payload,
    kind,
    yLabel,
    strokeWidth,
    formatDate = true,
}) => {
    if (!payload || payload === undefined) {
        return <EmptyTableContainer />;
    }

    const legendCustomFormatter = (value: SVGStringList) => {
        return (
            <span className="recharts-legend-item-text" style={{ fontSize: 12 }}>
                {value}
            </span>
        );
    };

    const seenEntries = new Set(); // to maintain seen entries, if seen (i.e. color assigned) then don't assign color again
    const labels = [];
    let idx = 0;
    let lengthOfKeys = 0; // to check if the keys are of longer length then we need to reduce the legend font size to 12
    // flatten the data
    // iterate over all data and put colors for each entry
    const flattenedData = [];
    Object.keys(payload).forEach((date) => {
        Object.keys(payload[date]).forEach((entry) => {
            if (!seenEntries.has(entry)) {
                labels.push({ key: entry, color: getChartColor(idx) });
                idx += 1;

                seenEntries.add(entry);

                lengthOfKeys += entry.length;
            }
        });
        flattenedData.push({
            date: formatDate ? formatDisplayDate(date) : date,
            ...payload[date],
        });
    });

    const chartWidth = 450;
    const chartHeight = 300;

    return (
        <div className="monthlyTrendChart">
            <TogglingLineChart
                kind={kind ?? togglingLineChartKind.percentages}
                xDataKey="date"
                yLabel={yLabel}
                values={flattenedData}
                labels={labels}
                width={chartWidth}
                height={chartHeight}
                strokeWidth={strokeWidth}
                tooltipStyles={lineChartTooltipStyles}
                legendFormatter={lengthOfKeys > 80 ? legendCustomFormatter : null}
            />
            <span className="subscriptStyleWithPadding">{MONTHLYTREND_MESSAGE}</span>
        </div>
    );
};
