import { getKustoResponse, getQueryParamsForLevel } from "api";
import { Workload } from "config/PlatformConfig";
import { MetricsResponseType, table } from "pages/commonTypes";
import { addMonth, formatDate, formatDisplayDate } from "utils/Helpers";

export const getPSSInfo = async (filters, showNewTickets, appsList) => {
    let queryParams = {};
    const levelParams = getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams = { ...levelParams, ...queryParams };
    queryParams["metric"] = showNewTickets ? "NewTickets" : "OpenTickets";
    queryParams["appsList"] = appsList;

    const pssData = await getKustoResponse({
        queryName: "tenantMetricsChartSupportTickets",
        platform: Workload.WEB,
        queryParams,
    });
    return pssData;
};

export const formatPSSChartData = (data: table) => {
    const pssData = [];

    if (!data?.rows?.length) {
        return pssData;
    }

    const x = {};
    for (let i = 0; i < data.rows.length; i++) {
        const date = data.rows[i][0];
        if (!x[date]) {
            x[date] = { Date: formatDisplayDate(date) };
        }
        x[date][data.rows[i][1]] = data.rows[i][2];
    }

    let nextDate = formatDate(data.rows[0][0]);
    Object.keys(x).forEach((key) => {
        while (formatDate(key) !== nextDate) {
            pssData.push({ Date: nextDate });
            nextDate = addMonth(nextDate, 1);
        }
        nextDate = addMonth(nextDate, 1);
        pssData.push(x[key]);
    });
    return pssData;
};

export const fetchPSSChartData = (pssDataJson: MetricsResponseType): table => {
    const data = pssDataJson?.Tables[0];
    if (!data) {
        return null;
    }
    return {
        headers: data.Columns.map((x: any) => x.ColumnName),
        rows: data.Rows,
    };
};

export const choicesForChoiceGroup = [
    {
        key: "NewTickets",
        text: "NewTickets",
    },
    {
        key: "OpenTickets",
        text: "OpenTickets",
    },
];

export const choiceGroupStyles = {
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
    },
    label: {
        margin: "0 8px 0 0",
    },
    flexContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr auto",
        gridColumnGap: "16px",
        marginTop: "-8px",
    },
};
