import React, { useEffect, useState } from "react";
import {
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    SelectionMode,
    ShimmeredDetailsList,
} from "@fluentui/react";
import { TextValue } from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { Workload } from "config/PlatformConfig";
import { detailsRowStyle } from "pages/common";
import { fetchCohortList } from "pages/ConsumerProductDrillDown/ApiHandler";
import {
    CohortListProps,
    CohortListState,
} from "pages/ConsumerProductDrillDown/types";
import { STATUS } from "utils/Constants";

const initialData: CohortListState = {
    items: [],
    groups: [],
    loading: true,
};

const _columns: IColumn[] = [
    {
        key: "1",
        name: "",
        fieldName: "status",
        minWidth: 10,
        maxWidth: 20,
    },
    {
        key: "2",
        name: "Cohort Type",
        fieldName: "cohortName",
        minWidth: 195,
        maxWidth: 195,
    },
];

const onRenderItemColumn = (item, index: number, column: IColumn) => {
    const { fieldName } = column;
    if (fieldName === "status")
        return (
            <StatusIndicator
                color={STATUS[item["status"]]?.["color"]}
                id={"customerListStatus"}
            />
        );
    return <TextValue class="customerListOrgName" value={item["cohortName"]} />;
};

export const CohortList = ({ onCohortChange }: CohortListProps) => {
    const onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        return (
            <div
                className="customerListRow"
                onClick={() => {
                    const newFilter = {
                        cohortType: props.item.cohortType,
                        cohortName: props.item.cohortName,
                    };
                    onCohortChange(newFilter);
                }}
            >
                <DetailsRow {...props} styles={detailsRowStyle} compact />
            </div>
        );
    };

    const [data, setData] = useState<CohortListState>(initialData);
    const [error, setError] = useState<string>(null);

    useEffect(() => {
        const getCohorts = async () => {
            setData((oldState) => {
                return { ...oldState, loading: true };
            });

            try {
                const cohorts = await fetchCohortList({}, Workload.WEB_CONSUMER);

                if (typeof cohorts === "string") {
                    setError(cohorts);
                    setData((oldDataState) => {
                        return { ...oldDataState, loading: false };
                    });
                } else {
                    setError(null);
                    setData((oldDataState) => {
                        return { ...oldDataState, ...cohorts, loading: false };
                    });
                }
            } catch (error) {
                setError(error.message);
                setData((oldDataState) => {
                    return { ...oldDataState, loading: false };
                });
            }
        };

        getCohorts();
    }, []);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <ShimmeredDetailsList
                items={data.items}
                groups={data.groups}
                enableShimmer={data.loading}
                columns={_columns}
                onRenderItemColumn={onRenderItemColumn}
                onRenderRow={onRenderRow}
                selectionMode={SelectionMode.none}
                groupProps={{
                    isAllGroupsCollapsed: false,
                }}
            />
        </>
    );
};
