import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import {
    getColorScaleByKeyAndApp,
    getKeys,
    getStageName,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/CompositeFunnelHelper";
import {
    xMaxBar,
    yMax,
} from "components/ConsumerDay0FunnelComponents/CompositeFunnel/constants";
import { FlattenedScenarioDataType } from "pages/ConsumerDay0Funnel/types";

export const stackedBarYScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
});

export const getStackedBarXScale = (payload: FlattenedScenarioDataType[]) => {
    return scaleBand<string>({
        range: [0, xMaxBar],
        domain: payload.map(getStageName),
    });
};

export const getColorScale = (
    payload: FlattenedScenarioDataType[],
    application: string,
) => {
    return scaleOrdinal<string, string>({
        domain: getKeys(payload),
        range: getKeys(payload).map((key) => {
            return getColorScaleByKeyAndApp(key, application);
        }),
    });
};
