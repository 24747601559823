export const win32CompositeScore: string = `
//win32CompositeScore
let level = "\${level}";
let app = "\${application}";
let currMonthdate = "\${date}";
let cohort = "\${cohort}";
let Mau = \${mauCountThreshold};
let currentDate = GetCurrentDate(false); //isTesting value is set to false for GetCurrentDate function
let prevMonthDate = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_Win32Overview
    | project-rename Tpid = TopParentId
    | where Date == currMonthdate
    | where MAU >= Mau
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == app and Color > 1
    | extend CurrencyDetails = parse_json(Currency_Details)
    | project OmsTenantId, Score, Color, MoMChange, MAU, Currency_InSupport_Score, Currency_InSupport_Color, 
        Date, Application, Perf_BootLaunchPercentageUnderNs_Score, Usage_PercMau5DayUsage_Score, 
        Usage_PercMau5DayUsage_Color, Usage_PercMau5DayUsage_MoMChange, Composite_Score = Score, 
        Tpid, Quality_Watson_Score, Quality_Watson_Color, Quality_UAE_Score, Quality_UAE_Color, 
        Feedback_Score, Feedback_Color, Perf_BootTimeP95_Score, Perf_Color, Perf_BootPercentageUnderNs_Score,CurrencyDetails, TenantName_Translated
| extend Currency_DominantChannelFork_Text = CurrencyDetails.DominantChannelFork
| join kind=inner
    (StrategicTenants
        | extend IsMidMarket = iff(Cohorts contains "MidMarket", true, false)
        | where Is\${cohort} == true and Date == currentDate
        | project tolong(Tpid), OmsTenantId, OrgName_Translated, TenantName_Translated
        | distinct \${levelColumnName}, iif( level  == "Tpid", OrgName_Translated, TenantName_Translated) 
        | project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    HealthyTenantScore_PastStatus | project-rename Tpid = TopParentId
    | where iff( level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application ==  app) on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, Application, OrgName = Name,
    Composite_Score = round(Score, 2),
    Composite_MoMChange = MoMChange,
    MAU_Score = MAU,
    EngagedUsers_Score = Usage_PercMau5DayUsage_Score, EngagedUsers_Score_Color = GetColor(Usage_PercMau5DayUsage_Color),
    PerfP95_Score = Perf_BootTimeP95_Score, PerfP95_Score_Color = GetColor(Perf_Color),
    PerfBoot_Score = round(Perf_BootPercentageUnderNs_Score,2),PerfBoot_Score_Color = GetColor(Perf_Color),
    Watson_Score = round(Quality_Watson_Score, 2), Watson_Score_Color = GetColor(Quality_Watson_Color),
    UAE_Score = round(Quality_UAE_Score, 2), UAE_Score_Color = GetColor(Quality_UAE_Color),    
    OCV_Score = round(Feedback_Score, 1), OCV_Score_Color = GetColor(Feedback_Color),
    Currency_Score = Currency_InSupport_Score, Currency_Score_Color = GetColor(Currency_InSupport_Color), Currency_DominantChannelFork_Text,
    Status =  GetColor(Color), Parent = Tpid, TenantName_Translated
    | sort by Composite_Score asc, MAU_Score desc
`;
