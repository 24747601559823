import React, { useEffect, useState } from "react";
import { LoadingBar } from "react-redux-loading-bar";
import { Stack } from "@fluentui/react";
import { ExemptionNoteTable } from "components/ExemptionPortalComponents/ExemptionNoteTable/ExemptionNoteTable";
import { NotesCosmosDbAccess } from "api/CosmosDbAccess";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { ExemptionPortalState } from "components/ExemptionPortalComponents/types";
import { commonPageBlock, commonPageStyle } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import "./style.css";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

const pageName = "Customer Health Exemption Tracking";

export const fetchExemptionNotes = async (setExemptionNotes) => {
    const customProperties = {};
    try {
        const notes = await new NotesCosmosDbAccess().getExemptionNotes();
        customProperties["notes"] = notes;
        setExemptionNotes({
            isFetching: false,
            notes,
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExemptionPortalNotesFetchFailure",
            ),
            customProperties,
            error,
        );
    }
};

export const ExemptionPortal = () => {
    const [exemptionNotes, setExemptionNotes] = useState<ExemptionPortalState>({
        isFetching: true,
        notes: [],
    });

    useEffect(() => {
        document.title = "Exemption Portal";
        AppInsights.getInstance().TrackPage(document.title);
        fetchExemptionNotes(setExemptionNotes);
    }, []);

    useSendLaunchEvent("Customer Health Exemption Tracking Portal");

    return exemptionNotes.isFetching ? (
        <LoadingModal text={`Loading ${pageName}...`} />
    ) : (
        <>
            <LoadingBar className="loadingBar" />
            <Stack disableShrink styles={commonPageStyle}>
                <div className="orgHeader" data-testid="exemptionPortalOrgHeader">
                    <br />
                    <span id="drilldownTitle"> {pageName} </span>
                </div>
                <Stack styles={commonPageBlock} data-testid="exemptionNoteTable">
                    <ExemptionNoteTable
                        payload={exemptionNotes.notes}
                        refresh={() => {
                            fetchExemptionNotes(setExemptionNotes);
                        }}
                        showPortalFeatures
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default ExemptionPortal;
