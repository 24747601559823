import { IChoiceGroupOption, IDropdownOption } from "@fluentui/react";

export const platformOptions: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Web", text: "Web" },
    { key: "Win32", text: "Win32" },
    { key: "Mac", text: "Mac" },
    { key: "iOS", text: "iOS" },
];

export const office365CategoryOptions: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Commercial", text: "Commercial" },
    { key: "Commercial - External", text: "Commercial - External" },
    { key: "Commercial - SMC", text: "Commercial - SMC" },
    { key: "Commercial - SMB", text: "Commercial - SMB" },
    { key: "Commercial - Enterprise", text: "Commercial - Enterprise" },
    { key: "Consumer", text: "Consumer" },
    { key: "Consumer Copilot Pro", text: "Consumer Copilot Pro" },
];

export const audienceGroupOptions: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Production", text: "Production" },
    { key: "Insiders", text: "Insiders" },
    { key: "Microsoft", text: "Microsoft" },
    { key: "Dogfood", text: "Dogfood" },
];

export const unitOptions: IDropdownOption[] = [
    { key: "User", text: "User" },
    { key: "Session", text: "Session" },
];

export const cadenceOptions: IChoiceGroupOption[] = [
    { key: "Weekly", text: "Weekly" },
    { key: "Monthly", text: "Monthly" },
];

export const cohortOptions: IDropdownOption[] = [
    { key: "AllUsers", text: "All" },
    { key: "NewUsers", text: "New Users" },
];
