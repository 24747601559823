import React, { useEffect } from "react";
import { CommandBarButton, Persona, PersonaSize } from "@fluentui/react";
import type { INavLink } from "@m365-admin/nav";
import { SearchBarWithSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/SearchBarWithSuggestions";
import { SearchSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/types";
import { insightsDashboards } from "components/InsightsComponents/constants";
import { isLandingPage } from "components/InsightsComponents/Helpers";
import { useNavigateToDashboard } from "utils/InsightsHooks";

interface DashboardSearchBoxProps {
    setNavCollapsed: (navCollapsed: boolean) => void;
}

const DashboardSearchBox = ({
    setNavCollapsed,
}: DashboardSearchBoxProps): JSX.Element => {
    const navigateToDashboard = useNavigateToDashboard();
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(!isLandingPage());

    const searchRef = React.useRef<HTMLDivElement>();

    const getSearchResultList = (allDashboards: INavLink[]): JSX.Element => {
        return <>{allDashboards.map((teamEntry) => getListCategory(teamEntry))}</>;
    };

    const getListCategory = (teamEntry: INavLink): JSX.Element => {
        return (
            <div
                className="dashboardSuggestionList"
                data-testid="dashboardSuggestionList"
            >
                {getListHeader(teamEntry)}
                {teamEntry.links.map((dashboard) =>
                    getListItem(dashboard, teamEntry.name as string),
                )}
            </div>
        );
    };

    const getListHeader = (teamEntry: INavLink): JSX.Element => {
        return (
            <div className="dashboardSuggestionCategoryHeader">
                Related {teamEntry.name} Dashboard(s) / Report(s)
            </div>
        );
    };

    const getListItem = (dashboard: INavLink, teamName: string): JSX.Element => {
        return (
            <CommandBarButton
                className="dashboardSuggestionItem"
                data-testid="dashboardSuggestionItem"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                    navigateToDashboard(e, dashboard, setNavCollapsed, "Search Box")
                }
            >
                <Persona
                    text={dashboard.name as string}
                    secondaryText={teamName}
                    size={PersonaSize.size40}
                />
            </CommandBarButton>
        );
    };

    const filterResults = (searchText: string): SearchSuggestions => {
        if (searchText) {
            const searchResult: SearchSuggestions = {
                renderResults(): JSX.Element {
                    const listOfDashboards: INavLink[] = filterLinks(searchText);

                    if (listOfDashboards.length === 0) {
                        return getEmptySearchResult();
                    }

                    return getSearchResultList(listOfDashboards);
                },
            };
            return searchResult;
        }
    };

    const isCaseInsensitiveMatch = (item: INavLink, text: String): boolean => {
        return (item.name as string).toLowerCase().includes(text.toLowerCase());
    };

    // Return a list of dashboard entries that match the text while preserving the grouping used in the navigation panel
    const filterLinks = (searchText: string): INavLink[] => {
        const filteredLinks: INavLink[] = [];

        insightsDashboards.forEach((teamEntry) => {
            // Add all dashboards from the owning team if the team name matches the search text
            if (isCaseInsensitiveMatch(teamEntry, searchText)) {
                filteredLinks.push({ ...teamEntry });
                return;
            }

            const matches = teamEntry.links.filter((dashboard) =>
                isCaseInsensitiveMatch(dashboard, searchText),
            );

            if (matches.length > 0) {
                filteredLinks.push({ ...teamEntry, links: matches });
            }
        });

        return filteredLinks;
    };

    const getEmptySearchResult = (): JSX.Element => {
        return (
            <div
                className="dashboardSuggestionList dashboardSuggestionCategoryHeader"
                data-testid="dashboardEmptyResultsList"
            >
                Search OPG Insights dashboards: 'Consumer Growth Scorecard'.
            </div>
        );
    };

    // Detect clicks outside of search bar to collapse it
    useEffect(() => {
        const handler = (event) => {
            if (!isLandingPage() && !searchRef.current?.contains(event.target)) {
                setIsCollapsed(true);
            }
        };

        window.addEventListener("click", handler);
        return () => window.removeEventListener("click", handler);
    }, []);

    const handleClickSearchIcon = (event) => {
        event.stopPropagation();
        setIsCollapsed(false);
    };

    return isCollapsed ? (
        <CommandBarButton
            className="headerButton"
            iconProps={{
                iconName: "Search",
            }}
            title="Search"
            checked
            onClick={handleClickSearchIcon}
        />
    ) : (
        <div className="dashboardSearchBox" ref={searchRef}>
            <SearchBarWithSuggestions
                defaultPrompt="Search OPG Insights dashboards"
                searchSuggestionFilterCallback={filterResults}
            />
        </div>
    );
};

export default DashboardSearchBox;
