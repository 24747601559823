export const macCoreMetrics: string = `
//macCoreMetrics (To display corresponding metrics, on tiles)
let dt = "\${date}";
let level = "\${level}";
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_MacAppView
    | where SubWorkload == "\${app}"
    | where ProcessDate == dt or ProcessDate == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,
    App_Currency = Currency_SignalGroup.App_Currency, App_Currency_Color = Currency_SignalGroup.App_Currency_Color,
    CH_Rate = Reliability_SignalGroup.CH_Rate, CH_Color = Reliability_SignalGroup.CH_Color,
    FQ_Rate = Reliability_SignalGroup.FQ_Rate, FQ_Color = Reliability_SignalGroup.FQ_Color,
    PerfP95_Score = Performance_SignalGroup.Perf_P95, Perf_Color = Performance_SignalGroup.Perf_Color,
    PerfBoot_Score = Performance_SignalGroup.Boot_Threshold, NPS = Feedback_SignalGroup.NPS, NPS_Color = Feedback_SignalGroup.NPS_Color,
    EngagedUsers_Score = Usage_SignalGroup.Engaged_Users, EngagedUsers_Color = Usage_SignalGroup.Engaged_Users_Color
    | project-rename Application = SubWorkload
    | where Color > 1
    | project \${levelColumnName}, Score, Color, MAU , OS_Currency, OS_Currency_Color, App_Currency, App_Currency_Color,
Date = ProcessDate, Application, Composite_Score = Score, CH_Color, FQ_Color, Perf_Color, NPS_Color, CH_Rate, FQ_Rate, PerfP95_Score,
PerfBoot_Score, NPS,EngagedUsers_Score,EngagedUsers_Color,PastStatus);
StrategicTenants
| where Date == toscalar(StrategicTenants|summarize max(Date)) |
where \${levelColumnName} == "\${id}"
| 
project \${levelColumnName},TenantName | limit 1 |join
kind = inner 
(
Overview  | where Date == dt 
)
on \${levelColumnName} |
join kind = leftouter (
    Overview | where Date == prev 
) on \${levelColumnName}
|extend OS_Currency_MoMChange = round((todouble(todouble(OS_Currency) - todouble(OS_Currency1))/todouble(OS_Currency1))*100, 2)
| extend App_Currency_MoMChange = round((todouble(todouble(App_Currency) - todouble(App_Currency1))/todouble(App_Currency1))*100, 2)
| extend NPS_Score_MoMChange = round((todouble(todouble(NPS) - todouble(NPS1))/todouble(NPS1))*100, 2)
| extend PerfP95_MoMChange = round((todouble(todouble(PerfP95_Score) - todouble(PerfP95_Score1))/todouble(PerfP95_Score1))*100, 2)
| extend PerfBoot_Score_MoMChange = round((todouble(todouble(PerfBoot_Score) - todouble(PerfBoot_Score1))/todouble(PerfBoot_Score1))*100, 2)
| extend CH_Score_MoMChange = round((todouble(todouble(CH_Rate) - todouble(CH_Rate1))/todouble(CH_Rate1))*100, 2)
| extend FQ_Score_MoMChange = round((todouble(todouble(FQ_Rate) - todouble(FQ_Rate1))/todouble(FQ_Rate1))*100, 2)
| extend EngagedUsers_MoMChange = round((round((100*todouble(EngagedUsers_Score))/todouble(MAU),2) - round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2))/round((100*todouble(EngagedUsers_Score1))/todouble(MAU1),2)*100, 2)
|extend Engagement =round(todouble(100 * EngagedUsers_Score) / MAU,2),MoM_Engagement = EngagedUsers_MoMChange,Color_Engagement = GetColor(EngagedUsers_Color),
OS_Currency = round(todouble(OS_Currency),2),MoM_OSCurrency = OS_Currency_MoMChange,Color_OSCurrency = GetColor(OS_Currency_Color),
App_Currency = round(todouble(App_Currency),2),MoM_AppCurrency = App_Currency_MoMChange,Color_AppCurrency = GetColor(App_Currency_Color),
NPS = round(todouble(NPS),2),MoM_NPS=NPS_Score_MoMChange,Color_NPS = GetColor(NPS_Color),
PerfP95=PerfP95_Score,MoM_PerfP95=round(PerfP95_MoMChange,2),Color_PerfP95=GetColor(Perf_Color),
FQ = round(todouble(FQ_Rate),2),MoM_FQ=FQ_Score_MoMChange,Color_FQ = GetColor(FQ_Color),
CH = round(todouble(CH_Rate),2),MoM_CH=CH_Score_MoMChange,Color_CH = GetColor(CH_Color),
BootThreshold=round(todouble(PerfBoot_Score),2),MoM_BootThreshold=round(PerfBoot_Score_MoMChange,2),
Color_BootThreshold=GetColor(Perf_Color),HealthColor=GetColor(Color)
| project Engagement,OSCurrency=OS_Currency,AppCurrency=App_Currency,NPS,PerfP95 = iff(isnotnull(PerfP95), PerfP95, 0), BootThreshold,FQ,CH,MoM_Engagement,MoM_OSCurrency,MoM_AppCurrency,
   MoM_NPS,MoM_PerfP95,MoM_BootThreshold,MoM_FQ,MoM_CH,Color_Engagement,Color_OSCurrency,Color_AppCurrency,Color_NPS,Color_PerfP95,
 Color_BootThreshold,Color_FQ,Color_CH,HealthColor,Score,Application`;
