import { isTest } from "utils/WebConstants";

export const webCoreMetrics: string = `
// Web CoreMetrics
let maxDate = GetCurrentDate(${isTest});
StrategicTenants
    | where \${levelColumnName} == "\${id}" and Date == maxDate
    | project \${levelColumnName}, OrgName=iff(OrgName =="MicrosoftStanleyGTest", "Microsoft", OrgName_Translated)
    | take 1
    | join kind = inner
    (
        Score
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == "\${date}" and \${levelColumnName} == "\${id}" and Application == "\${app}" and UiHost == 'All' and CompositeScore_Color != 'Gray'
    )
    on \${levelColumnName}
        | project
            MauRate = round(MauRate*100, 2), Color_MauRate = Color_Mau, MoM_MauRate = round(MoM_MauRate*100,2),
            MeuRate = round(MeuRate*100, 2), Color_MeuRate = Color_Meu, MoM_MeuRate = round(MoM_MeuRate*100,2),
            EngagedEditorsRate = round(EngagedEditorsRate*100, 2), Color_EngagedEditorsRate = Color_EngagedEditors, MoM_EngagedEditorsRate = round(MoM_EngagedEditorsRate*100,2),
            ReturningMauRate = round(ReturningMauRate*100, 2), Color_ReturningMauRate = Color_ReturningMau, MoM_ReturningMauRate = round(MoM_ReturningMauRate*100,2),
            EUPLP95 = round(EUPL_P95, 2), Color_EUPLP95 = Color_Performance, MoM_EUPLP95 = round(MoM_EUPLP95*100,2),
            EUPLP50 = round(EUPL_P50, 2), Color_EUPLP50 = Color_Performance, MoM_EUPLP50 = round(MoM_EUPLP50*100,2),
            ACEFreeUsersRate = round(ACEFreeUsersRate*100, 2), Color_ACEFreeUsersRate = Color_ACEFreeUsers, MoM_ACEFreeUsersRate = round(MoM_ACEFreeUsersRate*100,2),
            ICEFreeUsersRate = round(ICEFreeUsersRate*100, 2), Color_ICEFreeUsersRate = Color_ICEFreeUsers, MoM_ICEFreeUsersRate = round(MoM_ICEFreeUsersRate*100,2),
            Feedback = round(Feedback_SubScore, 2), Color_Feedback, MoM_Feedback = round(MoM_Feedback*100,2),
            OpenInClientRate = round(OpenInClientRate*100, 2), Color_OpenInClientRate = Color_OpenInClient, MoM_OpenInClientRate = round(MoM_OpenInClientRate*100,2),
            HealthColor = CompositeScore_Color, Score=CompositeScore, Application`;

export const webCoreMetrics_V2: string = `
// Web CoreMetrics V2
let maxDate = GetCurrentDate(${isTest});
let prevDate = format_datetime(datetime_add('Month', -1, todatetime("\${date}")), "yyyy-MM-dd");
StrategicTenants
    | where \${levelColumnName} == "\${id}" and Date == maxDate
    | project \${levelColumnName}, OrgName=iff(OrgName =="MicrosoftStanleyGTest", "Microsoft", OrgName_Translated)
    | take 1
    | join kind = inner
    (
        Score_V2
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == "\${date}" and \${levelColumnName} == "\${id}" and Application == "\${app}" and UiHost == 'All' and CompositeScore_Color != 'Gray'
        | join kind = leftouter (
            Score_V2
                | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                | where Date == prevDate and \${levelColumnName} == "\${id}" and Application == "\${app}" and UiHost == 'All' and CompositeScore_Color != 'Gray'
                | project \${levelColumnName},
                            prevASHAScore = ASHA,
                            PreviousMauRate = MauRate,
                            PreviousMeuRate = MeuRate,
                            PreviousEngagedEditorsRate = EngagedEditorsRate,
                            PreviousReturningMauRate = ReturningMauRate,
                            PreviousEUPL_P95 = EUPL_P95,
                            PreviousEUPL_P50 = EUPL_P50,
                            PreviousFeedback_SubScore = Feedback_SubScore,
                            PreviousOpenInClientRate = OpenInClientRate
        )
        on \${levelColumnName}
        | extend MoM_ASHA = ASHA - prevASHAScore
    )
    on \${levelColumnName}
        | extend MauRateMom = round((MoM_MauRate / PreviousMauRate) * 100.0,2)
        | extend MeuRateMom = round((MoM_MeuRate / PreviousMeuRate) * 100.0,2)
        | extend EngEditorsMom = round((MoM_EngagedEditorsRate / PreviousEngagedEditorsRate) * 100.0,2)
        | extend ReturningMauMom = round((MoM_ReturningMauRate / PreviousReturningMauRate) * 100.0 ,2)
        | extend EUPLP95Mom = round((MoM_EUPLP95 / PreviousEUPL_P95) * 100.0,2)
        | extend EUPLP50Mom = round((MoM_EUPLP50 / PreviousEUPL_P50) * 100.0,2)
        | extend Feedback_MoMChange = iff( PreviousFeedback_SubScore == 0, 100.00, round((MoM_Feedback / PreviousFeedback_SubScore) * 100.0,2))
        | extend OpenInClientMom = round((MoM_OpenInClientRate / PreviousOpenInClientRate) *100.0,2)
        | project
            MauRate = round(MauRate*100, 2), Color_MauRate = Color_Mau, MoM_MauRate = MauRateMom,
            MeuRate = round(MeuRate*100, 2), Color_MeuRate = Color_Meu, MoM_MeuRate = MeuRateMom,
            EngagedEditorsRate = round(EngagedEditorsRate*100, 2), Color_EngagedEditorsRate = Color_EngagedEditors, MoM_EngagedEditorsRate = EngEditorsMom,
            ReturningMauRate = round(ReturningMauRate*100, 2), Color_ReturningMauRate = Color_ReturningMau, MoM_ReturningMauRate = ReturningMauMom,
            EUPLP95 = round(EUPL_P95, 2), Color_EUPLP95 = Color_Performance, MoM_EUPLP95 = EUPLP95Mom,
            EUPLP50 = round(EUPL_P50, 2), Color_EUPLP50 = Color_Performance, MoM_EUPLP50 = EUPLP50Mom,
            ASHA = round(ASHA, 2), Color_ASHA = Color_ASHA, MoM_ASHA = round(MoM_ASHA, 2),
            Feedback = round(Feedback_SubScore, 2), Color_Feedback, MoM_Feedback = Feedback_MoMChange,
            OpenInClientRate = round(OpenInClientRate*100, 2), Color_OpenInClientRate = Color_OpenInClient, MoM_OpenInClientRate = OpenInClientMom,
            HealthColor = CompositeScore_Color, Score=CompositeScore, Application`;
