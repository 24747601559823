export const webconsumerCompositeScore: string = `
//webConsumerCompositeScore
let app = "\${application}";
let currMonthdate = "\${date}";
let cohort = "\${cohort}";
let pivot = "All";
let host = "OneDriveWOPI";
let prevMonthdate = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
ConsumerOverviewScore
| where CohortType == cohort and Application == app and Date == currMonthdate and PivotName == pivot and CohortName != "All" and Host == host
| extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
| join kind=leftouter(
    ConsumerOverviewScore
    | where CohortType == cohort and Application == app and Date == prevMonthdate and PivotName == pivot and CohortName != "All" and Host == host
    | extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
    | project CohortName, Application, PrevScore = Score
) on Application, CohortName
| extend RetentionRate = iff(CohortName has "New", double(null), RetentionRate)
| extend RetentionRate_Color = iff(CohortName has "New", "Gray", RetentionRate_Color)  // Todo: remove this condition after fix on cosmos
| project CohortName, Application,
    Composite_Score = round(Score, 2),
    Status = Score_Color,
    Composite_MoMChange = Score - PrevScore,
    MAU_Score = MAU,
    MAURate_Score = MAURate * 100, MAURate_Score_Color = MAURate_Color,
    CoreActionMAURate_Score = MEURate * 100, CoreActionMAURate_Score_Color = MEURate_Color,
    RetentionRate_Score = RetentionRate * 100, RetentionRate_Score_Color = RetentionRate_Color,
    EUPL_P95_Score = Performance_Score.EUPL_P95, EUPL_P95_Score_Color = Performance_Color.EUPL_P95,
    EUPL_P50_Score = Performance_Score.EUPL_P50, EUPL_P50_Score_Color = Performance_Color.EUPL_P50,
    ASHA_Score = Reliability_Score.ASHA, ASHA_Score_Color = Reliability_Color.ASHA,
    Feedback_Score = Feedback_Score.SAS, Feedback_Score_Color = Feedback_Color.SAS
    | sort by Composite_Score asc, MAU_Score desc
`;
