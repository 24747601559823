import React, { useEffect, useState } from "react";
import { ChartHoverCard, LineChart } from "@fluentui/react-charting";
import {
    IChartProps,
    ICustomizedCalloutData,
    ICustomizedCalloutDataPoint,
} from "@fluentui/react-charting/lib/types/IDataPoint";
import { EmptyState } from "@m365-admin/empty-state";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { emptyStateStyle } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { format } from "date-fns";
import { fetchAdvisoriesHistory } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/apihandler";
import {
    AdvisoriesHistoricProps,
    AdvisoriesHistoricState,
    AdvisoryFilterNames,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { ADMINCENTER_MESSAGES } from "utils/Messages";

const customToolTip = (props: ICustomizedCalloutData) => {
    return props ? (
        <div>
            {props.values.map(
                (value: ICustomizedCalloutDataPoint, index: number) => {
                    return (
                        <div key={value.legend}>
                            <ChartHoverCard
                                styles={{ calloutContentRoot: { paddingBottom: 2 } }}
                                XValue={
                                    // only show date once at the top
                                    index === 0
                                        ? format(props.x as Date, "MM/dd/yyyy")
                                        : null
                                }
                                Legend={value.legend}
                                color={value.color}
                                YValue={`${value.y} ${value.yAxisCalloutData["unit"]}`}
                            />
                            <span className="flyoutChartToolTip">{`${value.yAxisCalloutData["eventCount"]} ${value.yAxisCalloutData["suffix"]}`}</span>
                        </div>
                    );
                },
            )}
        </div>
    ) : null;
};

export const AdvisoriesFlyoutHistoricChart = ({
    selectedRow,
    date,
    id,
}: AdvisoriesHistoricProps) => {
    const [chartState, setChartData] = useState<AdvisoriesHistoricState>({
        data: [],
        loading: false,
        loadingFailed: false,
        error: "",
    });

    useEffect(() => {
        setChartData((oldState) => {
            return {
                ...oldState,
                loading: true,
            };
        });

        const queryParams = {
            date,
            id,
            channel: selectedRow.Channel,
            product: selectedRow.Product,
            metric: selectedRow[AdvisoryFilterNames.Metric],
            currentBuild: selectedRow.BuildVersion,
            comparedBuild: selectedRow.ComparedBuildVersion,
        };
        fetchAdvisoriesHistory(setChartData, selectedRow, queryParams);
    }, [selectedRow, date, id]);

    const chartData: IChartProps = {
        chartTitle: "TenantAdvisoriesHistoricChart",
        lineChartData: chartState.data,
    };

    return chartState.loading ? (
        <ShimmeredCardBody />
    ) : chartState.loadingFailed ? (
        <EmptyState
            title={ADMINCENTER_MESSAGES.NO_DATA_LOADING_FAILED}
            body={chartState.error}
            styles={emptyStateStyle}
        />
    ) : chartState.data.length === 0 ? (
        <EmptyState
            title={ADMINCENTER_MESSAGES.NO_DATA_HISTORIC_CHART}
            body={""}
            styles={emptyStateStyle}
        />
    ) : (
        <div style={{ width: 550, height: 250 }}>
            <LineChart
                data={chartData}
                strokeWidth={4}
                tickFormat={"%m/%d"}
                onRenderCalloutPerDataPoint={customToolTip}
            />
        </div>
    );
};
