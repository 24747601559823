import * as API from "api/index";
import { ERFMColumnConfig } from "components/ERFMComponents/ERFMHelper";
import {
    ERFMFiltersType,
    ERFMQueryNames,
    ERFMRowType,
    ERFMTableType,
    MonthGrouping,
} from "components/ERFMComponents/types";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { formatDisplayDate } from "utils/Helpers";

export const fetchERFMData = async (
    filters: ERFMFiltersType,
    queryName: ERFMQueryNames,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...filters };

    if (queryParams["application"] === "WXP") {
        queryParams["application"] = "All";
    }

    const response = await API.getKustoResponse({
        queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return response?.data;
};

export const formatDateOptions = (
    dataJson: KustoResponseType<string | number>,
): string[] => {
    const erfmDistinctDatesData = dataJson.Tables[0];
    if (erfmDistinctDatesData.Rows.length === 0) {
        return [];
    }

    const distinctDates = erfmDistinctDatesData.Rows.map(
        (date) => date[0] as string,
    );

    const datesMonthGrouping: MonthGrouping = {};

    distinctDates.forEach((date) => {
        const convertedMonth = formatDisplayDate(date);

        if (!(convertedMonth in datesMonthGrouping)) {
            datesMonthGrouping[convertedMonth] = [];
        }

        datesMonthGrouping[convertedMonth].push(date);
    });

    return distinctDates;
};

export const formatTableData = (
    dataJson: KustoResponseType<string | number>,
): ERFMTableType => {
    const erfmDataTable = dataJson.Tables[0];

    const formattedData: ERFMTableType = {};

    if (erfmDataTable.Rows.length === 0) {
        return formattedData;
    }

    // Schema: Date, Application, Segment, MAU, CAMAU, PercentCAMAU,
    // M1, M2, M3, PercentConvertedUsers, UAECrashRate, WatsonCrashRate
    erfmDataTable.Rows.forEach((dataRow) => {
        const date = dataRow[0] as string;
        const application = dataRow[1] as string;

        if (!(application in formattedData)) {
            formattedData[application] = [];
        }

        formattedData[application].push({
            Date: date,
            Segment: dataRow[2] as string,
            MAU: dataRow[3] as number,
            PercentCAMAU: dataRow[5] as number,
            M1: dataRow[6] as number,
            M2: dataRow[7] as number,
            M3: dataRow[8] as number,
            PercentConvertedUsers: dataRow[9] as number,
            UAECrashRate: dataRow[10] as number,
            WatsonCrashRate: dataRow[11] as number,
        });
    });

    const SegmentOrder = Object.fromEntries(
        Object.keys(ERFMColumnConfig).map((key, index) => [key, index]),
    );

    Object.keys(formattedData).forEach((app) => {
        formattedData[app].sort((a: ERFMRowType, b: ERFMRowType) => {
            // First sort by date
            const dateOrder = b.Date.localeCompare(a.Date);

            if (dateOrder === 0)
                return SegmentOrder[a.Segment] - SegmentOrder[b.Segment];

            return dateOrder;
        });
    });

    return formattedData;
};

export const formatAppChartData = (dataJson: KustoResponseType<string | number>) => {
    const erfmChartData = dataJson.Tables[0];
    const formattedData = {};

    // Schema: Date, Segment, Metric, Applications
    erfmChartData.Rows.forEach((dataRow) => {
        const date = dataRow[0] as string;
        const segment = dataRow[1] as string;
        const metric = dataRow[2] as string;

        if (!(segment in formattedData)) {
            formattedData[segment] = {};
        }
        if (!(metric in formattedData[segment])) {
            formattedData[segment][metric] = [];
        }

        const pivotColumnNames = erfmChartData.Columns.filter(
            (column) =>
                column["ColumnName"] !== "Date" &&
                column["ColumnName"] !== "Metric" &&
                column["ColumnName"] !== "Segment",
        );

        formattedData[segment][metric].push({
            date,
            ...Object.fromEntries(
                pivotColumnNames.map((column, index) => [
                    column["ColumnName"],
                    dataRow[index + 3],
                ]),
            ),
        });
    });

    return formattedData;
};

export const formatCountryChartData = (
    dataJson: KustoResponseType<string | number>,
) => {
    const erfmChartData = dataJson.Tables[0];
    const formattedData = {};

    // Schema: Date, Application, Segment, Metric, Countries
    erfmChartData.Rows.forEach((dataRow) => {
        const date = dataRow[0] as string;
        const application = dataRow[1] as string;
        const segment = dataRow[2] as string;
        const metric = dataRow[3] as string;

        if (!(application in formattedData)) {
            formattedData[application] = {};
        }

        if (!(segment in formattedData[application])) {
            formattedData[application][segment] = {};
        }
        if (!(metric in formattedData[application][segment])) {
            formattedData[application][segment][metric] = [];
        }

        const pivotColumnNames = erfmChartData.Columns.filter(
            (column) =>
                column["ColumnName"] !== "Date" &&
                column["ColumnName"] !== "Application" &&
                column["ColumnName"] !== "Metric" &&
                column["ColumnName"] !== "Segment",
        );

        formattedData[application][segment][metric].push({
            date,
            ...Object.fromEntries(
                pivotColumnNames.map((column, index) => [
                    column["ColumnName"],
                    dataRow[index + 4],
                ]),
            ),
        });
    });

    return formattedData;
};
