export const win32MetaData: string = `
//win32MetaData (populate list of cohorts the tenant belongs to, on tiles)
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") }; 
let currentDate = GetCurrentDate(false);
StrategicTenants
| where Date == currentDate and \${levelColumnName} == "\${id}" 
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO 
| project OmsTenantId,
            TenantName = TenantName_Translated,
            OrgName = OrgName_Translated,
            tolong(Tpid),
            IsS500,
            IsEPA,
            IsS2500,
            IsGoogleMove,
            IsGov,
            IsEO
| limit 1 
|join kind = leftouter (
    HealthyTenantScore_Win32Overview 
    | project-rename Tpid = TopParentId
    | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}" and Date == "\${date}" and Application == "\${app}"
    | extend Current_Status=GetColor(Color)
    | project-away PastStatus
    ) on \${levelColumnName}  
| join kind = leftouter (
    HealthyTenantScore_PastStatus 
    | project-rename Tpid = TopParentId 
    | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application =="\${app}"
    ) on \${levelColumnName}
| join kind=leftouter (
    HealthyTenantScore_Win32Overview 
    | project-rename Tpid = TopParentId
    | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and \${levelColumnName} == "\${id}" and Date == format_datetime(datetime_add('Month', -1, todatetime("\${date}")), "yyyy-MM-dd") and Application == "\${app}"
    | project \${levelColumnName}, PrevMonthScore = round(Score, 2)
    ) on \${levelColumnName}
| project Parent=Tpid,
                    \${levelColumnName},
                    OrgName=iif("\${level}" == "Tpid", OrgName, TenantName),
                    Score=round(Score, 2),
                    Current_Status,
                    PastStatus,
                    ScoreMoMChange=round(Score - PrevMonthScore, 2),
                    IsS500,
                    IsEPA,
                    IsS2500,
                    IsGoogleMove,
                    IsGov,
                    IsEO`;
