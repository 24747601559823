import * as API from "api";
import { KustoResponseType, Table } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { SummarizedInsightsRequestState, TenantListItem } from "./types";

export const formatSupportedTenantList = (response: KustoResponseType<string>) => {
    const tenantList: Table<string> = response?.Tables[0];

    const formattedTenantList: TenantListItem[] = [];

    tenantList.Rows.forEach((element) => {
        formattedTenantList.push({
            TenantId: element[0] as string,
            TenantName: element[1] as string,
        });
    });

    return formattedTenantList;
};

export const fetchSupportedTenantList = async (
    setTenantList: React.Dispatch<React.SetStateAction<TenantListItem[]>>,
    setState: React.Dispatch<React.SetStateAction<SummarizedInsightsRequestState>>,
) => {
    try {
        setState((state) => {
            return {
                ...state,
                isFetching: true,
            };
        });
        const supportedTenantListResponse = await API.getSupportedTenantList();
        const tenantList: TenantListItem[] = formatSupportedTenantList(
            supportedTenantListResponse?.supportedTenantList,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
            };
        });
        setTenantList(tenantList);
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "SummarizedInsightsRequestTenantListFetchFailed",
            ),
            {
                message:
                    "Failure while fetching the supported tenant list for Summarized Insights Request Page",
            },
            error,
        );
        setState((state) => {
            return {
                ...state,
                isFetching: false,
                isErrord: true,
                ErrorList: [
                    ...state.ErrorList,
                    ...[
                        "Failed to fetch the supported tenant list. Please enter the TenantId in the text box.",
                    ],
                ],
            };
        });
    }
};
