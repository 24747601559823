import { VulnerabilityAggregation } from "components/TenantDrilldownComponents/SecurityVulnerabilities/types";

export const aggregationTypes: { [id: string]: VulnerabilityAggregation } = {
    Average: {
        axisTitle: "Average Vulnerabilities per Device",
        dataKey: "AveragePerDevice",
    },
    Total: {
        axisTitle: "Total Vulnerabilities",
        dataKey: "TotalVulnerabilities",
    },
};
