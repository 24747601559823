import React from "react";
import { shouldShowConfidentialityBanner } from "utils/InsightsFrameHelpers";
import { CONFIDENTIAL_MESSAGE } from "utils/Messages";

const ConfidentialityBanner = () => {
    if (shouldShowConfidentialityBanner(window.location)) {
        return (
            <div className="confidentiality-banner">
                <em>{CONFIDENTIAL_MESSAGE}</em>
            </div>
        );
    }

    return <></>;
};

export default ConfidentialityBanner;
