import { IDropdownOption } from "@fluentui/react";

export * from "utils/Constants";

export const Filters = {
    DefaultRankOn: "WXPO",
    DefaultLevel: "TenantId",
    DefaultCohort: "S500",
    DefaultMinMau: "1000",
    AppsToRankOn: [
        "WXP",
        "WXPO",
        "WXPON",
        "Excel",
        "OneNote",
        "PowerPoint",
        "Outlook",
        "Word",
    ],
    TenantStatusList: ["Blue", "Yellow", "Red", "Deep Red"],
};

export const OSCustomerNameMapping = {
    RS1: "Windows 10",
    RS2: "Windows 10",
    RS3: "Windows 10",
    RS4: "Windows 10",
    RS5: "Windows 10",
    TH1: "Windows 10",
    TH2: "Windows 10",
    "19H1": "Windows 10",
    "19H2": "Windows 10",
    Vibranium: "Windows 10",
    Cobalt: "Windows 10",
    Iron: "Windows 10",
    Manganese: "Windows 10",
    "Windows 10 (Other)": "Windows 10",
};

export const workLoadCrossAppColumnsToExclude = {
    WXP: [
        "OneNote_Performance_AppColor",
        "Outlook_Performance_AppColor",
        "OneNote_Reliability_AppColor",
        "Outlook_Reliability_AppColor",
        "OneNote_Feedback_AppColor",
        "Outlook_Feedback_AppColor",
    ],
    WXPO: [
        "OneNote_Performance_AppColor",
        "OneNote_Reliability_AppColor",
        "OneNote_Feedback_AppColor",
    ],
    Office: [
        "Win32_Issues",
        "Web_Issues",
        "Current_Web_Issues",
        "Current_Win32_Issues",
        "Previous_Web_Issues",
        "Previous_Win32_Issues",
    ],
};

export const disableSortingColumnList = [
    "PastStatus",
    "Currency_DominantChannelFork_Text",
];

export const initialDropDownOptions: IDropdownOption[] = [{ key: "", text: "" }];
