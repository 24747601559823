import { fetchFunnelDataFromKusto } from "components/CommonFunnelComponents/CommonFunnel/ApiHandler";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import {
    DecompositionAutoPivotInsight,
    DecompositionAutoPivotNode,
} from "components/CommonFunnelComponents/CommonFunnelDecompositionAutoPivots/types";
import { FunnelQueryType, FunnelType } from "config/FunnelConfig";
import { KustoResponseType, MetricsResponseType } from "pages/commonTypes";

export const getFunnelDecompositionAutoPivots = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
    startStage: string | null,
): Promise<MetricsResponseType> => {
    if (!filters.additionalFilters) {
        filters.additionalFilters = {};
    }
    filters.additionalFilters["selectedStage"] = startStage ?? "All";

    const payload = await fetchFunnelDataFromKusto(
        filters,
        funnelType,
        FunnelQueryType.Decomposition,
    );

    return payload;
};

// Expected schema: StartStage, EndStage, Depth, ExampleCount, GroupSize, Novelty, PValue, Opportunity, GroupLoss, LossRelativeToStage, Explanation
export const formatAutoPivotDecompositionData = (
    decompData: KustoResponseType<string | number>,
): DecompositionAutoPivotInsight[] => {
    const decompInsights: DecompositionAutoPivotInsight[] = [];

    decompData.Tables[0].Rows.forEach((row) => {
        const nodes = JSON.parse(row[10] as string) as DecompositionAutoPivotNode[];
        const insight: DecompositionAutoPivotInsight = {
            startStage: row[0] as number,
            endStage: row[1] as number,
            depth: row[2] as number,
            exampleCount: row[3] as number,
            groupSize: row[4] as number,
            novelty: row[5] as number,
            pvalue: row[6] as number,
            opportunity: row[7] as number,
            groupLoss: row[8] as number,
            lossRelativeToStage: row[9] as number,
            nodeList: nodes,
            transformedExplanation: unpackExplanation(nodes),
        };

        decompInsights.push(insight);
    });

    return decompInsights;
};

export const unpackExplanation = (
    explanation: DecompositionAutoPivotNode[],
): string => {
    let pivots = new Map<string, string[]>();
    explanation.forEach((element) => {
        const key = `${element.pivot}: ${element.name}`;
        if (!pivots.has(key)) {
            pivots.set(key, [element.value]);
        } else {
            pivots.get(key).push(element.value);
        }
    });

    let descriptions: string[] = [];
    pivots.forEach((values, key) => {
        let description = `${key}`;
        if (values.length > 1) {
            description += " ";
            description += values.join(" and ");
        } else if (values.length === 1) {
            if (values[0] === "==0") {
                description = replaceWithNegation(description);
            } else if (values[0] !== ">0") {
                description += " " + values[0];
            }
        }
        descriptions.push(description);
    });

    return descriptions.join(", ");
};

// Some "not" values have natural negations, e.g. "Not Day0" -> "NonDay0",
// when converting the explanation to a human-readable format.
export const replaceWithNegation = (explanation: string): string => {
    return explanation
        .replace(": ", ": Not ")
        .replace("Not NonDay0", "Day0")
        .replace("Not Day0", "NonDay0")
        .replace("Not Returning", "New")
        .replace("Not New", "Returning")
        .replace("Not Free", "Paid")
        .replace("Not Paid", "Free");
};
