import React, { useState, useEffect } from "react";
import { Stack, Toggle } from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { horizontalStackTokens } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { RetentionTable } from "./RetentionTable";

interface RetentionState {
    payload: Array<Array<[string, number]>>;
}
const initialState: RetentionState = {
    payload: [],
};

export const Retention = (props) => {
    const { filters, metricFilters } = props;
    const [showAllUsers, toggleAllUsers] = useState<boolean>(true);
    const [showActiveUsers, toggleActiveUsers] = useState<boolean>(true);
    const [data, setData] = useState<RetentionState>(initialState);
    const tenantId = filters["id"];

    const onUserTypeToggleChange = () => {
        toggleAllUsers((showAllUsers) => !showAllUsers);
    };

    const onUserActionToggleChange = () => {
        toggleActiveUsers((showActiveUsers) => !showActiveUsers);
    };

    const getRetentionResponse = async (filters, metricFilters) => {
        let queryParams = {};
        const levelParams = API.getQueryParamsForLevel(filters["level"]);

        queryParams["id"] = filters["id"];
        queryParams["app"] = filters["rankOn"];
        queryParams["host"] = metricFilters["Host"];
        queryParams["uiHost"] = metricFilters["UiHost"];
        queryParams["userType"] = showAllUsers ? "All" : "New";
        queryParams["isEdit"] = !showActiveUsers;

        queryParams = { ...levelParams, ...queryParams };

        const retentionResponse = await API.getKustoResponse({
            queryName: "retention",
            platform: Workload.WEB,
            queryParams,
        }).catch((error) => {
            return Promise.reject(error);
        });

        return retentionResponse;
    };

    const getPayloadData = async (filters: {}, metricFilters: {}) => {
        setData((data) => {
            return {
                ...data,
            };
        });

        try {
            const response = await getRetentionResponse(filters, metricFilters);
            const formattedResponse = response.data?.Tables[0]?.Rows;

            setData({
                payload: formattedResponse,
            });
        } catch (err) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "RetentionMetricsFetchFailed",
                ),
                {
                    message: "Failed to fetch or format Retention Table Metrics",
                },
                err,
            );
            setData({
                payload: [],
            });
        }
    };

    useEffect(() => {
        getPayloadData(filters, metricFilters);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, showAllUsers, showActiveUsers]);

    return (
        <>
            <h2 className="commonPageBlockTitle">Retention</h2>
            {data.payload.length === 0 ? (
                <EmptyTableContainer />
            ) : (
                <>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Toggle
                            inlineLabel
                            checked={!showAllUsers}
                            onText="New Users"
                            offText="All Users"
                            onChange={onUserTypeToggleChange}
                            className="filterToggle"
                        />
                        <Toggle
                            inlineLabel
                            checked={!showActiveUsers}
                            onText="Edit Users"
                            offText="Active Users"
                            onChange={onUserActionToggleChange}
                            className="filterToggle"
                        />
                    </Stack>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item grow={1}>
                            <RetentionTable payload={data.payload} />
                        </Stack.Item>
                    </Stack>
                </>
            )}
        </>
    );
};
