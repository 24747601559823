import React from "react";
import { ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import TenantDetailsList from "components/TenantDrilldownComponents/TenantList/TenantDetailsList";
import { NO_DATA_MESSAGES } from "utils/Messages";

const ScrollableTenantDetailsList = (props) => {
    const {
        loading,
        columns,
        tenantListItems,
        platform,
        tpidGroup,
        isCompactMode,
        showTenantStatus,
        onHeaderRowClick,
        onDetailsRowClick,
    } = props;

    const sbWidth = 6;
    const sbThumbBg = "rgb(200, 198, 196)";
    const scrollbarStyles = {
        // Firefox
        ".ms-ScrollablePane--contentContainer": {
            scrollbarWidth: sbWidth,
            scrollbarColor: `${sbThumbBg} `,
            scrollbarBorderRadius: "6px",
        },

        // Chrome, Edge, etc
        ".ms-ScrollablePane--contentContainer::-webkit-scrollbar": {
            width: sbWidth,
            background: "#eeeeee99",
        },
        ".ms-ScrollablePane--contentContainer::-webkit-scrollbar-thumb": {
            background: sbThumbBg,
            width: "5px",
            borderRadius: "6px",
            height: "50px",
        },
    };

    return (
        <>
            {" "}
            {tenantListItems.length === 0 && !loading ? (
                <ShimmeredDetailsListWrapper
                    items={[]}
                    message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                />
            ) : (
                <ScrollablePane
                    scrollbarVisibility={ScrollbarVisibility.auto}
                    styles={{
                        root: {
                            selectors: scrollbarStyles,
                        },
                    }}
                >
                    <TenantDetailsList
                        loading={loading}
                        columns={columns}
                        tenantListItems={tenantListItems}
                        tpidGroup={tpidGroup}
                        isCompactMode={isCompactMode}
                        showTenantStatus={showTenantStatus}
                        onHeaderRowClick={onHeaderRowClick}
                        onDetailsRowClick={onDetailsRowClick}
                        platform={platform}
                    />
                </ScrollablePane>
            )}
        </>
    );
};

export default ScrollableTenantDetailsList;
