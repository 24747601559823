import React, { useCallback, useEffect, useState } from "react";
import { curveBasis } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";
import { scaleLinear } from "@visx/scale";
import { Area } from "@visx/shape";
import ISurveyFunnelChartProps from "components/QuIPSurveyReportComponents/SurveyFunnelChart/types";

const x = (d) => d.index;
const y = (d) => Math.max(d.value, 0.25);

function range(n) {
    return Array.from(Array(n).keys());
}

function interpolateData(data) {
    return data.map((d, i) => interpolatePoints(d, data[i + 1])).flat();
}

function interpolatePoints(current, next): Array<any> {
    if (!next) return current;
    const xStep = 0.25;
    const yStep = Math.abs(y(next) - y(current)) * 0.03;
    const yMid1 = Math.abs(y(current) - yStep);
    const yMid2 = Math.abs(y(next) + yStep);
    const xMid1 = Math.abs(x(current) + xStep);
    const xMid2 = Math.abs(x(next) - xStep);
    return [current, { index: xMid1, value: yMid1 }, { index: xMid2, value: yMid2 }];
}

function FunnelChart({ width, height, data: surveyData }: ISurveyFunnelChartProps) {
    const valuePadding = 50;
    const [data, setData] = useState<Array<any>>(interpolateData(surveyData));
    const [numSegments, setNumSegments] = useState<number>(
        Math.max(...surveyData.map(x)),
    );

    useEffect(() => {
        if (surveyData.length === 0) return;
        setData(interpolateData(surveyData));
        setNumSegments(Math.max(...surveyData.map(x)));
    }, [surveyData]);

    const maxValue = useCallback(() => {
        return Math.max(...data.map(y));
    }, [data]);

    const minMax = useCallback(() => {
        return maxValue() + valuePadding;
    }, [maxValue, valuePadding]);

    const padding = useCallback(() => {
        return width / numSegments / 2;
    }, [width, numSegments]);

    const xScale = useCallback(() => {
        return scaleLinear({
            range: [0, width],
            domain: [0, numSegments],
        });
    }, [width, numSegments]);

    const yScale = useCallback(() => {
        return scaleLinear({
            range: [height, 0],
            domain: [-minMax(), minMax()],
        });
    }, [height, minMax]);

    // Shade area of the funnel
    const areas = [
        { pad: 30, opacity: 0.1 },
        { pad: 15, opacity: 0.2 },
        { pad: 0, opacity: 1 },
    ];

    return (
        <>
            {data?.length === 0 ? (
                <div>No data available</div>
            ) : (
                <svg width={width} height={height}>
                    <LinearGradient
                        id="gradient"
                        from="#2167f9"
                        to="#06266a"
                        vertical={false}
                    />
                    {areas.map((area, i) => {
                        return (
                            <Area
                                key={`area-${i}`}
                                id={`area-${i}`}
                                data={data}
                                curve={curveBasis}
                                x={(d) => xScale()(x(d))}
                                y0={(d) => yScale()(y(d) + area.pad)}
                                y1={(d) => yScale()(-y(d) - area.pad)}
                                fill="url(#gradient)"
                                fillOpacity={area.opacity}
                                stroke="transparent"
                            />
                        );
                    })}
                    {data.map((d, i) => {
                        if (!data[i + 1] || i === data.length - 1) return null;
                        const r = range(numSegments);
                        return (
                            <React.Fragment key={`label-${i}`}>
                                {r.includes(x(d)) && (
                                    <>
                                        <text
                                            x={xScale()(x(d)) + padding()}
                                            y={(minMax() / 2) * 0.35}
                                            dy={".33em"}
                                            fontSize={22}
                                            textAnchor="middle"
                                            data-testid="funnel-label"
                                        >
                                            {`${d.label}`}
                                        </text>
                                        <text
                                            x={xScale()(x(d)) + padding() * 0.8}
                                            y={(minMax() / 2) * 0.65}
                                            dy={".33em"}
                                            fontSize={16}
                                            textAnchor="top"
                                        >
                                            (
                                            {d.rawValue.toLocaleString("en-US", {
                                                minimumFractionDigits: 0,
                                            })}
                                            )
                                        </text>
                                        <text
                                            x={xScale()(x(d)) + padding()}
                                            y={height / 2}
                                            dy={".33em"}
                                            fill="white"
                                            fontSize={22}
                                            textAnchor="middle"
                                        >
                                            {`${y(d).toLocaleString("en-US", {
                                                maximumFractionDigits: 2,
                                            })}%`}
                                        </text>
                                    </>
                                )}
                                {r.includes(x(d) + 1) && (
                                    <line
                                        x1={xScale()(x(d) + 1)}
                                        x2={xScale()(x(d) + 1)}
                                        y1={0}
                                        y2={height}
                                        stroke="#fff"
                                        strokeWidth={4}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </svg>
            )}
        </>
    );
}

export default FunnelChart;
