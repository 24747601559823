import { Workload, platformsConfig } from "config/PlatformConfig";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { appStyles } from "utils/WebConstants";

export const parseScoreBreakdown = (scores: string) => {
    try {
        const scoreBreakDown = JSON.parse(scores);
        return Object.keys(scoreBreakDown).map((key) => {
            return {
                name: key,
                icon: appStyles[key]["iconName"],
                score: scoreBreakDown[key].toFixed(2),
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ParseScoreBreakdownException",
            ),
            {
                message: "Exception while parsing score breakdown",
            },
            error,
        );
        return [];
    }
};

export const getTenantDrillDownPageTitle = (platform) => {
    switch (platform) {
        case Workload.WIN32:
        case Workload.WEB:
        case Workload.MAC:
            return `Office ${platformsConfig[platform].platform} Tenant View`;
        case Workload.TEAMS:
            return `${platformsConfig[platform].platform} Tenant View`;
        case Workload.WEB_CONSUMER:
            return `${platformsConfig[platform].platform} Cohort View`;
        default:
            return "Tenant View";
    }
};

export const getWorkloadMetricFiltersInitialState = (platform: string) => {
    switch (platform) {
        case Workload.WIN32:
        case Workload.MAC:
        case Workload.TEAMS:
            return {
                Date: "",
            };
        case Workload.WEB:
            return {
                Date: "",
                Host: "All",
                UiHost: "All",
                UserType: "All",
                IsEdit: false,
            };
        default:
            return {};
    }
};

export const getTenantMetadataTemplate = () => {
    return {
        tpid: null,
        level: "",
        orgName: "",
        status: "",
        cohorts: {
            isS500: false,
            isEpa: false,
            isS2500: false,
            isGoogleMove: false,
            isGov: false,
            isEo: false,
            isCopilot: false,
        },
        pastStatusSet: [""],
        exemptionNote: null,
        momScore: 0,
    };
};

export const getProductInsightsAppList = (platform) => {
    switch (platform) {
        case Workload.WIN32:
        case Workload.WEB:
        case Workload.MAC:
            return [
                ...platformsConfig[platform].appsList,
                ...platformsConfig[platform].crossAppsList,
            ];
        case Workload.TEAMS:
            return platformsConfig[Workload.TEAMS].appsList;
        default:
            return [];
    }
};
