import React, { useEffect, useState } from "react";
import { Dropdown, Icon, Label, Stack, Toggle, TooltipHost } from "@fluentui/react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderTitle,
} from "components/CustomComponents/Card/Card";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { CommonFunnelDecompositionPropsType } from "components/CommonFunnelComponents/CommonFunnelDecomposition/types";
import {
    formatAutoPivotDecompositionData,
    getFunnelDecompositionAutoPivots,
} from "components/CommonFunnelComponents/CommonFunnelDecompositionAutoPivots/ApiHandler";
import { DecompositionAutoPivotInsight } from "components/CommonFunnelComponents/CommonFunnelDecompositionAutoPivots/types";
import { FunnelConfig } from "config/FunnelConfig";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatNumberCompact } from "utils/Helpers";
import "components/CommonFunnelComponents/CommonFunnelDecomposition/styles.css";
import "components/CommonFunnelComponents/CommonFunnelDecompositionAutoPivots/styles.css";

export const DecompTableRow = (
    {
        startStage,
        endStage,
        groupLoss,
        lossRelativeToStage,
        transformedExplanation,
        groupSize,
    }: DecompositionAutoPivotInsight,
    index: number,
): JSX.Element => {
    const colorClass =
        lossRelativeToStage < 0
            ? "decompAutoPivotPositive"
            : "decompAutoPivotNegative";
    return (
        <tr className="decompAutoPivotRow" key={`decompAutoPivotRow-${index}`}>
            <td className="decompText">{transformedExplanation}</td>
            <td>{`Stage ${startStage} to ${endStage}`}</td>
            <td className="decompText">{formatNumberCompact(groupSize, 1)}</td>
            <td className={colorClass}>{`${formatNumberCompact(groupLoss)}%`}</td>
            <td className={colorClass}>{`${formatNumberCompact(
                lossRelativeToStage,
            )}%`}</td>
        </tr>
    );
};

export const filterDecompData = (
    showPositiveGroups: boolean,
    decompData: DecompositionAutoPivotInsight[],
): DecompositionAutoPivotInsight[] => {
    return decompData.filter((decompRow) => {
        return showPositiveGroups || decompRow.lossRelativeToStage > 0;
    });
};

export const getInsightCountDropdownOptions = (
    insightCount: number,
): { key: number; text: string }[] => {
    if (insightCount <= 5) {
        return [{ key: insightCount, text: "All" }];
    }

    if (insightCount <= 10) {
        return [
            { key: 5, text: "5" },
            { key: insightCount, text: "All" },
        ];
    }

    if (insightCount <= 20) {
        return [
            { key: 5, text: "5" },
            { key: 10, text: "10" },
            { key: insightCount, text: "All" },
        ];
    }

    return [
        { key: 5, text: "5" },
        { key: 10, text: "10" },
        { key: 20, text: "20" },
    ];
};

export const CommonFunnelDecompositionAutoPivots = ({
    funnelType,
    filters,
}: CommonFunnelDecompositionPropsType) => {
    const [selectedStage, setSelectedStage] = useState<string>("All");
    const [showPositiveGroups, setShowPositiveGroups] = useState<boolean>(false);
    const [insightCountToShow, setInsightCountToShow] = useState<number>(5);
    const [decompData, setDecompData] = useState<DecompositionAutoPivotInsight[]>(
        [],
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const funnelStages = [{ key: "All", text: "All Stages" }];
    FunnelConfig[funnelType].decompositionStages.forEach((stage, index) => {
        funnelStages.push({ key: (index + 1).toString(), text: `${stage}` });
    });

    useEffect(() => {
        const getDecompData = async () => {
            const queryFilters = { ...filters };
            setLoading(true);
            try {
                const payload = await getFunnelDecompositionAutoPivots(
                    queryFilters,
                    funnelType,
                    selectedStage,
                );
                const decompData = formatAutoPivotDecompositionData(payload);
                setDecompData(decompData);
                setLoading(false);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchFunnelAutoPivotDecompositionDataException",
                    ),
                    {
                        message:
                            "Failed to fetch funnel auto pivot decomposition insights for the given set of filters.",
                    },
                    error,
                );
                setError("Failed to fetch drop-off analysis insights.");
                setLoading(false);
            }
        };
        getDecompData();
    }, [filters, funnelType, selectedStage]);

    return error ? (
        <EmptyTableContainer message={error} />
    ) : (
        <Card>
            <CardHeader>
                <CardHeaderTitle>Funnel Stage Drop-off Analysis</CardHeaderTitle>
            </CardHeader>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <CardBody>
                    <Stack horizontal>
                        <Stack.Item className="decompFilterItem">
                            <Label className="filterLabel">Select a stage:</Label>
                            <Dropdown
                                options={funnelStages}
                                selectedKey={selectedStage}
                                onChange={(_event, item) => {
                                    setSelectedStage(item.key.toString());
                                }}
                                className="filterDropdown"
                                dropdownWidth={150}
                            />
                        </Stack.Item>
                        <Stack.Item className="decompFilterItem">
                            <Label className="filterLabel">Insights to show:</Label>
                            <Dropdown
                                options={getInsightCountDropdownOptions(
                                    filterDecompData(showPositiveGroups, decompData)
                                        .length,
                                )}
                                selectedKey={Math.min(
                                    filterDecompData(showPositiveGroups, decompData)
                                        .length,
                                    insightCountToShow,
                                )}
                                onChange={(_event, item) => {
                                    setInsightCountToShow(item.key as number);
                                }}
                                className="filterDropdown"
                                dropdownWidth={150}
                                disabled={
                                    filterDecompData(showPositiveGroups, decompData)
                                        .length <= 5
                                }
                            />
                        </Stack.Item>
                        <Stack.Item className="decompFilterItem">
                            <Label className="filterLabel">
                                Show above average groups:
                            </Label>
                            <Toggle
                                checked={showPositiveGroups}
                                onChange={() =>
                                    setShowPositiveGroups(!showPositiveGroups)
                                }
                                className="decompFilterToggle"
                            />
                        </Stack.Item>
                    </Stack>
                    <table className="decompAutoPivotTable">
                        <thead>
                            <tr>
                                <th>Group</th>
                                <th>Stages</th>
                                <th>
                                    Group Size
                                    <TooltipHost
                                        hostClassName="decompTooltip"
                                        content={`Group size is an approximation and may vary slightly from corresponding values in the funnel.`}
                                    >
                                        <Icon
                                            iconName="info"
                                            className="decompInfoIcon"
                                        />
                                    </TooltipHost>
                                </th>
                                <th>
                                    Loss
                                    <TooltipHost
                                        hostClassName="decompTooltip"
                                        content={`"Loss" is the percentage of users who dropped off between stages for this group.`}
                                    >
                                        <Icon
                                            iconName="info"
                                            className="decompInfoIcon"
                                        />
                                    </TooltipHost>
                                </th>
                                <th>
                                    Delta
                                    <TooltipHost
                                        hostClassName="decompTooltip"
                                        content={`"Delta" is the percentage point difference in loss between this group and the overall average. A positive number indicates that this group performed worse than average, while a negative number indicates that this group performed better than average.`}
                                    >
                                        <Icon
                                            iconName="info"
                                            className="decompInfoIcon"
                                        />
                                    </TooltipHost>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {decompData.length > 0 ? (
                                filterDecompData(showPositiveGroups, decompData).map(
                                    (decompRow, index) => {
                                        if (index >= insightCountToShow) {
                                            return null;
                                        }

                                        return DecompTableRow(decompRow, index);
                                    },
                                )
                            ) : (
                                <tr
                                    className="decompAutoPivotRow"
                                    key="decompNoStageData"
                                >
                                    <td colSpan={5} className="decompText">
                                        No insights available for this stage
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </CardBody>
            )}
        </Card>
    );
};
