import { isTest } from "utils/WebConstants";

export const collabFunnel: string = `
let maxDate = GetCurrentDate(${isTest});
let dt = iff(isempty("\${date}"), maxDate, "\${date}");
CollabFunnel
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" and Platform == "Web" and UiHost == "\${uiHost}" 
| extend Current = (Date == dt), TenantId = column_ifexists("OmsTenantId", "All")
| project Date, Tpid, TenantId, Application, Platform, UiHost, SignedInUsers, CloudActiveUsers,
    Collaborators, Contributors, AsyncCoEditors, Current
| sort by Date asc
`;
