// This is a wrapper component on top of the ShimmerDetailsList to display a message to the user
// when the no of items to be displayed in the list are empty.
import React from "react";
import { IColumn, IGroup, MessageBar, ShimmeredDetailsList } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { useOnDismissMessageBar } from "utils/Helpers";
import {
    sendDebouncedUserActionTelemetryEvent,
    sendUserActionTelemetryEvent,
} from "utils/PlgTelemetryLogger";

export const ShimmeredDetailsListWrapper = (props) => {
    const { message, getAdditionalTelemetryFields, ...shimmerProps } = props;

    const { isOpen, handleDismissMessageBar } = useOnDismissMessageBar();

    const hasItems = shimmerProps.items.length > 0;
    if (!hasItems && !shimmerProps.enableShimmer) {
        return (
            isOpen && (
                <MessageBar
                    isMultiline={false}
                    onDismiss={handleDismissMessageBar}
                    dismissButtonAriaLabel="Close"
                >
                    {message}
                </MessageBar>
            )
        );
    }

    return (
        <ShimmeredDetailsList
            onColumnResize={(column: IColumn) => {
                sendDebouncedUserActionTelemetryEvent(
                    {
                        userActionName: "TableColumnResize",
                        commandSurface: UserActionCommandSurface.Canvas,
                    },
                    {
                        ...(getAdditionalTelemetryFields
                            ? getAdditionalTelemetryFields()
                            : {}),
                        TableName: props.tableName,
                        ColumnName: column.key,
                    },
                );
            }}
            onColumnHeaderClick={(
                ev?: React.MouseEvent<HTMLElement>,
                column?: IColumn,
            ) => {
                sendUserActionTelemetryEvent(
                    {
                        userActionName: "TableHeaderClick",
                        commandSurface: UserActionCommandSurface.Canvas,
                    },
                    {
                        ...(getAdditionalTelemetryFields
                            ? getAdditionalTelemetryFields()
                            : {}),
                        TableName: props.tableName,
                        ColumnName: column.key,
                    },
                );
            }}
            groupProps={{
                onToggleCollapseAll: (isAllCollapsed: boolean) => {
                    sendUserActionTelemetryEvent(
                        {
                            userActionName: "TableGroupToggleCollapseExpand",
                            commandSurface: UserActionCommandSurface.Canvas,
                        },
                        {
                            ...(getAdditionalTelemetryFields
                                ? getAdditionalTelemetryFields()
                                : {}),
                            TableName: props.tableName,
                            Group: "AllGroups",
                            GroupNewState: isAllCollapsed ? "Collapsed" : "Expanded",
                        },
                    );
                },
                headerProps: {
                    onToggleCollapse: (group: IGroup) => {
                        sendUserActionTelemetryEvent(
                            {
                                userActionName: "TableGroupToggleCollapseExpand",
                                commandSurface: UserActionCommandSurface.Canvas,
                            },
                            {
                                ...(getAdditionalTelemetryFields
                                    ? getAdditionalTelemetryFields()
                                    : {}),
                                TableName: props.tableName,
                                Group: group.key,
                                GroupNewState: group.isCollapsed
                                    ? "Expanded"
                                    : "Collapsed",
                            },
                        );
                    },
                },
            }}
            {...shimmerProps}
        ></ShimmeredDetailsList>
    );
};
