import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { FunnelType } from "config/FunnelConfig";

export interface CommonFunnelDecompositionPropsType {
    funnelType: FunnelType;
    filters: CommonFilterStateType;
}

export enum DecompositionDataType {
    StageLossToStagePct = "StageLossToStagePct",
    StageLossToGroupPct = "StageLossToGroupPct",
}

export interface DecompositionInsight {
    text: string;
    stage: number;
    stageLossToStage: number;
    stageLossToGroup: number;
}

export type DecompositionInsightsType = {
    [key in keyof typeof DecompositionDataType]: DecompositionInsight[];
};
