import React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { TenantAddInOptions, Win32PerfInputUpdates } from "pages/Performance/types";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents } from "utils/AppInsightsHelper";

export const AddInPicker = ({
    progId,
    options,
    onChange,
}: {
    progId: string;
    options: TenantAddInOptions[];
    onChange: (updates: Win32PerfInputUpdates) => void;
}) => {
    const formattedOptions = options.map((option) => ({
        key: option.progId,
        text: option.text,
    }));

    const onProgIdChange = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        if (progId !== option.text) {
            AppInsights.getInstance().LogEvent(
                appInsightsCustomEvents.PerformanceView,
                {
                    type: "UIControl",
                    event: "Add-in change",
                    value: option.key,
                },
            );

            onChange({
                progId: option.key as string,
            });
        }
    };

    return (
        <Dropdown
            label="Add-in"
            options={formattedOptions}
            styles={{
                dropdown: { width: 600 },
            }}
            selectedKey={progId}
            onChange={onProgIdChange}
            className="filterDropdown"
        />
    );
};
