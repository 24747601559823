import { useEffect, useRef } from "react";
/**
 * Custom hook to mimick component did mount functionality
 * @param func function to run when the dependency is 'mounted'
 * @param deps dependency to watch
 */
const useDidMount = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useDidMount;
