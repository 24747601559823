import React, { useEffect, useState } from "react";
import { Callout, DirectionalHint, SearchBox } from "@fluentui/react";
import {
    SearchBarId,
    SearchBarSuggestionCalloutMaxHeight,
    SearchBarSuggestionCalloutMaxWidth,
    SearchBarSuggestionsCalloutClassName,
    SearchBarSuggestionsCalloutDescribedBy,
} from "components/CustomComponents/SearchBarWithSuggestions/SearchBarConstants";
import { SearchSuggestions } from "components/CustomComponents/SearchBarWithSuggestions/types";
import "components/CustomComponents/SearchBarWithSuggestions/style.css";

export const SearchBarWithSuggestions = ({
    defaultPrompt,
    searchSuggestionFilterCallback,
}) => {
    const [items, setItems] = useState<SearchSuggestions>(emptySearchSuggestion);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [showSearchSuggestions, setShowSearchSuggestions] =
        useState<boolean>(false);

    useEffect(() => {
        setItems(searchSuggestionFilterCallback(searchTerm));
    }, [searchSuggestionFilterCallback, searchTerm]);

    const showSuggestions = (
        input:
            | React.ChangeEvent<HTMLInputElement>
            | React.FocusEvent<HTMLInputElement, Element>,
    ) => {
        if (input?.target?.value) {
            setSearchTerm(input.target.value);
            setShowSearchSuggestions(true);
        } else {
            setShowSearchSuggestions(false);
        }
    };

    const renderSuggestions = () => {
        if (showSearchSuggestions) {
            return (
                <Callout
                    className={SearchBarSuggestionsCalloutClassName}
                    ariaDescribedBy={SearchBarSuggestionsCalloutDescribedBy}
                    gapSpace={0}
                    calloutWidth={SearchBarSuggestionCalloutMaxWidth}
                    calloutMaxHeight={SearchBarSuggestionCalloutMaxHeight}
                    coverTarget={false}
                    alignTargetEdge={true}
                    target={`#${SearchBarId}`}
                    directionalHint={DirectionalHint.bottomCenter}
                    isBeakVisible={false}
                    onDismiss={() => setShowSearchSuggestions(false)}
                >
                    {items?.renderResults()}
                </Callout>
            );
        }
    };

    return (
        <div>
            <SearchBox
                id={SearchBarId}
                className={SearchBarId}
                placeholder={defaultPrompt}
                onChange={(input) => {
                    showSuggestions(input);
                }}
                onFocus={(input) => {
                    showSuggestions(input);
                }}
            />

            {renderSuggestions()}
        </div>
    );
};

export const emptySearchSuggestion: SearchSuggestions = {
    renderResults() {
        return <div>{""}</div>;
    },
};
