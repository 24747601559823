import { precisionRound } from "@fluentui/react";
import {
    childKeys,
    parentKeys,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    FunnelStage,
    FunnelReferralSources,
} from "pages/ConsumerDay0Funnel/constants";
import {
    FunnelStageScenarioDataType,
    FunnelStageScenarioReferrerDataType,
} from "pages/ConsumerDay0Funnel/types";

export const getScenarioDataOverTime = (
    scenarioDistributionData: FunnelStageScenarioDataType,
    selectedStage: string,
    isGrouped: boolean,
) => {
    const scenarioDataOverTime = [];
    const accessorLevel = isGrouped ? "innerGroups" : "outerGroups";
    Object.keys(scenarioDistributionData)
        .sort()
        .forEach((date) => {
            const scenarioData = {};
            scenarioData["date"] = date;
            const sd =
                scenarioDistributionData[date]?.[selectedStage]?.[accessorLevel];
            for (const scenarioDataPoint in sd) {
                scenarioData[sd[scenarioDataPoint]["name"]] = {
                    value: sd[scenarioDataPoint]["value"],
                    percentage: parseFloat(
                        sd[scenarioDataPoint]["percent"].toFixed(2),
                    ),
                };
            }
            scenarioDataOverTime.push(scenarioData);
        });
    return scenarioDataOverTime;
};

export const getReferrerSourceDataOverTime = (
    referrerSourceData: FunnelStageScenarioReferrerDataType,
    selectedStage: FunnelStage,
    selectedScenario: string,
    isGrouped: boolean,
) => {
    const referrerSourceDataOverTime = [];
    const keySet = isGrouped ? parentKeys : childKeys;
    Object.keys(referrerSourceData)
        .sort()
        .forEach((date) => {
            let hasAtLeastOneReferrer = false;
            const referrerData = {};
            const referrerSources =
                referrerSourceData[date][selectedStage][selectedScenario][
                    "referralSources"
                ];
            for (const idx in referrerSources) {
                const referrerName = referrerSources[idx]["name"];
                if (referrerName === "All" || !keySet.includes(referrerName))
                    continue;

                hasAtLeastOneReferrer = true;
                const allUpData = referrerSources?.find(
                    (a) => a.name === FunnelReferralSources.All,
                );
                const value = referrerSources[idx]["value"];

                referrerData[referrerName] = {
                    value,
                    percentage: precisionRound(
                        ((value as number) * 100) / (allUpData["value"] as number),
                        2,
                    ),
                };
            }

            if (hasAtLeastOneReferrer) {
                referrerData["date"] = date;
                referrerSourceDataOverTime.push(referrerData);
            }
        });
    return referrerSourceDataOverTime;
};
