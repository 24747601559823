import React from "react";
import {
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    LineChart,
    Line,
    CartesianGrid,
} from "recharts";
import { chartXAxisPaddingStyles, drilldownChartHeight } from "pages/common";
import { appStyles } from "utils/WebConstants";

const ErrorImpactChart = ({ data, error, app, dataLevel, width, cohort }) => {
    return (
        <div>
            <LineChart
                width={width}
                height={drilldownChartHeight}
                data={data}
                margin={{ top: 20 }}
                syncId="anyId"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                <YAxis unit="%" />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    name="Tenant"
                    dataKey={`${error}_on${dataLevel}`}
                    stroke={appStyles[app]["color"]}
                    activeDot={{ r: 8 }}
                    connectNulls={false}
                />
                <Line
                    type="monotone"
                    name={`${cohort} Avg`}
                    dataKey={`${cohort}_${error}_on${dataLevel}`}
                    stroke="#FFA500"
                    activeDot={{ r: 8 }}
                    connectNulls={false}
                />
            </LineChart>
        </div>
    );
};

export default ErrorImpactChart;
