export const funnelSVGWidth = 175;

export const funnelSVGHeight = 700;

export const funnelSVGStrokeColor = "#ffffff";

export const funnelSVGFillColor = "#D9D9D9";

export const funnelIcicleWidth = 220;

export const funnelSVGArrowFill = "#696a6a";

export const funnelTextFill = "#e5e5e5";

export const arrowHeight = 42;
export const arrowTextWidth = funnelSVGWidth - 60;
