export const webconsumergrowthTenantMetrics = `
// webconsumergrowthTenantMetrics
database("ConsumerInsights").WebGrowthMetrics
| where Date == "\${date}"
| where Host == "\${host}"
| where LicenseModel == "\${license}"
| where UserSeenType == "\${seenType}"
| where Referrer == "\${referrer}"
| join kind = inner (MetricMapping | project DisplayName,Group,Metric) on Metric
| project Date, Metric, DisplayName, Group, Application, Value = iff(isempty(Value), 0.0, Value)
| extend GroupOrder = GetMetricGroupOrder(Group), MetricOrder = GetMetricOrder(Metric)
| order by GroupOrder asc, MetricOrder asc, DisplayName asc
| project-away MetricOrder, GroupOrder
`;

export const webconsumergrowthTenantMetricsChart = `
// webconsumergrowthTenantMetricsChart
database("ConsumerInsights").WebGrowthMetrics
| where Metric == "\${metric}"
| where Host == "\${host}"
| where LicenseModel == "\${license}"
| where UserSeenType == "\${seenType}"
| where Referrer == "\${referrer}"
| project Date, Application, Value = round(todecimal(Value),2)
| order by Date asc
`;
