import { isTest } from "utils/WebConstants";

let filterOn = (filter): string => {
    const query = `let dt = iff(isempty("\${date}"),GetCurrentDate(${isTest}), "\${date}");
                   Reliability
                   | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                   | where Application == "\${app}" and Date == dt and ErrorName == "\${errorname}" and Host != "All" and UiHost  != "All"
                   | distinct \${levelColumnName}, ${filter}
                   | summarize count() by ${filter}`;

    return query;
};

let filterOnDateAll = (filter): string => {
    const query = `Reliability
                   | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                   | where Application == "\${app}" and ErrorName == "\${errorname}" and Host != "All" and UiHost  != "All"
                   | distinct \${levelColumnName}, ${filter}
                   | summarize count() by ${filter}`;

    return query;
};

export const errorLookup: {} = {
    TenantsImpacted: `
                // TenantsImpacted - tenants impacted with table data
                let dt = iff(isempty("\${date}"),GetCurrentDate(${isTest}), "\${date}");
                   let currDate = GetCurrentDate(${isTest});
                   Reliability
                   | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                   | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}" and Date==dt 
                   | project-away Application, Host, UiHost
                   | join kind=inner (
                       StrategicTenants
                       | where Date == currDate
                       | extend Name = iff("\${levelColumnName}" == "Tpid", OrgName_Translated, TenantName_Translated)
                       | where Is\${cohort} == true
                       | distinct \${levelColumnName}, Name, Industry
                   ) on \${levelColumnName}
                   | project \${level} = \${levelColumnName}, Name, Industry,ErrorType, UserErrorCount, PercentUsersWithError, SessionErrorCount, PercentSessionsWithError
                   | order by PercentUsersWithError desc`,

    ErrorNames: `
                // ErrorNames - Get all error names per app
                let dt = iff(isempty("\${date}"),GetCurrentDate(${isTest}), "\${date}");
                   Reliability
                   | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") 
                   | where Application == "\${app}" and Date == dt and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName != "" and ErrorName != "Any"
                   | distinct ErrorName
                   | order by ErrorName asc`,

    CohortAvgData: `
                // CohortAvgData - get cohort avg data for each error
                let currDate = GetCurrentDate(${isTest});
                   Reliability
                   | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                   | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}"
                   | project-away Application, Host, UiHost
                   | join kind=inner (
                       StrategicTenants
                       | where Is\${cohort} == true and Date == currDate
                       | distinct \${levelColumnName}
                   )on \${levelColumnName}
                   | summarize CohortTenantCount = count(), sum(UserErrorCount), sum(TotalUsers), sum(SessionErrorCount), sum(TotalSessions) by Date
                   | extend CohortPercentageUsersWithError = round(todouble(sum_UserErrorCount)/sum_TotalUsers*100,2),
                                   CohortPercentageSessionsWithError = round(todouble(sum_SessionErrorCount)/sum_TotalSessions*100,2)                      
                   | project Date, CohortTenantCount, CohortPercentageSessionsWithError, CohortPercentageUsersWithError
                   | order by Date asc`,

    S2500AvgData: `
                   // S2500AvgData - get s2500 avg data for each error
                   let currDate = GetCurrentDate(${isTest});
                      Reliability
                      | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                      | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}"
                      | project-away Application, Host, UiHost
                      | join kind=inner (
                          StrategicTenants
                          | where IsS2500 == true and Date == currDate
                          | distinct \${levelColumnName}
                      )on \${levelColumnName}
                      | summarize S2500TenantCount = count(), sum(UserErrorCount), sum(TotalUsers), sum(SessionErrorCount), sum(TotalSessions) by Date
                      | extend S2500PercentageUsersWithError = round(todouble(sum_UserErrorCount)/sum_TotalUsers*100,2),
                                      S2500PercentageSessionsWithError = round(todouble(sum_SessionErrorCount)/sum_TotalSessions*100,2)                      
                      | project Date, S2500TenantCount, S2500PercentageSessionsWithError, S2500PercentageUsersWithError
                      | order by Date asc`,

    TenantsImpactedCount: `
                // TenantsImpactedCount - # tenants impacted
                Reliability
                  | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                  | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}"
                  | summarize count() by Date
                  | order by Date asc`,

    ChartData: `
                // ChartData for % user, session impacted 
                Reliability
                | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}" and \${levelColumnName} == "\${id}"   
                | project Date, PercentUsersWithError = round(PercentUsersWithError,2), PercentSessionsWithError= round(PercentSessionsWithError,2)
                | order by Date asc, PercentUsersWithError desc`,

    TenantsImpactedByHost: filterOn("Host"),

    TenantsImpactedByUiHost: filterOn("UiHost"),

    TenantsImpactedAllDates: `
                        // TenantsImpactedAllDates - all previous months' tenants impacted table data
                        let dt = "\${date}";
                          let currDate = GetCurrentDate(${isTest});
                          Reliability
                          | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                          | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}"
                          | project-away Application, Host, UiHost
                          | join kind=inner (
                              StrategicTenants
                              | where Date == currDate
                              | extend Name = iff("\${levelColumnName}" == "Tpid", OrgName_Translated, TenantName_Translated)
                              | where Is\${cohort} == true
                              | distinct \${levelColumnName}, Name, Industry
                          ) on \${levelColumnName}
                          | project \${level} = \${levelColumnName}, Name, Industry,ErrorType, UserErrorCount,PercentUsersWithError, SessionErrorCount, PercentSessionsWithError, Date
                          | order by Date desc, PercentUsersWithError desc`,

    TenantsImpactedByHostAllDates: filterOnDateAll("Host"),

    TenantsImpactedByUiHostAllDates: filterOnDateAll("UiHost"),

    CohortImpactCountAllDates: `
                            // CohortImpactCountAllDates - all previous months' # tenants for the cohort
                            Reliability
                            | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
                            | where Application == "\${app}" and Host == "\${host}" and UiHost == "\${uiHost}" and ErrorName == "\${errorname}"  
                            | distinct \${levelColumnName}
                            | join kind=inner (
                                StrategicTenants
                                | where Is\${cohort} == true
                                | distinct \${levelColumnName}
                            )on \${levelColumnName} 
                            | count`,
};
