import React from "react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { GeographicDistribution } from "components/TenantDrilldownComponents/SessionDistribution";
import { GeographicTenantEnvironmentProps } from "components/TenantDrilldownComponents/TenantEnvironment/types";
import { Workload } from "config/PlatformConfig";
import { getChartColor } from "utils/Helpers";

const locationPinIcon = () => {
    return (
        <svg>
            <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
        </svg>
    );
};
export const GeographicDistributionWrapper = ({
    geographicDistribution,
    geoDataMapping,
    loading,
    rankOn,
    workload,
}: GeographicTenantEnvironmentProps) => {
    const distributionTypes = {
        Win32: {
            Location: true,
        },
        Mac: {
            Location: true,
        },
        Teams: {
            Location: true,
        },
        Web: {
            SPOFarm: true,
            Location: true,
            WacDatacenter: true,
        },
        [Workload.COPILOT_COMMERCIAL]: { Location: true },
    };

    const distributionTypeInfo = {
        Win32: {
            Location: { color: getChartColor(5), maxRadius: 15 },
        },
        Mac: {
            Location: { color: getChartColor(5), maxRadius: 15 },
        },
        Teams: {
            Location: { color: getChartColor(5), maxRadius: 15 },
        },
        Web: {
            SPOFarm: {
                color: getChartColor(0),
                maxRadius: 8,
                icon: locationPinIcon,
            },
            Location: { color: getChartColor(5), maxRadius: 15 },
            WacDatacenter: { color: getChartColor(8), maxRadius: 25 },
        },
        [Workload.COPILOT_COMMERCIAL]: {
            Location: { color: getChartColor(5), maxRadius: 15 },
        },
    };
    return (
        <>
            <h2 className="commonPageBlockTitle">Geographic Distribution</h2>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                geographicDistribution && (
                    <GeographicDistribution
                        geographicDistribution={geographicDistribution}
                        geoDataMapping={geoDataMapping}
                        distributionTypes={distributionTypes[workload]}
                        distributionTypeInfo={distributionTypeInfo[workload]}
                        application={rankOn}
                        workload={workload}
                    />
                )
            )}{" "}
        </>
    );
};
