import { Filters } from "utils/WebConstants";
import { CollabFilterState, CollabState } from "./types";

export const getInitialCollabFilterState = (queryParams: {}): CollabFilterState => {
    return {
        level: queryParams["level"] ? queryParams["level"] : Filters.DefaultLevel,
        id: queryParams["id"],
        date: queryParams["date"] ? queryParams["date"] : "",
        Host: "All",
        UiHost: "All",
    };
};

export const getInitialCollabState = (): CollabState => {
    return {
        isFetching: true,
        collabFunnel: { chartData: [], funnelData: [] },
        filterOptions: { dateOptions: [], uihostOptions: [] },
        metadata: {
            cohorts: {
                isS500: false,
                isEpa: false,
                isS2500: false,
                isGoogleMove: false,
                isGov: false,
                isEo: false,
            },
            level: "",
            orgName: "",
            status: "",
            tpid: "",
        },
    };
};
