import React, { useEffect, useState } from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { CompositeFunnel } from "components/ConsumerDay0FunnelComponents/CompositeFunnel/CompositeFunnel";
import { CompositeFunnelStatusBar } from "components/ConsumerDay0FunnelComponents/CompositeFunnelStatusBar/CompositeFunnelStatusBar";
import { ExploreHistoricalTrendsPanel } from "components/ConsumerDay0FunnelComponents/ExploreHistoricalTrendsPanel/ExploreHistoricalTrendsPanel";
import { ReferrerFunnel } from "components/ConsumerDay0FunnelComponents/ReferrerFunnel/ReferrerFunnel";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderTitle,
    CardHeaderRight,
} from "components/CustomComponents/Card/Card";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { IcicleChart } from "components/CustomComponents/IcicleChart/IcicleChart";
import { ShimmeredFunnel } from "components/CustomComponents/ShimmeredWrapper/ShimmeredFunnel";
import {
    DefaultSelectedDropOff,
    DefaultSelectedFunnel,
    DefaultSelectedScenerio,
    getCompactValue,
    childColorScale,
    childKeys,
    day0FunnelHierarchicalMap,
    formatReferrers,
    parentColorScale,
    parentKeys,
    valueScale,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    funnelIcicleWidth,
    funnelSVGHeight,
} from "components/ConsumerDay0FunnelComponents/ReferrerFunnel/constants";
import { TooltipData } from "components/CustomComponents/IcicleChart/types";
import {
    formatFunnelDropOffOverTime,
    formatFunnelReferrerSource,
    formatFunnelScenarioDistribution,
    formatFunnelStageData,
    formatFunnelStageOverTimeData,
    getFunnelData,
    getFunnelDataAcrossScenarioAndReferrer,
    getScenarioData,
} from "pages/ConsumerDay0Funnel/ApiHandler";
import "pages/ConsumerDay0Funnel/style.css";
import { FunnelReferralSources } from "pages/ConsumerDay0Funnel/constants";
import {
    FunnelStagesPropsType,
    FunnelStageState,
    FunnelStateDataType,
    FunnelStageScenarioDataType,
    FunnelStageScenarioReferrerDataType,
} from "pages/ConsumerDay0Funnel/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { CONSUMERDAY0_MESSAGES } from "utils/Messages";

export const horizontalStackTokensDay0Funnel: IStackTokens = {
    childrenGap: 20,
    padding: "0 0 20px 0",
};

export const horizontalCompressedStackTokensDay0Funnel: IStackTokens = {
    childrenGap: 5,
    padding: "0 0 5px 0",
};

export const FunnelStages = ({
    filters,
    selectedStage,
    selectedDropOff,
    changeSelectedFunnel,
    changeSelectedDropOff,
    onFilterChange,
}: FunnelStagesPropsType) => {
    const [funnelStageState, setFunnelStageState] = useState<FunnelStageState>({
        loading: false,
        funnelData: {},
        funnelOverTimeData: [],
        funnelDropOffOverTimeData: [],
        error: null,
        scenarioDistribution: {},
        referralDistribution: {},
    });
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [showNotification, setNotification] = useState<boolean>(false);

    useEffect(() => {
        const getPayload = async () => {
            try {
                // Get Data from Kusto
                const payload = await getFunnelData(filters);
                const payloadAcrossScenarioAndReferrer =
                    await getFunnelDataAcrossScenarioAndReferrer(filters);
                const scenarioData = await getScenarioData(filters);

                let funnelData: FunnelStateDataType = {};

                if (payload.Tables[0].Rows.length === 0) {
                    setFunnelStageState({
                        funnelData: {},
                        funnelOverTimeData: [],
                        funnelDropOffOverTimeData: [],
                        loading: false,
                        error: "First Sessions data also not available",
                        scenarioDistribution: {},
                        referralDistribution: {},
                    });
                } else {
                    funnelData = formatFunnelStageData(payload);
                }

                // Format the kusto data
                const funnelDataOverTime: any[] =
                    formatFunnelStageOverTimeData(funnelData);
                const funnelDropOffOverTime: any[] =
                    formatFunnelDropOffOverTime(funnelData);
                const scenarioDistribution: FunnelStageScenarioDataType =
                    formatFunnelScenarioDistribution(scenarioData);
                const referrerSourceData: FunnelStageScenarioReferrerDataType =
                    formatFunnelReferrerSource(payloadAcrossScenarioAndReferrer);

                //data is not available for the filter combinations in the first stage also
                if (
                    Object.keys(funnelData).length !== 0 &&
                    funnelData[filters.date]?.[selectedStage] === undefined
                ) {
                    // cases where any stage other than first stage data is not available
                    if (
                        funnelData[filters.date]?.[DefaultSelectedFunnel] !==
                        undefined
                    ) {
                        changeSelectedFunnel(DefaultSelectedFunnel);
                        changeSelectedDropOff(DefaultSelectedDropOff);
                        setNotification(true);
                    } else {
                        setFunnelStageState({
                            funnelData: {},
                            funnelOverTimeData: [],
                            funnelDropOffOverTimeData: [],
                            loading: false,
                            error: "First Sessions data also not available",
                            scenarioDistribution: {},
                            referralDistribution: {},
                        });
                    }
                }

                setFunnelStageState({
                    funnelData: funnelData,
                    funnelOverTimeData: funnelDataOverTime,
                    funnelDropOffOverTimeData: funnelDropOffOverTime,
                    loading: false,
                    error: null,
                    scenarioDistribution: scenarioDistribution,
                    referralDistribution: referrerSourceData,
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerDay0FunnelStagesFetchFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer Day0Funnel Stages",
                    },
                    e,
                );
                setFunnelStageState({
                    funnelData: {},
                    funnelOverTimeData: [],
                    funnelDropOffOverTimeData: [],
                    loading: false,
                    error: e.message,
                    scenarioDistribution: {},
                    referralDistribution: {},
                });
            }
        };

        getPayload();
        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filters.application,
        filters.appMode,
        filters.browser,
        filters.cadence,
        filters.cohort,
        filters.country,
        filters.datacenter,
        filters.os,
        filters.referrer,
        filters.referrerPlatform,
        filters.scenario,
        filters,
    ]);

    const handleReferralChange = (referral: string) => {
        if (referral === filters.referrer) {
            onFilterChange("referrer", FunnelReferralSources.All);
        } else {
            onFilterChange("referrer", referral);
        }
    };

    const getToolTipMessage = (tooltipData: TooltipData) => {
        const referralSourcesFiltered = funnelStageState.referralDistribution[
            filters.date
        ][selectedStage][filters.scenario]?.referralSources.filter(
            (k) => k.name == tooltipData.key,
        )[0].value;

        return (
            <div>
                <div className="icicleChartToolTipMajor">
                    <strong>{tooltipData.key}</strong> - [
                    {tooltipData.bar.data[tooltipData.key]}% or{" "}
                    {getCompactValue(referralSourcesFiltered)}
                    ]
                    <br />
                    (of All Sessions)
                </div>
                <div className="icicleChartToolTipMinor">
                    <p>Click to select / unselect the Referrer</p>
                </div>
            </div>
        );
    };

    const shouldShowLoading = () => {
        return (
            !funnelStageState.error &&
            (funnelStageState.loading ||
                Object.keys(funnelStageState.funnelData).length === 0)
        );
    };

    const shouldShowNoData = () => {
        return (
            !funnelStageState ||
            !funnelStageState.funnelData ||
            !funnelStageState.referralDistribution ||
            !funnelStageState.scenarioDistribution ||
            Object.keys(funnelStageState.funnelData).length === 0 ||
            Object.keys(funnelStageState.referralDistribution).length === 0 ||
            funnelStageState.scenarioDistribution[filters.date] == null ||
            funnelStageState.funnelData[filters.date] == null ||
            funnelStageState.referralDistribution[filters.date] == null
        );
    };

    return (
        <Card width={1285}>
            <CardHeader>
                <CardHeaderTitle>Journey Funnel</CardHeaderTitle>
                {!(
                    shouldShowLoading() ||
                    shouldShowNoData() ||
                    funnelStageState.error
                ) && (
                    <CardHeaderRight>
                        <CompositeFunnelStatusBar
                            selectedStage={selectedStage}
                            selectedScenario={filters.scenario}
                            selectedReferrer={filters.referrer}
                            clearFunnelCallback={() => {
                                changeSelectedFunnel(DefaultSelectedFunnel);
                                changeSelectedDropOff(DefaultSelectedDropOff);
                                onFilterChange("scenario", DefaultSelectedScenerio);
                                handleReferralChange(FunnelReferralSources.All);
                            }}
                            setShowPanel={setShowPanel}
                        />
                    </CardHeaderRight>
                )}
            </CardHeader>
            <CardBody>
                {shouldShowLoading() ? (
                    <ShimmeredFunnel minHeight={790} minWidth={1175} />
                ) : shouldShowNoData() ? (
                    <EmptyTableContainer
                        minHeight={790}
                        minWidth={1175}
                        center
                        message={CONSUMERDAY0_MESSAGES.NO_DATA}
                    />
                ) : funnelStageState.error ? (
                    <EmptyTableContainer
                        minHeight={790}
                        minWidth={1175}
                        message={funnelStageState.error}
                    />
                ) : (
                    <Stack horizontal tokens={horizontalStackTokensDay0Funnel}>
                        <Stack
                            horizontal
                            tokens={horizontalCompressedStackTokensDay0Funnel}
                        >
                            <Stack.Item>
                                <IcicleChart
                                    width={funnelIcicleWidth}
                                    height={funnelSVGHeight}
                                    hierarchicalData={formatReferrers(
                                        funnelStageState.referralDistribution[
                                            filters.date
                                        ][selectedStage][filters.scenario]
                                            ?.referralSources,
                                        filters.date,
                                    )}
                                    parentKeys={parentKeys}
                                    parentColorScale={parentColorScale}
                                    childKeys={childKeys}
                                    childColorScale={childColorScale}
                                    actionHandle={(referral) => {
                                        handleReferralChange(referral);
                                    }}
                                    selectedItem={filters.referrer}
                                    isDefault={
                                        filters.referrer ===
                                        FunnelReferralSources.All
                                    }
                                    yScale={valueScale}
                                    getXAxis={(d) => d.date}
                                    hierarchicalMap={day0FunnelHierarchicalMap()}
                                    getToolTipMessage={(toolTipData) => {
                                        return getToolTipMessage(toolTipData);
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ReferrerFunnel
                                    selectedReferrer={filters.referrer}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Stack horizontal>
                                    <CompositeFunnel
                                        stageScenarioDistribution={
                                            funnelStageState.scenarioDistribution[
                                                filters.date
                                            ]
                                        }
                                        funnelData={
                                            funnelStageState.funnelData[filters.date]
                                        }
                                        filters={filters}
                                        selectedStage={selectedStage}
                                        selectedDropOff={selectedDropOff}
                                        selectedScenario={filters.scenario}
                                        changeSelectedDropOff={changeSelectedDropOff}
                                        changeSelectedFunnel={changeSelectedFunnel}
                                        changeSelectedScenario={(scenario) => {
                                            onFilterChange("scenario", scenario);
                                        }}
                                        changeSelectedReferrer={handleReferralChange}
                                        setShowPanel={setShowPanel}
                                    />
                                </Stack>
                                {/* TODO: Remove this note after the Excel Abandonment Data is available */}
                                {filters.application !== "Word" && (
                                    <div>
                                        <strong>Note: </strong>
                                        {CONSUMERDAY0_MESSAGES.UNABANDONED_MISSING}
                                    </div>
                                )}
                                {showNotification && (
                                    <div>
                                        <strong style={{ color: "red" }}>**</strong>{" "}
                                        {
                                            CONSUMERDAY0_MESSAGES.STAGEANDFILTERCOMBINATION_MISSING
                                        }
                                    </div>
                                )}
                            </Stack.Item>
                        </Stack>
                        <ExploreHistoricalTrendsPanel
                            showPanel={showPanel}
                            setShowPanel={setShowPanel}
                            funnelDataOverTime={funnelStageState.funnelOverTimeData}
                            funnelDropOffOverTime={
                                funnelStageState.funnelDropOffOverTimeData
                            }
                            funnelData={funnelStageState.funnelData}
                            filters={filters}
                            selectedStage={selectedStage}
                            scenarioData={funnelStageState.scenarioDistribution}
                            referrerSourceData={
                                funnelStageState.referralDistribution
                            }
                        />
                    </Stack>
                )}
            </CardBody>
        </Card>
    );
};
