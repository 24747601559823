import React from "react";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

const ConfirmationDialog = ({ hide, setHide, action, message }) => {
    return (
        <Dialog
            hidden={hide}
            onDismiss={() => setHide(!hide)}
            dialogContentProps={{
                closeButtonAriaLabel: "Close",
                subText: message,
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        setHide(!hide);
                        action();
                    }}
                    text="Ok"
                />
                <DefaultButton onClick={() => setHide(!hide)} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmationDialog;
