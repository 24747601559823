import React, { useEffect, useState } from "react";
import { IColumn, Stack } from "@fluentui/react";
import { CountAnnotationBar } from "@m365-admin/count-annotation";
import { EmptyState } from "@m365-admin/empty-state";
import { useFilterOptions } from "@m365-admin/hooks";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { AdvisoriesFilterPanel } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesFilterPanel";
import { AdvisoriesFlyoutPanel } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesFlyoutPanel";
import { DataTable } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/DataTable";
import {
    countAnnotationRectangleStyles,
    emptyStateStyle,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { onColumnRender } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { fetchProductAdvisories } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/apihandler";
import {
    AdvisoriesCardState,
    LoadingState,
    TableCardProps,
    AdvisoryFilterDetailsType,
    AdvisoryFilterNames,
    AdvisoryMetricType,
    AdvisorySeverity,
    AdvisoryTrend,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { commonInnerBlock } from "pages/common";
import { CustomDetailsItemsType } from "pages/commonTypes";
import { logFeatureUsage } from "utils/AppInsightsHelper";
import { ADMINCENTER_MESSAGES } from "utils/Messages";

const buildColumns = (): IColumn[] => {
    const displayColumns = [
        ["Message", "Advisory", 350, null],
        ["Product", "Impacted Application", 140, null],
        ["Channel", "Channel", 240, null],
        ["Build", "Build", 140, null],
        ["Severity", "Type", 140, null],
        ["CreatedDate", "Created", 120, null],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        true,
    );
    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

    return columns;
};

export const getAdvisorisColumn: IColumn[] = buildColumns();

export const AdvisoriesTableCard = ({ id, date }: TableCardProps) => {
    const [state, setState] = useState<AdvisoriesCardState>({
        data: [],
        error: "",
    });
    const [loadingState, setLoadingState] = useState<LoadingState>({
        isLoading: true,
        isLoadingFailed: false,
    });
    const [dataItems, setDataItems] = useState<CustomDetailsItemsType[]>([]);

    const [metrics, setMetrics, selectedMetrics] = useFilterOptions(
        Object.values(AdvisoryMetricType).filter(
            (value) => value !== AdvisoryMetricType.Unknown,
        ),
    );

    const [trends, setTrends, selectedTrends] = useFilterOptions(
        Object.values(AdvisoryTrend).filter(
            (value) => value !== AdvisoryTrend.Unknown,
        ),
    );

    const [types, setTypes, selectedTypes] = useFilterOptions(
        Object.values(AdvisorySeverity).filter(
            (value) => value !== AdvisorySeverity.Unknown,
        ),
    );

    const [isOpen, setOpen] = useState<boolean>(false);

    const [selectedRow, setSelectedRow] = useState<CustomDetailsItemsType>({});

    const advisoriesFilters: AdvisoryFilterDetailsType[] = [
        {
            name: AdvisoryFilterNames.Metric,
            items: metrics,
            selectedItems: selectedMetrics,
            setItems: setMetrics,
        },
        {
            name: AdvisoryFilterNames.Trend,
            items: trends,
            selectedItems: selectedTrends,
            setItems: setTrends,
        },
        {
            name: AdvisoryFilterNames.Type,
            items: types,
            selectedItems: selectedTypes,
            setItems: setTypes,
        },
    ];

    const onSearch = (value: string) => {
        setLoadingState((oldLoadingState) => {
            return { ...oldLoadingState, isLoading: true };
        });
        let filtered = [];
        if (isNil(state.data) || state.data.length === 0) filtered = [];
        else if (isNil(value) || isEmpty(value)) filtered = state.data;
        else {
            filtered = state.data.filter(
                (item) =>
                    item["Message"]
                        .toString()
                        .toUpperCase()
                        .indexOf(value.toUpperCase()) !== -1,
            );
        }

        setDataItems(filtered);
        setLoadingState((oldLoadingState) => {
            return { ...oldLoadingState, isLoading: false };
        });
    };

    const onColumnClick = (column: IColumn, items: CustomDetailsItemsType[]) => {
        const result = DetailsListHelper.sortItems(
            getAdvisorisColumn,
            column,
            items,
        );
        setDataItems(result.items);
    };

    useEffect(() => {
        logFeatureUsage("Apps Admin Center - Advisories Table");

        setLoadingState((oldLoadingState) => {
            return {
                ...oldLoadingState,
                isLoading: true,
            };
        });
        fetchProductAdvisories(setState, setLoadingState, setDataItems, id, date);
        if (!selectedTypes) {
            setTypes({ [AdvisorySeverity.Significant]: true });
        }
    }, [id, date, selectedTypes, setTypes]);

    const getItems = (): CustomDetailsItemsType[] => {
        let filtered: CustomDetailsItemsType[] = cloneDeep(dataItems);

        // Reason to filter over & over again for all 3 is because this is multiselect,and instead keeping state for each
        // filter selected, it makes sense to filter them again
        // also, this works because user can select and deselect any combination and it'll still work without any special logic

        if (selectedMetrics !== undefined) {
            filtered = filtered.filter((item) =>
                selectedMetrics.includes(item[AdvisoryFilterNames.Metric] as string),
            );
        }
        if (selectedTrends !== undefined) {
            filtered = filtered.filter((item) =>
                selectedTrends.includes(item[AdvisoryFilterNames.Trend] as string),
            );
        }
        if (selectedTypes !== undefined) {
            filtered = filtered.filter((item) =>
                selectedTypes.includes(item[AdvisoryFilterNames.Type] as string),
            );
        }

        return filtered;
    };

    const handleEmptyMessage = () => {
        if (state.data.length === 0) {
            return undefined;
        } else {
            if (
                selectedTypes &&
                selectedTypes.includes(AdvisorySeverity.Significant.toString())
            ) {
                return ADMINCENTER_MESSAGES.NO_SIGNIFICANT_MESSAGE;
            } else {
                return ADMINCENTER_MESSAGES.NO_RESULT_SELECTED_FILTERS;
            }
        }
    };

    return loadingState.isLoadingFailed ? (
        <EmptyState
            title={ADMINCENTER_MESSAGES.NO_DATA_LOADING_FAILED}
            body={state.error}
            styles={emptyStateStyle}
        />
    ) : (
        <Stack styles={commonInnerBlock}>
            <CountAnnotationBar
                countAnnotationProps={[
                    {
                        annotationText: "App advisories",
                        count: state.data.length,
                        isSelected: true,
                        disabled: loadingState.isLoading,
                        styles: countAnnotationRectangleStyles,
                    },
                ]}
            />
            <DataTable
                items={getItems()}
                columns={getAdvisorisColumn}
                onSearch={onSearch}
                loading={loadingState.isLoading}
                FilterPanel={
                    <AdvisoriesFilterPanel advisoriesFilters={advisoriesFilters} />
                }
                onColumnClick={onColumnClick}
                onColumnRender={onColumnRender}
                setOpenFlyoutPanel={setOpen}
                setSelectedRow={setSelectedRow}
                emptyMessage={handleEmptyMessage()}
            />
            <AdvisoriesFlyoutPanel
                isOpen={isOpen}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setOpen={setOpen}
                dataItems={state.data}
                id={id}
                date={date}
            />
        </Stack>
    );
};
