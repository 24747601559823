import { IDropdownOption, IStackStyles } from "@fluentui/react";
import { MilestoneFunnelType } from "config/FunnelConfig";
import { ConsumerAppMetricsLinkType } from "utils//types";

export const cohortOptions: IDropdownOption[] = [
    { key: "MSA Research", text: "MSA Research" },
    { key: "New Users", text: "New Users" },
];

export const ConsumerProductDrilldownMetricsTabs = {
    AppMetrics: "App Metrics",
    Environment: "Environment",
    Retention: "Retention",
};

export const ConsumerHostOptions: IDropdownOption[] = [
    { key: "OneDriveWOPI", text: "OneDrive" },
];

export const ConsumerAppMetricsLinkMapping: ConsumerAppMetricsLinkType = {
    Open: {
        toolTip: "ASHA Consumer Dashboard",
        getMetricUrl: (cohortName, cohortType, application, date) =>
            `//asha.microsoft.com/?M365App=Consumer&ViewBy=Month&Month=${date}&CohortType=${cohortType}&CohortName=${cohortName}&TimeRange=SelectRange&App=${application}`,
    },
    Save: {
        toolTip: "ASHA Consumer Dashboard",
        getMetricUrl: (cohortName, cohortType, application, date) =>
            `//asha.microsoft.com/?M365App=Consumer&ViewBy=Month&Month=${date}&CohortType=${cohortType}&CohortName=${cohortName}&TimeRange=SelectRange&App=${application}`,
    },
};

export const ConsumerOverviewSectionTabs = {
    Growth: "Growth",
    Harmony: MilestoneFunnelType.Harmony,
    AHPFunnels: "App Auth",
    UHPFunnels: "App Unauth",
};

export const consumerPageStyle: IStackStyles = {
    root: {
        padding: 10,
        border: "dotted 2px #f3f2f1",
        borderRadius: 5,
        width: "100%",
    },
};

export const ConsumerGrowthDrilldownMetricsTabs = {
    AppMetrics: "App Metrics",
};
