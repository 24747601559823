import React, { useEffect, useState } from "react";
import LoadingBar from "react-redux-loading-bar";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { IDropdownOption, PivotItem, Stack } from "@fluentui/react";
import StatusLegend from "components/CustomComponents/StatusLegend/StatusLegend";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import HealthDistribution from "components/OverviewComponents/HealthDistribution/HealthDistribution";
import { OverviewAppsPanel } from "components/OverviewComponents/OverviewPanel/OverviewAppsPanel";
import OverviewFilterPanel from "components/OverviewComponents/OverviewPanel/OverviewFilterPanel";
import { validateAndUpdatePlatformFilters } from "pages/ExecOverview/ExecOverviewUtils";
import { CompositeOverviewTable } from "components/OverviewComponents/CompositeOverviewTable";
import { TenantStatus } from "components/OverviewComponents/TenantStatus";
import { getConfig } from "config/PlatformConfig";
import { commonPageBlock, commonPageStyle, statusBlock } from "pages/common";
import "pages/common.css";
import { CommonProps } from "pages/commonTypes";
import {
    fetchData,
    setDatePickerOptions,
    setTenantsForSearch,
} from "pages/ExecOverview/ApiHandler";
import {
    ExecOverviewFilterState,
    ExecOverviewState,
    TenantsDataProps,
} from "pages/ExecOverview/types";
import AppInsights from "utils/AppInsights";
import {
    logFilterUsage,
    logLevelBasedView,
    logUsageOfAppView,
} from "utils/AppInsightsHelper";
import { computeQueryParams, extractQueryParams } from "utils/Helpers";
import { registerOverviewIcons } from "utils/IconRegistration";
import { NO_DATA_MESSAGES } from "utils/Messages";
import "pages/ExecOverview/style.css";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const ExecOverview = (props: CommonProps) => {
    registerOverviewIcons();

    const platform = props.platform;
    const config = getConfig(platform);
    const initialData = {
        compositeScore: { headers: [], rows: [] },
        tenantStatus: { headers: [], rows: [] },
        loading: true,
    };
    const initialDataNoLoading = {
        compositeScore: { headers: [], rows: [] },
        tenantStatus: { headers: [], rows: [] },
        loading: false,
    };

    const filtersFromQueryParams = extractQueryParams();
    const filtersToUse: ExecOverviewFilterState = {
        rankOn: config.defaults.app,
        level: config.defaults.level,
        cohort: config.defaults.cohort,
    };
    if (config.defaults.minMau) {
        filtersToUse.minMau = config.defaults.minMau;
    }

    const [filters, setFilters] = useState<ExecOverviewFilterState>(filtersToUse);
    const [tableNameMap, setTableNameMap] = useState({});

    const [dateFilter, setDateFilter] = useState("");
    const [dateOptions, setDateOptions] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState<ExecOverviewState>(initialData);
    const [tenantsData, setTenantsData] = useState<TenantsDataProps>(undefined);
    const crossApps = config.crossAppsList;
    const apps = config.appsList;

    const setUpDocumentUrl = (updatedFilter: ExecOverviewFilterState) => {
        document.title = config.pageTitle;
        window.history.pushState(
            "",
            "",
            window.location.pathname + computeQueryParams(updatedFilter),
        );
    };

    if (
        !isEmpty(filtersFromQueryParams) &&
        !isEqual(filtersFromQueryParams, filters)
    ) {
        const updatedFilters = validateAndUpdatePlatformFilters(
            filters,
            filtersFromQueryParams,
            platform,
        );
        setUpDocumentUrl(updatedFilters);
        setFilters(updatedFilters);
    }

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        setTableNameMap({});
        setDatePickerOptions(
            platform,
            setDateFilter,
            setDateOptions,
            setTableNameMap,
            setError,
            setData,
        );
        setUpDocumentUrl(filters);
        AppInsights.getInstance().TrackPage(document.title);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // todo - remove this logic after Gov cohort backfill
        if (
            filters.cohort === "Gov" &&
            new Date(dateFilter) < new Date("2022-02-01")
        ) {
            setData(initialDataNoLoading);
            setError(NO_DATA_MESSAGES.NO_GOV_DATA);
            return;
        }
        if (dateFilter !== "") {
            setData(initialData);
            setError(null);
            fetchData(platform, filters, setData, dateFilter, dateOptions, setError);
        }
        // ADO 7955411: useMemo on initialData, maybe pull initialDataNoLoading inside useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter, filters]); // todo - resolve react-hooks/exhaustive-deps warning

    useEffect(() => {
        if (dateFilter) {
            setTenantsForSearch(dateFilter, setTenantsData, setError);
        }
    }, [dateFilter]);

    const onFilterChange = (
        item: string,
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        const update = {};
        update[item] = option["key"];
        updateMinMauIfApplicable(item, option, update);
        const updatedFilter = { ...filters, ...update };
        setUpDocumentUrl(updatedFilter);
        setFilters(updatedFilter);
        logFilterUsage(document.title, item, String(option["key"]));
        sendFiltersChangeTelemetryEvent(updatedFilter);
    };

    const updateMinMauIfApplicable = (item, option, update) => {
        if (item === "cohort")
            if (option["key"] === "MidMarket") {
                update["minMau"] = "25";
            } else if (filters.minMau == "25" || filters.minMau == "100") {
                update["minMau"] = config.defaults.minMau;
            }
    };

    const onLevelChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        const level = checked ? "TenantId" : "Tpid";
        const updateLevel = {};
        updateLevel["level"] = level;
        const updatedFilter = { ...filters, ...updateLevel };
        setUpDocumentUrl(updatedFilter);
        setFilters({
            ...filters,
            ...updateLevel,
        });
        logLevelBasedView(document.title, level);
    };

    const onAppClick = (item: PivotItem) => {
        const rankOn = item.props.headerText;
        const updateApp = {};
        updateApp["rankOn"] = rankOn;
        const updatedFilter = { ...filters, ...updateApp };
        setUpDocumentUrl(updatedFilter);
        setFilters({ ...filters, ...updateApp });
        logUsageOfAppView(document.title, rankOn);
    };

    const onChangeDateSelection = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        if (option) {
            setDateFilter(String(option["key"]));
        }
    };
    const filterPanelHandler = {
        onFilterChange,
        onLevelChange,
        onChangeSelection: onChangeDateSelection,
    };
    const overviewPanelFilters = {
        filters,
        dateOptions,
        dateFilter,
        config,
        queryParamsUrl: computeQueryParams(filters),
    };

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <LoadingBar className="loadingBar" />
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader" data-testid="orgHeader">
                        {config.pageHeader}
                    </div>
                </Stack.Item>

                <Stack className="commonStickyPanel">
                    <OverviewFilterPanel
                        filterPanelHandler={filterPanelHandler}
                        panelFilters={overviewPanelFilters}
                        tenantsData={tenantsData}
                        platform={platform}
                    />

                    <OverviewAppsPanel
                        onAppClick={onAppClick}
                        filterApp={filters["rankOn"]}
                        crossApps={crossApps}
                        apps={apps}
                    />
                </Stack>

                <Stack styles={statusBlock}>
                    <StatusLegend platformConfig={config} />
                </Stack>

                <Stack styles={commonPageBlock}>
                    <CompositeOverviewTable
                        payload={data.compositeScore}
                        queryParamsUrl={computeQueryParams(filters)}
                        loading={data.loading}
                        tableNameMap={tableNameMap}
                        selectedDate={dateFilter}
                        rankOn={filters.rankOn}
                        platformConfig={config}
                        showAllColorTab={false}
                    />
                </Stack>
                {!error && (
                    <HealthDistribution
                        filters={filters}
                        dateFilter={dateFilter}
                        platform={platform}
                    />
                )}
                <Stack styles={commonPageBlock}>
                    <Stack.Item grow={1}>
                        <TenantStatus
                            payload={data.tenantStatus}
                            queryParamsUrl={computeQueryParams(filters)}
                            selectedDate={dateFilter}
                            level={filters["level"]}
                            type="Movers"
                            loading={data.loading}
                            tableNameMap={tableNameMap}
                            rankOn={filters.rankOn}
                            platformConfig={config}
                        />
                    </Stack.Item>
                    <Stack.Item
                        grow={1}
                        styles={{ root: { marginTop: "50px !important;" } }}
                    >
                        <TenantStatus
                            payload={data.tenantStatus}
                            queryParamsUrl={computeQueryParams(filters)}
                            selectedDate={dateFilter}
                            level={filters["level"]}
                            type="Shakers"
                            loading={data.loading}
                            tableNameMap={tableNameMap}
                            rankOn={filters.rankOn}
                            platformConfig={config}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};

export default ExecOverview;
