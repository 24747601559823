import React from "react";
import {
    ScatterChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
    LabelList,
    Cell,
    Label,
} from "recharts";
import {
    cohortAnalysisChartsHeight,
    cohortAnalysisChartsWidth,
} from "pages/CohortAnalysis/CohortAnalysisConstants";
import { chartMargin } from "pages/common";
import { getDomainForYAxis, getTicksForChart } from "utils/Helpers";
import CustomToolTip from "../CustomToolTip";

export default function ScatterChartGraph({
    chartData,
    chartTitle,
    XAxisDataKey,
    XAxisName,
    YAxisDataKey,
    YAxisName,
    ZAxisDataKey,
    ZAxisName,
    currentlySelectedError,
    chartCallbackFunction,
    getCellColor,
    getCellStroke,
    toolTipFunction,
}) {
    return (
        <ResponsiveContainer
            width={cohortAnalysisChartsWidth}
            height={cohortAnalysisChartsHeight}
        >
            <ScatterChart margin={chartMargin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={XAxisDataKey} name={XAxisName}>
                    <Label value={XAxisName} position="bottom" />
                </XAxis>
                <YAxis
                    dataKey={YAxisDataKey}
                    name={YAxisName}
                    label={{
                        value: YAxisName,
                        angle: -90,
                        position: "insideBottomLeft",
                    }}
                    domain={getDomainForYAxis(
                        chartData.map((element) => element[YAxisDataKey]),
                        true /* Start At Zero */,
                    )}
                    ticks={getTicksForChart(
                        chartData.map((element) => element[YAxisDataKey]),
                        true /* Start at Zero */,
                    )}
                />
                <ZAxis dataKey={ZAxisDataKey} range={[200, 4000]} name={ZAxisName} />
                <ZAxis dataKey="Name" name="Name" zAxisId="Meta" />
                <Tooltip
                    content={
                        <CustomToolTip
                            customToolTipContentFunction={toolTipFunction}
                            currentlySelectedError={currentlySelectedError}
                        />
                    }
                />
                <Legend verticalAlign="top" height={50} />
                <Scatter
                    name={chartTitle}
                    data={chartData}
                    isAnimationActive={true}
                    animationEasing="ease-in"
                    animationDuration={1000}
                    style={{ cursor: "pointer" }}
                    fill="0073FF"
                >
                    <LabelList dataKey="Name" position="bottom" />
                    {chartData.map((entry, index) => {
                        return (
                            <Cell
                                key={`cell-${index}-${entry.Name}`}
                                fill={getCellColor(entry)}
                                stroke={getCellStroke(entry, currentlySelectedError)}
                                onClick={() => chartCallbackFunction(entry.Name)}
                            />
                        );
                    })}
                </Scatter>
            </ScatterChart>
        </ResponsiveContainer>
    );
}
