import { IChoiceGroupOption, IColumn, IStackStyles } from "@fluentui/react";
import { tableColumnConfig } from "utils/types";

export const CamauTableColumnConfig: tableColumnConfig = {
    Segment: {
        headerGroups: ["None", "None"],
        tooltip: "Segment",
        displayName: "Platform - OS [Only OD Host]",
        style: {
            borderRight: "inset",
            borderRightWidth: 1,
        },
        exportCSVHeaderName: "Segment",
    },
    MAU: {
        headerGroups: ["None", "None"],
        tooltip: "MAU",
        displayName: "MAU",
        exportCSVHeaderName: "MAU",
    },
    CAMAU: {
        headerGroups: ["None", "None"],
        tooltip: "CAMAU",
        displayName: "CAMAU",
        exportCSVHeaderName: "CAMAU",
    },
    CAMAUPct: {
        headerGroups: ["None", "None"],
        tooltip: "CAMAU %",
        displayName: "CAMAU %",
        exportCSVHeaderName: "CAMAU Pct",
    },
};

export const defaultTimeSpanByCadence = {
    Daily: 3,
    Monthly: 12,
};

export const segmentMetadata = {
    "AllPlat-AllOS": { displayText: "All Platforms - All OS", segmentLevel: 1 },
    "Desktop-AllOS": { displayText: "Desktop - All", segmentLevel: 1 },
    "Desktop-Mac": { displayText: "Desktop - Mac", segmentLevel: 2 },
    "Desktop-Windows": { displayText: "Desktop - Windows", segmentLevel: 2 },
    "Mobile-AllOS": { displayText: "Mobile - All", segmentLevel: 1 },
    "Mobile-Android": { displayText: "Mobile - Android", segmentLevel: 2 },
    "Mobile-iOS": { displayText: "Mobile - iOS", segmentLevel: 2 },
    "Web-Web": { displayText: "Web - All", segmentLevel: 1 },
};

export const chartFlyoutBarsDisplayNames = {
    MAU: {
        DisplayName: "MAU",
        GoalField: "MAU",
        GoalFieldDisplay: "MAU",
    },
    CAMAU: {
        DisplayName: "CAMAU",
        GoalField: "CAMAU",
        GoalFieldDisplay: "CAMAU",
    },
};

export const chartFlyoutLinesDisplayNames = {
    CAMAUPct: {
        DisplayName: "CAMAU %",
        GoalField: "CAMAUPct",
        GoalFieldDisplay: "CAMAU %",
    },
};

export const legendPayload = [
    {
        inactive: false,
        dataKey: "MAU",
        type: "rect",
        color: "#edc949",
        value: "MAU",
    },
    {
        inactive: false,
        dataKey: "CAMAU",
        type: "line",
        color: "#76b7b2",
        value: "CAMAU",
    },
    {
        inactive: false,
        dataKey: "CAMAUPct",
        type: "rect",
        color: "#af7aa1",
        value: "CAMAU %",
    },
];

export const CamauCadenceOptions: IChoiceGroupOption[] = [
    { key: "daily", text: "Daily" },
    { key: "monthly", text: "Monthly" },
];

export const segmentOrder = Object.keys(segmentMetadata)
    .map((segmentKey, index) => {
        return { [segmentKey]: index };
    })
    .reduce((acc, val) => ({ ...acc, ...val }), {});

const getColumnHeaderMapping = (column: string) => {
    if (CamauTableColumnConfig[column]?.displayName) {
        return CamauTableColumnConfig[column].displayName;
    }
};

const getColumnHeaderMappingTooltip = (column: string) => {
    if (CamauTableColumnConfig[column]?.tooltip) {
        return CamauTableColumnConfig[column].tooltip;
    }
};

export const getConsumerCamauColumns = () => {
    const columns: IColumn[] = [];

    Object.keys(CamauTableColumnConfig).map((header) => {
        const displayColumnName = getColumnHeaderMapping(header);
        const tooltipColumnName = getColumnHeaderMappingTooltip(header);
        columns.push({
            key: header,
            name: displayColumnName,
            fieldName: displayColumnName,
            minWidth: header === "Segment" ? 200 : 77,
            maxWidth: header === "Segment" ? 300 : 77,
            ariaLabel: tooltipColumnName,
            isResizable: true,
            headerClassName: "detailsListColumnHeaderWrap",
        });
    });

    return columns;
};

export const consumerCamauCenterStyles: IStackStyles = {
    root: {
        alignItems: "center",
    },
};
