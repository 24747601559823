import React from "react";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import {
    Stack,
    IColumn,
    IDetailsRowProps,
    IGroup,
    TextField,
    ChoiceGroup,
    IChoiceGroupOption,
    IGroupDividerProps,
    Toggle,
} from "@fluentui/react";
import {
    generateTenantListTpidGroups,
    getSearchRankForTenant,
} from "components/SharedComponentFunctionality/TenantListSharedFunctionality";
import { cohortOptions, platformsConfig } from "config/PlatformConfig";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents } from "utils/AppInsightsHelper";
import { computeQueryParams } from "utils/Helpers";
import "./style.css";
import ScrollableTenantDetailsList from "./ScrollableTenantDetailsList";

const cohortChoiceGroupMarginStyle = { field: { marginLeft: "5px" } };

const cohortChoiceGroupOptions: IChoiceGroupOption[] = [];
cohortChoiceGroupOptions.push({
    key: "All",
    text: "All",
    styles: cohortChoiceGroupMarginStyle,
});
cohortOptions.forEach((cohort) => {
    cohortChoiceGroupOptions.push({
        key: `Is${cohort.key}`,
        text: cohort.text,
        styles: cohortChoiceGroupMarginStyle,
    });
});

const choiceGroupStyles = () => {
    return {
        flexContainer: {
            display: "flex",
            "max-width": "300px",
            "flex-wrap": "wrap",
        },
        root: { marginTop: "20px" },
    };
};

interface ITenantsListState {
    checkboxes: {};
    textChanged: boolean;
    tpidGroup: IGroup[];
    tenantListItems: any[];
    loading?: boolean;
    showEOToggle: boolean;
    showEOTenants: boolean;
}

const _columns: Array<IColumn> = [
    {
        key: "1",
        name: "",
        fieldName: "OmsStatus",
        minWidth: 10,
        maxWidth: 20,
    },
    {
        key: "2",
        name: "Tenant Name",
        fieldName: "TenantName",
        minWidth: 195,
        maxWidth: 195,
    },
];

export class TenantList extends React.Component<any, ITenantsListState> {
    private _isCompactMode: boolean;

    private queryParamsUrl = "";

    private _populateCheckBoxInitialState = () => {
        const checkBoxInitialState = {};
        cohortOptions.forEach((cohort) => {
            checkBoxInitialState[`Is${cohort.key}`] = false;
        });
        return checkBoxInitialState;
    };

    constructor(props: { payload: { rows: any[] }; queryParamsUrl: string }) {
        super(props);
        this._isCompactMode = false;
        this.state = {
            checkboxes: this._populateCheckBoxInitialState(),
            textChanged: true,
            tenantListItems: this.props.payload,
            tpidGroup: generateTenantListTpidGroups(this.props.payload, true),
            loading: this.props.loading,
            showEOToggle: false,
            showEOTenants: false,
        };
        this.queryParamsUrl = props.queryParamsUrl;
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.loading !== this.props.loading) {
            const newItems = this.props.payload;
            this.setState({
                tenantListItems: newItems,
                tpidGroup: generateTenantListTpidGroups(newItems, true),
                loading: this.props.loading,
            });
        }
    }

    private _load = (): void => {
        if (this.props?.updateUrl === true) {
            this.queryParamsUrl = computeQueryParams(this.props.filters, false);
            window.history.pushState(
                "",
                "",
                window.location.pathname + this.queryParamsUrl,
            );
        }

        this.props.getTenantMetrics(this.props.filters, this.props.metricFilters);
    };

    private _onChoiceGroupChange = (
        ev: React.FormEvent<HTMLElement>,
        option: IChoiceGroupOption,
    ): void => {
        const tenantListItems = this.props.payload;
        tenantListItems.forEach((i) => {
            i["Show"] = option.key !== "All" ? i[option.key] === true : true;
        });

        const newCheckboxState = {};
        Object.keys(this.state.checkboxes).forEach((key) => {
            newCheckboxState[key] =
                option.key !== "All" ? key === option.key : false;
        });
        const selected = tenantListItems.filter((i) => i.Show);

        this.setState({
            checkboxes: newCheckboxState,
            tenantListItems,
            tpidGroup: generateTenantListTpidGroups(selected, true),
            showEOToggle: option.key === "IsGov",
            showEOTenants: false,
        });

        AppInsights.getInstance().LogEvent(
            appInsightsCustomEvents.tenantStrategyRankClick,
            { level: option.key },
        );
    };

    private _toggleEOList = (
        _event: React.MouseEvent<HTMLElement>,
        checked: boolean,
    ): void => {
        const tenantListItems = this.props.payload;
        const toggleChecked = checked ?? false;
        // Toggle checked-filter EO, unchecked-filter Gov
        const cohortFilter = toggleChecked ? "IsEO" : "IsGov";

        tenantListItems.forEach((i) => {
            i["Show"] = i[cohortFilter] === true;
        });

        const selectedTenants = tenantListItems.filter((i) => i.Show);
        this.setState({
            tenantListItems,
            tpidGroup: generateTenantListTpidGroups(selectedTenants, true),
            showEOTenants: toggleChecked === true,
        });
    };

    private _onChangeText = (
        _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        text: string,
    ): void => {
        let isCollapsed = true;
        // returns the keys which have value as true, i.e selected cohort in an array
        const activeCheckBox = keys(pickBy(this.state.checkboxes));
        let selected: any[] = [...this.props.payload];

        if (text) {
            text = text.toLowerCase();
            selected.forEach((i) => {
                if (isCollapsed) {
                    isCollapsed = !(
                        i.TenantName.toLowerCase().indexOf(text) > -1 ||
                        i.TenantId.toString().indexOf(text) > -1
                    ); // To expand groups is tenant searched is oms level
                }

                i["Show"] =
                    (i.OrganizationName.toLowerCase().indexOf(text) > -1 ||
                        i.Tpid.toString().indexOf(text) > -1 ||
                        i.TenantName.toLowerCase().indexOf(text) > -1 ||
                        i.TenantId.toString().indexOf(text) > -1) &&
                    (activeCheckBox.length > 0 ? i[activeCheckBox[0]] : true);

                i["Rank"] = getSearchRankForTenant(text, i);
            });
            selected = selected.sort((a, b) => (a.Rank > b.Rank ? 1 : -1));
        } else {
            selected.forEach((i) => {
                i["Show"] = activeCheckBox.length > 0 ? i[activeCheckBox[0]] : true;
            });
        }

        selected = selected.filter((i) => i.Show);

        this.setState({
            tenantListItems: selected,
            textChanged: true,
            tpidGroup: generateTenantListTpidGroups(selected, isCollapsed),
        });
    };

    private _onHeaderRowClick = (props: IGroupDividerProps | undefined) => {
        // Disable the Tpid click platforms with no Tpid data (Ex: Teams)
        if (!platformsConfig[this.props.platform].showTpidData) {
            return;
        }
        this.props.filters["id"] = props.group.key;
        this.props.filters["level"] = "Tpid";
        delete this.props.filters["TenantId"];
        this._load();
    };

    private _onDetailsRowClick = (props: IDetailsRowProps | undefined) => {
        this.props.filters["level"] = "TenantId";
        this.props.filters["id"] = props.item.TenantId;
        this._load();
    };

    public render(): JSX.Element {
        return (
            <div>
                <TextField
                    placeholder="Filter by name or id"
                    onChange={this._onChangeText}
                    iconProps={{ iconName: "Filter" }}
                />
                <Stack horizontal className="checkboxFilterStyles">
                    <ChoiceGroup
                        styles={choiceGroupStyles}
                        defaultSelectedKey="All"
                        options={
                            this.props.cohortChoiceOptions ??
                            cohortChoiceGroupOptions
                        }
                        onChange={this._onChoiceGroupChange}
                        required={true}
                    />
                </Stack>
                {this.state.showEOToggle && (
                    <Stack horizontal className="eoCohortToggle">
                        <Toggle
                            inlineLabel
                            checked={this.state.showEOTenants}
                            onText="EO"
                            offText="EO"
                            onChange={this._toggleEOList}
                            className="filterToggle"
                        />
                    </Stack>
                )}
                <div className="tenantListScrollablePane">
                    <ScrollableTenantDetailsList
                        loading={this.state.loading}
                        columns={_columns}
                        filters={this.props.filters}
                        metricFilters={this.props.metricFilters}
                        getTenantMetrics={this.props.getTenantMetrics}
                        tenantListItems={this.state.tenantListItems}
                        platform={this.props.platform}
                        tpidGroup={this.state.tpidGroup}
                        isCompactMode={this._isCompactMode}
                        showTenantStatus={
                            platformsConfig[this.props.platform].showTenantStatus
                        }
                        onHeaderRowClick={this._onHeaderRowClick}
                        onDetailsRowClick={this._onDetailsRowClick}
                    />
                </div>
            </div>
        );
    }
}
