import React from "react";
import { IconButton, Stack, StackItem } from "@fluentui/react";
import { CustomBarChart } from "components/CustomComponents/CustomBarChart/CustomBarChart";
import { maxArray } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { chartSizes } from "pages/common";
import {
    StageMetricPayloadType,
    StagePayload,
} from "pages/ConsumerDay0Funnel/types";
import { CONSUMERDAY0_MESSAGES, MONTHLYTREND_MESSAGE } from "utils/Messages";
import "components/ConsumerDay0FunnelComponents/FunnelInsights/style.css";

export const SessionAndAbandomentCharts = ({
    updatedPayload,
    legendFormatter,
    tabNote,
}: {
    updatedPayload: StagePayload;
    legendFormatter: Formatter;
    tabNote?: string;
}) => {
    const appPayload: StageMetricPayloadType[] = updatedPayload?.AppMode;
    const countryPayload: StageMetricPayloadType[] = updatedPayload?.Country;
    const browserPayload: StageMetricPayloadType[] = updatedPayload?.Browser;
    const osPayload: StageMetricPayloadType[] = updatedPayload?.OS;
    const referrerPlatformPayload: StageMetricPayloadType[] =
        updatedPayload?.ReferrerPlatform;
    const datacenterPayload: StageMetricPayloadType[] = updatedPayload?.Datacenter;

    const maxAppMode = maxArray(appPayload);
    const maxCountry = maxArray(countryPayload);
    const maxBrowser = maxArray(browserPayload);
    const maxOs = maxArray(osPayload);
    const maxRefer = maxArray(referrerPlatformPayload);
    const maxDatacenter = maxArray(datacenterPayload);

    return (
        <div className="day0FunnelInsightsPivots">
            <Stack
                enableScopedSelectors
                horizontal
                wrap
                verticalAlign="space-around"
                horizontalAlign="space-evenly"
            >
                {tabNote && (
                    <div className="subscriptStyleBottom abandonmentNoteStyle">
                        {CONSUMERDAY0_MESSAGES.ABANDONMENT_INSIGHTS}
                    </div>
                )}
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">AppMode Distribution</h3>
                    <CustomBarChart
                        payload={appPayload}
                        keys={["Percent"]}
                        fill="#d6604d"
                        domain={[0, Math.min(Math.ceil(maxAppMode + 10), 100)]}
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                    />
                    {appPayload && (
                        <span className="subscriptStyleWithPadding">
                            {MONTHLYTREND_MESSAGE}
                        </span>
                    )}
                </StackItem>
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">Country Distribution</h3>
                    <CustomBarChart
                        payload={countryPayload}
                        keys={["Percent"]}
                        fill="#79a4e0"
                        domain={[0, Math.min(Math.ceil(maxCountry + 10), 100)]}
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                    />
                    {countryPayload && (
                        <span className="subscriptStyleWithPadding">
                            {CONSUMERDAY0_MESSAGES.TOP_10_Country}
                        </span>
                    )}
                </StackItem>
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">Browser Distribution</h3>
                    <CustomBarChart
                        payload={browserPayload}
                        keys={["Percent"]}
                        fill="#82ca9d"
                        domain={[0, Math.min(Math.ceil(maxBrowser + 10), 100)]}
                        layout="vertical"
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                    />
                    {browserPayload && (
                        <span className="subscriptStyleWithPadding">
                            {MONTHLYTREND_MESSAGE}
                        </span>
                    )}
                </StackItem>
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">OS Distribution</h3>
                    <CustomBarChart
                        payload={osPayload}
                        keys={["Percent"]}
                        fill="#8884d8"
                        domain={[0, Math.min(Math.ceil(maxOs + 10), 100)]}
                        layout="vertical"
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                    />
                    {osPayload && (
                        <span className="subscriptStyleWithPadding">
                            {MONTHLYTREND_MESSAGE}
                        </span>
                    )}
                </StackItem>
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">
                        Referrer Platform Distribution
                        <IconButton
                            iconProps={{
                                iconName: "Unknown",
                            }}
                            title={
                                CONSUMERDAY0_MESSAGES.REFERRER_PLATFORM_DEFINITION
                            }
                            ariaLabel={
                                CONSUMERDAY0_MESSAGES.REFERRER_PLATFORM_DEFINITION
                            }
                        />
                    </h3>

                    <CustomBarChart
                        payload={referrerPlatformPayload}
                        keys={["Percent"]}
                        fill="#8884d8"
                        domain={[0, Math.min(Math.ceil(maxRefer + 10), 100)]}
                        layout="vertical"
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                    />
                    {referrerPlatformPayload && (
                        <span className="subscriptStyleWithPadding">
                            {MONTHLYTREND_MESSAGE}
                        </span>
                    )}
                </StackItem>
                <StackItem className="chartContainer">
                    <h3 className="commonPageBlockTitle">Datacenter Distribution</h3>
                    <CustomBarChart
                        payload={datacenterPayload}
                        keys={["Percent"]}
                        fill="#79a4e0"
                        domain={[0, Math.min(Math.ceil(maxDatacenter + 10), 100)]}
                        width={chartSizes.width}
                        legendFormatter={legendFormatter}
                        xAxisInterval={0}
                    />
                    {datacenterPayload && (
                        <span className="subscriptStyleWithPadding">
                            {CONSUMERDAY0_MESSAGES.TOP_10_DC}
                        </span>
                    )}
                </StackItem>
            </Stack>
        </div>
    );
};
