import React from "react";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import { ValueType } from "recharts/src/component/DefaultTooltipContent";
import { PerformanceTooltipProps } from "pages/Performance/types";

const formatValue = (value: ValueType, roundTooltipValues: boolean) => {
    return typeof value === "number"
        ? value.toLocaleString("en-us", {
              maximumFractionDigits: roundTooltipValues ? 1 : undefined,
          })
        : value;
};

/**
 * Custom tooltip for PerformanceLineChart. It is mostly the same as the default tooltip,
 * except that when the user is hovering over a dot, we'll adjust the tooltip to show
 * device & event counts also.
 */
const PerformanceTooltip = ({
    hoveredSeries,
    roundTooltipValues,
    ...tooltipProps
}: PerformanceTooltipProps) => {
    // Format values in the payload
    if (tooltipProps.payload) {
        tooltipProps.payload = tooltipProps.payload.map((item) => {
            return { ...item, value: formatValue(item.value, roundTooltipValues) };
        });
    }

    if (hoveredSeries === "" || !tooltipProps.active || !tooltipProps.payload) {
        // If we're not hovering over a dot or the tooltip
        // isn't active, do the default behavior.
        return <DefaultTooltipContent {...tooltipProps} />;
    }

    // Find the payload item that corresponds to the dot being hovered over.
    const hoveredData = tooltipProps.payload.find(
        (series) => series.name === hoveredSeries,
    );
    if (!hoveredData) {
        return <DefaultTooltipContent {...tooltipProps} />;
    }

    // Add device & event counts for the hovered series to the payload array.
    const payloadWithCounts = [
        ...tooltipProps.payload,
        ...Object.keys(hoveredData.payload)
            .filter((key) => key.endsWith(`(${hoveredSeries})`))
            .map((key) => {
                return {
                    ...hoveredData,
                    name: key,
                    value: formatValue(
                        tooltipProps.payload[0].payload[key],
                        roundTooltipValues,
                    ),
                    unit: undefined,
                };
            }),
    ];

    return <DefaultTooltipContent {...tooltipProps} payload={payloadWithCounts} />;
};

export default PerformanceTooltip;
