import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { MetricsResponseType, table } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { MtrException, Severity } from "utils/Exceptions";
import { formatLastUpdated } from "utils/Helpers";
import { MTR_MESSAGES } from "utils/Messages";
import { MtrState, SignalTrendFilterState } from "./types";

export const fetchData = async (
    setData: React.Dispatch<React.SetStateAction<MtrState>>,
    setError: React.Dispatch<React.SetStateAction<string>>,
) => {
    try {
        const queryParams: {} = {};
        const [
            unhealthyTenantTrend,
            unhealthyTenantMoverShaker,
            topTrendingSignals,
            webHealthChart,
            win32HealthChart,
            macHealthChart,
        ] = await Promise.all([
            API.getKustoResponse({
                queryName: "unhealthyTenantTrend",
                platform: Workload.CROSSPLATFORM,
            }),
            API.getKustoResponse({
                queryName: "unhealthyTenantMoverShaker",
                platform: Workload.CROSSPLATFORM,
            }),
            API.getKustoResponse({
                queryName: "topTrendingSignals",
                platform: Workload.CROSSPLATFORM,
            }),
            API.mtrhealthChart(queryParams, Workload.WEB),
            API.mtrhealthChart(queryParams, Workload.WIN32),
            API.mtrhealthChart(queryParams, Workload.MAC),
        ]);

        try {
            const responseData = formatMtrData(
                unhealthyTenantTrend?.data,
                unhealthyTenantMoverShaker?.data,
                topTrendingSignals?.data,
                webHealthChart?.data,
                win32HealthChart?.data,
                macHealthChart?.data,
            );
            setData((data) => {
                return {
                    ...data,
                    ...responseData,
                };
            });
        } catch (error) {
            logException(
                new MtrException(Severity.SEV3, "MTRDataProcessingFailed"),
                {
                    message:
                        "Failure processing response data from MTR Dashboard queries",
                    responseCodes: {
                        unhealthyTenantTrend: unhealthyTenantTrend.status,
                        unhealthyTenantMoverShaker:
                            unhealthyTenantMoverShaker.status,
                        topTrendingSignals: topTrendingSignals.status,
                        webHealthChart: webHealthChart.status,
                        win32HealthChart: win32HealthChart.status,
                        macHealthChart: macHealthChart.status,
                    },
                },
                error,
            );
            setData((data) => {
                return { ...data, loading: false };
            });
        }
    } catch (error) {
        logException(
            new MtrException(Severity.SEV3, "MTRDataFetchFailed"),
            {
                message: "Failure in fetching data for MTR Dashboard",
            },
            error,
        );
        setData((data) => {
            return { ...data, loading: false };
        });
        setError(MTR_MESSAGES.MTR_INFO_DISPLAY_ERROR);
    }
};

export const fetchSignalTrendData = async (
    setData: React.Dispatch<React.SetStateAction<MtrState>>,
    setError: React.Dispatch<React.SetStateAction<string>>,
    filters: SignalTrendFilterState,
) => {
    try {
        const queryParams: {} = {};
        queryParams["cohort"] = filters["cohort"];
        queryParams["color"] = filters["color"];

        const signalTrends = await API.signalTrends(filters.platform, queryParams);

        try {
            const responseData = formatSignalTrendData(signalTrends?.data);
            setData((data) => {
                return {
                    ...data,
                    ...responseData,
                };
            });
        } catch (error) {
            logException(
                new MtrException(Severity.SEV3, "SignalTrendDataProcessingFailed"),
                {
                    message:
                        "Failure processing response data from Signal Trend queries",
                    responseCodes: {
                        signalTrends: signalTrends.status,
                    },
                },
                error,
            );
            setData((data) => {
                return { ...data, loading: false };
            });
        }
    } catch (error) {
        logException(
            new MtrException(Severity.SEV3, "SignalTrendDataFetchFailed"),
            {
                message: "Failure in fetching data for Signal Trends",
            },
            error,
        );
        setData((data) => {
            return { ...data, loading: false };
        });
        setError(MTR_MESSAGES.MTR_INFO_DISPLAY_ERROR);
    }
};

export const formatMtrData = (
    unhealthyTenantTrendJson: MetricsResponseType,
    unhealthyTenantMoverShakerJson: MetricsResponseType,
    topTrendingSignalsJson: MetricsResponseType,
    webHealthChartJson: MetricsResponseType,
    win32HealthChartJson: MetricsResponseType,
    macHealthChartJson: MetricsResponseType,
) => {
    try {
        const unhealthyTenantTrend = formatData(unhealthyTenantTrendJson);
        const unhealthyMoverShaker = formatData(unhealthyTenantMoverShakerJson);
        const topTrendingSignals = formatData(topTrendingSignalsJson);
        const webHealthChart = formatData(webHealthChartJson);
        const win32HealthChart = formatData(win32HealthChartJson);
        const macHealthChart = formatData(macHealthChartJson);
        const lastUpdated = formatLastUpdated(
            String(
                unhealthyTenantTrend.rows[unhealthyTenantTrend.rows.length - 1][0],
            ),
        );
        return {
            unhealthyTenantTrend,
            unhealthyMoverShaker,
            topTrendingSignals,
            webHealthChart,
            win32HealthChart,
            macHealthChart,
            lastUpdated,
            loading: false,
        };
    } catch (error) {
        logException(new MtrException(Severity.SEV3, "MTRDataFormattingFailed"), {
            message: "Formatting failed for the responses received for MTR Data",
            responses: {
                unhealthyTenantTrend: unhealthyTenantTrendJson,
                unhealthyTenantMoverShaker: unhealthyTenantMoverShakerJson,
                topTrendingSignals: topTrendingSignalsJson,
                webHealthChart: webHealthChartJson,
                win32HealthChart: win32HealthChartJson,
                macHealthChart: macHealthChartJson,
            },
        });
        throw error;
    }
};

export const formatSignalTrendData = (signalTrendsJson: MetricsResponseType) => {
    try {
        const signalTrends = formatData(signalTrendsJson);
        return {
            signalTrends,
            loading: false,
        };
    } catch (error) {
        logException(
            new MtrException(Severity.SEV3, "SignalTrendDataFormattingFailed"),
            {
                message:
                    "Formatting failed for the responses received for Signal Trend Data",
                responses: {
                    signalTrends: signalTrendsJson,
                },
            },
        );
        throw error;
    }
};

export const formatData = (jsonData: MetricsResponseType): table => {
    const table = jsonData.Tables[0];
    const data = {
        headers: table.Columns.map((x: any) => x.ColumnName),
        rows: table.Rows,
    };
    return data;
};
