import { Configuration, RedirectRequest } from "@azure/msal-browser";

const endpoint =
    "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";

// App Registration ID
// const clientId = "d6b74470-33b3-4f24-a910-8a6c4e84099e";

// TO DO -BEFORE PUSHING TO PROD
// This is the client Id of the new App
// Either Change this to older one or
// Add the redicrect URI in the azure portal of new app
const clientId = "0a37cb5c-b0db-4184-8d4a-4d9c758a6c32";

export const msalConfig: Configuration = {
    auth: {
        clientId,
        authority: endpoint,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: process.env.TENANT_CACHELOCATION,
    },
};

export const appInsightsEnabled = () => {
    return process.env.TENANT_APPINSIGHTS_ENABLED === "true";
};

export const cosmosdbConfig = {
    database: "tenantengagement",
    notesContainer: "Notes",
    outagesContainer: "Outages",
};

export const azureFunctionConfig = {
    GetSecretFromVault:
        "https://tenantinsights-fav4.azurewebsites.net/api/GetSecretFromVault",
    PostMessageToQueue:
        "https://tenantinsights-fav4.azurewebsites.net/api/PostMessageToQueue",
    PostMessageToQueueManagedIdentity:
        "https://tenantinsights-fav4.azurewebsites.net/api/PostMessageToQueueManagedIdentity",
    CosmosDb: "https://tenantinsights-fav4.azurewebsites.net/api/CosmosDb",
    TranslateTenantNameManagedIdentity:
        "https://tenantinsights-fav4.azurewebsites.net/api/TranslateTenantNameManagedIdentity",
};

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"],
};

export const AzureAPIPermissions = {
    kusto: "https://ehfuelinsights.westus2.kusto.windows.net/.default",
    dataExplorer:
        "https://azure.microsoft.com/services/data-explorer/user_impersonation",
    functionApp: "api://8828ec20-0138-40ad-9398-49729d41d02c/user_impersonation",
};
