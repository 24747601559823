import { IStackStyles, IStackTokens } from "@fluentui/react";
import {
    FunnelInsightsState,
    FunnelInsightsTabState,
} from "pages/ConsumerDay0Funnel/types";

export const filterInsightsButtonBlock: IStackStyles = {
    root: {
        border: "solid 2px #f3f2f1",
        marginTop: "20px",
        padding: "10px",
        borderRadius: "14px",
        overflowX: "auto",
    },
};

export const horizontalFilterInsightsStackTokens: IStackTokens = {
    childrenGap: 0,
    padding: "0 0 20px 0",
};

export const filterInsightsStackBlock: IStackStyles = {
    root: {
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        marginTop: "20px",
        padding: "20px",
        borderRadius: "14px",
        overflowX: "auto",
    },
};

export const initialState: FunnelInsightsState = {
    payload: {},
    loading: true,
    droppedPayload: {},
};

export const initialTabState: FunnelInsightsTabState = {
    sessions: true,
    abandonments: false,
    fundamentals: false,
};
