import { isTest } from "utils/WebConstants";

export const tenantTouchPoints: string = `
// Get tenant touch point data
let currDate = GetCurrentDate(${isTest});
let tenantList =
    StrategicTenants
    | where Date == currDate
    | where \${levelColumnName} == "\${id}"
    | distinct OmsTenantId;
TenantTouchPointEntry
| where LastUpdatedDate > ago(180d)
| where OmsTenantId in (tenantList)
| where TouchType in ("Proactive Outreach", "Critical Watch List", "Direct Engagement")
| project LastUpdatedDate=format_datetime(LastUpdatedDate, "yyyy-MM-dd"), TouchType, ProgramName, OwnerDisplayName, ExecutiveUpdate
| order by LastUpdatedDate desc
`;
