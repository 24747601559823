import { webconsumergrowthCoreMetrics } from "./webconsumergrowthCoreMetrics";
import { webconsumergrowthDateFilters } from "./webconsumergrowthDateFilters";
import { webconsumergrowthSparkLineScores } from "./webconsumergrowthSparkLineScores";
import {
    webconsumergrowthTenantMetrics,
    webconsumergrowthTenantMetricsChart,
} from "./webconsumergrowthTenantMetrics";

export const consumerGrowthQueries: {} = {
    webconsumergrowthCoreMetrics,
    webconsumergrowthDateFilters,
    webconsumergrowthSparkLineScores,
    webconsumergrowthTenantMetrics,
    webconsumergrowthTenantMetricsChart,
};
