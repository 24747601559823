import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import GrowthScorecardFilters from "components/ConsumerGrowthScorecardComponents/GrowthScorecardFilters";
import GrowthScorecardTable from "components/ConsumerGrowthScorecardComponents/GrowthScorecardTable";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchMauCamauSignificanceData,
    fetchRetentionSignificanceData,
    fetchScorecardTabledata,
    formatScorecardDateOptions,
    formatScorecardTableData,
    formatStatSigData,
} from "components/ConsumerGrowthScorecardComponents/ApiHandler";
import {
    getConsumerGrowthScorecardColumns,
    getStatSigMetricsFromTableData,
} from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecardHelper";
import {
    ConsumerGrowthScorecardFiltersType,
    ConsumerGrowthScorecardStateType,
    StatSigSegmentMetricMap,
    StatSigUpdateState,
} from "components/ConsumerGrowthScorecardComponents/types";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { commonPageBlock } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { consumerPageStyle } from "utils/ConsumerConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const growthScorecardTableColumns = getConsumerGrowthScorecardColumns();

const ConsumerGrowthScorecard = () => {
    const config = getPageConfig(DrilldownPages.GROWTHSCORECARD_CONSUMER);
    const [error, setError] = useState<string>(null);
    const [filters, setFilters] = useState<ConsumerGrowthScorecardFiltersType>(
        () => {
            const initFilters: ConsumerGrowthScorecardFiltersType = {
                application: config.defaults.app,
                date: null,
            };
            const filtersFromQueryParams = extractQueryParams();

            if (filtersFromQueryParams["application"]) {
                const appFromURL = filtersFromQueryParams["application"] as string;

                if (config.appsList.includes(appFromURL)) {
                    initFilters.application = appFromURL;
                }
            }

            return {
                ...initFilters,
            };
        },
    );

    const [state, setState] = useState<ConsumerGrowthScorecardStateType>({
        loading: true,
        growthScorecardTableRows: {},
        dateOptions: [],
        distinctDates: [],
    });

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        logFilterUsage(config.pageTitle, item, value);
        setUpDocumentUrl(
            {
                section: extractQueryParams()["section"],
                application: updatedFilter.application,
            },
            config.pageTitle,
        );
        setFilters(updatedFilter);
        sendFiltersChangeTelemetryEvent(updatedFilter);
    };

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        document.title = config.pageTitle;
        AppInsights.getInstance().TrackPage(config.pageTitle);

        const getScorecardData = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });

            try {
                const mauData = await fetchScorecardTabledata(
                    filters,
                    "consumerScorecardMetrics",
                );

                const [items, distinctDates] = formatScorecardTableData(mauData);
                const dateOptions = formatScorecardDateOptions(
                    distinctDates,
                    onFilterChange,
                );

                if (distinctDates.length === 0)
                    throw new Error("Consumer growth scorecard has no dates");

                onFilterChange("date", distinctDates[0]);

                setState((oldState) => {
                    return {
                        ...oldState,
                        growthScorecardTableRows: items,
                        dateOptions: dateOptions,
                        distinctDates: distinctDates,
                        loading: false,
                    };
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer growth scorecard data",
                    },
                    e,
                );

                setError(e.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        growthScorecardTableRows: {},
                        distinctDates: [],
                        dateOptions: [],
                        loading: false,
                    };
                });
                return;
            }
        };
        setUpDocumentUrl(
            {
                section: extractQueryParams()["section"],
                application: filters.application,
            },
            config.pageTitle,
        );
        getScorecardData();

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [statSigMetricsMap, setStatSigMap] = useState<StatSigSegmentMetricMap>(
        new Map<string, string[]>(),
    );

    const [statSigMetricsStatus, setStatSigMetricsStatus] =
        useState<StatSigUpdateState>({ date: "", application: "" });

    useEffect(() => {
        const getStatSigMetrics = async () => {
            try {
                const [retentionStatSigData, mauCamauStatSigData] =
                    await Promise.all([
                        fetchRetentionSignificanceData({
                            date: filters.date,
                            application: filters.application,
                        }),
                        fetchMauCamauSignificanceData({
                            date: filters.date,
                            application: filters.application,
                        }),
                    ]);
                const statSigMetrics = formatStatSigData(
                    retentionStatSigData,
                    mauCamauStatSigData,
                );
                getStatSigMetricsFromTableData(
                    statSigMetrics,
                    state.growthScorecardTableRows,
                    filters.application,
                    filters.date,
                );
                setStatSigMap(statSigMetrics);
                setStatSigMetricsStatus({
                    date: filters.date,
                    application: filters.application,
                });
            } catch (e) {
                setStatSigMetricsStatus({ date: "", application: "" });
                setStatSigMap(new Map<string, string[]>());
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerScorecardStatSigFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer Growth stat sig data",
                    },
                    e,
                );
            }
        };
        if (
            filters.date &&
            filters.application &&
            state.growthScorecardTableRows[filters.application] &&
            !(
                statSigMetricsStatus.date === filters.date &&
                statSigMetricsStatus.application === filters.application
            )
        ) {
            getStatSigMetrics();
        }
    }, [
        filters.date,
        filters.application,
        state.growthScorecardTableRows,
        statSigMetricsStatus.application,
        statSigMetricsStatus.date,
    ]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack styles={consumerPageStyle}>
            {!state.loading && filters.date === null ? (
                <EmptyTableContainer />
            ) : (
                <>
                    <Stack.Item>
                        <GrowthScorecardFilters
                            filters={filters}
                            onFilterChange={onFilterChange}
                            growthScorecardState={state}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item grow>
                                <AppOverviewIconPanel
                                    apps={config.appsList}
                                    onAppClick={onFilterChange}
                                    selectedApp={filters.application}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Stack.Item styles={commonPageBlock}>
                            <GrowthScorecardTable
                                items={state.growthScorecardTableRows}
                                columns={growthScorecardTableColumns}
                                loading={state.loading}
                                filters={filters}
                                statSigMetrics={statSigMetricsMap}
                            />
                        </Stack.Item>
                    </Stack>
                </>
            )}
        </Stack>
    );
};

export default ConsumerGrowthScorecard;
