import React, { ReactNode } from "react";
import { Stack, StackItem } from "@fluentui/react";
import "components/CustomComponents/Card/style.css";

interface CardProps {
    width?: number;
    children: ReactNode;
}

interface CardComponentProps {
    width?: number;
    children: ReactNode;
}

export const Card = ({ width = null, children }: CardProps) => {
    return (
        <Stack
            className="cardContainer"
            style={{
                maxWidth: width ?? "100%",
            }}
        >
            {children}
        </Stack>
    );
};

export const CardHeader = ({ children }: CardComponentProps) => {
    return (
        <Stack
            enableScopedSelectors
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className="cardContainerHeader"
        >
            {children}
        </Stack>
    );
};

export const CardHeaderTitle = ({ children }: CardComponentProps) => {
    return <StackItem className={"cardContainerHeaderTitle"}>{children}</StackItem>;
};

export const CardHeaderRight = ({ children }: CardComponentProps) => {
    return <StackItem className={"cardContainerHeaderRight"}>{children}</StackItem>;
};

export const CardBody = ({ children }: CardComponentProps) => {
    return <StackItem className={"cardContainerBody"}>{children}</StackItem>;
};
