import {
    harmonyFunnelStages,
    harmonyFunnelDates,
    harmonyFunnelFilterChoices,
    harmonyFunnelDecomposition,
} from "queries/JourneyFunnels/HarmonyFunnel/harmonyFunnelQueries";

export const harmonyFunnelQueries = {
    harmonyFunnelStages,
    harmonyFunnelDates,
    harmonyFunnelFilterChoices,
    harmonyFunnelDecomposition,
};
