import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { appInsightsEnabled } from "config/AuthConfig";
import AppInsights from "utils/AppInsights";
import AuthenticationUtil from "utils/AuthenticationUtil";

// Need this component because unlike ADAL, MSAL has abstracted away the login tokens
// and <MsalAuthenticationTemplate> component in web.tx takes care of that out of the box
// To initialize app insights that depend on user logging, we need this HOC

export const AppInsightsInitialization = ({ children }) => {
    const [IsAppInsightsInialized, SetAppInsightsInitializeState] =
        useState<boolean>(false);

    const IsAuthenticated = useIsAuthenticated(); // to check if msal auth is successful

    useEffect(() => {
        const Initialize = async () => {
            let instrumentationKey = "";
            if (appInsightsEnabled()) {
                instrumentationKey = await AuthenticationUtil.fetchSecret(
                    "AppInsightsInstrumentationKey",
                );
            }
            AppInsights.init(instrumentationKey);

            try {
                AppInsights.getInstance().SetAuthenticatedUserContext(
                    AuthenticationUtil.getLoggedInUser(),
                );
            } catch (ex) {
                console.error("Error in setting username in app insights instance");
            }
            SetAppInsightsInitializeState(true);
        };

        Initialize();
    }, []);

    return IsAppInsightsInialized && IsAuthenticated ? (
        <>{children}</>
    ) : (
        <>
            <Spinner label="Authenticating..." size={SpinnerSize.large} />
        </>
    );
};
