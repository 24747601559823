import React from "react";
import { FilterPill, InPageFilterSimple } from "@m365-admin/in-page-filter";
import { DefaultFilterValue } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import { AdvisoryFilterProps } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";

const clearSelections = (
    setState:
        | React.Dispatch<
              React.SetStateAction<{
                  [key: string]: boolean;
              }>
          >
        | React.Dispatch<React.SetStateAction<string[]>>,
    isCustomFilterState: boolean,
) => {
    if (isCustomFilterState) {
        const st = setState as React.Dispatch<React.SetStateAction<string[]>>;
        st([]);
    } else {
        const st = setState as React.Dispatch<
            React.SetStateAction<{
                [key: string]: boolean;
            }>
        >;

        st({});
    }
};

export const AdvisoriesFilterPanel = ({
    advisoriesFilters,
}: AdvisoryFilterProps) => {
    return (
        <InPageFilterSimple label="Filters">
            {advisoriesFilters.map((filter) => (
                <FilterPill
                    key={filter.name}
                    name={filter.name}
                    defaultValue={DefaultFilterValue}
                    value={filter.selectedItems}
                    menuProps={{
                        items: filter.items,
                    }}
                    resetProps={{
                        onClick: () =>
                            clearSelections(
                                filter.setItems,
                                filter.isCustomFilterState,
                            ),
                        "aria-label": `Clear ${filter.name} Filters`,
                    }}
                />
            ))}
        </InPageFilterSimple>
    );
};
