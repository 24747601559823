import React from "react";
import { Link, MessageBar, MessageBarType } from "@fluentui/react";
import { getCurrentPageContact } from "components/InsightsComponents/Helpers";
import { useOnDismissMessageBar } from "utils/Helpers";
import { ERROR_FALLBACK_MESSAGES } from "utils/Messages";
import { ContactType } from "utils/types";

interface ErrorMessageBarProps {
    close?: () => void;
    message: string;
    refresh?: boolean;
}

export const ErrorMessageBar = ({
    close = null,
    message,
    refresh = true,
}: ErrorMessageBarProps) => {
    const { isOpen, handleDismissMessageBar } = useOnDismissMessageBar(close);
    const pageContact: ContactType = getCurrentPageContact();

    return (
        isOpen && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={handleDismissMessageBar}
                dismissButtonAriaLabel="Close"
            >
                {message}{" "}
                {refresh && (
                    <>
                        <Link onClick={() => window.location.reload()}>
                            {"Refresh"}
                        </Link>
                        {ERROR_FALLBACK_MESSAGES.ERROR_FALLBACK}
                        <Link href={pageContact.email}>{pageContact.name}</Link>
                        {ERROR_FALLBACK_MESSAGES.ERROR_FALLBACK_MESSAGE_FOR_URL}
                    </>
                )}
            </MessageBar>
        )
    );
};
