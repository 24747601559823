import React, { useContext } from "react";
import { Shimmer, Stack } from "@fluentui/react";
import ScenarioChartHeader from "pages/Performance/ScenarioChartHeader";
import PerformanceLineChart from "components/CustomComponents/PerformanceLineChart";
import { perfChartStackItemStyles } from "pages/Performance/PerformanceConstants";
import PerformanceContext from "pages/Performance/PerformanceContext";
import { App, ChartData, PerfRankingData, Scenario } from "pages/Performance/types";

/**
 *  This is the wrapper to each chart displayed on the Win32Performance page.
 */
const ScenarioChart = ({
    appName,
    scenario,
    ranking,
    values,
}: {
    appName: App;
    scenario: Scenario;
    ranking: PerfRankingData;
    values: ChartData[];
}) => {
    const performanceOptions = useContext(PerformanceContext);
    const { valuesToShow, legend } = performanceOptions;

    const thresholdView = valuesToShow === "PercentBelowThreshold";

    // UI rendering definition
    return (
        <Shimmer isDataLoaded={typeof values !== "undefined"}>
            <Stack styles={perfChartStackItemStyles} key={`${appName}${scenario}`}>
                <ScenarioChartHeader
                    {...{ appName, scenario, ranking, valuesToShow }}
                />
                <PerformanceLineChart
                    yUnit={thresholdView ? "%" : "s"}
                    values={values}
                    labels={legend}
                    domain={thresholdView ? [0, 100] : null}
                    roundTooltipValues={true}
                ></PerformanceLineChart>
            </Stack>
        </Shimmer>
    );
};

export default ScenarioChart;
