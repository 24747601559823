import React, { useEffect, useState } from "react";
import LoadingBar from "react-redux-loading-bar";
import {
    Stack,
    Dropdown,
    IDropdownOption,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    Toggle,
    Label,
    IButtonStyles,
    IDropdownStyles,
} from "@fluentui/react";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { TenantList, TenantImpactedByError } from "components/ErrorLookupComponents";
import { cohortOptions } from "config/PlatformConfig";
import { commonInnerBlock, commonPageBlock, commonPageStyle } from "pages/common";
import {
    fetchErrorLookup,
    fetchErrorLookupChart,
    fetchErrorNames,
} from "pages/ErrorLookup/ApiHandler";
import { ErrorLookupState, ErrorLookupFilters } from "pages/ErrorLookup/types";
import "./style.css";
import AppInsights from "utils/AppInsights";
import { logFilterUsage, logLevelBasedView } from "utils/AppInsightsHelper";
import { computeQueryParams, extractQueryParams } from "utils/Helpers";
import { ERRORLOOKUP_MESSAGES } from "utils/Messages";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";
import {
    Filters,
    hostOptions,
    uihostOptions,
    Application,
    dropdownStyles,
    defaultCohort,
} from "utils/WebConstants";

const comboBoxButtonStyle: Partial<IButtonStyles> = {
    rootHovered: {
        backgroundColor: "none",
    },
    rootPressed: {
        backgroundColor: "none",
    },
};

const ErrorLookup = () => {
    const queryParams = extractQueryParams();
    const appOptions: IDropdownOption[] = Object.keys(Application).map((app) => {
        return { key: app, text: app };
    });
    const [filters, setFilters] = useState<ErrorLookupFilters>({
        host: "All",
        uihost: "All",
        level: queryParams["level"] ? queryParams["level"] : Filters.DefaultLevel,
        app: queryParams["app"] ? queryParams["app"] : appOptions[0].key.toString(),
        date: queryParams["date"] ? queryParams["date"] : "",
        errorname: queryParams["errorname"] ? queryParams["errorname"] : null,
        cohort: queryParams["cohort"] ? queryParams["cohort"] : defaultCohort,
    });
    const [state, setState] = useState<ErrorLookupState>({
        isFetching: true,
        tenantsImpacted: [],
        dateOptions: [],
        errorNames: [],
        cohortData: {},
        chartData: [],
        tenantsImpactedCount: [],
        tenantsImpactedByHost: [],
        tenantsImpactedByUiHost: [],
        error: null,
    });

    useSendLaunchEvent("Office Web Error Lookup Tool", flattenFilters(filters));

    useEffect(() => {
        document.title = "Office Web Error Lookup";
        AppInsights.getInstance().TrackPage(document.title);
        logLevelBasedView(document.title, filters["level"]);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeURLParams = (filters) => {
        ["app", "date", "level", "errorname", "cohort"].forEach((param) => {
            queryParams[param] = filters[param];
        });
        window.history.pushState(
            "",
            "",
            window.location.pathname + computeQueryParams(queryParams),
        );
    };

    useEffect(() => {
        changeURLParams(filters);
        fetchErrorNames(filters, setState, setFilters);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.app]);

    useEffect(() => {
        if (filters.errorname == null) return;
        changeURLParams(filters);
        fetchErrorLookup(filters, setState);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filters.host,
        filters.uihost,
        filters.level,
        filters.date,
        filters.errorname,
        filters.cohort,
    ]);

    const onFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            logFilterUsage(document.title, dropdownFor, item.key.toString());

            const updatedFilter = {
                ...filters,
                [dropdownFor]: item.key.toString(),
            };

            setFilters(updatedFilter);
            sendFiltersChangeTelemetryEvent(updatedFilter);
        };
    };

    const onLevelChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        const level = checked ? "TenantId" : "Tpid";
        logLevelBasedView(document.title, level);
        setFilters((filters) => {
            return {
                ...filters,
                level,
            };
        });
    };

    const onErrorFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
            logFilterUsage(document.title, dropdownFor, item.key.toString());
            setFilters((filters) => {
                return {
                    ...filters,
                    [dropdownFor]: item.key.toString(),
                };
            });
        };
    };

    const filterConstants = [
        { label: "Time Period", item: "date", option: state.dateOptions },
        { label: "Host", item: "host", option: hostOptions },
        { label: "UiHost", item: "uihost", option: uihostOptions },
        { label: "Cohort", item: "cohort", option: cohortOptions },
    ];

    const errorLookupdropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 100 },
    };

    return state.isFetching ? (
        <LoadingModal text="Loading Error Lookup page..." />
    ) : (
        <>
            <LoadingBar className="loadingBar" />
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader">Error Lookup</div>
                </Stack.Item>
                <Stack horizontal className="errorLookupFilterPanel filterPanel">
                    <div>
                        <Label className="filterLabel">Application:</Label>
                        <Dropdown
                            options={appOptions}
                            selectedKey={filters["app"]}
                            onChange={onFilterChange("app")}
                            className="filterDropdown"
                            styles={dropdownStyles}
                        />
                    </div>
                    <div>
                        <Label className="filterLabel">Error Name:</Label>
                        <ComboBox
                            options={state.errorNames}
                            selectedKey={filters["errorname"]}
                            onChange={onErrorFilterChange("errorname")}
                            allowFreeform
                            autoComplete="on"
                            className="filterDropdown"
                            caretDownButtonStyles={comboBoxButtonStyle}
                        />
                    </div>
                    {filterConstants.map((filterItem) => {
                        return (
                            <div>
                                <Label className="filterLabel">
                                    {filterItem.label}
                                </Label>
                                <Dropdown
                                    placeholder="Select an option"
                                    options={filterItem.option}
                                    selectedKey={filters[filterItem.item]}
                                    onChange={onFilterChange(filterItem.item)}
                                    className="filterDropdown"
                                    styles={errorLookupdropdownStyles}
                                />
                            </div>
                        );
                    })}
                    <div>
                        <Toggle
                            inlineLabel
                            checked={filters["level"] !== "Tpid"}
                            onText="Tenant View"
                            offText="Top Parent View"
                            onChange={onLevelChange}
                            className="filterToggle"
                        />
                    </div>
                </Stack>
                <Stack styles={commonPageBlock}>
                    <Stack.Item styles={commonInnerBlock}>
                        <TenantImpactedByError
                            tenantCount={state.tenantsImpactedCount}
                            hostCount={state.tenantsImpactedByHost}
                            uiHostCount={state.tenantsImpactedByUiHost}
                            filters={{ ...filters }}
                        />
                        <span className="subscriptStyleBottom">
                            {ERRORLOOKUP_MESSAGES.HOST_NOTE}
                        </span>
                    </Stack.Item>
                    <div className="commonPageBlockTitle errorLookupHeader">
                        Tenants with the error - {filters["errorname"]}
                    </div>
                    <Stack.Item styles={commonInnerBlock}>
                        <TenantList
                            chartData={state.chartData}
                            errorTenants={state.tenantsImpacted}
                            filters={{ ...filters }}
                            getChartData={(filters) =>
                                fetchErrorLookupChart(
                                    filters,
                                    state.cohortData,
                                    setState,
                                )
                            }
                            cohortData={state.cohortData}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};

export default ErrorLookup;
