import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication,
    SilentRequest,
} from "@azure/msal-browser";
import {
    AzureAPIPermissions,
    azureFunctionConfig,
    msalConfig,
} from "config/AuthConfig";
import { logErrorEvents } from "utils/AppInsightsHelper";

/*
Initialize the authentication

Newer way of authentication - https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react
MSAL Browser - https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser
Important Info about scopes and permissions that is required to clear up confusion for new person - https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent?WT.mc_id=Portal-Microsoft_AAD_RegisteredApps
Getting a token for MSAL - https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
Migration comparison between adal and msal - https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-compare-msal-js-and-adal-js
App Registration for MSAL 2 with Single Page App - https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-app-registration
Reason why cannot pass multiple scopes of different application - https://docs.microsoft.com/en-us/answers/questions/37952/issues-with-scopes-assignment-in-azure-ad.html
*/

export default class AuthenticationUtil {
    private static msalInstance: PublicClientApplication;

    static getMSALInstance() {
        if (!AuthenticationUtil.msalInstance) {
            AuthenticationUtil.msalInstance = new PublicClientApplication(
                msalConfig,
            );

            // Optional - This will update account state if a user signs in from another tab or window
            AuthenticationUtil.msalInstance.enableAccountStorageEvents();
            AuthenticationUtil.msalInstance.addEventCallback(
                (event: EventMessage) => {
                    if (
                        (event.eventType === EventType.ACCOUNT_ADDED ||
                            event.eventType === EventType.LOGIN_SUCCESS) &&
                        event.payload
                    ) {
                        const eventInfo = event.payload as AuthenticationResult;
                        if (!AuthenticationUtil.msalInstance.getActiveAccount()) {
                            AuthenticationUtil.msalInstance.setActiveAccount(
                                eventInfo.account,
                            );
                        }
                    }
                },
            );
        }
        return AuthenticationUtil.msalInstance;
    }

    static getLoggedInUser() {
        return AuthenticationUtil.getMSALInstance().getActiveAccount()?.username;
    }

    static async getToken(tokenFor = AzureAPIPermissions.kusto) {
        const activeAccount =
            AuthenticationUtil.getMSALInstance().getActiveAccount();
        const accounts = AuthenticationUtil.getMSALInstance().getAllAccounts();

        if (activeAccount || accounts.length > 0) {
            const request: SilentRequest = {
                scopes: [tokenFor],
                account: activeAccount || accounts[0],
            };

            const authResult =
                await AuthenticationUtil.getMSALInstance().acquireTokenSilent(
                    request,
                ); // This method also caches initial response, so every request won't be made to backend

            return authResult.accessToken;
        }

        // No current active account found through getActiveAccount() and none in cache from getAllAccounts() as well
        // Fix: Clear the user's localStorage and reload page silently to log in again and refresh tokens
        // TODO: Change this to a more robust way of handling no active account issue: VSO: 8379060
        if (activeAccount === null || accounts.length == 0) {
            logErrorEvents(
                "NoActiveAccountForcedRefresh",
                "current activeAccount is null or no active accounts in cache",
            );
            console.error("No User logged In but tried to access kusto tokens");

            localStorage.clear();
            window.location.reload();
        }
    }

    static async fetchSecret(secretName) {
        const token = await AuthenticationUtil.getToken(
            AzureAPIPermissions.functionApp,
        );
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const url = `${azureFunctionConfig.GetSecretFromVault}?secretName=${secretName}`;
        const response = await fetch(url, { method: "get", headers });
        return response.text();
    }

    static async PostMessage(message: string) {
        const token = await AuthenticationUtil.getToken(
            AzureAPIPermissions.functionApp,
        );
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
            message: message,
        });
        const url = `${azureFunctionConfig.PostMessageToQueueManagedIdentity}`;
        const response = await fetch(url, { method: "POST", headers, body: body });
        return response.status == 200;
    }

    static async TranslateTenantName(tenantName: string) {
        const token = await AuthenticationUtil.getToken(
            AzureAPIPermissions.functionApp,
        );
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
            message: tenantName,
        });
        const url = `${azureFunctionConfig.TranslateTenantNameManagedIdentity}`;
        const response = await fetch(url, { method: "POST", headers, body: body });
        return response?.json;
    }
}
