import React from "react";
import { Stack, DefaultButton } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import {
    getDoraLinkFromFilters,
    getDoraParamsFromFilters,
} from "components/CustomComponents/DoraConnector/DoraHelpers";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { FunnelType, getFunnelConfig } from "config/FunnelConfig";
import { logReferralEvent } from "utils/AppInsightsHelper";
import "components/CustomComponents/DoraConnector/style.css";

interface DoraLinkProps {
    funnelType: FunnelType;
    filters: CommonFilterStateType;
}

export const DoraLink = ({ funnelType, filters }: DoraLinkProps) => {
    const doraFilters = getDoraParamsFromFilters(filters);

    const handleDoraReferral = (event) => {
        const funnelConfig = getFunnelConfig(funnelType);
        logReferralEvent(
            funnelConfig.pageTitle,
            funnelConfig.pageHeader,
            "DORA",
            JSON.stringify(doraFilters),
            event.currentTarget.href,
        );
    };

    return (
        <Stack verticalAlign="center">
            <DefaultButton
                href={getDoraLinkFromFilters(doraFilters, funnelType)}
                target="_blank"
                className="filterButton doraButton"
                onClick={handleDoraReferral}
            >
                Explore in
                <Icon iconName="img/dora_Logo.svg" />
            </DefaultButton>
        </Stack>
    );
};
