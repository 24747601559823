import React from "react";
import { IColumn, IContextualMenuItem } from "@fluentui/react";
import { ILineChartPoints } from "@fluentui/react-charting";
import { CustomDetailsItemsType } from "pages/commonTypes";

export enum AdvisoryTrend {
    Unknown = "Unknown",
    Degradation = "Deterioration",
    Improvement = "Improvement",
}

export enum AdvisorySeverity {
    Unknown = "Unknown",
    Minor = "Minor",
    Significant = "Significant",
}

export enum AdvisoryMetricType {
    Unknown = "Unknown",
    CrashRate = "Crash rate",
    BootTime = "Boot time",
    FileLocalOpenTime = "Local file open time",
    FileSharePointOpenTime = "SharePoint file open time",
}

export enum ServicingChannelV2 {
    Unknown = "Unknown Channel",
    SemiAnnualEnterpriseChannel = "Semi-Annual Enterprise Channel",
    SemiAnnualEnterpriseChannelPreview = "Semi-Annual Enterprise Channel (Preview)",
    CurrentChannel = "Current Channel",
    CurrentChannelPreview = "Current Channel (Preview)",
    MonthlyEnterpriseChannel = "Monthly Enterprise Channel",
    BetaChannel = "Beta Channel",
}

export enum OfficeProduct {
    Unknown = "Unknown",
    Excel = "Excel",
    Word = "Word",
    PowerPoint = "PowerPoint",
    Access = "Access",
    Outlook = "Outlook",
    Project = "Project",
    Publisher = "Publisher",
    Visio = "Visio",
    OneNote = "OneNote",
    Teams = "Teams",
}

export enum AdvisoryTypeV2 {
    Unknown = "Unknown",
    MetricOutOfRangeBaselineBuild = "MetricOutOfRangeBaselineBuild",
    MetricOutOfRangeSameBuild = "MetricOutOfRangeSameBuild",
}

export enum AdvisoryFilterNames {
    Metric = "Metric",
    Trend = "Trend",
    Type = "Type",
}

export interface IAppHealthAdvisories {
    /** An Office servicing channel which the alert was generated for (e.g. CurrentChannel). */
    servicingChannel: ServicingChannelV2;
    /** An Office application which the alert was generated for (e.g. Excel). */
    product: OfficeProduct;
    /** A type of an Office health metric which the alert was generated for (e.g. CrashRate). */
    metricType: AdvisoryMetricType;
    /** A type of an Office health alert (e.g. MetricOutOfRangeSameBuild). */
    advisoryType: AdvisoryTypeV2;
    /** A severity of an Office health alert (e.g. Significant). */
    advisorySeverity: AdvisorySeverity;
    /** Office health alert trend (i.e. degradation). */
    advisoryTrend: AdvisoryTrend;
    /** A build version which the alert was generated for (e.g. 16.0.12730.20352). */
    buildVersion: string;
    /** A build version which a health metric was compared with (e.g. 16.0.12730.20352). */
    comparedBuildVersion: string;
    /** A release version which the alert was generated for (e.g. 2004). */
    releaseVersion: string;
    /** A release version which a health metric was compared with (e.g. 2004). */
    comparedReleaseVersion: string;
    /** A value of an Office health metric at the moment when the alert was generated (e.g. 0.032). */
    metricValue: number;
    /** A value of Office health metric which a metric was compared with
at the moment when the alert was generated (e.g. 0.015). */
    comparedMetricValue: number;
    /** A count of events which are affected by the alert (e.g. 3172). */
    monitoredEventCount: number;
    /** An UNIX epoch in seconds when the alert was generated (e.g. 1594033612). */
    createdEpochInSeconds: number;
    /** Impacted devices count. */
    impactedDevicesCount: number;
    availabilityDate: string;
    endOfSupportDate: string;
    eventCount: number;
}

export interface AdvisoriesCardState {
    data: CustomDetailsItemsType[];
    error?: string;
}

export interface AdvisoriesHistoricState {
    data: ILineChartPoints[];
    loading: boolean;
    loadingFailed: boolean;
    error?: string;
}

export interface AdvisoriesHistoricProps {
    selectedRow: CustomDetailsItemsType;
    date: string;
    id: string;
}

export interface LoadingState {
    isLoading: boolean;
    isLoadingFailed: boolean;
}

export interface AdvisoryFilterDetailsType {
    name: string;
    items: IContextualMenuItem[];
    selectedItems: string[];
    setItems:
        | React.Dispatch<
              React.SetStateAction<{
                  [key: string]: boolean;
              }>
          >
        | React.Dispatch<React.SetStateAction<string[]>>;
    isCustomFilterState?: boolean;
}

export interface AdvisoryFilterProps {
    advisoriesFilters: AdvisoryFilterDetailsType[];
}

export interface DataTableProps {
    items: CustomDetailsItemsType[] | AddInsTabletype[] | AddInsFlyoutPanelData[];
    columns: IColumn[];
    onSearch: (value: string) => void;
    loading: boolean;
    FilterPanel: React.ReactNode;
    onColumnClick?: (
        column: IColumn,
        items?:
            | CustomDetailsItemsType[]
            | AddInsTabletype[]
            | AddInsFlyoutPanelData[],
    ) => void;
    onColumnRender?: (
        item?: CustomDetailsItemsType | AddInsTabletype,
        index?: number,
        column?: IColumn,
    ) => JSX.Element;
    setOpenFlyoutPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedRow?: React.Dispatch<
        React.SetStateAction<CustomDetailsItemsType | AddInsTabletype>
    >;
    maxHeight?: number;
    emptyMessage?: string;
}

export interface FlyoutPanelProps {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRow: CustomDetailsItemsType | AddInsTabletype;
    setSelectedRow?: React.Dispatch<
        React.SetStateAction<CustomDetailsItemsType | AddInsTabletype>
    >;
    dataItems?: CustomDetailsItemsType[];
    id?: string;
    date?: string;
}

export interface M365WrapperProps {
    platform: string;
    level: string;
    id: string;
    date: string;
}

export interface TableCardProps {
    id: string;
    date: string;
}

export interface AddInsDataState {
    text: string;
    data: IAppHealthAddIns[];
    uniqueCounts: number;
    selected: boolean;
    color?: string;
}

export interface AddInsCardState {
    items: AddInsDataState[];
    error?: string;
    buildOptions: string[];
}

export interface IAppHealthAddIns extends AddInsFlyoutPanelData {
    addinId: string;
    addinProgId: string;
    addinProvider: string;
    addinName: string;
}

export enum AddInsFilterNames {
    Applications = "Office Apps",
    Channels = "Channels",
    Builds = "Builds",
}

export interface AddInsFlyoutPanelData {
    [AddInsFilterNames.Applications]: OfficeProduct;
    [AddInsFilterNames.Builds]: string;
    [AddInsFilterNames.Channels]: ServicingChannelV2;
    version: string;
    monitoredDeviceCount: number;
    sessionCount: number;
    crashRate: number;
    loadTime: number;
    architecture: string;
}

export interface AddInsTabletype {
    addinName: string;
    addinProvider: string;
    addinVersion: string;
    addinApps: string;
    addinProgId: string;
    deviceCounts: number;
    sessionCounts: number;
    addinCrashrate: number;
    addinLoadTime: number;
    flyoutPanelData: AddInsFlyoutPanelData[];
}

export interface AddInsFlyoutPanelDetailsTableProps {
    flyoutPanelData: AddInsFlyoutPanelData[];
}
