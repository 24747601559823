export const webconsumerLicense: string = `
    // webconsumerLicense
    let app = "\${application}";
    let cohortType = "\${cohortType}";
    let cohortName = "\${cohortName}";
    let host = "OneDriveWOPI";
    ConsumerLicense 
    | where Application  == app and Host == host and CohortType == cohortType and CohortName == cohortName
    | project Date, Metric, Value
    | order by Date asc`;
