export const win32HealthChart: string = `
//win32HealthChart
let dt = "\${date}";
let appName = "\${application}";
let MAU = \${mauCountThreshold};
let cohort = "\${cohort}";
let tenantLevel = iff("\${level}" == "Tpid", "TopParent", "Tenant");
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_HealthDistribution
| project Application, Cohort, Color, Count, level, mauTags, ProcessDate, noDataCount
| where iff(cohort == "Other", Cohort == "All", Cohort == cohort)
| where Application == appName and level == tenantLevel and mauTags == MAU
| extend Current = (ProcessDate == dt)
| project ProcessDate, Color=GetColor(Color), Count, Current, noDataCount
| order by ProcessDate asc, Color asc`;
