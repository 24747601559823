import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { ApplicationAddInPerformance } from "pages/Performance/AddIns/ApplicationAddInPerformance";
import PerformanceTitle from "pages/Performance/PerformanceTitle";
import { Win32PerformanceDatePicker } from "pages/Performance/Win32PerformanceDatePicker";
import * as API from "api";
import { commonPageStyle } from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import {
    appObjectMap,
    countAndPerfImpactDataFromResponse,
    createAppInformationObject,
    formatAllAddInCostHistory,
    populateAppObjectWithResponse,
} from "pages/Performance/AddIns/AddInPerformanceHelper";
import { perfConfig } from "pages/Performance/PerfConfig";
import {
    AddInCountAndPerfImpactData,
    AddinPerfProps,
    AllAddInCostHistoryResponseRow,
    AppObject,
    ChartData,
    TenantTopAddInPerformanceData,
} from "pages/Performance/types";
import {
    fillInMissingDates,
    getDefaultTimespan,
    getMostRecentDate,
    getValidQueryParams,
    parseParams,
} from "pages/Performance/Win32PerformanceHelper";
import AppInsights from "utils/AppInsights";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { computeQueryParams, isPositiveIntegerString } from "utils/Helpers";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

const getInitialQueryParams = (suppliedQueryParams: {}) => {
    const defaultQueryParams: AddinPerfProps = {
        id: "72f988bf-86f1-41af-91ab-2d7cd011db47", // Microsoft
        date: undefined,
        timespan: undefined,
    };

    const queryParamValidation = {
        id: (id: any) => typeof id === "string",
        date: (date: any) => !!Date.parse(date),
        timespan: isPositiveIntegerString,
    };

    const queryParams = getValidQueryParams(
        suppliedQueryParams,
        defaultQueryParams,
        queryParamValidation,
    );

    queryParams.date = queryParams.date ?? getMostRecentDate(false);
    queryParams.timespan = queryParams.timespan ?? getDefaultTimespan(false);

    return queryParams;
};

const AddInPerformance = () => {
    const pageName = "Office Add-In Performance";
    document.title = pageName;
    AppInsights.getInstance().TrackPage(pageName);

    const location = useLocation();
    const path = location.search;

    const [error, setError] = useState<string>(null);

    const [topAddInData, setTopAddInData] =
        useState<AppObject<TenantTopAddInPerformanceData[]>>(undefined);

    const [allAddInCostHistory, setAllAddInCostHistory] =
        useState<AppObject<ChartData[]>>(undefined);

    const [addInHistory, setAddInHistory] =
        useState<AppObject<AddInCountAndPerfImpactData>>(undefined);

    const [queryParamsState, setQueryParamsState] = useState<AddinPerfProps>(
        getInitialQueryParams(parseParams(path) as Partial<AddinPerfProps>),
    );

    const onQueryParamChange = (updates: Partial<AddinPerfProps>) => {
        setQueryParamsState({ ...queryParamsState, ...updates });
    };

    useSendLaunchEvent("Office Add-In Performance Dashboard");

    // Update the URL when necessary
    useEffect(() => {
        window.history.pushState(
            {},
            "",
            window.location.pathname + computeQueryParams(queryParamsState),
        );
    }, [queryParamsState]);

    // Top Add-in Data tables
    useEffect(() => {
        const queryListData = async () => {
            setTopAddInData(undefined);
            try {
                const response =
                    await API.fetchTenantTopAddInsPerformance(queryParamsState);
                setTopAddInData(populateAppObjectWithResponse(response));
            } catch (error) {
                setTopAddInData(null);
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantTopAddInsPerformanceException",
                    ),
                    {
                        message:
                            "Failed to fetch top add-in performance table data.",
                    },
                    error,
                );
            }
        };
        queryListData();
    }, [queryParamsState]);

    // All Add-In Cost History
    useEffect(() => {
        const queryChartData = async () => {
            setAllAddInCostHistory(undefined);
            try {
                const response: KustoResponseType<string | number> =
                    await API.fetchTenantAllAddInCostHistory(queryParamsState);

                setAllAddInCostHistory(
                    appObjectMap(
                        appObjectMap(
                            populateAppObjectWithResponse<
                                string | number,
                                AllAddInCostHistoryResponseRow
                            >(response),
                            formatAllAddInCostHistory,
                        ),
                        (data) => fillInMissingDates(data, false),
                    ),
                );
            } catch (error) {
                setAllAddInCostHistory(createAppInformationObject([]));
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantAllAddInCostHistoryException",
                    ),
                    {
                        message: "Failed to cost history of all add-ins.",
                    },
                    error,
                );
            }
        };
        queryChartData();
    }, [queryParamsState]);

    // Add-in History
    useEffect(() => {
        const queryChartData = async () => {
            setAddInHistory(undefined);
            try {
                const response: KustoResponseType<string | number> =
                    await API.fetchTenantAddInHistory(queryParamsState);

                setAddInHistory(countAndPerfImpactDataFromResponse(response));
            } catch (error) {
                setAddInHistory(createAppInformationObject({}));
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantAddInHistoryException",
                    ),
                    {
                        message: "Failed to fetch add-in history for tenant.",
                    },
                    error,
                );
            }
        };
        queryChartData();
    }, [queryParamsState]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack disableShrink styles={commonPageStyle}>
            <PerformanceTitle
                pageName={pageName}
                id={queryParamsState.id}
                level="TenantId"
                setError={setError}
            />
            <Win32PerformanceDatePicker
                isMonthly={false}
                timespan={queryParamsState.timespan}
                date={queryParamsState.date}
                onInputChange={onQueryParamChange}
            />
            {perfConfig.Win32.AppsList.map((appName) => {
                return (
                    <ApplicationAddInPerformance
                        key={appName}
                        {...queryParamsState}
                        appName={appName}
                        topAddInData={topAddInData?.[appName]}
                        allAddInCostHistory={allAddInCostHistory?.[appName]}
                        addInHistory={addInHistory?.[appName]}
                    />
                );
            })}
        </Stack>
    );
};

export default AddInPerformance;
