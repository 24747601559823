import {
    consumerFunnelStages,
    consumerFunnelStageDates,
    consumerFunnelExportData,
    consumerFunnelInsights,
    consumerFunnelScenarios,
    consumerFunnelStagesAcrossScenarioAndReferrer,
    consumerFunnelStageReferrers,
    consumerFunnelFilterChoices,
} from "queries/ConsumerDay0Funnel/consumerFunnelQueries";

import {
    ashaConsumerFunnelPillarScoreWeekly,
    ashaConsumerFunnelVetoScoreWeekly,
    ashaConsumerFunnelErrorScoreWeekly,
    ashaConsumerFunnelStageRatesWeekly_Excel,
    ashaConsumerFunnelStageRatesWeekly_PowerPoint,
    ashaConsumerFunnelStageRatesWeekly_Word,
    ashaConsumerFunnelPillarScoreMonthly,
    ashaConsumerFunnelVetoScoreMonthly,
    ashaConsumerFunnelErrorScoreMonthly,
    ashaConsumerFunnelStageRatesMonthly_Excel,
    ashaConsumerFunnelStageRatesMonthly_PowerPoint,
    ashaConsumerFunnelStageRatesMonthly_Word,
} from "queries/ConsumerDay0Funnel/ashaScores";

export const consumerday0FunnelQueries = {
    consumerFunnelStages,
    consumerFunnelStageDates,
    consumerFunnelExportData,
    consumerFunnelInsights,
    consumerFunnelScenarios,
    consumerFunnelFilterChoices,
    consumerFunnelStagesAcrossScenarioAndReferrer,
    consumerFunnelStageReferrers,
    ashaConsumerFunnelPillarScoreWeekly,
    ashaConsumerFunnelVetoScoreWeekly,
    ashaConsumerFunnelErrorScoreWeekly,
    ashaConsumerFunnelStageRatesWeekly_Excel,
    ashaConsumerFunnelStageRatesWeekly_PowerPoint,
    ashaConsumerFunnelStageRatesWeekly_Word,
    ashaConsumerFunnelPillarScoreMonthly,
    ashaConsumerFunnelVetoScoreMonthly,
    ashaConsumerFunnelErrorScoreMonthly,
    ashaConsumerFunnelStageRatesMonthly_Excel,
    ashaConsumerFunnelStageRatesMonthly_PowerPoint,
    ashaConsumerFunnelStageRatesMonthly_Word,
};
