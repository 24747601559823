import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { CommandBarButton } from "@fluentui/react";
import { FunnelDataExportProps } from "components/ConsumerDay0FunnelComponents/FunnelDataExport/types";
import {
    getCSVExportData,
    formatCSVData,
} from "pages/ConsumerDay0Funnel/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import "components/ConsumerDay0FunnelComponents/FunnelDataExport/style.css";

export const FunnelDataExport = ({ filters }: FunnelDataExportProps) => {
    const [exportCSVData, setExportCSVData] = useState<
        Array<Array<number | string>>
    >([]);

    useEffect(() => {
        const getDataForExport = async () => {
            try {
                const payload = await getCSVExportData(filters);

                const csvExportData: Array<Array<number | string>> =
                    formatCSVData(payload);
                setExportCSVData(csvExportData);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerDay0FunnelPageExportDataFetchFailed",
                    ),
                    {
                        message:
                            "Failed to fetch export data for Consumer Day 0 Funnel page",
                    },
                    e,
                );
            }
        };

        getDataForExport();
    }, [filters, filters.application, filters.cadence]);

    return exportCSVData.length > 0 ? (
        <div className="downloadCSVButton">
            <CSVLink
                data={exportCSVData}
                filename={`Day0Session0Journey-${filters.application}-OneDriveWOPI.csv`}
            >
                <CommandBarButton
                    className="commandBarButton"
                    iconProps={{
                        iconName: "Download",
                    }}
                    text="Export"
                />
            </CSVLink>
        </div>
    ) : (
        <> </>
    );
};
