import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import {
    Stack,
    IColumn,
    IDetailsRowProps,
    TextField,
    IGroupDividerProps,
    Shimmer,
} from "@fluentui/react";
import ScrollableTenantDetailsList from "components/TenantDrilldownComponents/TenantList/ScrollableTenantDetailsList";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { commonPageBlock } from "pages/common";
import {
    generateTpidGroups,
    formatTenantList,
} from "pages/M365TenantInsights/TenantSearchHelper";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { computeQueryParams } from "utils/Helpers";
import { M365_MESSAGES } from "utils/Messages";
import PlatformTenantLinks from "./PlatformTenantLinks";
import "./style.css";
import { M365Params } from "./types";

const columns: Array<IColumn> = [
    {
        key: "1",
        name: "Tenant Name",
        fieldName: "TenantName",
        minWidth: 195,
        maxWidth: 195,
    },
];

interface TenantType {
    Tpid: number;
    OrganizationName: string;
    TenantId: string;
    TenantName: string;
    Show: boolean;
}

interface TenantDetailsType {
    level: string;
    id: string;
    name: string;
    source: string[];
}

interface TpidGroupType {
    key: string;
    name: string;
    source: string[];
    startIndex: number;
    endIndex: number;
    level: number;
    count: number;
    isCollapsed: boolean;
    isEmptyGroup: boolean;
}

const tenantDetailsInitialstate: TenantDetailsType = {
    level: "TenantId",
    id: "",
    name: "",
    source: [],
};
const TenantSearch = ({
    setError,
    showTenantLinks,
    m365params,
}: {
    setError: (text: string) => void;
    showTenantLinks: boolean;
    m365params: M365Params;
}) => {
    const [tenantDetails, setTenantDetails] = useState<TenantDetailsType>(
        tenantDetailsInitialstate,
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [tpidGroup, setTpidGroup] = useState<TpidGroupType[]>([]);
    const [showPlatformLinks, TogglePlatformLinks] =
        useState<boolean>(showTenantLinks);
    const [showTenantList, ToggleTenantList] = useState<boolean>(false);
    const [allTenants, setAllTenants] = useState<TenantType[]>([]);

    const onDetailsRowClick = (props: IDetailsRowProps | undefined) => {
        window.history.pushState(
            "",
            "",
            window.location.pathname +
                computeQueryParams({ level: "TenantId", id: props.item.TenantId }),
        );
        setTenantDetails({
            level: "TenantId",
            id: props.item.TenantId,
            name: props.item.TenantName,
            source: props.item.TenantSource,
        });
        TogglePlatformLinks(true);
        ToggleTenantList(false);
    };

    const onHeaderRowClick = (props: IGroupDividerProps | undefined) => {
        window.history.pushState(
            "",
            "",
            window.location.pathname +
                computeQueryParams({ level: "Tpid", id: props.group.key }),
        );
        setTenantDetails({
            level: "Tpid",
            id: props.group.key,
            name: props.group.name,
            source: props.group.source,
        });
        TogglePlatformLinks(true);
        ToggleTenantList(false);
    };
    const defaultM365Params = {
        level: "",
        id: "",
    };
    const getTenantDetails = async () => {
        try {
            if (m365params == null || isEqual(m365params, defaultM365Params)) {
                return;
            }
            let queryParams = {};
            const levelParams = API.getQueryParamsForLevel(m365params["level"]);
            queryParams = { ...levelParams, ...m365params };
            const tenantDetails = await API.getKustoResponse({
                queryName: "m365TenantTpidDetails",
                platform: Workload.WEB,
                queryParams: queryParams,
            });
            try {
                const data = tenantDetails?.data?.Tables[0];
                const formattedTenantDetails = formatTenantList(data?.Rows);
                setTenantDetails({
                    level: m365params.level,
                    id:
                        m365params.level === "Tpid"
                            ? formattedTenantDetails[0].Tpid
                            : formattedTenantDetails[0].TenantId,
                    name: formattedTenantDetails[0].TenantName,
                    source:
                        m365params.level === "Tpid"
                            ? formattedTenantDetails[0].TpidSource
                            : formattedTenantDetails[0].TenantSource,
                });
                TogglePlatformLinks(true);
                ToggleTenantList(false);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "M365TenantInsightsDataProcessingFailed",
                    ),
                    {
                        message:
                            "Failure to get Tenant Details in M365TenantInsights query",
                    },
                    error,
                );
                setError(M365_MESSAGES.TENANT_DETAILS_QUERY_FAIL);
            }
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "M365TenantInsightsDataProcessingFailed",
                ),
                {
                    message:
                        "Failure to process M365 TenantInsights Tenant Details data",
                },
                error,
            );
            setError(M365_MESSAGES.TENANT_DETAILS_QUERY_FAIL);
        }
    };
    const getTenantList = async () => {
        try {
            const tenantsList = await API.getKustoResponse({
                queryName: "m365TenantList",
                platform: Workload.WEB,
                queryParams: {},
            });
            try {
                const data = tenantsList?.data?.Tables[0];
                const formattedTenantList = formatTenantList(data?.Rows);
                setTpidGroup(generateTpidGroups(formattedTenantList, false));
                setLoading(false);
                setAllTenants(formattedTenantList);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "M365TenantInsightsDataProcessingFailed",
                    ),
                    {
                        message:
                            "Failure to get data from M365 TenantInsights page queries",
                    },
                    error,
                );
                throw error;
            }
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "M365TenantInsightsDataProcessingFailed",
                ),
                {
                    message: "Failure to process M365 TenantInsights page data",
                },
                error,
            );
            setError(M365_MESSAGES.TENANT_LIST_QUERY_FAIL);
        }
    };

    const onSearchBarFocus = (
        input:
            | React.ChangeEvent<HTMLInputElement>
            | React.FocusEvent<HTMLInputElement, Element>,
    ): void => {
        if (input?.target?.value) {
            ToggleTenantList(true);
        } else {
            ToggleTenantList(false);
        }
    };

    // This click event handler on searchBlock is to make sure the tenantlist dropdown is closed when clicked outside.
    // This is a temporary fix for the issue. Ideally we should be using the Autocomplete component.
    // Task 6067755 to track this.
    const onSearchBlockClick = (ev) => {
        if (
            !(
                ev.target.className.includes("customerListOrgName") ||
                ev.target.className.includes("TextField") ||
                ev.target.className.includes("ms-Button") ||
                ev.target.className.includes("DetailsHeader")
            )
        ) {
            ToggleTenantList(false);
        }
    };

    const onChangeText = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        text: string,
    ): void => {
        let isCollapsed = true;
        const tenantsListTemp = allTenants;
        // returns the keys which have value as true, i.e selected cohort in an array
        if (text) {
            ToggleTenantList(true);
            text = text.toLowerCase();

            tenantsListTemp.forEach((i) => {
                if (isCollapsed) {
                    isCollapsed = !(
                        i.TenantName.toLowerCase().indexOf(text) > -1 ||
                        i.TenantId.toString().indexOf(text) > -1
                    ); // To expand groups is tenant searched is oms level
                }

                i["Show"] =
                    i.OrganizationName.toLowerCase().indexOf(text) > -1 ||
                    (i.Tpid !== null && i.Tpid.toString().indexOf(text) > -1) ||
                    i.TenantName.toLowerCase().indexOf(text) > -1 ||
                    i.TenantId.toString().indexOf(text) > -1;
            });
        } else {
            ToggleTenantList(false);
            TogglePlatformLinks(false);
        }
        const selected: any[] = tenantsListTemp.filter((i) => i.Show);
        setTpidGroup(generateTpidGroups(selected, isCollapsed));
    };

    const tenantSearchBoxStyle = {
        ".ms-TextField-fieldGroup": {
            borderRadius: "6px",
        },
    };

    useEffect(() => {
        getTenantList();
        // ADO 7955411: useCallback refactor on getTenantList
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getTenantDetails();
        // ADO 7955411: useCallback refactor on getTenantDetails
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [m365params]);
    return (
        <>
            <Stack
                className="tenantSearchBlock"
                styles={commonPageBlock}
                onClick={onSearchBlockClick}
            >
                <Stack.Item>
                    <div className="m365SearchHeader">Tenant Search</div>
                </Stack.Item>
                <Stack.Item>
                    {loading ? (
                        <>
                            <Shimmer className="m365Shimmer" />
                            <Shimmer className="m365Shimmer" />
                        </>
                    ) : (
                        <TextField
                            className="m365TenantSearchBox"
                            placeholder="Search by Tpid, Tenant Id, Tenant Name"
                            onChange={onChangeText}
                            onFocus={(input) => {
                                onSearchBarFocus(input);
                            }}
                            iconProps={{ iconName: "Filter" }}
                            styles={{
                                root: {
                                    selectors: tenantSearchBoxStyle,
                                },
                            }}
                            data-testid="m365TenantSearchTextField"
                        />
                    )}
                </Stack.Item>
                {showTenantList && (
                    <div
                        className="tenantListScrollablePanel365"
                        data-testid="m365TenantListScrollablePanel"
                    >
                        <ScrollableTenantDetailsList
                            loading={loading}
                            columns={columns}
                            tenantListItems={allTenants}
                            tpidGroup={tpidGroup}
                            isCompactMode={false}
                            showTenantStatus={false}
                            onHeaderRowClick={onHeaderRowClick}
                            onDetailsRowClick={onDetailsRowClick}
                        />
                    </div>
                )}
                {showPlatformLinks && (
                    <Stack.Item>
                        <>
                            <Stack.Item>
                                <div className="m365OrgHeader">
                                    <span id="tenantName">
                                        {" "}
                                        {tenantDetails.name}{" "}
                                    </span>
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <PlatformTenantLinks
                                    level={tenantDetails.level}
                                    id={tenantDetails.id}
                                    source={tenantDetails.source}
                                />
                            </Stack.Item>
                        </>
                    </Stack.Item>
                )}
            </Stack>
        </>
    );
};

export default TenantSearch;
