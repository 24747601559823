import React, { useEffect, useState } from "react";
import camelCase from "lodash/camelCase";
import { IDropdownOption, Shimmer, Stack } from "@fluentui/react";
import * as API from "api";
import { cohortOptions } from "config/PlatformConfig";
import {
    PerformanceTitleMetadata,
    PerformanceTitleProps,
} from "pages/Performance/types";
import { formatMetaData } from "pages/Performance/Win32PerformanceHelper";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { DRILLDOWN_ERROR_MESSAGES, USER_FORBIDDEN_BANNER } from "utils/Messages";
import "./style.css";

const defaultMetaDataProps: PerformanceTitleMetadata = {
    level: "Tpid",
    orgName: "",
    tpid: "",
    OmsTenantId: "",
    cohorts: {
        isGov: false,
        isS2500: false,
        isS500: false,
        isEpa: false,
        isGoogleMove: false,
    },
};

/* This is the title displayed on the performance Drilldowns page of Win32. This is a modified version of
TenantTitle to suit the performance requirements. */
const PerformanceTitle = ({
    pageName,
    id,
    level,
    setError,
}: PerformanceTitleProps) => {
    const [metadataState, setMetadataState] =
        useState<PerformanceTitleMetadata>(defaultMetaDataProps);
    const [hasTenantData, setHasTenantData] = useState<boolean>(false);

    // metaData state
    useEffect(() => {
        const loadMetadata = async () => {
            try {
                const metadata = await API.loadBasicTenantMetaData({
                    level,
                    id,
                });
                const metaDataResult =
                    metadata.Tables[0].Rows.length === 0
                        ? defaultMetaDataProps
                        : formatMetaData(metadata);
                setMetadataState(metaDataResult);
                setHasTenantData(true);
            } catch (error) {
                if (error.isUnAuthorized) {
                    setError(`${USER_FORBIDDEN_BANNER} ${pageName}. `);
                } else {
                    setError(`${pageName} ${DRILLDOWN_ERROR_MESSAGES}`);
                }

                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "Win32PerfLoadBasicTenantMetaDataFailed",
                    ),
                    {
                        message:
                            "Kusto Query failed for Win32Perf loadBasicTenantMetaData",
                        status: error?.errorResponse?.status,
                        query: error?.errorResponse?.config?.data,
                        level,
                        id,
                    },
                );
            }
        };

        loadMetadata();
    }, [id, level, pageName, setError]);

    if (!hasTenantData) {
        return (
            <>
                <Shimmer className="tenantTileShimmerHeader" />
                <Shimmer className="tenantTileShimmerBody" />
            </>
        );
    }

    const { OmsTenantId, tpid, orgName } = metadataState;
    const tenantId =
        metadataState.level === "TenantId" ? `(TenantId : ${OmsTenantId})` : <br />;

    const cohortTags = cohortOptions.reduce(
        (prevValue, currentValue: IDropdownOption) => {
            const cohortName = currentValue.text;
            const cohortKey = currentValue.key.toString();
            const cohortBoolean = camelCase(`is${cohortKey}`);
            const id = camelCase(cohortKey);
            if (metadataState.cohorts[cohortBoolean]) {
                return prevValue.concat(
                    <span id={id} key={cohortName} className={`cohortTag ${id}`}>
                        {cohortName}
                    </span>,
                );
            }
            return prevValue;
        },
        [],
    );

    return (
        <Stack>
            <div className="orgHeader">
                <span id="drilldownTitle">{pageName}</span>
                <div className="drilldownTenantName">
                    <span id="tenantDetails">
                        {orgName} (Tpid : {tpid}){cohortTags}
                    </span>
                    <Stack.Item>
                        <span className="tenantId">{tenantId}</span>
                    </Stack.Item>
                </div>
            </div>
        </Stack>
    );
};

export default PerformanceTitle;
