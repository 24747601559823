import { scaleBand } from "@visx/scale";
import { areaWidth } from "components/CommonFunnelComponents/CommonFunnelGraph/constants";
import { FunnelStageProps } from "components/CommonFunnelComponents/CommonFunnelStages/types";

export const getAreaXScale = (payload: FunnelStageProps[]) => {
    return scaleBand<string>({
        range: [0, 2 * areaWidth],
        domain: payload.map((d) => {
            return d?.stage.id;
        }),
    });
};
