export const webconsumergrowthCoreMetrics = `
// webconsumergrowthCoreMetrics
    let maxProcessDateForCohortEndDate = database('ConsumerInsights').EarlyRetentionMetrics
    | summarize MaxProcessDate = max(ProcessDate) by CohortEndDate
    | join kind=inner (database('ConsumerInsights').EarlyRetentionMetrics | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and UserSeenType == "New") on $left.CohortEndDate == $right.CohortEndDate, $left.MaxProcessDate == $right.ProcessDate
    | project ProcessDate, CohortEndDate, Application, DeviceType, Referrer, UiHost, Host, Platform, OperatingSystem, LicenseModel, UserSeenType, M1R, M3R, M1R_YoY, M3R_YoY;
    let application = "\${application}";
    let dt = "\${date}";
    let platform = "\${platform}";
    let host  = "\${host}";
    let license = "\${license}";
    let seenType = "\${seenType}";
    let nullOrRound = (value:real) { iff(isempty(value), value, round(value,2)) };
    let referrer = "\${referrer}";
    database('ConsumerInsights').Usage
    | where Date == dt
    | where OperatingSystem == "All" 
    | where DeviceType == "All"
    | where UiHost == "All"
    | where Application == application
    | where Platform == platform 
    | where Host == host
    | where LicenseModel == license
    | where UserSeenType == seenType
    | where Referrer == referrer
    | join kind=leftouter (database('ConsumerInsights').FYGoals | extend Referrer = "All")
            on Application, Host, LicenseModel, UserSeenType, Referrer
    | join kind=leftouter (
        maxProcessDateForCohortEndDate
        | where Application == application
        | where Platform == platform
        | where Referrer == referrer
        | where LicenseModel == license
        | where Host == host
        )
            on $left.Date == $right.CohortEndDate
    | join kind=leftouter (
        database('ConsumerInsights').CamauPctAtDay0
        | where Application == application
        | where CohortName in ("Free All", "Free New", "Free Returning", "OneDrive All", "Paid All")
        | extend CohortName = iff(CohortName  == "OneDrive All", "All All", CohortName)
        | extend LicenseModel = tostring(split(CohortName, " ", 0)[0])
        | extend UserSeenType = tostring(split(CohortName, " ", 1)[0])
        | extend CAMAUDay0_Pct = Value, CAMAUDay0_YoY = YoY
        ) 
            on Application, Host, LicenseModel, UserSeenType, Date
    | project Date, MAU, YoY_MAU=round(MAU_YoY,2), Goal_MAU = MAU_Goal, CAMAUDay0Pct=nullOrRound(CAMAUDay0_Pct), YoY_CAMAUDay0Pct=round(CAMAUDay0_YoY,2), Goal_CAMAUDay0Pct=CAMAUDay0_Goal, round(PercentCAMAU,2), YoY_PercentCAMAU=round(PercentCAMAU_YoY,2), Goal_PercentCAMAU=PercentCAMAU_Goal, CAMAU, YoY_CAMAU = round(CAMMAU_YoY,2), Goal_CAMAU=CAMAU_Goal, M1R, YoY_M1R=M1R_YoY, Goal_M1R=M1Retention_Goal, M3R, YoY_M3R=M3R_YoY, Goal_M3R=M3Retention_Goal
    | order by Date asc
`;
