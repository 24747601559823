import React, { useState } from "react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { getKustoResponse } from "api";
import { TenantList } from "components/TenantDrilldownComponents/TenantList";
import { Workload } from "config/PlatformConfig";
import { formatCopilotTenantsList } from "pages/Copilot/ApiHandler";
import {
    CopilotExecOverViewScoreType,
    CopilotProductDrilldownFilterState,
    CopilotTenantListPropsType,
    CopilotTenantListType,
} from "pages/Copilot/types";
import { logException } from "utils/AppInsightsHelper";
import { copilotTenantListChoiceOptions } from "utils/CopilotConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";

export const CopilotProductDrilldownTenantList = ({
    filters,
    scoreType,
    setFilters,
}: CopilotTenantListPropsType) => {
    const [data, setData] = useState<CopilotTenantListType>({
        tenantsListRows: [],
        error: null,
        loading: true,
    });

    const [score, setScore] = useState<CopilotExecOverViewScoreType>();

    const getTenantList = async (
        payloadFilters: CopilotProductDrilldownFilterState,
    ) => {
        try {
            setData((data) => {
                return {
                    ...data,
                    loading: true,
                };
            });
            setScore(scoreType); // Important to set score before fetching data else goes infinite loop

            const queryParams = { ...payloadFilters };

            const tenantList = await getKustoResponse({
                queryName: `copilot${scoreType}ScoreTenantList`,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            });

            const responseData = formatCopilotTenantsList(tenantList?.data);

            setData((data) => {
                return {
                    ...data,
                    tenantsListRows: responseData || [],
                    loading: false,
                };
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "CopilotProductDrilldownTenantListFetchingFailed",
                ),
                {
                    message:
                        "Failure in fetching TenantList for Copilot Product Drilldown",
                },
                error,
            );

            setData((data) => {
                return {
                    ...data,
                    error: error.message,
                    loading: false,
                };
            });
        }
    };

    if (score !== scoreType) {
        if (filters.date !== null) {
            getTenantList(filters);
        }
    }

    return data.error ? (
        <ErrorFallback message={data.error} />
    ) : (
        <TenantList
            payload={data.tenantsListRows}
            filters={{ ...filters }}
            metricFilters={{}}
            getTenantMetrics={(filters) => {
                setFilters((prevFilters) => {
                    return { ...prevFilters, ...filters };
                });
            }}
            queryParamsUrl={""}
            loading={data.loading}
            platform={Workload.COPILOT_COMMERCIAL}
            updateUrl={false}
            cohortChoiceOptions={copilotTenantListChoiceOptions}
        />
    );
};
