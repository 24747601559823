export const win32AvgCohortMetrics: string = `
//win32AvgCohortMetrics"; (To display average of a metric per cohort, on tiles)
let currentDate = GetCurrentDate(false); //isTesting value is set to false for GetCurrentDate function
StrategicTenants
| where Date == currentDate and \${levelColumnName} == "\${id}" 
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov 
| take 1
| project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
| project Cohorts = replace_string(Cohorts, "All;", "") // We don't want to treat the "other cohort" any different
| project allcohorts = split(Cohorts,';')
| project iff(array_length( allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
| mv-expand Cohort = Column1
| project Cohort = tostring(Cohort)
| join kind=inner(
	HealthyTenantScore_CohortAverage
	| where Application=="\${app}" and Date =="\${date}"
	) on Cohort
| project Cohort,
			Engagement = round(MAURate,2),
			Currency = round(Currency,2),
			OCV = round(OCV,2),
			Watson = round(Watson,2),
			UAE = round(UAE,2),
			PerfP95 = round(PerfP95,2),
			BootThreshold=round(BootThreshold,2)`;
