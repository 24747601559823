import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { CustomBarChart } from "components/CustomComponents/CustomBarChart/CustomBarChart";
import { CustomPieChart } from "components/CustomComponents/CustomPieChart/CustomPieChart";
import { Latency } from "components/TenantDrilldownComponents/TeamsMetrics/Latency";
import * as API from "api";
import { MonthlyTrend } from "components/TenantDrilldownComponents/MonthlyTrend";
import { formatTeamsMetrics } from "components/TenantDrilldownComponents/TeamsMetrics/TeamsMetricsHelper";
import {
    TeamsMetricsState,
    TeamsMetricsPropsType,
} from "components/TenantDrilldownComponents/TeamsMetrics/types";
import { TeamsSubWorkload, Workload } from "config/PlatformConfig";
import { horizontalStackTokens, equalStackItemsStyle } from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

const initialState: TeamsMetricsState = {
    payload: {},
    loading: true,
};

export const TeamsMetrics = ({
    app,
    filters,
    metricFilters,
}: TeamsMetricsPropsType) => {
    const [data, setData] = useState<TeamsMetricsState>(initialState);
    const tenantId = filters["id"];

    const getTeamsMetrics = async (
        filters,
    ): Promise<KustoResponseType<string | number>> => {
        let queryParams = {};
        const levelParams = API.getQueryParamsForLevel(filters["level"]);
        queryParams["id"] = tenantId ?? "";
        queryParams["date"] = metricFilters["Date"];
        queryParams = { ...levelParams, ...queryParams };
        const subWorkload = Object.values(TeamsSubWorkload).includes(app)
            ? app
            : TeamsSubWorkload.MEETING;
        const teamsProductInsightsMetrics = await API.getKustoResponse({
            queryName: `teams${subWorkload}ProductInsights`,
            platform: Workload.TEAMS,
            queryParams,
        });

        return teamsProductInsightsMetrics?.data;
    };

    const getPayloadData = async (filters: {}) => {
        setData((data) => {
            return {
                ...data,
                loading: true,
            };
        });

        try {
            const response: KustoResponseType<string | number> =
                await getTeamsMetrics(filters);
            const formattedResponse = formatTeamsMetrics(response, app);

            setData({
                payload: formattedResponse,
                loading: false,
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "TeamsAppMetricsFetchFailed",
                ),
                {
                    message: "Kusto Query failed for Teams App Metrics",
                    status: error?.errorResponse?.status,
                    query: error?.errorResponse?.config?.data,
                },
            );
            setData({
                payload: {},
                loading: false,
            });
        }
    };
    useEffect(() => {
        getPayloadData(filters);

        // ADO 7955411: useCallback refactor on getPayloadData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    switch (app) {
        case TeamsSubWorkload.MEETING:
            return (
                <>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item>
                            <Latency
                                filters={filters}
                                metricFilters={metricFilters}
                            />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">
                                Meeting Duration (in mins)
                            </h2>
                            <MonthlyTrend payload={data.payload["duration"]} />
                        </Stack.Item>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">Platform</h2>
                            <MonthlyTrend payload={data.payload["platform"]} />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">
                                Participant Count
                            </h2>
                            <MonthlyTrend
                                payload={data.payload["participantCount"]}
                            />
                        </Stack.Item>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">Session Quality</h2>
                            <CustomPieChart
                                payload={data.payload["sessionQuality"]}
                            />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item grow="initial">
                            <h2 className="commonPageBlockTitle">Modality</h2>
                            <CustomBarChart
                                payload={data.payload["modality"]}
                                keys={["Percent"]}
                                fill="#d6604d"
                                width={500}
                                height={300}
                            />
                        </Stack.Item>
                    </Stack>
                </>
            );
        // TODO: Add Chat Sender and Chat Reader metrics:
        // Backlog Item: 6394744
        case TeamsSubWorkload.CHAT:
            return (
                <>
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">Chat Type</h2>
                            <MonthlyTrend payload={data.payload["chatType"]} />
                        </Stack.Item>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">
                                Feature Usage by User%
                            </h2>

                            <MonthlyTrend payload={data.payload["feature"]} />
                        </Stack.Item>
                    </Stack>
                    <div className="break" />
                    <Stack horizontal tokens={horizontalStackTokens}>
                        <Stack.Item styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">OS</h2>

                            <CustomPieChart payload={data.payload["os"]} />
                        </Stack.Item>
                        <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                            <h2 className="commonPageBlockTitle">Platform</h2>
                            <MonthlyTrend payload={data.payload["platform"]} />
                        </Stack.Item>
                    </Stack>
                </>
            );
        default:
            return <div />;
    }
};
