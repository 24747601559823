import React from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { TopTrendingSignalProps, TopTrendingSignal } from "pages/MTR/types";

export const TopTrendingSignals = ({ payload, loading }: TopTrendingSignalProps) => {
    const data: TopTrendingSignal[] = [];

    if (!loading && payload.rows.length > 0) {
        payload.rows.forEach((element) => {
            const platform = String(element[0]);
            const signal = String(element[1]);
            const app = String(element[2]);
            const percentWithIssues = Math.round(Number(element[5]));

            // Aggregate Win32 Currency signals for the different apps into one Win32 Office Currency signal
            if (platform === "Win32" && signal === "Currency") {
                if (data.some((element) => element.App === "Office")) return;
                data.push({
                    Platform: platform,
                    Signal: signal,
                    App: "Office",
                    PercentWithIssues: percentWithIssues,
                });
            } else {
                data.push({
                    Platform: platform,
                    Signal: signal,
                    App: app,
                    PercentWithIssues: percentWithIssues,
                });
            }
        });
    }

    return (
        <div className="commonPageBlock">
            <Stack>
                <p className="commonPageBlockTitle">Trending Signals this month</p>
            </Stack>

            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <Stack>
                    <p className="topTrendingSignalsOverview">
                        Below are the top signals across Office endpoints that
                        impacted Tenant experience for Cross Platform S500 unhealthy
                        tenants
                    </p>
                    <Stack.Item className="topTrendingSignals">
                        {data.map((val, index) => (
                            <p key={index} className="text">
                                {val.PercentWithIssues}% unhealthy tenants have{" "}
                                {val.Platform} {val.App} {val.Signal} issues
                            </p>
                        ))}
                    </Stack.Item>
                </Stack>
            )}
        </div>
    );
};
