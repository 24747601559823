import { BootScenario, PerfAggregate } from "pages/Performance/types";
import { SLAs } from "utils/Constants";
import { ContactType, LinkParameters } from "utils/types";

export const FeedbackLink: string = "https://aka.ms/oxotenantinsightsfeedback";

export const DefaultIncidentLink: string = "https://aka.ms/insightsincident";

export const CUSTOMER_INSIGHTS_BASE_PATH: string =
    "https://customerinsights.microsoft.com";

export enum InsightsHeaders {
    InsightsNav = "InsightsNav",
    ShellHeader = "ShellHeader",
}

enum InsightsRoutePaths {
    AddIns = "AddIns",
    AddInPerformance = "AddInPerformance",
    AddInVersionPerformance = "AddInVersionPerformance",
    Cohort = "Cohort",
    CohortDeepDive = "CohortDeepDive",
    Collab = "Collab",
    Consumer = "Consumer",
    ConsumerCAMAU = "ConsumerCAMAU",
    CrossPlatformOverview = "CrossPlatformOverview",
    Day0Funnel = "Day0Funnel",
    GrowthDrilldown = "GrowthDrilldown",
    GrowthScorecard = "GrowthScorecard",
    ERFM = "ERFM",
    ErrorLookup = "ErrorLookup",
    ExemptionPortal = "ExemptionPortal",
    InsightsLanding = "InsightsLanding",
    M365TenantInsights = "M365TenantInsights",
    Mac = "Mac",
    MacTenant = "MacTenant",
    MTR = "MTR",
    OfficeCopilotScorecard = "OfficeCopilotScorecard",
    Performance = "Performance",
    QuipSurveyFunnelDrillDown = "SurveyFunnelDrillDown",
    Reliability = "Reliability",
    SummarizedInsightsRequest = "SummarizedInsightsRequest",
    Teams = "Teams",
    TeamsTenant = "TeamsTenant",
    Tenant = "Tenant",
    Web = "Web",
    WebTenant = "WebTenant",
    Win32 = "Win32",
    Win32PerfDrilldown = "Win32PerfDrilldown",
    Win32PerfInsights = "Win32PerfInsights",
    Win32Tenant = "Win32Tenant",
    Copilot = "Copilot",
    CopilotTenant = "CopilotTenant",
}

export interface InsightsRoute {
    path: string;
    header?: InsightsHeaders;
    shouldShowConfidentialityBanner?: boolean;
}

export const InsightsRoutes: {
    [key in keyof typeof InsightsRoutePaths]: InsightsRoute;
} = {
    AddIns: {
        path: "/addins",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    AddInPerformance: {
        path: "/addInPerformance",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    AddInVersionPerformance: {
        path: "/addInVersionPerformance",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Cohort: {
        path: "/cohort",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    CohortDeepDive: {
        path: "/cohortDeepDive",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Collab: {
        path: "/collab",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Consumer: {
        path: "/consumer",
        header: InsightsHeaders.InsightsNav,
    },
    ConsumerCAMAU: {
        path: "/consumercamau",
        header: InsightsHeaders.InsightsNav,
    },
    CrossPlatformOverview: {
        path: "/",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Day0Funnel: {
        path: "/day0funnel",
        header: InsightsHeaders.InsightsNav,
    },
    GrowthDrilldown: {
        path: "/growthdrilldown",
        header: InsightsHeaders.InsightsNav,
    },
    GrowthScorecard: {
        path: "/growthscorecard",
        header: InsightsHeaders.InsightsNav,
    },
    ERFM: { path: "/erfm", header: InsightsHeaders.InsightsNav },
    ErrorLookup: {
        path: "/errorLookup",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    ExemptionPortal: {
        path: "/portal",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    InsightsLanding: { path: "/opginsights", header: InsightsHeaders.InsightsNav },
    M365TenantInsights: {
        path: "/m365tenantinsights",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Mac: {
        path: "/mac",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    MacTenant: {
        path: "/mactenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    MTR: {
        path: "/mtr",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    OfficeCopilotScorecard: {
        path: "/officecopilotscorecard",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Performance: {
        path: "/performance",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    QuipSurveyFunnelDrillDown: {
        path: "/surveyfunneldrilldown",
        header: InsightsHeaders.InsightsNav,
    },
    Reliability: {
        path: "/reliability",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    SummarizedInsightsRequest: {
        path: "/summarizedinsightsrequest",
        header: InsightsHeaders.ShellHeader,
    },
    Teams: {
        path: "/teams",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    TeamsTenant: {
        path: "/teamstenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Tenant: {
        path: "/tenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Web: {
        path: "/web",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    WebTenant: {
        path: "/webtenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Win32: {
        path: "/win32",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Win32PerfDrilldown: {
        path: "/win32PerfDrilldown",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Win32PerfInsights: {
        path: "/win32PerfInsights",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Win32Tenant: {
        path: "/win32tenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    Copilot: {
        path: "/copilot",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
    CopilotTenant: {
        path: "/copilottenant",
        header: InsightsHeaders.ShellHeader,
        shouldShowConfidentialityBanner: true,
    },
};

export const InfoLinks = {
    ModernizationScore:
        "//microsoft.sharepoint.com/sites/knowledgecenter/SitePages/MODERNIZATION-SCORE.aspx",
    ACEHealthScore:
        "//msit.powerbi.com/groups/me/reports/541899b9-6b15-4715-9903-fdbd97ac768f/ReportSection8231b114051fae4cfd2c",
    NetworkScore:
        "//techcommunity.microsoft.com/t5/microsoft-365-blog/network-connectivity-in-the-microsoft-365-admin-center-preview/ba-p/1696648",
    NetworkScorePowerApp:
        "//apps.powerapps.com/play/7b570b0d-b103-42d7-b533-40e807a03414?OMSID=",
    ProductSupportTickets: "https://aka.ms/imeu",
    AdminCenterAddins:
        "//docs.microsoft.com/en-us/deployoffice/admincenter/microsoft-365-apps-health#add-in-health",
    AdmiCenterAdvisories:
        "//docs.microsoft.com/en-us/deployoffice/admincenter/microsoft-365-apps-health#all-advisories",
    SecurityVulnerabilities:
        "//learn.microsoft.com/en-us/officeupdates/microsoft365-apps-security-updates",
    ConsumerGrowthScorecardNotes:
        "//office.visualstudio.com/OC/_wiki/wikis/EH%20Fuel%20Insights/80361/Growth-Scorecard-Notes",
};

export const WikiLinks = {
    Access: "https://aka.ms/tiwiki/access",
    ASHA: "https://aka.ms/tiwiki/ashatsg",
    CohortAnalysis: "https://aka.ms/tiwiki/cohorts",
    Collab: "https://aka.ms/tiwiki/web/collab",
    ConsumerGrowthScorecard: "https://aka.ms/consumerwiki/growthscorecard",
    ConsumerWiki: "https://aka.ms/consumerwiki/consumer",
    Default: "https://aka.ms/oxotenantinsightswiki",
    ERFM: "https://office.visualstudio.com/OC/_wiki/wikis/EH Fuel Insights/89395/eRFM-Insights",
    MacScores: "https://aka.ms/oxotenantinsightsmacscores",
    MainWiki: "https://aka.ms/tiwiki/web",
    OfficeCopilotScorecard: "https://aka.ms/OfficeAIScorecardWiki",
    ProductSupportTickets:
        "https://www.owiki.ms/wiki/Common_Metric_Definitions#Product_Support_Tickets",
    Reliability: "https://aka.ms/tiwiki/reliability",
    Scores: "https://aka.ms/oxotenantinsightsScores",
    WebScores: "https://aka.ms/oxotenantinsightswebscores",
    Win32Scores: "https://aka.ms/oxotenantinsightswin32scores",
    Copilot:
        "https://office.visualstudio.com/OC/_wiki/wikis/EH%20Fuel%20Insights/98644/Copilot-Metrics-for-MTR-Score",
    CopilotScores:
        "https://office.visualstudio.com/OC/_wiki/wikis/EH%20Fuel%20Insights/102347/Copilot-Scoring-Logic",
    Intents: "https://go.microsoft.com/fwlink/?linkid=2275969",
};

export const pageWikiLinkMap: Map<string, string> = new Map([
    ["/asha", WikiLinks.ASHA],
    [InsightsRoutes.Cohort.path, WikiLinks.CohortAnalysis],
    [InsightsRoutes.CohortDeepDive.path, WikiLinks.ConsumerWiki],
    [InsightsRoutes.Collab.path, WikiLinks.Collab],
    [InsightsRoutes.Consumer.path, WikiLinks.ConsumerGrowthScorecard],
    [InsightsRoutes.ERFM.path, WikiLinks.ERFM],
    [InsightsRoutes.GrowthScorecard.path, WikiLinks.ConsumerGrowthScorecard],
    [InsightsRoutes.Mac.path, WikiLinks.MacScores],
    [InsightsRoutes.OfficeCopilotScorecard.path, WikiLinks.OfficeCopilotScorecard],
    [InsightsRoutes.Reliability.path, WikiLinks.Reliability],
    [InsightsRoutes.Web.path, WikiLinks.MainWiki],
    [InsightsRoutes.Win32.path, WikiLinks.Win32Scores],
    [InsightsRoutes.Copilot.path, WikiLinks.Copilot],
    [InsightsRoutes.CopilotTenant.path, WikiLinks.Copilot],
]);

export const pageContactMap: Map<string, ContactType> = new Map([
    [
        InsightsRoutes.OfficeCopilotScorecard.path,
        {
            name: "OGA Copilot Scorecard",
            email: "mailto:copilotscorecard@microsoft.com",
        },
    ],
]);

export const pageIncidentLinkMap: Map<string, string> = new Map([
    [
        InsightsRoutes.OfficeCopilotScorecard.path,
        "https://aka.ms/insightscopilotincident",
    ],
]);

export const pageSLAMap: Map<string, string> = new Map([
    [InsightsRoutes.GrowthDrilldown.path, SLAs.Consumer],
    [InsightsRoutes.Consumer.path, SLAs.Consumer],
    [InsightsRoutes.ERFM.path, SLAs.ERFM],
    [InsightsRoutes.OfficeCopilotScorecard.path, SLAs.Copilot],
]);

const performanceInsights = (aggregate: PerfAggregate, scenario: BootScenario) => {
    return (params: LinkParameters) =>
        `${InsightsRoutes.Win32PerfInsights.path}?id=${params.id}&level=${params.level}&date=${params.date}&scenario=${scenario}&valuesToShow=${aggregate}`;
};

export const AppMetricsLinkMapping = {
    Per_UsersWithError: {
        toolTip: "Reliability Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `${InsightsRoutes.Reliability.path}?id=${params.id}&level=${params.level}&date=${params.date}`,
    },
    Open: {
        toolTip: "ASHA Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `//asha.microsoft.com/tenant?Id=${params.id}&Level=${
                params.level
            }&Month=${params.date}&Host=${
                params.host ? params.host : "SharePoint Online"
            }${params.uiHost ? "&UIHost=" + params.uiHost : ""}`,
    },
    Save: {
        toolTip: "ASHA Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `//asha.microsoft.com/tenant?Id=${params.id}&Level=${
                params.level
            }&Month=${params.date}&Host=${
                params.host ? params.host : "SharePoint Online"
            }${params.uiHost ? "&UIHost=" + params.uiHost : ""}`,
    },
    "EUPL P50": {
        toolTip: "Performance Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `${InsightsRoutes.Performance.path}?id=${params.id}&level=${params.level}&date=${params.date}`,
    },
    "EUPL P95": {
        toolTip: "Performance Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `${InsightsRoutes.Performance.path}?id=${params.id}&level=${params.level}&date=${params.date}`,
    },
    Collaborators: {
        toolTip: "Collab Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) =>
            `${InsightsRoutes.Collab.path}?id=${params.id}&level=${params.level}&date=${params.date}`,
    },
    Watson: {
        toolTip: "Reliability Drilldown",
        tpidDisabled: true,
        getMetricUrl: (params: LinkParameters) =>
            `//metrical.azurewebsites.net/TenantDrilldown?AppPlatforms=All&Applications=All&Channels=All&AudienceGroups=All&Forks=All&Versions=All&tenantId=${params.id}`,
    },
    UAE: {
        toolTip: "Reliability Drilldown",
        tpidDisabled: true,
        getMetricUrl: (params: LinkParameters) =>
            `//metrical.azurewebsites.net/TenantDrilldown?AppPlatforms=All&Applications=All&Channels=All&AudienceGroups=All&Forks=All&Versions=All&tenantId=${params.id}`,
    },
    P95: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("P95Duration", "Boot-Overall"),
    },
    BootThreshold: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("PercentBelowThreshold", "Boot-Overall"),
    },
    BootLaunchP95: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("P95Duration", "Boot-Launch"),
    },
    BootLaunchThreshold: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("PercentBelowThreshold", "Boot-Launch"),
    },
    BootFileP95: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("P95Duration", "Boot-File"),
    },
    BootFileThreshold: {
        toolTip: "Performance Drilldown",
        getMetricUrl: performanceInsights("PercentBelowThreshold", "Boot-File"),
    },
    OCV: {
        toolTip: "Feedback Drilldown",
        tpidDisabled: true,
        getMetricUrl: (params: LinkParameters) =>
            `//ocv.microsoft.com/#/discover/?q=TenantId:${params.id}&allAreas&searchtype=OcvItems&relDateType=day&offset=-29&vs=%7B%22view%22:%22card%22%7D`,
    },
    "%in-service": {
        toolTip: "Currency Drilldown",
        tpidDisabled: true,
        getMetricUrl: (params: LinkParameters) =>
            `//aka.ms/oxotenantcurrencylookup?filter=TenantLeaderBoard%2FTenantId%20eq%20%27${params.id}%27`,
    },
    NPS: {
        toolTip: "NPS Drilldown",
        tpidDisabled: false,
        getMetricUrl: (params: LinkParameters) => {
            return params.level === "TenantId"
                ? `//sensei.microsoft.com/Reports/StrategicCustomersNPS/Tenants?pageName=ReportSection8df38b7ca8935160020b&tid=${params.id}&platform=${params.npsPlatformName}&surveyName=App%20EndUser%20NPS`
                : `//sensei.microsoft.com/Reports/StrategicCustomersNPS/Tenants?pageName=ReportSection6d30c52e77020900a368&tpid=${params.id}&platform=${params.npsPlatformName}&surveyName=App%20EndUser%20NPS`;
        },
    },
    CH_Rate: {
        toolTip: "Performance Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSection8779f692784d6c335569",
    },
    FQ_Rate: {
        toolTip: "Performance Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSection8779f692784d6c335569",
    },
    "% OS Currency": {
        toolTip: "Currency Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSection161cbfdccd01250785c0",
    },
    "% Build Currency": {
        toolTip: "Currency Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSection161cbfdccd01250785c0",
    },
    Perf_P95: {
        toolTip: "Performance Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSectiona700511ea0ac51d72a1e",
    },
    Mac_BootThreshold: {
        toolTip: "Performance Drilldown",
        tpidDisabled: true,
        getMetricUrl: () =>
            "//msit.powerbi.com/groups/me/reports/5918ada1-09f8-4f80-9f73-bfc6265df1b2/ReportSectiona700511ea0ac51d72a1e",
    },
};
