import React, { useCallback, useMemo } from "react";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { curveMonotoneX } from "@visx/curve";
import { localPoint } from "@visx/event";
import { GridRows, GridColumns } from "@visx/grid";
import { Group } from "@visx/group";
import { MarkerCircle, MarkerLine, MarkerX } from "@visx/marker";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Bar, Line, LinePath } from "@visx/shape";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { bisector, extent } from "@visx/vendor/d3-array";
import { timeFormat, timeParse } from "@visx/vendor/d3-time-format";
import {
    DataSchema,
    SeriesSchema,
    TooltipSchema,
} from "components/CustomComponents/TrendChart/types";
import { isNullOrEmpty } from "utils/Helpers";

// accessors
const val = (d: DataSchema) => d.MetricValue;
const parseTime = timeParse("%Y-%m-%d");
const date = (d: DataSchema) => parseTime(d.Date);

const margin = { top: 40, right: 30, bottom: 50, left: 40 };
const background = "#f3f3f3";
const bisectDate = bisector((d: DataSchema) => new Date(d.Date)).left;

const getTooltipData = (data, date) => {
    return data
        .filter((d) => d.Date === date)
        .sort((a, b) => b.MetricValue - a.MetricValue);
};

const TrendChart = ({
    width,
    height,
    series,
    id,
}: {
    width: number;
    height: number;
    series: SeriesSchema[];
    id: string;
}) => {
    const data: DataSchema[] = useMemo(() => {
        let tmp: DataSchema[] = [];
        series.forEach((entry) => tmp.push(...entry.Data));
        return tmp;
    }, [series]);

    const xMax = width - margin.left - margin.right - 20;
    const yMax = height - margin.top - margin.bottom;

    const xScale = useMemo(() => {
        return scaleTime({
            domain: extent(data?.map(date)),
            range: [0, xMax],
            nice: true,
        });
    }, [data, xMax]);

    const yScale = useMemo(() => {
        return scaleLinear<number>({
            // eslint-disable-next-line no-unsafe-optional-chaining
            domain: [Math.min(...data?.map(val)), Math.max(...data?.map(val))],
            range: [yMax, 0],
            nice: true,
        });
    }, [yMax, data]);

    const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
        useTooltip<TooltipSchema>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // use TooltipWithBounds
        detectBounds: true,
        // when tooltip containers are scrolled, this will correctly update the Tooltip position
        scroll: true,
    });

    const handleTooltip = useCallback(
        (event) => {
            const { x } = localPoint(event) || { x: 0 };
            const x0 = xScale.invert(x - margin.left);
            const index = bisectDate(data, x0, 1);
            const d0 = data[index - 1];
            const d1 = data[index];
            let d = d0;
            if (d1) {
                d =
                    x0.valueOf() - date(d0).valueOf() >
                    date(d1).valueOf() - x0.valueOf()
                        ? d1
                        : d0;
            }
            showTooltip({
                tooltipData: { Date: d.Date, Data: getTooltipData(data, d.Date) },
                tooltipLeft: x,
                tooltipTop: yScale(val(d)),
            });
        },
        [showTooltip, yScale, xScale, data],
    );

    const tooltipStyles = {
        ...defaultStyles,
        borderRadius: 20,
        color: "#323130",
        width: 200,
        height: 40,
        padding: 4,
    };

    return (
        series && (
            <div>
                <svg ref={containerRef} width={width} height={height}>
                    <rect width={width} height={height} fill={background} rx={22} />
                    <Group left={margin.left + 20} top={margin.top}>
                        <GridRows
                            scale={yScale}
                            width={xMax}
                            height={yMax}
                            stroke="#e0e0e0"
                        />
                        <GridColumns
                            scale={xScale}
                            width={xMax}
                            height={yMax}
                            stroke="#e0e0e0"
                            strokeWidth={1.5}
                            tickValues={data.map((d) => parseTime(d.Date))}
                        />
                        <AxisBottom
                            top={yMax}
                            scale={xScale}
                            tickValues={data.map((d) => parseTime(d.Date))}
                            tickFormat={timeFormat("%b %d")}
                            tickLabelProps={() => ({
                                fontSize: "12px",
                                fontWeight: 500,
                                textAnchor: "middle",
                            })}
                        />
                        <AxisLeft
                            scale={yScale}
                            tickLabelProps={() => ({
                                dx: "-0.25em",
                                dy: "0.25em",
                                fontSize: "12px",
                                fontWeight: 500,
                                textAnchor: "end",
                                alignmentBaseline: "middle",
                            })}
                        />
                        {series.map((entry, idx) => {
                            return (
                                <g key={idx}>
                                    <MarkerCircle
                                        id={`${id}-marker-circle-${idx}`}
                                        fill={entry.Color}
                                        size={2}
                                        refX={2}
                                    />
                                    <MarkerX
                                        id={`${id}-marker-x-${idx}`}
                                        stroke={entry.Color}
                                        size={10}
                                        strokeWidth={2}
                                        markerUnits="userSpaceOnUse"
                                    />
                                    <MarkerLine
                                        id={`${id}-marker-line-${idx}`}
                                        fill={entry.Color}
                                        size={10}
                                        strokeWidth={1}
                                    />
                                </g>
                            );
                        })}
                        {series.map((entry, idx) => {
                            const marker = isNullOrEmpty(entry.Marker)
                                ? `url(#${id}-marker-circle-${idx})`
                                : `url(#${id}-marker-${entry.Marker}-${idx})`;
                            return entry.Data.length === 1 ? (
                                <line
                                    x1={xScale(date(entry.Data[0])) ?? 0}
                                    y1={yScale(val(entry.Data[0])) ?? 0}
                                    x2={xScale(date(entry.Data[0])) ?? 0}
                                    y2={yScale(val(entry.Data[0])) ?? 0}
                                    markerStart={marker}
                                    strokeWidth={1.5}
                                    key={idx}
                                />
                            ) : (
                                <LinePath
                                    curve={curveMonotoneX}
                                    data={entry.Data}
                                    x={(d) => xScale(date(d)) ?? 0}
                                    y={(d) => yScale(val(d)) ?? 0}
                                    stroke={entry.Color}
                                    strokeWidth={1.5}
                                    strokeOpacity={0.8}
                                    markerMid={marker}
                                    markerStart={marker}
                                    markerEnd={marker}
                                    defined={(d) => !isNullOrEmpty(d.MetricValue)}
                                    key={idx}
                                />
                            );
                        })}
                        <Bar
                            x={0}
                            y={0}
                            width={xMax}
                            height={yMax}
                            fill="transparent"
                            rx={14}
                            onTouchStart={handleTooltip}
                            onTouchMove={handleTooltip}
                            onMouseMove={handleTooltip}
                            onMouseLeave={() => hideTooltip()}
                        />
                    </Group>
                    {tooltipData && (
                        <g>
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: yMax + margin.top }}
                                stroke={"gray"}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="4,2"
                            />
                        </g>
                    )}
                    {tooltipData &&
                        tooltipData.Data.map((d, idx) => {
                            return (
                                !isNullOrEmpty(val(d)) && (
                                    <g key={idx}>
                                        <circle
                                            cx={tooltipLeft}
                                            cy={yScale(val(d)) + margin.top - 2}
                                            r={4}
                                            fill="black"
                                            fillOpacity={0.1}
                                            stroke="black"
                                            strokeOpacity={0.1}
                                            strokeWidth={2}
                                            pointerEvents="none"
                                        />
                                    </g>
                                )
                            );
                        })}
                </svg>
                {tooltipData && (
                    <div>
                        <TooltipInPortal
                            key={Math.random()} // needed for bounds to update correctly
                            left={tooltipLeft}
                            top={tooltipTop}
                            style={{
                                ...tooltipStyles,
                                height: (tooltipData.Data?.length + 1) * 30,
                            }}
                        >
                            <p>Date: {tooltipData.Date}</p>
                            {tooltipData.Data.map((t, idx) => {
                                return (
                                    <p key={idx}>
                                        {t["AppName"]} - {t.MetricValue}
                                    </p>
                                );
                            })}
                        </TooltipInPortal>
                    </div>
                )}
            </div>
        )
    );
};

export default TrendChart;
