import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { FunnelAshaInsights } from "components/ConsumerDay0FunnelComponents/FunnelAshaInsights";
import { SessionAndAbandomentCharts } from "components/ConsumerDay0FunnelComponents/FunnelInsights/SessionsAndAbandonmentCharts";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderRight,
    CardHeaderTitle,
} from "components/CustomComponents/Card/Card";
import EmtpyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredStackCard } from "components/CustomComponents/ShimmeredWrapper/ShimmeredStackCard";
import { initialState } from "components/ConsumerDay0FunnelComponents/FunnelInsights/constants";
import { KustoResponseType } from "pages/commonTypes";
import {
    formatDroppedInsights,
    formatInsights,
    getFunnelInsights,
} from "pages/ConsumerDay0Funnel/ApiHandler";
import { FunnelStage } from "pages/ConsumerDay0Funnel/constants";
import {
    FunnelInsightsState,
    FunnelInsightsProps,
} from "pages/ConsumerDay0Funnel/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { CONSUMERDAY0_MESSAGES } from "utils/Messages";
import "components/ConsumerDay0FunnelComponents/FunnelInsights/style.css";

export const FunnelInsights = ({
    filters,
    stage,
    droppedStage,
    dropOff,
}: FunnelInsightsProps) => {
    const [data, setData] = useState<FunnelInsightsState>(initialState);

    useEffect(() => {
        const getPayloadData = async () => {
            setData((data) => {
                return {
                    ...data,
                    loading: true,
                };
            });

            try {
                const response: KustoResponseType<string | number> =
                    await getFunnelInsights(filters);
                const formattedResponse = formatInsights(response);
                const formattedDroppedDeltaResponse =
                    formatDroppedInsights(response);

                setData({
                    payload: formattedResponse,
                    loading: false,
                    droppedPayload: formattedDroppedDeltaResponse,
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerDay0FunnelInsightsFetchFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer Day 0 Funnel Insights",
                        filters,
                    },
                    e,
                );
                setData({
                    payload: {},
                    loading: false,
                    droppedPayload: {},
                });
            }
        };
        getPayloadData();
    }, [filters]);

    const tabDisabled = { disabled: true, style: { color: "grey" } };

    return (
        <Card>
            <CardHeader>
                <CardHeaderTitle>Funnel Insights</CardHeaderTitle>
                {!data.loading && Object.keys(data.payload).length > 0 && (
                    <CardHeaderRight>
                        <div className="funnelFilterStatusContainer">
                            <span className="selectedFilter">
                                <strong>Referrer: </strong>
                                {filters.referrer}
                            </span>
                            <span className="selectedFilter">
                                <strong>Stage: </strong>
                                {stage}
                            </span>
                            <span className="selectedFilter">
                                <strong>DocumentType: </strong>
                                {filters.scenario}
                            </span>
                        </div>
                    </CardHeaderRight>
                )}
            </CardHeader>
            <CardBody>
                {data.loading ? (
                    <ShimmeredStackCard minHeight={150} />
                ) : Object.keys(data.payload).length === 0 &&
                  Object.keys(data.droppedPayload).length === 0 ? (
                    <EmtpyTableContainer minHeight={150} center />
                ) : (
                    <div id="day0FunnelInsights">
                        <Pivot
                            className="day0FunnelInsightsPivots"
                            aria-label="Funnel Insights"
                        >
                            <PivotItem headerText="Sessions">
                                <SessionAndAbandomentCharts
                                    updatedPayload={data.payload[stage]}
                                    legendFormatter={() => {
                                        return "Stage sessions distribution";
                                    }}
                                />
                            </PivotItem>
                            <PivotItem
                                headerText="Abandonments"
                                headerButtonProps={
                                    stage === FunnelStage.FirstSessions
                                        ? tabDisabled
                                        : {}
                                }
                            >
                                <SessionAndAbandomentCharts
                                    updatedPayload={
                                        data.droppedPayload[droppedStage]
                                    }
                                    legendFormatter={() => {
                                        return "Drop % from previous stage";
                                    }}
                                    tabNote={
                                        CONSUMERDAY0_MESSAGES.ABANDONMENT_INSIGHTS
                                    }
                                />
                            </PivotItem>
                            <PivotItem
                                headerText="Fundamentals"
                                headerButtonProps={
                                    stage === FunnelStage.FirstSessions
                                        ? tabDisabled
                                        : {}
                                }
                            >
                                <div className="day0FunnelInsightsPivots">
                                    <FunnelAshaInsights
                                        filters={filters}
                                        dropOff={dropOff}
                                    />
                                </div>
                            </PivotItem>
                        </Pivot>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};
