import React, { useEffect, useState } from "react";
import { ChoiceGroup, IconButton, Link, Stack } from "@fluentui/react";
import { PSSChartModal } from "components/TenantDrilldownComponents/PSS/PSSChartModal";
import { PSSInfoState } from "components/TenantDrilldownComponents/PSS/Types";
import { platformsConfig } from "config/PlatformConfig";
import { horizontalStackTokens } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { InfoLinks, WikiLinks } from "utils/Links";
import { PSS_HEALTH_SCORE_MESSAGES } from "utils/Messages";
import {
    getPSSInfo,
    fetchPSSChartData,
    formatPSSChartData,
    choicesForChoiceGroup,
    choiceGroupStyles,
} from "./PSSChartHelper";

const initialState: PSSInfoState = {
    payload: [],
    loading: true,
    showNewTickets: true,
};

export const PSSChart = ({ filters, platform }) => {
    const appsList = platformsConfig[platform].appsList;
    const tenantId: string = filters["id"];
    const list = [];
    appsList.forEach((app) => {
        list.push({ color: appStyles[app]["color"], value: app, key: app });
    });

    const getPayloadData = async (filters: {}, showNewTickets: boolean) => {
        setData((data) => {
            return {
                ...data,
                loading: true,
                showNewTickets,
            };
        });
        const customProperties = {};
        try {
            const response = await getPSSInfo(filters, showNewTickets, appsList);
            customProperties["responseCode"] = response.status;
            const pssDataJson = response?.data;
            customProperties["responseJson"] = pssDataJson;
            const pssChartData = fetchPSSChartData(pssDataJson);
            customProperties["pssChartData"] = pssChartData;
            const formattedpssChartData = formatPSSChartData(pssChartData);
            setData({
                payload: formattedpssChartData,
                loading: false,
                showNewTickets,
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "PSSChartMetricsFetchOrProcessingException",
                ),
                {
                    message:
                        "Exception while fetching/processing PSS charts data in Product drill down page",
                    customProperties,
                },
                error,
            );
            setData({
                payload: null,
                loading: false,
                showNewTickets,
            });
        }
    };

    const [data, setData] = useState<PSSInfoState>(initialState);

    useEffect(() => {
        if (tenantId) {
            getPayloadData(filters, true);
        }

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const toggleChart = () => {
        getPayloadData(filters, !data.showNewTickets);
    };

    return (
        <>
            <h2 className="commonPageBlockTitle">
                Product Support Tickets
                <Link href={WikiLinks.ProductSupportTickets} target="_blank">
                    <IconButton
                        iconProps={{ iconName: "Unknown" }}
                        title={PSS_HEALTH_SCORE_MESSAGES.READ_MORE}
                        ariaLabel={PSS_HEALTH_SCORE_MESSAGES.READ_MORE}
                    />
                </Link>
                <Link href={InfoLinks.ProductSupportTickets} target="_blank">
                    <IconButton
                        iconProps={{ iconName: "PowerAppsLogo" }}
                        title={PSS_HEALTH_SCORE_MESSAGES.DRILLDOWN}
                        ariaLabel={PSS_HEALTH_SCORE_MESSAGES.DRILLDOWN}
                    />
                </Link>
            </h2>
            <Stack horizontal tokens={horizontalStackTokens}>
                <ChoiceGroup
                    defaultSelectedKey="NewTickets"
                    options={choicesForChoiceGroup}
                    onChange={toggleChart}
                    required={true}
                    styles={choiceGroupStyles}
                />
            </Stack>
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item>
                    <PSSChartModal
                        show={true}
                        data={data.payload}
                        appsList={appsList}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};
