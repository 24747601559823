import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@fluentui/react";
import DashboardSearchBox from "components/InsightsComponents/DashboardSearchBox";
import HeaderButtons from "components/InsightsComponents/HeaderButtons";
import { isLandingPage } from "components/InsightsComponents/Helpers";
import { InsightsRoutes } from "utils/Links";

interface InsightsHeaderProps {
    setNavCollapsed: (navCollapsed: boolean) => void;
}

const InsightsHeader = ({ setNavCollapsed }: InsightsHeaderProps) => {
    const navigate = useNavigate();

    // Need this click handler to prevent the page from refreshing when navigating to the landing page
    const handleClickHeader = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate(e.target["pathname"]);
        setNavCollapsed(!isLandingPage());
    };

    return (
        <div className="insightsLandingHeader">
            <span className="insightsLandingHeaderText">
                <Link
                    href={InsightsRoutes.InsightsLanding.path}
                    underline={false}
                    onClick={handleClickHeader}
                >
                    OPG Insights
                </Link>
            </span>
            <div className="insightsLandingHeaderButtons">
                <DashboardSearchBox setNavCollapsed={setNavCollapsed} />
                {!isLandingPage() && <HeaderButtons />}
            </div>
        </div>
    );
};

export default InsightsHeader;
