import React from "react";
import { Shimmer } from "@fluentui/react";

export const ShimmeredCardBody = () => {
    return (
        <div style={{ width: "100%" }}>
            <Shimmer
                width="95%"
                style={{ marginBottom: "20px", marginTop: "20px" }}
            />
            <Shimmer width="95%" />
            <Shimmer
                width="95%"
                style={{ marginBottom: "20px", marginTop: "20px" }}
            />
            <Shimmer width="95%" />
            <Shimmer
                width="95%"
                style={{ marginBottom: "10px", marginTop: "20px" }}
            />
        </div>
    );
};
