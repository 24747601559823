export const teamsHealthChart: string = `
//teamsHealthChart
let currDate = GetCurrentTeamsDate();
let dt = iif(isempty("\${date}"), currDate, "\${date}");
let app = "\${application}";
let MAU = \${mauCountThreshold};
let level = "\${level}";
let cohort = "\${cohort}";
Teams_Signals
| where Signal == "Score" and Signal_Color != "Gray"  and SubWorkload == app
| join kind = inner 
    (StrategicTenants
    | where Date == currDate and Is\${cohort} == true
    | distinct \${levelColumnName}
    ) on \${levelColumnName}
| summarize count() by Signal_Color, Date
| project Date, Status = Signal_Color, Count = count_, Current = Date == dt
| order by Date asc, Status asc
`;
