import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

export const formatTenantTouchPoints = (
    tenantTouchPoinstJson: KustoResponseType<string | number>,
) => {
    const tenantTouchPoints = {};
    tenantTouchPoinstJson.Tables[0].Rows.forEach((element) => {
        const lastUpdatedDate = element[0] as string;
        if (!(lastUpdatedDate in tenantTouchPoints))
            tenantTouchPoints[lastUpdatedDate] = {};

        tenantTouchPoints[lastUpdatedDate]["TouchType"] = element[1];
        tenantTouchPoints[lastUpdatedDate]["ProgramName"] = element[2];
        tenantTouchPoints[lastUpdatedDate]["OwnerDisplayName"] = element[3];
        tenantTouchPoints[lastUpdatedDate]["ExecutiveUpdate"] = element[4];
    });

    const formattedTouchPoints = [];
    Object.keys(tenantTouchPoints).forEach((date) => {
        formattedTouchPoints.push({
            LastUpdatedDate: date,
            ...tenantTouchPoints[date],
        });
    });

    return formattedTouchPoints;
};

export const getTenantTouchPoints = async (
    filters,
): Promise<KustoResponseType<string>> => {
    let queryParams = {};
    const levelParams = API.getQueryParamsForLevel(filters["level"]);
    queryParams["id"] = filters["id"];
    queryParams = { ...levelParams, ...queryParams };

    const tenantTouchPointsData = await API.getKustoResponse({
        queryName: "tenantTouchPoints",
        platform: Workload.CROSSPLATFORM,
        queryParams,
    });

    return tenantTouchPointsData?.data;
};

export const getPayloadData = async (filters: {}, setData) => {
    setData((data) => {
        return {
            ...data,
            loading: true,
        };
    });

    try {
        const response: KustoResponseType<string | number> =
            await getTenantTouchPoints(filters);
        const formattedResponse = formatTenantTouchPoints(response);
        setData({
            payload: formattedResponse,
            loading: false,
        });
    } catch (error) {
        setData({
            payload: [],
            loading: false,
        });
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "TenantTouchPointsFetchOrProcessingFailed",
            ),
            {
                message: "Failed to fetch or format tenant touch points",
                filters,
            },
            error,
        );
    }
};
