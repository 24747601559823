export const webAvgCohortMetrics: string = `
// Web AvgCohortMetrics
StrategicTenants
| where \${levelColumnName} == "\${id}" and Date == "\${date}"
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov
| take 1
| project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
| project Cohorts = replace_string(Cohorts, "All;", "")  // We don't want to treat the "other cohort" any different
| project allcohorts = split(Cohorts,';')
| project iff(array_length( allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
| mv-expand Cohort = Column1
| project Cohort = tostring(Cohort)
| join kind=inner(
    CohortAverage
    | where Date == "\${date}" and Application == "\${app}" and UiHost == "All" and Level == "\${level}"
) on Cohort
| project 
        Cohort,
        MauRate = round(MauRate*100,2), 
        MeuRate = round(MeuRate*100, 2), 
        EngagedEditorsRate = round(EngagedEditorsRate*100, 2),  
        ReturningMauRate = round(ReturningMauRate*100, 2), 
        EUPLP95 = round(EUPL_P95, 2),
        EUPLP50 = round(EUPL_P50, 2),
        ACEFreeUsersRate = round(ACEFreeUsersRate*100, 2), 
        ICEFreeUsersRate = round(ICEFreeUsersRate*100, 2), 
        Feedback = round(Feedback_SubScore, 2), 
        OpenInClientRate = round(OpenInClientRate*100, 2)`;

export const webAvgCohortMetrics_V2: string = `
// Web AvgCohortMetrics V2
StrategicTenants
| where \${levelColumnName} == "\${id}" and Date == "\${date}"
| sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov
| take 1
| project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
| project Cohorts = replace_string(Cohorts, "All;", "")  // We don't want to treat the "other cohort" any different
| project allcohorts = split(Cohorts,';')
| project iff(array_length( allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
| mv-expand Cohort = Column1
| project Cohort = tostring(Cohort)
| join kind=inner(
    CohortAverage_V2
    | where Date == "\${date}" and Application == "\${app}" and UiHost == "All" and Level == "\${level}"
) on Cohort
| project 
        Cohort,
        MauRate = round(MauRate*100,2), 
        MeuRate = round(MeuRate*100, 2), 
        EngagedEditorsRate = round(EngagedEditorsRate*100, 2),  
        ReturningMauRate = round(ReturningMauRate*100, 2), 
        EUPLP95 = round(EUPL_P95, 2),
        EUPLP50 = round(EUPL_P50, 2),
        ASHA = round(ASHA, 2),
        Feedback = round(Feedback_SubScore, 2), 
        OpenInClientRate = round(OpenInClientRate*100, 2)`;
