import React from "react";
import { Icon, Link } from "@fluentui/react";
import { m365TilesData } from "pages/M365TenantInsights/M365TenantInsights";
import { appIconStyle } from "utils/commonStyles";
import { appStyles } from "utils/Constants";
import { InsightsRoutes } from "utils/Links";

const PlatformIcon = ({ level, id, source }) => {
    const platformTiles = [];
    m365TilesData
        .filter((platform) => platform.IsTenantDrillDownAvailable)
        .map((platform) => {
            const platformDataAvailable: boolean = source.includes(platform.Name);
            if (platformDataAvailable) {
                return platformTiles.push(
                    <li
                        key={`${platform.Name}TenantLink`}
                        className="platformCard"
                        data-testid="platformIconCard"
                    >
                        <Link
                            href={`${platform.TenantDrillDownLink}&level=${level}&id=${id}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ paddingRight: 5, color: "inherit" }}
                        >
                            <div className="platformIconContainer">
                                <Icon
                                    iconName={appStyles[platform.Name]["iconName"]}
                                    className={
                                        platform.ClassName === ""
                                            ? appIconStyle
                                            : platform.ClassName
                                    }
                                />
                            </div>
                            <span className="platformName">
                                {platform.DisplayName}
                            </span>
                        </Link>
                    </li>,
                );
            } else {
                return platformTiles.push(
                    <li
                        key={`${platform.Name}TenantLink`}
                        className="platformCard"
                        data-testid="platformIconCard"
                    >
                        <div className="platformIconContainer">
                            <Icon
                                iconName={appStyles[platform.Name]["iconName"]}
                                className={
                                    platform.ClassName === ""
                                        ? appIconStyle
                                        : platform.ClassName
                                }
                            />
                        </div>
                        <span className="platformName">{platform.DisplayName}</span>
                        <div className="m365ErrorMessage">
                            {level === "TenantId" ? "Tenant" : "Tpid"} data not
                            available
                        </div>
                    </li>,
                );
            }
        });

    if (level === "TenantId") {
        platformTiles.push(
            <li
                key={`SummarizedInsightsTenantLink`}
                className="platformCard"
                data-testid="platformIconCard"
            >
                <Link
                    href={`${InsightsRoutes.SummarizedInsightsRequest.path}?TenantId=${id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: 5, color: "inherit" }}
                >
                    <div className="platformIconContainer">
                        <img
                            className="summarizedInsightsIcon"
                            src="/img/botrick.png"
                            alt=""
                        />
                    </div>
                    <span className="platformName">Summarized Insights Report</span>
                </Link>
            </li>,
        );
    } else {
        platformTiles.push(
            <li
                key={`SummarizedInsightsTenantLink`}
                className="platformCard"
                data-testid="platformIconCard"
            >
                <div className="platformIconContainer">
                    <img
                        className="summarizedInsightsIcon"
                        src="/img/botrick.png"
                        alt=""
                    />
                </div>
                <span className="platformName">Summarized Insights Report</span>
                <br />
                <div className="m365ErrorMessage">Tpid data not available</div>
            </li>,
        );
    }

    return <>{platformTiles}</>;
};

const PlatformTenantLinks = (props) => {
    return (
        <ul className="platformTiles">
            <PlatformIcon {...props} />
        </ul>
    );
};

export default PlatformTenantLinks;
