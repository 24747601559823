export const areaFillColor = "#e9e9e9";

export const funnelWidth = "110";

export const funnelHeight = "500";

export const funnelActiveColor = "#313132";

export const funnelInactiveColor = "#696a6a";

export const funnelYCoordinate = "253.5";

export const funnelXCoordinate = "32.5";

export const funnelTextFill = "#e5e5e5";

export const strokeColor = "#ffffff";
