export const areaFillColor = "#e9e9e9";

export const funnelWidth = "100";

export const funnelHeight = "350";

export const funnelColor = "#696a6a";

export const funnelYCoordinate = "444";

export const funnelXCoordinate = "32.5";

export const funnelTextFill = "#ffffff";

export const strokeColor = "#ffffff";
