import {
    IStackStyles,
    IStackItemStyles,
    IStackTokens,
    IButtonStyles,
} from "@fluentui/react";
import { LineChartTooltipStyle } from "./commonTypes";

export const commonPageStyle: IStackStyles = {
    root: {
        minWidth: "200px",
        height: "100%",
        padding: "20px",
        marginLeft: "20px",
        marginRight: "20px",
    },
};

export const commonPageBlock: IStackStyles = {
    root: {
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "20px",
        borderRadius: "14px",
    },
};

export const halfPageBlock: IStackStyles = {
    root: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "20px",
        borderRadius: "14px",
        alignItems: "left",
        width: "48%",
        justifyContent: "left",
    },
};

export const splitCommonPageBlockIntoEqual: IStackItemStyles = {
    root: {
        alignItems: "center",
        width: "48%",
        justifyContent: "center",
    },
};

export const splitCommonPageBlockIntoThree: IStackItemStyles = {
    root: {
        width: "31%",
    },
};

export const headerBlock: IStackStyles = {
    root: {
        maxWidth: "615px",
        marginTop: "16px",
        marginBottom: "6px",
    },
};

export const statusBlock: IStackStyles = {
    root: {
        marginTop: "10px !important",
        marginBottom: "20px",
        border: "solid 2px #f3f2f1",
        padding: "3px",
        borderRadius: "14px",
    },
};

export const commonInnerBlock: IStackStyles = {
    root: {
        marginBottom: "20px",
        border: "dashed 2px #f3f2f1",
        marginTop: "20px !important",
        padding: "20px",
        borderRadius: "14px",
    },
};

export const horizontalStackTokens: IStackTokens = {
    childrenGap: 15,
    padding: "0 0 20px 0",
};
export const verticalStackTokens: IStackTokens = {
    childrenGap: 15,
    padding: "0 0 20px 0",
};
export const filterStackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 20,
};
export const chartMarginStyles = {
    left: 45,
    bottom: 5,
};
export const chartLegendStyles = {
    marginLeft: 20,
};
export const chartXAxisPaddingStyles = { left: 30, right: 30 };

export const drilldownChartHeight = 300;

export const buttonStyle: IButtonStyles = {
    rootChecked: {
        background: "hsl(180, 32%, 89%)",
    },
    root: {
        borderColor: "rgba(0, 0, 0, 0.125)",
        borderRadius: "15px",
    },
};

export const centerStackStyles: IStackStyles = {
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

export const equalStackItemsStyle: IStackItemStyles = {
    root: {
        width: "50%",
    },
};

export const detailsListStackItemStyle: IStackItemStyles = {
    root: {
        height: "270px",
    },
};

export const filterStyles: IStackStyles = {
    root: {
        paddingLeft: "20px",
        paddingBottom: "20px",
    },
};

export const lineChartTooltipStyles: LineChartTooltipStyle = {
    labelStyle: { fontWeight: "bold" },
    itemStyle: { color: "black" },
};

export const chartSizes = {
    height: 300,
    width: 450,
};

export const chartMargin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
};

export const searchCategoriesStyle: IStackStyles = {
    root: {
        minWidth: "300px",
        height: "100%",
        position: "sticky",
        top: 60,
        marginRight: "20px",
        marginLeft: "-10px",
    },
};

export const detailsRowStyle = () => {
    const style = {
        root: {
            fontSize: "12px",
            cursor: "pointer",
            selectors: {
                ":focus": {
                    background: "hsl(180, 32%, 89%)",
                },
            },
            textOverflow: "ellipsis",
        },
    };
    return style;
};
