import {
    DocTypeColors_Excel,
    DocTypeColors_PowerPoint,
    DocTypeColors_Word,
    FunnelStage,
} from "pages/ConsumerDay0Funnel/constants";
import {
    FlattenedScenarioDataType,
    FunnelStageDataType,
    FunnelStageStateDataType,
} from "pages/ConsumerDay0Funnel/types";
import { getStageDropOff, getStageIndex } from "utils/Helpers";

export const getKeys = (data: FlattenedScenarioDataType[]) => {
    return data?.length > 0
        ? Object.keys(data[0])?.filter((k) => k !== "stage" && k !== "index")
        : [];
};

export const getStageName = (dataPoint: FlattenedScenarioDataType) =>
    dataPoint?.stage;

export const getColorScaleByKeyAndApp = (key: string, application: string) => {
    const defaultColor = "#6e6f70";
    switch (application) {
        case "Word":
            return DocTypeColors_Word[key] ?? defaultColor;
        case "Excel":
            return DocTypeColors_Excel[key] ?? defaultColor;
        case "PowerPoint":
            return DocTypeColors_PowerPoint[key] ?? defaultColor;
        default:
            return defaultColor;
    }
};

export const getTotalFirstSessions = (data: any) => {
    let total: number = 0;
    for (let index in data) {
        total = total + data[index]["value"];
    }

    return total;
};

export const flattenData = (
    data: FunnelStageDataType,
    scenario: string,
): FlattenedScenarioDataType[] => {
    let flattenedData = [];
    const totalFirstSessions = getTotalFirstSessions(
        data?.[FunnelStage.FirstSessions]?.["outerGroups"],
    );
    let stage: keyof typeof FunnelStage;
    for (stage in FunnelStage) {
        if (data[stage]) {
            const stageIndex = getStageIndex(stage);
            let flattenedDataObject = {
                index: stageIndex,
                stage: stage,
            };

            for (let index in data[stage]["outerGroups"]) {
                const docType = data[stage]["outerGroups"][index];
                if (
                    stageIndex === 0 ||
                    docType["name"] === scenario ||
                    scenario === "All"
                ) {
                    flattenedDataObject[docType["name"]] =
                        (100 * docType["value"]) / totalFirstSessions;
                }
            }

            flattenedData.push(flattenedDataObject);
        }
    }

    return flattenedData;
};

export const getStageAndNextStageDelta = (
    stageIndex: number,
    funnelData: FunnelStageStateDataType,
    flattenedData: FlattenedScenarioDataType[],
) => {
    if (!flattenedData || !funnelData || stageIndex >= flattenedData.length - 1) {
        return null;
    }

    const currentStage = funnelData[flattenedData[stageIndex]["stage"]];
    const nextStage = funnelData[flattenedData[stageIndex + 1]["stage"]];

    return getStageDropOff(currentStage, nextStage);
};

export const getNextStageName = (
    stageIndex: number,
    flattenedData: FlattenedScenarioDataType[],
) => {
    return stageIndex < flattenedData.length - 1
        ? flattenedData[stageIndex + 1]["stage"]
        : flattenedData[0]["stage"];
};
