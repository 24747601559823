import { subDays, subMonths } from "date-fns";
import format from "date-fns/format";
import {
    Column,
    DropDownData,
    KustoResponseType,
    LabelData,
    LegendEntry,
} from "pages/commonTypes";
import { perfConfig } from "pages/Performance/PerfConfig";
import {
    DeviceCount,
    EventCount,
    Fork,
} from "pages/Performance/PerformanceConstants";
import {
    AllPerfRankingData,
    App,
    AppScenarioMatrix,
    ChartData,
    DataPointsChoices,
    Dimension,
    Dimensions,
    KustoTenantData,
    Levels,
    PerfChartData,
    PerfInsightsQueryParams,
    PerformanceTitleMetadata,
    PerfQueryParams,
    PerfTableData,
    Scenario,
    ValuesToShowChoices,
} from "pages/Performance/types";
import {
    charsInIsoDate,
    createObjectArrayFromKustoResponse,
    dateTimeToDate,
    getChartColor,
    isPositiveIntegerString,
} from "utils/Helpers";

// Transform a general date string into yyyy-MM-dd format.
const formatDate = (date: string) =>
    new Date(date).toISOString().substring(0, charsInIsoDate);
//

export const formatMetaData = (
    metaDataJson: KustoResponseType<string | number | boolean>,
): PerformanceTitleMetadata => {
    const metadataTable = metaDataJson.Tables[0];
    // TODO : Metadata is coming as empty string for few. need to test this.
    // TODO : Need to write a test case to cover this.
    const { Columns: columns, Rows: rows } = metadataTable;
    const result: KustoTenantData = mapColumnsToRecords(
        columns,
        rows,
    )[0] as KustoTenantData;
    const {
        IsEpa: isEpa,
        IsS500: isS500,
        IsGoogleMove: isGoogleMove,
        IsS2500: isS2500,
        IsGov: isGov,
        OmsTenantId,
        OrgName: orgName,
        Parent: tpid,
    } = result;
    const metadata: PerformanceTitleMetadata = {
        tpid,
        OmsTenantId,
        orgName,
        cohorts: { isS2500, isS500, isEpa, isGoogleMove, isGov },
        level: OmsTenantId ? "TenantId" : "Tpid",
    };
    return metadata;
};

// The date for monthly data is always the 28th of the selected month
export const dateForMonthlyData = (date: Date) => format(date, "yyyy-MM-28");

// Default date is yesterday if daily, or the 28th of last month if monthly.
export const getMostRecentDate = (isMonthly: boolean): string => {
    const today = new Date();
    const getYesterday = () => format(subDays(today, 1), "yyyy-MM-dd");
    const getLatestAvailableMonthEnd = () => {
        const oneMonthAgo = subMonths(today, 1);
        return dateForMonthlyData(oneMonthAgo);
    };

    return isMonthly ? getLatestAvailableMonthEnd() : getYesterday();
};

// Default time span is 6 months or 90 days.
export const getDefaultTimespan = (isMonthly: boolean) => (isMonthly ? 6 : 90);

/**
 * Takes supplied URL query parameters, their defaults, and validation functions.
 * Returns an object with the supplied query parameters when they passed the
 * validation functions and the defaults in other cases.
 */
export const getValidQueryParams = <T>(
    suppliedQueryParams: {},
    defaultQueryParams: T,
    queryParamValidation: { [prop in keyof T]: (value: T[prop]) => boolean },
) => {
    const finalQueryParams = { ...defaultQueryParams };

    Object.keys(defaultQueryParams).forEach((prop) => {
        if (
            prop in suppliedQueryParams &&
            queryParamValidation[prop](suppliedQueryParams[prop])
        )
            finalQueryParams[prop] = suppliedQueryParams[prop];
    });

    return finalQueryParams;
};

export const getInitialPerfQueryParams = (suppliedQueryParams: {}) => {
    const defaultQueryParams: PerfQueryParams = {
        id: "72f988bf-86f1-41af-91ab-2d7cd011db47", // Microsoft
        level: "TenantId",
        dataPoints: "7Day",
        timespan: undefined,
        date: undefined,
        partitionBy: "",
        valuesToShow: "TypicalDuration",
    };

    const queryParamValidation = {
        id: (id: any) => typeof id === "string",
        level: (level: any) => Levels.includes(level),
        dataPoints: (dataPoints: any) => DataPointsChoices.includes(dataPoints),
        timespan: isPositiveIntegerString,
        date: (date: any) => !!Date.parse(date),
        partitionBy: (partitionBy: any) => Dimensions.includes(partitionBy),
        valuesToShow: (valuesToShow: any) =>
            ValuesToShowChoices.includes(valuesToShow),
    };

    const queryParams = getValidQueryParams(
        suppliedQueryParams,
        defaultQueryParams,
        queryParamValidation,
    );

    const isMonthly = queryParams.dataPoints === "Monthly";
    if (!Date.parse(queryParams.date))
        queryParams.date = getMostRecentDate(isMonthly);
    queryParams.timespan = queryParams.timespan ?? getDefaultTimespan(isMonthly);

    // users can enter tenantid in uppercase, so change it to lowercase for consistency
    queryParams.id = queryParams?.id?.toLowerCase();
    return queryParams;
};

export const getInitialPerfInsightQueryParams = (
    suppliedQueryParam: Partial<PerfInsightsQueryParams>,
): PerfInsightsQueryParams => {
    const defaultQueryParams: PerfInsightsQueryParams = {
        id: "72f988bf-86f1-41af-91ab-2d7cd011db47",
        level: "TenantId",
        valuesToShow: "P95Duration",
        scenario: "Boot-File",
        date: undefined,
    };

    const queryParams = { ...defaultQueryParams, ...suppliedQueryParam };
    queryParams.date = queryParams.date ?? getMostRecentDate(true);
    return queryParams;
};

export const getUpdatedPerfQueryParams = (
    originalQueryParams: PerfQueryParams,
    updates: Partial<PerfQueryParams>,
) => {
    const otherNeededChanges: Partial<PerfQueryParams> = {};
    if ("dataPoints" in updates) {
        const newValueIsMonthly = updates["dataPoints"] === "Monthly";
        otherNeededChanges["timespan"] = getDefaultTimespan(newValueIsMonthly);
        otherNeededChanges["date"] = getMostRecentDate(newValueIsMonthly);
    }
    return { ...originalQueryParams, ...updates, ...otherNeededChanges };
};

export const getStartDate = (
    endDateString: string,
    timespan: number,
    isMonthlyViewState: boolean,
): string => {
    const endDate = new Date(endDateString);
    const startDate = isMonthlyViewState
        ? subMonths(endDate, timespan - 1)
        : subDays(endDate, timespan - 1);
    const formattedDate = startDate.toISOString().substring(0, charsInIsoDate);
    return formattedDate.toString();
};

/**
 * Takes an array of objects, each containing a Date field and sorted in ascending
 * order, and inserts additional objects for any dates that are missing between
 * the beginning and the end.
 */
export const fillInMissingDates = <T extends { Date: string }>(
    data: T[],
    isMonthly: boolean,
): T[] => {
    const result = [];
    if (data.length === 0) return result;
    const nextDate = (date: Date) => {
        // Works around DST issues in addMonths/addDays -- see https://github.com/date-fns/date-fns/issues/571
        const newDate = new Date(date);
        if (isMonthly) newDate.setUTCMonth(date.getUTCMonth() + 1);
        else newDate.setUTCDate(date.getUTCDate() + 1);

        return newDate;
    };

    let currentDate = new Date(`${data[0].Date}T00:00:00Z`);
    for (const item of data) {
        const itemDate = new Date(`${item.Date}T00:00:00Z`);
        while (currentDate < itemDate) {
            result.push({
                Date: currentDate.toISOString().substring(0, charsInIsoDate),
            });
            currentDate = nextDate(currentDate);
        }
        result.push(item);
        currentDate = nextDate(itemDate);
    }

    return result;
};

/**
 * Creates an AppScenarioMatrix with the given initial value for member.
 * An AppScenarioMatrix is an object with properties for each app name, where each
 * of those property values in an object that has a property for each scenario.
 */
const createAppScenarioMatrix = <T>(initialValue: T): AppScenarioMatrix<T> =>
    perfConfig.Win32.AppsList.reduce(
        (appAcc, app) => ({
            ...appAcc,
            [app]: perfConfig.Win32.scenarios[app].reduce(
                (scenarioAcc, scenario) => ({
                    ...scenarioAcc,
                    [scenario]: initialValue,
                }),
                {},
            ),
        }),
        {},
    ) as AppScenarioMatrix<T>;

/**
 * Takes a Kusto response that contains both an AppName and a Scenario column and
 * separates the data out into an AppScenarioMatrix.
 */
export const populateMatrixWithResponse = <dataTypes, rowObjectType>(
    kustoResponse: KustoResponseType<dataTypes>,
) => {
    const responseRows = createObjectArrayFromKustoResponse<
        dataTypes,
        {
            AppName: App;
            Scenario: Scenario;
        } & rowObjectType
    >(kustoResponse);
    return responseRows.reduce(
        (acc, row) => {
            const { AppName: appName, Scenario: scenario, ...restOfRow } = row;
            return {
                ...acc,
                [appName]: {
                    ...acc[appName],
                    [scenario]: [...acc[appName][scenario], restOfRow],
                },
            };
        },
        createAppScenarioMatrix(<rowObjectType[]>[]),
    );
};

/**
 * Applies a mapping function to each member of an AppScenarioMatrix, returning
 * another AppScenarioMatrix.
 */
export const appScenarioMatrixMap = <T1, T2>(
    matrix: AppScenarioMatrix<T1>,
    func: (element: T1) => T2,
) => {
    const resultMatrix = createAppScenarioMatrix(<T2>undefined);
    perfConfig.Win32.AppsList.forEach((app) =>
        perfConfig.Win32.scenarios[app].forEach(
            (scenario) =>
                (resultMatrix[app][scenario] = func(matrix[app][scenario])),
        ),
    );
    return resultMatrix;
};

/**
 * Executes a function for every value in an AppScenarioMatrix.
 */
export const appScenarioMatrixForEach = <T>(
    matrix: AppScenarioMatrix<T>,
    func: (element: T) => void,
) => {
    perfConfig.Win32.AppsList.forEach((app) =>
        perfConfig.Win32.scenarios[app].forEach((scenario) =>
            func(matrix[app][scenario]),
        ),
    );
};

/**
 * Takes data retreieved from Kusto and formats it in a way that ReCharts can understand.
 */
export const adaptToChartingLibrary = (
    data: PerfChartData[],
    isMonthlyData: boolean,
): [LabelData[], ChartData[]] => {
    // Each element contains data about a given date for a given series.
    // We need to combine all the elements about a given date into one object.
    const dataForDate = (existingData: {}, element: PerfChartData) => {
        const date = dateTimeToDate(element.Date);
        const series = element.Series ? element.Series : element.DominantFork;
        const dataForElement = {
            [date]: {
                ...existingData[date],
                [series]: element.Value,
                [`${DeviceCount} (${series})`]: element.DeviceCount,
                [`${EventCount} (${series})`]: element.EventCount,
            },
        };
        if (element.DominantFork && series !== element.DominantFork)
            dataForElement[date][`Fork (${series})`] = element.DominantFork;
        return dataForElement;
    };
    const dataByDate = data.reduce(
        (acc, current) => ({ ...acc, ...dataForDate(acc, current) }),
        {},
    );

    // The format that ReCharts likes is created by tacking our dates (the keys) onto
    // the data associated with them (the values).
    const adaptedReceivedData = Object.entries<{}>(dataByDate)
        .map(([date, data]) => ({
            Date: date,
            ...data,
        }))
        .sort((a, b) => (a.Date > b.Date ? 1 : -1));

    // We need to add any dates that are missing from the ones we received.
    const adaptedData = fillInMissingDates(adaptedReceivedData, isMonthlyData);

    // Our labels will be the set of all unique keys found in the values of dataByDate,
    // excluding Device Count, Event Count, and Fork rows.
    const distinctLabels = Array.from(
        Object.values(dataByDate).reduce<Set<string>>(
            (set, dataForDate) =>
                Object.keys(dataForDate)
                    .filter(
                        (key) =>
                            !key.startsWith(`${DeviceCount} (`) &&
                            !key.startsWith(`${EventCount} (`) &&
                            !key.startsWith(`${Fork} (`),
                    )
                    .reduce<Set<string>>((set, dataKey) => set.add(dataKey), set),
            new Set<string>(),
        ),
    );

    const adaptedLabels = distinctLabels.sort().map((element, index) => {
        const color = getChartColor(index);
        const isCohort = element === "Overall" || element === "Large Enterprises";
        return {
            key: element,
            Name: element,
            color,
            stroked: isCohort,
            showDots: !isCohort && isMonthlyData,
        };
    });

    // Populated distinct labels that are to be passed to chart.
    // Charts would need labels and values as different arrays.
    // format data to suit labels accepted by chart.
    return [adaptedLabels, adaptedData];
};

export const mapColumnsToRecords = (
    columns: Array<Column>,
    records: Array<Array<Object>>,
): Array<Object> => {
    const reducer = (prevValue, currentValue, index) => {
        prevValue[columns[index].ColumnName] = currentValue;
        return prevValue;
    };
    return records.map((record) => {
        const chartsObj = record.reduce(reducer, {});
        return chartsObj;
    });
};

/** Returns an object with properties/values according to the
 * supplied query string, which we'd get from the browser's URL.
 */
export const parseParams = (querystring: string): {} => {
    const params = new URLSearchParams(querystring);
    const keysIterator = params.keys();
    const keysArray = Array.from(keysIterator);
    const result = keysArray.reduce((prevValue, key) => {
        if (params.getAll(key).length > 1) {
            prevValue[key] = params.getAll(key);
        } else {
            prevValue[key] = params.get(key);
        }
        return prevValue;
    }, {});
    return result;
};

/**
 * Parses the results of the perfRankingQuery Kusto query.
 */
export const parseRankingResponse = (
    response: KustoResponseType<boolean | number>,
): AllPerfRankingData => {
    const responseMatrix = populateMatrixWithResponse<
        boolean | number,
        {
            IsLargeEnterprise: boolean;
            Percentile: number;
            SignificantDifference: boolean;
        }
    >(response);
    return appScenarioMatrixMap(responseMatrix, (responses) => ({
        isLargeEnterprise: responses?.[0]?.IsLargeEnterprise ?? false,
        percentile: responses?.[0]?.Percentile ?? 0,
        significantDifference: responses?.[0]?.SignificantDifference ?? false,
    }));
};

// append hidden property to all the labels data.
export const transformLabelsData = (labelsData: LabelData[]): LegendEntry[] => {
    const legendsData = labelsData.map((labelData) => {
        return { ...labelData, hidden: false };
    });
    return legendsData;
};

export const formatPerformanceDates = (
    datesJSON: KustoResponseType<string>,
): Array<DropDownData> => {
    const datesTable = datesJSON.Tables[0];
    const reducer = (previous, current) => {
        return previous.concat(formatDate(current[0]));
    };
    const datesResult = datesTable.Rows.reduce(reducer, []);
    const result = datesResult.map((date) => {
        const resultObj = { key: date, text: date };
        return resultObj;
    });
    return result;
};

export const filterTableResults = (result: PerfTableData[]): PerfTableData[] => {
    // Kusto store the DimensionName as empty string when the Dimension Name is DominantFork.
    // The Fork must have already been in the response. Hence filtering this empty string record.
    return result.filter((element) => {
        return element.DimensionName !== "";
    });
};

export const getInitialPerfMapData = (): Map<App, PerfTableData[]> => {
    const map = new Map();
    perfConfig.Win32.AppsList.forEach((app) => {
        map.set(app, []);
    });
    return map;
};

export const getInitialDimensionMap = (): Map<App, Dimension> => {
    const map = new Map();
    perfConfig.Win32.AppsList.forEach((app) => {
        map.set(app, "");
    });
    return map;
};

export const formatToMapData = (
    data: PerfTableData[],
): Map<App, PerfTableData[]> => {
    const map = new Map();
    perfConfig.Win32.AppsList.forEach((app) => {
        map.set(
            app,
            data.filter((element) => {
                return element.AppName === app;
            }),
        );
    });
    return map;
};
