export const win32SignalTrends = `
// win32SignalTrends
set query_results_cache_max_age = time(12h);
let cohort = "Is\${cohort}";
let color = "\${color}";
let Performance = StagingGetWin32SignalTrend("Perf_Color", "Performance", cohort, color);
let Watson = StagingGetWin32SignalTrend("Quality_Watson_Color", "Watson", cohort, color);
let UAE = StagingGetWin32SignalTrend("Quality_UAE_Color", "UAE", cohort, color);
let Feedback = StagingGetWin32SignalTrend("Feedback_Color", "Feedback", cohort, color);
let Currency = StagingGetWin32SignalTrend("Currency_InSupport_Color", "Currency", cohort, color);
union Performance, Watson, UAE, Feedback, Currency
| sort by Date asc
`;
