export const macSparkLineScores: string = `
//macLineScores (To display the trend for each metric, on tiles)
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_MacAppView
| where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} =="\${id}" and SubWorkload == "\${app}" and todatetime(ProcessDate) <= todatetime("\${date}")
| extend OS_Currency = Currency_SignalGroup.OS_Currency, OS_Currency_Color = Currency_SignalGroup.OS_Currency_Color,
    App_Currency = Currency_SignalGroup.App_Currency, App_Currency_Color = Currency_SignalGroup.App_Currency_Color,
    CH_Rate = Reliability_SignalGroup.CH_Rate, CH_Color = Reliability_SignalGroup.CH_Color,
    FQ_Rate = Reliability_SignalGroup.FQ_Rate, FQ_Color = Reliability_SignalGroup.FQ_Color,
    PerfP95_Score = Performance_SignalGroup.Perf_P95, Perf_Color = Performance_SignalGroup.Perf_Color,
    PerfBoot_Score = Performance_SignalGroup.Boot_Threshold, NPS = Performance_SignalGroup.NPS, NPS_Color = Feedback_SignalGroup.NPS_Color,
    Engaged_Users_Pct = Usage_SignalGroup.Engaged_Users,Engaged_Users_Pct_Color = Usage_SignalGroup.Engaged_Users_Color
|extend Engagement =round(todouble(100 * Engaged_Users_Pct) / MAU,2),
        OS_Currency = round(todouble(OS_Currency),2),App_Currency = round(todouble(App_Currency),2),
        NPS = NPS,
        CH= round(todouble(CH_Rate),2),
        FQ = round(todouble(FQ_Rate),2),
        PerfP95=PerfP95_Score,
        BootThreshold=round(todouble(PerfBoot_Score),2),PerfP95_Color = GetColor(Perf_Color),BootThreshold_Color = GetColor(0)
| project Date = ProcessDate, Engagement, Engagement_Color = GetColor(Engaged_Users_Pct_Color), 
    OS_Currency,OSCurrency_Color=GetColor(OS_Currency_Color),NPS, NPS_Color = GetColor(NPS_Color),
    CH,CH_Color=GetColor(CH_Color),FQ,FQ_Color=GetColor(FQ_Color),
    PerfP95,PerfP95_Color,  BootThreshold, BootThreshold_Color, App_Currency, AppCurrency_Color = GetColor(App_Currency_Color)
| sort by Date asc`;
