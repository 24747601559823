import { isTest } from "utils/WebConstants";

export const reliability: string = `
// reliability - get reliability drilldown page data
let currDate = GetCurrentDate(${isTest});
let dt = iff(isempty("\${date}"), currDate, "\${date}");
let userThreshold = 1000;
let topErrors = (Reliability
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} == "\${id}" 
    and Date == dt and Host =="\${host}" and UiHost=="\${uiHost}" 
    and ErrorName != "Any" and ErrorName != '' //and PercentUsersWithError >= 1   
| project Application, ErrorName );
let tenantsWithTopErrors = topErrors
    | join kind=inner
    (
        Reliability
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Host =="All" and UiHost=="All" and Date == dt
            and ErrorName != "Any" and ErrorName != '' and TotalUsers > userThreshold
        | project \${levelColumnName}, ErrorName, Application, PercentUsersWithError
    ) on ErrorName, Application
    | top-nested of Application by max(1),
      top-nested of ErrorName by max(1),
      top-nested 15 of \${levelColumnName} by PercentUsersWithError = max(PercentUsersWithError)
    | project Application, ErrorName, \${levelColumnName}, PercentUsersWithError
    | join kind=inner 
        (
            StrategicTenants
            | where Date == currDate
            | extend OrgName = iff("\${levelColumnName}" == "Tpid", OrgName_Translated, TenantName_Translated)
            | distinct \${levelColumnName}, OrgName
        ) on \${levelColumnName}
    | extend tempData = pack("Id", \${levelColumnName}, "OrgName", OrgName, "PercentUsersWithError", PercentUsersWithError)
    | summarize make_list(tempData) by ErrorName, Application ;
topErrors
| join kind=inner
(
    Reliability
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    |where \${levelColumnName} == "\${id}" and Host =="\${host}" and UiHost=="\${uiHost}" 
)
on Application, ErrorName
| extend Current = (Date == dt)
| join kind=leftouter
(
    topErrors
    | join kind = inner
    (
    StrategicTenants
    | where IsS2500 == true and Date == currDate
    | extend OrgName = iff("\${levelColumnName}" == "Tpid", OrgName_Translated, TenantName_Translated)
    | distinct \${levelColumnName}, OrgName
    | join kind = inner
        (
            Reliability
            | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where Host =="\${host}" and UiHost=="\${uiHost}" 
        )
        on \${levelColumnName}  )on Application, ErrorName
        | summarize sum(UserErrorCount), sum(TotalUsers), sum(SessionErrorCount), sum(TotalSessions) by ErrorName, Application, Date
        | extend S2500PercentageUsersWithError = todouble(sum_UserErrorCount)/sum_TotalUsers*100,
                 S2500PercentageSessionsWithError = todouble(sum_SessionErrorCount)/sum_TotalSessions*100
    )
    on Date, Application, ErrorName
    | join kind=inner tenantsWithTopErrors on Application, ErrorName
    | project Date=Date1, Application=Application2, ErrorName=ErrorName2, ErrorType,  
    PercentUsersWithError, S2500PercentageUsersWithError,
    PercentSessionsWithError, S2500PercentageSessionsWithError, Current,
    TenantsWithSameError=list_tempData, Host, UiHost, Tpid, UserErrorCount
    | sort by Date, Application, PercentUsersWithError
`;

export const errorBrowserData: string = `
// errorBrowserData - browser distribution for each error
cluster('kusto.aria.microsoft.com').database('Office \${app} Online Health').Office_\${app}_Online_Health_Qos_Error_\${errorname}
| where User_TenantId =="\${id}" and Data_Veto =="\${veto}"
| where iff("\${host}" == "All", WAC_Host != "All", WAC_Host== "\${host}")
| where iff("\${uiHost}" == "All", WAC_UiHost != "All", iff("\${uiHost}" == "Teams", WAC_UiHost == "Teams" or WAC_UiHost == "teams", WAC_Host == "\${uiHost}"))
| where todatetime(Event_Time) >= todatetime("\${date}") and todatetime(Event_Time) <= todatetime("\${date}") + 28d
| summarize SessionCount = dcount(Session_Id) by strcat(Browser_Name, iff(Browser_Name =="IE", Browser_Version,""))
| order by SessionCount`;
