export const createFunnelDates: string = `
// CreateFunnelDates
let app = "All";
database('ConsumerInsights').CreateFunnelSSM 
| where AppName == app
| summarize by format_datetime(SliceDate, "yyyy-MM-dd")
| order by SliceDate desc
`;

export const createFunnelStages: string = `
// Create Funnel Stages
let app = "\${application}";
let createStages = pack_array("1-All visitors", "2-Visitors opening WXP doc/template in WAC");
let AllStages = 
    materialize (
    	database('ConsumerInsights').CreateFunnelSSM 
    		| union 
    		(database('ConsumerInsights').CreateFunnelRetentionSSM
    		| where MetricName == "5-M1Retention" 
    		| extend ReceivedDate = ReceivedDate-28d)
    	| extend AppName = iff(AppName == "All", "WXP", AppName)
    	| where AppName == app
	);// Add more filtering here when we have the cohorts and pivots		
let firstStage = 
	AllStages 
	| where MetricName == "1-All visitors" 
	| project total = User_UnifiedUserIdDistinctCount, ReceivedDate;
datatable (StageIndex: int, StageName:string, StageFriendlyName: string, Application: string, DropOffName: string, DropOffFriendlyName: string, CanBeNull: boolean)[
	1, "1-All visitors", "All Create Visitors", "All", "CreateVisitToOpenTemplate", "Abandoned before opening template", false,
	2, "2-Visitors opening WXP doc/template in WAC", "Opened WXP template", "All", "WxpTemplateToMsaInWac", "Was not MSA or did not make it to WAC", false,
	3, "3-New MSA users making it to WAC", "MSA user made it to WAC", "All", "NewWebSessionToActivation", "No Core Action", false,
	4, "4-New MSA users with core action in WAC (real edit)", "Performed Core Action", "All", "ActivationToM1Retention", "No Retention", false,
	5, "5-M1Retention", "M1 Retention", "All", "", "", true
]
| extend JoinKey = 1
| join kind=fullouter (AllStages | project Date = format_datetime(SliceDate, "yyyy-MM-dd"), JoinKey = 1) on JoinKey
| summarize count() by Date, StageIndex, StageName, StageFriendlyName, Application, DropOffName, DropOffFriendlyName, CanBeNull
| project-away count_
| join kind=fullouter (
	AllStages
	| join kind=inner (firstStage) on ReceivedDate
	| project Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
		StageName = MetricName,
		Value = iff(MetricName in (createStages), User_UnifiedUserIdDistinctCount, PuidDistinctCount),
		Total = total
	| order by Date desc
	) on StageName, Date
| project Date, StageIndex, StageName, StageFriendlyName, DropOffName, DropOffFriendlyName,CanBeNull, Value, Total
| where isnotempty(StageName)
| extend Percentage = round(100.0 * todouble(Value) / todouble(Total), 2)
| order by Date desc, StageIndex asc`;
