import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { INavLink } from "@m365-admin/nav";
import { appendReferralQueryStringParamsToUrl } from "@oga-plg/plg-telemetry/dist";
import { isLandingPage } from "components/InsightsComponents/Helpers";
import { shouldShowInsightsNav } from "utils/InsightsFrameHelpers";
import {
    getSessionIdFromTelemetryLogger,
    PlgTelemetryContext,
} from "utils/PlgTelemetryLogger";

// Returns the dynamic width of an element given a calculation function
// Updates with every window resize
export const useDynamicWidth = (
    calculation: (windowWidth: number) => number,
): number => {
    // Most of below code from https://usehooks.com/useWindowSize/

    const [dynamicWidth, setDynamicWidth] = useState(calculation(window.innerWidth));

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            setDynamicWidth(calculation(window.innerWidth));
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, [calculation]);

    return dynamicWidth;
};

// Custom hook to handle navigating to a different dashboard
// If link is internal, navigate to the dashboard manually and update the navCollapsed state
export const useNavigateToDashboard = (): ((
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: INavLink,
    setNavCollapsed: (navCollapsed: boolean) => void,
    navigationSourceForTelemetry: string,
) => void) => {
    const navigate = useNavigate();
    const plgTelemetryContext = useContext(PlgTelemetryContext);

    return (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        link: INavLink,
        setNavCollapsed: (navCollapsed: boolean) => void,
        navigationSourceForTelemetry: string,
    ) => {
        e.preventDefault();

        // Set referrer telemetry context information
        plgTelemetryContext?.updateState({
            referralFields: {
                referralFromToolName: `CI Navigation - ${navigationSourceForTelemetry}`,
                referralFromSessionId: getSessionIdFromTelemetryLogger(),
            },
        });

        if (
            link.href.startsWith("/") &&
            shouldShowInsightsNav({
                pathname: link.href,
            } as Location)
        ) {
            navigate(link.href);
            setNavCollapsed(!isLandingPage());
        } else {
            const updatedHref = link["enableReferralTelemetryViaUrl"]
                ? appendReferralQueryStringParamsToUrl(link.href, {
                      referralFromToolName: `CI Navigation - ${navigationSourceForTelemetry}`,
                      referralFromSessionId: getSessionIdFromTelemetryLogger(),
                  })
                : link.href;
            if (link.target) {
                window.open(updatedHref, link.target);
            } else {
                window.location.href = updatedHref;
            }
        }
    };
};
