import React, { useState } from "react";
import { IColumn, PanelType } from "@fluentui/react";
import { DetailPanelV2, DetailPanelV2Header } from "@m365-admin/detail-panel";
import { useFilterOptions } from "@m365-admin/hooks";
import { MetaDataList, MetaDataItem } from "@m365-admin/metadata";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { AdvisoriesFilterPanel } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesFilterPanel";
import { DataTable } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/DataTable";
import { onColumnRender } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { isNil, isEmpty } from "lodash";
import { AddinsMetadataTitles } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import {
    AddInsFilterNames,
    AddInsFlyoutPanelData,
    AddInsFlyoutPanelDetailsTableProps,
    AddInsTabletype,
    AdvisoryFilterDetailsType,
    FlyoutPanelProps,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { logFeatureUsage } from "utils/AppInsightsHelper";

export const getMetadataList = (selectedRow: AddInsTabletype): JSX.Element => {
    if (isNil(selectedRow)) return null;

    const metaDataItems: JSX.Element[] = [
        <MetaDataItem
            key="officeAppHealthAddInFlyoutProductDataItem"
            header={AddinsMetadataTitles.Product}
            body={selectedRow.addinApps}
        />,
        <MetaDataItem
            key="officeAppHealthAddInFlyoutPublisher"
            header={AddinsMetadataTitles.Publisher}
            body={selectedRow.addinProvider}
        />,
        <MetaDataItem
            key="officeAppHealthAddInFlyoutVersions"
            header={AddinsMetadataTitles.Versions}
            body={
                selectedRow.addinVersion.indexOf("versions") === -1
                    ? "1"
                    : selectedRow.addinVersion.replace("versions", "")
            }
        />,
        <MetaDataItem
            key="officeAppHealthAddInFlyoutSessions"
            header={AddinsMetadataTitles.Sessions}
            body={
                <DetailsListHelper.NumericalValue
                    value={selectedRow.sessionCounts}
                    postfix=""
                    defaultValue="NA"
                />
            }
        />,
        <MetaDataItem
            key="officeAppHealthAddInFlyoutDevices"
            header={AddinsMetadataTitles.Devices}
            body={
                <DetailsListHelper.NumericalValue
                    value={selectedRow.deviceCounts}
                    postfix=""
                    defaultValue="NA"
                />
            }
        />,
        <MetaDataItem
            key="officeAppHealthAddInFlyoutProgId"
            header={AddinsMetadataTitles.AddinProgId}
            body={selectedRow.addinProgId}
        />,
    ];

    return <MetaDataList>{metaDataItems}</MetaDataList>;
};

const onRenderHeader = (selectedRow: AddInsTabletype) => {
    return (
        <DetailPanelV2Header
            title={selectedRow.addinName}
            titleTooltipHostProps={{
                content: null,
            }}
        />
    );
};

const onRenderBody = (selectedRow: AddInsTabletype) => (
    <>
        {getMetadataList(selectedRow)}
        <div style={{ paddingTop: 50 }}>
            <AddInsFlyoutPanelDetailsTable
                flyoutPanelData={selectedRow.flyoutPanelData}
            />
        </div>
    </>
);

export const AddInsFlyoutPanel = ({
    isOpen,
    setOpen,
    selectedRow,
}: FlyoutPanelProps) => {
    logFeatureUsage("Apps Admin Center - AddinsFlyoutPanel");

    return (
        <div>
            <DetailPanelV2
                isOpen={isOpen}
                type={PanelType.medium}
                isLightDismiss={true}
                onDismiss={() => setOpen(false)}
                onLightDismissClick={() => setOpen(false)}
                onRenderHeader={() => onRenderHeader(selectedRow as AddInsTabletype)}
                onRenderBody={() => onRenderBody(selectedRow as AddInsTabletype)}
                closeButtonAriaLabel="Close"
            />
        </div>
    );
};

const buildColumns = (): IColumn[] => {
    const displayColumns = [
        ["version", "Version", 80, null],
        ["architecture", "Architecture", 40, null],
        [
            "monitoredDeviceCount",
            "Monitored devicees",
            70,
            "compositeListColumnHeaderWrap",
        ],
        ["sessionCount", "Session count", 70, "compositeListColumnHeaderWrap"],
        ["crashRate", "Crash rate (%)", 60, "compositeListColumnHeaderWrap"],
        ["loadTime", "Load Time (sec.)", 60, "compositeListColumnHeaderWrap"],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        true,
    );

    return columns;
};

export const getAddinsFlyoutPanelDetailsTableColumn: IColumn[] = buildColumns();

export const AddInsFlyoutPanelDetailsTable = ({
    flyoutPanelData,
}: AddInsFlyoutPanelDetailsTableProps) => {
    const [data, setData] = useState<AddInsFlyoutPanelData[]>(flyoutPanelData);

    // show only apps that are affected
    const [apps, setApps, selectedApps] = useFilterOptions([
        ...new Set(
            flyoutPanelData.map(
                (value: AddInsFlyoutPanelData) =>
                    value[AddInsFilterNames.Applications],
            ),
        ),
    ]);

    const [channels, setChannels, selectedChannels] = useFilterOptions([
        ...new Set(
            flyoutPanelData.map(
                (value: AddInsFlyoutPanelData) => value[AddInsFilterNames.Channels],
            ),
        ),
    ]);

    const [builds, setBuilds, selectedBuilds] = useFilterOptions([
        ...new Set(
            flyoutPanelData.map(
                (value: AddInsFlyoutPanelData) => value[AddInsFilterNames.Builds],
            ),
        ),
    ]);

    const addInFlyoutDetailsTableFilters: AdvisoryFilterDetailsType[] = [
        {
            name: AddInsFilterNames.Applications,
            items: apps,
            selectedItems: selectedApps,
            setItems: setApps,
        },
        {
            name: AddInsFilterNames.Channels,
            items: channels,
            selectedItems: selectedChannels,
            setItems: setChannels,
        },
        {
            name: AddInsFilterNames.Builds,
            items: builds,
            selectedItems: selectedBuilds,
            setItems: setBuilds,
        },
    ];

    const onSearch = (value: string) => {
        let filtered = [];
        if (isNil(flyoutPanelData) || flyoutPanelData.length === 0) filtered = [];
        else if (isNil(value) || isEmpty(value)) filtered = flyoutPanelData;
        else {
            filtered = flyoutPanelData.filter(
                (item) =>
                    item.version.toUpperCase().indexOf(value.toUpperCase()) !== -1,
            );
        }
        setData(filtered);
    };

    const getItems = (): AddInsFlyoutPanelData[] => {
        let filtered: AddInsFlyoutPanelData[] = data;

        if (selectedApps !== undefined) {
            filtered = filtered.filter((item) =>
                selectedApps.includes(
                    item[AddInsFilterNames.Applications] as string,
                ),
            );
        }
        if (selectedChannels !== undefined) {
            filtered = filtered.filter((item) =>
                selectedChannels.includes(
                    item[AddInsFilterNames.Channels] as string,
                ),
            );
        }
        if (selectedBuilds !== undefined) {
            filtered = filtered.filter((item) =>
                selectedBuilds.includes(item[AddInsFilterNames.Builds] as string),
            );
        }

        return filtered;
    };

    const onColumnClick = (column: IColumn, items: AddInsFlyoutPanelData[]) => {
        const result = DetailsListHelper.sortItems(
            getAddinsFlyoutPanelDetailsTableColumn,
            column,
            items,
        );
        setData(result.items);
    };

    return (
        <div className="addInsFlyoutPanelDetailsTable">
            <DataTable
                items={getItems()}
                columns={getAddinsFlyoutPanelDetailsTableColumn}
                onSearch={onSearch}
                loading={false}
                FilterPanel={
                    <AdvisoriesFilterPanel
                        advisoriesFilters={addInFlyoutDetailsTableFilters}
                    />
                }
                onColumnClick={onColumnClick}
                onColumnRender={onColumnRender}
                maxHeight={800}
            />
        </div>
    );
};
