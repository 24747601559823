import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import * as API from "api";
import { kustoApiResponseStatusCode } from "config/ApiConfiguration";
import { commonPageBlock, splitCommonPageBlockIntoEqual } from "pages/common";
import { formatWorkloadHealthData } from "pages/ExecOverview/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { EXEC_OVERVIEW_MESSAGES } from "utils/Messages";
import { HealthDistributionTable } from "./HealthDistributionTable";
import { HealthDistributionTrendChart } from "./HealthDistributionTrendChart";
import { TenantsWithNoData } from "./TenantsWithNoData";

const initialState = {
    healthChart: { headers: [], rows: [] },
    loading: true,
};

const HealthDistribution = ({ filters, dateFilter, platform }) => {
    const [healthData, setHealthData] = useState(initialState);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getPayloadData = async () => {
            try {
                let queryParams: {} = {};
                const levelQueryParams = API.getQueryParamsForLevel(filters.level);
                queryParams["application"] =
                    filters.rankOn === "Office"
                        ? "Base"
                        : filters.rankOn ?? filters.application;
                queryParams["mauCountThreshold"] = filters.minMau;
                queryParams["cohort"] = filters.cohort;
                queryParams["date"] = dateFilter;
                queryParams = { ...levelQueryParams, ...queryParams };

                const healthChart = await API.healthChart(queryParams, platform);
                if (healthChart.status !== kustoApiResponseStatusCode.isOK) {
                    setHealthData((data) => {
                        return { ...data, loading: false };
                    });
                    setError(EXEC_OVERVIEW_MESSAGES.HEALTH_INFO_DISPLAY_ERROR);
                }
                try {
                    const healthChartResponse = formatWorkloadHealthData(
                        healthChart?.data,
                    );
                    const responseData = {
                        healthChart: healthChartResponse.healthChartData,
                        loading: false,
                    };
                    setHealthData(responseData);
                } catch (error) {
                    logException(
                        new TenantInsightsException(
                            Severity.SEV2,
                            "ExecOverviewHealthDistributionProcessingFailed",
                        ),
                        {
                            message:
                                "Failure processing response data from Exec Health Distribution page query",
                            responseCodes: {
                                healthChart: healthChart.status,
                            },
                        },
                        error,
                    );
                    setHealthData((healthData) => {
                        return { ...healthData, loading: false };
                    });
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "ExecOverviewHealthDistributionDataFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching data for Exec Health Distribution page",
                    },
                    error,
                );
                setHealthData((healthData) => {
                    return { ...healthData, loading: false };
                });
                setError(EXEC_OVERVIEW_MESSAGES.HEALTH_INFO_DISPLAY_ERROR);
            }
        };
        if (dateFilter !== "") {
            setHealthData(initialState);
            setError(null);
            getPayloadData();
        }
    }, [
        dateFilter,
        filters.application,
        filters.cohort,
        filters.level,
        filters.minMau,
        filters.rankOn,
        platform,
    ]);

    return (
        <>
            {error ? (
                <ErrorFallback message={error} />
            ) : healthData.healthChart.rows.length === 0 && !healthData.loading ? (
                <ShimmeredDetailsListWrapper
                    items={[]}
                    message={EXEC_OVERVIEW_MESSAGES.NO_HEALTH_INFO_DISPLAY_BANNER}
                />
            ) : (
                <Stack styles={commonPageBlock} data-testid="healthDistribution">
                    <Stack horizontal>
                        <Stack.Item styles={splitCommonPageBlockIntoEqual}>
                            <HealthDistributionTrendChart
                                payload={healthData.healthChart}
                                level={filters["level"]}
                                title="Overall Health Trend"
                            />
                        </Stack.Item>
                        <Stack.Item
                            styles={splitCommonPageBlockIntoEqual}
                            data-testid="healthDistributionTable"
                        >
                            <HealthDistributionTable
                                payload={healthData.healthChart.rows.filter(
                                    (i) => i[3] === true,
                                )}
                                level={filters["level"]}
                                loading={healthData.loading}
                            />
                        </Stack.Item>
                    </Stack>
                    <TenantsWithNoData
                        payload={healthData.healthChart.rows}
                        platform={platform}
                    />
                </Stack>
            )}
        </>
    );
};

export default HealthDistribution;
