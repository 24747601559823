import React, { Children, ReactElement } from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";

export const Tabs = ({ defaultSelectedTab, onTabClick, children }) => {
    return Children.count(children) > 1 ? (
        <Pivot
            aria-label=""
            linkSize={"large"}
            selectedKey={defaultSelectedTab}
            onLinkClick={onTabClick}
        >
            {Children.map(children, (child: ReactElement) => {
                const headerText = child.key as string;
                return (
                    <PivotItem itemKey={headerText} headerText={headerText}>
                        {child}
                    </PivotItem>
                );
            })}
        </Pivot>
    ) : (
        <Stack>{children}</Stack>
    );
};
