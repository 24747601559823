import { useEffect, useState } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import {
    Dropdown,
    FontIcon,
    IDropdownOption,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Stack,
} from "@fluentui/react";
import { ApplicationIcon } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import PerformanceTitle from "pages/Performance/PerformanceTitle";
import Win32PerformanceInsightsChart from "pages/Performance/Win32PerformanceInsightsChart";
import Win32PerformanceInsightsTable from "pages/Performance/Win32PerformanceInsightsTable";
import * as API from "api";
import { commonPageStyle, horizontalStackTokens } from "pages/common";
import { DropDownData } from "pages/commonTypes";
import { perfConfig } from "pages/Performance/PerfConfig";
import { scenarioOptions } from "pages/Performance/PerformanceConstants";
import {
    App,
    BootScenario,
    Dimension,
    Level,
    PerfInsightsQuery,
    PerfInsightsQueryParams,
    PerfInsightsRow,
    PerfTableData,
    ValuesToShowChoice,
} from "pages/Performance/types";
import {
    formatPerformanceDates,
    filterTableResults,
    formatToMapData,
    getInitialPerfInsightQueryParams,
    getInitialPerfMapData,
    mapColumnsToRecords,
    parseParams,
} from "pages/Performance/Win32PerformanceHelper";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents, logException } from "utils/AppInsightsHelper";
import { dropdownStyles } from "utils/Constants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { computeQueryParams, dateMonthlyFormatHandler } from "utils/Helpers";
import { InsightsRoutes } from "utils/Links";
import { DRILLDOWN_ERROR_MESSAGES, USER_FORBIDDEN_BANNER } from "utils/Messages";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";
import { initialDropDownOptions } from "utils/Win32Constants";

export const innerBlock: IStackItemStyles = {
    root: {
        width: "30%",
    },
};

export const appBlockStyle: IStackStyles = {
    root: {
        margin: "10px 5px",
        border: "solid 2px #f3f2f1",
        padding: "5px",
        borderRadius: "14px",
    },
};

const filterStackTokens: IStackTokens = {
    childrenGap: 15,
};

export default function Win32PerformanceInsights() {
    const pageName = "Office Win32 Performance Tier 2";
    document.title = pageName;
    AppInsights.getInstance().TrackPage(pageName);

    const location = useLocation();
    const path = location.search;
    const [perfQueryParamsState, setPerfQueryParamsState] =
        useState<PerfInsightsQueryParams>(
            getInitialPerfInsightQueryParams(
                parseParams(path) as Partial<PerfInsightsQueryParams>,
            ),
        );
    const { id, level, date, valuesToShow, scenario } = perfQueryParamsState;

    const [scenarioState, setScenarioState] = useState<BootScenario>(scenario);
    const [dateOptionsState, setDateOptionsState] = useState<IDropdownOption[]>(
        initialDropDownOptions,
    );
    const [dateState, setDateState] = useState<string>(dateOptionsState[0].text);

    const [errorState, setErrorState] = useState<string>(null);
    const [insightsState, setInsightsState] = useState<Map<App, PerfTableData[]>>(
        getInitialPerfMapData(),
    );
    const [selectedInsightState, setSelectedInsightState] = useState<
        Map<App, PerfInsightsRow>
    >(new Map());

    const [dataLoaded, setDataLoaded] = useState(false);

    useSendLaunchEvent("Office Win32 Performance Tier 2 Dashboard");

    useEffect(() => {
        async function fetchDates() {
            const loadPerfDates = async (): Promise<DropDownData[]> => {
                const dates = await API.fetchPerformanceDates();
                const formattedDates = formatPerformanceDates(dates);
                return formattedDates;
            };

            try {
                const sortedTimePeriods = await loadPerfDates();
                setDateOptionsState(sortedTimePeriods);
                setDateState(sortedTimePeriods[0].text);
            } catch (error) {
                if (error.isUnAuthorized) {
                    setErrorState(`${USER_FORBIDDEN_BANNER} ${pageName}. `);
                } else {
                    setErrorState(`${pageName} ${DRILLDOWN_ERROR_MESSAGES}`);
                }

                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "Win32PerfLoadPerfDatesFailed",
                    ),
                    {
                        message: "Kusto Query failed for Win32Perf loadPerfDates",
                        status: error?.errorResponse?.status,
                        query: error?.errorResponse?.config?.data,
                        level,
                        id,
                    },
                );
            }
            //
        }
        fetchDates();
    }, [id, level]);

    useEffect(() => {
        const response = async () => {
            const queryParams: PerfInsightsQuery = {
                tableName: "Summary_TenantPerfMonthly",
                tenantId: id,
                date: date,
                scenario: scenarioState as BootScenario,
                metric: valuesToShow,
                sortOrder: valuesToShow == "P95Duration" ? "desc" : "asc",
            };
            try {
                const kustoResponse = await API.fetchWin32PerfInsights(queryParams);
                const responseTable = kustoResponse.Tables[0];
                const { Columns: columns, Rows: rows } = responseTable;
                const result: PerfTableData[] = mapColumnsToRecords(
                    columns,
                    rows,
                ) as PerfTableData[];
                const filteredResult = filterTableResults(result);
                const tableDataMap = formatToMapData(filteredResult);
                setInsightsState(tableDataMap);
                updateSelectedInsightState(tableDataMap);
                setDataLoaded(true);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "Win32PerfInsightsQueryFailed",
                    ),
                    {
                        message: "Kusto Query failed for Win32PerfInsights",
                        status: error?.errorResponse?.status,
                        query: error?.errorResponse?.config?.data,
                        level,
                        id,
                    },
                );
            }
        };
        response();
    }, [scenarioState, date, id, level, valuesToShow]);

    // Update the URL when dropdown values are changed.
    useEffect(() => {
        window.history.pushState(
            {},
            "",
            window.location.pathname + computeQueryParams(perfQueryParamsState),
        );
    }, [perfQueryParamsState]);

    const updateSelectedInsightState = (
        tableDataMap: Map<App, PerfTableData[]>,
    ): void => {
        const map = new Map();
        perfConfig.Win32.AppsList.forEach((app) => {
            if (tableDataMap.get(app).length > 0) {
                const insightSelected = tableDataMap.get(app)[0];
                map.set(app, {
                    dimension: insightSelected.DimensionName,
                    scenario: insightSelected.Scenario,
                });
            }
        });
        setSelectedInsightState(map);
    };

    const updateSelectedInsight = (
        app: App,
        dimensionName: Dimension,
        scenario: BootScenario,
    ) => {
        setSelectedInsightState(
            new Map(selectedInsightState).set(app, {
                dimension: dimensionName as Dimension,
                scenario: scenario,
            }),
        );
    };

    const _onFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            if (dropdownFor === "scenario") {
                const scenario = item.key.toString() as BootScenario;
                setScenarioState(scenario);
                setPerfQueryParamsState({
                    ...perfQueryParamsState,
                    scenario,
                });

                AppInsights.getInstance().LogEvent(
                    appInsightsCustomEvents.PerformanceView,
                    {
                        type: "UIControl",
                        event: "Tier2BootScenarioChange",
                        value: scenario,
                    },
                );
            } else if (dropdownFor === "date") {
                const date = item.key.toString();
                setDateState(date);
                setPerfQueryParamsState({ ...perfQueryParamsState, date });

                AppInsights.getInstance().LogEvent(
                    appInsightsCustomEvents.PerformanceView,
                    {
                        type: "UIControl",
                        event: "Tier2DateChange",
                        value: date,
                    },
                );
            }
        };
    };

    const getFooter = (scenario, instightsState, appName) => {
        let footer = "";
        const app = instightsState.get(appName)[0];
        if (!app) return "";
        if (scenario === "Boot-Overall" && app) {
            footer +=
                "*P95 baselines (tenant specific) for Boot-Launch is " +
                app.BootLaunchP95 +
                "s";

            if (appName != "Outlook" && appName != "OneNote") {
                footer += " and Boot-File is " + app.BootFileP95 + "s";
            }
        } else {
            footer = `*P95 baseline (tenant specific) for ${app.Scenario} is ${app.P95Baseline}s.`;
        }

        return footer;
    };

    return (
        <>
            {errorState ? (
                <ErrorFallback message={errorState} />
            ) : (
                <Stack disableShrink styles={commonPageStyle}>
                    <div className="drilldownTenantName">
                        <PerformanceTitle
                            pageName={pageName}
                            id={id}
                            level={level}
                            setError={setErrorState}
                        />
                    </div>
                    <Stack>
                        <Stack horizontal tokens={filterStackTokens}>
                            <FontIcon iconName="Filter" className="filterIcon" />
                            <Dropdown
                                placeholder="Select an option"
                                label="Scenario"
                                options={scenarioOptions}
                                selectedKey={scenarioState}
                                styles={dropdownStyles}
                                onChange={_onFilterChange("scenario")}
                                className="filterDropdown"
                            />
                            <Dropdown
                                placeholder="Select an option"
                                label="Months"
                                options={dateMonthlyFormatHandler(dateOptionsState)}
                                selectedKey={dateState}
                                styles={dropdownStyles}
                                onChange={_onFilterChange("date")}
                                className="filterDropdown"
                            />
                            <Stack.Item
                                grow={1}
                                align="center"
                                className="menuNavigation"
                            >
                                <a
                                    href={getPerfDrilldownLink({
                                        id,
                                        level,
                                        date,
                                        valuesToShow,
                                    })}
                                    rel="noopener noreferrer"
                                >
                                    {"Go to Performance Drilldown"}
                                </a>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack>
                        {perfConfig.Win32.AppsList.map((app) => {
                            return (
                                <Stack key={app} styles={appBlockStyle}>
                                    <Stack
                                        horizontal
                                        verticalAlign="center"
                                        horizontalAlign="space-evenly"
                                        tokens={horizontalStackTokens}
                                    >
                                        <Stack.Item
                                            styles={{
                                                root: {
                                                    width: "2%",
                                                },
                                            }}
                                        >
                                            <ApplicationIcon app={app} />
                                        </Stack.Item>
                                        <Stack.Item
                                            styles={{
                                                root: {
                                                    width: "50%",
                                                },
                                            }}
                                        >
                                            <Win32PerformanceInsightsTable
                                                payload={insightsState.get(app)}
                                                app={app}
                                                loading={dataLoaded}
                                                scenario={scenario}
                                                onRowSelectHandler={
                                                    updateSelectedInsight
                                                }
                                            />
                                            <span className="perfTablesBottom">
                                                {getFooter(
                                                    scenario,
                                                    insightsState,
                                                    app,
                                                )}
                                            </span>
                                        </Stack.Item>

                                        <Stack.Item
                                            styles={{
                                                root: {
                                                    width: "40%",
                                                },
                                            }}
                                        >
                                            {insightsState.get(app).length > 0 ? (
                                                <Win32PerformanceInsightsChart
                                                    appName={app}
                                                    selectedRow={selectedInsightState.get(
                                                        app,
                                                    )}
                                                    valuesToShow={valuesToShow}
                                                    tenantId={id}
                                                    endDate={date}
                                                ></Win32PerformanceInsightsChart>
                                            ) : (
                                                ""
                                            )}
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                </Stack>
            )}
        </>
    );
}

const getPerfDrilldownLink = ({
    id,
    level,
    date,
    valuesToShow,
}: {
    id: string;
    level: Level;
    date: string;
    valuesToShow: ValuesToShowChoice;
}): string => {
    return `${InsightsRoutes.Win32PerfDrilldown.path}?id=${id}&level=${level}&date=${date}&dataPoints=Monthly&partitionBy=&valuesToShow=${valuesToShow}`;
};
