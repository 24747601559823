import React from "react";
import { IStackStyles, Stack } from "@fluentui/react";
import "./styles.css";

const LegendIcon = (props: { color: string }) => {
    const backgroundColor = props.color ?? "red";

    const lineStyle = { backgroundColor: backgroundColor };
    const circleStyle = { borderColor: backgroundColor };

    const stackStyles: IStackStyles = {
        root: {
            margin: 3,
        },
    };

    return (
        // Display the stack with a line, circle and line horizontally.
        <Stack styles={stackStyles} horizontal verticalAlign="center">
            <span id="legendLine" style={lineStyle}></span>
            <span id="legendCircle" style={circleStyle}></span>
            <span id="legendLine" style={lineStyle}></span>
        </Stack>
    );
};

export default LegendIcon;
