import {
    FunnelDataType,
    FunnelStageProps,
} from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { MetricsResponseType } from "pages/commonTypes";

export const getAggregatedSliceValueAndPercentageForStage = (
    funnelStageData: FunnelStageProps,
): [number, number] => {
    let aggregatedSliceValue: number = 0;
    for (const slice of funnelStageData.slices) {
        aggregatedSliceValue = aggregatedSliceValue + slice.value;
    }

    const stagePercentOfTotal = (
        (100.0 * aggregatedSliceValue) /
        funnelStageData.slices[0].total
    ).toFixed(2);

    return [aggregatedSliceValue, stagePercentOfTotal as unknown as number];
};

export const getStageDelta = (stageData: FunnelStageProps[], stageIndex: number) => {
    if (!stageData || stageIndex >= stageData.length - 1) {
        return null;
    }

    const currentStageValue = getAggregatedSliceValueAndPercentageForStage(
        stageData[stageIndex],
    )[0];

    const nextStageValue = getAggregatedSliceValueAndPercentageForStage(
        stageData[stageIndex + 1],
    )[0];

    const rawDelta: number = nextStageValue - currentStageValue;
    const percentDelta: number = parseFloat(
        ((rawDelta * 100.0) / currentStageValue).toFixed(2),
    );

    return {
        value: rawDelta,
        percentage: percentDelta,
    };
};

export const getFunnelArrowValue = (
    stagesData: FunnelStageProps[],
    stageIndex: number,
    nextStagePercentage: number,
) => {
    if (!stagesData || stageIndex >= stagesData.length - 1) {
        return null;
    }

    if (stagesData[stageIndex + 1].canBeNull && nextStagePercentage === -100.0) {
        // This is the case where the next stage can be null. So showing a 100% drop off is not actually accurate.
        return "N/A";
    }

    return `${nextStagePercentage}%`;
};

export const isStageNull = (stage: FunnelStageProps) => {
    for (const slice of stage.slices) {
        if (!slice.value) {
            return true;
        }
    }

    return false;
};

export const getFirstDateWithAllStages = (funnelData: FunnelDataType): string => {
    let earliestDefaultDate: string = null;
    for (const date of Object.keys(funnelData)) {
        const funnelStagesForDate = funnelData[date];
        let allStagesHaveData = true;
        for (const stage of funnelStagesForDate) {
            allStagesHaveData = !isStageNull(stage);
        }
        if (allStagesHaveData) {
            return date;
        }
    }

    // We do not have all stages data. Return the earliest date instead
    return earliestDefaultDate;
};

export const isStageNullAsExpected = (stage: FunnelStageProps) => {
    return stage.canBeNull && isStageNull(stage);
};

export const IsFunnelDataEmpty = (funnelData: FunnelStageProps[]) => {
    return (
        !funnelData ||
        funnelData?.length === 0 ||
        funnelData[0].slices.length === 0 ||
        funnelData[0].slices[0].value == null
    );
};

export const flattenStagesData = (funnelStagesResponse: MetricsResponseType) => {
    const funnelDataTable = funnelStagesResponse.Tables[0];
    const funnelData = {};
    funnelDataTable.Rows.forEach((element) => {
        if (!(element[0] in funnelData)) {
            funnelData[element[0]] = {};
        }

        const stage = element[3] as string;
        funnelData[element[0]][stage] = {
            value: element[7] as number,
            percentage: element[9] as number,
        };
    });

    return funnelData;
};

export const formatFunnelStageOverTimeData = (
    funnelStagesResponse: MetricsResponseType,
) => {
    const funnelOverTime = [];
    const funnelData = flattenStagesData(funnelStagesResponse);

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            funnelOverTime.push({ date, ...funnelData[date] });
        });

    return funnelOverTime;
};

export const formatFunnelDropOffOverTime = (funnelData: FunnelDataType) => {
    const funnelDropOffOverTime = [];

    Object.keys(funnelData)
        .sort()
        .forEach((date) => {
            let dropOffData = {};
            dropOffData["date"] = date;
            const stagesData = funnelData[date];
            for (let i = 0; i < stagesData.length - 1; i++) {
                if (!isStageNullAsExpected(stagesData[i + 1])) {
                    dropOffData[stagesData[i].dropOff.friendlyName] = getStageDelta(
                        stagesData,
                        i,
                    );
                }
            }
            funnelDropOffOverTime.push(dropOffData);
        });

    return funnelDropOffOverTime;
};
