import React from "react";
import { Shimmer, Stack } from "@fluentui/react";
import PerformanceLineChart from "components/CustomComponents/PerformanceLineChart";
import { LegendEntry } from "pages/commonTypes";
import { getChartDataFromCountAndPerfImpactData } from "pages/Performance/AddIns/AddInPerformanceHelper";
import { perfChartStackItemStyles } from "pages/Performance/PerformanceConstants";
import { AddInCountAndPerfImpactData } from "../types";

export const AddInCountAndPerfImpactCharts = ({
    legend,
    data,
}: {
    legend: LegendEntry[];
    data: AddInCountAndPerfImpactData;
}) => {
    return (
        <>
            <Shimmer className="scenarioChart" isDataLoaded={data !== undefined}>
                <Stack styles={perfChartStackItemStyles}>
                    <h3 className="scenarioChartHeader">Devices with add-in</h3>
                    <PerformanceLineChart
                        values={getChartDataFromCountAndPerfImpactData(
                            "Counts",
                            data,
                        )}
                        labels={legend}
                    />
                </Stack>
            </Shimmer>
            <Shimmer className="scenarioChart" isDataLoaded={data !== undefined}>
                <Stack styles={perfChartStackItemStyles}>
                    <h3 className="scenarioChartHeader">
                        Typical boot load cost by add-in
                    </h3>
                    <PerformanceLineChart
                        values={getChartDataFromCountAndPerfImpactData(
                            "Durations",
                            data,
                        )}
                        labels={legend}
                        yUnit="s"
                    />
                </Stack>
            </Shimmer>
        </>
    );
};
