import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import CamauFilters from "components/ConsumerCamauComponents/CamauFilters";
import CamauTable from "components/ConsumerCamauComponents/CamauTable";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchScorecardTabledata,
    formatScorecardTableData,
} from "components/ConsumerCamauComponents/ApiHandler";
import { getConsumerCamauColumns } from "components/ConsumerCamauComponents/ConsumerCamauHelper";
import {
    CamauCadenceType,
    ConsumerCamauFiltersType,
    ConsumerCamauStateType,
} from "components/ConsumerCamauComponents/types";
import { Workload, config, getConfig } from "config/PlatformConfig";
import { commonPageBlock } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { consumerPageStyle } from "utils/ConsumerConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const camauTableColumns = getConsumerCamauColumns();

const ConsumerCamauOverview = () => {
    const [error, setError] = useState<string>(null);
    const [filters, setFilters] = useState<ConsumerCamauFiltersType>(() => {
        const config = getConfig(Workload.CONSUMER_CAMAU);
        const initFilters: ConsumerCamauFiltersType = {
            application: config.defaults.app,
            date: null,
            cadence: config.defaults.cadence as CamauCadenceType,
        };
        const filtersFromQueryParams = extractQueryParams();

        if (filtersFromQueryParams["application"]) {
            const appFromURL = filtersFromQueryParams["application"] as string;
            const cadenceFromURL = filtersFromQueryParams["cadence"] as string;
            const dateFromURL = filtersFromQueryParams["date"] as string;

            if (config.appsList.includes(appFromURL)) {
                initFilters.application = appFromURL;
            }
            if (config.defaults.cadence.includes(cadenceFromURL)) {
                initFilters.cadence = cadenceFromURL as CamauCadenceType;
            }
            if (filtersFromQueryParams["date"]) {
                initFilters.date = dateFromURL;
            }
        }
        return {
            ...initFilters,
        };
    });

    const [state, setState] = useState<ConsumerCamauStateType>({
        loading: true,
        camauTableRows: {},
        distinctDates: [],
    });

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        logFilterUsage(config.pageTitle, item, value);
        setUpDocumentUrl(
            {
                application: updatedFilter.application,
            },
            config.pageTitle,
        );
        setFilters(updatedFilter);
        sendFiltersChangeTelemetryEvent(updatedFilter);
    };

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        document.title = config.pageTitle;
        AppInsights.getInstance().TrackPage(config.pageTitle);
        const getCamauData = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });

            try {
                const mauData = await fetchScorecardTabledata(
                    filters,
                    filters.cadence === "daily"
                        ? "consumerCamauDailyMetrics" //{Consumer Daily query}//
                        : "consumerCamauMonthlyMetrics", //{Consumer Monthly query}//
                );

                const [items, distinctDates] = formatScorecardTableData(mauData);

                if (distinctDates.length === 0)
                    throw new Error("Consumer camau Daily/Monthly has no dates");

                const dateFromURL =
                    distinctDates[distinctDates.indexOf(filters.date)];

                onFilterChange(
                    "date",
                    dateFromURL === undefined ? distinctDates[0] : dateFromURL,
                );

                setState((oldState) => {
                    return {
                        ...oldState,
                        camauTableRows: items,
                        distinctDates,
                        loading: false,
                        cadence: filters.cadence,
                    };
                });
                setError(null);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerCamauDataProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format Consumer Camau data",
                    },
                    e,
                );

                setError(e.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        camauTableRows: {},
                        distinctDates: [],
                        loading: false,
                    };
                });
                return;
            }
        };
        setUpDocumentUrl(
            {
                application: filters.application,
            },
            config.pageTitle,
        );
        getCamauData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.cadence]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack styles={consumerPageStyle}>
            {!state.loading && filters.date === null ? (
                <EmptyTableContainer />
            ) : (
                <>
                    <Stack.Item>
                        <div className="orgHeader">{"Consumer Camau Overview"}</div>
                    </Stack.Item>
                    <Stack.Item>
                        <CamauFilters
                            filters={filters}
                            onFilterChange={onFilterChange}
                            camauState={state}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item grow>
                                <AppOverviewIconPanel
                                    apps={config.appsList}
                                    onAppClick={onFilterChange}
                                    selectedApp={filters.application}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <div className="camauTablePageSection">
                        <Stack>
                            <Stack.Item styles={commonPageBlock}>
                                <CamauTable
                                    items={state.camauTableRows}
                                    columns={camauTableColumns}
                                    loading={state.loading}
                                    application={filters.application}
                                    cadence={filters.cadence}
                                    date={filters.date}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                </>
            )}
        </Stack>
    );
};

export default ConsumerCamauOverview;
