import React, { useEffect, useState } from "react";
import {
    ChoiceGroup,
    ConstrainMode,
    DetailsListLayoutMode,
    IDropdownOption,
    Pivot,
    PivotItem,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from "@fluentui/react";
import { EmptyState } from "@m365-admin/empty-state";
import CopilotAppScorecardTable from "components/AIScorecardComponents/CopilotAppScorecardTable";
import { CopilotRetention } from "components/AIScorecardComponents/CopilotRetention";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { emptyStateStyle } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { cadenceOptions } from "components/AIScorecardComponents/AIScorecardConstants";
import {
    fetchCopilotData,
    formatCopilotLicenseData,
    formatScorecardTableData,
} from "components/AIScorecardComponents/ApiHandler";
import {
    AIScorecardStateType,
    LicenseType,
} from "components/AIScorecardComponents/types";
import { DatePicker } from "components/CustomComponents/DatePicker";
import { commonPageBlock, equalStackItemsStyle } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatDateOptions } from "utils/Helpers";
import { COPILOT_MESSAGES } from "utils/Messages";

const defaultFilters = {
    office365Category: "Commercial",
    audienceGroup: "Production",
    cohort: "AllUsers",
};

export const CopilotTab = ({ platform, level, id, app, isCopilot }) => {
    const [error, setError] = useState<string>(null);

    const [date, setDate] = useState<string>(null);
    const [cadence, setCadence] = useState<string>("Weekly");

    const [licenses, setLicenses] = useState<LicenseType>();

    const filters = {
        application: app,
        date,
        platform: platform,
        tenantId: id,
        cadence,
        ...defaultFilters,
    };

    const [state, setState] = useState<AIScorecardStateType>({
        loading: true,
        scorecardData: [],
        dateOptions: [],
    });

    useEffect(() => {
        const getDateOptions = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });
            setError(null);

            try {
                // Only need cadence param for this query
                const queryParams = {
                    cadence: cadence,
                };

                const dateJson = await fetchCopilotData(
                    queryParams,
                    "aiScorecardDates",
                );

                const datesData = formatDateOptions(dateJson);

                if (datesData.length === 0)
                    throw new Error("Copilot Tenant Scorecard has no dates");

                setDate(datesData[0]?.key as string);
                setState((oldState) => {
                    return {
                        ...oldState,
                        loading: false,
                        dateOptions: datesData,
                        scorecardData: [],
                    };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotTenantScorecardDatesProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot Tenant Scorecard date options",
                    },
                    error,
                );

                setError(error.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        dateOptions: [],
                        scorecardData: [],
                    };
                });
                return;
            }
        };
        getDateOptions();
    }, [cadence]);

    useEffect(() => {
        const getScorecardData = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });
            setError(null);

            try {
                const queryParams = {
                    platform,
                    tenantId: id,
                    application: app,
                    date,
                    cadence,
                    ...defaultFilters,
                };
                const [data, licenseData] = await Promise.all([
                    fetchCopilotData(queryParams, "aiScorecardMetrics"),
                    fetchCopilotData(queryParams, "copilotTenantLicenses"),
                ]);

                let formattedData = formatScorecardTableData(data);
                const licenseMetrics = formatCopilotLicenseData(licenseData);
                setLicenses(licenseMetrics);
                setState((oldState) => {
                    return {
                        ...oldState,
                        scorecardData: formattedData,
                        loading: false,
                    };
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotTenantScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot Tenant Scorecard data",
                    },
                    e,
                );

                setError(e.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        scorecardData: [],
                        loading: false,
                    };
                });
            }
        };

        if (date !== null) getScorecardData();
    }, [app, platform, id, date, cadence]);

    return error ? (
        <ErrorFallback message={error} />
    ) : state.loading ? (
        <div className="metricsTable tenantTable">
            <ShimmeredDetailsList
                items={[]}
                columns={[]}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={true}
                constrainMode={ConstrainMode.unconstrained}
            />
        </div>
    ) : (
        <>
            {isCopilot === false ? (
                <EmptyState
                    title={COPILOT_MESSAGES.NO_LICENSE}
                    body=""
                    styles={emptyStateStyle}
                />
            ) : level === "Tpid" ? (
                <EmptyState
                    title={COPILOT_MESSAGES.NO_TPID_DATA}
                    body=""
                    styles={emptyStateStyle}
                />
            ) : (
                <>
                    <Stack.Item>
                        {licenses && (
                            <Stack.Item>
                                <Stack horizontal className="copilotLicenseMetrics">
                                    <Stack.Item styles={equalStackItemsStyle}>
                                        Available Units: {licenses.availableUnits}
                                    </Stack.Item>
                                    <Stack.Item styles={equalStackItemsStyle}>
                                        Enabled Users: {licenses.enabledUsers}
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        )}
                        <Stack horizontal className="filterPanel">
                            <div>
                                <Stack horizontal>
                                    <ChoiceGroup
                                        defaultSelectedKey={cadence}
                                        options={cadenceOptions}
                                        onChange={(_event, option) => {
                                            setCadence(option.key as string);
                                        }}
                                        required={true}
                                        styles={{
                                            flexContainer: {
                                                display: "grid",
                                                gridTemplateColumns: "1fr 1fr auto",
                                                marginTop: "-8px",
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div>
                                <DatePicker
                                    dateOptions={state.dateOptions}
                                    onChangeSelection={(
                                        _event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption<any>,
                                    ) => {
                                        setDate(option.key as string);
                                    }}
                                    selected={date}
                                    shouldFormat={false}
                                />
                            </div>
                        </Stack>
                    </Stack.Item>
                    <Pivot linkSize={"normal"}>
                        <PivotItem itemKey={"Scorecard"} headerText={"Scorecard"}>
                            <Stack.Item styles={commonPageBlock}>
                                <CopilotAppScorecardTable
                                    items={state.scorecardData}
                                    loading={state.loading}
                                    filters={filters}
                                />
                            </Stack.Item>
                        </PivotItem>

                        <PivotItem itemKey={"Retention"} headerText={"Retention"}>
                            <Stack styles={commonPageBlock}>
                                <CopilotRetention filters={filters} />
                            </Stack>
                        </PivotItem>
                    </Pivot>
                </>
            )}
        </>
    );
};
