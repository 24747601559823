import { Workload } from "config/PlatformConfig";
import {
    bootLaunch,
    bootFile,
    fileOpenLocal,
    fileOpenOnline,
} from "pages/Performance/PerformanceConstants";
import { App } from "pages/Performance/types";

export const perfConfig = {
    [Workload.WIN32]: {
        AppsList: ["Word", "Excel", "PowerPoint", "Outlook", "OneNote"] as App[],
        MonthlyOverallTable: "Summary_TenantPerfOverallMonthly",
        WeeklyOverallTable: "Summary_TenantPerfOverall7Day",
        MonthlyTable: "Summary_TenantPerfMonthly",
        WeeklyDayTable: "Summary_TenantPerf7Day",
        scenarios: {
            Word: [bootLaunch, bootFile, fileOpenLocal, fileOpenOnline],
            Excel: [bootLaunch, bootFile, fileOpenLocal, fileOpenOnline],
            PowerPoint: [bootLaunch, bootFile, fileOpenLocal, fileOpenOnline],
            Outlook: [bootLaunch],
            OneNote: [bootLaunch],
        },
    },
};
