import { ErrorSpikeThreshold } from "utils/WebConstants";
import { isTest } from "utils/WebConstants";

export const errorSpike: string = `
let currDate = GetCurrentDate(${isTest});
let dt = iff(isempty("\${date}"), currDate, "\${date}");
ErrorSpike
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
 | where \${levelColumnName}=="\${id}" and Date == dt and UserErrorRate > ${ErrorSpikeThreshold}
 | sort by Priority_Overall asc 
 | project ErrorName, Application,
   UserErrorRate, UserErrorRate_Prev, 
   SessionErrorRate, SessionErrorRate_Prev,
   IsNewError, IsSpike, IsHighTrend, IsHighUserImpact = IsHighTrend == false and IsSpike == false and IsHighTrend == false
 `;
