export const webconsumerTenantStatus: string = `
//webConsumerTenantStatus
let app = "\${application}";
let currMonthdate = "\${date}";
let cohort = "\${cohort}";
let pivot = "All";
let host = "OneDriveWOPI";
let prevMonthdate = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
ConsumerOverviewScore
| where CohortType == cohort and Application == app and Date == currMonthdate and PivotName == pivot and CohortName != "All" and Host == host
| extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
| project CohortName, Application,
    Composite_Score = round(Score, 2),
    CurrentStatus = Score_Color,
    Composite_MoMChange = MoM_Score,
    MAU_Score = MAU,
    MAURate_Score = MAURate * 100, MAURate_Score_Color = MAURate_Color,
    CoreActionMAURate_Score = MEURate * 100, CoreActionMAURate_Score_Color = MEURate_Color,
    RetentionRate_Score = RetentionRate * 100, RetentionRate_Score_Color = RetentionRate_Color,
    EUPL_P95_Score = todouble(Performance_Score.EUPL_P95), EUPL_P95_Score_Color = Performance_Color.EUPL_P95,
    EUPL_P50_Score = todouble(Performance_Score.EUPL_P50), EUPL_P50_Score_Color = Performance_Color.EUPL_P50,
    ASHA_Score = todouble(Reliability_Score.ASHA), ASHA_Score_Color = Reliability_Color.ASHA,
    Feedback_Score = todouble(Feedback_Score.SAS), Feedback_Score_Color = Feedback_Color.SAS
| join kind =inner (
    ConsumerOverviewScore
    | where CohortType == cohort and Application == app and Date == prevMonthdate and PivotName == pivot and CohortName != "All" and Host == host
    | extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
    | project CohortName, Application,
        Composite_Score = round(Score, 2),
        PreviousStatus = Score_Color,
        Composite_MoMChange = MoM_Score,
        MAU_Score = MAU,
        MAURate_Score = MAURate * 100, MAURate_Score_Color = MAURate_Color,
        CoreActionMAURate_Score = MEURate * 100, CoreActionMAURate_Score_Color = MEURate_Color,
        RetentionRate_Score = RetentionRate * 100, RetentionRate_Score_Color = RetentionRate_Color,
        EUPL_P95_Score = todouble(Performance_Score.EUPL_P95), EUPL_P95_Score_Color = Performance_Color.EUPL_P95,
        EUPL_P50_Score = todouble( Performance_Score.EUPL_P50), EUPL_P50_Score_Color = Performance_Color.EUPL_P50,
        ASHA_Score = todouble(Reliability_Score.ASHA), ASHA_Score_Color = Reliability_Color.ASHA,
        Feedback_Score = iff(todouble(Feedback_Score.SAS) == 0, todouble(1),todouble(Feedback_Score.SAS)) , Feedback_Score_Color = Feedback_Color.SAS
    ) on CohortName, Application
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | project CohortName, Application, Composite_Score, CurrentStatus, PreviousStatus, Composite_MoMChange = Composite_Score - Composite_Score1, MAU_Score, MAU_MoMChange =  round((todouble(MAU_Score-MAU_Score1)/MAU_Score1)*100,2),
        MAURate_Score, MAURate_MoMChange = round((todouble(MAURate_Score-MAURate_Score1)/MAURate_Score1),2), MAURate_Score_Color,
        CoreActionMAURate_Score, CoreActionMAURate_MoMChange = round((todouble(CoreActionMAURate_Score-CoreActionMAURate_Score1)/CoreActionMAURate_Score1),2), CoreActionMAURate_Score_Color,
        RetentionRate_Score, RetentionRate_MoMChange = round((todouble(RetentionRate_Score-RetentionRate_Score1)/RetentionRate_Score1),2), RetentionRate_Score_Color,
        EUPL_P95_Score, EUPL_P95_MoMChange = round((todouble(EUPL_P95_Score-EUPL_P95_Score1)/EUPL_P95_Score1),2), EUPL_P95_Score_Color,
        EUPL_P50_Score, EUPL_P50_MoMChange = round((todouble(EUPL_P50_Score-EUPL_P50_Score1)/EUPL_P50_Score1),2), EUPL_P50_Score_Color,
        ASHA_Score, ASHA_MoMChange = round((todouble(EUPL_P50_Score-EUPL_P50_Score1)/EUPL_P50_Score1),2), ASHA_Score_Color,
        Feedback_Score, Feedback_MoMChange = round((todouble(Feedback_Score-Feedback_Score1)/Feedback_Score1),2), Feedback_Score_Color`;
