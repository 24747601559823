import React from "react";
import { SignalTrendFilter } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";

export const mtrCustomTooltipFunction = (props) => {
    const { active, payload } = props;

    if (active && payload) {
        return (
            <div className="customTooltipContainer">
                <p className="customTooltipText">
                    {payload[0].payload.Date
                        ? formatDisplayDate(payload[0].payload.Date)
                        : "N/A"}
                </p>
                {payload.map((el) => (
                    <p
                        className="customTooltipText"
                        style={{ color: el.color }}
                        key={el.name}
                    >
                        {el.name}: {el.value}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

export const getSignalTrendFilter = (filterKey: string) => {
    const update = {};
    if (filterKey === "platform" || filterKey === "group") {
        update["signal"] = SignalTrendFilter.DefaultSignal;
        update["app"] = SignalTrendFilter.DefaultApp;
        update["cohort"] = SignalTrendFilter.DefaultCohort;
    }

    return update;
};
