import React, { useEffect, useState } from "react";
import {
    IColumn,
    IDetailsRowProps,
    IRenderFunction,
    Link,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import {
    LegendAction,
    TenantTopAddInPerformanceData,
    TenantTopAddInPerformanceDataWithHref,
} from "pages/Performance/types";
import { computeQueryParams } from "utils/Helpers";

export const TenantTopAddInTable = ({
    id,
    date,
    timespan,
    topAddInData,
    dispatcher,
}: {
    id: string;
    date: string;
    timespan: number;
    topAddInData: TenantTopAddInPerformanceData[];
    dispatcher: React.Dispatch<LegendAction>;
}) => {
    const [items, setItems] = useState<TenantTopAddInPerformanceDataWithHref[]>([]);

    const DefineColumn = (
        fieldName: string,
        displayName: string,
        width: number,
        otherFields?: Partial<IColumn>,
    ): IColumn => ({
        key: fieldName,
        name: displayName,
        fieldName,
        minWidth: 100,
        maxWidth: width,
        isResizable: true,
        ...otherFields,
    });

    const defaultColumns: IColumn[] = [
        DefineColumn("Name", "Add-in name", 400),
        DefineColumn("ProgId", "ProgID", 400, {
            onRender: (item: TenantTopAddInPerformanceDataWithHref) => (
                <Link key={item.ProgId} href={item.Href}>
                    {item.ProgId}
                </Link>
            ),
        }),
        DefineColumn("DevicePercentage", "Percentage of devices", 200, {
            onRender: (item: TenantTopAddInPerformanceDataWithHref) =>
                `${item.DevicePercentage.toFixed(2)}%`,
        }),
        DefineColumn("TypicalDuration", "Typical duration", 200, {
            isSorted: true,
            isSortedDescending: true,
            onRender: (item: TenantTopAddInPerformanceDataWithHref) =>
                `${item.TypicalDuration.toFixed(3)} s`,
        }),
    ];

    const [columns, setColumns] = useState(defaultColumns);

    useEffect(() => {
        if (!topAddInData) return;

        const baseDrilldownHref =
            "addInVersionPerformance" +
            computeQueryParams({
                id,
                date,
                timespan,
            });
        setItems(
            topAddInData.map((item) => ({
                ...item,
                Href: `${baseDrilldownHref}&progId=${item.ProgId}`,
            })),
        );
    }, [id, date, timespan, topAddInData]);

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columns, column, items);
        setItems(result.items);
        setColumns(result.columns);
    };

    const onRenderRow: IRenderFunction<IDetailsRowProps> = (
        rowProps,
        defaultRender,
    ) => (
        <div
            onMouseEnter={() =>
                dispatcher({
                    type: "showDotsOnOneEntry",
                    payload: rowProps.item.ProgId,
                })
            }
            onMouseLeave={() =>
                dispatcher({ type: "showDotsOnOneEntry", payload: "" })
            }
        >
            {defaultRender(rowProps)}
        </div>
    );

    return (
        <ShimmeredDetailsListWrapper
            isDataLoaded={topAddInData !== undefined}
            message="No significant add-ins found."
            items={items}
            columns={columns}
            selectionMode={SelectionMode.none}
            onColumnHeaderClick={onColumnClick}
            onRenderRow={onRenderRow}
        />
    );
};
