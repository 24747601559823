import React from "react";
import { Icon, Shimmer } from "@fluentui/react";
import { appIconStyle } from "utils/commonStyles";
import { appStyles } from "utils/Constants";
import { STATUS } from "utils/WebConstants";
import { MiniApplicationTitle } from "../ApplicationTitle/ApplicationTitle";
import {
    StatusIndicator,
    StatusIndicatorTrend,
} from "../StatusIndicator/StatusIndicator";

export const ScoreStatus = ({
    score,
    application,
    status,
    statusTrend,
    momScore,
    scoreBreakDown = null,
    prefixText = "",
    loading = false,
    showStatusTrend = true,
    pastStatusText = null,
}) => {
    let arrowColor = null;
    const icon = appStyles[application]["iconName"];
    const trendLabel = pastStatusText
        ? pastStatusText
        : application === "All"
        ? "Monthly Tenant Health Trend"
        : "Monthly App Health Trend";
    if (momScore > 0) arrowColor = "positiveDelta";
    else if (momScore < 0) arrowColor = "negativeDelta";
    if (scoreBreakDown !== null) {
        scoreBreakDown.sort((a, b) => b.score - a.score);
    }

    return (
        <ul
            className={
                showStatusTrend
                    ? "coreHealthMetadata"
                    : "coreHealthMetadataNoPastStatus"
            }
        >
            <li style={{ display: "inline-flex", alignItems: "center" }}>
                <Icon iconName={icon} className={appIconStyle} />
                &nbsp;
                <div className="statusTitle">{prefixText} </div>
            </li>
            <li className="coreHealthTile">
                <Shimmer
                    isDataLoaded={!loading}
                    styles={{
                        root: {
                            fontWeight: "bold",
                        },
                    }}
                >
                    Status: &nbsp;
                    <div className="spacer" />
                    <StatusIndicator
                        color={STATUS[status]?.["color"]}
                        id="statusTitle"
                    />
                    <span className="contrib">{STATUS[status]?.["definition"]}</span>
                </Shimmer>
            </li>
            {status === "Gray" ? (
                <li className="coreHealthTile">
                    <Shimmer
                        isDataLoaded={!loading}
                        styles={{
                            root: {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Score: &nbsp; NA
                    </Shimmer>
                </li>
            ) : (
                <li className="coreHealthTile">
                    <Shimmer
                        isDataLoaded={!loading}
                        styles={{
                            root: {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        Score: &nbsp;
                        {score}
                        &nbsp;{" "}
                        <span>
                            <span className={arrowColor}>
                                {momScore < 0 ? (
                                    <span>&#9660;</span>
                                ) : momScore > 0 ? (
                                    <span>&#9650;</span>
                                ) : (
                                    <span />
                                )}
                            </span>
                            <span> {momScore ? `${momScore}` : ""}</span>
                        </span>
                        <div className="spacer" />
                        {scoreBreakDown && (
                            <span className="contrib">
                                <span style={{ fontSize: "larger" }}>(&nbsp;</span>
                                {scoreBreakDown.map(({ icon, score }) => (
                                    <span
                                        style={{
                                            display: "inline-flex",
                                        }}
                                    >
                                        <MiniApplicationTitle
                                            icon={icon}
                                            score={score}
                                        />
                                        {"%"}&nbsp;
                                    </span>
                                ))}
                                <span style={{ fontSize: "larger" }}>)</span>
                            </span>
                        )}
                    </Shimmer>
                </li>
            )}
            {showStatusTrend && (
                <li className="coreHealthTile">
                    <Shimmer
                        isDataLoaded={!loading}
                        styles={{
                            root: {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        {trendLabel}: &nbsp;
                        <div className="spacer" />
                        <StatusIndicatorTrend values={statusTrend} />
                    </Shimmer>
                </li>
            )}
        </ul>
    );
};
