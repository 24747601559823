import React from "react";
import { ChoiceGroup } from "@fluentui/react";
import "components/AIScorecardComponents/style.css";

export const FeatureFilter = ({ filters, onFilterChange }) => {
    const options = [
        { key: "Compose", text: "Compose" },
        { key: "Doc Q&A", text: "Doc Q&A", disabled: true },
        { key: "Summarize", text: "Summarize", disabled: true },
    ];

    return (
        <ChoiceGroup
            className="inlineflex"
            defaultSelectedKey={filters.feature}
            options={options}
            onChange={(event, option) => onFilterChange("feature", option.key)}
            label="Features"
        />
    );
};
