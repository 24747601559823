import React from "react";
import { Shimmer } from "@fluentui/react";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { CohortTitleMetadata } from "pages/ConsumerProductDrillDown/types";
import { STATUS } from "utils/WebConstants";

const CohortTitle = ({
    metadata,
    loading,
}: {
    metadata: CohortTitleMetadata;
    loading: boolean;
}) => {
    return loading ? (
        <div>
            <Shimmer className="customerTitleShimmerHeader" />
            <Shimmer className="customerTitleShimmerBody" />
        </div>
    ) : (
        <div className="tenantTitleWrapper">
            <span>
                <StatusIndicator
                    id="statusTitle"
                    color={STATUS[metadata?.status]["color"]}
                />
                <span id="tenantName"> {metadata?.cohortName} </span>
                <br />
                <span
                    style={{
                        fontSize: "14px",
                        paddingRight: "15px",
                    }}
                >
                    {`CohortType: ${metadata?.cohortType}`}
                </span>
            </span>
        </div>
    );
};

export default CohortTitle;
