import { SeverityLevel } from "@microsoft/applicationinsights-web";

export enum Severity {
    SEV2 = "Sev2",
    SEV3 = "Sev3",
}

export class TenantInsightsException extends Error {
    severityLevel: SeverityLevel;

    constructor(severity: Severity, m: string) {
        super(severity.concat(m));
        this.severityLevel =
            severity === Severity.SEV2 ? SeverityLevel.Error : SeverityLevel.Warning;
        Object.setPrototypeOf(this, TenantInsightsException.prototype);
    }
}

export class MtrException extends Error {
    constructor(severity: Severity, m: string) {
        super(severity.concat(m));
        Object.setPrototypeOf(this, MtrException.prototype);
    }
}
