import * as API from "api/index";
import { getDataTableName } from "components/CommonFunnelComponents/CommonFunnel/commonFunnelHelper";
import { getAdditionalFiltersKustoColumnIndex } from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import { CommonFunnelFilterOptions } from "components/CommonFunnelComponents/CommonFunnelFilter/types";
import { FunnelType } from "config/FunnelConfig";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";

export const getAdditionalPivotsFilterOptions = async (
    funnelType: FunnelType,
    application: string,
    date: string,
) => {
    const queryParams = {
        application,
        date,
        tableName: getDataTableName(funnelType, application),
    };

    const response = await API.getKustoResponse({
        queryName: `${funnelType.toLowerCase()}FunnelFilterChoices`,
        platform: Workload.WEB,
        queryParams,
    });

    return response?.data;
};

export const formatAdditionalFunnelFilterOptions = (
    funnelType: FunnelType,
    funnelJson: KustoResponseType<string | number>,
): CommonFunnelFilterOptions => {
    const funnelDataTable = funnelJson.Tables[0];
    const funnelData: CommonFunnelFilterOptions = {};

    const additionalPivotsToColumnIndex =
        getAdditionalFiltersKustoColumnIndex(funnelType);
    Object.keys(additionalPivotsToColumnIndex).forEach((element) => {
        funnelData[element] = [];
    });

    funnelDataTable.Rows.forEach((row) => {
        Object.keys(additionalPivotsToColumnIndex).forEach((pivot) => {
            const pivotOption = row[additionalPivotsToColumnIndex[pivot]] as string;

            if (pivotOption) {
                funnelData[pivot].push({ key: pivotOption, text: pivotOption });
            }
        });
    });

    return funnelData;
};
