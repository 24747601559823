export const webconsumerHealthChart: string = `
//webConsumerHealthChart
let dt = "\${date}";
let appName = "\${application}";
let cohort = "\${cohort}";
let pivot = "All";
let host = "OneDriveWOPI";
ConsumerOverviewScore
| where Application == appName and CohortType == cohort and PivotName == pivot and CohortName != "All" and Host == host
| extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
| summarize Count = dcount(CohortName) by Date, Score_Color
| extend Current = (Date == dt)
| project Date, Color=Score_Color, Count, Current
| order by Date asc, Color asc`;
