import React from "react";
import { Panel, PanelType, Stack } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { FunnelDataType } from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { CommonHistoricalTrendsPanelProps } from "components/CommonFunnelComponents/CommonHistoricalTrendsPanel/types";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { getFunnelConfig } from "config/FunnelConfig";
import { chartSizes } from "pages/common";
import { getChartColor } from "utils/Helpers";
import "components/CommonFunnelComponents/CommonHistoricalTrendsPanel/style.css";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";

const stagesWithoutData = (funnelData: FunnelDataType): [string, number][] => {
    const stages: { [stageName: string]: { level: number; total: number } } = {};
    Object.keys(funnelData).forEach((date) => {
        for (const stage of funnelData[date]) {
            if (!stages[stage.stage.friendlyName]) {
                stages[stage.stage.friendlyName] = { level: stage.level, total: 0 };
            }
            stages[stage.stage.friendlyName].total += stage.slices[0]?.total;
        }
    });

    const invalidStages: [string, number][] = [];
    Object.keys(stages)
        .filter((stage) => stages[stage].total === 0)
        .forEach((stage) => invalidStages.push([stage, stages[stage].level]));
    return invalidStages;
};

export const CommonHistoricalTrendsPanel = ({
    showPanel,
    setShowPanel,
    funnelType,
    funnelDataOverTime,
    funnelDropOffOverTime,
    funnelData,
    filters,
    getAdditionalTelemetryFields,
}: CommonHistoricalTrendsPanelProps) => {
    const funnelConfig = getFunnelConfig(funnelType);

    const historicalTrendsChartHeight = 400;
    const invalidStages = stagesWithoutData(funnelData);

    const chartLabels = () => {
        const stages = funnelData[filters.date].map((obj) => obj.stage.friendlyName);
        return stages
            .filter(
                (stage) =>
                    stage !== funnelConfig.defaultStage &&
                    !invalidStages.find((value) => value[0] === stage),
            )
            .map((stage, index) => {
                return { color: getChartColor(index), key: stage };
            });
    };

    const dropOffChartsLabels = () => {
        const dropOffStages = funnelData[filters.date].map(
            (obj): [number, string] => {
                return [obj.level, obj.dropOff.friendlyName];
            },
        );
        return dropOffStages
            .filter(
                (stage) =>
                    !invalidStages.find((value) => value[1] - 1 === stage[0]) &&
                    stage[1] !== "",
            )
            .map((stage, index) => {
                return {
                    color: getChartColor(index),
                    key: stage[1],
                };
            });
    };

    return (
        <Panel
            isLightDismiss
            isOpen={showPanel}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                sendUserActionTelemetryEvent(
                    {
                        userActionName: "CopilotFunnelHistoricalTrendsFlyoutClose",
                        commandSurface: UserActionCommandSurface.Taskpane,
                    },
                    getAdditionalTelemetryFields
                        ? getAdditionalTelemetryFields()
                        : {},
                );
                setShowPanel(false);
            }}
            headerText={`Historical Trends of the ${funnelConfig.displayName} Funnel`}
            headerClassName="flyoutHeader"
        >
            <Stack data-testid="commonFunnelFlyout">
                <Stack.Item grow>
                    <h2 className="commonPageBlockSubTitle">Funnel Over Time</h2>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={chartLabels()}
                        xDataKey="date"
                        values={funnelDataOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
                <div className="break" />
                <Stack.Item grow>
                    <h2 className="commonPageBlockSubTitle">
                        Drop from Previous Stage Over Time
                    </h2>
                    <TogglingLineChart
                        kind={togglingLineChartKind.percentagesWithValues}
                        labels={dropOffChartsLabels()}
                        xDataKey="date"
                        values={funnelDropOffOverTime}
                        width={chartSizes.width}
                        height={historicalTrendsChartHeight}
                    />
                </Stack.Item>
                <div className="break" />
            </Stack>
        </Panel>
    );
};
