export const teamsMeetingProductInsights: string = `
//Teams product insights-Meeting
let MeetingInsights = Teams_ProductInsights
| where OmsTenantId =="\${id}"
| where SubWorkload == "Meeting"
| extend Value = tolong(Value);
MeetingInsights
| where Metric == "Duration"
| project Metric = "Meeting Duration", Date, Name, Value, Total
| join  kind = inner 
     (datatable (Name:string, Order:int)["0-1",1,"1-5",2,"5-10",3,"10-30",4,"30-60",5,"60+",6])
on Name
| order by Date asc, Order asc 
| project-away Name1, Order
| order by Date asc
| union (
     MeetingInsights
     | where Metric == "Platform"
     | project
          Metric,
          Date,
          Name,
          Value,
          Total
     | order by Date asc
)
| union(
     MeetingInsights
     | where Metric == "Participant Count"
     | project Metric, Date, Name , Value, Total
     | join  kind = inner 
          (datatable (ParticipantCount:string, Order:int)["1",1,"2",2,"3-5",3,"6-10",4,"11-50",5,"51-100",6,"101-250",7,"251-500",8,"501-1000",9,"1000+",9])
     on $left.Name == $right.ParticipantCount
     | order by Date asc, Order asc 
     | project-away ParticipantCount, Order
) 
| union (
     MeetingInsights
     | where Metric == "Session Quality"
     | where Date == "\${date}"
     | project Metric, Date, Name, Value, Total     
)
| union (
     MeetingInsights
     | where Metric == "Modality"
     | where Date == "\${date}"
     | project
          Metric,
          Date,
          Name,
          Value,
          Total  
          | extend ord = iff(Name =="Audio", 1, iff(Name =="AppSharing", 3, iff(Name =="Video",2,4)))
          | order by ord asc
          | project-away ord            
)
`;
