import { webAvgCohortMetrics, webAvgCohortMetrics_V2 } from "./webAvgCohortMetrics";
import { webCoreMetrics, webCoreMetrics_V2 } from "./webCoreMetrics";
import { webMetaData, webMetaData_V2 } from "./webMetaData";
import { webTenantEnvironmentData } from "./webTenantEnvironmentData";
import {
    webOfficeTenantScore,
    webOfficeTenantScore_V2,
} from "./webOfficeTenantScore";
import { webSparkLineScores, webSparkLineScores_V2 } from "./webSparkLineScores";
import { webTenantList } from "./webTenantList";
import { webTenantMetrics, webTenantMetricsChart } from "./webTenantMetrics";

const inProductWebDrilldownQueries: {} = {
    webAvgCohortMetrics,
    webAvgCohortMetrics_V2,
    webCoreMetrics,
    webCoreMetrics_V2,
    webMetaData,
    webMetaData_V2,
    webTenantEnvironmentData,
    webOfficeTenantScore,
    webOfficeTenantScore_V2,
    webSparkLineScores,
    webSparkLineScores_V2,
    webTenantList,
    webTenantMetrics,
    webTenantMetricsChart,
};

export default inProductWebDrilldownQueries;
