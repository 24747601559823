import React from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { splitCommonPageBlockIntoThree } from "pages/common";
import {
    HealthDistributionTrendProps,
    HealthDistributionTrendChartData,
} from "pages/MTR/types";
import { HealthDistributionTrendChart } from "./HealthDistributionTrendChart";
import { healthTrendConstants } from "./HealthDistributionTrendConstants";
import { HealthDistributionTrendTable } from "./HealthDistributionTrendTable";

export const HealthDistributionTrend = ({
    payload,
    loading,
    platform,
}: HealthDistributionTrendProps) => {
    const data: HealthDistributionTrendChartData[] = [];
    const timePeriod = {};

    if (!loading && payload && payload?.rows?.length > 0) {
        payload.rows.forEach((element) => {
            const date = String(element[0]);
            const status = String(element[1]);
            const count = Number(element[2]);
            if (!timePeriod[date]) {
                timePeriod[date] = { Date: date };
            }
            timePeriod[date][status] = count;
        });
        Object.keys(timePeriod).forEach((date) => {
            data.push(timePeriod[date]);
        });
    }

    return (
        <Stack.Item styles={splitCommonPageBlockIntoThree}>
            <p className="commonPageBlockTitle healthTrendPlatform">
                {platform}{" "}
                <span className="healthTrendConstants">
                    (MAU = {healthTrendConstants[platform]?.minMau}, App ={" "}
                    {healthTrendConstants[platform]?.application})
                </span>
            </p>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <Stack horizontalAlign="center">
                    <HealthDistributionTrendChart data={data} />
                    {payload?.rows[0] && (
                        <HealthDistributionTrendTable
                            payload={payload.rows.filter((i) => i[3] === true)}
                        />
                    )}
                </Stack>
            )}
        </Stack.Item>
    );
};
