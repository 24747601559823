export const webconsumerCrossAppTenantStatus: string = `
//webConsumerCrossAppTenantStatus (for movers/shakers)
let app = "\${application}";
let currMonthdate = "\${date}";
let cohort = "\${cohort}";
let pivot = "All";
let host = "OneDriveWOPI";
let prevMonthdate = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
ConsumerOverviewScore
| where CohortType == cohort and Application == app and Date == currMonthdate and PivotName == pivot and CohortName != "All" and Host == host
| extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
| project CohortName, Application,
    Composite_Score = round(Score, 2),
    CurrentStatus = Score_Color,
    Composite_MoMChange = MoM_Score,
    MAU_Score = MAU,
    MAURate_Score = MAURate * 100, MAURate_Score_Color = MAURate_Color,
    CoreActionMAURate_Score = MEURate * 100, CoreActionMAURate_Score_Color = MEURate_Color,
    RetentionRate_Score = RetentionRate * 100, RetentionRate_Score_Color = RetentionRate_Color,
    Word_Performance_AppColor = Performance_Color.Word,
    Excel_Performance_AppColor = Performance_Color.Excel,
    PowerPoint_Performance_AppColor = Performance_Color.PowerPoint,
    Word_ASHA_AppColor = Reliability_Color.Word,
    Excel_ASHA_AppColor = Reliability_Color.Excel,
    PowerPoint_ASHA_AppColor = Reliability_Color.PowerPoint,
    Word_Feedback_AppColor = Feedback_Color.Word,
    Excel_Feedback_AppColor = Feedback_Color.Excel,
    PowerPoint_Feedback_AppColor = Feedback_Color.PowerPoint
| join kind =inner (
    ConsumerOverviewScore
    | where CohortType == cohort and Application == app and Date == prevMonthdate and PivotName == pivot and CohortName != "All" and Host == host
    | extend CohortName = iff(pivot == "All", CohortName, strcat(CohortName ," - ",iff(isempty(PivotValue), "None", PivotValue)))
    | project CohortName, Application,
        Composite_Score = round(Score, 2),
        PreviousStatus = Score_Color,
        Composite_MoMChange = MoM_Score,
        MAU_Score = MAU,
        MAURate_Score = MAURate * 100, MAURate_Score_Color = MAURate_Color,
        CoreActionMAURate_Score = MEURate * 100, CoreActionMAURate_Score_Color = MEURate_Color,
        RetentionRate_Score = RetentionRate * 100, RetentionRate_Score_Color = RetentionRate_Color,
        Word_Performance_AppColor_Prev = Performance_Color.Word,
        Excel_Performance_AppColor_Prev = Performance_Color.Excel,
        PowerPoint_Performance_AppColor_Prev = Performance_Color.PowerPoint,
        Word_ASHA_AppColor_Prev = Reliability_Color.Word,
        Excel_ASHA_AppColor_Prev = Reliability_Color.Excel,
        PowerPoint_ASHA_AppColor_Prev = Reliability_Color.PowerPoint,
        Word_Feedback_AppColor_Prev = Feedback_Color.Word,
        Excel_Feedback_AppColor_Prev = Feedback_Color.Excel,
        PowerPoint_Feedback_AppColor_Prev = Feedback_Color.PowerPoint
    ) on CohortName, Application
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | project CohortName, Application, Composite_Score, CurrentStatus, PreviousStatus, Composite_MoMChange = Composite_Score - Composite_Score1, MAU_Score, MAU_MoMChange =  round((todouble(MAU_Score-MAU_Score1)/MAU_Score1)*100,2),
        MAURate_Score, MAURate_MoMChange = round((todouble(MAURate_Score-MAURate_Score1)/MAURate_Score1),2), MAURate_Score_Color,
        CoreActionMAURate_Score, CoreActionMAURate_MoMChange = round((todouble(CoreActionMAURate_Score-CoreActionMAURate_Score1)/CoreActionMAURate_Score1),2), CoreActionMAURate_Score_Color,
        RetentionRate_Score, RetentionRate_MoMChange = round((todouble(RetentionRate_Score-RetentionRate_Score1)/RetentionRate_Score1),2), RetentionRate_Score_Color,
        Word_Performance_AppColor, Word_Performance_AppColor_Prev,
        Excel_Performance_AppColor, Excel_Performance_AppColor_Prev, 
        PowerPoint_Performance_AppColor, PowerPoint_Performance_AppColor_Prev,
        Word_ASHA_AppColor, Word_ASHA_AppColor_Prev,
        Excel_ASHA_AppColor,Excel_ASHA_AppColor_Prev,
        PowerPoint_ASHA_AppColor, PowerPoint_ASHA_AppColor_Prev,
        Word_Feedback_AppColor,
        Excel_Feedback_AppColor,
        PowerPoint_Feedback_AppColor,
        Word_Feedback_AppColor_Prev,
        Excel_Feedback_AppColor_Prev,
        PowerPoint_Feedback_AppColor_Prev`;
