import { InsightsRoutes } from "utils/Links";

export * from "utils/Constants";

export const crossPlatformLinks = {
    Web: {
        base: InsightsRoutes.Tenant.path,
    },
    Win32: {
        base: InsightsRoutes.Win32Tenant.path,
    },
};

export const colorMap = {
    0: "Gray",
    1: "Regression",
    2: "DeepRed",
    3: "Red",
    4: "Yellow",
    5: "Blue",
};

export enum CROSSPLATFORM_INDICES {
    CURRENTSTATUS = 12,
    PREVIOUSSTATUS = 13,
}
