import React, { useEffect, useRef, useState } from "react";
import { Stack, IDropdownOption, FontIcon, Dropdown } from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import TenantTitle from "components/CustomComponents/TenantTitle/TenantTitle";
import { CollabDataTable } from "components/CollabComponents/CollabDataTable";
import { CollabFunnel } from "components/CollabComponents/CollabFunnel";
import { CollabStageChart } from "components/CollabComponents/CollabStageChart";
import { TenantTitleCohortTags } from "config/PlatformConfig";
import { fetchCollabData } from "pages/Collab/ApiHandler";
import {
    getInitialCollabFilterState,
    getInitialCollabState,
} from "pages/Collab/CollabHelper";
import { CollabFilterState, CollabState } from "pages/Collab/types";
import { commonPageBlock, commonPageStyle, filterStackTokens } from "pages/common";
import "./style.css";
import AppInsights from "utils/AppInsights";
import { logLevelBasedView } from "utils/AppInsightsHelper";
import {
    checkMissingDrilldownQueryParams,
    computeQueryParams,
    extractQueryParams,
} from "utils/Helpers";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";
import { dropdownStyles } from "utils/WebConstants";

const pageName = "Collab Drilldown";

const apps = ["Word", "Excel", "PowerPoint", "WXP"];

const Collab = () => {
    const queryParams = checkMissingDrilldownQueryParams(extractQueryParams());
    const initialMount = useRef(true);
    const [filters, setFilters] = useState<CollabFilterState>(
        getInitialCollabFilterState(queryParams),
    );
    const [state, setState] = useState<CollabState>(getInitialCollabState());

    useSendLaunchEvent("Office Web Collab Tier 2", flattenFilters(filters));

    useEffect(() => {
        document.title = "Office Web Collab Tier 2";
        AppInsights.getInstance().TrackPage(document.title);
        fetchCollabData(filters, setState);
        logLevelBasedView(document.title, filters["level"]);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
            return;
        }
        if (filters.date !== queryParams["date"]) {
            queryParams["date"] = filters.date;
            window.history.pushState(
                "",
                "",
                window.location.pathname + computeQueryParams(queryParams),
            );
        }
        fetchCollabData(filters, setState);

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.UiHost, filters.date]);

    const onFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            const updatedFilter = {
                ...filters,
                [dropdownFor]: item.key.toString(),
            };
            setFilters(updatedFilter);
            sendFiltersChangeTelemetryEvent(updatedFilter);
        };
    };
    return (
        <>
            <Stack disableShrink styles={commonPageStyle}>
                <div className="orgHeader">
                    <br />
                    <span id="drilldownTitle"> {pageName} </span>
                </div>
                <div className="drilldownTenantName">
                    <TenantTitle
                        metadata={state["metadata"]}
                        level={filters["level"]}
                        loading={state.isFetching}
                        tenantStatus={state["metadata"]["status"]}
                        cohortTags={TenantTitleCohortTags}
                    />
                </div>
                <br />
                <Stack horizontal tokens={filterStackTokens}>
                    <FontIcon iconName="Filter" className="filterIcon" />
                    <Dropdown
                        placeholder="Select an option"
                        label="Time Period"
                        // Type 'string[]' is not assignable to type 'IDropdownOption<any>[]'.
                        options={state["filterOptions"]["dateOptions"] as any[]}
                        selectedKey={filters["date"]}
                        styles={dropdownStyles}
                        onChange={onFilterChange("date")}
                        className="filterDropdown"
                    />
                    <Dropdown
                        placeholder="Select an option"
                        label="UiHost"
                        // Type 'string[]' is not assignable to type 'IDropdownOption<any>[]'.
                        options={state["filterOptions"]["uihostOptions"] as any[]}
                        selectedKey={filters["UiHost"]}
                        styles={dropdownStyles}
                        onChange={onFilterChange("UiHost")}
                        className="filterDropdown"
                    />
                </Stack>
                <Stack>
                    <Stack.Item styles={commonPageBlock}>
                        <CollabDataTable
                            payload={state["collabFunnel"]["funnelData"]}
                            loading={state.isFetching}
                        />
                    </Stack.Item>
                    {apps.map((app) => {
                        return (
                            <Stack
                                styles={commonPageBlock}
                                key={app}
                                className="collabAppLevel"
                            >
                                <ApplicationTitle app={app} />
                                {state["collabFunnel"]["chartData"][app] ? (
                                    <Stack horizontal>
                                        <CollabFunnel
                                            payload={
                                                state["collabFunnel"]["chartData"][
                                                    app
                                                ].filter(
                                                    (i) =>
                                                        i["Date"] ===
                                                        filters["date"],
                                                )[0]
                                            }
                                            app={app}
                                        />
                                        <CollabStageChart
                                            payload={
                                                state["collabFunnel"]["chartData"][
                                                    app
                                                ]
                                            }
                                            app={app}
                                        />
                                    </Stack>
                                ) : (
                                    <EmptyTableContainer />
                                )}
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>
        </>
    );
};

export default Collab;
