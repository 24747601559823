import React, { useEffect } from "react";
import DashboardTiles from "components/InsightsComponents/DashboardTiles";
import "pages/InsightsLanding/style.css";
import { PageNotFound } from "components/InsightsComponents/PageNotFound";
import AppInsights from "utils/AppInsights";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

interface InsightsLandingProps {
    navCollapsed: boolean;
    setNavCollapsed: (navCollapsed: boolean) => void;
    errorCode?: number;
}

const InsightsLanding = ({
    navCollapsed,
    setNavCollapsed,
    errorCode,
}: InsightsLandingProps) => {
    // App insights tracking
    useEffect(() => {
        const pageTitle = "OPG Insights";
        document.title = pageTitle;
        AppInsights.getInstance().TrackPage(pageTitle);
    }, []);

    useSendLaunchEvent("OPG Insights Landing Page");

    return (
        <>
            {errorCode === 404 && <PageNotFound />}
            <DashboardTiles
                navCollapsed={navCollapsed}
                setNavCollapsed={setNavCollapsed}
            />
        </>
    );
};

export default InsightsLanding;
