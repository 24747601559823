import React, { useCallback, useEffect, useState } from "react";
import {
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Stack,
} from "@fluentui/react";
import UXSchemaDrillDownChart from "components/QuIPSurveyReportComponents/UxSchemaDrillDownChart/UXSchemaDrillDownChart";
import { getUxSchemaFromEcs } from "api";
import AppIdMapping from "pages/QuIP/AppIdToProductMapping";
import { executeQuipSurveyQuery } from "pages/QuIP/KustoHelper";
import {
    ProcessedUIFunnelDataValue,
    UxSchemaDrillDownChartProps,
} from "pages/QuIP/SurveyFunnelDrillDown20/types";
import {
    processRawUIFunnelData,
    uxSchemaToTree,
} from "pages/QuIP/SurveyFunnelDrillDown20/Utils";

const UxSchemaDrillDownChart: React.FC<UxSchemaDrillDownChartProps> = ({
    startDate,
    endDate,
    application,
    audience,
    platform,
    campaignId,
    height,
    width,
}: UxSchemaDrillDownChartProps) => {
    const [uxSchema, setUxSchema] = useState();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [uxSchemaTree, setUxSchemaTree] = useState<any>();
    const [uiFunnelData, setUiFunnelData] = useState<
        Map<string, ProcessedUIFunnelDataValue>
    >(new Map<string, ProcessedUIFunnelDataValue>());
    const [loading, setLoading] = useState<boolean>(true);

    const fetchEcsUxSchema = useCallback(async () => {
        // Fetch data from ECS.
        const appNameFromId = AppIdMapping.get(application);
        const response = await getUxSchemaFromEcs(appNameFromId, audience);
        Object.entries(response).forEach(([key, value]: [string, any]) => {
            const keyArray = key.split(".");
            if (keyArray.length > 1 && keyArray[1] === campaignId) {
                if (value.SurveyTemplate?.UxSchema) {
                    setUxSchema(value.SurveyTemplate?.UxSchema);
                } else {
                    throw new Error("UxSchema not found in ECS");
                }
            } else {
                throw new Error("Campaign ID not found in ECS");
            }
        });
    }, [application, audience, campaignId]);

    const fetchUIFunnelData = useCallback(async () => {
        if (
            !startDate ||
            !endDate ||
            !campaignId ||
            !platform ||
            !audience ||
            !application
        )
            return;
        setIsError(false);
        setLoading(true);
        try {
            const generalFunnelData = await executeQuipSurveyQuery(
                "quipSurveyUIFunnelData",
                {
                    startTime: `'${startDate}'`,
                    endTime: `'${endDate}'`,
                    surveyIds: campaignId,
                    platforms: platform,
                    audience: JSON.stringify(audience ? [audience] : "All"),
                    appIds: JSON.stringify(application ? [application] : "All"),
                },
                false,
            );
            await fetchEcsUxSchema();
            const processedFunnelData = processRawUIFunnelData(generalFunnelData);
            if (processRawUIFunnelData.length > 0) {
                setUiFunnelData(processedFunnelData);
            } else {
                throw new Error("No Funnel data found for this Campaign ID.");
            }
        } catch (e) {
            setIsError(true);
            setErrorMessage(e.message);
            setLoading(false);
            return;
        }

        setLoading(false);
    }, [
        application,
        audience,
        campaignId,
        endDate,
        fetchEcsUxSchema,
        platform,
        startDate,
    ]);

    useEffect(() => {
        fetchUIFunnelData();
    }, [fetchUIFunnelData]);

    useEffect(() => {
        if (!uxSchema || !uiFunnelData) return;
        setUxSchemaTree(uxSchemaToTree(uxSchema, uiFunnelData));
    }, [uxSchema, uiFunnelData]);

    return (
        <div>
            {isError && !loading ? (
                <MessageBar messageBarType={MessageBarType.error}>
                    Error: {errorMessage}
                </MessageBar>
            ) : loading ? (
                <Stack.Item style={{ marginTop: "10%" }}>
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>
            ) : (
                uxSchemaTree && (
                    <>
                        <UXSchemaDrillDownChart
                            width={width}
                            height={height}
                            rawNodes={uxSchemaTree}
                        />
                    </>
                )
            )}
        </div>
    );
};

export default UxSchemaDrillDownChart;
