import { macCompositeScore } from "./mac/macCompositeScore";
import { macCrossAppCompositeScore } from "./mac/macCrossAppCompositeScore";
import { macCrossAppTenantStatus } from "./mac/macCrossAppTenantStatus";
import { macDateFilters } from "./mac/macDateFilters";
import { macHealthChart } from "./mac/macHealthChart";
import { macTenantStatus } from "./mac/macTenantStatus";
import { webCompositeScore, webCompositeScore_V2 } from "./web/webCompositeScore";
import {
    webCrossAppCompositeScore,
    webCrossAppCompositeScore_V2,
} from "./web/webCrossAppCompositeScore";
import {
    webCrossAppTenantStatus,
    webCrossAppTenantStatus_V2,
} from "./web/webCrossAppTenantStatus";
import { webDateFilters } from "./web/webDateFilters";
import { webHealthChart } from "./web/webHealthChart";
import { webTenantStatus, webTenantStatus_V2 } from "./web/webTenantStatus";
import { win32CompositeScore } from "./win32/win32CompositeScore";
import { win32CrossAppCompositeScore } from "./win32/win32CrossAppCompositeScore";
import { win32CrossAppTenantStatus } from "./win32/win32CrossAppTenantStatus";
import { win32DateFilters } from "./win32/win32DateFilters";
import { win32HealthChart } from "./win32/win32HealthChart";
import { win32TenantStatus } from "./win32/win32TenantStatus";
import { crossplatformCompositeScore } from "./crossplatform/crossplatformCompositeScore";
import { crossplatformDateFilters } from "./crossplatform/crossplatformDateFilters";
import { crossplatformHealthChart } from "./crossplatform/crossplatformHealthChart";
import { crossplatformTenantStatus } from "./crossplatform/crossplatformTenantStatus";
import { teamsDateFilters } from "./teams/teamsDateFilters";
import { teamsMeetingCompositeScore } from "./teams/teamsMeetingCompositeScore";
import { teamsMeetingTenantStatus } from "./teams/teamsMeetingTenantStatus";
import { teamsChatCompositeScore } from "./teams/teamsChatCompositeScore";
import { teamsChatTenantStatus } from "./teams/teamsChatTenantStatus";
import { teamsHealthChart } from "./teams/teamsHealthChart";
import { consumerExecOverviewQueries } from "./consumer/index";

const execOverviewQueries: {} = {
    macCompositeScore,
    macCrossAppCompositeScore,
    macCrossAppTenantStatus,
    macDateFilters,
    macHealthChart,
    macTenantStatus,
    webCompositeScore,
    webCompositeScore_V2,
    webCrossAppCompositeScore,
    webCrossAppCompositeScore_V2,
    webCrossAppTenantStatus,
    webCrossAppTenantStatus_V2,
    webDateFilters,
    webHealthChart,
    webTenantStatus,
    webTenantStatus_V2,
    win32CompositeScore,
    win32CrossAppCompositeScore,
    win32CrossAppTenantStatus,
    win32DateFilters,
    win32HealthChart,
    win32TenantStatus,
    crossplatformCompositeScore,
    crossplatformDateFilters,
    crossplatformHealthChart,
    crossplatformTenantStatus,
    teamsDateFilters,
    teamsMeetingCompositeScore,
    teamsMeetingTenantStatus,
    teamsChatCompositeScore,
    teamsChatTenantStatus,
    teamsHealthChart,
    ...consumerExecOverviewQueries,
};

export default execOverviewQueries;
