import React from "react";
import {
    ComboBox,
    DatePicker,
    IComboBox,
    IComboBoxOption,
    Stack,
} from "@fluentui/react";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import {
    dateForMonthlyData,
    getDefaultTimespan,
    getMostRecentDate,
} from "pages/Performance/Win32PerformanceHelper";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents } from "utils/AppInsightsHelper";
import { Win32PerfInputUpdates } from "./types";

export const Win32PerformanceDatePicker = ({
    isMonthly,
    timespan,
    date,
    onInputChange,
}: {
    isMonthly: boolean;
    timespan: number;
    date: string;
    onInputChange: (updates: Win32PerfInputUpdates) => void;
}) => {
    const timespanOptions = (
        isMonthly ? [3, 6, 9, 12, 18, 24] : [30, 60, 90, 180, 365]
    ).map((v) => ({ key: v, text: v.toString() }));

    const onTimespanChange = (
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        value?: string,
    ) => {
        let selectedTimespan = (option?.key as number) ?? Math.round(Number(value));
        if (!(selectedTimespan > 0))
            selectedTimespan = getDefaultTimespan(isMonthly);

        AppInsights.getInstance().LogEvent(appInsightsCustomEvents.PerformanceView, {
            type: "UIControl",
            event: "TimespanChange",
            value: selectedTimespan,
        });

        onInputChange({
            timespan: selectedTimespan,
        });
    };

    const onSelectDate = (date: Date) => {
        const dateString = isMonthly
            ? dateForMonthlyData(date)
            : format(date, "yyyy-MM-dd");
        AppInsights.getInstance().LogEvent(appInsightsCustomEvents.PerformanceView, {
            type: "UIControl",
            event: "DateChange",
            value: dateString,
        });

        onInputChange({ date: dateString });
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ComboBox
                label={`${isMonthly ? "Months" : "Days"} to show`}
                options={timespanOptions}
                allowFreeform={true}
                styles={{ root: { width: 150 } }}
                text={timespan.toString()}
                onChange={onTimespanChange}
                className="filterDropdown"
            />
            <DatePicker
                label="Ending on"
                allowTextInput
                styles={{ root: { width: 150 } }}
                onSelectDate={onSelectDate}
                formatDate={(date: Date) =>
                    format(date, isMonthly ? "MMMM yyyy" : "E MMM dd, yyyy")
                }
                className="filterDropdown"
                maxDate={parseISO(getMostRecentDate(isMonthly))}
                value={parseISO(date)}
            />
        </Stack>
    );
};
