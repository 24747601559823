import React from "react";
import {
    DetailsRow,
    FontSizes,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    SelectionMode,
    DetailsList,
} from "@fluentui/react";
import { StatusIndicator } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import { HealthDistributionTableProps, TenantHealthStats } from "pages/MTR/types";
import { STATUS, TenantsWithNoDataLabel, OrderedStatusList } from "utils/Constants";
import { formatDisplayDate } from "utils/Helpers";

export const HealthDistributionTrendTable = ({
    payload,
}: HealthDistributionTableProps) => {
    const date = formatDisplayDate(String(payload[0][0]));
    const data: TenantHealthStats[] = [];
    let total = 0;

    const columns: Array<IColumn> = [
        {
            key: "Status",
            name: `${date} Status`,
            fieldName: "Status",
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            isPadded: false,
        },
        {
            key: "Count",
            name: "Tenant Count",
            fieldName: "Count",
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            isPadded: false,
        },
        {
            key: "Percent",
            name: "Tenant Percent",
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            isPadded: false,
        },
    ];

    payload
        .filter((x) => x[1] !== TenantsWithNoDataLabel)
        .forEach((element) => {
            data.push({
                Status: String(element[1]),
                Count: Number(element[2]),
                Order: Number(OrderedStatusList[String(element[1])]),
            });
            total += Number(element[2]);
        });

    const onRenderRow = (props: IDetailsRowProps) => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        customStyles.root = {
            color: "black",
            fontSize: FontSizes.mediumPlus,
        };

        return (
            <DetailsRow
                {...props}
                onRenderItemColumn={onRenderItemColumn}
                styles={customStyles}
            />
        );
    };

    const onRenderItemColumn = (
        item: TenantHealthStats,
        index: Number,
        column: IColumn,
    ) => {
        const fieldContent = String(item[column.fieldName]);
        const fieldColor = STATUS[fieldContent]
            ? STATUS[fieldContent]["color"]
            : STATUS[""]["color"];
        const fieldName = STATUS[fieldContent]
            ? STATUS[fieldContent]["name"]
            : STATUS[""]["name"];
        const fieldTooltip = STATUS[fieldContent]
            ? STATUS[fieldContent]["definition"]
            : STATUS[""]["definition"];

        switch (column.key) {
            case "Status":
                return (
                    <div title={fieldTooltip}>
                        <StatusIndicator color={fieldColor} id="statusLegend" />
                        {fieldName}
                    </div>
                );
            case "Percent":
                return <div>{((item.Count / total) * 100).toFixed(2)}%</div>;
            default:
                return <div>{fieldContent}</div>;
        }
    };

    return (
        <DetailsList
            items={data.sort((a, b) => a["Order"] - b["Order"])}
            columns={columns}
            compact
            selectionMode={SelectionMode.none}
            onRenderRow={onRenderRow}
            styles={{
                root: {
                    maxWidth: 400,
                    overflowX: "hidden",
                },
            }}
        />
    );
};
