import React from "react";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { MonthlyTrend } from "components/TenantDrilldownComponents/MonthlyTrend";
import { TenantEnvironmentProps } from "components/TenantDrilldownComponents/TenantEnvironment/types";

export const MonthlyTenantEnvironmentChart = ({
    displayName,
    payload,
    kind,
    yLabel,
    strokeWidth,
    loading,
    formatDate,
    info,
}: TenantEnvironmentProps & {
    displayName: string;
    yLabel?: string;
    formatDate?: boolean;
    info?: { message: string; link?: string };
}) => {
    return (
        <>
            <h2 className="commonPageBlockTitle">
                {displayName}
                {info && info?.message && (
                    <LinkIconButton link={info?.link} message={info?.message} />
                )}
            </h2>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <MonthlyTrend
                    payload={payload}
                    kind={kind}
                    yLabel={yLabel}
                    strokeWidth={strokeWidth}
                    formatDate={formatDate}
                />
            )}
        </>
    );
};
