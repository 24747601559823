import Axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import {
    apiRetryConfiguration,
    errorInterceptor,
    requestInterceptor,
    responseInterceptor,
} from "config/ApiInterceptor";

export class ApiClient {
    private static instance: AxiosInstance;

    public static getInstance = (): AxiosInstance => {
        if (!ApiClient.instance) {
            ApiClient.createInstance();
        }

        return ApiClient.instance;
    };

    private constructor() {
        ApiClient.getInstance();
    }

    protected static createInstance = () => {
        const responseType = "json";
        const client = Axios.create({
            responseType,
        });
        axiosRetry(client, apiRetryConfiguration);
        client.interceptors.request.use(requestInterceptor);
        client.interceptors.response.use(responseInterceptor, errorInterceptor);

        ApiClient.instance = client;
    };
}

export class UnauthenticatedApiClient {
    private static instance: AxiosInstance;

    public static getInstance = (): AxiosInstance => {
        if (!UnauthenticatedApiClient.instance) {
            UnauthenticatedApiClient.createInstance();
        }

        return UnauthenticatedApiClient.instance;
    };

    private constructor() {
        UnauthenticatedApiClient.getInstance();
    }

    protected static createInstance = () => {
        const responseType = "json";
        const client = Axios.create({
            responseType,
        });
        axiosRetry(client, apiRetryConfiguration);
        client.interceptors.response.use(responseInterceptor, errorInterceptor);

        UnauthenticatedApiClient.instance = client;
    };
}
