export const upsellFunnelDates: string = `
// ERFM Funnel Dates
database('ConsumerInsights').\${tableName}
| summarize by format_datetime(SliceDate, "yyyy-MM-dd")
| order by SliceDate desc
`;

export const upsellFunnelStages: string = `
// eRFM Funnel Stages
let allStages =
    database('ConsumerInsights').\${tableName}
    | where AggregationType == "\${intervalWindow}"
    | where iff("\${application}" == "WXP", true, AppName == "\${application}")
    | where iff("\${country}" == "All", true, ClientCountry == "\${country}")
    | where iff("\${dimeEntryPoint}" == "All" or MetricName in~ ("1-eRFM_Upsell_Funnel_EligibleToEnter", "2-eRFM_Upsell_Funnel_Enter", "3-eRFM_Upsell_Funnel_SignIn"), true, DimeEntryPoint == "\${dimeEntryPoint}")
    | where iff("\${eRFMEntryPoint}" == "All", true, eRFMEntryPoint == "\${eRFMEntryPoint}")
    | summarize Count = sum(PuidDistinctCount) by ReceivedDate, MetricName
    | order by ReceivedDate desc, MetricName asc;
let defaultDenominator = toscalar(allStages | project Count);
database('ConsumerInsights').\${tableName}_FunnelConfig
| extend JoinKey = 1
| join kind=fullouter (allStages
    | project Date = format_datetime(ReceivedDate, "yyyy-MM-dd"), JoinKey = 1)
    on JoinKey
| summarize count()
    by
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    Application,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    ToolTipMessage
| project-away count_
| join kind=fullouter  (
    allStages
    | extend
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        StageName = MetricName,
        Value = Count
    | order by Date desc
    )
    on StageName, Date
| where isnotempty(Date)
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    DenominatorColumn,
    Value,
    ToolTipMessage
| extend joinKey = DenominatorColumn
| join kind=leftouter (
    allStages
    | extend
        joinKey = MetricName,
        Date = format_datetime(ReceivedDate, "yyyy-MM-dd"),
        Total = Count
    | project Date, joinKey, Total
    )
    on joinKey, Date
| extend Total = iff(isempty(Total) or isnull(Total), defaultDenominator, Total)
| extend Percentage = round(100.0 * todouble(Value) / todouble(Total), 2)
| where isnotempty(Percentage) or CanBeNull == true
| project
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    Value,
    Total,
    Percentage,
    ToolTipMessage
| order by Date desc, StageIndex asc
`;

export const upsellFunnelFilterChoices: string = `
// eRFM Upsell Funnel Additional Pivots Choices
let AllFilterOptions = datatable(DimeEntryPoint: string, eRFMEntryPoint: string, ClientCountry: string) [
    "All", "All", "All"
];
let DimeEntryPoint=database('ConsumerInsights').\${tableName}
| distinct tostring(DimeEntryPoint);
let eRFMEntryPoint=database('ConsumerInsights').\${tableName}
| distinct tostring(eRFMEntryPoint);
let ClientCountry=database('ConsumerInsights').\${tableName}
| distinct ClientCountry;
DimeEntryPoint
| union eRFMEntryPoint
| union ClientCountry
| union AllFilterOptions
| project DimeEntryPoint, eRFMEntryPoint, ClientCountry
`;
