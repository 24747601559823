export const webconsumerCoreMetrics: string = `
// webconsumerCoreMetrics
let app = "\${application}";
let currMonthdate = "\${date}";
let cohortType = "\${cohortType}";
let cohortName = "\${cohortName}";
let pivot = "All";
let host = "OneDriveWOPI";
let prevMonthdate = format_datetime(datetime_add('Month', -1, todatetime(currMonthdate)), "yyyy-MM-dd");
ConsumerOverviewScore
    | where CohortType == cohortType and Application == app and Date == currMonthdate and PivotName == pivot and CohortName == cohortName and Host == host
    | join kind=leftouter(
        ConsumerOverviewScore
        | where CohortType == cohortType and Application == app and Date == prevMonthdate and PivotName == pivot and CohortName == cohortName and Host == host
        | project CohortName, Application, PrevScore = Score, PrevMAURate = MAURate, PrevCoreActionMAURate = MEURate, PrevRetentionRate = RetentionRate, 
            PrevP95 = todouble(Performance_Score.EUPL_P95), PrevP50 = todouble(Performance_Score.EUPL_P50), PrevASHAScore = todouble(Reliability_Score.ASHA), PrevFeedback = todouble(Feedback_Score.SAS)
    ) on Application, CohortName
    | extend RetentionRate = iff(CohortName has "New", double(null), RetentionRate)
    | extend RetentionRate_Color = iff(CohortName has "New", "Gray", RetentionRate_Color)  // Todo: remove this condition after fix on cosmos
    | project CohortName, 
        MAURate= round(MAURate * 100, 2), Color_MAURate = MAURate_Color, MoM_MAURate = round((MAURate - PrevMAURate)*100, 2),
        CoreActionMAURate = round(MEURate * 100, 2), Color_CoreActionMAURate = MEURate_Color, MoM_CoreActionMAURate = round((MEURate - PrevCoreActionMAURate)*100, 2),
        RetentionRate = round(RetentionRate * 100, 2), Color_RetentionRate = RetentionRate_Color, MoM_RetentionRate = round((RetentionRate - PrevRetentionRate)*100, 2),
        EUPLP95 = Performance_Score.EUPL_P95, Color_EUPLP95 = Performance_Color.EUPL_P95, MoM_EUPLP95 = round((todouble(Performance_Score.EUPL_P95) - PrevP95), 2),
        EUPLP50 = Performance_Score.EUPL_P50, Color_EUPLP50 = Performance_Color.EUPL_P50, MoM_EUPLP50 = round((todouble(Performance_Score.EUPL_P50) - PrevP50), 2),
        ASHA = Reliability_Score.ASHA, Color_ASHA = Reliability_Color.ASHA, MoM_ASHA = round((todouble(Reliability_Score.ASHA) - PrevASHAScore), 2),
        Feedback = round(todouble(Feedback_Score.SAS), 2), Color_Feedback = Feedback_Color.SAS, MoM_Feedback = round((todouble(Feedback_Score.SAS) - PrevFeedback), 2),
        Status = Score_Color, Score = round(Score,2), Application`;
