/**
 * Headers for a request.
 */
export interface RequestHeaders {
    [prop: string]: string;
}

export interface ApiRequestConfiguration {
    baseUrl?: string;
    headers?: RequestHeaders;
    timeout?: number;
}
export const DEFAULT_KUSTO_TIMEOUT = 120000;
export const DEFAULT_KUSTO_RETRY_COUNT = 3;

export const kustoApiResponseStatusCode = {
    isRetryable: [401, 429, 504, 520], // Token Expired , TooManyRequests , ServerTimeOut , ServiceError
    isTimedOut: 408,
    isUnauthorized: 403,
    isOK: 200,
};

export const kustoApiRequestDefaultConfiguration: ApiRequestConfiguration = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    timeout: DEFAULT_KUSTO_TIMEOUT,
};
