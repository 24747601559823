import React from "react";
import { formatDisplayDate } from "utils/Helpers";

export const HealthyTenantTrendTable = ({ data }) => {
    return (
        <table className="healthyTenantTrendTable">
            <thead>
                <tr>
                    <th>Month</th>
                    <th>Total Tenants</th>
                    <th>Unhealthy Tenants</th>
                    <th>% Unhealthy</th>
                    <th>% Healthy</th>
                </tr>
            </thead>
            <tbody>
                {data.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td>{formatDisplayDate(val.Date)}</td>
                            <td>{val.TotalTenants}</td>
                            <td>{val.UnhealthyTenants}</td>
                            <td>{val.PercentUnhealthy}%</td>
                            <td>{val.PercentHealthy}%</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
