import { getDoraDashboardLink } from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";

// construct the RISON link
export const getDoraLinkFromFilters = (doraFilters: {}, funnelType) => {
    var native_filters = "";
    Object.entries(doraFilters).map(([key, value], index) => {
        const filterName = key;
        const filterValue = value;
        const filterId = `NATIVE_FILTER-${funnelType}-${filterName}`;
        // temp fix to include ReferralCategory for DORA linkage
        if (filterValue !== "All" || filterName == "ReferralCategory") {
            native_filters =
                native_filters +
                `${
                    index == 0 ? "" : ","
                }${filterId}:(__cache:(label:'${filterValue}',validateStatus:!f,value:!('${filterValue}')),extraFormData:(filters:!((col:${filterName},op:IN,val:!('${filterValue}')))),filterState:(label:'${filterValue}',validateStatus:!f,value:!('${filterValue}')),id:${filterId},ownState:())`;
        }
    });
    var dashboardId = getDoraDashboardLink(funnelType);
    return `https://dora-nezha.microsoft.com/nezha/dashboard/${dashboardId}/?native_filters=(${native_filters})`;
};

export const getDoraParamsFromFilters = (filters: CommonFilterStateType) => {
    // remove filters that we don't want passed to DORA
    const doraFilters = { ...filters, ...filters.additionalFilters };
    delete doraFilters.additionalFilters;
    delete doraFilters.section;
    delete doraFilters.application;
    delete doraFilters.date;

    // add application & date since they have special naming requirements
    doraFilters["OfficeAppName"] = filters.application;
    doraFilters["process_date"] = filters.date;

    return doraFilters;
};
