import { isTest } from "utils/WebConstants";

export const webCrossAppCompositeScore: string = `
// webCrossAppCompositeScore
let dt = iif(isempty("\${date}"), GetCurrentDate(${isTest}), "\${date}");
let currentDate = GetCurrentDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
Score_WXP
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and Application ==  app and UiHost == "All" and WXP_Mau > \${mauCountThreshold}
| join kind=inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == currentDate
        | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    PastStatus
    | where Application == app and UiHost == 'All' and Date == currentDate 
    | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
) on OmsTenantId, Tpid
| join kind = leftouter
    (
        Score_WXP
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application ==  app and UiHost == 'All'
        | project \${levelColumnName}, PrevMonthScore = round(WXP_Score, 2)
    )
    on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, OrgName = Name, Application, 
    Composite_Score = round(WXP_Score, 2),
    Composite_MoMChange = WXP_Score - PrevMonthScore,
    ActiveUsers_Score = ActiveUsers,
    MAU_Score = WXP_Mau,
    MAURate_Score = round(MauRate*100,2), MAURate_Score_Color = Color_Mau,
    MEURate_Score = round(MeuRate*100,2), MEURate_Score_Color = Color_Meu,
    EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors,
    Retention_Score = round(ReturningMauRate*100,2), Retention_Score_Color = Color_ReturningMau,
    Word_Performance_AppColor = Word_PerfColor,
    Excel_Performance_AppColor = Excel_PerfColor,
    PowerPoint_Performance_AppColor = PPT_PerfColor,
    Word_ACEFreeUsers_AppColor = Word_ACEColor,
    Excel_ACEFreeUsers_AppColor = Excel_ACEColor,
    PowerPoint_ACEFreeUsers_AppColor = PPT_ACEColor,
    Word_ICEFreeUsers_AppColor = Word_ICEColor,
    Excel_ICEFreeUsers_AppColor = Excel_ICEColor,
    PowerPoint_ICEFreeUsers_AppColor = PPT_ICEColor,
    Word_Feedback_AppColor = Word_FbkColor,
    Excel_Feedback_AppColor = Excel_FbkColor,
    PowerPoint_Feedback_AppColor = PPT_FbkColor,
    Word_OpenInClient_AppColor = Word_OICColor,
    Excel_OpenInClient_AppColor = Excel_OICColor,
    PowerPoint_OpenInClient_AppColor = PPT_OICColor,
    Status = WXP_Score_Color,
    Parent = Tpid
| sort by Composite_Score desc , MAU_Score desc
`;

export const webCrossAppCompositeScore_V2: string = `
// webCrossAppCompositeScore
let dt = iif(isempty("\${date}"), GetCurrentDate(${isTest}), "\${date}");
let currentDate = GetCurrentDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
Score_WXP_V2 
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and Application ==  app and UiHost == "All" and WXP_Mau > \${mauCountThreshold}
| join kind=inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == currentDate
        | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project \${levelColumnName}, Name = Column1
    ) on \${levelColumnName}
| join kind=leftouter (
    PastStatus
    | where Application == app and UiHost == 'All' and Date == currentDate 
    | extend PastStatus = GetPastYearStatus(PastStatusSet, dt)
) on OmsTenantId, Tpid
| join kind = leftouter
    (
        Score_WXP_V2 
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and Application ==  app and UiHost == 'All'
        | project \${levelColumnName}, PrevMonthScore = round(WXP_Score, 2)
    )
    on \${levelColumnName}
| project PastStatus, Date, \${level} = \${levelColumnName}, OrgName = Name, Application, 
    Composite_Score = round(WXP_Score, 2),
    Composite_MoMChange = WXP_Score - PrevMonthScore,
    ActiveUsers_Score = ActiveUsers,
    MAU_Score = WXP_Mau,
    MAURate_Score = round(MauRate*100,2), MAURate_Score_Color = Color_Mau,
    MEURate_Score = round(MeuRate*100,2), MEURate_Score_Color = Color_Meu,
    EngagedEditors_Score = round(EngagedEditorsRate*100, 2), EngagedEditors_Score_Color = Color_EngagedEditors,
    Retention_Score = round(ReturningMauRate*100,2), Retention_Score_Color = Color_ReturningMau,
    Word_Performance_AppColor = Word_PerfColor,
    Excel_Performance_AppColor = Excel_PerfColor,
    PowerPoint_Performance_AppColor = PPT_PerfColor,
    Word_ASHA_AppColor = Word_ASHAColor,
    Excel_ASHA_AppColor = Excel_ASHAColor,
    PowerPoint_ASHA_AppColor = PPT_ASHAColor,
    Word_Feedback_AppColor = Word_FbkColor,
    Excel_Feedback_AppColor = Excel_FbkColor,
    PowerPoint_Feedback_AppColor = PPT_FbkColor,
    Word_OpenInClient_AppColor = Word_OICColor,
    Excel_OpenInClient_AppColor = Excel_OICColor,
    PowerPoint_OpenInClient_AppColor = PPT_OICColor,
    Status = WXP_Score_Color,
    Parent = Tpid
| sort by Composite_Score desc , MAU_Score desc
`;
