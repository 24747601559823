import React, { useState } from "react";
import {
    PrimaryButton,
    Link,
    MessageBarType,
    TeachingBubble,
    MessageBar,
} from "@fluentui/react";

const buttonId = "targetButton";
export const ForbiddenMessageBar = ({ message }) => {
    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);
    return (
        <>
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                {message}
                <PrimaryButton
                    id={buttonId}
                    onClick={() => setTeachingBubbleVisible((value) => !value)}
                    text={teachingBubbleVisible ? "Hide" : "Request Access"}
                    ariaLabel={
                        "Click on the button to follow the steps to get access"
                    }
                    styles={{
                        root: {
                            height: "23px",
                            width: "125px",
                        },
                    }}
                />
            </MessageBar>
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${buttonId}`}
                    hasCondensedHeadline={true}
                    onDismiss={() => setTeachingBubbleVisible((value) => !value)}
                >
                    {"Please request access to "}
                    <Link
                        href={
                            "https://idwebelements/GroupManagement.aspx?Group=odaUsers&Operation=join"
                        }
                        style={{ color: "white", textDecoration: "underline" }}
                        target="_blank"
                    >
                        ODA Users
                    </Link>{" "}
                    Security Group.
                </TeachingBubble>
            )}
        </>
    );
};
