import { navLinkGroups } from "components/InsightsComponents/constants";
import { IDashboardLink } from "components/InsightsComponents/types";
import { DefaultPageContact, SLAs } from "utils/Constants";
import {
    DefaultIncidentLink,
    InsightsRoutes,
    pageContactMap,
    pageIncidentLinkMap,
    pageSLAMap,
    pageWikiLinkMap,
    WikiLinks,
} from "utils/Links";
import { ContactType } from "utils/types";

export const getCurrentWikiLink = (): string => {
    return (
        pageWikiLinkMap.get(window.location?.pathname?.toLowerCase()) ??
        WikiLinks.Default
    );
};

export const getCurrentSLAText = (): string => {
    return pageSLAMap.get(window.location?.pathname?.toLowerCase()) ?? SLAs.Default;
};

// Identifies which dashboard link matches current URL
// Expects path to be window.location
export const getNavKey = (location: Location): string => {
    for (let i = 0; i < navLinkGroups.length; i++) {
        const navLinkGroup: IDashboardLink = navLinkGroups[i] as IDashboardLink;
        const navKey: string = getNavKeyHelper(location, navLinkGroup);
        if (navKey) {
            return navKey;
        }
    }

    return null;
};

export const getNavKeyHelper = (
    location: Location,
    navLink: IDashboardLink,
): string => {
    if (!navLink.links) {
        // Find pathname in URL (must handle both relative and absolute paths)
        // We have relative paths for customer insights hrefs and absolute paths for external web pages
        if (navLink.href.startsWith("/")) {
            return navLink.href === location.pathname ? navLink.key : null;
        }

        const navLinkURL = new URL(navLink.href);
        return navLinkURL.pathname === location.pathname ? navLink.key : null;
    }

    for (let i = 0; i < navLink.links.length; i++) {
        const subNavLink: IDashboardLink = navLink.links[i];
        const navKey: string = getNavKeyHelper(location, subNavLink);
        if (navKey) {
            return navKey;
        }
    }
};

export const isInvalidInsightsRoute = (): boolean => {
    return Object.keys(InsightsRoutes).every((key) => {
        return (
            window.location.pathname?.toLowerCase() !==
            InsightsRoutes[key].path.toLowerCase()
        );
    });
};

// Can either be on the landing page from the home route or an invalid route
export const isLandingPage = (): boolean => {
    if (window.location.pathname === InsightsRoutes.InsightsLanding.path) {
        return true;
    }

    return isInvalidInsightsRoute();
};

export const getCurrentIncidentLink = (): string => {
    return (
        pageIncidentLinkMap.get(window.location?.pathname?.toLowerCase()) ??
        DefaultIncidentLink
    );
};

export const getCurrentPageContact = (): ContactType => {
    return (
        pageContactMap.get(window.location?.pathname?.toLowerCase()) ??
        DefaultPageContact
    );
};
