import {
    erfmDates,
    erfmTable,
    erfmAppChart,
    erfmCountryChart,
} from "queries/ERFM/erfmQueries";
import {
    upsellFunnelDates,
    upsellFunnelStages,
    upsellFunnelFilterChoices,
} from "queries/ERFM/erfmFunnelQueries";

export const erfmQueries = {
    erfmDates,
    erfmTable,
    erfmAppChart,
    erfmCountryChart,
    upsellFunnelDates,
    upsellFunnelStages,
    upsellFunnelFilterChoices,
};
