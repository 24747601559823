import { jStat } from "jstat";
import { StatTestResult } from ".././types";

// Uses a two-sample z-test for proportions to highlight statistically significant deltas when comparing two datasets
// See: https://microsoft.sharepoint-df.com/:p:/t/OXODIG-Team/EfBZagTxGz1OhBxBzZymPoEByhi0jfG5SGuUYm7fkYd35w?e=IWG9Ch
// See: https://vitalflux.com/two-sample-z-test-for-proportions-formula-examples/
export const getComparisonPValue = (
    scoreA: number,
    sessionCountA: number,
    scoreB: number,
    sessionCountB: number,
): number => {
    const fixedScoreA = scoreA / 100.0;
    const fixedScoreB = scoreB / 100.0;

    // Proportions can't be zero for the z-test or greater than 1 for the z-test
    if (fixedScoreA <= 0 || fixedScoreA > 1 || fixedScoreB <= 0 || fixedScoreB > 1) {
        return NaN;
    }

    return jStat.fn.twoSidedDifferenceOfProportions(
        fixedScoreA,
        sessionCountA,
        fixedScoreB,
        sessionCountB,
    );
};

const CONFIDENCE_VALUE_95 = 0.05;
const CONFIDENCE_VALUE_99 = 0.01;

const getHolmBonferroni = (
    numOfComparisons: number,
    rank: number,
    alpha: number,
) => {
    return alpha / (numOfComparisons - rank + 1);
};

// Uses a Holm-Bonferroni correction to check if the comparison is significant at the 95% and 99% confidence intervals
export const getComparisonResult = (
    pValue: number,
    numOfComparisons: number,
    rank: number,
): StatTestResult => {
    if (pValue < getHolmBonferroni(numOfComparisons, rank, CONFIDENCE_VALUE_99)) {
        return StatTestResult.HighlySignificant;
    }

    if (pValue < getHolmBonferroni(numOfComparisons, rank, CONFIDENCE_VALUE_95)) {
        return StatTestResult.Significant;
    }

    return StatTestResult.NotSignificant;
};
