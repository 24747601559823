import React from "react";
import {
    Stack,
    Pivot,
    PivotItem,
    IPivotItemProps,
    Icon,
    mergeStyles,
} from "@fluentui/react";
import { appStyles, PivotSize } from "utils/Constants";

const miniAppIconStyleWithPadding = mergeStyles({
    selectors: {
        "> *": {
            verticalAlign: "sub",
            height: 20,
            width: 20,
            marginRight: 5,
        },
    },
});
interface AppOverviewIconPanelProps {
    selectedApp: string;
    apps: string[];
    onAppClick: (item: string, value: string) => void;
    disabledAppList?: string[];
}

const customRendererWrapper = (appName: string, disabledAppList: string[]) => {
    return function customRenderer(
        link?: IPivotItemProps,
        defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
    ): JSX.Element | null {
        if (!link || !defaultRenderer) {
            return null;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                <Icon
                    iconName={link.itemIcon}
                    className={miniAppIconStyleWithPadding}
                    style={{ opacity: disabledAppList?.includes(appName) ? 0.5 : 1 }}
                />
                {defaultRenderer({
                    ...link,
                    itemIcon: undefined,
                })}
            </span>
        );
    };
};

const AppOverviewIconPanel = ({
    apps,
    onAppClick,
    selectedApp,
    disabledAppList,
}: AppOverviewIconPanelProps) => {
    return (
        <Stack.Item>
            <Pivot
                linkSize={PivotSize.large}
                onLinkClick={(item?: PivotItem) =>
                    onAppClick("application", item.props.itemKey as string)
                }
                selectedKey={selectedApp}
            >
                {apps.map((el, idx) => {
                    return (
                        <PivotItem
                            headerText={el}
                            itemKey={el}
                            key={idx}
                            itemIcon={appStyles[el]?.iconName}
                            onRenderItemLink={customRendererWrapper(
                                el,
                                disabledAppList,
                            )}
                            headerButtonProps={{
                                disabled: disabledAppList?.includes(el) || false,
                                style: {
                                    color: disabledAppList?.includes(el)
                                        ? "#cabfbff5"
                                        : "black",
                                },
                            }}
                        />
                    );
                })}
            </Pivot>
        </Stack.Item>
    );
};

export default AppOverviewIconPanel;
