import React from "react";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    footerDownArrowFillColor,
    footerDownArrowStrokeColor,
    footerHeight,
    footerWidth,
} from "components/CommonFunnelComponents/CommonFunnelFooter/constants";
import { CommonFunnelFooterProps } from "components/CommonFunnelComponents/CommonFunnelFooter/types";
import "components/CommonFunnelComponents/CommonFunnelFooter/style.css";

export const CommonFunnelFooter = ({
    dropOffName,
    dropOffDeltaValue,
    dropOffDeltaPercent,
    isLastStage,
}: CommonFunnelFooterProps) => {
    const emptyRect = (
        <svg width={footerWidth} height={footerHeight}>
            <rect height={footerHeight} width={footerWidth} opacity={0} />
        </svg>
    );

    return (
        <div>
            {isLastStage ? (
                <div>
                    {emptyRect}
                    <div className="commonFunnelFooterTextContainer">
                        {emptyRect}
                    </div>
                </div>
            ) : (
                <div>
                    <svg width={footerWidth} height={footerHeight}>
                        <g>
                            <path
                                transform="rotate(180 16.5 25)"
                                stroke={footerDownArrowStrokeColor}
                                id="commonFunnelFooterDownArrow"
                                d="m0,24.9652l16.5,-14.4652l16.5,14.4652l-8.25,0l0,14.5348l-16.5,0l0,-14.5348l-8.25,0z"
                                fill={footerDownArrowFillColor}
                            />
                        </g>
                    </svg>
                    <div className="commonFunnelFooterTextContainer">
                        <div
                            className="commonFunnelFooterStageName"
                            title={dropOffName.friendlyName}
                        >
                            {dropOffName.friendlyName}
                        </div>
                        <div
                            className="commonFunnelFooterValue"
                            title={`${dropOffDeltaValue}`}
                        >
                            {isLastStage
                                ? "All"
                                : getCompactValue(dropOffDeltaValue)}
                        </div>
                        <div className="commonFunnelFooterPercent">
                            [{isLastStage ? 100 : dropOffDeltaPercent}
                            %]
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
