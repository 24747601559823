import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import * as API from "api";
import {
    MacTenantEnvironment,
    TeamsTenantEnvironment,
    Win32TenantEnvironment,
    WebTenantEnvironment,
} from "components/TenantDrilldownComponents/TenantEnvironment/index";
import { TenantEnvironmentWrapperProps } from "components/TenantDrilldownComponents/TenantEnvironment/types";
import { Workload } from "config/PlatformConfig";
import { formatTenantEnvironmentData } from "pages/InProductDrillDown/ApiHandler";
import { TenantEnvironmentType } from "pages/InProductDrillDown/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { INPRODUCT_DRILLDOWN_MESSAGES } from "utils/Messages";

const getTenantEnvironmentResponseTemplate = (): TenantEnvironmentType => {
    return {
        os: {},
        currency: {},
        geographicDistribution: {},
        licenseDistribution: {},
        openDocument: [],
        geoDataMapping: new Map<string, {}>(),
        OfficeDiagnosticConsentLevel: {},
        WindowsTelemetryLevel: {},
        OfficeUsage: {},
        OfficeUsageBreakDown: {},
        browser: {},
    };
};
const initialState = {
    tenantEnvironment: getTenantEnvironmentResponseTemplate(),
    loading: true,
};

export const TenantEnvironmentWrapper = ({
    platform,
    filters,
    metricFilters,
}: TenantEnvironmentWrapperProps) => {
    const [environmentState, setEnvironmentState] = useState(initialState);
    const [error, setError] = useState(null);
    const getPayloadData = async () => {
        try {
            let queryParams = [];
            const levelParams = API.getQueryParamsForLevel(filters["level"]);
            queryParams["id"] = filters["id"];
            queryParams["app"] = filters["rankOn"];
            queryParams["date"] = metricFilters["Date"];
            queryParams = { ...levelParams, ...queryParams };
            const tenantEnvironment = await API.miscellaneousData(
                queryParams,
                platform,
            );
            const geoDataMappingSelected = new Map<string, {}>();
            try {
                const tenantEnvironmentResponse = formatTenantEnvironmentData(
                    tenantEnvironment?.data,
                    geoDataMappingSelected,
                    platform,
                );
                setEnvironmentState((environmentState) => {
                    let environmentData = getTenantEnvironmentResponseTemplate();
                    environmentData = {
                        ...environmentData,
                        ...tenantEnvironmentResponse,
                    };
                    environmentData.geoDataMapping = geoDataMappingSelected;
                    const updatedEnvironmentData = {
                        ...environmentState,
                        tenantEnvironment: { ...environmentData },
                    };
                    return { ...updatedEnvironmentData, loading: false };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV2,
                        "InProductDrilldownTenantEnvironmentProcessingFailed",
                    ),
                    {
                        message:
                            "Failure processing response data from InProduct Drilldown Tenant Environment page query",
                        responseCodes: {
                            environmentState: tenantEnvironment.status,
                        },
                    },
                    error,
                );
                setEnvironmentState((environmentState) => {
                    return { ...environmentState, loading: false };
                });
            }
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "InProductDrilldownTenantEnvironmentDataFetchFailed",
                ),
                {
                    message:
                        "Failure in fetching data for InProductDrilldown Tenant Environment page",
                },
                error,
            );
            setEnvironmentState((environmentState) => {
                return { ...environmentState, loading: false };
            });
            setError(INPRODUCT_DRILLDOWN_MESSAGES.TENANT_ENVIRONMENT_DISPLAY_ERROR);
        }
    };
    useEffect(() => {
        if (metricFilters !== "") {
            setEnvironmentState(initialState);
            setError(null);
            getPayloadData();
        }
        // ADO 7955411: useCallback refactor on getPayloadData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricFilters, filters]);

    if (error) return <ErrorFallback message={error} />;
    else if (
        isEqual(environmentState, getTenantEnvironmentResponseTemplate()) &&
        !environmentState.loading
    ) {
        return (
            <ShimmeredDetailsListWrapper
                items={[]}
                message={
                    INPRODUCT_DRILLDOWN_MESSAGES.NO_TENANT_ENVIRONMENT_INFO_DISPLAY_BANNER
                }
            />
        );
    }
    switch (platform) {
        case Workload.WIN32:
            return (
                <Win32TenantEnvironment
                    tenantEnvironment={environmentState.tenantEnvironment}
                    loading={environmentState.loading}
                    filters={filters}
                    metricFilters={metricFilters}
                />
            );
        case Workload.MAC:
            return (
                <MacTenantEnvironment
                    tenantEnvironment={environmentState.tenantEnvironment}
                    loading={environmentState.loading}
                    filters={filters}
                    metricFilters={metricFilters}
                />
            );
        case Workload.TEAMS:
            return (
                <TeamsTenantEnvironment
                    tenantEnvironment={environmentState.tenantEnvironment}
                    filters={filters}
                    metricFilters={metricFilters}
                    loading={environmentState.loading}
                />
            );
        case Workload.WEB:
            return (
                <WebTenantEnvironment
                    tenantEnvironment={environmentState.tenantEnvironment}
                    filters={filters}
                    metricFilters={metricFilters}
                    loading={environmentState.loading}
                />
            );
        default:
            return <> </>;
    }
};
