import React from "react";
import { IColumn, Icon, TooltipHost } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import {
    AIScorecardColumns,
    MTEandTVScorecardColumns,
} from "components/AIScorecardComponents/config";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import { AIScorecardTableType } from "components/AIScorecardComponents/types";

export const getAIMBRFeatureScorecardColumns = (
    aiScorecardColumns,
    cadence: string,
) => {
    const columns: IColumn[] = [];

    aiScorecardColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (
                <>
                    {getColumnDisplayName(column.name, column.displayName, cadence)}
                    {column.tooltip && (
                        <TooltipHost content={column.tooltip}>
                            <Icon iconName="info" className="tableColumnDefintion" />
                        </TooltipHost>
                    )}
                </>
            ) as any,
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 80,
            maxWidth: column.minWidth ?? 80,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                column.className ? column.className : "alignRightHeader"
            }`,
            styles: column.style,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};

const getColumnDisplayName = (
    colName: string,
    defaultDisplayName: string,
    cadence: string,
) => {
    switch (colName) {
        case "RetentionRate":
            return cadence === "Weekly" ? "W1 Retention Rate" : "M1 Retention Rate";
        case "Tried":
            return cadence === "Weekly" ? "WAU" : "MAU";
        case "FeatureTriedPercent":
            return cadence === "Weekly" ? "% Feature WAU" : "% Feature MAU";
        default:
            return defaultDisplayName;
    }
};

export const getAIScorecardColumns = () => {
    const columns: IColumn[] = [];

    AIScorecardColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (
                <>
                    {column.displayName}
                    {column.tooltip && (
                        <TooltipHost content={column.tooltip}>
                            <Icon iconName="info" className="tableColumnDefintion" />
                        </TooltipHost>
                    )}
                </>
            ) as any,
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 100,
            maxWidth: column.minWidth ?? 100,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                column.className ? column.className : "alignRightHeader"
            }`,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};

export const getGroups = (
    scorecardData: AIScorecardTableType[],
    groupKey = "Capability",
) => {
    const groups = [];
    if (scorecardData?.length !== 0) {
        let currentGroup = scorecardData[0][groupKey];
        let start = 0;
        scorecardData.forEach((value, i) => {
            if (value[groupKey] !== currentGroup) {
                groups.push({
                    key: currentGroup,
                    name: currentGroup,
                    startIndex: start,
                    count: i - start,
                    level: 0,
                });
                start = i;
                currentGroup = value[groupKey];
            }
        });
        groups.push({
            key: currentGroup,
            name: currentGroup,
            startIndex: start,
            count: scorecardData.length - start,
            level: 0,
        });
    }
    return groups;
};

export const getMTEandTVScorecardColumns = () => {
    const columns: IColumn[] = [];

    MTEandTVScorecardColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (
                <>
                    {column.displayName}
                    {column.tooltip && (
                        <TooltipHost
                            content={column.tooltip}
                            onTooltipToggle={(isTooltipVisible) => {
                                if (isTooltipVisible) {
                                    sendAIScorecardUserActionTelemetryEvent(
                                        {
                                            userActionName:
                                                "M365ScorecardTableColumnTooltipActivate",
                                            commandSurface:
                                                UserActionCommandSurface.Canvas,
                                        },
                                        {
                                            TableColumnName: column.displayName,
                                        },
                                    );
                                }
                            }}
                        >
                            <Icon iconName="info" className="tableColumnDefintion" />
                        </TooltipHost>
                    )}
                </>
            ) as any,
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 160,
            maxWidth: column.minWidth ?? 160,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                column.className ? column.className : "alignRightHeader"
            }`,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};
