export const teamsChatProductInsights: string = `
// Chat ProductInsights
let currentDate = GetCurrentTeamsDate(false);
let Chat_Insights =  Teams_ProductInsights
| where OmsTenantId =="\${id}"
| where SubWorkload == "Chat"
| extend Value = tolong(Value);
Chat_Insights
| where Metric in ("Type", "Feature", "Platform")
| extend Metric = iff(Metric == "Type", "ChatType", Metric)
| project Metric, Date, Name, Value, Total
| order by Date asc
| union (
     Chat_Insights
     | where Metric == "OS"
     | where Date == currentDate
     | project  Metric = "OS", Date, Name, Value, Total
     | order by Date asc
)
`;
