import React, { useEffect, useMemo, useState } from "react";
import { IDropdownOption, PivotItem, Stack } from "@fluentui/react";
import AddInFilterPanel from "components/AddInComponents/AddinFilterPanel";
import { AddInTable } from "components/AddInComponents/AddInTable";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { OverviewAppsPanel } from "components/OverviewComponents/OverviewPanel/OverviewAppsPanel";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { fetchData } from "pages/AddIns/ApiHandler";
import {
    AddInsFilterState,
    AddInsState,
    AddInsSearchState,
} from "pages/AddIns/types";
import { commonPageBlock, commonPageStyle } from "pages/common";
import "pages/common.css";
import AppInsights from "utils/AppInsights";
import { logFilterUsage, logUsageOfAppView } from "utils/AppInsightsHelper";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import { registerOverviewIcons } from "utils/IconRegistration";
import { ADDIN_MESSAGES } from "utils/Messages";
import "pages/AddIns/style.css";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const AddInsDashboard = () => {
    registerOverviewIcons();
    const config = useMemo(() => getPageConfig(DrilldownPages.ADDINS), []);

    const [filters, setFilters] = useState<AddInsFilterState>(() => {
        AppInsights.getInstance().TrackPage(document.title);
        const filtersFromQueryParams = extractQueryParams();
        const initialFilters: AddInsFilterState = {
            date: "",
            cohort: config.defaults.cohort,
            publisher: config.defaults.publisher,
            application: config.defaults.app,
        };

        return { ...initialFilters, ...filtersFromQueryParams };
    });

    const [error, setError] = useState<string>("");
    const [data, setData] = useState<AddInsState>({
        dateOptions: [],
        performanceScore: { items: [], columns: [] },
        reliabilityScore: { items: [], columns: [] },
        loading: true,
    });
    const [searchedData, setSearchedData] = useState<AddInsSearchState>({
        performaceRows: [],
        reliabiltyRows: [],
    });

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        setData((data) => {
            return { ...data, loading: true };
        });
        const effectiveFilters: AddInsFilterState = {
            application: filters.application,
            cohort: filters.cohort,
            date: filters.date,
            publisher: filters.publisher,
        };

        fetchData(effectiveFilters, setData, setError, setFilters, config);
        setUpDocumentUrl(effectiveFilters, config.pageTitle);
    }, [
        filters.application,
        filters.cohort,
        filters.date,
        filters.publisher,
        config,
    ]);

    useEffect(() => {
        setSearchedData({
            performaceRows: data.performanceScore.items,
            reliabiltyRows: data.reliabilityScore.items,
        });
    }, [data.performanceScore.items, data.reliabilityScore.items]);

    const onFilterChange = (filterName: string) => {
        return (ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            const updatedFilter = { ...filters, [filterName]: option["key"] };
            logFilterUsage(document.title, filterName, option.key.toString());
            setUpDocumentUrl(updatedFilter, config.pageTitle);
            setFilters(updatedFilter);
            sendFiltersChangeTelemetryEvent(updatedFilter);
        };
    };

    const onAppClick = (item: PivotItem) => {
        const app = item.props.headerText;
        const updatedFilter = { ...filters, application: app };
        setUpDocumentUrl(updatedFilter, config.pageTitle);
        setFilters(updatedFilter);
        logUsageOfAppView(document.title, app);
    };

    const onSearchTextChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        text?: string,
    ) => {
        if (text) {
            const textUpper = text.toUpperCase();
            const perfRows = data.performanceScore.items.filter(
                (i) =>
                    i.AddInFriendlyName.toString().toUpperCase().indexOf(textUpper) >
                        -1 ||
                    i.AddInProgId.toString().toUpperCase().indexOf(textUpper) > -1,
            );
            const relRows = data.reliabilityScore.items.filter(
                (i) =>
                    i.AddInFriendlyName.toString().toUpperCase().indexOf(textUpper) >
                        -1 ||
                    i.AddInProgId.toString().toUpperCase().indexOf(textUpper) > -1,
            );

            setSearchedData({
                performaceRows: perfRows,
                reliabiltyRows: relRows,
            });
        } else {
            setSearchedData({
                performaceRows: data.performanceScore.items,
                reliabiltyRows: data.reliabilityScore.items,
            });
        }
    };

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader">{config.pageHeader}</div>
                </Stack.Item>
                <Stack className="commonStickyPanel">
                    <AddInFilterPanel
                        panelFilters={filters}
                        dateOptions={data.dateOptions}
                        onFilterChange={onFilterChange}
                        onSearchTextChange={onSearchTextChange}
                    />
                    <OverviewAppsPanel
                        onAppClick={onAppClick}
                        filterApp={filters.application}
                        crossApps={[]}
                        apps={config.appsList}
                    />
                </Stack>
                <Stack styles={commonPageBlock}>
                    <div className="addInsSubTitle">Performance Insights</div>
                    <AddInTable
                        rows={searchedData.performaceRows}
                        headers={data.performanceScore.columns}
                        loading={data.loading}
                        message={ADDIN_MESSAGES.TOP_PERFORMANCE}
                    />
                </Stack>
                <Stack styles={commonPageBlock}>
                    <div className="addInsSubTitle">Reliability Insights</div>
                    <AddInTable
                        rows={searchedData.reliabiltyRows}
                        headers={data.reliabilityScore.columns}
                        loading={data.loading}
                        message={ADDIN_MESSAGES.TOP_RELIABILTY}
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default AddInsDashboard;
