import { filterChangeChainType } from "./types";

export const cohortAnalysisChartsWidth = "100%";
export const cohortAnalysisChartsHeight = 550;
export const startingNumberOfOptionsToShow = 5;
export const maxNumberOfOptionsToShow = 20;
export const stepForSlider = 5;
export const defaultApp = "Word";
export const defaultFilterChangeChain: filterChangeChainType[] = [
    {
        filterName: "app",
        filterChain: ["date", "cohortCategory", "cohort", "errorname"],
    },
    {
        filterName: "date",
        filterChain: ["cohortCategory", "cohort", "errorname"],
    },
    {
        filterName: "cohortCategory",
        filterChain: ["cohort", "errorname"],
    },
    {
        filterName: "cohort",
        filterChain: ["errorname"],
    },
    {
        filterName: "errorname",
        filterChain: [],
    },
];
export const paramsToLook = [
    "app",
    "date",
    "level",
    "cohortCategory",
    "cohort",
    "errorname",
];
