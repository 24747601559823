import { webconsumerCoreMetrics } from "./webconsumerCoreMetrics";
import { webconsumerMetaData } from "./webconsumerMetaData";
import { webconsumerSparkLineScores } from "./webconsumerSparkLineScores";
import { webconsumerCustomerList } from "./webconsumerCustomerList";
import {
    webconsumerCustomerAppMetrics,
    webconsumerCustomerAppMetricsChart,
} from "./webconsumerCustomerAppMetrics";
import { webconsumerRetention } from "./webconsumerRetention";
import { webconsumerLicense } from "./webconsumerLicense";

const consumerProductDrilldownQueries: {} = {
    webconsumerCoreMetrics,
    webconsumerMetaData,
    webconsumerSparkLineScores,
    webconsumerCustomerList,
    webconsumerCustomerAppMetrics,
    webconsumerTenantMetricsChart: webconsumerCustomerAppMetricsChart,
    webconsumerRetention,
    webconsumerLicense,
};

export default consumerProductDrilldownQueries;
