import { ErrorSpikeThreshold } from "utils/WebConstants";

export const INVALID_URL =
    "We are sorry, the page you requested cannot be found.  The URL may be misspelled or the page you're looking for is no longer available.";

export const NO_DATA_MESSAGES = {
    NO_DATA: "No Data Available!",
    NO_DATA_CATEGORY: "No items available for this category.",
    NO_GOV_DATA: "US Gov cohort data is not available before Feb, 2022",
    NO_DATA_FOR_FILTER: "No data available for the selected filter combination.",
};

export const CONFIDENTIAL_MESSAGE =
    "This page contains Microsoft Confidential Information. Please do not share images or data.";

export const USER_FORBIDDEN_BANNER = "You do not have access to the";

export const LOOKBACK_SUBSCRIPT =
    "Chart displays data going back 28 days from the date selected.";

export const RELIABILITY_MESSAGES = {
    ONE_PERCENT: "*List contains errors that affected more than 1% of users.",
    TOP_20: "*List contains top 20 errors. There are no errors that affected more than 1% of users.",
    ERROR_SPIKE_COLUMNS: {
        ERROR_SPIKE_CONDITION: `The list of errors that impacted more than ${
            ErrorSpikeThreshold * 100
        }% of SharePoint Online Host and All UiHosts users.`,
        SPIKE: "Spike : The error impact had a sudden spike in current month, considering same tenant over time.",
        HIGH_TREND:
            "Significant: Higher users/sessions affected in current month compared to other tenants for an error.",
        HIGH_USER_IMPACT:
            "High User Impact: Significant number of users are impacted by an error in current month.",
    },
};

export const COLLAB_STAGES = {
    SignedInUsers: "Commercial Users Signed in WXP during the reporting window.",
    CloudActiveUsers:
        "Commercial WXP Active and Cloud Enabled Users that used ODSP/MSFT cloud for documents during the reporting window.",
    Collaborators:
        "A group of two or more users who read the same document after one of them has edited the document.",
    Contributors:
        "A user who edits a document that read or updated by one or more other users after their update.",
    AsyncCoEditors:
        "A group of two or more users who make edits to the same document within the observation period.",
};

export const MONTHLYTREND_MESSAGE = "*Chart includes values > 0.5%";

export const SESSION_DISTRIBUTION_MESSAGES = {
    CIRCLEMESSAGE: "*Circle size is proportionate to session percent.",
};

export const ERRORLOOKUP_MESSAGES = {
    HOST_NOTE:
        "*Host and UiHost Distribution charts are not affected by Host and UiHost filters.",
};

export const PERFORMANCE_MESSAGES = {
    TOP_10: "*Table contains top 10 entries & click on entry to see monthly trends.",
};

export const LEGEND_CLICK = "*Click on legend items to toggle.";

export const ERROR_FALLBACK_MESSAGES = {
    ERROR_FALLBACK:
        " the page or try again after some time. If the issue persists, please reach out to",
    ERROR_FALLBACK_MESSAGE_FOR_URL: " with your current URL.",
};

export const NETWORKSCORE_MESSAGES = {
    NO_DATA: "*No data indicated as (-1)",
    READ_MORE: "Read more about network score",
    NETWORKSCORE_SITE:
        "Visit the network score team's internal Power Apps site for this tenant.\nVisit aka.ms/netconnectivity for instructions on how to get access.",
};

export const EXEMPTION_PORTAL_MSG = {
    NOTE_ARCHIVED: "Note was successfully archived.",
    SOMETHING_WENT_WRONG: "Something went wrong. Please try again!",
    NOTE_CONFIRMATION:
        "The note will not show up in the portal. Do you want to continue?",
};

export const ACE_HEALTH_SCORE_MESSAGES = {
    READ_MORE: "Read more about ACE Health Score",
};

export const PSS_HEALTH_SCORE_MESSAGES = {
    READ_MORE: "Read more about Product Support Tickets",
    DRILLDOWN: "IMEU dashboard",
};

export const ADDIN_MESSAGES = {
    TOP_RELIABILTY: "*Table contains top 200 entries & default sorted by #Crashes",
    TOP_PERFORMANCE:
        "*Table contains top 200 entries and has data for Typical Load Duration > 1s. Data is sampled at 2%",
    ADDIN_INFO_DISPLAY_ERROR: "Add In details cannot be displayed at the moment. ",
};

export const GENERIC_BANNER_MESSAGES = {
    TENANT_INFO_DISPLAY_ERROR: "Tenant details cannot be displayed at the moment. ",
};

export const EXEC_OVERVIEW_MESSAGES = {
    HEALTH_INFO_DISPLAY_ERROR: "Health details cannot be displayed at the moment. ",
    NO_HEALTH_INFO_DISPLAY_BANNER:
        "No items available to display Health Distribution",
};

export const M365_MESSAGES = {
    TENANT_LIST_QUERY_FAIL:
        "Tenant list could not be loaded. Please check if you have access.",
    TENANT_DETAILS_QUERY_FAIL:
        "Tenant/Tpid Details could not be loaded. Please check the URL.",
};

export const INPRODUCT_DRILLDOWN_MESSAGES = {
    TENANT_ENVIRONMENT_DISPLAY_ERROR:
        "Tenant Environment cannot be displayed at the moment. ",
    NO_TENANT_ENVIRONMENT_INFO_DISPLAY_BANNER:
        "No items available to display Tenant Environment",
};

export const ADMINCENTER_MESSAGES = {
    NO_TPID_DATA: "This feature is not available at TopParent (Tpid) level",
    NO_PLATFORM_DATA: "This feature is not available in this platform",
    NO_DATA_LOADING_FAILED:
        "Data loading failed for this, please retry. If error persists please contact us",
    NO_DATA_HISTORIC_CHART: "No data available for historic trends",
    NO_SIGNIFICANT_MESSAGE:
        "No significant deteriorations found. Please change 'Type' filter to see minor deteriorations ",
    NO_RESULT_SELECTED_FILTERS: "No results found. Please change selected filters",
};

export const DRILLDOWN_ERROR_MESSAGES = "cannot be displayed at the moment. ";

export const MTR_MESSAGES = {
    MTR_INFO_DISPLAY_ERROR:
        "MTR Dashboard cannot be displayed at the moment. Please try again later.",
};

export const CONSUMERDAY0_MESSAGES = {
    NO_DATA: "No data available for this filter combination",
    TOP_10_Country: "*Only top 10 countries are shown",
    TOP_10_DC: "*Only top 10 datacenters are shown",
    ASHA_INSIGHTS_NOTE:
        "*Only top 5 insights are shown, and all insights are statistically significant",
    ASHA_INSIGHTS_HOWTO:
        "[Click on the arrow drop-offs in the funnel above to see ASHA insights]",
    UNABANDONED_MISSING: "User Abandonment stage is not available yet.",
    STAGEANDFILTERCOMBINATION_MISSING:
        "The selected stage and filter combinations are not applicable. Defaults to FirstSessions Stage",
    SCENARIO_DISTRIBUTION:
        "*Percentage distribution of scenarios are a percentage of the All scenario",
    REFERRER_PLATFORM_DEFINITION:
        "Indicates from which Harmony Platform the session came to Office for the Web",
    ORIGINAL_REFERRER_DEFINITION: "Indicates where the user came to Harmony from",
    ABANDONMENT_INSIGHTS: "Shows the drop% from previous stage for each metrics",
};

export const CONSUMERSCORECARD_MESSAGES = {
    RETENTION_NOTE:
        "• Retention (M1R, M3R) & CoreAction (Day0Metrics) apply only to New Users",
    NOTE_SOMETHING_WENT_WRONG: "Something went wrong. Please try again!",
    NOTE_SAVED_SUCCESSFULLY: "Note Saved Successfully!",
    NOTE_INVALID:
        "Note is empty/invalid or startDate > endDate, please verify and try again.",
    NOTE_EMPTY: "No notes found, please add.",
    CLICK_SEGMENT_LINKS: "Click on Segment to see the trend",
    ASHA_INSIGHTS_NOTE:
        "• Top 5 Insights are currently only provided for the OneDrive Host -> Free Users -> New Users segment, and these insights are statistically significant",
    STAT_SIG_NOTE: "• σ denotes a statistically significant change",
};

export const CONSUMERCAMAU_MESSAGES = {
    PERIOD_BYCADENCE:
        "• Both Monthly and Daily Trends and Snapshot are based on RL28 days.",
};

export const SECURITY_VULNERABILITIES_MESSAGES = {
    READ_MORE: "Read more about security vulnerabilities",
};

export const CONSUMERHEALTH_MESSAGES = {
    IN_PREVIEW: "This is Work in Progress - Beta version",
};

export const CONSUMERFUNNELS_MESSAGES = {
    HARMONY_DATA_AVAILABILITY:
        "Data in the funnel before 12/09/2023 does not have critical Harmony telemetry fixes, and is not recoverable. Stage1 however, is correct for all dates.",
    WORK_IN_PROGRESS:
        "This funnel is a work in progress. Please avoid using it unless you are familiar with which parts are complete and suitable for use.",
};

export const ERFM_MESSAGES = {
    CLICK_SEGMENT_LINKS: "Click on a segment to see trends and more analysis.",
    NO_DATES_DATA: "ERFM has no dates data.",
    NO_TABLE_DATA: "ERFM has no table data.",
    FETCH_FAILURE: "Failed to fetch or format ERFM date options and/or metrics",
    NO_DATA_AVAILABLE: "No data available.",
};

export const COPILOT_MESSAGES = {
    NO_TPID_DATA:
        "Data is not available at TopParent (Tpid) level. Please select a tenant.",
    NO_LICENSE: "This tenant does not have M365 Copilot Licenses",
    USERJOURNEYFUNNEL_NO_DATA_MESSAGE:
        "No data available for the selected filter combination. Data is available for Commercial User Segments only.",
    ENGAGEMENTMETRICS_WARNING:
        "Due to telemetry bug in apps, Engagement Metrics may show >100% before Apr 2024",
    NO_DATA_INTENT_ANALYSIS:
        "Currently, data is available for Word app on a weekly basis. Monthly reporting and additional applications are in progress and will be gradually available over the coming weeks. If you are uncertain whether your team is part of the Intent effort, please contact OPGContentAnalysis.",
};
