export const webconsumerRetention: string = `
// webconsumerRetention
let cohortType = "\${cohortType}"; 
let host = "OneDriveWOPI";
let app = "\${application}";
ConsumerRetention
| where Host == host and Application == app and CohortType == cohortType and Date=="\${date}"
| project-away Host, Application, MonthsAgo, DaysSinceEndOfMonth
| project CohortName, UserCount, RetentionRate_1stPeriod = RetentionRate_1stPeriod/100, RetentionRate_2ndPeriod = RetentionRate_2ndPeriod/100,
RetentionRate_3rdPeriod = RetentionRate_3rdPeriod/100, RetentionRate_4thPeriod = RetentionRate_4thPeriod/100, RetentionRate_5thPeriod = RetentionRate_5thPeriod/100,
RetentionRate_6thPeriod = RetentionRate_6thPeriod/100, RetentionRate_7thPeriod = RetentionRate_7thPeriod/100, RetentionRate_8thPeriod= RetentionRate_8thPeriod/100,
RetentionRate_9thPeriod = RetentionRate_9thPeriod/100, RetentionRate_10thPeriod = RetentionRate_10thPeriod/100, RetentionRate_11thPeriod = RetentionRate_11thPeriod/100, RetentionRate_12thPeriod = RetentionRate_12thPeriod/100
| order by CohortName asc
`;
