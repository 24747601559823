import {
    TeamsMetricType,
    ColorFillsType,
    AppMetricsFillColorsType,
} from "components/TenantDrilldownComponents/TeamsMetrics/types";
import { TeamsSubWorkload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { appStyles } from "utils/Constants";

const chatTypeColorFills: ColorFillsType = {
    desktop: "#b35806",
    mobile: "#f1a340",
    ios: "#fee0b6",
    android: "#d8daeb",
    web: "#998ec3",
    default: "#542788",
};

const appMetricsFillColors: AppMetricsFillColorsType = {
    [TeamsMetricType.SESSION_QUALITY]: {
        good: "#4dac26",
        major: "#d7191c",
        default: "#fdae61",
    },
    [TeamsMetricType.MESSAGES_SENT]: chatTypeColorFills,
    [TeamsMetricType.CHAT_SENDER]: chatTypeColorFills,
    [TeamsMetricType.CHAT_READER]: chatTypeColorFills,
    [TeamsMetricType.OS]: {
        Windows: "#a6cee3",
        Android: "#b2df8a",
        Mac: "#33a02c",
        iOS: "#e31a1c",
        Other: "#fdbf6f",
        Unknown: "#cab2d6",
        default: "black",
    },
};

export const formatTeamsMetrics = (
    teamsMetrics: KustoResponseType<string | number>,
    subWorkload: string,
) => {
    const teamsAppMetricsTable = teamsMetrics.Tables[0];
    const miscellaneous = {};
    let miscData = {};
    const displayMaping = {};
    const groupMapping = {};
    const commonMetrics = {};
    teamsAppMetricsTable.Rows.forEach((x) => {
        const metric = x[1];

        if (!(metric in commonMetrics)) {
            commonMetrics[metric] = {};
            displayMaping[metric] = x[5];
            groupMapping[metric] = x[4];
        }

        commonMetrics[metric][x[2]] = x[3];
    });
    const flattenCommonMetrics = [];
    Object.keys(commonMetrics).forEach((x) => {
        const tmp = commonMetrics[x];
        tmp["Metric"] = x;
        tmp["Display"] = displayMaping[x];
        tmp["Group"] = groupMapping[x];
        flattenCommonMetrics.push(tmp);
    });

    if (subWorkload === TeamsSubWorkload.MEETING) {
        teamsAppMetricsTable.Rows.forEach((element) => {
            const metric = element[0];
            const name = element[2] === "" ? "(Blank)" : element[2];
            const value = element[3];
            const total = element[4] ?? 1;
            const percent = Number(((value / total) * 100).toFixed(2));
            const date = element[1];

            const fillColor = (metric, name) => {
                if (metric === TeamsMetricType.SESSION_QUALITY)
                    return (
                        appMetricsFillColors[metric][name] ??
                        appMetricsFillColors[metric]["default"]
                    );

                return appStyles[name]["color"];
            };
            switch (metric) {
                case TeamsMetricType.MEETING_DURATION:
                case TeamsMetricType.PARTICIPANT_COUNT:
                case TeamsMetricType.PLATFORM:
                case TeamsMetricType.LICENSE_DISTRIBUTION:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                    if (!(date in miscellaneous[metric]))
                        miscellaneous[metric][date] = {};

                    if (
                        percent > 0.5 ||
                        metric === TeamsMetricType.PARTICIPANT_COUNT
                    )
                        miscellaneous[metric][date][name] = percent;
                    break;
                case TeamsMetricType.SESSION_QUALITY:
                case TeamsMetricType.OFFICE_USAGE_BREAKDOWN:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = [];

                    miscellaneous[metric].push({
                        name,
                        value,
                        total,
                        fill: fillColor(metric, name),
                    });
                    break;

                case TeamsMetricType.OFFICE_USAGE:
                case TeamsMetricType.MODALITY:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = [];

                    if (metric === TeamsMetricType.OFFICE_USAGE)
                        miscellaneous[metric].push({
                            name: date,
                            Teams: percent,
                        });
                    else {
                        miscellaneous[metric].push({
                            name,
                            Percent: percent,
                        });
                    }
                    break;
                default:
                    break;
            }
        });

        miscData = {
            duration: miscellaneous[TeamsMetricType.MEETING_DURATION],
            participantCount: miscellaneous[TeamsMetricType.PARTICIPANT_COUNT],
            sessionQuality: miscellaneous[TeamsMetricType.SESSION_QUALITY],
            platform: miscellaneous[TeamsMetricType.PLATFORM],
            officeUsage: miscellaneous[TeamsMetricType.OFFICE_USAGE],
            officeUsageBreakdown:
                miscellaneous[TeamsMetricType.OFFICE_USAGE_BREAKDOWN],
            modality: miscellaneous[TeamsMetricType.MODALITY],
        };
    } else {
        teamsAppMetricsTable.Rows.forEach((element) => {
            const metric = element[0];
            const name = element[2] === "" ? "(Blank)" : element[2];
            const value = element[3];
            const total = element[4] ?? 1;
            const percent = Number(((value / total) * 100).toFixed(2));
            const date = element[1];

            const fillColor = (metric, name) => {
                if (metric in appMetricsFillColors) {
                    return (
                        appMetricsFillColors[metric][name] ??
                        appMetricsFillColors[metric]["default"]
                    );
                }
                return appStyles[name]["color"];
            };
            switch (metric) {
                case TeamsMetricType.CHAT_TYPE:
                case TeamsMetricType.FEATURE:
                case TeamsMetricType.LICENSE_DISTRIBUTION:
                case TeamsMetricType.PLATFORM:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = {};
                    if (!(date in miscellaneous[metric]))
                        miscellaneous[metric][date] = {};

                    miscellaneous[metric][date][name] = percent;
                    break;
                case TeamsMetricType.CHAT_SENDER:
                case TeamsMetricType.CHAT_READER:
                case TeamsMetricType.OS:
                case TeamsMetricType.MESSAGES_SENT:
                case TeamsMetricType.OFFICE_USAGE_BREAKDOWN:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = [];

                    miscellaneous[metric].push({
                        name,
                        value,
                        total,
                        fill: fillColor(metric, name),
                    });
                    break;
                case TeamsMetricType.OFFICE_USAGE:
                case TeamsMetricType.MODALITY:
                case TeamsMetricType.USER_TYPE:
                    if (!(metric in miscellaneous)) miscellaneous[metric] = [];

                    if (metric === TeamsMetricType.USER_TYPE)
                        miscellaneous[metric].push({
                            name,
                            Count: value,
                        });
                    else if (metric === TeamsMetricType.OFFICE_USAGE)
                        miscellaneous[metric].push({
                            name: date,
                            Teams: percent,
                        });
                    else {
                        miscellaneous[metric].push({
                            name,
                            Percent: percent,
                        });
                    }
                    break;
                default:
                    break;
            }
            miscData = {
                chatType: miscellaneous[TeamsMetricType.CHAT_TYPE],
                feature: miscellaneous[TeamsMetricType.FEATURE],
                chatSender: miscellaneous[TeamsMetricType.CHAT_SENDER],
                chatReader: miscellaneous[TeamsMetricType.CHAT_READER],
                os: miscellaneous[TeamsMetricType.OS],
                platform: miscellaneous[TeamsMetricType.PLATFORM],
                messagesSent: miscellaneous[TeamsMetricType.MESSAGES_SENT],
                officeUsage: miscellaneous[TeamsMetricType.OFFICE_USAGE],
                officeUsageBreakdown:
                    miscellaneous[TeamsMetricType.OFFICE_USAGE_BREAKDOWN],
                userType: miscellaneous[TeamsMetricType.USER_TYPE],
                license: miscellaneous[TeamsMetricType.LICENSE_DISTRIBUTION],
            };
        });
    }
    return miscData;
};
