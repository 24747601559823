import React from "react";
import { XAxis, YAxis, Bar, BarChart, Tooltip } from "recharts";
import { Stack, IStackStyles } from "@fluentui/react";
import {
    ComparisonType,
    getDeltaSpan,
} from "components/AshaComponents/AshaTopInsightsWidgetUtils";
import { commonPageBlock, horizontalStackTokens } from "pages/common";
import {
    AshaTopInsightsWidgetProps,
    FunnelStageAshaInsight,
} from "pages/ConsumerDay0Funnel/types";
import { CONSUMERDAY0_MESSAGES } from "utils/Messages";

const funnelBarHeight = 220;
const funnelBarWidth = 70;

const StagesColor = {
    Word: {
        stage: "#2b7cd3",
        dropOff: "#d5e5f6",
    },
    Excel: {
        stage: "#20874f",
        dropOff: "#d6f5e4",
    },
    PowerPoint: {
        stage: "#cf593a",
        dropOff: "#f5dcd6",
    },
};

const getExtraDetailSpan = (
    type: ComparisonType,
    insight: FunnelStageAshaInsight,
): JSX.Element => {
    return (
        <span className="topInsightsExtraDetail">
            {getExtraDetailText(insight, type)}
        </span>
    );
};

const getFunnelComparisonInsightsDescriptiveText = (
    insight: FunnelStageAshaInsight,
): string => {
    const sessionsCount: number = insight.HitVetoOrErrorSessionCount ?? 0;
    const encounteredBySessionsText =
        sessionsCount > 0
            ? ` (encountered by ${sessionsCount.toLocaleString()} sessions) `
            : " ";

    return `${encounteredBySessionsText}`;
};

const getExtraDetailText = (
    insight: FunnelStageAshaInsight,
    type: ComparisonType,
): string => {
    if (type === ComparisonType.Veto) {
        return `(${insight.Pillar} pillar)`;
    }

    if (type === ComparisonType.Error) {
        return `(${insight.Pillar} pillar \u2192 ${insight.Veto} veto)`;
    }

    return "";
};

const getInsightLine = (insight: FunnelStageAshaInsight): JSX.Element => {
    const pillar = insight.Pillar;
    const veto = insight.Veto;
    const error = insight.Error;
    const delta = insight.Delta;
    const key = `${pillar}_${veto}_${error}`;

    const name = error ?? veto ?? pillar;
    const type = error
        ? ComparisonType.Error
        : veto
        ? ComparisonType.Veto
        : ComparisonType.Pillar;

    return (
        <li key={key} className="topInsightsEntry">
            {getDeltaSpan(type, delta)}
            {getFunnelComparisonInsightsDescriptiveText(insight)} {name} {type}{" "}
            {getExtraDetailSpan(type, insight)}
        </li>
    );
};

// adjusting the second stage percent and percent drop accordingly so that it always sums up to the 100 (for visual parity in the bar)
const adjustedSecondStagePercent = (
    firstStagePercent: number,
    secondStagePercent: number,
) => {
    const percentDrop = firstStagePercent - secondStagePercent;
    if (firstStagePercent >= 100 && percentDrop <= 10) {
        return 90;
    } else if (firstStagePercent < 100) {
        return secondStagePercent + (100 - firstStagePercent) / 2;
    }
    return secondStagePercent;
};

// adjusting the second stage percent and percent drop accordingly so that it always sums up to the 100 (for visual parity in the bar)
const adjustedDropoffPercent = (
    firstStagePercent: number,
    secondStagePercent: number,
) => {
    const percentDrop = firstStagePercent - secondStagePercent;
    if (firstStagePercent >= 100 && percentDrop < 10) {
        return 10;
    } else if (firstStagePercent < 100) {
        return percentDrop + (100 - firstStagePercent) / 2;
    }
    return percentDrop;
};

const BarWithOnlyTopAndBottomBorderA = (
    firstStageName: string,
    firstStagePercent: number,
    secondStagePercent: number,
) => {
    return (props) => {
        const { x, y, height } = props;
        return (
            <g>
                <line x1={x} y1={y} x2={x + 20} y2={y} stroke="#b3b3b3" />
                <line
                    x1={x}
                    y1={y + height}
                    x2={x + 20}
                    y2={y + height}
                    stroke="#b3b3b3"
                />
                <text
                    x={x + 25}
                    y={`${
                        5 +
                        (100 -
                            adjustedSecondStagePercent(
                                firstStagePercent,
                                secondStagePercent,
                            )) /
                            2
                    }%`}
                    style={{
                        fontSize: 14,
                        fill: "#000000",
                        fontStyle: "italic",
                    }}
                >
                    Sessions dropped off from{" "}
                    <tspan fontWeight={"bold"}>{firstStageName}</tspan> stage (
                    <tspan fontWeight={"bold"}>A</tspan>)
                </text>
            </g>
        );
    };
};

const BarWithOnlyTopAndBottomBorderB = (
    secondStageName: string,
    firstStagePercent: number,
    secondStagePercent: number,
) => {
    return (props) => {
        const { x, y, height } = props;
        return (
            <g>
                <line x1={x} y1={y} x2={x + 20} y2={y} stroke="#b3b3b3" />
                <line
                    x1={x}
                    y1={y + height}
                    x2={x + 20}
                    y2={y + height}
                    stroke="#b3b3b3"
                />
                <text
                    x={x + 25}
                    y={`${
                        100 -
                        adjustedSecondStagePercent(
                            firstStagePercent,
                            secondStagePercent,
                        ) /
                            2
                    }%`}
                    style={{
                        fontSize: 14,
                        fill: "#000000",
                        fontStyle: "italic",
                    }}
                >
                    Sessions advanced into{" "}
                    <tspan fontWeight={"bold"}>{secondStageName}</tspan> stage (
                    <tspan fontWeight={"bold"}>B</tspan>)
                </text>
            </g>
        );
    };
};

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length == 1) {
        // first bar is always going to be the first Stage
        return (
            <div className="customTooltip">
                <div>{`${
                    payload[0].payload.name
                } : ${payload[0].payload.actual.toFixed(2)}%`}</div>
                <div>* (of All Sessions)</div>
            </div>
        );
    } else if (active && payload && payload.length > 1) {
        // second bar contains the second stage and the percent drop
        return (
            <div className="customTooltip">
                <div>{`Dropped Sessions (A) : ${payload[0].payload.actualDrop.toFixed(
                    2,
                )}%`}</div>
                <div>{`${
                    payload[0].payload.newStage
                } (B) : ${payload[0].payload.actualStageRate.toFixed(2)}%`}</div>
                <div>* (of All Sessions)</div>
            </div>
        );
    }

    return null;
};

const commonPageBlockRightMargin: IStackStyles = {
    root: { ...(commonPageBlock.root as {}), marginRight: "10px" },
};

export const AshaTopInsightsWidget = (
    props: AshaTopInsightsWidgetProps,
): JSX.Element => {
    const canShowInsights =
        props.ashaTopInsights &&
        props.ashaTopInsights.Insights &&
        props.ashaTopInsights.Insights.length > 0 &&
        props.ashaTopInsights.StageRates;

    const app = props.application;

    return (
        <Stack>
            {canShowInsights ? (
                <Stack horizontal tokens={horizontalStackTokens}>
                    <Stack.Item styles={commonPageBlockRightMargin}>
                        <Stack horizontal>
                            <div className="funnelBar">
                                <BarChart
                                    width={funnelBarWidth}
                                    height={funnelBarHeight}
                                    data={[
                                        {
                                            name: props.ashaTopInsights.StageRates
                                                .FirstStageName,
                                            percentage: 100,
                                            actual: props.ashaTopInsights.StageRates
                                                .FirstStagePercent,
                                        },
                                    ]}
                                >
                                    <YAxis domain={[0, 100]} hide />
                                    <XAxis dataKey="name" hide />
                                    <Tooltip
                                        content={<CustomTooltip />}
                                        position={{ y: 0 }}
                                        cursor={false}
                                        wrapperStyle={{
                                            fontSize: "12px",
                                            zIndex: 100,
                                        }}
                                    />
                                    <Bar
                                        dataKey="percentage"
                                        fill={StagesColor[app]?.stage}
                                    />
                                </BarChart>
                            </div>
                            <span style={{ display: "inline-flex" }}>
                                <div
                                    className="diffValueForAsha"
                                    title="% users dropped"
                                >
                                    <div className="diffArrowForAsha">
                                        <span>
                                            -
                                            {
                                                props.ashaTopInsights.StageRates
                                                    .PercentDropBetweenFirstAndSecondStages
                                            }
                                            %
                                        </span>
                                    </div>
                                </div>
                            </span>

                            <div className="funnelBar">
                                <BarChart
                                    width={funnelBarWidth * 7}
                                    height={funnelBarHeight}
                                    data={[
                                        {
                                            DroppedOff: adjustedDropoffPercent(
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent,
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            ),
                                            Stage: adjustedSecondStagePercent(
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent,
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            ),
                                            actualDrop:
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent -
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            actualStageRate:
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            newStage:
                                                props.ashaTopInsights.StageRates
                                                    .SecondStageName,
                                        },
                                    ]}
                                >
                                    <YAxis domain={[0, 100]} hide />
                                    <XAxis dataKey="name" hide />
                                    <Tooltip
                                        content={<CustomTooltip />}
                                        position={{ x: 8, y: 0 }}
                                        cursor={false}
                                        wrapperStyle={{
                                            fontSize: "12px",
                                        }}
                                    />
                                    <Bar
                                        dataKey="Stage"
                                        stackId="a"
                                        fill={StagesColor[app]?.stage}
                                        barSize={50}
                                    />
                                    <Bar
                                        dataKey="DroppedOff"
                                        stackId="a"
                                        fill={StagesColor[app]?.dropOff}
                                        barSize={50}
                                    />
                                    <text
                                        x="30%"
                                        dx={-112}
                                        y={`${
                                            5 +
                                            (100 -
                                                adjustedSecondStagePercent(
                                                    props.ashaTopInsights.StageRates
                                                        .FirstStagePercent,
                                                    props.ashaTopInsights.StageRates
                                                        .SecondStagePercent,
                                                )) /
                                                2
                                        }%`}
                                        style={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            fill: StagesColor[app]?.stage,
                                        }}
                                        width={200}
                                        textAnchor="middle"
                                    >
                                        A
                                    </text>
                                    <text
                                        x="30%"
                                        dx={-112}
                                        y={`${
                                            100 -
                                            adjustedSecondStagePercent(
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent,
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            ) /
                                                2
                                        }%`}
                                        style={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            fill: "#ffffff",
                                        }}
                                        width={200}
                                        textAnchor="middle"
                                    >
                                        B
                                    </text>
                                    <Bar
                                        dataKey="Stage"
                                        stackId="b"
                                        shape={BarWithOnlyTopAndBottomBorderA(
                                            props.ashaTopInsights.StageRates
                                                .FirstStageName,
                                            100,
                                            adjustedSecondStagePercent(
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent,
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            ),
                                        )}
                                        barSize={420}
                                    />
                                    <Bar
                                        dataKey="DroppedOff"
                                        stackId="b"
                                        shape={BarWithOnlyTopAndBottomBorderB(
                                            props.ashaTopInsights.StageRates
                                                .SecondStageName,
                                            100,
                                            adjustedSecondStagePercent(
                                                props.ashaTopInsights.StageRates
                                                    .FirstStagePercent,
                                                props.ashaTopInsights.StageRates
                                                    .SecondStagePercent,
                                            ),
                                        )}
                                        barSize={420}
                                    />
                                </BarChart>
                            </div>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={commonPageBlock}>
                        <Stack>
                            <span className="funnelTopInsightsSubHeader">
                                Dropped off sessions (A) have:
                            </span>
                            <ul className="topInsightsList">
                                {props.ashaTopInsights.Insights.map(
                                    (insight: FunnelStageAshaInsight) =>
                                        getInsightLine(insight),
                                )}
                            </ul>
                            <span className="subscriptStyleBottom">
                                {CONSUMERDAY0_MESSAGES.ASHA_INSIGHTS_NOTE}
                            </span>
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) : (
                <span className="topInsightsEmpty">
                    No significant differences to show for the selected datasets or
                    dropped off sessions did not experience worse issues than the
                    sessions in the new stage.
                </span>
            )}
        </Stack>
    );
};
