import React from "react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import TogglingLineChart from "components/CustomComponents/TogglingLineChart";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import { appStyles } from "utils/Constants";

export const UserFrequency = (props) => {
    const { metric, payload, apps, xLabelName } = props;
    const chartHeight = 300;
    const chartWidth = 450;

    const labels = [];
    apps.forEach((app) => labels.push({ key: app, color: appStyles[app]["color"] }));

    return payload.length !== 0 ? (
        <TogglingLineChart
            kind={togglingLineChartKind.percentages}
            xDataKey={metric}
            values={payload}
            labels={labels}
            width={chartWidth}
            height={chartHeight}
            xLabel={xLabelName}
            yLabel="% Users"
        />
    ) : (
        <EmptyTableContainer />
    );
};
