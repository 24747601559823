import { hideLoading, showLoading } from "react-redux-loading-bar";
import { descending } from "d3-array";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { formatMetaData } from "pages/InProductDrillDown/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { formatDisplayDate, getChartColor, setBlankIfEmpty } from "utils/Helpers";
import {
    FETCH_PERFORMANCE_BEGIN,
    FETCH_PERFORMANCE_FAILURE,
    FETCH_PERFORMANCE_SUCCESS,
    WebPerfAction,
    WebPerformanceState,
} from "./types";

export function fetchPerformanceSuccess(filters: {}, data: any) {
    const perfTable = data.performanceDistribution.Tables[0];
    const datesTable = data.dateOptions.Tables[0];
    const metadataJson = data?.metadata;
    const filterDate = filters["date"];

    const dateOptions = [];
    const uniqueDates = Array.from(new Set(datesTable.Rows)).sort(descending);
    uniqueDates.forEach((a) =>
        dateOptions.push({ key: a[0], text: formatDisplayDate(a[0]) }),
    );

    // Schema - Date, Application, Metric, Name, P95, P50, N, Total

    const performanceTableData = {};
    const modalChartData = {};
    const tmpEuplCharts = {};
    const chartLabels = {};

    let index = 0;
    perfTable.Rows.forEach((element) => {
        const date = element[0];
        const app = element[1];
        const metric = element[2];
        const name = setBlankIfEmpty(element[3]);
        const P95 = element[4];
        const P50 = element[5];
        const N = element[6];
        const total = element[7];

        const percentRoundedString = ((N * 100) / total).toFixed(2);
        const percent = Number(percentRoundedString);

        // take for the selected date for the table
        if (filterDate === date) {
            if (!(metric in performanceTableData)) {
                performanceTableData[metric] = {};
            }

            if (!(app in performanceTableData[metric])) {
                performanceTableData[metric][app] = [];
            }

            if (percent > 0)
                performanceTableData[metric][app].push({
                    name,
                    P95,
                    P50,
                    "% Sessions": percent,
                });
        }

        // for th modal chart that pops when clicked on item
        if (!(metric in modalChartData)) {
            modalChartData[metric] = {};
        }

        if (!(app in modalChartData[metric])) {
            modalChartData[metric][app] = {};
        }

        if (!(name in modalChartData[metric][app])) {
            modalChartData[metric][app][name] = [];
        }

        if (percent > 0)
            modalChartData[metric][app][name].push({
                Date: formatDisplayDate(date),
                P95,
                P50,
                "% Sessions": percent,
            });

        Object.keys(modalChartData).forEach((metric) => {
            chartLabels[metric] = {};
            Object.keys(modalChartData[metric]).forEach((app) => {
                chartLabels[metric][app] = [];
                index = 0;
                Object.keys(modalChartData[metric][app]).forEach((name) => {
                    chartLabels[metric][app].push({
                        key: name,
                        color: getChartColor(index % 9),
                    });
                    index += 1;
                });
            });
        });

        // for the EUPL trend charts
        if (!(metric in tmpEuplCharts)) {
            tmpEuplCharts[metric] = {};
        }

        if (!(app in tmpEuplCharts[metric])) {
            tmpEuplCharts[metric][app] = {};
            tmpEuplCharts[metric][app]["P95"] = {};
            tmpEuplCharts[metric][app]["P50"] = {};
        }

        if (!(date in tmpEuplCharts[metric][app]["P95"])) {
            tmpEuplCharts[metric][app]["P95"][date] = {
                Date: formatDisplayDate(date),
            };
            tmpEuplCharts[metric][app]["P50"][date] = {
                Date: formatDisplayDate(date),
            };
        }

        tmpEuplCharts[metric][app]["P95"][date][name] = P95;
        tmpEuplCharts[metric][app]["P50"][date][name] = P50;
    });

    const euplCharts = {};
    Object.keys(tmpEuplCharts).forEach((metric) => {
        euplCharts[metric] = {};
        Object.keys(tmpEuplCharts[metric]).forEach((app) => {
            euplCharts[metric][app] = {};
            Object.keys(tmpEuplCharts[metric][app]).forEach((p) => {
                euplCharts[metric][app][p] = [];
                Object.keys(tmpEuplCharts[metric][app][p]).forEach((date) => {
                    euplCharts[metric][app][p].push(
                        tmpEuplCharts[metric][app][p][date],
                    );
                });
            });
        });
    });

    const metadata = formatMetaData(metadataJson, Workload.WEB);

    const tenantPerf = {
        performanceTableData,
        modalChartData,
        euplCharts,
        chartLabels,
        dateOptions,
        metadata,
    };

    return {
        type: FETCH_PERFORMANCE_SUCCESS,
        payload: tenantPerf,
    };
}

export function fetchPerformanceFailure(error: {}) {
    return {
        type: FETCH_PERFORMANCE_FAILURE,
        error,
    };
}

export function fetchPerformance(filters: {}) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PERFORMANCE_BEGIN });
        dispatch(showLoading());
        try {
            const response = await API.getPerformanceDistribution(filters);
            const successResponse = fetchPerformanceSuccess(filters, response);
            dispatch(successResponse);
            dispatch(hideLoading());
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "PerformanceDataFetchOrProcessFailed",
                ),
                {
                    message: "Failure while fetching or formatting perf data",
                    filters,
                },
                error,
            );
            dispatch(fetchPerformanceFailure(error));
            dispatch(hideLoading());
        }
    };
}

export const initialState: WebPerformanceState = {
    isFetching: false,
    filters: { Host: "All", UiHost: "All" },
};

export function WebPerformanceReducer(
    state = initialState,
    action: WebPerfAction,
): WebPerformanceState {
    let actionType: string = action.type as string;
    switch (actionType) {
        case FETCH_PERFORMANCE_BEGIN:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_PERFORMANCE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                performanceTableData: action.payload["performanceTableData"],
                modalChartData: action.payload["modalChartData"],
                euplCharts: action.payload["euplCharts"],
                chartLabels: action.payload["chartLabels"],
                dateOptions: action.payload["dateOptions"],
                metadata: action.payload["metadata"],
                filters: {
                    ...state.filters,
                },
            };
        case FETCH_PERFORMANCE_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
