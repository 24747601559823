import {
    AdditionalFilterType,
    AddtionalFilterKustoIndexType,
    CommonFilterStateType,
} from "components/CommonFunnelComponents/CommonFunnel/types";
import { FunnelType, getFunnelConfig } from "config/FunnelConfig";

// Converts additional filter labels into following format:
// { filterKey: label }
export const getAdditionalFilterLabels = (
    funnelType: FunnelType,
): AdditionalFilterType => {
    const { additionalFilters } = getFunnelConfig(funnelType);

    return additionalFilters?.reduce(
        (accumulator, filter) =>
            Object.assign(accumulator, { [filter.filterKey]: filter.label }),
        {},
    );
};

// Converts additional filter defaults into following format:
// { filterKey: selectedKey }
export const getAdditionalFilterDefaults = (
    funnelType: FunnelType,
): AdditionalFilterType => {
    const { additionalFilters } = getFunnelConfig(funnelType);

    return additionalFilters
        ?.filter((filter) => filter.selectedKey)
        .reduce(
            (accumulator, filter) =>
                Object.assign(accumulator, {
                    [filter.filterKey]: filter.selectedKey,
                }),
            {},
        );
};

// Converts required filters into following format:
// { filterKey: filterValueFromUrlFilters or defaultFilterValue }
export const getRequiredFiltersUrlFriendly = (
    funnelType: FunnelType,
    filters?: CommonFilterStateType,
): AdditionalFilterType => {
    const { requiredFilters } = getFunnelConfig(funnelType);

    return requiredFilters?.reduce(
        (accumulator, filter) =>
            Object.assign(accumulator, {
                [filter.filterKey]:
                    filters?.[filter.filterKey] ?? filter.selectedKey,
            }),
        {},
    );
};

// Converts additional filters into following format:
// { filterKey: kustoColumnIndex }
export const getAdditionalFiltersKustoColumnIndex = (
    funnelType: FunnelType,
): AddtionalFilterKustoIndexType => {
    const { additionalFilters } = getFunnelConfig(funnelType);

    return additionalFilters?.reduce(
        (accumulator, filter) =>
            Object.assign(accumulator, {
                [filter.filterKey]: filter.kustoColumnIndex,
            }),
        {},
    );
};

// Gets the doraDashboardId from the Funnel Config
export const getDoraDashboardLink = (funnelType: FunnelType): string => {
    const { doraDashboardId } = getFunnelConfig(funnelType);
    return doraDashboardId;
};
