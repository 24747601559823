import React, { useRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { initializeIcons } from "@fluentui/react";
import { AppInsightsInitialization } from "components/AuthComponents/AppInsightsInitialization";
import InsightsFrame from "components/CustomComponents/InsightsFrame/InsightsFrame";
import { ShellHeader } from "components/CustomComponents/ShellHeader/ShellHeader";
import DeveloperOverlay from "components/Developer/DeveloperOverlay";
import ErrorBoundary from "components/ErrorFallbackComponents/ErrorBoundary";
import Error from "pages/Error/Error";
import { createRoot } from "react-dom/client";
import InsightsBrowserRoutes from "utils/InsightsBrowserRoutes";
import { loginRequest } from "config/AuthConfig";
import configureStore from "redux/store";
import AuthenticationUtil from "utils/AuthenticationUtil";
import { isDev } from "utils/Helpers";
import { registerAppIcons } from "utils/IconRegistration";
import {
    shouldShowShellHeader,
    shouldShowInsightsNav,
} from "utils/InsightsFrameHelpers";
import { PlgTelemetryContext, PlgTelemetryState } from "utils/PlgTelemetryLogger";

const { store } = configureStore();
const root = createRoot(document.getElementById("root"));
initializeIcons();
registerAppIcons();

const renderRootFrame = () => {
    if (shouldShowInsightsNav(location)) {
        return (
            <>
                {isDev() && <DeveloperOverlay />}
                <InsightsFrame>
                    <InsightsBrowserRoutes />
                </InsightsFrame>
            </>
        );
    } else {
        return (
            <>
                {shouldShowShellHeader(location) && <ShellHeader />}
                <InsightsBrowserRoutes />
            </>
        );
    }
};

const App = () => {
    const plgTelemetryState = useRef<PlgTelemetryState>({});
    const setPlgTelemetryState = (newState: PlgTelemetryState) => {
        plgTelemetryState.current = newState;
    };

    return (
        <Provider store={store}>
            <MsalProvider instance={AuthenticationUtil.getMSALInstance()}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                    errorComponent={Error}
                >
                    <AppInsightsInitialization>
                        <ErrorBoundary>
                            <PlgTelemetryContext.Provider
                                value={{
                                    state: plgTelemetryState,
                                    updateState: setPlgTelemetryState,
                                }}
                            >
                                <BrowserRouter
                                    basename={process.env.TENANT_BASENAME}
                                >
                                    {renderRootFrame()}
                                </BrowserRouter>
                            </PlgTelemetryContext.Provider>
                        </ErrorBoundary>
                    </AppInsightsInitialization>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </Provider>
    );
};

root.render(<App />);
