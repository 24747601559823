import React, { useEffect, useState } from "react";
import {
    DetailsList,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";

const CONSTANTS = {
    COLUMN_WIDTH: 70,
    FIXED_ROW_HEADER_COLUMN_WIDTH: 80,
};

const _generateColumns = () => {
    const displayColumns = [
        ["name", "Name", CONSTANTS.FIXED_ROW_HEADER_COLUMN_WIDTH, null],
        ["P95", "EUPL (P95)", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
        ["P50", "EUPL (P50)", CONSTANTS.COLUMN_WIDTH, "alignRightHeader"],
        [
            "% Sessions",
            "% Sessions",
            CONSTANTS.FIXED_ROW_HEADER_COLUMN_WIDTH,
            "alignRightHeader",
        ],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        "% Sessions",
        true,
    );

    return columns;
};

export const PerformanceTable = (props) => {
    const { payload, toggleModal, changeDataSelected, application } = props;
    const [columns, setColumns] = useState(_generateColumns());
    const [items, setItems] = useState(payload);

    useEffect(() => {
        setItems(payload);
    }, [payload]);

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columns, column, items);
        setItems(result.items);
        setColumns(result.columns);
    };

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        switch (column.key) {
            case "name":
                return <DetailsListHelper.TextValue value={fieldContent} />;
            case "% Sessions":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="s"
                        decimalPrecision={2}
                    />
                );
        }
    };

    const _onRenderRow = (rowProps: IDetailsRowProps | undefined): JSX.Element => {
        return (
            <div
                className="metricExpand"
                onClick={() => {
                    changeDataSelected({
                        app: application,
                        name: rowProps.item.name,
                    });
                    toggleModal();
                }}
            >
                <DetailsRow {...rowProps} />
            </div>
        );
    };

    return (
        <div
            data-is-scrollable
            className="pageSection"
            data-testid="PerfTablePageSection"
            style={{ minHeight: "300px", maxHeight: "300px" }}
        >
            <DetailsList
                items={items}
                columns={columns}
                onRenderRow={_onRenderRow}
                onColumnHeaderClick={_onColumnClick}
                onRenderItemColumn={_renderItemColumn}
                selectionMode={SelectionMode.none}
                setKey="none"
                compact
                className="tenantTable"
                styles={DetailsListHelper.tenantTableStyle}
            />
        </div>
    );
};
