export const formatTenantList = (rows) => {
    const formattedTenantObjectList = [];
    rows.forEach((row) => {
        formattedTenantObjectList.push({
            Tpid: row[0],
            OrganizationName: row[1],
            TenantId: row[2],
            TenantName: row[3],
            TenantSource: row[4],
            TpidSource: row[5],
            Show: true,
        });
    });
    return formattedTenantObjectList;
};

export const generateTpidGroups = (payload: any[], isCollapsed: boolean) => {
    const tenantGroup = {};
    let index = 0;

    // Payload is already sorted
    payload.forEach((element) => {
        const tpid: string = element["Tpid"];

        if (!(tpid in tenantGroup)) {
            tenantGroup[tpid] = {
                name: element["OrganizationName"],
                source: element["TpidSource"],
                status: element["TpidStatus"],
                startIndex: index,
                isEmptyGroup: element["TenantId"] === "",
            };
        }
        tenantGroup[tpid]["endIndex"] = index;

        index += 1;
    });

    return Object.keys(tenantGroup)
        .map((tpid) => {
            const sIdx = tenantGroup[tpid]["startIndex"];
            const eIdx = tenantGroup[tpid]["endIndex"];

            return {
                key: tpid,
                name: tenantGroup[tpid]["name"],
                source: tenantGroup[tpid]["source"],
                startIndex: sIdx,
                endIndex: eIdx,
                level: 0,
                count: tenantGroup[tpid]["isEmptyGroup"] ? 0 : eIdx - sIdx + 1,
                isCollapsed,
                isEmptyGroup: tenantGroup[tpid]["isEmptyGroup"],
                status: tenantGroup[tpid]["status"],
            };
        })
        .sort((a, b) => a.startIndex - b.startIndex);
};
