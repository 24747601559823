import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { Stack, FontIcon, Dropdown, IDropdownOption } from "@fluentui/react";
import TenantTitle from "components/CustomComponents/TenantTitle/TenantTitle";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import ErrorSpike from "components/ReliabilityComponents/ErrorSpike/ErrorSpike";
import { BrowserDataChartModal } from "components/ReliabilityComponents/ReliabilityDrilldown/BrowserDataChartModal";
import { ReliabilityDrilldown } from "components/ReliabilityComponents/ReliabilityDrilldown/ReliabilityDrilldown";
import LoadingModal from "components/CustomComponents/LoadingModal";
import { TenantTitleCohortTags } from "config/PlatformConfig";
import { commonPageBlock, commonPageStyle, filterStackTokens } from "pages/common";
import {
    fetchBrowserDataReliability,
    fetchReliability,
} from "redux/modules/reliability";
import { AppState } from "redux/store";
import AppInsights from "utils/AppInsights";
import { logLevelBasedView } from "utils/AppInsightsHelper";
import {
    checkMissingDrilldownQueryParams,
    computeQueryParams,
    extractQueryParams,
    formatDisplayDate,
} from "utils/Helpers";
import {
    hostOptions,
    uihostOptions,
    dropdownStyles,
    Application,
} from "utils/WebConstants";

const pageName = "Reliability Drilldown";
export interface PropsFromState {
    tenantReliability: {};
    dateOptions: IDropdownOption[];
    filters: {};
    isFetching: boolean;
    chartData: [];
    error: string | null;
    browserDataError: string | null;
}

interface PropsFromDispatch {
    getTenantReliability: (filters: {}) => void;
    getBrowserDataReliability: (filters: {}) => void;
}

interface LocalState {
    show: boolean;
}
type AllProps = PropsFromState & PropsFromDispatch;

class Reliability extends Component<AllProps, LocalState> {
    private queryParams: {} = {};

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        document.title = "Office Web Reliability Tier 2";
        AppInsights.getInstance().TrackPage(document.title);

        const queryParams = checkMissingDrilldownQueryParams(extractQueryParams());
        this.props.filters["id"] = queryParams["id"];
        this.props.filters["date"] = queryParams["date"];
        this.props.filters["level"] = queryParams["level"];
        this.queryParams = queryParams;
        logLevelBasedView(document.title, this.props.filters["level"]);
        this.props.getTenantReliability(this.props.filters);
    }

    private _onChartToggle = () => {
        this.setState((state) => ({ show: !state.show }));
    };

    private _onFilterChange = (dropdownFor: string) => {
        return (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
            if (dropdownFor === "date") {
                this.queryParams["date"] = item.key.toString();
                window.history.pushState(
                    "",
                    "",
                    window.location.pathname + computeQueryParams(this.queryParams),
                );
            }

            this.props.filters[dropdownFor] = item.key.toString();
            this.props.getTenantReliability(this.props.filters);
        };
    };

    render() {
        const {
            tenantReliability,
            dateOptions,
            filters,
            chartData,
            isFetching,
            error,
            browserDataError,
        } = this.props;
        if (error) {
            return <ErrorFallback message={error} />;
        }

        if (!tenantReliability) {
            return <LoadingModal text={`Loading ${pageName}...`} />;
        }

        dateOptions.forEach((element) => {
            element.text = formatDisplayDate(element.key as string);
        });

        return (
            <>
                <LoadingBar className="loadingBar" />
                <Stack disableShrink styles={commonPageStyle}>
                    <div className="orgHeader">
                        <br />
                        <span id="reliability"> {pageName} </span>
                    </div>

                    <div className="drilldownTenantName">
                        <TenantTitle
                            metadata={tenantReliability["metadata"]}
                            level={this.props.filters["level"]}
                            loading={false}
                            tenantStatus={tenantReliability["metadata"]["status"]}
                            cohortTags={TenantTitleCohortTags}
                        />
                    </div>
                    <br />
                    <Stack horizontal tokens={filterStackTokens}>
                        <FontIcon iconName="Filter" className="filterIcon" />
                        <Dropdown
                            placeholder="Select an option"
                            label="Time Period"
                            options={dateOptions}
                            selectedKey={filters["date"]}
                            styles={dropdownStyles}
                            onChange={this._onFilterChange("date")}
                            className="filterDropdown"
                        />
                        <Dropdown
                            placeholder="Select an option"
                            label="Host"
                            options={hostOptions}
                            selectedKey={filters["Host"]}
                            styles={dropdownStyles}
                            onChange={this._onFilterChange("Host")}
                            className="filterDropdown"
                        />
                        <Dropdown
                            placeholder="Select an option"
                            label="UiHost"
                            options={uihostOptions}
                            selectedKey={filters["UiHost"]}
                            styles={dropdownStyles}
                            onChange={this._onFilterChange("UiHost")}
                            className="filterDropdown"
                        />
                    </Stack>
                    {this.props.tenantReliability["errorSpike"] && (
                        <Stack styles={commonPageBlock}>
                            <ErrorSpike
                                props={this.props.tenantReliability["errorSpike"]}
                            />
                        </Stack>
                    )}
                    <Stack>
                        {Object.keys(Application).map((app) => {
                            return (
                                <ReliabilityDrilldown
                                    dataItems={tenantReliability["items"][app] ?? []}
                                    chart={tenantReliability["chart"][app] ?? []}
                                    filters={filters}
                                    appSelected={app}
                                    key={app}
                                    chartModalToggle={this._onChartToggle}
                                    getChartModalData={
                                        this.props.getBrowserDataReliability
                                    }
                                />
                            );
                        })}
                    </Stack>
                    <BrowserDataChartModal
                        handleClose={this._onChartToggle}
                        show={this.state.show}
                        chartData={chartData}
                        isFetching={isFetching}
                        errorName={filters["errorname"]}
                        componentError={browserDataError}
                    />
                </Stack>
            </>
        );
    }
}

const mapState = (state: AppState) => {
    let tenantReliability = null;
    let dateOptions = [];
    let filters = {};
    let chartData = [];
    let error = null;
    let browserDataError = null;
    if (state.reliability != null) {
        tenantReliability = state.reliability.tenantReliability;
        dateOptions = state.reliability.dateOptions;
        filters = state.reliability.filters;
        chartData = state.reliability.chartData;
        error = state.reliability.error;
        browserDataError = state.reliability.browserDataError;
    }
    return {
        isFetching: state.reliability.isFetching,
        tenantReliability,
        dateOptions,
        filters,
        chartData,
        error,
        browserDataError,
    };
};

const mapDispatch = (dispatch: any) => {
    return {
        getTenantReliability: (filters: {}) => dispatch(fetchReliability(filters)),
        getBrowserDataReliability: (filters: {}) =>
            dispatch(fetchBrowserDataReliability(filters)),
    };
};

export default connect(mapState, mapDispatch)(Reliability);
