import {
    IColumn,
    IDetailsColumnProps,
    IGroup,
    IRenderFunction,
    IStackStyles,
    IStyle,
} from "@fluentui/react";
import { onRenderSegmentHeader } from "components/ConsumerGrowthScorecardComponents/GrowthScorecardTable";
import parse from "date-fns/parse";
import {
    ConsumerGrowthScorecardNoteItemType,
    ConsumerGrowthScorecardNoteListItemType,
    ConsumerGrowthScorecardTableType,
    StatSigSegmentMetricMap,
} from "components/ConsumerGrowthScorecardComponents/types";
import { getComparisonPValue } from "utils/Asha/AshaStatHelper";
import { tableColumnConfig } from "utils/types";

export const ScorecardTableColumnConfig: tableColumnConfig = {
    Segment: {
        headerGroups: ["None", "None"],
        displayName: "Segment",
        style: {
            marginLeft: -36,
        },
        exportCSVHeaderName: "Segment",
        onRenderHeader: onRenderSegmentHeader,
    },
    MAU_Score: {
        headerGroups: ["MAU", "None"],
        displayName: "MAU",
        style: {
            borderLeft: "1px solid lightgrey",
        },
        exportCSVHeaderName: "MAU",
    },
    MAU_YoY: {
        headerGroups: ["MAU", "None"],
        exportCSVHeaderName: "MAU YoY",
    },
    CAMAUDay0_Pct: {
        headerGroups: ["Core Action", "None"],
        displayName: "CAMAU % @Day0",
        exportCSVHeaderName: "CAMAU @Day0",
    },
    CAMAUDay0_YoY: {
        headerGroups: ["Core Action", "None"],
        exportCSVHeaderName: "CAMAU @Day0 YoY",
    },
    CAMAUPct_Pct: {
        headerGroups: ["Core Action", "None"],
        displayName: "CAMAU %",
        exportCSVHeaderName: "CAMAU %",
    },
    CAMAUPct_YoY: {
        headerGroups: ["Core Action", "None"],
        exportCSVHeaderName: "CAMAU % YoY",
    },
    CAMAU_Score: {
        headerGroups: ["Core Action", "None"],
        displayName: "CAMAU",
        exportCSVHeaderName: "CAMAU",
    },
    CAMAU_YoY: {
        headerGroups: ["Core Action", "None"],
        exportCSVHeaderName: "CAMAU YoY",
    },
    M1Retention_Pct: {
        headerGroups: ["Retention", "None"],
        displayName: "M1R",
        exportCSVHeaderName: "M1R",
    },
    M1Retention_YoY: {
        headerGroups: ["Retention", "None"],
        exportCSVHeaderName: "M1R YoY",
    },
    M3Retention_Pct: {
        headerGroups: ["Retention", "None"],
        displayName: "M3R",
        exportCSVHeaderName: "M3R",
    },
    M3Retention_YoY: {
        headerGroups: ["Retention", "None"],
        exportCSVHeaderName: "M3R YoY",
    },
};

export const segmentMetadata: {
    [key: string]: { displayText: string; segmentLevel: number };
} = {
    "All All All All": {
        displayText: "Web All (ODSP Converged Host, Outlook)",
        segmentLevel: 1,
    },
    "Exchange Online Consumer All All All": {
        displayText: "Outlook Host",
        segmentLevel: 1,
    },
    "ODSP Converged All All All": {
        displayText: "ODSP Converged Host",
        segmentLevel: 1,
    },
    "ODSP Converged Paid All All": {
        displayText: "Paid Users",
        segmentLevel: 2,
    },
    "ODSP Converged Free All All": {
        displayText: "Free Users",
        segmentLevel: 2,
    },
    "ODSP Converged Free Returning All": {
        displayText: "Returning Users",
        segmentLevel: 4,
    },
    "ODSP Converged Free New All": {
        displayText: "New Users",
        segmentLevel: 4,
    },
    "ODSP Converged Free New Harmony All": {
        displayText: "Harmony All",
        segmentLevel: 5,
    },
    "ODSP Converged Free New Harmony Home Page": {
        displayText: "Harmony Home Page",
        segmentLevel: 6,
    },
    "ODSP Converged Free New Harmony App Start Pages": {
        displayText: "Harmony App Start Pages",
        segmentLevel: 6,
    },
    "ODSP Converged Free New Harmony Other": {
        displayText: "Harmony Others",
        segmentLevel: 6,
    },
    "ODSP Converged Free New Outlook": {
        displayText: "Outlook Referrals",
        segmentLevel: 5,
    },
    "ODSP Converged Free New OneDrive": {
        displayText: "OneDrive Referrals",
        segmentLevel: 5,
    },
    "ODSP Converged Free New Other": {
        displayText: "Other Referrals",
        segmentLevel: 5,
    },
    "OneDriveWOPI All All All": { displayText: "OneDrive Host", segmentLevel: 1 },
    "OneDriveWOPI Paid All All": { displayText: "Paid Users", segmentLevel: 2 },
    "OneDriveWOPI Free All All": { displayText: "Free Users", segmentLevel: 2 },
    "OneDriveWOPI Free Returning All": {
        displayText: "Returning Users",
        segmentLevel: 4,
    },
    "OneDriveWOPI Free New All": { displayText: "New Users", segmentLevel: 4 },
    "OneDriveWOPI Free New Harmony All": {
        displayText: "Harmony All",
        segmentLevel: 5,
    },
    "OneDriveWOPI Free New Harmony Home Page": {
        displayText: "Harmony Home Page",
        segmentLevel: 6,
    },
    "OneDriveWOPI Free New Harmony App Start Pages": {
        displayText: "Harmony App Start Pages",
        segmentLevel: 6,
    },
    "OneDriveWOPI Free New Harmony Other": {
        displayText: "Harmony Others",
        segmentLevel: 6,
    },
    "OneDriveWOPI Free New Outlook": {
        displayText: "Outlook Referrals",
        segmentLevel: 5,
    },
    "OneDriveWOPI Free New OneDrive": {
        displayText: "OneDrive Referrals",
        segmentLevel: 5,
    },
    "OneDriveWOPI Free New Other": {
        displayText: "Other Referrals",
        segmentLevel: 5,
    },
    "SharePoint Online Consumer All All All": {
        displayText: "SharePoint Online Consumer Host",
        segmentLevel: 1,
    },
    "SharePoint Online Consumer Paid All All": {
        displayText: "Paid Users",
        segmentLevel: 2,
    },
    "SharePoint Online Consumer Free All All": {
        displayText: "Free Users",
        segmentLevel: 2,
    },
    "SharePoint Online Consumer Free Returning All": {
        displayText: "Returning Users",
        segmentLevel: 4,
    },
    "SharePoint Online Consumer Free New All": {
        displayText: "New Users",
        segmentLevel: 4,
    },
    "SharePoint Online Consumer Free New Harmony All": {
        displayText: "Harmony All",
        segmentLevel: 5,
    },
    "SharePoint Online Consumer Free New Harmony Home Page": {
        displayText: "Harmony Home Page",
        segmentLevel: 6,
    },
    "SharePoint Online Consumer Free New Harmony App Start Pages": {
        displayText: "Harmony App Start Pages",
        segmentLevel: 6,
    },
    "SharePoint Online Consumer Free New Harmony Other": {
        displayText: "Harmony Others",
        segmentLevel: 6,
    },
    "SharePoint Online Consumer Free New Outlook": {
        displayText: "Outlook Referrals",
        segmentLevel: 5,
    },
    "SharePoint Online Consumer Free New OneDrive": {
        displayText: "OneDrive Referrals",
        segmentLevel: 5,
    },
    "SharePoint Online Consumer Free New Other": {
        displayText: "Other Referrals",
        segmentLevel: 5,
    },
};

export const chartFlyoutBarsDisplayNames = {
    MAU_Score: {
        DisplayName: "MAU",
        GoalField: "MAUGoal_Score",
        GoalFieldDisplay: "MAU Goal",
    },
    CAMAU_Score: {
        DisplayName: "CAMAU",
        GoalField: "CAMAUGoal_Score",
        GoalFieldDisplay: "CAMAU Goal",
    },
};

export const chartFlyoutLinesDisplayNames = {
    CAMAUDay0_Pct: {
        DisplayName: "CAMAU@Day0 %",
        GoalField: "CAMAUDay0Goal_Pct",
        GoalFieldDisplay: "CAMAU@Day0 % Goal",
    },
    CAMAUPct_Pct: {
        DisplayName: "CAMAU %",
        GoalField: "CAMAUPctGoal_Pct",
        GoalFieldDisplay: "CAMAU % Goal",
    },
    M1Retention_Pct: {
        DisplayName: "M1R",
        GoalField: "M1RetentionGoal_Pct",
        GoalFieldDisplay: "M1R Goal",
    },
    M3Retention_Pct: {
        DisplayName: "M3R",
        GoalField: "M3RetentionGoal_Pct",
        GoalFieldDisplay: "M3R Goal",
    },
};

export const legendPayload = [
    {
        inactive: false,
        dataKey: "MAU_Score",
        type: "rect",
        color: "#edc949",
        value: "MAU",
    },
    {
        inactive: false,
        dataKey: "CAMAU_Score",
        type: "rect",
        color: "#af7aa1",
        value: "CAMAU",
    },
    {
        inactive: false,
        dataKey: "CAMAUDay0_Pct",
        type: "line",
        color: "#4e79a7",
        value: "CAMAU@Day0 %",
    },
    {
        inactive: false,
        dataKey: "CAMAUPct_Pct",
        type: "line",
        color: "#f28e2c",
        value: "CAMAU %",
    },
    {
        inactive: false,
        dataKey: "M1Retention_Pct",
        type: "line",
        color: "#e15759",
        value: "M1R",
    },
    {
        inactive: false,
        dataKey: "M3Retention_Pct",
        type: "line",
        color: "#76b7b2",
        value: "M3R",
    },
];

export const segmentOrder = Object.keys(segmentMetadata)
    .map((segmentKey, index) => {
        return { [segmentKey]: index };
    })
    .reduce((acc, val) => ({ ...acc, ...val }), {});

const getColumnHeaderMapping = (column: string) => {
    if (ScorecardTableColumnConfig[column]?.displayName) {
        return ScorecardTableColumnConfig[column].displayName;
    } else if (column.includes("_YoY")) {
        return "YoY";
    } else return column;
};

export const getColumnWidthMappings = (column: string) => {
    if (column === "Segment") {
        return { minWidth: 200, maxWidth: 300 };
    }
    if (column === "CAMAUDay0_Pct" || column === "CAMAUPct_Pct") {
        return { minWidth: 70, maxWidth: 70 };
    }
    return { minWidth: 50, maxWidth: 60 };
};

export const getConsumerGrowthScorecardColumns = () => {
    const columns: IColumn[] = [];

    Object.keys(ScorecardTableColumnConfig).map((header) => {
        const displayColumnName = getColumnHeaderMapping(header);
        const columnWidths = getColumnWidthMappings(header);
        columns.push({
            key: header,
            name: displayColumnName,
            fieldName: displayColumnName,
            minWidth: columnWidths.minWidth,
            maxWidth: columnWidths.maxWidth,
            ariaLabel: displayColumnName,
            isResizable: false,
            onRenderHeader: ScorecardTableColumnConfig[header]
                ?.onRenderHeader as IRenderFunction<IDetailsColumnProps>,
            headerClassName: "detailsListColumnHeaderWrap",
            styles: { root: ScorecardTableColumnConfig[header]?.style as IStyle },
        });
    });

    return columns;
};

export const validateNote = (
    updatedNoteItem: ConsumerGrowthScorecardNoteItemType,
): boolean => {
    if (!updatedNoteItem.note || updatedNoteItem.note.length === 0) {
        // if empty note
        return false;
    }

    if (
        updatedNoteItem.startDate === undefined ||
        updatedNoteItem.startDate === null
    ) {
        return false;
    }

    if (updatedNoteItem.endDate === undefined || updatedNoteItem.endDate === null) {
        return false;
    }
    if (
        parse(updatedNoteItem.endDate, "yyyy-MM-dd", new Date()) <
        parse(updatedNoteItem.startDate, "yyyy-MM-dd", new Date())
    ) {
        return false;
    }
    return true;
};

export const GetNoteGroupsAndItems = (
    noteItems: ConsumerGrowthScorecardNoteItemType[],
): [IGroup[], ConsumerGrowthScorecardNoteListItemType[]] => {
    const groups: IGroup[] = [];
    const items: ConsumerGrowthScorecardNoteListItemType[] = [];

    if (!noteItems || noteItems.length === 0) {
        return [groups, items];
    }
    let index = 0;
    let groupIndex = 0;
    let isFirstGroupfound = false;
    // we want to segregate these notes into 2 groups, 1. notes that are across weeks and 2. notes for specific week
    // it is given that notes will be in order i.e. all notes belonging to group (1. notes that are across weeks) will be sequential
    noteItems.forEach((note: ConsumerGrowthScorecardNoteItemType) => {
        if (!isFirstGroupfound && !note.isMultiWeek) {
            groups.push({
                key: "Multi week",
                name: "Multi week",
                count: index,
                startIndex: groupIndex,
                level: 0,
            });
            isFirstGroupfound = true;
            groupIndex = index;
        }

        items.push({ key: `note-${index}`, value: { ...note } });
        index = index + 1;
    });

    groups.push({
        key: "Selected week",
        name: "Selected week",
        count: noteItems.length - groupIndex,
        startIndex: groupIndex,
        level: 0,
    });

    return [groups, items];
};

export const consumerScorecardCenterStyles: IStackStyles = {
    root: {
        alignItems: "center",
    },
};

export const checkSignificanceAndUpdateMap = (
    map: StatSigSegmentMetricMap,
    segment: string,
    metricName: string,
    proportionA: number,
    populationA: number,
    proportionB: number,
    populationB: number,
) => {
    if (
        getComparisonPValue(proportionA, populationA, proportionB, populationB) <=
        0.05
    ) {
        if (map.has(segment)) {
            map.get(segment).push(metricName);
        } else {
            map.set(segment, [metricName]);
        }
    }
};

export const getStatSigMetricsFromTableData = (
    map: StatSigSegmentMetricMap,
    data: ConsumerGrowthScorecardTableType,
    application: string,
    date: string,
): string[] => {
    let statSigMovers: string[] = [];
    const applicationDateEntries = data[application].filter(
        (entry) => entry.Date == date,
    );

    applicationDateEntries.forEach((entry) => {
        const segmentDisplayText = segmentMetadata[entry.Segment]?.displayText;

        if (entry.MAU_Score && entry.MAU_YoY) {
            const mau = entry.MAU_Score;
            const mauLastYear = (1.0 * mau) / (1 + entry.MAU_YoY / 100);
            if (entry.CAMAUDay0_Pct && entry.CAMAUDay0_YoY) {
                checkSignificanceAndUpdateMap(
                    map,
                    segmentDisplayText,
                    "CAMAUDay0_YoY",
                    entry.CAMAUDay0_Pct,
                    mau,
                    entry.CAMAUDay0_Pct - entry.CAMAUDay0_YoY,
                    mauLastYear,
                );
            }

            if (entry.CAMAUPct_Pct && entry.CAMAUPct_YoY) {
                checkSignificanceAndUpdateMap(
                    map,
                    segmentDisplayText,
                    "CAMAUPct_YoY",
                    entry.CAMAUPct_Pct,
                    mau,
                    entry.CAMAUPct_Pct - entry.CAMAUPct_YoY,
                    mauLastYear,
                );
            }
        }
    });

    return statSigMovers;
};

// Group sizes and indices vary by the number of rows per host, and there
// is no referrer data prior to 2023-04-29, so we have to adjust accordingly
export const getGroupsForDate = (date: string): IGroup[] => {
    const includeReferrer = new Date(Date.parse(date)) >= new Date("2023-04-29");
    const groupCount = includeReferrer ? 11 : 4;
    const tableGroups: IGroup[] = [
        {
            key: "WebAll",
            name: "Web All",
            count: 0,
            startIndex: 0,
            level: 0,
            isCollapsed: false,
            data: { isCollapsible: false },
        },
        {
            key: "OutlookHost",
            name: "Outlook Host",
            count: 0,
            startIndex: 0,
            level: 0,
            isCollapsed: false,
            data: { isCollapsible: false },
        },
        {
            key: "OdspConvergedHost",
            name: "ODSP Converged Host",
            count: groupCount,
            startIndex: 0,
            level: 0,
            isCollapsed: false,
            data: { isCollapsible: true },
        },
        {
            key: "OneDriveHost",
            name: "OneDrive Host",
            count: groupCount,
            startIndex: 1 * groupCount,
            level: 0,
            isCollapsed: true,
            data: { isCollapsible: true },
        },
        {
            key: "SpocHost",
            name: "SharePoint Online Consumer Host",
            count: groupCount,
            startIndex: 2 * groupCount,
            level: 0,
            isCollapsed: true,
            data: { isCollapsible: true },
        },
    ];

    return tableGroups;
};
