export const teamsSparkLineScores: string = `
// TeamsSparkLineScores - show bars in each metric signal for past 18 months
let currDate = todatetime("\${date}");
let oldestDate = datetime_add('month',-17,currDate);
let app = "\${app}";
Teams_Scores
| where OmsTenantId == "\${id}"
| where todatetime(Date) between (oldestDate .. currDate) and SubWorkload == app
| project Date, UsageRate = round(UsageRate_Score,2), Color_UsageRate = UsageRate_Color,
StreamEstablishedSuccessPercentage = round(StreamEstablishedSuccessPercentage_Score,2), Color_StreamEstablishedSuccessPercentage = StreamEstablishedSuccessPercentage_Color,
DropRatePercentage = round(DropRatePercentage_Score,2),  Color_DropRatePercentage = DropRatePercentage_Color,
AudioPSRPercentage = round(AudioPSRPercentage_Score,2),  Color_AudioPSRPercentage = AudioPSRPercentage_Color,
PoorCPURate = round(PoorCPURate_Score,2), Color_PoorCPURate = PoorCPURate_Color,
PoorMemoryRate = round(PoorMemoryRate_Score,2),  Color_PoorMemoryRate = PoorMemoryRate_Color,
PoorFrequencyRate = round(PoorFrequencyRate_Score,2),  Color_PoorFrequencyRate = PoorFrequencyRate_Color,
FeedbackPoorPercentage = round(FeedbackPoorPercentage_Score,2),  Color_FeedbackPoorPercentage = FeedbackPoorPercentage_Color,
NPS = round(NPS_Score,2), Color_NPS = NPS_Color,
ChatSwitchV2 = round(ChatSwitchV2_Score,2),  Color_ChatSwitchV2 = ChatSwitchV2_Color,
P95ChatSwitchV2 = round(P95ChatSwitchV2_Score,2),  Color_P95ChatSwitchV2 = P95ChatSwitchV2_Color,
P50ChatSwitchV2 = round(P50ChatSwitchV2_Score,2),  Color_P50ChatSwitchV2 = P50ChatSwitchV2_Color,
NewChat = round(NewChat_Score, 2),  Color_NewChat = NewChat_Color,
ChatSendMessage = round(ChatSendMessage_Score, 2), Color_ChatSendMessage = ChatSendMessage_Color
| sort by Date asc;
`;
