import React from "react";
import {
    IChoiceGroupOption,
    IDropdownOption,
    precisionRound,
} from "@fluentui/react";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import {
    AdditionalFunnelFilters,
    AppSpecificDropOffNames,
    FunnelDropOff,
    FunnelDroppedStage,
    FunnelReferralSources,
    FunnelStage,
} from "pages/ConsumerDay0Funnel/constants";
import {
    Day0FunnelCadenceType,
    ChildReferralSources,
    ParentReferralSources,
    ReferralDataObject,
    ReferralDataType,
    StageMetricPayloadType,
    FunnelFilterStateType,
} from "pages/ConsumerDay0Funnel/types";

export const ConsumerDay0FunnelStagesColor = {
    Word: {
        selected: "#103F91",
        unselected: "#2B7CD3",
    },
    Excel: {
        selected: "#054528",
        unselected: "#20874f",
    },
    PowerPoint: {
        selected: "#a83b08",
        unselected: "#cf593a",
    },
};

export const ConsumerDay0FunnelCohortNames: IDropdownOption[] = [
    { key: "Free New Users", text: "New Users - Free" },
    { key: "New Users", text: "New Users - All" },
    { key: "Free Returning Users", text: "Returning Users - Free" },
    { key: "Returning Users", text: "Returning Users - All" },
];

export const ConsumerDay0FunnelScenarioNames: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Open", text: "Open" },
    { key: "Open Own Doc", text: "Open Own Doc" },
    { key: "Open Shared Doc", text: "Open Shared Doc" },
    { key: "Create", text: "Create" },
    { key: "Create From Blank", text: "Create From Blank" },
    {
        key: "Create From Template",
        text: "Create From Template",
    },
];

export const ScenarioLevel: { [segment: string]: number } = {
    All: 1,
    Open: 1,
    "Open Own Doc": 2,
    "Open Shared Doc": 2,
    Create: 1,
    "Create From Blank": 2,
    "Create From Template": 2,
};

export const ConsumerDay0FunnelReferrerNames: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "Harmony All", text: "Harmony" },
    { key: "Harmony Home Page", text: "Home Page" },
    { key: "Harmony App Start Pages", text: "App Start Pages" },
    { key: "Harmony Other", text: "Other" },
    { key: "OneDrive", text: "OneDrive" },
    { key: "Other", text: "Other Referrers" },
];

export const ReferrerLevel: { [segment: string]: number } = {
    All: 1,
    Harmony: 1,
    "Home Page": 2,
    "App Start Pages": 2,
    Other: 2,
    OneDrive: 1,
    "Other Referrers": 1,
};

export const ConsumerDay0FunnelCadenceNames: IChoiceGroupOption[] = [
    { key: "weekly", text: "Weekly" },
    { key: "monthly", text: "Monthly" },
];

export const DefaultSelectedFunnel: FunnelStage = FunnelStage.FirstSessions;

export const DefaultSelectedDropOff: FunnelDropOff = FunnelDropOff.Default;

export const DefaultSelectedDroppedStage: FunnelDroppedStage =
    FunnelDroppedStage.Default;

export const DefaultSelectedScenerio = "All";

export const DefaultSelectedReferrer = "All";

export const ConsumerDay0FunnelTables = {
    monthly: "ConsumerDay0FunnelStagesInsightsMonthly",
    weekly: "ConsumerDay0FunnelStagesInsightsWeekly",
};

export const isCadenceWeekly = (cadence: Day0FunnelCadenceType) => {
    return cadence === "weekly";
};

export const getCompactValue = (value: number): string => {
    return Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 2,
    }).format(value);
};

const getAllQueryForRemainingPivots = (pivot: string, filters): string => {
    let query = "";
    Object.values(AdditionalFunnelFilters)
        .filter((item) => item !== pivot)
        .forEach((otherPivot) => {
            let otherPivotsStatement = `| where ${otherPivot} == 
            ${otherPivot in filters ? `"${filters[otherPivot]}"\r\n` : '"All"'}`;

            query += otherPivotsStatement;
        });

    return query;
};

/**
 * @param filters 
 * @returns dynamic query for all additional filters
 * Example: when the filters has AppMode:Edit
 * let appModeInsights = AllData | where appMode == "Edit"| where browser == "All"
| where country == "All"
| where datacenter == "All"
| where os == "All"
| where referrerPlatform == "All"
| extend Metric = "AppMode"
| project Stage, Metric, Name=appMode, Value, Total;
 */
export const generateDynamicPivotsQuery = (filters: FunnelFilterStateType) => {
    let str = "// Filter on Additional Pivots";
    Object.values(AdditionalFunnelFilters).forEach((pivot) => {
        let intermediateRowset = `\r\nlet ${pivot}Insights = AllData
        | where ${pivot} ${
            pivot in filters && filters[pivot] != "All"
                ? `== "${filters[pivot]}"`
                : '!= "All" '
        } ${getAllQueryForRemainingPivots(pivot, filters)} 
        | extend Metric = "${getFilterLabel(pivot)}" 
        | project Stage, Metric, Name=${pivot}, Value, Total;`;

        str += intermediateRowset;
    });
    return str;
};

export const getDropOffNameForStage = (stage: FunnelStage, application: string) => {
    switch (stage) {
        case FunnelStage.Unabandoned:
            return FunnelDropOff.FirstSessionsToUnabandoned;

        case FunnelStage.CommandingActions:
            switch (application) {
                case "Word":
                    return FunnelDropOff.UnabandonedToCommandingActions;
                case "Excel":
                case "PowerPoint":
                    return FunnelDropOff.FirstSessionsToCommandingActions;
                default:
                    return null;
            }
        case FunnelStage.EditDocument:
            switch (application) {
                case "Word":
                case "Excel":
                case "PowerPoint":
                    return FunnelDropOff.CommandingActionsToEditDocument;
                default:
                    return null;
            }
        case FunnelStage.Collab:
            switch (application) {
                case "Word":
                case "PowerPoint":
                    return FunnelDropOff.EditDocumentToCollab;
                default:
                    return null;
            }
        case FunnelStage.OtherRetentionDrivers:
            return FunnelDropOff.EditDocumentToOtherRetentionDrivers;

        case FunnelStage.OtherRetentionDriversHittingThreshold:
            return FunnelDropOff.OtherRetentionDriversToOtherRetentionDriversHittingThreshold;
        case FunnelStage.FirstSessions:
            return FunnelDropOff.Default;

        default:
            return null;
    }
};

export const getDroppedStageNames = (stage: FunnelStage, application: string) => {
    switch (stage) {
        case FunnelStage.FirstSessions:
            return FunnelDroppedStage.Default;
        case FunnelStage.Unabandoned:
            return FunnelDroppedStage.FirstSessionsSessionsDropped;
        case FunnelStage.CommandingActions:
            switch (application) {
                case "Word":
                    return FunnelDroppedStage.UnabandonedSessionsDropped;
                case "Excel":
                case "PowerPoint":
                    return FunnelDroppedStage.FirstSessionsSessionsDropped;
                default:
                    return FunnelDroppedStage.Default;
            }
        case FunnelStage.EditDocument:
            return FunnelDroppedStage.CommandingActionsSessionsDropped;
        case FunnelStage.OtherRetentionDrivers:
            return FunnelDroppedStage.EditDocumentSessionsDropped;
        case FunnelStage.OtherRetentionDriversHittingThreshold:
            return FunnelDroppedStage.OtherRetentionDriversSessionsDropped;
        case FunnelStage.Collab:
            switch (application) {
                case "Word":
                case "PowerPoint":
                    return FunnelDroppedStage.EditDocumentSessionsDropped;
                default:
                    return FunnelDroppedStage.Default;
            }
        default:
            return FunnelDroppedStage.Default;
    }
};

export const SCENARIO_CHART_INNER_COLORS = ["#8884d8", "#82ca9d"];
export const SCENARIO_CHART_OUTER_COLORS = [
    "#8884d8",
    "#8884d8",
    "#82ca9d",
    "#82ca9d",
];
export const RADIAN = Math.PI / 180;

export const renderCustomizedLabelForInner = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    value,
    percent,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            fontSize={11}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {partitions(
                `${name.toUpperCase()} ${getCompactValue(value)} (${precisionRound(
                    percent,
                    2,
                )}%)`,
                x,
                y,
                4,
            )}
        </text>
    );
};

export const getFilterLabel = (item: string) => {
    if (item === "os") {
        return item.toUpperCase();
    } else {
        return item.charAt(0).toUpperCase() + item.slice(1);
    }
};

export const partitions = (
    name: string,
    x: number,
    y: number,
    maxLengthInLine: number,
) => {
    const textfontSize = 11;

    const lineHeight = 1.1;
    let i = 0;
    const result = [];
    while (i < name.length) {
        let end =
            i + maxLengthInLine < name.length ? i + maxLengthInLine : name.length;

        // Dont break the word in between but check for next space
        if (end < name.length && name.charAt(end) !== " ")
            while (end < name.length && name.charAt(end) !== " ") end += 1;

        result.push(end);
        i = end;
    }

    if (i !== name.length) result.push(name.length - 1);

    return result.map((data, index) => {
        const start = index === 0 ? 0 : result[index - 1];
        return (
            <tspan x={x} y={y + index * textfontSize * lineHeight} key={index}>
                {name.substring(start, data)}
            </tspan>
        );
    });
};

export const renderCustomizedLabelForOuter = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    name,
    value,
    percent,
}): any => {
    const radius = outerRadius + 15;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <>
            <text
                x={x}
                y={y}
                alignmentBaseline="middle"
                fontSize={11}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
            >
                {partitions(
                    `${name} ${getCompactValue(value)} (${precisionRound(
                        percent,
                        2,
                    )}%)`,
                    x,
                    y,
                    12,
                )}
            </text>
        </>
    );
};

export const maxArray = (array: StageMetricPayloadType[]) => {
    let maxValue = 0;
    if (array != null) {
        maxValue = array.reduce((acc, value) => {
            return (acc = acc > value.Percent ? acc : value.Percent);
        }, 0);
    }
    return maxValue;
};

export const parentKeys: ParentReferralSources[] = [
    FunnelReferralSources.HarmonyAll,
    FunnelReferralSources.OneDrive,
    FunnelReferralSources.Other,
];
export const childKeys: ChildReferralSources[] = [
    FunnelReferralSources.HarmonyAppStart,
    FunnelReferralSources.HarmonyHome,
    FunnelReferralSources.HarmonyOther,
    FunnelReferralSources.OneDrive,
    FunnelReferralSources.Other,
];

export const PARENT_REFERRER_LEVEL_COLORS = [
    "#155F82",
    "#A02B93",
    "#E97135",
    "#5CAA47",
];

export const CHILD_REFERRER_LEVEL_COLORS = [
    "#155F82",
    "#155F82",
    "#155F82",
    "#A02B93",
    "#E97135",
    "#5CAA47",
];

export const parentColorScale = scaleOrdinal<ParentReferralSources, string>({
    domain: parentKeys.sort(),
    range: PARENT_REFERRER_LEVEL_COLORS,
});

export const childColorScale = scaleOrdinal<ChildReferralSources, string>({
    domain: childKeys.sort(),
    range: CHILD_REFERRER_LEVEL_COLORS,
});

export const valueScale = scaleLinear<number>({
    domain: [0, 100], // since percentage
    nice: true,
});

export const day0FunnelHierarchicalMap = () => {
    const map = new Map();
    map.set(FunnelReferralSources.HarmonyAll, [
        FunnelReferralSources.HarmonyAppStart,
        FunnelReferralSources.HarmonyHome,
        FunnelReferralSources.HarmonyOther,
    ]);
    map.set(FunnelReferralSources.OneDrive, [FunnelReferralSources.OneDrive]);
    map.set(FunnelReferralSources.Other, [FunnelReferralSources.Other]);

    return map;
};

export function formatReferrers(data: ReferralDataType[], date: string) {
    const referralObjPct: ReferralDataObject | any = new Object();
    const referralData = [];
    if (!(date in referralObjPct)) {
        referralObjPct["date"] = date;
    }

    const AllUp = data?.find((a) => a.name === FunnelReferralSources.All);

    data?.forEach((a) => {
        const percent = ((a.value as number) * 100) / (AllUp.value as number);
        const pct =
            percent <= 0.009
                ? precisionRound(percent, 4)
                : precisionRound(percent, 2);

        if (!(a.name in referralObjPct)) {
            referralObjPct[a.name] = pct;
        }
    });
    referralData.push(referralObjPct);

    return referralData;
}

export const additionalFilterKeys = [
    "browser",
    "datacenter",
    "country",
    "appMode",
    "os",
    "referrerPlatform",
];

export function getAdditionalFiltersToShow(filtersFromQueryParams) {
    let additionalFilters = new Set<string>();
    const additionalFiltersSet = new Set<string>(additionalFilterKeys);
    for (let key in filtersFromQueryParams) {
        if (additionalFiltersSet.has(key)) {
            additionalFilters.add(key);
        }
    }

    return additionalFilters;
}

export function populateQueryParamsForAsha(queryParams) {
    for (const key of additionalFilterKeys) {
        if (queryParams[key] === undefined) {
            queryParams[key] = "All";
        }
    }
}

export const DropOffNames = (() => {
    const flattened = {};

    Object.values(AppSpecificDropOffNames).forEach((value) => {
        Object.assign(flattened, value);
    });

    return flattened;
})();
