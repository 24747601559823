export const tenantTopAddInsPerformanceQuery = `
// tenantTopAddInsPerformanceQuery
Summary_AddInsByTenantV3
| where AddInVersion == "All" and SQLizerStreamDateId == (toscalar(Summary_AddInsByTenantV3 | summarize SQLizerStreamDateId = max(SQLizerStreamDateId)))
| where TenantId=='\${id}' and AppName in ("Word", "Excel", "PowerPoint", "Outlook", "OneNote") and PercentDevicesWithAddInVersion >= 5.0 and (DeviceCount > 100 or DevicesWithAddIn > 20)
| extend AddInFriendlyName = case(isempty(AddInCLSID), "Private add-ins", case(isnotempty(AddInFriendlyName), AddInFriendlyName, isnotempty(AddInCLSID), strcat("Unknown (CLSID ", AddInCLSID, ")"), "Unknown (CLSID missing)"))
| extend AddInProgId = case(isempty(AddInCLSID), "Private add-ins", coalesce(AddInProgId, "Unknown Add-In(s)"))
| summarize arg_max(DevicesWithAddIn, PercentDevicesWithAddInVersion, TypicalLoadDurationPerSession, AddInFriendlyName, IsSampled) by AppName, AddInProgId, AddInCLSID
| extend AddInProgId = strcat(AddInProgId, case(IsSampled, " (Sampled)", ""))
| project AppName, Name=AddInFriendlyName, ProgId=AddInProgId, DevicePercentage=min_of(PercentDevicesWithAddInVersion,100.0), TypicalDuration=TypicalLoadDurationPerSession, IsSampled
| order by AppName asc, TypicalDuration desc
`;
