import React from "react";
import { logException } from "utils/AppInsightsHelper";

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        logException(
            new Error("UnhandledException"),
            {
                message: "Unhandled exception caught",
            },
            error,
        );
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException(
            new Error("UnhandledException"),
            {
                message: "Unhandled exception caught by Error boundary",
                state: this.state,
                errorInfo,
            },
            error,
        );
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
