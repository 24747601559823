import React from "react";
import { DefaultButton, IStackTokens, Stack } from "@fluentui/react";
import { buttonStyle } from "pages/common";
import {
    DefaultWorkloadAppButtonLabel,
    WorkloadApplicationNameStore,
} from "utils/Constants";
import { AppButtonsPropsType } from "utils/types";

const stackTokens: IStackTokens = { childrenGap: 10 };

export const AppButtons = ({
    selectedApp,
    setSelectedApp,
    workload,
}: AppButtonsPropsType) => {
    const appConstants = workload
        ? {
              Application: WorkloadApplicationNameStore[workload],
              DefaultAppButtonLabel: DefaultWorkloadAppButtonLabel[workload],
          }
        : {
              Application: {},
              DefaultAppButtonLabel: "WXPON",
          };
    const { Application, DefaultAppButtonLabel } = appConstants;

    return (
        <Stack horizontal tokens={stackTokens} horizontalAlign="center">
            {DefaultAppButtonLabel !== "" && (
                <DefaultButton
                    text={DefaultAppButtonLabel}
                    onClick={() => setSelectedApp(DefaultAppButtonLabel)}
                    checked={selectedApp === DefaultAppButtonLabel}
                    styles={buttonStyle}
                />
            )}
            {Object.keys(Application).map((app) => {
                return (
                    <DefaultButton
                        text={app}
                        onClick={() => setSelectedApp(app)}
                        checked={selectedApp === app}
                        styles={buttonStyle}
                        key={app}
                    />
                );
            })}
        </Stack>
    );
};
