import React from "react";
import { Dropdown, Label, Stack, TextField } from "@fluentui/react";
import { DatePicker } from "components/CustomComponents/DatePicker/DatePicker";
import { cohortOptions } from "config/PlatformConfig";
import "pages/common.css";
import { AddInsFilterPanelProps } from "pages/AddIns/types";
import { addinPublisherCategories } from "utils/Constants";

export const AddInFilterPanel = ({
    panelFilters,
    dateOptions,
    onFilterChange,
    onSearchTextChange,
}: AddInsFilterPanelProps) => {
    return (
        <Stack horizontal className="filterPanel">
            <div className="timePeriod">
                <DatePicker
                    dateOptions={dateOptions}
                    onChangeSelection={onFilterChange("date")}
                    selected={panelFilters.date}
                />
            </div>
            <div className="cohortFilter">
                <Label className="filterLabel">Cohort:</Label>
                <Dropdown
                    options={cohortOptions}
                    onChange={onFilterChange("cohort")}
                    selectedKey={panelFilters.cohort}
                    className="filterDropdown dropdownMinWidth"
                />
            </div>
            <div className="publisherFilter">
                <Label className="filterLabel">Publisher: </Label>
                <Dropdown
                    options={addinPublisherCategories}
                    onChange={onFilterChange("publisher")}
                    selectedKey={panelFilters.publisher}
                    className="filterDropdown dropdownMinWidth"
                />
            </div>
            <div className="addinSearchFilter">
                <Label className="filterLabel">Search for Add-In: </Label>
                <TextField
                    placeholder="Search Name or ProgId"
                    onChange={onSearchTextChange}
                    iconProps={{ iconName: "Search" }}
                    className="filterDropdown"
                />
            </div>
        </Stack>
    );
};

export default AddInFilterPanel;
