import { mergeStyles } from "@fluentui/react";

export const appIconStyle = mergeStyles({
    selectors: {
        "> *": {
            height: 45,
            width: 45,
        },
    },
});

export const miniAppIconStyle = mergeStyles({
    selectors: {
        "> *": {
            height: 18,
            width: 18,
        },
    },
});
