export const teamsAvgCohortMetrics: string = `
// Teams AvgCohortsCore Metrics
let app = "\${app}";
let dt = "\${date}";
StrategicTenants
| where Date == toscalar(StrategicTenants|summarize max(Date))
| where \${levelColumnName} == "\${id}" 
| sort by IsS2500, IsS500, IsEPA, IsGov |take 1
| project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
| project allcohorts = split(Cohorts,';')
| project iff(array_length( allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
| mv-expand Cohort = Column1
| project Cohort = tostring(Cohort)
| where Cohort != "OXOPriorityTenants"
| join kind=inner(Teams_Cohort_Averages | where SubWorkload==app and Date == dt
| where Signal != "Score"
| evaluate pivot(Signal, take_any(round(Average, 2)), Date, Cohort, SubWorkload)) on Cohort
| project-away Cohort1, SubWorkload, Date
`;
