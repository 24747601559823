import React from "react";
import {
    funnelSVGArrowFill,
    funnelSVGFillColor,
    funnelSVGHeight,
    funnelSVGStrokeColor,
    funnelSVGWidth,
    funnelTextFill,
    arrowHeight,
    arrowTextWidth,
} from "components/ConsumerDay0FunnelComponents/ReferrerFunnel/constants";

export const ReferrerFunnel = ({ selectedReferrer }) => {
    return (
        <svg width={funnelSVGWidth} height={funnelSVGHeight}>
            <g transform="translate(0,-65)">
                <g>
                    <path
                        stroke={funnelSVGStrokeColor}
                        id="funnelSVG"
                        d="M176.4736837817383,637.0000589648438 L-3.4736862182617188,542.8750289648438 L-3.4736862182617188,229.12492896484375 L176.4736837817383,134.99993896484375 L176.4736837817383,637.0000389648437 L176.4736837817383,637.0000489648437 L176.4736837817383,637.0000589648438 z"
                        fill={funnelSVGFillColor}
                    />
                </g>
                <path
                    id="funnelSVGArrow"
                    d="M-1.4736762182617191,366.99994704176663 L127.5262637817383,366.99994704176663 L170.52631378173828,387.9998981033921 L127.5262637817383,408.99993801116943 L-1.4736762182617191,408.99993801116943 L20.01095378173828,387.1922058140397 L-1.4736762182617191,366.99994704176663 z"
                    stroke={funnelSVGArrowFill}
                    fill={funnelSVGArrowFill}
                />
                <foreignObject
                    id="referrerFunnelArrow"
                    width={arrowTextWidth}
                    height={arrowHeight}
                    y="365"
                    x="20"
                    stroke={funnelTextFill}
                    fill={funnelTextFill}
                >
                    <span
                        style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            display: "table-cell",
                            height: arrowHeight,
                            color: funnelTextFill,
                            width: arrowTextWidth,
                        }}
                    >
                        {selectedReferrer}
                    </span>
                </foreignObject>
            </g>
        </svg>
    );
};
