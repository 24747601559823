import React from "react";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleBand } from "@visx/scale";
import { BarStack } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
    background,
    barStackHorizontalCompartmentSize,
    barStackNumberOfCompartments,
    barStackYPaddingOffset,
    barStackPercentagePaddingOffset,
    barStackVerticalCompartmentSize,
    barStackWidthOffSet,
    defaultMargin,
    dominantBaseline,
    fontSize,
    strokeColor,
    strokeWidth,
    textAnchor,
    textFill,
    tooltipStyles,
    barStackXPaddingOffset,
    mapText,
    minHeight,
    heightAdjustWhenMin,
} from "components/CustomComponents/IcicleChart/constants";
import {
    IcicleChartProps,
    TooltipData,
} from "components/CustomComponents/IcicleChart/types";
import { FunnelReferralSources } from "pages/ConsumerDay0Funnel/constants";

export const rotateText = (x: number, y: number) => {
    return `rotate(-90 ,${x}, ${y})`;
};

export const getFilteredKeys = (data, keysToFilter) => {
    const updatedKeys = Object.keys(data).filter((d) => keysToFilter.includes(d));
    if (updatedKeys.length === 0) {
        updatedKeys.push(FunnelReferralSources.All);
    }
    return updatedKeys;
};

export const IcicleChart = ({
    width,
    height,
    margin = defaultMargin,
    hierarchicalData,
    parentKeys,
    parentColorScale,
    childKeys,
    childColorScale,
    actionHandle,
    selectedItem,
    isDefault,
    yScale,
    getXAxis,
    hierarchicalMap,
    getToolTipMessage,
}: IcicleChartProps) => {
    const {
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    } = useTooltip<TooltipData>();
    let tooltipTimeout: number;
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
    });

    if (width < 10) return null;
    // bounds
    const xMax = width;
    const yMax = height - margin.top - barStackHorizontalCompartmentSize;

    const parentBarWidth = width / barStackNumberOfCompartments;
    const childBarWidth =
        (width / barStackNumberOfCompartments) * (barStackNumberOfCompartments - 1);

    // scales
    const xScale = scaleBand<string>({
        range: [0, xMax],
        domain: hierarchicalData.map(getXAxis),
    });

    xScale.rangeRound([0, xMax]);
    yScale.range([yMax, 0]);

    const checkVisibility = (height: number, isHeight: boolean) => {
        // for the child stacks we place the text and percentage value in the middle of the box, so we check if height/2 (middle position) + the gap between text and percentage (paddingOffset) + gap below the percentage (percentage padding offset) is between the total stack height of that child stack
        if (
            (height <
                height / 2 +
                    barStackYPaddingOffset +
                    barStackPercentagePaddingOffset &&
                !isHeight) ||
            (height < barStackWidthOffSet && isHeight)
        )
            return "hidden";
        return "visible";
    };

    const isParentSelected = () => {
        if (hierarchicalMap.has(selectedItem)) {
            return true;
        }
        return false;
    };

    const getOpacity = (bar) => {
        return selectedItem === bar.key ||
            (isParentSelected &&
                hierarchicalMap.get(selectedItem)?.indexOf(bar.key) > -1) ||
            isDefault
            ? 1
            : 0.8;
    };

    const handleToolTip = (event, bar, isParent) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout);
        const eventSvgCoords = localPoint(event);
        const left = isParent ? bar.x : bar.x + bar.width / 2;
        showTooltip({
            tooltipData: bar,
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: left,
        });
    };

    return width < 10 ? null : (
        <div className="icicleChartContainer">
            <svg ref={containerRef} width={width} height={height}>
                <rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill={background}
                    rx={14}
                />
                <>
                    <BarStack
                        data={hierarchicalData}
                        keys={getFilteredKeys(
                            hierarchicalData[0],
                            parentKeys,
                        ).sort()}
                        x={getXAxis}
                        xScale={xScale}
                        yScale={yScale}
                        color={parentColorScale}
                    >
                        {(barStacks) =>
                            barStacks.map((barStack) =>
                                barStack.bars.map((bar) => (
                                    <Group
                                        key={`bar-group-${barStack.index}-${bar.index}`}
                                        left={
                                            width / barStackHorizontalCompartmentSize
                                        }
                                        top={
                                            height / barStackVerticalCompartmentSize
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <rect
                                            key={`bar-stack-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            height={
                                                bar.height <= minHeight
                                                    ? bar.height +
                                                      heightAdjustWhenMin
                                                    : bar.height
                                            }
                                            width={parentBarWidth}
                                            fill={bar.color}
                                            stroke={strokeColor}
                                            strokeWidth={strokeWidth}
                                            onClick={() => {
                                                actionHandle(bar.key);
                                            }}
                                            opacity={getOpacity(bar)}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(
                                                    () => {
                                                        hideTooltip();
                                                    },
                                                    300,
                                                );
                                            }}
                                            onMouseMove={(event) => {
                                                handleToolTip(event, bar, true);
                                            }}
                                        />
                                        <text
                                            x={bar.x + parentBarWidth / 2}
                                            y={bar.y + bar.height / 2}
                                            fontSize={fontSize}
                                            fill={textFill}
                                            visibility={checkVisibility(
                                                bar.height,
                                                true,
                                            )}
                                            transform={rotateText(
                                                bar.x + parentBarWidth / 2,
                                                bar.y + bar.height / 2,
                                            )}
                                            textAnchor={textAnchor}
                                            dominantBaseline={dominantBaseline}
                                            onClick={() => {
                                                actionHandle(bar.key);
                                            }}
                                        >
                                            {mapText.has(bar.key)
                                                ? mapText.get(bar.key)
                                                : bar.key}
                                        </text>
                                    </Group>
                                )),
                            )
                        }
                    </BarStack>
                    <BarStack
                        data={hierarchicalData}
                        keys={getFilteredKeys(hierarchicalData[0], childKeys).sort()}
                        x={getXAxis}
                        xScale={xScale}
                        yScale={yScale}
                        color={childColorScale}
                    >
                        {(barStacks) =>
                            barStacks.map((barStack) =>
                                barStack.bars.map((bar) => (
                                    <Group
                                        key={`bar-group-${barStack.index}-${bar.index}`}
                                        left={
                                            width / barStackHorizontalCompartmentSize
                                        }
                                        top={
                                            height / barStackVerticalCompartmentSize
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <rect
                                            key={`bar-stack-${barStack.index}-${bar.index}`}
                                            x={bar.x + parentBarWidth}
                                            y={bar.y}
                                            height={
                                                bar.height <= minHeight
                                                    ? bar.height +
                                                      heightAdjustWhenMin
                                                    : bar.height
                                            }
                                            width={childBarWidth}
                                            fill={bar.color}
                                            stroke={strokeColor}
                                            strokeWidth={strokeWidth}
                                            onClick={() => {
                                                actionHandle(bar.key);
                                            }}
                                            opacity={getOpacity(bar)}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(
                                                    () => {
                                                        hideTooltip();
                                                    },
                                                    300,
                                                );
                                            }}
                                            onMouseMove={(event) => {
                                                handleToolTip(event, bar, false);
                                            }}
                                        />
                                        <text
                                            x={
                                                bar.x +
                                                childBarWidth / 2 +
                                                barStackXPaddingOffset
                                            }
                                            y={bar.y + bar.height / 2}
                                            fontSize={fontSize}
                                            fill={textFill}
                                            visibility={checkVisibility(
                                                bar.height,
                                                false,
                                            )}
                                            textAnchor={textAnchor}
                                            onClick={() => {
                                                actionHandle(bar.key);
                                            }}
                                        >
                                            {mapText.has(bar.key)
                                                ? mapText.get(bar.key)
                                                : bar.key}
                                        </text>
                                        <text
                                            x={
                                                bar.x +
                                                childBarWidth / 2 +
                                                barStackXPaddingOffset
                                            }
                                            y={
                                                bar.y +
                                                bar.height / 2 +
                                                barStackYPaddingOffset
                                            }
                                            fontSize={fontSize}
                                            fill={textFill}
                                            visibility={checkVisibility(
                                                bar.height,
                                                false,
                                            )}
                                            textAnchor={textAnchor}
                                            onClick={() => {
                                                actionHandle(bar.key);
                                            }}
                                        >
                                            {`${bar.bar.data[bar.key]}%`}
                                        </text>
                                    </Group>
                                )),
                            )
                        }
                    </BarStack>
                </>
            </svg>
            {tooltipOpen && tooltipData && (
                <TooltipInPortal
                    top={tooltipTop}
                    left={tooltipLeft}
                    style={tooltipStyles}
                >
                    {getToolTipMessage(tooltipData)}
                </TooltipInPortal>
            )}
        </div>
    );
};
