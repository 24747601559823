import React from "react";
import { Stack, ThemeProvider } from "@fluentui/react";
import { M365ActualLightTheme } from "@m365-admin/customizations";
import { EmptyState } from "@m365-admin/empty-state";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { AddInsTableCard } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AddInsTableCard";
import { AdvisoriesTableCard } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/AdvisoriesTableCard";
import { emptyStateStyle } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { M365WrapperProps } from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { getConfig, PlatformConfigType } from "config/PlatformConfig";
import { InfoLinks } from "utils/Links";
import { ADMINCENTER_MESSAGES } from "utils/Messages";

export const M365AppsHealthWrapper = ({
    platform,
    level,
    id,
    date,
}: M365WrapperProps) => {
    const platformConfig: PlatformConfigType = getConfig(platform);
    const isFeatureEnabled: boolean =
        platformConfig.featureConfig?.InProductDrillDown["isAdminCenterEnabled"];

    return (
        <>
            {level === "Tpid" ? (
                <EmptyState
                    title={ADMINCENTER_MESSAGES.NO_TPID_DATA}
                    body=""
                    styles={emptyStateStyle}
                />
            ) : !isFeatureEnabled ? (
                <EmptyState
                    title={ADMINCENTER_MESSAGES.NO_PLATFORM_DATA}
                    body=""
                    styles={emptyStateStyle}
                />
            ) : (
                <Stack>
                    <ThemeProvider theme={M365ActualLightTheme}>
                        <h2 className="commonPageBlockTitle">
                            App Health Advisories
                            <LinkIconButton
                                link={InfoLinks.AdmiCenterAdvisories}
                                message="Read more about App Health Advisories"
                            />
                        </h2>
                        <Stack.Item>
                            <AdvisoriesTableCard id={id} date={date} />
                        </Stack.Item>
                        <div className="break" />
                        <h2 className="commonPageBlockTitle">
                            Add-ins
                            <LinkIconButton
                                link={InfoLinks.AdminCenterAddins}
                                message="Read more about Add-ins health"
                            />
                        </h2>
                        <Stack.Item>
                            <AddInsTableCard id={id} date={date} />
                        </Stack.Item>
                    </ThemeProvider>
                </Stack>
            )}
        </>
    );
};
