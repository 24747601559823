export const win32CoreMetrics: string = `
//win32CoreMetrics (To display corresponding metrics, on tiles)
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime("\${date}")), "yyyy-MM-dd");
let currentDate = GetCurrentDate(false);
StrategicTenants
| where Date == currentDate and \${levelColumnName} == "\${id}" | 
project OmsTenantId,TenantName,OrgName,tolong(Tpid) | limit 1 |join
kind = inner 
(
HealthyTenantScore_Win32Overview | project-rename Tpid = TopParentId | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") | where Date == "\${date}" and 
\${levelColumnName} == "\${id}" and Application == "\${app}"
)
on \${levelColumnName} |
join kind = leftouter (
    HealthyTenantScore_Win32Overview | project-rename Tpid = TopParentId | where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All") | where Date == prev and 
\${levelColumnName} == "\${id}" and Application == "\${app}" 
) 
on \${levelColumnName} 
|extend Engagement =round(Usage_PercMau5DayUsage_Score,2),
        MoM_Engagement = round(Usage_PercMau5DayUsage_MoMChange*100,2),Color_Engagement = GetColor(Usage_PercMau5DayUsage_Color),
        Currency = round(Currency_InSupport_Score,2),
        MoM_Currency = round((Currency_InSupport_Score-Currency_InSupport_Score1)/Currency_InSupport_Score1*100,2),Color_Currency = GetColor(Currency_InSupport_Color),
        OCV = round(Feedback_Score,2),MoM_OCV=round(Feedback_MoMChange*100,2),Color_OCV = GetColor(Feedback_Color),
        Watson= round(Quality_Watson_Score,2),MoM_Watson=round(Quality_Watson_MoMChange*100,2),Color_Watson=GetColor(Quality_Watson_Color),
        UAE = round(Quality_UAE_Score,2),MoM_UAE=round(Quality_UAE_MoMChange*100,2),Color_UAE = GetColor(Quality_UAE_Color),
        PerfP95=Perf_BootTimeP95_Score,MoM_PerfP95=round(Perf_BootTimeP95_MoMChange*100,2),Color_PerfP95=GetColor(Perf_Color),
        BootThreshold=round(Perf_BootPercentageUnderNs_Score,2),MoM_BootThreshold=round(Perf_BootPercentageUnderNs_MoMChange*100,2),
        Color_BootThreshold=GetColor(Perf_Color),HealthColor=GetColor(Color)
        | project Engagement,Currency,OCV,Watson,UAE,PerfP95,BootThreshold,MoM_Engagement,MoM_Currency,
        MoM_OCV,MoM_Watson,MoM_UAE,MoM_PerfP95,MoM_BootThreshold,Color_Engagement,Color_Currency,Color_OCV,Color_Watson,Color_UAE,Color_PerfP95,
        Color_BootThreshold,HealthColor,Score,Application`;
