import React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { appStyles } from "utils/Constants";
import "components/AIScorecardComponents/style.css";

const stackTokens: IStackTokens = { childrenGap: 10 };

export const AppLegend = ({ onClickHandler, apps, width }) => {
    return (
        <Stack
            horizontal
            tokens={stackTokens}
            horizontalAlign="center"
            style={{ width }}
        >
            {Object.keys(apps).map((app, idx) => {
                const checked = apps[app];
                const color = appStyles[app]["color"];
                return (
                    <Stack.Item
                        className="appLegendKey"
                        onClick={() => onClickHandler(app)}
                        style={
                            checked
                                ? {
                                      border: `3px solid ${color}`,
                                  }
                                : { border: "1px solid rgb(138, 136, 134)" }
                        }
                        key={idx}
                    >
                        {app}
                    </Stack.Item>
                );
            })}
        </Stack>
    );
};
