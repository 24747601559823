import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { IStackTokens, Stack, DefaultEffects } from "@fluentui/react";
import {
    DocumentCard,
    DocumentCardPreview,
    DocumentCardTitle,
    IDocumentCardPreviewProps,
} from "@fluentui/react/lib/DocumentCard";
import { ImageFit } from "@fluentui/react/lib/Image";
import { getFileTypeIconAsUrl } from "@fluentui/react-file-type-icons";
import { insightsDashboards } from "components/InsightsComponents/constants";
import { IDashboardLink } from "components/InsightsComponents/types";
import { commonPageStyle } from "pages/common";
import { useDynamicWidth, useNavigateToDashboard } from "utils/InsightsHooks";

const mediumSpacingStackTokens: IStackTokens = {
    childrenGap: "m",
};

const maxWidthStackTokens: IStackTokens = {
    maxWidth: "100%",
};

interface DashboardTilesProps {
    navCollapsed: boolean;
    setNavCollapsed: (navCollapsed: boolean) => void;
}

const DashboardTiles = ({ navCollapsed, setNavCollapsed }: DashboardTilesProps) => {
    const navigateToDashboard = useNavigateToDashboard();
    const [searchParams] = useSearchParams();
    const dashboardGroup = insightsDashboards.find(
        (validatedDashboardGroup: IDashboardLink) =>
            validatedDashboardGroup.key === searchParams.get("Group"),
    );

    // We want to show as many dashboard tiles as will fit into one row, calculate width of container dynamically
    const TILE_WIDTH = 280;
    const TILE_HEIGHT = 125;
    const TILE_PADDING_MARGIN = 2 + 16; // 280px width + 2px border + 16px margin

    const NAV_WIDTH = navCollapsed ? 48 : 295;
    const COMMON_SPACING = 40 + 40; // 40px padding, 40px margin
    const dashboardTilesTotalWidth = useDynamicWidth(
        (windowWidth) => windowWidth - NAV_WIDTH - COMMON_SPACING,
    );
    const numTilesPerRow = Math.floor(
        dashboardTilesTotalWidth / (TILE_WIDTH + TILE_PADDING_MARGIN),
    );

    const getDocumentCardPreviewProps = (
        dashboard: IDashboardLink,
    ): IDocumentCardPreviewProps => {
        const props: IDocumentCardPreviewProps = {
            previewImages: [
                {
                    previewImageSrc: dashboard.screenshot,
                    iconSrc: dashboard.extension
                        ? getFileTypeIconAsUrl({
                              extension: dashboard.extension,
                              size: 32,
                              imageFileType: "png",
                          })
                        : "",
                    imageFit: ImageFit.centerCover,
                    width: TILE_WIDTH,
                    height: TILE_HEIGHT,
                },
            ],
        };
        return props;
    };

    // If selectedDashboardGroup is passed in, only show dashboards for that page
    // otherwise show only top ranked dashboards
    const renderDashboardTilesPerGroup = (
        dashboards: IDashboardLink[],
        dashboardGroupIndex: number,
    ) => {
        // Sort alphabetically by name if selectedDashboardGroup is passed in
        const sorted: IDashboardLink[] = dashboardGroup
            ? dashboards.sort((a: IDashboardLink, b: IDashboardLink) => {
                  const aName = a.name.toString().toUpperCase();
                  const bName = b.name.toString().toUpperCase();
                  return aName < bName ? -1 : aName > bName ? 1 : 0;
              })
            : dashboards;

        return sorted
            .sort((a: IDashboardLink, b: IDashboardLink) => a.rank - b.rank)
            .map((dashboard: IDashboardLink, index: number) => {
                return (
                    <Link
                        to={dashboard.href}
                        key={`dashboard-${dashboardGroupIndex}-${index}`}
                        onClick={(
                            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                        ) =>
                            navigateToDashboard(
                                e,
                                dashboard,
                                setNavCollapsed,
                                "Landing Page Tile",
                            )
                        }
                        style={{
                            display:
                                !dashboardGroup &&
                                (index < numTilesPerRow ? "block" : "none"),
                        }}
                    >
                        <DocumentCard
                            style={{
                                boxShadow: DefaultEffects.elevation4,
                            }}
                        >
                            <DocumentCardPreview
                                {...getDocumentCardPreviewProps(dashboard)}
                            />
                            <DocumentCardTitle
                                title={dashboard.name.toString()}
                                shouldTruncate
                            />
                        </DocumentCard>
                    </Link>
                );
            });
    };

    // If URL param Group is passed in, only show dashboards for that dashboard group
    // otherwise show top dashboards for all dashboard groups
    const renderDashboardTiles = insightsDashboards
        .filter((group: IDashboardLink) =>
            dashboardGroup ? group.key === dashboardGroup.key : true,
        )
        .map((group: IDashboardLink, index: number) => {
            return (
                <Stack
                    key={`dashboard-page-section-${index}`}
                    className="dashboardSection"
                >
                    {!dashboardGroup && (
                        <h2 className="dashboardSectionHeader">{group.name}</h2>
                    )}
                    <Stack wrap horizontal tokens={mediumSpacingStackTokens}>
                        {renderDashboardTilesPerGroup(group.links, index)}
                    </Stack>
                </Stack>
            );
        });

    return (
        <Stack styles={commonPageStyle} tokens={maxWidthStackTokens}>
            <h1 className="sectionHeader">
                {dashboardGroup?.name ?? "Top Dashboards & Scorecards"}
            </h1>
            {renderDashboardTiles}
        </Stack>
    );
};

export default DashboardTiles;
