import {
    FunnelDropOff,
    FunnelStage,
    FunnelDroppedStage,
} from "pages/ConsumerDay0Funnel/constants";
import {
    AshaLinks,
    AshaMergedScore,
    AshaStagePillarSessionCountData,
    AshaStageScoreData,
    FunnelAshaData,
    FunnelStageAshaInsight,
} from "pages/ConsumerDay0Funnel/types";
import { getComparisonPValue, getComparisonResult } from "utils/Asha/AshaStatHelper";
import { StatTestResult } from "utils/types";

export const mergeStageAshaScores = (
    stageAshaSessionCountsDropped: AshaStagePillarSessionCountData,
    stageAshaScoresDropped: AshaStageScoreData,
    stageAshaSessionCountsKept: AshaStagePillarSessionCountData,
    stageAshaScoresKept: AshaStageScoreData,
    dropOff: string,
): AshaMergedScore[] => {
    const mergedScores: AshaMergedScore[] = [];

    stageAshaScoresDropped?.AshaScore.forEach((droppedScore) => {
        const keptScore = stageAshaScoresKept?.AshaScore.find(
            (s) => s.Key === droppedScore.Key,
        );

        const droppedSessionCount =
            stageAshaSessionCountsDropped?.AshaPillarSessionCounts.find(
                (s) => s.Key === droppedScore.SessionCountKey,
            );

        const keptSessionCount =
            stageAshaSessionCountsKept?.AshaPillarSessionCounts.find(
                (s) => s.Key === droppedScore.SessionCountKey,
            );

        if (!keptScore || !droppedSessionCount || !keptSessionCount) {
            return;
        }

        const mergedScore: AshaMergedScore = {
            DropOff: dropOff,
            Pillar: droppedScore.Pillar,
            Key: droppedScore.Key,
        };

        if ("Veto" in droppedScore) {
            mergedScore.Veto = droppedScore.Veto;
        }

        if ("Error" in droppedScore) {
            mergedScore.Error = droppedScore.Error;
        }

        const pValue = getComparisonPValue(
            droppedScore.Score,
            droppedSessionCount.PillarSessionCount,
            keptScore.Score,
            keptSessionCount.PillarSessionCount,
        );

        mergedScore.DroppedScore = droppedScore.Score;
        mergedScore.DroppedSessionCount = droppedSessionCount.PillarSessionCount;
        mergedScore.HitVetoOrErrorSessionCount =
            droppedScore.HitVetoOrErrorSessionCount;
        mergedScore.KeptScore = keptScore.Score;
        mergedScore.KeptSessionCount = keptSessionCount.PillarSessionCount;
        mergedScore.Delta = droppedScore.Score - keptScore.Score;
        mergedScore.PValue = pValue;

        mergedScores.push(mergedScore);
    });

    return mergedScores;
};

export const mergeStageScoresToGenerateAshaInsights = (
    ashaData: FunnelAshaData,
    dropOff: string,
): FunnelStageAshaInsight[] => {
    let keptStage = null;
    let droppedStage = null;

    switch (dropOff) {
        case FunnelDropOff.FirstSessionsToUnabandoned:
            keptStage = FunnelStage.Unabandoned;
            droppedStage = FunnelDroppedStage.FirstSessionsSessionsDropped;
            break;

        case FunnelDropOff.UnabandonedToCommandingActions:
            keptStage = FunnelStage.CommandingActions;
            droppedStage = FunnelDroppedStage.UnabandonedSessionsDropped;
            break;

        case FunnelDropOff.CommandingActionsToEditDocument:
            keptStage = FunnelStage.EditDocument;
            droppedStage = FunnelDroppedStage.CommandingActionsSessionsDropped;
            break;

        case FunnelDropOff.EditDocumentToCollab:
            keptStage = FunnelStage.Collab;
            droppedStage = FunnelDroppedStage.EditDocumentSessionsDropped;
            break;

        case FunnelDropOff.FirstSessionsToCommandingActions:
            keptStage = FunnelStage.CommandingActions;
            droppedStage = FunnelDroppedStage.FirstSessionsSessionsDropped;
            break;

        case FunnelDropOff.EditDocumentToOtherRetentionDrivers:
            keptStage = FunnelStage.OtherRetentionDrivers;
            droppedStage = FunnelDroppedStage.EditDocumentSessionsDropped;
            break;

        case FunnelDropOff.OtherRetentionDriversToOtherRetentionDriversHittingThreshold:
            keptStage = FunnelStage.OtherRetentionDriversHittingThreshold;
            droppedStage = FunnelDroppedStage.OtherRetentionDriversSessionsDropped;
            break;

        case FunnelDropOff.Default:
        default:
            keptStage = null;
            droppedStage = null;
            break;
    }

    if (!droppedStage) {
        return null;
    }

    const droppedSessionCountData: AshaStagePillarSessionCountData =
        ashaData.PillarSessionCounts[droppedStage];
    const droppedPillarData: AshaStageScoreData =
        ashaData.PillarScores[droppedStage];
    const droppedVetoData: AshaStageScoreData = ashaData.VetoScores[droppedStage];
    const droppedErrorData: AshaStageScoreData = ashaData.ErrorScores[droppedStage];

    const keptSessionCountData: AshaStagePillarSessionCountData =
        ashaData.PillarSessionCounts[keptStage];
    const keptPillarData: AshaStageScoreData = ashaData.PillarScores[keptStage];
    const keptVetoData: AshaStageScoreData = ashaData.VetoScores[keptStage];
    const keptErrorData: AshaStageScoreData = ashaData.ErrorScores[keptStage];

    const pillarMergedScores = mergeStageAshaScores(
        droppedSessionCountData,
        droppedPillarData,
        keptSessionCountData,
        keptPillarData,
        dropOff,
    );

    const vetoMergedScores = mergeStageAshaScores(
        droppedSessionCountData,
        droppedVetoData,
        keptSessionCountData,
        keptVetoData,
        dropOff,
    );

    const errorMergedScores = mergeStageAshaScores(
        droppedSessionCountData,
        droppedErrorData,
        keptSessionCountData,
        keptErrorData,
        dropOff,
    );

    // Holm-Bonferroni corrections
    applyHBCorrectionToMergedScores(pillarMergedScores);
    applyHBCorrectionToMergedScores(vetoMergedScores);
    applyHBCorrectionToMergedScores(errorMergedScores);

    // Find top N insights
    const topComparisons = getTopComparisonDeltas([
        pillarMergedScores,
        vetoMergedScores,
        errorMergedScores,
    ]);

    return topComparisons;
};

const applyHBCorrectionToMergedScores = (mergedScores: AshaMergedScore[]) => {
    const pValues = new Map<string, number>();

    mergedScores.forEach((score: AshaMergedScore) => {
        const key = `${score.Pillar}_${score.Veto}_${score.Error}`;
        pValues.set(key, score.PValue);
    });

    const sortedPValues = [...pValues.values()];
    sortedPValues.sort((a, b) => a - b);

    mergedScores.forEach((score: AshaMergedScore) => {
        const pValue = score.PValue;
        const rank = sortedPValues.indexOf(pValue) + 1;

        score.TTestResult = getComparisonResult(pValue, sortedPValues.length, rank);
    });
};

const getTopComparisonDeltas = (
    allScores: Array<AshaMergedScore[]>,
): FunnelStageAshaInsight[] => {
    return getTopNDeltas(allScores.map(getAllDeltas));
};

const getAllDeltas = (scores: AshaMergedScore[]): FunnelStageAshaInsight[] => {
    const deltas: FunnelStageAshaInsight[] = [];

    scores.forEach((score: AshaMergedScore) => {
        if (
            score.Pillar === "Overall" ||
            score.TTestResult === StatTestResult.NotSignificant
        ) {
            return;
        }

        const delta: FunnelStageAshaInsight = {
            DropOff: score.DropOff,
            Pillar: score.Pillar,
            Veto: score.Veto,
            Error: score.Error,
            Delta: score.Delta,
            HitVetoOrErrorSessionCount: score.HitVetoOrErrorSessionCount,
        };

        deltas.push(delta);
    });

    return deltas;
};

export const MAX_NUM_OF_COMPARISON_INSIGHTS = 5;

const getTopNDeltas = (
    allDeltas: Array<AshaMergedScore[]>,
): FunnelStageAshaInsight[] => {
    const mergedDeltas: FunnelStageAshaInsight[] = [];

    allDeltas.forEach((subDeltas: AshaMergedScore[]) => {
        subDeltas.forEach((delta: AshaMergedScore) => {
            mergedDeltas.push({
                DropOff: delta.DropOff,
                Pillar: delta.Pillar,
                Veto: delta.Veto,
                Error: delta.Error,
                Delta: delta.Delta,
                HitVetoOrErrorSessionCount: delta.HitVetoOrErrorSessionCount,
            });
        });
    });

    mergedDeltas.sort(
        (entryA: FunnelStageAshaInsight, entryB: FunnelStageAshaInsight) => {
            const deltaA = Math.abs(entryA.Delta);
            const deltaB = Math.abs(entryB.Delta);

            if (deltaA > deltaB) {
                return -1;
            }

            if (deltaA < deltaB) {
                return 1;
            }

            return 0;
        },
    );

    // Only want to show deltas where the drop-off is worse
    // Pillar: positive delta means better, negative delta means worse
    // Veto and Error: negative delta means better, positive delta means worse
    return mergedDeltas
        .filter(
            (delta) =>
                (!delta.Veto && !delta.Error && delta.Delta < 0) ||
                ((delta.Veto || delta.Error) && delta.Delta > 0),
        )
        .slice(0, MAX_NUM_OF_COMPARISON_INSIGHTS);
};

export const AshaLinkMapping = {
    toolTip: "ASHA Drilldown",
    getAshaUrl: (ashalinks: AshaLinks) =>
        `//asha.microsoft.com/day0funnelcomparison?ViewBy=${
            ashalinks.viewBy === "Weekly" ? "Week" : "Month"
        }&M365App=Office&Platform=Web&CohortName=${ashalinks.cohortName}&App=${
            ashalinks.app
        }&${ashalinks.viewBy}Date=${ashalinks.date}&DropOff=${
            ashalinks.dropOff
        }&Scenario=${ashalinks.scenario}&Referrer=${ashalinks.referrer}&Browser=${
            ashalinks.browser
        }&Datacenter=${ashalinks.datacenter}&Country=${
            ashalinks.country
        }&ApplicationMode=${ashalinks.appMode}&OS=${ashalinks.os}&ReferrerPlatform=${
            ashalinks.referrerPlatform
        }`,
};
