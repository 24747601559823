import React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import AIScorecardFilters from "components/AIScorecardComponents/AIScorecardFilters";
import { CopilotJourneyFunnel } from "components/AIScorecardComponents/CopilotJourneyFunnel";
import { CopilotTopTenantInsights } from "components/AIScorecardComponents/CopilotTopTenantInsights";
import { CopilotM365Trends } from "components/AIScorecardComponents/CopilotTrends/CopilotM365Trends";
import { CopilotM365Scorecard } from "components/AIScorecardComponents/CopilotViews/CopilotM365Scorecard";
import { commonPageBlock } from "pages/common";
import { CopilotMteAndTvView } from "./CopilotMTEandTVView";

export const CopilotM365View = ({
    dates,
    filters,
    onFilterChange,
    tab,
    onTabSelected,
}) => {
    return (
        <>
            <AIScorecardFilters
                dates={dates}
                filters={filters}
                onFilterChange={onFilterChange}
            />
            <Pivot
                linkSize={"normal"}
                defaultSelectedKey={tab}
                onLinkClick={onTabSelected}
            >
                <PivotItem itemKey={"Scorecard"} headerText={"Scorecard"}>
                    <Stack.Item styles={commonPageBlock}>
                        <CopilotM365Scorecard filters={filters} />
                    </Stack.Item>
                    <Stack.Item styles={commonPageBlock}>
                        <CopilotM365Trends filters={filters}></CopilotM365Trends>
                    </Stack.Item>
                </PivotItem>
                <PivotItem
                    itemKey={"Top Tenant Insights"}
                    headerText={"Top Tenant Insights"}
                >
                    <Stack styles={commonPageBlock}>
                        <CopilotTopTenantInsights
                            filters={filters}
                        ></CopilotTopTenantInsights>
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={"Funnel"} headerText={"Funnel"}>
                    <Stack styles={commonPageBlock}>
                        <CopilotJourneyFunnel
                            filters={filters}
                        ></CopilotJourneyFunnel>
                    </Stack>
                </PivotItem>
                <PivotItem itemKey={"MTETV"} headerText={"MTE & TV"}>
                    <Stack styles={commonPageBlock}>
                        <CopilotMteAndTvView filters={filters}></CopilotMteAndTvView>
                    </Stack>
                </PivotItem>
            </Pivot>
        </>
    );
};
