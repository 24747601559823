import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { SegmentQueryParameters } from "./types";

export const defaultReferrerOption: IDropdownOption[] = [
    { key: "All", text: "All" },
];

export const SegmentParametersMap: {
    [segmentName: string]: {
        displayName: string;
        queryParameters: SegmentQueryParameters;
        referrerOptions: IDropdownOption[];
    };
} = {
    WebAll: {
        displayName: "Web All",
        queryParameters: {
            host: "All",
            license: "All",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    Outlook: {
        displayName: "Outlook Host",
        queryParameters: {
            host: "Exchange Online Consumer",
            license: "All",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    OneDrivePaidAll: {
        displayName: "OneDrive Paid (All)",
        queryParameters: {
            host: "OneDriveWOPI",
            license: "Paid",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    OneDriveFreeAll: {
        displayName: "OneDrive Free (All)",
        queryParameters: {
            host: "OneDriveWOPI",
            license: "Free",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    OneDriveFreeReturning: {
        displayName: "OneDrive Free (Returning Users)",
        queryParameters: {
            host: "OneDriveWOPI",
            license: "Free",
            seenType: "Returning",
        },
        referrerOptions: defaultReferrerOption,
    },
    OneDriveFreeNew: {
        displayName: "OneDrive Free (New Users)",
        queryParameters: {
            host: "OneDriveWOPI",
            license: "Free",
            seenType: "New",
        },
        referrerOptions: [
            { key: "All", text: "All" },
            { key: "Harmony All", text: "Harmony All" },
            { key: "Harmony Home Page", text: "Harmony Home Page" },
            { key: "Harmony App Start Pages", text: "Harmony App Start Pages" },
            { key: "Harmony Other", text: "Harmony Other" },
            { key: "OneDrive", text: "OneDrive" },
            { key: "Outlook", text: "Outlook" },
            { key: "Other", text: "Other Referrers" },
        ],
    },
    OdspConvergedPaidAll: {
        displayName: "ODSP Converged Paid (All)",
        queryParameters: {
            host: "ODSP Converged",
            license: "Paid",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    OdspConvergedFreeAll: {
        displayName: "ODSP Converged Free (All)",
        queryParameters: {
            host: "ODSP Converged",
            license: "Free",
            seenType: "All",
        },
        referrerOptions: defaultReferrerOption,
    },
    OdspConvergedFreeReturning: {
        displayName: "ODSP Converged Free (Returning Users)",
        queryParameters: {
            host: "ODSP Converged",
            license: "Free",
            seenType: "Returning",
        },
        referrerOptions: defaultReferrerOption,
    },
    OdspConvergedFreeNew: {
        displayName: "ODSP Converged Free (New Users)",
        queryParameters: {
            host: "ODSP Converged",
            license: "Free",
            seenType: "New",
        },
        referrerOptions: [
            { key: "All", text: "All" },
            { key: "Harmony All", text: "Harmony All" },
            { key: "Harmony Home Page", text: "Harmony Home Page" },
            { key: "Harmony App Start Pages", text: "Harmony App Start Pages" },
            { key: "Harmony Other", text: "Harmony Other" },
            { key: "OneDrive", text: "OneDrive" },
            { key: "Outlook", text: "Outlook" },
            { key: "Other", text: "Other Referrers" },
        ],
    },
};
