import React from "react";
import { Stack } from "@fluentui/react";
import {
    DailyNetworkTrend,
    MonthlyNetworkTrend,
} from "components/TenantDrilldownComponents/NetworkScore";
import { CommonTenantEnvironmentProps } from "components/TenantDrilldownComponents/TenantEnvironment/types";
import { equalStackItemsStyle, horizontalStackTokens } from "pages/common";

export const CommonTenantEnvironment = ({
    filters,
    selectedDate,
    platform,
}: CommonTenantEnvironmentProps) => {
    return (
        <>
            <div className="break" />
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item styles={equalStackItemsStyle}>
                    <DailyNetworkTrend
                        filters={filters}
                        selectedDate={selectedDate}
                        platform={platform}
                    />
                </Stack.Item>
                <Stack.Item styles={equalStackItemsStyle}>
                    <MonthlyNetworkTrend filters={filters} />
                </Stack.Item>
            </Stack>
        </>
    );
};
