import React from "react";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    CartesianGrid,
    Tooltip,
    XAxis,
    YAxis,
    Text,
} from "recharts";
import CustomToolTip from "components/CustomComponents/CustomToolTip";
import {
    drilldownChartHeight,
    chartXAxisPaddingStyles,
    chartMargin,
} from "pages/common";
import { MonthlyHealthyTenants } from "pages/MTR/types";
import { formatDisplayDate } from "utils/Helpers";

export const HealthyTenantTrendChart = ({
    data,
}: {
    data: MonthlyHealthyTenants[];
}) => {
    return (
        <ResponsiveContainer height={drilldownChartHeight}>
            <LineChart data={data} margin={chartMargin}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="Date"
                    tickFormatter={data.length > 0 ? formatDisplayDate : null}
                    padding={chartXAxisPaddingStyles}
                />
                <YAxis
                    dataKey="PercentHealthy"
                    unit="%"
                    label={<HealthyTenantCountLabel />}
                    tickCount={6}
                    domain={[(dataMin) => Math.min(85, dataMin), 100]}
                />
                <Tooltip
                    content={
                        <CustomToolTip
                            props={data}
                            customToolTipContentFunction={customToolTipFunction}
                        />
                    }
                />
                <Line dataKey="PercentHealthy" dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
};

const HealthyTenantCountLabel = () => {
    return (
        <Text dx={-175} dy={15} transform="rotate(-90)">
            % of S500 Healthy
        </Text>
    );
};

const customToolTipFunction = (props) => {
    const { active, payload } = props;
    if (active && payload) {
        return (
            <div className="customToolTipContainer">
                <p className="customToolTipText">
                    {formatDisplayDate(payload[0].payload.Date)}
                </p>
                <p className="customToolTipText redTooltip">
                    Unhealthy Tenants: {payload[0].payload.UnhealthyTenants}
                </p>
                <p className="customToolTipText blueTooltip">
                    Total Tenants: {payload[0].payload.TotalTenants}
                </p>
                <p className="customToolTipText redTooltip">
                    Percent Unhealthy: {payload[0].payload.PercentUnhealthy}%
                </p>
                <p className="customToolTipText blueTooltip">
                    Percent Healthy: {payload[0].payload.PercentHealthy}%
                </p>
            </div>
        );
    }

    return null;
};
