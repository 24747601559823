import AppInsights from "./AppInsights";
import {
    tenantColorViewLogType,
    requestRetryLogType,
    tenantDetailHoverViewType,
} from "./types";

export const appInsightsCustomEvents = {
    appLevelUsage: "appLevelViewUsage",
    tenantStrategyRankClick: "filterTenantOnStrategicRanking",
    metricTrendChart: "metricTrendChart",
    levelBasedView: "levelBasedView",
    filterUsage: "filterUsage",
    tenantColorView: "colorLevelViewUsage",
    PerformanceView: "Win32PerformanceView",
    kustoQueryRetryView: "kustoQueryRetryView",
    TenantDetailHoverView: "tenantDetailHoverView",
    featureUsageView: "FeatureUsageView",
    referralEvent: "ReferralEvent",
};

export function logUsageOfAppView(page: string, rankOn: string) {
    const properties = {};
    properties["page"] = page;
    properties["rankOn"] = rankOn;
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.appLevelUsage,
        properties,
    );
}

export function logUsageMetricsTrendChart(metric: string) {
    const properties = {};
    properties["metric"] = metric;
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.metricTrendChart,
        properties,
    );
}

export const logTenantColorBasedView = (
    tenantColorPropertiesLog: tenantColorViewLogType,
) => {
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.tenantColorView,
        tenantColorPropertiesLog,
    );
};

export const logOnHoverTenantNameView = (
    tenantHoverDetailViewTypeLog: tenantDetailHoverViewType,
) => {
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.TenantDetailHoverView,
        tenantHoverDetailViewTypeLog,
    );
};

export function logLevelBasedView(page: string, level: string) {
    const properties = {};
    properties["page"] = page;
    properties["level"] = level;
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.levelBasedView,
        properties,
    );
}

export function logFilterUsage(
    page: string,
    filterItem: string,
    filterValue: string,
) {
    const properties = {};
    properties["page"] = page;
    properties["filterItem"] = filterItem;
    properties["filterValue"] = filterValue;

    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.filterUsage,
        properties,
    );
}

export function logException(
    exception: Error,
    customProperties: any = {},
    causedByException: Error = null,
): void {
    if (!AppInsights.isInitialized()) return;

    const innerError = causedByException ? JSON.stringify(causedByException) : null;

    const properties = {
        ...customProperties,
        location: window.location.href,
        rootCauseError: innerError,
    };

    AppInsights.getInstance().LogException(exception, properties);
}

export const logKustoRetriedQueryView = (
    kustoQueryRetriedLog: requestRetryLogType,
) => {
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.kustoQueryRetryView,
        kustoQueryRetriedLog,
    );
};

export function logFeatureUsage(featureName: string) {
    const properties = {};
    properties["feature"] = featureName;
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.featureUsageView,
        properties,
    );
}

export function logErrorEvents(eventName: string, error?: {}) {
    if (!AppInsights.isInitialized()) return;

    AppInsights.getInstance().LogEvent(eventName, { error });
}

export function logReferralEvent(
    page: string,
    section: string,
    referralType: string,
    parameters: string,
    referralUrl: string,
) {
    const properties = {};
    properties["page"] = page;
    properties["section"] = section;
    properties["referralType"] = referralType;
    properties["parameters"] = parameters;
    properties["referralUrl"] = referralUrl;
    AppInsights.getInstance().LogEvent(
        appInsightsCustomEvents.featureUsageView,
        properties,
    );
}
