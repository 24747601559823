import { isInvalidInsightsRoute } from "components/InsightsComponents/Helpers";
import { InsightsHeaders, InsightsRoutes } from "utils/Links";

const validatedPathname = (pathname: string): string => {
    return pathname?.toLowerCase().match(/\/\w+/)?.[0];
};

// Only display shell header if on a valid, non-landing page
export const shouldShowShellHeader = (location: Location): boolean => {
    return shouldShowHeader(location, InsightsHeaders.ShellHeader);
};

// Experimental, only shown on consumer dashboards or invalid insights routes
export const shouldShowInsightsNav = (location: Location): boolean => {
    return (
        isInvalidInsightsRoute() ||
        shouldShowHeader(location, InsightsHeaders.InsightsNav)
    );
};

const shouldShowHeader = (location: Location, header: InsightsHeaders): boolean => {
    const path = validatedPathname(location?.pathname);

    return (
        path &&
        Object.keys(InsightsRoutes).some((routePath: string) => {
            return (
                InsightsRoutes[routePath].path === path &&
                InsightsRoutes[routePath].header === header
            );
        })
    );
};

export const shouldShowConfidentialityBanner = (location: Location): boolean => {
    const path = validatedPathname(location?.pathname);

    return (
        path &&
        Object.keys(InsightsRoutes).some((routePath: string) => {
            return (
                InsightsRoutes[routePath].path === path &&
                InsightsRoutes[routePath].shouldShowConfidentialityBanner
            );
        })
    );
};
