import React from "react";
import { Icon } from "@fluentui/react";
import SparkLine from "components/CustomComponents/SparkLine/SparkLine";
import { STATUS } from "utils/WebConstants";
import { ShimmeredCardBody } from "../ShimmeredWrapper/ShimmeredCardBody";

const Tile = ({
    sparkLineScores,
    loading,
    data: {
        MetricName: metric,
        Definition: defintion,
        MetricValue: metricValue,
        MomDelta: momDelta,
        YoyDelta: yoyDelta,
        AverageCohortValues: avgCohorts,
        Status: status,
        DeltaColor: negate,
        Goal: goal,
    },
    link,
}) => {
    status = status === undefined ? "" : status;

    enum DeltaType {
        MoM = "MoM Delta",
        YoY = "YoY Delta",
    }

    const getDeltaClass = (value: number) => {
        let arrowColor = null;
        if (value > 0) arrowColor = negate ? "negativeDelta" : "positiveDelta";
        else if (value < 0) arrowColor = negate ? "positiveDelta" : "negativeDelta";

        return arrowColor;
    };

    const delta = (deltaType: DeltaType, value: number) => {
        return (
            <span>
                {deltaType}
                <span className="cardAvg">
                    <span className={getDeltaClass(value)}>
                        {value < 0 ? (
                            <span>&#9660;</span>
                        ) : value > 0 ? (
                            <span>&#9650;</span>
                        ) : (
                            <></>
                        )}
                    </span>
                    <span> {value != null ? `${value}%` : "NA"}</span>
                </span>
            </span>
        );
    };

    const avgValue = (Title, data) => (
        <tr style={{ textAlign: "center" }} key={Title}>
            <td>
                <span>
                    {Title} &nbsp;
                    <span className="cardAvg">{data}</span>
                </span>
            </td>
        </tr>
    );

    const tileHeader = (
        <span>
            {metric} <br />
            {defintion !== "" ? (
                <span style={{ fontWeight: "normal", fontSize: "10px" }}>
                    {" "}
                    ({defintion}){" "}
                </span>
            ) : (
                <br />
            )}
        </span>
    );

    return (
        <li className="card">
            <div className="cardHeader">
                <a title={link?.toolTip} target="_blank" href={link?.href}>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "10px" }}>
                                    {(STATUS[status]?.highlight ?? false) && (
                                        <Icon iconName="img/alert.svg" />
                                    )}
                                </td>
                                <td>{tileHeader}</td>
                            </tr>
                        </tbody>
                    </table>
                </a>
            </div>

            <div className="cardBody">
                {loading ? (
                    <ShimmeredCardBody />
                ) : (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr style={{ textAlign: "center" }}>
                                <td className="mainColumn">
                                    {!metricValue.includes(undefined) ? (
                                        <span id="tileScore">{metricValue}</span>
                                    ) : (
                                        <span id="tileScore">NA</span>
                                    )}
                                </td>
                            </tr>
                            <tr style={{ textAlign: "center" }}>
                                <td
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    {!metricValue.includes(undefined) &&
                                    sparkLineScores.length > 1 ? (
                                        <SparkLine
                                            data={sparkLineScores}
                                            metric={metric}
                                            goal={goal}
                                        />
                                    ) : (
                                        <div style={{ fontSize: "10px" }}>
                                            Not enough datapoints
                                        </div>
                                    )}
                                </td>
                            </tr>
                            {momDelta !== undefined && (
                                <tr style={{ textAlign: "center" }}>
                                    <td>{delta(DeltaType.MoM, momDelta)}</td>
                                </tr>
                            )}
                            {yoyDelta !== undefined && (
                                <tr style={{ textAlign: "center" }}>
                                    <td>{delta(DeltaType.YoY, yoyDelta)}</td>
                                </tr>
                            )}
                            {avgCohorts &&
                                Object.keys(avgCohorts).map((key) =>
                                    avgValue(`${key} Average`, avgCohorts[key]),
                                )}
                        </tbody>
                    </table>
                )}
            </div>
        </li>
    );
};

export default Tile;
