import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    IStackItemStyles,
    IStackTokens,
    Icon,
    Label,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Stack,
    TooltipHost,
} from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    getAIScorecardAppTabSelection,
    getAIScorecardTabSelection,
    sendAIScorecardUserActionTelemetryEvent,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatCopilotNewTenantData,
    formatCopilotTopTenantUsageData,
} from "components/AIScorecardComponents/ApiHandler";
import { TopTenantInsightsType } from "components/AIScorecardComponents/types";
import { commonPageBlock } from "pages/common";
import { logException } from "utils/AppInsightsHelper";
import { appStyles } from "utils/Constants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { InsightsRoutes } from "utils/Links";
import { NO_DATA_MESSAGES } from "utils/Messages";

const innerStackTokens: IStackTokens = {
    childrenGap: 15,
};

export const leftStackItemsStyle: IStackItemStyles = {
    root: {
        width: "40%",
    },
};

const getDrillDownLink = (item: any, platform: string) => {
    const tid = item["tenantId"];
    const platformLowerCase = platform.toLowerCase();
    const link = `/${platformLowerCase}Tenant?id=${tid}&level=TenantId`;
    const availableUnits = item["availableUnits"];
    const tenantMessage = `Tenant Insights integration is disabled due to this tenant has less than 50 seats, TI models are not able to gather meaningful insights with such seat size due to low signal strengths of the metrics`;
    return (
        <div>
            <TooltipHost content={availableUnits < 50 ? tenantMessage : ""}>
                <Link
                    to={link}
                    target={"_blank"}
                    onClick={(event) => {
                        sendAIScorecardUserActionTelemetryEvent(
                            {
                                userActionName:
                                    "TopTenantInsightsTenantDrillDownLinkClick",
                                commandSurface: UserActionCommandSurface.Canvas,
                            },
                            {
                                DrillDownLinkPlatformTarget: platform,
                            },
                        );

                        return availableUnits < 50
                            ? event.preventDefault()
                            : undefined;
                    }}
                >
                    <Icon
                        className="titleIcon"
                        iconName={appStyles[platform].iconName}
                        style={{ color: availableUnits < 50 ? "#c8c6c4" : "" }}
                    />
                </Link>
            </TooltipHost>
        </div>
    );
};

const _renderItemColumn = (item, _index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName] as string;
    switch (column.key) {
        case "links":
            return (
                <Stack horizontal className="platformLinks">
                    <Stack.Item>{getDrillDownLink(item, "Web")}</Stack.Item>
                    <Stack.Item grow="initial">
                        {getDrillDownLink(item, "Win32")}
                    </Stack.Item>
                </Stack>
            );

        default:
            return <DetailsListHelper.TextValue value={fieldContent} />;
    }
};

const buildNewTenantColumns = () => {
    const displayColumns = [
        ["tenantName", "Tenant Name", 300, null],
        ["links", "Links", 100, null],
        ["availableUnits", "Available Units", 100, null],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        true,
    );

    return columns;
};

const buildTopTenantUsageColumns = (cadence: string) => {
    const displayColumns = [
        ["tenantName", "Tenant Name", 300, null],
        ["links", "Links", 100, null],
        ["availableUnits", "Available Units", 100, null],
        ["enabledUsers", "Enabled Users", 100, null],
        ["mau", cadence === "Weekly" ? "WAU" : "MAU", 100, null],
    ];

    const columns: IColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        null,
        "MAU",
        true,
    );

    return columns;
};

export const CopilotTopTenantInsights = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [state, setState] = useState<TopTenantInsightsType>({
        loading: false,
        newTenants: [],
        topTenantsUsage: [],
    });

    useEffect(() => {
        const getData = async () => {
            setState((oldState) => {
                return { ...oldState, loading: true };
            });
            setError(null);

            try {
                const [newTenants, topTenantsUsage] = await Promise.all([
                    fetchCopilotData(
                        {
                            office365Category: filters.office365Category,
                        },
                        "newTenants",
                    ),
                    fetchCopilotData(
                        {
                            application: filters.application,
                            cadence: filters.cadence,
                            office365Category: filters.office365Category,
                        },
                        "topTenantsUsage",
                    ),
                ]);

                let formattedData = formatCopilotNewTenantData(newTenants);
                let formattedData2 =
                    formatCopilotTopTenantUsageData(topTenantsUsage);

                setState((oldState) => {
                    return {
                        ...oldState,
                        loading: false,
                        newTenants: formattedData,
                        topTenantsUsage: formattedData2,
                    };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotScorecardTopTenantInsightsProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Tenant Insights in Copilot Scorecard",
                    },
                    error,
                );

                setError(error.message);
                setState((oldState) => {
                    return {
                        ...oldState,
                        newTenants: [],
                        loading: false,
                        topTenantsUsage: [],
                    };
                });
                return;
            }
        };
        getData();
    }, [filters.application, filters.cadence, filters.office365Category]);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <Stack horizontal className="filterPanel">
                <Stack.Item grow={1} className="menuNavigation">
                    <Label className="filterLabel"> Go To Tenant Insights</Label>
                    <Link
                        to={InsightsRoutes.WebTenant.path}
                        target="_blank"
                        rel="noreferrer"
                        className="copilotLinks"
                        onClick={() => {
                            sendAIScorecardUserActionTelemetryEvent({
                                userActionName:
                                    "CopilotScorecardTopTenantInsightsWebLinkClick",
                                commandSurface: UserActionCommandSurface.Header,
                            });
                        }}
                    >
                        Web |
                    </Link>
                    <Link
                        to={InsightsRoutes.Win32Tenant.path}
                        target="_blank"
                        rel="noreferrer"
                        className="copilotLinks"
                        onClick={() => {
                            sendAIScorecardUserActionTelemetryEvent({
                                userActionName:
                                    "CopilotScorecardTopTenantInsightsWin32LinkClick",
                                commandSurface: UserActionCommandSurface.Header,
                            });
                        }}
                    >
                        Win32 |
                    </Link>
                    <Link
                        to={InsightsRoutes.MacTenant.path}
                        target="_blank"
                        rel="noreferrer"
                        className="copilotLinks"
                        onClick={() => {
                            sendAIScorecardUserActionTelemetryEvent({
                                userActionName:
                                    "CopilotScorecardTopTenantInsightsMacLinkClick",
                                commandSurface: UserActionCommandSurface.Header,
                            });
                        }}
                    >
                        Mac
                    </Link>
                </Stack.Item>
            </Stack>
            <Stack styles={commonPageBlock}>
                <Stack horizontal tokens={innerStackTokens}>
                    <Stack.Item styles={leftStackItemsStyle}>
                        <h3 className="commonPageBlockTitle">
                            {" "}
                            Tenants that bought Copilot Licenses in last 30 days
                        </h3>
                        <div
                            data-is-scrollable
                            className="tenantsTable"
                            id="scorecardTable"
                        >
                            <ScrollablePane
                                scrollbarVisibility={ScrollbarVisibility.auto}
                            >
                                <ShimmeredDetailsListWrapper
                                    items={state.newTenants}
                                    columns={buildNewTenantColumns()}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={
                                        DetailsListHelper.renderDetailsHeader
                                    }
                                    enableShimmer={state.loading}
                                    onRenderItemColumn={_renderItemColumn}
                                    styles={DetailsListHelper.tenantTableStyle}
                                    message={
                                        state.newTenants.length == 0
                                            ? NO_DATA_MESSAGES.NO_DATA_FOR_FILTER
                                            : ""
                                    }
                                    tableName="CopilotTopTenantsInsightsTenantsBoughtRecently"
                                    getAdditionalTelemetryFields={() => {
                                        return {
                                            ActiveAppTab:
                                                getAIScorecardAppTabSelection(),
                                            ActiveScorecardTab:
                                                getAIScorecardTabSelection(),
                                        };
                                    }}
                                />
                            </ScrollablePane>
                        </div>
                    </Stack.Item>
                    <Stack.Item grow>
                        <h3 className="commonPageBlockTitle">
                            {" "}
                            Top 10 Tenants by Usage
                        </h3>
                        <div
                            data-is-scrollable
                            className="tenantsTable"
                            id="scorecardTable"
                        >
                            <ScrollablePane
                                scrollbarVisibility={ScrollbarVisibility.auto}
                            >
                                <ShimmeredDetailsListWrapper
                                    items={state.topTenantsUsage}
                                    columns={buildTopTenantUsageColumns(
                                        filters.cadence,
                                    )}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={
                                        DetailsListHelper.renderDetailsHeader
                                    }
                                    enableShimmer={state.loading}
                                    onRenderItemColumn={_renderItemColumn}
                                    styles={DetailsListHelper.tenantTableStyle}
                                    message={
                                        state.newTenants.length == 0
                                            ? NO_DATA_MESSAGES.NO_DATA_FOR_FILTER
                                            : ""
                                    }
                                    tableName="CopilotTopTenantsInsightsTopTenantsByUsage"
                                    getAdditionalTelemetryFields={() => {
                                        return {
                                            ActiveAppTab:
                                                getAIScorecardAppTabSelection(),
                                            ActiveScorecardTab:
                                                getAIScorecardTabSelection(),
                                        };
                                    }}
                                />
                            </ScrollablePane>
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};
