import { KustoResponseType } from "pages/commonTypes";
import {
    AddInCountAndPerfImpactData,
    AllAddInCostHistoryResponseRow,
    AllTypicalAddInCostData,
    App,
    AppObject,
    ChartData,
    TenantTypicalAddInCostResponse,
} from "pages/Performance/types";
import { createObjectArrayFromKustoResponse, dateTimeToDate } from "utils/Helpers";
import { perfConfig } from "../PerfConfig";

/**
 * Takes data retreieved from the tenantAllAddInCostHistory Kusto query and
 * transforms it to be displayed in the "Typical boot load cost (all add-ins)" chart.
 */
export const formatAllAddInCostHistory = (
    rawHistory: AllAddInCostHistoryResponseRow[],
) => {
    const label = "All add-ins";

    return rawHistory.map((row) => ({
        Date: row.Date,
        [label]: row.TypicalLoadDurationPerSession,
        [`Device Count (${label})`]: row.DeviceCount,
        [`Session Count (${label})`]: row.SessionCount,
        [`Devices with add-in (${label})`]: row.DeviceWithAddInCount,
    }));
};

/**
 * Takes data retrieved from the tenantAddInHistory Kusto query and transforms it
 * to be displayed in the "Devices with add-in" and "Typical boot load cost by
 * add-in" charts.
 */
export const countAndPerfImpactDataFromResponse = (
    response: KustoResponseType<string | number>,
): AppObject<AddInCountAndPerfImpactData> => {
    return response.Tables[0].Rows.reduce((acc, row) => {
        const appName = row[0] as string;
        const date = dateTimeToDate(row[1] as string);
        const progId = row[2] as string;
        const duration = row[4] as number;
        const deviceCount = row[5] as number;
        const sessionCount = row[6] as number;
        const percentage = row[7] as string;

        const percentAndSessionData = {
            [`Percent of Devices (${progId})`]: percentage,
            [`Session Count (${progId})`]: sessionCount,
        };

        const durations = {
            ...acc[appName][date]?.Durations,
            [progId]: duration,
            ...percentAndSessionData,
        };

        const counts = {
            ...acc[appName][date]?.Counts,
            [progId]: deviceCount,
            ...percentAndSessionData,
        };

        return {
            ...acc,
            [appName]: {
                ...acc[appName],
                [date]: { Durations: durations, Counts: counts },
            },
        };
    }, createAppInformationObject({}));
};

/**
 * Separates data for either the "Devices with add-in" or
 * "Typical boot load cost by add-in" chart.
 */
export const getChartDataFromCountAndPerfImpactData = (
    type: "Counts" | "Durations",
    data: AddInCountAndPerfImpactData,
): ChartData[] =>
    !data
        ? []
        : Object.entries(data).map(([date, countsAndDurations]) => ({
              Date: date,
              ...countsAndDurations[type],
          }));

/**
 * Gets the set of all ProgIds appearing in the data.
 */
export const progIdsFromHistoryData = (history: AddInCountAndPerfImpactData) => {
    const progIds = Object.values(history).flatMap((countsAndDurations) =>
        Object.keys(countsAndDurations.Counts).filter(
            (key) =>
                !key.startsWith("Percent of Devices (") &&
                !key.startsWith("Session Count ("),
        ),
    );
    return [...new Set(progIds)];
};

/**
 * Gets the set of all ProgIds across all applications appearing in the data.
 */
export const progIdsFromAllAppHistoryData = (
    allAppHistory: AppObject<AddInCountAndPerfImpactData>,
) => {
    const progIds = Object.values(allAppHistory).flatMap(progIdsFromHistoryData);
    return [...new Set(progIds)];
};

/**
 * Creates an AppObject object (an object with properties for each app) with the
 * given initial values for each app.
 */
export const createAppInformationObject = <T>(initialValue: T) =>
    perfConfig.Win32.AppsList.reduce(
        (acc, app) => ({ ...acc, [app]: initialValue }),
        {},
    ) as AppObject<T>;

/**
 * Parses the results of the tenantTypicalAddInCost Kusto query.
 */
export const parseTypicalAddInCosts = (
    response: KustoResponseType<string | number>,
): AllTypicalAddInCostData =>
    createObjectArrayFromKustoResponse<
        string | number,
        TenantTypicalAddInCostResponse
    >(response).reduce(
        (acc, row) => ({
            ...acc,
            [row.AppName]: row.TypicalLoadDurationPerSession,
        }),
        createAppInformationObject<number>(null),
    );

/**
 * Takes a Kusto response that contains an AppName column and separates
 * the data by app into an AppObject.
 */
export const populateAppObjectWithResponse = <dataTypes, rowObjectType>(
    kustoResponse: KustoResponseType<dataTypes>,
) => {
    const responseRows = createObjectArrayFromKustoResponse<
        dataTypes,
        { AppName: App } & rowObjectType
    >(kustoResponse);
    return responseRows.reduce((acc, row) => {
        const { AppName: appName, ...restOfRow } = row;
        return { ...acc, [appName]: [...acc[appName], restOfRow] };
    }, createAppInformationObject<rowObjectType[]>([]));
};

/**
 * Applies a mapping function to every app's value in an AppObject, returning
 * another AppObject.
 */
export const appObjectMap = <T1, T2>(
    appObject: AppObject<T1>,
    func: (element: T1) => T2,
) => {
    const resultObject = createAppInformationObject<T2>(undefined);
    perfConfig.Win32.AppsList.forEach(
        (app) => (resultObject[app] = func(appObject[app])),
    );
    return resultObject;
};
