export const win32CrossAppTenantStatus: string = `
//win32CrossAppTenantStatus (for movers/shakers)
let appName = "\${application}";
let dt = "\${date}";
let level = "\${level}";
let Mau = \${mauCountThreshold};
let currentDate = GetCurrentDate(false);
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let Overview = materialize(HealthyTenantScore_Win32Overview
    | where Date == dt or Date == prev
    | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == appName and Color > 1
    | project-rename Tpid = TopParentId
    | extend CurrencyDetails = parse_json(Currency_Details)
    | project OmsTenantId, Score, Color, MoMChange, MAU, Currency_InSupport_Score, Currency_InSupport_Color, 
Date, Application, Perf_BootLaunchPercentageUnderNs_Score, Usage_PercMau5DayUsage_Score, 
Usage_PercMau5DayUsage_Color, Usage_PercMau5DayUsage_MoMChange, Composite_Score = Score, Tpid, TenantName_Translated,Currency_DominantChannelFork_Text = CurrencyDetails.DominantChannelFork
);
let Colors = materialize(TenantInsights_Win32_Quality_Colors
        | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | project \${levelColumnName}, Date, Perf_Colors=parse_json(Perf_Colors), Reliability_Colors=parse_json(Reliability_Colors),
         Feedback_Colors=parse_json(Feedback_Colors));
Overview 
    | where Date == dt
    | where MAU > Mau
    | extend CurrentStatus = Color
 | join kind= inner 
    (
        Overview
        | where Date == prev
        | extend PreviousStatus = Color
    )     
on \${levelColumnName} 
| where CurrentStatus == 3 or CurrentStatus == 2 or PreviousStatus == 3 or PreviousStatus == 2
| extend ScoreDelta = round(Score - Score1, 2) 
| join kind=innerunique 
        (StrategicTenants
            | extend IsMidMarket = iff(Cohorts contains "MidMarket", true, false)
            | where Date == currentDate and Is\${cohort} == true
            | project tolong(Tpid), OmsTenantId, OrgName_Translated, TenantName_Translated
            | distinct \${levelColumnName}, iif( level  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        )
    on \${levelColumnName} 
    | join kind = inner (
        HealthyTenantScore_PastStatus | project-rename Tpid = TopParentId  | where iff(level == "Tpid", OmsTenantId == "All", OmsTenantId != "All") and Application == appName
    )
    on \${levelColumnName}
    | join kind=inner (
        Colors 
        | where Date == dt 
    )
    on \${levelColumnName}         
    | join kind=inner (
            Colors 
            | where Date == prev 
        ) 
    on \${levelColumnName}  
    | extend Composite_MoMChange = round(MoMChange,2)
    | extend MAU_MoMChange = round((todouble(MAU-MAU1)/MAU1)*100,2) | extend MAU_Score = MAU
    | extend EngagedUsers_MoMChange = round((todouble(Usage_PercMau5DayUsage_Score-Usage_PercMau5DayUsage_Score1)/Usage_PercMau5DayUsage_Score1)*100,2)
    | extend Currency_MoMChange = round((todouble(Currency_InSupport_Score - Currency_InSupport_Score1)/Currency_InSupport_Score1)*100, 2)
    | project \${level} = \${levelColumnName}, OrgName = Name , 
    Composite_Score, Composite_MoMChange, 
    MAU_Score, MAU_MoMChange,
    EngagedUsers_Score = Usage_PercMau5DayUsage_Score, EngagedUsers_Score_Color = GetColor(Usage_PercMau5DayUsage_Color), EngagedUsers_MoMChange,
    Word_Performance_AppColor = GetColor(Perf_Colors.Word),
    Excel_Performance_AppColor = GetColor(Perf_Colors.Excel),
    PowerPoint_Performance_AppColor = GetColor(Perf_Colors.PowerPoint),
    Outlook_Performance_AppColor = GetColor(Perf_Colors.Outlook),
    OneNote_Performance_AppColor = GetColor(Perf_Colors.OneNote),
    Word_Reliability_AppColor = GetColor(Reliability_Colors.Word),
    Excel_Reliability_AppColor = GetColor(Reliability_Colors.Excel),
    PowerPoint_Reliability_AppColor = GetColor(Reliability_Colors.PowerPoint),
    Outlook_Reliability_AppColor = GetColor(Reliability_Colors.Outlook),
    OneNote_Reliability_AppColor = GetColor(Reliability_Colors.OneNote),
    Word_Feedback_AppColor = GetColor(Feedback_Colors.Word),
    Excel_Feedback_AppColor = GetColor(Feedback_Colors.Excel),
    PowerPoint_Feedback_AppColor = GetColor(Feedback_Colors.PowerPoint),
    Outlook_Feedback_AppColor = GetColor(Feedback_Colors.Outlook),
    OneNote_Feedback_AppColor = GetColor(Feedback_Colors.OneNote),
    Word_Performance_AppColor_Prev = GetColor(Perf_Colors1.Word),
    Excel_Performance_AppColor_Prev = GetColor(Perf_Colors1.Excel),
    PowerPoint_Performance_AppColor_Prev = GetColor(Perf_Colors1.PowerPoint),
    Outlook_Performance_AppColor_Prev = GetColor(Perf_Colors1.Outlook),
    OneNote_Performance_AppColor_Prev = GetColor(Perf_Colors1.OneNote),
    Word_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Word),
    Excel_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Excel),
    PowerPoint_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.PowerPoint),
    Outlook_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.Outlook),
    OneNote_Reliability_AppColor_Prev = GetColor(Reliability_Colors1.OneNote),
    Word_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Word),
    Excel_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Excel),
    PowerPoint_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.PowerPoint),
    Outlook_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.Outlook),
    OneNote_Feedback_AppColor_Prev = GetColor(Feedback_Colors1.OneNote),
    Currency_Score = Currency_InSupport_Score, Currency_Score_Color = GetColor(Currency_InSupport_Color), Currency_MoMChange, Currency_DominantChannelFork_Text,
    PastStatus, CurrentStatus = GetColor(CurrentStatus), PreviousStatus = GetColor(PreviousStatus), Parent = Tpid, TenantName_Translated`;
