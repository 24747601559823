import React from "react";
import { IColumn } from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ExpandRow } from "components/CustomComponents/ExpandRow/ExpandRow";
import { ExemptIcon } from "components/CustomComponents/Icons/Icons";
import { StatusIndicatorTrend } from "components/CustomComponents/StatusIndicator/StatusIndicator";
import {
    ColumnType,
    OverviewTableColumn,
} from "components/OverviewComponents/types";
import {
    cohortOptions,
    getConfig,
    PlatformConfigType,
    Workload,
} from "config/PlatformConfig";
import { table } from "pages/commonTypes";
import { ExecOverviewFilterState } from "pages/ExecOverview/types";
import {
    headerColumnDetailsTable,
    headerColumnWidthIndex,
    headerColumnTypeIndex,
    headerColumnAlignmentIndex,
    appStyles,
    scorePrecisionValue,
    STATUS,
} from "utils/Constants";
import { copilotHeaderColumnDetailsTable } from "utils/CopilotConstants";
import {
    computePlatformUrl,
    getNegateValue,
    getTenantInsightsRedirectUrl,
    getPostfixValue,
} from "utils/Helpers";
import { InsightsRoutes } from "utils/Links";
import { disableSortingColumnList } from "utils/Win32Constants";

const getMetricGroupFromAppColorColumn = (column: string): string => {
    return column.split("_").reverse()[1];
};

const addPaddingToAppColorColumns = (defaultColumns: any[]): any[] => {
    const finalColumns = [];
    let prevAppColorColumn: (string | number | ColumnType)[] = null;
    let prevAppColorMetricGroup = "";
    let dummyCount = 1;
    let dummyPushed = false;

    defaultColumns.forEach((column) => {
        if (column[4] === ColumnType.APPCOLOR) {
            const metricGroup = getMetricGroupFromAppColorColumn(column[0]);
            if (prevAppColorMetricGroup !== metricGroup) {
                const colWidth = dummyPushed ? 45 : 5;
                dummyPushed = true;
                finalColumns.push([
                    "dummy".concat(dummyCount.toString()),
                    "",
                    colWidth,
                    "alignCenterHeader",
                ]);
                dummyCount += 1;
            }
        } else if (
            prevAppColorColumn &&
            prevAppColorColumn[4] === ColumnType.APPCOLOR
        ) {
            finalColumns.push([
                "dummy".concat(dummyCount.toString()),
                "",
                5,
                "alignCenterHeader",
            ]);
            dummyCount += 1;
        }

        finalColumns.push(column);
        prevAppColorColumn = column;
        prevAppColorMetricGroup =
            column[4] === ColumnType.APPCOLOR
                ? getMetricGroupFromAppColorColumn(column[0])
                : "";
    });

    return finalColumns;
};

export const getTableHeaders = (
    defaultColumns: (string | number | ColumnType)[][],
    data: table,
    tableNameMap: any,
    platform: string,
): (string | number)[][] => {
    data.headers.forEach((header) => {
        if (header.includes("_")) {
            const headerValue = header.split("_").reverse()[0];
            if (headerValue in headerColumnDetailsTable) {
                defaultColumns.push([
                    header,
                    header in tableNameMap ? tableNameMap[header]["name"] : "",
                    getMinWidthForColumnHeaders(platform, headerValue),
                    headerColumnDetailsTable[headerValue][
                        headerColumnAlignmentIndex
                    ],
                    headerColumnDetailsTable[headerValue][headerColumnTypeIndex],
                ]);
            }
        }
    });

    return addPaddingToAppColorColumns(defaultColumns);
};

export const getDisplayColumns = (
    { platformConfig: { platform }, payload, tableNameMap },
    id: string,
): any[] => {
    id = id === undefined ? "TenantId" : id;
    const displayColumns = getTableHeaders(
        getDefaultColumns(platform, id),
        payload,
        tableNameMap,
        platform,
    );
    return displayColumns;
};

export const onColumnClick = (
    setState,
    disableSortingColumnList: string[],
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn,
): void => {
    if (!disableSortingColumnList.includes(column.key)) {
        setState((state) => {
            const result = DetailsListHelper.sortItems(
                state.columns,
                column,
                state.data,
            );
            return {
                ...state,
                columns: result.columns,
                data: result.items,
                sortOptions: {
                    sortKey: column.key,
                    sortedDescending: !column.isSortedDescending,
                },
            };
        });
    }
};

export const getTenantDrilldownRedirectUrl = (filterProps) => {
    let queryParams: string = filterProps.queryParamsUrl;
    queryParams = queryParams.startsWith("?") ? queryParams : `?${queryParams}`;
    switch (filterProps.config.platform) {
        case Workload.WIN32:
            return `${InsightsRoutes.Win32Tenant.path}${queryParams}&date=${filterProps.dateFilter}`;
        case Workload.MAC:
            return `${InsightsRoutes.MacTenant.path}${queryParams}&date=${filterProps.dateFilter}`;
        default:
            return `${InsightsRoutes.Tenant.path}${queryParams}`;
    }
};

export const generateColumns = (level, props, sortOptions, setState) => {
    const id = level;
    const displayColumns = getDisplayColumns(props, id);
    const columns: OverviewTableColumn[] = DetailsListHelper.customBuildColumns(
        displayColumns,
        true,
        (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
            onColumnClick(setState, disableSortingColumnList, ev, column);
        },
        sortOptions.sortKey,
        sortOptions.sortedDescending,
    );
    columns.forEach((column) => {
        if (column.columnType === ColumnType.MOM_CHANGE) {
            column.iconName = "TriangleShape";
            column.iconClassName = "deltaHeaderIcon";
        }
        if (column.columnType === ColumnType.APPCOLOR) {
            const colStyle = {
                borderLeft: "1px solid #c2c2c2",
                maxHeight: 36,
            };
            const colKeyData = column.key.split("_");
            const appName = colKeyData[0];
            const appMetric = colKeyData[1];
            column.iconName = appStyles[appName]["smallIconName"];
            column.ariaLabel = appName.concat(" ").concat(appMetric);
            column.iconClassName = appStyles[appName]["iconClassName"] ?? "appIcon";
            if (DetailsListHelper.isLastGroupedSignal(props.rankOn, column.key)) {
                colStyle["borderRight"] = "1px solid #c2c2c2";
            }
            column.styles = {
                root: colStyle,
            };
        }
        if (column.key in props.tableNameMap) {
            column.ariaLabel = props.tableNameMap[column.key]["description"];
        }
    });
    columns.push({
        key: "Dummy",
        name: "",
        fieldName: "Dummy",
        minWidth: 10,
        columnType: ColumnType.REGULAR,
    });
    return columns;
};

export const renderItemColumn = (level, props, queryParamsUrl) => {
    return (item: any, index: number, column: OverviewTableColumn) => {
        const fieldContent = item[column.fieldName] as string;
        const id = item[level];
        switch (column.columnType) {
            case ColumnType.ORG_NAME:
                const link = getTenantInsightsRedirectUrl(queryParamsUrl, id, props);
                return link !== "" ? (
                    <DetailsListHelper.LinkedTextValue
                        value={fieldContent}
                        link={getTenantInsightsRedirectUrl(
                            queryParamsUrl,
                            id,
                            props,
                        )}
                        platform={props.platformConfig.platform}
                    />
                ) : (
                    <DetailsListHelper.TextValue value={fieldContent} />
                );
            case ColumnType.EXEMPT:
                const itemColor = item.Status ?? item.CurrentStatus;
                return (
                    item.IsExempted &&
                    (itemColor === "Red" || itemColor === "DeepRed") && (
                        <ExemptIcon />
                    )
                );
            case ColumnType.PAST_STATUS:
                return (
                    <ExpandRow condition={item.Notes}>
                        <StatusIndicatorTrend values={item.PastStatus} />
                    </ExpandRow>
                );
            case ColumnType.MOM_CHANGE:
                return (
                    <DetailsListHelper.DeltaValue
                        value={fieldContent}
                        deltaPostfix={getPostfixValue(column.key)}
                        negate={getNegateValue(column.key)}
                    />
                );
            case ColumnType.APPCOLOR:
                return (
                    <DetailsListHelper.ColorBar
                        color={fieldContent}
                        selectedCrossApp={props.rankOn}
                        signal={column.fieldName}
                    />
                );
            case ColumnType.TEXT:
                return (
                    <DetailsListHelper.AlignRightTextValue value={fieldContent} />
                );
            case ColumnType.VALUE:
                return item[`${column.fieldName}_Color`] ? (
                    <DetailsListHelper.StatusBar
                        color={STATUS[item[`${column.fieldName}_Color`]]["color"]}
                    >
                        <DetailsListHelper.NumericalValue
                            value={fieldContent}
                            postfix={getPostfixValue(column.key)}
                            decimalPrecision={2}
                            defaultValue="N/A"
                        />
                    </DetailsListHelper.StatusBar>
                ) : (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix={getPostfixValue(column.key)}
                        decimalPrecision={scorePrecisionValue[column.key]}
                        defaultValue="NA"
                    />
                );
            case ColumnType.PLATFORM:
                const platform = column.fieldName.split("_")[0];
                return item[`${column.fieldName}_Color`] ? (
                    <DetailsListHelper.StatusBar
                        color={STATUS[item[`${column.fieldName}_Color`]]["color"]}
                    >
                        <a
                            href={computePlatformUrl(platform, level, id)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DetailsListHelper.NumericalValue
                                value={fieldContent}
                                postfix={getPostfixValue(column.key)}
                                decimalPrecision={2}
                                defaultValue="NA"
                            />
                        </a>
                    </DetailsListHelper.StatusBar>
                ) : fieldContent ? (
                    <a
                        href={computePlatformUrl(platform, level, id)}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: "auto" }}
                    >
                        <DetailsListHelper.NumericalValue
                            value={fieldContent}
                            postfix={getPostfixValue(column.key)}
                            decimalPrecision={scorePrecisionValue[column.key]}
                            defaultValue="NA"
                        />
                    </a>
                ) : (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix={getPostfixValue(column.key)}
                        decimalPrecision={scorePrecisionValue[column.key]}
                        defaultValue="NA"
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };
};

export const validateAndUpdatePlatformFilters = (
    filters: ExecOverviewFilterState,
    filtersFromQueryParams: {},
    workload: string,
): ExecOverviewFilterState => {
    const config: PlatformConfigType = getConfig(workload);
    const updatedFilters: ExecOverviewFilterState = { ...filters };
    if (
        config.crossAppsList
            .concat(config.appsList)
            .includes(filtersFromQueryParams["rankOn"])
    )
        updatedFilters["rankOn"] = filtersFromQueryParams["rankOn"];
    if (["TenantId", "Tpid"].includes(filtersFromQueryParams["level"]))
        updatedFilters["level"] = filtersFromQueryParams["level"];
    if (cohortOptions.map((c) => c.key).includes(filtersFromQueryParams["cohort"]))
        updatedFilters["cohort"] = filtersFromQueryParams["cohort"];
    if (
        config.defaults.minMau &&
        config.defaults.mauOptions
            ?.map((o) => o.key)
            .includes(filtersFromQueryParams["minMau"])
    )
        updatedFilters["minMau"] = filtersFromQueryParams["minMau"];

    return updatedFilters;
};

const getDefaultColumns = (platform, id) => {
    switch (platform) {
        case Workload.WEB_CONSUMER:
            return [["CohortName", "CohortName", 250, null, ColumnType.ORG_NAME]];
        case Workload.COPILOT_COMMERCIAL:
            return [
                ["PastStatus", "Monthly Trend", 128, null, ColumnType.PAST_STATUS],
                [id, id, 125, null, ColumnType.REGULAR],
                ["OrgName", "Org Name", 150, null, ColumnType.ORG_NAME],
            ];
        default:
            return [
                [
                    "PastStatus",
                    "Monthly Health Trend",
                    128,
                    null,
                    ColumnType.PAST_STATUS,
                ],
                ["IsExempted", "Exempted", 55, null, ColumnType.EXEMPT],
                [id, id, 75, null, ColumnType.REGULAR],
                ["OrgName", "Org Name", 150, null, ColumnType.ORG_NAME],
            ];
    }
};

/**
 * In case of tenant insights, we used to pass this as "Level" that would mean either tpid or tenantid and it would be used to form a url for pg2.
 * since we don't have levels like tpid/tenantid for consumer, we only need 'CohortName' to be passed and we still want to pass level for tenant insights since we are using shared component
 */
export const getLevelForColumns = (
    platform: string,
    defaultColumn: string,
): string => {
    switch (platform) {
        case Workload.WEB_CONSUMER:
            return "CohortName";
        default:
            return defaultColumn;
    }
};

export const getMinWidthForColumnHeaders = (
    platform: string,
    header: string,
): number => {
    switch (platform) {
        case Workload.COPILOT_COMMERCIAL:
            return copilotHeaderColumnDetailsTable[header][headerColumnWidthIndex];
        default:
            return headerColumnDetailsTable[header][headerColumnWidthIndex];
    }
};
