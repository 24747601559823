import { isTest } from "utils/WebConstants";

export const aceHealthScore = `// get ace health score data
    let currDate = GetCurrentDate(${isTest});
    ACEHealthScore
        | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == "\${id}"
        | project Date, Name = "CustomerSupportHealthScore", Value = round(Value,2)
        | union 
            ( // avg score per cohort
                StrategicTenants
                | where Date == currDate and \${levelColumnName} == "\${id}"
                | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov
                | take 1
                | project Cohort = iff(IsS500, "S500", iff(IsS2500, "S2500", iff(IsGoogleMove, "GoogleMove", iff(IsEPA, "EPA", "Gov"))))
                | join kind=inner( ACEHealthScoreAverage | where Level == "\${level}") on Cohort
                | project Date, Name = "CSHSCohortAverage", Value = round(Value,1)
            )
        | order by Date asc
    `;
