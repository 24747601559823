import React, { useEffect, useState } from "react";
import {
    DetailsList,
    Fabric,
    IColumn,
    IDetailsHeaderProps,
    TooltipHost,
    Sticky,
    StickyPositionType,
    SelectionMode,
    DetailsListLayoutMode,
    IGroupDividerProps,
    IRenderFunction,
    IGroupHeaderProps,
    IStyle,
    CollapseAllVisibility,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import * as API from "api";
import { Workload } from "config/PlatformConfig";
import { MetricsResponseType, WorkloadMetricFilterState } from "pages/commonTypes";
import * as TYPES from "pages/InProductDrillDown/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import "./style.css";

interface LatencyMetricsState {
    payload: any[];
    loading: boolean;
}
interface LatencyPropsType {
    filters: TYPES.WorkloadTenantFilterState;
    metricFilters: WorkloadMetricFilterState;
}

enum LatencyColumns {
    SCENARIO_NAME = "Scenario_Name",
    LATENCY = "Latency",
}
export const Latency = ({ filters, metricFilters }: LatencyPropsType) => {
    const tenantId = filters["id"];
    const date = metricFilters["Date"];

    const getLatencyMetrics = async () => {
        let queryParams = {};
        const levelParams = API.getQueryParamsForLevel(filters["level"]);
        queryParams["id"] = filters["id"];
        queryParams["date"] = metricFilters["Date"];
        queryParams = { ...levelParams, ...queryParams };

        const latency = await API.getKustoResponse({
            queryName: "teamsMeetingLatency",
            platform: Workload.TEAMS,
            queryParams,
        });

        return latency;
    };

    // TODO: Make the list of OS and Browsers dynamic
    const operatingSystems = ["Windows", "Mac", "Linux"];
    const browsers = ["Edge", "Chrome", "Safari", "Electron"];
    const generateColumns = () => {
        const displayColumns = [
            [LatencyColumns.SCENARIO_NAME, "Scenario", 150, "alignRightHeader"],
            [LatencyColumns.LATENCY, "Latency", 70, "alignRightHeader"],
        ];
        operatingSystems.forEach((os) => {
            displayColumns.push([os, os, 70, "alignRightHeader"]);
        });

        browsers.forEach((browser) => {
            displayColumns.push([browser, browser, 70, "alignRightHeader"]);
        });

        const columns: IColumn[] = DetailsListHelper.customBuildColumns(
            displayColumns,
            true,
            null,
            null,
            false,
        );
        return columns;
    };

    const generateMetricGroups = (metrics) => {
        let startIdx = 0;
        let idx = 0;
        const orderedGroup = [];
        let group = metrics[0].Group;
        let prevGroup = group;

        Object.keys(metrics).forEach((index) => {
            idx = parseInt(index, 10);
            const metric = metrics[index];
            group = metric["Group"];
            if (group !== prevGroup) {
                orderedGroup.push({
                    key: prevGroup,
                    name: prevGroup,
                    startIndex: startIdx,
                    level: 0,
                    count: idx - startIdx,
                    isCollapsed: true,
                });
                startIdx = idx;
            }
            prevGroup = group;
        });

        orderedGroup.push({
            key: prevGroup,
            name: prevGroup,
            startIndex: startIdx,
            level: 0,
            count: idx - startIdx + 1,
            isCollapsed: true,
        });

        return orderedGroup;
    };

    const onRenderHeader = (
        headerProps: IGroupDividerProps,
        defaultRender: IRenderFunction<IGroupHeaderProps>,
    ) => {
        const headerCountStyle: IStyle = { display: "none" };
        return (
            <span>
                {defaultRender({
                    ...headerProps,
                    styles: { headerCount: headerCountStyle },
                })}
            </span>
        );
    };

    const renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldName = item[column.fieldName];
        const fieldContent =
            fieldName == null
                ? "-"
                : new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      notation: "compact",
                      compactDisplay: "short",
                  }).format(fieldName);
        const fieldContentString = fieldName as string;

        switch (column.key) {
            case LatencyColumns.SCENARIO_NAME:
                return (
                    <DetailsListHelper.AlignLeftTextValue
                        value={fieldContentString}
                    />
                );
            case LatencyColumns.LATENCY:
                return (
                    <DetailsListHelper.AlignRightTextValue
                        value={fieldContentString}
                    />
                );
            default:
                return (
                    <DetailsListHelper.AlignRightTextValue value={fieldContent} />
                );
        }
    };

    const formatLatencyMetrics = (latencyMetricsJson: MetricsResponseType) => {
        const flattenScenarios = [];
        const latencyMetricsColumns = latencyMetricsJson.Tables[0].Columns;
        latencyMetricsJson.Tables[0].Rows.forEach((x) => {
            const entry = {};
            latencyMetricsColumns.forEach((metricColumn, idx) => {
                entry[metricColumn.ColumnName] = x[idx];
            });
            flattenScenarios.push(entry);
        });
        return flattenScenarios;
    };

    const getPayloadData = async () => {
        setData((data) => {
            return {
                ...data,
                loading: true,
            };
        });

        try {
            const response = await getLatencyMetrics();
            const responseData = await response?.data;

            const formattedResponse = formatLatencyMetrics(responseData);
            setData({
                payload: formattedResponse,
                loading: false,
            });
        } catch (err) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "TeamsLatencyFetchFailed",
                ),
                {
                    message: "Failed to fetch or format Teams Latency Table Metrics",
                },
                err,
            );
            setData({
                payload: [],
                loading: false,
            });
        }
    };

    const intialState: LatencyMetricsState = {
        payload: [],
        loading: true,
    };
    const [data, setData] = useState<LatencyMetricsState>(intialState);

    const renderGroupedDetailsHeaderForLatency = (
        props: IDetailsHeaderProps,
        defaultRender?: IRenderFunction<IDetailsHeaderProps>,
    ): JSX.Element => {
        type ColumnGroup = { name: string; width: number; key: number };
        let pillars: ColumnGroup[] = [];
        let signalGroups: ColumnGroup[] = [];

        pillars.push({ name: "None", width: 300, key: 0 });
        pillars.push({ name: "OS", width: 270, key: 1 });
        pillars.push({ name: "Browser", width: 360, key: 2 });

        signalGroups.push({ name: "None", width: 890, key: 0 });

        if (pillars.length === 1 && pillars[0]["name"] === "None") pillars = [];
        if (signalGroups.length === 1 && signalGroups[0]["name"] === "None")
            signalGroups = [];

        let tableBottomStyle: {} = {
            paddingTop: 3,
        };
        if (pillars.length === 0) tableBottomStyle = {};

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                <div className="latencyHeader" key={"listGrouping"}>
                    {pillars.map((pillar) => {
                        if (pillar.name === "None") {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        minWidth: pillar.width,
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    key={pillar.key}
                                >
                                    {" "}
                                    <div
                                        style={{
                                            borderBottom: "2px solid white",
                                            minWidth: pillar.width - 6,
                                        }}
                                    />
                                </div>
                            );
                        }
                        return (
                            <div
                                style={{
                                    minWidth: pillar.width,
                                    display: "flex",
                                    textAlign: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                                key={pillar.key}
                            >
                                {" "}
                                {pillar.name}
                                <div
                                    style={{
                                        borderBottom: "2px solid black",
                                        minWidth: pillar.width - 6,
                                        display: "flex",
                                        paddingTop: 7,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="latencySubHeader" key={"listSubGrouping"}>
                    {signalGroups.map((signalGroup) => {
                        if (signalGroup.name === "None") {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        minWidth: signalGroup.width,
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                    key={signalGroup.key}
                                >
                                    {" "}
                                    <div
                                        style={{
                                            minWidth: signalGroup.width - 6,
                                        }}
                                    />
                                </div>
                            );
                        }
                        return (
                            <div
                                style={{
                                    minWidth: signalGroup.width,
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    paddingTop: 7,
                                    alignItems: "center",
                                }}
                                key={signalGroup.key}
                            >
                                {" "}
                                {signalGroup.name}
                                <div
                                    style={{
                                        borderBottom: "1px solid black",
                                        minWidth: signalGroup.width - 6,
                                        display: "flex",
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps) => (
                        <TooltipHost {...tooltipHostProps} />
                    ),
                    styles: {
                        root: {
                            ...tableBottomStyle,
                            selectors: {
                                ".ms-DetailsHeader-cell": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    height: "100%",
                                },
                                ".ms-DetailsHeader-cellTitle": {
                                    height: "100%",
                                },
                            },
                        },
                    },
                })}
            </Sticky>
        );
    };

    useEffect(() => {
        if (tenantId) getPayloadData();
        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, date]);

    return (
        <>
            <h2 className="commonPageBlockTitle">Latency</h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.payload.length > 0 ? (
                <div className="metricsTable tenantTable">
                    <Fabric>
                        <DetailsList
                            items={data.payload}
                            columns={generateColumns()}
                            selectionMode={SelectionMode.none}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            onRenderRow={DetailsListHelper.renderExpandableRow}
                            onRenderItemColumn={renderItemColumn}
                            onRenderDetailsHeader={(headerProps, defaultrender) => {
                                return renderGroupedDetailsHeaderForLatency(
                                    headerProps,
                                    defaultrender,
                                );
                            }}
                            groups={generateMetricGroups(data.payload)}
                            groupProps={{
                                onRenderHeader,
                                isAllGroupsCollapsed: true,
                                collapseAllVisibility: CollapseAllVisibility.visible,
                            }}
                        />
                    </Fabric>
                </div>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
