import React, { useEffect, useState } from "react";
import {
    Stack,
    ScrollablePane,
    ScrollbarVisibility,
    DetailsList,
    SelectionMode,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    getTheme,
} from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { getPayloadData } from "components/TenantDrilldownComponents/TenantTouchPoints/apiHandler";
import { TenantTouchPointsState } from "components/TenantDrilldownComponents/TenantTouchPoints/types";
import { commonInnerBlock } from "pages/common";

const displayColumns: IColumn[] = [
    {
        key: "LastUpdatedDate",
        fieldName: "LastUpdatedDate",
        name: "Last Update",
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: "TouchType",
        fieldName: "TouchType",
        name: "Touch Type",
        minWidth: 150,
        maxWidth: 175,
    },
    {
        key: "ProgramName",
        fieldName: "ProgramName",
        name: "Program Name",
        minWidth: 175,
        maxWidth: 175,
    },
    {
        key: "OwnerDisplayName",
        fieldName: "OwnerDisplayName",
        name: "Owner",
        minWidth: 150,
        maxWidth: 200,
    },
    {
        key: "ExecutiveUpdate",
        fieldName: "ExecutiveUpdate",
        name: "Notes",
        minWidth: 400,
        isMultiline: true,
    },
];

const initialState: TenantTouchPointsState = {
    payload: [],
    loading: true,
};

const renderDetailsRow = (props: IDetailsRowProps): JSX.Element => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: getTheme().palette.themeLighterAlt };
    }

    customStyles.cell = {
        overflowX: "visible",
        overflowWrap: "break-word",
        whiteSpace: "normal",
    };

    return <DetailsRow {...props} styles={customStyles} />;
};

export const TenantTouchPoints = ({ filters }) => {
    const tenantId = filters.id;
    const level = filters.level;
    const [data, setData] = useState<TenantTouchPointsState>(initialState);

    useEffect(() => {
        if (tenantId) {
            getPayloadData({ id: tenantId, level }, setData);
        }
    }, [tenantId, level]);

    return (
        <>
            <h2
                className="commonPageBlockTitle"
                data-testid="tenantTouchPointsHeader"
            >
                Tenant Touch Points
            </h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.payload.length !== 0 ? (
                <>
                    <Stack styles={commonInnerBlock}>
                        <div data-is-scrollable className="pageSection">
                            <ScrollablePane
                                scrollbarVisibility={ScrollbarVisibility.auto}
                            >
                                <DetailsList
                                    items={data.payload}
                                    columns={displayColumns}
                                    selectionMode={SelectionMode.none}
                                    onRenderRow={renderDetailsRow}
                                />
                            </ScrollablePane>
                        </div>
                    </Stack>
                </>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
