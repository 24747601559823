import { isTest } from "utils/WebConstants";

export const teamsChatCompositeScore: string = `
//teamsChatCompositeScore
let dt = iif(isempty("\${date}"), GetCurrentTeamsDate(${isTest}), "\${date}");
let currentDate = GetCurrentTeamsDate(${isTest});
let app = "\${application}";
let levelName = "\${levelColumnName}";
let MAUThreshold = \${mauCountThreshold};
Teams_Scores
| where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Date == dt and SubWorkload ==  app
| join kind=inner 
    (StrategicTenants
        | where Is\${cohort} == true and Date == currentDate
        | distinct OmsTenantId, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
        |  project OmsTenantId, Name = Column1
    ) on OmsTenantId
| join kind=leftouter (
    Teams_PastStatus
    | where SubWorkload == app and Date == dt 
    | extend PastStatus = GetTeamsPastYearStatus(PastStatusSet, dt)
) on OmsTenantId
| join kind = leftouter
    (
        Teams_Scores
        | where iff( levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd") and SubWorkload ==  app 
        | project OmsTenantId, PrevMonthScore = round(Score_Score, 2)
    )
    on OmsTenantId
    | join kind = leftouter(
    (Teams_Meetings_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId = tostring(TenantId), MAU= Meetings_MAU, TeamsMAU = Teams_MAU
        )
        | union (
        Teams_Chat_Health_Scores
        | where Date == dt and App_Name == app
        | project TenantId, MAU= Usage_Chats_MAU, TeamsMAU = Usage_Teams_MAU
        )
    ) on $left.OmsTenantId == $right.TenantId
    | where MAU > MAUThreshold
    | project PastStatus, Date,
  TenantId=OmsTenantId, SubWorkload, OrgName=Name,Tpid,
  Composite_Score=round(Score_Score,2), Status=Score_Color, CompositeScore_MoMChange = Score_Score-PrevMonthScore,
  MAU_Score=MAU, TeamsMAU_Score=TeamsMAU,
  UsageRate_Score, UsageRate_Score_Color=UsageRate_Color,
  ChatSwitchV2_Score, ChatSwitchV2_Score_Color=ChatSwitchV2_Color,
  NewChat_Score, NewChat_Score_Color=NewChat_Color,
  ChatSendMessage_Score, ChatSendMessage_Score_Color=ChatSendMessage_Color,
  P95ChatSwitchV2_Score, P95ChatSwitchV2_Score_Color=P95ChatSwitchV2_Color,
  P50ChatSwitchV2_Score, P50ChatSwitchV2_Score_Color=P50ChatSwitchV2_Color,
  NPS_Score, NPS_Score_Color=NPS_Color
  | sort by Composite_Score, MAU_Score desc
`;
