import React, { useEffect, useState } from "react";
import { Dropdown, Icon, Label, Stack, TooltipHost } from "@fluentui/react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderTitle,
} from "components/CustomComponents/Card/Card";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import {
    formatDecompositionData,
    getFunnelDecomposition,
} from "components/CommonFunnelComponents/CommonFunnelDecomposition/ApiHandler";
import {
    CommonFunnelDecompositionPropsType,
    DecompositionDataType,
    DecompositionInsight,
    DecompositionInsightsType,
} from "components/CommonFunnelComponents/CommonFunnelDecomposition/types";
import "components/CommonFunnelComponents/CommonFunnelDecomposition/styles.css";
import { FunnelConfig } from "config/FunnelConfig";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { formatNumberCompact } from "utils/Helpers";

export const DecompTableRow = (
    { text, stage, stageLossToStage, stageLossToGroup }: DecompositionInsight,
    type: DecompositionDataType,
    index: number,
): JSX.Element => {
    let pctFormatString = `${formatNumberCompact(stageLossToStage)}%`;
    if (type === DecompositionDataType.StageLossToGroupPct) {
        pctFormatString = `${formatNumberCompact(
            stageLossToGroup,
        )}% (${formatNumberCompact(stageLossToStage)}%)`;
    }
    return (
        <tr className="decompRow" key={`groupLoss-${index}`}>
            <td className="decompText">{text}</td>
            <td>{`Stage ${stage} to ${stage + 1}`}</td>
            <td className="decompPercent">{pctFormatString}</td>
        </tr>
    );
};

export const CommonFunnelDecomposition = (
    props: CommonFunnelDecompositionPropsType,
) => {
    const [selectedStage, setSelectedStage] = useState<string>("All");
    const [insightCountToShow, setInsightCountToShow] = useState<number>(3);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [decompositionInsights, setDecompositionInsights] =
        useState<DecompositionInsightsType>({
            StageLossToStagePct: [],
            StageLossToGroupPct: [],
        });

    const funnelStages = [{ key: "All", text: "All Stages" }];
    FunnelConfig[props.funnelType].decompositionStages.forEach((stage, index) => {
        funnelStages.push({ key: (index + 1).toString(), text: `${stage}` });
    });

    useEffect(() => {
        const getDecompositionInsights = async () => {
            setLoading(true);
            try {
                const filters = { ...props.filters };
                const decomp = await getFunnelDecomposition(
                    filters,
                    props.funnelType,
                    selectedStage,
                );
                setDecompositionInsights(formatDecompositionData(decomp));
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchFunnelDecompositionDataException",
                    ),
                    {
                        message:
                            "Failed to fetch funnel decomposition insights for the given set of filters.",
                    },
                    error,
                );
                setError("Failed to fetch decomposition insights");
            }
            setLoading(false);
        };
        getDecompositionInsights();
    }, [props.filters, props.funnelType, selectedStage]);
    return error ? (
        <EmptyTableContainer message={error} />
    ) : (
        <Card>
            <CardHeader>
                <CardHeaderTitle>Funnel Stage Drop-off Analysis</CardHeaderTitle>
            </CardHeader>
            {loading ? (
                <ShimmeredCardBody />
            ) : (
                <CardBody>
                    <Stack horizontal>
                        <Stack.Item className="decompFilterItem">
                            <Label className="filterLabel">Select a stage:</Label>
                            <Dropdown
                                options={funnelStages}
                                selectedKey={selectedStage}
                                onChange={(_event, item) => {
                                    setSelectedStage(item.key.toString());
                                }}
                                className="filterDropdown"
                                dropdownWidth={150}
                            />
                        </Stack.Item>
                        <Stack.Item className="decompFilterItem">
                            <Label className="filterLabel">Insights to show:</Label>
                            <Dropdown
                                options={[3, 5, 10].map((num) => ({
                                    key: num,
                                    text: num.toString(),
                                }))}
                                selectedKey={insightCountToShow}
                                onChange={(_event, item) => {
                                    setInsightCountToShow(item.key as number);
                                }}
                                className="filterDropdown"
                                dropdownWidth={150}
                            />
                        </Stack.Item>
                    </Stack>
                    {decompositionInsights.StageLossToStagePct.length > 0 ||
                    decompositionInsights.StageLossToGroupPct.length > 0 ? (
                        <div>
                            <table className="decompTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Top Stage Loss
                                            <TooltipHost
                                                hostClassName="decompTooltip"
                                                content={`"Stage loss" denotes the percentage of the overall loss at this stage that can be attributed to this group.`}
                                            >
                                                <Icon
                                                    iconName="info"
                                                    className="decompInfoIcon"
                                                />
                                            </TooltipHost>
                                        </th>
                                        <th>Drop-off Stage</th>
                                        <th>% Stage Loss</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {decompositionInsights.StageLossToStagePct.map(
                                        (insight, index) => {
                                            if (index >= insightCountToShow) {
                                                return null;
                                            }

                                            return DecompTableRow(
                                                insight,
                                                DecompositionDataType.StageLossToStagePct,
                                                index,
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                            <table className="decompTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Top Group Loss
                                            <TooltipHost
                                                hostClassName="decompTooltip"
                                                content={`"Group loss" refers to the percentage drop-off experienced by the user group at this stage. The "stage loss" (in parentheses) denotes the percentage of the overall loss at this stage that can be attributed to this group.`}
                                            >
                                                <Icon
                                                    iconName="info"
                                                    className="decompInfoIcon"
                                                />
                                            </TooltipHost>
                                        </th>
                                        <th>Drop-off Stage</th>
                                        <th>% Group Loss (% Stage)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {decompositionInsights.StageLossToGroupPct.map(
                                        (insight, index) => {
                                            if (index >= insightCountToShow) {
                                                return null;
                                            }

                                            return DecompTableRow(
                                                insight,
                                                DecompositionDataType.StageLossToGroupPct,
                                                index,
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                            {FunnelConfig[props.funnelType].decompositionFooter && (
                                <footer className="decompFooter">
                                    {
                                        FunnelConfig[props.funnelType]
                                            .decompositionFooter
                                    }
                                </footer>
                            )}
                        </div>
                    ) : (
                        <EmptyTableContainer />
                    )}
                </CardBody>
            )}
        </Card>
    );
};
