import React, { useEffect, useState } from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { AppButtons } from "components/CustomComponents/AppButtons";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import CustomToolTip from "components/CustomComponents/CustomToolTip";
import { Workload } from "config/PlatformConfig";

const expandAcronym = {
    DOCLIB: "SPO BROWSER through DOCLIB",
    "SPO CLASSIC DOCLIB": "SPO CLASSIC DOCLIB",
    TEAMS: "TEAMS - UiHost",
    "OFFICE APP": "OFFICE APP - UiHost",
    OWA: "OWA - UiHost",
    owalegacy: "OWA SxS - non Uihost",
    "SPO OFFICE APP": "SPO BROWSER through OFFICE APP",
    "SPO TEAMS": "SPO BROWSER through TEAMS",
    "SPO OWA": "SPO BROWSER through OWA",
    "SPO Other": "SPO BROWSER other(not Teams, OWA, Office App, Doclib)",
    Other: "Other",
};

export const SessionOriginDistribution = (props) => {
    const { payload, application } = props;
    const [selectedApp, setSelectedApp] = useState(application);
    const textfontSize = 11;
    const height = 230;
    useEffect(() => {
        setSelectedApp(application);
    }, [application]);

    const customToolTipContent = (toolTipProps): string => {
        const { payload: toolTipPayload } = toolTipProps;
        const percentage = (
            (toolTipPayload[0].value / toolTipPayload[0].payload.total) *
            100
        ).toFixed(2);
        return `${expandAcronym[toolTipPayload[0].name]}: ${percentage}% (${
            toolTipPayload[0].value
        })`;
    };

    // Break down the whole text into tspans of length 30 each and show them one after another
    const partitions = (name: string, x: number, y: number) => {
        const maxLengthInLine = 30;

        const lineHeight = 1.1;
        let i = 0;
        const result = [];
        while (i < name.length) {
            let end =
                i + maxLengthInLine < name.length
                    ? i + maxLengthInLine
                    : name.length;

            // Dont break the word in between but check for next space
            if (end < name.length && name.charAt(end) !== " ")
                while (end < name.length && name.charAt(end) !== " ") end += 1;

            result.push(end);
            i = end;
        }

        if (i !== name.length) result.push(name.length - 1);

        return result.map((data, index) => {
            const start = index === 0 ? 0 : result[index - 1];
            return (
                <tspan x={x} y={y + index * textfontSize * lineHeight} key={index}>
                    {name.substring(start, data)}
                </tspan>
            );
        });
    };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        name,
        value,
    }): any => {
        const IsminSectorPercent = percent * 100 > 5;

        if (IsminSectorPercent) {
            const RADIAN = Math.PI / 180;
            const radius = outerRadius + 10;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            const percentRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const percentX = cx + percentRadius * Math.cos(-midAngle * RADIAN);
            const percentY = cy + percentRadius * Math.sin(-midAngle * RADIAN);
            const percentage = (percent * 100).toFixed(2);

            return (
                <>
                    <text
                        x={percentX}
                        y={percentY}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={textfontSize}
                        fontWeight={500}
                    >
                        {`${percentage}%`}
                    </text>
                    <text
                        x={x}
                        y={y}
                        alignmentBaseline="middle"
                        fontSize={textfontSize}
                        fill="black"
                        textAnchor={x > cx ? "start" : "end"}
                    >
                        {partitions(
                            `${expandAcronym[name]} : ${percentage}% (${value})`,
                            x,
                            y,
                        )}
                    </text>
                </>
            );
        }
    };

    return (
        <>
            <h2 className="commonPageBlockTitle">Session Origin Distribution</h2>
            {payload && payload[selectedApp] ? (
                <div>
                    <ResponsiveContainer height={height}>
                        <PieChart>
                            <Pie
                                data={payload[selectedApp]}
                                dataKey="value"
                                label={renderCustomizedLabel}
                                labelLine={false}
                                isAnimationActive={false}
                            />
                            <Tooltip
                                content={
                                    <CustomToolTip
                                        customToolTipContentFunction={
                                            customToolTipContent
                                        }
                                    />
                                }
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div>
                    <EmptyTableContainer />
                </div>
            )}
            <AppButtons
                selectedApp={selectedApp}
                setSelectedApp={setSelectedApp}
                workload={Workload.WEB}
            />
        </>
    );
};
