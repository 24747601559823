import React from "react";
import { MBRTableType } from "components/AIScorecardComponents/types";
import { WikiLinks } from "utils/Links";

interface AIScorecardConfig {
    [key: string]: any;
}

export const config: AIScorecardConfig = {
    appsList: [
        "Word",
        "Excel",
        "PowerPoint",
        "OneNote",
        "Loop",
        "Whiteboard",
        "Teams",
        "Outlook",
        "M365 Chat",
        "Stream",
        "Forms",
        "Planner",
    ],
    pageTitle: "M365 Copilot Scorecard",
    pageHeader: "M365 Copilot Scorecard",
    pageToolNameForTelemetry: "M365 Copilot Scorecard",
    defaults: {
        app: "Summary",
        platform: "All",
        office365Category: "Commercial",
        audienceGroup: "Production",
        unit: "Session",
        cadence: "Weekly",
        cohort: "AllUsers",
    },
};

const tabConfig: AIScorecardConfig = {
    Summary: ["Scorecard"],
    All: ["Scorecard", "Top Tenant Insights", "Funnel", "MTETV"],
    default: [
        "Scorecard",
        "MBR",
        "Retention",
        "Top Tenant Insights",
        "Funnel",
        "Intent Analysis",
    ],
};

export function getTabConfig(app: string) {
    return app
        ? tabConfig[app] ?? tabConfig.default
        : tabConfig[config.defaults.app];
}

export const metricDefinitions: AIScorecardConfig = {
    ASHA: "Aggregate Session Happiness Score.",
    AuthBlockRate: "Rate at which Copilot is blocked due to auth issues.",
    "Block Rate": "Rate at which Copilot Workflows were blocked due to RAI checks.",
    CapabilityNegativeFeelingsRate: "User's dis-satisfaction with Verb.",
    CapabilityNegativeTimeSavedRate:
        "User's perception of lack of time saved with the Verb.",
    COGSCost: (
        <div>
            <span>Available only when ‘All’ is selected in the filters above.</span>
            <br />
            <br />
            <span>
                LLM costs for Copilot Scenarios in the App. This depends on the
                number and price of input/output tokens and the LLM used, such as PPO
                or 3.5 Turbo.
            </span>
        </div>
    ),
    COGSCostPerUser: (
        <div>
            <span>Available only when ‘All’ is selected in the filters above.</span>
            <br />
            <br />
            <span>
                Cost per user when a proactive or reactive call is made to LLM API,
                both of which incur costs. This user metric differs from the MAU/WAU
                column, which solely accounts for reactive calls (User Tries) to
                track active usage.
            </span>
        </div>
    ),
    CopilotSessionsPercent: "% of all sessions where Copilot was used.",
    DeltaNps:
        "Difference in NPS scores provided by users of the app who also have the Copilot SKU, and those who do not.",
    CopilotNps:
        "NPS scores derived from ratings of users who answer the question: 'How likely are you to recommend Copilot for Microsoft 365 to others, if asked?'",
    E2ELatency: "Time it takes for connection from AL to Sydney",
    Enabled: "Copilot feature is available to the user",
    EngagedUsersPercent: "% of users whose usage met the engaged usage threshold.",
    "Exceeded Expectations":
        "% who answered 'Exceeded expectations'  ≥40% in FPS after 3rd try: How well did Copilot in <App> meet your expectations? ",
    FeatureTriedPercent:
        "% Users that tried the feature / Users that tried Copilot within the App.",
    Kept: "User kept the results generated by the Copilot feature. E.g. User clicked insert in document in the Rewrite feature.",
    KeptFeatureRate: "Kept Action Count / Tried Action Count",
    MTENegativeFeelings:
        "% of negative responses (Extremely negative & Somewhat negative) to total responses",
    MTEDidntSaveTime:
        "% of negative responses (Strongly disagree & Somewhat disagree) to total responses",
    "Product Market Fit":
        "% who answered 'Very disappointed'  ≥40% in FPS after 3rd Try - FPS after 3rd try: How would you feel if you could no longer use Copilot in the <App> ",
    PositiveProductMarketFitRate:
        "% who answered 'Very disappointed'  ≥40% in FPS after 3rd Try - FPS after 3rd try: How would you feel if you could no longer use Copilot in the App ",
    QuickRatio:
        "Ratio of Resurrected and New Users to Churned Users. A value > 1 indicates product growth.",
    RAIBlockRate:
        "Rate at which incoming or outgoing content from Copilot is blocked from being offensive or inappropriate to Responsible AI standards.",
    Rejected: "User rejected or undid the results generated by the Copilot feature.",
    RetentionRate:
        " % of users who tried Copilot during the observation window that returned and tried copilot during the current window.",
    Seen: "Copilot feature dialog is rendered",
    ServiceBlockRate:
        "Rate at which Service returned error during Copilot generation.",
    SkillFinalResultLatency:
        "Wait time in seconds for the user to see the complete output from Copilot.",
    SkillFirstResultLatency:
        "Wait time in seconds for the user to see any output from Copilot.",
    TVPMF: "% of “Very disappointed” responses to total responses",
    TVExceededExpectations:
        "% of “Exceeded expectations” responses to total responses",
    ThumbsUpResponseRate:
        "The percent of thumbs up / total thumbs for the individual Copilot feature",
    ThumbsDownPer100k: "The number of thumbs down clicks per 100K try actions",
    Tried: "Distinct number of users who tried the Copilot feature. E.g. Submitted a prompt or clicked Generate Summary.",
    TriedByEnabledRate: "Tried Users / Enabled Users.",
    TriedCountsPerUser: "Ratio of total try actions to the number of tried users.",
    TriedRate: "Tried Users / Seen Users.",
    TVPassFail:
        "Transformative Value (TV) Passed or Failed for Copilot in this app.",
    UnsupportedFeaturesRate:
        "Rate at which Copilot deemed it could not produce quality content (hallucination, gap in feature support, etc.).",
    UserSuccessRate:
        "% of feature sessions where only successful outputs were received.",
    VerbsPassedMTE:
        "Number of Verbs that passed the Minimum Trustworthy Experience (MTE) in this app.",
    Intent: (
        <div>
            {"Click the icon to read more about "}
            <a href={WikiLinks.Intents} target="_blank">
                intent descriptions
            </a>
        </div>
    ),
};

export const Capability = ["Content Generation", "QnA and Summaries"];

export const AIScorecardColumns: {
    name: string;
    tooltip?: string;
    displayName: string;
    headerGroups: string[];
    className?: string;
    minWidth?: number;
}[] = [
    {
        name: "Feature",
        displayName: "Feature Name",
        headerGroups: ["None", "None"],
        className: "alignLeftHeader",
        minWidth: 120,
    },
    {
        name: "Enabled",
        tooltip: metricDefinitions["Enabled"],
        displayName: "Enabled",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "Seen",
        tooltip: metricDefinitions["Seen"],
        displayName: "Seen",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "Tried",
        tooltip: metricDefinitions["Tried"],
        displayName: "Tried",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "Kept",
        tooltip: metricDefinitions["Kept"],
        displayName: "Kept",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "Rejected",
        tooltip: metricDefinitions["Rejected"],
        displayName: "Rejected",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "ASHA",
        displayName: "ASHA",
        headerGroups: ["Quality", "None"],
    },
    {
        name: "CopilotNps",
        tooltip: metricDefinitions["CopilotNps"],
        displayName: "Copilot NPS",
        headerGroups: ["Sentiment", "None"],
    },
    {
        name: "ThumbsDownPer100k",
        tooltip: metricDefinitions["ThumbsDownPer100k"],
        displayName: "Thumbs Down / 100K Tries",
        minWidth: 200,
        headerGroups: ["Sentiment", "None"],
    },
];

const columnHighlightColor = "white";

export const AIMBRFeatureScorecardColumns: MBRTableType[] = [
    {
        name: "Feature",
        displayName: "Feature Name",
        headerGroups: ["None", "None"],
        className: "alignLeftHeader",
        minWidth: 120,
        style: {
            borderRight: "inset",
            borderRightWidth: 1,
        },
    },
    {
        name: "RAIBlockRate",
        tooltip: metricDefinitions["RAIBlockRate"],
        displayName: "RAI Block",
        headerGroups: ["Reliability", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "AuthBlockRate",
        tooltip: metricDefinitions["AuthBlockRate"],
        displayName: "Auth Block",
        headerGroups: ["Reliability", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "UnsupportedFeaturesRate",
        tooltip: metricDefinitions["UnsupportedFeaturesRate"],
        displayName: "Unsupported Features",
        headerGroups: ["Reliability", "None"],
        minWidth: 90,
        postFix: "%",
        precision: 2,
    },
    {
        name: "ServiceBlockRate",
        tooltip: metricDefinitions["ServiceBlockRate"],
        displayName: "Service Block",
        headerGroups: ["Reliability", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "UserSuccessRate",
        tooltip: metricDefinitions["UserSuccessRate"],
        displayName: "User Success Rate",
        headerGroups: ["Reliability", "None"],
        minWidth: 90,
        postFix: "%",
        precision: 2,
    },
    {
        name: "E2ELatency",
        tooltip: metricDefinitions["E2ELatency"],
        displayName: "E2E Latency (s)",
        headerGroups: ["Performance", "None"],
        precision: 2,
    },
    {
        name: "SkillFirstResultLatency",
        tooltip: metricDefinitions["SkillFirstResultLatency"],
        displayName: "Skill - 1st Result (s)",
        headerGroups: ["Performance", "None"],
        precision: 2,
    },
    {
        name: "SkillFinalResultLatency",
        tooltip: metricDefinitions["SkillFinalResultLatency"],
        displayName: "Skill - Final Result (s)",
        headerGroups: ["Performance", "None"],
        style: {
            borderRight: "inset",
            borderRightWidth: 1,
        },
        precision: 2,
    },
    {
        name: "CapabilityNegativeFeelingsRate",
        tooltip: metricDefinitions["MTENegativeFeelings"],
        displayName: "Negative Feelings",
        headerGroups: ["Minimal Trustworthy Experience (MTE)", "None"],
        style: {
            backgroundColor: columnHighlightColor,
        },
        postFix: "%",
        precision: 2,
    },
    {
        name: "CapabilityNegativeTimeSavedRate",
        tooltip: metricDefinitions["MTEDidntSaveTime"],
        displayName: "Didn't Save Me Time",
        headerGroups: ["Minimal Trustworthy Experience (MTE)", "None"],
        style: {
            backgroundColor: columnHighlightColor,
            borderRight: "inset",
            borderRightWidth: 1,
        },
        postFix: "%",
        precision: 2,
    },
    {
        name: "ASHA",
        tooltip: metricDefinitions["ASHA"],
        displayName: "ASHA",
        headerGroups: ["Minimal Trustworthy Experience (MTE)", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "KeptFeatureRate",
        tooltip: metricDefinitions["KeptFeatureRate"],
        displayName: "Kept Feature Rate",
        headerGroups: ["Minimal Trustworthy Experience (MTE)", "None"],
        minWidth: 90,
        postFix: "%",
        precision: 2,
    },
    {
        name: "TriedRate",
        tooltip: metricDefinitions["TriedRate"],
        displayName: "Tried User Rate",
        headerGroups: ["Feature Funnel Health", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "FeatureTriedPercent",
        tooltip: metricDefinitions["FeatureTriedPercent"],
        displayName: "% Feature MAU",
        headerGroups: ["Feature Funnel Health", "None"],
        postFix: "%",
        precision: 2,
    },
];

export const AIMBRAppOverviewColumns: MBRTableType[] = [
    {
        name: "RowLabel",
        displayName: "",
        headerGroups: ["None", "None"],
        minWidth: 120,
        className: "alignLeftHeader",
    },
    {
        name: "ExceededExpectationsRate",
        tooltip: metricDefinitions["Exceeded Expectations"],
        displayName: "Exceeded Expectations",
        headerGroups: ["Transformative Value (TV)", "None"],
        minWidth: 105,
        postFix: "%",
        precision: 2,
    },
    {
        name: "RetentionRate",
        tooltip: metricDefinitions["RetentionRate"],
        displayName: "Retention Rate",
        headerGroups: ["Transformative Value (TV)", "None"],
        minWidth: 90,
        postFix: "%",
        precision: 2,
    },
    {
        name: "CopilotNps",
        tooltip: metricDefinitions["CopilotNps"],
        displayName: "Copilot NPS",
        headerGroups: ["Transformative Value (TV)", "None"],
        minWidth: 60,
        precision: 2,
    },
    {
        name: "PositiveProductMarketFitRate",
        tooltip: metricDefinitions["PositiveProductMarketFitRate"],
        displayName: "Product Market Fit",
        headerGroups: ["Transformative Value (TV)", "None"],
        minWidth: 95,
        postFix: "%",
        precision: 2,
    },
    {
        name: "Tried",
        tooltip: metricDefinitions["Tried"],
        displayName: "MAU",
        headerGroups: ["App Funnel Health", "None"],
    },
    {
        name: "TriedByEnabledRate",
        tooltip: metricDefinitions["TriedByEnabledRate"],
        displayName: "% Tried / Enabled",
        headerGroups: ["App Funnel Health", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "EngagedUsersPercent",
        tooltip: metricDefinitions["EngagedUsersPercent"],
        displayName: "% Engaged Users",
        headerGroups: ["App Funnel Health", "None"],
        postFix: "%",
        precision: 2,
    },
    {
        name: "ASHA",
        tooltip: metricDefinitions["ASHA"],
        displayName: "ASHA",
        headerGroups: ["App Funnel Health", "None"],
        postFix: "%",
        precision: 2,
    },
];

export const M365ScorecardColumns: {
    name: string;
    tooltip?: string;
    displayName: string;
    headerGroups: string[];
    className?: string;
    minWidth?: number;
}[] = [
    {
        name: "AppName",
        displayName: "Apps",
        headerGroups: ["None", "None"],
        className: "alignLeftHeader",
    },
    {
        name: "Tried",
        tooltip: metricDefinitions["Tried"],
        displayName: "Tried",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "TriedCountsPerUser",
        tooltip: metricDefinitions["TriedCountsPerUser"],
        displayName: "Tries per User",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "QuickRatio",
        tooltip: metricDefinitions["QuickRatio"],
        displayName: "Quick Ratio",
        headerGroups: ["Usage", "None"],
    },
    {
        name: "CopilotSessionsPercent",
        tooltip: metricDefinitions["CopilotSessionsPercent"],
        displayName: "% Copilot sessions",
        headerGroups: ["Usage", "None"],
        minWidth: 140,
    },
    {
        name: "ASHA",
        displayName: "ASHA",
        headerGroups: ["Quality", "None"],
    },
    {
        name: "CopilotNps",
        tooltip: metricDefinitions["CopilotNps"],
        displayName: "Copilot NPS",
        headerGroups: ["Feedback", "None"],
    },
    {
        name: "ThumbsDownPer100k",
        tooltip: metricDefinitions["ThumbsDownPer100k"],
        displayName: "Thumbs Down / 100k Tries",
        headerGroups: ["Feedback", "None"],
        minWidth: 200,
    },
    {
        name: "VerbsPassedMTE",
        tooltip: metricDefinitions["VerbsPassedMTE"],
        displayName: "MTE",
        headerGroups: ["Feedback", "None"],
        minWidth: 100,
    },
    {
        name: "TVPassFail",
        tooltip: metricDefinitions["TVPassFail"],
        displayName: "TV",
        headerGroups: ["Feedback", "None"],
        minWidth: 100,
    },
    {
        name: "COGSCost",
        tooltip: metricDefinitions["COGSCost"],
        displayName: "Cost",
        headerGroups: ["COGS", "None"],
        minWidth: 100,
    },
    {
        name: "COGSCostPerUser",
        tooltip: metricDefinitions["COGSCostPerUser"],
        displayName: "Average Usage Cost",
        headerGroups: ["COGS", "None"],
        minWidth: 150,
    },
];

export const MTEandTVScorecardColumns: {
    name: string;
    tooltip?: string;
    displayName: string;
    headerGroups: string[];
    className?: string;
    minWidth?: number;
}[] = [
    {
        name: "FeatureVerb",
        displayName: "Product Name",
        headerGroups: ["None", "None"],
        minWidth: 120,
        className: "alignLeftHeader",
    },
    {
        name: "NegativeFeelingsRate",
        tooltip: metricDefinitions["MTENegativeFeelings"],
        displayName: "% Negative Feeling",
        headerGroups: ["MTE", "None"],
        minWidth: 120,
    },
    {
        name: "NegativeTimeSavedRate",
        tooltip: metricDefinitions["MTEDidntSaveTime"],
        displayName: "% Didn't save time",
        headerGroups: ["MTE", "None"],
        minWidth: 140,
    },
    {
        name: "ThumbsDownPer100k",
        tooltip: metricDefinitions["ThumbsDownPer100k"],
        displayName: "Thumbs Down / 100k Tries",
        headerGroups: ["MTE", "None"],
        minWidth: 200,
    },
    {
        name: "ASHA",
        displayName: "ASHA",
        tooltip: metricDefinitions["ASHA"],
        headerGroups: ["MTE", "None"],
        minWidth: 80,
    },
    {
        name: "CopilotNps",
        tooltip: metricDefinitions["CopilotNps"],
        displayName: "Copilot NPS",
        headerGroups: ["TV", "None"],
        minWidth: 120,
    },
    {
        name: "PositiveProductMarketFitRate",
        displayName: "PMF",
        tooltip: metricDefinitions["TVPMF"],
        headerGroups: ["TV", "None"],
        minWidth: 80,
    },
    {
        name: "ExceededExpectationsRate",
        displayName: "% Exceeded Expectations",
        tooltip: metricDefinitions["TVExceededExpectations"],
        headerGroups: ["TV", "None"],
        minWidth: 190,
    },
    {
        name: "Week1RetentionRate",
        displayName: "% Week 1 Retention",
        headerGroups: ["TV", "None"],
        minWidth: 120,
    },
];

export const IntentAnalysisScorecardColumns: {
    name: string;
    tooltip?: string;
    displayName: string;
    className?: string;
    minWidth?: number;
}[] = [
    {
        name: "Intent",
        displayName: "Intent",
        tooltip: metricDefinitions["Intent"],
        minWidth: 200,
    },
    {
        name: "TriedCount",
        displayName: "Tried Count",
        minWidth: 200,
    },
    {
        name: "TriedRate",
        displayName: "% of Total Tries",
        minWidth: 200,
    },
    {
        name: "KeptRate",
        displayName: "Kept Rate",
        minWidth: 200,
    },
];
