import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import { LegendList } from "components/CustomComponents/LegendList/LegendList";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { AddInPicker } from "pages/Performance/AddIns/AddInPicker";
import { AddInVersionPerfCharts } from "pages/Performance/AddIns/AddInVersionPerfCharts";
import PerformanceTitle from "pages/Performance/PerformanceTitle";
import { Win32PerformanceDatePicker } from "pages/Performance/Win32PerformanceDatePicker";
import * as API from "api";
import { legendReducer } from "components/CustomComponents/LegendList/LegendReducer";
import { commonPageBlock, commonPageStyle } from "pages/common";
import { KustoResponseType } from "pages/commonTypes";
import {
    countAndPerfImpactDataFromResponse,
    createAppInformationObject,
    progIdsFromAllAppHistoryData,
} from "pages/Performance/AddIns/AddInPerformanceHelper";
import { perfConfig } from "pages/Performance/PerfConfig";
import {
    AddInCountAndPerfImpactData,
    AddinPerfByVersionProps,
    AppObject,
    TenantAddInOptions,
} from "pages/Performance/types";
import {
    getDefaultTimespan,
    getMostRecentDate,
    getValidQueryParams,
    parseParams,
} from "pages/Performance/Win32PerformanceHelper";
import AppInsights from "utils/AppInsights";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import {
    computeQueryParams,
    createObjectArrayFromKustoResponse,
    getChartColor,
    isPositiveIntegerString,
} from "utils/Helpers";
import { useSendLaunchEvent } from "utils/PlgTelemetryLogger";

const getInitialParams = (suppliedParams: {}) => {
    const defaultParams: AddinPerfByVersionProps = {
        id: "72f988bf-86f1-41af-91ab-2d7cd011db47", // Microsoft
        date: undefined,
        timespan: undefined,
        progId: undefined,
    };

    const paramValidation = {
        id: (id: any) => typeof id === "string",
        date: (date: any) => !!Date.parse(date),
        timespan: isPositiveIntegerString,
        progId: (progId: any) => typeof progId === "string",
    };

    const params = getValidQueryParams(
        suppliedParams,
        defaultParams,
        paramValidation,
    );

    params.date = params.date ?? getMostRecentDate(false);
    params.timespan = params.timespan ?? getDefaultTimespan(false);

    return params;
};

const AddInVersionPerformance = () => {
    const pageName = "Office Add-In Performance by Version";
    document.title = pageName;
    AppInsights.getInstance().TrackPage(pageName);

    const location = useLocation();
    const path = location.search;

    const [error, setError] = useState<string>(null);

    const [paramsState, setParamsState] = useState<AddinPerfByVersionProps>(
        getInitialParams(parseParams(path) as Partial<AddinPerfByVersionProps>),
    );

    const [options, setOptions] = useState<TenantAddInOptions[]>([]);

    const [clsid, setClsid] = useState<string>(undefined);

    const [addInVersionHistory, setAddInVersionHistory] =
        useState<AppObject<AddInCountAndPerfImpactData>>(undefined);

    const [legend, dispatcher] = useReducer(legendReducer, []);

    useSendLaunchEvent("Office Add-In Performance by Version Dashboard");

    useEffect(() => {
        const queryAddInOptions = async () => {
            try {
                const response: KustoResponseType<string> =
                    await API.fetchTenantAddInOptions({ id: paramsState.id });
                setOptions(createObjectArrayFromKustoResponse(response));
            } catch (error) {
                setOptions([]);
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantAddInOptionsException",
                    ),
                    {
                        message: "Failed to fetch list of selectable add-ins.",
                    },
                    error,
                );
            }
        };
        queryAddInOptions();
    }, [paramsState.id]);

    useEffect(() => {
        const selectedOption = options.filter(
            (option) => option.progId == paramsState.progId,
        );
        setClsid(selectedOption[0]?.clsid);
        AppInsights.getInstance().LogEvent("AddInVersionSelectedAddInChanged", {
            selectedOption: selectedOption[0],
        });
    }, [paramsState.progId, options]);

    // Update the URL when necessary
    useEffect(() => {
        window.history.pushState(
            {},
            "",
            window.location.pathname + computeQueryParams(paramsState),
        );
    }, [paramsState]);

    const onParamChange = (updates: Partial<AddinPerfByVersionProps>) => {
        setParamsState({ ...paramsState, ...updates });
    };

    useEffect(() => {
        const queryChartData = async () => {
            setAddInVersionHistory(undefined);
            if (
                !paramsState.progId ||
                (!clsid && !paramsState.progId.includes("Private add-ins"))
            )
                return;
            try {
                const response: KustoResponseType<string | number> =
                    await API.fetchTenantAddInVersionHistory({
                        id: paramsState.id,
                        date: paramsState.date,
                        timespan: paramsState.timespan,
                        clsid,
                        progid: paramsState.progId,
                    });

                const history = countAndPerfImpactDataFromResponse(response);
                setAddInVersionHistory(history);

                const labels = progIdsFromAllAppHistoryData(history)
                    .sort()
                    .map((version, index) => ({
                        key: version,
                        Name: version,
                        color: getChartColor(index),
                    }));
                dispatcher({
                    type: "addEntries",
                    payload: labels,
                });
            } catch (error) {
                setAddInVersionHistory(createAppInformationObject({}));
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchTenantAddInVersionHistoryException",
                    ),
                    {
                        message: "Failed to fetch add-in version history.",
                    },
                    error,
                );
            }
        };
        queryChartData();
    }, [
        paramsState.id,
        paramsState.date,
        paramsState.timespan,
        clsid,
        paramsState.progId,
        dispatcher,
    ]);

    // When the user changes which add-in to show, reset the legend.
    useEffect(() => {
        dispatcher({
            type: "clearAndSetToAll",
            payload: "",
        });
    }, [dispatcher, clsid]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack disableShrink styles={commonPageStyle}>
            <PerformanceTitle
                pageName={pageName}
                id={paramsState.id}
                level="TenantId"
                setError={setError}
            />
            <Stack horizontal tokens={{ childrenGap: 50 }}>
                <Win32PerformanceDatePicker
                    isMonthly={false}
                    timespan={paramsState.timespan}
                    date={paramsState.date}
                    onInputChange={onParamChange}
                />
                <AddInPicker
                    progId={paramsState.progId}
                    options={options}
                    onChange={onParamChange}
                />
            </Stack>
            <Stack>
                <LegendList legend={legend} dispatcher={dispatcher} />
                {perfConfig.Win32.AppsList.map((appName) => {
                    return (
                        <Stack styles={commonPageBlock} key={appName}>
                            <ApplicationTitle app={appName} />
                            <AddInVersionPerfCharts
                                progId={paramsState.progId}
                                data={addInVersionHistory?.[appName]}
                                legend={legend}
                            />
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default AddInVersionPerformance;
