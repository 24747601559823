import React, { useEffect, useState } from "react";
import CopilotSummaryScorecardTable from "components/AIScorecardComponents/CopilotSummaryScorecardTable";
import EmtpyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchCopilotData,
    formatSummaryScorecardData,
} from "components/AIScorecardComponents/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";

export const CopilotSummaryScorecard = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [scorecardData, setScorecardData] = useState([]);

    useEffect(() => {
        const getScorecardData = async () => {
            setLoading(true);
            setError(null);

            try {
                const [data] = await Promise.all([
                    fetchCopilotData(
                        {
                            date: filters.date,
                            audienceGroup: filters.audienceGroup,
                            office365Category: filters.office365Category,
                            platform: filters.platform,
                            cadence: filters.cadence,
                            cohort: filters.cohort,
                        },
                        "copilotSummaryScorecard",
                    ),
                ]);

                let formattedData = formatSummaryScorecardData(data);
                setScorecardData(formattedData);
                setLoading(false);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotSummaryScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot Summary Scorecard data",
                    },
                    e,
                );

                setError(e.message);
                setScorecardData([]);
                setLoading(false);
            }
        };

        if (filters.date !== null) getScorecardData();
    }, [
        filters.date,
        filters.audienceGroup,
        filters.office365Category,
        filters.platform,
        filters.cadence,
        filters.cohort,
    ]);
    return (
        <>
            {error && <ErrorFallback message={error} />}
            {!loading && filters.date === null ? (
                <EmtpyTableContainer />
            ) : (
                <CopilotSummaryScorecardTable
                    items={scorecardData}
                    loading={loading}
                    filters={filters}
                />
            )}
        </>
    );
};
