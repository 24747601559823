import React, { useEffect, useState } from "react";
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
} from "@fluentui/react";
import { MtrComboBoxProps } from "pages/MTR/types";

// Adapted from https://developer.microsoft.com/en-us/fluentui?fabricVer=8#/controls/web/combobox and ASHA
// Controlled multi-select ComboBox with select all
export const MtrComboBox = (props: MtrComboBoxProps) => {
    const separator = ",";

    // Create an array with just the valid / selectable items in the dropdown menu
    const selectableOptions: string[] =
        props.options
            ?.filter((option: IDropdownOption) => !option.disabled)
            ?.map((filter: any) => filter.key) ?? [];

    // Filter out invalid values from the initial selection
    const initialValue = new Set<string>(
        props.filterValue
            .split(separator)
            .filter((value: string) => selectableOptions.includes(value)),
    );

    // Selecting everything if the initial value was invalid or it was set to "All"
    const initialSelection =
        initialValue.size === 0 || initialValue.has("All")
            ? selectableOptions
            : [...initialValue];
    const [selectedKeys, setSelectedKeys] = useState<string[]>(initialSelection);

    // Update our state whenever the filters change
    useEffect(() => {
        setSelectedKeys(initialSelection);
        // ADO 7955411: possible useMemo refactor on initialSelection
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    // Define a minimal, safe set of options for Signals/Apps ("All" for Signals and Apps)
    const _getDeselectAllOptions = () => {
        switch (props.filterKey) {
            case "signal":
            case "app":
                return [...selectableOptions];
            default:
                return [""];
        }
    };

    // Sadly, we have no way to view our pivot selections from onMenuDismissed
    // if we aren't managing the changes manually, so we need to listen to every click
    const _onChange = (
        _: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void => {
        const isKeySelected = option?.selected;
        const currentSelectedKeys = selectedKeys.filter((key) => key !== "All");
        const areAllKeysSelected =
            currentSelectedKeys.length + 1 === selectableOptions.length;

        if (!option) {
            return;
        }

        if (option?.key === "All") {
            if (areAllKeysSelected) {
                setSelectedKeys([]);
            } else {
                setSelectedKeys([...selectableOptions]);
            }
            return;
        }

        // Remove or add the key from the list as needed
        const key = option.key as string;
        const updatedKeys = isKeySelected
            ? [...currentSelectedKeys, key]
            : currentSelectedKeys.filter((k: string) => k !== key);

        // Add "All" to the list if we're now selecting all keys
        if (updatedKeys.length + 1 === selectableOptions.length) {
            updatedKeys.push("All");
        }

        setSelectedKeys(updatedKeys);
    };

    // Only update our filters and re-run the queries once the dropdown menu is dismissed (instead of doing it on every click)
    const _onMenuDismissed = () => {
        const emptyOptions = _getDeselectAllOptions();
        const areAllKeysDeselected =
            selectedKeys.length === 0 && selectableOptions.length > 0;

        // If we just deselected the last remaining key, default to a same set of options before re-running our queries
        if (areAllKeysDeselected) {
            setSelectedKeys(emptyOptions);
        }

        const currentSelection = new Set(
            areAllKeysDeselected ? emptyOptions : selectedKeys,
        );

        // No need to do anything if we started with "All" and we're still selecting all filter options
        if (initialValue.has("All") && currentSelection.has("All")) {
            return;
        }

        // If we're selecting all options, replace that with "All"
        if (currentSelection.has("All")) {
            props.onFilterChange(props.filterKey, "All");
            return;
        }

        // `initialValue` will never have "All" inside unless it's the only value,
        // so we need to remove it from `currentSelection` before comparing the two sets
        currentSelection.delete("All");

        // Do nothing if we're selecting the same set of values we started with
        if (
            currentSelection.size === initialValue.size &&
            [...currentSelection].every((option) => initialValue.has(option))
        ) {
            return;
        }

        props.onFilterChange(
            props.filterKey,
            Array.from(currentSelection).join(separator),
        );
    };

    const _getComboBoxText = (): string => {
        const numOfSelectedKeys = new Set(selectedKeys).size;
        return numOfSelectedKeys === selectableOptions.length ||
            (numOfSelectedKeys === 1 && selectedKeys[0] === "All")
            ? "All"
            : null;
    };

    return (
        <ComboBox
            className={props.className}
            text={_getComboBoxText()}
            styles={props.styles}
            options={props.options}
            onChange={_onChange}
            selectedKey={selectedKeys}
            onMenuDismissed={_onMenuDismissed}
            multiSelect
            useComboBoxAsMenuWidth
        />
    );
};
