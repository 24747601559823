export const SearchBarSuggestionCalloutMaxHeight = 300;
export const SearchBarSuggestionCalloutMaxWidth = 470;

export const SearchBarSuggestionsCalloutClassName = "search-results-callout";

export const SearchBarSuggestionsCalloutDescribedBy = "callout-description";

export const SearchBarId = "searchBoxId";

export const SearchBarWidth = 320;
