import React, { useState } from "react";
import {
    DatePicker,
    IDatePickerStyles,
    Stack,
    Label,
    IDropdownOption,
} from "@fluentui/react";
import SearchableDropdown from "components/CustomComponents/SearchableDropDown/SearchableDropDown";
import { IFiltersPanelProps } from "components/QuIPSurveyReportComponents/FiltersPanel/types";

const FiltersPanel = (props: IFiltersPanelProps) => {
    const datePickerStyles: Partial<IDatePickerStyles> = {
        root: [
            {
                maxWidth: 400,
                marginRight: "10px",
                selectors: {
                    ".ms-TextField-field": { fontSize: 12, fontWeight: 600 },
                    ".ms-TextField-fieldGroup": { borderRadius: 14, border: "none" },
                },
            },
        ],
        textField: { width: 110 },
        statusMessage: { margin: 0 },
    };

    const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([
        props.data.platforms[0].key as string,
    ]);
    const [selectedAudience, setSelectedAudience] = useState<string[]>([
        props.data.audienceGroups[0].key as string,
    ]);
    const [selectedCampaignId, setSelectedCampaignId] = useState<
        string | number | null
    >(props.data.campaignIds[0].key);
    const [selectedAppId, setSelectedAppId] = useState<string | number | null>(
        props.data.appIds[0].key,
    );
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(
        props.data.dateRange.startDate,
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(
        props.data.dateRange.endDate,
    );

    return (
        <Stack horizontal className="filterPanel" tokens={{ childrenGap: 10 }}>
            <Stack horizontal>
                <Label className="filterLabel"> Start Date: </Label>
                <DatePicker
                    styles={datePickerStyles}
                    data-testid="startDate"
                    placeholder="Select a date..."
                    ariaLabel="Start date"
                    value={selectedStartDate}
                    onSelectDate={(date: Date) => {
                        setSelectedStartDate(date);
                        props.onChange(
                            "startDate",
                            date.toLocaleDateString("en-US"),
                        );
                    }}
                />
                <Label className="filterLabel"> End Date: </Label>
                <DatePicker
                    styles={datePickerStyles}
                    data-testid="endDate"
                    placeholder="Select a date..."
                    ariaLabel="End date"
                    value={selectedEndDate}
                    onSelectDate={(date: Date) => {
                        setSelectedEndDate(date);
                        props.onChange("endDate", date.toLocaleDateString("en-US"));
                    }}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <Label className="filterLabel">Application:</Label>
                <SearchableDropdown
                    data-testid="appId"
                    placeholder="Select an App ID"
                    options={props.data.appIds}
                    className="filterDropdown"
                    multiSelect={false}
                    dropdownWidth={150}
                    defaultSelectedKey={selectedAppId}
                    onChange={(_event, item: IDropdownOption) => {
                        setSelectedAppId(item.key as string);
                        props.onChange("appId", item.key);
                    }}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <Label className="filterLabel">Platform:</Label>
                <SearchableDropdown
                    data-testid="platform"
                    options={props.data.platforms}
                    className="filterDropdown"
                    multiSelect={true}
                    dropdownWidth={200}
                    selectedKeys={selectedPlatforms}
                    onChange={(_event, item: IDropdownOption) => {
                        if (item) {
                            const newVal = item.selected
                                ? [...selectedPlatforms, item.key as string]
                                : selectedPlatforms.filter(
                                      (key) => key !== item.key,
                                  );
                            setSelectedPlatforms(newVal);
                            props.onChange("platforms", newVal);
                        }
                    }}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <Label className="filterLabel">Audience:</Label>
                <SearchableDropdown
                    data-testid="audienceGroup"
                    options={props.data.audienceGroups}
                    className="filterDropdown"
                    multiSelect={true}
                    dropdownWidth={300}
                    selectedKeys={selectedAudience}
                    onChange={(_event, item: IDropdownOption) => {
                        if (item) {
                            const newVal = item.selected
                                ? [...selectedAudience, item.key as string]
                                : selectedAudience.filter((key) => key !== item.key);
                            setSelectedAudience(newVal);
                            props.onChange("audienceGroup", newVal);
                        }
                    }}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <Label className="filterLabel">Campaign Id:</Label>
                <SearchableDropdown
                    data-testid="campaignId"
                    placeholder="Select a Campaign"
                    options={props.data.campaignIds}
                    className="filterDropdown"
                    multiSelect={false}
                    dropdownWidth={350}
                    defaultSelectedKey={selectedCampaignId}
                    onChange={(_event, item: IDropdownOption) => {
                        setSelectedCampaignId(item.key as string);
                        props.onChange("campaignId", item.key);
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default FiltersPanel;
