import React from "react";
import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import { Win32PerformanceDatePicker } from "pages/Performance/Win32PerformanceDatePicker";
import {
    DataPointsLabel,
    dataPointsOptions,
    PartitionByLabel,
    partitionByOptions,
    valuesToShowOptions,
} from "pages/Performance/PerformanceConstants";
import {
    DataPointsChoice,
    Dimension,
    PerfQueryParams,
    ValuesToShowChoice,
    Win32PerfInputUpdates,
} from "pages/Performance/types";
import AppInsights from "utils/AppInsights";
import { appInsightsCustomEvents } from "utils/AppInsightsHelper";
import { dropdownStyles } from "utils/WebConstants";

export const Win32PerformanceInputBar = ({
    queryParams,
    onInputChange,
}: {
    queryParams: PerfQueryParams;
    onInputChange: (updates: Win32PerfInputUpdates) => void;
}) => {
    const { timespan, date, dataPoints, partitionBy, valuesToShow } = queryParams;

    const isMonthly = dataPoints === "Monthly";

    const onValuesToShowChange = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        AppInsights.getInstance().LogEvent(appInsightsCustomEvents.PerformanceView, {
            type: "UIControl",
            event: "AggregationChange",
            value: option.key,
        });

        onInputChange({
            valuesToShow: option.key as ValuesToShowChoice,
        });
    };

    const onDataPointsChange = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        if (dataPoints !== option.text) {
            AppInsights.getInstance().LogEvent(
                appInsightsCustomEvents.PerformanceView,
                {
                    type: "UIControl",
                    event: "DataPointsChange",
                    value: option.key,
                },
            );

            onInputChange({
                dataPoints: option.key as DataPointsChoice,
            });
        }
    };

    const onPartitionByChange = (
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ) => {
        if (partitionBy !== option.text) {
            AppInsights.getInstance().LogEvent(
                appInsightsCustomEvents.PerformanceView,
                {
                    type: "UIControl",
                    event: "PartitionByChange",
                    value: option.key,
                },
            );

            onInputChange({
                partitionBy: option.key as Dimension,
            });
        }
    };

    return (
        <Stack horizontal>
            <Stack horizontal tokens={{ childrenGap: 50 }}>
                <Dropdown
                    label={DataPointsLabel}
                    options={dataPointsOptions}
                    styles={dropdownStyles}
                    selectedKey={dataPoints}
                    onChange={onDataPointsChange}
                    className="filterDropdown"
                />
                <Win32PerformanceDatePicker
                    isMonthly={isMonthly}
                    timespan={timespan}
                    date={date}
                    onInputChange={onInputChange}
                />
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        label={PartitionByLabel}
                        options={partitionByOptions}
                        styles={dropdownStyles}
                        selectedKey={partitionBy}
                        onChange={onPartitionByChange}
                        className="filterDropdown"
                    ></Dropdown>
                    <Dropdown
                        label="Values to show"
                        options={valuesToShowOptions}
                        styles={dropdownStyles}
                        selectedKey={valuesToShow}
                        onChange={onValuesToShowChange}
                        className="filterDropdown"
                    ></Dropdown>
                </Stack>
            </Stack>
        </Stack>
    );
};
