import React, { useState, useEffect } from "react";
import {
    ScatterChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    LabelList,
    Label,
    Cell,
    Scatter,
} from "recharts";
import {
    Stack,
    Slider,
    DetailsList,
    MessageBar,
    MessageBarType,
} from "@fluentui/react";
import {
    CustomTooltip,
    getScorecardColumns,
} from "components/AIScorecardComponents/CopilotCapAnalysisHelper";
import { CapHistoricalTrendsPanel } from "components/AIScorecardComponents/CopilotViews/CopilotCapTrends";
import { FeatureFilter } from "components/AIScorecardComponents/FeatureFilter";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchCopilotData,
    formatCapData,
    formatCapTrendsData,
} from "components/AIScorecardComponents/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { NO_DATA_MESSAGES, COPILOT_MESSAGES } from "utils/Messages";

const CheckAppDataAvailable = (cadence: string, application: string) => {
    return cadence === "Weekly" && application === "Word";
};

export const CopilotCapAnalysis = ({ filters, onFilterChange }) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState(true);
    const [capData, setCapData] = useState([]);
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [capTrendsData, setCapTrendsData] = useState({});
    const [numberOfPointsToShow, setNumberOfPointsToShow] = useState(10);
    const [xMin, setxMin] = useState(0);
    const [xMax, setxMax] = useState(30);

    useEffect(() => {
        const getCapData = async () => {
            const queryParams = { ...filters };
            setLoading(true);
            setCapData([]);
            setError(null);
            try {
                const capKusto = await fetchCopilotData(
                    queryParams,
                    "copilotCapData",
                );
                let formattedData = formatCapData(capKusto).sort(function (a, b) {
                    return b.TriedRate - a.TriedRate;
                });
                setCapData(formattedData);
                if (formattedData.length > 0) {
                    setxMax(Math.round(3 + formattedData[0].TriedRate));
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "copilotCapDataProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format CAP data",
                    },
                    error,
                );

                setError(error.message);
                setCapData([]);
                return;
            }
            setLoading(false);
        };
        getCapData();
    }, [filters]);

    const getCapTrendsData = async (name, intents) => {
        const queryParams = { ...filters, intents: intents };
        setError(null);
        try {
            const capTrendsKusto = await fetchCopilotData(
                queryParams,
                "copilotCapTrendsData",
            );
            setCapTrendsData({
                Name: name,
                Data: formatCapTrendsData(capTrendsKusto),
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "copilotCapTrendsDataProcessingFailed",
                ),
                {
                    message: "Failed to fetch or format CAP Trends data",
                },
                error,
            );
            setError(error.message);
            setCapTrendsData([]);
            return;
        }
    };

    const columns = getScorecardColumns();

    return loading ? (
        <ShimmeredCardBody />
    ) : error ? (
        <ErrorFallback message={error} />
    ) : !loading && !CheckAppDataAvailable(filters.cadence, filters.application) ? (
        <EmptyTableContainer message={COPILOT_MESSAGES.NO_DATA_INTENT_ANALYSIS} />
    ) : !loading && capData.length == 0 ? (
        <EmptyTableContainer message={NO_DATA_MESSAGES.NO_DATA_FOR_FILTER} />
    ) : (
        <>
            <MessageBar messageBarType={MessageBarType.warning}>
                This report is work in progress. Data may be incorrect or missing.
                Only Commercial data for North American region is shown.
            </MessageBar>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign="start">
                        <Stack.Item grow={2}>
                            <ResponsiveContainer width="100%" height={600}>
                                <ScatterChart
                                    margin={{
                                        top: 20,
                                        right: 50,
                                        bottom: 20,
                                        left: 15,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="TriedRate"
                                        type="number"
                                        name="TriedRate"
                                        unit="%"
                                        domain={[xMin, xMax]}
                                        allowDataOverflow
                                    >
                                        <Label
                                            value="% of Total Tries"
                                            offset={0}
                                            position="insideBottom"
                                        />
                                    </XAxis>
                                    <YAxis
                                        dataKey="KeptRate"
                                        type="number"
                                        name="KeptRate"
                                        unit="%"
                                        domain={["dataMin", "dataMax"]}
                                        allowDecimals={false}
                                        padding={{ top: 10, bottom: 10 }}
                                    >
                                        <Label
                                            value="Kept Rate"
                                            angle={-90}
                                            position="left"
                                        />
                                    </YAxis>
                                    <ZAxis
                                        type="number"
                                        range={[1, 100]}
                                        dataKey="z"
                                    />
                                    <ReferenceLine
                                        y="60"
                                        stroke="green"
                                        label={
                                            <Label
                                                value="60%"
                                                position="insideTopRight"
                                            />
                                        }
                                    />
                                    <Tooltip
                                        content={<CustomTooltip active payload />}
                                    />
                                    <Scatter
                                        name="Intents"
                                        data={capData.slice(0, numberOfPointsToShow)}
                                    >
                                        <LabelList
                                            dataKey="Name"
                                            position="right"
                                            width={200}
                                            fill="black"
                                        />
                                        {capData
                                            .slice(0, numberOfPointsToShow)
                                            .map((entry, index) => {
                                                return (
                                                    <Cell
                                                        key={`cell-${index}-${entry.Name}`}
                                                        fill={
                                                            // all bubbles are blue for now
                                                            // keeping the logic for POST-MVP
                                                            entry.TriedRate >= 10
                                                                ? entry.KeptRate >=
                                                                  60
                                                                    ? "blue"
                                                                    : "blue"
                                                                : "blue"
                                                        }
                                                        onMouseOver={() => {
                                                            return CustomTooltip(
                                                                entry,
                                                            );
                                                        }}
                                                        onClick={() => {
                                                            getCapTrendsData(
                                                                entry.Name,
                                                                entry.Intents,
                                                            );
                                                            setShowPanel(true);
                                                        }}
                                                    />
                                                );
                                            })}
                                    </Scatter>
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <Stack tokens={{ childrenGap: 20 }}>
                                <Stack.Item>
                                    <FeatureFilter
                                        filters={filters}
                                        onFilterChange={onFilterChange}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { minWidth: "200px" } }}>
                                    <Slider
                                        label="Number of Intents to Show"
                                        min={5}
                                        max={capData.length}
                                        step={1}
                                        value={numberOfPointsToShow}
                                        onChange={(value: number) =>
                                            setNumberOfPointsToShow(value)
                                        }
                                        showValue={true}
                                        className="numberToDisplaySlider"
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Slider
                                        label="X-axis"
                                        ranged
                                        min={0}
                                        max={100}
                                        step={1}
                                        value={xMax}
                                        lowerValue={xMin}
                                        onChange={(value, range) => {
                                            setxMin(range[0]);
                                            setxMax(range[1]);
                                        }}
                                        showValue={true}
                                        className="XAxisSlider"
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <CapHistoricalTrendsPanel
                                showPanel={showPanel}
                                setShowPanel={setShowPanel}
                                data={capTrendsData}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <DetailsList
                        columns={columns}
                        items={capData.map((item) => {
                            return {
                                Intent: item.Name,
                                "Tried Count": item.TriedCount,
                                "% of Total Tries": item.TriedRate + "%",
                                "Kept Rate": item.KeptRate + "%",
                            };
                        })}
                        selectionMode={0}
                        onItemInvoked={(item) => {
                            let index = capData.findIndex((i) => {
                                return i.Intent == item.Name;
                            });
                            getCapTrendsData(item.Intent, capData[index].Intents);
                            setShowPanel(true);
                        }}
                        compact
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};
