export const quipSurveyFunnelAppPlatforms: string = `
\${tableName}
| distinct AppPlatform | order by AppPlatform asc 
`;

export const quipSurveyFunnelAudienceGroups: string = `
\${tableName}
| distinct AudienceGroup | order by AudienceGroup asc 
`;

export const quipSurveyFunnelApplicationIds: string = `
\${tableName}
| distinct HostId | order by HostId asc 
`;

export const quipSurveyFunnelCampaignIds: string = `
\${tableName}
| distinct CampaignId
`;

export const quipSurveyGeneralFunnelData: string = `
GetGeneralFunnelData(\${startTime}, \${endTime}, dynamic(\${surveyIds}), dynamic(\${platforms}), dynamic(\${audience}), dynamic(\${appIds}))
`;

export const quipSurveyUIFunnelData: string = `
GetUIFunnelData(\${startTime}, \${endTime}, '\${surveyIds}', '\${platforms}', dynamic(\${audience}), dynamic(\${appIds}))
`;

export const quipSurveyFunnelQueries: {} = {
    quipSurveyFunnelAppPlatforms,
    quipSurveyFunnelAudienceGroups,
    quipSurveyFunnelApplicationIds,
    quipSurveyFunnelCampaignIds,
    quipSurveyGeneralFunnelData,
    quipSurveyUIFunnelData,
};
