import React from "react";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { Stack } from "@fluentui/react/lib/Stack";
import { CommonFunnel } from "components/CommonFunnelComponents/CommonFunnel/CommonFunnel";
import CommonPivot from "components/CommonPivot/CommonPivot";
import { addBetaTag } from "components/CommonPivot/CommonPivotHelpers";
import ConsumerGrowthScorecard from "components/ConsumerGrowthScorecardComponents/ConsumerGrowthScorecard";
import { MilestoneFunnelType } from "config/FunnelConfig";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { commonPageStyle } from "pages/common";
import { ConsumerOverviewSectionTabs } from "utils/ConsumerConstants";

const ConsumerOverview = () => {
    const { pageHeader, pageTitle } = getPageConfig(
        DrilldownPages.GROWTHSCORECARD_CONSUMER,
    );

    return (
        <Stack styles={commonPageStyle}>
            <Stack.Item>
                <div className="orgHeader">{pageHeader}</div>
            </Stack.Item>
            <Stack.Item>
                <CommonPivot
                    pageTitle={pageTitle}
                    sectionTabs={ConsumerOverviewSectionTabs}
                >
                    <PivotItem
                        itemKey={ConsumerOverviewSectionTabs.Growth}
                        headerText={ConsumerOverviewSectionTabs.Growth}
                    >
                        <ConsumerGrowthScorecard />
                    </PivotItem>
                    <PivotItem
                        itemKey={ConsumerOverviewSectionTabs.Harmony}
                        headerText={`${ConsumerOverviewSectionTabs.Harmony} Funnel`}
                    >
                        <CommonFunnel funnelType={MilestoneFunnelType.Harmony} />
                    </PivotItem>
                    <PivotItem
                        itemKey={ConsumerOverviewSectionTabs.AHPFunnels}
                        headerText={`${ConsumerOverviewSectionTabs.AHPFunnels} Funnel`}
                        onRenderItemLink={addBetaTag}
                    >
                        <CommonFunnel funnelType={MilestoneFunnelType.AHP} />
                    </PivotItem>
                    <PivotItem
                        itemKey={ConsumerOverviewSectionTabs.UHPFunnels}
                        headerText={`${ConsumerOverviewSectionTabs.UHPFunnels} Funnel`}
                        onRenderItemLink={addBetaTag}
                    >
                        <CommonFunnel funnelType={MilestoneFunnelType.UHP} />
                    </PivotItem>
                </CommonPivot>
            </Stack.Item>
        </Stack>
    );
};

export default ConsumerOverview;
