import React, { useEffect, useState } from "react";
import { ComposedChart, XAxis, YAxis, Bar, Legend, Tooltip } from "recharts";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import "components/ConsumerGrowthScorecardComponents/style.css";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatChartData,
} from "components/AIScorecardComponents/ApiHandler";
import {
    AIScorecardFlyoutPropsType,
    CopilotWeeklyChartType,
    LegendPayloadType,
} from "components/AIScorecardComponents/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { getChartColor } from "utils/Helpers";

const chartFlyoutBarsDisplayNames = {
    Enabled: {
        DisplayName: "Enabled",
    },
    Seen: {
        DisplayName: "Seen",
    },
    Tried: {
        DisplayName: "Tried",
    },
    Kept: {
        DisplayName: "Kept",
    },
};

const legendPayload = [
    {
        inactive: false,
        dataKey: "Enabled",
        type: "rect",
        color: "#edc949",
        value: "Enabled",
    },
    {
        inactive: false,
        dataKey: "Seen",
        type: "line",
        color: "#edc949",
        value: "Seen",
    },
    {
        inactive: false,
        dataKey: "Tried",
        type: "rect",
        color: "#af7aa1",
        value: "Tried",
    },
    {
        inactive: false,
        dataKey: "Kept",
        type: "rect",
        color: "#af7aa1",
        value: "Kept",
    },
];

export const AIScorecardChartFlyout = ({
    isPanelOpen,
    togglePanel,
    feature,
    capability,
    filters,
}: AIScorecardFlyoutPropsType) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [chartData, setChartData] = useState<CopilotWeeklyChartType[]>([]);
    const [legendProps, setLegendProps] =
        useState<LegendPayloadType[]>(legendPayload);

    useEffect(() => {
        const getChartData = async () => {
            setLoading(true);
            try {
                filters["feature"] = feature;
                filters["capability"] = capability;
                const data = await fetchCopilotData(filters, "aiScorecardChart");
                setChartData(formatChartData(data));
                setLoading(false);
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ConsumerScorecardProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Consumer growth scorecard data",
                    },
                    e,
                );
                setError(e.message);
                setLoading(false);
                return;
            }
        };
        getChartData();

        // ADO 7955411: Complex dependency requires deep validation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickMetricFromLegend = (e) => {
        setLegendProps(
            legendProps.map((legend) =>
                legend.dataKey === e.dataKey
                    ? { ...legend, inactive: !legend.inactive }
                    : legend,
            ),
        );
    };

    const numberFormat = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
    });

    const customToolTipContent = (value): string => {
        const formattedValue = numberFormat.format(value);
        return `${formattedValue}`;
    };

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Panel
            isLightDismiss
            isOpen={isPanelOpen}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                sendAIScorecardUserActionTelemetryEvent({
                    userActionName: "CopilotMetricTrendsTrendsFlyoutClose",
                    commandSurface: UserActionCommandSurface.Canvas,
                });
                togglePanel();
            }}
            closeButtonAriaLabel="Close"
            headerText="Metric trends"
            headerClassName="flyoutHeader"
        >
            {loading ? (
                "Loading"
            ) : chartData?.length !== 0 ? (
                <div>
                    <span>App - {filters.application}</span>
                    <br />
                    {capability} (verb) - {feature} (feature)
                    <ComposedChart
                        width={650}
                        height={470}
                        data={chartData}
                        className="growthTrendsChart"
                    >
                        <XAxis dataKey="Date" />
                        <YAxis
                            yAxisId="left"
                            unit=""
                            allowDecimals={true}
                            domain={[0, "auto"]}
                        />
                        <Tooltip formatter={customToolTipContent} />
                        <Legend
                            onClick={clickMetricFromLegend}
                            wrapperStyle={{
                                paddingTop: "15px",
                            }}
                        />
                        {Object.keys(chartFlyoutBarsDisplayNames).map(
                            (barField, index) => (
                                <>
                                    <Bar
                                        key={barField}
                                        dataKey={barField}
                                        name={
                                            chartFlyoutBarsDisplayNames[barField]
                                                .DisplayName
                                        }
                                        hide={
                                            legendProps.find(
                                                (metric) =>
                                                    metric.dataKey === barField,
                                            ).inactive
                                        }
                                        fill={getChartColor(index)}
                                        yAxisId="left"
                                    />
                                </>
                            ),
                        )}
                        {/* {Object.keys(chartFlyoutLinesDisplayNames).map(
                            (lineField, index) => (
                                <>
                                    <Line
                                        key={lineField}
                                        type="monotone"
                                        name={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .DisplayName
                                        }
                                        dataKey={lineField}
                                        yAxisId="left"
                                        dot={false}
                                        stroke={getChartColor(index)}
                                        strokeWidth={2}
                                    />
                                    <Line
                                        key={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalField
                                        }
                                        type="monotone"
                                        name={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalFieldDisplay
                                        }
                                        dataKey={
                                            chartFlyoutLinesDisplayNames[lineField]
                                                .GoalField
                                        }
                                        yAxisId="left"
                                        dot={false}
                                        stroke={getChartColor(index)}
                                        strokeDasharray="2 2"
                                        strokeWidth={2}
                                        isAnimationActive={false}
                                    />
                                </>
                            )
                        )} */}
                    </ComposedChart>
                </div>
            ) : (
                <div>
                    <EmptyTableContainer />
                </div>
            )}
        </Panel>
    );
};
