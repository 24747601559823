export const win32SparkLineScores: string = `
//win32LineScores (To display the trend for each metric, on tiles)
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
HealthyTenantScore_Win32Overview | project-rename Tpid = TopParentId
| where iff("\${level}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName} =="\${id}" and Application == "\${app}" and todatetime(Date) <= todatetime("\${date}")
|extend Engagement =round(Usage_PercMau5DayUsage_Score,2),
        Currency = round(Currency_InSupport_Score,2),
        OCV = Feedback_Score,
        Watson= round(Quality_Watson_Score,2),
        UAE = round(Quality_UAE_Score,2),
        PerfP95=Perf_BootTimeP95_Score,
        BootThreshold=round(Perf_BootPercentageUnderNs_Score,2),PerfP95_Color = GetColor(Perf_Boot_Color),BootThreshold_Color = GetColor(Perf_Boot_Color)
| project Date, Engagement, Engagement_Color = GetColor(Usage_PercMau5DayUsage_Color), 
    Currency,Currency_Color=GetColor(Currency_InSupport_Color),OCV, OCV_Color = GetColor(Feedback_Color),
    Watson,Watson_Color=GetColor(Quality_Watson_Color),UAE,UAE_Color=GetColor(Quality_UAE_Color),
    PerfP95,PerfP95_Color,  BootThreshold, BootThreshold_Color
| sort by Date asc;`;
