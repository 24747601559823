import { IDropdownOption, IStackItemStyles } from "@fluentui/react";
import { Scenario } from "pages/Performance/types";

export const DataPointsLabel = "Data points";
export const PartitionByLabel = "Partition by";

const country = "Country";
const OSVersion = "OsVersionName";
export const DominantFork = "DominantFork";
const vmType = "VmType";
const deviceManagement = "DeviceManagement";
export const DeviceCount = "Device Count";
export const EventCount = "Event Count";
export const Fork = "Fork";

export const dataPointsOptions: IDropdownOption[] = [
    { key: "7Day", text: "7-Day Aggregates" },
    { key: "Monthly", text: "Monthly Aggregates" },
];

export const partitionByOptions: IDropdownOption[] = [
    { key: "", text: "Dominant Fork" },
    { key: Fork, text: Fork },
    { key: OSVersion, text: "OS Version" },
    { key: vmType, text: "Virtual Machine Type" },
    { key: deviceManagement, text: "Device Management" },
    { key: country, text: country },
];

export const valuesToShowOptions: IDropdownOption[] = [
    { key: "TypicalDuration", text: "Typical Duration" },
    { key: "P25Duration", text: "P25 Duration" },
    { key: "P50Duration", text: "P50 Duration" },
    { key: "P75Duration", text: "P75 Duration" },
    { key: "P90Duration", text: "P90 Duration" },
    { key: "P95Duration", text: "P95 Duration" },
    { key: "PercentBelowThreshold", text: "% Below Threshold" },
];

export const bootLaunch: Scenario = "Boot-Launch";
export const bootFile: Scenario = "Boot-File";
export const fileOpenLocal: Scenario = "FileOpen-Local";
export const fileOpenOnline: Scenario = "FileOpen-SharePointOnline";

export const scenarioOptions: IDropdownOption[] = [
    { key: "Boot-Launch", text: "Boot-Launch" },
    { key: "Boot-File", text: "Boot-File" },
    { key: "Boot-Overall", text: "Boot-Overall" },
];

export const kustoToUIDimensionMap = {
    OsVersionName: "OS Version",
    Country: "Country",
    Fork: "Fork",
    VmType: "VM Type",
    DeviceManagement: "Device Management",
};

export const perfChartStackItemStyles: IStackItemStyles = {
    root: {
        textAlign: "center",
    },
};
