import React from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { UnhealthyTenantMoverShakerProps } from "pages/MTR/types";

const renderMovers = (count: number, prevCount: number) => {
    return (
        <Stack.Item>
            <p className="text">
                +{count}
                {count === 1 ? " tenant has " : " tenants have "}
                moved out of unhealthy state <strong>this month</strong>
            </p>
            <p className="subtext">
                +{prevCount}
                {prevCount === 1 ? " tenant has " : " tenants have "}
                moved out of unhealthy state <strong>last month</strong>
            </p>
        </Stack.Item>
    );
};

const renderShakers = (count: number, prevCount: number) => {
    return (
        <Stack.Item>
            <p className="text">
                +{count}
                {count === 1 ? " tenant has " : " tenants have "}
                moved into unhealthy state <strong>this month</strong>
            </p>
            <p className="subtext">
                +{prevCount}
                {prevCount === 1 ? " tenant has " : " tenants have "}
                moved into unhealthy state <strong>last month</strong>
            </p>
        </Stack.Item>
    );
};

export const UnhealthyTenantMoverShaker = ({
    payload,
    loading,
}: UnhealthyTenantMoverShakerProps) => {
    const data = {
        MoverCount: 0,
        ShakerCount: 0,
        PreviousMoverCount: 0,
        PreviousShakerCount: 0,
    };

    if (!loading && payload) {
        data.MoverCount = payload[0];
        data.ShakerCount = payload[1];
        data.PreviousMoverCount = payload[2];
        data.PreviousShakerCount = payload[3];
    }

    return (
        <div className="commonPageBlock">
            <Stack>
                <p className="commonPageBlockTitle">Unhealthy Tenants this month</p>
            </Stack>

            {loading || !payload ? (
                <ShimmeredCardBody />
            ) : (
                <Stack>
                    <div className="activeWarning">ACTIVE WARNING</div>
                    {renderShakers(data.ShakerCount, data.PreviousShakerCount)}
                    {renderMovers(data.MoverCount, data.PreviousMoverCount)}
                </Stack>
            )}
        </div>
    );
};
