import React from "react";
import {
    Shimmer,
    Stack,
    IStackItemStyles,
    ShimmerElementType,
} from "@fluentui/react";

export const ShimmeredFunnel = ({ minHeight, minWidth }) => {
    const headerSize = 80;
    const footerSize = 200;
    const paddingSize = 10;
    const headerFooterMargin = 20;

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: `${paddingSize}px`,
            borderRadius: "14px",
            overflowX: "auto",
            minHeight: `${minHeight}px`,
        },
    };

    const shimmerChartHeader = [
        {
            type: ShimmerElementType.line,
            height: headerSize,
        },
    ];

    const shimmerCharFooter = [
        {
            type: ShimmerElementType.line,
            height: footerSize,
        },
    ];

    const shimmerChartBody = [
        {
            type: ShimmerElementType.line,
            height:
                minHeight -
                headerSize -
                footerSize -
                paddingSize * 2 -
                headerFooterMargin * 2 -
                10, // just add a little buffer
        },
    ];

    return (
        <Stack styles={stackItemStyles}>
            <Shimmer
                width={minWidth}
                shimmerElements={shimmerChartHeader}
                style={{ marginBottom: `${headerFooterMargin}px` }}
            />
            <Shimmer width={minWidth} shimmerElements={shimmerChartBody} />
            <Shimmer
                width={minWidth}
                shimmerElements={shimmerCharFooter}
                style={{ marginTop: `${headerFooterMargin}px` }}
            />
        </Stack>
    );
};
