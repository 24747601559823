import React, { useEffect, useState } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { DefaultButton, Stack } from "@fluentui/react";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { LinkIconButton } from "components/CustomComponents/LinkIconButton/LinkIconButton";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { getPayloadData } from "components/TenantDrilldownComponents/SecurityVulnerabilities/apiHandler";
import { aggregationTypes } from "components/TenantDrilldownComponents/SecurityVulnerabilities/constants";
import { SecurityVulnerabilitiesState } from "components/TenantDrilldownComponents/SecurityVulnerabilities/types";
import {
    buttonStyle,
    chartSizes,
    chartXAxisPaddingStyles,
    filterStackTokens,
    lineChartTooltipStyles,
} from "pages/common";
import { getChartColor } from "utils/Helpers";
import { InfoLinks } from "utils/Links";
import { SECURITY_VULNERABILITIES_MESSAGES } from "utils/Messages";

const initialState: SecurityVulnerabilitiesState = {
    payload: [],
    loading: true,
};

export const VulnerabilityAggregationButtons = ({
    selectedAggregation,
    aggregationChangeHandler,
}) => {
    return (
        <Stack
            horizontal
            tokens={filterStackTokens}
            data-testid="securityVulnerabilitiesAggregationButtons"
        >
            {Object.keys(aggregationTypes).map((agg) => {
                return (
                    <DefaultButton
                        text={agg}
                        onClick={() => aggregationChangeHandler(agg)}
                        checked={selectedAggregation === agg}
                        styles={buttonStyle}
                        key={agg}
                    />
                );
            })}
        </Stack>
    );
};

export const SecurityVulnerabilities = ({ filters }) => {
    const tenantId = filters.id;
    const level = filters.level;

    const [data, setData] = useState<SecurityVulnerabilitiesState>(initialState);
    const [aggregation, setAggregation] = useState<string>(
        Object.keys(aggregationTypes)[0],
    );

    useEffect(() => {
        if (tenantId) {
            getPayloadData({ id: tenantId, level }, setData);
        }
    }, [tenantId, level]);

    return (
        <>
            <h2
                className="commonPageBlockTitle"
                data-testid="securityVulnerabilitiesHeader"
            >
                Security Vulnerabilities
                <LinkIconButton
                    link={InfoLinks.SecurityVulnerabilities}
                    message={SECURITY_VULNERABILITIES_MESSAGES.READ_MORE}
                />
            </h2>
            {data.loading ? (
                <ShimmeredCardBody />
            ) : data.payload.length !== 0 ? (
                <>
                    <LineChart
                        width={chartSizes.width}
                        height={chartSizes.height}
                        data={data.payload}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" padding={chartXAxisPaddingStyles} />
                        <YAxis
                            label={{
                                value: aggregationTypes[aggregation].axisTitle,
                                angle: -90,
                                dx: -25,
                            }}
                            tickFormatter={(value) =>
                                new Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    compactDisplay: "short",
                                }).format(value)
                            }
                        />
                        <Tooltip
                            labelStyle={lineChartTooltipStyles.labelStyle}
                            itemStyle={lineChartTooltipStyles.itemStyle}
                        />
                        <Legend />
                        <Line
                            name="Vulnerabilities"
                            type="monotone"
                            dataKey={aggregationTypes[aggregation].dataKey}
                            stroke={getChartColor(0)}
                        />
                    </LineChart>
                    <VulnerabilityAggregationButtons
                        selectedAggregation={aggregation}
                        aggregationChangeHandler={setAggregation}
                    />
                </>
            ) : (
                <EmptyTableContainer />
            )}
        </>
    );
};
