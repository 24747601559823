import format from "date-fns/format";
import * as API from "api";
import { NotesCosmosDbAccess } from "api/CosmosDbAccess";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType, MetricsResponseType, table } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { mauCountThreshold, OrderedStatusList } from "utils/Constants";
import { colorMap, CROSSPLATFORM_INDICES } from "utils/CrossPlatformConstants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import {
    calculateOffset,
    formatDateOptions,
    formatStatusColors,
    isTenantExempt,
} from "utils/Helpers";
import { GENERIC_BANNER_MESSAGES } from "utils/Messages";
import { workLoadCrossAppColumnsToExclude } from "utils/Win32Constants";
import { ExecOverviewState } from "./types";

export const getExecOverviewTableRows = (
    execOverviewTable,
    dateFilter,
    dateOptions,
    platform,
) => {
    if (platform === Workload.CROSSPLATFORM) {
        return formatCrossPlatformCompositeScore(execOverviewTable);
    }
    const execOverviewRows = [];
    let offset: number = getOffset(platform, dateFilter, dateOptions);

    execOverviewTable.Rows.forEach((row) => {
        const dict = {};

        row.forEach((val, idx) => {
            const colName = execOverviewTable.Columns[idx].ColumnName;
            dict[colName] = val;
        });

        dict["PastStatus"] = getFormattedStatus(
            platform,
            dict["PastStatus"],
            offset,
        );

        execOverviewRows.push(dict);
    });
    return execOverviewRows;
};

export const getExecOverviewRowHeaders = (
    execOverviewRows,
    execOverviewScoreTable,
    application,
    platform,
) => {
    const headersFromTable = execOverviewScoreTable.Columns.map(
        (x) => x.ColumnName,
    ).filter((x) =>
        application in workLoadCrossAppColumnsToExclude
            ? !workLoadCrossAppColumnsToExclude[application].includes(x)
            : true,
    );

    const extraColumns = getExtraColumns(platform);
    const execOverviewRowHeaders = {
        rows: execOverviewRows,
        headers: [...headersFromTable, ...extraColumns],
    };
    return execOverviewRowHeaders;
};

export const formatWorkloadCompositeScore = (
    compositeScoreJson: MetricsResponseType,
    dateFilter: string,
    dateOptions: string[],
    application: string,
    platform: string,
): table => {
    const compositeScoreTable = compositeScoreJson.Tables[0];
    const compositeScoreRows = getExecOverviewTableRows(
        compositeScoreTable,
        dateFilter,
        dateOptions,
        platform,
    );
    const compositeScore = getExecOverviewRowHeaders(
        compositeScoreRows,
        compositeScoreTable,
        application,
        platform,
    );

    return compositeScore;
};

const isColorWorse = (color1, color2) => {
    return Number(OrderedStatusList[color1]) > Number(OrderedStatusList[color2]);
};

const isColorBetter = (color1, color2) => {
    return Number(OrderedStatusList[color1]) < Number(OrderedStatusList[color2]);
};

export const handleIndividualAppColorChanges = (rows) => {
    rows.forEach((row) => {
        const movedToRedOrDeepRed =
            row["CurrentStatus"] === "Red" || row["CurrentStatus"] === "DeepRed";

        Object.keys(row).forEach((metric) => {
            if (metric.endsWith("_AppColor")) {
                const currentAppColor = row[metric];
                const previousAppColor = row[metric.concat("_Prev")];

                if (
                    currentAppColor === "Gray" ||
                    currentAppColor === "" ||
                    previousAppColor === "Gray" ||
                    previousAppColor === ""
                ) {
                    row[metric] = "";
                } else if (movedToRedOrDeepRed) {
                    if (currentAppColor !== "Regression") {
                        row[metric] = isColorWorse(currentAppColor, previousAppColor)
                            ? currentAppColor
                            : "";
                    }
                } else {
                    row[metric] = isColorBetter(currentAppColor, previousAppColor)
                        ? currentAppColor
                        : "";
                }
            }
        });
    });
};

export const formatWorkloadTenantStatus = (
    tenantStatusJson: MetricsResponseType,
    dateFilter: string,
    dateOptions: string[],
    application: string,
    platform: string,
): table => {
    const tenantTable = tenantStatusJson.Tables[0];

    let tenantTableRows = [];

    if (platform === Workload.CROSSPLATFORM) {
        tenantTableRows = formatCrossPlatformTenantStatus(tenantTable);
    } else {
        tenantTableRows = getExecOverviewTableRows(
            tenantTable,
            dateFilter,
            dateOptions,
            platform,
        );

        handleIndividualAppColorChanges(tenantTableRows);
    }

    const tenants = getExecOverviewRowHeaders(
        tenantTableRows,
        tenantTable,
        application,
        platform,
    );
    return tenants;
};

export const formatWorkloadHealthData = (healthChartJson: MetricsResponseType) => {
    const healthChartTable = healthChartJson.Tables[0];
    const healthChartData = {
        headers: healthChartTable.Columns.map((x: any) => x.ColumnName),
        rows: healthChartTable.Rows,
    };
    return { healthChartData };
};

export const formatPlatformSignal = (platformSignalJson) => {
    const columnNamesMap = {};
    platformSignalJson.Tables[0].Rows.forEach((row) => {
        columnNamesMap[row[0]] = {
            name: row[1],
            description: row.length > 3 ? row[3] : row[1], // if description is found add it, else reuse the name
        };
    });

    return columnNamesMap;
};

export const formatWorkloadOverviewData = (
    compositeScoreJson: MetricsResponseType,
    tenantStatusJson: MetricsResponseType,
    dateFilter: string,
    dateOptions: string[],
    application: string,
    platform: string,
): ExecOverviewState => {
    try {
        const compositeScore = formatWorkloadCompositeScore(
            compositeScoreJson,
            dateFilter,
            dateOptions,
            application,
            platform,
        );
        const tenantStatus = formatWorkloadTenantStatus(
            tenantStatusJson,
            dateFilter,
            dateOptions,
            application,
            platform,
        );
        return { compositeScore, tenantStatus, loading: false };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewDataFormattingFailed",
            ),
            {
                message:
                    "Formatting failed for the responses recieved for Exec Scorecard page",
                responses: {
                    compositeScore: compositeScoreJson,
                    tenantStatus: tenantStatusJson,
                },
            },
        );
        throw error;
    }
};

export const setDatePickerOptions = async (
    platform,
    setDateFilter,
    setDateOptions,
    setTableNameMap: any,
    setError,
    setData,
) => {
    try {
        const [dates, platformSignal] = await Promise.all([
            API.dateFilters(platform),
            API.platformSignal(platform),
        ]);
        try {
            const responseData = formatDateOptions(dates?.data);
            const tableNameMap = formatPlatformSignal(platformSignal?.data);
            setDateOptions(responseData);
            setDateFilter(responseData[0]["key"]);
            setTableNameMap(tableNameMap);
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "ExecOverviewDatesOrConfigProcessingFailed",
                ),
                {
                    message:
                        "Failure processing dates/table meta data fetched from kusto for Exec Overview Page",
                    dates: dates.status,
                    platformSignal: platformSignal.status,
                },
                error,
            );
            throw error;
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewDatesOrConfigFetchFailed",
            ),
            {
                message:
                    "Failure in fetching dates/table meta data for Exec Overview Page from Kusto",
            },
            error,
        );
        setData((data) => {
            return { ...data, loading: false };
        });
        setError(GENERIC_BANNER_MESSAGES.TENANT_INFO_DISPLAY_ERROR);
    }
};

const formatTenantListSuccess = (
    tenantJson: KustoResponseType<string>,
    workload: string,
) => {
    const tenantList = tenantJson.Tables[0];

    const rows: any[] = [];
    tenantList.Rows.forEach((element) => {
        rows.push({
            Tpid: element[0],
            OrganizationName: element[1],
            TenantId: element[2],
            TenantName: element[3],
            TpidStatus: element[4],
            OmsStatus: element[5],
            IsS500: element[6],
            IsS2500: element[7],
            IsEPA: element[8],
            IsGoogleMove: element[9],
            IsGov: element[10],
            TenantName_Translated: element[11],
            Show: true,
        });
    });

    return {
        headerName: workload,
        headers: tenantList.Columns.map((x: any) => x.ColumnName),
        rows,
        workload,
    };
};

export const setTenantsForSearch = async (
    dateFilter: string,
    setTenantsData,
    setError,
) => {
    try {
        const queryParams: {} = {};
        if (!dateFilter) {
            return;
        }

        const date = Date.parse(`${dateFilter}T00:00:00`);
        queryParams["date"] = format(date, `yyyy-MM-01`);
        queryParams["mauCountThreshold"] = mauCountThreshold;

        const queryParamsWithModifiedDate: {} = {};
        queryParamsWithModifiedDate["date"] = format(date, `yyyy-MM-28`);
        const [webTenants, win32Tenants, macTenants] = await Promise.all([
            API.tenantList(queryParamsWithModifiedDate, Workload.WEB),
            API.tenantList(queryParamsWithModifiedDate, Workload.WIN32),
            API.tenantList(queryParamsWithModifiedDate, Workload.MAC),
        ]);
        setTenantsData({
            webTenants: formatTenantListSuccess(webTenants, Workload.WEB),
            win32Tenants: formatTenantListSuccess(win32Tenants, Workload.WIN32),
            macTenants: formatTenantListSuccess(macTenants, Workload.MAC),
        });
    } catch (error) {
        setError(GENERIC_BANNER_MESSAGES.TENANT_INFO_DISPLAY_ERROR);
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewTenantListFetchFailed",
            ),
            {
                message:
                    "Failure getting tenant list for the search functionality on ExecOverview page",
            },
            error,
        );
    }
};

export const fetchData = async (
    platform: string,
    filters: any,
    setData: any,
    dateFilter: string,
    dateOptions: any[],
    setError: any,
) => {
    try {
        let queryParams: {} = {};
        const levelQueryParams = API.getQueryParamsForLevel(filters["level"]);
        queryParams["application"] =
            filters["rankOn"] === "Office" ? "Base" : filters["rankOn"];
        queryParams["mauCountThreshold"] = filters["minMau"];
        queryParams["cohort"] = filters["cohort"];
        queryParams["date"] = dateFilter;
        queryParams = { ...levelQueryParams, ...queryParams };

        const [compositeScore, tenantStatus] = await Promise.all([
            API.compositeScore(queryParams, platform),
            API.tenantStatus(queryParams, platform),
        ]);
        try {
            const responseData = formatWorkloadOverviewData(
                compositeScore?.data,
                tenantStatus?.data,
                dateFilter,
                dateOptions,
                filters["rankOn"],
                platform,
            );
            setData(responseData);
            fetchNotes(platform, responseData, setData);
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV2,
                    "ExecOverviewDataProcessingFailed",
                ),
                {
                    message:
                        "Failure processing response data from Exec Overview page queries",
                    responseCodes: {
                        compositeScore: compositeScore.status,
                        tenantStatus: tenantStatus.status,
                    },
                },
                error,
            );
            setData((data) => {
                return { ...data, loading: false };
            });
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewDataFetchFailed",
            ),
            {
                message: "Failure in fetching data for Exec Overview page",
            },
            error,
        );
        setData((data) => {
            return { ...data, loading: false };
        });
        setError(GENERIC_BANNER_MESSAGES.TENANT_INFO_DISPLAY_ERROR);
    }
};

const fetchNotes = async (platform: string, data: {}, setData: any) => {
    try {
        const allNotes = await new NotesCosmosDbAccess().getTenantExemptionNotes(
            platform,
        );
        const enrichedData = enrichDataWithNotes(data, allNotes, platform);
        setData(enrichedData);
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewExemptionNotesFetchFailed",
            ),
            {
                message: "Failure in fetching exemption notes from cosmos db",
                platform,
            },
            error,
        );
    }
};

export const enrichDataWithNotes = (
    data: any,
    allNotes: any[],
    platform: string,
) => {
    try {
        let notesMap = {};
        allNotes.forEach((note) => {
            if ("Tpid" in note && "OmsTenantId" in note) {
                const tpid = note["Tpid"];
                const tenantId = note["OmsTenantId"];

                if (tpid in notesMap) {
                    // we already have an entry for this tpid
                    let tenantToNotesMap = notesMap[tpid];
                    if (tenantId in tenantToNotesMap) {
                        if (tenantToNotesMap[tenantId]._ts < note._ts) {
                            notesMap[tpid][tenantId] = note;
                        }
                    } else {
                        notesMap[tpid][tenantId] = note;
                    }
                } else {
                    let tenantIdToNotesMap = {};
                    tenantIdToNotesMap[tenantId] = note;
                    notesMap[tpid] = tenantIdToNotesMap;
                }
            }
        });

        const compositeScoreRows = addNotesToTenants(
            data.compositeScore.rows,
            notesMap,
            platform,
        );
        const tenantStatusRows = addNotesToTenants(
            data.tenantStatus.rows,
            notesMap,
            platform,
        );

        return {
            ...data,
            compositeScore: { ...data.compositeScore, rows: compositeScoreRows },
            tenantStatus: { ...data.tenantStatus, rows: tenantStatusRows },
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "ExecOverviewExemptionNotesProcessingFailed",
            ),
            {
                message:
                    "Failure in processing exemption notes from cosmos db @ Exec Overview page",
                data,
                allNotes,
                platform,
            },
            error,
        );
        throw error;
    }
};

const addNotesToTenants = (rows: any[], notesMap: any, platform: string) => {
    const newRows = [];
    rows.forEach((row) => {
        let newRow = row;
        const id = row.Parent?.toString() ?? "";
        const tenantId = row["TenantId"]?.toString() ?? "";
        if (id in notesMap && id !== "") {
            // Add notes if TenantId matches or if the note is at Tpid level
            const note = notesMap[id][tenantId] ?? notesMap[id]["All"];
            if (note) {
                newRow = { ...row };
                newRow["Notes"] = note["Notes"];
                newRow["IsExempted"] = isTenantExempt(note, platform);
                newRow["EntryDate"] = note["Date"];
            }
        }
        newRows.push(newRow);
    });

    return newRows;
};

export const formatCrossPlatformCompositeScore = (compositeScoreTable) => {
    const _compositeScoreRows: any[] = [];

    compositeScoreTable.Rows.forEach((row) => {
        const dict = {};
        row.forEach((val, idx) => {
            const colName = compositeScoreTable.Columns[idx].ColumnName;
            if (colName === "Win32_Issues" || colName === "Web_Issues") {
                if (val) {
                    const platform = colName.split("_")[0];
                    const trailingcomma = /,(?=\s*?[}\]])/g;
                    const payload = JSON.parse(val.replace(trailingcomma, ""));
                    const res = {};
                    payload["apps"].forEach((app) => {
                        const appName = app["name"];
                        app["issues"].forEach((issue) => {
                            const color = issue["color"];
                            if (color === 1 || color === 2 || color === 3) {
                                const type =
                                    issue["type"].charAt(0).toUpperCase() +
                                    issue["type"].slice(1);
                                const col = `${platform}_${type}_AppColor`;
                                if (!(col in res)) res[col] = {};
                                res[col][appName] = color;
                            }
                        });
                    });

                    Object.keys(res).forEach((key) => {
                        let col = 6;
                        Object.keys(res[key]).forEach((app) => {
                            col = Math.min(col, res[key][app]);
                        });
                        dict[key] = colorMap[col];
                    });
                }
            } else {
                dict[colName] = val;
            }
        });
        _compositeScoreRows.push(dict);
    });

    return _compositeScoreRows;
};

export const formatCrossPlatformTenantStatus = (tenantTable) => {
    const _tenantTableRows: any[] = [];
    tenantTable.Rows.forEach((row) => {
        const dict = {};
        const res = {};
        const isPromotor = isColorBetter(
            row[CROSSPLATFORM_INDICES.CURRENTSTATUS],
            row[CROSSPLATFORM_INDICES.PREVIOUSSTATUS],
        );

        row.forEach((val, idx) => {
            const colName = tenantTable.Columns[idx].ColumnName;
            const splitColumnName = colName.split("_");
            const platform = splitColumnName[1];
            const status = splitColumnName[0];

            if (colName.endsWith("_Issues")) {
                if (val) {
                    const trailingcomma = /,(?=\s*?[}\]])/g;
                    const payload = JSON.parse(val.replace(trailingcomma, ""));
                    payload["apps"].forEach((app) => {
                        const appName = app["name"];

                        app["issues"].forEach((issue) => {
                            const color = issue["color"];
                            const type =
                                issue["type"].charAt(0).toUpperCase() +
                                issue["type"].slice(1);

                            if (!(platform in res)) res[platform] = {};
                            if (!(type in res[platform])) res[platform][type] = {};
                            if (!(appName in res[platform][type]))
                                res[platform][type][appName] = {};
                            res[platform][type][appName][status] = color;
                        });
                    });
                }
            } else {
                dict[colName] = val;
            }
        });

        if (isPromotor) {
            Object.keys(res).forEach((platform) => {
                Object.keys(res[platform]).forEach((type) => {
                    let maxPromotor = -1;
                    Object.keys(res[platform][type]).forEach((app) => {
                        if (
                            res[platform][type][app]["Current"] &&
                            res[platform][type][app]["Previous"] &&
                            res[platform][type][app]["Current"] >
                                res[platform][type][app]["Previous"]
                        ) {
                            maxPromotor = Math.max(
                                maxPromotor,
                                res[platform][type][app]["Current"],
                            );
                        }
                    });
                    if (maxPromotor !== -1)
                        dict[`${platform}_${type}_AppColor`] = colorMap[maxPromotor];
                });
            });
        } else {
            Object.keys(res).forEach((platform) => {
                Object.keys(res[platform]).forEach((type) => {
                    let maxDetractor = 6;
                    Object.keys(res[platform][type]).forEach((app) => {
                        if (
                            res[platform][type][app]["Current"] &&
                            res[platform][type][app]["Previous"] &&
                            res[platform][type][app]["Current"] <
                                res[platform][type][app]["Previous"]
                        ) {
                            maxDetractor = Math.min(
                                maxDetractor,
                                res[platform][type][app]["Current"],
                            );
                        }
                    });
                    if (maxDetractor !== 6)
                        dict[`${platform}_${type}_AppColor`] =
                            colorMap[maxDetractor];
                });
            });
        }

        _tenantTableRows.push(dict);
    });

    return _tenantTableRows;
};

const getExtraColumns = (platform) => {
    const extraColumns = [];

    switch (platform) {
        case Workload.CROSSPLATFORM:
            ["Usage", "Performance", "Reliability", "Feedback", "Currency"].forEach(
                (type) => {
                    ["Win32", "Web"].forEach((platform) => {
                        const colName = `${platform}_${type}_AppColor`;
                        extraColumns.push(colName);
                    });
                },
            );
            break;
        default:
            break;
    }

    return extraColumns;
};

const getOffset = (platform, dateFilter, dateOptions): number => {
    switch (platform) {
        case Workload.WEB:
        case Workload.WEB_CONSUMER:
        case Workload.COPILOT_COMMERCIAL:
        case Workload.MAC:
            return 0;
        default:
            return calculateOffset(dateFilter, dateOptions);
    }
};

const getFormattedStatus = (platform, pastStatus, offset) => {
    switch (platform) {
        case Workload.WEB:
        case Workload.WEB_CONSUMER:
        case Workload.COPILOT_COMMERCIAL:
        case Workload.TEAMS:
            return pastStatus;
        default:
            return formatStatusColors(pastStatus, offset);
    }
};
