import React from "react";
import { Shimmer, Stack } from "@fluentui/react";
import PerformanceLineChart from "components/CustomComponents/PerformanceLineChart";
import { LegendEntry } from "pages/commonTypes";
import { perfChartStackItemStyles } from "pages/Performance/PerformanceConstants";
import { ChartData } from "pages/Performance/types";

const labels: LegendEntry[] = [
    {
        key: "All add-ins",
        Name: "All add-ins",
        color: "black",
        hidden: false,
    },
];

export const AllAddInCostHistoryChart = ({ data }: { data: ChartData[] }) => {
    return (
        <Shimmer className="scenarioChart" isDataLoaded={data !== undefined}>
            <Stack styles={perfChartStackItemStyles}>
                <h3 className="scenarioChartHeader">
                    Typical boot load cost (all add-ins)
                </h3>
                <PerformanceLineChart yUnit="s" values={data} labels={labels} />
            </Stack>
        </Shimmer>
    );
};
