import React from "react";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import { CompositeFunnelHeaderProps } from "components/ConsumerDay0FunnelComponents/CompositeFunnelHeader/types";
import "components/ConsumerDay0FunnelComponents/CompositeFunnelHeader/style.css";
import { ShortenedFunnelStageNames } from "pages/ConsumerDay0Funnel/constants";

export const CompositeFunnelHeader = ({
    funnelStageData,
    stageName,
}: CompositeFunnelHeaderProps) => {
    return (
        <div className="compositeFunnelHeaderContainer">
            <div className="compositeFunnelHeaderStageName">
                {ShortenedFunnelStageNames[stageName]}
            </div>
            <div>
                <span className="compositeFunnelHeaderValue">
                    {getCompactValue(funnelStageData.value)}
                </span>
                {` [${funnelStageData.percentage}%]`}
            </div>
        </div>
    );
};
