export const consumerScorecardMetrics = `
// Consumer score card metrics
set query_results_cache_max_age = time(12h);
let pltfm = "Web";
let host  = "OneDriveWOPI";
let maxProcessDateForCohortEndDate = database('ConsumerInsights').EarlyRetentionMetrics
| summarize MaxProcessDate = max(ProcessDate) by CohortEndDate
| join kind=inner (database('ConsumerInsights').EarlyRetentionMetrics | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and UserSeenType == "New") on $left.CohortEndDate == $right.CohortEndDate, $left.MaxProcessDate == $right.ProcessDate
| project ProcessDate, CohortEndDate, Application, DeviceType, Referrer, UiHost, Host, Platform, OperatingSystem, LicenseModel, UserSeenType, M1R, M3R, M1R_YoY, M3R_YoY;
database('ConsumerInsights').Usage
| where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and Referrer == "All"
| where Platform == pltfm and Host in ("OneDriveWOPI", "SharePoint Online Consumer", "ODSP Converged") and LicenseModel =="Free"
| union ( // OD Paid, All
    database('ConsumerInsights').Usage
    | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and Referrer == "All"
    | where Platform == pltfm and Host in ("OneDriveWOPI", "SharePoint Online Consumer", "ODSP Converged") and LicenseModel in ("Paid", "All") and UserSeenType == "All"
    )
| union ( // outlook Host and SharePointOnlineConsumer host
    database('ConsumerInsights').Usage
    | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and Referrer == "All"
    | where Platform == pltfm and Host == "Exchange Online Consumer" and LicenseModel == "All" and UserSeenType == "All"
    )
| union( // web All
    database('ConsumerInsights').Usage
    | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and Referrer == "All"
    | where Platform == pltfm and Host == "All" and LicenseModel == "All" and UserSeenType == "All"
    )
| union(// Free New Harmony
    database('ConsumerInsights').Usage
    | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and Referrer != "All"
    | where Platform == pltfm and Host in ("OneDriveWOPI", "SharePoint Online Consumer", "ODSP Converged") and LicenseModel =="Free" and UserSeenType == "New"
    )
        | join kind=leftouter(database('ConsumerInsights').FYGoals_withReferrer)
            on Application, Host, LicenseModel, UserSeenType, Referrer
        | project Date, Application, Segment = strcat_delim(" ", Host, LicenseModel, UserSeenType, Referrer), MAU, MAU_YoY, MAU_Goal, PercentCAMAU, PercentCAMAU_YoY, PercentCAMAU_Goal, CAMAU, CAMMAU_YoY, CAMAU_Goal, CAMAUDay0_Goal
        | join kind=leftouter 
            (database('ConsumerInsights').CamauPctAtDay0
            | where Host == host
            | where CohortName in ("Free All", "Free New", "Free Returning", "OneDrive All", "Paid All", "Free New Harmony All", "Free New Harmony App Start Pages", "Free New Harmony Home Page", "Free New Harmony Other", "Free New OneDrive", "Free New Outlook", "Free New Other")
            | extend CohortName = iff(CohortName  == "OneDrive All", "All All", CohortName)
            | extend Segment = iff(CohortName in ("Free New Harmony All", "Free New Harmony App Start Pages", "Free New Harmony Home Page", "Free New Harmony Other", "Free New OneDrive", "Free New Outlook", "Free New Other"), strcat_delim(" ", Host, CohortName), strcat_delim(" ", Host, CohortName, "All")), CAMAUDay0_Pct = Value, CAMAUDay0_YoY = YoY
) on Segment, Date, Application
        | join kind=leftouter (maxProcessDateForCohortEndDate
            | where OperatingSystem == "All" and DeviceType == "All" and UiHost == "All" and UserSeenType == "New"
            | where Platform == pltfm 
            | join kind=inner 
                ( // filter OD returning & all
                database('ConsumerInsights').FYGoals_withReferrer | where UserSeenType != "Returning" 
                    | where LicenseModel != "Free" or UserSeenType != "All"
                ) on Application, Host, LicenseModel
            | project Date = CohortEndDate, Application, Segment = strcat_delim(" ", Host, LicenseModel, UserSeenType1, Referrer), M1R, M1R_YoY, M1Retention_Goal, M3R, M3R_YoY, M3Retention_Goal
        ) on Date, Segment, Application
| project Date, Application, Segment, MAU, MAU_YoY, MAU_Goal, CAMAUDay0_Pct, CAMAUDay0_YoY, CAMAUDay0Goal_Pct=CAMAUDay0_Goal, PercentCAMAU, PercentCAMAU_YoY, PercentCAMAU_Goal, CAMAU, CAMMAU_YoY, CAMAU_Goal, M1R, M1R_YoY, M1Retention_Goal, M3R, M3R_YoY, M3Retention_Goal
| extend DateFormatted = todatetime(Date)
| where DateFormatted > ago(540d) //18 months
| order by Application, Date desc
`;

export const consumerRetentionStatSigMetrics = `
// consumerRetentionStatSigMetrics
let dt = "\${date}";
let application = "\${application}";
let dateAsDate = todatetime(dt);
let getDateforRetention =  datetime_add('day', -28, dateAsDate); // cohort end date for the given Process date. cohort end date - 2023-11-04
let getHighestProcessDate = database('ConsumerInsights').EarlyRetentionMetrics| where todatetime(CohortEndDate) == getDateforRetention | summarize maxProcessDate = max(ProcessDate) by getDateforRetention | project maxProcessDate;
let m3MauOneYearAgo = database('ConsumerInsights').Usage | where todatetime(Date) == datetime_add('week', -64, dateAsDate) and Application == application | project M3Mau_LastYear = MAU, Application, Platform, Host, LicenseModel, UserSeenType, Referrer;
let m3Mau = database('ConsumerInsights').Usage | where todatetime(Date) == datetime_add('week', -12, dateAsDate) and Application == application | project M3Mau = MAU, Application, Platform, Host, LicenseModel, UserSeenType, Referrer;
let m1MauOneYearAgo = database('ConsumerInsights').Usage | where todatetime(Date) == datetime_add('week', -56, dateAsDate) and Application == application | project M1Mau_LastYear = MAU, Application, Platform, Host, LicenseModel, UserSeenType, Referrer;
let m1Mau= database('ConsumerInsights').Usage | where todatetime(Date) == datetime_add('week', -4, dateAsDate) and Application == application | project M1Mau = MAU, Application, Platform, Host, LicenseModel, UserSeenType, Referrer;
database('ConsumerInsights').EarlyRetentionMetrics
| where Application == application and todatetime(CohortEndDate) == todatetime(getDateforRetention) and todatetime(ProcessDate) == todatetime(toscalar(getHighestProcessDate))
| join kind=leftouter (m3MauOneYearAgo) on $left.Application == $right.Application and $left.Host == $right.Host and $left.LicenseModel == $right.LicenseModel and $left.UserSeenType == $right.UserSeenType and $left.Referrer == $right.Referrer
| join kind=leftouter (m3Mau) on $left.Application == $right.Application and $left.Host == $right.Host and $left.LicenseModel == $right.LicenseModel and $left.UserSeenType == $right.UserSeenType and $left.Referrer == $right.Referrer
| join kind=leftouter (m1MauOneYearAgo) on $left.Application == $right.Application and $left.Host == $right.Host and $left.LicenseModel == $right.LicenseModel and $left.UserSeenType == $right.UserSeenType and $left.Referrer == $right.Referrer
| join kind=leftouter (m1Mau) on $left.Application == $right.Application and $left.Host == $right.Host and $left.LicenseModel == $right.LicenseModel and $left.UserSeenType == $right.UserSeenType and $left.Referrer == $right.Referrer
| extend M1R=todouble(M1R), M3R=todouble(M3R), M1R_YoY=todouble(M1R_YoY), M3R_YoY=todouble(M3R_YoY)
| extend UserSeenType=iff(Host == "OneDriveWOPI" and LicenseModel == "Free", UserSeenType, "All")
| project Segment=strcat_delim(" ", Host, LicenseModel, UserSeenType, Referrer), M1R, M3R, M1R_LastYear=M1R-M1R_YoY, M3R_LastYear=M3R-M3R_YoY, M1Mau, M3Mau, M1Mau_LastYear, M3Mau_LastYear
`;

export const consumerMauCamauStatSigMetrics = `
// consumerMauCamauStatSigMetrics
let dt = "\${date}";
let application = "\${application}";
let dateAsDate = todatetime(dt);
let zScoreThreshold = 1.96;
let stdevMauCamau =
database('ConsumerInsights').Usage 
| where todatetime(Date) between(datetime_add('week', -52, dateAsDate) .. datetime_add('week', -1, dateAsDate)) and Application == application
| extend Segment=strcat_delim(" ", Host, LicenseModel, UserSeenType, Referrer)
| summarize StdevMau=stdev(MAU_YoY), StdevCamau=stdev(CAMMAU_YoY), MeanMau=avg(MAU_YoY), MeanCamau=avg(CAMMAU_YoY) by Segment;
database('ConsumerInsights').Usage
| where Date == dt and Application == application
| extend Segment=strcat_delim(" ", Host, LicenseModel, UserSeenType, Referrer)
| join kind=leftouter (stdevMauCamau) on $left.Segment == $right.Segment
| extend MauZScore=abs((MAU_YoY - MeanMau)/StdevMau)
| extend CaumauZScore=abs((CAMMAU_YoY - MeanCamau)/StdevCamau)
| extend isMauStatSig = MauZScore >= zScoreThreshold, isCamauStatSig = CaumauZScore >= zScoreThreshold
| project Segment, isMauStatSig, isCamauStatSig
| where isMauStatSig or isCamauStatSig
`;
