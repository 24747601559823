import { format as stringFormat } from "util";
import { ILineChartPoints } from "@fluentui/react-charting";
import {
    AddInHighCrashRateByAppThreshold,
    AddInHighLoadTimeInSecondsByAppThreshold,
    getAdvisoriesFlyoutPanelColors,
    getFormattedMetricValue,
    getMetricSuffix,
    getMetricUnit,
    GroupAddins,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/M365HealthWrapperUtils";
import { format as dateFormat, differenceInDays, isFuture } from "date-fns";
import * as API from "api";
import {
    AddinsAnnotationText,
    AdvisoriesFlyoutDetails,
    AdvisoriesRecommendedActions,
    advisoriesResourceStrings,
    DateResourceStrings,
    DEFAULT_DATE,
    MILLISECONDS_IN_ONE_SECOND,
    SECONDS_IN_ONE_DAY,
    SECONDS_IN_ONE_MONTH,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/messages";
import {
    AdvisoriesCardState,
    AdvisoriesHistoricState,
    LoadingState,
    AdvisoryFilterNames,
    AdvisoryMetricType,
    AdvisorySeverity,
    AdvisoryTrend,
    AdvisoryTypeV2,
    IAppHealthAdvisories,
    OfficeProduct,
    ServicingChannelV2,
    AddInsCardState,
    IAppHealthAddIns,
    AddInsDataState,
    AddInsFilterNames,
} from "components/TenantDrilldownComponents/M365AppsHealthWrapper/types";
import { CustomDetailsItemsType, KustoResponseType } from "pages/commonTypes";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { getChartColor } from "utils/Helpers";

export const fetchProductAdvisories = async (
    setData: React.Dispatch<React.SetStateAction<AdvisoriesCardState>>,
    setLoadingState: React.Dispatch<React.SetStateAction<LoadingState>>,
    setDataItems: React.Dispatch<React.SetStateAction<CustomDetailsItemsType[]>>,
    id: string,
    date: string,
) => {
    try {
        const queryParams = {};
        const dt = Date.parse(`${date}T00:00:00`);
        queryParams["date"] = dateFormat(dt, `yyyy-MM-01`);
        queryParams["tenantId"] = id;

        const appsHealthAdvisories = await Promise.resolve(
            API.getProductAdvisories(queryParams),
        );

        const appsHealthAdvisoriesdata: KustoResponseType<string | number> =
            appsHealthAdvisories?.data;

        const formattedData = formatProductAdvisories(appsHealthAdvisoriesdata);
        setData((oldState) => {
            return {
                ...oldState,
                data: formattedData,
            };
        });
        setDataItems(formattedData);
        setLoadingState({
            isLoadingFailed: false,
            isLoading: false,
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "OfficeProductAdvisoriesFetchFailed",
            ),
            {
                message: "Failure in fetching data for Apps Health Advisories page",
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                error: error.message,
            };
        });
        setLoadingState({
            isLoadingFailed: true,
            isLoading: false,
        });
    }
};

export const formatProductAdvisories = (
    data: KustoResponseType<string | number>,
): CustomDetailsItemsType[] => {
    const dataTable = data.Tables[0];
    const kustoData: IAppHealthAdvisories[] = [];

    dataTable.Rows.forEach((element) => {
        const advisoryRow: IAppHealthAdvisories = {
            servicingChannel: ServicingChannelV2[element[0] as string],
            product: OfficeProduct[element[1] as string],
            metricType: AdvisoryMetricType[element[2] as string],
            advisoryType: AdvisoryTypeV2[element[3] as string],
            advisorySeverity: AdvisorySeverity[element[4] as string],
            advisoryTrend: AdvisoryTrend[element[5] as string],
            buildVersion: element[6] as string,
            comparedBuildVersion: element[7] as string,
            releaseVersion: element[8] as string,
            comparedReleaseVersion: (element[9] as string) ?? "", // null in case of performance metrics
            metricValue: element[10] as number,
            comparedMetricValue: element[11] as number,
            monitoredEventCount: element[12] as number,
            createdEpochInSeconds: element[13] as number,
            impactedDevicesCount: element[14] as number,
            availabilityDate: element[15] as string,
            endOfSupportDate: element[16] as string,
            eventCount: element[17] as number,
        };
        kustoData.push(advisoryRow);
    });

    const nowDate: Date = new Date();
    const nowEpochInSeconds: number = Math.round(
        nowDate.getTime() / MILLISECONDS_IN_ONE_SECOND,
    );
    const items: CustomDetailsItemsType[] = [];

    kustoData.forEach((row) => {
        const rowItem: CustomDetailsItemsType = {
            Message: resolveAdvisoriesMessage(row),
            Product: row.product,
            Channel: row.servicingChannel,
            Build: `${row.releaseVersion} (${row.buildVersion})`,
            BuildVersion: row.buildVersion,
            Severity: `${row.advisorySeverity} ${row.advisoryTrend}`,
            CreatedDate: resolveCreatedDate(
                row.createdEpochInSeconds,
                nowEpochInSeconds,
            ),
            [AdvisoryFilterNames.Metric]: row.metricType,
            [AdvisoryFilterNames.Trend]: row.advisoryTrend,
            [AdvisoryFilterNames.Type]: row.advisorySeverity,
            DeviceCount: row.impactedDevicesCount,
            AdvisoryType: row.advisoryType,
            ComparedBuild: `${row.comparedReleaseVersion} (${row.comparedBuildVersion})`,
            ComparedBuildVersion: row.comparedBuildVersion,
            CreatedEpochInSeconds: row.createdEpochInSeconds,
            MetricValue: row.metricValue,
            ComparedMetricValue: row.comparedMetricValue,
            Description: resolveAdvisoriesDescription(row),
            RecommendedAction: resolveRecommendedAction(row, nowDate),
            EventCount: row.monitoredEventCount,
        };

        items.push(rowItem);
    });

    return items;
};

export const resolveAdvisoriesMessage = (
    advisoryRow: IAppHealthAdvisories,
): string => {
    const metricDelta: number = Math.abs(
        advisoryRow.comparedMetricValue - advisoryRow.metricValue,
    );
    const metricDeltaFormatted = getFormattedMetricValue(
        advisoryRow.metricType,
        metricDelta,
    );

    const stringTemplate =
        advisoriesResourceStrings[advisoryRow.metricType]?.[
            advisoryRow.advisoryTrend
        ]?.Message?.[advisoryRow.advisoryType];

    if (!stringTemplate) return "";

    return stringFormat(stringTemplate, metricDeltaFormatted);
};

export const resolveCreatedDate = (
    createdEpochInSeconds: number,
    nowEpochInSeconds: number,
): string => {
    if (createdEpochInSeconds <= 0) {
        return "";
    }
    const createdDate: Date = new Date(createdEpochInSeconds * 1000);
    const createdNowDifferenceInSeconds: number =
        nowEpochInSeconds - createdEpochInSeconds;

    if (createdNowDifferenceInSeconds < SECONDS_IN_ONE_MONTH) {
        const createdNowDifferenceInDays = Math.floor(
            createdNowDifferenceInSeconds / SECONDS_IN_ONE_DAY,
        );
        if (createdNowDifferenceInDays === 1) {
            return DateResourceStrings.OneDayAgo;
        }

        return stringFormat(
            DateResourceStrings.NDaysAgo,
            createdNowDifferenceInDays,
        );
    }

    return Intl.DateTimeFormat("en-US", {
        timeZone: "UTC",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(createdDate);
};

export const resolveAdvisoriesDescription = (advisoryRow: IAppHealthAdvisories) => {
    const stringTemplate =
        advisoriesResourceStrings[advisoryRow.metricType]?.[
            advisoryRow.advisoryTrend
        ]?.Description?.[advisoryRow.advisoryType];

    if (!stringTemplate) return "";

    const params = [
        advisoryRow.servicingChannel,
        advisoryRow.product,
        advisoryRow.releaseVersion,
        advisoryRow.buildVersion,
    ];

    if (advisoryRow.advisoryType === AdvisoryTypeV2.MetricOutOfRangeBaselineBuild)
        params.push(
            advisoryRow.comparedReleaseVersion,
            advisoryRow.comparedBuildVersion,
        );

    return stringFormat(stringTemplate, ...params);
};

export const resolveRecommendedAction = (
    advisoryRow: IAppHealthAdvisories,
    nowDate: Date,
) => {
    if (advisoryRow.advisoryTrend === AdvisoryTrend.Improvement) return null;

    if (AdvisoryMetricType.BootTime === advisoryRow.metricType) {
        return AdvisoriesRecommendedActions.BootTime;
    }

    const isSupported =
        isFuture(new Date(advisoryRow.endOfSupportDate)) ||
        advisoryRow.endOfSupportDate === DEFAULT_DATE;

    if (!isSupported) {
        return stringFormat(
            AdvisoriesRecommendedActions.UnsupportedBuild,
            advisoryRow.servicingChannel,
        );
    }
    const availabilityDays = differenceInDays(
        nowDate,
        new Date(advisoryRow.availabilityDate),
    );
    const isBuildOlderThan15Days = availabilityDays > 15;
    const isBuildOlderThan60Days = availabilityDays > 60;
    const isSAC = [
        ServicingChannelV2.SemiAnnualEnterpriseChannel,
        ServicingChannelV2.SemiAnnualEnterpriseChannelPreview,
    ].includes(advisoryRow.servicingChannel);

    if ((isSAC && isBuildOlderThan60Days) || isBuildOlderThan15Days) {
        return stringFormat(
            AdvisoriesRecommendedActions.SupportedOlder,
            `${advisoryRow.releaseVersion} (${advisoryRow.buildVersion})`,
            `${advisoryRow.comparedReleaseVersion} (${advisoryRow.comparedBuildVersion})`,
        );
    }

    return stringFormat(
        AdvisoriesRecommendedActions.Supported,
        advisoryRow.buildVersion,
    );
};

export const fetchAdvisoriesHistory = async (
    setData: React.Dispatch<React.SetStateAction<AdvisoriesHistoricState>>,
    selectedRow: CustomDetailsItemsType,
    queryParameters: {},
) => {
    try {
        const queryParams = { ...queryParameters };
        const dt = Date.parse(`${queryParams["date"]}T00:00:00`);
        queryParams["date"] = dateFormat(dt, `yyyy-MM-01`);
        queryParams["tenantId"] = queryParams["id"];
        queryParams["channel"] =
            Object.keys(ServicingChannelV2)[
                Object.values(ServicingChannelV2).indexOf(queryParameters["channel"])
            ];
        queryParams["metric"] =
            Object.keys(AdvisoryMetricType)[
                Object.values(AdvisoryMetricType).indexOf(queryParameters["metric"])
            ];
        const appsAdvisoriesHistory = await Promise.resolve(
            API.getAdvisoriesHistory(queryParams),
        );

        const appsAdvisoriesHistorydata: KustoResponseType<string | number> =
            appsAdvisoriesHistory?.data;

        const formattedData = formatAdvisoriesHistory(
            appsAdvisoriesHistorydata,
            selectedRow,
        );
        setData((oldState) => {
            return {
                ...oldState,
                data: formattedData,
                loading: false,
                loadingFailed: false,
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "OfficeProductAdvisoriesFetchFailed",
            ),
            {
                message:
                    "Failure in fetching data for Apps Health Advisories History chart on flyout panel",
            },
            error,
        );
        setData((oldState) => {
            return {
                ...oldState,
                error: error.message,
                loading: false,
                loadingFailed: true,
            };
        });
    }
};

export const formatAdvisoriesHistory = (
    data: KustoResponseType<string | number>,
    selectedRow: CustomDetailsItemsType,
): ILineChartPoints[] => {
    // Schema - ReleaseVersion,OfficeBuild, MetricDate, Metric, EventCount, MetricType
    const dataTable = data.Tables[0];

    const advHistory = {};

    dataTable.Rows.forEach((row) => {
        const build = `${row[0]} ${row[1]}`;
        const metricType = selectedRow[AdvisoryFilterNames.Metric] as string;

        if (!(build in advHistory)) {
            advHistory[build] = [];
        }

        advHistory[build].push({
            x: new Date(row[2]),
            y: getFormattedMetricValue(metricType, row[3] as number),
            yAxisCalloutData: {
                eventCount: row[4] as number,
                unit: getMetricUnit(metricType),
                suffix: getMetricSuffix(metricType),
            },
        });
    });

    // Push the current selected advisory at the chart
    if (selectedRow) {
        const metricType = selectedRow[AdvisoryFilterNames.Metric] as string;

        advHistory[AdvisoriesFlyoutDetails.ThisAdvisory] = [
            {
                x: new Date((selectedRow.CreatedEpochInSeconds as number) * 1000),
                y: getFormattedMetricValue(
                    metricType,
                    selectedRow.MetricValue as number,
                ),
                yAxisCalloutData: {
                    eventCount: selectedRow.EventCount as number,
                    unit: getMetricUnit(metricType),
                    suffix: getMetricSuffix(metricType),
                },
            },
        ];
    }

    return Object.keys(advHistory).map((key, index) => {
        return {
            legend: key,
            data: advHistory[key],
            lineOptions: {
                lineBorderWidth: "4",
            },
            color: getAdvisoriesFlyoutPanelColors(index % 2),
        };
    });
};

export const fetchApphealthAddIns = async (
    setData: React.Dispatch<React.SetStateAction<AddInsCardState>>,
    setLoadingState: React.Dispatch<React.SetStateAction<LoadingState>>,
    id: string,
    date: string,
) => {
    try {
        const queryParams = {};
        const dt = Date.parse(`${date}T00:00:00`);
        queryParams["date"] = dateFormat(dt, `yyyy-MM-01`);
        queryParams["tenantId"] = id;

        const appsHealthAddins = await Promise.resolve(
            API.getApphealthAddins(queryParams),
        );

        const appsHealthAddinsdata: KustoResponseType<string | number> =
            appsHealthAddins?.data;

        const [dataItems, buildOptions] =
            formatApphealthAddins(appsHealthAddinsdata);
        setData((oldState) => {
            return {
                ...oldState,
                items: dataItems,
                buildOptions,
            };
        });
        setLoadingState({
            isLoadingFailed: false,
            isLoading: false,
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "OfficeProductAdvisoriesFetchFailed",
            ),
            {
                message: "Failure in fetching data for Apps Health Addins page",
            },
            error,
        );
        setData((data) => {
            return {
                ...data,
                error: error.message,
            };
        });
        setLoadingState({
            isLoadingFailed: true,
            isLoading: false,
        });
    }
};

export const formatApphealthAddins = (
    data: KustoResponseType<string | number>,
): [AddInsDataState[], string[]] => {
    // schema - OfficeProduct, OfficeArchitecture, ReleaseVersion, BuildVersion, OfficeServicingChannel, AddInId, AddinProgId, AddinVersion,
    // AddinProvider, AddinName, MonitoredDeviceCount, MonitoredSessionCount, AddInCrashRate, AddInLoadTime_GeoMean

    const dataTable = data.Tables[0];

    const allAddins: IAppHealthAddIns[] = [];
    const highCrashAddins: IAppHealthAddIns[] = [];
    const highLoadTimeAddins: IAppHealthAddIns[] = [];
    // map<channel, set<builds>>
    const buildOptions: Map<ServicingChannelV2, Set<string>> = new Map<
        ServicingChannelV2,
        Set<string>
    >();

    dataTable.Rows.forEach((element) => {
        const product: OfficeProduct = OfficeProduct[element[0] as string];
        const architecture: string = element[1] as string;
        const releaseVersion: number = element[2] as number;
        const buildVersion: string = element[3] as string;
        const channels: ServicingChannelV2 =
            ServicingChannelV2[element[4] as string];
        const addinId: string = element[5] as string;
        const addinProgId: string = element[6] as string;
        const addinVersion: string = element[7] as string;
        const addinProvider: string = element[8] as string;
        const addinName: string = element[9] as string;
        const monitoredDeviceCount: number = (element[10] as number) ?? 0;
        const sessionCount: number = (element[11] as number) ?? 0;
        const addinCrashRate: number = (element[12] as number) ?? 0;
        const addinLoadTime: number = (element[13] as number) ?? 0;

        const build: string = `${releaseVersion} (${buildVersion})`;
        const row: IAppHealthAddIns = {
            [AddInsFilterNames.Applications]: product,
            [AddInsFilterNames.Builds]: build,
            [AddInsFilterNames.Channels]: channels,
            addinId,
            addinProgId,
            version: addinVersion,
            addinProvider,
            addinName,
            monitoredDeviceCount,
            sessionCount,
            crashRate: addinCrashRate,
            loadTime: addinLoadTime,
            architecture,
        };
        allAddins.push(row);

        if (addinCrashRate >= AddInHighCrashRateByAppThreshold.get(product))
            highCrashAddins.push(row);

        if (addinLoadTime >= AddInHighLoadTimeInSecondsByAppThreshold.get(product))
            highLoadTimeAddins.push(row);

        if (!buildOptions.has(channels))
            buildOptions.set(channels, new Set<string>());

        if (!buildOptions.get(channels).has(build))
            buildOptions.get(channels).add(build);
    });

    const defaultSelectedText =
        highCrashAddins.length > 0
            ? AddinsAnnotationText.Crash
            : highLoadTimeAddins.length > 0
            ? AddinsAnnotationText.Load
            : AddinsAnnotationText.All;

    const result: AddInsDataState[] = [
        {
            text: AddinsAnnotationText.All,
            data: allAddins,
            uniqueCounts: GroupAddins(allAddins).length,
            selected: AddinsAnnotationText.All === defaultSelectedText,
            color: getChartColor(0), // constant color
        },
        {
            text: AddinsAnnotationText.Crash,
            data: highCrashAddins,
            uniqueCounts: GroupAddins(highCrashAddins).length,
            selected: AddinsAnnotationText.Crash === defaultSelectedText,
            color: getChartColor(1), // constant color
        },
        {
            text: AddinsAnnotationText.Load,
            data: highLoadTimeAddins,
            uniqueCounts: GroupAddins(highLoadTimeAddins).length,
            selected: AddinsAnnotationText.Load === defaultSelectedText,
            color: getChartColor(1), // constant color
        },
    ];

    const builds: string[] = [];

    for (const [channel, set] of buildOptions) {
        builds.push(channel);
        set.forEach((build) => {
            builds.push(`${channel},${build}`);
        });
    }

    return [result, builds];
};
