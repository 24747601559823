import React, { Profiler, useState } from "react";
import { useLocation } from "react-router-dom";
import { OutageBanner } from "components/CustomComponents/MessageBars/OutageBanner";
import ConfidentialityBanner from "components/InsightsComponents/ConfidentialityBanner";
import InsightsHeader from "components/InsightsComponents/InsightsHeader";
import NavigationPanel from "components/InsightsComponents/NavigationPanel";
import { incrementRenderCount } from "components/Developer/DeveloperStateStore";
import { isLandingPage } from "components/InsightsComponents/Helpers";

const InsightsFrame = ({ children }) => {
    const location = useLocation();
    const [navCollapsed, setNavCollapsed] = useState<boolean>(!isLandingPage());

    return (
        <Profiler
            id="InsightsFrame"
            onRender={() => {
                incrementRenderCount();
            }}
        >
            <header>
                <InsightsHeader setNavCollapsed={setNavCollapsed} />
            </header>
            <div className="insightsMain">
                <aside>
                    <NavigationPanel
                        navCollapsed={navCollapsed}
                        setNavCollapsed={setNavCollapsed}
                    />
                </aside>
                <main
                    style={{ marginLeft: navCollapsed ? "48px" : "280px" }}
                    key={String(navCollapsed) + String(location.pathname)}
                >
                    <>
                        <OutageBanner />
                        {React.cloneElement(children, {
                            navCollapsed,
                            setNavCollapsed,
                        })}
                    </>
                </main>
            </div>
            <ConfidentialityBanner />
        </Profiler>
    );
};

export default InsightsFrame;
