import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { ReferralQueryStringParamNames } from "@oga-plg/plg-telemetry/dist";
import AddInsDashboard from "pages/AddIns/AddInsDashboard";
import AIScorecard from "pages/AIScorecard/AIScorecard";
import CohortAnalysis from "pages/CohortAnalysis/CohortAnalysis";
import Collab from "pages/Collab/Collab";
import ConsumerCamauOverview from "pages/ConsumerCamauDrilldown/ConsumerCamauOverview";
import ConsumerDay0Funnel from "pages/ConsumerDay0Funnel/ConsumerDay0Funnel";
import ConsumerGrowthDrilldown from "pages/ConsumerGrowthDrilldown/ConsumerGrowthDrilldown";
import ConsumerOverview from "pages/ConsumerOverview/ConsumerOverview";
import ConsumerProductDrillDown from "pages/ConsumerProductDrillDown/ConsumerProductDrillDown";
import CopilotExecOverview from "pages/Copilot/CopilotExecOverview";
import CopilotProductDrilldown from "pages/Copilot/CopilotProductDrilldown";
import ERFM from "pages/ERFM/ERFM";
import ErrorLookup from "pages/ErrorLookup/ErrorLookup";
import ExecOverview from "pages/ExecOverview/ExecOverview";
import ExemptionPortal from "pages/ExemptionPortal/ExemptionPortal";
import InProductDrillDown from "pages/InProductDrillDown/InProductDrillDown";
import InsightsLanding from "pages/InsightsLanding/InsightsLanding";
import M365TenantInsights from "pages/M365TenantInsights/M365TenantInsights";
import MTR from "pages/MTR/MTR";
import AddInPerformance from "pages/Performance/AddIns/AddInPerformance";
import AddInVersionPerformance from "pages/Performance/AddIns/AddInVersionPerformance";
import Performance from "pages/Performance/Performance";
import Win32Performance from "pages/Performance/Win32Performance";
import Win32PerformanceInsights from "pages/Performance/Win32PerformanceInsights";
import SurveyFunnelDrillDown20 from "pages/QuIP/SurveyFunnelDrillDown20/SurveyFunnelDrillDown20";
import Reliability from "pages/Reliability/Reliability";
import SummarizedInsightsRequest from "pages/SummarizedInsightsRequest/SummarizedInsightsRequest";
import { Workload } from "config/PlatformConfig";
import { InsightsRoutes } from "utils/Links";
import { PlgTelemetryContext } from "utils/PlgTelemetryLogger";

interface InsightsBrowserRoutesProps {
    navCollapsed?: boolean;
    setNavCollapsed?: (navCollapsed: boolean) => void;
}

const InsightsBrowserRoutes = ({
    navCollapsed,
    setNavCollapsed,
}: InsightsBrowserRoutesProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const plgTelemetryContext = useContext(PlgTelemetryContext);

    // If there is referral tool information in the URL, update the telemetry context,
    if (searchParams.has(ReferralQueryStringParamNames.RefFrom)) {
        plgTelemetryContext?.updateState({
            referralFields: {
                referralFromToolName: searchParams.get(
                    ReferralQueryStringParamNames.RefFrom,
                ),
                referralFromFeatureName:
                    searchParams.get(ReferralQueryStringParamNames.RefFromFeature) ??
                    "",
                referralFromSessionId:
                    searchParams.get(
                        ReferralQueryStringParamNames.RefFromSessionId,
                    ) ?? "",
            },
        });
    }

    useEffect(() => {
        // If there is referral tool information in the URL then clear out those params from the URL.
        // The cleaning of the URL has to be done inside a useEffect because setting the search params
        // can't be done when the component is first rendered.
        if (searchParams.has(ReferralQueryStringParamNames.RefFrom)) {
            searchParams.delete(ReferralQueryStringParamNames.RefFrom);
            searchParams.delete(ReferralQueryStringParamNames.RefFromFeature);
            searchParams.delete(ReferralQueryStringParamNames.RefFromSessionId);
            setSearchParams(searchParams, { replace: true });
        }
    }, [searchParams, setSearchParams]);

    return (
        <Routes>
            <Route
                path={InsightsRoutes.AddInPerformance.path}
                element={<AddInPerformance />}
            />
            <Route path={InsightsRoutes.AddIns.path} element={<AddInsDashboard />} />
            <Route
                path={InsightsRoutes.AddInVersionPerformance.path}
                element={<AddInVersionPerformance />}
            />
            <Route path={InsightsRoutes.Cohort.path} element={<CohortAnalysis />} />
            <Route
                path={InsightsRoutes.CohortDeepDive.path}
                element={
                    <ConsumerProductDrillDown platform={Workload.WEB_CONSUMER} />
                }
            />
            <Route path={InsightsRoutes.Collab.path} element={<Collab />} />
            <Route
                path={InsightsRoutes.Consumer.path}
                element={<ConsumerOverview />}
            />
            <Route
                path={InsightsRoutes.ConsumerCAMAU.path}
                element={<ConsumerCamauOverview />}
            />
            <Route
                path={InsightsRoutes.CrossPlatformOverview.path}
                element={<ExecOverview platform={Workload.CROSSPLATFORM} />}
            />
            <Route
                path={InsightsRoutes.Day0Funnel.path}
                element={<ConsumerDay0Funnel />}
            />
            <Route
                path={InsightsRoutes.ErrorLookup.path}
                element={<ErrorLookup />}
            />
            <Route path={InsightsRoutes.ERFM.path} element={<ERFM />} />
            <Route
                path={InsightsRoutes.ExemptionPortal.path}
                element={<ExemptionPortal />}
            />
            <Route
                path={InsightsRoutes.GrowthDrilldown.path}
                element={<ConsumerGrowthDrilldown />}
            />
            <Route
                path={InsightsRoutes.GrowthScorecard.path}
                element={<Navigate replace to={InsightsRoutes.Consumer.path} />}
            />
            <Route
                path={InsightsRoutes.QuipSurveyFunnelDrillDown.path}
                element={<SurveyFunnelDrillDown20 />}
            />
            <Route
                path={InsightsRoutes.InsightsLanding.path}
                element={
                    <InsightsLanding
                        navCollapsed={navCollapsed}
                        setNavCollapsed={setNavCollapsed}
                    />
                }
            />
            <Route
                path={InsightsRoutes.M365TenantInsights.path}
                element={<M365TenantInsights level="" id="" />}
            />
            <Route
                path={InsightsRoutes.Mac.path}
                element={<ExecOverview platform={Workload.MAC} />}
            />
            <Route
                path={InsightsRoutes.MacTenant.path}
                element={<InProductDrillDown platform={Workload.MAC} />}
            />
            <Route path={InsightsRoutes.MTR.path} element={<MTR />} />
            <Route
                path={InsightsRoutes.OfficeCopilotScorecard.path}
                element={<AIScorecard />}
            />
            <Route
                path={InsightsRoutes.Performance.path}
                element={<Performance />}
            />
            <Route
                path={InsightsRoutes.Reliability.path}
                element={<Reliability />}
            />
            <Route
                path={InsightsRoutes.SummarizedInsightsRequest.path}
                element={<SummarizedInsightsRequest />}
            />
            <Route
                path={InsightsRoutes.Teams.path}
                element={<ExecOverview platform={Workload.TEAMS} />}
            />
            <Route
                path={InsightsRoutes.TeamsTenant.path}
                element={<InProductDrillDown platform={Workload.TEAMS} />}
            />
            <Route
                path={InsightsRoutes.Tenant.path}
                element={<InProductDrillDown platform={Workload.WEB} />}
            />
            <Route
                path={InsightsRoutes.Web.path}
                element={<ExecOverview platform={Workload.WEB} />}
            />
            <Route
                path={InsightsRoutes.WebTenant.path}
                element={<InProductDrillDown platform={Workload.WEB} />}
            />
            <Route
                path={InsightsRoutes.Win32.path}
                element={<ExecOverview platform={Workload.WIN32} />}
            />
            <Route
                path={InsightsRoutes.Win32PerfDrilldown.path}
                element={<Win32Performance />}
            />
            <Route
                path={InsightsRoutes.Win32PerfInsights.path}
                element={<Win32PerformanceInsights />}
            />
            <Route
                path={InsightsRoutes.Win32Tenant.path}
                element={<InProductDrillDown platform={Workload.WIN32} />}
            />
            <Route
                path={InsightsRoutes.Copilot.path}
                element={<CopilotExecOverview />}
            />
            <Route
                path={InsightsRoutes.CopilotTenant.path}
                element={<CopilotProductDrilldown />}
            />
            <Route
                path="*"
                element={
                    <InsightsLanding
                        navCollapsed={navCollapsed}
                        setNavCollapsed={setNavCollapsed}
                        errorCode={404}
                    />
                }
            />
        </Routes>
    );
};

export default InsightsBrowserRoutes;
