import React, { useEffect, useState } from "react";
import {
    Stack,
    ChoiceGroup,
    Label,
    Dropdown,
    IIconProps,
    IContextualMenuProps,
    DefaultButton,
} from "@fluentui/react";
import { FunnelDataExport } from "components/ConsumerDay0FunnelComponents/FunnelDataExport/FunnelDataExport";
import { CadenceFrequency } from "components/CustomComponents/CadenceLabel/CadenceLabel";
import NavigableDatePickerWithCalendar from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendar";
import {
    ConsumerDay0FunnelCohortNames,
    ConsumerDay0FunnelCadenceNames,
    getFilterLabel,
} from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";
import {
    horizontalStackTokensDay0FunnelFilters,
    stackItemStylesDay0FunnelFilters,
} from "components/ConsumerDay0FunnelComponents/FunnelFilters/constants";
import {
    formatFunnelFilterOptions,
    getFilterChoices,
} from "pages/ConsumerDay0Funnel/ApiHandler";
import {
    Day0FunnelCadenceType,
    FunnelFilterOptions,
    FunnelFiltersPropsType,
} from "pages/ConsumerDay0Funnel/types";
import { logException } from "utils/AppInsightsHelper";
import "components/CommonFunnelComponents/CommonFunnelFilter/styles.css";
import { Severity, TenantInsightsException } from "utils/Exceptions";

const FunnelFilterPanel = ({
    dateOptions,
    filters,
    onFilterChange,
    onAddFilter,
    additionalFilters,
}: FunnelFiltersPropsType) => {
    const addIcon: IIconProps = { iconName: "Add" };
    const cancelIcon: IIconProps = { iconName: "Cancel" };
    const [filterOptions, setFilterOptions] = useState<FunnelFilterOptions>({});
    const [cadence, setCadence] = useState<Day0FunnelCadenceType>(filters.cadence);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "AppMode",
                text: "AppMode",
                checked: additionalFilters.has("appMode"),
                iconProps: additionalFilters.has("appMode") && cancelIcon,
                onClick: () => onAddFilter("appMode"),
            },
            {
                key: "Browser",
                text: "Browser",
                checked: additionalFilters.has("browser"),
                iconProps: additionalFilters.has("browser") && cancelIcon,
                onClick: () => onAddFilter("browser"),
            },
            {
                key: "Country",
                text: "Country",
                checked: additionalFilters.has("country"),
                iconProps: additionalFilters.has("country") && cancelIcon,
                onClick: () => onAddFilter("country"),
            },
            {
                key: "Datacenter",
                text: "Datacenter",
                checked: additionalFilters.has("datacenter"),
                iconProps: additionalFilters.has("datacenter") && cancelIcon,
                onClick: () => onAddFilter("datacenter"),
            },
            {
                key: "OS",
                text: "OS",
                checked: additionalFilters.has("os"),
                iconProps: additionalFilters.has("os") && cancelIcon,
                onClick: () => onAddFilter("os"),
            },
            {
                key: "ReferrerPlatform",
                text: "Referrer Platform",
                checked: additionalFilters.has("referrerPlatform"),
                iconProps: additionalFilters.has("referrerPlatform") && cancelIcon,
                onClick: () => onAddFilter("referrerPlatform"),
            },
        ],
    };

    const getAdditionalFilters = () => {
        let additionalFiltersArray = [];
        additionalFilters.forEach((item) => {
            additionalFiltersArray.push(
                <div
                    className="dropdown"
                    style={{
                        padding: 5,
                    }}
                    key={getFilterLabel(item)}
                >
                    <Label className="filterLabel">{getFilterLabel(item)}:</Label>
                    <Dropdown
                        options={filterOptions[item]}
                        onChange={(_event, option) =>
                            onFilterChange(item, option.key as string)
                        }
                        selectedKey={filters[item]}
                        className="filterDropdown"
                    />
                </div>,
            );
        });
        return additionalFiltersArray;
    };

    useEffect(() => {
        const getFilterOptions = async () => {
            try {
                const response = await getFilterChoices(filters);
                const filterOptions: FunnelFilterOptions =
                    formatFunnelFilterOptions(response);
                setFilterOptions(filterOptions);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "FetchFunnelFilterOptionsException",
                    ),
                    {
                        message:
                            "Failed to fetch filter options for the given date.",
                    },
                    error,
                );
            }
        };
        getFilterOptions();
    }, [filters]);

    return (
        <Stack
            horizontal
            className="filterPanel"
            styles={stackItemStylesDay0FunnelFilters}
            tokens={horizontalStackTokensDay0FunnelFilters}
        >
            <Stack horizontal style={{ flexShrink: 0 }}>
                <Label className="filterLabel">View By:</Label>
                <ChoiceGroup
                    defaultSelectedKey={filters.cadence}
                    options={ConsumerDay0FunnelCadenceNames}
                    onChange={(_event, option) => {
                        setCadence(option.key as Day0FunnelCadenceType);
                        onFilterChange("cadence", option.key as string);
                    }}
                    required
                    styles={{
                        flexContainer: {
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr auto",
                            marginTop: "-8px",
                        },
                    }}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <NavigableDatePickerWithCalendar
                    backTitle="Go to Previous Period"
                    forwardTitle="Go to Next Period"
                    dateOptions={dateOptions}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    dateFilter={filters.date}
                    cadence={
                        cadence === "weekly"
                            ? CadenceFrequency.Weekly_Day0Funnel
                            : CadenceFrequency.Monthly_Day0Funnel
                    }
                    showGoToToday={false}
                />
            </Stack>
            <Stack horizontal style={{ minWidth: 270 }}>
                <Label className="filterLabel">Cohort:</Label>
                <Dropdown
                    options={ConsumerDay0FunnelCohortNames}
                    onChange={(_event, option) =>
                        onFilterChange("cohort", option.key as string)
                    }
                    selectedKey={filters.cohort}
                    className="filterDropdown"
                />
            </Stack>
            <Stack verticalAlign="center">
                <DefaultButton
                    className="filterButton"
                    iconProps={addIcon}
                    menuProps={menuProps}
                    text="Filters"
                    aria-label="Add Additional Filters"
                    title="Add Additional Filters"
                />
            </Stack>
            <Stack horizontal wrap data-testid="additionalFilterDropdown">
                {getAdditionalFilters()}
            </Stack>
            <FunnelDataExport filters={filters} />
        </Stack>
    );
};

export default FunnelFilterPanel;
