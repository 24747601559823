import React from "react";

// data-link="https://codepen.io/jackrugile/pen/JddmaX"
const Loader = () => {
    return (
        <div className="loader-inner">
            <div className="loader-line-wrap">
                <div className="loader-line" />
            </div>
            <div className="loader-line-wrap">
                <div className="loader-line" />
            </div>
            <div className="loader-line-wrap">
                <div className="loader-line" />
            </div>
            <div className="loader-line-wrap">
                <div className="loader-line" />
            </div>
            <div className="loader-line-wrap">
                <div className="loader-line" />
            </div>
        </div>
    );
};

export default Loader;
