import React, { useState, useEffect } from "react";
import {
    Stack,
    IStackTokens,
    DetailsList,
    SelectionMode,
    IColumn,
    DetailsRow,
    IDetailsRowProps,
    IconButton,
} from "@fluentui/react";
import { ApplicationTitle } from "components/CustomComponents/ApplicationTitle/ApplicationTitle";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import ErrorImpactChart from "components/ReliabilityComponents/ErrorImpactChart/ErrorImpactChart";
import {
    commonInnerBlock,
    commonPageBlock,
    drilldownChartHeight,
} from "pages/common";
import { InsightsRoutes } from "utils/Links";
import { RELIABILITY_MESSAGES } from "utils/Messages";
import { defaultCohort } from "utils/WebConstants";

const innerStackTokens: IStackTokens = {
    childrenGap: 15,
};

export const ReliabilityDrilldown = ({
    chartModalToggle,
    getChartModalData,
    filters,
    appSelected,
    dataItems,
    chart,
}) => {
    const [items, setItems] = useState(dataItems);
    useEffect(() => {
        const errorColumns = [
            ["ErrorName", "Error Name", 250, null],
            ["ErrorType", "Error Type", 75, null],
            ["UserErrorCount", "# Users With Error", 120, "alignRightHeader"],
            ["PercentUsersWithError", "% Users With Error", 150, "alignRightHeader"],
            [
                "S2500AverageUser",
                "S2500 Avg% Users With Error",
                200,
                "alignRightHeader",
            ],
            [
                "PercentSessionsWithError",
                "% Sessions With Error",
                150,
                "alignRightHeader",
            ],
            [
                "S2500AverageSession",
                "S2500 Avg% Sessions With Error",
                180,
                "alignRightHeader",
            ],
        ];

        const columns: Array<IColumn> = DetailsListHelper.customBuildColumns(
            errorColumns,
            true,
            null,
            "PercentUsersWithError",
            true,
        );
        // Show browser column only for latest month
        if (filters.latestMonth === filters.date && filters.level !== "topParent")
            columns.push({
                key: "ChartModalLink",
                name: "Browser",
                fieldName: "Browser",
                minWidth: 50,
            });

        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
        setItems(dataItems);
        setColumnsErrorTable(columns);
    }, [dataItems, filters.latestMonth, filters.date, filters.level]);

    const [error, setError] = useState(items[0] != null ? items[0].ErrorName : null);

    const [tenantsWithSameError, setTenantsWithSameError] = useState(
        items[0] != null ? items[0].TenantsWithSameError.slice() : null,
    );

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        const result = DetailsListHelper.sortItems(columnsErrorTable, column, items);
        setItems(result.items);
        setColumnsErrorTable(result.columns);
    };

    const [columnsErrorTable, setColumnsErrorTable] = useState([]);

    const _generateTenantsWithSameErrorColumns = () => {
        const displayColumns = [
            ["OrgName", "Org Name", 200, null],
            ["Id", filters["level"], filters["level"] === "Tpid" ? 80 : 200, null],
            ["PercentUsersWithError", "% Users With Error", 150, "alignRightHeader"],
        ];

        const columns: IColumn[] = DetailsListHelper.customBuildColumns(
            displayColumns,
            true,
            null,
            "PercentUsersWithError",
            true,
        );
        columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

        return columns;
    };

    const _onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        return (
            <div
                onClick={() => {
                    setError(props.item.ErrorName);
                    setTenantsWithSameError(props.item.TenantsWithSameError);
                }}
            >
                <DetailsRow {...props} />
            </div>
        );
    };

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        switch (column.key) {
            case "UserErrorCount":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={0}
                    />
                );
            case "PercentUsersWithError":
            case "S2500AverageUser":
            case "PercentSessionsWithError":
            case "S2500AverageSession":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            case "ErrorName":
                return (
                    <DetailsListHelper.TextValue
                        value={fieldContent}
                        style={{ cursor: "pointer" }}
                    />
                );
            case "ChartModalLink":
                return (
                    <div
                        className="metricExpand"
                        onClick={() => {
                            filters["veto"] = item["ErrorType"];
                            filters["errorname"] = item["ErrorName"];
                            filters["app"] = appSelected;
                            getChartModalData(filters);
                            chartModalToggle();
                        }}
                    >
                        <IconButton
                            iconProps={{ iconName: "Chart" }}
                            title="Chart"
                            ariaLabel="Chart"
                            className="drillDownIcon"
                        />
                    </div>
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    const _renderItemColumnTenant = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;
        switch (column.key) {
            case "OrgName":
                const link = `${InsightsRoutes.Reliability.path}?id=${item.Id}&level=${filters["level"]}&date=${filters["date"]}`;
                return (
                    <DetailsListHelper.LinkedTextValue
                        value={fieldContent}
                        link={link}
                    />
                );
            case "PercentUsersWithError":
                return (
                    <DetailsListHelper.NumericalValue
                        value={fieldContent}
                        postfix="%"
                        decimalPrecision={2}
                    />
                );
            default:
                return <DetailsListHelper.TextValue value={fieldContent} />;
        }
    };

    let topItems = [];
    let message = RELIABILITY_MESSAGES.ONE_PERCENT;

    if (items.filter((i) => i["PercentUsersWithError"] >= 1).length === 0) {
        topItems = items.slice(0, 20);
        message = RELIABILITY_MESSAGES.TOP_20;
    } else {
        topItems = items.filter((i) => i["PercentUsersWithError"] >= 1);
    }

    if (topItems.length === 0) {
        return (
            <Stack styles={commonPageBlock}>
                <ApplicationTitle app={appSelected} />
                <EmptyTableContainer />
            </Stack>
        );
    }
    return (
        <>
            <div>
                <Stack styles={commonPageBlock}>
                    <ApplicationTitle app={appSelected} />
                    <Stack>
                        <Stack styles={commonInnerBlock}>
                            <DetailsList
                                items={topItems}
                                columns={columnsErrorTable}
                                selectionMode={SelectionMode.none}
                                compact
                                onRenderDetailsHeader={
                                    DetailsListHelper.renderDetailsHeader
                                }
                                onRenderRow={_onRenderRow}
                                onRenderItemColumn={_renderItemColumn}
                                onColumnHeaderClick={_onColumnClick}
                                styles={{
                                    root: {
                                        height: "270px",
                                    },
                                }}
                                className="tenantTable"
                            />
                            <div className="subscriptStyleBottom">{message}</div>
                        </Stack>
                        <br />
                        <h3 className="subHeader"> {error} </h3>

                        <Stack horizontal tokens={innerStackTokens}>
                            <Stack.Item>
                                <h3 className="commonPageBlockTitle">
                                    {" "}
                                    % Users Impacted
                                </h3>
                                <ErrorImpactChart
                                    data={chart}
                                    error={error}
                                    app={appSelected}
                                    dataLevel={"Users"}
                                    width={(window.innerWidth - 170) / 3}
                                    cohort={defaultCohort}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <h3 className="commonPageBlockTitle">
                                    {" "}
                                    % Sessions Impacted
                                </h3>
                                <ErrorImpactChart
                                    data={chart}
                                    error={error}
                                    app={appSelected}
                                    dataLevel={"Sessions"}
                                    width={(window.innerWidth - 170) / 3}
                                    cohort={defaultCohort}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <h3 className="commonPageBlockTitle">
                                    {" "}
                                    Top 15 Tenants With Same Error
                                </h3>
                                {tenantsWithSameError &&
                                    tenantsWithSameError.length !== 0 && (
                                        <DetailsList
                                            items={tenantsWithSameError}
                                            columns={_generateTenantsWithSameErrorColumns()}
                                            selectionMode={SelectionMode.none}
                                            compact
                                            onRenderItemColumn={
                                                _renderItemColumnTenant
                                            }
                                            styles={{
                                                root: {
                                                    height: drilldownChartHeight,
                                                    width:
                                                        (window.innerWidth - 170) /
                                                        3,
                                                },
                                            }}
                                            className="tenantTable"
                                        />
                                    )}
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </>
    );
};
