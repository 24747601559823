import React from "react";
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    BarChart,
    Legend,
} from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { AxisDomain, LayoutType, AxisInterval } from "recharts/types/util/types";
import { chartLegendStyles, lineChartTooltipStyles } from "pages/common";

interface CustomBarChartPropsType {
    payload: Array<{}>;
    keys: Array<string>;
    fill: string;
    yLabel?: string;
    width?: number;
    height?: number;
    domain?: AxisDomain;
    layout?: LayoutType;
    legendFormatter?: Formatter;
    xAxisInterval?: AxisInterval;
}
export const CustomBarChart = (props: CustomBarChartPropsType) => {
    const {
        payload,
        keys,
        fill,
        yLabel,
        width = 450,
        height = 300,
        domain = [0, 100],
        layout = "horizontal",
    } = props;
    const customFormatter = (value) => {
        return `${value} %`;
    };

    let XAxisType: "category" | "number" = "category",
        YAxisType: "category" | "number" = "number";
    let XdomainValue: AxisDomain = null,
        YdomainValue: AxisDomain = domain;
    let XdataKey: string = "name",
        YdataKey: string = undefined;
    let XUnit: string = undefined,
        YUnit: string = "%";
    let YLabel = yLabel
        ? {
              value: yLabel,
              angle: -90,
              dx: -25,
          }
        : null;
    let YMargin = undefined;
    let YTick = undefined;

    if (layout === "vertical") {
        XAxisType = "number";
        YAxisType = "category";
        XdomainValue = domain;
        YdomainValue = null;
        XdataKey = undefined;
        YdataKey = "name";
        XUnit = "%";
        YUnit = undefined;
        YLabel = undefined;
        YMargin = { left: 50, bottom: 10 };
        YTick = { width: 100 };
    }

    return payload && payload.length !== 0 ? (
        <BarChart
            width={width}
            height={height}
            data={payload}
            layout={layout}
            margin={YMargin}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                type={XAxisType}
                dataKey={XdataKey}
                domain={XdomainValue}
                unit={XUnit}
                interval={props.xAxisInterval}
            />
            <YAxis
                type={YAxisType}
                domain={YdomainValue}
                dataKey={YdataKey}
                unit={YUnit}
                label={YLabel}
                tick={YTick}
            />
            <Tooltip
                labelStyle={lineChartTooltipStyles.labelStyle}
                itemStyle={lineChartTooltipStyles.itemStyle}
                formatter={customFormatter}
                animationDuration={0}
                animationEasing="linear"
            />
            {keys.map((key) => {
                return (
                    <Bar dataKey={key} fill={fill} barSize={10} key={`bar-${key}`} />
                );
            })}
            <Legend
                formatter={props.legendFormatter}
                wrapperStyle={chartLegendStyles}
            />
        </BarChart>
    ) : (
        <EmptyTableContainer />
    );
};
