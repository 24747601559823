import React, { useState } from "react";
import { Link } from "react-router-dom";
import has from "lodash/has";
import {
    IconButton,
    IGroupHeaderProps,
    IRenderFunction,
    IStyle,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
    IGroupDividerProps,
    ShimmeredDetailsList,
    ConstrainMode,
} from "@fluentui/react";
import {
    customBuildColumns,
    NumericalValue,
} from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ChartModal } from "components/TenantDrilldownComponents/ChartModal";
import { platformsConfig, platformColumns, Workload } from "config/PlatformConfig";
import { appStyles } from "utils/Constants";
import { ConsumerAppMetricsLinkMapping } from "utils/ConsumerConstants";
import { AppMetricsLinkMapping } from "utils/Links";
import { LinkParameters } from "utils/types";

const CONSTANTS = {
    COLUMN_WIDTH: 75,
    FIXED_ROW_HEADER_COLUMN_WIDTH: 225,
};

const generateColumns = (platform) => {
    const displayColumns = [
        ["Links", "", 20, null],
        ["Display", "", CONSTANTS.FIXED_ROW_HEADER_COLUMN_WIDTH, null],
    ];
    platformColumns[platform].forEach((column) => {
        displayColumns.push([
            column,
            platformsConfig[platform].showIconsInMetricsTable ? "" : column,
            CONSTANTS.COLUMN_WIDTH,
            "alignRightHeader",
        ]);
    });
    const columns: IColumn[] = customBuildColumns(
        displayColumns,
        true,
        null,
        null,
        false,
    );
    columns.forEach((column) => {
        if (!(column.key === "Links" || column.key === "Display")) {
            column.iconName = appStyles[column.key]["iconName"];
        }
    });
    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });
    return columns;
};

const generateMetricGroups = (metrics: {}) => {
    let startIdx = 0;
    let idx = 0;
    const orderedGroup = [];
    let group = metrics[0].Group;
    let prevGroup = group;

    Object.keys(metrics).forEach((index) => {
        idx = parseInt(index, 10);
        const metric = metrics[index];
        group = metric["Group"];
        if (group !== prevGroup) {
            orderedGroup.push({
                key: prevGroup,
                name: prevGroup,
                startIndex: startIdx,
                level: 0,
                count: idx - startIdx,
                isCollapsed: false,
            });
            startIdx = idx;
        }
        prevGroup = group;
    });

    orderedGroup.push({
        key: prevGroup,
        name: prevGroup,
        startIndex: startIdx,
        level: 0,
        count: idx - startIdx + 1,
        isCollapsed: false,
    });

    return orderedGroup;
};

const getDrillDownLink = (filters: {}, platform: string, item: any) => {
    let disabled = true;
    let label = "";
    let link = "";
    switch (platform) {
        case Workload.WEB_CONSUMER:
            if (has(ConsumerAppMetricsLinkMapping, item.Metric)) {
                disabled = false;
                label = ConsumerAppMetricsLinkMapping[item.Metric].toolTip;
                link = ConsumerAppMetricsLinkMapping[item.Metric].getMetricUrl(
                    filters["cohortName"],
                    filters["cohortType"],
                    filters["application"],
                    filters["date"],
                );
            }
            break;
        case Workload.WEB_CONSUMER_GROWTH:
            break;
        default:
            if (has(AppMetricsLinkMapping, item.Metric)) {
                disabled =
                    AppMetricsLinkMapping[item.Metric].disabled ||
                    (AppMetricsLinkMapping[item.Metric].tpidDisabled &&
                        filters["level"] === "Tpid");
                label = AppMetricsLinkMapping[item.Metric].toolTip;
                const params: LinkParameters = {
                    id: filters["id"],
                    level: filters["level"],
                    date: filters["Date"],
                    host: filters["Host"],
                    uiHost: filters["UiHost"],
                    npsPlatformName: platformsConfig[platform].platformNameNPS,
                };

                link = AppMetricsLinkMapping[item.Metric].getMetricUrl(params);
            }
    }

    return !disabled ? (
        <div>
            <Link to={link} target="_blank">
                <IconButton
                    iconProps={{ iconName: "DrillThrough" }}
                    title={label}
                    ariaLabel={label}
                    className="drillDownIcon"
                    disabled={disabled}
                />
            </Link>
        </div>
    ) : (
        <div />
    );
};
const disabledMetricsForCharts = ["Currency Fork"];

export const TenantMetrics = (props) => {
    const [show, setShow] = useState(false);
    const toggleModal = () => {
        setShow(!show);
    };

    if (props.loading) {
        return (
            <div className="metricsTable tenantTable">
                <ShimmeredDetailsList
                    items={[]}
                    columns={generateColumns(props.platform)}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    enableShimmer={true}
                    constrainMode={ConstrainMode.unconstrained}
                />
            </div>
        );
    }

    const _renderItemColumn = (item, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName] as string;

        switch (column.key) {
            case "Links":
                return getDrillDownLink(
                    { ...props.filters, ...props.metricFilters },
                    props.platform,
                    item,
                );
            case "Display":
                return !disabledMetricsForCharts.includes(item.Metric) ? (
                    <div
                        className="metricExpand metricExpandCursor"
                        onClick={() => {
                            setShow(!show);
                            props.getChartMetrics(
                                item.Metric,
                                item.Display,
                                props.filters["level"],
                                props.filters["id"],
                                props.metricFilters,
                            );
                        }}
                    >{`${fieldContent}`}</div>
                ) : (
                    <div className="metricExpand">{`${fieldContent}`}</div>
                );
            default:
                let decimalPrecision = 0;
                if (!Number.isInteger(fieldContent)) decimalPrecision = 2;
                const platformSpecificColumnName = getAppColumnName(props.platform);
                return (
                    <NumericalValue
                        value={fieldContent}
                        postfix=""
                        decimalPrecision={decimalPrecision}
                        class={
                            column.fieldName !==
                            props.filters[platformSpecificColumnName]
                                ? "metricScoreTableLowOpacity"
                                : ""
                        }
                    />
                );
        }
    };

    const onRenderHeader = (
        headerProps: IGroupDividerProps,
        defaultRender: IRenderFunction<IGroupHeaderProps>,
    ) => {
        const headerCountStyle: IStyle = { display: "none" };
        return (
            <span>
                {defaultRender({
                    ...headerProps,
                    styles: { headerCount: headerCountStyle },
                })}
            </span>
        );
    };

    return (
        props.payload.length > 0 && (
            <div className="metricsTable tenantTable">
                <ChartModal
                    show={show}
                    handleClose={toggleModal}
                    data={props.chartData}
                    appsList={platformColumns[props.platform]}
                    useMonthlyIntervals={props.useMonthlyIntervals ?? true}
                />
                <DetailsList
                    items={props.payload}
                    columns={generateColumns(props.platform)}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderItemColumn={_renderItemColumn}
                    groups={generateMetricGroups(props.payload)}
                    groupProps={{ onRenderHeader }}
                />
            </div>
        )
    );
};

const getAppColumnName = (platform: string) => {
    switch (platform) {
        case Workload.COPILOT_COMMERCIAL:
            return "application";
        default:
            return "rankOn";
    }
};
