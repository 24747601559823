export const webSparkLineScores: string = `
// Web SparkLineScores - show bars in each metric signal for past 18 months
let currDate = todatetime("\${date}");
let oldestDate = datetime_add('month',-17,currDate);
Score
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName}=="\${id}" and UiHost=="All" and Application =="\${app}"
| where todatetime(Date) between (oldestDate .. currDate)
| project Date, MauRate, MauRate_Color = Color_Mau, 
    MeuRate, MeuRate_Color = Color_Meu, 
    EngagedEditorsRate, EngagedEditorsRate_Color = Color_EngagedEditors,
    ReturningMauRate, ReturningMauRate_Color = Color_ReturningMau,
    EUPL_P95, EUPL_P95_Color = Color_Performance,
    EUPL_P50, EUPL_P50_Color = Color_Performance,
    ACEFreeUsersRate, ACEFreeUsersRate_Color = Color_ACEFreeUsers,
    ICEFreeUsersRate, ICEFreeUsersRate_Color = Color_ICEFreeUsers,
    Feedback = Feedback_SubScore, Color_Feedback, 
    OpenInClientRate, OpenInClientRate_Color = Color_OpenInClient
| sort by Date asc;
`;

export const webSparkLineScores_V2: string = `
// Web SparkLineScores V2 - show bars in each metric signal for past 18 months
let currDate = todatetime("\${date}");
let v1date = todatetime("2023-06-01");
let v2date = todatetime("2023-07-01");
let oldestDate = datetime_add('month',-17,currDate);
let V1data = Score
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName}=="\${id}" and UiHost=="All" and Application =="\${app}"
| where todatetime(Date) between (oldestDate .. v1date)
| project Date, MauRate, MauRate_Color = Color_Mau, 
    MeuRate, MeuRate_Color = Color_Meu, 
    EngagedEditorsRate, EngagedEditorsRate_Color = Color_EngagedEditors,
    ReturningMauRate, ReturningMauRate_Color = Color_ReturningMau,
    EUPL_P95, EUPL_P95_Color = Color_Performance,
    EUPL_P50, EUPL_P50_Color = Color_Performance,
    ASHA = round(0, 2), Color_ASHA = "Gray",
    Feedback = Feedback_SubScore, Color_Feedback, 
    OpenInClientRate, OpenInClientRate_Color = Color_OpenInClient;
let V2data = Score_V2
| where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where \${levelColumnName}=="\${id}" and UiHost=="All" and Application =="\${app}"
| where todatetime(Date) between (v2date .. currDate)
| project Date, MauRate, MauRate_Color = Color_Mau, 
    MeuRate, MeuRate_Color = Color_Meu, 
    EngagedEditorsRate, EngagedEditorsRate_Color = Color_EngagedEditors,
    ReturningMauRate, ReturningMauRate_Color = Color_ReturningMau,
    EUPL_P95, EUPL_P95_Color = Color_Performance,
    EUPL_P50, EUPL_P50_Color = Color_Performance,
    ASHA = round(ASHA, 2), Color_ASHA = Color_ASHA,
    Feedback = Feedback_SubScore, Color_Feedback, 
    OpenInClientRate, OpenInClientRate_Color = Color_OpenInClient;
union 
V1data, V2data
| sort by Date asc;
`;
