import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { getQueryParamsForLevel } from "api";
import { HealthDistributionTable } from "components/OverviewComponents/HealthDistribution/HealthDistributionTable";
import { HealthDistributionTrendChart } from "components/OverviewComponents/HealthDistribution/HealthDistributionTrendChart";
import { kustoApiResponseStatusCode } from "config/ApiConfiguration";
import { commonPageBlock, splitCommonPageBlockIntoEqual } from "pages/common";
import { getHealthChartData } from "pages/Copilot/ApiHandler";
import {
    CopilotExecOverViewScoreType,
    CopilotExecOverviewFilterState,
} from "pages/Copilot/types";
import { formatWorkloadHealthData } from "pages/ExecOverview/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { EXEC_OVERVIEW_MESSAGES } from "utils/Messages";

const initialState = {
    healthChart: { headers: [], rows: [] },
    loading: true,
};

const CopilotExecOverviewHealthDistribution = ({
    filters,
    scoreType,
}: {
    filters: CopilotExecOverviewFilterState;
    scoreType: CopilotExecOverViewScoreType;
}) => {
    const [healthData, setHealthData] = useState(initialState);
    const [error, setError] = useState<string>(null);

    useEffect(() => {
        const getPayloadData = async (
            payloadFilters: CopilotExecOverviewFilterState,
        ) => {
            try {
                let queryParams: {} = { ...payloadFilters };
                const levelQueryParams = getQueryParamsForLevel(
                    payloadFilters.level,
                );
                queryParams = {
                    ...levelQueryParams,
                    ...queryParams,
                };

                const healthChart = await getHealthChartData(queryParams, scoreType);
                if (healthChart.status !== kustoApiResponseStatusCode.isOK) {
                    setHealthData((data) => {
                        return { ...data, loading: false };
                    });
                    setError(EXEC_OVERVIEW_MESSAGES.HEALTH_INFO_DISPLAY_ERROR);
                }
                try {
                    const healthChartResponse = formatWorkloadHealthData(
                        healthChart?.data,
                    );
                    const responseData = {
                        healthChart: healthChartResponse.healthChartData,
                        loading: false,
                    };
                    setHealthData(responseData);
                } catch (error) {
                    logException(
                        new TenantInsightsException(
                            Severity.SEV3,
                            "ExecOverviewHealthDistributionFormattingFailed",
                        ),
                        {
                            message:
                                "Failure formatting response data from Exec Health Distribution page query",
                            responseCodes: {
                                healthChart: healthChart.status,
                            },
                        },
                        error,
                    );
                    setHealthData((healthData) => {
                        return { ...healthData, loading: false };
                    });
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "ExecOverviewHealthDistributionDataFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching data for Exec Health Distribution page",
                    },
                    error,
                );
                setHealthData((healthData) => {
                    return { ...healthData, loading: false };
                });
                setError(EXEC_OVERVIEW_MESSAGES.HEALTH_INFO_DISPLAY_ERROR);
            }
        };

        if (filters.date !== null) {
            setHealthData(initialState);
            setError(null);
            getPayloadData({
                date: filters.date,
                application: filters.application,
                cohort: filters.cohort,
                level: filters.level,
                mau: filters.mau,
                platform: filters.platform,
            });
        }
    }, [
        filters.date,
        filters.application,
        filters.cohort,
        filters.level,
        filters.mau,
        filters.platform,
        scoreType,
    ]);

    return (
        <>
            {error ? (
                <ErrorFallback message={error} />
            ) : healthData.healthChart.rows.length === 0 && !healthData.loading ? (
                <ShimmeredDetailsListWrapper
                    items={[]}
                    message={EXEC_OVERVIEW_MESSAGES.NO_HEALTH_INFO_DISPLAY_BANNER}
                />
            ) : (
                <Stack styles={commonPageBlock} data-testid="healthDistribution">
                    <Stack horizontal>
                        <Stack.Item styles={splitCommonPageBlockIntoEqual}>
                            <HealthDistributionTrendChart
                                payload={healthData.healthChart}
                                level={filters.level}
                                title={`Overall ${scoreType} Trend`}
                            />
                        </Stack.Item>
                        <Stack.Item
                            styles={splitCommonPageBlockIntoEqual}
                            data-testid="healthDistributionTable"
                        >
                            <HealthDistributionTable
                                payload={healthData.healthChart.rows.filter(
                                    (i) => i[3] === true,
                                )}
                                level={filters.level}
                                loading={healthData.loading}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default CopilotExecOverviewHealthDistribution;
