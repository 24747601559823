import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { PivotSize } from "utils/Constants";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import "components/CommonPivot/style.css";

interface CommonPivotProps {
    children: React.ReactNode;
    pageTitle: string;
    sectionTabs: Record<string, string>;
}

// Handles updating the URL based on the section selected and standardizes appearance of pivots
const CommonPivot = ({ children, pageTitle, sectionTabs }: CommonPivotProps) => {
    const [section, setSection] = useState<keyof typeof sectionTabs>(() => {
        const filtersFromQueryParams = extractQueryParams();
        return filtersFromQueryParams["section"] ?? Object.keys(sectionTabs)[0];
    });

    // Set the section in case not yet in the URL
    useEffect(() => {
        setUpDocumentUrl({ ...extractQueryParams(), section }, pageTitle);
    }, [pageTitle, section]);

    const handleLinkClick = (item?: PivotItem) => {
        const updatedSection = item.props.itemKey! as keyof typeof sectionTabs;
        setUpDocumentUrl(
            {
                ...extractQueryParams(),
                section: updatedSection,
            },
            pageTitle,
        );

        setSection(updatedSection);
    };

    return (
        <Pivot
            linkSize={PivotSize.large}
            selectedKey={section}
            onLinkClick={handleLinkClick}
        >
            {children}
        </Pivot>
    );
};

export default CommonPivot;
