export interface ICosmosDbConnectionConfig {
    database: string;
    container: string;
}

export const notesDbConfig: ICosmosDbConnectionConfig = {
    database: "tenantengagement",
    container: "Notes",
};

export const outagesDbConfig: ICosmosDbConnectionConfig = {
    database: "tenantengagement",
    container: "Outages",
};

export const consumerScorecardDbConfig: ICosmosDbConnectionConfig = {
    database: "consumerGrowthScorecard",
    container: "Notes",
};
