import React from "react";
import { useSearchParams } from "react-router-dom";
import { useM365Theme } from "@m365-admin/customizations";
import type { INavLink, INavLinkGroup } from "@m365-admin/nav";
import { Nav } from "@m365-admin/nav";
import { navLinkGroups } from "components/InsightsComponents/constants";
import { getNavKey } from "components/InsightsComponents/Helpers";
import { useNavigateToDashboard } from "utils/InsightsHooks";

interface NavigationPanelProps {
    navCollapsed: boolean;
    setNavCollapsed: (navCollapsed: boolean) => void;
}

const NavigationPanel = ({
    navCollapsed,
    setNavCollapsed,
}: NavigationPanelProps) => {
    const navigateToDashboard = useNavigateToDashboard();

    // eslint-disable-next-line no-unused-vars
    const [_searchParams, setSearchParams] = useSearchParams({
        Group: getNavKey(window.location),
    });

    const theme = useM365Theme();
    /**
     * These colors are used in the Nav component internally and the specific variables are missing from semanticColors
       and are throwing exceptions. Adding them manually to resolve the issues.
     */
    theme.semanticColors["navBackground"] = "#ffffff";
    theme.semanticColors["navItemBackgroundHovered"] = "#b3b3b3";
    theme.semanticColors["navItemBackgroundPressed"] = "#b3b3b3";

    const selectLinkAddClick = (groups: INavLinkGroup[]): INavLinkGroup[] => {
        const processLinks = (links: INavLink[]): INavLink[] => {
            const newLinks = links.map((link: INavLink) => {
                let subLinks: INavLink[];

                if (link.links) {
                    subLinks = processLinks(link.links);
                }

                return {
                    ...link,
                    links: subLinks,
                    onClick: (
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                    ) => {
                        // Avoid the page refreshing when navigating to customer insights page from side nav
                        // External links will still refresh the page
                        // !link.links means it is a dashboard link, else it is a dashboard group
                        if (!link.links) {
                            navigateToDashboard(
                                e,
                                link,
                                setNavCollapsed,
                                "Navigation Side Panel",
                            );
                        } else {
                            setSearchParams({ Group: link.key.toString() });
                        }
                    },
                    isSelected: link.key === getNavKey(window.location),
                };
            });

            return newLinks;
        };

        const newGroups = groups.map((value: INavLinkGroup) => {
            if (value.links) {
                const newLinks = processLinks(value.links);

                return { ...value, links: newLinks };
            }
            return { ...value };
        });
        return newGroups;
    };

    const getNavButtonLabel: string = navCollapsed
        ? "Expand Navigation"
        : "Collapse Navigation";

    return (
        <Nav
            isNavCollapsed={navCollapsed}
            groups={selectLinkAddClick(navLinkGroups)}
            onNavCollapsed={() => setNavCollapsed(!navCollapsed)}
            collapseNavLinkProps={{
                id: "collapseButton",
                "data-hint": "collapse Button",
                title: getNavButtonLabel,
                primaryIconName: "GlobalNavButton",
                "aria-label": getNavButtonLabel,
            }}
            styles={{
                root: {
                    position: "fixed",
                    top: "51px",
                    bottom: 0,
                },
                navContainer: {
                    backgroundColor: "#F0F0F0",
                },
                subComponentStyles: {
                    collapseNavButtonStyles: {
                        root: {
                            backgroundColor: "#F0F0F0",
                        },
                    },
                },
            }}
        />
    );
};

export default NavigationPanel;
