import React from "react";
import { Stack, mergeStyleSets } from "@fluentui/react";
import { CopilotTrendTemplate } from "components/AIScorecardComponents/CopilotTrends/CopilotTrendTemplate";
import { commonPageBlock } from "pages/common";

const defaultApps = [
    "Word",
    "Excel",
    "PowerPoint",
    "OneNote",
    "Outlook",
    "Teams",
    "Loop",
    "Whiteboard",
    "M365 Chat",
    "Stream",
    "Forms",
    "Planner",
];

const crossApps = Array.from(defaultApps).concat("WXPN");

export const commonPageBlockNoPadding = mergeStyleSets(
    { ...commonPageBlock },
    {
        root: {
            padding: "0px",
        },
    },
);
export const CopilotM365Trends = ({ filters }) => {
    return (
        <Stack>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="Tried"
                    chartTitle={filters.cadence === "Weekly" ? "WAU" : "MAU"}
                    applications={crossApps}
                />
            </Stack.Item>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="TriedCountsPerUser"
                    chartTitle={"Tries Per User"}
                    applications={crossApps}
                />
            </Stack.Item>
            <Stack.Item styles={commonPageBlockNoPadding}>
                <CopilotTrendTemplate
                    filters={filters}
                    id="QuickRatio"
                    chartTitle={"Quick Ratio"}
                    applications={crossApps}
                />
            </Stack.Item>
        </Stack>
    );
};
